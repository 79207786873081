import { EditorView, EditorSelection } from '@uiw/react-codemirror';

// By default, CodeMirror uses triple click to select the entire line, plus a new line. We dont want the new line.
const tripleClickExtension = EditorView.mouseSelectionStyle.of((view, event) => {
  if (event.detail !== 3 || event.button !== 0) return null;
  let start = view.posAtCoords(event);
  if (start == null) return null;
  let startSel = view.state.selection;
  return {
    get(curEvent, extend, multiple) {
      let head = view.posAtCoords(curEvent);
      if (head == null) return startSel;
      let anchor = extend ? startSel.main.anchor : start!;
      let anchorLine = view.state.doc.lineAt(anchor),
        headLine = view.state.doc.lineAt(head);
      let range =
        head >= anchor
          ? EditorSelection.range(anchorLine.from, headLine.to)
          : EditorSelection.range(anchorLine.to, headLine.from);
      return multiple ? startSel.addRange(range) : EditorSelection.create([range]);
    },
    update(update) {
      start = update.changes.mapPos(start!);
      startSel = startSel.map(update.changes);
    },
  };
});

export default tripleClickExtension;
