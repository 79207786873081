import React, { useEffect, useState } from 'react';

import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';

import IconButton, { IconButtonProps } from './IconButton';

interface CopyButtonProps extends Omit<IconButtonProps, 'onClick'> {
  onClick(): void; // This should call navigator.clipboard.writeText(value);
  className?: string;
}

const CopyButton = (props: CopyButtonProps) => {
  const { onClick, ...rest } = props;
  const [copiedTip, setCopiedTip] = useState(false);

  useEffect(() => {
    copiedTip &&
      setTimeout(() => {
        setCopiedTip(false);
      }, 1000);
  }, [copiedTip]);

  const handleCopy = () => {
    setCopiedTip(true);
    if (onClick) {
      onClick();
    }
  };

  return (
    <TooltipTrigger tip={copiedTip ? 'Copied' : 'Copy'} forceShow={copiedTip} placement="top">
      <IconButton
        icon="Files"
        variant="lightDullTransparent"
        size="small"
        onClick={handleCopy}
        {...rest}
      />
    </TooltipTrigger>
  );
};

export default CopyButton;
