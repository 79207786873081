import { Formik } from 'formik';
import * as yup from 'yup';

import Button from 'components/inputs/basic/Button/Button';
import ListboxFormikGroup, {
  ListboxOption,
} from 'components/inputs/formik_group/ListboxFormikGroup/ListboxFormikGroup';
import RadioFormikGroup, {
  RadioInputOption,
} from 'components/inputs/formik_group/RadioFormikGroup/RadioFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { SnowflakeUser, SnowflakeUserSaveableFields } from '../useSnowflakeUsers';

const formSchema = yup.object({
  first_name: yup.string().trim().max(255, 'First name cannot be longer than 255 characters'),
  last_name: yup.string().trim().max(255, 'Last name cannot be longer than 255 characters'),
  email: yup.string().email('Invalid email').trim(),
  default_role: yup.string(),
  disabled: yup.boolean(),
});

interface UpdateSnowflakeUserModalProps {
  snowflakeUser: SnowflakeUser;
  snowflakeRoleOptions: ListboxOption[];
  snowflakeRoleError: string;
  snowflakeRoleLoading: boolean;
  savingError: string;
  saving: boolean;
  onClose: () => void;
  onUpdateUser: (username: string, newValues: SnowflakeUserSaveableFields) => void;
}

export default function UpdateSnowflakeUserModal(props: UpdateSnowflakeUserModalProps) {
  const {
    snowflakeUser,
    snowflakeRoleOptions,
    snowflakeRoleError,
    snowflakeRoleLoading,
    savingError,
    saving,
    onClose,
    onUpdateUser,
  } = props;

  const initialValues: SnowflakeUserSaveableFields = {
    first_name: snowflakeUser.first_name,
    last_name: snowflakeUser.last_name,
    email: snowflakeUser.email,
    default_role: snowflakeUser.default_role,
    disabled: snowflakeUser.disabled,
  };

  const radioOptions: RadioInputOption[] = [
    { label: 'Yes', value: 'false' },
    { label: 'No', value: 'true' },
  ];

  const handleSave = (newValues: SnowflakeUserSaveableFields) => {
    onUpdateUser(snowflakeUser.name, newValues);
  };

  return (
    <Modal
      onClose={onClose}
      header={`Snowflake User: ${snowflakeUser.name}`}
      cancelButton={true}
      containerClass="w-[500px]"
    >
      <div className="flex flex-col h-full pt-2">
        {savingError && (
          <Alert className="mx-4 mb-2" variant="error">
            {savingError}
          </Alert>
        )}
        <Formik validationSchema={formSchema} onSubmit={handleSave} initialValues={initialValues}>
          {({ handleSubmit }) => {
            return (
              <form noValidate onSubmit={handleSubmit} className="flex flex-col h-full w-full">
                <div className="px-4 mb-2">
                  <TextFormikGroup name="first_name" label="First Name" groupClass="mt-4" />
                  <TextFormikGroup name="last_name" label="Last Name" groupClass="mt-4" />
                  <TextFormikGroup name="email" label="Email" groupClass="mt-4" type="email" />
                  <ListboxFormikGroup
                    label="Default Role"
                    name="default_role"
                    options={snowflakeRoleOptions}
                    groupClass="mt-4"
                    optionsMaxHeightClass="max-h-[250px]"
                    error={snowflakeRoleError}
                    spinning={snowflakeRoleLoading}
                    postLabelElement={
                      <InfoIcon
                        content="Updating the default role will revoke the old role from this user."
                        containerClass="ml-1"
                        popoverProps={{ style: { maxWidth: '700px' } }}
                      />
                    }
                  />
                  <RadioFormikGroup
                    name="disabled"
                    label="Enabled"
                    options={radioOptions}
                    groupClass="mt-4"
                  />
                  <Button type="submit" variant="lightAction" spinning={saving} className="mt-4 mb-2">
                    Save
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}
