import cn from 'classnames';

import IconButton from 'components/inputs/basic/Button/IconButton';

interface AIExplainSqlButtonProps {
  onClick: () => void;
  className?: string;
}

const AIExplainSqlButton = (props: AIExplainSqlButtonProps) => {
  const { onClick, className } = props;

  return (
    <IconButton
      text="Explain SQL"
      icon="Stars"
      onClick={onClick}
      size="small"
      variant="lightDullTransparent"
      className={cn('mr-1', className)}
    />
  );
};

export default AIExplainSqlButton;
