import axios from 'axios';

import { usePoll } from 'hooks/useApi';

import API from './API';
import { VersionUserProfile } from './APITypes';

// pending: We told the backend that there is a file to upload and it gave us a signed url
// uploaded: We uploaded the file to the signed url and told the backend to do its thing (load the file to Snowflake, etc.)
// success/failure: We hit a terminal state
export type CSVUploadState = 'pending' | 'uploaded' | 'success' | 'failure';

export interface CSVUpload {
  id: string;
  created_at: string;
  updated_at: string;
  creator_user_profile: VersionUserProfile;
  table: string;
  file_name: string;
  state: CSVUploadState;
  error_message: string | null;
  num_rows: number | null;
}

type CSVUploadInitialResponse = CSVUpload & {
  upload_url: string;
};

interface BaseSaveableCSVUploadProps {
  schema: string;
  table_name: string;
  description: string;
  overwrite?: boolean;
}

export type InitialSaveableCSVUploadProps = BaseSaveableCSVUploadProps & {
  file: File | null;
};

export type SaveableCSVUploadProps = BaseSaveableCSVUploadProps & {
  file: File;
};

const uploadFile = async (file: File, signedUrl: string) => {
  return axios.put(signedUrl, file, {
    // Need to keep the headers in sync with get_signed_upload_url on the backend
    headers: {
      'Content-Type': 'application/octet-stream',
      'X-Goog-Content-Length-Range': '1,1073741824', // up to 1GB
    },
  });
};

const csvUploadAPI = {
  upload: async (saveableProps: SaveableCSVUploadProps): Promise<CSVUpload> => {
    const { table_name, schema, description, file, overwrite } = saveableProps;
    const api = new API();

    // Ask the backend for a signed url where we can load the file to GCS
    return api
      .post('api/csv_uploads', {
        table_name,
        schema,
        overwrite,
        file_name: file.name,
        description,
      })
      .then((resp) => {
        const initialResponse = resp.data as CSVUploadInitialResponse;
        // Load the file to the GCS signed url
        return uploadFile(file, initialResponse.upload_url)
          .then((resp) => {
            // Tell the backend that the file is uploaded
            return api
              .patch(`/api/csv_uploads/${initialResponse.id}`, {
                state: 'uploaded',
              })
              .then((resp) => resp.data as CSVUpload);
          })
          .catch((e) => {
            // Tell the backend that the file upload failed
            return api
              .patch(`/api/csv_uploads/${initialResponse.id}`, {
                state: 'failure',
                error_message: e.message,
              })
              .then((resp) => resp.data as CSVUpload);
          });
      });
  },
  getUpload: async (csvUploadId: string): Promise<CSVUpload> => {
    const api = new API();
    return api.get(`api/csv_uploads/${csvUploadId}`).then((resp) => resp.data as CSVUpload);
  },
  getLatestUploadsByTable: async (): Promise<CSVUpload[]> => {
    // Get the latest CSVUpload for each table
    const api = new API();
    return api.get(`api/csv_uploads/latest`).then((resp) => resp.data as CSVUpload[]);
  },
  getUploadsForTable: async (table: string): Promise<CSVUpload[]> => {
    const api = new API();
    return api.get(`api/csv_uploads?table=${table}`).then((resp) => resp.data as CSVUpload[]);
  },
  usePollCSVUploadStatus: function (props: { csvUploadId: string | null; pollForStatus: boolean }) {
    const { csvUploadId, pollForStatus } = props;
    const {
      data: pollData,
      error: pollError,
      isLoading: pollIsLoading,
    } = usePoll(
      'csvStatus',
      () => this.getUpload(csvUploadId || ''), // empty string appeases TypeScript; we won't poll unless !!csvUploadId
      [2], // Poll every 2 seconds
      pollForStatus && !!csvUploadId,
    );
    return { pollData, pollError, pollIsLoading };
  },
};
export default csvUploadAPI;
