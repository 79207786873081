import Button from 'components/inputs/basic/Button/Button';

export interface ObjectNotificationSettingsProps {
  isSubscribed: boolean;
  savingSubscription: boolean;
  handleToggleSubscription(): void;
  currentStateExplanation: string;
  containerClass?: string;
}

export default function ObjectNotificationSettings(props: ObjectNotificationSettingsProps) {
  const {
    isSubscribed,
    savingSubscription,
    handleToggleSubscription,
    currentStateExplanation,
    containerClass,
  } = props;
  return (
    <div className={containerClass}>
      <h4 className="text-base font-medium mb-2">Notification Settings</h4>
      <Button
        size="small"
        variant="darkDullAction"
        onClick={handleToggleSubscription}
        spinning={savingSubscription}
        className="w-[108px]"
      >
        {isSubscribed ? 'Unsubscribe' : 'Subscribe'}
      </Button>
      <p className="mt-1 text-pri-gray-500 text-sm">{currentStateExplanation}</p>
    </div>
  );
}
