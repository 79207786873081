import { useMemo } from 'react';

import { Diagram3Fill } from 'react-bootstrap-icons';

import { Connector } from 'api/APITypes';
import { ReportAvailable } from 'api/dashboardAPI';
import ConfirmDeleteModal from 'components/layouts/containers/modals/ConfirmDeleteModal/ConfirmDeleteModal';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { getTransforms } from '../reportUtils';

interface DeleteReportModalProps {
  reportsAvailable: ReportAvailable[];
  deleting: boolean;
  error: string;
  onCancel(): void;
  onConfirm(): void;
  connector: Connector;
}

export default function DeleteReportModal(props: DeleteReportModalProps) {
  const { reportsAvailable, deleting, error, onCancel, onConfirm, connector } = props;

  const transforms = useMemo(() => getTransforms(reportsAvailable), [reportsAvailable]);

  return (
    <ConfirmDeleteModal
      header={`Delete report for ${connector.schema}?`}
      enableConfirm={true}
      confirmText="Delete"
      deleting={deleting}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <div className="max-h-[60vh] p-4">
        <div className="mt-2 font-medium">
          Deleting the report will also delete the following transforms:
        </div>
        <div className="mt-1 grid grid-cols-1 gap-y-2 overflow-auto">
          {transforms.map((t) => {
            const fullName = `${t.report_schema_prefix}_${connector.schema}.${t.table_name}`;
            return (
              <div key={fullName} className="h-[24px] f-row-y-center">
                <div>
                  <Diagram3Fill size={16} color="var(--teal)" transform="rotate(90 0 0)" />
                </div>
                <span className="ml-1">{fullName}</span>
              </div>
            );
          })}
        </div>
        {error && (
          <Alert variant="error" className="mt-4">
            {error}
          </Alert>
        )}
      </div>
    </ConfirmDeleteModal>
  );
}
