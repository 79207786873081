import React from 'react';

import cn from 'classnames';

export type Variant =
  | 'error' // Something ran and it failed either due to input error or server error.
  | 'kinda_bad' // This should probably be 'warning' but I didn't want to break legacy code. Incomplete or missing data. It's not an error, but you should fix it.
  | 'warning' // Historically used when account setup is incomplete.
  | 'no_results' // Search didn't return any results.
  | 'info'; // FYIs

export interface AlertProps {
  variant: Variant;
  children: React.ReactNode;
  className?: string;
}

const VARIANT_COLORS: Record<Variant, string> = {
  error: 'bg-pri-error-100 border-pri-error-300 text-pri-error-700',
  kinda_bad: 'bg-sec-orange-100 border-sec-orange-300 text-sec-orange-700',
  warning: 'bg-pri-warning-50 text-pri-warning-700',
  no_results: 'bg-sec-purple-100 border-sec-purple-300 text-purple',
  info: 'bg-sec-blue-light-100 text-pri-gray-500',
};

export default function Alert(props: AlertProps) {
  const { variant, children, className } = props;
  const baseClasses = 'border-1 px-5 py-3 f-col-center rounded-[3px]';
  const actualClasses = cn(baseClasses, VARIANT_COLORS[variant], className);

  return <div className={actualClasses}>{children}</div>;
}
