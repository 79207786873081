import {
  BiParentVertex,
  FlowchartQueryModel,
  FlowchartVertex,
  getVertex,
  HasSelectColumns,
  MonoParentVertex,
  SourceTable,
} from '../model/FlowchartQueryModel';

/**
 * @param fqm the entire FlowchartQueryModel
 * @param fromVertex the vertex to start the search from
 * @param columnName the columnName to search for
 * @returns the first ancestor vertex to define the given columnName
 */
export function getDefiningVertex(
  fqm: FlowchartQueryModel,
  fromVertex: FlowchartVertex,
  columnName: string,
): FlowchartVertex | null {
  // Base Case: I am the most recent vertex to define the column
  if (definesAlias(fromVertex, columnName) || definesColumn(fromVertex, columnName)) {
    return fromVertex;
  }

  // Recurse on ancestors
  if (fromVertex.isMonoParent()) {
    const monoParent = fromVertex as MonoParentVertex;
    if (monoParent.singleParentID) {
      const singleParent = getVertex(fqm, monoParent.singleParentID);
      return getDefiningVertex(fqm, singleParent, columnName);
    }
  }

  if (fromVertex.isBiParent()) {
    const biParent = fromVertex as BiParentVertex;
    if (biParent.leftParentID && biParent.rightParentID) {
      const leftParent = getVertex(fqm, biParent.leftParentID);
      const rightParent = getVertex(fqm, biParent.rightParentID);
      return (
        getDefiningVertex(fqm, leftParent, columnName) || getDefiningVertex(fqm, rightParent, columnName)
      );
    }
  }

  return null;
}

function definesAlias(vertex: FlowchartVertex, columnName: string) {
  if (vertex.hasSelectColumns()) {
    const selectColumns = vertex as HasSelectColumns;
    if (selectColumns.selectedColumns?.find((s) => s.alias === columnName)) {
      return true;
    }
  }
  return false;
}

function definesColumn(vertex: FlowchartVertex, columnName: string) {
  if (vertex.type === 'source_table') {
    const sourceTable = vertex as SourceTable;
    if (sourceTable.columns.find((c) => c.name === columnName)) {
      return sourceTable;
    }
  }

  if (vertex.hasSelectColumns()) {
    const selectColumns = vertex as HasSelectColumns;
    if (selectColumns.selectedColumns?.find((s) => s.name === columnName)) {
      return true;
    }
  }

  return false;
}
