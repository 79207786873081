import { SQLCommand } from './SQLBox/SQLBox';

const SQLcommands: SQLCommand[] = [
  {
    comment: `-- 0. Set the password `,
    commands: [`SET MOZART_PASSWORD='<mypassword>';`],
  },
  {
    comment: `-- 1. Get on the ACCOUNTADMIN role in Snowflake`,
    commands: [`USE ROLE ACCOUNTADMIN;`],
  },
  {
    comment: `-- 2. Create a new role`,
    commands: [`CREATE ROLE MOZARTADMIN_ROLE;`],
  },
  {
    comment: `-- 3. Create a new Snowflake user account in your organization`,
    commands: [`CREATE USER MOZARTADMIN PASSWORD=$MOZART_PASSWORD DEFAULT_ROLE='MOZARTADMIN_ROLE';`],
  },
  {
    comment: `-- 4. Give the new user the privileges of mozartadmin_role`,
    commands: [`GRANT ROLE MOZARTADMIN_ROLE TO USER MOZARTADMIN;`],
  },
  {
    comment: `-- 5. Grant appropriate roles & privileges`,
    commands: [
      `GRANT CREATE USER ON ACCOUNT TO ROLE MOZARTADMIN_ROLE;`,
      `GRANT CREATE ROLE ON ACCOUNT TO ROLE MOZARTADMIN_ROLE;`,
      `GRANT CREATE DATABASE ON ACCOUNT TO ROLE MOZARTADMIN_ROLE;`,
      `GRANT CREATE WAREHOUSE ON ACCOUNT TO ROLE MOZARTADMIN_ROLE;`,
      `GRANT IMPORTED PRIVILEGES ON DATABASE SNOWFLAKE TO ROLE MOZARTADMIN_ROLE;`,
      `GRANT CREATE SHARE, IMPORT SHARE ON ACCOUNT TO ROLE MOZARTADMIN_ROLE;`,
      `GRANT CREATE INTEGRATION ON ACCOUNT TO ROLE MOZARTADMIN_ROLE;`,
    ],
  },
];

export default SQLcommands;
