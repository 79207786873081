import { ReactNode } from 'react';

import { Connector } from 'api/APITypes';

import ConnectorLayout from './ConnectorLayout';

interface PictureSentenceLayoutProps {
  connector: Pick<Connector, 'service' | 'schema'>;
  img: string;
  sentence: string;
  children: ReactNode;
}

export default function PictureSentenceLayout(props: PictureSentenceLayoutProps) {
  const { connector, img, sentence, children } = props;

  return (
    <ConnectorLayout connector={connector}>
      <div className="f-row-y-center mt-[40px]">
        <img src={img} alt={img} className="w-[285px]" />
        <div className="ml-[48px]">
          <h1 className="text-xl font-medium">{sentence}</h1>
          <div className="mt-6">{children}</div>
        </div>
      </div>
    </ConnectorLayout>
  );
}
