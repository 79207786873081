import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import { Formik } from 'formik';
import url from 'url';
import * as yup from 'yup';

import API from 'api/API';
import { CompanyRole } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import RadioFormikGroup, {
  RadioInputOption,
} from 'components/inputs/formik_group/RadioFormikGroup/RadioFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { deslug } from 'utils/Form';

import { UserRoleIconContent } from './ListUsers/MozartUsersTab/MozartUsersTabView/UserTable/UserRoleIconContent';

const SERVER_ERROR_SLUGS = {
  email_already_in_use: 'An account with that email already exists.',
};

const schema = yup.object({
  first_name: yup.string().required('Required').trim(),
  last_name: yup.string().required('Required').trim(),
  email: yup.string().trim().lowercase().required('Required').email('Invalid email'),
  company_role: yup.string().required('Required').oneOf(['admin', 'editor', 'viewer']),
  snowflake_role: yup.string().required('Required'),
});

interface AddUserProps {
  first_name: string;
  last_name: string;
  email: string;
  company_role: CompanyRole;
  snowflake_role: string;
}

export default function AddUser(props: AddUserProps) {
  useTitle('Add User');

  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const initialValues: AddUserProps = {
    first_name: '',
    last_name: '',
    email: '',
    company_role: 'editor',
    snowflake_role: 'MOZART_EDITOR',
  };

  const handleSave = (values: AddUserProps, actions: any) => {
    setSaving(true);
    setErrorMessage('');

    const postData = schema.cast(values);

    // If the user cannot input snowflake role, use a map from their mozart role
    const mozartRoleToSnowflakeRoleMap = {
      admin: 'MOZART_ADMIN',
      editor: 'MOZART_EDITOR',
      viewer: 'MOZART_VIEWER',
    };
    postData['snowflake_role'] = mozartRoleToSnowflakeRoleMap[postData.company_role as CompanyRole];

    const api = new API();

    api
      .post('api/company/users/new', postData)
      .then((response) => {
        analytics.track('AddUser Add');
        const usersUrl = url.format({
          pathname: '/users',
          query: { email: values.email },
        });
        navigate(usersUrl);
      })
      .catch((error) => {
        // Form validation errors
        if (error.response?.status === 400) {
          const data = error.response.data;
          actions.setErrors(deslug(data, SERVER_ERROR_SLUGS));
        } else {
          setErrorMessage('There was a problem creating your user.');
        }
        setSaving(false);
      });
  };

  const handleCancel = () => {
    analytics.track('AddUser Cancel');
    navigate('/users');
  };

  const radioOptions: RadioInputOption[] = [
    { label: 'Admin', value: 'admin' },
    { label: 'Editor', value: 'editor' },
    { label: 'Viewer', value: 'viewer' },
  ];

  return (
    <CenteredLayout title="Add User" maxWidth="445px">
      <Formik validationSchema={schema} onSubmit={handleSave} initialValues={initialValues}>
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <div className="row-split">
                <TextFormikGroup autoFocus name="first_name" label="First Name" />
                <TextFormikGroup name="last_name" label="Last Name" />
              </div>
              <TextFormikGroup
                name="email"
                label="Email"
                placeholder="sarah@infolabs.com"
                groupClass="mt-4"
              />
              <RadioFormikGroup
                name="company_role"
                label="Mozart Role"
                postLabelElement={
                  <InfoIcon
                    content={UserRoleIconContent}
                    containerClass="ml-1"
                    popoverProps={{ style: { maxWidth: '700px' } }}
                  />
                }
                options={radioOptions}
                groupClass="mt-4"
              />
              {errorMessage && (
                <Alert variant="error" className="mt-4">
                  {errorMessage}
                </Alert>
              )}
              <div className="row-split mt-4">
                <Button type="submit" variant="lightAction" spinning={saving} className="w-full">
                  Add
                </Button>
                <Button variant="lightDanger" className="w-full" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </CenteredLayout>
  );
}
