import React from 'react';

import { useTitle } from 'react-use';

import { Experiments, ExperimentKeys, experimentOptions } from 'Experiments';
import { Formik } from 'formik';

import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import Switch from 'components/inputs/basic/Switch/Switch';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import { useExperiments } from 'context/ExperimentContext';

export default function ListExperiments() {
  useTitle('Experiments');
  const { experiments, saveExperiments } = useExperiments();

  const handleSave = (values: Experiments) => {
    saveExperiments(values);
  };

  const experimentKeys = Object.keys(experiments) as ExperimentKeys[];

  return (
    <CenteredContainer maxWidth="600px" title="Experiments">
      <h5 className="text-xl font-medium text-pri-error-700">
        DO NOT turn one of these on without checking with the experiment's project leader.
      </h5>
      <Formik onSubmit={handleSave} initialValues={experiments}>
        {({ handleSubmit, handleChange, values, setFieldValue }) => {
          const saveSwitchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(event);
            handleSubmit();
          };

          const saveListboxOnChange = (field: string, newVal: ListboxValue) => {
            setFieldValue(field, newVal);
            handleSubmit();
          };
          return (
            <form noValidate onSubmit={handleSubmit}>
              {experimentKeys.map((k) => (
                <div key={k} className="flex items-center mt-2 ml-6">
                  {typeof values[k] === 'boolean' ? (
                    <Switch name={k} checked={values[k] as boolean} onChange={saveSwitchOnChange} />
                  ) : (
                    <Listbox
                      value={values[k] as unknown as string}
                      onChange={(newVal) => saveListboxOnChange(k, newVal)}
                      options={(experimentOptions[k] as string[]).map((o) => ({ label: o, value: o }))}
                      widthClass="w-[300px]"
                    />
                  )}
                  <div className="ml-2">{k}</div>
                </div>
              ))}
            </form>
          );
        }}
      </Formik>
    </CenteredContainer>
  );
}
