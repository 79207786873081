import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import TextInput from 'components/inputs/basic/TextInput/TextInput';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import AIAssistantRow from 'components/query/ChatAIEditor/AIAssistantRow';
import { AIAssistantChatMessage } from 'components/query/useAIAssistant';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { formatDate, parseIso } from 'utils/dateTime';

interface RecentChat {
  object_id: string;
  user_profile_id: string;
  user_name: string;
  company_name: string;
  timestamp: string;
}

const formSchema = Yup.object().shape({
  object_id: Yup.string().trim().required('Required'),
  user_profile_id: Yup.string().trim().required('Required'),
});

const initialValues = {
  object_id: '',
  user_profile_id: '',
};

interface FormValues {
  object_id: string;
  user_profile_id: string;
}

export default function AIAssistantHistoryTab() {
  const [chatHistory, setChatHistory] = useState<AIAssistantChatMessage[]>([]);
  const [recentChats, setRecentChats] = useState<RecentChat[]>([]);
  const [loadingRecentChats, setLoadingRecentChats] = useState(true);
  const [recentChatsError, setRecentChatsError] = useState('');
  const [error, setError] = useState('');
  const [formValues, setFormValues] = useState<FormValues>(initialValues);
  const [filterText, setFilterText] = useState('');

  const location = useLocation();
  const history = useHistory();

  const api = useMemo(() => new API(), []);

  const fetchRecentChats = useCallback(async () => {
    setLoadingRecentChats(true);
    setRecentChatsError('');
    try {
      const response = await api.get('api/internal/recent_ai_chats');
      setRecentChats(response.data.recent_chats);
    } catch (err) {
      setRecentChatsError('Unable to fetch recent chats.');
    } finally {
      setLoadingRecentChats(false);
    }
  }, [api]);

  const fetchChatHistory = useCallback(
    async (object_id: string, user_profile_id: string) => {
      setError('');
      const params = `object_id=${object_id}&user_profile_id=${user_profile_id}`;
      try {
        const response = await api.get(`api/ai_pipeline_assistant?${params}`);
        setChatHistory(response.data.chat_history || []);
      } catch (err) {
        setError('Unable to fetch chat history.');
      }
    },
    [api],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const object_id = searchParams.get('object_id');
    const user_profile_id = searchParams.get('user_profile_id');

    if (object_id && user_profile_id) {
      setFormValues({ object_id, user_profile_id });
      fetchChatHistory(object_id, user_profile_id);
    } else {
      setFormValues(initialValues);
      setChatHistory([]);
    }

    fetchRecentChats();
  }, [location.search, fetchRecentChats, fetchChatHistory]);

  const handleFormSubmit = useCallback(
    (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      const searchParams = new URLSearchParams();
      searchParams.set('object_id', values.object_id);
      searchParams.set('user_profile_id', values.user_profile_id);
      history.push({ search: `?${searchParams.toString()}` });
      fetchChatHistory(values.object_id, values.user_profile_id);
      setSubmitting(false);
    },
    [history, fetchChatHistory],
  );

  const handleRecentChatClick = useCallback(
    (chat: RecentChat) => {
      const searchParams = new URLSearchParams();
      searchParams.set('object_id', chat.object_id);
      searchParams.set('user_profile_id', chat.user_profile_id);
      history.push({ search: `?${searchParams.toString()}` });
    },
    [history],
  );

  const filteredChats = useMemo(() => {
    if (!filterText) {
      return recentChats;
    }
    const lowercasedFilter = filterText.toLowerCase();
    return recentChats.filter((chat) => {
      return (
        chat.user_name.toLowerCase().includes(lowercasedFilter) ||
        chat.company_name.toLowerCase().includes(lowercasedFilter) ||
        chat.object_id.toLowerCase().includes(lowercasedFilter) ||
        chat.user_profile_id.toLowerCase().includes(lowercasedFilter)
      );
    });
  }, [recentChats, filterText]);

  return (
    <div className="flex h-screen bg-gray-100 p-4 gap-4">
      <div className="w-1/3 flex flex-col bg-white rounded-lg shadow">
        <div className="p-4 border-b">
          <h2 className="text-xl font-bold mb-4">AI Assistant History</h2>
          <Formik
            initialValues={formValues}
            validationSchema={formSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize
          >
            {({ isSubmitting }) => (
              <Form>
                <TextFormikGroup
                  name="object_id"
                  label="OBJECT ID"
                  placeholder="Enter Object ID"
                  data-testid="object-id-input"
                />
                <TextFormikGroup
                  name="user_profile_id"
                  label="USER PROFILE ID"
                  placeholder="Enter User Profile ID"
                  data-testid="user-profile-id-input"
                />
                <Button type="submit" disabled={isSubmitting} variant="lightAction" size="large">
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>

        <div className="flex flex-col flex-grow overflow-hidden">
          <div className="p-4 bg-white z-10">
            <h3 className="font-semibold mb-2">Recent Chats</h3>
            <TextInput
              name="filter"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              placeholder="Filter chats..."
            />
          </div>
          <div className="flex-grow overflow-auto">
            <div className="space-y-2 px-4">
              {loadingRecentChats ? (
                <p>Loading recent chats...</p>
              ) : recentChatsError ? (
                <Alert variant="error">{recentChatsError}</Alert>
              ) : (
                filteredChats.map((chat, index) => (
                  <div key={index} className="bg-gray-50 p-3 rounded-lg">
                    <div className="text-sm text-gray-500">{formatDate(parseIso(chat.timestamp))}</div>
                    <div className="font-medium">
                      Object ID:{' '}
                      <span
                        className="text-blue-600 cursor-pointer"
                        onClick={() => handleRecentChatClick(chat)}
                      >
                        {chat.object_id}
                      </span>
                    </div>
                    <div>
                      User Profile ID:{' '}
                      <span
                        className="text-blue-600 cursor-pointer"
                        onClick={() => handleRecentChatClick(chat)}
                      >
                        {chat.user_profile_id}
                      </span>
                    </div>
                    <div>
                      <span className="font-semibold">User: </span>
                      {chat.user_name}
                    </div>
                    <div>
                      <span className="font-semibold">Company: </span>
                      {chat.company_name}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-2/3 bg-white rounded-lg shadow p-4 overflow-auto">
        <h2 className="text-xl font-bold mb-4">Chat History</h2>
        {error && (
          <Alert variant="error" className="mb-4">
            {error}
          </Alert>
        )}
        <div className="space-y-4">
          {chatHistory.length > 0 ? (
            chatHistory.map((message, index) => <AIAssistantRow key={index} message={message} />)
          ) : (
            <p>No chat history available. Select a recent chat or submit a search to view history.</p>
          )}
        </div>
      </div>
    </div>
  );
}
