import { UsageState } from './FeatureModel';
import { LayoutCondition, RulesEnum } from './LayoutModel';

type LayoutRulesKeys = RulesEnum;
type LayoutRulesMap = {
  [key in LayoutRulesKeys]: (condition: LayoutCondition, state: UsageState) => Boolean;
};

// Given a rule, condition, and state, determine if the rule condition is met
// each rule must return a Boolean value so that the condition can be evaluated in isActive
// TODO: make this more readable somehow, maybe make each it's own function
const LayoutRules: LayoutRulesMap = {
  hasBillingData: (condition, state) =>
    Boolean(state.companyPlanByMonth.length > 0) === condition.conditionArgs,
  hasCurrentInvoice: (condition, state) => Boolean(state.currentInvoice) === condition.conditionArgs,
  isUsage: (condition, state) => Boolean(state.currentInvoice?.['IS_USAGE'] === condition.conditionArgs),
  recentAllotmentGrainIncludes: (condition, state) =>
    Boolean(condition.conditionArgs.includes(state.currentInvoice?.['RECENT_ALLOTMENT_GRAIN'])),
  planTypeMostRecentIncludes: (condition, state) =>
    Boolean(condition.conditionArgs.includes(state.currentInvoice?.['PLAN_TYPE_MOST_RECENT'])),
};

export default LayoutRules;
