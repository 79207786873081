/**
 * This file is the React App's Global Authentication State.
 * This file reads from AuthStorage which is the definitive source authentication state.
 * Note that API.ts can log the user out without consulting this file. In these
 * situtations it will update AuthStorage and set `window.location.href` to force a full page refresh.
 *
 * Necessary values to track a user's logged in state are put in AuthContext.Provider.
 * Context consumers read those values through a custom hooks, useAuth and useUserProfile.
 *
 * A user is considered logged in if there is user_profile data (which means their token is also valid).
 *
 * isLoggedIn flag is the source of truth to determine Auth or UnAuth app to render.
 *
 * App consistently checks if there is a user object in order to determine isLoggedIn flag.
 *
 * On app load check if there is a stored auth token:
 *     Yes => The user is logged in.
 *            Also get the stored userProfile.
 *            Render the app with the stored userProfile.
 *            In the background, fetch a new copy of the userProfile just incase
 *            the version cached in localStorage is out of date.
 *            Rerender the app with the fresh userProfile.
 *
 *     No =>  User is not signed in.
 *            Only render signed out pages.
 **/
import React, { useCallback, useState, useEffect, useMemo } from 'react';

import API from 'api/API';
import * as authClient from 'api/APIAuth';
import { ILogin, ILogin2FA, ISignup, IResetPassword } from 'api/APIAuth';
import { UserProfile, Company, DatabaseAccountWithDisplayName } from 'api/APITypes';
import {
  getLocalStorageToken,
  getLocalStorageUserProfile,
  setLocalStorageUserProfile,
} from 'api/AuthStorage';

import * as Sentry from '@sentry/browser';

import history from '../appHistory';

// Servicebell was deprecated October 3rd 2024, but we left the code, just commented out.
// ServiceBell loads after the rest of the app loads.
// This promise will resolve when the logged in user is known.
// ServiceBell subscribes to this promise.
declare global {
  interface Window {
    userProfileLoaded: Promise<UserProfile>;
  }
}
let resolveUserProfile: (userProfile: UserProfile) => void;
window.userProfileLoaded = new Promise<UserProfile>((resolve) => {
  resolveUserProfile = resolve;
});

export type GetUserProfileURL = (userProfile: UserProfile) => string;

export type Context = {
  login: (form: ILogin, forwardToAfterLogin?: GetUserProfileURL) => Promise<any>;
  login2FA: (form: ILogin2FA, forwardToAfterLogin?: GetUserProfileURL) => Promise<any>;
  signup: (form: ISignup) => Promise<any>;
  resetPassword: (formValues: IResetPassword, forwardToAfterLogin?: GetUserProfileURL) => Promise<any>;
  logout: () => void;
  userProfile: UserProfile | undefined;
  setUserProfile: (userProfile: UserProfile) => void;
  updateCompany: (newCompany: Company) => void;
  isLoggedIn: boolean;
};

const AuthContext = React.createContext<Context | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

// Make sure component initializes with user set.
// Otherwise, isLoggedIn=false will be returned to the router on the first
// render pass and the 404 route will render before the logged in route
// causing page blink issues.
const initialUserValue = getLocalStorageToken() ? getLocalStorageUserProfile() : undefined;

const AuthProvider = (props: React.PropsWithChildren<{}>) => {
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(initialUserValue);

  // When we update the userProfile we need to update it in this component
  // AND in localStorage.
  // Places in this file that only call setUserProfile() inside of an authClient.then()
  // rely on the fact that authClient calls setLocalStorageUserProfile() for them.
  const setUserProfileEverywhere = useCallback((newUserProfile: UserProfile) => {
    setUserProfile(newUserProfile);
    setLocalStorageUserProfile(newUserProfile);
  }, []);

  const setUserInTrackingSystems = (userProfile: UserProfile) => {
    // Sentry Bug Reporting
    Sentry.setUser({
      email: userProfile.user.email,
    });
    Sentry.setTag('company', userProfile.company.name!);

    // Segment Analytics
    analytics.identify(userProfile.user.email);

    // Resolve userProfileLoaded promise for ServiceBell
    resolveUserProfile(userProfile);
  };

  useEffect(() => {
    // Only get user profile if there is a token
    if (getLocalStorageToken() && !userProfile) {
      const userProfile = getLocalStorageUserProfile();
      setUserProfile(userProfile);
    }

    // Tell all tracking systems the logged in user.
    // Note we don't have a notion of logging a user out from tracking.
    // I believe this is OK and perhaps desireable.
    if (userProfile) {
      setUserInTrackingSystems(userProfile);
    }
  }, [userProfile]);

  // If the user is logged in, the app will initially render with
  // the user profile cached in localStorage.
  // This is good for page load performance.
  // In the background, refetch the user profile when the page first loads
  // just incase the user profile changed since the user last logged in.
  // The app will then rerender with the new user profile.
  // Note we will not call setUserInTrackingSystems() again since the user's
  // email should not have changed.
  useEffect(() => {
    if (getLocalStorageToken() && userProfile) {
      const api = new API();
      api
        .get('api/user_profile')
        .then((response) => {
          setUserProfileEverywhere(response.data.user_profile);
        })
        .catch((e) => {
          // Silently eat error.
          // The app will run with an out of date user profile.
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // catch the error where login is called
  const login = useCallback(
    (form: ILogin, forwardToAfterLogin?: GetUserProfileURL) =>
      authClient.login(form).then((resp) => {
        // If we get '2FA' we should open the 2FA modal with loginData, otherwise, we are logged in!
        if (resp.require_2FA) {
          return resp;
        } else {
          analytics.track('Login Login');
          doLogin(resp, forwardToAfterLogin);
        }
      }),
    [],
  );

  const resetPassword = (form: IResetPassword, forwardToAfterLogin?: GetUserProfileURL) =>
    authClient.resetPassword(form).then((resp) => {
      // If we get '2FA' we should open the 2FA modal with loginData, otherwise, we are logged in!
      if (resp.require_2FA) {
        return resp;
      } else {
        analytics.track('ResetPassword ResetPassword');
        return doLogin(resp, forwardToAfterLogin);
      }
    });

  // catch the error where login2FA is called
  const login2FA = (form: ILogin2FA, forwardToAfterLogin?: GetUserProfileURL) =>
    authClient.login2FA(form).then((userProfile) => {
      analytics.track('Login Login2FA');
      doLogin(userProfile, forwardToAfterLogin);
    });

  const doLogin = (userProfile: UserProfile, forwardToAfterLogin?: GetUserProfileURL) => {
    setUserProfile(userProfile); // Set after analytics tracking

    let forwardURL = forwardToAfterLogin?.(userProfile);

    // If Snowflake is not set up yet, we need to redirect to the setup page.
    if (!userProfile.company.database_account) {
      forwardURL = '/select-warehouse';
    }

    history.push(forwardURL || '/');
  };

  // catch the error where signup is called
  const signup = (form: ISignup) =>
    authClient.signup(form).then((response) => {
      if (response.user_profile) {
        analytics.track('Signup SignupSuccess');
        // Don't log in the user yet because they need to verify their email first.
        // Give up execution context so analytics.track() logs before navigation.
        setTimeout(() => history.push('/email-verification'), 0);
      }
      return response;
    });

  // Ignore backend response.
  // Always force a logout.
  // This method only covers clicking the logout button.
  // This method is bypassed by:
  //   1. An API 401 response logout
  //   2. A window.onstorage event logout
  const logout = () =>
    authClient.logout().finally(() => {
      analytics.track('Header Logout');

      // At some point we may want to do the logical inverse of setUserInTrackingSystems() here.
      // Theoretically, a second user could sign into the same browser at which point setUserInTrackingSystems() would reset the user.
      // Osano said we don't have to log users out of tracking systems when we log them out of our app.
      // So not bothering.

      // Page refresh to reset app memory state.
      window.location.href = '/login';
    });

  const updateCompany = useCallback(
    (newCompany: Company) => {
      if (userProfile) {
        const newUserProfile = { ...userProfile, company: newCompany };
        setUserProfileEverywhere(newUserProfile);
      }
    },
    [userProfile, setUserProfileEverywhere],
  );

  const isLoggedIn = Boolean(userProfile);

  const value = {
    isLoggedIn,
    resetPassword,
    login,
    login2FA,
    logout,
    signup,
    userProfile,
    setUserProfile: setUserProfileEverywhere,
    updateCompany,
  };

  return <AuthContext.Provider value={value} {...props} />;
};

/**
 * Custom hooks to consume AuthContext.
 * Must be used within an AuthProvider.
 * useAuth is for login/logout situations.
 * useUserProfile is for getting and setting the user object only when the user is logged in.
 * */

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useUserProfile() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useUserProfile must be used within a AuthProvider`);
  }
  if (context.userProfile === undefined) {
    throw new Error(`User must be logged in to use this hook`);
  }
  return {
    userProfile: context.userProfile as UserProfile,
    setUserProfile: context.setUserProfile,
    updateCompany: context.updateCompany,
  };
}

function useDatabaseAccount(): DatabaseAccountWithDisplayName {
  const userProfile = useUserProfile().userProfile;
  const databaseAccount = userProfile.company.database_account;
  if (!databaseAccount) {
    throw new Error(`Database account must be set up to use this hook`);
  }

  const memoizedDatabaseAccount = useMemo(() => {
    const displayName: 'Snowflake' | 'BigQuery' =
      databaseAccount.type === 'snowflake' ? 'Snowflake' : 'BigQuery';

    return {
      ...databaseAccount,
      displayName,
    };
  }, [databaseAccount]);

  return memoizedDatabaseAccount;
}

export { AuthContext, AuthProvider, useAuth, useUserProfile, useDatabaseAccount };
