/*
  Container to wrap RadioTabColumn, a divider, and the panels the tabs turn on and off.
  Per TabRow convention, this component's parent is responsible for 
  rendering or not rendering the tabbed panels when the panels are turned on and off.
*/
import { CSSProperties } from 'react';

import cn from 'classnames';

import RadioTabColumn, { RadioTabColumnProps } from '../RadioTabColumn/RadioTabColumn';

export type { TabKeyType } from '../RadioTabColumn/RadioTabColumn';

export interface RadioTabPanelBoxProps extends RadioTabColumnProps {
  children: React.ReactNode;
  rounded?: boolean;
  containerClass?: string;
  dividerWidth?: string;
  dividerEnabledColor?: string;
  dividerDisabledColor?: string;
}

const RadioTabPanelBox = (props: RadioTabPanelBoxProps) => {
  const {
    tabs,
    selectedTab,
    onClick,
    enabled,
    horizontal,
    children,
    rounded,
    containerClass,
    dividerWidth = '2px',
    dividerEnabledColor = 'var(--sec-blue-gray-200)',
    dividerDisabledColor = 'var(--spri-gray-200)',
  } = props;
  const actualContainerClass = cn(
    'w-full h-full min-h-0',
    {
      'f-col': horizontal,
      flex: !horizontal,
      'rounded overflow-hidden': rounded,
    },
    containerClass,
  );
  const dividerClass = cn({
    'w-full': horizontal,
    'h-full': !horizontal,
  });
  const dividerStyle: CSSProperties = {};
  if (horizontal) {
    dividerStyle.height = dividerWidth;
  } else {
    dividerStyle.width = dividerWidth;
  }
  if (enabled) {
    dividerStyle.backgroundColor = dividerEnabledColor;
  } else {
    dividerStyle.backgroundColor = dividerDisabledColor;
  }
  return (
    <div className={actualContainerClass}>
      <RadioTabColumn
        tabs={tabs}
        selectedTab={selectedTab}
        onClick={onClick}
        enabled={enabled}
        horizontal={horizontal}
      />
      <div className={dividerClass} style={dividerStyle}></div>
      {children}
    </div>
  );
};

export default RadioTabPanelBox;
