import React from 'react';

import cn from 'classnames';

interface ViewDescriptionProps {
  description: string;
  canEdit: boolean;
  onClick: () => void;
}

const ViewDescription = (props: ViewDescriptionProps) => {
  const { description, canEdit, onClick } = props;

  const baseStyles = 'max-h-[182px] w-full flex whitespace-pre-wrap overflow-y-auto';
  const canEditStyles =
    'p-2 -ml-2 hover:bg-pri-gray-100 active:bg-pri-gray-200 cursor-pointer rounded-md';

  const emptyState = <div className="items-center text-pri-gray-400">None</div>;

  return (
    <div
      role={canEdit ? 'button' : undefined}
      className={cn(baseStyles, canEdit ? canEditStyles : '')}
      onClick={canEdit ? onClick : undefined}
    >
      {description || emptyState}
    </div>
  );
};

export default ViewDescription;
