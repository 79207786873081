import { AggTable } from 'api/tableAPI';
import { humanSyncFrequency } from 'model_helpers/aggTableHelper';

import { DependencyEdge, Edge } from '../PipelineTab/PipelineEditor/PipelineEditor';

import RunAfterDependencies, { AnyVertex } from './RunAfterDependencies';

export interface TransformScheduleWidgetProps {
  table: AggTable;
  vertexDependencies: DependencyEdge[] | Edge[];
  vertices: AnyVertex[];
  tablesByID: { [key: string]: AggTable };
}

export default function TransformScheduleWidget(props: TransformScheduleWidgetProps) {
  const { table, vertexDependencies, vertices, tablesByID } = props;
  const { scheduled, schedule_mode } = table.transform ?? {};

  let humanSchedule = humanSyncFrequency(table);
  if (scheduled) {
    if (schedule_mode === 'all_marked' || schedule_mode === 'any_marked') {
      return (
        <RunAfterDependencies
          title={`${humanSchedule}:`}
          vertexDependencies={vertexDependencies}
          vertices={vertices}
          tablesByID={tablesByID}
        />
      );
    }
  }

  return <span>{humanSchedule}</span>;
}
