import { Check } from 'react-bootstrap-icons';

import cn from 'classnames';

interface CheckableNumberProps {
  number: number;
  checked: boolean;
  // We don't currently support more than one variant.
  // Just future proofing this interface.
  variant: 'dullAction';
  size?: number;
}

export default function CheckableNumber(props: CheckableNumberProps) {
  const { number, checked, size = 20 } = props;

  const checkedBackgroundColor = 'bg-pri-success-600';
  const checkedFontColor = 'text-pri-gray-100';
  const uncheckedBackgroundColor = 'bg-sec-blue-gray-500';
  const uncheckedFontColor = 'text-pri-gray-100';

  const bgColor = checked ? checkedBackgroundColor : uncheckedBackgroundColor;
  const fontColor = checked ? checkedFontColor : uncheckedFontColor;
  const content = checked ? <Check size={16} color="white" /> : number;
  return (
    <div
      className={cn('f-center', bgColor, fontColor)}
      style={{ width: `${size}px`, height: `${size}px`, borderRadius: `${size}px` }}
    >
      <div className="text-sm">{content}</div>
    </div>
  );
}
