import Button from 'components/inputs/basic/Button/Button';
import IconButton from 'components/inputs/basic/Button/IconButton';
import Listbox from 'components/inputs/basic/Listbox/Listbox';
import Switch from 'components/inputs/basic/Switch/Switch';
import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useDatabaseAccount } from 'context/AuthContext';
import { DAILY_SYNC_UTC_TIMES, SYNC_FREQUENCIES } from 'model_helpers/connectorHelper';
import ConfirmHistoricalResyncConnectorModal from 'pages/connectors/ListConnectors/ConfirmHistoricalResyncConnectorModal';

import * as Sentry from '@sentry/browser';

import { SummaryTabState } from '../useSummaryTabState';

import ConfirmDeleteConnectorModal from './ConfirmDeleteConnectorModal';
import ErrorBoundary from './ErrorBoundary';
import HorizontalBar from './HorizontalBar';
import { GridLabel, SettingsGrid, SettingsGridRow } from './SettingsGrid';
import SyncHistoryChart from './SyncHistoryChart';

interface SummaryTabProps extends SummaryTabState {}

export default function SummaryTab(props: SummaryTabProps) {
  const {
    historicalResync,
    syncFrequency,
    connectorSyncs,
    togglePaused,
    syncNow,
    deleteConnector,
    connectorSetup,
    setupState,
    findInWarehouse,
    goToUsage,
    isViewer,
  } = props;
  const databaseType = useDatabaseAccount().type;
  const compiledError = [historicalResync.error, syncFrequency.error, togglePaused.error, syncNow.error]
    .filter(Boolean)
    .join('. ');

  const isDailySync = syncFrequency.syncFrequency === 1440;

  const getSyncHistory = () => {
    if (connectorSyncs.loading) {
      return <CenteredSpinner containerMinHeight="100%" />;
    } else if (connectorSyncs.error) {
      return <Alert variant="error">{connectorSyncs.error}</Alert>;
    } else if (connectorSyncs.connectorSyncs.length === 0) {
      return <Alert variant="info">No syncs have completed yet.</Alert>;
    } else {
      return (
        <div className="h-[220px]">
          <SyncHistoryChart connectorSyncs={connectorSyncs.connectorSyncs} />
        </div>
      );
    }
  };

  return (
    <TabLayout loading={false} error={compiledError} variant="wide_padding">
      {connectorSyncs.systemAlert && (
        <div className="m-4">
          <Alert variant="error">Connector sync failure: {connectorSyncs.systemAlert}</Alert>
        </div>
      )}
      <ErrorBoundary
        fallback={<></>}
        onError={(error, infoStack) => {
          Sentry.captureException(error);
        }}
      >
        <div className="font-medium text-sec-blue-gray-700 mb-6">Sync History</div>
        <div className="max-w-[990px]">{getSyncHistory()}</div>
        <HorizontalBar />
      </ErrorBoundary>
      <div className="flex items-start justify-between max-w-[990px]">
        <SettingsGrid heading="Sync Status">
          <SettingsGridRow label="Enabled">
            <Switch
              name="connector_enabled"
              checked={!togglePaused.paused}
              disabled={isViewer}
              onChange={togglePaused.togglePaused}
              spinning={togglePaused.submitting}
            />
          </SettingsGridRow>
          <SettingsGridRow
            label={
              isDailySync ? (
                <div className="f-row-y-center">
                  <GridLabel label="Sync Frequency" />
                  <InfoIcon
                    content="Once-daily syncs will run within the hour you select. The exact time cannot be set due to load balancing. The timezone is UTC."
                    containerClass="ml-2"
                  />
                </div>
              ) : (
                'Sync Frequency'
              )
            }
          >
            <div className="f-row-y-center space-x-2">
              <Listbox
                value={syncFrequency.syncFrequency}
                options={SYNC_FREQUENCIES}
                onChange={(newVal) => {
                  syncFrequency.handleSetSyncFreq(newVal as number, syncFrequency.dailySyncTime);
                }}
                disabled={isViewer}
                spinning={syncFrequency.loading}
                widthClass="w-[200px]"
                variant="gray"
                size="small"
              />
              {isDailySync && (
                <Listbox
                  value={syncFrequency.dailySyncTime || '00:00'}
                  options={DAILY_SYNC_UTC_TIMES}
                  onChange={(newVal) => {
                    syncFrequency.handleSetSyncFreq(syncFrequency.syncFrequency, newVal as string);
                  }}
                  disabled={isViewer}
                  spinning={syncFrequency.loading}
                  widthClass="w-[140px]"
                  variant="gray"
                  size="small"
                />
              )}
            </div>
          </SettingsGridRow>
          <SettingsGridRow label="Sync">
            <Button
              onClick={syncNow.syncNow}
              disabled={syncNow.disabled || isViewer}
              spinning={syncNow.spinning}
              variant="darkDullAction"
              size="small"
              className="w-[101px]"
            >
              Sync Now
            </Button>
          </SettingsGridRow>
        </SettingsGrid>
        <SettingsGrid heading="Connector Details">
          <SettingsGridRow label="Historical Re-sync">
            <Button
              variant="darkDullAction"
              size="small"
              spinning={historicalResync.spinning}
              disabled={historicalResync.disabled || isViewer}
              onClick={() => historicalResync.openHistoricalResyncModal()}
              className="w-[164px]"
            >
              Re-sync Connector
            </Button>
            {historicalResync.showHistoricalResyncModal && (
              <ConfirmHistoricalResyncConnectorModal
                resyncing={historicalResync.spinning}
                onCancel={historicalResync.closeHistoricalResyncModal}
                onConfirm={historicalResync.performHistoricalResync}
              />
            )}
          </SettingsGridRow>
          <SettingsGridRow label="Fivetran Setup">
            <Button
              variant="darkDullAction"
              size="small"
              spinning={connectorSetup.loading}
              disabled={isViewer || connectorSetup.loading}
              onClick={() => connectorSetup.handleEditConnector('SummaryTab')}
            >
              Edit Setup
            </Button>
          </SettingsGridRow>
          <SettingsGridRow label="Delete Connector">
            <Button
              // variant="darkDanger"
              variant="lightDanger"
              size="small"
              spinning={deleteConnector.loading}
              disabled={isViewer}
              onClick={() => deleteConnector.onOpen()}
            >
              Delete
            </Button>
            {deleteConnector.showModal && <ConfirmDeleteConnectorModal {...deleteConnector} />}
          </SettingsGridRow>
        </SettingsGrid>
      </div>
      <HorizontalBar />
      <div className="flex text-sec-blue-gray-500 font-medium">
        <IconButton
          icon="Search"
          text="Find Synced Data in Warehouse"
          size="form"
          variant="lightDullTransparent"
          disabled={setupState === 'incomplete'}
          onClick={findInWarehouse}
        />
        {databaseType !== 'bigquery' && (
          <IconButton
            icon="BoxArrowUpRight"
            text="Go to Usage"
            size="form"
            variant="lightDullTransparent"
            onClick={goToUsage}
            disabled={setupState === 'incomplete' || isViewer}
          />
        )}
      </div>
    </TabLayout>
  );
}
