/*
DraggablePanes manages its own dragged width state.
This is the version of DraggablePanes you want to use most of the time.
If you need to control the width or height of dragged panes from
the state of the parent component use ControlledDraggablePanes instead.
*/
import { StringSize } from 'utils/cssMath';

import ControlledDraggablePanes, {
  BasicDraggablePanesProps,
} from '../ControlledDraggablePanes/ControlledDraggablePanes';
import useDraggablePanesState from '../useDraggablePanesState';

interface DraggablePanesProps extends BasicDraggablePanesProps {
  // Panes default to side by side. Set this to get top and bottom.
  vertical?: boolean;
  // Properties that apply when vertical = false
  leftStartingWidth?: StringSize;
  leftMinWidth?: StringSize;
  rightMinWidth?: StringSize;
  // Properties that apply when vertical = true
  topStartingHeight?: StringSize;
  topMinHeight?: StringSize;
  bottomMinHeight?: StringSize;
  containerRef?: React.MutableRefObject<null>;
}

export default function DraggablePanes(props: DraggablePanesProps) {
  const {
    vertical = false,
    leftStartingWidth = '50%',
    topStartingHeight = '50%',
    containerRef,
    ...draggablePaneProps
  } = props;

  const firstChildStartingSize = vertical ? topStartingHeight : leftStartingWidth;
  const hookState = useDraggablePanesState(vertical, firstChildStartingSize, containerRef);

  return <ControlledDraggablePanes {...draggablePaneProps} {...hookState} />;
}
