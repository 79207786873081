import React from 'react';

import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import { TransformRunFraction } from 'model_helpers/transformHelper';

interface TransformRunIconProps {
  run: TransformRunFraction | null;
  rotate?: boolean;
}

export default function TransformRunIcon(props: TransformRunIconProps) {
  let { run, rotate } = props;

  if (!run) {
    return <StatusIcon status="NA" />;
  }

  const { state, error_message } = run;
  const errorTip = error_message ? error_message : 'No error message provided.';
  if (state === 'success') {
    return <StatusIcon status="success" />;
  } else if (state === 'failed') {
    return (
      <TooltipTrigger tip={errorTip}>
        {/* <div> takes ref so we don't have to forwardRef to component. */}
        {/* w-fit fixes bug where div expands to full width in table cells. */}
        <div className="w-fit">
          <StatusIcon status="error" />
        </div>
      </TooltipTrigger>
    );
  } else {
    return <StatusIcon status="syncing" rotate={rotate} />;
  }
}
