import { useMemo } from 'react';

import { Connector } from 'api/APITypes';
import { Dashboard, ReportAvailable } from 'api/dashboardAPI';
import Button from 'components/inputs/basic/Button/Button';

import ConnectorLayout from '../ConnectorLayout';
import { getTransforms } from '../reportUtils';

import ReportTransforms from './ReportTransforms';
import WhatIsATransform from './WhatIsATransform';

export interface ReviewTransformsViewProps {
  connector: Pick<Connector, 'id' | 'service' | 'schema'>;
  dashboard: Dashboard;
  reportsAvailable: ReportAvailable[];
  onGoToGSheetConfig(): void;
}

export default function ReviewTransformsView(props: ReviewTransformsViewProps) {
  const { connector, reportsAvailable, onGoToGSheetConfig } = props;

  const transforms = useMemo(() => getTransforms(reportsAvailable), [reportsAvailable]);

  return (
    <ConnectorLayout connector={connector}>
      <h2 className="mt-10 text-xl font-medium">
        In order to create your report, we will create the following transforms.
      </h2>
      <div className="mt-6 flex flex-col">
        <div className="h-[200px] mt-4">
          <ReportTransforms transforms={transforms} connector={connector} />
        </div>
        <div className="mt-4">
          <WhatIsATransform />
        </div>
      </div>
      <div className="mt-6 f-row-y-center">
        <Button onClick={onGoToGSheetConfig}>Continue</Button>
      </div>
    </ConnectorLayout>
  );
}
