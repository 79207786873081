/**
 * This page stores state and logic that is common to all Report Builder editing views.
 * It is a little bit like a router.
 */
import { RouteComponentProps } from 'react-router';
import { useParams } from 'react-router-dom-v5-compat';

import { useGetDashboardsAvailable, useGetDashboardsInstalled } from 'api/dashboardAPI';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';

import { useGetConnectors } from '../ConnectorUtils';

import AuthorizeGSheet from './AuthorizeGSheet/AuthorizeGSheetController';
import CreateLaterController from './CreateLater/CreateLaterController';
import DeprecatedPickReportsController from './DeprecatedPickReports/DeprecatedPickReportsController';
import PreviewReportController from './PreviewReport/PreviewReportController';
import ReviewTransformsController from './ReviewTransforms/ReviewTransformsController';
import SetupComplete from './SetupComplete/SetupCompleteController';
import ShowReportController from './ShowReport/ShowReportController';

export const LAYOUT_WIDTH = '814px';

interface MatchParams {
  id?: string;
  view?: string;
}

interface PickReportsProps extends RouteComponentProps<MatchParams> {}

export default function PickReports(props: PickReportsProps) {
  const { id, view } = useParams();

  // The user is probably coming straight from the ListConnectors page.
  // So, react-query has probably already fetched all these APIs and
  // will be pulling these values out of it's cache.
  // This is why it's OK to pull the entire list of connectors instead of
  // just the connector we are interested in.
  // TODO:
  // It would probably be better to store the current controller in some sort of context or app model layer.
  // OR tune react-query not to fetch APIs again unnecessarily.

  // Fetch connectors.
  const { isLoading: connectorsLoading, connectors, error: connectorsError } = useGetConnectors();

  // Fetch dashboards available.
  const { dashboardsAvailableLoading, dashboardsAvailableError, dashboardsAvailable } =
    useGetDashboardsAvailable();

  // Fetch dashboards installed.
  const {
    dashboardsInstalledLoading,
    dashboardsInstalledError,
    // dashboardsInstalled,
    dashboardsInstalledByConnectorID,
  } = useGetDashboardsInstalled();

  // console.log('dashboardsAvailable', dashboardsAvailable);
  // console.log('dashboardsInstalled', dashboardsInstalled);

  const pageLoading = connectorsLoading || dashboardsAvailableLoading || dashboardsInstalledLoading;
  let pageLoadingError = connectorsError || dashboardsAvailableError || dashboardsInstalledError;

  const connector = connectors.find((c) => c.id === id);

  if (!pageLoading && !pageLoadingError && !connector) {
    pageLoadingError = 'Unknown connector. Double check your URL and try refreshing the page.';
  }

  if (pageLoading || pageLoadingError || !connector) {
    return (
      <CenteredLayout maxWidth={LAYOUT_WIDTH} loading={pageLoading} loadingError={pageLoadingError} />
    );
  }

  // TODO: Maybe replace this series of if statments with a React Router Switch
  if (view === 'show') {
    return (
      <ShowReportController
        connector={connector}
        dashboard={dashboardsInstalledByConnectorID[connector.id]}
        reportsAvailable={dashboardsAvailable[connector.service]}
      />
    );
  }

  // This view is no longer used in the customer facing view.
  // It was replaced by `preview`.
  // We are temporarily retaining this for internal experimentation.
  // Delete this if it is still here in 2024.
  if (view === 'pick') {
    return (
      <DeprecatedPickReportsController
        connector={connector}
        dashboard={dashboardsInstalledByConnectorID[connector.id]}
        reportsAvailable={dashboardsAvailable[connector.service]}
      />
    );
  }

  if (view === 'preview') {
    return (
      <PreviewReportController
        connector={connector}
        reportAvailable={dashboardsAvailable[connector.service][0]}
      />
    );
  }

  if (view === 'later') {
    return <CreateLaterController connector={connector} />;
  }

  if (view === 'review_transforms') {
    return (
      <ReviewTransformsController
        connector={connector}
        dashboard={dashboardsInstalledByConnectorID[connector.id]}
        reportsAvailable={dashboardsAvailable[connector.service]}
      />
    );
  }

  if (view === 'authorize_gsheet') {
    return (
      <AuthorizeGSheet
        connector={connector}
        dashboard={dashboardsInstalledByConnectorID[connector.id]}
      />
    );
  }

  if (view === 'setup_complete') {
    return (
      <SetupComplete connector={connector} dashboard={dashboardsInstalledByConnectorID[connector.id]} />
    );
  }

  return <CenteredLayout maxWidth={LAYOUT_WIDTH} loadingError={`Unknown view [${view}].`} />;
}
