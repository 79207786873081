import React from 'react';

import cn from 'classnames';

import { AggTable, Favorite } from 'api/APITypes';
import favoriteAPI from 'api/favoriteAPI';
import ApiIconButton, {
  useApiIconState,
  ExtendersProps,
  ApiIconButtonProps,
} from 'components/inputs/basic/Button/ApiIconButton';

import s from './ToggleFavoriteIcon.module.css';

interface PassThroughProps
  extends Omit<ApiIconButtonProps, 'icon' | 'errorState' | 'setErrorState' | 'onClick'> {}

interface ToggleFavoriteIconProps extends ExtendersProps, PassThroughProps {
  showTip: boolean; // Performance optimization. Skip tips on long lists of tables.
  table: AggTable;
  favorite?: Favorite;
  addFavorite: (favorite: Favorite) => void;
  removeFavorite: (favorite: Favorite) => void;
  logRecent?: (tableID: string) => void;
}

const ToggleFavoriteIcon = (props: ToggleFavoriteIconProps) => {
  const { spinning, setSpinning, errorState, setErrorState } = useApiIconState();
  const {
    showTip,
    table,
    favorite,
    addFavorite,
    removeFavorite,
    logRecent,
    eventPage,
    className,
    ...apiIconProps
  } = props;

  const { id } = table;
  const isFavorite = !!favorite;

  const handleToggleFavorite = async () => {
    if (spinning) {
      return;
    }

    setSpinning(true);
    const newIsFavorite = !isFavorite;
    if (newIsFavorite) {
      favoriteAPI
        .add(id)
        .then((response) => {
          addFavorite(response.data);
          analytics.track(`${eventPage} AddFavorite`);
          if (logRecent) {
            logRecent(id);
          }
        })
        .catch(() => {
          setErrorState({
            show: true,
            header: 'Server Error',
            body: 'Error setting favorite.',
          });
        })
        .finally(() => {
          setSpinning(false);
        });
    } else {
      favoriteAPI
        .delete(favorite.id)
        .then((response) => {
          setSpinning(false);
          removeFavorite(favorite);
          analytics.track(`${eventPage} RemoveFavorite`);
          if (logRecent) {
            logRecent(id);
          }
        })
        .catch(() => {
          setSpinning(false);
          setErrorState({
            show: true,
            header: 'Server Error',
            body: 'Error setting favorite.',
          });
        });
    }
  };

  let tip = undefined;
  if (showTip) {
    tip = isFavorite ? 'Remove table from my favorites' : 'Add table to my favorites';
  }

  return (
    <ApiIconButton
      icon={isFavorite ? 'StarFill' : 'Star'}
      iconColor="var(--pri-gray-500)"
      iconSize={16}
      size="small"
      variant="lightDullTransparent"
      spinning={spinning}
      errorState={errorState}
      setErrorState={setErrorState}
      onClick={handleToggleFavorite}
      tip={tip}
      className={cn(className, s.favoriteIcon)}
      {...apiIconProps}
    />
  );
};

export default ToggleFavoriteIcon;
