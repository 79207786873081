// This tab uses a series of useMemos or variable definitions to build a data pipeline.
// The end result is the datasets used for the chart and the table, which are not the same
import React, { useState, useMemo, useCallback } from 'react';

import { formatApiDate } from 'utils/dateTime';

import TabView from '../TabView';
import { ReportTypes } from '../Usage';
import { AppQueryingDailyRow, AppQueryingMonthlyRow, UsagePostData } from '../useUsageFetch';
import {
  getChartFormattedData,
  getDataAggregated,
  getSortedDatasetLabels,
  getDataGrouped,
  getDatasets,
  getLabels,
} from '../Utils';

interface CloudServiceTabProps {
  appQueryingData: AppQueryingDailyRow[];
  reportType: ReportTypes;
  today: Date;
  startDate: Date;
  setReportType: (newReportType: ReportTypes) => void;
  safeFetchAndFormatData: (url: string, postData: UsagePostData) => Promise<any>;
  setError: (newError: string) => void;
}

interface QueryTextRow {
  SAMPLE_QUERY_TEXT: string;
  QUERY_TYPE: string;
  QUERY_TAG: string;
  NUM_RUNS: number;
  COMPUTE_CREDITS: number;
  CLOUD_SERVICE_CREDITS: number;
  TIME: number;
}

// This component uses a series of useMemos to filter and group the data
export default function CloudServiceTab(props: CloudServiceTabProps) {
  const {
    appQueryingData,
    reportType,
    today,
    startDate,
    setReportType,
    safeFetchAndFormatData,
    setError,
  } = props;
  const [queryTextData, setQueryTextData] = useState<QueryTextRow[] | undefined>();
  const [selectedAppQuerying, setSelectedAppQuerying] = useState<string | null>(null);
  const [loadingQueryTextData, setLoadingQueryTextData] = useState(false);

  const useQueryTextData = selectedAppQuerying !== null && queryTextData !== undefined;

  // Most of the data we need is already passed in by useUsageFetch, but when a user clicks on
  //   a specific app_querying in the table (right now only Fivetran works in the BE), we
  //   request the top 15 queries by cloud_service_credits for the date range they are currently viewing.
  const fetchQueryTextData = useCallback(
    (aq: string) => {
      // Fetches the top 15 queries for a given appQuerying for the given date range
      setLoadingQueryTextData(true);
      safeFetchAndFormatData('/api/usages/query_text_compute_usage', {
        app_querying: aq,
        query_text_order_by: 'cloud_service_credits',
        start_date: formatApiDate(startDate),
        end_date: formatApiDate(today),
      })
        .then((formattedData) => {
          setQueryTextData(formattedData);
        })
        .finally(() => {
          setLoadingQueryTextData(false);
        });
    },
    [today, startDate, safeFetchAndFormatData],
  );

  const chartFormattedData = useMemo(() => {
    return getChartFormattedData(
      appQueryingData,
      'APP_QUERYING',
      'QUERY_DATE',
      selectedAppQuerying,
      startDate,
      today,
      reportType,
      ['QUERY_MONTH', 'APP_QUERYING'],
    );
  }, [appQueryingData, selectedAppQuerying, reportType, today, startDate]);

  const dataGroupedByAppQuerying = useMemo(() => {
    return getDataAggregated(chartFormattedData, ['APP_QUERYING']);
  }, [chartFormattedData]);

  // If we have selected an app_querying and received queryTextData, show that data in the table
  // Otherwise, group by app_querying for display in the table
  const tableFormattedData = useMemo(() => {
    if (useQueryTextData) {
      // If there are any Query Tags, add them to the table data, otherwise, don't bother showing them
      const hasQueryTags = queryTextData.some((row) => {
        return row.QUERY_TAG;
      });
      return queryTextData.map((row) => {
        let baseFormattedData: any = {
          SQL: row.SAMPLE_QUERY_TEXT,
          'CLOUD SERVICE CREDITS': Math.round(row.CLOUD_SERVICE_CREDITS * 100) / 100,
          'COMPUTE CREDITS': Math.round(row.COMPUTE_CREDITS * 100) / 100,
          'NUMBER OF RUNS': row.NUM_RUNS,
          'TIME IN SECONDS': Math.round(row.TIME),
        };
        if (hasQueryTags) {
          baseFormattedData = { 'QUERY SOURCE': row.QUERY_TAG, ...baseFormattedData };
        }
        return baseFormattedData;
      });
    } else {
      return dataGroupedByAppQuerying.map((row) => {
        return {
          APP: row.APP_QUERYING,
          'CLOUD SERVICE CREDITS': Math.round(row.CLOUD_SERVICE_CREDITS * 100) / 100,
          'COMPUTE CREDITS': Math.round(row.COMPUTE_CREDITS * 100) / 100,
          'NUMBER OF RUNS': row.NUM_RUNS,
          'TIME IN SECONDS': Math.round(row.TIME),
        };
      });
    }
  }, [useQueryTextData, queryTextData, dataGroupedByAppQuerying]);

  const appQueryingTypes = useMemo(() => {
    return getSortedDatasetLabels(
      chartFormattedData,
      dataGroupedByAppQuerying,
      'APP_QUERYING',
      'CLOUD_SERVICE_CREDITS',
    );
  }, [chartFormattedData, dataGroupedByAppQuerying]);

  const chartDataGroupedByDate: { [date: string]: AppQueryingDailyRow[] | AppQueryingMonthlyRow[] } =
    useMemo(() => {
      const groupByColumn = reportType === 'lastYearMonthly' ? 'QUERY_MONTH' : 'QUERY_DATE';
      return getDataGrouped(chartFormattedData, groupByColumn);
    }, [chartFormattedData, reportType]);

  const datasets = useMemo(() => {
    return getDatasets(
      appQueryingTypes,
      chartDataGroupedByDate,
      'APP_QUERYING',
      'CLOUD_SERVICE_CREDITS',
    );
  }, [chartDataGroupedByDate, appQueryingTypes]);

  const labels = useMemo(() => {
    return getLabels(chartDataGroupedByDate);
  }, [chartDataGroupedByDate]);

  const handleSetReportType = (newReportType: ReportTypes) => {
    setError('');
    if (newReportType !== reportType && selectedAppQuerying !== null) {
      fetchQueryTextData(selectedAppQuerying);
    }
    setReportType(newReportType);
    analytics.track('Usage SetReportType', { type: newReportType, tab: 'Cloud Service' });
  };

  const handleSelectAppQuerying = (appQuerying: string | null) => {
    setError('');
    setSelectedAppQuerying(appQuerying);
    if (appQuerying !== null) {
      fetchQueryTextData(appQuerying);
    }
    analytics.track('Usage SelectAppQuerying', { appQuerying: appQuerying, tab: 'Cloud Service' });
  };

  return (
    <TabView
      loadingTable={loadingQueryTextData}
      tableData={tableFormattedData}
      datasets={datasets}
      labels={labels}
      startDate={startDate}
      today={today}
      selectedFilter={selectedAppQuerying}
      reportType={reportType}
      onSetReportType={handleSetReportType}
      onSelectFilter={handleSelectAppQuerying}
      onRowClick={
        useQueryTextData
          ? undefined
          : (row: any) => {
              handleSelectAppQuerying(row['APP']);
            }
      }
      tab="cloud_service"
    />
  );
}
