import { useCallback, useEffect, useMemo, useState } from 'react';

import { useWindowSize } from 'react-use';

import API from 'api/API';
import { CompanySetupGuideOverrides } from 'api/APITypes';
import { HANDLE_WIDTH_NUM } from 'components/layouts/containers/draggable_panes/ControlledDraggablePanes/ControlledDraggablePanes';
import { STARTING_WIDTH } from 'components/page_parts/CompanySetupGuide/CompanySetupGuide';
import guideSteps, { StepDetails } from 'components/page_parts/CompanySetupGuide/guideSteps';
import { useUserProfile, useDatabaseAccount } from 'context/AuthContext';
import { OnboardingPopoverTourContextInterface } from 'model_layer/OnboardingPopoverTourContext';

import { APIStepKey, CompanySetupGuideContextInterface, UIStepKey } from './CompanySetupGuideContext';

interface CrossTabState {
  openStep: UIStepKey | null;
  currentTab: string;
}

interface CrossTabMessage {
  type: 'request' | 'response';
  state: CrossTabState | null;
}

interface LastOpenTabs {
  explore_data: string | null;
  get_data: string | null;
  transform_data: string | null;
  use_data: string | null;
}

export default function useCompanySetupGuideContext(
  onboardingPopoverTourContext: OnboardingPopoverTourContextInterface,
) {
  // CompanySetupGuide starts disabled and is then set to enabled
  // after the APIs load if the account setup is incomplete.
  // This prevents the setup guide from flashing on for complete accounts.
  const [enabled, setEnabled] = useState(false);
  const [show, setShow] = useState(false);
  const [openStep, setOpenStep] = useState<UIStepKey | null>(null);
  const [currentTab, setCurrentTab] = useState('');
  const [lastOpenTabs, setLastOpenTabs] = useState<LastOpenTabs>({
    explore_data: null,
    get_data: null,
    transform_data: null,
    use_data: null,
  });

  const { userProfile, updateCompany } = useUserProfile();
  const databaseType = useDatabaseAccount().type;
  const { loaded, currentStep } = onboardingPopoverTourContext;
  const popoverTourOpen = !(currentStep === 'never_started' || currentStep === 'complete');

  const apiSetupGuideState = userProfile.company.company_setup_guide_overrides;
  const { explore_data, get_data, transform_data, use_data } = apiSetupGuideState;
  const setupComplete = get_data && explore_data && transform_data && use_data;

  // Do no show the popover tour if there isn't enough room on the page.
  const SEMI_OFFICIAL_STANDARD_WIDTH = 814;
  const minPageWidth = SEMI_OFFICIAL_STANDARD_WIDTH + HANDLE_WIDTH_NUM + STARTING_WIDTH;
  const { width } = useWindowSize();
  const wideEnoughToShowGuide = width >= minPageWidth;

  // When the app first loads, `setupComplete` and `show` are calculated from
  // the API state at the time when the user's session started.
  // After that they are client side properties until the browser is closed.
  useEffect(
    () => {
      if (wideEnoughToShowGuide) {
        // Default to showing nothing.
        // Do not show the popover tour and the setup guide at the same time.
        // If the setup guide is not complete, show it.
        if (userProfile.feature_flags.company_setup_guide) {
          const enabled = loaded && !popoverTourOpen && !setupComplete;
          const show = enabled;
          setEnabled(enabled);
          setShow(show);
        }
      } else {
        setEnabled(false);
        setShow(false);
      }
    },
    // `setupComplete` is not a dependency because we don't want to change the `enabled` status if the user completes the guide in this session.
    [loaded, popoverTourOpen, wideEnoughToShowGuide], // eslint-disable-line react-hooks/exhaustive-deps
  );

  // Use BroadcastChannel to share Company Setup Guide state between tabs.
  const componentMountedAt = useMemo(() => new Date().getTime(), []);
  useEffect(() => {
    const bc = new BroadcastChannel('csg_state');

    // Listen for messages from other tabs.
    bc.onmessage = (event) => {
      const message = event.data as CrossTabMessage;

      // If another tab asks for state, send it.
      if (message.type === 'request') {
        const response: CrossTabMessage = {
          type: 'response',
          state: { openStep, currentTab },
        };
        bc.postMessage(response);
      }

      // If another tab sends me their state, set it locally.
      else if (message.type === 'response') {
        // Do not listen for another tab's state indefinitely.
        // If I don't get a response back immediately, it means there
        // isn't another tab open.
        const WAIT_FOR = 2000;
        const now = new Date().getTime();
        if (now < componentMountedAt + WAIT_FOR) {
          const remoteState = message.state as CrossTabState;
          setOpenStep(remoteState.openStep || null);
          setCurrentTab(remoteState.currentTab || '');
        }
      }
    };

    // When I load ask other tabs for state.
    bc.postMessage({ type: 'request' });

    const close = () => {
      bc.close();
    };

    return close;
  }, [componentMountedAt, currentTab, openStep]);

  const updateOverrides = useCallback(
    (overrides: Partial<CompanySetupGuideOverrides>) => {
      const api = new API();
      return api
        .patch(
          `/api/company_setup_guide_overrides/${userProfile.company.company_setup_guide_overrides.id}`,
          overrides,
        )
        .then((response) => {
          updateCompany({ ...userProfile.company, company_setup_guide_overrides: response.data });
        })
        .catch(() => {
          // Silently eat error because there is no good place to show it and it's not a big deal if setup guide state is not saved.
        });
    },
    [userProfile.company, updateCompany],
  );

  const setStep = useCallback(
    (step: APIStepKey, value: boolean) => {
      return updateOverrides({ [step]: value });
    },
    [updateOverrides],
  );

  const handleSetOpenStep = useCallback(
    (step: UIStepKey | null) => {
      // Set the tab to the last tab that was open for this step.
      // If the step was never open use the first tab.
      if (step !== null && step !== 'useful_links') {
        const stepDetails = guideSteps.find((sd) => sd.key === step) as StepDetails;
        const tabs = stepDetails.instructions.map((i) => i.label);
        const firstTabOfCurrentStep = tabs[0].key;
        const lastOpenTab = lastOpenTabs[step] || firstTabOfCurrentStep;
        setCurrentTab(lastOpenTab);
        setLastOpenTabs({
          ...lastOpenTabs,
          [step]: lastOpenTab,
        });
      }
      setOpenStep(step);
    },
    [lastOpenTabs],
  );

  const handleSetCurrentTab = useCallback(
    (tab: string) => {
      setLastOpenTabs({
        ...lastOpenTabs,
        [openStep as UIStepKey]: tab,
      });
      setCurrentTab(tab);
    },
    [openStep, lastOpenTabs],
  );

  const onComplete = useCallback(() => {
    setEnabled(false);
    setShow(false);
    setOpenStep(null);
  }, []);

  // Show the guide if the guide is not shown because:
  // 1. It was completed before app load.
  // 2. The user just completed it, and it was closed by onComplete().
  const onShowGuide = useCallback(() => {
    setEnabled(true);
    setShow(true);
    setOpenStep(null);
  }, []);

  // Debugging convenience for reseting the guide.
  const startGuide = useCallback(() => {
    const resetOverrides = {
      explore_data: false,
      get_data: false,
      transform_data: false,
      use_data: false,
    };

    updateOverrides(resetOverrides).then(() => {
      setEnabled(true);
      setShow(true);
      setOpenStep(null);
    });
  }, [updateOverrides]);

  // Debugging convenience for completing the guide.
  const endGuide = useCallback(() => {
    const resetOverrides = {
      explore_data: true,
      get_data: true,
      transform_data: true,
      use_data: true,
    };

    updateOverrides(resetOverrides).then(() => {
      setEnabled(false);
      setShow(false);
      setOpenStep(null);
    });
  }, [updateOverrides]);

  // Developer testing conveniences
  useEffect(() => {
    // Safety check just incase something dangerous accidentally gets merged into master
    if (process.env.NODE_ENV === 'development') {
      // setEnabled(true);
      // setShow(true);
    }
  }, []);

  const context = useMemo(() => {
    const context: CompanySetupGuideContextInterface = {
      enabled: databaseType !== 'bigquery' ? enabled : false,
      show: databaseType !== 'bigquery' ? show : false,
      steps: apiSetupGuideState,
      openStep,
      currentTab,
      setupComplete,
      setShow,
      setStep,
      setOpenStep: handleSetOpenStep,
      setCurrentTab: handleSetCurrentTab,
      onComplete,
      onShowGuide,
      startGuide,
      endGuide,
    };
    return context;
  }, [
    enabled,
    show,
    apiSetupGuideState,
    openStep,
    currentTab,
    setupComplete,
    databaseType,
    setShow,
    setStep,
    handleSetOpenStep,
    handleSetCurrentTab,
    onComplete,
    onShowGuide,
    startGuide,
    endGuide,
  ]);

  // Add some hacking tools to the developer console.
  // @ts-ignore
  window.csgContext = { ...context };

  return context;
}
