// Note these all have some sort of version kicker on them,
// so that if we change the API format it won't load the payload
// from a previous API version from localStorage.
export const LIST_CONNECTORS = '/connectors/V3';
export const LIST_TRANSFORMS = '/transforms/V4';
export const LIST_TABLES = '/tables/V6';
export const LIST_RECENTS = '/recents/V1';
export const LIST_FAVORITES = '/favorites/V1';
export const LIST_TAGS = '/tags/V1';
export const LIST_DBT_RUN_CONFIGS = '/api/dbt_run_configurations/V2';
export const LIST_DBT_DESTINATION_TABLES = '/api/dbt_destination_tables/V1';
export const LIST_LATEST_CSV_UPLOADS = '/api/csv_uploads/latest/V1';
export const SEARCH_COLUMNS_BY_TABLE_ID = '/search_columns_by_table_id/V1';

/*******************************************************************************
 * Call this after you do anything that would invalidate cached data,
 * such as adding or deleting a connector or a transform.
 ******************************************************************************/
export function deleteCaches() {
  window.localStorage.removeItem(LIST_CONNECTORS);
  window.localStorage.removeItem(LIST_TRANSFORMS);
  window.localStorage.removeItem(LIST_TABLES);
  window.localStorage.removeItem(LIST_RECENTS);
  window.localStorage.removeItem(LIST_FAVORITES);
  window.localStorage.removeItem(LIST_TAGS);
  window.localStorage.removeItem(LIST_DBT_RUN_CONFIGS);
  window.localStorage.removeItem(LIST_DBT_DESTINATION_TABLES);
  window.localStorage.removeItem(LIST_LATEST_CSV_UPLOADS);
  window.localStorage.removeItem(SEARCH_COLUMNS_BY_TABLE_ID);
}

const KILOBYTES_PER_CHAR = 2 / 1024;
/*******************************************************************************
 * ALWAYS USE setAPIPayload() OR trySetItem() WHEN SETTING A localStorage KEY THAT
 * IS OPTIONAL TO SET, ie the app will be OK if it isn't saved.
 *
 * Critical localStorage keys such as the auth token get written before APIs,
 * so they shouldn't be a problem.
 *
 * Caching the APIs might have filled up localStorage.
 *
 * window.localStorage.setItem() will throw an exception if
 * the sum of the current item and all previously cached keys
 * is larger than localStorage's max size of 5MB.
 * This method prevents those exceptions from crashing the app.
 *
 * Failure to use this method may result in a "QuotaExceededError".
 ******************************************************************************/
export function trySetItem(key: string, value: any) {
  // TODO: Remove this Safari skip if either Safari fixes their issue or we no longer use localStorage for auth tokens.
  // Safari has a bug where overflowing localStorage fails and doesn't raise an exception for us to catch below.
  // Initial Report: https://mozartdata.slack.com/archives/C012PFNG868/p1674778452040539
  // So for now check how much we have already stored before setting to prevent causing an exception.
  value = typeof value === 'string' ? value : JSON.stringify(value);
  if (!canStoreWithoutException(value)) {
    return;
  }

  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    // Our largest customers have API responses that in total are too big to store in localStorage's max size of 5MB.
    // Delete the least important cached API response and try again.
    // At present, the only optional cached API is `/api/columns`, so this logic is simple.
    // This is also the largest response payload, so deleting this one is the most beneficial.
    // `/api/columns` is optional because it's data is not used for default renders on the Warehouse or TableList.
    // It's only used for searches triggered by user input.
    // FYI:
    // This if statment is unlikely to run now that we added the canStoreWithoutExection() method.
    // Leaving it in incase we want this behavior in the future.
    // It probably doesn't hurt anything.
    if (key !== SEARCH_COLUMNS_BY_TABLE_ID) {
      try {
        window.localStorage.removeItem(SEARCH_COLUMNS_BY_TABLE_ID);
        window.localStorage.setItem(key, value);
      } catch (error) {
        analytics.track('LocalStorage DeleteAndTryAgainSetException', {
          key,
        });
      }
    } else {
      analytics.track('LocalStorage SetException', {
        key,
      });
    }
  }
}

function canStoreWithoutException(nextValue: string) {
  // Sum currently stored data
  let sum = 0;
  Object.keys(localStorage).forEach((k) => {
    const item = localStorage.getItem(k);
    const size = item?.length || 0;
    sum += size;
  });

  // Safari should be able to hold about 5MB in localStorage.
  // We should leave some room for third party JS to write to localStorage.
  const CONSERVATIVE_ESTIMATE_OF_5MB = 4500;
  const nextSum = (sum + nextValue.length) * KILOBYTES_PER_CHAR;
  return nextSum < CONSERVATIVE_ESTIMATE_OF_5MB;
}

/*******************************************************************************
 * Always use this method to save an API payload to localStorage.
 *
 * History:
 * The logic in trySetItem() was originally in this method,
 * but it became apparent that we need to do the trySetItem() technique on
 * non-API setItems() as well, so we generalized.
 ******************************************************************************/
export function setAPIPayload(key: string, apiPayload: any) {
  trySetItem(key, apiPayload);
}

export function getAPIPayload<T>(key: string) {
  const cachedValue = window.localStorage.getItem(key);
  let cachedObject = null;
  if (cachedValue) {
    try {
      cachedObject = JSON.parse(cachedValue) as T;
    } catch (error) {
      analytics.track('LocalStorage GetException', {
        key,
      });
    }
  }
  return cachedObject;
}
