import Button from 'components/inputs/basic/Button/Button';
import Modal from 'components/layouts/containers/modals/Modal/Modal';

interface PreviewReportModalProps {
  connectorService: string;
  onClose(): void;
}

export default function PreviewReportModal(props: PreviewReportModalProps) {
  const { connectorService, onClose } = props;

  const footer = (
    <div className="w-full flex justify-end">
      <Button size="small" variant="darkDullAction" onClick={onClose}>
        Close
      </Button>
    </div>
  );
  return (
    <Modal
      header="Report Preview"
      footer={footer}
      onClose={onClose}
      cancelButton={true}
      containerClass="w-full h-full max-w-[80vw] max-h-[80vh] m-auto"
    >
      <div className="h-full flex flex-col p-6">
        <img
          src={`/images/report_builder/full/${connectorService}.png`}
          alt="full size report preview"
          className="w-full h-full object-contain"
        />
      </div>
    </Modal>
  );
}
