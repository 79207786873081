import { HourglassSplit } from 'react-bootstrap-icons';

import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import Alert from 'components/widgets/alerts/Alert/Alert';
import ConfirmHistoricalResyncConnectorModal from 'pages/connectors/ListConnectors/ConfirmHistoricalResyncConnectorModal';

import { ManageTablesTabState } from '../useManageTablesTabState';

import EditButtons from './EditButtons';
import PickTablesBeforeSyncingModal from './PickTablesBeforeSyncingModal/PickTablesBeforeSyncingModal';
import SchemaChangePolicy from './SchemaChangePolicy';
import SchemasAndTablesToSync from './SchemasAndTablesToSync';

interface ManageTablesTabProps extends ManageTablesTabState {}

export default function ManageTablesTab(props: ManageTablesTabProps) {
  const {
    connector,
    isViewer,
    savingDisabled,
    saving,
    loading,
    error,
    editMode,
    showPickTablesBeforeSyncingModal,
    schemaChangePolicy,
    schemasAndTablesToSync,
    historicalResyncState,
    onEdit,
    onDiscard,
    onSave,
    onCancelPickTablesBeforeSyncing,
    onGotItPickTablesBeforeSyncing,
  } = props;

  const { startDisabledTables } = connector;

  // If a connector starts unpaused and it's still syncing for the first time,
  // it's possible the Fivetran API returned the connector's schema as undefined and
  // our frontend model is storing it as null.
  // In this case, we can't render an empty set of controls so render
  // and explanatory Alert that advises the user to be patient.
  // In theory, the schemaChangePolicy && schemasAndTablesToSync could be independent.
  // We are merging their rendering into one flag because there is only one set of edit/save buttons.
  const showEditControls = schemaChangePolicy && schemasAndTablesToSync;
  const stillSyncing = connector.status.setup_state === 'connected' && connector.succeeded_at === null;
  const showSchemaPending = !showEditControls && stillSyncing && !error;

  return (
    <TabLayout loading={loading} error={error} variant="wide_padding">
      {showSchemaPending && (
        <Alert variant="info" className="mb-4">
          Your connector is still syncing for the first time. The controls on this page will become
          available once your connector's synced table(s) are known.
        </Alert>
      )}
      {showEditControls && (
        <div>
          {startDisabledTables && (
            <Alert variant="warning" className="mb-4">
              <span>
                Mozart Data has disabled a handful of tables by default. These tables can slow down syncs
                and can also lead to high MAR usage.
              </span>
              <span>
                We marked the these tables with an{' "'}
                <HourglassSplit className="inline" color={'var(--pri-warning-600)'} />" icon. Reenable
                these tables if you need their data.
              </span>
            </Alert>
          )}
          <div className="flex space-x-2">
            <div className="text-base font-medium">Schema and Tables Settings</div>
            <EditButtons
              editMode={editMode}
              saving={saving}
              isViewer={isViewer}
              onEdit={onEdit}
              onDiscard={onDiscard}
              onSave={onSave}
            />
          </div>
        </div>
      )}
      {showEditControls && schemaChangePolicy && (
        <SchemaChangePolicy schemaChangePolicy={schemaChangePolicy} savingDisabled={savingDisabled} />
      )}
      {showEditControls && schemasAndTablesToSync && (
        <SchemasAndTablesToSync
          schemasAndTablesToSync={schemasAndTablesToSync}
          savingDisabled={savingDisabled}
          saving={saving}
          resyncDisabled={historicalResyncState.disabled}
          resyncSpinning={historicalResyncState.spinning}
          openHistoricalResyncModal={historicalResyncState.openHistoricalResyncModal}
          startDisabledTables={startDisabledTables}
        />
      )}
      {historicalResyncState.showHistoricalResyncModal && (
        <ConfirmHistoricalResyncConnectorModal
          resyncing={historicalResyncState.spinning}
          scope={historicalResyncState.scope}
          onCancel={historicalResyncState.closeHistoricalResyncModal}
          onConfirm={historicalResyncState.performHistoricalResync}
        />
      )}
      {showPickTablesBeforeSyncingModal && (
        <PickTablesBeforeSyncingModal
          connector={connector}
          onCancel={onCancelPickTablesBeforeSyncing}
          onGotIt={onGotItPickTablesBeforeSyncing}
        />
      )}
    </TabLayout>
  );
}
