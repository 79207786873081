/* This is an HTML table row that gets inject once for each AggTable before
the HTML table rows for each of the AggTable's alerts are rendered.
It's like a header row but it's a TD embedded in a larger table. */
import { Link } from 'react-router-dom';

import { AggTable } from 'api/tableAPI';
import { getLastRunMessage } from 'pages/tables/ShowTable/DataAlertTab/shared/DataAlertHelper';

interface DataAlertTableBannerRowViewProps {
  table: AggTable;
}

const DataAlertTableBannerRowView = (props: DataAlertTableBannerRowViewProps) => {
  const { table } = props;
  const lastTableRunMessage = getLastRunMessage(table, 'ml-4 text-sm');
  return (
    <tr key={table.id}>
      <td colSpan={9} className="!h-8 !p-0 !pl-4 bg-pri-gray-100 !border-b-0">
        <div className="f-row-y-center">
          <Link
            to={`/tables/${table.id}/alerts`}
            target="_blank"
            data-track="DataAlertsPage DataAlertClick"
            className="mr-4 !text-purple text-sm font-medium cursor-pointer"
          >
            {table.full_name}
          </Link>
          -{lastTableRunMessage}
        </div>
      </td>
    </tr>
  );
};

export default DataAlertTableBannerRowView;
