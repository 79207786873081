import React from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { AggTable } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { DataAlertWithTable, CreateDataAlertProps, SavableDataAlertProps } from 'hooks/useDataAlerts';

import DataAlertSqlLayout from './shared/DataAlertSqlLayout';

const createFormSchema = yup.object({
  dataAlertName: yup
    .string()
    .required('Name is required')
    .max(255, 'Name cannot be longer than 255 characters'),
  sql: yup.string(),
});

interface CreateDataAlertModalProps {
  createModalOpen: boolean;
  table: AggTable;
  createErrorMessage: string;
  saving: boolean;
  showConfirmModal: boolean;
  onCloseCreateModal: () => void;
  onCreateDataAlert: (values: CreateDataAlertProps) => void;
  onUpdateDataAlert: (
    dataAlert: DataAlertWithTable,
    savableProps: SavableDataAlertProps,
  ) => Promise<DataAlertWithTable>;
  onSetUnsavedSql: (unsaved: boolean) => void;
  onCloseConfirmModal: () => void;
  onConfirmConfirmModal: () => void;
}

export default function CreateDataAlertModal(props: CreateDataAlertModalProps) {
  const {
    createModalOpen,
    table,
    createErrorMessage,
    saving,
    showConfirmModal,
    onCloseCreateModal,
    onCreateDataAlert,
    onUpdateDataAlert,
    onSetUnsavedSql,
    onCloseConfirmModal,
    onConfirmConfirmModal,
  } = props;

  let createInitialValues = {
    dataAlertName: '',
    sql: '',
    table: table.id,
  };

  let createModal = null;
  if (createModalOpen) {
    createModal = (
      <Modal
        onClose={onCloseCreateModal}
        header={`New alert for ${table.full_name}`}
        fullscreen={true}
        cancelButton={true}
      >
        <div className="flex flex-col h-full pt-2">
          {createErrorMessage && (
            <Alert className="mx-4 mb-2" variant="error">
              {createErrorMessage}
            </Alert>
          )}
          <Formik
            validationSchema={createFormSchema}
            onSubmit={onCreateDataAlert}
            initialValues={createInitialValues}
          >
            {({ handleSubmit, handleChange, values, errors }) => {
              // The AceEditor is special and needs special handleChange
              const handleAfterSqlChange = (newSql: string): void => {
                values.sql = newSql;
              };
              const defaultSql = `SELECT * FROM ${table.full_name} WHERE `;
              const newUnsavedDataAlert: DataAlertWithTable = {
                id: 'NEW_TABLE_TEST_UNSAVED_OBJECT_ID',
                type: 'custom',
                name: '',
                sql: defaultSql,
                enabled: true,
                mode: 'notify',
                schedule: '',
                table: table,
                sql_changed_since_last_run: false,
                should_send_csv: false,
                last_table_test_run: null,
              };
              return (
                <form noValidate onSubmit={handleSubmit} className="flex flex-col h-full w-full">
                  <div className="px-4 mb-2 f-between">
                    <TextFormikGroup name="dataAlertName" label="Name" groupClass="w-1/2" />
                    <Button type="submit" variant="lightAction" spinning={saving}>
                      Save
                    </Button>
                  </div>
                  <DataAlertSqlLayout
                    dataAlert={newUnsavedDataAlert}
                    table={table}
                    saving={saving}
                    createMode={true}
                    saveDataAlert={onUpdateDataAlert}
                    afterSqlChange={handleAfterSqlChange}
                    setUnsavedSql={onSetUnsavedSql}
                  />
                </form>
              );
            }}
          </Formik>
          {showConfirmModal && (
            <ConfirmModal
              header="You have unsaved SQL. Are you sure you want to exit?"
              confirmText="Confirm"
              confirmVariant="darkDanger"
              onCancel={onCloseConfirmModal}
              onConfirm={onConfirmConfirmModal}
            />
          )}
        </div>
      </Modal>
    );
  }
  return createModal;
}
