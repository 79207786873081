import { AggRecentTable } from 'api/tableAPI';
import LinkIconButton from 'components/inputs/basic/Button/LinkIconButton';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';

import RecentWorkWidgetItem from './RecentWorkWidgetItem/RecentWorkWidgetItem';

export interface RecentWorkWidgetState {
  allLoaded: boolean;
  anyError: string;
  recentTables: AggRecentTable[];
  onClickRecentWorkWidgetItem: (url: string, aggRecentTable: AggRecentTable) => void;
  onClickViewAll(): void;
}

export interface RecentWorkWidgetProps extends RecentWorkWidgetState {
  className?: string;
}

const RecentWorkWidget = (props: RecentWorkWidgetProps) => {
  const { allLoaded, anyError, recentTables, className, onClickRecentWorkWidgetItem, onClickViewAll } =
    props;

  let content: JSX.Element;
  if (!allLoaded) {
    content = <CenteredSpinner type="circle" />;
  } else if (anyError) {
    // TODO(homepage-v1): See if we can add a refresh button to retry fetching recent tables
    content = (
      <div>
        <Alert variant="error">
          There was an error loading recent tables. Refresh the page to try again.
        </Alert>
      </div>
    );
  } else if (recentTables.length === 0) {
    content = (
      <div className="p-4 w-full h-[300px] flex justify-center items-center bg-gray-50 col-span-2">
        <div className="flex justify-center items-center space-x-2">
          <img
            className="h-[200px] flex-shrink-0 mr-4"
            src="/images/usage/Usage_Sonata_Empty.svg"
            alt=""
          />
          <p className="font-medium flex-shrink">Your recent tables will be displayed here.</p>
        </div>
      </div>
    );
  } else {
    content = (
      <div className="flex flex-col mt-4">
        <table className="w-full">
          <tbody>
            {recentTables.map((r) => (
              <RecentWorkWidgetItem
                key={r.id}
                aggRecentTable={r}
                onClickRecentWorkWidgetItem={onClickRecentWorkWidgetItem}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="flex items-flex-start justify-between">
        <h1 className="text-lg font-medium">Your Recent Tables</h1>
        <LinkIconButton
          icon="ChevronRight"
          text="View All"
          iconLast
          size="small"
          variant="lightDullTransparent"
          to="/warehouse?schemas=recent"
          onClickCapture={onClickViewAll}
          className="uppercase"
        />
      </div>
      {content}
    </div>
  );
};

export default RecentWorkWidget;
