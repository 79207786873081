/*
  VSCode style tab row which allows scrolling, adding, deleting, and moving of tabs.
*/
import React, { useState, useEffect } from 'react';

import { ChevronLeft, ChevronRight, PlusLg, ChevronDown } from 'react-bootstrap-icons';

import cn from 'classnames';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';

import { Menu } from '@headlessui/react';

import MutableTab from './MutableTab';

import s from './MutableTabBar.module.css';
import menuStyle from 'components/widgets/Menu/Menu.module.css';

export interface Tab {
  id: string; // IDs must be unique
  name: string;
}

interface MenuOption {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface MutableTabBarProps {
  selectedTabId: string;
  tabs: Tab[];
  editTabName: boolean; // If the selected tab's name is being edited
  creatingNewTab: boolean;
  addMenuOptions: MenuOption[];
  menuOptions: MenuOption[];
  onClickTab: (tabId: string) => void;
  onRemoveTab: (tabId: string, track?: boolean) => void;
  onDropTab: (draggedTabId: string, droppedOnTabId: string) => void;
  onChangeName: (tabId: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseEditNameMode: () => void;
  onClickNew: (isFlowchart: boolean) => void;
}

const MutableTabBar = (props: MutableTabBarProps) => {
  const {
    selectedTabId,
    tabs,
    editTabName,
    creatingNewTab,
    addMenuOptions,
    menuOptions,
    onClickTab,
    onRemoveTab,
    onDropTab,
    onChangeName,
    onCloseEditNameMode,
    onClickNew,
  } = props;

  const [draggingTabId, setDraggingTabId] = useState('');
  const [dragEnteredId, setDragEnteredId] = useState('');
  const [isOverflowing, setIsOverflowing] = useState(false);

  const scrollRef = useHorizontalScroll();
  useEffect(() => {
    // This useEffect forces a rerender when scrollRef changes from null to an actual ref.
    // First render's ref is null, then the ref gets assigned after that first render, then this useEffect causes another render.
    // The result is we fast-forward through the first render where the ref is null, so things like isOverflowing work without clicking
    if (scrollRef.current) {
      setIsOverflowing(scrollRef.current.offsetWidth < scrollRef.current.scrollWidth);
    }
  }, [scrollRef, scrollRef.current?.offsetWidth, scrollRef.current?.scrollWidth]);

  const handleClickScrollLeft = () => {
    if (scrollRef.current) {
      const scrollAmount = scrollRef.current.clientWidth * -0.75;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handleClickScrollRight = () => {
    if (scrollRef.current) {
      const scrollAmount = scrollRef.current.clientWidth * 0.75;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const onDragStart = (tabId: string, event: React.DragEvent<HTMLDivElement>) => {
    setDraggingTabId(tabId);
    event.dataTransfer.setData('text/plain', tabId);
  };

  const onDragEnd = () => {
    setDraggingTabId('');
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (tabId: string) => {
    setDragEnteredId(tabId);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    // This event fires when we mouse over a column tag's child element.
    // Do not remove the class when this happens.
    // @ts-ignore
    const currentTargetRect = event.currentTarget.getBoundingClientRect();
    // If you move the mouse slowly, the leave event triggers when the mouse coord equals the rect border, so we cannot do <=
    const mouseIsInsideTab =
      event.clientX > currentTargetRect.left &&
      event.clientX < currentTargetRect.right &&
      event.clientY > currentTargetRect.top &&
      event.clientY < currentTargetRect.bottom;
    if (!mouseIsInsideTab) {
      setDragEnteredId('');
    }
  };

  const handleDrop = (droppedOnTabId: string, event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDraggingTabId('');
    setDragEnteredId('');
    const draggedTab = event.dataTransfer.getData('text/plain');
    onDropTab(draggedTab, droppedOnTabId);
  };

  const arrowButtonClass =
    'w-[24px] min-w-[24px] f-center bg-sec-blue-gray-300 hover:bg-sec-blue-gray-400';
  const endButtonClass =
    'w-[36px] min-w-[36px] h-[40px] f-center border-inherit hover:bg-sec-blue-gray-200';

  return (
    <div className="w-full h-full f-between">
      <div className="w-full min-w-0 flex">
        {isOverflowing && (
          <button className={arrowButtonClass} onClick={handleClickScrollLeft}>
            <ChevronLeft size="16" color="white" />
          </button>
        )}
        <div ref={scrollRef} className={cn(s.mutableTabBar, 'flex overflow-x-auto')}>
          {tabs.map((t) => (
            <MutableTab
              key={`mutable-tab-${t.id}`}
              tabs={tabs}
              tab={t}
              selectedTabId={selectedTabId}
              editTabName={editTabName}
              draggingTabId={draggingTabId}
              dragEnteredId={dragEnteredId}
              onClickTab={onClickTab}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={handleDrop}
              onRemoveTab={onRemoveTab}
              onChangeName={onChangeName}
              onCloseEditNameMode={onCloseEditNameMode}
            />
          ))}
        </div>
        {isOverflowing && (
          <button className={arrowButtonClass} onClick={handleClickScrollRight}>
            <ChevronRight size="16" color="white" />
          </button>
        )}
        {addMenuOptions.length > 1 && (
          <Menu as="div" className={menuStyle.menu}>
            {({ open }) => (
              <div className="relative">
                <Menu.Button
                  id="mutable-tab-bar-add-menu"
                  className={cn(endButtonClass, 'cursor-pointer ml-0.5')}
                  disabled={creatingNewTab}
                >
                  {creatingNewTab ? (
                    <CenteredSpinner spinnerSize="16px" type="circle" />
                  ) : (
                    <PlusLg size="20" color="var(--sec-blue-gray-500)" />
                  )}
                </Menu.Button>
                <Menu.Items
                  className={cn(
                    'absolute left-0 mt-[40px] origin-top-left min-w-[150px]',
                    menuStyle.menuItems,
                  )}
                >
                  {addMenuOptions.map((option) => (
                    <Menu.Item key={option.label} disabled={option.disabled}>
                      <div
                        onClick={option.onClick}
                        className={cn(menuStyle.menuItem, {
                          [menuStyle.menuItemDisabled]: option.disabled,
                        })}
                      >
                        {option.label}
                      </div>
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </div>
            )}
          </Menu>
        )}
        {addMenuOptions.length === 1 && (
          <button
            className={cn(endButtonClass, 'cursor-pointer ml-0.5')}
            onClick={() => onClickNew(false)}
            disabled={creatingNewTab}
          >
            {creatingNewTab ? (
              <CenteredSpinner spinnerSize="16px" type="circle" />
            ) : (
              <PlusLg size="20" color="var(--sec-blue-gray-500)" />
            )}
          </button>
        )}
      </div>
      <Menu as="div" className={menuStyle.menu}>
        {({ open }) => (
          <>
            <Menu.Button
              id="mutable-tab-bar-menu"
              className={cn(endButtonClass, 'ml-0.5', {
                'bg-sec-blue-gray-400 hover:bg-sec-blue-gray-500': open,
              })}
            >
              <ChevronDown size="20" color={open ? 'white' : 'var(--sec-blue-gray-500)'} />
            </Menu.Button>
            <Menu.Items className={cn('min-w-[150px]', menuStyle.menuItems)}>
              {menuOptions.map((option) => (
                <Menu.Item key={option.label} disabled={option.disabled}>
                  <div
                    onClick={option.onClick}
                    className={cn(menuStyle.menuItem, { [menuStyle.menuItemDisabled]: option.disabled })}
                  >
                    {option.label}
                  </div>
                </Menu.Item>
              ))}
            </Menu.Items>
          </>
        )}
      </Menu>
    </div>
  );
};

export default MutableTabBar;
