import cn from 'classnames';

interface SetupGuideButtonProps {
  show: boolean;
  setShow: (val: boolean) => void;
  className?: string;
}
export const SetupGuideButton = (props: SetupGuideButtonProps) => {
  const { show, setShow, className } = props;

  const handleToggleSetupGuide = () => {
    const newShow = !show;
    setShow(newShow);
    analytics.track(newShow ? 'Header ShowCompanySetupGuide' : 'Header HideCompanySetupGuide');
  };

  const actualClass = cn(
    'h-[28px] px-2 rounded-full text-sm text-primary-700 font-medium hover:cursor-pointer',
    className,
    {
      'bg-[#b6aae6]': show,
      'bg-silver': !show,
    },
  );

  return (
    <button onClick={handleToggleSetupGuide} className={actualClass}>
      Setup Guide
    </button>
  );
};
