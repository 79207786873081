import React, { ForwardedRef } from 'react';

import cn from 'classnames';

import ButtonSpinner from './ButtonSpinner';

import s from './Button.module.css';

/** Button Sizes
 * - "form" size should match height of text fields
 * - "field" size should also match height of text fields, but is meant for icon-only buttons next
 *    to input fields. E.g. "X" or "Edit" buttons.
 */
export type ButtonSize = 'small' | 'large' | 'form' | 'field';
export type ButtonVariant =
  | 'lightAction'
  | 'darkAction'
  | 'lightDullAction'
  | 'darkDullAction'
  | 'save'
  | 'lightWarning'
  | 'lightDanger'
  | 'darkDanger'
  | 'lightTransparent'
  | 'lightDullTransparent'
  | 'saveTransparent'
  | 'warningTransparent'
  | 'dangerTransparent'
  | 'lightDullTab';

// Props LinkButtons and Buttons have in commone.
export interface LinkButtonProps {
  size?: ButtonSize;
  variant?: ButtonVariant;
  className?: string;
  // The following props render the element as if one of the pseudoclasses applied.
  // They are conveniences for visually inspecting in Storybook.
  forceHover?: boolean;
  forceFocus?: boolean;
  forceActive?: boolean;
}

// Props Buttons and ButtonMenus have in common.
export interface CommonButtonProps extends LinkButtonProps {
  spinning?: boolean;
  disabled?: boolean;
}

export interface ButtonProps
  extends CommonButtonProps,
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  noClickGuard?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, forwardedRef: ForwardedRef<HTMLButtonElement>) => {
    let {
      size,
      variant,
      spinning,
      disabled,
      // TODO Deprecate. This is weird.
      noClickGuard, // Example use, button inside a tag with href, so the href is triggered
      onClick,
      children,
      // Remove the forceXXX props from ...buttonProps to avoid injecting them into the <button> element.
      // This prevents warnings in the browser console.
      forceHover,
      forceFocus,
      forceActive,
      ...buttonProps
    } = props;

    // Default button type is submit, but we want to default to button, so if a type is not passed, set it to button
    const buttonType = buttonProps.type;
    if (buttonType === undefined) {
      buttonProps.type = 'button';
    }

    // Do not let the button click or submit while it is spinning.
    // Not setting disable because we don't want to change the visual treatment.
    // We just want multiple clicks to silently disappear.
    const guardedClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      // If the button is a submit, we want to propagate so the form onSubmit works. Otherwise, don't propagate.
      // Also don't do anything while spinning.
      if (buttonProps.type !== 'submit' || spinning) {
        // Buttons default to type submit unless you set their type to button
        // and their default behavior is a form submit.
        // Executing a form submit by accident would be super annoying.
        // This may not be necessary now that we force the buttons type to button earlier in this file AND because
        // this code is not reachable if the button is of type submit.
        e.preventDefault();
        e.stopPropagation();
        if (!spinning && onClick) {
          onClick(e);
        }
      }
    };

    // Fix default values if they are undefined.
    size = size || 'large';
    variant = variant || 'lightAction';
    spinning = spinning === true;
    disabled = disabled === true;

    // Pick colors based on variant and colorState
    const effectiveClass = getEffectiveClass(size, variant, spinning, disabled, true, props);

    return (
      <button
        ref={forwardedRef}
        disabled={disabled}
        onClick={noClickGuard ? onClick : guardedClick}
        {...buttonProps}
        className={effectiveClass}
      >
        {spinning && <ButtonSpinner size={size} />}
        <div className={cn({ hidden: spinning })}>{children}</div>
      </button>
    );
  },
);

export default Button;

export function getEffectiveClass(
  size: ButtonSize,
  variant: ButtonVariant,
  spinning: boolean,
  disabled: boolean,
  canExpand: boolean,
  props: CommonButtonProps,
) {
  const { forceHover, forceFocus, forceActive, className } = props;
  const enabled = !disabled && !spinning;
  const fontSize = size === 'large' || size === 'form' ? 'text-base' : 'text-sm';
  const buttonSize = s[size] ?? s.small;
  let effectiveClass = cn(s.button, s[variant], fontSize, buttonSize, {
    [s.spinning]: spinning,
    [s.disabled]: disabled,
    ['cursor-default']: !enabled, // eslint-disable-line no-useless-computed-key
    [s.forceHover]: !!forceHover,
    [s.forceFocus]: !!forceFocus,
    [s.forceActive]: !!forceActive,
    [s.canExpand]: canExpand,
    [className as string]: !!className,
  });
  return effectiveClass;
}
