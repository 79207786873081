import React from 'react';

import { UserProfile } from 'api/APITypes';
import LinkButton from 'components/inputs/basic/Button/LinkButton';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

import { trackConnectorID } from '../ConnectorUtils';
import { previewReportPath, showReportPath } from '../ReportBuilder/pathUtils';

interface DashboardColProps {
  available: boolean;
  installed: boolean;
  connectorID: string;
  fivetranConnectorID: string;
  userProfile: UserProfile;
}

export default function DashboardCol({
  available,
  installed,
  connectorID,
  fivetranConnectorID,
  userProfile,
}: DashboardColProps) {
  if (!available) {
    return null;
  }

  const buttonTo = installed ? showReportPath(connectorID) : previewReportPath(connectorID);
  const buttonText = installed ? 'View Report' : 'Add Report';
  const buttonDisabled = !installed && userProfile.company_role === 'viewer';

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    const dataTrack = installed ? 'ListConnectors GoToShowReport' : 'ListConnectors GoToPreviewReport';
    trackConnectorID(dataTrack, connectorID, fivetranConnectorID);
  };

  return (
    <div className="f-row-y-center">
      <LinkButton
        to={buttonTo}
        variant="lightDullTransparent"
        size="small"
        onClick={handleClick}
        disabled={buttonDisabled}
      >
        {buttonText}
      </LinkButton>
      <InfoIcon
        content={
          <div className="text-left">
            Jumpstart your analytics with Report Builder. Get{' '}
            <span className="whitespace-nowrap">pre-built</span> transforms and reports with zero effort.
          </div>
        }
        placement="top"
        popoverProps={{ style: { width: '245px' } }}
      />
    </div>
  );
}
