import React from 'react';

import cn from 'classnames';

import s from './Circle.module.css';

interface CircleProps {
  style?: React.CSSProperties;
  className?: string;
}

function Circle(props: CircleProps) {
  const { style, className } = props;
  return <div className={cn(s.circle, className)} style={style} />;
}

export default Circle;
