/*
 * Layouts are spacing templates that might contain useful tools
 * that all pages need such as:
 * 1. Rendering page titles.
 * 2. Rendering error messages.
 * 3. Headers and Footers if we choose to move those out of the routing file.
 *
 * Most pages should probably use a layout to insure consistent look and feel
 * and to avoid code duplication.
 *
 * 02/23/2024:
 * This file used to do a bit more.
 * It's probably good that this component or another layout continues to exist as the outer most element
 * on any component that react-router will route to in case we need to add common functionality.
 */
import CenteredContainer, {
  CenteredContainerProps,
} from 'components/layouts/containers/CenteredContainer/CenteredContainer';

export interface CenteredLayoutProps extends CenteredContainerProps {
  pageType?: 'standard' | 'full';
}

export default function CenteredLayout(props: CenteredLayoutProps) {
  let { maxWidth, pageType = 'standard', ...rest } = props;

  const actualMaxWidth = `min(100%, ${maxWidth})`;

  const spacingModifiers =
    pageType === 'full' ? { containerClass: '!p-0', contentWrapperClass: '!m-0' } : {};

  return <CenteredContainer {...rest} maxWidth={actualMaxWidth} {...spacingModifiers} />;
}
