import React, { useContext } from 'react';

import History from 'history';

import { UserProfile } from 'api/APITypes';
import { useDatabaseAccount } from 'context/AuthContext';
import { Experiments } from 'context/ExperimentContext';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import useHelpUrl from 'hooks/useHelpUrl';
import { CompanySetupGuideContext } from 'model_layer/CompanySetupGuideContext';
import { OnboardingPopoverTourRefs } from 'model_layer/OnboardingPopoverTourContext';

import HeaderLayout from '../../HeaderLayout/HeaderLayout';

import { AuthHeaderControllerProps } from '../AuthHeaderController';
import { BannerState } from '../useBannerState';

import AddMenu from './AddMenu/AddMenu';
import CommandPalette from './CommandPalette/CommandPalette';
import HeaderIcon from './HeaderIcon/HeaderIcon';
// import HelpChatButton from './HelpChatButton/HelpChatButton';
import HelpMenu, { HelpMenuProps } from './HelpMenu/HelpMenu';
import NotificationDropdown from './NotificationDropdown/NotificationDropdown';
import PrivateLinks from './PrivateLinks/PrivateLinks';
import { SetupGuideButton } from './SetupGuideButton/SetupGuideButton';
import UserMenu from './UserMenu/UserMenu';
import useShowBanner from './useShowBanner';

interface AuthHeaderViewProps extends AuthHeaderControllerProps {
  userProfile: UserProfile;
  logout: () => void;
  location: History.Location;
  experiments: Experiments;
  onboardingPopoverTourRefs: OnboardingPopoverTourRefs;
  bannerState?: BannerState;
  helpMenuProps: HelpMenuProps;
}

export const AuthHeaderView = React.memo((props: AuthHeaderViewProps) => {
  const {
    currentPathObject,
    setShowHotkeys,
    userProfile,
    logout,
    location,
    onboardingPopoverTourRefs,
    bannerState,
    helpMenuProps,
  } = props;
  const helpUrl = useHelpUrl(currentPathObject, location);
  const hasCommandBar = useBooleanFlag('command_bar');
  const hasCompanySetupGuide = useBooleanFlag('company_setup_guide');
  const databaseType = useDatabaseAccount().type;

  const companySetupGuide = useContext(CompanySetupGuideContext);

  const rightJustifiedDiv = (
    <div className="f-row-y-center">
      {hasCommandBar && (
        <div className="ml-2">
          <CommandPalette setShowHotkeys={setShowHotkeys} />
        </div>
      )}

      {userProfile.company_role !== 'viewer' && (
        <>
          <AddMenu ref={onboardingPopoverTourRefs.transforms} />
          <div className="ml-2 h-[24px] w-[1px] bg-silver" />
        </>
      )}

      {hasCompanySetupGuide && databaseType !== 'bigquery' ? (
        <HelpMenu {...helpMenuProps} ref={onboardingPopoverTourRefs.docs} />
      ) : (
        <a
          ref={onboardingPopoverTourRefs.docs}
          className="ml-2"
          href={helpUrl}
          target="blank"
          data-track="Header Help"
        >
          <HeaderIcon type="help" />
        </a>
      )}

      {/* // DEPRECATING THE SERVICEBELL */}
      {/* <HelpChatButton ref={onboardingPopoverTourRefs.analyst_support} /> */}

      <NotificationDropdown />

      <UserMenu accountDisabled={false} userProfile={userProfile} logout={logout} />

      {companySetupGuide.enabled && (
        <SetupGuideButton
          show={companySetupGuide.show}
          setShow={companySetupGuide.setShow}
          className="ml-4"
        />
      )}
    </div>
  );

  const banner = useShowBanner(bannerState);

  const logoUrl = databaseType === 'bigquery' ? '/warehouse' : '/home';

  return (
    <HeaderLayout
      logoPath={logoUrl}
      logoDataTrack="Header LogoHome"
      left={
        <PrivateLinks
          userProfile={userProfile}
          currentPath={location.pathname}
          onboardingPopoverTourRefs={onboardingPopoverTourRefs}
        />
      }
      right={rightJustifiedDiv}
      banner={banner}
    />
  );
});

export default AuthHeaderView;
