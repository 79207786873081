/*
  Some customer's have hundreds of thousands of columns.
  So, we can't compute the entire set of completions at SqlEditor load.
  Instead, we compute the list of column completions for tables in the query
  on an as needed basis.
*/
import { useCallback, useEffect, useRef } from 'react';

import { SearchColumnsByTableID } from 'api/searchColumnAPI';
import { AggTable } from 'api/tableAPI';

import { Completion } from '@codemirror/autocomplete';

import { columnToCompletion } from './objectToCompletion';

const useColumnCompletionCache = (
  tablesByFullName: { [fullName: string]: AggTable },
  searchColumnsByTableID: SearchColumnsByTableID,
) => {
  const columnCompletionCacheRef = useRef<Record<string, Completion[]>>({});

  // Clear the cache every time we get new data from the API.
  useEffect(() => {
    columnCompletionCacheRef.current = {};
  }, [tablesByFullName, searchColumnsByTableID]);

  const getColumnCompletionForTable = useCallback(
    (fullName: string): Completion[] | null => {
      // 1. Try to get the columns out of the cache.
      let completions: Completion[] | null = columnCompletionCacheRef.current[fullName] || null;
      if (completions) {
        return completions;
      }

      // 2. If the table is not in the cache, generate a new list of column completions for this table.
      const table = tablesByFullName[fullName];
      if (table) {
        const columns = searchColumnsByTableID[table.id];
        if (columns) {
          completions = columns.map((c) => columnToCompletion(c));
          columnCompletionCacheRef.current[fullName] = completions;
          return completions;
        }
      }

      // 3. If all else fails, return null.
      return null;
    },
    [tablesByFullName, searchColumnsByTableID],
  );

  const getColumnCompletionsForTables = useCallback(
    (fullNames: string[]): Completion[] => {
      return fullNames
        .map((fullName) => getColumnCompletionForTable(fullName))
        .filter((n) => n !== null)
        .flat() as Completion[];
    },
    [getColumnCompletionForTable],
  );

  return getColumnCompletionsForTables;
};

export default useColumnCompletionCache;
