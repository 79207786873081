/*
  Headless UI Tabs would not let us prevent a tab switch so we rolled our own tab component.

  This component is opinionated in a couple of ways:
  1. It only renders the row of tab buttons.
     Most tab implementations require you to use some sort of <Tab.Panel/> implemenation that tries to 
     handle toggling tab panel content on and off for you. In a complicated UI like Mozart Data,
     the application programmer almost always wants to control this themselves.
  2. The tab keys are strings instead of numeric indexes because those are easier for humans.
*/
import React from 'react';

import cn from 'classnames';
import { capitalize } from 'lodash';

import Tab from './Tab';

export interface LabeledTab {
  key: string;
  label: string;
}

// If you use a string, the string is used as the key and the capitalized string is used as the label.
// If you use a LabeledTab object, the object props are used as the key and label.
export type TabKeyType = string | LabeledTab;

// Variants supported by the design system at present
export type TabVariant = 'default' | 'lightDull' | 'bgGray';
export interface AbstractTabRowProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  tabs: TabKeyType[];
  selectedTab: string;
  leadingEdgeWidth?: number;
  onClick: (selectedTab: string) => void;
}

export interface TabRowProps extends AbstractTabRowProps {
  variant?: TabVariant;
}

const TabRow = (props: TabRowProps) => {
  const { tabs, selectedTab, leadingEdgeWidth, variant, className, onClick, ...rest } = props;
  const safeVariant = variant || 'default';

  const actualClass = cn('flex h-fit', className);
  return (
    <div className={actualClass} {...rest}>
      {safeVariant === 'default' && leadingEdgeWidth !== undefined && (
        <div
          className="h-9 border-b border-pri-gray-200"
          style={{ width: `${leadingEdgeWidth}px`, minWidth: `${leadingEdgeWidth}px` }}
        ></div>
      )}
      {tabs.map((t) => {
        const key = typeof t === 'string' ? t : t.key;
        // Capitalize keys used as labels.
        // If programmer sets label respect it.
        const label = typeof t === 'string' ? capitalize(t) : t.label;
        const selected = key === selectedTab;

        return (
          <Tab
            key={key}
            label={label}
            selected={selected}
            variant={safeVariant}
            onClick={() => onClick(key)}
          />
        );
      })}
      {safeVariant === 'default' && <div className="h-9 w-full border-b border-[pri-gray-200]"></div>}
    </div>
  );
};

export default TabRow;
