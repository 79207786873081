/*
Wrapper used by Query pages via SqlEditorStack.tsx

When the user clicks the Run button this component renders a loading spinner, 
an error message, or the query results table.
*/
import React from 'react';

import { QueryRunResults } from 'api/APITypes';
import ProtipSpinner from 'components/layouts/parts/ProtipSpinner/ProtipSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';

import AIFixSql from '../AIFixSql/AIFixSql';

import RunResultsTable from './RunResultsTable';

export type TimeFormat = 'TIMESTAMP' | 'HUMAN' | 'AGO';

interface ResultsResultProps {
  loading: boolean;
  lastRunSql: string;
  runErrorLines: string[];
  runResults: QueryRunResults | null;
  timeFormat: TimeFormat;
  maxLines: number;
  hideEmptyColumns: boolean;
  selectedColumns: string[];
  hideUnselectedColumns: boolean;
  isShown: boolean;
  onToggleColumn(column: string): void;
  onToggleAllColumns(): void;
  setEditorSql(sql: string, byUser: boolean): void;
  editMode: boolean;
}

const RunResults = React.memo((props: ResultsResultProps) => {
  const {
    loading,
    lastRunSql,
    runErrorLines,
    runResults,
    timeFormat,
    maxLines,
    hideEmptyColumns,
    selectedColumns,
    hideUnselectedColumns,
    isShown,
    editMode,
    onToggleColumn,
    onToggleAllColumns,
    setEditorSql,
  } = props;

  const multipleSqlError =
    runErrorLines.length > 0 && runErrorLines[0].includes('You cannot run multiple SQL statements');

  let content = null;
  if (loading) {
    content = <ProtipSpinner />;
  } else if (runErrorLines.length) {
    content = (
      <div className="px-2">
        <Alert variant="error">
          {runErrorLines.map((line: string, i: number) => {
            return <p key={i}>{line}</p>;
          })}
          {!multipleSqlError && (
            <AIFixSql
              sql={lastRunSql}
              sqlError={runErrorLines.join('\n')}
              editMode={editMode}
              setEditorSql={setEditorSql}
            />
          )}
        </Alert>
      </div>
    );
  } else {
    if (runResults === null) {
      return <></>;
    }
    content = (
      <RunResultsTable
        columns={runResults.columns}
        rows={runResults.rows}
        timeFormat={timeFormat}
        maxLines={maxLines}
        hideEmptyColumns={hideEmptyColumns}
        selectedColumns={selectedColumns}
        hideUnselectedColumns={hideUnselectedColumns}
        isShown={isShown}
        onToggleColumn={onToggleColumn}
        onToggleAllColumns={onToggleAllColumns}
      />
    );
  }
  return <div className="h-full min-h-[200px] bg-white">{content}</div>;
});

export default RunResults;
