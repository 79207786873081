import React from 'react';

import Button from 'components/inputs/basic/Button/Button';

import Modal from '../Modal/Modal';

interface AlertModalProps {
  children?: React.ReactNode; // The body of the modal
  header: React.ReactNode | string;
  closeText?: string;
  onClose: () => void;
}

export default function AlertModal(props: AlertModalProps) {
  let { header, children, closeText = 'OK', onClose } = props;

  const footer = (
    <div className="w-full f-center">
      <Button onClick={onClose}>{closeText}</Button>
    </div>
  );

  return (
    <Modal header={header} footer={footer} onClose={onClose}>
      {children}
    </Modal>
  );
}
