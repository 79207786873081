import cn from 'classnames';

import s from './Hotkey.module.css';

export type HotkeySize = 'small' | 'medium';
export type HotkeyVariant = 'crisp' | 'dull';

interface HotkeyProps {
  children: string; // Examples: "s", "Enter"
  size?: HotkeySize;
  variant?: HotkeyVariant;
}

// Some letters in our font are not centered in their line-height and
// it looks bad when there is a key border drawn around them.
const CENTERING_HACKS: Record<HotkeySize, Record<string, string>> = {
  medium: {
    '⌘': 'top-[1px]',
    p: 'bottom-[1px]',
  },
  small: {
    '⌘': 'top-[1px]',
    p: 'bottom-[1px]',
  },
};

export default function Hotkey(props: HotkeyProps) {
  const { children, size = 'medium', variant = 'crisp' } = props;
  return (
    <kbd className={cn(s.buttonKey, { [s.small]: size === 'small', [s.dull]: variant === 'dull' })}>
      <span className={cn('relative', CENTERING_HACKS[size]?.[children])}>{children}</span>
    </kbd>
  );
}
