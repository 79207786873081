import { useContext, useState } from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { Connector } from 'api/APITypes';
import { DbtRepository, DbtRunConfigUpsertParams, DbtScheduleMode } from 'api/dbtAPI';
import Button from 'components/inputs/basic/Button/Button';
import IconButton from 'components/inputs/basic/Button/IconButton';
import Switch from 'components/inputs/basic/Switch/Switch';
import RadioTabPanelBox, {
  TabKeyType,
} from 'components/inputs/composite/RadioTabPanelBox/RadioTabPanelBox';
import ListboxFormikGroup from 'components/inputs/formik_group/ListboxFormikGroup/ListboxFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import { GroupError } from 'components/inputs/group/InputGroup';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { valSchemaName } from 'utils/Validators';

import AllMarkedScheduleSettings from './AllMarkedScheduleSettings';
import CommandsFormikGroup from './CommandsFormikGroup/CommandsFormikGroup';
import CronSettings from './CronSettings';

export const TABS: TabKeyType[] = [
  { key: 'all_marked', label: 'After all selected connectors' },
  { key: 'cron', label: 'At a specific time' },
];

interface RunConfigFormProps {
  initialValues: DbtRunConfigUpsertParams;
  repos: DbtRepository[];
  onOpenNewRepoForm(): void;
  // This function should:
  // 1. Call the API to do the create or update.
  // 2. Update any global state as appropriate.
  // 3. Navigate to or render the page the user should be on after the save completes.
  // 4. It does not need to catch errors. This form page does that.
  doSave(newValues: DbtRunConfigUpsertParams): Promise<void>;
  // This function should stop rendering this form.
  onCancel(): void;
}

const RunConfigForm = (props: RunConfigFormProps) => {
  const { initialValues, repos, onOpenNewRepoForm, doSave, onCancel } = props;
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { connectors } = useContext(TableModelsContext);
  // Only one company for now (Komet Sales) wants this, but it probably needs to be a permenant flag.
  const settingDatabaseEnabled = useBooleanFlag('dbt_set_database');

  const handleSave = async (newValues: DbtRunConfigUpsertParams, actions: any) => {
    setSaving(true);
    setErrorMessage(null);

    const valuesToSave = {
      ...newValues,
      custom_git_branch: newValues.custom_git_branch || null,
      use_custom_git_branch: !!newValues.custom_git_branch,
    };
    try {
      await doSave(valuesToSave);
    } catch (error: any) {
      if (error.response?.status === 400) {
        const data = error.response.data;
        actions.setErrors(data);
        if (data.non_field_errors) {
          setErrorMessage(data.non_field_errors);
        }
      } else {
        setErrorMessage(`There was a problem saving your dbt job.`);
      }
      setSaving(false);
    }
  };

  // Configure client side input validation
  const validationSchema = yup.object().shape({
    name: yup.string().trim().required('Required'),
    dbt_repository: yup.string().trim().required('Required'),
    target_schema: valSchemaName('Target Schema').trim(),
  } as Partial<Record<keyof DbtRunConfigUpsertParams, any>>);

  // Props that control the height of the <RadioTabPanelBox />
  const CRON_HEIGHT = 154;
  const CONNECTOR_HEIGHT = 40;
  const SEARCH_AND_PADDING = 16 + 42 + 8 + 16;
  const afterAllHeight = CONNECTOR_HEIGHT * Math.min(connectors.length, 6) + SEARCH_AND_PADDING;
  const minHeight = Math.max(afterAllHeight, CRON_HEIGHT);

  return (
    <Formik validationSchema={validationSchema} onSubmit={handleSave} initialValues={initialValues}>
      {({ handleSubmit, handleChange, setFieldValue, getFieldMeta, values }) => {
        const { scheduled, schedule_mode, schedule, connector_dependencies } = values;
        const handleScheduleModeChange = (newMode: string) => {
          setFieldValue('schedule_mode', newMode as DbtScheduleMode);
        };

        const handleToggleConnectorDependency = (connector: Connector) => {
          let prevConnectorIDs = values.connector_dependencies;
          let newConnectorIDs = [];
          if (prevConnectorIDs.includes(connector.id)) {
            newConnectorIDs = prevConnectorIDs.filter((id) => id !== connector.id);
          } else {
            newConnectorIDs = [...prevConnectorIDs, connector.id];
          }
          setFieldValue('connector_dependencies', newConnectorIDs);
        };

        const handleSetSchedule = (schedule: string) => {
          setFieldValue('schedule', schedule);
        };

        return (
          <form noValidate onSubmit={handleSubmit}>
            <div className="w-full grid grid-cols-1 gap-4">
              <TextFormikGroup name="name" label="Job name" onChange={handleChange} />
              <ListboxFormikGroup
                name="dbt_repository"
                label="Git Repo"
                variant="white"
                size="large"
                containerClass="w-full f-row-y-center border border-pri-gray-300 rounded"
                optionsMaxHeightClass="max-h-[210px]"
                options={repos.map((r) => ({
                  value: r.id,
                  label: r.git_repository_owner + '/' + r.git_repository_name,
                }))}
                postLabelActionElement={
                  <IconButton
                    onClick={onOpenNewRepoForm}
                    text="Link a new repo"
                    icon="ChevronRight"
                    iconLast={true}
                    size="small"
                    variant="lightDullTransparent"
                  />
                }
                subscriptDetail={
                  <span className="text-pri-gray-500 text-xs">
                    A dbt job uses your linked dbt Git repo to run dbt commands on a schedule.
                  </span>
                }
              />
              <TextFormikGroup
                name="custom_git_branch"
                label="Git Branch (Optional)"
                placeholder="my_custom_branch"
                onChange={handleChange}
                postLabelElement={
                  <InfoIcon
                    content=<div style={{ whiteSpace: 'pre-line' }}>
                      {`Leave blank to use the repo's default branch (recommended for production scheduled runs).\n\nUsing the non-default branch may be helpful for testing changes such as new dbt packages or new SQL models. You may want to write to a testing schema to avoid overwriting your production data.`}
                    </div>
                    containerClass="ml-2"
                    popoverProps={{ style: { maxWidth: '700px' } }}
                  />
                }
              />
              <TextFormikGroup
                name="target_schema"
                label="Target Schema"
                placeholder="e.g. analytics"
                onChange={handleChange}
                postLabelElement={
                  <InfoIcon
                    content={`Schemas are a collection of tables. This dbt job will write all output tables to the schema entered here.`}
                    containerClass="ml-1"
                    popoverProps={{ style: { maxWidth: '700px' } }}
                  />
                }
              />
              {settingDatabaseEnabled && (
                <TextFormikGroup
                  name="target_database"
                  label="Target Database (optional)"
                  placeholder=""
                  onChange={handleChange}
                  postLabelElement={
                    <InfoIcon
                      content={`If unset, this dbt job will write to your default Mozart Snowflake database. Data written to other Snowflake databases will not show up in the Mozart app.`}
                      containerClass="ml-1"
                      popoverProps={{ style: { maxWidth: '700px' } }}
                    />
                  }
                />
              )}
              <ListboxFormikGroup
                name="dbt_version"
                label="dbt Version"
                variant="white"
                size="large"
                containerClass="w-full f-row-y-center border border-pri-gray-300 rounded"
                optionsMaxHeightClass="max-h-[210px]"
                options={['1.7', '1.6', '1.5', '1.4', '1.3'].map((v) => ({
                  value: v,
                  label: v,
                }))}
              />
              <CommandsFormikGroup
                name="commands"
                label="dbt Commands"
                onChange={handleChange}
                setFieldValue={setFieldValue}
              />
              <div className="w-full flex items-center">
                <Switch name="scheduled" checked={values.scheduled} onChange={handleChange} />
                <label htmlFor="scheduled" className="ml-4 text-base font-medium text-sec-blue-gray-500">
                  SCHEDULE
                </label>
              </div>
              {scheduled && (
                <div>
                  <RadioTabPanelBox
                    tabs={TABS}
                    enabled={true}
                    selectedTab={schedule_mode}
                    horizontal={true}
                    rounded={true}
                    dividerWidth="1px"
                    dividerEnabledColor="var(--sec-blue-gray-100)"
                    onClick={handleScheduleModeChange}
                    containerClass="!h-auto"
                  >
                    <div
                      className="w-full p-4 border-b border-x border-sec-blue-gray-100 rounded-b"
                      style={{ minHeight: `${minHeight}px` }}
                    >
                      {schedule_mode === 'all_marked' && (
                        <AllMarkedScheduleSettings
                          connectors={connectors}
                          savedConnectorDependencyIDs={initialValues.connector_dependencies}
                          unsavedConnectorDependencyIDs={connector_dependencies}
                          onToggleConnector={handleToggleConnectorDependency}
                        />
                      )}
                      {values.schedule_mode === 'cron' && (
                        <CronSettings schedule={schedule} setSchedule={handleSetSchedule} />
                      )}
                    </div>
                  </RadioTabPanelBox>
                  <GroupError error={getFieldMeta('connector_dependencies').error} />
                </div>
              )}
            </div>
            {errorMessage && (
              <Alert variant="error" className="mt-4">
                {errorMessage}
              </Alert>
            )}
            <div className="flex mt-4">
              <Button type="submit" variant="lightAction" spinning={saving}>
                Save
              </Button>
              <Button variant="lightDanger" className="ml-2" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default RunConfigForm;
