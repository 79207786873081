import React from 'react';

import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';

import { TimeFormat } from '../RunResults/RunResults';

interface AdvancedRunResultsOptionsProps {
  timeFormat: TimeFormat;
  setTimeFormat(format: TimeFormat): void;
  maxLines: number;
  setMaxLines(lines: number): void;
  hideEmptyColumns: boolean;
  setHideEmptyColumns(hide: boolean): void;
}

const AdvancedRunResultsOptions = React.memo((props: AdvancedRunResultsOptionsProps) => {
  const { timeFormat, setTimeFormat, maxLines, setMaxLines, hideEmptyColumns, setHideEmptyColumns } =
    props;

  const handleChangeFormat = (format: ListboxValue) => {
    analytics.track('AdvancedRROptions SetTimeFormat', {
      format,
    });
    setTimeFormat(format as TimeFormat);
  };

  const handleChangeLines = (maxLines: ListboxValue) => {
    analytics.track('AdvancedRROptions SetMaxLines', {
      maxLines,
    });
    setMaxLines(maxLines as number);
  };

  const handleChangeHideColumns = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newHide = !hideEmptyColumns;
    analytics.track(
      newHide ? 'AdvancedRROptions HideEmptyColumns' : 'AdvancedRROptions ShowEmptyColumns',
    );
    setHideEmptyColumns(newHide);
  };

  return (
    <form className="flex items-center px-2 pb-2 bg-white">
      <Listbox
        value={timeFormat}
        onChange={handleChangeFormat}
        variant="gray"
        widthClass="w-fit"
        size="medium"
        options={[
          { label: 'Timestamp Format', value: 'TIMESTAMP' },
          { label: 'Human Readable', value: 'HUMAN' },
          { label: 'Time Ago Format', value: 'AGO' },
        ]}
      />

      <Listbox
        value={maxLines}
        onChange={handleChangeLines}
        variant="gray"
        widthClass="w-fit"
        containerClass="ml-4"
        size="medium"
        options={[
          { label: 'Display One Line', value: 1 },
          { label: 'Display Three Lines', value: 3 },
          { label: 'Display Five Lines', value: 5 },
          { label: 'Display Ten Lines', value: 10 },
        ]}
      />
      <div className="flex items-center">
        <Checkbox
          name="hideEmptyColumns"
          checked={hideEmptyColumns}
          variant="blue_gray"
          className="ml-4"
          onChange={handleChangeHideColumns}
        />
        Hide Empty Columns
      </div>
    </form>
  );
});

export default AdvancedRunResultsOptions;
