import { RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react';

import CytoscapeComponent from 'react-cytoscapejs';
import { renderToString } from 'react-dom/server';

import cytoscape from 'cytoscape';

import IconButton from 'components/inputs/basic/Button/IconButton';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import useCytoscapeSetup from 'hooks/useCytoscapeSetup';
import { UserPreferencesContext } from 'model_layer/UserPreferencesContext';

import { Pipeline } from '../PipelineEditor';
import { PipelineVertex } from '../useVertexConverter';

import CustomizeVertexModal, { FormValues } from './CustomizeVertexModal/CustomizeVertexModal';
import usePipelineGraphBuilder from './hooks/usePipelineGraphBuilder';
import stylesheet, { GRAPH_PADDING, VERTEX_WIDTH } from './PipelineGraph.styles';
import PipelineGraphControls from './PipelineGraphControls/PipelineGraphControls';
import HtmlPipelineVertex, { VertexData } from './PipelineVertex/PipelineVertex';
import { getZoomLevels } from './utils/graphUtils';

interface Props {
  pipeline: Pipeline;
  pipelineVertices: PipelineVertex[];
  initialVertexId: string | null;
  legendRef: RefObject<HTMLDivElement | null>;
  onSelectVertex: (id: string) => void;
  onDoubleClickVertex: (id: string) => void;
}

const PipelineGraph = (props: Props) => {
  const { pipeline, pipelineVertices, initialVertexId, legendRef, onSelectVertex, onDoubleClickVertex } =
    props;
  const { edges } = pipeline;
  const cyRef = useRef<cytoscape.Core | null>(null);

  useCytoscapeSetup();

  const { userPreferences, updateUserPreferences } = useContext(UserPreferencesContext);

  /** List of keys to display in graph that map to vertex data */
  const [customizeVertexKeys, setCustomizeVertexKeys] = useState<string[]>(
    userPreferences.pipelineCustomizeVertexKeys ?? [],
  );
  const [showCustomizeVertexModal, setShowCustomizeVertexModal] = useState(false);

  useEffect(() => {
    if (userPreferences.pipelineCustomizeVertexKeys) {
      setCustomizeVertexKeys(userPreferences.pipelineCustomizeVertexKeys);
    }
  }, [userPreferences.pipelineCustomizeVertexKeys]);

  const {
    graphElements,
    hasChangesToLoad,
    onUserMutatesGraphPerspective,
    onUserResetsGraphPerspective,
    loadLatestChanges,
  } = usePipelineGraphBuilder({
    vertices: pipelineVertices,
    edges,
    customizeVertexKeys,
  });

  const setListeners = (cy: cytoscape.Core) => {
    /* Graph Events */
    cy.ready(() => {
      // Unselect all nodes on graph when re-rendering (e.g. on local env reload)
      if (cy.$(':selected').length > 0) {
        cy.$(':selected').unselect();
      }

      // Select initial vertex on the graph
      if (initialVertexId) {
        const initialVertex = cy.getElementById(initialVertexId);
        initialVertex.select();
      }

      // Don't allow edges to be selectable
      cy.edges().unselectify();
    });

    cy.on('layoutstop', () => {
      // Fit graph to screen
      cy.fit(undefined, GRAPH_PADDING);

      const ZOOM_LEVELS = getZoomLevels(cy);

      // Set min zoom level to size of graph or mid-level zoom to add some padding for smaller graphs
      const minZoom = Math.min(ZOOM_LEVELS.low, ZOOM_LEVELS.mid);
      cy.minZoom(minZoom);
      // Set max zoom level to high-level zoom or `minZoom` to lock-in how far you can zoom in on smaller graphs
      const maxZoom = Math.max(ZOOM_LEVELS.high, minZoom);
      cy.maxZoom(maxZoom);

      const graphWidth = cy.extent().w;
      // Hack to describe graphs with 4 or more vertices
      const WIDTH_THRESHOLD = VERTEX_WIDTH * 5;

      // Pan and zoom to currently selected node if graph is large enough. Otherwise, set initial zoom
      // to full graph size
      if (graphWidth > WIDTH_THRESHOLD) {
        cy.zoom(ZOOM_LEVELS.mid);
        cy.center(cy.$(':selected'));
      } else {
        cy.zoom(minZoom);
        cy.center();
      }
    });

    /* User Events */
    cy.on('tap', (event) => {
      // Don't allow deselecting nodes when clicking on the background
      if (event.target === cy) {
        cy.nodes().unselectify();
      } else {
        cy.nodes().selectify();
      }
    });

    cy.on('tap', 'node', (event) => {
      const { id } = event.target.data();
      onSelectVertex(id);
    });
    cy.on('dbltap', 'node', (event) => {
      const { id } = event.target.data();
      onDoubleClickVertex(id);
    });

    cy.on('mouseover', 'edge', (event) => {
      const { id } = event.target.data();
      cy.$(`#${id}`).addClass('hovered');
    });
    cy.on('mouseout', 'edge', (event) => {
      const { id } = event.target.data();
      cy.$(`#${id}`).removeClass('hovered');
    });

    // We need to log when the user does an interaction that would
    // change graph layout or position.
    // Unfortunetly, `cy.on('viewport', (event)=> {})` logs programatic changes,
    // so we need to listen on the individual user interactions.
    cy.on('dragpan', (event) => {
      onUserMutatesGraphPerspective();
    });
    cy.on('scrollzoom', (event) => {
      onUserMutatesGraphPerspective();
    });
  };

  const createNodes = (cy: cytoscape.Core) => {
    //@ts-ignore
    cy.nodeHtmlLabel(
      [
        {
          query: 'node',
          tpl(data: VertexData) {
            const { id } = data;
            const isSelected = cy.$(`#${id}`).selected();
            return renderToString(<HtmlPipelineVertex {...data} isSelected={isSelected} />);
          },
        },
      ],
      {
        enablePointerEvents: true,
      },
    );
  };

  const setUpWithCy = (cy: cytoscape.Core) => {
    setListeners(cy);
    createNodes(cy);
    cyRef.current = cy;
  };

  /* Event handlers for graph controls */
  const handleZoom = (zoomLevelDelta: number) => {
    onUserMutatesGraphPerspective();
    const cy = cyRef.current;
    if (cy) {
      const { x1, x2, y1, y2 } = cy.extent();
      const viewportCenter = {
        x: (x1 + x2) / 2,
        y: (y1 + y2) / 2,
      };
      cy.zoom({ level: cy.zoom() + zoomLevelDelta, position: viewportCenter });
    }
  };

  const handleZoomIn = () => {
    handleZoom(0.2);
  };

  const handleZoomOut = () => {
    handleZoom(-0.2);
  };

  const handleResetView = () => {
    onUserResetsGraphPerspective();
    const cy = cyRef.current;
    if (cy) {
      const ZOOM_LEVELS = getZoomLevels(cy);
      cy.zoom(ZOOM_LEVELS.mid);
      if (initialVertexId) {
        cy.center(cy.getElementById(initialVertexId));
      }
    }
  };

  const handleCustomizeVertexClick = () => {
    setShowCustomizeVertexModal(true);
  };

  const handleCloseModal = () => {
    setShowCustomizeVertexModal(false);
  };

  const handleSaveCustomizeVertexKeys = (values: FormValues) => {
    const transformedSaveData = Object.keys(values).filter((key) => values[key]);

    setCustomizeVertexKeys(transformedSaveData);
    updateUserPreferences({ pipelineCustomizeVertexKeys: transformedSaveData });
    analytics.track('PipelineEditor SaveCustomizeVertexKeys', { keys: transformedSaveData });

    setShowCustomizeVertexModal(false);
  };

  const legendHeight = legendRef.current?.clientHeight ?? 0;
  const graphHeight = `calc(100% - ${legendHeight}px)`;

  /**
   * Memoized to avoid re-renders until user refreshes the graph
   * TODO(pipeline-improv): This is a temporary fix to avoid jumping the user around the graph
   * when it reloads (i.e. when transforms or tables are re-fetched). Refactor to persist viewport
   * data for re-renders.
   */
  const graphComponent = useMemo(
    () =>
      graphElements.length === 0 ? null : (
        <>
          {/* @ts-ignore */}
          <CytoscapeComponent
            elements={graphElements}
            layout={{
              // @ts-ignore
              name: 'elk',
              // @ts-ignore
              elk: { algorithm: 'layered', 'elk.direction': 'RIGHT' },
            }}
            style={{ width: '100%', height: graphHeight }}
            stylesheet={stylesheet}
            cy={setUpWithCy}
            wheelSensitivity={0.8}
            // Disables multi-selecting nodes and edges
            boxSelectionEnabled={false}
            // Disables drag-and-drop of nodes
            autoungrabify
          />
        </>
      ),
    // Adding `setUpWithCy` to the dependency array causes the graph to re-render, making the
    // `useMemo` useless. This is temporary until we can figure out how to persist viewport data
    // for re-renders (see TODO above in `graphComponent`).
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphElements, graphHeight],
  );

  if (graphElements.length === 0) {
    return <CenteredSpinner containerSize="100%" />;
  }

  return (
    <>
      {/* 
        Button to load latest changes in graph .This is necessary to prevent UX issues with graph 
        refreshing and jumping around.

        TODO(pipeline-improv): Remove this button once we have a better solution for graph re-loads
        (see TODO above in `graphComponent`)
      */}
      <div className="absolute m-4 z-50">
        <IconButton
          variant="lightAction"
          icon={hasChangesToLoad ? 'ArrowRepeat' : 'Check2'}
          text={hasChangesToLoad ? 'Load New Changes' : 'Up to Date'}
          onClick={loadLatestChanges}
          disabled={!hasChangesToLoad}
        />
        <div className="mt-2">
          <IconButton
            variant="lightAction"
            icon={'Plus'}
            text="Customize"
            onClick={handleCustomizeVertexClick}
          />
          {showCustomizeVertexModal ? (
            <CustomizeVertexModal
              customizeVertexKeys={customizeVertexKeys}
              onClose={handleCloseModal}
              onSubmit={handleSaveCustomizeVertexKeys}
            />
          ) : null}
        </div>
      </div>
      {/* 
        Set pipeline controls' z-index to be below modal components (i.e. below 50 - not sure exactly
        why 50) but above Cytoscape canvas widget
      */}
      <PipelineGraphControls
        className="absolute right-0 z-[40]"
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        onResetView={handleResetView}
      />
      {graphComponent}
    </>
  );
};

export default PipelineGraph;
