/*******************************************************************************
 * Column picking component for FlowchartEditor.
 *
 * This file is responsible for setting state after user input.
 * This file delegates converting state to JSX to ColumnSearchableCheckboxPicker.
 ******************************************************************************/
import { useCallback, useMemo, useRef, useState } from 'react';

import deepEqual from 'fast-deep-equal';

import { TextInputProps } from 'components/inputs/basic/TextInput/TextInput';
import { moveTo } from 'utils/Array';

import ColumnSearchableCheckboxPicker, {
  ColumnSearchableCheckboxPickerProps,
} from './ColumnSearchableCheckboxPicker/ColumnSearchableCheckboxPicker';
import {
  ColumnRowObject,
  ColumnSearchableCheckboxPickerEveryRowProps,
  ColumnValueID,
} from './ColumnSearchableCheckboxPicker/ColumnSearchableCheckboxPickerRow';
import { SelectorColumn } from './useColumnSelector';

export interface ColumnSelectorProps {
  initialSelections: SelectorColumn[];
  currentSelections: SelectorColumn[];
  aliasErrors: Record<ColumnValueID, string>;
  textInputProps?: Partial<Omit<TextInputProps, 'ref'>>;
  enableScrollListMode?: boolean;
  pickerOverrides?: Partial<ColumnSearchableCheckboxPickerProps>;
  showSocket?: boolean;
  setCurrentSelections: React.Dispatch<React.SetStateAction<SelectorColumn[]>>;
}

const ColumnSelector = (props: ColumnSelectorProps) => {
  const {
    initialSelections,
    currentSelections,
    aliasErrors,
    textInputProps,
    enableScrollListMode,
    pickerOverrides,
    showSocket,
    setCurrentSelections,
  } = props;
  const [draggingColumn, setDraggingColumn] = useState<ColumnValueID>('');
  const [dragEntered, setDragEntered] = useState<ColumnValueID>('');

  // Make readonly copies of state so callbacks aren't redefined, triggering renders.
  const draggingColumnRef = useRef<ColumnValueID>('');
  const dragEnteredRef = useRef<ColumnValueID>('');
  const currentSelectionsRef = useRef<SelectorColumn[]>([]);
  draggingColumnRef.current = draggingColumn;
  dragEnteredRef.current = dragEntered;
  currentSelectionsRef.current = currentSelections;

  /*******************************************************************************
   * Utilities to make updates easier
   ******************************************************************************/
  const updateSelection = useCallback(
    (columnValueID: ColumnValueID, updateFound: (selection: SelectorColumn) => SelectorColumn) => {
      const currentSelections = currentSelectionsRef.current;
      const currentIndex = currentSelections.findIndex((cs) => cs.id === columnValueID);

      if (currentIndex > -1) {
        const updatedSelections = [...currentSelectionsRef.current];
        updatedSelections[currentIndex] = updateFound(updatedSelections[currentIndex]);
        setCurrentSelections(updatedSelections);
      }
    },
    [setCurrentSelections],
  );

  /*******************************************************************************
   * ColumnSearchableCheckboxPicker Props
   ******************************************************************************/
  const handleToggleCheck = useCallback(
    (row: ColumnRowObject) => {
      const updateFound = (oldSelection: SelectorColumn) => {
        const newSelection: SelectorColumn = {
          ...oldSelection,
          picked: !oldSelection.picked,
        };
        return newSelection;
      };
      updateSelection(row.columnValue.id, updateFound);
    },
    [updateSelection],
  );

  // Append UI specific props to QueryModel object so we can loop over one array of objects.
  const convertRow = useCallback(
    (columnValue: SelectorColumn): ColumnRowObject => ({
      columnValue,
      isChecked: columnValue.picked,
      hasChanged: !deepEqual(
        initialSelections.find((sc) => sc.id === columnValue.id),
        columnValue,
      ),
      aliasError: aliasErrors[columnValue.id],
      isBeingDragged: draggingColumn === columnValue.id,
      isDraggedOver: dragEntered === columnValue.id,
      samples: [],
    }),
    [initialSelections, aliasErrors, draggingColumn, dragEntered],
  );

  // New objects trigger rerenders of column selector rows.
  // Reuse old object references if the objects are the same.
  const rowCacheRef = useRef<Record<string, ColumnRowObject>>({});
  const currentRows: ColumnRowObject[] = useMemo(
    () =>
      currentSelections.map((cs) => {
        const newRow = convertRow(cs);
        const cachedRow = rowCacheRef.current[newRow.columnValue.id];
        if (cachedRow && deepEqual(cachedRow, newRow)) {
          return cachedRow;
        }
        rowCacheRef.current[newRow.columnValue.id] = newRow;
        return newRow;
      }),
    [currentSelections, convertRow],
  );

  /*******************************************************************************
   * ColumnSearchableCheckboxPickerEveryRowProps
   *
   * Note:
   * There can be a lot of SelectColumnRows, so these are wrapped
   * in useCallback() to allow React memoization to only have to rerender
   * the rows that change.
   ******************************************************************************/
  const onDragStart = useCallback((column: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => {
    setDraggingColumn(column);
    event.dataTransfer.setData('text/plain', column);
    event.dataTransfer.effectAllowed = 'move';
  }, []);

  const onDragEnd = useCallback(() => {
    setDraggingColumn('');
  }, []);

  const onDragOver = useCallback((event: React.DragEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const onDragEnter = useCallback(
    (enteredColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => {
      event.preventDefault();

      // You can drag onto yourself
      if (enteredColumn !== dragEnteredRef.current) {
        setDragEntered(enteredColumn);
      }
    },
    [dragEnteredRef],
  );

  const onDragLeave = useCallback(
    (leftColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => {
      if (leftColumn === dragEnteredRef.current) {
        // DO NOT change state if the element I "left to" is my descendant.
        // That is not a real leave.
        // @ts-ignore
        const isMyDescendant = event.target.contains(event.relatedTarget);
        if (!isMyDescendant) {
          setDragEntered('');
        }
      }
    },
    [dragEnteredRef],
  );

  const onDropColumn = useCallback(
    (draggedColumn: ColumnValueID, droppedOnColumn: ColumnValueID) => {
      const currentSelections = currentSelectionsRef.current;
      const draggedIndex = currentSelections.findIndex((cs) => cs.id === draggedColumn);
      const droppedOnIndex = currentSelections.findIndex((cs) => cs.id === droppedOnColumn);
      if (draggedIndex >= 0 && droppedOnIndex >= 0) {
        setCurrentSelections(moveTo(currentSelections, draggedIndex, droppedOnIndex));
      }
    },
    [currentSelectionsRef, setCurrentSelections],
  );

  const onDrop = useCallback(
    (droppedOnColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setDraggingColumn('');
      setDragEntered('');

      // You cannot drop onto yourself
      const draggedColumn = event.dataTransfer.getData('text/plain');
      if (droppedOnColumn !== draggedColumn) {
        onDropColumn(draggedColumn, droppedOnColumn);
      }
    },
    [onDropColumn],
  );

  const onDelete = useCallback(
    (columnValueID: string) => {
      const updatedSelections = [...currentSelectionsRef.current].filter((s) => s.id !== columnValueID);
      setCurrentSelections(updatedSelections);
    },
    [setCurrentSelections],
  );

  const onUpdateAlias = useCallback(
    (columnValueID: string, alias: string) => {
      const updateFound = (oldSelection: SelectorColumn) => {
        const newSelection: SelectorColumn = {
          ...oldSelection,
          alias,
        };
        // Do not save empty aliases
        if (alias === '') {
          delete newSelection.alias;
        }
        return newSelection;
      };
      updateSelection(columnValueID, updateFound);
    },
    [updateSelection],
  );

  const everyRowComponentProps: ColumnSearchableCheckboxPickerEveryRowProps = {
    onToggleCheck: handleToggleCheck,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
    onDelete,
    onUpdateAlias,
    showSocket,
  };

  /*******************************************************************************
   * ColumnSelector/Form Level Props
   ******************************************************************************/
  const handleSelectAll = () => {
    const newSelections = currentSelections.map((c) => ({ ...c, picked: true }));
    setCurrentSelections(newSelections);
  };

  const handleDeselectAll = () => {
    const newSelections = currentSelections.map((c) => ({ ...c, picked: false }));
    setCurrentSelections(newSelections);
  };

  return (
    <ColumnSearchableCheckboxPicker
      enableScrollListMode={enableScrollListMode}
      {...pickerOverrides}
      objects={currentRows}
      textInputProps={textInputProps}
      everyRowComponentProps={everyRowComponentProps}
      onSelectAll={handleSelectAll}
      onDeselectAll={handleDeselectAll}
    />
  );
};

export default ColumnSelector;
