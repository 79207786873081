import React from 'react';

import { RouteComponentProps } from 'react-router';
import { useTitle } from 'react-use';

import TabRow from 'components/layouts/parts/TabRow/TabRow';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';
import useSnowflakeRoles from 'hooks/useSnowflakeRoles';
import useTabs from 'hooks/useTabs';

import MozartUsersTabController from './MozartUsersTab/MozartUsersTabController';
import SnowflakeUsersTabController from './SnowflakeUsersTab/SnowflakeUsersTabController';

const MOZART_TAB = 'mozart';
const SNOWFLAKE_TAB = 'snowflake';
const TABS = [MOZART_TAB, SNOWFLAKE_TAB];

interface MatchParams {
  tab: string;
}

interface ListUsersProps extends RouteComponentProps<MatchParams> {}

export default function ListUsers(props: ListUsersProps) {
  useTitle('Users');
  const { userProfile } = useUserProfile();
  const databaseAccount = useDatabaseAccount();

  const isByos = databaseAccount.type === 'snowflake' && databaseAccount.is_byos;
  const databaseType = databaseAccount.type;
  const companyRole = userProfile.company_role;

  const {
    listboxOptions: snowflakeRoleOptions,
    error: snowflakeRoleError,
    loading: snowflakeRoleLoading,
  } = useSnowflakeRoles(databaseAccount);

  const canShowSnowflakeUsersPage = companyRole === 'admin' && !isByos;

  if (databaseType === 'bigquery') {
    TABS.pop();
  }

  const { currentTab, handleSelectTab } = useTabs(
    TABS,
    MOZART_TAB,
    '/users/',
    'Users',
    props.match.params.tab,
    'Users',
  );

  return (
    <div className="f-col mx-10 my-6">
      <h1 className="display-sm mb-2 ml-4">Users</h1>
      {canShowSnowflakeUsersPage && (
        <>
          <TabRow tabs={TABS} selectedTab={currentTab} onClick={handleSelectTab} />
          {databaseType === 'snowflake' && (
            <SnowflakeUsersTabController
              show={currentTab === SNOWFLAKE_TAB}
              snowflakeRoleOptions={snowflakeRoleOptions}
              snowflakeRoleError={snowflakeRoleError}
              snowflakeRoleLoading={snowflakeRoleLoading}
            />
          )}
        </>
      )}
      <MozartUsersTabController
        show={currentTab === MOZART_TAB}
        snowflakeRoleOptions={snowflakeRoleOptions}
        snowflakeRoleError={snowflakeRoleError}
        snowflakeRoleLoading={snowflakeRoleLoading}
      />
    </div>
  );
}
