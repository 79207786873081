import React from 'react';

import cn from 'classnames';

import mozartSpinner from 'components/mozart_spinner.png';

import s from './MozartSpinnerIcon.module.css';

export interface MozartSpinnerIconProps {
  spinning: boolean;
  justify?: 'start' | 'center' | 'end';
  size?: string;
  className?: string;
}

export default function MozartSpinnerIcon(props: MozartSpinnerIconProps) {
  const { spinning, size, className } = props;
  const sizeClass = size === 'medium' ? s.mozartSpinnerMedium : s.mozartSpinner;
  const combinedClasses = cn(sizeClass, { [s.spinnerRotate]: spinning }, className);
  return <img src={mozartSpinner} alt="loading spinner" className={combinedClasses} />;
}

const JUSTIFY_CLASSES = {
  start: 'justify-start',
  center: 'justify-center',
  end: 'justify-end',
};

export function CenteredMozartSpinnerIcon(props: MozartSpinnerIconProps) {
  const { spinning, justify, size, className } = props;
  const safeJustify = justify || 'center';

  return (
    <div className={'f-row-y-center w-full h-full ' + JUSTIFY_CLASSES[safeJustify]}>
      <MozartSpinnerIcon spinning={spinning} size={size} className={className} />
    </div>
  );
}
