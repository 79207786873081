/*
All of the connectors we support are listed in mozart/connectors_common/connectors.json
This file is mostly updated by the scripts, but accepts manual curration.

To add a new connector you need to:
1. To add new Fivetran or Portable connectors run this script: `poetry run python manage.py reconcile_connector_config -v 2`
2. You can manually edit mozart/connectors_common/connectors.json to add a "Custom Connector" or 
   some other exception.
*/
import _ from 'lodash';

// Does the connector create one table, one schema with many tables, or many schemas with many tables?
export type SyncScope =
  | 'table' // Usually, a file that maps to one table.
  | 'one_schema' // Usually, a SaaS tool that creates one table for each object type.
  | 'prefixed_schemas'; // Usually, a database, which has many schemas.

interface ConnectorTypeFivetran {
  // "Custom" Connectors are connectors created by Mozart Data.
  // 1. They are technically Fivetran google_cloud_function connectors.
  // 2. By convention their `service` code starts with `mozart_`.
  // 3. We have decided not to build these anymore if we can help it.
  //    Ideally, we'd outsouce connector creation to Portable or some other vendor.
  // 4. There are very few Custom Connectors used by very few active customers.
  vendor: 'fivetran' | 'custom';
  // What we prepopulate the schema or schema_prefix input with.
  // This is not editable if syncScope==='table'.
  suggestedSchema: string;
  // Note that if syncScope is 'prefixed_schemas' it will start paused no matter what the value of startPaused is.
  syncScope: SyncScope;
  // Should this connector be paused when it is first created,
  // so that the user has a chance to pick which tables and columns to sync before the connector starts syncing data?
  // We used to enable this a lot before we allowed Manage Tables for every connector, but now it's just for a handful (i.e. Salesforce).
  startPaused?: boolean;
  // Human readable version of "service" displayed in UI.
  name: string;
  // The connector is deprecated.
  // Do not let a user create a new one.
  // Connector remains in registry so icon and name lookup still works on existing connectors.
  preventNew?: boolean;
  // Not used for anything on the frontend, but we keep a note if this is also a Portable connector that will we just omit.
  portableName?: string;
  // If we can't make a Connect Card, we will give a message about how it's a lite connector and maybe support can help.
  lite?: boolean;
  // We will maintain a manual list of tables that we shouldn't sync by default due to slowness or MAR issues.
  // We should set startPaused as well if we are setting startDisabledTables
  // TODO: Make reconcile connectors script throw error if startDisabledTables is set without startPaused? Make TypeScript set startPaused at run time?
  startDisabledTables?: string[];
}

interface ConnectorTypePortable {
  vendor: 'portable';
  name: string;
  preventNew?: boolean;
}

// pseudo-connector for CSV files that redirects the user to the CSV upload form
interface ConnectorTypeCSV {
  vendor: 'csv';
  name: 'CSV files';
}

export type ConnectorType = ConnectorTypeFivetran | ConnectorTypePortable | ConnectorTypeCSV;

// Read connectors from the JSON shared between the frontend and backend.
const allConnectors: Record<string, ConnectorType> = require('connectors_symlink.json');
const fivetranConnectors = Object.keys(allConnectors).reduce(
  (result, key) => {
    const connector = allConnectors[key];
    if (connector.vendor === 'fivetran' || connector.vendor === 'custom') {
      result[key] = connector;
    }
    return result;
  },
  {} as Record<string, ConnectorTypeFivetran>,
);

// Connectors we will allow the user to add.
// 1. If a connector is deprecated, it is marked with `preventNew`.
// 2. As of Jan 2024, we don't expose any custom connectors anymore (just deprecated Mozart's Ordway connector).
const addableConnectors = _.pickBy(allConnectors, (connector) => {
  if (connector.vendor === 'csv') {
    return true;
  }
  return !connector.preventNew;
});

// Get human readable name from service key
function conName(service: string) {
  // Handle unknown connectors gracefully
  return allConnectors[service]?.name || service;
}

// Get the icon url of a given service
function iconUrl(service: string) {
  const connectorType = allConnectors[service];
  // Handle unknown connectors gracefully
  if (!connectorType) {
    return '';
  }
  if (connectorType.vendor === 'portable') {
    // TODO: This will be obsolete after we move portable connectors to a sprite sheet.
    return `/images/connector_icons/portable/${service}.png`;
  }
  if (connectorType.vendor === 'csv') {
    return `/images/connector_icons/csv/csv.svg`;
  }
  return `/images/connector_icons/fivetran/${service}.svg`;
}

export { addableConnectors, allConnectors, fivetranConnectors, conName, iconUrl };
