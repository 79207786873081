import React, { useState } from 'react';

import cn from 'classnames';

import TextInput from 'components/inputs/basic/TextInput/TextInput';
import RadioInputGroup from 'components/inputs/group/RadioInputGroup/RadioInputGroup';
import { scheduleOptionsMap } from 'utils/cron';

import { UnsavedTransform } from './RunOptionsModal';

interface CronSettingsProps {
  unsavedTransform: UnsavedTransform;
  setUnsavedTransform: React.Dispatch<React.SetStateAction<UnsavedTransform>>;
}

export default function CronSettings(props: CronSettingsProps) {
  const { unsavedTransform, setUnsavedTransform } = props;

  const cronIsCustom = !Object.keys(scheduleOptionsMap).includes(unsavedTransform.schedule);

  const [customCronSelected, setCustomCronSelected] = useState(cronIsCustom);

  const disabled = !unsavedTransform.scheduled;

  const handleSelectSchedule = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue === 'custom') {
      setCustomCronSelected(true);
    } else {
      setCustomCronSelected(false);
      setUnsavedTransform({ ...unsavedTransform, schedule: newValue });
    }
  };

  const handleChangeCustomCron = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setUnsavedTransform({ ...unsavedTransform, schedule: newValue });
  };

  const customRadioRow = (
    <div className="f-row-y-center">
      <div>Custom</div>
      <TextInput
        name="customSchedule"
        value={unsavedTransform.schedule}
        className="w-[120px] h-[28px] ml-2"
        disabled={!customCronSelected || disabled}
        onChange={handleChangeCustomCron}
      />
      <a
        href={`https://crontab.guru/#${unsavedTransform.schedule.replace(' ', '_')}`}
        rel="noopener noreferrer"
        target="_blank"
        className="ml-2 text-pri-gray-400 whitespace-nowrap hover:underline"
        data-track="TransformTab LearnCron"
      >
        Learn Cron
      </a>
    </div>
  );

  return (
    <div className={cn('flex flex-col', { 'text-pri-gray-400': disabled })}>
      <div className="text-sm font-medium">
        This transform will run at a specific time selected below:
      </div>
      <div className="flex flex-col">
        <RadioInputGroup
          value={customCronSelected ? 'custom' : unsavedTransform.schedule}
          onChange={handleSelectSchedule}
          name="schedule"
          options={Object.keys(scheduleOptionsMap)
            .map((k) => {
              return { value: k, label: scheduleOptionsMap[k] as string | React.ReactNode };
            })
            .concat({ value: 'custom', label: customRadioRow })}
          sharedRadioInputProps={{
            disabled,
            containerClass: 'text-sm mt-2',
          }}
        />
      </div>
    </div>
  );
}
