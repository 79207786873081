import cn from 'classnames';

import Button from 'components/inputs/basic/Button/Button';
import Popover from 'components/overlay/Popover/Popover';
import PopperOverlay from 'components/overlay/PopperOverlay/PopperOverlay';

export interface OnboardingPopoverProps {
  targetRef: React.RefObject<HTMLElement> | null;
  show: boolean;
  step: number;
  totalSteps: number;
  heading: string;
  imgSrc: string;
  detail: string;
  onNext(): void;
  onPrevious(): void;
  onSkip(): void;
}

export default function OnboardingPopover(props: OnboardingPopoverProps) {
  const { targetRef, show, step, totalSteps, heading, imgSrc, detail, onNext, onPrevious, onSkip } =
    props;

  const content = (
    <div className="max-w-[355px]">
      <div className="f-between">
        <h2 className="text-pri-gray-700 font-medium">{heading}</h2>
        <div className="ml-2 whitespace-nowrap text-sm text-pri-gray-500 font-medium">
          {step} of {totalSteps}
        </div>
      </div>
      <div className="f-center mt-1">
        <img className="max-w-[355px] max-h-[200px]" src={imgSrc} alt={`${heading} graphic`}></img>
      </div>
      <div className="mt-2 text-pri-gray-500">{detail}</div>
      <div className={cn('mt-2 flex', step === totalSteps ? 'justify-end' : 'justify-between')}>
        {step !== totalSteps && (
          <Button variant="lightDullTransparent" size="small" onClick={onSkip} className="-ml-4">
            Skip
          </Button>
        )}
        <div>
          {step > 1 && (
            <Button variant="darkDullAction" size="small" onClick={onPrevious} className="w-[87px]">
              Previous
            </Button>
          )}
          <Button variant="darkDullAction" size="small" onClick={onNext} className="w-[87px] ml-2">
            {step === totalSteps ? 'Done' : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <PopperOverlay
        target={targetRef}
        show={show}
        placement="bottom"
        renderPopper={(renderPopperProps) => {
          return (
            <Popover
              content={content}
              popoverProps={{ className: '', style: { maxWidth: '400px' } }}
              {...renderPopperProps}
            />
          );
        }}
      />
      <div className="opacity-[.29] fixed inset-0 z-[2000] bg-black"></div>
    </>
  );
}
