import React, { useState, useEffect, useCallback, useContext } from 'react';

import { useLocation } from 'react-router';

import { AxiosResponse } from 'axios';
import { uniqBy } from 'lodash';
import qs from 'query-string';

import API from 'api/API';
import { TransformVersion, TransformUserProfile } from 'api/APITypes';
import IconButton from 'components/inputs/basic/Button/IconButton';
import CodeEditor from 'components/inputs/basic/CodeEditor/CodeEditor';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import { SPINNER_MIN_HEIGHT } from 'components/layouts/containers/TabLayout/TabLayout';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import UserHover from 'components/overlay/UserHover/UserHover';
import Alert from 'components/widgets/alerts/Alert/Alert';
import HeaderLabel from 'components/widgets/HeaderLabel/HeaderLabel';
import { useUserProfile } from 'context/AuthContext';
import { usePoll } from 'hooks/useApi';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { TransformTable } from 'pages/tables/ShowTable/ShowTable';
import VersionDiffModal from 'pages/tables/ShowTable/VersionHistoryTab/VersionDiffModal';
import { handleSqlErrors } from 'utils/apiResponseFormatter';
import { formatDate, parseIso } from 'utils/dateTime';

interface VersionHistoryTabProps {
  table: TransformTable;
}

export default function VersionHistoryTab(props: VersionHistoryTabProps) {
  const { table } = props;
  const {
    userProfile: { company, company_role },
  } = useUserProfile();
  const [versionHistoryResp, setVersionHistoryResp] = useState<TransformVersion[]>([]);
  const [error, setError] = useState('');
  const [sqlModalVersion, setSqlModalVersion] = useState<TransformVersion | null>(null);

  const { search } = useLocation();
  const urlSearchParams = qs.parse(search);
  const versionId = urlSearchParams?.version_id;

  const [showDiffModal, setShowDiffModal] = useState(false);
  const [diffVersion, setDiffVersion] = useState<number>(0);
  const [reverting, setReverting] = useState(false);
  const [revertVersionNumber, setRevertVersionNumber] = useState<number | null>(null);
  const { updateTransforms } = useContext(TableModelsContext);

  const {
    data: updatedTransformVersionsData,
    error: pollError,
    isLoading: pollIsLoading,
  } = usePoll<TransformVersion[]>(
    `transformVersionHistory/${table.transform.id}`,
    `/api/transform_versions?transform_id=${table.transform.id}`,
  );

  const handleOpenDiffModal = useCallback((versionNumber: number) => {
    setDiffVersion(versionNumber);
    setShowDiffModal(true);
    analytics.track('VersionHistoryTab OpenDiffModal');
  }, []);

  const handleOpenConfirmRevertVersionModal = (versionNumber: number) => {
    analytics.track('VersionHistoryTab OpenConfirmRevertVersionModal');
    setRevertVersionNumber(versionNumber);
  };

  const handleConfirmRevertVersion = () => {
    setError('');
    const postData = {
      version_number: revertVersionNumber,
    };
    const api = new API();
    setReverting(true);
    api
      .post(`/api/transforms/${table.transform.id}/revert`, postData)
      .then((response: AxiosResponse) => {
        if (response.data) {
          analytics.track('VersionHistoryTab RevertVersion');
          setVersionHistoryResp([response.data.current_version, ...versionHistoryResp]);
          updateTransforms([response.data]);
        }
      })
      .catch((e) => {
        if (e.response?.data) {
          const snapshotNowErrorSlug = e.response.data[0];
          setError(handleSqlErrors(snapshotNowErrorSlug));
        } else {
          setError('An error occured while reverting the SQL for your transform.');
        }
      })
      .finally(() => {
        setRevertVersionNumber(null);
        setReverting(false);
      });
  };

  const handleCancelRevertVersion = () => {
    setRevertVersionNumber(null);
    analytics.track('VersionHistoryTab CancelRevertVersion');
  };

  // If the URL contains a version_id, open that version in the diff modal.
  useEffect(() => {
    if (versionId) {
      handleOpenDiffModal(+versionId);
    }
  }, [versionId, handleOpenDiffModal]);

  useEffect(() => {
    if (updatedTransformVersionsData) {
      setVersionHistoryResp(updatedTransformVersionsData);
    }
  }, [updatedTransformVersionsData]);

  if (pollIsLoading) {
    return <CenteredSpinner containerMinHeight={SPINNER_MIN_HEIGHT} />;
  }

  const handleOpenSqlModal = (version: TransformVersion) => {
    setSqlModalVersion(version);
    analytics.track('VersionHistoryTab OpenSqlModal');
  };

  const handleCloseSqlModal = () => {
    setSqlModalVersion(null);
    analytics.track('VersionHistoryTab OpenSqlModal');
  };

  const handleCloseDiffModal = () => {
    setShowDiffModal(false);
    analytics.track('VersionHistoryTab CloseDiffModal');
  };

  let authorProfiles: TransformUserProfile[] = [];
  if (versionHistoryResp.length > 0) {
    authorProfiles = uniqBy(versionHistoryResp, (version) => version.created_by.user.email).map(
      (version) => version.created_by,
    );
  }

  // If the company didn't transfer their GitHub repo, or the transform version has no commit shas at all, don't show a column for the commit sha.
  const hasRelevantCommitShas =
    company.github_integration && versionHistoryResp.some((version) => version.commit_sha);

  const hasAnyDescriptions = versionHistoryResp.some((version) => version.description);

  if (pollError) {
    return (
      <Alert variant="error" className="m-4">
        There was a problem loading the version history.
      </Alert>
    );
  }
  if (versionHistoryResp.length === 0) {
    return (
      <Alert variant="error" className="m-4">
        Transform {table.name} has no versions.
      </Alert>
    );
  }

  return (
    <>
      {error && (
        <Alert variant="error" className="m-4">
          {error}
        </Alert>
      )}
      {showDiffModal && (
        <VersionDiffModal
          transformName={table.full_name}
          versionHistory={versionHistoryResp}
          handleCloseDiffModal={handleCloseDiffModal}
          initialDiffVersion={diffVersion}
        />
      )}
      {revertVersionNumber !== null && (
        <ConfirmModal
          saving={reverting}
          header={
            <span className="text-lg font-medium">Are you sure you want to revert to this version?</span>
          }
          confirmText="Revert"
          confirmVariant="darkDanger"
          onCancel={handleCancelRevertVersion}
          onConfirm={handleConfirmRevertVersion}
        />
      )}
      {sqlModalVersion !== null && (
        <Modal
          onClose={handleCloseSqlModal}
          fullscreen={true}
          cancelButton={true}
          header={
            <div className="f-row-y-center">
              <HeaderLabel label={`VERSION ${sqlModalVersion.version_number}`} />
              <h1 className="text-xl font-medium ml-2">{table.full_name}</h1>
            </div>
          }
        >
          <div className="h-full p-4">
            <CodeEditor value={sqlModalVersion.sql} height="100%" minHeight="100%" mode="sql" />
          </div>
        </Modal>
      )}
      <div className="p-4 mb-[54px]">
        {/*
            Experimental Idea:
            Only show this when the number of versions is cumbersome for the user to read.
            See thread for stats: https://mozartdata.slack.com/archives/C012LKRM3S8/p1644863550657409
          */}
        {versionHistoryResp.length >= 10 && authorProfiles.length >= 2 && (
          <div className="f-row-y-center" style={{ padding: '0.75rem' }}>
            <strong className="mr-2">Authors:</strong>
            <div className="f-row-y-center">
              {authorProfiles.map((a, i) => (
                <div key={a.user.email}>
                  <UserHover userProfile={a} />
                  {i < authorProfiles.length - 1 && <span className="mr-2">,</span>}
                </div>
              ))}
            </div>
          </div>
        )}
        <table className="blueGrayHeaderTable">
          <thead>
            <tr className="whitespace-nowrap">
              <th className="text-right">Version Number</th>
              <th>Created At</th>
              <th>Created By</th>
              <th>Email</th>
              {hasAnyDescriptions && <th>Description</th>}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {versionHistoryResp.map((version) => {
              // Pick which optional fields should be shown in the last column.
              const diffVersion = version.version_number !== 0;
              const showRevert =
                version.version_number !== 0 &&
                version.version_number !== table.transform.current_version.version_number &&
                company_role !== 'viewer';

              // Set these to the width of the option fields in the last column when they are shown.
              // This insures that the 'N/A' is centered.
              const diffWidth = 'w-[67.5px]';
              const revertWidth = 'w-[89.5px]';
              const commitShaWidth = 'w-[94.5px]';

              return (
                <tr key={version.id}>
                  <td className="text-right">{version.version_number}</td>
                  <td>{formatDate(parseIso(version.created_at))}</td>
                  <td>
                    <UserHover userProfile={version.created_by} />
                  </td>
                  <td>{version.created_by.user.email}</td>
                  {hasAnyDescriptions && (
                    <td>
                      {version.description ? (
                        version.description
                      ) : (
                        <span className="text-pri-gray-400">None</span>
                      )}
                    </td>
                  )}
                  <td>
                    <div className="f-row-y-center">
                      <IconButton
                        icon="Server"
                        text="SQL"
                        size="small"
                        variant="lightDullTransparent"
                        onClick={() => handleOpenSqlModal(version)}
                      />
                      {diffVersion ? (
                        <IconButton
                          icon="LayoutSplit"
                          text="DIFF"
                          size="small"
                          variant="lightDullTransparent"
                          onClick={() => handleOpenDiffModal(version.version_number)}
                        />
                      ) : (
                        notApplicable(diffWidth)
                      )}
                      {showRevert ? (
                        <IconButton
                          icon="LayoutSplit"
                          text="REVERT"
                          size="small"
                          variant="lightDullTransparent"
                          onClick={() => handleOpenConfirmRevertVersionModal(version.version_number)}
                        />
                      ) : (
                        notApplicable(revertWidth)
                      )}
                      {version.commit_sha && company.github_integration ? (
                        <a
                          href={`https://github.com/${company.github_integration.repo_owner}/${company.github_integration.repo_name}/commit/${version.commit_sha}`}
                          data-track="TransformVersions CommitLinkClick"
                          target="blank"
                        >
                          <IconButton
                            icon="Github"
                            text={version.commit_sha.slice(0, 7)}
                            size="small"
                            variant="lightDullTransparent"
                            noClickGuard
                          />
                        </a>
                      ) : hasRelevantCommitShas ? (
                        notApplicable(commitShaWidth)
                      ) : null}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

const notApplicable = (widthClass: string) => (
  <div className={`${widthClass} f-center text-pri-gray-400`}>N/A</div>
);
