import React, { useState } from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { AggTable } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { DataAlertWithTable, SavableDataAlertProps } from 'hooks/useDataAlerts';

import DataAlertSqlLayout from './DataAlertSqlLayout';

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Name is required')
    .trim()
    .max(255, 'Name cannot be longer than 255 characters.'),
});

interface RenameDataAlertProps {
  name: string;
}

interface EditDataAlertModalProps {
  table: AggTable;
  dataAlert: DataAlertWithTable;
  saving: boolean;
  updateErrorMessage: string;
  showConfirmModal: boolean;
  setUpdateErrorMessage: (newError: string) => void;
  onCloseDataAlertModal: () => void;
  onUpdateDataAlert: (
    dataAlert: DataAlertWithTable,
    savableProps: SavableDataAlertProps,
  ) => Promise<DataAlertWithTable>;
  onSetUnsavedSql: (unsaved: boolean) => void;
  onCloseConfirmModal: () => void;
  onConfirmConfirmModal: () => void;
}

export default function EditDataAlertModal(props: EditDataAlertModalProps) {
  const {
    table,
    dataAlert,
    saving,
    updateErrorMessage,
    showConfirmModal,
    setUpdateErrorMessage,
    onCloseDataAlertModal,
    onUpdateDataAlert,
    onSetUnsavedSql,
    onCloseConfirmModal,
    onConfirmConfirmModal,
  } = props;
  const [editorOpened, setEditorOpened] = useState(false);

  const handleOpenEditor = () => {
    analytics.track('TableTestTab OpenEditTableTestName');
    setEditorOpened(true);
  };

  const handleCancelEdit = () => {
    analytics.track('TableTestTab CancelEditTableTestName');
    setEditorOpened(false);
    setUpdateErrorMessage('');
  };

  const handleSaveName = (values: RenameDataAlertProps) => {
    const transformValues = validationSchema.cast(values);
    setUpdateErrorMessage('');
    onUpdateDataAlert(dataAlert, { name: transformValues.name })
      .then((newDataAlert) => {
        setEditorOpened(false);
      })
      .catch(() => {
        // Error handled in onUpdateDataAlert
      });
  };

  let modalHeader = (
    <h1 className="f-row-y-center text-xl font-medium">
      {table.full_name} -{' '}
      <div
        className="px-2 cursor-pointer rounded hover:bg-pri-gray-100 active:bg-pri-gray-200"
        onClick={handleOpenEditor}
      >
        {dataAlert.name}
      </div>
    </h1>
  );

  if (editorOpened) {
    modalHeader = (
      <Formik
        validationSchema={validationSchema}
        onSubmit={handleSaveName}
        onReset={handleCancelEdit}
        initialValues={{ name: dataAlert.name }}
      >
        {({ handleSubmit, handleChange, handleReset }) => {
          return (
            <form noValidate onSubmit={handleSubmit} onReset={handleReset}>
              <div className="f-row-y-center">
                <h1 className="text-xl font-medium">{table.full_name} - </h1>
                <TextFormikGroup
                  name="name"
                  placeholder="Data Alert Name"
                  onChange={handleChange}
                  groupClass="ml-2 w-25"
                />
                <div className="f-row-y-center ml-1">
                  <Button variant="lightAction" type="submit" className="ml-2" spinning={saving}>
                    Save
                  </Button>
                  <Button variant="lightDanger" onClick={handleCancelEdit} className="ml-2">
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }

  return (
    <>
      <Modal fullscreen={true} onClose={onCloseDataAlertModal} header={modalHeader} cancelButton={true}>
        <div className="h-full flex flex-col">
          {updateErrorMessage && (
            <Alert className="mx-4 my-2" variant="error">
              {updateErrorMessage}
            </Alert>
          )}
          <DataAlertSqlLayout
            dataAlert={dataAlert}
            table={table}
            saving={saving}
            createMode={false}
            saveDataAlert={onUpdateDataAlert}
            setUnsavedSql={onSetUnsavedSql}
          />
        </div>
      </Modal>
      {showConfirmModal && (
        <ConfirmModal
          header="You have unsaved SQL. Are you sure you want to exit?"
          confirmText="Confirm"
          confirmVariant="darkDanger"
          onCancel={onCloseConfirmModal}
          onConfirm={onConfirmConfirmModal}
        />
      )}
    </>
  );
}
