import React from 'react';

import Button from 'components/inputs/basic/Button/Button';
import { useUserProfile } from 'context/AuthContext';

export default function GitHubIntegration() {
  const { userProfile } = useUserProfile();

  const githubIntegration = userProfile.company.github_integration;
  const githubOwner = githubIntegration?.repo_owner;
  const githubRepo = githubIntegration?.repo_name;
  const link = githubIntegration
    ? `https://github.com/${githubOwner}/${githubRepo}`
    : 'https://help.mozartdata.com/docs/using-mozart-data-with-github';
  const text = githubIntegration ? 'Connected' : 'Store and manage your transforms in GitHub.';
  const buttonText = githubIntegration ? 'Go to Linked Repo' : 'Learn More';
  const dataTrack = githubIntegration
    ? 'CompanySettings VisitGitHubRepo'
    : 'CompanySettings GitHubLearnMore';

  return (
    <div className="f-between" style={{ borderBottom: '1px solid #dee2e6', padding: '0.75rem' }}>
      <div className="f-row-y-center">
        <img alt="" height="25" width="100" src="/images/settings/github_wide.png" />
        <div className="ml-4 text-pri-gray-500 text-[1.25rem]">{text}</div>
      </div>
      <a href={link} data-track={dataTrack} target="blank">
        <Button noClickGuard>{buttonText}</Button>
      </a>
    </div>
  );
}
