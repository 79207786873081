/**
 * This file converts FlowchartExpressions into SQLGlotExpressions.
 * The SQLGlotExpressions are then submitted to the API for conversion to SQL.
 */
import { AggTable } from 'api/tableAPI';

import {
  TableColumn,
  FlowchartQueryModel,
  getVertex,
  MonoParentVertex,
  SourceTable,
  FlowchartVertex,
} from '../model/FlowchartQueryModel';

import { getDefiningVertex } from './columnUtils';
import { CTEName, FlowchartExpression } from './FlowchartExpression';
import {
  Identifier,
  Column,
  Expression,
  Star,
  From,
  Select,
  SelectExpression,
  Table,
  ComparisonClass,
  Comparison,
  Join,
  Alias,
  TableAlias,
  CTE,
  With,
} from './SQLGlotExpression';

/*******************************************************************************
 * Complex compound and recursive expressions logic
 ******************************************************************************/
export function buildGlotExpression(
  fqm: FlowchartQueryModel,
  rootExpression: FlowchartExpression,
  allTableAliasesByVertexID: { [vertexID: string]: string } = {},
  expressionsByVertexID: { [vertexID: string]: FlowchartExpression } = {},
): Select {
  const { select, from, join, ctes } = rootExpression;
  // eslint-disable-next-line no-console
  // console.log('buildGlotExpression.rootExpression', rootExpression);

  if (from && join) {
    // This is a programmer error.
    // Only one of these should define the from table at a given time.
    throw new Error(ERROR_FROM_AND_JOIN);
  }

  if (!from && !join) {
    throw new Error(ERROR_NO_FROM);
  }

  let _from: From | undefined = undefined;
  let _join: Join | undefined = undefined;
  let _with: With | undefined = undefined;
  // Only the aliases of the tables in the root select
  const rootTableAliasesByVertexID: { [vertexID: string]: string } = {};
  expressionsByVertexID[rootExpression.rootVertex.id] = rootExpression;

  if (ctes && ctes.length > 0) {
    const cteExpressions: Select[] = [];
    const cteAliases: CTEName[] = [];
    ctes.forEach((cte, i) => {
      cteExpressions.push(
        buildGlotExpression(fqm, cte, allTableAliasesByVertexID, expressionsByVertexID),
      );
      if (cte.fullNameOrCTEName === undefined) {
        throw new Error(ERROR_MISSING_CTE_ALIAS);
      }
      cteAliases.push(cte.fullNameOrCTEName);
      allTableAliasesByVertexID[cte.rootVertex?.id || `UNKNOWN_CTE_ROOT_ID_${i}`] =
        cte.fullNameOrCTEName;
    });

    _with = getWith(cteExpressions, cteAliases);
  }

  if (from) {
    if (typeof from === 'object') {
      if (from.table === null) {
        throw new Error(ERROR_NO_FROM_TABLE);
      }
      _from = getFrom(from.table);
      rootTableAliasesByVertexID[from.id] = from.alias as string;
    } else {
      _from = getFrom(from);
    }
  }

  let selectedColumns = select?.selectedColumns;

  if (join) {
    const {
      table: leftTable,
      alias: leftAlias,
      vertexID: leftVertexID,
    } = getTableNames(fqm, expressionsByVertexID, join.leftParentID, ERROR_JOIN_SET_LEFT_TABLE);
    const {
      table: rightTable,
      alias: rightAlias,
      vertexID: rightVertexID,
    } = getTableNames(fqm, expressionsByVertexID, join.rightParentID, ERROR_JOIN_SET_RIGHT_TABLE);

    _from = getFrom(leftTable, leftAlias);
    _join = getJoin(rightTable, leftAlias, rightAlias, join.leftColumn, join.rightColumn);
    rootTableAliasesByVertexID[leftVertexID] = leftAlias;
    rootTableAliasesByVertexID[rightVertexID] = rightAlias;
    allTableAliasesByVertexID[leftVertexID] = leftAlias;
    allTableAliasesByVertexID[rightVertexID] = rightAlias;
    selectedColumns = join.selectedColumns;
  }

  // A join could join on the same table in which case the key count would be 1.
  // So both checks are necessary.
  const aliasExpressionColumns = !!_join || Object.keys(rootTableAliasesByVertexID).length > 1;

  const expressions = getExpressions(
    fqm,
    rootExpression.rootVertex,
    selectedColumns,
    Object.values(rootTableAliasesByVertexID),
    allTableAliasesByVertexID,
    aliasExpressionColumns,
  );

  const result: Select = {
    class: 'Select',
    args: {
      expressions,
      from: _from as From,
    },
  };

  if (_join) {
    result.args.joins = [_join];
  }

  if (_with) {
    result.args.with = _with;
  }

  return result;
}

interface TableNameProps {
  table: AggTable | string;
  alias: string;
  vertexID: string;
}

export function getSourceTableNames(sourceTable: SourceTable, errorMessage: string): TableNameProps {
  if (sourceTable.table === null) {
    throw new Error(errorMessage);
  }
  if (sourceTable.alias === null) {
    throw new Error(errorMessage);
  }
  return {
    table: sourceTable.table,
    alias: sourceTable.alias,
    vertexID: sourceTable.id,
  };
}

export function getTableNames(
  fqm: FlowchartQueryModel,
  expressionsByVertexID: { [vertexID: string]: FlowchartExpression },
  parentID: string | null,
  errorMessage: string,
): TableNameProps {
  if (parentID === null) {
    throw new Error(errorMessage);
  }
  const parent = getVertex(fqm, parentID);
  if (parent.type === 'source_table') {
    return getSourceTableNames(parent as SourceTable, errorMessage);
  } else {
    // Any previous node that specifies its own columns should generate an expression.
    const parentExpression = expressionsByVertexID[parentID];
    if (parentExpression) {
      // Let's see how long it takes someone to complain about aliasing a CTE table to its own name.
      // This should be valid and I don't want to bother writing code to omit it.
      return {
        table: parentExpression.fullNameOrCTEName as string,
        alias: parentExpression.fullNameOrCTEName as string,
        vertexID: parent.id,
      };
    }
    // Recurse up the tree of ancestors until we hit a vertex that defines its own columns
    // or we hit a source table.
    // Note: BiParent vertices must always create an expression because they fork in two directions.
    // So we do not check for them here.
    else if (parent.isMonoParent()) {
      const nextParent = getVertex(fqm, (parent as MonoParentVertex).singleParentID as string);
      if (nextParent) {
        return getTableNames(fqm, expressionsByVertexID, nextParent.id, errorMessage);
      }
    }
  }

  // We should never get here. This represents unimplemented code paths.
  return {
    table: 'CODE_NOT_IMPLEMENTED',
    alias: 'CODE_NOT_IMPLEMENTED',
    vertexID: 'CODE_NOT_IMPLEMENTED',
  };
}

export interface TableAliasColumnValue extends TableColumn {
  tableAlias?: string;
}

export function getExpressions(
  fqm: FlowchartQueryModel,
  rootVertex: FlowchartVertex,
  selectedColumns: TableColumn[] | undefined,
  rootTableAliases: string[],
  allTableAliasesByVertexID: { [vertexID: string]: string },
  aliasExpressionColumns: boolean,
): SelectExpression[] {
  // Default to *
  let expressions: SelectExpression[] = [getStar()];

  // Prefix joined *s with aliases
  if (rootTableAliases.length > 1) {
    expressions = rootTableAliases.map((tn) => getStarColumn(tn));
  }

  // The user selected specific columns and/or column order
  if (!!selectedColumns) {
    expressions = selectedColumns
      .map((sc) => ({
        ...sc,
        tableAlias: aliasExpressionColumns
          ? getAliasFrom(fqm, rootVertex, sc, allTableAliasesByVertexID)
          : undefined,
      }))
      .filter((sc) => sc.picked)
      .map((sc) => getAliasOrColumn(sc));
  }

  return expressions;
}

export function getAliasFrom(
  fqm: FlowchartQueryModel,
  fromVertex: FlowchartVertex,
  tableColumn: TableColumn,
  allTableAliasesByVertexID: { [vertexID: string]: string },
): string {
  let alias = 'UNKNOWN';
  const { name, socket } = tableColumn;
  const parentID = fromVertex.socketToParentID(socket);
  const parentVertex = getVertex(fqm, parentID as string);
  const definingVertex = getDefiningVertex(fqm, parentVertex, name);
  if (definingVertex) {
    const definedName = allTableAliasesByVertexID[definingVertex.id];
    if (definedName) {
      alias = definedName;
    }
  }

  return alias;
}
/*******************************************************************************
 * Simple GLOT building blocks
 ******************************************************************************/
export function getFrom(table: AggTable | string, tableAlias?: string): From {
  return {
    class: 'From',
    args: {
      this: getTable(table, tableAlias),
    },
  };
}

export function getTable(table: AggTable | string, tableAlias?: string): Table {
  const args =
    typeof table === 'object'
      ? {
          this: getIdentifier(table.name, false),
          db: getIdentifier(table.schema, false),
        }
      : {
          this: getIdentifier(table, false),
        };

  const result: Table = {
    class: 'Table',
    args,
  };

  if (tableAlias) {
    result.args.alias = getTableAlias(tableAlias);
  }

  return result;
}

export function getTableAlias(tableAlias: string): TableAlias {
  return {
    class: 'TableAlias',
    args: { this: getIdentifier(tableAlias, false) },
  };
}

export function getStar(): Star {
  return {
    class: 'Star',
    args: {},
  };
}

export function getStarColumn(alias: string): Column {
  return {
    class: 'Column',
    args: {
      this: getStar(),
      table: getIdentifier(alias, false),
    },
  };
}

export function getColumn(value: string, quoted: boolean, tableAlias?: string): Column {
  const column: Column = {
    class: 'Column',
    args: {
      this: getIdentifier(value, quoted),
    },
  };

  if (tableAlias) {
    column.args.table = getIdentifier(tableAlias, false);
  }

  return column;
}

export function getAliasOrColumn(cv: TableAliasColumnValue): Column | Alias {
  const column = getColumn(cv.name, false, cv.tableAlias);

  if (cv.alias) {
    const alias: Alias = {
      class: 'Alias',
      args: {
        this: column,
        alias: getIdentifier(cv.alias, false),
      },
    };
    return alias;
  }
  return column;
}

export function getIdentifier(name: string, quoted: boolean): Identifier {
  return {
    class: 'Identifier',
    args: {
      this: name,
      quoted,
    },
  };
}

export function getComparison(
  comparator: ComparisonClass,
  leftSide: Expression,
  rightSide: Expression,
): Comparison {
  return {
    class: comparator,
    args: {
      this: leftSide,
      expression: rightSide,
    },
  };
}

export function getJoin(
  rightTable: AggTable | string,
  leftTableAlias: string,
  rightTableAlias: string,
  leftColumn: string,
  rightColumn: string,
): Join {
  return {
    class: 'Join',
    args: {
      this: getTable(rightTable, rightTableAlias), // The table after `on`, not the table after `from`
      on: getComparison(
        'EQ',
        getColumn(leftColumn, false, leftTableAlias),
        getColumn(rightColumn, false, rightTableAlias),
      ),
    },
  };
}

export function getWith(cteExpressionContents: Select[], cteAliases: CTEName[]): With {
  return {
    class: 'With',
    args: {
      expressions: cteExpressionContents.map((e, i) => getCTE(e, cteAliases[i])),
    },
  };
}

export function getCTE(expression: Select, alias: CTEName): CTE {
  return {
    class: 'CTE',
    args: {
      this: expression,
      alias: getTableAlias(alias),
    },
  };
}

/*******************************************************************************
 * Error Messages
 ******************************************************************************/
export const ERROR_NO_FROM = 'You must set from.';
export const ERROR_NO_FROM_TABLE = 'You must set the table on your from.';
export const ERROR_FROM_AND_JOIN = 'From and join not supported.';
export const ERROR_JOIN_SET_LEFT_TABLE = 'You must set the left table of a join.';
export const ERROR_JOIN_SET_RIGHT_TABLE = 'You must set the right table of a join.';
export const ERROR_MISSING_CTE_ALIAS = 'You must set the CTE alias.';
