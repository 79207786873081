/*
The data table used by RunResults and TableExpando.

This component coordinates:
  1. The scrollable data cells
  2. Sticky headers
  3. Sticky row indexes
  4. Data cell click events 
*/
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { throttle } from 'lodash';

import { DatabaseType, QueryRunResults } from 'api/APITypes';
import { Column } from 'api/columnAPI';
import CodeModal from 'components/layouts/containers/modals/CodeModal/CodeModal';
import { useDatabaseAccount } from 'context/AuthContext';
import { convertColumnName } from 'utils/dbName';

import { TimeFormat } from './RunResults';
import ScrollableDataTable from './ScrollableDataTable';
import { findColumnIndexes } from './tableHelpers';

import rt from 'components/tables/ResultsTable/ResultsTable.module.css';

const DEFAULT_CORNER_WIDTH = 50;
const DEFAULT_CORNER_HEIGHT = 29;

export const SNOWFLAKE_NUMBER_TYPES: string[] = [
  'NUMBER',
  'DECIMAL',
  'NUMERIC',
  'INT',
  'INTEGER',
  'BIGINT',
  'SMALLINT',
  'TINYINT',
  'BYTEINT',
  'FLOAT',
  'FLOAT4',
  'FLOAT8',
  'DOUBLE',
  'DOUBLE PRECISION',
  'REAL',
];

type SelectedColumnMap = { [key: string]: boolean };

interface RunResultsTableProps extends QueryRunResults {
  timeFormat: TimeFormat;
  maxLines: number;
  hideEmptyColumns: boolean;
  selectedColumns: string[];
  hideUnselectedColumns: boolean;
  hideBorder?: boolean;
  isShown: boolean;
  onToggleColumn: (column: string) => void;
  onToggleAllColumns: () => void;
}

interface SelectedCell {
  header: string;
  body: string;
}

export default function RunResultsTable(props: RunResultsTableProps) {
  // The sticky header and sticky row indexes are always on the top and left side
  // of the RunResultsTable respectively no matter how the user scrolls.
  // They move independtly of the data table scrolling,
  // so they are different tables with the same styling and
  // we need refs to each of them.
  const runResultsRef = useRef<HTMLDivElement>(null); // The entire component, which can change size
  const scrollRef = useRef<HTMLDivElement>(null); // The scrollable area that contains the data table(dataRef)
  const dataRef = useRef<HTMLTableElement>(null); // The table that contains the data cells
  const headerRef = useRef<HTMLTableElement>(null); // The table that contains the column headers
  const indexesRef = useRef<HTMLTableElement>(null); // The table that contains the indexes to the left of each data row
  const cornerRef = useRef<HTMLTableElement>(null); // The corner between the headers and indexes
  const {
    rows,
    columns,
    timeFormat,
    maxLines,
    hideEmptyColumns,
    selectedColumns,
    hideUnselectedColumns,
    hideBorder,
    isShown,
    onToggleColumn,
    onToggleAllColumns,
  } = props;
  const databaseType = useDatabaseAccount().type;

  // We cannot know the sizes of certain objects until after the component
  // renders once. User's might set their browser zoom to a non-default level
  // thereby changing the size of fonts thereby changing the size of
  // the sticky header and sticky index.
  const [scrollWidth, setScrollWidth] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [cornerCellWidth, setCornerCellWidth] = useState(0);
  const [cornerCellHeight, setCornerCellHeight] = useState(0);
  const [totalColumnWidth, setTotalColumnWidth] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [rowHeight, setRowHeight] = useState(0);
  // columnWidths.length will be set to columnIndexes.length which can be shorter than columns.length
  const [columnWidths, setColumnWidths] = useState<number[]>([]);

  const [selectedCell, setSelectedCell] = useState<SelectedCell | null>(null); // Shows a modal of long cell data
  const [selectedRow, setSelectedRow] = useState<number>(-1); // Stores index of selected row for highlighting

  const handleSelectRow = useCallback(
    (index: number) => {
      analytics.track('RunResults SelectRow');
      setSelectedRow(selectedRow === index ? -1 : index);
    },
    [selectedRow, setSelectedRow],
  );

  // This is the list of columnIndexes to render.
  // By default it's all columns.
  // The user may filter out columns to render, such as columns with no data in them.
  const columnIndexes = useMemo(() => {
    return findColumnIndexes(rows, columns, hideEmptyColumns, selectedColumns, hideUnselectedColumns);
  }, [rows, columns, hideEmptyColumns, selectedColumns, hideUnselectedColumns]);

  const selectedColumnMap = useMemo(() => {
    const map: SelectedColumnMap = {};
    if (selectedColumns) {
      selectedColumns.forEach((c) => {
        map[c] = true;
      });
    }
    return map;
  }, [selectedColumns]);

  const onEditorResize = useCallback(() => {
    if (!runResultsRef?.current || !cornerRef?.current) {
      return;
    }
    // @ts-ignore
    const runResultsWidth = runResultsRef.current.offsetWidth;
    // @ts-ignore
    const runResultsHeight = runResultsRef.current.offsetHeight;
    // @ts-ignore
    const cornerWidth = cornerRef.current.offsetWidth;
    // @ts-ignore
    const cornerHeight = cornerRef.current.offsetHeight;

    setScrollWidth(runResultsWidth - cornerWidth);
    setScrollHeight(runResultsHeight - cornerHeight);
  }, [setScrollWidth, setScrollHeight]);

  // The Query page's panes can be resized by the user.
  // Listen for changes in size of the RunResultsTable.
  useEffect(() => {
    if (runResultsRef !== null && runResultsRef.current !== null) {
      const node = runResultsRef.current;
      const resizeObserver = new ResizeObserver(
        // Mouse resize events happen quicker than the browser can rerender.
        // Throttle the resizes so that the UI remains snappy and does not throw errors.
        // DEBUGGING NOTE:
        // If a user very smoothly and continuously drags the resize bar,
        // Chrome tends to not to call the ResizeObserver until the user
        // temporarily pauses their resizing.
        // Firefox will reliable call ResizeObserver the whole time the user drags.
        // Anyway, this may make the resize not redraw until the user stops dragging
        // and if done just right this can prevent redraws for much longer than the React
        // Render loop or this `throttle` statement.
        throttle(onEditorResize, 33),
      );

      resizeObserver.observe(node);
      return () => {
        resizeObserver.unobserve(node);
      };
    }
  }, [runResultsRef, onEditorResize]);

  /*
  On first layout, the headers, indexes, and results establish their relative sizes.
  We cannot know their sizes until the first layout because the user
  can set their browser font size which changes the height of the header row
  and the width of the index column.
  This method is responsible for positioning them relative to each other after
  their dimensions have been established.

  Warning:
  This method does all DOM reads before all DOM writes to prevent unnecessary 
  browser layouts.
  
  Warning:
  The width of a cell and the table(with no padding, margine, or border) are
  not neccesarily the same when a browser calculates layout, well maybe,
  not totally sure about this. Be skeptical.
  */
  useLayoutEffect(() => {
    if (
      scrollRef.current !== null &&
      dataRef.current !== null &&
      headerRef.current !== null &&
      indexesRef.current !== null &&
      cornerRef.current !== null &&
      isShown
    ) {
      // @ts-ignore
      const currentSR = scrollRef.current;
      // @ts-ignore
      const rowTable = dataRef.current;
      // @ts-ignore
      const headerTable = headerRef.current;
      // @ts-ignore
      const headerCells = headerTable.firstChild.firstChild.children;
      // WARNING: This will be undefined if the query returns no rows
      // @ts-ignore
      const firstRowCells = rowTable.firstChild?.firstChild?.children;

      // If the first run of useLayoutEffect() has already set the table widths,
      // unset them before summing column widths to get the new table width.
      // If the data changes the auto calculated table width will change.
      if (headerTable.style.width) {
        // @ts-ignore
        headerTable.style.setProperty('width', null);

        for (let i = 0; i < headerCells.length; i++) {
          headerCells[i].style.setProperty('width', null);
        }

        // @ts-ignore
        rowTable.style.setProperty('width', null);

        if (firstRowCells) {
          for (let i = 0; i < firstRowCells.length; i++) {
            firstRowCells[i].style.setProperty('width', null);
          }
        }
      }

      // Read max width of each (header cell / first row cell) pair
      // at one time and save them to a list.
      let cellWidthSum = 0;
      const hasResults = rows.length > 0;
      const cellWidths = [];
      for (let i = 0; i < headerCells.length; i++) {
        const dataCellWidth = hasResults && firstRowCells ? firstRowCells[i].offsetWidth : 0;
        const headerCellWidth = headerCells[i].offsetWidth;
        let maxCellWidth = Math.max(headerCellWidth, dataCellWidth);
        // Content that is wider in one cell than the other can cause off by one pixel
        // errors. Add another pixel to max to prevent this.
        maxCellWidth += 1;
        cellWidths.push(maxCellWidth);
        cellWidthSum += maxCellWidth;
      }

      // Read more values before we trigger browser reflows
      // @ts-ignore
      const runResultsWidth = runResultsRef.current.offsetWidth;
      // @ts-ignore
      const runResultsHeight = runResultsRef.current.offsetHeight;
      // The layout width can be fractional
      // offsetWidth returns height rounded to the nearest integer
      // getBoundingClientRect returns a float
      // @ts-ignore
      const firstIndexTD = indexesRef?.current?.firstChild?.firstChild?.firstChild;
      // @ts-ignore
      const firstIndexTDRect = firstIndexTD?.getBoundingClientRect();

      // @ts-ignore
      let cornerCellWidth = firstIndexTDRect?.width || DEFAULT_CORNER_WIDTH;

      // Layout heights are fixed to integer by CSS.
      // @ts-ignore
      const cornerCellHeight = headerCells[0]?.offsetHeight || DEFAULT_CORNER_HEIGHT;
      // The corner will be one pixel wider and taller than the measured cell height.
      // However, we want to displace the other elements at minus one pixel
      // so the borders overlap. So this works out.
      let cornerDisplacementWidth = cornerCellWidth;
      let cornerDisplacementHeight = cornerCellHeight;

      // @ts-ignore
      const boundingRowHeight = firstIndexTDRect?.height || DEFAULT_CORNER_HEIGHT;

      // Now that we have read all the current values, start writing values.
      // All widths and heights are set by react state so we can rerender
      // without fear of losing dimensions.
      // Translates are done here since, their value is based on the ephemeral state of scroll.
      // The `onScroll` method will make future updates to Translates.

      // Offset indexes by header's height
      // @ts-ignore
      indexesRef.current.style.setProperty(
        'transform',
        `translateY(${currentSR.scrollTop * -1 + cornerDisplacementHeight}px)`,
      );

      // Offset header by index's width
      // @ts-ignore
      headerTable.style.setProperty(
        'transform',
        `translateX(${currentSR.scrollLeft * -1 + cornerDisplacementWidth}px)`,
      );

      // Offset scrollable area by indexes's width and header's height
      // @ts-ignore
      currentSR.style.setProperty(
        'transform',
        `translate(${cornerDisplacementWidth}px, ${cornerDisplacementHeight}px)`,
      );

      setScrollWidth(runResultsWidth - cornerDisplacementWidth);
      setScrollHeight(runResultsHeight - cornerDisplacementHeight);
      setCornerCellWidth(cornerCellWidth);
      setCornerCellHeight(cornerCellHeight);
      setTotalColumnWidth(cellWidthSum);
      setColumnWidths(cellWidths);
      setHeaderHeight(cornerCellHeight + 1);
      setRowHeight(boundingRowHeight);
    }
  }, [rows, columns, columnIndexes, timeFormat, maxLines, isShown]);

  const onScroll = useCallback((xOffset: number, yOffset: number) => {
    // Do everything relative to corner at this point.
    if (cornerRef.current !== null && headerRef.current !== null && indexesRef.current !== null) {
      // @ts-ignore
      const cornerBox = cornerRef.current.getBoundingClientRect();
      // Scroll Header
      let cornerDisplacementWidth = cornerBox.width;
      cornerDisplacementWidth -= 1;
      headerRef.current.style.transform = `translateX(${xOffset * -1 + cornerDisplacementWidth}px)`;

      // Scroll Indexes
      let headerDisplacementHeight = cornerBox.height;
      headerDisplacementHeight -= 1;
      indexesRef.current.style.transform = `translateY(${yOffset * -1 + headerDisplacementHeight}px)`;
    }
  }, []);

  const memoizedCorner = useMemo<React.ReactNode>(() => {
    const cellStyle: React.CSSProperties = {};
    if (cornerCellWidth) {
      cellStyle.width = `${cornerCellWidth}px`;
    }
    if (cornerCellHeight) {
      cellStyle.height = `${cornerCellHeight}px`;
    }
    return (
      <table ref={cornerRef} className={cn(rt.resultsTable, rt.resultsTableCorner)}>
        <thead>
          <tr>
            <th onClick={onToggleAllColumns} style={cellStyle}></th>
          </tr>
        </thead>
      </table>
    );
  }, [cornerRef, cornerCellWidth, cornerCellHeight, onToggleAllColumns]);

  const memoizedHeaders = useMemo<React.ReactNode>(
    () =>
      Headers(
        headerRef,
        columns,
        columnIndexes,
        columnWidths,
        totalColumnWidth,
        selectedColumnMap,
        onToggleColumn,
        databaseType,
      ),
    [
      headerRef,
      columns,
      columnIndexes,
      columnWidths,
      totalColumnWidth,
      selectedColumnMap,
      onToggleColumn,
      databaseType,
    ],
  );

  const memoizedIndexes = useMemo<React.ReactNode>(
    () => Indexes(indexesRef, rows, maxLines, handleSelectRow),
    [indexesRef, rows, maxLines, handleSelectRow],
  );

  // Single clicks handle selecting rows. Double clicks handle opening the cell modal
  const handleTableClick = useCallback(
    (event: React.MouseEvent<HTMLTableElement>) => {
      // Table cells are always a <div> inside a <td>.
      // The click handler will return either depending on if you click on the
      // content(<div>) or the td padding.
      let clickedElement = event.target as HTMLTableCellElement;
      let td;
      let innerDiv;

      // We clicked on the div inside the td
      if (clickedElement.nodeName === 'DIV') {
        td = clickedElement.parentElement as HTMLTableCellElement;
        innerDiv = clickedElement as HTMLDivElement;
      } else {
        td = clickedElement;
        innerDiv = td.firstChild as HTMLDivElement;
      }

      // @ts-ignore
      const rowIndex = td.parentElement.rowIndex;

      // Single Click
      if (event.detail === 1) {
        analytics.track('RunResults SelectRow');
        handleSelectRow(rowIndex);
      }
      if (event.detail === 2) {
        const overflowsTD =
          innerDiv.scrollHeight > td.clientHeight || innerDiv.scrollWidth > td.clientWidth;
        // Only open the modal when the content is larger than the table cell
        if (!overflowsTD) {
          return;
        }

        analytics.track('RunResults OpenCell');

        // @ts-ignore
        const filteredColumnIndex = td.cellIndex;
        const absoluteColumnIndex = columnIndexes[filteredColumnIndex];

        setSelectedCell({
          header: `Row ${rowIndex + 1}, ${columns[absoluteColumnIndex].name}`,
          body: (td.firstChild as HTMLDivElement).textContent || '',
        });
      }
    },
    [columnIndexes, columns, handleSelectRow],
  );

  const handleCloseCellModal = () => {
    setSelectedCell(null);
  };

  // If we get a new set of columnIndexes ignore the columnWidths saved in state, so
  // useLayoutEffect can compute fresh widths
  const freshWidths = columnWidths.length === columnIndexes.length ? columnWidths : [];

  return (
    <div ref={runResultsRef} className={cn('text-xs ', rt.runResults, { [rt.hideBorder]: hideBorder })}>
      {memoizedCorner}
      {memoizedHeaders}
      {memoizedIndexes}

      <ScrollableDataTable
        scrollRef={scrollRef}
        dataRef={dataRef}
        scrollWidth={scrollWidth}
        scrollHeight={scrollHeight}
        totalColumnWidth={totalColumnWidth}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        rows={rows}
        columns={columns}
        columnIndexes={columnIndexes}
        columnWidths={freshWidths}
        timeFormat={timeFormat}
        maxLines={maxLines}
        selectedRowIndex={selectedRow}
        onTableClick={handleTableClick}
        onScroll={onScroll}
      />

      {selectedCell && (
        <CodeModal
          header={selectedCell.header}
          code={selectedCell.body}
          onClose={handleCloseCellModal}
        />
      )}
    </div>
  );
}

const Headers = (
  headerRef: React.RefObject<HTMLTableElement>,
  columns: Column[],
  columnIndexes: number[],
  columnWidths: number[],
  totalColumnWidth: number,
  selectedColumnsMap: SelectedColumnMap,
  onToggleColumn: (column: string) => void,
  databaseType: DatabaseType,
) => {
  const hasCellWidths = columnWidths.length > 0;

  const tableStyle: React.CSSProperties = {};
  // Do not override current transform
  if (headerRef?.current?.style.transform) {
    tableStyle.transform = headerRef?.current?.style.transform;
  }

  if (totalColumnWidth) {
    const updateHack = Math.random() / 10000;
    tableStyle.width = `${totalColumnWidth + updateHack}px`;
  }

  const onNameClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
  };

  return (
    <table ref={headerRef} className={cn(rt.resultsTable, rt.resultsTableHeader)} style={tableStyle}>
      <thead>
        <tr>
          {columnIndexes.map((columnIndex: number, loopIndex: number) => {
            const column = columns[columnIndex];
            const cellStyle = hasCellWidths ? { width: `${columnWidths[loopIndex]}px` } : undefined;
            // We set CSS widths with manual Javascript.
            // React diffs against the virtual DOM and will not overwrite the manual widths
            // unless we force an update.
            const forceUpdate = columnIndex + Math.random();

            let className = selectedColumnsMap[column.name]
              ? cn(rt.resultsTableSelectedColumn)
              : undefined;
            const onCellClick = () => {
              onToggleColumn(column.name);
            };
            if (SNOWFLAKE_NUMBER_TYPES.includes(column.type)) {
              className += ' text-right';
            }
            return (
              <th key={forceUpdate} className={className} style={cellStyle} onClick={onCellClick}>
                <div onClick={onNameClick} className={rt.columnName}>
                  {convertColumnName(column.name, databaseType)}
                </div>
                <div className={rt.dataType}>{column.type}</div>
              </th>
            );
          })}
        </tr>
      </thead>
    </table>
  );
};

const Indexes = (
  indexesRef: React.RefObject<HTMLTableElement>,
  rows: any[][],
  maxLines: number,
  onSelectRow: (index: number) => void,
) => {
  return (
    <table
      ref={indexesRef}
      className={cn(rt.resultsTable, rt.resultsTableIndexes, rt[`size${maxLines}`])}
    >
      <tbody>
        {rows.map((row: any[], rowIndex: number) => (
          <tr key={rowIndex} onClick={() => onSelectRow(rowIndex)}>
            <td>{rowIndex + 1}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
