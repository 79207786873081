import * as yup from 'yup';

import 'patches/yupPatches';

import snowflakeReservedWords from './SnowflakeReservedWords';

// Table and Column Name Rules:
//
// Previously, we were using a least common denominator set of DB rules.
// Now we are using Snowflake's rules with a few exceptions.
//   1. Do not allow $ as that has to be encoded in a URL.
//   2. Do not allow any characters that have to be escaped by quotation marks.
//   3. Identifiers must be all lowercase.
// There is discussion of reasoning for these exceptions at:
// https://github.com/MozartData/mozart/pull/104
//
// For Reference:
// https://docs.snowflake.com/en/sql-reference/identifiers-syntax.html
// https://docs.snowflake.com/en/sql-reference/reserved-keywords.html
//
// Actual Rules:
// 1. Is 1-255 characters long.
// 2. Contains only english lowercase letters, numbers, and underscores.
//    Note that Snowflake is case insensitive unless using quotes.
// 3. Does not start with a number.
// 4. Is not a snowflake reserved word or a mozart reserved word.
const tableNameValidCharacters = /^[a-z0-9_]+$/;
const tableNameDoesNotStartWithNumber = /^[a-z_].*$/;

const valDBIdentifier = (fieldName: string) =>
  yup
    .string()
    .required(`${fieldName} is required`)
    .max(255, `${fieldName} cannot be longer than 255 characters`)
    .matches(tableNameValidCharacters, {
      message: `${fieldName} may only contain lowercase letters, numbers, and underscores`,
    })
    .matches(tableNameDoesNotStartWithNumber, { message: `${fieldName} may not start with a number` })
    .notOneOf(snowflakeReservedWords, `${fieldName} may not be a reserved word`);

const valSchemaName = (fieldName: string) => {
  return valDBIdentifier(fieldName).notOneOf(
    ['snapshots'],
    'The "snapshots" schema is reserved for Mozart snapshots.',
  );
};

const valConnectorSchemaName = (fieldName: string, existingSchemas: string[]) => {
  return valSchemaName(fieldName).notOneOf(
    existingSchemas,
    `${fieldName} \${value} already exists`, // eslint-disable-line no-template-curly-in-string
  );
};

const valConnectorTableName = (
  fieldName: string,
  predefinedSchema: string,
  existingFullNames: string[],
) => {
  return valDBIdentifier(fieldName).test({
    name: 'is-unique',
    message: 'Table ${value} already exists in the schema ${schema}', // eslint-disable-line no-template-curly-in-string
    params: {
      schema: predefinedSchema,
    },
    test: function (value: string) {
      const fullName = `${predefinedSchema}.${value}`;
      return !existingFullNames.includes(fullName);
    },
  });
};

// I'm not sure why this isn't part yup's API.
const getValidationErrorsSync = (schema: any, data: any): Record<string, string> => {
  try {
    schema.validateSync(data, { abortEarly: false });
    return {};
  } catch (err: any) {
    // If there are validation errors, transform them into an object
    const errors: Record<string, string> = {};
    if (err.inner) {
      err.inner.forEach((error: yup.ValidationError) => {
        if (error.path) {
          errors[error.path] = error.message;
        }
      });
    }
    return errors;
  }
};

export {
  valDBIdentifier,
  valSchemaName,
  valConnectorSchemaName,
  valConnectorTableName,
  getValidationErrorsSync,
};
