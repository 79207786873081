/* 
  Utils that help coalesce vertex details for customized displays in a pipeline graph.
*/
import {
  durationAsString,
  humanSyncFrequency,
  lastRunAgo,
  lastRunDateAsString,
  numRowsAsString,
  description,
  lastCompletedRunVersionSavedBy,
  lastCompletedRunVersionSavedAtAsString,
  lastCompletedRunVersionSavedAgo,
} from 'model_helpers/aggTableHelper';

import { PipelineVertex } from '../../useVertexConverter';

/** Return number of rows based on table type
 * @param vertex Vertex data for pipeline
 * @returns The Database Table Type
 */
const getDatabaseTableType = (vertex: PipelineVertex) => {
  const { table } = vertex;
  if (table) {
    const { database_table_type: type } = table;
    // TODO(pipeline-improv): There are some discrepancies on how we evaluate and display to the user
    //  the database table type. Specifically, we allow users to create Secure Views, but are showing
    //  them as just "View" in the pipeline graph since v1. We should standardize this.
    if (['VIEW', 'SECURE VIEW', 'MATERIALIZED VIEW'].includes(type)) {
      return 'View';
    } else {
      return 'Table';
    }
  }

  return 'N/A';
};

/** Return customized vertex data from user-selected keys in graph
 * @param vertex Vertex data for pipeline
 * @param keys Array of keys for customized data
 * @returns Array of customized data objects to render in vertex
 */
export const getCustomizedVertexData = (vertex: PipelineVertex, keys: string[]) => {
  return keys
    .map((key) => {
      switch (key) {
        case 'database_table_type':
          return {
            key: 'database_table_type',
            label: 'Database Type',
            value: getDatabaseTableType(vertex),
          };
        case 'description':
          return {
            key: 'description',
            label: 'Description',
            value: description(vertex.table),
          };
        case 'schedule':
          return {
            key: 'schedule',
            label: 'Schedule',
            value: humanSyncFrequency(vertex.table),
          };
        case 'last_run_time':
          return {
            key: 'last_run_time',
            label: 'Last Run Time',
            value: lastRunDateAsString(vertex.table),
          };
        case 'last_run_ago':
          return {
            key: 'last_run_ago',
            label: 'Last Run Ago',
            value: lastRunAgo(vertex.table),
          };
        case 'duration':
          return {
            key: 'duration',
            label: 'Duration',
            value: durationAsString(vertex.table),
          };
        case 'row_count':
          return {
            key: 'row_count',
            label: 'Row Count',
            value: numRowsAsString(vertex.table),
          };
        case 'last_run_saved_by':
          return {
            key: 'last_run_saved_by',
            label: 'Saved By',
            value: lastCompletedRunVersionSavedBy(vertex.table),
          };
        case 'last_run_saved_at':
          return {
            key: 'last_run_saved_at',
            label: 'Saved At',
            value: lastCompletedRunVersionSavedAtAsString(vertex.table),
          };
        case 'last_run_saved_ago':
          return {
            key: 'last_run_saved_ago',
            label: 'Saved Ago',
            value: lastCompletedRunVersionSavedAgo(vertex.table),
          };
        default:
          return null;
      }
    })
    .filter((data) => data !== null);
};
