import { AggTable } from 'api/tableAPI';
import {
  humanSyncFrequency as connectorHumanSyncFrequency,
  lastRunDate as connectorLastRanDate,
  lastCompletedRunVersionSavedBy as connectorLastCompletedRunVersionSavedBy,
  lastCompletedRunVersionSavedAt as connectorLastCompletedRunVersionSavedAt,
} from 'model_helpers/connectorHelper';
import {
  lastUploadDate as csvLastRanDate,
  duration as csvDuration,
  lastCompletedRunVersionSavedBy as csvLastCompletedRunVersionSavedBy,
  lastCompletedRunVersionSavedAt as csvLastCompletedRunVersionSavedAt,
} from 'model_helpers/csvUploadHelper';
import { lastRunDate as dbtLastRanDate } from 'model_helpers/dbtAggTableHelper';
import {
  displaySchedule as dbtDisplaySchedule,
  duration as dbtDuration,
  lastCompletedRunVersionSavedBy as dbtLastCompletedRunVersionSavedBy,
  lastCompletedRunVersionSavedAt as dbtLastCompletedRunVersionSavedAt,
} from 'model_helpers/dbtAggTableHelper';
import {
  humanSyncFrequency as snapshotHumanSyncFrequency,
  lastRunDate as snapshotLastRanDate,
  lastCompletedRunVersionSavedBy as snapshotLastCompletedRunVersionSavedBy,
  lastCompletedRunVersionSavedAt as snapshotLastCompletedRunVersionSavedAt,
} from 'model_helpers/snapshotHelper';
import {
  displaySchedule as transformDisplaySchedule,
  lastRunDate as transformLastRanDate,
  duration as transformDuration,
  lastCompletedRunVersionSavedBy as transformLastCompletedRunVersionSavedBy,
  lastCompletedRunVersionSavedAt as transformLastCompletedRunVersionSavedAt,
} from 'model_helpers/transformHelper';
import { agoDate, formatDate, formatDuration } from 'utils/dateTime';
import { formatNumber } from 'utils/String';

export const tableTypeMap = {
  transform: 'TRANSFORM',
  unmanaged: 'CONNECTED',
  snapshot: 'SNAPSHOT',
  dbt: 'DBT',
  csv_upload: 'UPLOADED CSV',
};

export function getHeaderLabel(table: AggTable | null): string {
  let headerLabel = '';
  if (table) {
    headerLabel = tableTypeMap[table.type];
    // Making a distinction between tables we know the origin of
    // vs tables that magically appeared via Portable, manual DB operations,
    // an external DBT run, or some other process is contravertial.
    // Putting this here to start a conversation.
    if (table.type === 'unmanaged' && table.connector === null) {
      headerLabel = 'UNMANAGED';
    }
    if (table.database_table_type !== 'BASE TABLE') {
      headerLabel = `${headerLabel} ${table.database_table_type}`;
    }
  }
  return headerLabel;
}

export function humanSyncFrequency(table: AggTable | null): string {
  if (!table) {
    return 'N/A';
  }

  if (table.transform) {
    return transformDisplaySchedule(table.transform);
  }

  if (table.connector) {
    let frequency = connectorHumanSyncFrequency(table.connector.sync_frequency);
    // Make times returned by Cron.tsx and connectorHelper.ts the same.
    // I thought this hack was better than changing either of these files.
    if (frequency === 'Every 24 hours') {
      frequency = 'Once a Day';
    }
    return frequency;
  }

  if (table.type === 'snapshot') {
    return snapshotHumanSyncFrequency();
  }

  if (table.type === 'dbt') {
    return dbtDisplaySchedule(table);
  }

  return 'N/A';
}

export function lastRunDate(table: AggTable | null): Date | null {
  let date: Date | null = null;
  if (table) {
    const { transform, connector, csvUpload } = table;
    if (table.type === 'transform' && transform) {
      date = transformLastRanDate(transform);
    } else if (table.type === 'unmanaged' && connector) {
      date = connectorLastRanDate(connector);
    } else if (table.type === 'snapshot') {
      date = snapshotLastRanDate(table);
    } else if (table.type === 'dbt') {
      date = dbtLastRanDate(table);
    } else if (table.type === 'csv_upload' && csvUpload) {
      date = csvLastRanDate(csvUpload);
    }
  }
  return date;
}

export function lastRunDateAsString(table: AggTable | null): string {
  const date = lastRunDate(table);
  if (date) {
    return formatDate(date);
  }

  // lastRunDate() returns null if the table creator never ran.
  // Replace this null with something user friendly.
  if (table && (table.type === 'transform' || table.type === 'dbt')) {
    return 'Never Run';
  }

  return 'N/A';
}

export function lastRunAgo(table: AggTable | null): string {
  const date = lastRunDate(table);
  if (date) {
    return agoDate(date);
  }

  // lastRunDate() returns null if the table creator never ran.
  // Replace this null with something user friendly.
  if (table && (table.type === 'transform' || table.type === 'dbt')) {
    return 'Never Run';
  }

  return 'N/A';
}

export function numRows(table: AggTable | null): number {
  if (!table) {
    return -1;
  }
  // If the table doesnt exist in snowflake but num_rows isnt -1, make it -1
  let count = table.num_rows;
  if (!table.django_thinks_exists_in_snowflake) {
    count = -1;
  }
  return count;
}

export function numRowsAsString(table: AggTable | null): string {
  const rows = numRows(table);
  return rows === -1 ? 'N/A' : formatNumber(rows) || 'N/A';
}

export function duration(table: AggTable | null): number | null {
  if (!table) {
    return null;
  }
  const { type, transform, csvUpload } = table;
  let duration = null;
  if (type === 'transform' && transform) {
    duration = transformDuration(transform);
  } else if (type === 'dbt') {
    duration = dbtDuration(table);
  } else if (table.type === 'csv_upload' && csvUpload) {
    duration = csvDuration(csvUpload);
  }
  return duration;
}

export function durationAsString(table: AggTable | null): string {
  const d = duration(table);
  return d === -1 ? 'N/A' : formatDuration(d) || 'N/A';
}

export function lastCompletedRunVersionSavedBy(table: AggTable | null): string {
  let savedBy: string = 'N/A';
  if (table) {
    const { transform, connector, csvUpload } = table;
    if (table.type === 'transform' && transform) {
      savedBy = transformLastCompletedRunVersionSavedBy(transform);
    } else if (table.type === 'unmanaged' && connector) {
      savedBy = connectorLastCompletedRunVersionSavedBy(connector);
    } else if (table.type === 'snapshot') {
      savedBy = snapshotLastCompletedRunVersionSavedBy(table);
    } else if (table.type === 'dbt') {
      savedBy = dbtLastCompletedRunVersionSavedBy(table);
    } else if (table.type === 'csv_upload' && csvUpload) {
      savedBy = csvLastCompletedRunVersionSavedBy(csvUpload);
    }
  }
  return savedBy;
}

export function lastCompletedRunVersionSavedAt(table: AggTable | null): Date | null {
  let date: Date | null = null;
  if (table) {
    const { transform, connector, csvUpload } = table;
    if (table.type === 'transform' && transform) {
      date = transformLastCompletedRunVersionSavedAt(transform);
    } else if (table.type === 'unmanaged' && connector) {
      date = connectorLastCompletedRunVersionSavedAt(connector);
    } else if (table.type === 'snapshot') {
      date = snapshotLastCompletedRunVersionSavedAt(table);
    } else if (table.type === 'dbt') {
      date = dbtLastCompletedRunVersionSavedAt(table);
    } else if (table.type === 'csv_upload' && csvUpload) {
      date = csvLastCompletedRunVersionSavedAt(csvUpload);
    }
  }
  return date;
}

export function lastCompletedRunVersionSavedAtAsString(table: AggTable | null): string {
  const createdAt = lastCompletedRunVersionSavedAt(table);

  if (createdAt) {
    return formatDate(createdAt);
  }
  return 'N/A';
}

export function lastCompletedRunVersionSavedAgo(table: AggTable | null): string {
  const createdAt = lastCompletedRunVersionSavedAt(table);

  if (createdAt) {
    return agoDate(createdAt);
  }

  return 'N/A';
}

export function description(table: AggTable | null): string {
  if (!table) {
    return 'None';
  }
  return table.description || 'None';
}
