export function clamp(val: number, min: number, max: number) {
  return Math.min(Math.max(val, min), max);
}

export function percent(part: number, whole: number) {
  let result = 0;
  if (whole !== 0) {
    result = (part / whole) * 100;
  }
  return result;
}

export function percentFormatted(part: number, whole: number, minDecimalPlaces: number = 1) {
  const decimalFormat = Intl.NumberFormat('en-US', {
    minimumFractionDigits: minDecimalPlaces,
    maximumFractionDigits: 1,
  });
  return decimalFormat.format(percent(part, whole));
}
