import React, { useEffect } from 'react';

import { RouteComponentProps, useLocation } from 'react-router';
import { useTitle } from 'react-use';

import useDataAlerts from 'hooks/useDataAlerts';
import useFilterDataAlerts from 'pages/data_alerts/useFilterDataAlerts';

import useCreateDataAlerts, { CreateAlertSource } from './CreateDataAlerts/useCreateDataAlerts';
import ListDataAlertsView from './ListDataAlertsView';

type LocationState = { createAlert?: boolean; eventLocation?: CreateAlertSource };

interface MatchParams {}

interface ListDataAlertsControllerProps extends RouteComponentProps<MatchParams> {}

export default function ListDataAlertsController(props: ListDataAlertsControllerProps) {
  useTitle('Data Alerts');

  const {
    loading,
    saving,
    errorMessage,
    dataAlerts,
    selectedDataAlert,
    updateErrorMessage,
    failureLogs,
    failureLogsErrorLines,
    failureLogsDataAlert,
    loadingFailureLogs,
    showConfirmModal,
    setUpdateErrorMessage,
    handleUpdateDataAlert,
    handleSetUnsavedSql,
    handleCloseConfirmModal,
    handleConfirmConfirmModal,
    handleCloseDataAlertModal,
    handleCloseFailureLogsModal,
    handleGetFailureLogs,
    handleDeleteDataAlert,
    handleSelectDataAlert,
  } = useDataAlerts({
    eventPage: 'DataAlertsPage',
  });

  const filterProps = useFilterDataAlerts(dataAlerts);
  const createProps = useCreateDataAlerts();
  const { onOpenCreateAlertModal } = createProps;

  const location = useLocation<LocationState>();
  useEffect(() => {
    const createAlert = location.state?.createAlert;
    const eventLocation = location.state?.eventLocation ?? 'DataAlertsPage';
    if (createAlert) {
      onOpenCreateAlertModal(eventLocation);
      // Clear location state so that the modal doesn't reopen on refresh
      window.history.replaceState({}, '');
    }

    location.state = {};
  }, [location, onOpenCreateAlertModal]);

  return (
    <ListDataAlertsView
      errorMessage={errorMessage}
      loading={loading}
      saving={saving}
      dataAlerts={dataAlerts}
      selectedDataAlert={selectedDataAlert}
      updateErrorMessage={updateErrorMessage}
      failureLogs={failureLogs}
      failureLogsErrorLines={failureLogsErrorLines}
      failureLogsDataAlert={failureLogsDataAlert}
      loadingFailureLogs={loadingFailureLogs}
      showConfirmModal={showConfirmModal}
      setUpdateErrorMessage={setUpdateErrorMessage}
      onUpdateDataAlert={handleUpdateDataAlert}
      onSetUnsavedSql={handleSetUnsavedSql}
      onCloseConfirmModal={handleCloseConfirmModal}
      onConfirmConfirmModal={handleConfirmConfirmModal}
      onCloseDataAlertModal={handleCloseDataAlertModal}
      onCloseFailureLogsModal={handleCloseFailureLogsModal}
      onGetFailureLogs={handleGetFailureLogs}
      onDeleteDataAlert={handleDeleteDataAlert}
      onSelectDataAlert={handleSelectDataAlert}
      {...filterProps}
      {...createProps}
    />
  );
}
