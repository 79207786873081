import React, { useState } from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import ListboxFormikGroup, {
  ListboxOption,
} from 'components/inputs/formik_group/ListboxFormikGroup/ListboxFormikGroup';
import RadioFormikGroup, {
  RadioInputOption,
} from 'components/inputs/formik_group/RadioFormikGroup/RadioFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { SnowflakeAccount } from './useAccounts';

const formSchema = yup.object({
  name: yup.string().trim().max(255, 'First name cannot be longer than 255 characters'),
  default_role: yup.string(),
  disabled: yup.boolean(),
});

interface AccountSaveableFields {
  [key: string]: string | undefined;
}

interface UpdateAccountModalProps {
  account: SnowflakeAccount;
  providerOptions: ListboxOption[];
  providerError: string;
  providerLoading: boolean;
  closeModal: () => void;
  // setAccounts: (accounts: SnowflakeAccount[]) => void;
}

export default function UpdateAccountModal(props: UpdateAccountModalProps) {
  const { account, providerError, providerOptions, providerLoading, closeModal } = props;
  const [error, setError] = useState('');
  const [updatingAccount, setUpdatingAccount] = useState(false);

  const initialValues = {
    name: account.name,
    locator: account.locator,
    provider: account.provider,
    region: account.region,
  };

  const updateUser = (values: AccountSaveableFields) => {
    setError('');
    setUpdatingAccount(true);
    const api = new API();
    api
      .get(`/api/snowflake_accounts/${account.id}`)
      .then((response) => {
        closeModal();
      })
      .catch((error) => {
        setError('Failed to update account.');
      })
      .finally(() => {
        setUpdatingAccount(false);
      });
  };

  const radioOptions: RadioInputOption[] = [
    { label: 'Yes', value: 'false' },
    { label: 'No', value: 'true' },
  ];

  return (
    <Modal
      onClose={closeModal}
      header={`SnowflakeAccount (Django Object): ${account.id}`}
      cancelButton={true}
      containerClass="w-[500px]"
    >
      <div className="flex flex-col h-full pt-2">
        {error && (
          <Alert className="mx-4 mb-2" variant="error">
            {error}
          </Alert>
        )}
        <Formik validationSchema={formSchema} onSubmit={updateUser} initialValues={initialValues}>
          {({ handleSubmit, handleChange, values, errors }) => {
            return (
              <form noValidate onSubmit={handleSubmit} className="flex flex-col h-full w-full">
                <div className="px-4 mb-2">
                  <TextFormikGroup name="name" label="Name" groupClass="mt-4" />
                  <ListboxFormikGroup
                    label="Provider"
                    name="provider"
                    options={providerOptions}
                    groupClass="mt-4"
                    optionsMaxHeightClass="max-h-[250px]"
                    error={providerError}
                    spinning={providerLoading}
                    postLabelElement={
                      <InfoIcon
                        content="Just select Snowflake. We will probably never change this."
                        containerClass="ml-1"
                        popoverProps={{ style: { maxWidth: '700px' } }}
                      />
                    }
                  />
                  <TextFormikGroup name="region" label="Region" groupClass="mt-4" />
                  <TextFormikGroup name="locator" label="Locator" groupClass="mt-4" />
                  <RadioFormikGroup
                    name="disabled"
                    label="Enabled"
                    options={radioOptions}
                    groupClass="mt-4"
                  />
                  <Button
                    type="submit"
                    variant="lightAction"
                    spinning={updatingAccount}
                    className="mt-4 mb-2"
                  >
                    Save (This is a no-op)
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}
