import React, { createContext } from 'react';

import { InAppNotification } from 'pages/notifications/NotificationHelpers';

export interface NotificationContextInterface {
  updatedNotifications: InAppNotification[];
  setUpdatedNotifications: React.Dispatch<React.SetStateAction<InAppNotification[]>>;
}

export const NotificationContext = createContext<NotificationContextInterface>({
  updatedNotifications: [],
  setUpdatedNotifications: () => {},
});
