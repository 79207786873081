import RadioInputGroup from 'components/inputs/group/RadioInputGroup/RadioInputGroup';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

import {
  SchemaChangeHandling,
  SchemaChangePolicyState,
  schemaChangeHandlingMap,
} from '../useSchemaChangePolicyState';

export default function SchemaChangePolicy(props: {
  schemaChangePolicy: SchemaChangePolicyState;
  savingDisabled: boolean;
}) {
  const {
    schemaChangePolicy: { onChangePolicy, unsavedPolicy },
    savingDisabled,
  } = props;
  return (
    <div className="mt-4">
      <div className="flex items-center">
        <h5 className="text-sm font-medium">Schema Change Policy</h5>
        <InfoIcon
          content="New tables or columns may be added to this connector's source after you manually configure which tables and columns to sync. This setting tells the system what to do in that scenario."
          containerClass="ml-1"
          popoverProps={{ style: { maxWidth: '550px', fontSize: '1rem' } }}
        />
      </div>
      <RadioInputGroup
        value={unsavedPolicy}
        onChange={onChangePolicy}
        name="schema_change_handling"
        options={Object.keys(schemaChangeHandlingMap).map((k) => {
          return { value: k, label: schemaChangeHandlingMap[k as SchemaChangeHandling] };
        })}
        sharedRadioInputProps={{
          disabled: savingDisabled,
        }}
        groupClass="mt-2"
        containerStyle={{
          gridTemplateColumns: 'repeat(3, max-content)',
          gridTemplateRows: 'repeat(1, 1fr)',
          columnGap: '16px',
          fontSize: '14px',
          fontWeight: 400,
        }}
      />
    </div>
  );
}
