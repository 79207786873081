import { Lightbulb } from 'react-bootstrap-icons';

export interface WhatIsATransformProps {}

export default function WhatIsATransform(props: WhatIsATransformProps) {
  return (
    <div className="h-full py-4 px-6 border-2 border-pri-gray-200 text-pri-gray-500">
      <h3 className="f-row-y-center font-medium">
        <Lightbulb size={16} color="var(--pri-gray-500)" />
        <span className="ml-1">What is a Transform?</span>
      </h3>
      <ul className="pl-[19px] list-disc text-small">
        <li>Transforms clean, standardize, and aggregate data.</li>
        <li>
          Transforms are a useful building block towards analysis, reports, and meaningful insights.
        </li>
        <li>
          Report Builder automates the creation of transforms and the reports that consume their output.
        </li>
        <li>
          Report Builder transforms automatically run after the connector they are associated with syncs
          data.
        </li>
      </ul>
    </div>
  );
}
