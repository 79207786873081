import cn from 'classnames';

interface UnderConstructionProps {
  alignment?: 'horizontal' | 'vertical';
  linkHref?: string;
  className?: string;
}

const UnderConstruction = (props: UnderConstructionProps) => {
  const { alignment = 'horizontal', linkHref, className } = props;

  return (
    <div
      className={cn(
        className,
        'p-8 bg-pri-gray-50 rounded-lg',
        alignment === 'horizontal'
          ? 'f-row-y-center'
          : 'flex items-center sm:flex-col sm:justify-center',
      )}
    >
      <img
        src="/images/graphics/under_construction/stacking_boxes_grayscale.svg"
        alt="Under Construction"
        className="w-48 m-4"
      />
      <div>
        <h1 className="text-md font-medium">Coming Soon</h1>
        <p className="mt-2">
          We're still building this feature, but don't worry!{' '}
          {linkHref ? (
            <>
              You can check out{' '}
              <a href={linkHref} className="text-sec-blue-light-700">
                the full-page version
              </a>{' '}
              of this feature now.
            </>
          ) : (
            "It's coming soon..."
          )}
        </p>
      </div>
    </div>
  );
};

export default UnderConstruction;
