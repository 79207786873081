import React from 'react';

import LinkButton from 'components/inputs/basic/Button/LinkButton';
import { useDatabaseAccount } from 'context/AuthContext';

export default function WelcomeNoData(props: {}) {
  const databaseType = useDatabaseAccount().type;
  return (
    <div className="f-col-center">
      <h1 className="display-sm mt-10">Add a connector to sync data to your warehouse.</h1>

      <img
        src="/images/graphics/onboarding_popover_tour/connectors.svg"
        alt="step 1 add connector"
        className="mt-6 ml-4"
        style={{ width: '525px' }}
      />

      <div className="mt-8">
        {databaseType !== 'bigquery' && (
          <LinkButton
            variant="lightTransparent"
            to="/csv/add"
            data-track="Warehouse NoConnectorsUploadCSV"
            className="mr-2"
          >
            Upload CSV
          </LinkButton>
        )}
        <LinkButton
          variant="lightAction"
          to="/connectors/add"
          data-track="Warehouse NoConnectorsAddConnector"
        >
          Add Connector
        </LinkButton>
      </div>
    </div>
  );
}
