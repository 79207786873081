/**
 * This file calculates the SQL query from a given vertex
 * by traversing all of its ancestors, converting
 * the FlowchartQueryModel of the given vertex into an SQLGlotModel
 * and posting that model to the API.
 * The API will convert the SQLGlotModel into SQL.
 */
import { AggTable } from 'api/APITypes';

import { TableColumn, FlowchartVertex, SourceTable } from '../model/FlowchartQueryModel';

// A QueryBlock is all of the statements we can put in one query without creating
// some sort of nesting structure like a CTE.
interface QueryBlock {
  table?: AggTable;
  selectedColumns?: TableColumn[];
}

export function buildQueryBlockFromVertex(
  vertex: FlowchartVertex,
  queryBlock: QueryBlock = {},
): QueryBlock {
  // Base case
  if (vertex.type === 'source_table') {
    const sourceTable = vertex as SourceTable;
    queryBlock.table = sourceTable.table || undefined;
    return queryBlock;
  } else if (vertex.type === 'select_columns') {
    // const pickColumns = vertex as PickColumns;
    // queryBlock.columnSelections = pickColumns.columnSelections || undefined;
    // const monoParent = vertex as MonoParentVertex;
    // const parent = vertices.find((v) => v.id === monoParent.parentVertexID) as MonoParentVertex;
    // return buildQueryBlockFromVertex(parent, vertices, tablesByID, columnsByTableID, queryBlock);
    return {};
  }

  // If we get this far it is an error.
  // eslint-disable-next-line no-console
  console.log('THIS IS AN ERROR', queryBlock);
  return queryBlock;
}

export function buildQueryFromVertex(vertex: FlowchartVertex): string {
  const queryBlock = buildQueryBlockFromVertex(vertex);
  let columns = '*';
  if (queryBlock.selectedColumns) {
    columns = queryBlock.selectedColumns
      .filter((c) => c.picked)
      .map((c) => c.name)
      .join(', ');
  }

  // Temporary hack
  const tableName = 'web_public.android_events';
  let sql = `SELECT ${columns} FROM ${tableName}`;
  return sql;
}

export function pickAlias(table: AggTable, vertices: FlowchartVertex[]): string {
  const existingAliases = vertices
    .filter((v) => v.type === 'source_table')
    .map((v) => (v as SourceTable).alias)
    .filter((a) => a !== null);
  const alias = table.name
    .split('_')
    .map((s) => s.slice(0, 1))
    .join('');
  if (!existingAliases.includes(alias)) {
    return alias;
  }
  for (let i = 1; i < 100; i++) {
    const aliasPlusKicker = alias + i;
    if (!existingAliases.includes(aliasPlusKicker)) {
      return aliasPlusKicker;
    }
  }
  return 'I_GIVE_UP';
}
