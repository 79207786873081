import { useEffect, useState } from 'react';

import API from 'api/API';
import { DbtRunConfig } from 'api/dbtAPI';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';

interface DocsTabProps {
  runConfig: DbtRunConfig;
}

interface DocUrlResponse {
  url: string;
}

const DocsTab = (props: DocsTabProps) => {
  const { runConfig } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [docsResponse, setDocResponse] = useState<DocUrlResponse | null>(null);
  useEffect(() => {
    const api = new API();
    api
      .get(`api/dbt_repositories/${runConfig.dbt_repository.id}/docs_url`)
      .then((response) => {
        setDocResponse(response.data as DocUrlResponse);
      })
      .catch(() => {
        setError('Failed to load dbt docs.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const docsReady = runConfig.dbt_repository.is_docs_ready;
  const showDocsNotGenerated = runConfig.last_run === null;
  const showDocsNotReady = runConfig.last_run && !error && !docsReady;
  const showDocs = runConfig.last_run && !error && docsReady;

  return (
    <CenteredContainer
      maxWidth="100%"
      containerClass="!m-0 h-full"
      contentClassName="h-full"
      contentWrapperClass="h-full !m-0"
      childrenWrapperClass="h-full"
      loading={loading}
      loadingError={error}
    >
      {showDocsNotGenerated && (
        <NoObjectsAlert
          heading="Docs are not generated until your dbt job is run."
          detail="You must run go to the Runs tab and run your dbt job before the docs are ready."
          className="m-4"
        />
      )}
      {showDocsNotReady && (
        <NoObjectsAlert
          heading="Docs are being generated."
          detail="Docs are generated about a minute after your first successful dbt run with this repo in the Mozart platform."
          className="m-4"
        />
      )}
      {showDocs && <iframe src={docsResponse?.url} title="dbt docs" width="100%" height="100%" />}
    </CenteredContainer>
  );
};

export default DocsTab;
