/*****************************************************************************
A Virtual Schema is a grouping of tables in the Mozart UI.
A Virtual Schema looks like a DB schema, frequently represents a DB schema,
but its collection of tables does not necessarily have to be a one to one mapping
with an actual DB schema.
1. A table can be put in more than one virtual schema.
2. The tables of one Snowflake schema can be grouped into more than one virtual schema.
   Therefore, a "virtual schema key" is the combination of the virtual schema type
   and the actual Snowflake schema.

   For Example:
   Not all tables in the schema `mozart` are transforms.
   In dev environments, some tables in the `mozart` schema are not transforms.
   They are tables that belong to transforms in another programmer's dev env or
   a dev env that existed before you ran `make reset_db`.
   This edgecase would apply to real world customers that deleted a transform but
   not its corresponding table.
*****************************************************************************/
export type VirtualSchemaType =
  | 'MOZART_ALL'
  | 'MOZART_RECENT'
  | 'MOZART_FAVORITES'
  | 'MOZART_TRANSFORMS'
  | 'MOZART_SNAPSHOTS'
  | 'UNMANAGED_SCHEMA';
export const MOZART_ALL = 'MOZART_ALL';
export const MOZART_RECENT = 'MOZART_RECENT';
export const MOZART_FAVORITES = 'MOZART_FAVORITES';
export const MOZART_TRANSFORMS = 'MOZART_TRANSFORMS';
export const MOZART_SNAPSHOTS = 'MOZART_SNAPSHOTS';
export const UNMANAGED_SCHEMA = 'UNMANAGED_SCHEMA';

export const VIRTUAL_SCHEMA_KEY_DIVIDER = '>';

export function virtualSchemaKey(virtualSchema: VirtualSchemaType, dbSchema: string) {
  return virtualSchema + VIRTUAL_SCHEMA_KEY_DIVIDER + dbSchema;
}

export function virtualSchemaKeyParts(virtualSchemaKey: string) {
  // Split on the first occurrence of the divider.
  const firstIndex = virtualSchemaKey.indexOf(VIRTUAL_SCHEMA_KEY_DIVIDER);
  const virtualSchemaType = virtualSchemaKey.slice(0, firstIndex) as VirtualSchemaType;
  const schema = virtualSchemaKey.slice(firstIndex + VIRTUAL_SCHEMA_KEY_DIVIDER.length);
  return { virtualSchemaType, schema };
}
