/*****************************************************************************
 * Tools to calculate column widths for TablesTable.
 ****************************************************************************/

// All TableTable columns.
// Some of these columns are static
export type ColumnKey =
  | 'table'
  | 'description'
  | 'scheduled'
  | 'status'
  | 'lastRun'
  | 'rowCount' // not resizeable, but stored in AdjustableTransformWidths to make coding easier.
  | 'snapshot' // static
  | 'query'; // static

// We store these columns column widths for transforms.
// All other widths are static or can be computed from this set.
// These values store the percentage of screen width that isn't
// in a static column.
export interface AdjustableTransformWidths {
  table: number;
  description: number;
  scheduled: number;
  status: number;
  lastRun: number;
  rowCount: number;
}

// THESE MUST ADD UP TO 100
const DEFAULT_ADJUSTABLE_WIDTHS: AdjustableTransformWidths = {
  table: 25,
  description: 26,
  scheduled: 15,
  status: 9,
  lastRun: 15,
  rowCount: 10,
};

const FIXED_COLUMN_PIXELS: { [key: string]: string } = {
  snapshot: '100px',
  query: '60px',
};

const sumFixedPixels = Object.values(FIXED_COLUMN_PIXELS).reduce(
  (previous: number, current: string) => previous + pixelsToNum(current),
  0,
);

const getRenderedWidth = (transformWidths: AdjustableTransformWidths, columnKey: ColumnKey) => {
  const fixedWidth = FIXED_COLUMN_PIXELS[columnKey];
  if (fixedWidth) {
    return fixedWidth;
  }

  const width = transformWidths[columnKey as keyof AdjustableTransformWidths];
  return width + '%';
};

export interface ColumnTypeRenderWidths {
  table: string;
  description: string;
  scheduled: string;
  status: string;
  lastRun: string;
  rowCount: string;
  snapshot: string;
  query: string;
}

export interface WarehouseRenderWidths {
  transform: ColumnTypeRenderWidths;
  unmanaged: ColumnTypeRenderWidths;
  snapshot: ColumnTypeRenderWidths;
}

const getAllRenderedColumnWidths = (
  transformWidths: AdjustableTransformWidths,
): ColumnTypeRenderWidths => {
  return {
    table: getRenderedWidth(transformWidths, 'table'),
    description: getRenderedWidth(transformWidths, 'description'),
    scheduled: getRenderedWidth(transformWidths, 'scheduled'),
    status: getRenderedWidth(transformWidths, 'status'),
    lastRun: getRenderedWidth(transformWidths, 'lastRun'),
    rowCount: getRenderedWidth(transformWidths, 'rowCount'),
    snapshot: getRenderedWidth(transformWidths, 'snapshot'),
    query: getRenderedWidth(transformWidths, 'query'),
  };
};

/*****************************************************************************
 * Resize a column on all schema expandos: (THIS IS COMPLICATED.)
 * 1. Fixed columns cannot be resized.
 * 2. Fixed column widths are defined in pixels.
 * 3. Adjustable columns can be resized.
 * 4. Adjustable columns need to be defined in terms of percentages so that they will
 *    resize if the user resizes the browser window.
 * 5. Adjustable columns have their width defined as a percentage of (totalTableWidth - the sum of the fixed columns)
 * 6. When a column is resized, the drag distance is subtracted from the column to the right of it
 *    and added to the resized column.
 * 7. Not all table types have the same columns.
 ****************************************************************************/
const resizeColumnWidths = (
  adjustableWidths: AdjustableTransformWidths,
  columnKey: keyof AdjustableTransformWidths,
  nextRightKey: keyof AdjustableTransformWidths,
  tableWidth: number,
  deltaPixels: number,
) => {
  const remainingPixels = tableWidth - sumFixedPixels;
  const deltaPercent = (deltaPixels / remainingPixels) * 100;

  const oldWidths = adjustableWidths;
  const newWidths = { ...oldWidths };
  newWidths[columnKey] = oldWidths[columnKey] + deltaPercent;
  newWidths[nextRightKey] = oldWidths[nextRightKey] - deltaPercent;

  return newWidths;
};

export { DEFAULT_ADJUSTABLE_WIDTHS, sumFixedPixels, resizeColumnWidths, getAllRenderedColumnWidths };

export function pixelsToNum(percentString: string) {
  return Number(percentString.slice(0, -2));
}

// This only returns valid values for columns that can be resized.
export function nextRightColumn(
  resizedColumn: keyof AdjustableTransformWidths,
): keyof AdjustableTransformWidths {
  if (resizedColumn === 'table') {
    return 'description';
  }

  if (resizedColumn === 'description') {
    return 'scheduled';
  }

  if (resizedColumn === 'scheduled') {
    return 'status';
  }

  return 'rowCount';
}
