import { Diagnostic, linter } from '@codemirror/lint';
import { Text } from '@codemirror/state';
import { EditorView } from '@codemirror/view';

import { stateToSelection } from './SelectSqlPlugin';

export type ErrorProps = {
  line: number; // The editor line number starting at 1.
  column: number; // The editor column starting at 0.
  markerString: string; // The word to underline in red.
  joinedMsg: string; // The multiline API error message joined together with '\n's.
  hasAPIOffset: boolean; // The API error message contains a line and column.
  editorMsg: string; // A short version of the error message to display in the editor.
};
type SelectedSql = { column: number; line: number; setByEditor: Boolean; sql: string };

// Parses the server's error message to get the line number and position
// of the error so we can highlight the error in the editor.
export const calcErrorProps = (runErrorLines: string[], selectedSql: SelectedSql): ErrorProps => {
  let line = -1;
  let column = -1;
  let markerString = '';
  let joinedMsg = '';
  let hasAPIOffset = false;
  let editorMsg = '';

  if (runErrorLines.length) {
    joinedMsg = runErrorLines.join('\n');

    // Find code to highlight
    const unexpectedMatches = joinedMsg.match(/unexpected '(.*)'/);
    if (unexpectedMatches) {
      editorMsg = unexpectedMatches[0];
      markerString = unexpectedMatches[1];
    }

    if (!markerString) {
      const invalidMatches = joinedMsg.match(/invalid identifier '(.*)'/i);
      if (invalidMatches) {
        editorMsg = invalidMatches[0];
        markerString = invalidMatches[1];
      }
    }

    if (!markerString) {
      const doesNotExistMatches = joinedMsg.match(/'(.*)' does not exist or not authorized/i);
      // The matched string could be of three forms:
      // WAREHOUSE.SCHMEA.TABLE
      // SCHEMA.TABLE
      // TABLE
      // Snowflake always complains about the first identifier it does not recognize from left to right.
      // So the right most identifier Snowflake returns in the error message will be the thing it does not recognize.
      if (doesNotExistMatches) {
        // WAREHOUSE does not exist
        markerString = doesNotExistMatches[1];
        const parts = markerString.split('.');
        // WAREHOUSE.SCHMEA does not exist
        if (parts.length === 2) {
          markerString = parts.slice(1).join('.');
        }
        // WAREHOUSE.SCHMEA.TABLE does not exist
        else if (parts.length === 3) {
          markerString = parts.slice(2).join('.');
        }
        editorMsg = `'${markerString}' does not exist or not authorized.`;
      }
    }

    if (!markerString) {
      const invalidMatches = joinedMsg.match(
        /Missing window specification for function \[(.*)\(.*\]\./i,
      );
      if (invalidMatches) {
        editorMsg = invalidMatches[0];
        markerString = invalidMatches[1];
      }
    }

    if (!markerString) {
      const invalidMatches = joinedMsg.match(/(not enough|too many) arguments for function \[(.*)\].*/i);
      if (invalidMatches) {
        editorMsg = invalidMatches[0];
        markerString = invalidMatches[2];
      }
    }

    if (!markerString) {
      const invalidMatches = joinedMsg.match(/Unknown function (\w.*)/i);
      if (invalidMatches) {
        editorMsg = invalidMatches[0];
        markerString = invalidMatches[1];
      }
    }

    if (!markerString) {
      editorMsg = joinedMsg;
    }

    const lineNumberMatches = joinedMsg.match(/line (\d+) at position (\d+)/);
    // If there are line numbers in the runErrorLines, `sanitizeErrorLineNumber()` has already sanitized them.
    // So use the numbers in the string.
    if (lineNumberMatches) {
      line = Number(lineNumberMatches[1]);
      column = Number(lineNumberMatches[2]);
      hasAPIOffset = true;
    }
    // If there are not line numbers in the runErrorLines AND there is a markerString to search for, set them to the start of the selected text.
    // The default selected text is the start of the document.
    else if (markerString !== '') {
      line = selectedSql.line;
      column = selectedSql.column;
    }
  }
  return { line, column, markerString, joinedMsg, hasAPIOffset, editorMsg };
};

export const findMarkerRange = (doc: Text, errorProps: ErrorProps, selectedSql: string) => {
  const { line, column, markerString, hasAPIOffset } = errorProps;

  const lineObj = doc.line(line);
  let from = 0;
  let to = 0;
  // If the API returned an offset in the error message, calculate from and to from the API message.
  if (hasAPIOffset) {
    from = lineObj.from + column;
    to = from + markerString.length;
    // The API appends 'LIMIT 100' to the end of queries.
    // Therefore, it can return positions that are beyond the last character
    // in the Code Mirror doc. If that happens, set the range to the last
    // character in the doc.
    if (from > doc.length - 1) {
      from = doc.length - 1;
    }
    if (to > doc.length) {
      to = doc.length;
    }
  }
  // Else do a string search from the selected text.
  // `line` and `column` should be the start of the selected text if the API did not return an offset.
  else {
    const searchStart = lineObj.from + column;
    const searchLength = selectedSql !== '' ? selectedSql.length : -1;
    const searchText =
      searchLength !== -1 ? doc.slice(searchStart, searchStart + selectedSql.length) : doc;
    const searchString = searchText.sliceString(0).toLowerCase();
    const searchIndex = searchString.indexOf(markerString.toLowerCase());
    from = searchIndex > -1 ? searchStart + searchIndex : searchStart;
    to = from + markerString.length;
  }

  return [from, to];
};

const SnowflakeLinter = (runErrorLines: string[]) =>
  linter((view: EditorView) => {
    const diagnostics: Diagnostic[] = [];

    const selectedSql = stateToSelection(view.state);

    const errorProps = calcErrorProps(runErrorLines, selectedSql);

    if (errorProps.line >= 0) {
      const [from, to] = findMarkerRange(view.state.doc, errorProps, selectedSql.sql);

      const diagnostic: Diagnostic = {
        from,
        to,
        severity: 'error',
        message: errorProps.editorMsg,
      };

      diagnostics.push(diagnostic);
    }

    return diagnostics;
  });

export default SnowflakeLinter;
