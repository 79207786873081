/*
  Use <FixMeAlert/> when the user should take immediate action to 
  create a specific type of object that is critcal to system operation,
  such as creating their first connector, creating their first transform,
  or running a transform that has never been run before.
*/
import React from 'react';

import cn from 'classnames';

import LinkButton from 'components/inputs/basic/Button/LinkButton';
import Alert from 'components/widgets/alerts/Alert/Alert';

interface FixMeAlertProps {
  heading: string;
  detail?: React.ReactNode;
  imgSrc?: string;
  alertClass?: string;
  additionalCenteredClass?: string;
  additionalWordClass?: string;
  additionalDetailClass?: string;
  cta?: string;
  ctaLink?: string;
  dataTrack?: string;
  variant?: 'kinda_bad' | 'warning';
  disabled?: boolean;
}

export default function FixMeAlert(props: FixMeAlertProps) {
  const {
    heading,
    detail,
    imgSrc,
    alertClass,
    additionalCenteredClass,
    additionalWordClass,
    additionalDetailClass,
    cta,
    ctaLink,
    dataTrack,
    variant,
    disabled,
  } = props;
  const actualCenteredClass = cn('f-row-y-center', additionalCenteredClass);
  const actualWordClass = cn('max-w-[452px]', additionalWordClass);
  const actualDetailClass = cn('text-base mt-2', additionalDetailClass);
  return (
    <Alert variant={variant || 'warning'} className={alertClass}>
      <div className={actualCenteredClass}>
        {imgSrc && <img src={imgSrc} className="w-[244px] mr-[40px]" alt="warning diagram" />}
        <div className={actualWordClass}>
          <h6 className="text-xl font-medium">{heading}</h6>
          {typeof detail === 'string' ? <p className={actualDetailClass}>{detail}</p> : detail}
          {cta && ctaLink && dataTrack && (
            <LinkButton
              variant="darkAction"
              to={ctaLink}
              data-track={dataTrack}
              className="mt-6"
              disabled={disabled}
            >
              {cta}
            </LinkButton>
          )}
        </div>
      </div>
    </Alert>
  );
}
