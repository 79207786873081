import { useEffect, useRef, useState } from 'react';

import api from 'api/sqlAIAPI';

interface useAIFixSqlProps {
  sql: string;
  sqlError: string;
}

const ErrorMessages: { [code: string]: string } = {
  unknown_error: 'There was an error generating a fix. Please try again.',
  unable_to_fix_error:
    'Sorry, Mozart AI is unable to fix this error. Make sure your schema and table names are correct and try running again.',
};

const useAIFixSql = (props: useAIFixSqlProps) => {
  const { sql, sqlError } = props;
  const [responseId, setResponseId] = useState<string | null>(null);
  const [fixedSql, setFixedSql] = useState<string>('');
  const [apiError, setApiError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [takingLonger, setTakingLonger] = useState<boolean>(false);
  const timer: React.MutableRefObject<NodeJS.Timeout | null> = useRef(null);

  useEffect(() => {
    if (isLoading) {
      // Set timer to show loading message after 7 seconds
      timer.current = setTimeout(() => {
        setTakingLonger(true);
      }, 7000);
    } else {
      setTakingLonger(false);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [isLoading, timer]);

  const handleGenerateSql = async () => {
    setIsLoading(true);

    try {
      const response = await api.generateSqlFix({ sql, sql_error: sqlError });
      const responseData = response.data;

      setResponseId(responseData.gpt_response_id);
      setFixedSql(responseData.fixed_sql);
      setApiError(null);

      analytics.track('SqlEditor GenerateAIFixSql', {
        response_id: responseData.gpt_response_id,
        original_sql: sql,
        original_sql_error: sqlError,
        fixed_sql: responseData.fixed_sql,
      });
    } catch (error: any) {
      const errorCode = error?.response?.data?.error ?? 'unknown_error';
      const errorMessage = ErrorMessages[errorCode];

      setResponseId(null);
      setFixedSql('');
      setApiError(errorMessage);

      analytics.track('SqlEditor GenerateAIFixSqlError', {
        original_sql: sql,
        original_sql_error: sqlError,
        error: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    takingLonger,
    responseId,
    fixedSql,
    apiError,
    onGenerateSql: handleGenerateSql,
  };
};

export default useAIFixSql;
