import * as yup from 'yup';

// Override yup.string().notOneOf() to fix yup bug the yup maintainer refused to fix.
// Multiple chained together calls to `notOneOf()` create one list of excluded values.
// So each list of excluded values cannot have its own error message. Fix this.
// Reported Bug: https://github.com/jquense/yup/issues/1193
yup.addMethod(yup.string, 'notOneOf', function (strList: string[], message?: string) {
  const actualMessage = message || `this must not be one of the following values: ${strList.join(', ')}`;
  return this.test('notOneOf', actualMessage, function (value, ctx) {
    if (value && strList.includes(value)) {
      return ctx.createError({ message: actualMessage });
    }
    return true;
  });
});
