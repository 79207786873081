import { AggTable } from 'api/APITypes';
import { parseIso } from 'utils/dateTime';

import AbstractTableSorter, { LAST_RUN_AGO, LAST_RUN_STATUS } from './AbstractTableSorter';

class AggTableSorter extends AbstractTableSorter<AggTable> {
  schedule(table: AggTable): number {
    // Snapshots run once a day.
    return 60 * 24;
  }

  lastRunAgo(table: AggTable) {
    if (table.last_snapshot_at) {
      const parsedTime = parseIso(table.last_snapshot_at);
      if (parsedTime) {
        const now = new Date();
        return now.getTime() - parsedTime.getTime();
      }
    }

    if (table.snapshot) {
      return LAST_RUN_AGO.NEVER_RAN;
    }

    return LAST_RUN_AGO.NOT_APPLICABLE;
  }

  runStatus(table: AggTable) {
    if (table.last_snapshot_at) {
      // TODO: Do better than this when data is available.
      // We don't have snapshot last run status data,
      // but it probably went OK.
      return LAST_RUN_STATUS.success;
    }

    return LAST_RUN_STATUS.NA;
  }
}

const staticInstance = new AggTableSorter();

export default staticInstance;
