import { CompletionContext } from '@codemirror/autocomplete';
import { syntaxTree } from '@codemirror/language';

export type TreeNode = {
  type: string;
  text: string;
  from: number;
  to: number;
};

export const filterTreeNodes = (treeNodes: TreeNode[], nodeTypes: string[]) => {
  return treeNodes.filter(({ type }) => nodeTypes.includes(type));
};

/*******************************************************************************
 * This function walks a Syntax Tree and outputs it as an array of custom tree nodes.
 * (Example of an Abstract Syntax Tree https://medium.com/basecs/leveling-up-ones-parsing-game-with-asts-d7a6fc2400ff)
 *
 *
 * The CodeMirror syntax tree is produced using the Lezer Library Syntax Tree (https://lezer.codemirror.net/docs/ref/#common.Trees)
 * Lezer syntax trees are not abstract, they just tell you which nodes were parsed where, without providing additional information
 * about their role or relation (beyond parent-child relations).
 *
 * EXAMPLE SYNTAX TREE (not exact to what's produced by CodeMirror):
 * An example of a syntax tree for the statement (SELECT * FROM animals) is
 * {
 *    "type": "statement",
 *    "variant": "list",
 *    "statement": [
 *      {
 *        "type": "statement",
 *        "variant": "select",
 *        "result": [
 *          {
 *            "type": "identifier",
 *            "variant": "star",
 *            "name": "*"
 *          }
 *        ],
 *        "from": {
 *          "type": "identifier",
 *          "variant": "table",
 *          "name": "animals"
 *        }
 *      }
 *    ]
 *  }
 ******************************************************************************/
export const getEntireEditorTreeNodes = (context: CompletionContext): TreeNode[] => {
  const AST = syntaxTree(context.state);
  let treeNodes: TreeNode[] = [];

  // does this walk left to right?
  AST.iterate({
    enter: (node) => {
      if (!['Script', 'Statement'].includes(node.type.name)) {
        treeNodes.push({
          type: node.type.name,
          text: context.state.sliceDoc(node.from, node.to),
          from: node.from,
          to: node.to,
        });
      }
    },
  });

  return treeNodes;
};
