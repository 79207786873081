import AlertModal from 'components/layouts/containers/modals/AlertModal/AlertModal';

interface ViewerSnapshotModalProps {
  onClose(): void;
}

const ViewerSnapshotModal = (props: ViewerSnapshotModalProps) => {
  return (
    <AlertModal
      header="Users with viewer level permission aren't allowed to toggle the snapshot setting."
      onClose={props.onClose}
    >
      <div className="p-4 f-center">
        Contact your Mozart Data admin to get them to do this for you or get permission to do it
        yourself.
      </div>
    </AlertModal>
  );
};

export default ViewerSnapshotModal;
