export type IntegrationType =
  | 'webapp'
  | 'braze'
  | 'dbt'
  | 'desktop'
  | 'looker'
  | 'python'
  | 'quaeris'
  | 'sigma'
  | 'snowcli'
  | 'snowflake';

export interface Integration {
  key: string;
  type: IntegrationType;
  uiName: string;
}

export type IntegrationMap = {
  [key: string]: Integration;
};

/**
 * ListIntegrations renders this list of Integrations.
 *
 * Key: The unique key in code
 * Key.type = The layout component to use to render the instructions.
 * Key.uiName = The pretty name displayed to the user in the UI
 **/
const integrationMap: IntegrationMap = {
  astrato: {
    key: 'astrato',
    type: 'webapp',
    uiName: 'Astrato',
  },
  braze: {
    key: 'braze',
    type: 'braze',
    uiName: 'Braze',
  },
  census: {
    key: 'census',
    type: 'webapp',
    uiName: 'Census',
  },
  dbt: {
    key: 'dbt',
    type: 'dbt',
    uiName: 'dbt',
  },
  // Excel instructions at: https://interworks.com/blog/hcalder/2019/01/30/query-snowflake-with-excel/
  excel: {
    key: 'excel',
    type: 'desktop',
    uiName: 'Excel',
  },
  explo: {
    key: 'explo',
    type: 'webapp',
    uiName: 'Explo',
  },
  hightouch: {
    key: 'hightouch',
    type: 'webapp',
    uiName: 'Hightouch',
  },
  generic: {
    key: 'generic',
    type: 'desktop',
    uiName: 'Generic',
  },
  looker: {
    key: 'looker',
    type: 'looker',
    uiName: 'Looker',
  },
  metabase: {
    key: 'metabase',
    type: 'desktop',
    uiName: 'Metabase',
  },
  mode: {
    key: 'mode',
    type: 'webapp',
    uiName: 'Mode',
  },
  omni: {
    key: 'omni',
    type: 'desktop', // It's a webapp but disabling link behavior
    uiName: 'Omni',
  },
  popsql: {
    key: 'popsql',
    type: 'webapp',
    uiName: 'PopSQL',
  },
  powerbi: {
    key: 'powerbi',
    type: 'desktop',
    uiName: 'Power BI Desktop',
  },
  python: {
    key: 'python',
    type: 'python',
    uiName: 'Python',
  },
  /* Commenting out Quaeris at Tim's request, for now.  Possible they will be back in the future.
     https://mozartdata.slack.com/archives/C012PFNG868/p1672763398423809

  quaeris: {
    key: 'quaeris',
    type: 'quaeris',
    uiName: 'Quaeris',
  },
  */
  redash: {
    key: 'redash',
    type: 'webapp',
    uiName: 'Redash',
  },
  retool: {
    key: 'retool',
    type: 'webapp',
    uiName: 'Retool',
  },
  sigma: {
    key: 'sigma',
    type: 'sigma',
    uiName: 'Sigma',
  },
  sisense: {
    key: 'sisense',
    type: 'desktop',
    uiName: 'Sisense',
  },
  snowflake: {
    key: 'snowflake',
    type: 'snowflake',
    uiName: 'Snowflake',
  },
  snowcli: {
    key: 'snowcli',
    type: 'snowcli',
    uiName: 'SnowSQL (CLI Client)',
  },
  tableau_online: {
    key: 'tableau_online',
    type: 'webapp',
    uiName: 'Tableau Online',
  },
  tableau_desktop: {
    key: 'tableau_desktop',
    type: 'desktop',
    uiName: 'Tableau Desktop',
  },
  thoughtspot: {
    key: 'thoughtspot',
    type: 'desktop', // It's a webapp but disabling link behavior
    uiName: 'ThoughtSpot',
  },
  zing: {
    key: 'zing',
    type: 'webapp',
    uiName: 'Zing Data',
  },
};

const bigQueryIntegrationMap: IntegrationMap = {
  dbt: integrationMap.dbt,
  generic: integrationMap.generic,
  looker: integrationMap.looker,
  python: integrationMap.python,
};
export { integrationMap, bigQueryIntegrationMap };
