import React from 'react';

import cn from 'classnames';

import AgoHover from 'components/widgets/time/AgoHover/AgoHover';

interface LastSyncColProps {
  incomplete: boolean;
  lastRun: Date | null;
  lastRunFailed: boolean;
}

export default function LastSyncCol({ incomplete, lastRun, lastRunFailed }: LastSyncColProps) {
  if (incomplete) {
    return <div className="text-pri-error-700">N/A</div>;
  }
  if (lastRun === null) {
    return <>Never</>;
  }
  return (
    <div className={cn({ 'text-pri-error-700': lastRunFailed })}>
      <AgoHover date={lastRun} />
    </div>
  );
}
