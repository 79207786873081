import { APIStepKey } from 'model_layer/CompanySetupGuideContext';

import { SetupMethodInstructionsProps } from './GuideStep/SetupMethodInstructions/SetupMethodInstructions';

export interface StepDetails {
  key: APIStepKey;
  number: number;
  label: string;
  detail: string;
  instructions: SetupMethodInstructionsProps[];
}

const guideSteps: StepDetails[] = [
  {
    key: 'get_data',
    number: 1,
    label: 'Connect your data',
    detail: 'Sync data on a schedule OR upload a CSV',
    instructions: [
      {
        label: { key: 'addConnector', label: 'Add Connector' },
        videoUrl: 'https://www.youtube.com/embed/prbCDfiIdaM',
        steps: [
          'Click Connectors on the header.',
          "Click the 'Add Connector' button.",
          'Select your data source.',
          'Enter the destination schema name to load the source tables into.',
          "Enter your connection details in the form and click 'Test & Run'.",
          'Syncing completes in minutes or hours depending on the volume of data.',
        ],
        learnMore: {
          label: 'Learn more about Connectors',
          url: 'https://help.mozartdata.com/docs/connectors',
          segmentEvent: 'CompanySetupGuide LinkToLearnMoreAboutConnectors',
        },
      },
      {
        label: { key: 'uploadCSV', label: 'Upload CSV' },
        videoUrl: 'https://www.youtube.com/embed/8oLS9VzwbHU',
        steps: [
          "Click the '+' sign on the header.",
          "Select 'Upload CSV'.",
          'Enter the destination schema and table name to upload the CSV to.',
          'Select a file from your computer.',
          'Click Upload.',
        ],
        learnMore: {
          label: 'Learn more about CSV Uploader',
          url: 'https://help.mozartdata.com/docs/csv-uploader',
          segmentEvent: 'CompanySetupGuide LinkToLearnMoreAboutCSVs',
        },
      },
    ],
  },
  {
    key: 'explore_data',
    number: 2,
    label: 'Explore your data',
    detail: 'Survey what data you have. Get familiar with your data. Do ad-hoc analysis.',
    instructions: [
      {
        label: { key: 'query', label: 'Query' },
        videoUrl: 'https://www.youtube.com/embed/cZ3MLGoxuVE',
        steps: [
          'Click Query on the header.',
          'The column on the right is the Table Explorer. It lists every table you can query. Note the Table Explorer is under this guide and you will have to minimize this guide to see it.',
          "Clicking on a table in the Table Explorer shows you the table's columns and other details of that table.",
          'Type a SQL query in the editor window.',
          "Click the query's tab to name it.",
          'Share your query with a colleague or save it as a transform.',
        ],
        learnMore: {
          label: 'Learn more about Saved Queries',
          url: 'https://help.mozartdata.com/docs/queries',
          segmentEvent: 'CompanySetupGuide LinkToLearnMoreAboutQueries',
        },
      },
    ],
  },
  {
    key: 'transform_data',
    number: 3,
    label: 'Transform your data',
    detail: 'Sync data on a schedule OR upload a CSV',
    instructions: [
      {
        label: { key: 'addTransform', label: 'Add Transform' },
        videoUrl: 'https://www.youtube.com/embed/MUi3wPj6O1I',
        steps: [
          "Click the '+' sign on the header.",
          "Click 'Create Transform'.",
          'Enter the destination schema and table name for the transform.',
          'Type a SQL query in the editor window.',
          "Click 'Run' (cmd + return) to test the query and see sample results.",
          "Schedule the transform to refresh the transform's output table on a set schedule.",
          "Go to the 'Runs' tab and click on the 'Run' button to create the transform's output table immediately.",
        ],
        learnMore: {
          label: 'Learn more about Transforms',
          url: 'https://help.mozartdata.com/docs/transforms',
          segmentEvent: 'CompanySetupGuide LinkToLearnMoreAboutTransforms',
        },
      },
      {
        label: { key: 'dbt', label: 'dbt' },
        videoUrl: 'https://www.youtube.com/embed/9Bw2qr15IcU',
        steps: [
          'Click dbt on the header.',
          "Click the 'Add Job' button to create and schedule a new dbt job.",
          'Enter job name, git repo, target schema, and dbt commands.',
          'Click Save.',
        ],
        learnMore: {
          label: 'Learn more about dbt Core in Mozart Data',
          url: 'https://help.mozartdata.com/docs/dbt-core-integration',
          segmentEvent: 'CompanySetupGuide LinkToLearnMoreAboutDBT',
        },
      },
    ],
  },
  {
    key: 'use_data',
    number: 4,
    label: 'Use your data',
    detail: 'Add a BI Tool with the Integrations page or sync your data to a google sheet.',
    instructions: [
      {
        label: { key: 'addIntegration', label: 'Add Integrations' },
        videoUrl: 'https://www.youtube.com/embed/DSAzgxntKHU',
        steps: [
          'Click Integrations on the header.',
          "Click on the integration you want to connect. If your tool is not listed on the page, scroll down and click on the 'Generic Instructions' button.",
          'The top of the page will contain instructions to follow to connect your chosen tool.',
          'The bottom of the page has the connection info you need to enter in your chosen tool. ',
          'At the bottom of the page, there is a button to Generate a Snowflake user that is dedicated to connecting this tool.',
          'We recommend you save the credentials of this new user in a secure location such as a password manager.',
        ],
        learnMore: {
          label: 'Learn more about Integrations',
          url: 'https://help.mozartdata.com/docs/integrations',
          segmentEvent: 'CompanySetupGuide LinkToLearnMoreAboutIntegrations',
        },
      },
      {
        label: { key: 'sheetSync', label: 'Google Sheet Sync' },
        videoUrl: 'https://www.youtube.com/embed/9Yi9tg1w65Y',
        steps: [
          'Click Warehouse on the header.',
          'Click on the name of the table you want to sync to a Google Sheet.',
          "Click the 'Sheet Sync' tab.",
          "Click the 'Add Destination' button which is hidden under this guide.",
          'Follow the first two steps to grant edit access to Mozart Data.',
          'Copy & paste the url of the destination Google Sheet into Mozart Data.',
          'Make sure the table is on a refresh or sync schedule to ensure fresh data is exported at a certain cadence.',
        ],
        learnMore: {
          label: 'Learn more about Google Sheets Sync',
          url: 'https://help.mozartdata.com/docs/google-sheet-sync',
          segmentEvent: 'CompanySetupGuide LinkToLearnMoreAboutGoogleSheetSync',
        },
      },
    ],
  },
];

export default guideSteps;
