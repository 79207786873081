/* eslint-disable no-console */
import { useCallback, useRef, DependencyList, useEffect } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';
import { usePrevious } from 'react-use';

/*
componentDidUpdate(prevProps: any, prevState: any) {
  logChanged(prevProps, this.props);
  logChanged(prevState, this.state);
}
*/
export function logChanged(prev: any, current: any) {
  if (typeof prev !== 'object') {
    console.log('    prev is not an object', prev);
    return;
  }
  if (typeof current !== 'object') {
    console.log('    current is not an object', current);
    return;
  }
  Object.keys(prev)
    .filter((key) => {
      return prev[key] !== current[key];
    })
    .forEach((key) => {
      console.log('    changed property:', key, 'from', prev[key], 'to', current[key]);
    });
}

/*
// Logs changed props when a component renders
const MyComponent = (props) => {
  useLogChanged('MyComponent', props);
}
*/
export function useLogChanged(componentNameOrLabel: string, props: any) {
  useEffect(
    () => {
      console.log(`${componentNameOrLabel}.onMount()`, props);
      return () => {
        console.log(`${componentNameOrLabel}.onUnmount()`, props);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  console.log(`${componentNameOrLabel}.render()`);
  const prev = usePrevious(props);
  // eslint-disable-next-line no-restricted-syntax
  logChanged(prev, props);
}

/*
// Just replace `useCallback` with `useLogCallback` and pass in a function name as the first function parameter
const myCallback = useLogCallback(
  'myCallback',
  ()=>{
    // Closure on dep1, dep2, dep3
  },
  [dep1, dep2, dep3] // Changes in these will get logged when myFunction is redefined.
});
*/
export function useLogCallback<T extends (...args: any[]) => any>(
  functionNameOrKey: string,
  callback: T,
  hookDependencies: DependencyList,
) {
  // eslint-disable-next-line no-restricted-syntax
  useLogChanged(functionNameOrKey, hookDependencies);
  return useCallback(callback, hookDependencies); // eslint-disable-line react-hooks/exhaustive-deps
}

export function logEqual(prev: any, current: any) {
  let equal = true;
  Object.keys(prev).forEach((key) => {
    if (prev[key] !== current[key]) {
      equal = false;
      console.log('    changed property:', key, 'from', prev[key], 'to', current[key]);
    }
  });
  return equal;
}

export function downloadFile(fileName: string, data: any) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}

export function useFocus(): [React.MutableRefObject<null>, () => void] {
  const ref = useRef(null);
  const setFocus = () => {
    if (ref.current) {
      (ref.current as any).focus();
    }
  };

  return [ref, setFocus];
}

export function useSearchFocus(): [React.RefObject<HTMLInputElement>, () => void] {
  const ref = useRef<HTMLInputElement>(null);
  const setFocus = useCallback(() => {
    if (ref.current) {
      (ref.current as any).focus();
    }
  }, []);

  useHotkeys(
    'ctrl+., ⌘+.',
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setFocus();
    },
    // This is theoretically a problem if there are two useSearchFocus()es on the page at the same time.
    // As of 2022/08/02, this only happens when the TagPicker is open on the Warehouse.
    // So we don't care because a user might not ever try the key binding in that scenario.
    { enableOnTags: ['TEXTAREA', 'INPUT'], enableOnContentEditable: true },
    [setFocus],
  );

  return [ref, setFocus];
}
