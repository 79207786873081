import React, { useEffect, useRef, useState } from 'react';

import { parseIso, formatDuration } from 'utils/dateTime';

export interface DurationsProps {
  start: string | Date | null;
  end: string | Date | null;
  adjustment?: number; // Maybe you want to adjust the duration
  interval?: number; // miliseconds
  disableInterval?: boolean; // Lots of intervals are slow. Disable on long lists.
  className?: string;
}

export default function Durations(props: DurationsProps) {
  const { start, end, adjustment, interval, disableInterval, className } = props;
  const startObj = typeof start === 'string' ? parseIso(start) : start;
  const endObj = typeof end === 'string' ? parseIso(end) : end;
  const duration = useDuration(startObj, endObj, adjustment, interval, disableInterval);

  return <span className={className}>{duration}</span>;
}

const ONCE_A_MINUTE = 1000 * 60;
export function useDuration(
  start: Date | null,
  end: Date | null,
  adjustment: number = 0,
  interval: number = ONCE_A_MINUTE,
  disableInterval?: boolean,
) {
  const [duration, setDuration] = useState(getDuration(start, end));
  const timerRef = useRef<NodeJS.Timer | undefined>(undefined);

  useEffect(() => {
    // Immediately set duration
    setDuration(getDuration(start, end, adjustment));

    // Stop the timer it end is set.
    if (end !== null && timerRef.current) {
      clearInterval(timerRef.current);
    }

    // Set an interval to update duration
    if (!disableInterval && end === null) {
      timerRef.current = setInterval(() => {
        setDuration(getDuration(start, end, adjustment));
      }, interval);
      return () => {
        clearInterval(timerRef.current);
      };
    }
  }, [start, end, adjustment, disableInterval, interval]);

  return duration;
}

export function getDuration(start: Date | null, end: Date | null, adjustment: number = 0) {
  if (start === null) {
    return null;
  }

  if (end === null) {
    end = new Date();
  }

  return formatDuration(end.getTime() - start.getTime() + adjustment);
}
