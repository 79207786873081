import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import LinkButton from 'components/inputs/basic/Button/LinkButton';
import TextInput from 'components/inputs/basic/TextInput/TextInput';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import Alert from 'components/widgets/alerts/Alert/Alert';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import PlatformCard, { PlatformCardProps } from 'components/widgets/PlatformCard/PlatformCard';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';
import useTrackFilter from 'hooks/useTrackFilter';
import { useSearchFocus } from 'utils/React';

import { integrationMap, bigQueryIntegrationMap } from './Integration';
import { iconUrl } from './integrationUtils';

const ListIntegrations = () => {
  useTitle('Integrations');
  const [integrationFilter, setIntegrationFilter] = useState('');
  const reportOnBlur = useTrackFilter('ListIntegrations', integrationFilter);
  const [filterRef] = useSearchFocus();
  const navigate = useNavigate();
  const {
    userProfile: { company_role },
  } = useUserProfile();
  const { type: databaseType, displayName: databaseDisplayName } = useDatabaseAccount();

  let filteredIntegrations = Object.keys(
    databaseType === 'bigquery' ? bigQueryIntegrationMap : integrationMap,
  ).filter((integrationKey: string) => {
    return integrationKey !== 'generic';
  });
  if (integrationFilter) {
    filteredIntegrations = filteredIntegrations.filter((integrationKey: string) => {
      const name = integrationMap[integrationKey].uiName.toLowerCase();
      return name.includes(integrationFilter);
    });
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIntegrationFilter(event.target.value.toLowerCase());
  };

  const handleClick = (platform: string) => {
    analytics.track('ListIntegrations Select', { platform });
    navigate(`/integrations/${platform}`);
  };

  return (
    <CenteredLayout maxWidth="814px" title="Select an integration you would like to connect">
      {company_role === 'viewer' && (
        <Alert variant="info" className="my-2">
          Viewers cannot connect integrations. Please contact your admin to connect integrations.
        </Alert>
      )}
      <TextInput
        name="search"
        ref={filterRef}
        placeholder="Search"
        value={integrationFilter}
        onChange={handleFilterChange}
        onBlur={reportOnBlur}
        autoFocus
        className="mt-2 mb-4"
        style={{ maxWidth: '275px' }}
        maxLength={200}
      />
      {filteredIntegrations.length > 0 ? (
        <div className="grid grid-cols-3 gap-x-4 gap-y-6">
          {filteredIntegrations.map((platform) => {
            return (
              <IntegrationCard
                key={platform}
                platform={platform}
                disabled={company_role === 'viewer'}
                onClick={() => handleClick(platform)}
              />
            );
          })}
        </div>
      ) : (
        <NoMatchesFoundAlert heading="No matches found." className="my-4" />
      )}
      <GenericIntegrationAlert databaseDisplayName={databaseDisplayName} />
    </CenteredLayout>
  );
};

export default ListIntegrations;

const GenericIntegrationAlert = (props: { databaseDisplayName: string }) => {
  const { databaseDisplayName } = props;
  return (
    <div className="mt-4 px-[3.75rem] py-[2.5rem] flex flex-col bg-[--sec-blue-50]">
      <div className="flex flex-row">
        <img src="/images/graphics/integrations/i-have-question-3.svg" alt="" className="w-[220px]" />
        <div className="flex flex-col justify-center">
          <div className="ml-8 text-pri-gray-500">
            <h2 className="text-lg font-medium">Don't see your favorite tool?</h2>
            <p className="mt-2">
              These credentials will work for any tool that can connect to {databaseDisplayName}.
            </p>
            <LinkButton
              variant="darkAction"
              className="mt-6"
              to="/integrations/generic"
              data-track="ListIntegrations SelectGeneric"
            >
              Generic Instructions
            </LinkButton>
          </div>
        </div>
      </div>
      <div className="mt-6 h-[1px] w-full bg-pri-gray-200" />
      <div className="mt-6 text-pri-gray-500">
        <h2 className="text-lg font-medium">Not sure which BI tool is right for you?</h2>
        <p className="mt-2">
          Read our{' '}
          <a
            href="https://www.mozartdata.com/post/how-to-pick-a-business-intelligence-tool"
            rel="noopener noreferrer"
            target="_blank"
            className="text-sec-blue-light-700"
            data-track="ListIntegrations PickBIToolIntegration"
          >
            How to Pick a Business Intelligence Tool
          </a>{' '}
          guide or{' '}
          <a className="text-sec-blue-light-700" href="mailto:support@mozartdata.com">
            Ask Support
          </a>
          .
        </p>
      </div>
    </div>
  );
};

// Passing a dynamic React Node in as a prop slows down performance noticeably.
// This wrapper saves a lot of memory allocation.
// Memoizing should also help on rerenders as the user types.
interface IntegrationCardProps extends Omit<PlatformCardProps, 'icon' | 'name'> {
  platform: string;
}

const IntegrationCard = React.memo((props: IntegrationCardProps) => {
  const { platform, ...rest } = props;

  const integration = integrationMap[platform];

  const icon = (
    <img
      className="w-12 h-12 object-contain"
      src={iconUrl(platform)}
      alt={`${integration.uiName} logo`}
    />
  );

  return <PlatformCard icon={icon} name={integration.uiName} {...rest} />;
});
