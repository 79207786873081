import LinkIconButton from 'components/inputs/basic/Button/LinkIconButton';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { UserAction } from 'pages/user_actions/usePagedUserActions';

import UserActionsWidgetItem from './UserActionsWidgetItem/UserActionsWidgetItem';

export interface UserActionsWidgetState {
  allLoaded: boolean;
  anyError: boolean;
  userActions: UserAction[];
  onClickViewAll(): void;
}

export interface UserActionsWidgetProps extends UserActionsWidgetState {
  className?: string;
  contentClassName?: string;
}

const UserActionsWidget = (props: UserActionsWidgetProps) => {
  const { allLoaded, anyError, userActions, className, contentClassName, onClickViewAll } = props;

  let content: JSX.Element;
  if (!allLoaded) {
    content = <CenteredSpinner type="circle" />;
  } else if (anyError) {
    // TODO(homepage-v1): See if we can add a refresh button to retry fetching user actions
    content = (
      <div>
        <Alert variant="error">
          There was an error loading user actions. Refresh the page to try again.
        </Alert>
      </div>
    );
  } else if (userActions.length === 0) {
    content = <div className="text-pri-gray-500">Company-wide user actions will be displayed here.</div>;
  } else {
    content = (
      <div>
        {userActions.map((userAction) => {
          return <UserActionsWidgetItem key={userAction.id} userAction={userAction} />;
        })}
      </div>
    );
  }

  return (
    <div className={className}>
      <div className={contentClassName}>
        <div className="flex items-flex-start justify-between">
          <h1 className="text-lg font-medium mb-4">User Actions</h1>
          <LinkIconButton
            icon="ChevronRight"
            text="View All"
            iconLast
            size="small"
            variant="lightDullTransparent"
            to="/user-actions"
            onClickCapture={onClickViewAll}
            className="uppercase"
          />
        </div>
        {content}
      </div>
    </div>
  );
};

export default UserActionsWidget;
