/*
Use this when you want to give the user an FYI they can dismiss, AND
it's clear that the rendering of this FYI should not be controlled by some other piece of code.
For example, DO NOT use this for API errors or <FixMeAlert/> type situations.

Protip:
You probably want to add separating space to this elements className because it is condtionally rendered:
Example: <DismissibleAlert className="mb-4"/>
*/
import { X } from 'react-bootstrap-icons';

import Alert, { AlertProps, Variant } from '../Alert/Alert';

const VARIANT_COLORS: Record<Variant, string> = {
  error: 'var(--pri-error-700)',
  kinda_bad: 'var(--sec-orange-700)',
  warning: 'var(--pri-warning-700)',
  no_results: 'var(--purple)',
  info: 'var(--pri-gray-500)',
};

interface DismissibleAlertProps extends AlertProps {
  show: boolean;
  onClose(): void;
}

export default function DismissibleAlert(props: DismissibleAlertProps) {
  const { children, show, onClose, ...rest } = props;

  if (!show) {
    return null;
  }

  return (
    <Alert {...rest}>
      <div className="w-full f-between">
        <div className="w-full f-center">{children}</div>
        <X
          size="24"
          color={VARIANT_COLORS[props.variant]}
          onClick={onClose}
          className="hover:cursor-pointer"
        />
      </div>
    </Alert>
  );
}
