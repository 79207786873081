import { AggTable, PickedItemKey, SchemaName } from 'api/tableAPI';
import Button from 'components/inputs/basic/Button/Button';
import { SchemaIconType } from 'components/primitives/icons/SchemaIcon/SchemaIcon';

import { isAggTable } from '../useAIAssistantTablePicker';

import AIAssistantTablePickerPickedItem from './AIAssistantTablePickerPickedItem';

interface AIAssistantTablePickerPickedItemsProps {
  pickedItems: Record<PickedItemKey, AggTable | SchemaIconType>;
  unpickTable: (table: AggTable) => void;
  unpickSchema: (schema: SchemaName) => void;
  onClearSelection: () => void;
}

export default function AIAssistantTablePickerPickedItems(
  props: AIAssistantTablePickerPickedItemsProps,
) {
  const { pickedItems, unpickTable, unpickSchema, onClearSelection } = props;
  return (
    <div className="flex justify-between my-1">
      <div className="mx-2 my-1 flex flex-wrap gap-2">
        {Object.entries(pickedItems).map(([key, value]) => {
          return isAggTable(value) ? (
            <AIAssistantTablePickerPickedItem
              key={key}
              name={value.full_name}
              onUnpickItem={() => unpickTable(value)}
            />
          ) : (
            <AIAssistantTablePickerPickedItem
              key={key}
              name={key}
              iconType={value}
              onUnpickItem={() => unpickSchema(key)}
            />
          );
        })}
      </div>
      <div className="w-20 flex justify-center mx-4 mt-1">
        <Button onClick={onClearSelection} variant="lightDullTransparent" className="ml-2" size="small">
          Clear All
        </Button>
      </div>
    </div>
  );
}
