import { Transform } from 'api/APITypes';
import { calcGenericRunStatus } from 'model_helpers/transformHelper';
import { parseIso } from 'utils/dateTime';

import AbstractTableSorter, {
  SCHEDULE_FREQUENCY,
  LAST_RUN_AGO,
  LAST_RUN_STATUS,
} from './AbstractTableSorter';

class TransformSorter extends AbstractTableSorter<Transform> {
  schedule(transform: Transform): number {
    const { scheduled, schedule_mode, schedule } = transform;

    if (!scheduled) {
      return SCHEDULE_FREQUENCY.PAUSED_OR_NOT_SCHEDULED;
    }
    if (schedule_mode === 'cron') {
      return this.approximateScheduleFrequency(schedule);
    }
    if (schedule_mode === 'all_marked') {
      return SCHEDULE_FREQUENCY.AFTER_ALL;
    }
    if (schedule_mode === 'any_marked') {
      return SCHEDULE_FREQUENCY.AFTER_ANY;
    }

    return SCHEDULE_FREQUENCY.PAUSED_OR_NOT_SCHEDULED;
  }

  lastRunAgo(transform: Transform) {
    const { last_completed_run } = transform;

    if (last_completed_run) {
      const parsedTime = parseIso(last_completed_run.created_at);
      if (parsedTime) {
        return new Date().getTime() - parsedTime.getTime();
      }
    }

    return LAST_RUN_AGO.NEVER_RAN;
  }

  runStatus(transform: Transform) {
    const runStatus = calcGenericRunStatus(transform);
    return LAST_RUN_STATUS[runStatus];
  }

  approximateScheduleFrequency(schedule: string) {
    let frequency = this.standardScheduleFrequencyMap[schedule];
    // If it's a custom cron assume its a very long interval that is less
    // than the values we use for no schedule because that's easy.
    // If we want to do better than that, we probably need to use an NPM module on par
    // with https://github.com/harrisiirak/cron-parser
    return frequency ?? SCHEDULE_FREQUENCY.UNKNOWN_CRON;
  }

  // All values are in minutes
  standardScheduleFrequencyMap: { [key: string]: number } = {
    '0 * * * *': 60, // 'Every Hour',
    '0 */2 * * *': 60 * 2, // 'Every 2 Hours',
    '0 */6 * * *': 60 * 6, // 'Every 6 Hours',
    '0 */12 * * *': 60 * 12, // 'Every 12 Hours',
    '0 9 * * *': 60 * 24, // 'Once a Day',
    '0 9 * * 0': 60 * 24 * 7, // 'Once a Week',
    '0 9 1 * *': 60 * 24 * 30, // 'Once a Month',
  };
}

const staticInstance = new TransformSorter();

export default staticInstance;
