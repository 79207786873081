import { AggTable } from 'api/tableAPI';
import UserHover from 'components/overlay/UserHover/UserHover';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import {
  numRowsAsString,
  lastRunDateAsString,
  lastRunAgo,
  duration,
} from 'model_helpers/aggTableHelper';
import { calcGenericRunStatus } from 'model_helpers/csvUploadHelper';
import { formatDuration } from 'utils/dateTime';

import SidebarDescription from './SidebarDescription';
import VertexTableSection, { VertexTableSectionRow } from './VertexTableSection';

interface CSVUploadContentProps {
  table: AggTable;
}

export default function CSVUploadContent(props: CSVUploadContentProps) {
  const { table } = props;
  const { csvUpload } = table;

  if (!csvUpload) {
    return null;
  }

  const { file_name, error_message, creator_user_profile } = csvUpload;

  const rows: VertexTableSectionRow[] = [
    { label: 'Filename', value: file_name },
    { label: 'Status', value: <StatusIcon status={calcGenericRunStatus(csvUpload)} /> },
  ];

  if (error_message) {
    rows.push({ label: 'Error', value: <div className="w-full break-words">{error_message}</div> });
  }

  rows.push(
    ...[
      { label: 'Uploaded By', value: <UserHover userProfile={creator_user_profile} /> },
      { label: 'Completed At', value: lastRunDateAsString(table) },
      { label: 'Completed Ago', value: lastRunAgo(table) },
      { label: 'Duration', value: formatDuration(duration(table)) || 'In Progress' },
      { label: 'Rows', value: numRowsAsString(table) },
      { label: 'Table Type', value: table.database_table_type },
    ],
  );

  return (
    <div>
      <SidebarDescription description={table.description} />
      <VertexTableSection title="Last Upload" rows={rows} className="mt-6" />
    </div>
  );
}
