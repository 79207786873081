import React from 'react';

import cn from 'classnames';

export interface PlatformCardProps {
  icon: React.ReactNode;
  name: string;
  disabled?: boolean;
  onClick: () => void;
}

export default function PlatformCard(props: PlatformCardProps) {
  const { icon, name, disabled, onClick } = props;

  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={cn('w-full flex items-center p-2 rounded', {
        'cursor-pointer hover:bg-pri-gray-100': !disabled,
      })}
    >
      {icon}
      <div className="font-medium ml-3">{name}</div>
    </div>
  );
}
