/**
 * NOT USED AS OF 04/12/2023:
 * We are holding onto this code incase this comes back.
 * The initial version of ReportBuilder allowed users to pick any combination of reports.
 * Due to the low volume of charts on mostly related subjects for each connector as of April 2023,
 * the analysts merged all charts we had created for a given connector into one report.
 * So, there was no point in picking one of one reports.
 * This UI is now bypassed in favor of simpler UI.
 */
import { useState } from 'react';

import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import deepEqual from 'fast-deep-equal';
import { differenceBy, pick, sortBy } from 'lodash';

import { Connector } from 'api/APITypes';
import { Dashboard, ReportAvailable, ReportInstalled } from 'api/dashboardAPI';

import { trackConnector } from '../../ConnectorUtils';

import { connectorPath, laterPath, reviewTransformsPath, showReportPath } from '../pathUtils';
import { trackDashboard } from '../reportUtils';
import useDashboardMutations from '../useDashboardMutations';

import DeprecatedPickReportsView from './DeprecatedPickReportsView';

interface PickReportsControllerProps {
  connector: Connector;
  dashboard?: Dashboard;
  reportsAvailable: ReportAvailable[];
}

export default function PickReportsController(props: PickReportsControllerProps) {
  useTitle('Pick Reports');
  const { connector, dashboard, reportsAvailable } = props;

  const fullReportsInstalled = dashboard?.reports || [];
  const reportsInstalled = fullReportsInstalled.map((fr) => pick(fr, ['key']) as ReportInstalled);
  const isNewDashboard = reportsInstalled.length === 0;

  const [reportsPicked, setReportsPicked] = useState<ReportInstalled[]>(
    isNewDashboard
      ? reportsAvailable.map((ra) => pick(ra, ['key']) as ReportInstalled)
      : reportsInstalled,
  );
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const reportsToRemove = differenceBy(reportsInstalled, reportsPicked, 'key');

  // Did we pick the currently saved set of reports in any order?
  const saveDisabled = deepEqual(sortBy(reportsInstalled, 'key'), sortBy(reportsPicked, 'key'));

  const handleChange = (key: string, checked: boolean) => {
    if (checked) {
      setReportsPicked([...reportsPicked, { key: key }]);
    } else {
      setReportsPicked(reportsPicked.filter((rp) => rp.key !== key));
    }
  };

  const navigate = useNavigate();

  const {
    createLoading,
    updateLoading,
    deleteLoading,
    createError,
    updateError,
    deleteError,
    createDashboard,
    updateDashboard,
    deleteDashboard,
  } = useDashboardMutations(connector, dashboard);

  const saving = createLoading || updateLoading || deleteLoading;
  const savingError = createError || updateError || deleteError;

  const handleCreate = (reportsPicked: ReportInstalled[]) => {
    createDashboard(reportsPicked).then((dashboard: Dashboard) => {
      trackDashboard('ReportBuilderPickReports CreateDashboard', connector, dashboard);
      navigate(reviewTransformsPath(connector));
    });
  };

  const handleUpdate = (reportsPicked: ReportInstalled[]) => {
    updateDashboard(reportsPicked).then(() => {
      trackDashboard('ReportBuilderPickReports UpdateDashboard', connector, dashboard as Dashboard);
      navigate(reviewTransformsPath(connector));
    });
  };

  const handleDelete = () => {
    deleteDashboard().then(() => {
      trackDashboard('ReportBuilderPickReports DeleteDashboard', connector, dashboard as Dashboard);
      navigate(connectorPath(connector));
    });
  };

  const handleOnSave = () => {
    if (!dashboard) {
      handleCreate(reportsPicked);
    } else {
      if (reportsToRemove.length > 0) {
        trackDashboard('ReportBuilderPickReports OpenConfirmDeleteReport', connector, dashboard);
        setShowConfirmDelete(true);
      } else {
        handleUpdate(reportsPicked);
      }
    }
  };

  const handleDeleteConfirmed = () => {
    if (reportsPicked.length === 0) {
      handleDelete();
    } else {
      handleUpdate(reportsPicked);
    }
  };

  const handleDeleteCanceled = () => {
    setShowConfirmDelete(false);
    trackDashboard(
      'ReportBuilderPickReports CancelConfirmDeleteReport',
      connector,
      dashboard as Dashboard,
    );
  };

  const handleOnCancel = () => {
    if (dashboard) {
      trackDashboard('ReportBuilderPickReports CancelToListConnectors', connector, dashboard);
      navigate(showReportPath(connector));
    } else {
      trackConnector('ReportBuilderPickReports CancelToLater', connector);
      navigate(laterPath(connector));
    }
  };

  return (
    <DeprecatedPickReportsView
      connector={connector}
      reportsAvailable={reportsAvailable}
      reportsPicked={reportsPicked}
      reportsToRemove={reportsToRemove}
      isNew={!dashboard}
      saveDisabled={saveDisabled}
      saving={saving}
      savingError={savingError}
      showConfirmDelete={showConfirmDelete}
      onChange={handleChange}
      onSave={handleOnSave}
      onCancel={handleOnCancel}
      onDeleteConfirmed={handleDeleteConfirmed}
      onDeleteCanceled={handleDeleteCanceled}
    />
  );
}
