// Create a new array with the given element being replaced.
export function patch<T>(array: T[], newVal: T, replaceValFinder: (e: T) => boolean): T[] {
  const i = array.findIndex(replaceValFinder);

  if (i === -1) {
    return array;
  }

  const start = array.slice(0, i);
  const end = array.slice(i + 1);
  return [...start, newVal, ...end];
}

// Create a new array with the given element being replaced or
// appened to the end if it is not present in the original.
export function patchOrAppend<T>(array: T[], newVal: T, replaceValFinder: (e: T) => boolean): T[] {
  const i = array.findIndex(replaceValFinder);

  if (i === -1) {
    return [...array, newVal];
  }

  const start = array.slice(0, i);
  const end = array.slice(i + 1);
  return [...start, newVal, ...end];
}

export function insertAt<T>(array: T[], newVal: T, insertAt: number): T[] {
  const start = array.slice(0, insertAt);
  const end = array.slice(insertAt);
  return [...start, newVal, ...end];
}

export function moveTo<T>(array: T[], fromIndex: number, toIndex: number): T[] {
  const fromItem = array[fromIndex];
  const withoutFrom = array.filter((v, i) => i !== fromIndex);
  // Insert the fromItem right before the toItem,
  // unless the fromItem is immediately before the toItem.
  // In this case, swap them.
  const fromIsRightBeforeTo = fromIndex + 1 === toIndex;
  let adjustedToIndex = toIndex;
  if (fromIndex < toIndex && !fromIsRightBeforeTo) {
    adjustedToIndex = toIndex - 1;
  }
  return insertAt(withoutFrom, fromItem, adjustedToIndex);
}

// Replaces elements from oldArray with matching elements of newArray such that
// oldArray's order is maintained.
// Note: Elements of newArray not preseent in oldArray will not be included.
export function mapReplace<T>(oldArray: T[], newArray: T[], equal: (a: T, b: T) => boolean): T[] {
  return oldArray.map((oldEle: T) => newArray.find((newEle: T) => equal(oldEle, newEle)) || oldEle);
}

// Backfill nulls in an array with the previous non-null value in the array
// Reverses array before and after mapping because it expects data in order from newest to oldest
// The order of the mapping matters because as we go through we track lastDefinedElement
export function backfillNulls<T>(array: (T | null)[]): (T | null)[] {
  let lastDefinedElement: T | null = null;
  return array
    .reverse()
    .map((element) => {
      if (element === null) {
        element = lastDefinedElement;
      }

      lastDefinedElement = element;
      return element;
    })
    .reverse();
}

export interface HasID {
  id: string;
}

// Replaces objects in oldItems with objects in newItems.
// Objects are compared by ID.
// If there are any newItems that are not in oldItems, append them to the end of the new list.
export function updateManyByID<T extends HasID>(oldItems: T[], newItems: T[]) {
  const newItemIDs = newItems.map((item) => item.id);
  let newMasterItems = oldItems.map((item) =>
    newItemIDs.includes(item.id) ? newItems.find((t) => t.id === item.id) : item,
  ) as T[];
  // Don't forget to append newTables that were not previously in tables
  const newMasterItemIDs = newMasterItems.map((item) => item.id);
  const newItemsToAppend = newItems.filter((t) => !newMasterItemIDs.includes(t.id));
  newMasterItems.push(...newItemsToAppend);
  return newMasterItems;
}

export function regexFrom(strings: (string | RegExp)[], flags: string) {
  const pattern = strings
    // Escape special characters
    .map((s) => {
      if (typeof s === 'string') {
        return s.replace(/[()[\]{}*+?^$|#.,/\\\s-]/g, '\\$&');
      } else {
        return s.toString().slice(1, -1); // Remove leading and trailing slashes on regex
      }
    })
    // Sort for maximal munch
    .sort((a, b) => b.length - a.length)
    .join('|');
  // react-string-replace requires words to be wrapped in matching groups
  const wrappedPattern = '(' + pattern + ')';
  return new RegExp(wrappedPattern, flags);
}
