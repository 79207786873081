import React, { useCallback } from 'react';

import { AggTable } from 'api/APITypes';
import IconButton from 'components/inputs/basic/Button/IconButton';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';

import CopyButton, { HandleCopy } from '../CopyButton';

import { ROW_HEIGHT } from './TableRow';

interface TableRowHoverOverlayProps {
  hoveredIndex: number;
  table: AggTable;
  selected: boolean;
  pinned: boolean;
  pinnedBySql: boolean;
  showCopied: boolean;
  overlayRight: number;
  editMode: boolean;
  onManuallyPinTable(tableId: string, pinned: boolean): void;
  onInsertTableQuery(table: AggTable): void;
  onCopy: HandleCopy;
}

const TableRowHoverOverlay = React.memo((props: TableRowHoverOverlayProps) => {
  const {
    hoveredIndex,
    table,
    selected,
    pinned,
    pinnedBySql,
    showCopied,
    overlayRight,
    onCopy,
    onInsertTableQuery,
    onManuallyPinTable,
    editMode,
  } = props;

  const handleInsertTableQuery = useCallback(
    (e: any) => {
      e.stopPropagation();
      onInsertTableQuery(table);
    },
    [table, onInsertTableQuery],
  );

  const handleManuallyPinTable = useCallback(
    (e: any) => {
      e.stopPropagation();
      onManuallyPinTable(table.id, !pinned);
    },
    [table.id, pinned, onManuallyPinTable],
  );

  let pinnedTooltip = pinned
    ? 'Remove this table from the pinned list.'
    : 'Add this table to the pinned list.';
  if (pinnedBySql) {
    pinnedTooltip = 'This table is used in your query. It is automatically pinned.';
  }

  return (
    <div
      className="tt-table-buttons"
      style={{
        height: `${ROW_HEIGHT}px`,
        top: `${hoveredIndex * ROW_HEIGHT}px`,
        right: `${overlayRight}px`,
      }}
    >
      <div className={'f-row-y-center ' + (selected ? 'bg-sec-blue-gray-100' : 'bg-pri-gray-100')}>
        <TooltipTrigger tip={pinnedTooltip}>
          <IconButton
            icon={pinned ? 'PinFill' : 'Pin'}
            onClick={handleManuallyPinTable}
            size="small"
            variant="lightDullTransparent"
            disabled={pinnedBySql}
          />
        </TooltipTrigger>
        {editMode && (
          <TooltipTrigger tip="Run select query in editor.">
            <IconButton
              icon="BoxArrowLeft"
              onClick={handleInsertTableQuery}
              size="small"
              variant="lightDullTransparent"
              iconSize={16}
            />
          </TooltipTrigger>
        )}
        <TooltipTrigger tip="Copy table name." forceShow={showCopied === true ? false : undefined}>
          <CopyButton value={table.full_name} onCopy={onCopy} />
        </TooltipTrigger>
      </div>
    </div>
  );
});

export default TableRowHoverOverlay;
