import InputCopy from 'components/inputs/composite/InputCopy/InputCopy';

import { fieldValue, Field } from './fieldUtils';
import { DatabaseConfigInfoResponse } from './IntegrationSnowflakeConfig';

export interface SnowflakeConfigInfoListProps {
  header: string;
  fields: Field[];
  values: DatabaseConfigInfoResponse;
  className?: string;
}

const SnowflakeConfigList = (props: SnowflakeConfigInfoListProps) => {
  const { header, fields, values, className } = props;

  return (
    <div className={className}>
      <h2 className="text-xl font-medium my-2">{header}</h2>
      {values && (
        <form>
          {fields.map((field: Field) => {
            const { uiName, key } = field;
            const isHiddenField = field.key === 'password' || field.key === 'snowflake_admin_password';
            return (
              <InputCopy
                isHiddenField={isHiddenField}
                label={uiName}
                value={fieldValue(values, key)}
                analyticsEvent="IntegrationCredentials AnyCopy"
                key={key + uiName}
                containerClass="py-4"
              />
            );
          })}
        </form>
      )}
    </div>
  );
};

export default SnowflakeConfigList;
