import { useEffect, useRef } from 'react';

export interface HoverClient {
  onEnter(id: string): void;
  onExit(): void;
}

export interface HoverClientMap {
  [key: string]: HoverClient;
}

export function useHoverCallbacks(hoverClients: HoverClientMap): React.RefObject<HTMLDivElement> {
  const mouseRef = useRef(null);
  const lastHoverProps = useRef({
    x: 0,
    y: 0,
    lastKey: '',
    lastId: '',
    lastHoverAt: 0,
    timeout: null as any,
  });
  useEffect(() => {
    const TIP_TIME = 250;
    const hoverKeys = Object.keys(hoverClients);

    const findHoveredData = (target: Element | null) => {
      if (!target) {
        return ['', ''];
      }

      const MAX_DEPTH = 5;
      let hoveredKey = '';
      let hoveredId = '';
      let ds: any = {};

      for (let i = 0; i < MAX_DEPTH; i++) {
        ds = (target as HTMLElement).dataset;
        hoveredKey = hoverKeys.find((k) => ds[k]) || ''; // eslint-disable-line no-loop-func
        if (hoveredKey || !target.parentElement) {
          hoveredId = ds[hoveredKey] || '';
          break;
        }
        target = target.parentElement;
      }
      return [hoveredKey, hoveredId];
    };

    const onHoverTimeout = () => {
      const current = lastHoverProps.current;
      const { x, y, lastHoverAt, lastKey, lastId } = current;
      const delta = Date.now() - lastHoverAt;
      if (delta > TIP_TIME) {
        const overElement = document.elementFromPoint(x, y);
        const [hoveredKey, hoveredId] = findHoveredData(overElement);
        if (hoveredId && hoveredKey === lastKey && hoveredId === lastId) {
          current.timeout = setTimeout(onHoverTimeout, TIP_TIME);
        } else {
          current.lastKey = '';
          current.lastId = '';
          hoverClients[lastKey].onExit();
        }
      } else {
        current.timeout = setTimeout(onHoverTimeout, TIP_TIME - delta);
      }
    };

    const moveHandler = (event: MouseEvent) => {
      // Update mouse position
      const current = lastHoverProps.current;
      current.x = event.clientX;
      current.y = event.clientY;

      const { lastKey, lastId } = current;

      // Search parent nodes for a datakey we listen to
      const [hoveredKey, hoveredId] = findHoveredData(event.target as Element);

      const notSameTarget = hoveredKey !== lastKey || hoveredId !== lastId;

      // If we moved onto a new hover target
      if (lastId && hoveredId && notSameTarget) {
        current.lastKey = '';
        current.lastId = '';
        hoverClients[lastKey].onExit();
      }

      // If hovering
      if (hoveredKey && hoveredId) {
        current.lastHoverAt = Date.now();
        // If we moved onto a new hover target, call onEnter
        if (notSameTarget) {
          current.lastKey = hoveredKey;
          current.lastId = hoveredId;
          hoverClients[hoveredKey].onEnter(hoveredId);

          clearTimeout(current.timeout);
          current.timeout = setTimeout(onHoverTimeout, TIP_TIME);
        }
      }
    };

    const mouseOut = (event: MouseEvent) => {
      // Update mouse position
      const current = lastHoverProps.current;
      const { lastKey, lastId } = current;
      if (lastKey && lastId) {
        current.x = -1;
        current.y = -1;
        clearTimeout(current.timeout);
        current.timeout = setTimeout(onHoverTimeout, TIP_TIME);
      }
    };

    const current = mouseRef.current;

    if (current !== null) {
      (current as any).addEventListener('mousemove', moveHandler);
      (current as any).addEventListener('mouseout', mouseOut);
    }

    return () => {
      if (current !== null) {
        (current as any).removeEventListener('mousemove', moveHandler);
        (current as any).removeEventListener('mouseout', mouseOut);
      }
    };
  }, [hoverClients]);

  return mouseRef;
}
