/* eslint-disable no-console */
const PROD_KEY = 'BANK7g6ktaAoipuRdx78A48tQFWwBEqM';
const DEV_KEY = 'W2O4PEOIDCBXqBAMq7sOHBp6nXs7iACV';
const segmentKey = process.env.NODE_ENV === 'production' ? PROD_KEY : DEV_KEY;

// Snippet from Segment's site
(function () {
  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console && console.error && console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      analytics.factory = function (e) {
        return function () {
          var t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (var e = 0; e < analytics.methods.length; e++) {
        var key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        var t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
        var n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey = segmentKey;
      analytics.SNIPPET_VERSION = '4.15.3';

      /*******************************************************************************
       * WARN:
       * The first half of this file is a code snippet coppied from Segment's site.
       * EVERYTHING BELOW THIS LINE IS CUSTOMIZED BY MOZART.
       ******************************************************************************/
      // Segment passes it's data to certain destinations based on the settings in `analytics.options.integrations`.
      // This convert's Osano's GDPR settings to Segment's destination integration settings.
      const osanoConsentToSegmentIntegrations = (consent) => ({
        // How to get the list of keys to set in this object:
        // 1. PROD_KEY uses a different set of destinations than DEV_KEY.
        //    So the first thing you should do is set `const segmentKey = PROD_KEY;` to get your dev env to load correct set of destinations.
        //    This list of destinations is set at https://app.segment.com/mozartdata/sources/prodwebsite/overview
        // 2. You may find it useful to put a `analytics.debug(true);` before the `analytics.load = ...` statement.
        // 3. Refresh the page and Segment will make a request to https://cdn.segment.com/v1/projects/BANK7g6ktaAoipuRdx78A48tQFWwBEqM/settings
        //    The response payload of this request contains the keys for the list below.
        // 4. After you set the list as desired refresh the page again, and check the browser's network tab
        //    to make sure requests aren't being made for JS files from places you don't expect.
        // 5. The keys are the destination's type from the line that looks like a filepath on a Segment destination page',
        //    not what we named the destination in Segment.
        //    For example, if you went to https://app.segment.com/mozartdata/destinations/actions-google-analytics-4-web/sources/prodwebsite/instances/642756554264d682438c3e40/configuration
        //    You would use the 'Google Analytics 4 Web' from 'Destinations/Google Analytics 4 Web' instead of 'prod_app_ga4'.

        // Default to false so if the analytics team adds a destination
        // and doesn't inform engineering so there isn't a GDPR breach.
        All: false,
        // I'm not sure if `Segment.io` is a valid value. It was in an example.
        // It does not stop segment from posting. It may do something on the server.
        // So we call `window.analytics.off();` lower down to completely stop Segment
        // from reporting to the server at all.
        'Segment.io': consent.ANALYTICS === 'ACCEPT',
        'Facebook Pixel': consent.MARKETING === 'ACCEPT',
        // On 04/04/2023, JohnS and JLau were trying to get Google Analytics 4 tracking in place.
        // The API of Google Analytics 4 is action based and piping it through Segment doesn't add a lot of utility.
        // It's kind of like trying to shove a square peg through a round hole.
        // Most importantly, we couldn't figure out how to make it work.
        // So, we added logging Google Analytics 4 directly with the Google Analytics script snippet in index.html.
        // We also, disabled logging these other google trackers(AdWords, Google Tag Manager) to reduce redundancy.
        // AdWords: consent.MARKETING === 'ACCEPT',
        // 'Google Tag Manager': consent.MARKETING === 'ACCEPT',
        // 'Google Analytics 4 Web' is a failed experiment. We couldn't figure out how to make it work.
        // The "Web" on the end of this one means it loads JS in the app instead of proxying data through Segment's server.
        // 'Google Analytics 4 Web': consent.MARKETING === 'ACCEPT',
        'LinkedIn Insight Tag': consent.MARKETING === 'ACCEPT',
        'Quora Conversion Pixel': consent.MARKETING === 'ACCEPT',

        // Snowflake is a destination in Segment, but I'm pretty sure it is of a different type
        // that is not disabled in this way. Not logging to Segment in general effectively
        // turns Snowflake off.
      });

      // Load Segment with the GDPR options that Osano currently allows
      // Osano will completely block Segment from loading if it is blocking
      // Analytics at the time of pageload.
      const consent = window.Osano.cm.getConsent();
      analytics.load(segmentKey, { integrations: osanoConsentToSegmentIntegrations(consent) });

      // If the user changes their Osano settings mid session,
      // change Segment accordingly.
      analytics.ready(() => {
        window.Osano.cm.addEventListener('osano-cm-consent-saved', function (consent) {
          // Note:
          // This even handler runs once when the app first loads AND when the user clicks the Save button on the Osano drawer.
          window.analytics.options.integrations = osanoConsentToSegmentIntegrations(consent);

          if (consent.ANALYTICS !== 'ACCEPT') {
            window.analytics.off();
          }
        });
      });
    }
})();
