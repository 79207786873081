import { Clock } from 'react-bootstrap-icons';

import { UserProfile } from 'api/APITypes';
import { parseApiDate } from 'utils/dateTime';

const ONE_DAY = 24 * 60 * 60 * 1000;

export interface TrialBannerProps {
  userProfile: UserProfile;
}

export const TrialBanner = (props: TrialBannerProps) => {
  const { userProfile } = props;

  // Calc days left in your trial.
  // You always have at least one day left because you would never see the
  // header trial banner if your trial is expired.
  const expDate = parseApiDate(userProfile.company?.trial_expiry_date || null) || new Date();
  const deltaDays = (expDate.getTime() - new Date().getTime()) / ONE_DAY;
  const daysLeft = Math.max(Math.ceil(deltaDays), 1);

  return (
    <div className="h-[28px] f-center text-pri-gray-500 text-sm font-medium bg-sec-blue-light-200">
      <Clock size="16" color="var(--pri-gray-500)" />
      <span className="ml-1">
        You have {daysLeft} days left in your unlimited free trial. Need help getting started?{' '}
      </span>
      <a href="mailto:support@mozartdata.com" className="ml-1 text-sec-blue-light-700">
        Contact Us.
      </a>
      <span className="ml-1"> We're glad to help.</span>
    </div>
  );
};

export default TrialBanner;
