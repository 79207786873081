/**
 * Methods to convert SavedFlowchartQueryModels to FlowchartQueryModels and visa versa.
 * This file should be easy to write unit tests on.
 */
import { cloneDeep, keyBy } from 'lodash';

import { Column, ColumnsByTableID } from 'api/columnAPI';
import { AggTable } from 'api/tableAPI';

import {
  BiParentVertex,
  TableColumn,
  Edge,
  FlowchartQueryModel,
  FlowchartVertex,
  GroupBy,
  Join,
  MonoParentVertex,
  OrderBy,
  SelectColumns,
  SourceTable,
  Union,
  HasSelectColumns,
} from './FlowchartQueryModel';
import {
  SavedFlowchartQueryModel,
  SavedVertex,
  SavedSourceTable,
  SavedJoin,
  SavedSelectColumns,
  SavedTableColumn,
  SavedGroupBy,
  SavedOrderBy,
} from './SavedFlowchartQueryModel';

function savedColumnValuesToColumnValues(
  savedColumnValues: SavedTableColumn[] | undefined,
): TableColumn[] | undefined {
  if (savedColumnValues === undefined) {
    return undefined;
  }
  return savedColumnValues.map((s) => {
    const columnValue: TableColumn = {
      id: s.id,
      name: s.name,
      socket: s.socket,
      picked: s.picked,
      table: null, // Mark null for now. This is set later when cascadeTableColumns() is called.
      column: null, // Mark null for now. This is set later when cascadeTableColumns() is called.
    };
    if (s.alias) {
      columnValue.alias = s.alias;
    }
    return columnValue;
  });
}

function columnValuesToSavedColumnValues(
  columnValues: TableColumn[] | undefined,
): SavedTableColumn[] | undefined {
  if (columnValues === undefined) {
    return undefined;
  }
  return columnValues.map((c) => {
    const savedColumnValue: SavedTableColumn = {
      id: c.id,
      name: c.name,
      socket: c.socket,
      picked: c.picked,
    };
    if (c.alias) {
      savedColumnValue.alias = c.alias;
    }

    return savedColumnValue;
  });
}

export function savedVertexToFlowchartVertex(
  savedVertex: SavedVertex,
  tablesByID: Record<string, AggTable>,
  columnsByTableID: ColumnsByTableID,
): FlowchartVertex {
  const { id, type, position } = savedVertex;

  if (type === 'source_table') {
    const savedTableVertex = savedVertex as SavedSourceTable;
    const tableVertex = new SourceTable(id, position);
    tableVertex.alias = savedTableVertex.alias;
    tableVertex.table = tablesByID[savedTableVertex.tableID || ''] || null;
    tableVertex.columns = columnsByTableID[savedTableVertex.tableID || ''] || [];
    return tableVertex;
  }

  if (type === 'select_columns') {
    const savedSelectVertex = savedVertex as SavedSelectColumns;
    const selectVertex = new SelectColumns(id, position);
    selectVertex.selectedColumns = savedColumnValuesToColumnValues(savedSelectVertex.selectedColumns);
    return selectVertex;
  }

  if (type === 'join') {
    const savedJoinVertex = savedVertex as SavedJoin;
    const joinVertex = new Join(id, position);
    joinVertex.joinType = savedJoinVertex.joinType;
    joinVertex.leftColumn = savedJoinVertex.leftColumn;
    joinVertex.rightColumn = savedJoinVertex.rightColumn;
    joinVertex.selectedColumns = savedColumnValuesToColumnValues(savedJoinVertex.selectedColumns);
    return joinVertex;
  }

  if (type === 'union') {
    const unionVertex = new Union(id, position);
    return unionVertex;
  }

  if (type === 'group_by') {
    const savedGroupByVertex = savedVertex as SavedGroupBy;
    const groupByVertex = new GroupBy(id, position);
    groupByVertex.groupBy = savedGroupByVertex.groupBy;
    return groupByVertex;
  }

  if (type === 'order_by') {
    const savedOrderByVertex = savedVertex as SavedOrderBy;
    const orderByVertex = new OrderBy(id, position);
    orderByVertex.orderBy = savedOrderByVertex.orderBy;
    return orderByVertex;
  }

  // Base case that should never happen.
  // This just makes TS happy.
  const flowchartVertex = new FlowchartVertex(id, position);
  return flowchartVertex;
}

/**
 * Use the edges to apply parent and child links to each vertex.
 * This allows vetices to find their parents and children without
 * having to do a look in the list of edges.
 * @param vertices all of the vertices in the FlowchartQueryModel
 * @param edges all of the edges in the FlowchartQueryModel
 */
export function linkVertices(vertices: FlowchartVertex[], edges: Edge[]) {
  vertices.forEach((vert) => {
    const { id, type } = vert;

    // Try to link the single child of this vertex.
    vert.childID = null;
    const childEdge = edges.find((e) => e.sourceID === id);
    if (childEdge) {
      const child = vertices.find((v) => v.id === childEdge.destinationID);
      vert.childID = child?.id || null;
    }

    // Source tables do not have parents
    if (type === 'source_table') {
      return;
    }

    // Try to link the two parents of a BiParentVertex
    if (vert.isBiParent()) {
      const biParentVertex = vert as BiParentVertex;
      biParentVertex.leftParentID = null;
      biParentVertex.rightParentID = null;

      // Find left parent
      const leftEdge = edges.find((e) => e.destinationID === id && e.destinationSocket === 'left');
      if (leftEdge) {
        const leftParent = vertices.find((v) => v.id === leftEdge.sourceID);
        biParentVertex.leftParentID = leftParent?.id || null;
      }

      // Find right parent
      const rightEdge = edges.find((e) => e.destinationID === id && e.destinationSocket === 'right');
      if (rightEdge) {
        const rightParent = vertices.find((v) => v.id === rightEdge.sourceID);
        biParentVertex.rightParentID = rightParent?.id || null;
      }
    }
    // Try to link the single parent of a MonoParentVertex
    else if (vert.isMonoParent()) {
      const monoParentVertex = vert as MonoParentVertex;
      monoParentVertex.singleParentID = null;

      // Find single parent
      const singleEdge = edges.find((e) => e.destinationID === id && e.destinationSocket === 'single');
      if (singleEdge) {
        const singleParent = vertices.find((v) => v.id === singleEdge.sourceID);
        monoParentVertex.singleParentID = singleParent?.id || null;
      }
    }
  });
}

/**
 * Copy table and column objects to the selectedColumns of any descendant
 * vertex of the `fromVertex`.
 * @param fromVertex the vertex to start the recursive copy from
 * @param vertices all of the vertices in the FlowchartQueryModel
 * @param table the AggTable of the original source_table vertex
 * @param columnsByAliasOrName Column objects keyed by their original column name or the name they have been aliased to.
 */
export function cascadeTableColumnsFromVertex(
  fromVertex: FlowchartVertex,
  vertices: FlowchartVertex[],
  table: AggTable | null,
  columnsByAliasOrName: Record<string, Column>,
) {
  const toVertex = vertices.find((v) => v.id === fromVertex.childID);
  if (toVertex) {
    const socket = toVertex.parentIDToSocket(fromVertex.id);
    if (socket) {
      if (toVertex.hasSelectColumns()) {
        const hasSelectColumns = toVertex as HasSelectColumns;
        if (hasSelectColumns.selectedColumns) {
          // Set the table and columns for every selected column that came from the fromVertex.
          // We filter by socket because a join could inherit columns from two vertices.
          const columnsToCascade = hasSelectColumns.selectedColumns.filter((s) => s.socket === socket);
          columnsToCascade.forEach((cc) => {
            cc.table = table;
            cc.column = columnsByAliasOrName[cc.name] || null;
          });

          // Do pass along any columns that have been filtered out
          const filtered = columnsToCascade.filter((cs) => cs.picked && cs.column !== null);

          // Rebuild columnsByAliasOrName with filtered values and aliases
          columnsByAliasOrName = {};
          filtered.forEach((cs) => {
            columnsByAliasOrName[cs.alias || cs.name] = cs.column as Column;
          });
        }
      }

      cascadeTableColumnsFromVertex(toVertex, vertices, table, columnsByAliasOrName);
    }
  }
}

/**
 * Find every source_table vertex.
 * Then recursively copy each source_table's table and column objects
 * to the selectedColumn deginitions in each descendant that inherits
 * columns from the the source_table.
 * @param vertices all of the vertices in the FlowchartQueryModel
 */
export function cascadeTableColumns(vertices: FlowchartVertex[]) {
  const sourceTables = vertices.filter((v) => v.type === 'source_table');
  sourceTables.forEach((s) => {
    const st = s as SourceTable;
    const columnsByName = keyBy(st.columns, 'name');
    cascadeTableColumnsFromVertex(st, vertices, st.table, columnsByName);
  });
}

export function savedModelToFlowchartModel(
  savedFlowchartModel: SavedFlowchartQueryModel,
  tablesByID: Record<string, AggTable>,
  columnsByTableID: ColumnsByTableID,
): FlowchartQueryModel {
  const edges: Edge[] = cloneDeep(savedFlowchartModel.edges);
  const vertices = savedFlowchartModel.vertices.map((s) =>
    savedVertexToFlowchartVertex(s, tablesByID, columnsByTableID),
  );
  linkVertices(vertices, edges);
  cascadeTableColumns(vertices);
  const flowchartModel: FlowchartQueryModel = {
    vertices,
    edges,
  };

  return flowchartModel;
}

export function flowchartVertexToSavedVertex(flowchartVertex: FlowchartVertex): SavedVertex {
  const { id, type, position } = flowchartVertex;
  const savedVertex: SavedVertex = {
    id,
    type,
    position,
  };

  if (type === 'source_table') {
    const tableVertex = flowchartVertex as SourceTable;
    const savedTableVertex: SavedSourceTable = {
      ...savedVertex,
      tableID: tableVertex.table?.id || null,
      alias: tableVertex.alias,
    };
    return savedTableVertex;
  }
  if (type === 'select_columns') {
    const selectVertex = flowchartVertex as SelectColumns;
    const savedSelectVertex: SavedSelectColumns = {
      ...savedVertex,
    };
    if (selectVertex.selectedColumns) {
      savedSelectVertex.selectedColumns = columnValuesToSavedColumnValues(selectVertex.selectedColumns);
    }
    return savedSelectVertex;
  }

  if (type === 'join') {
    const joinVertex = flowchartVertex as Join;
    const savedJoinVertex: SavedJoin = {
      ...savedVertex,
      joinType: joinVertex.joinType,
      leftColumn: joinVertex.leftColumn,
      rightColumn: joinVertex.rightColumn,
    };
    if (joinVertex.selectedColumns) {
      savedJoinVertex.selectedColumns = columnValuesToSavedColumnValues(joinVertex.selectedColumns);
    }
    return savedJoinVertex;
  }

  if (type === 'union') {
    // Nothing to set at present
    return savedVertex;
  }

  if (type === 'group_by') {
    const groupByVertex = flowchartVertex as GroupBy;
    const savedGroupByVertex: SavedGroupBy = {
      ...savedVertex,
      groupBy: groupByVertex.groupBy,
    };
    return savedGroupByVertex;
  }

  if (type === 'order_by') {
    const orderByByVertex = flowchartVertex as OrderBy;
    const savedOrderByVertex: SavedOrderBy = {
      ...savedVertex,
      orderBy: orderByByVertex.orderBy,
    };
    return savedOrderByVertex;
  }

  return savedVertex;
}

export function flowchartModelToSavedModel(
  flowchartModel: FlowchartQueryModel,
): SavedFlowchartQueryModel {
  const edges: Edge[] = cloneDeep(flowchartModel.edges);
  const vertices = flowchartModel.vertices.map((s) => flowchartVertexToSavedVertex(s));
  const savedModel: SavedFlowchartQueryModel = {
    vertices,
    edges,
  };

  return savedModel;
}
