import GenerateSnowflakeUser from 'components/business_logic/GenerateSnowflakeUser/GenerateSnowflakeUser';
import CodeCopy, { editorHeight } from 'components/inputs/composite/CodeCopy/CodeCopy';

import ExternalLink from '../ExternalLink';
import { fieldValue } from '../fieldUtils';
import { IntegrationLayoutProps } from '../IntegrationSnowflakeConfig';
import Steps from '../Steps';

const PythonLayout = (props: IntegrationLayoutProps) => {
  const { values } = props;
  let script: string = '';
  let steps: JSX.Element | null = null;
  if (values) {
    if (values.type === 'bigquery') {
      // Adapted from https://www.rudderstack.com/guides/how-to-access-and-query-your-bigquery-data-using-python-and-r/
      const project_id = fieldValue(values, 'project_id');
      script = `from google.cloud import bigquery
from google.oauth2 import service_account
credentials = service_account.Credentials.from_service_account_file(
'path/to/file.json')

project_id = '${project_id}'
client = bigquery.Client(credentials= credentials,project=project_id)

query_job = client.query("""
   SELECT 1 as num UNION ALL SELECT 2 as num UNION ALL SELECT 3 as num""")

results = query_job.result()
for row in results:
    print(dict(row))
`;
      steps = (
        <Steps>
          <li>
            Run <code className="bg-pri-gray-200">pip install --upgrade google-cloud-bigquery</code>
          </li>
          <li>
            Copy the following script to <code className="bg-pri-gray-200">test.py</code>
          </li>
          <li>
            You can reuse the service account JSON file that you used to connect BigQuery to Mozart Data,
            or make a new service account or key via the instructions{' '}
            <ExternalLink href="https://help.mozartdata.com/docs/bigquery-mozart-data">
              here
            </ExternalLink>
            .
          </li>
          <li>
            Then run <code className="bg-pri-gray-200">python test.py</code>
          </li>
        </Steps>
      );
    } else {
      const account_name = fieldValue(values, 'account_name');
      const database = fieldValue(values, 'database');
      script = `import snowflake.connector

con = snowflake.connector.connect(
    account="${account_name}",
    user="<Snowflake Username>",
    password="<Snowflake Password>",
    database="${database}",
)
cursor = con.cursor()
result = cursor.execute("select 1").fetchall()
print(result)`;
      steps = (
        <Steps>
          <li>
            Run <code className="bg-pri-gray-200">pip install --upgrade snowflake-connector-python</code>
          </li>
          <li>
            Copy the following script to <code className="bg-pri-gray-200">test.py</code>
          </li>
          <li>
            If you do not have a Snowflake username and password you want to use for this integration,
            you can create one below by clicking the "Generate Snowflake User" button. Then substitute
            the generated values into the script.
          </li>
          <li>
            Then run <code className="bg-pri-gray-200">python test.py</code>
          </li>
        </Steps>
      );
    }
  }
  return (
    <>
      {steps}
      <CodeCopy
        label="test.py"
        value={script}
        mode="python"
        height={`${editorHeight(script.split('\n').length)}px`}
        containerClass="pt-4"
      />
      {values?.type === 'snowflake' && <GenerateSnowflakeUser className="mt-4" />}
    </>
  );
};

export default PythonLayout;
