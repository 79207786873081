import React, { useState } from 'react';

import { RouteComponentProps } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';

import API from 'api/API';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import TabRow from 'components/layouts/parts/TabRow/TabRow';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useUserProfile } from 'context/AuthContext';
import CompanySettings from 'pages/settings/CompanySettings';
import UserSettings from 'pages/settings/UserSettings';

import BigQuerySettings from './BigQuerySettings';
import GitHubIntegration from './GitHubIntegration';
import NotificationSubscriptions from './NotificationSubscriptions';
import OktaIntegration from './OktaIntegration';
import SlackAppInstaller from './SlackAppInstaller';
import SnowflakeSettings from './SnowflakeSettings';

// Properties that can be update on the company model
export interface CompanyUpdateProps {
  require_2fa?: boolean;
  delete_fivetran_deleted?: boolean;
  sso_type?: 'okta' | 'google' | null;
  okta_client_id?: string;
  okta_domain?: string;
  okta_client_secret?: string;
  allow_ai?: boolean;
}

interface MatchParams {}

interface SettingsProps extends RouteComponentProps<MatchParams> {}

export default function Settings(props: SettingsProps) {
  useTitle('Settings');
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [error, setError] = useState('');
  const [currentTab, setCurrentTab] = useState<string>(query.get('tab') || 'user');
  const { userProfile, updateCompany } = useUserProfile();

  const {
    company: { database_account },
    company_role,
  } = userProfile;

  const canShowSnowflakeSettings =
    database_account?.type === 'snowflake' &&
    database_account.is_main_account === false &&
    database_account.is_byos === false &&
    company_role === 'admin';

  const canShowBigQuerySettings = database_account?.type === 'bigquery' && company_role === 'admin';

  let tabs = [
    { key: 'user', label: 'User' },
    { key: 'notifications', label: 'Notifications' },
    { key: 'company', label: 'Company' },
    { key: 'integrations', label: 'Integrations' },
  ];

  const handleUpdateCompany = (companyUpdateProps: CompanyUpdateProps, trackingText: string) => {
    const api = new API();
    return api.patch(`api/companies/${userProfile.company.id}`, companyUpdateProps).then((response) => {
      updateCompany(response.data);
      analytics.track(trackingText);
    });
  };

  const handleTabClick = (tabKey: string) => {
    setCurrentTab(tabKey);
    query.set('tab', tabKey);
    history.push(`${location.pathname}?${query.toString()}`);
  };

  const Integrations = () => (
    <>
      {company_role !== 'admin' && (
        <Alert variant="info" className="mt-4">
          Only admins can change these settings.
        </Alert>
      )}
      <div className="mt-6">
        <SlackAppInstaller setError={setError} />
      </div>
      <div>
        <GitHubIntegration />
      </div>
      <div>
        <OktaIntegration onUpdateCompany={handleUpdateCompany} />
      </div>
    </>
  );

  const TabContentMap: { [key: string]: any } = {
    user: <UserSettings setError={setError} />,
    notifications: <NotificationSubscriptions setError={setError} />,
    company: <CompanySettings onUpdateCompany={handleUpdateCompany} setError={setError} />,
    integrations: <Integrations />,
  };

  if (canShowSnowflakeSettings) {
    tabs.splice(3, 0, { key: 'snowflake', label: 'Snowflake Account' });
    TabContentMap['snowflake'] = <SnowflakeSettings />;
  }

  if (canShowBigQuerySettings) {
    tabs.splice(3, 0, { key: 'bigquery', label: 'BigQuery Account' });
    TabContentMap['bigquery'] = <BigQuerySettings />;
  }

  return (
    <CenteredLayout maxWidth="900px">
      {error && (
        <Alert variant="error" className="mb-4">
          {error}
        </Alert>
      )}
      <h1 className="display-sm mb-4"> Settings</h1>
      <TabRow
        tabs={tabs}
        selectedTab={currentTab}
        onClick={(tabKey: string) => {
          handleTabClick(tabKey);
        }}
      />

      {TabContentMap[currentTab]}
    </CenteredLayout>
  );
}
