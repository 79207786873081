import { useMemo } from 'react';

import { AggTable, PickedItemKey, SchemaName, TableID } from 'api/tableAPI';
import Button from 'components/inputs/basic/Button/Button';
import TablePickerExplorer from 'components/inputs/basic/TablePicker/TablePickerExplorer/TablePickerExplorer';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import { SchemaIconType, SchemaWithIconType } from 'components/primitives/icons/SchemaIcon/SchemaIcon';

import AIAssistantTablePickerPickedItems from './AIAssistantTablePickerPickedItems';

interface AIAssistantTablePickerModalProps {
  selectedTable: AggTable | null;
  onClose: () => void;
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>;
  onClearSelection: () => void;
  pickTable: (table: AggTable) => void;
  unpickTable: (table: AggTable) => void;
  pickedTables: Record<TableID, AggTable>;
  pickSchema: (schemaWithIconType: SchemaWithIconType) => void;
  unpickSchema: (schema: SchemaName) => void;
  pickedSchemas: Record<SchemaName, SchemaIconType>;
  pickedItems: Record<PickedItemKey, AggTable | SchemaIconType>;
  schemaPickingEnabled: boolean;
}

const AIAssistantTablePickerModal = (props: AIAssistantTablePickerModalProps) => {
  const {
    selectedTable,
    onClose,
    setSelectedTable,
    pickTable,
    unpickTable,
    pickSchema,
    unpickSchema,
    onClearSelection,
    pickedTables,
    pickedSchemas,
    pickedItems,
    schemaPickingEnabled,
  } = props;

  const multiPick = useMemo(() => {
    return {
      pickedTables,
      pickedSchemas,
      onUnpickTable: unpickTable,
      onUnpickSchema: unpickSchema,
      onPickSchema: pickSchema,
      schemaPickingEnabled,
    };
  }, [pickedTables, pickedSchemas, unpickTable, unpickSchema, pickSchema, schemaPickingEnabled]);

  const footer = (
    <div className="flex w-full justify-end">
      <Button onClick={onClose} size="small" variant="darkDullAction">
        Save
      </Button>
    </div>
  );

  // TODO: Make a cancel button that reverts to the previous unsaved state
  // (if you had A and B picked then pick C and then cancel, you go back to A and B)
  return (
    <Modal
      header={`Select tables ${
        schemaPickingEnabled ? 'and/or schemas ' : ''
      }for your request (optional)`}
      onClose={onClose}
      footer={footer}
      containerClass="w-[700px] h-[90%]"
    >
      {Object.keys(pickedItems).length > 0 && (
        <AIAssistantTablePickerPickedItems
          pickedItems={pickedItems}
          unpickTable={unpickTable}
          unpickSchema={unpickSchema}
          onClearSelection={onClearSelection}
        />
      )}
      <TablePickerExplorer
        selectedTable={selectedTable}
        tableDraggable={false}
        setSelectedTable={setSelectedTable}
        multiPick={multiPick}
        onPickTable={pickTable}
        hideTabs
      />
    </Modal>
  );
};

export default AIAssistantTablePickerModal;
