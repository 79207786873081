/*
  Create an input component that is like a list of checkboxes
  that are all cards with titles and descriptions. Actual checkboxes don't
  quite work for this, so doing something custom.
*/
import CheckboxCard from './CheckboxCard';
import { CheckboxCardProps } from './CheckboxCard';

export type { CheckboxCardProps, VisibleCheckboxCardProps } from './CheckboxCard';

export interface CheckboxCardGridProps {
  cards: CheckboxCardProps[];
}

const CheckboxCardGrid = (props: CheckboxCardGridProps) => {
  const { cards } = props;
  return (
    <div className="w-full grid gap-3 auto-rows-[128px] grid-cols-2">
      {cards.map((c, i) => {
        return <CheckboxCard key={i} {...c} />;
      })}
    </div>
  );
};

export default CheckboxCardGrid;
