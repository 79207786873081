import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function SlackAppInstallerInfoIcon() {
  const content = (
    <div>
      <div>
        <strong>Integrate your Slack workspace with Mozart Data for notifications.</strong>
      </div>
      <div className="mt-2">
        Once the Mozart Data Slack app is installed in your workspace, you can invite this app to any
        Slack channel where it will post notification messages.
      </div>
      <div className="mt-2">
        Unlike In-app and Email notifications, Slack notifications are configured at the Company level.
        This means only one Slack app can be installed at a time.
      </div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return (
    <InfoIcon placement="bottom" content={content} containerClass="ml-2" popoverProps={popoverProps} />
  );
}
