import React from 'react';

import { CompanyRole, DatabaseType, UserProfile } from 'api/APITypes';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

import { UsersWithValidSnowflakeRoles } from '../../useMozartUsers';

import { UserRoleIconContent } from './UserRoleIconContent';
import UserRow from './UserRow/UserRow';

interface UserTableProps {
  imAdmin: boolean;
  require2FA: boolean;
  showSnowflakeRole: boolean;
  filteredUsers: UserProfile[];
  usersWithValidSnowflakeRoles: UsersWithValidSnowflakeRoles;
  urlSelectedEmail: string;
  roleSpinners: { [key: string]: true | undefined };
  isActiveSpinners: { [key: string]: true | undefined };
  isByos: boolean;
  databaseType: DatabaseType;
  saveRole(userProfile: UserProfile, newRole: CompanyRole): void;
  openConfirmSaveIsActive(userProfile: UserProfile, newIsActive: boolean): void;
  openSnowflakeModal: (user: UserProfile) => void;
}

const UserTable = React.memo((props: UserTableProps) => {
  const {
    imAdmin,
    require2FA,
    showSnowflakeRole,
    filteredUsers,
    usersWithValidSnowflakeRoles,
    urlSelectedEmail,
    roleSpinners,
    isActiveSpinners,
    isByos,
    databaseType,
    saveRole,
    openConfirmSaveIsActive,
    openSnowflakeModal,
  } = props;

  const twoFAContent = (
    <div className="text-left">
      <p>
        2-Factor Authentication is {require2FA ? 'REQUIRED' : 'NOT REQUIRED'} for users in your company
        to be able to log in.
      </p>
      <br />
      <p>Admins can change this requirement in their company settings.</p>
    </div>
  );

  return (
    <table className="lineTable">
      <thead>
        <tr>
          <th style={{ minWidth: '28%' }}>Name</th>
          <th style={{ width: '34%' }}>Email</th>
          <th className="text-center" style={{ width: '12%' }}>
            <div className="f-center">
              <span>2FA Enabled</span>
              <InfoIcon
                content={twoFAContent}
                containerClass="ml-1"
                placement="bottom"
                popoverProps={{ style: { maxWidth: '370px' } }} // Aesthetic tweak
              />
            </div>
          </th>
          <th style={{ width: '12%' }}>
            <div className="f-center">
              <span>Mozart Role</span>
              <InfoIcon
                content={UserRoleIconContent}
                containerClass="ml-1"
                placement="bottom"
                popoverProps={{ style: { maxWidth: '700px' } }}
              />
            </div>
          </th>
          {showSnowflakeRole && (
            <th style={{ width: '16%' }}>
              <div className="f-row-y-center">
                <span>Snowflake Role</span>
                <InfoIcon
                  content="This user's queries will run with this Snowflake role."
                  containerClass="ml-1"
                  placement="bottom"
                />
              </div>
            </th>
          )}
          {imAdmin && (
            <th style={{ width: '8%' }}>
              <div className="f-center">
                <span>Active</span>
                <InfoIcon
                  content="Can this user log into Mozart Data?"
                  containerClass="ml-1"
                  placement="bottom"
                />
              </div>
            </th>
          )}
          {imAdmin && !isByos && <th style={{ width: '4%' }}></th>}
        </tr>
      </thead>
      <tbody>
        {filteredUsers.map((userProfile: UserProfile) => (
          <UserRow
            key={userProfile.user.email}
            userProfile={userProfile}
            selected={userProfile.user.email === urlSelectedEmail}
            imAdmin={imAdmin}
            showSnowflakeRole={showSnowflakeRole}
            validSnowflakeRole={!!usersWithValidSnowflakeRoles[userProfile.id]}
            require_2fa={require2FA}
            spinningRole={!!roleSpinners[userProfile.user.email]}
            spinningIsActive={!!isActiveSpinners[userProfile.user.email]}
            databaseType={databaseType}
            saveRole={saveRole}
            openConfirmSaveIsActive={openConfirmSaveIsActive}
            openSnowflakeModal={openSnowflakeModal}
          />
        ))}
      </tbody>
    </table>
  );
});

export default UserTable;
