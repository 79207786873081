/*
Utilities for converting Mozart objects into CodeMirror completions objects.
*/
import { AggTable } from 'api/APITypes';
import { SearchColumn } from 'api/searchColumnAPI';
import { isFivetranColumn } from 'utils/dbName';

import { Completion } from '@codemirror/autocomplete';

import { AUTOCOMPLETE_SEARCH_BOOST } from './autocompleteConstants';

const tableToCompletion = (table: AggTable): Completion => {
  const { full_name, type } = table;
  return {
    label: full_name,
    detail: `table(${type === 'unmanaged' ? 'source' : type})`,
    boost:
      type === 'transform' || type === 'dbt'
        ? AUTOCOMPLETE_SEARCH_BOOST.transform
        : AUTOCOMPLETE_SEARCH_BOOST.tables,
  };
};

const columnToCompletion = (col: SearchColumn): Completion => ({
  label: col.name,
  // This is used to pick an icon to show for the completion. https://codemirror.net/docs/ref/#autocomplete.Completion.type
  // However we don't yet use icons in our commpletion styling
  type: 'property',
  detail: `column(${col.type})`,
  // Sort _fivetran_ columns last.
  boost: isFivetranColumn(col.name)
    ? AUTOCOMPLETE_SEARCH_BOOST.fivetranColumn
    : AUTOCOMPLETE_SEARCH_BOOST.columns,
});

export { tableToCompletion, columnToCompletion };
