import React, { useState } from 'react';

import { DbtRunConfig } from 'api/dbtAPI';

import EditMode from './EditMode';
import ViewMode from './ViewMode';

interface SettingsTabProps {
  runConfig: DbtRunConfig;
}

const SettingsTab = (props: SettingsTabProps) => {
  const { runConfig } = props;
  const [editingConfiguration, setEditingConfiguration] = useState<boolean>(false);

  const editMode = <EditMode runConfig={runConfig} setEditingConfiguration={setEditingConfiguration} />;
  const viewMode = <ViewMode runConfig={runConfig} setEditingConfiguration={setEditingConfiguration} />;

  return <>{editingConfiguration ? editMode : viewMode}</>;
};

export default SettingsTab;
