import React from 'react';

import cn from 'classnames';

import { AggTable } from 'api/tableAPI';
import SchemaIconForDatabaseSearch from 'components/primitives/icons/SchemaIconForDatabaseSearch/SchemaIconForDatabaseSearch';
import { highlightSearch } from 'components/query/TableExplorer/highlightUtils';
import { virtualSchemaKeyParts } from 'pages/Warehouse/DatabaseSearch2/DatabaseSearchReducer';

import { getSchemaTitle } from '../schemaUtils';

import FilterCount from './FilterCount';

interface SchemaItemProps {
  schemaKey: string;
  selected: boolean;
  highlightFilter: string | RegExp;
  isFiltering: boolean;
  iconTables: AggTable[];
  filteredCount: number;
  unfilteredCount: number;
  onClickSchema(schemaKey: string, metaKey: boolean, ctrlKey: boolean, shiftKey: boolean): void;
}

const SchemaItem = React.memo((props: SchemaItemProps) => {
  const {
    schemaKey,
    selected,
    highlightFilter,
    isFiltering,
    iconTables,
    filteredCount,
    unfilteredCount,
    onClickSchema,
  } = props;

  const { virtualSchemaType, schema } = virtualSchemaKeyParts(schemaKey);
  const title = getSchemaTitle(schema);

  return (
    <div
      className={cn(
        'h-[28px] py-[2px] pl-4 pr-2 f-row-y-center hover:cursor-pointer text-sm text-sec-blue-gray-700',
        {
          'bg-sec-blue-gray-200 hover:bg-sec-blue-gray-300': selected,
          'hover:bg-sec-blue-gray-100': !selected,
        },
      )}
      onClick={(e: React.MouseEvent) => {
        onClickSchema(schemaKey, e.metaKey, e.ctrlKey, e.shiftKey);
      }}
    >
      <SchemaIconForDatabaseSearch
        virtualSchemaType={virtualSchemaType}
        schema={schema}
        tables={iconTables}
        size={16}
        variant="dullAction"
      />
      <div className="font-medium ml-2 select-text">{highlightSearch(title, highlightFilter)}</div>
      <FilterCount
        isFiltering={isFiltering}
        filteredCount={filteredCount}
        unfilteredCount={unfilteredCount}
      />
    </div>
  );
});

export default SchemaItem;
