export type PercentSize = `${number}%`;
export type PixelSize = `${number}px`;
export type StringSize = PercentSize | PixelSize;
export type CSSSize = number | StringSize;

// Converts CSS dimensions in an unknown format(number, %, or px) to pixels as a number.
export function dimToPixNum(unknownDimension: CSSSize, oneHundredPercentInPixels: number) {
  // It's already a number. Assume it's already in pixels.
  if (typeof unknownDimension === 'number') {
    return unknownDimension;
  }

  // Convert percents to pixels
  const lastChar = unknownDimension.slice(-1);
  if (lastChar === '%') {
    return (Number(unknownDimension.slice(0, -1)) / 100) * oneHundredPercentInPixels;
  }

  // Convert string px to number.
  return pixToNum(unknownDimension as PixelSize);
}

// Converts CSS dimensions in string pixels to a number.
export function pixToNum(pixels: PixelSize) {
  return Number(pixels.slice(0, -2));
}

// Converts [255, 255, 255] to #ffffff
export function colorArrayToHex(colors: number[]) {
  let red = colors[0].toString(16);
  let green = colors[1].toString(16);
  let blue = colors[2].toString(16);
  if (red.length === 1) {
    red = '0' + red;
  }
  if (green.length === 1) {
    green = '0' + green;
  }
  if (blue.length === 1) {
    blue = '0' + blue;
  }
  return `#${red}${green}${blue}`;
}

// Converts #ffffff to [255, 255, 255]
export function colorHexToArray(color: string) {
  return [
    parseInt(color.slice(1, 3), 16),
    parseInt(color.slice(3, 5), 16),
    parseInt(color.slice(5, 7), 16),
  ];
}
