import cn from 'classnames';

import PageButtons from 'components/inputs/basic/Button/PageButtons';
import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import DismissibleAlert from 'components/widgets/alerts/DismissibleAlert/DismissibleAlert';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';

import { LogLevel, LogsTabState } from '../useLogsTabState';

import LogRow from './LogRow/LogRow';

interface LogsTabProps extends LogsTabState {}

const logLevelOptions: { label: string; value: LogLevel }[] = [
  { label: 'All', value: 'all' },
  { label: 'Errors', value: 'errors' },
  { label: 'Errors and Warnings', value: 'errors_and_warnings' },
];

const emptyLogMessage = 'Logs appear here within 30 minutes of the first sync attempt.';

const emptyLogMessageMap: Record<LogLevel, { heading: string }> = {
  all: {
    heading: 'This Connector has never been synced.',
  },
  errors: {
    heading: 'This Connector has never had an error.',
  },
  errors_and_warnings: {
    heading: 'This Connector has never had an error or warning.',
  },
};

export default function LogsTab(props: LogsTabProps) {
  const {
    logLevel,
    setLogLevelAndResetPage,
    loading,
    error,
    showSyncDelayWarning,
    logs,
    hasPrevious,
    hasNext,
    onCloseSyncDelayWarning,
    onClickFirst,
    onClickPrevious,
    onClickNext,
  } = props;

  return (
    <TabLayout loading={loading} error={error} variant="wide_padding">
      {!logs.length ? (
        <NoObjectsAlert
          heading={emptyLogMessageMap[logLevel].heading}
          detail={<p className="max-w-[500px] mt-2">{emptyLogMessage}</p>}
        />
      ) : (
        <div>
          <DismissibleAlert
            variant="info"
            className="mb-4"
            show={showSyncDelayWarning}
            onClose={onCloseSyncDelayWarning}
          >
            Heads up! Logs may take up to 30 minutes to appear after a sync ends.
          </DismissibleAlert>

          <div className={cn('h-[36px] f-between', { 'mt-2': !showSyncDelayWarning })}>
            <Listbox
              value={logLevel}
              options={logLevelOptions}
              onChange={(newValue: ListboxValue) => setLogLevelAndResetPage(newValue as LogLevel)}
              disabled={loading}
              widthClass="w-[200px]"
            />
            <PageButtons
              hasPreviousPage={hasPrevious && !loading}
              hasNextPage={hasNext && !loading}
              onLoadPreviousPage={onClickPrevious}
              onLoadNextPage={onClickNext}
              onLoadFirstPage={onClickFirst}
              // Not really feasible to give a last button since we are doing timestamp-based pagination
            />
          </div>
          <div className="mt-4">
            <div className="mt=4 h-full f-col overflow-y-auto">
              {logs.map((log) => (
                <LogRow key={log.sync_id} log={log} />
              ))}
            </div>
          </div>
        </div>
      )}
    </TabLayout>
  );
}
