/*
Reusable search functions and search function picker.
*/
import escapeRegex from 'escape-string-regexp';
import _ from 'lodash';
import searchParser from 'search-query-parser';

import { AggTable, FavoritesByTableID } from 'api/APITypes';
import { SearchColumnsByTableID, SearchColumn } from 'api/searchColumnAPI';

type TableFilter = (table: AggTable) => boolean;

// Keywords are words that are followed by a colon and
// pass an option to the search engine.
// For example:
// `t:pattern` searches table names for `pattern`
const WAREHOUSE_KEYWORDS = ['s', 't', 'c', 'i', 'f', 'q', 'td', 'cd', 'tag', 'u', 'is'];

// Stores all Keyword matches search-query-parser
// finds in the filter string.
export interface KeywordLists {
  s?: string[]; // schema names
  t?: string[]; // table names
  c?: string[]; // column names
  i?: string[]; // ids
  f?: string[]; // defaults = schema namess, table names, or column names: `f` is a throw back to `full_name` which is effectively schemas + tables, but not columns.
  q?: string[]; // sql
  td?: string[]; // table descriptions
  tag?: string[]; // tag contains
  u?: string[]; // last user to save
  is?: string[]; // is:favorite, is:snapshot, is:transform, is:scheduled, is:incremental, is:dbt, is:view, etc...
}

export function pickFilterFunc(
  filter: string,
  searchColumnsByTableID: SearchColumnsByTableID,
  favoritesByTableID: FavoritesByTableID,
): [(t: AggTable) => boolean, KeywordLists] {
  const activeKeywords = WAREHOUSE_KEYWORDS;
  const parserOptions = {
    keywords: activeKeywords,
    tokenize: true,
    alwaysArray: true,
    offsets: false,
  };
  const searchConstraints = searchParser.parse(filter, parserOptions) as searchParser.SearchParserResult;

  // If keyword match, filter by constraint object
  let { exclude: untypedExclude, ...untypedInclude } = searchConstraints;
  const include = untypedInclude as KeywordLists;
  const exclude = (untypedExclude || {}) as KeywordLists;

  // include.text can be undefined | string[].
  // Force it to string[].
  let textList = _.without(_.castArray(untypedInclude.text), undefined) as string[];
  if (textList.length) {
    textList = textList.map((textItem) => deBlinkFilterString(textItem, activeKeywords));
  }

  // Merge default textList search onto defaults
  // Defaults are aliased to 'f'
  include.f = include.f || [];
  include.f = _.concat(include.f, textList);

  const includeFunctions: TableFilter[] = keywordListsToFilterFunctions(
    include as KeywordLists,
    searchColumnsByTableID,
    favoritesByTableID,
  );
  const excludeFunctions: TableFilter[] = keywordListsToExcludeFuntions(
    exclude as KeywordLists,
    searchColumnsByTableID,
    favoritesByTableID,
  );
  const filterFunctions = _.concat(includeFunctions, excludeFunctions);

  // Performance optimizations because this will loop over thousands of objects.
  // A keyword: without a value, as in "t:", will cause filterFunctions.length to be zero.
  if (filterFunctions.length === 0) {
    return [(t: AggTable) => true, include];
  }
  if (filterFunctions.length === 1) {
    return [filterFunctions[0], include];
  }

  // AND together every filterFunction
  return [
    (table: AggTable) => {
      return _.every(filterFunctions, (filterFunc) => filterFunc(table));
    },
    include,
  ];
}

// If you want to search for `pattern t:name` you first have to type `pattern t`,
// and `pattern t` will cause the filter to not match anything until you type
// the following colon. Prevent this so there is no screen blink.
// Also handle keywords preceeded by `-`
function deBlinkFilterString(filter: string, activeKeywords: string[]) {
  const parts = filter.split(' ');
  if (parts.length > 1) {
    const firstParts = parts.slice(0, -1);
    const lastPart = parts.slice(-1);
    let lastString = lastPart[0].toLowerCase();
    const firstCharIsExclude = lastString.slice(0, 1) === '-';
    const isStartExclude = firstCharIsExclude && lastString.length === 1;
    if (firstCharIsExclude) {
      lastString = lastString.slice(1);
    }

    if (isStartExclude || activeKeywords.includes(lastString)) {
      filter = firstParts.join(' ');
    }
  }
  return filter;
}

function keywordListsToFilterFunctions(
  include: KeywordLists,
  searchColumnsByTableID: SearchColumnsByTableID,
  favoritesByTableID: FavoritesByTableID,
) {
  const filterFunctions: TableFilter[] = [];

  // Include filters
  // These are sorted by efficiency
  if (include.is) {
    include.is.forEach((isType: string) => {
      isType = isType.toLowerCase();
      // Table Creators:
      if (isType === 'favorite') {
        filterFunctions.push(filterIsFavorite(favoritesByTableID));
      } else if (isType === 'connector') {
        filterFunctions.push(filterIsConnector());
      } else if (isType === 'csv') {
        filterFunctions.push(filterIsCSV());
      } else if (isType === 'transform') {
        filterFunctions.push(filterIsTransform());
      } else if (isType === 'dbt') {
        filterFunctions.push(filterIsDbt());
      } else if (isType === 'unmanaged') {
        filterFunctions.push(filterIsUnmanaged());
      }
      // Settings that apply to all tables
      else if (isType === 'snapshotted') {
        filterFunctions.push(filterIsSnapshotted());
      }
      // Transform Status:
      else if (isType === 'scheduled') {
        filterFunctions.push(filterIsScheduled());
      } else if (isType === 'incremental') {
        filterFunctions.push(filterIsIncremental());
      } else if (isType === 'failed') {
        filterFunctions.push(filterIsFailed());
      }
      // Database Table Type
      else if (isType === 'table') {
        filterFunctions.push(filterIsTable());
      } else if (isType === 'view') {
        filterFunctions.push(filterIsView());
      } else if (isType === 'materialized') {
        filterFunctions.push(filterIsMaterializedView());
      }
    });
  }
  if (include.s) {
    include.s.forEach((pattern: string) => filterFunctions.push(filterBySchema(pattern)));
  }
  if (include.t) {
    include.t.forEach((pattern: string) => filterFunctions.push(filterByTable(pattern)));
  }
  if (include.i) {
    include.i.forEach((pattern: string) => filterFunctions.push(filterByID(pattern)));
  }
  if (include.c) {
    include.c.forEach((pattern: string) =>
      filterFunctions.push(filterByColumn(pattern, searchColumnsByTableID)),
    );
  }
  if (include.f) {
    include.f.forEach((pattern: string) =>
      filterFunctions.push(filterByDefaults(pattern, searchColumnsByTableID)),
    );
  }
  if (include.u) {
    include.u.forEach((pattern: string) => filterFunctions.push(filterByUser(pattern)));
  }
  if (include.td) {
    include.td.forEach((pattern: string) => filterFunctions.push(filterByTableDescription(pattern)));
  }
  if (include.tag) {
    include.tag.forEach((pattern: string) => filterFunctions.push(filterByTag(pattern)));
  }
  if (include.q) {
    include.q.forEach((pattern: string) => filterFunctions.push(filterBySql(pattern)));
  }

  return filterFunctions;
}

function keywordListsToExcludeFuntions(
  include: KeywordLists,
  searchColumnsByTableID: SearchColumnsByTableID,
  favoritesByTableID: FavoritesByTableID,
) {
  const includeFunctions = keywordListsToFilterFunctions(
    include as KeywordLists,
    searchColumnsByTableID,
    favoritesByTableID,
  );
  const excludeFunctions = includeFunctions.map((f: TableFilter) => (t: AggTable) => !f(t));
  return excludeFunctions;
}

/*******************************************************************************
 * Filter By Table Properties
 ******************************************************************************/
function filterByDefaults(filter: string, searchColumnsByTableID: SearchColumnsByTableID) {
  const fbc = filterByColumn(filter, searchColumnsByTableID);

  const escapedFilter = escapeRegex(filter);
  const escapedRegex = new RegExp(escapedFilter, 'i');
  return (t: AggTable) => {
    return !!t.full_name.match(escapedRegex) || fbc(t);
  };
}

function filterBySchema(filter: string) {
  const escapedFilter = escapeRegex(filter);
  const escapedRegex = new RegExp(escapedFilter, 'i');
  return (t: AggTable) => {
    return !!t.schema.match(escapedRegex);
  };
}

function filterByTable(filter: string) {
  const escapedFilter = escapeRegex(filter);
  const escapedRegex = new RegExp(escapedFilter, 'i');
  return (t: AggTable) => {
    return !!t.name.match(escapedRegex);
  };
}

function filterByColumn(filter: string, searchColumnsByTableID: SearchColumnsByTableID) {
  const escapedFilter = escapeRegex(filter);
  const escapedRegex = new RegExp(escapedFilter, 'i');
  return (t: AggTable) => {
    const columns = searchColumnsByTableID[t.id];
    return !!columns && columns.some((c: SearchColumn) => !!c.name.match(escapedRegex));
  };
}

function filterByID(filter: string) {
  const escapedFilter = escapeRegex(filter);
  const escapedRegex = new RegExp(escapedFilter, 'i');
  return (t: AggTable) => {
    return !!t.id.match(escapedRegex);
  };
}

function filterBySql(filter: string) {
  const escapedFilter = escapeRegex(filter);
  const escapedRegex = new RegExp(escapedFilter, 'i');
  return (t: AggTable) => {
    return (
      !!t.transform &&
      !!t.transform.current_version &&
      !!t.transform.current_version.sql.match(escapedRegex)
    );
  };
}

function filterByTableDescription(filter: string) {
  const escapedFilter = escapeRegex(filter);
  const escapedRegex = new RegExp(escapedFilter, 'i');
  return (t: AggTable) => {
    return !!t.description && !!t.description.match(escapedRegex);
  };
}

function filterByTag(filter: string) {
  const escapedFilter = escapeRegex(filter);
  const escapedRegex = new RegExp(escapedFilter, 'i');
  return (t: AggTable) => {
    return t.tags.length > 0 && t.tagObjs.some((t) => t.name.match(escapedRegex));
  };
}

function filterByUser(filter: string) {
  const escapedFilter = escapeRegex(filter);
  const escapedRegex = new RegExp(escapedFilter, 'i');
  return (t: AggTable) => {
    if (t.transform === null || t.transform.current_version === undefined) {
      return false;
    }
    const { first_name, last_name, user } = t.transform.current_version.created_by;
    const first = first_name || '';
    const last = last_name || '';
    const email = user.email;
    const searchString = `${first} ${last} ${email}`;
    return !!searchString.match(escapedRegex);
  };
}

/*******************************************************************************
 * Filter By Table Creators
 ******************************************************************************/
function filterIsFavorite(favoritesByTableID: FavoritesByTableID) {
  return (t: AggTable) => {
    return !!favoritesByTableID[t.id];
  };
}

function filterIsConnector() {
  return (t: AggTable) => {
    return !!t.connector;
  };
}

function filterIsCSV() {
  return (t: AggTable) => {
    return !!t.csvUpload;
  };
}

function filterIsTransform() {
  return (t: AggTable) => {
    return !!t.transform;
  };
}

function filterIsDbt() {
  return (t: AggTable) => {
    return t.type === 'dbt';
  };
}

function filterIsUnmanaged() {
  return (t: AggTable) => {
    return t.type === 'unmanaged' && !t.connector;
  };
}

/*******************************************************************************
 * Filter settings that apply to all tables
 ******************************************************************************/
function filterIsSnapshotted() {
  return (t: AggTable) => {
    return !!t.snapshot;
  };
}

/*******************************************************************************
 * Filter By Transform Status
 ******************************************************************************/
function filterIsScheduled() {
  return (t: AggTable) => {
    return !!t.transform && t.transform.scheduled;
  };
}

function filterIsIncremental() {
  return (t: AggTable) => {
    return !!t.transform && t.transform.incremental;
  };
}

function filterIsFailed() {
  return (t: AggTable) => {
    if (!t.transform || !t.transform.last_completed_run) {
      return false;
    }
    return t.transform.last_completed_run.state === 'failed';
  };
}

/*******************************************************************************
 * Filter By Database Type
 ******************************************************************************/
function filterIsTable() {
  return (t: AggTable) => {
    return t.database_table_type === 'BASE TABLE';
  };
}

function filterIsView() {
  return (t: AggTable) => {
    return t.database_table_type === 'VIEW';
  };
}

function filterIsMaterializedView() {
  return (t: AggTable) => {
    return t.database_table_type === 'MATERIALIZED VIEW';
  };
}
