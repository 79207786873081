import { ConnectorSyncStatus } from 'api/connectorsAPI';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';

interface LogStatusIconProps {
  status: ConnectorSyncStatus;
}

export default function LogStatusIcon(props: LogStatusIconProps) {
  const { status } = props;
  if (status === 'FAILURE' || status === 'FAILURE_WITH_TASK') {
    return <StatusIcon status="error" />;
  }
  if (status === 'CANCELED') {
    return <StatusIcon status="warning" />;
  }
  // Rescheduled should not be a "success".
  // It should be a "we are still working on it".
  // It was moved here as a temporary mitigation on 09/11/24:
  // https://github.com/MozartData/mozart/pull/8087
  if (status === 'SUCCESSFUL' || status === 'RESCHEDULED') {
    return <StatusIcon status="success" />;
  }
  return <StatusIcon status="syncing" rotate={true} />;
}
