import React from 'react';

import { Link } from 'react-router-dom';

export interface FormLayoutTitleRowProps {
  title: string;
  linkText: string;
  linkTo: string;
  linkDataTrack: string;
}

export default function FormLayoutTitleRow(props: FormLayoutTitleRowProps) {
  let { title, linkText, linkTo, linkDataTrack } = props;
  return (
    <div className="f-between">
      <h1 className="display-sm">{title}</h1>
      <Link className="text-purple" to={linkTo} data-track={linkDataTrack}>
        {linkText}
      </Link>
    </div>
  );
}
