/*
Actions that apply to any table type.
*/
import {
  ObjectDelta,
  ObjectName,
  TableLinkOrName,
  UserActionSentencePartProps,
} from './UserActionSentenceParts';

export const TableSnapshotToggledText = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  const { new_value } = userAction.metadata;
  return (
    <>
      Snapshot for <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> has been
      toggled <ObjectDelta value={new_value} />.
    </>
  );
};

export const TableSnapshotManualRunText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => {
  return (
    <>
      <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> was{' '}
      <ObjectDelta value="manually snapshotted" />.
    </>
  );
};

export const TableHasBeenRenamedText = ({ userAction, eventLocation }: UserActionSentencePartProps) => (
  <>
    <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> has been renamed from{' '}
    <ObjectDelta value={userAction.metadata.old_value} /> to{' '}
    <ObjectDelta value={userAction.metadata.new_value} />.
  </>
);

export const CSVUploadedToTableText = ({ userAction, eventLocation }: UserActionSentencePartProps) => (
  <>
    CSV file <ObjectName object_name={userAction.metadata.file_name} /> was uploaded to{' '}
    <TableLinkOrName userAction={userAction} eventLocation={eventLocation} />.
  </>
);
