import { formatNumber } from 'utils/String';

interface FilterCountProps {
  isFiltering: boolean;
  filteredCount: number;
  unfilteredCount: number;
}

const FilterCount = (props: FilterCountProps) => {
  const { isFiltering, filteredCount, unfilteredCount } = props;
  if (isFiltering) {
    return (
      <div className="text-pri-gray-500 ml-1">
        {`(${formatNumber(filteredCount)} of ${formatNumber(unfilteredCount)} tables)`}
      </div>
    );
  }
  return null;
};

export default FilterCount;
