import { useEffect, useState, useCallback, useRef } from 'react';

import API from 'api/API';
import { SEARCH_COLUMNS_BY_TABLE_ID, setAPIPayload, getAPIPayload } from 'api/CacheKeys';
import searchColumnAPI, {
  SearchColumn,
  SearchColumnsByTableID,
  convertAPIColumnsByTableId,
} from 'api/searchColumnAPI';
import { useDatabaseAccount } from 'context/AuthContext';
import { parseIso } from 'utils/dateTime';

export default function useColumnContext() {
  const [searchColumnsByTableID, setSearchColumnsByTableID] = useState<SearchColumnsByTableID>({});
  const [isLoading, setIsLoading] = useState(true); // We have no columns from API.
  const [isLocal, setIsLocal] = useState(false); // We have columns from localStorage, but not the API.
  const [error, setError] = useState('');
  const [lastColumnsChange, setLastColumnsChange] = useState<Date | null>(null);
  const anyApiHasReturned = useRef(false); // When columns first load, has the api come back yet? So we don't overwrite it from localStorage cache
  const normalApiHasReturned = useRef(false); // Do not let the cached API overwrite the normal API.
  const apiRequestInProgress = useRef(false); // We don't want to refetch if we are currently waiting for a response
  const databaseType = useDatabaseAccount().type;

  const refreshColumns = useCallback(() => {
    if (!apiRequestInProgress.current) {
      apiRequestInProgress.current = true;
      searchColumnAPI
        .fetchColumnsByTableID(databaseType)
        .then((response) => {
          anyApiHasReturned.current = true;
          normalApiHasReturned.current = true;
          setSearchColumnsByTableID(response.data.columns);
          setLastColumnsChange(parseIso(response.data.last_columns_change));
          setIsLocal(false);
          setAPIPayload(SEARCH_COLUMNS_BY_TABLE_ID, response.data.columns);
        })
        .catch(() => {
          setError('Failed to load column list.');
        })
        .finally(() => {
          setIsLoading(false);
          apiRequestInProgress.current = false;
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshColumnsForTable = (tableID: string): Promise<SearchColumn[] | null> => {
    return searchColumnAPI
      .fetchTableColumns(tableID, databaseType)
      .then((response) => {
        const columns = response.data as SearchColumn[];
        setSearchColumnsByTableID((prevColumnsByTableID) => {
          const newColumnsByTableID = {
            ...prevColumnsByTableID,
            [tableID]: columns,
          };
          setAPIPayload(SEARCH_COLUMNS_BY_TABLE_ID, newColumnsByTableID);
          return newColumnsByTableID;
        });
        return columns;
      })
      .catch(() => {
        setError('Failed to load column list.');
        return null;
      });
  };

  // Step 1:
  // Get columns from a previous API call out of localStorage.
  useEffect(() => {
    const searchColumnsByTableID = getAPIPayload<SearchColumnsByTableID>(SEARCH_COLUMNS_BY_TABLE_ID);
    if (!anyApiHasReturned.current && searchColumnsByTableID) {
      setSearchColumnsByTableID(searchColumnsByTableID);
      setIsLocal(true);
    }
  }, []);

  // Step 2:
  // Call the cached API to get a list of tables quickly so the app can render quickly.
  // Do not write to the localStorage cache.
  // Do not overwrite the normal API if that API comes back quicker.
  useEffect(() => {
    const api = new API();
    api
      .get(`/api/columns/cached`)
      .then((response) => {
        if (!normalApiHasReturned.current) {
          // Only update table list if we got a cache hit and the API returned data.
          if (response.data.columns && response.data.last_tables_change) {
            anyApiHasReturned.current = true;
            const converted = convertAPIColumnsByTableId(response.data, databaseType);
            setSearchColumnsByTableID(converted.columns);
            setLastColumnsChange(parseIso(converted.last_columns_change));
            setIsLocal(false);
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        // Silently eat this error.
        // If the normal API loads correctly we are still OK.
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Step 3:
  // Get an up to date copy of the columns from the API.
  useEffect(() => {
    refreshColumns();
  }, [refreshColumns]);

  return {
    searchColumnsByTableID,
    isLoading,
    error,
    isLocal,
    lastColumnsChange,
    refreshColumns,
    refreshColumnsForTable,
  };
}
