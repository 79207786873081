import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import { useUserProfile } from 'context/AuthContext';

import StatusWidgetItem, { StatusWidgetItemData } from './StatusWidgetItem/StatusWidgetItem';

export interface StatusWidgetState {
  connectorsData: StatusWidgetItemData;
  transformsData: StatusWidgetItemData;
  dataAlertsData: StatusWidgetItemData;
}

export interface StatusWidgetProps extends StatusWidgetState {
  className?: string;
}

const StatusWidget = (props: StatusWidgetProps) => {
  const { connectorsData, transformsData, dataAlertsData, className } = props;
  const { userProfile } = useUserProfile();

  const anyLoading = !connectorsData.loaded && !transformsData.loaded && !dataAlertsData.loaded;
  const greeting = userProfile.first_name ? `Hello, ${userProfile.first_name}!` : 'Hello!';

  return (
    <div className={className}>
      <h1 className="text-3xl text-purple mb-4">{greeting}</h1>
      {anyLoading ? (
        <CenteredSpinner type="circle" />
      ) : (
        <div className="grid gap-y-2 gap-x-2 sm:grid-cols-3">
          <StatusWidgetItem type="connector" {...connectorsData} />
          <StatusWidgetItem type="transform" {...transformsData} />
          <StatusWidgetItem type="alert" {...dataAlertsData} />
        </div>
      )}
    </div>
  );
};

export default StatusWidget;
