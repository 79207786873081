import { Connector } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';

import PictureSentenceLayout from '../PictureSentenceLayout';

export interface CreateLaterViewProps {
  connector: Pick<Connector, 'id' | 'service' | 'schema'>;
  onGoBack(): void;
  onGoToConnectors(): void;
}

export default function CreateLaterView(props: CreateLaterViewProps) {
  const { connector, onGoBack, onGoToConnectors } = props;

  return (
    <PictureSentenceLayout
      connector={connector}
      img="/images/graphics/ootb/book_laptop_coffee.svg"
      sentence="No Problem. You can always create a report later."
    >
      <div className="f-row-y-center">
        <Button onClick={onGoBack}>Back</Button>
        <Button onClick={onGoToConnectors} className="ml-2">
          Go To Connectors
        </Button>
      </div>
    </PictureSentenceLayout>
  );
}
