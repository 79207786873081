import { useMemo, useState } from 'react';

import { AggTable } from 'api/APITypes';
import TabRow, { TabKeyType } from 'components/layouts/parts/TabRow/TabRow';
import { getMostRecentDestinationTable } from 'model_helpers/dbtAggTableHelper';

import { SummaryData } from '../usePreviewPaneData';

import HeaderRow, { HEADER_ROW_HEIGHT } from './HeaderRow';
import RunResultsTab from './RunResultsTab';
import SqlTab from './SqlTab';
import SummaryTab from './SummaryTab';

interface PreviewPaneProps {
  table: AggTable;
  maximized: boolean;
  loadingSummary: boolean;
  loadingError: string;
  summaryData: SummaryData | null;
  onClose(): void;
  onMaximize(): void;
  onNormalize(): void;
}

const SUMMARY_TAB = 'Summary';
const EXAMPLE_ROWS_TAB = 'ExampleRows';
const TRANSFORM_SQL_TAB = 'TransformSql';
const VIEW_SQL_TAB = 'ViewSql';
const TEMPLATED_CODE_TAB = 'TemplatedCode';
const COMPILED_SQL_TAB = 'CompiledSql';

export default function PreviewPane(props: PreviewPaneProps) {
  const {
    table,
    maximized,
    loadingSummary,
    loadingError,
    summaryData,
    onClose,
    onMaximize,
    onNormalize,
  } = props;
  const [tab, setTab] = useState(EXAMPLE_ROWS_TAB);

  const { type, view_sql } = table;
  const hasTransformSqlTab = type === 'transform';
  const hasViewSqlTab = !!view_sql && type === 'unmanaged';
  const hasDBTSqlTabs = type === 'dbt';

  // A user can be on a table and click on a different table that doesn't have that same tab.
  if (!hasTransformSqlTab && tab === TRANSFORM_SQL_TAB) {
    setTab(EXAMPLE_ROWS_TAB);
  }
  if (!hasViewSqlTab && tab === VIEW_SQL_TAB) {
    setTab(EXAMPLE_ROWS_TAB);
  }
  if (!hasDBTSqlTabs && (tab === TEMPLATED_CODE_TAB || tab === COMPILED_SQL_TAB)) {
    setTab(EXAMPLE_ROWS_TAB);
  }

  const tabs = useMemo(() => {
    const tabs: TabKeyType[] = [
      { key: SUMMARY_TAB, label: 'Summary' },
      { key: EXAMPLE_ROWS_TAB, label: 'Example Rows' },
    ];
    if (hasTransformSqlTab) {
      tabs.push({ key: TRANSFORM_SQL_TAB, label: 'SQL' });
    }
    if (hasViewSqlTab) {
      tabs.push({ key: VIEW_SQL_TAB, label: 'View SQL' });
    }
    if (hasDBTSqlTabs) {
      tabs.push({ key: TEMPLATED_CODE_TAB, label: 'DBT Templated Code' });
      tabs.push({ key: COMPILED_SQL_TAB, label: 'DBT Compiled SQL' });
    }
    return tabs;
  }, [hasTransformSqlTab, hasViewSqlTab, hasDBTSqlTabs]);

  const handleSelectTab = (tabKey: string) => {
    if (tabKey !== tab) {
      analytics.track(`Warehouse Select${tabKey}Tab`);
      setTab(tabKey);
    }
  };

  const HEADER_ROW_HEIGHT_AND_PADDING = HEADER_ROW_HEIGHT + 16;
  const TAB_HEIGHT = 36;

  return (
    <div className={`w-full h-full bg-white px-4 pt-4`}>
      <div className="w-full h-full">
        <HeaderRow
          table={table}
          maximized={maximized}
          onClose={onClose}
          onMaximize={onMaximize}
          onNormalize={onNormalize}
        />
        <div
          className="w-full pt-2"
          style={{ height: `calc(100% - ${HEADER_ROW_HEIGHT_AND_PADDING}px)` }}
        >
          <div className="f-col w-full h-full">
            <div className="f-col w-full h-full">
              <TabRow tabs={tabs} variant="default" selectedTab={tab} onClick={handleSelectTab} />
              <div className="f-col w-full shadow-sm" style={{ height: `calc(100% - ${TAB_HEIGHT}px)` }}>
                {tab === SUMMARY_TAB && <SummaryTab table={table} />}
                {tab === EXAMPLE_ROWS_TAB && (
                  <RunResultsTab
                    table={table}
                    loadingSummary={loadingSummary}
                    loadingError={loadingError}
                    summaryData={summaryData}
                  />
                )}
                {hasTransformSqlTab && tab === TRANSFORM_SQL_TAB && (
                  <SqlTab sql={table.transform?.sql || ''} />
                )}
                {hasViewSqlTab && tab === VIEW_SQL_TAB && <SqlTab sql={table.view_sql || ''} />}
                {hasDBTSqlTabs && tab === TEMPLATED_CODE_TAB && (
                  <SqlTab sql={getMostRecentDestinationTable(table)?.raw_code || ''} />
                )}
                {hasDBTSqlTabs && tab === COMPILED_SQL_TAB && (
                  <SqlTab sql={getMostRecentDestinationTable(table)?.compiled_code || ''} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
