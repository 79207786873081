import React, { useContext, useMemo } from 'react';

import { AggTable } from 'api/APITypes';
import { SaveableTableProps } from 'api/tableAPI';
import ToggleFavoriteIcon from 'components/api_icon_buttons/ToggleFavoriteIcon/ToggleFavoriteIcon';
import LinkButton from 'components/inputs/basic/Button/LinkButton';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import { queryTable } from 'components/query/queryUtil';
import HeaderLabel from 'components/widgets/HeaderLabel/HeaderLabel';
import { getHeaderLabel } from 'model_helpers/aggTableHelper';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { Pipeline } from 'pages/tables/ShowTable/PipelineTab/PipelineEditor/PipelineEditor';
import TableTitleEditor from 'pages/tables/ShowTable/TitleEditor/TableTitleEditor';
import { snapshotFullName } from 'utils/dbName';

interface TableHeaderProps {
  table: AggTable;
  currentTab: string;
  pipeline: Pipeline | null;
  loadingPipeline: boolean;
  fetchPipelineData: () => void;
  saveTable?: (
    tableProps: SaveableTableProps,
    afterSave?: (table: AggTable) => void,
    afterFinally?: () => void,
    setError?: (e: string) => void,
  ) => void;
}

export default function TableHeader(props: TableHeaderProps) {
  const { table, currentTab, pipeline, loadingPipeline, fetchPipelineData, saveTable } = props;
  const { favoritesByTableID, addFavorite, removeFavorite } = useContext(TableModelsContext);

  // Needed to render TitleEditor warning message
  const snapshotName = useMemo(() => {
    if (table === null) {
      return null;
    }
    return snapshotFullName(table);
  }, [table]);

  return (
    <div className="f-between">
      <div className="flex items-center">
        <HeaderLabel label={getHeaderLabel(table)} />
        <TableTitleEditor
          table={table}
          snapshotName={snapshotName}
          fetchPipelineData={fetchPipelineData}
          saveTable={saveTable}
          pipeline={pipeline}
          loadingPipeline={loadingPipeline}
        />
        <ToggleFavoriteIcon
          showTip={true}
          table={table}
          favorite={favoritesByTableID[table.id]}
          addFavorite={addFavorite}
          removeFavorite={removeFavorite}
          eventPage="ShowTable"
          className="ml-1"
        />
      </div>
      <LinkButton
        variant="lightAction"
        disabled={!table.django_thinks_exists_in_snowflake}
        className="ml-4"
        to={queryTable(table.full_name, true)}
        data-track={`${currentTab} QueryTable`}
      >
        <div className="f-center">
          Query Table
          {!table.django_thinks_exists_in_snowflake && (
            <InfoIcon
              content={<div>This transform's table will be created after the transform is run.</div>}
              containerClass="ml-1"
              popoverProps={{ style: { maxWidth: '700px' } }}
            />
          )}
        </div>
      </LinkButton>
    </div>
  );
}
