/*
Renders a different editor layout depending on what isFlowchart and editorType are set to.
*/
import DraggablePanes from 'components/layouts/containers/draggable_panes/DraggablePanes/DraggablePanes';
import { AIAssistantState } from 'components/query/useAIAssistant';
import { EditorType } from 'components/query/useQueryEditor';

import AIAssistantEditor from './ChatAIEditor/AIAssistantEditor';
import SavedFlowchartEditor from './FlowchartEditor/SavedFlowchartEditor';
import SqlEditor, { SqlEditorProps } from './SqlEditor';
import { FlowchartEditorState } from './useFlowchartEditor';

interface EditorTypePickerProps extends Omit<SqlEditorProps, 'editorHeightCSS'> {
  isFlowchart: boolean;
  editorType: EditorType;
  eventPrefix: string;
  editorHeight: number;
  assistantState: AIAssistantState;
  flowchartState: FlowchartEditorState;
}

const EditorTypePicker = (props: EditorTypePickerProps) => {
  const {
    isFlowchart,
    editorType,
    eventPrefix,
    editorHeight,
    editMode,
    editorSql,
    assistantState,
    flowchartState,
    ...passToEditor
  } = props;

  // Pick which editor to render
  let sqlEditor: null | JSX.Element = null;
  if (!isFlowchart || (isFlowchart && flowchartState.showSQL)) {
    sqlEditor = (
      <SqlEditor
        id="SqlEditor"
        editMode={editMode}
        editorSql={editorSql}
        editorHeightCSS={`${editorHeight}px`}
        {...passToEditor}
      />
    );
  }

  let flowchartEditor: null | JSX.Element = null;
  if (isFlowchart) {
    flowchartEditor = <SavedFlowchartEditor flowchartState={flowchartState} />;
  }

  let innerElement: null | JSX.Element = sqlEditor;

  // Render SqlEditor and ChatAIEditor side by side.
  if (editorType === 'ai_assistant') {
    innerElement = (
      <DraggablePanes
        id="AIDraggablePanes"
        leftStartingWidth="50%"
        leftMinWidth={`10%`}
        rightMinWidth={`10%`}
      >
        <AIAssistantEditor {...assistantState} />
        {sqlEditor}
      </DraggablePanes>
    );
  }

  // Render FlowchartEditor and SqlEditor side by side.
  else if (isFlowchart && !flowchartState.showSQL) {
    innerElement = flowchartEditor;
  } else if (isFlowchart && flowchartState.showSQL) {
    innerElement = (
      <DraggablePanes leftStartingWidth="50%" leftMinWidth={`10%`} rightMinWidth={`10%`}>
        {flowchartEditor}
        {sqlEditor}
      </DraggablePanes>
    );
  }

  return innerElement;
};
export default EditorTypePicker;
