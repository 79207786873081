import React from 'react';

import cn from 'classnames';

import Button from 'components/inputs/basic/Button/Button';
import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { DependencyEdge } from 'pages/tables/ShowTable/PipelineTab/PipelineEditor/PipelineEditor';

import { UnsavedTransform, DependenciesById } from './RunOptionsModal';

interface AllMarkedSettingsProps {
  unsavedTransform: UnsavedTransform;
  unsavedDependencies: DependenciesById;
  setUnsavedDependencies: React.Dispatch<React.SetStateAction<DependenciesById>>;
}

export default function AllMarkedSettings(props: AllMarkedSettingsProps) {
  const { unsavedTransform, unsavedDependencies, setUnsavedDependencies } = props;

  const disabled = !unsavedTransform.scheduled;

  const handleToggleDependency = (dependency: DependencyEdge) => {
    const addedDependency = !unsavedDependencies[dependency.id].is_scheduling_dependency;
    unsavedDependencies[dependency.id].is_scheduling_dependency = addedDependency;
    setUnsavedDependencies({ ...unsavedDependencies });
  };

  const handleSelectAll = () => {
    // Modify every is_scheduling_dependency to true
    Object.keys(unsavedDependencies).forEach((id) => {
      unsavedDependencies[id].is_scheduling_dependency = true;
    });
    setUnsavedDependencies({ ...unsavedDependencies });
    analytics.track('RunOptionsModal SelectAll');
  };

  const handleClearAll = () => {
    // Modify every is_scheduling_dependency to false
    Object.keys(unsavedDependencies).forEach((id) => {
      unsavedDependencies[id].is_scheduling_dependency = false;
    });
    setUnsavedDependencies({ ...unsavedDependencies });
    analytics.track('RunOptionsModal ClearAll');
  };

  return (
    <div className={cn('h-full min-h-0 flex flex-col', { 'text-pri-gray-400': disabled })}>
      {Object.keys(unsavedDependencies).length === 0 ? (
        <Alert variant="warning" className="m-2">
          This transform does not have any selectable ancestors.
        </Alert>
      ) : (
        <>
          <div className="f-row-y-center">
            <Button size="small" variant="lightDullAction" onClick={handleSelectAll} disabled={disabled}>
              Select All
            </Button>
            <Button
              size="small"
              variant="lightDullAction"
              className="ml-2"
              onClick={handleClearAll}
              disabled={disabled}
            >
              Clear All
            </Button>
          </div>
          {Object.values(unsavedDependencies).filter((e) => e.is_scheduling_dependency === true)
            .length === 0 &&
            !disabled && (
              <Alert variant="warning" className="mt-2">
                This transform will not run unless you select at least one ancestor.
              </Alert>
            )}
          <div className="mt-2 text-sm font-medium">
            This transform will run after ALL selected ancestors have run:
          </div>
          <div className="h-full min-h-0 overflow-y-auto">
            {Object.values(unsavedDependencies)
              .sort((a, b) => a.source_name.localeCompare(b.source_name))
              .map((d) => {
                return (
                  <div
                    key={d.id}
                    onClick={disabled ? () => {} : () => handleToggleDependency(d)}
                    className={cn('flex mt-2', { 'cursor-pointer': !disabled })}
                  >
                    <Checkbox
                      name={`${d.id}_checkbox`}
                      checked={d.is_scheduling_dependency}
                      variant="blue_gray"
                      disabled={disabled}
                      readOnly={true} // the parent div handles onClick
                    />
                    <div className="ml-2">{d.source_name}</div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
}
