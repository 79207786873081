import { useRef } from 'react';

import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { AggTable } from 'api/APITypes';
import { CSVUpload, InitialSaveableCSVUploadProps, SaveableCSVUploadProps } from 'api/csvUploadAPI';
import useUploadCSV from 'api_hooks/useUploadCSV';
import Button from 'components/inputs/basic/Button/Button';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Alert from 'components/widgets/alerts/Alert/Alert';
import FileUpload, { explainNetworkError } from 'pages/csv/FileUpload';

const csvSchema = yup.object({
  file: yup.mixed().required('Required'),
});

interface ReplaceCSVModalProps {
  onCloseCSVModal(): void;
  onSuccessfulCSVUpload(csvUpload: CSVUpload): void;
  table: AggTable;
}

const ReplaceCSVModal = (props: ReplaceCSVModalProps) => {
  const { onCloseCSVModal, onSuccessfulCSVUpload, table } = props;

  const { saveCSV, savingCSV, csvErrorMessage, setCSVErrorMessage } = useUploadCSV();
  const fileRef = useRef<HTMLInputElement | null>(null);

  const handleReplaceCSV = (
    values: InitialSaveableCSVUploadProps,
    formikHelpers: FormikHelpers<InitialSaveableCSVUploadProps>,
  ) => {
    if (!values.file) {
      // Should never get here due to form validation.
      setCSVErrorMessage('File required');
      return;
    }

    setCSVErrorMessage('');
    return saveCSV(values as SaveableCSVUploadProps)
      .then((csvUpload) => {
        onSuccessfulCSVUpload(csvUpload);
      })
      .catch((e: Error) => {
        // FIRST RESPONSIBILITY:
        // Eat error so it doesn't bubble up to global exception handler.
        // saveCSV() already sets csvErrorMessage.

        // SECOND RESPONSIBILITY:
        // This fixes a Chrome bug.
        // See comments in FileUpload.tsx for details.
        if (e.message === 'Network Error' && fileRef.current) {
          formikHelpers.setFieldValue('file', null); // Reset Formik value
          fileRef.current.value = ''; // Reset the value in the <input type="file"/> element
        }
      });
  };

  const initialCSVReplaceValues: InitialSaveableCSVUploadProps = {
    table_name: table.name,
    schema: table.schema,
    description: table.description,
    file: null,
    overwrite: true,
  };

  return (
    <Modal
      header="Replace File"
      onClose={onCloseCSVModal}
      cancelButton={true}
      containerClass="w-[491px]"
    >
      <Formik
        validationSchema={csvSchema}
        onSubmit={handleReplaceCSV}
        initialValues={initialCSVReplaceValues}
      >
        {({ handleSubmit, values }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div className="flex-col p-4">
                <div className="text-pri-gray-700">Select a file to replace the current table</div>
                <div className="mt-2 flex flex-col items-start">
                  <FileUpload ref={fileRef} setErrorMessage={setCSVErrorMessage} />
                </div>
                {csvErrorMessage && (
                  <Alert variant="error" className="mt-4 break-all">
                    {explainNetworkError(csvErrorMessage)}
                  </Alert>
                )}
              </div>
              <div className="w-full h-px bg-gray-200" />
              <div className="flex-col p-4">
                <div className="flex justify-end">
                  <Button
                    variant="lightAction"
                    type="submit"
                    className="ml-2"
                    spinning={savingCSV}
                    disabled={!values.file?.name}
                  >
                    Replace
                  </Button>
                  <Button
                    variant="lightDanger"
                    className="ml-2"
                    onClick={onCloseCSVModal}
                    disabled={savingCSV}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ReplaceCSVModal;
