import { useState } from 'react';

import { FormikValues } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import descriptionAIAPI from 'api/descriptionAIAPI';
import { AggTable } from 'api/tableAPI';
import { useUserProfile } from 'context/AuthContext';
import useAIPermissionModalContext from 'model_layer/useAIPermissionModalContext';

import AITableDescriptionButton from './AITableDescription/AITableDescriptionButton';
import EditDescriptionForm from './EditDescriptionForm';
import ViewDescription from './ViewDescription';

type GeneratedText = {
  id: string;
  text: string;
} | null;

interface DescriptionProps {
  table: AggTable;
  description: string;
  isSaving: boolean;
  onSaveDescription: (description: string, onSaveSuccess: () => void) => void;
}

const Description = (props: DescriptionProps) => {
  const { table, description, isSaving, onSaveDescription } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  // Used to track the editing "session" for metrics
  const [editingID, setEditingID] = useState<string | null>(null);
  const [isAIEnabled, setIsAIEnabled] = useState<boolean>(false);

  const [isGeneratingText, setIsGeneratingText] = useState<boolean>(false);
  const [generatedText, setGeneratedText] = useState<GeneratedText>(null);
  const [generatedTextError, setGeneratedTextError] = useState<any>(null);

  const [feedbackResponse, setFeedbackResponse] = useState<string | null>(null);
  const { setShowAIPermissionModal } = useAIPermissionModalContext();
  const { userProfile } = useUserProfile();
  const {
    company: { allow_ai },
  } = userProfile;
  const canEdit = userProfile.company_role !== 'viewer';

  const trackAIDescriptionGeneration = (eventName: string, trackProperties?: Object) => {
    performance.mark(`SummaryTab GenerateAIDescription end`);
    performance.measure(
      `SummaryTab GenerateAIDescription`,
      `SummaryTab GenerateAIDescription start`,
      `SummaryTab GenerateAIDescription end`,
    );

    const performanceList = performance.getEntriesByName(`SummaryTab GenerateAIDescription`);
    const duration = performanceList[performanceList.length - 1].duration;
    analytics.track(eventName, {
      editing_id: editingID,
      table_id: table.id,
      table_name: table.name,
      original_description: description,
      duration,
      ...(trackProperties ?? {}),
    });
  };

  const fetchGeneratedText = async (id?: string) => {
    performance.mark('SummaryTab GenerateAIDescription start');
    setIsGeneratingText(true);

    try {
      const response = await descriptionAIAPI.generateDescription(table.id);
      const responseData = {
        id: response.data.generated_description_record_id,
        text: response.data.table_description,
      };
      setGeneratedText(responseData);
      setGeneratedTextError(null);
      trackAIDescriptionGeneration('SummaryTab FinishAIDescriptionGeneration', {
        editing_id: id ?? editingID,
        // `id` is only passed on first generation, so this helps us track when a description was
        // regenerated or initially generated.
        regenerated: id === undefined,
        generated_description_record_id: responseData.id,
        generated_description: responseData.text,
      });
    } catch (error: any) {
      setGeneratedText(null);
      setGeneratedTextError(error);
      const { message: errorMessage } = error;

      trackAIDescriptionGeneration('SummaryTab RaiseAIDescriptionGenerationError', {
        editing_id: id ?? editingID,
        error: errorMessage,
      });
    }

    setIsGeneratingText(false);
    setFeedbackResponse(null);
  };

  const handleFeedbackClick = (response: string) => {
    setFeedbackResponse(response);
    analytics.track('SummaryTab SubmitAIDescriptionFeedback', {
      editing_id: editingID,
      table_id: table.id,
      table_name: table.name,
      original_description: description,
      generated_description_record_id: generatedText?.id ?? null,
      generated_description: generatedText?.text ?? null,
      feedback_response: response,
    });
  };

  const handleUseGeneratedTextClick = (eventName: string, updatedDescription?: string) => {
    analytics.track(eventName, {
      editing_id: editingID,
      table_id: table.id,
      table_name: table.name,
      original_description: description,
      // Only include updatedDescription prop if it's not undefined (i.e. when the action wasn't "Copy")
      ...(updatedDescription ? { updated_description: updatedDescription } : {}),
      generated_description_record_id: generatedText?.id ?? null,
      generated_description: generatedText?.text ?? null,
    });
  };

  const handleClickToEdit = () => {
    const id = uuidv4();
    setEditingID(id);

    setIsEditing(true);
    analytics.track('SummaryTab OpenEditDescription', {
      editing_id: id,
      table_id: table.id,
      table_name: table.name,
      original_description: description,
    });
  };

  const handleClickAI = () => {
    if (!allow_ai) {
      setShowAIPermissionModal(true);
    } else {
      // When AI button is clicked, either
      //  1) the user is already editing the description, so we should use the existing `editingID`, or
      //  2) the user is starting an edit, so we need to use the existing editing "session" ID.
      const id = editingID ?? uuidv4();

      setIsAIEnabled(true);
      setIsEditing(true);
      analytics.track('SummaryTab OpenAIForDescription', {
        editing_id: id,
        table_id: table.id,
        table_name: table.name,
        original_description: description,
      });

      setEditingID(id);

      fetchGeneratedText(id);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setIsAIEnabled(false);
    analytics.track('SummaryTab CancelEditDescription', {
      editing_id: editingID,
      table_id: table.id,
      table_name: table.name,
      original_description: description,
      generated_description_record_id: generatedText?.id ?? null,
      generated_description: generatedText?.text ?? null,
      feedback_response: feedbackResponse ?? null,
    });

    setEditingID(null);
    setGeneratedText(null);
    setGeneratedTextError(null);
    setFeedbackResponse(null);
  };

  const handleCloseAI = () => {
    setIsAIEnabled(false);
    analytics.track('SummaryTab CloseAIForDescription', {
      editing_id: editingID,
      table_id: table.id,
      table_name: table.name,
      original_description: description,
      generated_description_record_id: generatedText?.id ?? null,
      generated_description: generatedText?.text ?? null,
      feedback_response: feedbackResponse ?? null,
    });

    setGeneratedText(null);
    setGeneratedTextError(null);
    setFeedbackResponse(null);
  };

  const handleSave = (values: FormikValues) => {
    onSaveDescription(values.description, () => {
      analytics.track('SummaryTab SaveDescription', {
        editing_id: editingID,
        table_id: table.id,
        table_name: table.name,
        original_description: description,
        updated_description: values.description,
        generated_description_record_id: generatedText?.id ?? null,
        generated_description: generatedText?.text ?? null,
        feedback_response: feedbackResponse ?? null,
      });

      setIsEditing(false);
      setIsAIEnabled(false);

      setEditingID(null);
      setGeneratedText(null);
      setGeneratedTextError(null);
      setFeedbackResponse(null);
    });
  };

  if (!isEditing) {
    return (
      <div>
        <ViewDescription description={description} canEdit={canEdit} onClick={handleClickToEdit} />
        <AITableDescriptionButton onClick={handleClickAI} className="mt-1" />
      </div>
    );
  }

  return (
    <EditDescriptionForm
      initialValue={description}
      isSaving={isSaving}
      isAIEnabled={isAIEnabled}
      generatedText={generatedText?.text ?? ''}
      generatedTextError={generatedTextError}
      isGeneratingText={isGeneratingText}
      onClickAIButton={handleClickAI}
      onCloseAI={handleCloseAI}
      onGenerateText={fetchGeneratedText}
      onUseGeneratedTextClick={handleUseGeneratedTextClick}
      onFeedbackClick={handleFeedbackClick}
      onSubmit={handleSave}
      onCancel={handleCancelEdit}
    />
  );
};

export default Description;
