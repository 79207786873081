/*
Rounded corners, shadow, and spacing to create perception
child element is floating on top.
*/

interface FloatingSidebarWrapperProps {
  children: React.ReactNode;
}

export default function FloatingSidebarWrapper(props: FloatingSidebarWrapperProps) {
  const { children } = props;

  return (
    <div className="h-full py-4 pr-4 f-col flex-grow">
      <div className="w-full h-full f-col flex-grow overflow-hidden rounded shadow-[0px_0px_6px_1px_rgba(0,0,0,0.25)]">
        {children}
      </div>
    </div>
  );
}
