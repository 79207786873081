import { SigmaIntegration } from 'api/APITypes';
import GenerateSnowflakeUser from 'components/business_logic/GenerateSnowflakeUser/GenerateSnowflakeUser';
import Accordion from 'components/widgets/accordions/Accordion/Accordion';

import ExternalLink from '../../ExternalLink';
import { DatabaseConfigInfoResponse } from '../../IntegrationSnowflakeConfig';
import SnowflakeConfig from '../../SnowflakeConfig';
import Steps from '../../Steps';

import { defaultIntegrationMap } from '../DefaultLayoutData';

import APIKeyForm from './APIKeyForm';
import ConnectSnowflake from './ConnectSnowflake';
import { SigmaScreen } from './SigmaController';
import TrySigma from './TrySigma';
import YouAreAlreadyUsingSigma from './YouAreAlreadyUsingSigma';

export interface SigmaAPIKeyFormValues {
  base_url_prefix: string;
  client_id: string;
  client_secret: string;
}

export interface SigmaViewProps {
  values: DatabaseConfigInfoResponse | undefined;
  apiKeyScreen: SigmaScreen;
  setupSnowflakeScreen: SigmaScreen;
  handleManualConnectionInstructionsClick: (newExpand: boolean) => void;
  handleShowAPIKeysIntegrationClick: () => void;
  handleShowSnowflakeInSigmaClick: () => void;
  handleSubmitAPIKey: (values: SigmaAPIKeyFormValues) => void;
  handleDoSetupSnowflakeInSigma: () => void;
  databaseType: string;
  databaseDisplayName: string;
  sigmaIntegration: SigmaIntegration | null;
}

const SigmaView = (props: SigmaViewProps) => {
  const {
    values,
    apiKeyScreen,
    setupSnowflakeScreen,
    handleManualConnectionInstructionsClick,
    handleShowAPIKeysIntegrationClick,
    handleShowSnowflakeInSigmaClick,
    handleSubmitAPIKey,
    handleDoSetupSnowflakeInSigma,
    databaseType,
    databaseDisplayName,
    sigmaIntegration,
  } = props;

  const { key, type, url, steps, uiName, ...rest } = defaultIntegrationMap['sigma'];

  if (apiKeyScreen.show) {
    return (
      <APIKeyForm
        handleSubmitAPIKey={handleSubmitAPIKey}
        loading={apiKeyScreen.loading}
        error={apiKeyScreen.error}
        handleShowAPIKeysIntegrationClick={handleShowAPIKeysIntegrationClick}
      />
    );
  }

  if (setupSnowflakeScreen.show) {
    return (
      <ConnectSnowflake
        handleShowSnowflakeInSigmaClick={handleShowSnowflakeInSigmaClick}
        handleDoSetupSnowflakeInSigma={handleDoSetupSnowflakeInSigma}
        settingUpSnowflake={setupSnowflakeScreen.loading}
        setupSnowflakeError={setupSnowflakeScreen.error}
      />
    );
  }

  return (
    <div>
      {sigmaIntegration ? (
        <YouAreAlreadyUsingSigma
          sigmaIntegration={sigmaIntegration}
          handleShowSnowflakeInSigmaClick={handleShowSnowflakeInSigmaClick}
        />
      ) : (
        <TrySigma
          handleShowAPIKeysIntegrationClick={handleShowAPIKeysIntegrationClick}
          databaseDisplayName={databaseDisplayName}
        />
      )}
      {databaseType !== 'bigquery' && (
        <Accordion
          header="Connect to Sigma manually"
          onClick={handleManualConnectionInstructionsClick}
          className="mt-6"
        >
          <Steps containerClass="bg-pri-gray-50 pb-4 pr-4 rounded">
            <li>
              Log into <ExternalLink href={url}>{uiName}</ExternalLink>.
            </li>
            {steps.map((step, idx) => (
              <li key={idx}>{step}</li>
            ))}
          </Steps>
          <SnowflakeConfig {...rest} values={values} />
          <GenerateSnowflakeUser className="mt-4" />
        </Accordion>
      )}
    </div>
  );
};

export default SigmaView;
