import { PipelineVertex } from '../useVertexConverter';

/**
 * Get table type (e.g. "Snapshot", "Transform", etc.) from vertex
 * @param vertex
 * @returns Table type as a label to use for legend vertex
 */
export function getTableType(vertex: PipelineVertex) {
  let tableType = 'Table';
  if (vertex.table && vertex.table.type === 'transform') {
    tableType = 'Transform';
  } else if (vertex.table && vertex.table.type === 'snapshot') {
    tableType = 'Snapshot';
  }
  return tableType;
}
