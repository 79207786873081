import React from 'react';

import usePopperRefs, { Placement, RenderPopper } from 'components/overlay/PopperTrigger/usePopperRefs';

import OverlayTrigger, { OverlayTriggerProps } from '../OverlayTrigger/OverlayTrigger';

export interface PopperTriggerProps extends Omit<OverlayTriggerProps, 'renderOverlay'> {
  renderPopper: RenderPopper;
  placement: Placement;
  popoverProps?: React.HTMLAttributes<HTMLDivElement>;
}

function PopperTrigger(props: PopperTriggerProps) {
  const { renderPopper, placement, popoverProps, ...overlayProps } = props;

  const { setReferenceElement, ...renderPopperProps } = usePopperRefs(placement);
  const allPopperProps = {
    ...renderPopperProps,
    popoverProps,
  };

  return (
    <OverlayTrigger
      ref={setReferenceElement}
      {...overlayProps}
      renderOverlay={() => {
        return renderPopper(allPopperProps);
      }}
    />
  );
}

export default PopperTrigger;
