/*
  Controls tasks relevant to the FlowchartEditor in the larger QueryEditor.

Responsibilities:
1. Loads and saves SavedFlowchartQueryModels for the FlowchartEditor.
2. Collects all of the other API models needed to build a FlowchartQueryModel from a SavedFlowchartQueryModel.
3. Generates SQL from the FlowchartQueryModel.
3. Runs SQL queries derrived from the FlowchartQueryModel with useQueryEditor()'s query tools.
*/
import { useCallback, useEffect, useMemo, useState, SetStateAction } from 'react';

import deepEqual from 'fast-deep-equal';

import API from 'api/API';
import { QueryRunResults } from 'api/APITypes';
import { Column, ColumnsByTableID } from 'api/columnAPI';
import { AggTable } from 'api/tableAPI';
import {
  FlowchartQueryModel,
  FlowchartVertex,
  getFinalVertex,
} from 'components/query/FlowchartEditor/model/FlowchartQueryModel';
import { buildFlowchartExpression } from 'components/query/FlowchartEditor/query_builder/flowchartExpressionBuilder';
import { buildGlotExpression } from 'components/query/FlowchartEditor/query_builder/glotExpressionBuilder';
import useFlowchartModelTools, {
  FlowchartQueryModelTools,
} from 'components/query/useFlowchartQueryModelTools';
import { FlowchartSaveProps } from 'components/query/useQueryEditor';
import useMemoObject from 'hooks/useMemoObject';

import { errorsToSqlComments } from './FlowchartEditor/model/errorsToSql';
import { flowchartModelToSavedModel } from './FlowchartEditor/model/flowchartQueryModelConverter';
import { validate } from './FlowchartEditor/model/flowchartQueryModelValidator';
import { SavedFlowchartQueryModel } from './FlowchartEditor/model/SavedFlowchartQueryModel';
import QueryRunCache from './FlowchartEditor/QueryRunCache';
import useFlowchartAPILoader from './useFlowchartAPILoader';
import useFlowchartModelConverter from './useFlowchartModelConverter';
import { EditorRunProps } from './useSqlEditor';

// Temporary state that is not part of the longterm persisted FlowchartQueryModel
// and is caused by user interactions with the UI
// such as users clicking or APIs loading.
export interface InteractiveFlowchartProps {
  loadingVertexIDs: Record<string, boolean>;
  selectedVertexID: string | null;
  glottingVertexID: string | null;
  selectedEdgeID: string | null;
}

export interface FlowchartEditorState {
  savedFQM: FlowchartQueryModel; // FQM at last save point.
  activeFQM: FlowchartQueryModel; // FQM currently rendered in the UI. This may contain unsaved changes.
  hasUnsavedChanges: boolean;
  flowchartModelTools: FlowchartQueryModelTools;
  tablesByID: { [id: string]: AggTable };
  columnsByTableID: ColumnsByTableID; // The columns of the tables used by this flowchart.
  objectsForSavedModelLoaded: boolean;
  interactiveFlowchartProps: InteractiveFlowchartProps;
  flowchartControlBarProps: FlowchartControlBarProps;
  error: string;
  showSQL: boolean;
  isSaving: boolean;
  selectedVertexID: string | null;
  selectedEdgeID: string | null;
  setSelectedVertexID: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedEdgeID: React.Dispatch<React.SetStateAction<string | null>>;
  setVertexIDLoading: (vertexID: string, loading: boolean) => void;
  cachingSetAndRunSQL: (sql: string, skipRunWithNull?: boolean) => Promise<EditorRunProps>;
  vertexToSQL: (vertex: FlowchartVertex) => Promise<string>;
  setError: (error: string) => void;
  fetchColumns: (tableID: string) => Promise<{ columns: Column[]; columnsByTableID: ColumnsByTableID }>;
  setShowSQL: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => Promise<FlowchartQueryModel>;
  onDiscardChanges: () => void;
}

export interface FlowchartControlBarProps {
  isSaved: boolean;
  isSaving: boolean;
  showSQL: boolean;
  setShowSQL: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => Promise<FlowchartQueryModel>;
  onDiscardChanges: () => void;
}

interface UseFlowchartEditorProps {
  flowchartSaveProps?: FlowchartSaveProps;
  tablesByID: { [id: string]: AggTable };
  tablesLoaded: boolean;
  isHidden: boolean;
  setSqlAndRun: (sql: string) => Promise<EditorRunProps>;
  setSqlAndSimulateRun: (sql: string, runResults: QueryRunResults | null) => EditorRunProps;
}

export default function useFlowchartEditor(props: UseFlowchartEditorProps): FlowchartEditorState {
  const { flowchartSaveProps, tablesByID, tablesLoaded, isHidden, setSqlAndRun, setSqlAndSimulateRun } =
    props;
  // Vertexes the user cannot interact with because the app is doing something,
  // probably fetching data from the API
  const [loadingVertexIDs, setLoadingVertexIDs] = useState<Record<string, boolean>>({});
  // The vertex the user clicked on last.
  const [selectedVertexID, setSelectedVertexID] = useState<string | null>(null);
  // The edge the user clicked on last.
  const [selectedEdgeID, setSelectedEdgeID] = useState<string | null>(null);
  // The vertex we are trying to calculate SQL for.
  // Probably the selectedVertexID.
  const [glottingVertexID, setGlottingVertexID] = useState<string | null>(null);
  const [error, setError] = useState('');
  const [showSQL, setShowSQL] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const queryRunCache = useMemo(() => new QueryRunCache(), []);

  // Selecting a vertex clears the selected edge.
  const resettingSetSelectedVertexID = useCallback((selectedVertexID: SetStateAction<string | null>) => {
    setSelectedVertexID(selectedVertexID);
    setSelectedEdgeID(null);
  }, []);

  // Selecting an edge clears the selected vertex.
  const resettingSetSelectedEdgeID = useCallback((selectedEdgeID: SetStateAction<string | null>) => {
    setSelectedEdgeID(selectedEdgeID);
    setSelectedVertexID(null);
  }, []);

  // @MATT, I don't 100% understand how the SavedQueries page is supposed to work.
  // Is there a better way to address this problem. This is getting the job done for now.
  // When I first load in SavedQuery mode use the API's value.
  // In useQueryTabs().saveFlowchart() the call to setAllSavedQueries() method does no trigger a rerender of newAllSavedQueries,
  // So I'm going to update this variable to trigger a rerender.
  // This might be desirable in all modes so that an API update cannot overwrite changes.
  // We might also want to redo this when we do Transform and Alert modes.
  // Discuss as group at later date.
  const [mySFQM, setMySFQM] = useState<SavedFlowchartQueryModel | undefined>(
    flowchartSaveProps?.savedFlowchartModel,
  );

  // Fetch the models I need from the API to convert SavedFlowchartQueryModel
  const apiState = useFlowchartAPILoader({
    savedFlowchartModel: mySFQM,
    tablesByID,
    tablesLoaded,
    isHidden,
  });

  // Convert SavedFlowchartModel into data structure the UI can use.
  const convertedState = useFlowchartModelConverter(apiState);
  const { savedFQM, columnsByTableID, objectsForSavedModelLoaded } = convertedState;

  // The FlowchartQueryModel the user is actively editing that might be in an unsaved state
  const [activeFQM, setActiveFQM] = useState<FlowchartQueryModel>(savedFQM);

  // FYI:
  // This line has the potential to overwrite user changes if the user were
  // to save to the API and make more local changes very quickly before the API returned
  // or if some future API polling updated this variable.
  // On 07/29/24, we decided this was good enough for now, but are mindful of this logic.
  useEffect(() => {
    setActiveFQM(savedFQM);
  }, [savedFQM]);

  const hasUnsavedChanges = useMemo(() => !deepEqual(activeFQM, savedFQM), [activeFQM, savedFQM]);

  // Useful tools for updating the FlowchartQueryModel
  const flowchartModelTools = useFlowchartModelTools(setActiveFQM);

  const vertexToSQL = useCallback(
    (vertex: FlowchartVertex): Promise<string> => {
      // There can only be one conversion at a time
      if (glottingVertexID) {
        return Promise.reject(new Error('Glot in progress.'));
      }

      setGlottingVertexID(vertex.id);
      setError('');

      try {
        var flowchartExpression = buildFlowchartExpression(activeFQM, vertex);
      } catch (e) {
        setGlottingVertexID(null);
        return Promise.reject(e);
      }

      try {
        var glotExpression = buildGlotExpression(activeFQM, flowchartExpression);
      } catch (e) {
        setGlottingVertexID(null);
        return Promise.reject(e);
      }

      const postData = {
        sqlglot: glotExpression,
      };

      const api = new API();
      return api
        .post('api/nocode/sqlglot_to_sql', postData)
        .then((response) => {
          return response.data.sql;
        })
        .catch((e) => {
          const prettyError = new Error('Failed to convert flowchart to SQL.');
          return prettyError;
        })
        .finally(() => {
          setGlottingVertexID(null);
        });
    },
    [activeFQM, glottingVertexID],
  );

  const cachingSetAndRunSQL = useCallback(
    (sql: string, skipRunWithNull: boolean = false): Promise<EditorRunProps> => {
      const cachedRun = queryRunCache.get(sql);
      if (cachedRun !== undefined) {
        const simulatedRunProps = setSqlAndSimulateRun(sql, cachedRun);

        return Promise.resolve(simulatedRunProps);
      }
      if (skipRunWithNull) {
        queryRunCache.set(sql, null);
        const simulatedRunProps = setSqlAndSimulateRun(sql, null);

        return Promise.resolve(simulatedRunProps);
      }
      return setSqlAndRun(sql).then((runProps) => {
        if (runProps.results) {
          queryRunCache.set(runProps.ranSql, runProps.results);
        }

        return runProps;
      });
    },
    [setSqlAndRun, setSqlAndSimulateRun, queryRunCache],
  );

  const interactiveFlowchartProps = useMemoObject<InteractiveFlowchartProps>({
    loadingVertexIDs,
    selectedVertexID,
    glottingVertexID,
    selectedEdgeID,
  });

  const setVertexIDLoading = useCallback((vertexID: string, loading: boolean): void => {
    setLoadingVertexIDs((oldLoading) => ({
      ...oldLoading,
      [vertexID]: loading ? true : false,
    }));
  }, []);

  const handleSave = useCallback(async () => {
    // Before we start, make Typescript happy.
    // This should never happen because this click handler will never get called when flowchartSaveProps === undefined.
    if (flowchartSaveProps === undefined) {
      const error = 'flowchartSaveProps === undefined is a programmer error.';
      return Promise.reject(error);
    }

    // 1. Start
    setIsSaving(true);
    setError('');

    // 2. Validate
    const errors = validate(activeFQM);

    // 3. Convert into SavedFlowchartQueryModel
    const newModel = flowchartModelToSavedModel(activeFQM);

    // 4. Calculate SQL
    let sql = '';
    let finalVertex: FlowchartVertex | null = null;
    // The user can save an invalid model as a work in progress.
    // If this happens save error messages to SQL.
    if (errors) {
      sql = errorsToSqlComments(errors);
      setSqlAndSimulateRun(sql, null);
    } else {
      try {
        // The model is valid.
        // Use the API to calculate the SQL of the final vertex.
        finalVertex = getFinalVertex(activeFQM);
        sql = await vertexToSQL(finalVertex);
      } catch (e) {
        setError('Failed to calculate SQL for flowchart.');
        setIsSaving(false);
        throw e;
      }
    }

    // 5. Reset some UI:
    setSelectedEdgeID(null);
    if (selectedVertexID !== finalVertex?.id || errors) {
      setSelectedVertexID(null);
    }

    // 6. Save in API
    try {
      const savedModel = await flowchartSaveProps?.save(newModel, sql);
      setMySFQM(savedModel);
    } catch (e) {
      setError('Failed to save flowchart.');
      throw e;
    } finally {
      setIsSaving(false);
    }

    return activeFQM;
  }, [flowchartSaveProps, activeFQM, selectedVertexID, vertexToSQL, setSqlAndSimulateRun]);

  const handleDiscardChanges = useCallback(() => {
    setActiveFQM(savedFQM);

    // If the selected vertex no longer exists, unset it.
    if (!savedFQM.vertices.find((v) => v.id === selectedVertexID)) {
      setSelectedVertexID(null);
    }

    // There may be some other stuff we need to unset like clearing the RunResults.
    // Not worrying about that right now.
  }, [savedFQM, selectedVertexID]);

  const flowchartControlBarProps: FlowchartControlBarProps = useMemo(
    () => ({
      isSaved: !hasUnsavedChanges,
      isSaving,
      showSQL,
      setShowSQL,
      onSave: handleSave,
      onDiscardChanges: handleDiscardChanges,
    }),
    [hasUnsavedChanges, isSaving, showSQL, setShowSQL, handleSave, handleDiscardChanges],
  );

  const flowchartEditorState = useMemoObject<FlowchartEditorState>({
    savedFQM,
    activeFQM,
    hasUnsavedChanges,
    flowchartModelTools,
    tablesByID,
    columnsByTableID,
    objectsForSavedModelLoaded,
    interactiveFlowchartProps,
    flowchartControlBarProps,
    error,
    showSQL,
    isSaving,
    selectedVertexID,
    selectedEdgeID,
    setSelectedVertexID: resettingSetSelectedVertexID,
    setSelectedEdgeID: resettingSetSelectedEdgeID,
    setVertexIDLoading,
    cachingSetAndRunSQL,
    vertexToSQL,
    setError,
    fetchColumns: apiState.fetchColumns,
    setShowSQL,
    onSave: handleSave,
    onDiscardChanges: handleDiscardChanges,
  });

  return flowchartEditorState;
}
