/*
API.ts is a universal request class for when you want full control of the axiosResponseObject.
This is a subclass of API.ts that provides a less verbose interface
in the 99% case where we don't need to check the response object,
and it has tighter TypeScript definitions.
API request promises resolve "entire response objects" as `promise.resolve(axiosResponseObject)`.
This has the same call signature as API.ts except
it's promises resolve the response payload as `promise.resolve(axiosResponseObject.data)`.

Future Work:
The third parameter `convert` can probably be removed from API.ts methods,
once DataAPI.ts is used in most places.
*/
import { AxiosResponse } from 'axios';

import API from 'api/API';

export type DataConverter<T> = (data: any) => T;

export default class DataAPI extends API {
  async post<T>(relativeUrl: string, data: any, convert?: DataConverter<T>) {
    const serverResponse = await this.instance.post(relativeUrl, data);
    return convertResponse<T>(serverResponse, convert);
  }

  async patch<T>(relativeUrl: string, data: any, convert?: DataConverter<T>) {
    const serverResponse = await super.patch(relativeUrl, data);
    return convertResponse<T>(serverResponse, convert);
  }

  async get<T>(relativeUrl: string, convert?: DataConverter<T>) {
    const serverResponse = await this.instance.get(relativeUrl);
    return convertResponse<T>(serverResponse, convert);
  }

  async delete<T>(relativeUrl: string, payload: any, convert?: DataConverter<T>) {
    const serverResponse = await this.instance.delete(relativeUrl, { data: payload });
    return convertResponse<T>(serverResponse, convert);
  }
}

async function convertResponse<T>(response: AxiosResponse, convert?: DataConverter<T>) {
  if (convert) {
    return convert(response.data);
  }
  return response.data;
}
