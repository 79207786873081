/**
 * Usage sum rendered in a similar layout to UsageProgressBar. Includes empty state.
 */
import { cleanColor } from 'utils/String';

import { UsageTabs } from './Usage';
import { CompanyPlanRow } from './useUsageFetch';

interface UsageSumDisplayProps {
  currentMonthPlan: CompanyPlanRow | null;
  sumValue: number;
  tab: UsageTabs;
}

const UsageSumDisplay = (props: UsageSumDisplayProps) => {
  const { currentMonthPlan, sumValue, tab } = props;

  const { title, units, emptyStateText, backgroundColor, textColor } =
    tab === 'compute'
      ? {
          title: 'Total Compute Credits this month:',
          units: 'credits',
          emptyStateText: 'Total compute credits used this month will be displayed here',
          backgroundColor: cleanColor('--pri-success-50'),
          textColor: cleanColor('--pri-success-700'),
        }
      : {
          title: 'Total MAR this month:',
          units: 'MAR',
          emptyStateText: 'Total MAR used this month will be displayed here',
          backgroundColor: cleanColor('--sec-blue-50'),
          textColor: cleanColor('--sec-blue-700'),
        };

  let content: JSX.Element;
  if (!currentMonthPlan) {
    content = (
      <div>
        <div className="f-row-y-center text-lg font-medium">
          <div>{title}</div>
        </div>
        <div className="text-pri-gray-500">{emptyStateText}</div>
      </div>
    );
  } else {
    content = (
      <div className="f-row-y-center text-lg font-medium">
        <div>{title}</div>
        <div className="ml-2" style={{ color: textColor }}>
          {Math.ceil(sumValue)} {units}
        </div>
      </div>
    );
  }

  return (
    <div className="w-full mt-4 py-4 px-6 rounded" style={{ backgroundColor }}>
      {content}
    </div>
  );
};

export default UsageSumDisplay;
