import { useCallback, useState } from 'react';

import { CompanyRole, UserProfile } from 'api/APITypes';
import { ListboxOption } from 'components/inputs/basic/Listbox/Listbox';

export interface SnowflakeRoleModalHookProps {
  snowflakeRoleOptions: ListboxOption[];
  snowflakeRoleError: string;
  snowflakeRoleLoading: boolean;
  snowflakeRoleSaving: boolean;
  snowflakeRoleSavingError: string;
  snowflakeModalUser: UserProfile | null;
  openSnowflakeModal: (user: UserProfile) => void;
  closeSnowflakeModal: () => void;
  onSaveSnowflakeRole: (newSnowflakeRole: string) => void;
}

export default function useSnowflakeRoleModal(
  save: (
    email: string,
    is_active: boolean,
    company_role: CompanyRole,
    snowflake_role: string,
  ) => Promise<void>,
  snowflakeRoleOptions: ListboxOption[],
  snowflakeRoleError: string,
  snowflakeRoleLoading: boolean,
): SnowflakeRoleModalHookProps {
  const [snowflakeRoleSaving, setSnowflakeRoleSaving] = useState(false);
  const [snowflakeRoleSavingError, setSnowflakeRoleSavingError] = useState('');
  const [snowflakeModalUser, setSnowflakeModalUser] = useState<UserProfile | null>(null);

  const onSaveSnowflakeRole = useCallback(
    (newSnowflakeRole: string) => {
      if (snowflakeModalUser === null) {
        return;
      }
      const { email, is_active } = snowflakeModalUser.user;
      const { company_role } = snowflakeModalUser;
      setSnowflakeRoleSaving(true);
      setSnowflakeRoleSavingError('');
      save(email, is_active, company_role, newSnowflakeRole)
        .then(() => {
          analytics.track('ListUsers SaveSnowflakeRole', {
            snowflake_role: newSnowflakeRole,
          });
          setSnowflakeModalUser(null);
        })
        .catch(() => {
          setSnowflakeRoleSavingError('Failed to save Snowflake role.');
        })
        .finally(() => {
          setSnowflakeRoleSaving(false);
        });
    },
    [save, snowflakeModalUser],
  );

  const openSnowflakeModal = useCallback((user: UserProfile) => {
    setSnowflakeModalUser(user);
    analytics.track('ListUsers OpenSnowflakeModal');
  }, []);

  const closeSnowflakeModal = useCallback(() => {
    setSnowflakeModalUser(null);
    analytics.track('ListUsers CloseSnowflakeModal');
  }, []);

  return {
    snowflakeRoleOptions,
    snowflakeRoleError,
    snowflakeRoleLoading,
    snowflakeRoleSaving,
    snowflakeRoleSavingError,
    snowflakeModalUser,
    openSnowflakeModal,
    closeSnowflakeModal,
    onSaveSnowflakeRole,
  };
}
