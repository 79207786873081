/* 
This is the union of react-use's useMouse() and useScroll()
Updates returned state when a mouse is moved OR the scrollRef is scrolled.
*/
import { RefObject, useEffect, useRef } from 'react';

// Stuff we return to caller
// All measurements are from the top, left of the relavant container.
export interface MouseAndScrollState {
  eventType: 'mouse' | 'scroll';
  // Mouse position on the document
  mouseDocX: number;
  mouseDocY: number;
  // Top, left corner of the inner element on the document
  innerElDocX: number;
  innerElDocY: number;
  // Mouse position relative to the inner element
  innerElX: number;
  innerElY: number;
  // Inner element dimensions
  innerElH: number;
  innerElW: number;
  // How much as the inner element been scrolled in the scroll element
  scrollX: number;
  scrollY: number;
  // Top, left corner of the scroll element on the document
  scrollElDocX: number;
  scrollElDocY: number;
  // Mouse position relative to the scroll element
  scrollElX: number;
  scrollElY: number;
  // Scroll element dimensions
  scrollElH: number;
  scrollElW: number;
}

// Stuff we can only get from the browser on MouseEvents AND
// must store in order to do calculations on ScrollEvents.
export interface MouseState {
  mouseDocX: number;
  mouseDocY: number;
}

const useMouseAndScroll = (
  innerRef: RefObject<Element>,
  scrollRef: RefObject<Element>,
  // Mouse events and scroll events happen very frequently.
  // You probably want to trigger a rerender on a small fraction of those updates.
  // So this hook uses a callback, which should set state to trigger a render only when conditions are right.
  onMouseOrScroll: (mouseState: MouseAndScrollState) => void,
) => {
  if (process.env.NODE_ENV === 'development') {
    if (typeof innerRef !== 'object' || typeof innerRef.current === 'undefined') {
      // eslint-disable-next-line no-console
      console.error('useMouseAndScroll expects a single ref argument.');
    }
  }

  const mouseRef = useRef<MouseState>({
    mouseDocX: 0,
    mouseDocY: 0,
  });

  const animationFrameID = useRef(0);

  useEffect(() => {
    // Mouse events happen very frequently.
    // Throttle them to animationFrame.
    const setState = (state: MouseAndScrollState) => {
      if (animationFrameID.current) {
        window.cancelAnimationFrame(animationFrameID.current);
      }
      animationFrameID.current = requestAnimationFrame(() => {
        onMouseOrScroll(state);
      });
    };

    const getState = (mouseDocX: number, mouseDocY: number, innerEl: Element, scrollEl: Element) => {
      // Inner Ref offsets
      const {
        left: innerElViewportX, // Distance from top left of innerEl to top left of viewport
        top: innerElViewportY, // Distance from top left of innerEl to top left of viewport
        width: innerElW,
        height: innerElH,
      } = innerEl.getBoundingClientRect();

      // Position of innerEl on Document.
      const innerElDocX = innerElViewportX + window.pageXOffset;
      const innerElDocY = innerElViewportY + window.pageYOffset;

      // Position of mouse on innerEl
      const innerElX = mouseDocX - innerElDocX;
      const innerElY = mouseDocY - innerElDocY;

      // Scroll positions
      const scrollX = scrollEl.scrollLeft;
      const scrollY = scrollEl.scrollTop;

      // Scroll Ref offsets
      const {
        left: scrollElViewportLeft, // Distance from top left of scrollEl to top left of viewport
        top: scrollElViewportTop, // Distance from top left of scrollEl to top left of viewport
        width: scrollElW,
        height: scrollElH,
      } = scrollEl.getBoundingClientRect();

      // Position of scrollEl on Document.
      const scrollElDocX = scrollElViewportLeft + window.pageXOffset;
      const scrollElDocY = scrollElViewportTop + window.pageYOffset;

      // Position of mouse on scrollEl
      const scrollElX = mouseDocX - scrollElDocX;
      const scrollElY = mouseDocY - scrollElDocY;

      return {
        eventType: 'mouse',
        mouseDocX,
        mouseDocY,
        innerElDocX,
        innerElDocY,
        innerElX,
        innerElY,
        innerElH,
        innerElW,
        scrollX,
        scrollY,
        scrollElDocX,
        scrollElDocY,
        scrollElX,
        scrollElY,
        scrollElH,
        scrollElW,
      } as MouseAndScrollState;
    };

    const moveHandler = (event: MouseEvent) => {
      if (innerRef && innerRef.current && scrollRef && scrollRef.current) {
        const mouseDocX = event.pageX; // Mouse position on document
        const mouseDocY = event.pageY; // Mouse position on document

        // Scroll events don't have mouse positions so store them for the scroll event to use later
        mouseRef.current = {
          mouseDocX,
          mouseDocY,
        };

        const state = getState(mouseDocX, mouseDocY, innerRef.current, scrollRef.current);
        setState(state);
      }
    };

    const scrollHandler = (event: Event) => {
      if (innerRef && innerRef.current && scrollRef && scrollRef.current) {
        // Read the mouse position from the last mouse event
        const { mouseDocX, mouseDocY } = mouseRef.current;

        const state = getState(mouseDocX, mouseDocY, innerRef.current, scrollRef.current);
        state.eventType = 'scroll';
        setState(state);
      }
    };

    // Add moveHandler to document
    // It has to be on something bigger than scrollRef so that we can tell when
    // the mouse has left the scrollRef.
    // Alternatively we could turn the listener on and off with onenter and onleave events.
    document.addEventListener('mousemove', moveHandler);

    // Add scrollHandler to scroll element
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', scrollHandler, {
        capture: false,
        passive: true,
      });
    }

    // Remove mouse listeners on unmount
    return () => {
      if (animationFrameID.current) {
        window.cancelAnimationFrame(animationFrameID.current);
      }

      document.removeEventListener('mousemove', moveHandler);

      if (scrollElement) {
        scrollElement.removeEventListener('scroll', scrollHandler);
      }
    };
  }, [innerRef, scrollRef, onMouseOrScroll]);
};

export default useMouseAndScroll;
