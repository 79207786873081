import React from 'react';

import { SaveableTransformAndAncestorProps, Transform } from 'api/APITypes';
import { DependencyEdge } from 'pages/tables/ShowTable/PipelineTab/PipelineEditor/PipelineEditor';
import { TransformTable } from 'pages/tables/ShowTable/ShowTable';

import RunOptions from './RunOptions';

import s from './TransformOptionsBar.module.css';

interface TransformOptionsBarProps {
  table: TransformTable;
  incrementalUpdateKeys: string[] | null; // Names of columns produced by saved SQL
  validSql: boolean; // If the currently saved sql is valid and can get update keys
  loadingUpdateKeys: boolean;
  vertexDependencies: DependencyEdge[];
  onSaveTransformAndAncestors: (
    transformId: string, // The id of the transform to edit
    transformAndAncestorSaveProps: SaveableTransformAndAncestorProps,
  ) => Promise<{ transform: Transform; ancestors: DependencyEdge[] }>;
}

export default function TransformOptionsBar(props: TransformOptionsBarProps) {
  return (
    <div className="flex justify-between items-end py-2 px-4">
      <div className={s.optionsColumn}>
        <RunOptions {...props} />
      </div>
    </div>
  );
}
