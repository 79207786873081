import { HourglassSplit } from 'react-bootstrap-icons';

import IconButton from 'components/inputs/basic/Button/IconButton';
import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import ExpandingTreeNode from 'components/layouts/containers/ExpandingTreeNode/ExpandingTreeNode';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

import { Scope } from '../useHistoricalResyncState';
import { Table } from '../useManageTablesTabState';

import ColumnsTable from './ColumnsTable';

interface ToggleableTableProps {
  schemaName: string;
  tableName: string;
  table: Table;
  tableExpanded: boolean;
  onToggleExpandTable: (schemaName: string, tableName: string) => void;
  onToggleTable: (schemaName: string, tableName: string) => void;
  onToggleEnableColumn: (schemaName: string, tableName: string, columnName: string) => void;
  onToggleHashColumn: (schemaName: string, tableName: string, columnName: string) => void;
  onOpenHistoricalResyncModal: (newScope?: Scope) => void;
  // Caller should set this to false if editMode === true or isViewer === true.
  resyncDisabled: boolean;
  resyncSpinning: boolean;
  savingDisabled: boolean;
  saving: boolean;
  startDisabled: boolean;
}

export default function ToggleableTable(props: ToggleableTableProps) {
  const {
    schemaName,
    tableName,
    table,
    tableExpanded,
    onToggleExpandTable,
    onToggleTable,
    onOpenHistoricalResyncModal,
    onToggleEnableColumn,
    onToggleHashColumn,
    resyncDisabled,
    resyncSpinning,
    savingDisabled,
    saving,
    startDisabled,
  } = props;
  const patchAllowed = table.enabled_patch_settings.allowed;
  // Hide the resync button when:
  // 1. We are currently saving the sync schema.
  // 2. In edit mode, handled by parent.
  // 3. The user is a viewer, handled by parent.
  // 4. Fivetran throws an error if you try a resync because of !patchAllowed or !table.enabled.
  const showResyncButton = !(saving || resyncDisabled || !patchAllowed || !table.enabled);
  // FOOD FOR THOUGHT:
  // Under ideal circumstances it would be good to turn the resync button into a spinner
  // to let the user know the system is working.
  // As of 04/04/2024, the code is not structured to tell if individual tables are
  // resyncing vs the entire connector. So we have to change the state of all table resync buttons
  // at once, not just the ones that are resyncing.
  // At this point, disabling all of the buttons is maybe marginally less confusing
  // than turning all of the buttons into spinners.
  // FYI: I checked Fivetran's UI and it lets you execute several table resyncs in a row.
  // I did not investigate how these resyncs are implemented.
  // To the best of my knowledge, the main connector object only has one sync state,
  // so I'm curious how or if individual table sync states are represented in Fivetran's API.
  const actualResyncDisabled = saving || resyncDisabled || !patchAllowed || resyncSpinning;

  return (
    <ExpandingTreeNode
      key={`${schemaName}__${tableName}`}
      titleHeight={26}
      enabled={patchAllowed}
      expanded={tableExpanded}
      onExpand={() => onToggleExpandTable(schemaName, tableName)}
    >
      <div className="h-[28px] f-between">
        <div className="f-row-y-center">
          <Checkbox
            name={`table-${schemaName}__${tableName}`}
            variant="blue_gray"
            label={tableName}
            checked={table.enabled}
            onChange={() => onToggleTable(schemaName, tableName)}
            disabled={savingDisabled || !patchAllowed}
          />
          {!patchAllowed && (
            <InfoIcon
              content={table.enabled_patch_settings.reason || 'Not allowed to modify this table.'}
              containerClass="ml-1"
              iconColor={'var(--pri-gray-400)'}
            />
          )}
          {startDisabled && (
            <InfoIcon
              content={`Mozart Data has disabled this table by default. 
              This table can slow down syncs and can also lead to high MAR usage.
              Reenable this table if you need its data.`}
              containerClass="ml-1"
              icon={HourglassSplit}
              iconColor={'var(--pri-warning-600)'}
            />
          )}
          {showResyncButton && (
            <IconButton
              icon="ArrowClockwise"
              text="Resync"
              size="small"
              variant="lightDullTransparent"
              disabled={actualResyncDisabled}
              spinning={false}
              onClick={() => onOpenHistoricalResyncModal({ [schemaName]: [tableName] })}
            />
          )}
        </div>
      </div>
      <div className="">
        {!table.columns && tableExpanded && <CenteredSpinner containerMinHeight="110px" />}
        {tableExpanded && table.columns && Object.keys(table.columns).length > 0 && (
          <ColumnsTable
            schemaName={schemaName}
            tableName={tableName}
            columns={table.columns}
            savingDisabled={savingDisabled}
            onToggleEnableColumn={onToggleEnableColumn}
            onToggleHashColumn={onToggleHashColumn}
          />
        )}
      </div>
    </ExpandingTreeNode>
  );
}
