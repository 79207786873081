import cn from 'classnames';

import s from './SummaryTab.module.css';

interface SectionProps {
  title: string;
  rows: { [key: string]: React.ReactNode };
}

export default function Section(props: SectionProps) {
  const { title, rows } = props;
  return (
    <div className="p-2">
      <div className={s.statHeading}>{title}</div>
      <table className={cn('ml-2 mt-2', s.statTable)}>
        <tbody>
          {Object.keys(rows).map((r) => (
            <tr key={r}>
              <td className="w-auto !pr-6 font-medium whitespace-nowrap">{r}</td>
              <td className="w-full">{rows[r]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
