import React, { useRef, useCallback } from 'react';

import { CheckLg } from 'react-bootstrap-icons';

import { AggTable } from 'api/APITypes';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';

import { highlightSearch } from '../highlightUtils';
import { PassedToTableRowProps } from '../TableNameList';

export const ROW_HEIGHT = 36;

interface TableRowProps extends PassedToTableRowProps {
  table: AggTable;
  selected: boolean;
  disabled: boolean;
  picked?: boolean;
  inFolder?: boolean;
  hideSchema?: boolean;
}

const TableRow = React.memo((props: TableRowProps) => {
  const {
    table,
    highlightFilter,
    selected,
    disabled,
    picked,
    inFolder,
    hideSchema,
    onSetTable,
    onDoubleClickTable,
  } = props;
  const rowRef = useRef(null);

  const onDoubleClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      onDoubleClickTable(table);
    },
    [table, onDoubleClickTable],
  );

  let className = 'tt-table-list-row';
  if (disabled) {
    className += ' tt-table-list-disabled-row';
  }
  if (selected) {
    className += ' tt-table-list-selected-row';
  }
  if (inFolder && !picked) {
    className += ' tt-table-list-indented-row';
  }

  /* Perf: <li>s are way slower to render than <div>s for some reason */
  const row = (
    <div
      ref={rowRef}
      onClick={() => !disabled && onSetTable(table)}
      onDoubleClick={!disabled ? onDoubleClick : () => {}}
      className={className}
    >
      <div className="f-row-y-center">
        {picked && <CheckLg size={16} className="mr-3" />}
        <span>{highlightSearch(hideSchema ? table.name : table.full_name, highlightFilter)}</span>
      </div>
    </div>
  );

  // For now, we only have one use case for disabled tables (i.e. disable table rows for create data
  // alerts if they're not schedulable).
  // TODO(table-picker): Abstract or branch as needed to customize the tooltip based on the use case
  if (disabled) {
    return (
      <TooltipTrigger tip="Cannot create alerts for non-schedulable tables" placement="top" delay={1000}>
        {row}
      </TooltipTrigger>
    );
  }

  return row;
});

export default TableRow;
