import dayjs from 'dayjs';
// dayjs humanize() is crazy slow. Not sure why. Using another library instead.
import TimeAgo, { LocaleData } from 'javascript-time-ago';

import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en as LocaleData);
const timeAgo = new TimeAgo('en-US');
const durationFormat = Intl.NumberFormat('en-US', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});
const integerDurationFormat = Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
// Times in milliseconds
const ONE_SECOND_IN_MILLISECONDS = 1000;
const ONE_MINUTE_IN_MILLISECONDS = 60000;
const ONE_HOUR_IN_MILLISECONDS = 3600000;

const parseIso = (timestamp: string | null) => {
  if (!timestamp) {
    return null;
  }
  return dayjs(timestamp).toDate();
};

const parseApiDate = (dateString: string | null) => {
  if (!dateString) {
    return null;
  }
  return dayjs(dateString, 'YYYY-MM-DD').toDate();
};

const formatApiDate = (parsedTimestamp: Date | null, format?: string) => {
  if (!parsedTimestamp) {
    return '';
  }
  const formatString = format || 'YYYY-MM-DD';
  return dayjs(parsedTimestamp).format(formatString);
};

const formatDate = (parsedTimestamp: Date | null) => {
  if (!parsedTimestamp) {
    return '';
  }
  return dayjs(parsedTimestamp).format('MMM D, YYYY [at] hh:mm A');
};

const formatDBDate = (date: string | null) => {
  const parsed = parseIso(date);
  if (!parsed) {
    return '';
  }
  return dayjs(parsed).format('MMM D, YYYY');
};

const formatDBTime = (time: string | null) => {
  if (!time) {
    return '';
  }
  // daysjs does not parse incomplete dates, so add a day to the time.
  // Any date will do so use January 1st.
  time = `2021-01-01T${time}`;
  return dayjs(time).format('hh:mm A');
};

const formatIso = (timestamp: string | null) => {
  return formatDate(parseIso(timestamp));
};

const agoDate = (parsedTimestamp: Date | null) => {
  if (!parsedTimestamp) {
    return '';
  }
  return timeAgo.format(parsedTimestamp.valueOf(), 'round');
};

const agoIso = (timestamp: string | null) => {
  return agoDate(parseIso(timestamp));
};

const millisecondsAgo = (parsedTimestamp: Date | null, errorOrUnsetValue: number) => {
  if (!parsedTimestamp) {
    return errorOrUnsetValue;
  }
  return Date.now() - parsedTimestamp.getTime();
};

const parseMillisecondsAgo = (timestamp: string | null, errorOrUnsetValue: number) => {
  return millisecondsAgo(parseIso(timestamp), errorOrUnsetValue);
};

const formatAgoTime = (timestamp: string | null | undefined) => {
  return agoIso(timestamp || null) || 'Never';
};

// NOTE: This shows different units of time, if we need to compare a duration of 58 seconds and 66 seconds,
//           we should make a new formatDuration for that which uses the same unit.
const formatDuration = (milliseconds: number | null) => {
  if (!milliseconds) {
    return '';
  }
  if (milliseconds <= ONE_MINUTE_IN_MILLISECONDS) {
    return durationFormat.format(milliseconds / ONE_SECOND_IN_MILLISECONDS) + ' seconds';
  }
  if (milliseconds <= ONE_HOUR_IN_MILLISECONDS) {
    return durationFormat.format(milliseconds / ONE_MINUTE_IN_MILLISECONDS) + ' minutes';
  }
  return durationFormat.format(milliseconds / ONE_HOUR_IN_MILLISECONDS) + ' hours';
};

// Same as formatDuration except it returns integers
const integerFormatDuration = (milliseconds: number | null) => {
  if (!milliseconds) {
    return '';
  }
  if (milliseconds <= ONE_MINUTE_IN_MILLISECONDS) {
    return integerDurationFormat.format(milliseconds / ONE_SECOND_IN_MILLISECONDS) + ' seconds';
  }
  if (milliseconds <= ONE_HOUR_IN_MILLISECONDS) {
    return integerDurationFormat.format(milliseconds / ONE_MINUTE_IN_MILLISECONDS) + ' minutes';
  }
  return integerDurationFormat.format(milliseconds / ONE_HOUR_IN_MILLISECONDS) + ' hours';
};

const millisecondsToSeconds = (milliseconds: number | null) => {
  if (!milliseconds) {
    return null;
  }
  const seconds = milliseconds / 1000;
  return Math.round(seconds * 1e1) / 1e1; // Rounds to one decimal place
};

const durationFromIsoTimes = (startTime: string | null, endTime: string | null) => {
  if (!endTime || !startTime) {
    return null;
  }
  const parsedEndTime = parseIso(endTime);
  const parsedStartTime = parseIso(startTime);
  let duration = null;
  if (parsedEndTime && parsedStartTime) {
    duration = parsedEndTime.getTime() - parsedStartTime.getTime();
  }
  return duration;
};

const formatDurationFromIsoTimes = (startTime: string | null, endTime: string | null) => {
  return formatDuration(durationFromIsoTimes(startTime, endTime));
};

export {
  parseIso,
  parseApiDate,
  formatApiDate,
  formatDate,
  formatIso,
  formatDBDate,
  formatDBTime,
  agoDate,
  agoIso,
  millisecondsAgo,
  parseMillisecondsAgo,
  formatAgoTime,
  formatDuration,
  integerFormatDuration,
  millisecondsToSeconds,
  durationFromIsoTimes,
  formatDurationFromIsoTimes,
};
