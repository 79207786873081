/**
 * Spirtually similar to SQL EditButtons on transforms, except for flowcharts.
 */
import React, { useState, useCallback } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import Button from 'components/inputs/basic/Button/Button';
import ButtonGroup from 'components/inputs/basic/Button/ButtonGroup';
import ButtonMenu from 'components/inputs/basic/Button/ButtonMenu';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import { useUserProfile } from 'context/AuthContext';
import VersionDescriptionModal from 'pages/tables/ShowTable/TransformTab/TransformOptionsBar/VersionDescriptionModal';

import { FlowchartQueryModel } from './FlowchartEditor/model/FlowchartQueryModel';

export interface FlowchartEditButtonsProps {
  isTransform: boolean;
  editMode: boolean;
  eventPrefix: string;
  isSaved: boolean;
  isSaving: boolean;
  setEditMode: (editMode: boolean) => void;
  onSave: (versionDescription?: string) => Promise<FlowchartQueryModel>;
  onDiscardChanges: () => void;
}

export default function FlowchartEditButtons(props: FlowchartEditButtonsProps) {
  const {
    isTransform,
    editMode,
    eventPrefix,
    isSaved,
    isSaving,
    setEditMode,
    onSave,
    onDiscardChanges,
  } = props;

  const [confirmDiscard, setConfirmDiscard] = useState(false); // true = Show the discard changes modal
  const [showVersionDescriptionModal, setShowVersionDescriptionModal] = useState(false);

  const { userProfile } = useUserProfile();

  const handleDiscardAttempted = useCallback(() => {
    if (!isSaved) {
      analytics.track(`${eventPrefix} EditFlowchartDiscardAttempted`);
      setConfirmDiscard(true);
    } else {
      analytics.track(`${eventPrefix} EditFlowchartDiscarded`);
      setEditMode(true);
    }
  }, [isSaved, eventPrefix, setEditMode]);

  const handleSaveFlowchart = useCallback(
    (eventVerb: 'Saved' | 'SavedFlowchartWithVersionDescription', versionDescription?: string) => {
      if (isSaving || isSaved) {
        return;
      }

      const savePromise = onSave(versionDescription);
      if (savePromise) {
        savePromise
          .then(() => {
            analytics.track(`${eventPrefix} ${eventVerb}`);
          })
          .catch(() => {
            // Silently swallow error here to prevent exception from bubbling up to global exception handler.
            // Error message is set in onSave().
          })
          .finally(() => {
            setEditMode(true);
            setShowVersionDescriptionModal(false);
          });
      }
    },
    [isSaving, isSaved, eventPrefix, setEditMode, onSave],
  );

  const handleEditFlowchart = useCallback(() => {
    analytics.track(`${eventPrefix} EditFlowchart`);
    setEditMode(true);
  }, [eventPrefix, setEditMode]);

  const handleDiscardCancelled = () => {
    analytics.track(`${eventPrefix} EditFlowchartDiscardCancelled`);
    setConfirmDiscard(false);
  };

  const handleDiscardConfirmed = () => {
    analytics.track(`${eventPrefix} EditFlowchartDiscarded`);
    setEditMode(true);
    setConfirmDiscard(false);
    onDiscardChanges();
  };

  const handleOpenVersionDescriptionModal = () => {
    analytics.track(`${eventPrefix} OpenFlowchartVersionDescriptionModal`);
    setShowVersionDescriptionModal(true);
  };

  const handleCloseVersionDescriptionModal = () => {
    analytics.track(`${eventPrefix} CloseFlowchartVersionDescriptionModal`);
    setShowVersionDescriptionModal(false);
  };

  const handleSaveWithVersionDescription = (versionDescription: string) => {
    handleSaveFlowchart('SavedFlowchartWithVersionDescription', versionDescription);
  };

  const handleSaveWithoutVersionDescription = useCallback(() => {
    handleSaveFlowchart('Saved');
  }, [handleSaveFlowchart]);

  useHotkeys(
    'ctrl+e, ⌘+e',
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (!editMode) {
        handleEditFlowchart();
      }
    },
    { enableOnTags: ['TEXTAREA', 'INPUT'], enableOnContentEditable: true },
    [handleEditFlowchart],
  );

  useHotkeys(
    'ctrl+s, ⌘+s',
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      handleSaveWithoutVersionDescription();
    },
    { enableOnTags: ['TEXTAREA', 'INPUT'], enableOnContentEditable: true },
    [onSave],
  );

  useHotkeys(
    'ctrl+Escape, ⌘+Escape',
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      handleDiscardAttempted();
    },
    { enableOnTags: ['TEXTAREA', 'INPUT'], enableOnContentEditable: true },
    [handleDiscardAttempted],
  );

  const showDropdownButton = !isSaved && isTransform;

  const saveButton = (
    <Button
      size="small"
      variant="save"
      className={isSaved ? '' : 'ml-2'}
      spinning={isSaving}
      disabled={isSaved}
      onClick={handleSaveWithoutVersionDescription}
    >
      {isSaved ? 'Saved' : 'Save'}
    </Button>
  );

  return (
    <div className="f-row-y-center">
      {confirmDiscard && (
        <ConfirmModal
          header="Discard changes?"
          confirmText="Discard"
          confirmVariant="darkDanger"
          onCancel={handleDiscardCancelled}
          onConfirm={handleDiscardConfirmed}
        />
      )}
      {showVersionDescriptionModal && (
        <VersionDescriptionModal
          saving={isSaving}
          onClose={handleCloseVersionDescriptionModal}
          onSaveWithVersionDescription={handleSaveWithVersionDescription}
        />
      )}
      {isTransform && !editMode && userProfile.company_role !== 'viewer' && (
        <Button size="small" variant="darkDullAction" onClick={handleEditFlowchart}>
          Edit Flowchart
        </Button>
      )}
      {editMode && (
        <>
          {!isSaved && (
            <Button
              size="small"
              variant="darkDanger"
              disabled={isSaving}
              onClick={handleDiscardAttempted}
            >
              Discard Changes
            </Button>
          )}
          {showDropdownButton && (
            <ButtonGroup variant="darkDullAction">
              {saveButton}
              <ButtonMenu
                size="small"
                variant="save"
                dropdownOptions={[
                  { label: 'Save with Description', onClick: handleOpenVersionDescriptionModal },
                ]}
              />
            </ButtonGroup>
          )}
          {!showDropdownButton && saveButton}
        </>
      )}
    </div>
  );
}
