import { Connector } from 'api/APITypes';

export type StepPath =
  | 'show'
  | 'preview'
  | 'later'
  | 'review_transforms'
  | 'authorize_gsheet'
  | 'setup_complete';
export type ConnectorOrID = Connector | string;

export function connectorPath(connector: Connector) {
  return `/connectors/${connector.id}`;
}

export function stepPath(connector: ConnectorOrID, step: StepPath) {
  const connectorID = typeof connector === 'string' ? connector : connector.id;
  return `/connectors/${connectorID}/report_builder/${step}`;
}

export function showReportPath(connector: ConnectorOrID) {
  return stepPath(connector, 'show');
}

export function previewReportPath(connector: ConnectorOrID) {
  return stepPath(connector, 'preview');
}

export function laterPath(connector: ConnectorOrID) {
  return stepPath(connector, 'later');
}

export function reviewTransformsPath(connector: ConnectorOrID) {
  return stepPath(connector, 'review_transforms');
}

export function authorizeGSheetPath(connector: ConnectorOrID) {
  return stepPath(connector, 'authorize_gsheet');
}

export function setupCompletePath(connector: ConnectorOrID) {
  return stepPath(connector, 'setup_complete');
}

export function spreadsheetPath(spreadsheetID: string) {
  return `https://docs.google.com/spreadsheets/d/${spreadsheetID}/edit#gid=0`;
}
