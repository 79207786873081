import { useEffect, useState } from 'react';

import API from 'api/API';
import { ConnectorLog } from 'api/connectorsAPI';
import { Connector } from 'model_layer/TableModelsContext';

export type LogLevel = 'all' | 'errors' | 'errors_and_warnings';

const LOGS_PER_PAGE = 10;

export interface LogsTabState {
  logLevel: LogLevel;
  setLogLevelAndResetPage: (newLogLevel: LogLevel) => void;
  loading: boolean;
  error: string;
  showSyncDelayWarning: boolean;
  connector: Connector;
  logs: ConnectorLog[];
  hasPrevious: boolean;
  hasNext: boolean;
  onCloseSyncDelayWarning(): void;
  onClickFirst(): void;
  onClickPrevious(): void;
  onClickNext(): void;
  // not really feasible to give you the last page via onClickLast since we are doing timestamp-based pagination
}

export default function useLogsTabState(connector: Connector): LogsTabState {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showSyncDelayWarning, setShowSyncDelayWarning] = useState(true);
  const [logs, setLogs] = useState<ConnectorLog[]>([]);
  const [paginationTimestamps, setPaginationTimestamps] = useState<string[]>([]);
  const [logLevel, setLogLevel] = useState<LogLevel>('all');

  const onCloseSyncDelayWarning = () => {
    setShowSyncDelayWarning(false);
    analytics.track('ShowConnectorLogsTab CloseSyncDelayWarning');
  };

  const handleFetchLogs = async (connectorID: string, level: string, beforeTimestamp: string | null) => {
    const api = new API();
    let url = `/api/connectors/${connectorID}/logs?log_level=${level}`;
    if (beforeTimestamp) {
      url += `&before_timestamp=${beforeTimestamp}`;
    }
    return api.get(url);
  };

  useEffect(() => {
    const beforeTimestamp =
      paginationTimestamps.length > 0 ? paginationTimestamps[paginationTimestamps.length - 1] : null;
    setLoading(true);
    handleFetchLogs(connector.id, logLevel, beforeTimestamp)
      .then((response) => {
        setLogs(response.data);
      })
      .catch((_e) => {
        setError('There was a problem fetching Connector logs.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [connector, paginationTimestamps, logLevel]);

  const handleClickNext = () => {
    const lastTimestamp = logs[logs.length - 1].start_timestamp;
    setPaginationTimestamps([...paginationTimestamps, lastTimestamp]);
    analytics.track('ShowConnectorLogsTab ClickNextPage');
  };

  const handleClickPrevious = () => {
    setPaginationTimestamps((oldTimestamps) => {
      return oldTimestamps.slice(0, oldTimestamps.length - 1);
    });
    analytics.track('ShowConnectorLogsTab ClickPreviousPage');
  };

  const handleClickFirst = () => {
    setPaginationTimestamps([]);
    analytics.track('ShowConnectorLogsTab ClickFirstPage');
  };

  const setLogLevelAndResetPage = (newLogLevel: LogLevel) => {
    // Go back to page 1 when the log level changes
    setLogLevel(newLogLevel);
    setPaginationTimestamps([]);
    analytics.track('ShowConnectorLogsTab SetLogLevel', { logLevel: newLogLevel });
  };

  return {
    logLevel,
    setLogLevelAndResetPage,
    loading,
    error,
    showSyncDelayWarning,
    connector,
    logs,
    hasPrevious: paginationTimestamps.length > 0,
    hasNext: logs.length === LOGS_PER_PAGE, // If the page had exactly LOGS_PER_PAGE logs, the user will just get an empty page next. Oh well
    onCloseSyncDelayWarning,
    onClickFirst: handleClickFirst,
    onClickPrevious: handleClickPrevious,
    onClickNext: handleClickNext,
  };
}
