import React from 'react';

import cn from 'classnames';
import { Formik } from 'formik';

import Button from 'components/inputs/basic/Button/Button';
import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import Modal from 'components/layouts/containers/modals/Modal/Modal';

import styles from './CustomizeVertexModal.module.css';

export type FormValues = {
  [key: string]: boolean;
};

type Option = {
  /** Similar to what `value` would be */
  customizeKey: string;
  label: string;
};

type Section = {
  name: string;
  label: string;
  options: Option[];
};

interface Props {
  customizeVertexKeys: string[];
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
}

const CustomizeVertexModal = (props: Props) => {
  const { customizeVertexKeys, onClose, onSubmit } = props;

  const SECTIONS: Section[] = [
    {
      name: 'summary_details',
      label: 'Details',
      options: [
        { customizeKey: 'database_table_type', label: 'Database Type (e.g. Table, View)' },
        { customizeKey: 'description', label: 'Description' },
        { customizeKey: 'schedule', label: 'Schedule' },
      ],
    },
    {
      name: 'last_completed_run',
      label: 'Last Completed Run',
      options: [
        { customizeKey: 'last_run_time', label: 'Last Run Time' },
        { customizeKey: 'last_run_ago', label: 'Last Run Ago' },
        { customizeKey: 'duration', label: 'Duration' },
        { customizeKey: 'row_count', label: 'Row Count' },
      ],
    },
    {
      name: 'last_completed_run_version',
      label: 'Last Completed Run Version',
      options: [
        { customizeKey: 'last_run_saved_by', label: 'Saved By' },
        { customizeKey: 'last_run_saved_at', label: 'Saved At' },
        { customizeKey: 'last_run_saved_ago', label: 'Saved Ago' },
      ],
    },
  ];

  const OPTIONS: Option[] = SECTIONS.reduce(
    (acc: Option[], section) => [...acc, ...section.options],
    [],
  );

  const initialValues: FormValues = OPTIONS.map((option) => option.customizeKey).reduce(
    (acc, customizeKey) => ({
      ...acc,
      [customizeKey]: customizeVertexKeys.indexOf(customizeKey) > -1,
    }),
    {},
  );

  const areNoneSelected = (values: FormValues) => Object.values(values).every((value) => !value);

  const handleSelectAllOrClearAll = (values: FormValues, setFieldValue: Function) => {
    if (areNoneSelected(values)) {
      OPTIONS.forEach((option) => setFieldValue(option.customizeKey, true));
    } else {
      OPTIONS.forEach((option) => setFieldValue(option.customizeKey, false));
    }
  };

  const renderModalFooter = (values: FormValues, setFieldValue: Function) => (
    <>
      <Button
        variant="lightDullTransparent"
        onClick={() => handleSelectAllOrClearAll(values, setFieldValue)}
        className="mr-2"
      >
        {areNoneSelected(values) ? 'Select All' : 'Clear All'}
      </Button>
      <Button type="submit" variant="lightAction">
        Save
      </Button>
    </>
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({ values, setFieldValue, handleChange, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            header="Select Data to Display in Graph"
            footer={renderModalFooter(values, setFieldValue)}
            onClose={onClose}
            containerClass={styles.container}
            cancelButton
          >
            <div className={cn('p-4 mb-2', styles.sections)}>
              {SECTIONS.map(({ name, label, options }) => (
                <div key={name} className="mb-4">
                  <h2 className="mb-1 font-medium">{label}</h2>
                  {options.map((option) => {
                    const { customizeKey, label } = option;
                    return (
                      <Checkbox
                        key={customizeKey}
                        name={customizeKey}
                        label={label}
                        checked={values[customizeKey]}
                        onChange={handleChange}
                        labelClass="text-sm"
                      />
                    );
                  })}
                </div>
              ))}
            </div>
          </Modal>
        </form>
      )}
    </Formik>
  );
};

export default CustomizeVertexModal;
