import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import cn from 'classnames';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import SortableTable from 'components/tables/SortableTable/SortableTable';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';
import SqlPreviewEditor from 'components/widgets/SqlPreviewEditor/SqlPreviewEditor';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { formatApiDate } from 'utils/dateTime';
import { formatNumber } from 'utils/String';

import { UsageTabs } from './Usage';

const TRANSFORM_QUERY_TAG_START = 'mozart_transform_table:';
const METADATA_SYNC_TAG_START = 'mozart_table_metadata_sync:';

const LEFT_ALIGN_COLUMNS = ['APP', 'QUERY SOURCE', 'CONNECTOR NAME', 'SQL', 'TABLE', 'MONTH', 'DATE'];

const SORT_TAB_MAP = {
  summary: 'Summary',
  connectors: 'MAR',
  compute: 'COMPUTE CREDITS',
  storage: 'DATE',
  cloud_service: 'CLOUD SERVICE CREDITS',
  estimator: 'ESTIMATED COST',
};

interface UsageTableProps {
  loading?: boolean;
  data: any[];
  tab: UsageTabs;
  onRowClick?: (row: any) => void;
}

export function UsageTable(props: UsageTableProps) {
  const { loading, data, tab, onRowClick } = props;

  const { tablesByID, tablesByFullName } = useContext(TableModelsContext);

  return (
    <div className="mb-4">
      {loading ? (
        <CenteredSpinner containerMinHeight="200px" />
      ) : data.length > 0 ? (
        <SortableTable
          className="mt-4"
          headers={Object.keys(data[0])
            .filter((c) => c !== 'index')
            .map((column) => {
              return {
                key: column,
                label: column,
                divClassName: 'w-min',
                THClassName: '!whitespace-normal',
                THAlign: LEFT_ALIGN_COLUMNS.includes(column) ? undefined : 'right',
                getValueToSortOn: (item) => item[column],
              };
            })}
          type="blueGrayHeaderTable"
          items={data}
          defaultSortConfig={{ key: SORT_TAB_MAP[tab], direction: 'descending' }}
          renderRow={(row) => (
            <tr
              key={row.rowIndex}
              onClick={onRowClick ? () => onRowClick(row) : undefined}
              className={cn('items-center bg-pri-gray-50', {
                'hover:bg-pri-gray-100 hover:cursor-pointer': !!onRowClick,
              })}
            >
              {Object.entries(row).map(([key, value]) => {
                // If the key is index, don't return anything
                if (key === 'index') {
                  return undefined;
                }
                if (
                  key === 'QUERY SOURCE' &&
                  typeof value === 'string' &&
                  value.startsWith(TRANSFORM_QUERY_TAG_START)
                ) {
                  const tableId = value.split(TRANSFORM_QUERY_TAG_START)[1];
                  const table = tablesByID[tableId];
                  if (table) {
                    return (
                      <td>
                        <Link
                          to={`/tables/${tableId}`}
                          target="_blank"
                          data-track="Usage QueryTagClick"
                          className="min-h-9 f-row-y-center font-medium hover:text-sec-blue-gray-500 hover:cursor-pointer"
                        >
                          Transform: {table.schema}.{table.name}
                        </Link>
                      </td>
                    );
                  } else {
                    return <td>Unknown Transform</td>;
                  }
                }
                if (
                  key === 'QUERY SOURCE' &&
                  typeof value === 'string' &&
                  value.startsWith(METADATA_SYNC_TAG_START)
                ) {
                  const tableId = value.split(METADATA_SYNC_TAG_START)[1];
                  if (tableId === 'all') {
                    // If we dont get a specific table, just return the generic name
                    return <td>Mozart Table Metadata Sync</td>;
                  }
                  const table = tablesByID[tableId];
                  if (table) {
                    return (
                      <td>
                        <Link
                          to={`/tables/${tableId}`}
                          target="_blank"
                          data-track="Usage QueryTagClick"
                          className="min-h-9 f-row-y-center font-medium hover:text-sec-blue-gray-500 hover:cursor-pointer"
                        >
                          Mozart Table Metadata Sync: {table.schema}.{table.name}
                        </Link>
                      </td>
                    );
                  } else {
                    // This could happen if the table was deleted
                    return <td>Mozart Table Metadata Sync: Unknown Table</td>;
                  }
                }
                if (key === 'TABLE') {
                  const table = tablesByFullName[value as string];
                  if (table) {
                    return (
                      <td>
                        <Link
                          to={`/tables/${table.id}`}
                          target="_blank"
                          data-track="Usage QueryTagClick"
                          className="min-h-9 f-row-y-center font-medium hover:text-sec-blue-gray-500 hover:cursor-pointer"
                        >
                          {table.schema}.{table.name}
                        </Link>
                      </td>
                    );
                  } else {
                    return <td>Unknown Table</td>;
                  }
                }
                if (key === 'SQL') {
                  return (
                    <td style={{ height: '100%', maxWidth: '500px' }}>
                      <SqlPreviewEditor sql={value as string} />
                    </td>
                  );
                }
                // If the value is a number, format it and right align it
                if (Number.isFinite(value)) {
                  return <td className="text-right">{formatNumber(value as number)}</td>;
                }
                if (value instanceof Date) {
                  return <td>{formatApiDate(value)}</td>;
                } else {
                  return <td>{value as string}</td>;
                }
              })}
            </tr>
          )}
        />
      ) : (
        <NoObjectsAlert heading="No Results." className="mt-4" />
      )}
    </div>
  );
}
