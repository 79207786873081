import { useCallback, useEffect, useState } from 'react';

import cachePromise from 'api/cachePromise';
import usageAPI, {
  UsageInterval,
  ConnectorUsage,
  TableUsage,
  ComputeUsage,
  StorageUsage,
} from 'api/usageAPI';

import { CONNECTOR_TAB, COMPUTE_TAB, STORAGE_TAB } from './Usage';

export default function useUsageFetch(
  currentTab: string,
  interval: UsageInterval,
  startDate: string,
  endDate: string,
  connectorID: string,
) {
  /******************** State that caches previous API responses ********************/
  // If a user flips back and forth between different usage filter parmeters,
  // we don't want to requery the data when the user flips back.
  // When we query the API we store the response by the unique key of the API query parameters used.
  // When filter parameter state changes, we search the cache for the new filter parameter's key
  // to see if we need to fetch new data from the API.
  // The API key of the payload most recently loaded from the API or the `apiCache`
  const [lastLoadedAllConnectorApiKey, setLastLoadedAllConnectorApiKey] = useState('NOT_SET_YET');
  const [lastLoadedSpecificConnectorApiKey, setLastLoadedSpecificConnectorApiKey] =
    useState('NOT_SET_YET');

  /******************** State that stores API responses that are rendered ********************/
  const [connectorUsage, setConnectorUsage] = useState<ConnectorUsage[]>([]); // Daily or monthly usage of each connector on a per date basis
  const [connectorConnectorTablesUsage, setConnectorConnectorTablesUsage] = useState<TableUsage[]>([]); // Usage of each table made by a connector on a per date basis
  const [computeUsage, setComputeUsage] = useState<ComputeUsage[]>([]); // Daily or monthly compute usage of each database on a per date basis
  const [storageUsage, setStorageUsage] = useState<StorageUsage[]>([]); // Daily or monthly storage usage of each database on a per date basis

  /******************** All other State ********************/
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Wraps every API fetch in standard setLoading and setError code
  // TODO: Maybe add cache category to the key so we can clear the cache for a specific category
  const wrapFetch = useCallback(
    (
      currentApiKey: string,
      fetchAndSetMethod: () => Promise<any>,
      setLastApiKey: (oldKey: string) => void,
    ) => {
      setLoading(true);
      setError('');
      setLastApiKey(currentApiKey);
      fetchAndSetMethod()
        .catch((e) => {
          setError('There was an error loading your usage.');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [],
  );

  // Since the connector specific page needs to refresh both the connector usage and the connector tables usage,
  //   we have two api keys so we can cache both responses.
  // In practice, this means usageAPI.getConnectorTablesUsage is cached independently with specificConnectorCurrentApiKey
  //   and the others are cached with allConnectorsCurrentApiKey.
  const allConnectorsCurrentApiKey = `allConnectors_${currentTab}_${interval}_${startDate}_${endDate}`;
  const specificConnectorCurrentApiKey = `specificConnector_${currentTab}_${interval}_${startDate}_${endDate}_${connectorID}`;

  /*******************************************************************************
   * Fetch Connector Usage Data
   ******************************************************************************/
  useEffect(() => {
    // If we have already loaded the data, do nothing.
    if (
      loading ||
      currentTab !== CONNECTOR_TAB ||
      allConnectorsCurrentApiKey === lastLoadedAllConnectorApiKey
    ) {
      return;
    }

    // If not, call the API.
    wrapFetch(
      allConnectorsCurrentApiKey,
      () =>
        cachePromise<ConnectorUsage[]>(allConnectorsCurrentApiKey, () =>
          usageAPI.getConnectorUsage(interval, startDate, endDate),
        ).then((data) => {
          setConnectorUsage(data);
        }),
      setLastLoadedAllConnectorApiKey,
    );
  }, [
    loading,
    currentTab,
    allConnectorsCurrentApiKey,
    lastLoadedAllConnectorApiKey,
    interval,
    startDate,
    endDate,
    wrapFetch,
  ]);

  /*******************************************************************************
   * Fetch Tables made by Connector Usage Data
   ******************************************************************************/
  useEffect(() => {
    // If we have already loaded the data, do nothing.
    if (
      loading ||
      currentTab !== CONNECTOR_TAB ||
      connectorID === '' ||
      specificConnectorCurrentApiKey === lastLoadedSpecificConnectorApiKey
    ) {
      return;
    }

    // If not, call the API.
    wrapFetch(
      specificConnectorCurrentApiKey,
      () =>
        cachePromise<TableUsage[]>(specificConnectorCurrentApiKey, () =>
          usageAPI.getConnectorTablesUsage(connectorID, startDate, endDate),
        ).then((data) => {
          setConnectorConnectorTablesUsage(data);
        }),
      setLastLoadedSpecificConnectorApiKey,
    );
  }, [
    loading,
    currentTab,
    specificConnectorCurrentApiKey,
    lastLoadedSpecificConnectorApiKey,
    interval,
    startDate,
    endDate,
    connectorID,
    wrapFetch,
  ]);

  /*******************************************************************************
   * Fetch Compute Usage Data
   ******************************************************************************/
  useEffect(() => {
    // If we have already loaded the data, do nothing.
    if (
      loading ||
      currentTab !== COMPUTE_TAB ||
      allConnectorsCurrentApiKey === lastLoadedAllConnectorApiKey
    ) {
      return;
    }

    // If not, call the API.
    wrapFetch(
      allConnectorsCurrentApiKey,
      () =>
        cachePromise<ComputeUsage[]>(allConnectorsCurrentApiKey, () =>
          usageAPI.getComputeUsage(interval, startDate, endDate),
        ).then((data) => {
          setComputeUsage(data);
        }),
      setLastLoadedAllConnectorApiKey,
    );
  }, [
    loading,
    currentTab,
    allConnectorsCurrentApiKey,
    lastLoadedAllConnectorApiKey,
    interval,
    startDate,
    endDate,
    connectorID,
    wrapFetch,
  ]);

  /*******************************************************************************
   * Fetch Storage Usage Data
   ******************************************************************************/
  useEffect(() => {
    // If we have already loaded the data, do nothing.
    if (
      loading ||
      currentTab !== STORAGE_TAB ||
      allConnectorsCurrentApiKey === lastLoadedAllConnectorApiKey
    ) {
      return;
    }

    // If not, call the API.
    wrapFetch(
      allConnectorsCurrentApiKey,
      () =>
        cachePromise<StorageUsage[]>(allConnectorsCurrentApiKey, () =>
          usageAPI.getStorageUsage(interval, startDate, endDate),
        ).then((data) => {
          setStorageUsage(data);
        }),
      setLastLoadedAllConnectorApiKey,
    );
  }, [
    loading,
    currentTab,
    allConnectorsCurrentApiKey,
    lastLoadedAllConnectorApiKey,
    interval,
    startDate,
    endDate,
    connectorID,
    wrapFetch,
  ]);

  return { loading, error, connectorUsage, connectorConnectorTablesUsage, computeUsage, storageUsage };
}
