/*
  This is a generic "'Folder-Like' Item Explorer" that renders a list of "folder-like" items
  that can be open or closed. The "folder-like" items could be database schemas or Mozart Data tags.
*/
import React, { useCallback } from 'react';

import { AggTable, TableID } from 'api/tableAPI';
import { SchemaWithIconType } from 'components/primitives/icons/SchemaIcon/SchemaIcon';
import { getSchemaType } from 'components/primitives/icons/SchemaIconForDatabaseSearch/SchemaIconForDatabaseSearch';
import { useUserProfile } from 'context/AuthContext';
import { getSortedSchemaKeys } from 'pages/Warehouse/DatabaseSearch2/DatabaseSearch2';
import { virtualSchemaKeyParts } from 'pages/Warehouse/DatabaseSearch2/virtualSchema';

import HoveredIndexWrapper, { PassedByHoveredIndexWrapperUser } from '../../HoveredIndexWrapper';
import { FilteredSchemaMap, SchemasExpandedMap } from '../../TableExplorerReducer';
import { DisabledTablesByTableID, PassedToTableRowProps } from '../../TableNameList';

import { TableExplorerCommonExpandoProps } from '../expandos/TableExplorerGenericExpando';

// Props common to the Generic and Concrete Lists
export interface CommonExpandoListProps {
  isFiltering: boolean;
  filteredExpandoObjectMap: FilteredSchemaMap; // This could be any object type that fits this type (SchemaMap, TagMap)
  unfilteredExpandoObjectMap: FilteredSchemaMap; // Same as filteredExpandoObjectMap, except unfiltered.
  objectsExpandedMap: SchemasExpandedMap; // This could be any object type that fits this type (SchemaMap, TagMap)
  selectedTable: AggTable | null;
  disabledTablesByID: DisabledTablesByTableID;
  passedToTableRow: PassedToTableRowProps;
}

// The props the renderExpando() function is expected to set
export interface RenderExpandoProps extends TableExplorerCommonExpandoProps {
  // Used by expando:
  key: string;
  picked: boolean;
  companyName: string; // Needed to calculate schema icons due to Custom Connector hacks
}

// The props a ConcreteList must define
export interface ConcreteListResponsibility {
  renderExpando(props: RenderExpandoProps): void;
  isExpandoPicked?(expandoKey: string): boolean;
  getPickedTables?(expandoKey: string): Record<TableID, AggTable> | undefined;
}

interface InnerGenericExpandoListProps extends CommonExpandoListProps, ConcreteListResponsibility {}

export interface GenericExpandoListProps
  extends InnerGenericExpandoListProps,
    PassedByHoveredIndexWrapperUser {}

const GenericExpandoList = React.memo((props: GenericExpandoListProps) => {
  const {
    filteredExpandoObjectMap,
    objectsExpandedMap,
    maxHeight,
    selectedTable,
    renderTableHover,
    renderSchemaHover,
    ...rest
  } = props;

  const getHoveredItem: (hoveredIndex: number) => AggTable | SchemaWithIconType | null = useCallback(
    (hoveredIndex: number) => {
      // Am I hovering over the TableExplorer at all?
      if (hoveredIndex < 0) {
        return null;
      }

      const sortedExpandoKeys = getSortedSchemaKeys(filteredExpandoObjectMap, []);

      for (const key of sortedExpandoKeys) {
        const tables = filteredExpandoObjectMap[key];
        // Am I hovering over an expando heading, which is not a table?
        if (hoveredIndex <= 0) {
          const { schema, virtualSchemaType } = virtualSchemaKeyParts(key);
          return { schema, iconType: getSchemaType(virtualSchemaType, schema, tables) };
        }
        // Subtract 1 for the heading
        hoveredIndex--;

        // Is the current expando open?
        if (objectsExpandedMap[key]) {
          // Is the hovered row in the current expando?
          if (hoveredIndex < tables.length) {
            return tables[hoveredIndex];
          } else {
            hoveredIndex -= tables.length;
          }
        }
      }

      // I didn't find anything, so the cursor is probably below the expando list.
      return null;
    },
    [filteredExpandoObjectMap, objectsExpandedMap],
  );

  return (
    <HoveredIndexWrapper
      maxHeight={maxHeight}
      selectedTable={selectedTable}
      renderTableHover={renderTableHover}
      renderSchemaHover={renderSchemaHover}
      getHoveredItem={getHoveredItem}
      renderedTables={
        <InnerGenericExpandoList
          filteredExpandoObjectMap={filteredExpandoObjectMap}
          objectsExpandedMap={objectsExpandedMap}
          selectedTable={selectedTable}
          {...rest}
        />
      }
    />
  );
});

const InnerGenericExpandoList = React.memo((props: InnerGenericExpandoListProps) => {
  const {
    isFiltering,
    filteredExpandoObjectMap,
    unfilteredExpandoObjectMap,
    objectsExpandedMap,
    selectedTable,
    disabledTablesByID,
    passedToTableRow,
    renderExpando,
    isExpandoPicked,
    getPickedTables,
  } = props;
  const { userProfile } = useUserProfile();

  const sortedExpandoKeys = getSortedSchemaKeys(filteredExpandoObjectMap, []);

  return (
    <div className="tt-table-list-name-list">
      {sortedExpandoKeys.map((key: string) => {
        const isOpen = objectsExpandedMap[key];
        const tables = filteredExpandoObjectMap[key] || [];
        const unfilteredCount = unfilteredExpandoObjectMap[key].length;
        const picked = isExpandoPicked?.(key) ?? false;
        const pickedTables = getPickedTables?.(key);

        const renderExpandoProps: RenderExpandoProps = {
          key,
          picked,
          isOpen: isOpen,
          isFiltering,
          tables: tables,
          unfilteredCount,
          companyName: userProfile.company.name,
          selectedTable,
          pickedTables,
          disabledTablesByID: disabledTablesByID,
          passedToTableRow: passedToTableRow,
        };

        return renderExpando(renderExpandoProps);
      })}
    </div>
  );
});

export default GenericExpandoList;
