/*
  Wraps TextAreaInputGroup and injects the Formik hook props into the input group.
*/
import React from 'react';

import { useField } from 'formik';

import TextAreaInputGroup, {
  TextAreaInputGroupProps,
} from 'components/inputs/group/TextAreaInputGroup/TextAreaInputGroup';

// We get value from Formik
export type SanitizedTextAreaInputGroupProps = Omit<TextAreaInputGroupProps, 'value'>;

export interface TextAreaFormikGroupProps extends SanitizedTextAreaInputGroupProps {}

export default function TextAreaFormikGroup(props: TextAreaFormikGroupProps) {
  const { name, ...rest } = props;
  const [field, meta] = useField(name);
  const { name: fieldName, value: fieldValue, ...fieldRest } = field;

  return (
    <TextAreaInputGroup
      name={name}
      value={meta.value}
      error={meta.touched && meta.error ? meta.error : ''}
      {...fieldRest}
      {...rest}
    />
  );
}
