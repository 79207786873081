/*
This is a base class for all icon buttons that trigger an API call or some long running
action that would require a spinner and possibly trigger and error.

Presumeably you would wrap this component in another component that implements
`onClick` and sets `icon`.
*/
import React, { useState } from 'react';

import Button from 'components/inputs/basic/Button/Button';
import IconButton, { IconButtonProps } from 'components/inputs/basic/Button/IconButton';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';

interface ErrorState {
  show: boolean;
  header: string;
  body: string;
}
export function useApiIconState() {
  const [spinning, setSpinning] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<ErrorState>({
    show: false,
    header: '',
    body: '',
  });
  return { spinning, setSpinning, errorState, setErrorState };
}

// Any component that wraps this component should make these props
// available to it's caller.
export interface ExtendersProps {
  eventPage: string; // The first word in a Segment event
}

export type ApiIconButtonProps = IconButtonProps & {
  errorState: ErrorState;
  tip?: string;
  setErrorState: (errorState: ErrorState) => void;
  onClick: () => void;
};

export default function ApiIconButton(props: ApiIconButtonProps) {
  const { errorState, tip, setErrorState, onClick, ...iconButtonProps } = props;

  const resetErrorState = () => {
    setErrorState({
      show: false,
      header: '',
      body: '',
    });
  };

  const modalFooter = (
    <Button variant="lightDanger" onClick={resetErrorState}>
      Close
    </Button>
  );
  const errorModal = errorState.show && (
    <Modal
      header={errorState.header}
      footer={modalFooter}
      onClose={resetErrorState}
      containerClass="min-w-[25%] max-w-[90%]"
    >
      <div className="p-4">{errorState.body}</div>
    </Modal>
  );

  const spinnerIconButton = <IconButton onClick={onClick} {...iconButtonProps} />;

  return (
    <>
      {errorModal}
      {tip ? (
        <TooltipTrigger tip={tip || ''}>{spinnerIconButton}</TooltipTrigger>
      ) : (
        <>{spinnerIconButton}</>
      )}
    </>
  );
}
