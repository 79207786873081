import React from 'react';

import cn from 'classnames';

import { Connector } from 'api/APITypes';
import { AggTable } from 'api/tableAPI';
import LinkIconButton from 'components/inputs/basic/Button/LinkIconButton';
import TableStatusIcon from 'components/primitives/icons/TableStatusIcon/TableStatusIcon';
import {
  numRowsAsString,
  lastRunDateAsString,
  lastRunAgo,
  durationAsString,
} from 'model_helpers/aggTableHelper';
import { getMostFrequentRunConfig, getMostRecentRunConfig } from 'model_helpers/dbtAggTableHelper';
import RunConfigScheduleWidget from 'pages/dbt/ShowDBTRunConfig/SettingsTab/RunConfigScheduleWidget';
import { dbtRunConfigurationPath } from 'utils/PathMaker';

import SidebarDescription from './SidebarDescription';
import { HEADING_CLASS } from './VertexSidebar';
import VertexTableSection from './VertexTableSection';

interface DBTSidebarContentProps {
  table: AggTable;
  connectorsByID: { [key: string]: Connector };
}

export default function DBTSidebarContent(props: DBTSidebarContentProps) {
  const { table, connectorsByID } = props;

  const mostRecentConfig = getMostRecentRunConfig(table);

  const runConfig = getMostFrequentRunConfig(table);
  const frequency = runConfig ? (
    <RunConfigScheduleWidget runConfig={runConfig} connectorsByID={connectorsByID} />
  ) : (
    'MISSING TRY REFRESHING'
  );

  const lastRunRows = [
    { label: 'Status', value: <TableStatusIcon table={table} /> },
    { label: 'Started At', value: lastRunDateAsString(table) },
    { label: 'Started Ago', value: lastRunAgo(table) },
    { label: 'Duration', value: durationAsString(table) },
    { label: 'Rows', value: numRowsAsString(table) },
    { label: 'Table Type', value: table.database_table_type },
    { label: 'Type', value: mostRecentConfig?.last_run?.is_manual ? 'Manual' : 'Scheduled' },
  ];

  const trackGoToRunConfig = () => {
    analytics.track('PipelineEditor GoToDBTRunConfig', {
      table_id: table.id,
      run_config_id: mostRecentConfig?.id || 'UNKNOWN',
    });
  };

  return (
    <div>
      <SidebarDescription description={table.description} />
      <div className={cn(HEADING_CLASS, 'mt-6')}>Schedule</div>
      <div className="mt-1 text-sm">{frequency}</div>
      <VertexTableSection title="Last Run" rows={lastRunRows} className="mt-6" />
      {mostRecentConfig && (
        <LinkIconButton
          icon="BoxArrowUpRight"
          text="GO TO DBT JOB"
          iconLast={true}
          iconSize={10}
          size="small"
          variant="lightDullTransparent"
          to={dbtRunConfigurationPath(mostRecentConfig.id)}
          onClickCapture={trackGoToRunConfig}
          target="_blank"
          className="mt-6"
        />
      )}
    </div>
  );
}
