import LinkIconButton from 'components/inputs/basic/Button/LinkIconButton';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import UsageProgressBar from 'pages/UsageV2/UsageProgressBar';
import UsageSumDisplay from 'pages/UsageV2/UsageSumDisplay';
import { CompanyPlanRow } from 'pages/UsageV2/useUsageFetch';

import UnderConstruction from '../UnderConstruction/UnderConstruction';

export interface UsageWidgetState {
  allLoaded: boolean;
  anyError: boolean;
  currentMonthPlan: CompanyPlanRow | null;
  currentMonthMar: number;
  currentMonthCompute: number;
  onClickViewUsage(): void;
}

export interface UsageWidgetProps extends UsageWidgetState {
  className?: string;
}

const UsageWidget = (props: UsageWidgetProps) => {
  const {
    allLoaded,
    anyError,
    currentMonthPlan,
    currentMonthMar,
    currentMonthCompute,
    className,
    onClickViewUsage,
  } = props;
  const isUsageWidgetEnabled = useBooleanFlag('homepage_usage_widget');

  if (!isUsageWidgetEnabled) {
    const content = <UnderConstruction linkHref="/usage/summary" />;

    return (
      <div className={className}>
        <h1 className="text-lg font-medium mb-4">Usage Overview</h1>
        {content}
      </div>
    );
  }

  let content: JSX.Element | null = null;
  if (!allLoaded) {
    content = <CenteredSpinner type="circle" />;
  } else if (anyError) {
    // TODO(homepage-v1): See if we can add a refresh button to retry fetching usage data
    content = (
      <div>
        <Alert variant="error">
          There was an error loading usage data. Refresh the page to try again.
        </Alert>
      </div>
    );
  } else if (currentMonthPlan && !currentMonthPlan.IS_USAGE) {
    content = (
      <div className="f-row-y-center max-xl:flex-col gap-x-2">
        <UsageProgressBar
          currentMonthPlan={currentMonthPlan}
          currentMonthValue={currentMonthMar}
          tab="connectors"
        />
        <UsageProgressBar
          currentMonthPlan={currentMonthPlan}
          currentMonthValue={currentMonthCompute}
          tab="compute"
        />
      </div>
    );
  } else {
    content = (
      <div className="f-row-y-center max-xl:flex-col gap-x-2">
        <UsageSumDisplay
          currentMonthPlan={currentMonthPlan}
          sumValue={currentMonthMar}
          tab="connectors"
        />
        <UsageSumDisplay
          currentMonthPlan={currentMonthPlan}
          sumValue={currentMonthCompute}
          tab="compute"
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="flex items-flex-start justify-between">
        <h1 className="text-lg font-medium mb-4">Usage Overview</h1>
        <LinkIconButton
          icon="ChevronRight"
          text="Go to Usage"
          iconLast
          size="small"
          variant="lightDullTransparent"
          to="/usage"
          onClickCapture={onClickViewUsage}
          className="uppercase"
        />
      </div>
      {content}
    </div>
  );
};

export default UsageWidget;
