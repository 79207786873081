import Button from 'components/inputs/basic/Button/Button';
import Alert from 'components/widgets/alerts/Alert/Alert';

interface ConnectSnowflakeProps {
  handleShowSnowflakeInLookerClick: () => void;
  handleDoSetupSnowflakeInLooker: () => void;
  settingUpSnowflake: boolean;
  setupSnowflakeError: string | null;
  databaseDisplayName: string;
}

const ConnectSnowflake = (props: ConnectSnowflakeProps) => {
  const {
    handleShowSnowflakeInLookerClick,
    handleDoSetupSnowflakeInLooker,
    settingUpSnowflake,
    setupSnowflakeError,
    databaseDisplayName,
  } = props;
  return (
    <div>
      {setupSnowflakeError && (
        <Alert variant="error" className="mb-4">
          {setupSnowflakeError}
        </Alert>
      )}
      <h2 className="text-md font-medium">Make {databaseDisplayName} Connection</h2>
      <p className="text-md mt-2">
        If you have not already connected Looker to your Mozart Data {databaseDisplayName} database, the
        button below will do it for you in one click.
      </p>
      <div className="w-full h-[1px] bg-pri-gray-200 my-4" />
      <div className="flex justify-end space-x-4">
        <Button onClick={handleShowSnowflakeInLookerClick} variant="lightTransparent">
          Already Connected
        </Button>
        <Button
          onClick={handleDoSetupSnowflakeInLooker}
          variant="lightAction"
          spinning={settingUpSnowflake}
        >
          Connect to {databaseDisplayName}
        </Button>
      </div>
    </div>
  );
};

export default ConnectSnowflake;
