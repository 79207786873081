import React from 'react';

import cn from 'classnames';

import { cleanColor } from 'utils/String';

interface CircularProgressBarProps {
  numerator: number;
  denominator: number;
  barColor?: string; // These are colors, like --pri-gray-200 or #454545
  backgroundColor?: string;
  containerClassName?: string;
}

const CircularProgressBar = (props: CircularProgressBarProps) => {
  const { numerator, denominator, backgroundColor, barColor, containerClassName } = props;

  const progress = denominator === 0 ? 0 : numerator / denominator;
  const strokeDasharray = `${progress * 100} 100`;

  const actualBarColor = barColor ? cleanColor(barColor) : 'var(--sec-blue-gray-500)';
  const actualBackgroundColor = backgroundColor
    ? cleanColor(backgroundColor)
    : 'var(--sec-blue-gray-200)';

  const actualContainerClassName = containerClassName || 'w-[100px] h-[100px]';

  return (
    <div className={cn('inline-block relative', actualContainerClassName)}>
      <svg viewBox="0 0 36 36" className="mx-auto my-0 w-full h-full">
        <circle
          className="circleBg"
          cx="18"
          cy="18"
          r="15.9155"
          fill="none"
          stroke={actualBackgroundColor}
          strokeWidth="4"
          strokeLinecap="round"
          strokeDasharray="100, 100"
        />
        <path
          className="origin-center"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          fill="none"
          stroke={actualBarColor}
          strokeWidth="4"
          strokeDasharray={strokeDasharray}
        />
        <text
          x="18"
          y="21.35"
          className="percentage"
          textAnchor="middle"
          fontSize="10"
          fontWeight="500"
          fill={actualBarColor}
        >
          {Math.floor(progress * 100)}%
        </text>
      </svg>
    </div>
  );
};

export default CircularProgressBar;
