import React from 'react';

import { AggTable } from 'api/APITypes';
import { SaveableTableProps } from 'api/tableAPI';
import HeaderTabLayout from 'components/layouts/pages/HeaderTabLayout/HeaderTabLayout';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import { TabKeyType } from 'components/layouts/parts/TabRow/TabRow';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { Pipeline } from 'pages/tables/ShowTable/PipelineTab/PipelineEditor/PipelineEditor';

import TableHeader from './TableHeader';

interface TableLayoutProps {
  loading: boolean;
  loadingError: string;
  afterLoadError: string;
  table: AggTable | null;
  tabs: TabKeyType[];
  tabContent: React.ReactNode;
  currentTab: string;
  pipeline: Pipeline | null;
  loadingPipeline: boolean;
  fetchPipelineData: () => void;
  saveTable?: (
    tableProps: SaveableTableProps,
    afterSave?: (table: AggTable) => void,
    afterFinally?: () => void,
    setError?: (e: string) => void,
  ) => void;
  onSelectTab: (
    unsafeTab: string | null,
    event?: React.SyntheticEvent<unknown>,
    trackEvent?: string,
  ) => void;
  modals: React.ReactNode;
}

export default function TableLayout(props: TableLayoutProps) {
  const {
    loading,
    loadingError,
    afterLoadError,
    table,
    tabs,
    tabContent,
    currentTab,
    pipeline,
    loadingPipeline,
    fetchPipelineData,
    saveTable,
    onSelectTab,
    modals,
  } = props;

  if (loading) {
    return <CenteredSpinner containerMinHeight="60vh" />;
  }

  if (loadingError) {
    // The whole page is an error message if we failed an initial API load.
    return (
      <Alert className="m-4" variant="error">
        {loadingError}
      </Alert>
    );
  }

  return (
    <HeaderTabLayout
      header={
        <>
          {table && (
            <TableHeader
              table={table}
              currentTab={currentTab}
              pipeline={pipeline}
              loadingPipeline={loadingPipeline}
              fetchPipelineData={fetchPipelineData}
              saveTable={saveTable}
            />
          )}
        </>
      }
      tabs={tabs}
      currentTab={currentTab}
      // The Query Table button in the right corner of the header is much taller than the title over the tabs.
      // This spacing hack counter acts the button's height to create the perception of normal spacing.
      contentClass="!pt-2"
      afterLoadError={afterLoadError}
      onSelectTab={onSelectTab}
    >
      {tabContent}
      {modals}
    </HeaderTabLayout>
  );
}
