import React from 'react';

import cn from 'classnames';

import { ButtonVariant } from './Button';

import s from './Button.module.css';

export const BACKGROUND_COLOR: { [key in ButtonVariant]: string } = {
  lightAction: 'bg-sec-purple-100',
  darkAction: 'bg-purple',
  lightDullAction: 'bg-sec-blue-gray-100',
  darkDullAction: 'bg-sec-blue-gray-500',
  save: 'bg-pri-success-700',
  lightWarning: 'bg-pri-warning-100',
  lightDanger: 'bg-pri-error-100',
  darkDanger: 'bg-pri-error-700',
  lightTransparent: 'transparent',
  lightDullTransparent: 'transparent',
  saveTransparent: 'transparent',
  warningTransparent: 'transparent',
  dangerTransparent: 'transparent',
  lightDullTab: 'transparent',
};

export const ACCENT_COLOR: { [key in ButtonVariant]: string } = {
  lightAction: 'bg-purple',
  darkAction: 'bg-silver',
  lightDullAction: 'bg-sec-blue-gray-500',
  darkDullAction: 'bg-white',
  save: 'bg-white',
  lightWarning: 'bg-pri-warning-700',
  lightDanger: 'bg-pri-error-700',
  darkDanger: 'bg-silver',
  lightTransparent: 'bg-purple',
  lightDullTransparent: 'bg-sec-blue-gray-500',
  saveTransparent: 'bg-pri-success-700',
  warningTransparent: 'bg-pri-warning-700',
  dangerTransparent: 'bg-pri-error-700',
  lightDullTab: 'bg-pri-gray-500',
};

export interface ButtonGroupProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant: ButtonVariant;
}

const ButtonGroup = (props: ButtonGroupProps) => {
  const { variant, children, className, ...remainingProps } = props;

  const actualClass = cn(s.buttonGroup, className);
  const bgColor = BACKGROUND_COLOR[variant];
  const accentColor = ACCENT_COLOR[variant];
  const childArray = React.Children.toArray(children);

  return (
    <div className={actualClass} {...remainingProps}>
      {React.Children.map(children, (child, i) => (
        <>
          {child}
          {i < childArray.length - 1 && (
            <div className={`w-[1px] h-full f-center shrink-0 ${bgColor}`}>
              <div className={`w-[1px] h-[71%] ${accentColor}`}></div>
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default ButtonGroup;
