import React from 'react';

import { random } from 'lodash';

import Popover from 'components/overlay/Popover/Popover';
import PopperOverlay from 'components/overlay/PopperOverlay/PopperOverlay';

// Set of colors manually currated by designer.
// background color to font color
export const COLORS: { [fontColor: string]: string } = {
  // Row 1, Light Colors
  '#e7f8f0': '#12b76a', // pri-success-500
  '#e7f6fd': '#0ba5ec', // sec-blue-light-500
  '#eaf4ff': '#2e90fa', // sec-blue-500
  '#eff1fe': '#6172f3', // sec-indigo-500
  '#f2eefe': '#7a5af8', // sec-purple-500
  '#feecf0': '#f63d68', // sec-rose-500
  '#fff0e7': '#fb6514', // sec-orange-500
  '#f0f1f3': '#667085', // pri-gray-500
  // Row 2, Dark Colors
  '#e6f2ed': '#027a48', // pri-success-700
  '#e6f0f6': '#026aa2', // sec-blue-light-700
  '#e8effb': '#175cd3', // sec-blue-700
  '#ebebfa': '#3538cd', // sec-indigo-700
  '#eee9fb': '#5925dc', // sec-purple-700
  '#f9e7ed': '#c01048', // sec-rose-700
  '#f9ebe6': '#c4320a', // sec-orange-700
  '#ebecee': '#344054', // pri-gray-700
};

interface ColorPickerProps {
  targetRef: React.RefObject<HTMLButtonElement>;
  show: boolean;
  closePopover: () => void;
  onPickColor: (color: string) => void;
}

export default function ColorPicker(props: ColorPickerProps) {
  const { targetRef, show, closePopover, onPickColor } = props;

  return (
    <PopperOverlay
      target={targetRef}
      show={show}
      placement="bottom"
      renderPopper={(renderPopperProps) => {
        const content = (
          <div className="grid grid-cols-8 gap-2">
            {Object.entries(COLORS).map(([backgroundColor, fontColor]) => (
              <div
                key={backgroundColor}
                onClick={() => onPickColor(backgroundColor)}
                className="w-7 h-7 f-center cursor-pointer rounded"
                style={{ color: fontColor, backgroundColor: backgroundColor }}
              >
                Aa
              </div>
            ))}
          </div>
        );
        return (
          <>
            <Popover
              content={content}
              popoverProps={{ style: { maxWidth: 'unset', padding: '12px' } }} // Popover hardcodes maxWidth to 300px, so overwrite it here. Also 12px of padding to hide Popover arrow.
              {...renderPopperProps}
            />
            <div onClick={closePopover} className="fixed inset-0 z-[2000]" />
          </>
        );
      }}
    />
  );
}

export function randomColor() {
  const keys = Object.keys(COLORS);
  return keys[random(keys.length - 1)];
}
