import React from 'react';

import cn from 'classnames';

import Listbox, { ListboxValue, ListboxOption } from 'components/inputs/basic/Listbox/Listbox';
import Switch from 'components/inputs/basic/Switch/Switch';
import { useDatabaseAccount } from 'context/AuthContext';
import { convertColumnName } from 'utils/dbName';

import { UnsavedTransform } from './RunOptionsModal';

interface IncrementalTabProps {
  incrementalUpdateKeys: string[] | null; // Names of columns produced by saved SQL
  validSql: boolean; // If the currently saved sql is valid and can get update keys
  loadingUpdateKeys: boolean;
  unsavedTransform: UnsavedTransform;
  setUnsavedTransform: React.Dispatch<React.SetStateAction<UnsavedTransform>>;
}

export default function IncrementalTab(props: IncrementalTabProps) {
  const { incrementalUpdateKeys, validSql, loadingUpdateKeys, unsavedTransform, setUnsavedTransform } =
    props;

  const databaseType = useDatabaseAccount().type;

  const disabled = !unsavedTransform.incremental;

  const handleToggleIncremental = () => {
    setUnsavedTransform({ ...unsavedTransform, incremental: !unsavedTransform.incremental });
  };

  const handleToggleAutoRebuild = () => {
    setUnsavedTransform({
      ...unsavedTransform,
      auto_rebuild_on_change: !unsavedTransform.auto_rebuild_on_change,
    });
  };

  const setIncrementalUpdateKey = (newValue: ListboxValue) => {
    if (typeof newValue === 'string') {
      let updateKey: string | null = newValue;
      if (updateKey === 'None') {
        updateKey = null;
      }
      setUnsavedTransform({ ...unsavedTransform, incremental_update_key: updateKey });
    }
  };

  // Default to options when !validSql and overwrite if validSql
  const { incremental_update_key } = unsavedTransform;
  let listboxOptions: ListboxOption[] = [];
  let currentUpdateKeyLabel = 'Invalid SQL';
  if (validSql) {
    let incrementalOptions: string[] = [];
    if (incrementalUpdateKeys) {
      incrementalOptions = incrementalUpdateKeys;
    } else if (incremental_update_key) {
      incrementalOptions = [incremental_update_key];
    }

    const NO_UPDATE_KEY_LABEL = 'None';
    currentUpdateKeyLabel = incremental_update_key
      ? convertColumnName(incremental_update_key, databaseType)
      : NO_UPDATE_KEY_LABEL;
    listboxOptions = [{ value: NO_UPDATE_KEY_LABEL, label: NO_UPDATE_KEY_LABEL }].concat(
      incrementalOptions.map((k) => {
        return { value: k, label: convertColumnName(k, databaseType) };
      }),
    );
  }
  const updateKeyDropdown = (
    <Listbox
      value={incremental_update_key}
      label={currentUpdateKeyLabel}
      onChange={setIncrementalUpdateKey}
      variant="white"
      size="medium"
      containerClass="mt-4"
      optionsMaxHeightClass="max-h-[210px]"
      disabled={disabled || !validSql}
      hasError={!validSql}
      spinning={loadingUpdateKeys}
      options={listboxOptions}
    />
  );

  const DIVIDER_WIDTH = 34;
  const COLUMN_WIDTH = `calc(50% - ${DIVIDER_WIDTH}px)`;

  return (
    <div className="h-full min-h-0 flex flex-col">
      <div className="text-sm text-pri-gray-500">
        <div>
          Incremental transforms only process new data that has changed since the last time your
          transform ran. Incremental transforms save time and money on large data sets. You can do a full
          rebuild of your transform table by running your incremental transform in non-incremental mode.
          Incremental mode is only available if Create As is set to 'Table'.
        </div>
        <div className="mt-1">
          <a
            href="https://help.mozartdata.com/docs/incremental-transforms"
            rel="noopener noreferrer"
            target="_blank"
            data-track={`RunOptionsModal LinkToIncrementalTransformsLearnMore`}
            className="font-medium hover:pointer hover:underline hover:text-sec-blue-gray-500"
          >
            Learn More.
          </a>
        </div>
      </div>
      <div className="f-row-y-center mt-4">
        <Switch
          id="enable-incremental-switch"
          name="enable"
          checked={unsavedTransform.incremental}
          onChange={handleToggleIncremental}
        />
        <h2 className="text-base font-medium text-sec-blue-gray-500 ml-2">INCREMENTAL</h2>
      </div>
      <div
        className={cn(
          'mt-4 p-4 f-row-y-center rounded',
          { 'bg-pri-gray-100': !disabled },
          { 'bg-pri-gray-25 text-pri-gray-400': disabled },
        )}
      >
        <div className="flex flex-col" style={{ width: COLUMN_WIDTH }}>
          <div className="text-sm font-medium">Select a column for the update key</div>
          {updateKeyDropdown}
          <div className={cn('mt-2 text-sm', { 'text-pri-gray-500': !disabled })}>
            The update key can be used to update existing table rows.
          </div>
        </div>
        <div className="w-[2px] h-[124px] mx-4 bg-pri-gray-200" />
        <div className="flex flex-col" style={{ width: COLUMN_WIDTH }}>
          <div className="f-row-y-center">
            <Switch
              id="enable-auto-rebuild-switch"
              name="autoRebuild"
              checked={unsavedTransform.auto_rebuild_on_change}
              onChange={handleToggleAutoRebuild}
              disabled={disabled}
            />
            <h2 className={cn('text-base font-medium ml-2', { 'text-sec-blue-gray-500': !disabled })}>
              AUTO REBUILD
            </h2>
          </div>
          <div className={cn('mt-4 text-sm', { 'text-pri-gray-500': !disabled })}>
            In the event that there is a schema change, Auto Rebuild will do a full rebuild of your
            table. You may want to disable this if your data set is very large.
          </div>
        </div>
      </div>
    </div>
  );
}
