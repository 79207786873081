import React, { useEffect, useCallback } from 'react';

import { AggTable } from 'api/tableAPI';
import QueryEditor from 'components/query/QueryEditor';
import useQueryEditor from 'components/query/useQueryEditor';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { DataAlertWithTable, SavableDataAlertProps } from 'hooks/useDataAlerts';

const EVENT_PREFIX = 'TableTestSqlLayout';

interface DataAlertSqlLayoutProps {
  dataAlert: DataAlertWithTable;
  table: AggTable;
  saving: boolean;
  createMode: boolean;
  saveDataAlert(
    dataAlert: DataAlertWithTable,
    savableProps: SavableDataAlertProps,
  ): Promise<DataAlertWithTable>;
  afterSqlChange?(newSql: string): void;
  setUnsavedSql(unsaved: boolean): void;
}

const DataAlertSqlLayout = (props: DataAlertSqlLayoutProps) => {
  const { dataAlert, table, saving, createMode, saveDataAlert, afterSqlChange, setUnsavedSql } = props;
  const queryEditorState = useQueryEditor({
    eventPrefix: EVENT_PREFIX,
    errorOnDuplicateColumns: true,
    object: { id: dataAlert.id, type: 'dataAlert' },
  });

  const { setSelectedTable, queryRunnerState, sqlState } = queryEditorState;
  const { runResults, runErrorLines, onExportCsv, onExportGSheet } = queryRunnerState;
  const { editorSql, setEditorSql } = sqlState;

  useEffect(() => {
    setEditorSql(dataAlert.sql, false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Default TableList to current table if it exists
    setSelectedTable(table);
  }, [table, setSelectedTable]);

  useEffect(() => {
    // If we are in createMode, we need the Formik form to update with the sql in the editor
    if (afterSqlChange) {
      afterSqlChange(editorSql);
    }
  }, [editorSql, afterSqlChange]);

  const handleSaveSql = useCallback(() => {
    return saveDataAlert(dataAlert, { sql: editorSql }).then((newDataAlert) => newDataAlert.sql);
  }, [dataAlert, editorSql, saveDataAlert]);

  let testResultDisplay = null;
  if (runResults && runResults.rows.length === 0) {
    testResultDisplay = (
      <Alert className="my-2 py-1 px-2" variant="no_results">
        Alert returned 0 rows on the current table.
      </Alert>
    );
  } else if (runResults && runResults.rows.length > 0) {
    const numRows = runResults.rows.length;
    testResultDisplay = (
      <Alert className="my-2 py-1 px-2" variant="error">
        Alert returned {numRows} row{numRows > 1 ? 's' : ''} on current table.
      </Alert>
    );
  } else if (runErrorLines.length > 0) {
    testResultDisplay = (
      <Alert className="my-2 py-1 px-2" variant="error">
        Alert failed to run due to an error.
      </Alert>
    );
  }

  const handleExportCsv = useCallback(
    () => onExportCsv(`${table.schema}_${table.name}-${dataAlert.name}.csv`),
    [onExportCsv, table.schema, table.name, dataAlert.name],
  );

  const handleExportGSheet = useCallback(
    () => onExportGSheet(`${table.schema}_${table.name}-${dataAlert.name}`, `${dataAlert.name}`),
    [onExportGSheet, table.schema, table.name, dataAlert.name],
  );

  return (
    <div className="h-full min-h-0 flex flex-col">
      {testResultDisplay && <div className="h-fit flex px-4">{testResultDisplay}</div>}
      <QueryEditor
        queryEditorState={queryEditorState}
        canShowEditButtons={!createMode}
        savedSql={dataAlert.sql}
        isTransform={false}
        eventPrefix={EVENT_PREFIX}
        saving={saving}
        isShown={true}
        initialEditMode={true}
        onExportCsv={handleExportCsv}
        onExportGSheet={handleExportGSheet}
        onSaveSql={handleSaveSql}
        setUnsavedSql={setUnsavedSql}
      />
    </div>
  );
};

export default DataAlertSqlLayout;
