import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import { tableAndViewDescription } from 'pages/tables/ShowTable/TransformTab/TransformOptionsBar/RunOptionsModal/CreateAsTab';

export default function CreateAsInfoIcon() {
  const content = <div>{tableAndViewDescription}</div>;
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} containerClass="ml-1" popoverProps={popoverProps} />;
}
