import { forwardRef } from 'react';

import { Link } from 'react-router-dom';

import cn from 'classnames';

import { useBooleanFlag } from 'hooks/useFeatureFlags';

import { Menu } from '@headlessui/react';

import HeaderIcon from '../HeaderIcon/HeaderIcon';

import menuStyle from 'components/widgets/Menu/Menu.module.css';

export const AddMenu = forwardRef<HTMLAnchorElement, {}>((props, forwardedRef) => {
  const isCreateDataAlertsEnabled = useBooleanFlag('create_data_alerts');

  return (
    <Menu ref={forwardedRef} as="div" className={menuStyle.menu}>
      <Menu.Button className="f-row-y-center ml-2" id="nav-plus-dropdown">
        <HeaderIcon type="plus" className="bg-silver" />
      </Menu.Button>
      <Menu.Items className={cn('min-w-[150px]', menuStyle.menuItems)}>
        <Menu.Item>
          <Link
            to="/transforms/add"
            target="_blank"
            data-track="Header CreateTransform"
            className={menuStyle.menuItem}
          >
            Create Transform
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="/connectors/add"
            target="_blank"
            data-track="Header AddConnector"
            className={menuStyle.menuItem}
          >
            Add Connector
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="/csv/add"
            target="_blank"
            data-track="Header UploadCSV"
            className={menuStyle.menuItem}
          >
            Upload CSV
          </Link>
        </Menu.Item>
        {/* 
          TODO(create-data-alerts): Had to make this open on the same tab in order to get the modal
          opened on load. If we want to make this consistent with the other links, we either need to use
          the URL (query params, URL within `/alerts`) or local storage to get this working. Revisit
          if needed.
         */}
        {isCreateDataAlertsEnabled && (
          <Menu.Item>
            <Link
              to={{
                pathname: '/alerts',
                state: { createAlert: true, eventLocation: 'Header' },
              }}
              data-track="Header CreateAlert"
              className={menuStyle.menuItem}
            >
              Create Alert
            </Link>
          </Menu.Item>
        )}
      </Menu.Items>
    </Menu>
  );
});

export default AddMenu;
