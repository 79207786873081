/*
  Wraps ListboxGroup and injects the Formik hook props into the input group.
*/
import React from 'react';

import { useField } from 'formik';

import ListboxGroup, {
  ListboxGroupProps,
  ListboxValue,
} from 'components/inputs/group/ListboxGroup/ListboxGroup';

export type { ListboxOption } from 'components/inputs/group/ListboxGroup/ListboxGroup';

// We get value from Formik
export type SanitizedListboxGroupProps = Omit<ListboxGroupProps, 'value' | 'onChange'>;

export interface ListboxFormikGroupProps extends SanitizedListboxGroupProps {}
ListboxFormikGroup.displayName = 'ListboxFormikGroup';
export default function ListboxFormikGroup(props: ListboxFormikGroupProps) {
  const { name, ...rest } = props;
  const [field, meta, helpers] = useField(name);
  const { name: fieldName, value: fieldValue, ...fieldRest } = field;

  // I could not get field.onBlur() to work because this is a button not a form element with a name.
  const onBlur = () => {
    helpers.setTouched(true, true);
  };

  // Convert Listbox change to one Formik change.
  const onChange = (newVal: ListboxValue) => {
    helpers.setValue(newVal);
  };

  return (
    <ListboxGroup
      name={name}
      value={meta.value}
      error={meta.touched && meta.error ? meta.error : ''}
      {...fieldRest}
      {...rest}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}
