import { createContext } from 'react';

import { SearchColumn, SearchColumnsByTableID } from 'api/searchColumnAPI';

export interface SearchColumnContextInterface {
  searchColumnsByTableID: SearchColumnsByTableID;
  isLoading: boolean;
  error: string;
  isLocal: boolean;
  lastColumnsChange: Date | null;
  refreshColumns: () => void;
  refreshColumnsForTable: (tableID: string) => Promise<SearchColumn[] | null>;
}

export const SearchColumnContext = createContext<SearchColumnContextInterface>({
  searchColumnsByTableID: {},
  isLoading: false,
  error: '',
  isLocal: false,
  lastColumnsChange: null,
  refreshColumns: () => {},
  refreshColumnsForTable: () => Promise.resolve(null),
});
