import React from 'react';

import cn from 'classnames';

import Button from 'components/inputs/basic/Button/Button';
import PageButtons from 'components/inputs/basic/Button/PageButtons';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';
import {
  PaginatedInAppNotification,
  InAppNotification,
  displayableEventType,
} from 'pages/notifications/NotificationHelpers';
import { formatDate, agoDate, parseIso } from 'utils/dateTime';

interface ListNotificationsViewProps {
  error: string;
  loading: boolean;
  markingAllSeen: boolean;
  notificationsResp: PaginatedInAppNotification | null;
  onNotificationClick(notification: InAppNotification): void;
  onMarkAllSeen(): void;
  onLoadPreviousPage(): void;
  onLoadNextPage(): void;
}

export default function ListNotificationsView(props: ListNotificationsViewProps) {
  const {
    error,
    loading,
    markingAllSeen,
    notificationsResp,
    onNotificationClick,
    onMarkAllSeen,
    onLoadPreviousPage,
    onLoadNextPage,
  } = props;

  if (!notificationsResp || notificationsResp.count === 0) {
    return (
      <CenteredLayout
        maxWidth="95%"
        header={<h1 className="display-sm">Notifications</h1>}
        loadingError={error}
        loading={loading}
      >
        {!error && <NoObjectsAlert heading="You have no notifications." />}
      </CenteredLayout>
    );
  }

  const allSeen = notificationsResp.results.every((n) => n.seen);

  return (
    <CenteredLayout
      maxWidth="95%"
      header={<h1 className="display-sm">Notifications</h1>}
      loadingError={error}
      loading={loading}
    >
      <div className="f-between">
        <Button
          variant="lightAction"
          size="small"
          onClick={onMarkAllSeen}
          spinning={markingAllSeen}
          style={{ width: '126px' }}
          disabled={allSeen}
        >
          Mark All Seen
        </Button>
        <PageButtons
          hasPreviousPage={!!notificationsResp.previous}
          hasNextPage={!!notificationsResp.next}
          onLoadPreviousPage={onLoadPreviousPage}
          onLoadNextPage={onLoadNextPage}
        />
      </div>
      <table className="lineTable mt-4">
        <thead>
          <tr>
            <th className="whitespace-nowrap">Time</th>
            <th className="whitespace-nowrap">Ago</th>
            <th className="whitespace-nowrap">Type</th>
            <th className="whitespace-nowrap">Message</th>
          </tr>
        </thead>
        <tbody>
          {notificationsResp.results.map((notification: InAppNotification) => (
            <tr
              key={notification.id}
              className={cn(
                'hover:cursor-pointer',
                notification.seen
                  ? 'hover:bg-pri-gray-100'
                  : 'bg-sec-purple-50 hover:bg-sec-purple-100 border-l-[3px] border-sec-purple-700',
              )}
              onClick={() => onNotificationClick(notification)}
            >
              <td>{formatDate(parseIso(notification.created_at))}</td>
              <td>{agoDate(parseIso(notification.created_at))}</td>
              <td>{displayableEventType(notification.notification_event.notification_event_type)}</td>
              <td>{notificationText(notification)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </CenteredLayout>
  );
}

// Notification.tsx and NotificationDropdown.tsx have their own implementations
// of this method so we can vary messages by context.
export const notificationText = (notification: InAppNotification) => {
  const { notification_event_type, name, error } = notification.notification_event;
  if (notification_event_type === 'transform_failure') {
    return (
      <span>
        Transform <code>{name}</code> failed.
      </span>
    );
  } else if (notification_event_type === 'connector_initial_sync') {
    return (
      <span>
        Connector <code>{name}</code> finished its initial sync.
      </span>
    );
  } else if (notification_event_type === 'data_alert') {
    const [dataAlertName, tableName] = name.split(' on ');
    return (
      <span>
        Data alert <code>{dataAlertName}</code> on <code>{tableName}</code> returned results.
      </span>
    );
  } else if (notification_event_type === 'gsheet_failure') {
    return (
      <span>
        GSheet Sync from table <code>{name}</code> failed with error: {error}
      </span>
    );
  } else if (notification_event_type === 'dbt_run_failure') {
    return (
      <span>
        DBT Run from configuration <code>{name}</code> failed.
      </span>
    );
  } else if (notification_event_type === 'connector_failure') {
    return (
      <span>
        Connector <code>{name}</code> failed.
      </span>
    );
  } else {
    return <span>Unknown notification type.</span>;
  }
};
