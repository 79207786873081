/*
This the signup form we made in Feb 2023, when we launched the Free Sonata Plan.
*/
import { Helmet } from 'react-helmet';

import LoggedOutFormBackgroundLayout from 'components/layouts/pages/LoggedOutFormBackgroundLayout/LoggedOutFormBackgroundLayout';

import SignupRightSideForm from './SignupRightSideForm';
import SonataLeftSideCopy from './SonataLeftSideCopy';

interface SignupSonataProps {}

const SignupSonata = (props: SignupSonataProps) => {
  return (
    <LoggedOutFormBackgroundLayout>
      <Helmet>
        <title>Signup for Mozart Data | Mozart Data</title>
        <meta
          name="description"
          content="Sign up for Mozart Data and deploy your own data stack in just a few minutes. Start free with Mozart Data today."
        />
      </Helmet>
      <div className="w-full h-full p-4 flex overflow-auto">
        <div className="w-full lg:w-auto m-auto flex flex-col lg:flex-row">
          <SonataLeftSideCopy />
          <SignupRightSideForm />
        </div>
      </div>
    </LoggedOutFormBackgroundLayout>
  );
};

export default SignupSonata;
