import React from 'react';

import cn from 'classnames';

import { RenderPopperProps } from 'components/overlay/PopperTrigger/usePopperRefs';

import s from './Tooltip.module.css';

interface TooltipProps extends RenderPopperProps {
  tip: string | React.ReactNode;
}

export default function Tooltip(props: TooltipProps) {
  const { tip, setPopperElement, setArrowElement, popperStyles, popperAttributes } = props;

  const popoverProps = props.popoverProps || {};
  const { className, ...restPopoverProps } = popoverProps;
  const defaultClasses = `${s.box} text-sm font-normal drop-shadow-xl`;
  const actualClasses = cn(defaultClasses, className);

  return (
    <div
      ref={setPopperElement}
      className={s.popover}
      style={popperStyles.popper}
      {...popperAttributes.popper}
    >
      <div ref={setArrowElement} className={s.arrow} style={{ ...popperStyles.arrow }} />
      <div className={actualClasses} {...restPopoverProps}>
        {tip}
      </div>
    </div>
  );
}
