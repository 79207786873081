import { Connector } from 'api/APITypes';
import { calcGenericRunStatus, lastRunDate } from 'model_helpers/connectorHelper';

import AbstractTableSorter, { LAST_RUN_AGO, LAST_RUN_STATUS } from './AbstractTableSorter';

class ConnectorSorter extends AbstractTableSorter<Connector> {
  schedule(connector: Connector): number {
    return connector.sync_frequency;
  }

  lastRunAgo(connector: Connector) {
    const mostRecent = lastRunDate(connector);
    if (mostRecent) {
      const now = new Date();
      return now.getTime() - mostRecent.getTime();
    }

    return LAST_RUN_AGO.NOT_APPLICABLE;
  }

  runStatus(connector: Connector) {
    const runStatus = calcGenericRunStatus(connector);
    return LAST_RUN_STATUS[runStatus];
  }
}

const staticInstance = new ConnectorSorter();

export default staticInstance;
