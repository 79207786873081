import { Check } from 'react-bootstrap-icons';

import FreeInfoIcon from './FreeInfoIcon';
import MeritBadges from './MeritBadges';

interface SignupLeftSideCopyProps {}

const SignupLeftSideCopy = (props: SignupLeftSideCopyProps) => {
  // As of 04/03/23, 53px of margin-top is hand tunded to look good.
  return (
    <div className="w-full lg:min-w-[444px] lg:w-[444px] lg:mx-2 lg:mt-[16px]">
      <a href="https://www.mozartdata.com" data-track="Signup LinkToMarketingSite">
        <img className="h-[28px]" src="/images/logos/MozartDataLogoPurple.svg" alt="" />
      </a>
      <div className="display-xs mt-1">
        Modern data platform for centralizing, organizing, and analyzing your data.
      </div>
      <div className="f-row-y-center text-xl mt-6">
        <div className="w-4 h-4 f-center mr-2 bg-teal rounded">
          <Check size="14" color="var(--white)" />
        </div>
        Try for free
      </div>
      <div className="f-row-y-center mt-1">
        <div className="">$500 in free credits every month</div>
        <div className="ml-1">
          <FreeInfoIcon />
        </div>
      </div>
      <div className="mt-1">No credit card required</div>
      <div className="f-row-y-center text-xl mt-6">
        <div className="w-4 h-4 f-center mr-2 bg-teal rounded">
          <Check size="14" color="var(--white)" />
        </div>
        Get started fast
      </div>
      <div className="mt-1">Set up a modern data stack in 20 minutes</div>
      <div className="f-row-y-center text-xl mt-6">
        <div className="w-4 h-4 f-center mr-2 bg-teal rounded">
          <Check size="14" color="var(--white)" />
        </div>
        Easy to use
      </div>
      <div className="mt-1">Work with your data using no-code tools and SQL</div>
      <div className="w-full max-w-[400px] mt-6">
        <MeritBadges />
      </div>
    </div>
  );
};

export default SignupLeftSideCopy;
