import React, { ForwardedRef } from 'react';

import cn from 'classnames';

import { getActualClass } from 'components/inputs/basic/TextInput/TextInput';

export interface TextAreaInputProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  name: string;
  value: string;
  rows?: number;
  hasError?: boolean;
}

const TextAreaInput = React.forwardRef<HTMLTextAreaElement, TextAreaInputProps>(
  (props: TextAreaInputProps, forwardedRef: ForwardedRef<HTMLTextAreaElement>) => {
    let { name, value, rows, hasError, disabled, placeholder, className, ...remainingProps } = props;

    // Sanitize optional props
    hasError = hasError === true;

    // <textarea/> will render with an unexplained space under it without the align-top
    // https://stackoverflow.com/questions/7144843/extra-space-under-textarea-differs-along-browsers
    const spaceFix = cn('align-top', className);

    // Pick styles based on props
    const actualClass = getActualClass(spaceFix, hasError, disabled);

    return (
      <textarea
        ref={forwardedRef}
        name={name}
        value={value}
        rows={rows}
        placeholder={placeholder}
        disabled={disabled}
        className={actualClass}
        {...remainingProps}
      />
    );
  },
);

export default TextAreaInput;
