import { Dashboard, ReportAvailable, ReportTransform } from 'api/dashboardAPI';

import { trackConnector, LoggableConnector } from '../ConnectorUtils';

export function getTransforms(reportsAvailable: ReportAvailable[]) {
  let transforms: ReportTransform[] = [];
  reportsAvailable.forEach((ra) => {
    transforms = transforms.concat(ra.transforms);
  });
  return transforms;
}

export function trackDashboard(
  event: string,
  connector: LoggableConnector,
  dashboard: Dashboard,
  overrides?: Record<string, unknown>,
) {
  const dashboard_id = dashboard.id;
  trackConnector(event, connector, {
    dashboard_id,
    ...overrides,
  });
}
