// This page now supports 4 types of submissions, all of which log the user in.
// 1) Reset Password: This needs new password and the reset token id. Hits the reset password endpoint.
// 2) Reset Password with 2FA: This needs a new password, reset token id, and a totp token. Hits the reset password endpoint.
// 3) Login with SSO: This just needs to google credentials. Hits the Login endpoint.
// 4) Login with SSO and 2FA: This needs to google credentials and a totp token. Hits the Login 2FA endpoint.
// For submissions requiring 2FA, if it is the user's first time logging it, it will show the users totp secret
//   Otherwise, it just asks for the token.
// If the company requires SSO, the Reset Password form is not shown.
import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';

import { Formik } from 'formik';
import qs from 'query-string';
import url from 'url';
import * as Yup from 'yup';

import API from 'api/API';
import { UserProfile } from 'api/APITypes';
import QRCodeComponent from 'components/business_logic/QRCodeComponent/QRCodeComponent';
import Button from 'components/inputs/basic/Button/Button';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import LoggedOutFormBackgroundLayout from 'components/layouts/pages/LoggedOutFormBackgroundLayout/LoggedOutFormBackgroundLayout';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import FormLayoutTitleRow from 'components/layouts/parts/FormLayoutTitleRow/FormLayoutTitleRow';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useAuth } from 'context/AuthContext';

import { GoogleLogin } from '@react-oauth/google';

interface IResetPassword {
  password: string;
  confirmPassword: string;
}

interface IResetPassword2FA {
  password: string;
  confirmPassword: string;
  totp_token: string;
}

interface ILoginSSO {
  google_credential: string;
}

interface ILoginSSO2FA {
  google_credential: string;
  totp_token: string;
}

interface MatchParams {
  id: string;
}

interface ResetPasswordProps extends RouteComponentProps<MatchParams> {}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(12, 'At least 12 characters')
    .max(72, 'Not more than 72 characters')
    .matches(/^(?!\d+$).*$/, 'Password cannot be entirely numeric')
    .required('Required'),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('password')], "Passwords don't match")
    .required('Required'),
});

const resetPassword2FASchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(12, 'At least 12 characters')
    .max(72, 'Not more than 72 characters')
    .matches(/^(?!\d+$).*$/, 'Password cannot be entirely numeric')
    .required('Required'),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('password')], "Passwords don't match")
    .required('Required'),
  totp_token: Yup.string()
    .trim()
    .min(6, 'Exactly 6 digits')
    .max(6, 'Exactly 6 digits')
    .required('Required'),
});

const loginSSOSchema = Yup.object().shape({
  google_credential: Yup.string().required('Required'),
});

const loginSSO2FASchema = Yup.object().shape({
  google_credential: Yup.string().required('Required'),
  totp_token: Yup.string()
    .trim()
    .min(6, 'Exactly 6 digits')
    .max(6, 'Exactly 6 digits')
    .required('Required'),
});

const initialResetPasswordValue = {
  password: '',
  confirmPassword: '',
  totp_token: '',
};

const initialLoginSSOValue = {
  google_credential: '',
  totp_token: '',
};

const ResetPassword = (props: ResetPasswordProps) => {
  const location = useLocation();
  const { login, login2FA, resetPassword } = useAuth();
  const [userHasNeverLoggedIn, setUserHasNeverLoggedIn] = useState(false);
  const [companyRequires2FA, setCompanyRequires2FA] = useState(false);
  const [userRequires2FA, setUserRequires2FA] = useState(false);
  const [companyRequiresSSO, setCompanyRequiresSSO] = useState(false);
  const [totpData, setTotpData] = useState({ totp_url: '', totp_secret: '' });
  const [isOpenResetPassword2FA, setIsOpenResetPassword2FA] = useState(false);
  const [isOpenLoginSSO2FA, setIsOpenLoginSSO2FA] = useState(false);
  const [initialResetPassword2FAValues, setInitialResetPassword2FAValues] =
    useState<IResetPassword2FA>(initialResetPasswordValue);
  const [initialLoginSSO2FAValues, setInitialLoginSSO2FAValues] =
    useState<ILoginSSO2FA>(initialLoginSSOValue);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [saving2FA, setSaving2FA] = useState(false);
  const [error, setError] = useState('');
  const { id } = props.match.params;
  const isInvitedUser = qs.parse(location.search)?.invited_user === 'true';
  const isSetPassword = location.pathname.startsWith('/set-password');

  const getLoginUrl = (isSetPassword: any, isInvitedUser: any) => (userProfile: UserProfile) => {
    let pathname = '/login';
    if (isSetPassword) {
      if (isInvitedUser) {
        pathname = '/getting-started/invited-user';
      } else {
        pathname = '/select-warehouse';
      }
    }

    return url.format({
      pathname,
      ...(!isSetPassword &&
        !isInvitedUser && {
          query: {
            reset: true,
          },
        }),
    });
  };

  // This will not matter until loading === false, where we will have a user data from api
  // If a user has never logged in but their company requires 2fa, we need to show them a QR code so they can set up 2fa when they set their password
  //   Otherwise, we just want to ask for a totp token without fetching/showing their totp secret
  let userNeeds2FASetup = userHasNeverLoggedIn && companyRequires2FA;
  let userRequires2FAToken = !userHasNeverLoggedIn && (userRequires2FA || companyRequires2FA);

  // Fetching the user data (and maybe totp_data) tells us if the user if setting up their account,
  //     or just resetting their password, based on if the user has logged in before.
  // We also will receive totp_data if the user is setting up their account and their company requires 2FA,
  //     which the user needs to set up 2FA and send a valid totp_token with their new password.
  useEffect(() => {
    const api = new API();
    api
      .post('api/get_setup_user_and_totp_data', { reset_token: id })
      .then((response) => {
        setTotpData(response.data.totp_data);
        setUserHasNeverLoggedIn(response.data.user_has_never_logged_in);
        setCompanyRequires2FA(response.data.company_requires_2fa);
        setUserRequires2FA(response.data.user_requires_2fa);
        setCompanyRequiresSSO(response.data.company_requires_sso);
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          setError(e.response.data.error);
        } else {
          setError('There was a problem loading your user.');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <CenteredSpinner containerMinHeight="60vh" />;
  }

  const close2FAModal = () => {
    analytics.track('ResetPassword Close2FA');
    setIsOpenResetPassword2FA(false);
    setIsOpenLoginSSO2FA(false);
  };

  const handleFormSubmitResetPassword = (values: IResetPassword) => {
    if (userNeeds2FASetup || userRequires2FAToken) {
      setInitialResetPassword2FAValues({ ...resetPasswordSchema.cast(values), totp_token: '' });
      setIsOpenResetPassword2FA(true);
    } else {
      const postData: any = { reset_token: id, ...resetPasswordSchema.cast(values) };
      // don't send confirmPassword field to BE, this if FE validation
      delete postData.confirmPassword;
      setSaving(true);
      setError('');
      resetPassword(postData, getLoginUrl(isSetPassword, isInvitedUser))
        .then(() => {
          analytics.track('ResetPassword Reset');
        })
        .catch((e) => {
          if (e.response?.data?.error) {
            setError(e.response.data.error);
          } else {
            setError('Your password could not be reset at this time.');
          }
        })
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const handleForm2FASubmitResetPassword = (values: any) => {
    const postData: any = { reset_token: id, ...resetPasswordSchema.cast(values) };
    // don't send confirmPassword field to BE, this if FE validation
    delete postData.confirmPassword;
    setSaving2FA(true);
    setError('');
    resetPassword(postData, getLoginUrl(isSetPassword, isInvitedUser))
      .then(() => {
        analytics.track('ResetPassword Reset');
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          setError(e.response.data.error);
        } else {
          setError('Your password could not be reset at this time.');
        }
      })
      .finally(() => {
        setSaving2FA(false);
      });
  };

  const handleLogin = (form: ILoginSSO) => {
    setError('');
    setSaving(true);
    login(form)
      .catch((e) => {
        if (e.response?.data?.non_field_errors) {
          setError(e.response.data.non_field_errors);
        } else if (e.response?.data?.error) {
          setError(e.response.data.error);
        } else {
          setError('There was a problem logging into your account. Please try again later.');
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleFormSubmitLoginSSO = (values: any) => {
    const postData = loginSSOSchema.cast(values);
    handleLogin(postData);
  };

  const handleFormSubmitLoginSSO2FA = (values: any) => {
    const postData = loginSSO2FASchema.cast(values);
    setError('');
    setSaving2FA(true);
    login2FA(postData)
      .catch((e) => {
        if (e.response?.data?.non_field_errors) {
          setError(e.response.data.non_field_errors);
        } else if (e.response?.data?.error) {
          setError(e.response.data.error);
        } else {
          setError('There was a problem logging into your account. Please try again later.');
        }
        close2FAModal();
      })
      .finally(() => {
        setSaving2FA(false);
      });
  };

  const title = isSetPassword ? 'Set your password' : 'Reset your password';

  // If we require 2FA for either ResetPassword or Login2FA, we share a model and dynamically render the formik props
  const twoFAModal = (
    <Modal onClose={close2FAModal} header="2-Factor Authentication">
      <Formik
        initialValues={isOpenResetPassword2FA ? initialResetPassword2FAValues : initialLoginSSO2FAValues}
        onSubmit={
          isOpenResetPassword2FA ? handleForm2FASubmitResetPassword : handleFormSubmitLoginSSO2FA
        }
        validationSchema={isOpenResetPassword2FA ? resetPassword2FASchema : loginSSO2FASchema}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="py-6 px-10">
              {userNeeds2FASetup ? (
                <div className="mt-4 f-col items-center">
                  <p>
                    Please register with a 2FA mobile app (Google Authenticator or similar) and submit a
                    2FA token to finish enabling 2FA.
                  </p>
                  <QRCodeComponent totp_secret={totpData.totp_secret} totp_url={totpData.totp_url} />
                  <TextFormikGroup name="totp_token" type="text" groupClass="mt-4 w-full" />
                </div>
              ) : null}
              {userRequires2FAToken && (
                <div className="mt-4 f-col items-center">
                  <p>Please input the token from your authentication app on your phone.</p>
                  <TextFormikGroup name="totp_token" type="text" groupClass="mt-4 w-full" />
                </div>
              )}
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <Button variant="lightDanger" onClick={close2FAModal}>
                  Close
                </Button>
                <Button
                  autoFocus
                  type="submit"
                  variant="lightAction"
                  spinning={saving2FA}
                  className="ml-4"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );

  return (
    <LoggedOutFormBackgroundLayout>
      <div className="w-full h-full flex overflow-auto p-4">
        <div className="w-[444px] m-auto">
          <Helmet>
            <title>{title} | Mozart Data</title>
            <meta name="description" content={`${title}.`} />
          </Helmet>
          {(isOpenResetPassword2FA || isOpenLoginSSO2FA) && twoFAModal}
          <Formik
            initialValues={initialResetPasswordValue}
            onSubmit={(values) => handleFormSubmitResetPassword(values)}
            validationSchema={resetPasswordSchema}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} noValidate>
                <img className="h-[28px] mb-10" src="/images/logos/MozartDataLogoPurple.svg" alt="" />
                <FormLayoutTitleRow
                  title={isSetPassword ? 'Set Your Password' : 'Password Reset'}
                  linkText="Log In"
                  linkTo="/login"
                  linkDataTrack="ResetPassword Login"
                />

                {!companyRequiresSSO && (
                  <>
                    <TextFormikGroup
                      autoFocus
                      type="password"
                      name="password"
                      placeholder="At least 12 characters"
                      label={userHasNeverLoggedIn ? 'Password' : 'New Password'}
                      groupClass="mt-4"
                    />
                    <TextFormikGroup
                      type="password"
                      name="confirmPassword"
                      label={userHasNeverLoggedIn ? 'Confirm Password' : 'Confirm New Password'}
                      groupClass="mt-4"
                    />
                  </>
                )}
                {error && (
                  <Alert variant="error" className="mt-4">
                    {error}
                  </Alert>
                )}
                {!companyRequiresSSO && (
                  <Button type="submit" variant="lightAction" className="w-[50%] mt-4" spinning={saving}>
                    {userHasNeverLoggedIn ? 'Submit' : 'Reset'}
                  </Button>
                )}
                <div className="flex-col mt-4">
                  {!companyRequiresSSO && <div className="w-full text-center my-2 text-base">or</div>}
                  {companyRequiresSSO && (
                    <div className="f-center my-4">Your company requires that you login with SSO.</div>
                  )}
                  <div className="f-center w-full">
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        const form = {
                          google_credential: credentialResponse['credential'],
                        } as ILoginSSO;
                        if (form['google_credential'] === undefined) {
                          setError('An error occured when attempting to login using Google SSO');
                        } else if (userNeeds2FASetup || userRequires2FAToken) {
                          setInitialLoginSSO2FAValues({ ...form, totp_token: '' });
                          setIsOpenLoginSSO2FA(true);
                        } else {
                          handleFormSubmitLoginSSO(form);
                        }
                      }}
                      onError={() => {
                        setError('An error occured when attempting to login using Google SSO');
                      }}
                      width={222}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </LoggedOutFormBackgroundLayout>
  );
};

export default ResetPassword;
