import { useEffect, useState } from 'react';

import API from 'api/API';
import { AggTable, TransformUserProfile } from 'api/APITypes';
import { useUserProfile } from 'context/AuthContext';
import { patch } from 'utils/Array';

import SyncTab from './SyncTab';

export interface Worksheet {
  id: number;
  name: string;
  charts: any[];
}

export interface GSheetDestination {
  id: string;
  table: string; // table_id
  enabled: boolean;
  spreadsheet_id: string;
  spreadsheet_title: string;
  worksheet_id: number;
  // Note, the UI calls these `tabs` and Google calls these `sheet_title`.
  // We chose `worksheet_name` to reduce confusion with `spreadsheet_title`.
  worksheet_name: string;
  last_edited_by_user_profile: TransformUserProfile;
  worksheets: Worksheet[];
  order_by: string;
  last_synced_at: string | null;
  last_sync_status: 'success' | 'failure' | null;
  last_sync_error_message: string | null;
}

export type NewGSheetDestination = Omit<
  GSheetDestination,
  | 'id'
  | 'last_edited_by_user_profile'
  | 'worksheet_id'
  | 'worksheets'
  | 'last_synced_at'
  | 'last_sync_status'
  | 'last_sync_error_message'
>;

interface SyncTabProps {
  table: AggTable;
}

export default function SyncTabController(props: SyncTabProps) {
  const { table } = props;
  const [existingDestinations, setExistingDestinations] = useState<GSheetDestination[]>([]);
  const [formDestination, setFormDestination] = useState<NewGSheetDestination | null>(null);
  const [loading, setLoading] = useState(false); // The gsheetDestinations list is loading
  const [error, setError] = useState('');
  const {
    userProfile: { company_role },
  } = useUserProfile();

  // Load gsheet destinations on page load
  useEffect(() => {
    setLoading(true);
    const api = new API();
    api
      .get(`/api/gsheet_destinations?table_id=${table.id}`)
      .then((response) => {
        setExistingDestinations(response.data);
      })
      .catch((e) => {
        setError("There was a problem fetching this tranform's google sheet destinations.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [table.id]);

  const openAddModal = () => {
    const newDestination = {
      table: table.id,
      enabled: true,
      spreadsheet_id: '',
      spreadsheet_title: '',
      worksheet_name: table.name,
      order_by: '',
    };
    setFormDestination(newDestination);
    analytics.track('SyncTab OpenAddModal');
  };

  const setDestinationsAndCloseModal = (newDestinations: GSheetDestination[]) => {
    setExistingDestinations(newDestinations);
    setFormDestination(null);
  };

  const deleteDestination = (destination: GSheetDestination) => {
    const api = new API();
    return api
      .delete(`/api/gsheet_destinations/${destination.id}`, {})
      .then(() => {
        const newDestinations = existingDestinations.filter((d) => d.id !== destination.id);
        setExistingDestinations(newDestinations);
        analytics.track('SyncTab DeleteDestination');
      })
      .catch((e) => {
        setError('There was a problem deleting this google sheet destination.');
        return Promise.reject(e);
      });
  };

  const toggleDestination = (destination: GSheetDestination) => {
    destination.enabled = !destination.enabled;
    const api = new API();
    const newValues = {
      enabled: destination.enabled,
    };
    return api
      .patch(`/api/gsheet_destinations/${destination.id}`, newValues)
      .then((response) => response.data as GSheetDestination)
      .then((newGSheetDestination) => {
        const newDestinations = patch(
          existingDestinations,
          newGSheetDestination,
          (d) => d.id === destination.id,
        );
        setExistingDestinations(newDestinations);
        analytics.track(newValues.enabled ? 'SyncTab EnableDestination' : 'SyncTab DisableDestination');
        return newGSheetDestination;
      })
      .catch((e) => {
        setError('There was a problem updating this google sheet destination.');
        return Promise.reject(e);
      });
  };

  const openEditModal = (destination: GSheetDestination) => {
    setFormDestination(destination);
    analytics.track('SyncTab OpenEditModal');
  };

  const cancelEditModal = () => {
    setFormDestination(null);
    analytics.track('SyncTab CloseEditModal');
  };

  return SyncTab({
    table,
    loading,
    error,
    companyRole: company_role,
    existingDestinations,
    formDestination,
    openAddModal,
    openEditModal,
    toggleDestination,
    deleteDestination,
    setDestinationsAndCloseModal,
    cancelEditModal,
  });
}
