/*
  Wraps RadioInputGroup and injects the Formik hook props into the input group.
*/
import React from 'react';

import { useField } from 'formik';

import RadioInputGroup, {
  RadioInputGroupProps,
} from 'components/inputs/group/RadioInputGroup/RadioInputGroup';

export type { RadioInputOption } from 'components/inputs/group/RadioInputGroup/RadioInputGroup';

// We get value from Formik
export type SanitizedRadioInputGroupProps = Omit<RadioInputGroupProps, 'value'>;

export interface RadioFormikGroupProps extends SanitizedRadioInputGroupProps {}
RadioFormikGroup.displayName = 'RadioFormikGroup';
export default function RadioFormikGroup(props: RadioFormikGroupProps) {
  const { name, disabled, ...rest } = props;
  const [field, meta] = useField(name);
  const { name: fieldName, value: fieldValue, ...fieldRest } = field;

  return (
    <RadioInputGroup
      name={name}
      disabled={disabled}
      value={meta.value}
      error={meta.touched && meta.error ? meta.error : ''}
      {...fieldRest}
      {...rest}
    />
  );
}
