import React from 'react';

import cn from 'classnames';

interface LabeledHorizontalRuleProps {
  label: string;
  /**
   * Themes:
   * - `primary`: purple background
   * - `default`: gray background
   */
  theme?: 'primary' | 'default';
  className?: string;
}

export default function LabeledHorizontalRule(props: LabeledHorizontalRuleProps) {
  const { label, theme = 'default', className } = props;
  const THEMES = {
    primary: 'bg-sec-purple-50 text-purple',
    default: 'bg-pri-gray-100',
  };

  return (
    <div className={cn('w-full f-row-y-center ', className)}>
      <div
        className={cn(
          'w-[200px] h-6 f-center text-base font-medium whitespace-nowrap rounded-full',
          THEMES[theme],
        )}
      >
        {label}
      </div>
      <div className="h-[1px] ml-2 bg-pri-gray-200" style={{ width: 'calc(100% - 208px' }}></div>
    </div>
  );
}
