import React from 'react';

import IconButton from 'components/inputs/basic/Button/IconButton';

import AIDisclaimerInfoIcon from './AIDisclaimerInfoIcon';

interface AITableDescriptionButtonProps {
  onClick: () => void;
  className?: string;
}

const AITableDescriptionButton = (props: AITableDescriptionButtonProps) => {
  const { onClick, className } = props;

  return (
    <div className="f-row-y-center">
      <IconButton
        text="Generate Description"
        icon="Stars"
        onClick={onClick}
        size="small"
        variant="lightDullTransparent"
        className={className}
      />
      <AIDisclaimerInfoIcon />
    </div>
  );
};

export default AITableDescriptionButton;
