import { useCallback, useEffect, useMemo } from 'react';

import {
  nextTourStep,
  previousTourStep,
} from 'components/page_parts/OnboardingPopoverTour/OnboardingPopoverTourSteps';
import { useUserProfile, useDatabaseAccount } from 'context/AuthContext';
import { useBooleanFlag } from 'hooks/useFeatureFlags';

import {
  OnboardingPopoverTourContextInterface,
  OnboardingPopoverTourStep,
} from './OnboardingPopoverTourContext';
import useOnboardingPopoverTourState from './useOnboardingPopoverTourState';
import { UserPreferencesContextInterface } from './UserPreferencesContext';

export default function useOnboardingPopoverTourContext(
  userPreferencesContext: UserPreferencesContextInterface,
) {
  const { loaded, setLoaded, currentStep, setCurrentStep, refs } =
    useOnboardingPopoverTourState('never_started');

  const enablePopoverTour = useBooleanFlag('popover_tour');
  const { userProfile } = useUserProfile();
  const databaseType = useDatabaseAccount().type;
  const { initialLoadComplete, userPreferences, updateUserPreferences } = userPreferencesContext;
  const isViewer = userProfile.company_role === 'viewer';

  const apiSetCurrentStep = useCallback(
    (nextStep: OnboardingPopoverTourStep) => {
      // Set the local step immediately so the user can quickly go through
      // the tutorial without waiting for the API to round trip.
      setCurrentStep(nextStep);

      // Update the API.
      // Do not wait for it to return.
      // Assume things will go fine and ignore error logging.
      // If the API fails to save, they can skip the popover tour at later date when the API is working.
      // Ignore scenarios where internet lag might cause sequential calls to arrive out of order.
      updateUserPreferences({ currentPopoverTourStep: nextStep });
    },
    [setCurrentStep, updateUserPreferences],
  );

  const apiStartTour = useCallback(() => {
    apiSetCurrentStep('connectors');
  }, [apiSetCurrentStep]);

  // When the app boots load the current popover tour step from the API.
  useEffect(() => {
    if (enablePopoverTour && initialLoadComplete && !loaded) {
      // Initialize the local currentStep with the API value.
      const currentPopoverTourStep = userPreferences.currentPopoverTourStep || 'never_started';
      setCurrentStep(currentPopoverTourStep);

      // If the user has not started the popover tour
      // AND their userProfile was created after the popover tour feature,
      // start the tour.
      const popoverTourLaunchedOn = new Date('Thu Jan 18 2024');
      const userCreatedAt = new Date(userProfile.created_at);
      if (currentPopoverTourStep === 'never_started' && userCreatedAt > popoverTourLaunchedOn) {
        apiStartTour();
      }

      setLoaded(true);
    }
  }, [
    enablePopoverTour,
    initialLoadComplete,
    loaded,
    userPreferences,
    userProfile.created_at,
    setCurrentStep,
    apiStartTour,
    setLoaded,
  ]);

  const handleNext = useCallback(() => {
    apiSetCurrentStep(nextTourStep(isViewer, currentStep));
    analytics.track('PopoverTour Next', {
      currentStep,
    });
  }, [isViewer, currentStep, apiSetCurrentStep]);

  const handlePrevious = useCallback(() => {
    apiSetCurrentStep(previousTourStep(isViewer, currentStep));
    analytics.track('PopoverTour Previous', {
      currentStep,
    });
  }, [isViewer, currentStep, apiSetCurrentStep]);

  const handleSkip = useCallback(() => {
    apiSetCurrentStep('complete');
    analytics.track('PopoverTour Skip', {
      currentStep,
    });
  }, [currentStep, apiSetCurrentStep]);

  const handleRestart = useCallback(
    (segmentEvent: string) => {
      apiStartTour();
      analytics.track(segmentEvent);
    },
    [apiStartTour],
  );

  // This file memoizes the context it returns to reduce rerenders further down the tree.
  const context: OnboardingPopoverTourContextInterface = useMemo(
    () => ({
      loaded,
      currentStep: databaseType !== 'bigquery' ? currentStep : 'complete',
      onNext: handleNext,
      onPrevious: handlePrevious,
      onSkip: handleSkip,
      onRestart: handleRestart,
      refs,
    }),
    [loaded, currentStep, handleNext, handlePrevious, handleSkip, handleRestart, refs, databaseType],
  );

  // Add some hacking tools to the developer console.
  // @ts-ignore
  window.optContext = {
    // Give full access to the context
    ...context,
    // Also attach some debugging tools
    apiSetCurrentStep,
    apiStartTour,
    // Run this then refresh the browser
    apiResetTour: () => updateUserPreferences({ currentPopoverTourStep: 'never_started' }),
  };

  return context;
}
