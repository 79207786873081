import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function DeleteFivetranDeletedInfoIcon() {
  const content = (
    <div>
      <div>
        <strong>Remove rows from tables where _fivetran_deleted = True.</strong>
      </div>
      <div className="mt-2">
        A nightly job will search through tables in your warehouse (excluding Transform and Snapshot
        tables), and delete all rows where _fivetran_deleted = True if that column exists in the table.
      </div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} containerClass="ml-1" popoverProps={popoverProps} />;
}
