// This tab uses a series of useMemos or variable definitions to build a data pipeline.
// The end result is the datasets used for the chart and the table, which are not the same
import React, { useState, useMemo, useCallback } from 'react';

import _ from 'lodash';

import { formatApiDate } from 'utils/dateTime';

import TabView from '../TabView';
import { ReportTypes } from '../Usage';
import { ConnectorDailyRow, ConnectorMonthlyRow, CompanyPlanRow, UsagePostData } from '../useUsageFetch';
import {
  getChartFormattedData,
  getDataAggregated,
  getSortedDatasetLabels,
  getBudgetDataset,
  getDataGrouped,
  getDatasets,
  getCurrentMonthValue,
  getLabels,
} from '../Utils';

interface ConnectorTabProps {
  connectorData: ConnectorDailyRow[];
  companyPlanByMonth: CompanyPlanRow[] | null;
  currentMonthPlan: CompanyPlanRow | null;
  reportType: ReportTypes;
  today: Date;
  startDate: Date;
  setReportType: (newReportType: ReportTypes) => void;
  safeFetchAndFormatData: (url: string, postData: UsagePostData) => Promise<any>;
  setError: (newError: string) => void;
}

interface ConnectorTableRow {
  SCHEMA_NAME: string;
  TABLE_NAME: string;
  MAR: number;
}

// This component uses a series of useMemos to filter and group the data
export default function ConnectorTab(props: ConnectorTabProps) {
  const {
    connectorData,
    companyPlanByMonth,
    currentMonthPlan,
    reportType,
    today,
    startDate,
    setReportType,
    safeFetchAndFormatData,
    setError,
  } = props;
  const [selectedConnectorData, setSelectedConnectorData] = useState<ConnectorTableRow[] | undefined>();
  const [selectedConnector, setSelectedConnector] = useState<string | null>(null);
  const [loadingSelectedConnectorData, setLoadingSelectedConnectorData] = useState(false);

  const useSelectedConnectorData = selectedConnector !== null && selectedConnectorData !== undefined;

  const connectorNameToIDMap = useMemo(() => {
    let map: { [key: string]: string } = {};
    _.uniqBy(connectorData, 'CONNECTOR_NAME').forEach((r) => {
      map[r.CONNECTOR_NAME] = r.CONNECTOR_ID;
    });
    return map;
  }, [connectorData]);

  // Most of the data we need is already passed in by useUsageFetch, but when a user clicks on
  //   a specific app_querying in the table (right now only Fivetran works in the BE), we
  //   request the top 15 queries by compute for the date range they are currently viewing.
  const fetchSelectedConnectorData = useCallback(
    (connectorID: string) => {
      // Fetches the top 15 queries for a given connector for the given date range
      setLoadingSelectedConnectorData(true);
      safeFetchAndFormatData(`/api/usages/${connectorID}/connector_table_usage`, {
        start_date: formatApiDate(startDate),
        end_date: formatApiDate(today),
      })
        .then((formattedData) => {
          setSelectedConnectorData(formattedData);
        })
        .finally(() => {
          setLoadingSelectedConnectorData(false);
        });
    },
    [today, startDate, safeFetchAndFormatData],
  );

  const chartFormattedData = useMemo(() => {
    return getChartFormattedData(
      connectorData,
      'CONNECTOR_NAME',
      'MEASURED_DATE',
      selectedConnector,
      startDate,
      today,
      reportType,
      ['MEASURED_MONTH', 'CONNECTOR_NAME'],
    );
  }, [connectorData, selectedConnector, reportType, today, startDate]);

  const dataGroupedByConnector = useMemo(() => {
    return getDataAggregated(chartFormattedData, ['CONNECTOR_NAME']);
  }, [chartFormattedData]);

  // If we have selected a connector and received selectedConnectorData, show that data in the table
  // Otherwise, group by connector for display in the table
  const tableFormattedData = useMemo(() => {
    if (useSelectedConnectorData) {
      return selectedConnectorData.map((row) => {
        return {
          TABLE: row.SCHEMA_NAME + '.' + row.TABLE_NAME,
          MAR: Math.round(row.MAR * 100) / 100,
        };
      });
    } else {
      return dataGroupedByConnector.map((row) => {
        return {
          'CONNECTOR NAME': row.CONNECTOR_NAME,
          MAR: row.MAR,
        };
      });
    }
  }, [useSelectedConnectorData, selectedConnectorData, dataGroupedByConnector]);

  const connectorTypes = useMemo(() => {
    return getSortedDatasetLabels(chartFormattedData, dataGroupedByConnector, 'CONNECTOR_NAME', 'MAR');
  }, [chartFormattedData, dataGroupedByConnector]);

  const budgetDataset = useMemo(() => {
    return getBudgetDataset(companyPlanByMonth, 'SUM_FIVETRAN_MAR_ALLOTMENT', today);
  }, [companyPlanByMonth, today]);

  const chartDataGroupedByDate: { [date: string]: ConnectorDailyRow[] | ConnectorMonthlyRow[] } =
    useMemo(() => {
      const groupByColumn = reportType === 'lastYearMonthly' ? 'MEASURED_MONTH' : 'MEASURED_DATE';
      return getDataGrouped(chartFormattedData, groupByColumn);
    }, [chartFormattedData, reportType]);

  const datasets = useMemo(() => {
    let newDatasets = getDatasets(connectorTypes, chartDataGroupedByDate, 'CONNECTOR_NAME', 'MAR');
    if (reportType === 'lastYearMonthly' && selectedConnector === null && budgetDataset) {
      newDatasets.unshift(budgetDataset);
    }
    return newDatasets;
  }, [chartDataGroupedByDate, reportType, budgetDataset, connectorTypes, selectedConnector]);

  const labels = useMemo(() => {
    return getLabels(chartDataGroupedByDate, companyPlanByMonth);
  }, [chartDataGroupedByDate, companyPlanByMonth]);

  const currentMonthMar = useMemo(() => {
    return getCurrentMonthValue(connectorData, today, 'MEASURED_DATE', 'MAR');
  }, [connectorData, today]);

  const handleSetReportType = (newReportType: ReportTypes) => {
    setError('');
    if (newReportType !== reportType && selectedConnector !== null) {
      fetchSelectedConnectorData(connectorNameToIDMap[selectedConnector]);
    }
    setReportType(newReportType);
    analytics.track('Usage SetReportType', { type: newReportType, tab: 'Connector' });
  };

  const handleSelectConnector = (connector: string | null) => {
    setError('');
    setSelectedConnector(connector);
    if (connector !== null) {
      fetchSelectedConnectorData(connectorNameToIDMap[connector]);
    }
    analytics.track('Usage SelectConnector', { connector: connector, tab: 'Connector' });
  };

  return (
    <TabView
      loadingTable={loadingSelectedConnectorData}
      tableData={tableFormattedData}
      datasets={datasets}
      labels={labels}
      startDate={startDate}
      today={today}
      selectedFilter={selectedConnector}
      reportType={reportType}
      currentMonthPlan={currentMonthPlan}
      currentMonthValue={currentMonthMar}
      onSetReportType={handleSetReportType}
      onSelectFilter={handleSelectConnector}
      onRowClick={
        useSelectedConnectorData
          ? undefined
          : (row: any) => {
              handleSelectConnector(row['CONNECTOR NAME']);
            }
      }
      tab="connectors"
    />
  );
}
