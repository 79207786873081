import { memo } from 'react';

import { ExclamationCircleFill as ErrorIcon } from 'react-bootstrap-icons';

import cn from 'classnames';

import IconButton from 'components/inputs/basic/Button/IconButton';
import { CenteredMozartSpinnerIcon } from 'components/primitives/icons/MozartSpinnerIcon/MozartSpinnerIcon';

import { FlowchartVertex, SourceTable } from '../../model/FlowchartQueryModel';

import {
  BORDER_WIDTH,
  BORDER_RADIUS,
  DEFAULT_BORDER_COLOR,
  FONT_SIZE, // ICON_SIZE,
  LABEL_FONT_WEIGHT, // LEGEND_FONT_SIZE,
  LINE_HEIGHT,
  SELECTED_BORDER_WIDTH, // SUBLABEL_FONT_WEIGHT,
  VERTEX_HEIGHT,
  getWidth,
  getIcon,
} from '../Flowchart.styles';
import { getVertexStyle } from '../utils/graphUtils';

import Socket from './Socket';

import s from './FlowchartVertex.module.css';

export interface VertexData {
  id: string;
  vertex: FlowchartVertex;
  isLoading?: boolean;
  isSelected?: boolean;
  vertexHeight?: number;
  className?: string;
}

export type FlowchartVertexElementProps = VertexData;

const FlowchartVertexElement = (props: FlowchartVertexElementProps) => {
  const {
    vertex,
    isLoading = false,
    isSelected = false,
    vertexHeight = VERTEX_HEIGHT,
    className = '',
  } = props;

  const style = getVertexStyle();
  const { bgColor, color, selectedBorderColor } = style;
  const borderColor = isSelected ? selectedBorderColor : DEFAULT_BORDER_COLOR;

  // Print the first error on the vertex
  let errorIcon = null;
  const errors = vertex.validateFlat();
  if (errors) {
    errorIcon = (
      <ErrorIcon
        color="var(--pri-error-600)"
        size={16}
        className={cn('absolute top-[2px] right-[2px]', s.errorIcon)}
        id={getErrorIconID(vertex.id)}
      />
    );
  }

  const myTable = (vertex as SourceTable).table || null;
  const label = myTable ? myTable.name : vertex.prettyType();

  const textContent = (
    <div
      style={{
        fontWeight: LABEL_FONT_WEIGHT,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // Ideally, we'd word wrap and truncate with an ellipsis.
        // Word wrap disables the ellipsis because CSS isn't smart enough.
        // It's more important that we word wrap.
        wordWrap: 'break-word',
      }}
    >
      {label}
    </div>
  );

  const width = `${getWidth(vertex)}px`;
  const cursor = 'pointer';
  const borderWidth = isSelected ? SELECTED_BORDER_WIDTH : BORDER_WIDTH;

  const textStyle = {
    color,
    fontSize: FONT_SIZE,
    fontFamily: 'Roboto, sans-serif',
    lineHeight: LINE_HEIGHT,
  };

  return (
    <div
      id={`vertex-${vertex.id}`}
      className={cn('p-1 relative', s.flowchartVertex, className)}
      style={{
        width,
        height: `${vertexHeight}px`,
        border: `${borderWidth}px solid ${borderColor}`,
        borderRadius: `${BORDER_RADIUS}px`,
        backgroundColor: bgColor,
        cursor,
      }}
      draggable={true}
    >
      <div className="h-full f-row-y-center">
        <div className="p-2">{getIcon(vertex, color)}</div>
        {isLoading && <CenteredMozartSpinnerIcon spinning={true} className="mr-2" />}
        {!isLoading && (
          <div
            style={{
              ...textStyle,
              width: '100%',
              height: '100%',
              paddingRight: '8px',
              flex: '1 1',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            {textContent}
          </div>
        )}
        {!isLoading && vertex.canEdit() && (
          <div className={cn('absolute right-0 bottom-0', s.hoverButtonDiv)}>
            <IconButton
              id={`editButton-${vertex.id}`}
              icon="PencilSquare"
              size="small"
              variant="lightDullTransparent"
            />
          </div>
        )}
      </div>
      {vertex.isMonoParent() && <Socket type="single" plugged={vertex.isSingleParentPlugged()} />}
      {vertex.isBiParent() && <Socket type="left" plugged={vertex.isLeftParentPlugged()} />}
      {vertex.isBiParent() && <Socket type="right" plugged={vertex.isRightParentPlugged()} />}
      <Socket type="child" plugged={vertex.isChildPlugged()} />
      {!isLoading && errorIcon}
    </div>
  );
};

export default memo(FlowchartVertexElement);

export function getErrorIconID(vertexID: string) {
  return `errorIcon-${vertexID}`;
}
