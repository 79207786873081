import { ReactNode } from 'react';

import { integrationMap, Integration } from 'pages/Integrations/Integration';

import ExternalLink from '../ExternalLink';
import { Field } from '../fieldUtils';

// The data required to render DefaultIntegration.tsx
export interface DefaultIntegrationLayoutData {
  url: string;
  steps: (string | ReactNode)[];
  bigQuerySteps?: (string | ReactNode)[];
  fields: Field[];
  bigQueryFields?: Field[];
  additionalFields?: Field[];
  additionalFieldsHeader?: string;
}

export type DefaultIntegrationLayoutDataMap = {
  [key: string]: DefaultIntegrationLayoutData;
};

export interface DefaultIntegration extends Integration, DefaultIntegrationLayoutData {}

export type DefaultIntegrationMap = {
  [key: string]: DefaultIntegration;
};

const STANDARD_CREDENTIAL_SENTENCE =
  'Copy the credentials below into the form. If you do not have a Snowflake username and password you can click the "Generate Snowflake User" button below to generate them, or use credentials you already have.';

const STANDARD_BIGQUERY_CREDENTIAL_SENTENCE = (
  <>
    You can reuse the service account JSON file that you used to connect BigQuery to Mozart Data, or make
    a new service account or key via the instructions{' '}
    <ExternalLink href="https://help.mozartdata.com/docs/bigquery-mozart-data">here</ExternalLink>.
  </>
);

/**
 * Mapping to integration platform that describe steps to connect warehouse to integration.
 *
 * Key.url = url to link out to integration platform
 * Key.steps = instructions on integration platform on how to get to page to input credentials
 * Key.fields = Name and value pair
 **/
const defaultIntegrationLayoutDataMap: DefaultIntegrationLayoutDataMap = {
  astrato: {
    url: 'https://astrato.io/', //'https://help.astrato.io/en/articles/5161726-connecting-to-snowflake',
    steps: [
      'Click on “Data” in the sidebar menu in Astrato.',
      'Go into the “Data Connection” tab and click on the “New Connection” button.',
      'Select Snowflake from the list of options.',
      'Copy the credential below into the form. If you do not have a Snowflake username and password dedicated to Astrato, click the “Generate Snowflake User” button to generate one with the MOZART_VIEWER role.',
      'Click “Test connection” and proceed to the next screen.',
      'Select the same database name as below to connect to Astrato. Then, create a generic data source name.',
      'Set the visibility setting for each Astrato user in your organization.',
      'Click “Connect” to begin connecting your data warehouse to Astrato.',
    ],
    fields: [
      { uiName: 'Snowflake Account Name', key: 'account_name' },
      { uiName: 'Query Execution Warehouse', key: 'warehouse' },
      { uiName: 'Database', key: 'database' },
    ],
  },

  census: {
    url: 'https://app.getcensus.com/', //'https://docs.getcensus.com/sources/snowflake',
    steps: [
      'On the lefthand side select Sources.',
      'Click New Source.',
      'Select Snowflake.',
      'Select Sync Engine = Basic.',
      STANDARD_CREDENTIAL_SENTENCE,
      'After clicking Connect ("Configure Advanced Options" not required), navigate to the help page https://docs.getcensus.com/sources/snowflake, copy the script, and run it in Snowflake (not Mozart).',
      'Test connection and Finish.',
    ],
    fields: [
      { uiName: 'Snowflake Account Name', key: 'account_name' },
      { uiName: 'Query Execution Warehouse', key: 'warehouse' },
    ],
  },

  // Excel instructions at: https://interworks.com/blog/hcalder/2019/01/30/query-snowflake-with-excel/
  excel: {
    url: 'https://www.microsoft.com/en-us/microsoft-365/excel',
    steps: [
      'Open Excel',
      'Go to File > Account > About Excel and note if you are using the 32-bit or 64-bit version.',
      'Download and install the correct ODBC driver from https://sfc-repo.snowflakecomputing.com/odbc/index.html',
      'On Windows, open the "ODBC Data Source Administrator".',
      'Click on the "User DSN" tab.',
      'Click the "Add" button.',
      'Select the "SnowflakeDSIIDriver" and click "Finish".',
      'Fill in form with the values below and click "OK".',
      'Go back to Excel.',
      'Select the "Data" tab.',
      'Click "Get Data > From Other Sources > From ODBC".',
      'Select "mozart" and click "OK".',
      'A window will open asking you for a username and password. If you do not have Snowflake User credentials you can click the "Generate Snowflake User" button below to generate them, or use credentials you already have. When you have submitted a username and password click "Connect".',
      'Select the table you want to import from the list on the left.',
      'Click the "Load" button.',
    ],
    fields: [
      { uiName: 'Data Source', key: 'mozart' },
      { uiName: 'Server', key: 'host' },
      { uiName: 'Database', key: 'database' },
      { uiName: 'Warehouse', key: 'warehouse' },
    ],
  },
  explo: {
    url: 'https://explo.co/',
    steps: [
      "If you've never connected a database to Explo before a form will open automatically asking you to connect a database. Otherwise, navigate to connect a new database.",
      'For "Nickname", enter "mozart" or anything else you like.',
      'For "Type", select "Snowflake".',
      STANDARD_CREDENTIAL_SENTENCE,
      'Click "Connect".',
    ],
    fields: [
      { uiName: 'Account', key: 'account_name' },
      { uiName: 'Database', key: 'database' },
    ],
  },
  hightouch: {
    url: 'https://app.hightouch.io/',
    steps: [
      'In the lefthand menu, click "Sources"',
      'Click "Create Source".',
      'Select "Snowflake".',
      'Click "Continue".',
      STANDARD_CREDENTIAL_SENTENCE,
      'Click the "Test Source" button below the form.',
      'Click "Continue".',
      'For "Name", enter "mozart" or anything else you like.',
      'Click "Finish".',
    ],
    fields: [
      { uiName: 'Account', key: 'account_name' },
      { uiName: 'Warehouse', key: 'warehouse' },
      { uiName: 'Database', key: 'database' },
    ],
  },
  generic: {
    url: 'N/A',
    steps: [
      'In the account settings of most apps there is an option to "Add a Connection", "Add a Database", or something like that.',
      'Some combination of the following values should work for any app that can connect to Snowflake. Note, that apps tend to reorder or rename these things.',
    ],
    bigQuerySteps: [
      'In the account settings of most apps there is an option to "Add a Connection", "Add a Database", or something like that.',
      'Some combination of the following values should work for any app that can connect to BigQuery. Note, that apps tend to reorder or rename these things.',
      STANDARD_BIGQUERY_CREDENTIAL_SENTENCE,
    ],
    fields: [
      { uiName: 'Account', key: 'base_account_name' },
      { uiName: 'Warehouse', key: 'warehouse' },
      { uiName: 'Database', key: 'database' },
      { uiName: 'Host', key: 'host' },
    ],
    bigQueryFields: [
      { uiName: 'Project ID', key: 'project_id' },
      { uiName: 'Region', key: 'region' },
    ],
  },
  looker: {
    url: 'https://www.looker.com',
    steps: [
      'On the Looker page header, click the "Admin" menu.',
      'Scroll down to the "Database" section and click on "Connections".',
      'Click the "Add Connection" button.',
      'Then click "Database connection".',
      'Set "Name" to whatever you want.',
      'Set "Dialect" to "Snowflake".',
      STANDARD_CREDENTIAL_SENTENCE,
      'Click "Test These Settings".',
      'Click "Add Connection".',
    ],
    bigQuerySteps: [
      'On the Looker page header, click the "Admin" menu.',
      'Scroll down to the "Database" section and click on "Connections".',
      'Click the "Add Connection" button.',
      'Then click "Database connection".',
      'Set "Name" to whatever you want.',
      'Set "Dialect" to "Google BigQuery Standard SQL".',
      STANDARD_BIGQUERY_CREDENTIAL_SENTENCE,
      'Click "Test These Settings".',
      'Click "Add Connection".',
    ],
    fields: [
      { uiName: 'Remote Host', key: 'host' },
      { uiName: 'Database', key: 'upper.database' },
      { uiName: 'Schema', key: 'db_schema' },
      { uiName: 'Additional Params', key: 'looker_additional_params' },
    ],
    bigQueryFields: [
      { uiName: 'Billing Project ID', key: 'project_id' },
      { uiName: 'Dataset', key: 'looker_bigquery_dataset' }, // Doesn't actually matter but it's required
    ],
  },
  metabase: {
    url: 'https://www.metabase.com/',
    steps: [
      'Click on the settings gear in the top right corner.',
      'Select "Admin".',
      'On the leftside menu, select "Setup".',
      'Select "Add a database".',
      'Under "Database type", select "Snowflake".',
      'Copy the credentials below into the form. If you do not have a Snowflake username and password you can click the "Generate Snowflake User" button below to generate them, or use credentials you already have. Leave "Schema" blank.',
      'Click "Save".',
    ],
    fields: [
      { uiName: 'Account', key: 'account_name' },
      { uiName: 'Warehouse', key: 'upper.warehouse' },
      { uiName: 'Database name', key: 'upper.database' },
      { uiName: 'Region ID', key: 'region.platform' },
    ],
  },
  mode: {
    url: 'https://app.mode.com/',
    steps: [
      'On the left navigation bar, click on your account dropdown and select "Connect a Database".',
      'Select "Snowflake".',
      STANDARD_CREDENTIAL_SENTENCE,
    ],
    fields: [
      { uiName: 'Account URL/Host', key: 'host' },
      { uiName: 'Warehouse', key: 'warehouse' },
      { uiName: 'Database name', key: 'database' },
    ],
  },
  omni: {
    url: 'N/A', // They seem to have different urls for different customer instances
    steps: [
      'In the top navigation bar, click "Admin".',
      'Under "Connections", click "Add Connection".',
      STANDARD_CREDENTIAL_SENTENCE,
    ],
    fields: [
      { uiName: 'Account Identifier', key: 'account_name' },
      { uiName: 'Warehouse', key: 'warehouse' },
      { uiName: 'Database', key: 'database' },
    ],
  },
  popsql: {
    url: 'https://www.popsql.com/',
    steps: [
      'In the top right corner, click on your user name.',
      'Select "Manage Connections".',
      'Click "New Connection".',
      'Set the type to "Snowflake".',
      STANDARD_CREDENTIAL_SENTENCE,
    ],
    fields: [
      { uiName: 'SNOWFLAKE ACCOUNT', key: 'account_name' },
      { uiName: 'SNOWFLAKE WAREHOUSE', key: 'warehouse' },
      { uiName: 'DATABASE', key: 'database' },
    ],
  },
  powerbi: {
    url: 'https://powerbi.microsoft.com/en-us/',
    steps: [
      'Open Power BI Desktop.',
      'Open the "File" menu.',
      'Click "Get Data".',
      'Select "Get data to get started" to see more options.',
      'Type "Snowflake" in the search box.',
      'Select "Snowflake".',
      'Click "Connect".',
      'Copy "Server" and "Warehouse" from the credentials below into the form.',
      'Set "Data Connectivity Mode" to "DirectQuery".',
      'Click "OK".',
      'If you do not have a Snowflake username and password you can click the "Generate Snowflake User" button below to generate them, or use credentials you already have. Copy the "Username" and "Password" into the form.',
      'Click "Connect".',
    ],
    fields: [
      { uiName: 'Server', key: 'host' },
      { uiName: 'Warehouse', key: 'warehouse' },
    ],
  },
  redash: {
    url: 'https://app.redash.io',
    steps: [
      'Click on your user name in top right corner of the screen.',
      'Select "Data Sources".',
      'Click on the "New Data Source" button.',
      'Search for and select "Snowflake".',
      STANDARD_CREDENTIAL_SENTENCE,
      'Click "Create".',
      'Click "Test Connection".',
    ],
    fields: [
      { uiName: 'Account', key: 'account_name' },
      { uiName: 'Warehouse', key: 'warehouse' },
      { uiName: 'Database', key: 'database' },
      { uiName: 'Region', key: 'region.platform' },
    ],
  },
  retool: {
    url: 'https://www.retool.com/',
    steps: [
      'Click on the "Resources" tab.',
      'Click the "Create New" button.',
      'Select "Snowflake".',
      'Give your resource a name, perhaps "mozart".',
      STANDARD_CREDENTIAL_SENTENCE,
      'Click "Create Resource".',
    ],
    fields: [
      { uiName: 'Account name', key: 'account_name' },
      { uiName: 'Database name', key: 'database' },
      { uiName: 'Database warehouse', key: 'warehouse' },
    ],
  },
  sigma: {
    url: 'https://app.sigmacomputing.com/',
    steps: [
      'Click on the "Administration" at the bottom of the left hand menu.',
      'Click "Connections".',
      'Click the "Create Connection" button.',
      'Give your connection a name, perhaps "mozart".',
      'Select "Snowflake".',
      'Leave "Use Custom Host" untoggled.',
      STANDARD_CREDENTIAL_SENTENCE,
      'Click "Create" in the top right corner.',
    ],
    fields: [
      { uiName: 'Account name', key: 'account_name' },
      { uiName: 'Warehouse', key: 'warehouse' },
    ],
  },
  sisense: {
    url: 'https://www.sisense.com/',
    steps: [
      'Click the "Data" tab.',
      'Click the "+ Live" button.',
      'Enter a model name, perhaps "mozart", and click "Save".',
      'Click the "+ Data" button on the model you just created.',
      'Select "Snowflake".',
      STANDARD_CREDENTIAL_SENTENCE,
      'Click "Next".',
      'Select the tables you want to query.',
      'Click "Done".',
      'Optionally, create any relationships you want.',
      'Click "Publish".',
    ],
    fields: [{ uiName: 'Connection String', key: 'sisense_jdbc' }],
  },
  tableau_online: {
    url: 'https://online.tableau.com/',
    steps: [
      'There is a menu bar on the left side of the screen. Click on "Explore".',
      'Open a project or create a new one.',
      'Open a workbook or create a new one.',
      'In the menu bar, click "Data".',
      'Select "New Data Source".',
      'Click on the "Connectors" tab.',
      'Select "Snowflake".',
      'Set "Authentication" to "Username and Password".',
      STANDARD_CREDENTIAL_SENTENCE,
    ],
    fields: [{ uiName: 'Server', key: 'host' }],
    additionalFields: [
      { uiName: 'Warehouse', key: 'warehouse' },
      { uiName: 'Database', key: 'database' },
    ],
    additionalFieldsHeader: 'Fields you will need to set when searching for data',
  },
  tableau_desktop: {
    url: 'N/A',
    steps: [
      'Install the Snowflake ODBC drivers which can be found at https://sfc-repo.snowflakecomputing.com/odbc/index.html',
      'Open Tableau.',
      'In menu bar, select "Data".',
      'Select "New Data Source".',
      'Type "Snowflake" in the search box and then select it.',
      'Set "Authentication" to "Username and Password".',
      STANDARD_CREDENTIAL_SENTENCE,
      'If the "Sign In" button is not enabled, that likely means you need to install the Snowflake drivers from step #1.',
    ],
    fields: [{ uiName: 'Server', key: 'host' }],
    additionalFields: [
      { uiName: 'Warehouse', key: 'warehouse' },
      { uiName: 'Database', key: 'database' },
    ],
    additionalFieldsHeader: 'Fields you will need to set when searching for data',
  },
  thoughtspot: {
    url: 'https://www.thoughtspot.com/',
    steps: [
      'Log into your Thoughtspot account.',
      'Click "Setup" in the header.',
      'Click "Connect Now" button.',
      'Name your connection anything you want, perhaps call it "mozart".',
      'Select the Snowflake tile.',
      'Click "Continue" in the top right corner.',
      STANDARD_CREDENTIAL_SENTENCE,
      'Click "Continue".',
      'Select the tables you want to connect.',
      'Click "Create connection".',
    ],
    fields: [
      { uiName: 'Account Name', key: 'account_name' },
      { uiName: 'Warehouse', key: 'warehouse' },
      { uiName: 'Database', key: 'database' },
    ],
  },
  zing: {
    url: 'https://getzingdata.com/',
    steps: [
      'Click "Data Sources" tab.',
      'Click the "New datasource” button.',
      'Select "Snowflake" as the database type.',
      STANDARD_CREDENTIAL_SENTENCE,
      'Click "Check Connection" and optionally choose which tables you want to make visible to Zing.',
      'Click "Save".',
    ],
    fields: [
      { uiName: 'Hostname', key: 'account_name' },
      { uiName: 'Database Name', key: 'database' },
    ],
  },
};

const defaultIntegrationMap: DefaultIntegrationMap = {};
Object.entries(defaultIntegrationLayoutDataMap).forEach(([k, v]) => {
  defaultIntegrationMap[k] = {
    ...v,
    ...integrationMap[k],
  };
});

export { defaultIntegrationMap };
