/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';

import axios from 'axios';

import Button from 'components/inputs/basic/Button/Button';
import APIKeyManager from 'pages/internal/APIKeyManager';
import { File } from 'pages/internal/FilePanel';

const githubKeyManager = new APIKeyManager('GitHub');

interface PullRequest {
  id: string;
  number: number;
  title: string;
  user: {
    login: string;
  };
  head: {
    ref: string;
  };
}

export const FilesContext = React.createContext({
  setCIStatuses: (ciStatuses: any[]) => {},
  setFiles: (files: File[]) => {},
  pullRequests: [] as PullRequest[],
  setPullRequests: (prs: PullRequest[]) => {},
  editingPR: null as string | null,
  setEditingPR: (prID: string | null) => {},
});

// Utility function to handle GitHub API requests with authorization
export const fetchGitHubWithAuth = async (method: string, endpoint: string, data: any = null) => {
  // Construct the URL from the base API URL and the endpoint
  const url = `https://api.github.com/repos/MozartData/mozart/${endpoint}`;

  const apiKey = githubKeyManager.getAPIKey(
    'It should be possible to visit https://github.com/settings/tokens and regenerate a personal access token unless this is your first time.',
  );

  if (method === 'get') {
    let config = {
      headers: { Authorization: `Bearer ${apiKey}` },
      params: data,
    };
    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      console.error(`Failed to fetch from GitHub with error:`, error);
      throw error;
    }
  }

  let config = {
    method: method,
    url: url,
    headers: { Authorization: `Bearer ${apiKey}` },
    data: data,
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    console.error(`Failed to fetch from GitHub with error:`, error);
    throw error;
  }
};

export const fetchFileContent = async (filename: string, branch: string) => {
  try {
    const response = await fetchGitHubWithAuth('get', `contents/${filename}`, { ref: branch });
    const content = atob(response.data.content);
    return content;
  } catch (error) {
    console.error(`Failed to fetch file content for ${filename} from branch ${branch}:`, error);
    return '';
  }
};

export default function GitHubPRHelper() {
  const [showPRs, setShowPRs] = useState<boolean>(false);
  const [onlyMyPRs, setOnlyMyPRs] = useState<boolean>(true);
  const [editInput, setEditInput] = useState('');
  const { setCIStatuses, setFiles, pullRequests, setPullRequests, editingPR, setEditingPR } =
    useContext(FilesContext);

  useEffect(() => {
    const fetchOpenPullRequests = async () => {
      try {
        const response = await fetchGitHubWithAuth('get', `pulls`, { state: 'open' });
        setPullRequests(response.data as PullRequest[]);
      } catch (error) {
        console.error('Failed to fetch open pull requests:', error);
      }
    };

    if (showPRs) {
      fetchOpenPullRequests();
    }
  }, [showPRs, setPullRequests]);

  const handlePRFiles = async (prNumber: string) => {
    try {
      const prDetailsResponse = await fetchGitHubWithAuth('get', `pulls/${prNumber}`);
      const headRef = prDetailsResponse.data.head.sha;

      console.log('Fetched head ref:', headRef);

      const filesResponse = await fetchGitHubWithAuth('get', `pulls/${prNumber}/files`);
      console.log('Fetched PR files:', filesResponse.data);

      const statusesResponse = await fetchGitHubWithAuth('get', `commits/${headRef}/statuses`);
      setCIStatuses(statusesResponse.data);
      console.log('Fetched CI statuses:', statusesResponse.data);

      setFiles(
        await Promise.all(
          filesResponse.data.map(async (prFile: any) => ({
            filename: prFile.filename,
            content: await fetchFileContent(prFile.filename, headRef),
          })),
        ),
      );
    } catch (error) {
      console.error('Failed to fetch files for pull request:', error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setEditingPR(null);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setEditingPR]);

  const handleEdit = (pr: any) => {
    if (onlyMyPRs) {
      setEditingPR(pr.id);
      setEditInput(pr.title);
    }
  };

  const handleChange = (e: any) => {
    setEditInput(e.target.value);
  };

  const handleSave = async (prID: string) => {
    const pr = pullRequests.find((pr) => pr.id === prID);
    if (!pr) return;
    const newTitle = editInput;
    const oldTitle = pr.title;

    if (onlyMyPRs) {
      const isConfirmed = window.confirm(
        `Are you sure you want to change the title for PR ${pr.number}?\nOld title:\n${oldTitle}\n\nNew Title:\n${newTitle}`,
      );
      if (isConfirmed) {
        console.log(`Saving new title for PR ${prID} (#${pr.number}): ${editInput}`);

        try {
          const response = await fetchGitHubWithAuth('patch', `pulls/${pr.number}`, { title: newTitle });
          console.log(`Title for PR ${pr.number} successfully updated to '${newTitle}'.`, response.data);
          setEditingPR(null);
          const updatedPRs = pullRequests.map((pr) =>
            pr.id === prID ? { ...pr, title: newTitle } : pr,
          );
          setPullRequests(updatedPRs);
        } catch (error) {
          console.error(`Error updating title for PR ${pr.number}: `, error);
        }
      } else {
        console.log('User canceled the title change.');
      }
    } else {
      console.log(`Not in onlyMyPRs mode...Not saving.`);
    }
  };

  const handleCancel = () => {
    setEditingPR(null);
  };

  return (
    <div>
      <Button onClick={() => setShowPRs(!showPRs)}>{showPRs ? 'Hide' : 'Show'} Open PRs</Button>
      {showPRs && (
        <>
          <div style={{ marginBottom: '10px' }}>
            <label>
              <input type="checkbox" checked={onlyMyPRs} onChange={() => setOnlyMyPRs(!onlyMyPRs)} />{' '}
              Only Mine
            </label>
          </div>
          <h2>Open Pull Requests</h2>
          <div>
            {pullRequests
              .filter((pr) => !onlyMyPRs || pr.user.login === 'johnclyde')
              .map((pr) => (
                <div
                  key={pr.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <div style={{ flex: '0.2', padding: '0 10px', textAlign: 'left' }}>#{pr.number}</div>
                  <div
                    style={{
                      flex: '0.8',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0 10px',
                      textAlign: 'right',
                    }}
                  >
                    <div
                      title={pr.head.ref}
                      style={{
                        backgroundColor: '#70CC70',
                        color: 'white',
                        padding: '2px 5px',
                        borderRadius: '5px',
                        marginBottom: '5px',
                        fontSize: 'small',
                        width: '100px',
                        textAlign: 'center',
                        height: '14px',
                        lineHeight: '9px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handlePRFiles(pr.number.toString())}
                    >
                      {pr.head.ref}
                    </div>
                    {editingPR === pr.id && onlyMyPRs ? (
                      <>
                        <textarea
                          value={editInput}
                          onChange={handleChange}
                          style={{ fontSize: 'small', width: '180px' }}
                        />
                        <button onClick={() => handleSave(pr.id)} title="Send to GitHub">
                          <span>↑</span>
                        </button>
                        <button onClick={handleCancel}>Cancel</button>
                      </>
                    ) : (
                      <div
                        onClick={() => handleEdit(pr)}
                        title={pr.title}
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: 'small',
                          textAlign: 'left',
                          width: '180px',
                        }}
                      >
                        {pr.title}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}
