import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function CompanyAllowAIInfoIcon() {
  const content = (
    <div>
      <div>
        <strong>Allow Mozart Data to send your metadata to OpenAI.</strong>
      </div>
      <div className="mt-2">
        Metadata includes schema names, table names, column names, column data types, SQL queries, and
        descriptions.
      </div>
      <div className="mt-2">We will not send the actual contents of your table to OpenAI.</div>
      <div className="mt-2">This setting is required for access to all AI features in Mozart Data.</div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} containerClass="ml-1" popoverProps={popoverProps} />;
}
