import React, { useContext, useEffect, useState } from 'react';

import { Gear } from 'react-bootstrap-icons';
import { RouteComponentProps } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import API from 'api/API';
import BackButton from 'components/inputs/basic/Button/BackButton';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import { TableModelsContext } from 'model_layer/TableModelsContext';

import { integrationMap, Integration } from '../Integration';
import { iconUrl } from '../integrationUtils';

import BrazeLayout from './integration_layouts/BrazeLayout';
import DbtLayout from './integration_layouts/DbtLayout';
import DefaultLayout from './integration_layouts/DefaultLayout';
import LookerController from './integration_layouts/LookerLayout/LookerController';
import PythonLayout from './integration_layouts/PythonLayout';
import SigmaController from './integration_layouts/SigmaLayout/SigmaController';
import SnowCliLayout from './integration_layouts/SnowflakeCliLayout';
import SnowflakeLayout from './integration_layouts/SnowflakeLayout';

// The nulls are possible for BYOS accounts
export type DatabaseConfigInfoResponse =
  | {
      [key: string]: string | null;
      warehouse: string;
      database: string;
      account_name: string;
      full_account_name: string;
      base_account_name: string | null;
      region: string | null;
      platform: string | null;
      type: 'snowflake';
    }
  | {
      type: 'bigquery';
      [key: string]: string | null;
      project_id: string;
      region: string;
    };

export interface IntegrationLayoutProps {
  integration: Integration;
  values: DatabaseConfigInfoResponse | undefined;
}

interface MatchParams {
  platform: string;
}

const IntegrationSnowflakeConfig = (props: RouteComponentProps<MatchParams>) => {
  const { platform } = props.match.params;
  const integration = integrationMap[platform];
  useTitle(`${integration.uiName} Integration`);
  const { allLoaded } = useContext(TableModelsContext);

  const [values, setValues] = useState<DatabaseConfigInfoResponse | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    const api = new API();
    api
      .get('/api/database_config')
      .then((response) => {
        setValues(response.data);
      })
      .finally(() => setLoading(false));
  }, []);

  const useDefault = integration.type === 'webapp' || integration.type === 'desktop';

  const handleBackToAllIntegrations = () => {
    analytics.track('IntegrationCredentials BackToAllIntegrations');
    navigate('/integrations');
  };

  const header = (
    <div className="f-between">
      <div className="f-center">
        {platform === 'generic' ? (
          <Gear size={48} />
        ) : (
          <img className="w-12 h-12" src={iconUrl(platform)} alt={`${integration.uiName} logo`} />
        )}
        <div className="display-sm ml-2">{integration.uiName}</div>
      </div>
      {/* MVP Hack: Hide back link because DBT can be accessed from Header. */}
      {integration.type !== 'dbt' && (
        <BackButton text="ALL INTEGRATIONS" onClick={handleBackToAllIntegrations} />
      )}
    </div>
  );

  const loadingSpinner = loading || (integration.type === 'dbt' && !allLoaded);

  return (
    <CenteredLayout maxWidth="814px" header={header} loading={loadingSpinner}>
      {useDefault && <DefaultLayout integration={integration} values={values} />}
      {integration.type === 'braze' && <BrazeLayout integration={integration} values={values} />}
      {integration.type === 'dbt' && <DbtLayout integration={integration} values={values} />}
      {integration.type === 'sigma' && <SigmaController integration={integration} values={values} />}
      {integration.type === 'looker' && <LookerController integration={integration} values={values} />}
      {integration.type === 'python' && <PythonLayout integration={integration} values={values} />}
      {integration.type === 'snowflake' && <SnowflakeLayout integration={integration} values={values} />}
      {integration.type === 'snowcli' && <SnowCliLayout integration={integration} values={values} />}
    </CenteredLayout>
  );
};

export default IntegrationSnowflakeConfig;
