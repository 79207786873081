import React, { ForwardedRef } from 'react';

import { Link, LinkProps } from 'react-router-dom';

import cn from 'classnames';

import { LinkButtonProps as ButtonCommonProps, getEffectiveClass } from './Button';

export interface LinkButtonProps extends LinkProps, ButtonCommonProps {
  disabled?: boolean;
}

const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (props: LinkButtonProps, forwardedRef: ForwardedRef<HTMLAnchorElement>) => {
    let {
      size,
      variant,
      disabled,
      to,
      forceHover,
      forceFocus,
      forceActive,
      className,
      children,
      target,
      ...linkProps
    } = props;

    // Fix default values if they are undefined.
    size = size || 'large';
    variant = variant || 'lightAction';
    disabled = disabled || false;
    to = disabled ? '#' : to;
    target = disabled ? undefined : target;

    // Pick colors based on variant and colorState
    const variantAndSizeClasses = getEffectiveClass(size, variant, false, disabled, true, props);
    const actualClass = cn('inline-block', variantAndSizeClasses, className);

    return (
      <Link ref={forwardedRef} className={actualClass} to={to} target={target} {...linkProps}>
        {children}
      </Link>
    );
  },
);

export default LinkButton;
