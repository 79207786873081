/*******************************************************************************
 * SearchableCheckboxPicker is the original implementation's interface.
 * It differs from the BaseSearchableCheckboxPicker in that it defines how
 * the rowComponent is implemented and expects props for this rowComponent.
 ******************************************************************************/
import BaseSearchableCheckboxPicker, {
  BaseSearchableCheckboxPickerListProps,
} from './BaseSearchableCheckboxPicker';
import SearchableCheckboxPickerRow, {
  SearchableCheckboxPickerEveryRowProps,
} from './SearchableCheckboxPickerRow';

interface SearchableCheckboxPickerProps<SearchableObject>
  extends BaseSearchableCheckboxPickerListProps<SearchableObject>,
    SearchableCheckboxPickerEveryRowProps<SearchableObject> {}

const SearchableCheckboxPicker = <SearchableObject extends object>(
  props: SearchableCheckboxPickerProps<SearchableObject>,
) => {
  const {
    objectsRowClass,
    objectsBoxClass,
    isChecked,
    hasChanged,
    onToggleCheck,
    renderObject,
    getDescription,
    ...baseProps
  } = props;

  const everyRowComponentProps: SearchableCheckboxPickerEveryRowProps<SearchableObject> = {
    objectsRowClass,
    objectsBoxClass,
    isChecked,
    hasChanged,
    onToggleCheck,
    renderObject,
    getDescription,
  };

  return (
    <BaseSearchableCheckboxPicker
      {...baseProps}
      rowComponent={SearchableCheckboxPickerRow}
      everyRowComponentProps={everyRowComponentProps}
    />
  );
};

export default SearchableCheckboxPicker;
