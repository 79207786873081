/*******************************************************************************
Developer convenience for simulating demo and testing scenarios.

In your local dev, you can edit this to do whatever you want.
For example you could call `apiMocker.getTable()`.
BE CAREFUL NOT TO CHECK HACKS INTO MASTER!
You are probably better off using an API proxy server or browser plugin
on your development box to mock most API responses.

Defaults to extremely useful and common scenario of testing empty list state.
Example:
If you type a URL like `http://localhost:3000/connectors?no_connectors`
you will get the connectors empty state.
********************************************************************************/
import { Connector, Favorite, TableVisit, Tag } from 'api/APITypes';
import { CSVUpload } from 'api/csvUploadAPI';
import { DbtDestinationTable, DbtRunConfig } from 'api/dbtAPI';
import { AggTable, Transform } from 'api/tableAPI';

/*******************************************************************************
 * URL Utilities
 ******************************************************************************/
export function urlHasParam(searchParamKey: string) {
  return new URL(window.location.href).searchParams.has(searchParamKey);
}

export function maybeMockList<APIType>(
  searchParamKey: string,
  apiList: APIType[],
  replacementList: APIType[],
): APIType[] {
  return urlHasParam(searchParamKey) ? replacementList : apiList;
}

/*******************************************************************************
 * Model Mocking Functions
 ******************************************************************************/
export function maybeMockConnectors(connectors: Connector[]) {
  return maybeMockList('no_connectors', connectors, []);
}

export function maybeMockTables(tables: AggTable[]) {
  return maybeMockList('no_tables', tables, []);
}

export function maybeMockTransforms(transforms: Transform[]) {
  return maybeMockList('no_transforms', transforms, []);
}

export function maybeMockDbtRunConfigs(runConfigs: DbtRunConfig[]) {
  return maybeMockList('no_dbt', runConfigs, []);
}

export function maybeMockDbtDestinationTables(destinationTables: DbtDestinationTable[]) {
  return maybeMockList('no_dbt', destinationTables, []);
}

export function maybeMockCSVUploads(csvUploads: CSVUpload[]) {
  return maybeMockList('no_csvs', csvUploads, []);
}

export function maybeMockFavorites(favorites: Favorite[]) {
  return maybeMockList('no_favorites', favorites, []);
}

export function maybeMockRecents(recents: TableVisit[]) {
  return maybeMockList('no_recents', recents, []);
}

export function maybeMockTags(tags: Tag[]) {
  return maybeMockList('no_tags', tags, []);
}
