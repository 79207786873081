/* Utils that help render a basic pipeline graph */
import { Edge } from '../../PipelineEditor';
import { VertexShape, ErrorColor, PipelineVertex } from '../../useVertexConverter';

import {
  FONT_SIZE,
  LINE_HEIGHT,
  STYLES_BY_STATUS,
  STYLES_BY_TYPE,
  VERTEX_HEIGHT,
} from '../PipelineGraph.styles';

/** Calculate height of vertex based on how many customized data points are visible
 * @param keys Array of keys for customized data
 * @returns Height of vertex
 */
export const calcCustomizedVertexHeight = (keys: string[]) => {
  const numProperties = Object.keys(keys).length;
  /**
   * Make sure this is consistent with styles in `PipelineVertex`
   * 1px for separator + 6px for margin-top + 6px for padding-top + 8px for padding-bottom = 21px
   */
  const separatorHeight = numProperties > 0 ? 21 : 0;
  const customizedDataHeight = numProperties * (LINE_HEIGHT + FONT_SIZE);

  return VERTEX_HEIGHT + separatorHeight + customizedDataHeight;
};

/** Return object of styles to apply to rendered vertex */
export const getVertexStyle = (type: VertexShape, status: ErrorColor) => {
  if (status !== ErrorColor.Normal) {
    return STYLES_BY_STATUS[status];
  } else {
    return STYLES_BY_TYPE[type];
  }
};

/** Returns color of edge based on source vertex status */
export const getEdgeColor = (edge: Edge, vertices: PipelineVertex[]) => {
  const sourceVertex = vertices.find((v) => v.id === edge.source);
  if (!sourceVertex) {
    return STYLES_BY_STATUS[ErrorColor.Normal].selectedBorderColor;
  }

  const { errorColor: status } = sourceVertex;
  return STYLES_BY_STATUS[status].selectedBorderColor;
};

/** Returns constants for different zoom levels commonly used for pipeline graphs */
export const getZoomLevels = (cy: cytoscape.Core) => ({
  /** Should be able to see full graph */
  low: cy.zoom(),
  /** Should be able to see a centered vertex and its surrounding vertices */
  mid: 1.1,
  /** Should be able to see a centered vertex and its surrounding edges */
  high: 2.5,
});
