/*
  HTML that looks like a radio button input but isn't an input.
*/
import { Check } from 'react-bootstrap-icons';

import cn from 'classnames';

const FakeRadioButton = (props: { selected: boolean; enabled: boolean }) => {
  const { selected, enabled } = props;

  const fakeRadioSize = 'w-4 h-4 rounded-lg';

  return selected ? (
    <div
      className={cn(fakeRadioSize, 'f-center', {
        'bg-sec-blue-gray-500': enabled,
        'bg-pri-gray-400': !enabled,
      })}
    >
      <Check size={16} color={enabled ? 'white' : 'var(--pri-gray-100)'} />
    </div>
  ) : (
    <div
      className={cn(fakeRadioSize, 'border-[1px]', {
        'border-pri-gray-500 bg-white': enabled,
        'border-pri-gray-400 bg-pri-gray-50': !enabled,
      })}
    ></div>
  );
};

export default FakeRadioButton;
