import React from 'react';

import cn from 'classnames';

import { TransformRun, TransformVersion } from 'api/APITypes';
import { AggTable, Transform } from 'api/tableAPI';
import UserHover from 'components/overlay/UserHover/UserHover';
import TransformRunIcon from 'components/primitives/icons/TransformRunIcon/TransformRunIcon';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';
import {
  numRowsAsString,
  lastRunDateAsString,
  lastRunAgo,
  durationAsString,
} from 'model_helpers/aggTableHelper';
import TransformScheduleWidget from 'pages/tables/ShowTable/SummaryTab/TransformScheduleWidget';
import { agoIso, formatIso } from 'utils/dateTime';

import { Pipeline } from '../PipelineEditor';
import { PipelineVertex } from '../useVertexConverter';

import SidebarDescription from './SidebarDescription';
import { HEADING_CLASS } from './VertexSidebar';
import VertexTableSection from './VertexTableSection';

interface TransformSidebarContentProps {
  pipeline: Pipeline;
  selectedVertex: PipelineVertex;
  table: AggTable;
  tablesByID: { [key: string]: AggTable };
}

export default function TransformSidebarContent(props: TransformSidebarContentProps) {
  const { pipeline, selectedVertex, table, tablesByID } = props;
  const transform = table.transform as Transform;

  const lastCompletedRun = transform.last_completed_run as TransformRun;
  const lastRunIsCurrentVersion =
    lastCompletedRun?.version.version_number === transform.current_version.version_number;

  const vertexDependencies = pipeline.edges.filter(
    (v) => v.destination === selectedVertex.id && v.is_scheduling_dependency,
  );

  return (
    <div>
      <SidebarDescription description={table.description} />
      <div className={cn(HEADING_CLASS, 'mt-6')}>Schedule</div>
      <div className="mt-1 text-sm">
        <TransformScheduleWidget
          table={table}
          vertexDependencies={vertexDependencies}
          vertices={pipeline.vertices}
          tablesByID={tablesByID}
        />
      </div>
      {lastCompletedRun ? (
        <>
          <TransformRunSection
            title="Last Completed Run"
            table={table}
            transformRun={lastCompletedRun}
            className="mt-6"
          />

          {lastRunIsCurrentVersion ? (
            <TransformVersionSection
              title="Last Completed Run & Current Version"
              version={lastCompletedRun.version}
              className="mt-6"
            />
          ) : (
            <>
              <TransformVersionSection
                title="Last Completed Run Version"
                version={lastCompletedRun.version}
                className="mt-6"
              />
              <TransformVersionSection
                title="Current Version"
                version={transform.current_version}
                className="mt-6"
              />
            </>
          )}
        </>
      ) : (
        <FixMeAlert
          heading="Uh-Oh!"
          detail={
            <div>
              <p className="mt-2">This table is missing because this transform was never run.</p>
              <p className="mt-2">
                Go to this transform's{' '}
                <a
                  href={`/tables/${transform.table_id}/run`}
                  data-track="PipelineEditor TransformNeverRanScheduleLink"
                  className="text-sec-blue-light-700 font-medium"
                  target="_blank"
                  rel="noreferrer"
                >
                  Runs Tab
                </a>{' '}
                and click the "Run" button to create table{' '}
                <code className="text-pri-gray-700">{table.full_name}</code>.
              </p>
            </div>
          }
          alertClass="w-full mt-6"
          additionalCenteredClass="w-full"
          additionalWordClass="w-full break-words"
          variant="kinda_bad"
        />
      )}
    </div>
  );
}

interface TransformRunSectionProps {
  title: string;
  table: AggTable;
  transformRun: TransformRun;
  className?: string;
}

function TransformRunSection(props: TransformRunSectionProps) {
  const { table, transformRun, ...rest } = props;
  const rows = [
    { label: 'Status', value: <TransformRunIcon run={transformRun} rotate={true} /> },
    { label: 'Start Time', value: lastRunDateAsString(table) },
    { label: 'Started Ago', value: lastRunAgo(table) },
    { label: 'Duration', value: durationAsString(table) },
    { label: 'Rows', value: numRowsAsString(table) },
    { label: 'Type', value: transformRun.run_manually ? 'Manual' : 'Scheduled' },
  ];
  return <VertexTableSection rows={rows} {...rest} />;
}

interface TransformVersionSectionProps {
  title: string;
  version: TransformVersion;
  className?: string;
}

function TransformVersionSection(props: TransformVersionSectionProps) {
  const { version, ...rest } = props;
  const rows = [
    { label: 'Version', value: version.version_number },
    { label: 'Saved By', value: <UserHover userProfile={version.created_by} /> },
    { label: 'Saved At', value: formatIso(version.created_at) },
    { label: 'Saved Ago', value: agoIso(version.created_at) },
  ];
  return <VertexTableSection rows={rows} {...rest} />;
}
