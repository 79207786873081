import React, { useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import API from 'api/API';
import QRCodeComponent from 'components/business_logic/QRCodeComponent/QRCodeComponent';
import Button from 'components/inputs/basic/Button/Button';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import { useUserProfile } from 'context/AuthContext';

const totpTokenSchema = Yup.object().shape({
  totp_token: Yup.string().trim().min(6, 'Exactly 6 digits').max(6, 'Exactly 6 digits'),
});

interface totpToken {
  totp_token: string; // totp = Time One-Time Password
}

interface ResetTotpButtonProps {
  setTotpData: (totpData: { totp_secret: string; totp_url: string }) => void;
  setError: (error: string) => void; // Error to display on base Settings.tsx page.
}

export default function ResetTotpButton(props: ResetTotpButtonProps) {
  const { setTotpData, setError } = props;
  const { setUserProfile } = useUserProfile();
  const [showResetTotpModal, setShowResetTotpModal] = useState(false);
  const [resetTotpData, setResetTotpData] = useState({ totp_url: '', totp_secret: '' });

  const openResetTotpModal = () => {
    const api = new API();
    api
      .get(`/api/user_settings/get_new_totp_secret`)
      .then((response) => {
        setResetTotpData(response.data.totp_data);
        setShowResetTotpModal(true);
        analytics.track('UserSettings OpenResetTotpModal');
      })
      .catch((e) => {
        setError('There was a problem fetching new 2FA credentials.');
      });
  };

  const closeResetTotpModal = () => {
    setShowResetTotpModal(false);
    analytics.track('UserSettings CloseResetTotpModal');
  };

  const submitResetTotp = (values: totpToken) => {
    const transformValues = totpTokenSchema.cast(values);
    setError('');
    const api = new API();
    const postData = { totp_secret: resetTotpData.totp_secret, totp_token: transformValues.totp_token };
    api
      .post(`/api/user_settings/set_totp_secret`, postData)
      .then((response) => {
        setUserProfile(response.data.user_profile);
        setTotpData(response.data.totp_data);
        analytics.track('UserSettings SubmitResetTotp');
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          setError(e.response.data.error);
        } else if (e.response?.data?.non_field_errors) {
          setError(e.response.data.non_field_errors);
        } else {
          setError('There was a problem verifying your 2FA Token');
        }
      })
      .finally(() => {
        setShowResetTotpModal(false);
      });
  };

  const resetTotpModal = (
    <Modal onClose={closeResetTotpModal} header="Reset 2FA Credentials" cancelButton={true}>
      <div className="m-4 flex flex-col items-center">
        <p>
          Please register with a 2FA mobile app (Google Authenticator or similar) and submit a 2FA token
          to finish resetting your 2FA credentials.
        </p>
        <QRCodeComponent totp_secret={resetTotpData.totp_secret} totp_url={resetTotpData.totp_url} />
        <Formik
          initialValues={{ totp_token: '' }}
          onSubmit={(values) => submitResetTotp(values)}
          validationSchema={totpTokenSchema}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="flex flex-col items-center" noValidate>
              <TextFormikGroup name="totp_token" type="text" />
              <Button type="submit" variant="lightAction" className="mt-2" spinning={isSubmitting}>
                Submit
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );

  return (
    <>
      {showResetTotpModal && resetTotpModal}
      <Button onClick={openResetTotpModal} size="small" className="m-1 mt-2">
        Reset 2FA Credentials
      </Button>
    </>
  );
}
