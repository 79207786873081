import { useEffect, useState } from 'react';

import { mapValues } from 'lodash';

import API from 'api/API';

import { Schemas, Table } from './useManageTablesTabState';

export type SchemasExpanded = Record<string, SchemaExpanded>;

export type SchemaExpanded = {
  expanded: boolean;
  tables: Record<string, boolean>;
};

export interface SchemasAndTablesToSyncState {
  schemasToDisplay: Schemas;
  error: string;
  filter: string;
  schemasExpanded: SchemasExpanded;
  onFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleExpandSchema: (schemaName: string) => void;
  onToggleExpandTable: (schemaName: string, tableName: string) => void;
  onToggleSchema: (schemaName: string) => void;
  onToggleTable: (schemaName: string, tableName: string) => void;
  onToggleEnableColumn: (schemaName: string, tableName: string, columnName: string) => void;
  onToggleHashColumn: (schemaName: string, tableName: string, columnName: string) => void;
}

export function useSchemasAndTablesToSyncState(props: {
  fivetran_connector_id: string;
  unsavedSchemas: Schemas | null;
  setUnsavedSchemas: (unsavedSchemas: Schemas | null) => void;
}): SchemasAndTablesToSyncState | null {
  const { unsavedSchemas, setUnsavedSchemas, fivetran_connector_id } = props;

  const [schemasToDisplay, setSchemasToDisplay] = useState<Schemas | null>(null);
  const [schemasExpanded, setSchemasExpanded] = useState<SchemasExpanded | null>(null);
  const [filter, setFilter] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // On page load expand all schemas
    const isFirstPageLoad = !!unsavedSchemas && !schemasExpanded;
    if (isFirstPageLoad) {
      setSchemasExpanded(
        mapValues(unsavedSchemas, (schema) => ({
          expanded: true,
          tables: mapValues(schema.tables, () => false),
        })),
      );
    }
  }, [unsavedSchemas, schemasExpanded]);

  useEffect(() => {
    // If we haven't loaded unsavedSchemas yet, do nothing
    if (!unsavedSchemas) {
      return;
    }
    // If no filter, show everything
    if (!filter) {
      setSchemasToDisplay(unsavedSchemas);
      return;
    }
    const lowercaseFilter = filter.toLowerCase();
    const newSchemasToDisplay: Schemas = {};
    Object.entries(unsavedSchemas).forEach(([schemaName, schema]) => {
      // If the schema matches the filter, include all its tables
      if (schemaName.toLowerCase().includes(lowercaseFilter)) {
        newSchemasToDisplay[schemaName] = schema;
        return;
      }
      // Else if some tables in the schema match the filter, include just those tables
      const matchingTables = Object.entries(schema.tables || {}).reduce<Record<string, Table>>(
        (acc, [tableName, table]) => {
          if (tableName.toLowerCase().includes(lowercaseFilter)) {
            acc[tableName] = table;
          }
          return acc;
        },
        {},
      );
      if (Object.keys(matchingTables).length > 0) {
        newSchemasToDisplay[schemaName] = {
          ...schema,
          tables: matchingTables,
        };
      }
    });

    setSchemasToDisplay(newSchemasToDisplay);
  }, [filter, unsavedSchemas]);

  if (!unsavedSchemas || !schemasToDisplay || !schemasExpanded) {
    return null;
  }

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const onToggleExpandSchema = (schemaName: string) => {
    const newSchemasExpanded = { ...schemasExpanded };
    newSchemasExpanded[schemaName].expanded = !newSchemasExpanded[schemaName].expanded;
    setSchemasExpanded(newSchemasExpanded);
  };

  const onToggleExpandTable = (schemaName: string, tableName: string) => {
    // First, expand
    const newSchemasExpanded = { ...schemasExpanded };
    newSchemasExpanded[schemaName].tables[tableName] = !newSchemasExpanded[schemaName].tables[tableName];
    setSchemasExpanded(newSchemasExpanded);
    // Second, load if we need to
    if (!unsavedSchemas[schemaName].tables[tableName].columns) {
      const api = new API();
      setError('');
      api
        .get(`api/fivetran/connector/${fivetran_connector_id}/columns/${schemaName}/${tableName}`)
        .then((response) => {
          const columns = response.data.columns || {}; // if we get back nothing, just stop the spinner and show no columns table
          const newUnsavedSchemas = { ...unsavedSchemas };
          newUnsavedSchemas[schemaName].tables[tableName].columns = columns;
          setUnsavedSchemas(newUnsavedSchemas);
        })
        .catch(() => {
          setError('There was a problem loading the columns.');
        });
    }
  };

  // Jan 19 2024: Unlike the old behavior, we won't turn on all tables if you turn on the schema.
  // Seems to remember that you toggled off a table even if you flip its schema on and off.
  // Less MAR surprises this way I think.
  const onToggleSchema = (schemaName: string) => {
    const newUnsavedSchemas = { ...unsavedSchemas };
    newUnsavedSchemas[schemaName].enabled = !newUnsavedSchemas[schemaName].enabled;
    setUnsavedSchemas(newUnsavedSchemas);
  };

  const onToggleTable = (schemaName: string, tableName: string) => {
    const newUnsavedSchemas = { ...unsavedSchemas };
    newUnsavedSchemas[schemaName].tables[tableName].enabled =
      !newUnsavedSchemas[schemaName].tables[tableName].enabled;
    setUnsavedSchemas(newUnsavedSchemas);
  };

  const onToggleColumn = (
    schemaName: string,
    tableName: string,
    columnName: string,
    keyName: 'enabled' | 'hashed',
  ) => {
    const newUnsavedSchemas = { ...unsavedSchemas };
    newUnsavedSchemas[schemaName].tables[tableName].columns![columnName][keyName] =
      !newUnsavedSchemas[schemaName].tables[tableName].columns![columnName][keyName];
    setUnsavedSchemas(newUnsavedSchemas);
  };

  const onToggleEnableColumn = (schemaName: string, tableName: string, columnName: string) => {
    onToggleColumn(schemaName, tableName, columnName, 'enabled');
  };

  const onToggleHashColumn = (schemaName: string, tableName: string, columnName: string) => {
    onToggleColumn(schemaName, tableName, columnName, 'hashed');
  };

  return {
    schemasToDisplay,
    error,
    filter,
    schemasExpanded,
    onFilterChange,
    onToggleExpandSchema,
    onToggleExpandTable,
    onToggleSchema,
    onToggleTable,
    onToggleEnableColumn,
    onToggleHashColumn,
  };
}
