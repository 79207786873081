import React, { useCallback } from 'react';

import { Column } from 'api/columnAPI';

import { highlightSearch } from '../../highlightUtils';

interface ColumnHoverRowProps {
  rowRef: React.RefObject<HTMLTableRowElement>;
  column: Column;
  hovered: boolean;
  onDoubleClickColumn(column: Column): void;
  highlightFilter: string | RegExp;
  overlayRight: number;
  renderColumnHover(column: Column, overlayRight: number): React.ReactNode;
}

const ColumnHoverRow = React.memo((props: ColumnHoverRowProps) => {
  const {
    rowRef,
    column,
    hovered,
    onDoubleClickColumn,
    highlightFilter,
    overlayRight,
    renderColumnHover,
  } = props;

  const onDoubleClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      onDoubleClickColumn(column);
    },
    [column, onDoubleClickColumn],
  );

  return (
    <tr ref={rowRef} data-dsample={column.name} onDoubleClick={onDoubleClick}>
      <td>
        <div className="tt-table-details-row">
          <div className="tt-table-details-row-data">
            <span className="tt-table-details-row-name">
              {highlightSearch(column.name, highlightFilter)}
            </span>
            <span className="tt-table-details-row-type">{column.type}</span>
          </div>
          {hovered && renderColumnHover(column, overlayRight)}
        </div>
      </td>
    </tr>
  );
});

export default ColumnHoverRow;
