import { useState } from 'react';

import { useUserProfile } from 'context/AuthContext';
import useAIPermissionModalContext from 'model_layer/useAIPermissionModalContext';

import AIExplainSqlButton from './AIExplainSqlButton';
import AIExplainSqlModal from './AIExplainSqlModal';
import useAIExplainSql from './useAIExplainSql';

interface AIExplainSqlProps {
  sql: string;
}

const AIExplainSql = (props: AIExplainSqlProps) => {
  const { sql } = props;

  const [showModal, setShowModal] = useState(false);

  const { userProfile } = useUserProfile();
  const {
    company: { allow_ai: allowAI },
  } = userProfile;

  const { setShowAIPermissionModal } = useAIPermissionModalContext();

  const { isLoading, takingLonger, responseId, explanation, apiError, onGenerateExplanation } =
    useAIExplainSql({
      sql,
    });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    if (!allowAI) {
      setShowAIPermissionModal(true);
    } else {
      onGenerateExplanation();
      setShowModal(true);
    }
  };

  const handleFeedbackClick = (response: string) => {
    analytics.track('SqlEditor SubmitAIExplainSqlFeedback', {
      response_id: responseId,
      sql,
      explanation,
      feedback_response: response,
    });
  };

  // Hide AI Explain SQL button if sql is empty
  if (!sql) {
    return null;
  }

  return (
    <>
      <AIExplainSqlButton onClick={handleOpenModal} />
      {showModal && (
        <AIExplainSqlModal
          onClose={handleCloseModal}
          isLoading={isLoading}
          takingLonger={takingLonger}
          explanation={explanation}
          apiError={apiError}
          onFeedbackClick={handleFeedbackClick}
        />
      )}
    </>
  );
};

export default AIExplainSql;
