/* How to update this list:
1. Run this query: https://app.mozartdata.com/query/28c98cad-3417-4a5e-942a-8b5b209867f8
2. Download the CSV.
3. Open in Mac Numbers or other spreadsheet.
4. Copy and paste the connector_type and avg_sync_time columns into this doc between the array brackets.
5. Run your editor's search and replace with:
   1. Regex Match: `(\w+)\s(\w+)`
   2. Replace With: `  {service:'$1', seconds: $2},`
*/

interface ConnectorTime {
  service: string;
  seconds: number;
}

export const avgConnectorTimes: ConnectorTime[] = [
  { service: 'zendesk_chat', seconds: 2230311 },
  { service: 'sftp', seconds: 492106 },
  { service: 'stripe_test', seconds: 312774 },
  { service: 'paypal', seconds: 250088 },
  { service: 'itunes_connect', seconds: 173593 },
  { service: 'shopify', seconds: 144683 },
  { service: 'freshdesk', seconds: 123491 },
  { service: 'front', seconds: 109674 },
  { service: 'mongo_sharded', seconds: 100801 },
  { service: 'mongo', seconds: 57990 },
  { service: 'github', seconds: 57686 },
  { service: 'iterable', seconds: 52930 },
  { service: 'google_analytics', seconds: 49826 },
  { service: 'klaviyo', seconds: 47441 },
  { service: 'stripe', seconds: 41608 },
  { service: 'snowflake_db', seconds: 32349 },
  { service: 'mixpanel', seconds: 30520 },
  { service: 'outreach', seconds: 30057 },
  { service: 'mailchimp', seconds: 27181 },
  { service: 'replyio', seconds: 22930 },
  { service: 'amplitude', seconds: 17781 },
  { service: 'intercom', seconds: 15923 },
  { service: 'recurly', seconds: 15362 },
  { service: 'pendo', seconds: 15324 },
  { service: 'facebook_ads', seconds: 15185 },
  { service: 'google_analytics_mcf', seconds: 14386 },
  { service: 'google_search_console', seconds: 13859 },
  { service: 'twitter_ads', seconds: 11044 },
  { service: 'zendesk', seconds: 10863 },
  { service: 'asana', seconds: 9561 },
  { service: 'greenhouse', seconds: 9192 },
  { service: 'twilio', seconds: 8747 },
  { service: 'sql_server', seconds: 8649 },
  { service: 's3', seconds: 7357 },
  { service: 'jira', seconds: 6330 },
  { service: 'facebook_pages', seconds: 6238 },
  { service: 'aurora_postgres', seconds: 5970 },
  { service: 'postgres', seconds: 5698 },
  { service: 'pipedrive', seconds: 5656 },
  { service: 'mysql_rds', seconds: 4350 },
  { service: 'netsuite_suiteanalytics', seconds: 4348 },
  { service: 'postgres_rds', seconds: 3700 },
  { service: 'hubspot', seconds: 3373 },
  { service: 'pinterest_ads', seconds: 2637 },
  { service: 'salesforce', seconds: 2422 },
  { service: 'mysql', seconds: 2204 },
  { service: 'harvest', seconds: 1994 },
  { service: 'google_ads', seconds: 1857 },
  { service: 'helpscout', seconds: 1779 },
  { service: 'kustomer', seconds: 1722 },
  { service: 'bingads', seconds: 1512 },
  { service: 'aurora', seconds: 1479 },
  { service: 'aws_lambda', seconds: 1214 },
  { service: 'tiktok_ads', seconds: 1093 },
  { service: 'recharge', seconds: 1076 },
  { service: 'linkedin_ads', seconds: 997 },
  { service: 'double_click_campaign_manager', seconds: 912 },
  { service: 'gcs', seconds: 771 },
  { service: 'google_cloud_postgresql', seconds: 628 },
  { service: 'xero', seconds: 599 },
  { service: 'heroku_postgres', seconds: 547 },
  { service: 'twitter', seconds: 541 },
  { service: 'ftp', seconds: 534 },
  { service: 'fivetran_log', seconds: 516 },
  { service: 'adwords', seconds: 421 },
  { service: 'google_cloud_function', seconds: 392 },
  { service: 'google_display_and_video_360', seconds: 362 },
  { service: 'big_commerce', seconds: 329 },
  { service: 'confluent_cloud', seconds: 310 },
  { service: 'dynamodb', seconds: 299 },
  { service: 'linkedin_company_pages', seconds: 253 },
  { service: 'quickbooks', seconds: 166 },
  { service: 'bamboohr', seconds: 140 },
  { service: 'salesforce_sandbox', seconds: 135 },
  { service: 'typeform', seconds: 130 },
  { service: 'google_play', seconds: 111 },
  { service: 'share_point', seconds: 110 },
  { service: 'instagram_business', seconds: 98 },
  { service: 'sendgrid', seconds: 83 },
  { service: 'google_drive', seconds: 81 },
  { service: 'youtube_analytics', seconds: 57 },
  { service: 'delighted', seconds: 49 },
  { service: 'adroll', seconds: 38 },
  { service: 'email', seconds: 35 },
  { service: 'google_sheets', seconds: 35 },
  { service: 'google_analytics_4', seconds: 31 },
  { service: 'segment', seconds: 29 },
  { service: 'appsflyer', seconds: 25 },
  { service: 'webhooks', seconds: 24 },
];
