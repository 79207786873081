import { useCallback, useMemo, useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import { isEqual } from 'lodash';
import qs from 'query-string';

import useDebounce from 'hooks/useDebounce';
import { UK_ALL, urlKeyToVSK } from 'pages/Warehouse/DatabaseSearch2/urlSchema';
import { warehousePathSearch } from 'utils/PathMaker';

export default function useSetSearchURL(
  filter: string,
  setFilter: (f: string) => void,
  virtualSchemaKeysToSearch: string[],
  setVirtualSchemaKeysToSearch: (vs: string[]) => void,
) {
  const history = useHistory();
  const urlSearch = history.location.search;
  const hasReadFilterFromUrl = useRef(false);
  const nextUrlPath = useRef({ inputWriteTime: 0, filter, virtualSchemaKeysToSearch });
  const lastUrlUpdateTime = useRef(0); // The time at which this hook updates the URL to reflect the user's typing.

  // history.replace() triggers a rerender of the entire Warehouse component,
  // which is expensive and therefore creates render lag.
  // Debounce this so it happens infrequently and trails the user's typing.
  const debouncedUrlUpdate = useDebounce(
    useCallback(() => {
      const { inputWriteTime, filter, virtualSchemaKeysToSearch } = nextUrlPath.current;
      if (inputWriteTime > lastUrlUpdateTime.current) {
        // console.log(
        //   `Debounce - ${Math.floor(
        //     performance.now()
        //   )} - Replacing URL with [${filter}] [${virtualSchemaKeysToSearch}]`
        // );
        lastUrlUpdateTime.current = inputWriteTime;
        history.replace(
          warehousePathSearch(history.location.pathname, filter, virtualSchemaKeysToSearch),
        );
      } else {
        // console.log(
        //   `Debounce - ${Math.floor(
        //     performance.now()
        //   )} - Ignoring Old Update. [${filter}] [${virtualSchemaKeysToSearch}]`
        // );
      }
    }, [nextUrlPath, lastUrlUpdateTime, history]),
    100,
    useMemo(() => ({ maxWait: 200, leading: false, trailing: true }), []),
  );

  // Update the filter or schemas in the URL query parameters after the user changes an input.
  // Exclude the initial change to the inputs at page load
  // when the inputs are set from the URL query parameters.
  useEffect(() => {
    if (hasReadFilterFromUrl.current) {
      const { urlFilter, urlVirtualSchemaKeysToSearch } = getUrlFilters(urlSearch);

      if (filter !== urlFilter || !isEqual(virtualSchemaKeysToSearch, urlVirtualSchemaKeysToSearch)) {
        // console.log(
        //   `DIFF - ${Math.floor(
        //     performance.now()
        //   )} - User typed [${filter}] [${virtualSchemaKeysToSearch}]. URL is [${urlFilter}] [${urlVirtualSchemaKeysToSearch}]`
        // );
        const now = performance.now();
        nextUrlPath.current = { inputWriteTime: now, filter, virtualSchemaKeysToSearch };
        debouncedUrlUpdate();
      }
    } else {
      // console.log('Skipping. URL has not set filter yet.');
    }
  }, [filter, virtualSchemaKeysToSearch, debouncedUrlUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // When the page first loads update the filter from the URL.
  // This is defined last so that `hasReadFilterFromUrl.current` is set
  // after the other useEffects have run.
  useEffect(() => {
    hasReadFilterFromUrl.current = true;
    const { urlFilter, urlVirtualSchemaKeysToSearch } = getUrlFilters(urlSearch);
    if (filter !== urlFilter) {
      setFilter(urlFilter);
      // console.log(`URL setting filter from [${filter}] to [${urlFilter}]`);
    }
    if (!isEqual(virtualSchemaKeysToSearch, urlVirtualSchemaKeysToSearch)) {
      setVirtualSchemaKeysToSearch(urlVirtualSchemaKeysToSearch);
      // console.log(
      //   `URL setting virtualSchemaKeysToSearch from [${virtualSchemaKeysToSearch}] to [${urlVirtualSchemaKeysToSearch}]`
      // );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

function getUrlFilters(urlSearch: string) {
  const parsedSearch = qs.parse(urlSearch);
  const urlFilter = (parsedSearch?.filter as string) || '';
  const urlSchemaKeysToSearchString = (parsedSearch?.schemas as string) || '';
  const urlSchemaKeysToSearch =
    urlSchemaKeysToSearchString === '' ? [UK_ALL] : urlSchemaKeysToSearchString.split(',');
  const urlVirtualSchemaKeysToSearch = urlSchemaKeysToSearch.map((urlKey) => urlKeyToVSK(urlKey));
  return { urlFilter, urlVirtualSchemaKeysToSearch };
}
