import { AggTable } from 'api/tableAPI';
import { parseIso } from 'utils/dateTime';

export const humanSyncFrequency = () => {
  // We don't store this yet. Fake it.
  return 'Once a Day';
};

export function lastRunDate(table: AggTable) {
  return parseIso(table.last_snapshot_at);
}

export function lastCompletedRunVersionSavedBy(table: AggTable): string {
  // We don't store this yet.
  return 'N/A';
}

export function lastCompletedRunVersionSavedAt(table: AggTable): Date | null {
  // We don't store this yet.
  return null;
}
