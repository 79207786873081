import { useState, Dispatch, SetStateAction } from 'react';

import { usePopper } from 'react-popper';

export type Placement =
  | 'auto'
  | 'right'
  | 'left'
  | 'top'
  | 'bottom'
  | 'auto-start'
  | 'auto-end'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end'
  | undefined;

// All Components that intend to render the props of usePopperRefs(ie Popover and Tooltip)
// should extend these props
export interface RenderPopperProps {
  setPopperElement: Dispatch<SetStateAction<HTMLElement | null>>;
  setArrowElement: Dispatch<SetStateAction<HTMLElement | null>>;
  popperStyles: { [key: string]: React.CSSProperties };
  popperAttributes: { [key: string]: { [key: string]: string } | undefined };
  popoverProps?: React.HTMLAttributes<HTMLDivElement>; // Additional props for the outermost div element of the positioned popover
}

export type RenderPopper = (renderPopperProps: RenderPopperProps) => React.ReactNode;

export default function usePopperRefs(placement: Placement) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      strategy: 'fixed',
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        {
          name: 'offset',
          options: {
            offset: [0, 16],
          },
        },
      ],
    },
  );
  return {
    referenceElement,
    setReferenceElement,
    popperElement,
    setPopperElement,
    arrowElement,
    setArrowElement,
    popperStyles,
    popperAttributes,
  };
}
