/*
 * Type definitions, API methods, and conversion utilities for Favorites.
 */
import API from 'api/API';

/*******************************************************************************
 * Types
 ******************************************************************************/
export interface Favorite {
  id: string;
  table: string;
  created_at: string;
}

export interface FavoritesByTableID {
  [key: string]: Favorite; // key is table id
}

/*******************************************************************************
 * APIs
 ******************************************************************************/
const favoriteAPI = {
  // Get array of all a user's favorites
  fetchFavorites: () => {
    const api = new API();
    return api.get(`/api/favorites`);
  },

  // Add a favorite
  add: (tableID: string) => {
    const api = new API();
    const bodyProps = {
      table: tableID,
    };
    return api.post(`/api/favorites`, bodyProps);
  },

  // Delete a favorite
  delete: (favoriteID: string) => {
    const api = new API();
    return api.delete(`/api/favorites/${favoriteID}`, {});
  },
};
export default favoriteAPI;

/*******************************************************************************
 * Conversion Utilites
 ******************************************************************************/
