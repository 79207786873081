/*
  Osano could block Segment and other optional third party libraries from loading.
  This file contains tools to help deal with that.
*/

// Note: I tried appending `getAnonymousId()` to the `window.analytics` object in `analytics.ready()`, and that didn't seem to work.
export function getAnonymousId() {
  const analytics = window.analytics;
  let anonymousId = null;
  if (analytics && typeof analytics.user === 'function') {
    const user = analytics.user();
    if (user && typeof user.anonymousId === 'function') {
      anonymousId = user.anonymousId() || null;
    }
  }
  return anonymousId;
}

// Useful for debugging
window.getAnonymousId = getAnonymousId;

declare global {
  interface Window {
    getAnonymousId: () => string | null;
  }
}
