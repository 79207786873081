/* Utils that help render a basic flowchart graph */
import { STYLES_BY_TYPE } from '../Flowchart.styles';

/** Return object of styles to apply to rendered vertex */
export const getVertexStyle = () => {
  return STYLES_BY_TYPE.Normal;
};

/** Returns color of edge based on source vertex status */
export const getEdgeColor = (isSelected: boolean) => {
  return isSelected ? STYLES_BY_TYPE.Normal.selectedEdgeColor : STYLES_BY_TYPE.Normal.edgeColor;
};

/** Returns constants for different zoom levels commonly used for flowchart graphs */
export const getZoomLevels = (cy: cytoscape.Core) => ({
  /** Should be able to see full graph */
  low: cy.zoom(),
  /** Should be able to see a centered vertex and its surrounding vertices */
  mid: 1.1,
  /** Should be able to see a centered vertex and its surrounding edges */
  high: 2.5,
});
