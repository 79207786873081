import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import { Connector } from 'api/APITypes';
import { Dashboard } from 'api/dashboardAPI';

import { trackDashboard } from '../reportUtils';

import SetupCompleteView from './SetupCompleteView';

interface SetupCompleteControllerProps {
  connector: Connector;
  dashboard: Dashboard;
}

export default function SetupCompleteController(props: SetupCompleteControllerProps) {
  useTitle('Setup Complete');
  const { connector, dashboard } = props;
  const navigate = useNavigate();

  const handleAddConnector = () => {
    trackDashboard('ReportBuilderSetupComplete GoToAddConnector', connector, dashboard);
    navigate('/connectors/add');
  };

  const handleGoToConnectors = () => {
    trackDashboard('ReportBuilderSetupComplete GoToListConnectors', connector, dashboard);
    navigate('/connectors');
  };

  return (
    <SetupCompleteView
      connector={connector}
      onAddConnector={handleAddConnector}
      onGoToConnectors={handleGoToConnectors}
    />
  );
}
