import React from 'react';

import cn from 'classnames';

import CircularProgressBar from 'components/widgets/progress/CircularProgressBar/CircularProgressBar';
import { cleanColor } from 'utils/String';
import { formatToDollarAmount } from 'utils/String';
import { formatNumber } from 'utils/String';

import { UsageMode } from '../layout/LayoutModel';

type UsageMetricWidgetProps = {
  containerClass: string;
  headerText?: string;
  units: string;
  cost: number;
  monthlyUnitsUsed: number;
  monthlyAllotment: number;
  annualUnitsUsed: number;
  annualAllotment: number;
  showOverage: boolean;
  overageCost: number;
  backgroundColor: string; // Background color of the card
  progressBarBackgroundColor: string;
  textAndBarColor: string;
  mode?: UsageMode;
};

export default function UsageMetricWidget(props: UsageMetricWidgetProps) {
  const {
    containerClass,
    headerText,
    units,
    cost,
    monthlyUnitsUsed,
    monthlyAllotment,
    annualUnitsUsed,
    annualAllotment,
    showOverage,
    overageCost,
    backgroundColor,
    progressBarBackgroundColor,
    textAndBarColor,
    mode,
  } = props;

  const warningColor = cleanColor('--pri-warning-500');

  let actualUnitsUsed = monthlyUnitsUsed || 0;
  let actualUnitsAlloted = monthlyAllotment || 0;
  if (mode === 'annual') {
    actualUnitsUsed = annualUnitsUsed || 0;
    actualUnitsAlloted = annualAllotment || 0;
  }

  const isOver = actualUnitsUsed > actualUnitsAlloted;

  const actualBackgroundColor = cleanColor(backgroundColor);
  const actualProgressBarBackgroundColor = cleanColor(progressBarBackgroundColor);
  const actualTextAndBarColor = cleanColor(textAndBarColor);

  const overageAmount = isOver ? actualUnitsUsed - actualUnitsAlloted : 0;

  return (
    <div
      className={cn('p-4 block relative', containerClass)}
      style={{ backgroundColor: actualBackgroundColor }}
    >
      {mode !== 'cost' && (
        <>
          <span>
            <h2 className="text-xl font-medium">{headerText}</h2>

            <div className="py-2 flex">
              <CircularProgressBar
                barColor={isOver ? warningColor : actualTextAndBarColor}
                backgroundColor={actualProgressBarBackgroundColor}
                numerator={actualUnitsUsed}
                denominator={actualUnitsAlloted}
              />

              <div className="py-2 ml-4">
                <h3
                  className="display-base font-medium"
                  style={{ color: isOver ? warningColor : actualTextAndBarColor }}
                >
                  {actualUnitsUsed?.toLocaleString()}
                </h3>
                <p>
                  out of {actualUnitsAlloted?.toLocaleString()} {units}
                </p>
              </div>
            </div>
          </span>
          {showOverage && (
            <div className="flex">
              <div className="w-1/2">
                <div className="font-medium">Overage Cost</div>
                <div className="mt-2 display-xs font-medium" style={{ color: actualTextAndBarColor }}>
                  ${formatToDollarAmount(overageCost || 0)}
                </div>
              </div>
              <div className="w-1/2">
                <div className="font-medium">Overage Amount</div>
                <div className="mt-2 display-xs font-medium" style={{ color: actualTextAndBarColor }}>
                  {formatNumber(overageAmount)}
                </div>
                <p className="text-sm">{units}</p>
              </div>
            </div>
          )}
          {mode === 'annual' && (
            <span>
              <h3 className="font-medium">Average Monthly Usage</h3>
              <p className="display-xs pt-2 font-medium" style={{ color: actualTextAndBarColor }}>
                {monthlyUnitsUsed?.toLocaleString()}
              </p>
              <p>
                {units} out of {formatNumber(monthlyAllotment)}
              </p>
            </span>
          )}
        </>
      )}
      {mode === 'cost' && (
        <>
          <span>
            <h2 className="text-xl font-medium">{headerText}</h2>

            <div className="py-2 ">
              <h3 className="display-base font-medium" style={{ color: actualTextAndBarColor }}>
                ${formatToDollarAmount(cost)}
              </h3>
              <p>current cost</p>
            </div>
          </span>
          <span>
            <p className="display-xs pt-2 font-medium" style={{ color: actualTextAndBarColor }}>
              {monthlyUnitsUsed?.toLocaleString()}
            </p>
            <p>{units}</p>
          </span>
        </>
      )}
    </div>
  );
}
