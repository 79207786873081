import React from 'react';

import MonthRangeInput from './MonthRangeInput/MonthRangeInput';

// Commentary on Date types:
//   There is an arguement we should use Date() objects and not date strings
//   for this component's date props.
//   For the time being we are going to use strings since that is the
//   format the API uses in the one place we use this component as of 2021-08-02.
//   This also eliminates ambiguity around time of day.
//   Right now Date() objects are considered an internal implmentation detail of `MonthRangeInput.tsx`.
//   Feel free to reverse this decision if it proves a bad idea.
export interface MonthRangePickerProps {
  startDate: string; // YYYY-MM-DD
  endDate: string; // YYYY-MM-DD
  startMonths: string[]; // Array of YYYY-MM-DD
  endMonths: string[]; // Array of YYYY-MM-DD
  setDateRange: (startDate: string, endDate: string) => void;
}

export default function MonthRangePicker(props: MonthRangePickerProps) {
  const { startDate, endDate, startMonths, endMonths, setDateRange } = props;

  const handleSetStart = (startDate: string) => {
    setDateRange(startDate, endDate);
  };

  const handleSetEnd = (endDate: string) => {
    setDateRange(startDate, endDate);
  };

  return (
    <div className="f-center">
      <MonthRangeInput value={startDate} months={startMonths} onSetDate={handleSetStart} />
      <span className="mx-2">to</span>
      <MonthRangeInput
        value={endDate}
        months={endMonths}
        invalid={endDate < startDate}
        onSetDate={handleSetEnd}
      />
    </div>
  );
}
