import React from 'react';

export interface CenterIconProps {
  color?: string;
  size?: number;
}

const CenterIcon = (props: CenterIconProps) => {
  const { color = '#4E5BA6', size = 16 } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="5.5" stroke={color} />
      <line x1="0.5" y1="8" x2="2.5" y2="8" stroke={color} strokeLinecap="round" />
      <line x1="13.5" y1="8" x2="15.5" y2="8" stroke={color} strokeLinecap="round" />
      <line x1="8" y1="2.5" x2="8" y2="0.5" stroke={color} strokeLinecap="round" />
      <line x1="8" y1="15.5" x2="8" y2="13.5" stroke={color} strokeLinecap="round" />
      <circle cx="8" cy="8" r="2" fill={color} />
    </svg>
  );
};

export default CenterIcon;
