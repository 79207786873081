import { useCallback, useEffect, useReducer, useRef } from 'react';

import { AxiosResponse } from 'axios';

import { AggTable, Transform } from 'api/APITypes';
import {
  LIST_CONNECTORS,
  LIST_RECENTS,
  LIST_FAVORITES,
  LIST_TAGS,
  LIST_DBT_RUN_CONFIGS,
  LIST_DBT_DESTINATION_TABLES,
  LIST_LATEST_CSV_UPLOADS,
} from 'api/CacheKeys';
import { CSVUpload } from 'api/csvUploadAPI';
import { DbtRunConfig } from 'api/dbtAPI';
import { Favorite, FavoritesByTableID } from 'api/favoriteAPI';
import tableVisitAPI, { TableVisit } from 'api/tableVisitAPI';
import { Tag } from 'api/tagAPI';
import useLocalStorageGet, { useLocalStorageSet } from 'hooks/useLocalStorageGet';
import { patchOrAppend, updateManyByID } from 'utils/Array';

import { reducer, initialState, ConnectorsBySchema } from './AggTableReducer';
import {
  maybeMockConnectors,
  maybeMockCSVUploads,
  maybeMockDbtDestinationTables,
  maybeMockDbtRunConfigs,
  maybeMockFavorites,
  maybeMockRecents,
  maybeMockTags,
} from './maybeMock';
import { TableContextInterface } from './TableContext';
import { TransformContextInterface } from './TransformContext';

// Re-export some types:
export type { AggTable, ConnectorsBySchema, TableVisit, Favorite, FavoritesByTableID, Tag };

/*
This context should be used by any page that wants to list tables with details about
their highly correlated models(connectors, transforms, recents, favorites, tags).

This hook is responsible for:
1. Fetching the lists of all of those models in an account from the API.
2. Holding state in a global context so data doesn't have to be refetched on URL navigation.
3. Computing any data structures many pages would want to reuse.
4. Providing reusable functions and utilities that many pages would want to use.

Use:
export default function Warehouse() {
  const {
    tables, // AggTables with transforms and connectors appended by AggTableReducer.
    tags,
    recents,
    favorites,
    allLoaded,
    anyLocal,
    // All of the other table related variables and funtions you might want.
  } = useContext(TableModelContext);
  return <div>{tables}</div>
};

Expected consumers of this:
1. Warehouse
2. ShowTable, especially the PipelineTab
3. TableExplorer
4. Home Page
5. CommandPalette
6. Hypothetical Tag Warehouse

*/

export default function useTableModelsContext(
  tableContext: TableContextInterface,
  transformContext: TransformContextInterface,
) {
  // AggTableReducer attaches connectors and transforms to tables to get a list of AggTables.
  const [reducerState, dispatch] = useReducer(reducer, initialState);
  const {
    connectors,
    hasConnector,
    connectorsByID,
    connectorsBySchema,
    tables,
    tablesByID,
    tablesByFullName,
    transforms,
    transformsByID,
    recentTables,
    favoriteTables,
    recents,
    favorites,
    favoritesByTableID,
    tags,
    dbtRunConfigs,
    dbtRunConfigsByID,
    dbtDestinationTables,
    dbtDestinationTablesByID,
    latestCsvUploads,
    aggTablesLoaded,
    loadedConnectors,
    loadedTransforms,
    loadedDbtRunConfigs,
  } = reducerState;
  const localStorageSet = useLocalStorageSet();

  /*****************************************************************************
   * Load Connectors from API
   ****************************************************************************/
  const {
    isLoading: connectorsIsLoading,
    data: connectorsFromAPI,
    error: connectorsErrorObj,
    isLocal: connectorsIsLocal,
  } = useLocalStorageGet(LIST_CONNECTORS, '/api/fivetran/connectors');
  const connectorsError = connectorsErrorObj
    ? connectorsErrorObj.message || 'Unknown error fetching connectors.'
    : '';

  useEffect(() => {
    if (!connectorsError) {
      // On 12/05/22 we investigated and failed to figure out why response could be undefined.
      // We added the `&& connectorsFromAPI !== undefined` as a temporary bandaid
      const hasData = (connectorsIsLocal || !connectorsIsLoading) && connectorsFromAPI !== undefined;
      if (hasData) {
        dispatch({ type: 'SET_CONNECTORS', data: maybeMockConnectors(connectorsFromAPI) });
      }
    }
  }, [connectorsIsLoading, connectorsFromAPI, connectorsError, connectorsIsLocal]);

  /*****************************************************************************
   * Load tables from TableContext(AKA API)
   ****************************************************************************/
  const {
    tables: tablesFromContext,
    error: tablesError,
    isLocal: tablesIsLocal,
    isLoading: tablesIsLoading,
    updateTables,
  } = tableContext;

  // Only set tables if we have a list of tables and the table list is different than last time.
  // tablesFromContext, tablesIsLocal, and tablesIsLoading are set consecutively, but
  // React can update update them independently causing unnecessary rerenders.
  const lastDispatchedTables = useRef<AggTable[] | null>(null);
  useEffect(() => {
    if (!tablesError) {
      const hasData = tablesIsLocal || !tablesIsLoading;
      if (hasData && lastDispatchedTables.current !== tablesFromContext) {
        lastDispatchedTables.current = tablesFromContext;
        dispatch({ type: 'SET_TABLES', data: tablesFromContext });
      }
    }
  }, [tablesFromContext, tablesError, tablesIsLocal, tablesIsLoading]);

  /*****************************************************************************
   * Load transforms from TransformContext(AKA API)
   ****************************************************************************/
  const {
    transforms: transformsFromContext,
    error: transformsError,
    isLocal: transformsIsLocal,
    isLoading: transformsIsLoading,
    updateTransforms,
  } = transformContext;

  // See note on lastDispatchedTables above.
  const lastDispatchedTransforms = useRef<Transform[] | null>(null);
  useEffect(() => {
    if (!transformsError) {
      const hasData = transformsIsLocal || !transformsIsLoading;
      if (hasData && lastDispatchedTransforms.current !== transformsFromContext) {
        lastDispatchedTransforms.current = transformsFromContext;
        dispatch({ type: 'SET_TRANSFORMS', data: transformsFromContext });
      }
    }
  }, [transformsFromContext, transformsError, transformsIsLocal, transformsIsLoading]);

  /*****************************************************************************
   * Load TableVisits(Recents) from API
   *
   * Note:
   * The backend model is a `TableVisit`. The frontend thinks in terms of
   * "recent tables". Every point in the code path after this point will
   * call `TableVisit` objects `recentX`.
   ****************************************************************************/
  const {
    isLoading: recentsIsLoading,
    data: recentsFromAPI,
    error: recentsError,
    isLocal: recentsIsLocal,
  } = useLocalStorageGet(LIST_RECENTS, '/api/tables/recent');

  useEffect(() => {
    if (!recentsError) {
      const hasData = recentsIsLocal || !recentsIsLoading;
      if (hasData) {
        dispatch({ type: 'SET_RECENTS', data: maybeMockRecents(recentsFromAPI) });
      }
    }
  }, [recentsError, recentsIsLocal, recentsIsLoading, recentsFromAPI]);

  /*****************************************************************************
   * logRecent(TableVisit)
   ****************************************************************************/
  const logRecent = useCallback(
    (tableID: string) => {
      tableVisitAPI
        .add(tableID)
        .then((response: AxiosResponse<TableVisit>) => {
          const newRecent = response.data;
          // Move newRecent to the start of the list
          const newRecentFirst = recents.filter((r) => r.id !== newRecent.id);
          newRecentFirst.unshift(newRecent);
          // Save new list to system
          dispatch({ type: 'SET_RECENTS', data: newRecentFirst });
          localStorageSet(LIST_RECENTS, newRecentFirst);
        })
        .catch((e) => {
          // Silently do noting on this error.
          // It's a nice to have because it doesn't effect user inputed data.
        });
    },
    [recents, localStorageSet],
  );

  /*****************************************************************************
   * Load Favorites from API
   ****************************************************************************/
  const {
    isLoading: favoritesIsLoading,
    data: favoritesFromAPI,
    error: favoritesError,
    isLocal: favoritesIsLocal,
  } = useLocalStorageGet(LIST_FAVORITES, '/api/favorites');

  useEffect(() => {
    if (!favoritesError) {
      const hasData = favoritesIsLocal || !favoritesIsLoading;
      if (hasData) {
        dispatch({ type: 'SET_FAVORITES', data: maybeMockFavorites(favoritesFromAPI) });
      }
    }
  }, [favoritesError, favoritesIsLocal, favoritesIsLoading, favoritesFromAPI]);

  /*****************************************************************************
   * addFavorite, deleteFavorite
   ****************************************************************************/
  const addFavorite = useCallback(
    (newFavorite: Favorite) => {
      const mergedFavorites = updateManyByID(favorites, [newFavorite]);
      dispatch({ type: 'SET_FAVORITES', data: mergedFavorites });
      localStorageSet(LIST_FAVORITES, mergedFavorites);
    },
    [favorites, localStorageSet],
  );

  const removeFavorite = useCallback(
    (deletedFavorite: Favorite) => {
      const filteredFavorites = favorites.filter((f) => f.id !== deletedFavorite.id);
      dispatch({ type: 'SET_FAVORITES', data: filteredFavorites });
      localStorageSet(LIST_FAVORITES, filteredFavorites);
    },
    [favorites, localStorageSet],
  );

  /*****************************************************************************
   * Load Tags from API
   ****************************************************************************/
  const {
    isLoading: tagsIsLoading,
    data: tagsFromAPI,
    error: tagsError,
    isLocal: tagsIsLocal,
  } = useLocalStorageGet(LIST_TAGS, '/api/tags');

  useEffect(() => {
    if (!tagsError) {
      const hasData = tagsIsLocal || !tagsIsLoading;
      if (hasData) {
        dispatch({ type: 'SET_TAGS', data: maybeMockTags(tagsFromAPI) });
      }
    }
  }, [tagsError, tagsIsLocal, tagsIsLoading, tagsFromAPI]);

  const addTag = useCallback(
    (newTag: Tag) => {
      const mergedTags = updateManyByID(tags, [newTag]);
      dispatch({ type: 'SET_TAGS', data: mergedTags });
      localStorageSet(LIST_TAGS, mergedTags);
    },
    [tags, localStorageSet],
  );

  const removeTag = useCallback(
    (deletedTag: Tag) => {
      const filteredTags = tags.filter((t) => t.id !== deletedTag.id);
      dispatch({ type: 'SET_TAGS', data: filteredTags });
      localStorageSet(LIST_TAGS, filteredTags);
    },
    [tags, localStorageSet],
  );

  // Update or extend list of tags in table models context.
  const updateTags = useCallback(
    (newTags: Tag[]) => {
      const mergedTags = updateManyByID(tags, newTags);
      dispatch({ type: 'SET_TAGS', data: mergedTags });
      localStorageSet(LIST_TAGS, mergedTags);
    },
    [dispatch, localStorageSet, tags],
  );

  /*****************************************************************************
   * Load DBT Run Configs from API
   ****************************************************************************/
  const {
    isLoading: dbtRunConfigsIsLoading,
    data: dbtRunConfigsFromAPI,
    error: dbtRunConfigsError,
    isLocal: dbtRunConfigsIsLocal,
  } = useLocalStorageGet(LIST_DBT_RUN_CONFIGS, '/api/dbt_run_configurations');

  useEffect(() => {
    if (!dbtRunConfigsError) {
      const hasData = dbtRunConfigsIsLocal || !dbtRunConfigsIsLoading;
      if (hasData) {
        dispatch({ type: 'SET_DBT_RUN_CONFIGS', data: maybeMockDbtRunConfigs(dbtRunConfigsFromAPI) });
      }
    }
  }, [dbtRunConfigsError, dbtRunConfigsIsLocal, dbtRunConfigsIsLoading, dbtRunConfigsFromAPI]);

  const addDbtRunConfig = useCallback(
    (newDbtRunConfig: DbtRunConfig) => {
      const mergedDbtRunConfigs = updateManyByID(dbtRunConfigs, [newDbtRunConfig]);
      dispatch({ type: 'SET_DBT_RUN_CONFIGS', data: mergedDbtRunConfigs });
      localStorageSet(LIST_DBT_RUN_CONFIGS, mergedDbtRunConfigs);
    },
    [dbtRunConfigs, localStorageSet],
  );

  const removeDbtRunConfig = useCallback(
    (deletedDbtRunConfig: DbtRunConfig) => {
      const filteredDbtRunConfigs = dbtRunConfigs.filter((t) => t.id !== deletedDbtRunConfig.id);
      dispatch({ type: 'SET_DBT_RUN_CONFIGS', data: filteredDbtRunConfigs });
      localStorageSet(LIST_DBT_RUN_CONFIGS, filteredDbtRunConfigs);
    },
    [dbtRunConfigs, localStorageSet],
  );

  const updateDbtRunConfigs = useCallback(
    (newDbtRunConfigs: DbtRunConfig[]) => {
      const mergedDbtRunConfigs = updateManyByID(dbtRunConfigs, newDbtRunConfigs);
      dispatch({ type: 'SET_DBT_RUN_CONFIGS', data: mergedDbtRunConfigs });
      localStorageSet(LIST_DBT_RUN_CONFIGS, mergedDbtRunConfigs);
    },
    [dbtRunConfigs, localStorageSet],
  );

  /*****************************************************************************
   * Load DBT Destination Tables from API
   ****************************************************************************/
  const {
    isLoading: dbtDestinationTablesIsLoading,
    data: dbtDestinationTablesFromAPI,
    error: dbtDestinationTablesError,
    isLocal: dbtDestinationTablesIsLocal,
  } = useLocalStorageGet(LIST_DBT_DESTINATION_TABLES, '/api/dbt_destination_tables');

  useEffect(() => {
    if (!dbtDestinationTablesError) {
      const hasData = dbtDestinationTablesIsLocal || !dbtDestinationTablesIsLoading;
      if (hasData) {
        dispatch({
          type: 'SET_DBT_DESTINATION_TABLES',
          data: maybeMockDbtDestinationTables(dbtDestinationTablesFromAPI),
        });
      }
    }
  }, [
    dbtDestinationTablesError,
    dbtDestinationTablesIsLocal,
    dbtDestinationTablesIsLoading,
    dbtDestinationTablesFromAPI,
  ]);

  /*****************************************************************************
   * Load Latest CSV Uploads from API
   ****************************************************************************/
  const {
    isLoading: latestCSVUploadsIsLoading,
    data: latestCSVUploadsFromAPI,
    error: latestCSVUploadsError,
    isLocal: latestCSVUploadsIsLocal,
  } = useLocalStorageGet(LIST_LATEST_CSV_UPLOADS, '/api/csv_uploads/latest');

  useEffect(() => {
    if (!latestCSVUploadsError) {
      const hasData = latestCSVUploadsIsLocal || !latestCSVUploadsIsLoading;
      if (hasData) {
        dispatch({ type: 'SET_LATEST_CSV_UPLOADS', data: maybeMockCSVUploads(latestCSVUploadsFromAPI) });
      }
    }
  }, [
    latestCSVUploadsError,
    latestCSVUploadsIsLocal,
    latestCSVUploadsIsLoading,
    latestCSVUploadsFromAPI,
  ]);

  const addCsvUpload = useCallback(
    (newCsvUpload: CSVUpload) => {
      const mergedCsvUploads = patchOrAppend<CSVUpload>(
        latestCsvUploads,
        newCsvUpload,
        (upload) => upload.table === newCsvUpload.table,
      );
      dispatch({ type: 'SET_LATEST_CSV_UPLOADS', data: mergedCsvUploads });
      localStorageSet(LIST_LATEST_CSV_UPLOADS, mergedCsvUploads);
    },
    [latestCsvUploads, localStorageSet],
  );

  const removeCsvUpload = useCallback(
    (deletedCsvUpload: CSVUpload) => {
      const filteredCsvUploads = latestCsvUploads.filter((t) => t.id !== deletedCsvUpload.id);
      dispatch({ type: 'SET_LATEST_CSV_UPLOADS', data: filteredCsvUploads });
      localStorageSet(LIST_LATEST_CSV_UPLOADS, filteredCsvUploads);
    },
    [latestCsvUploads, localStorageSet],
  );

  const updateCsvUpload = useCallback(
    (newCsvUpload: CSVUpload) => {
      const mergedCsvUploads = patchOrAppend<CSVUpload>(
        latestCsvUploads,
        newCsvUpload,
        (upload) => upload.table === newCsvUpload.table,
      );
      dispatch({ type: 'SET_LATEST_CSV_UPLOADS', data: mergedCsvUploads });
      localStorageSet(LIST_LATEST_CSV_UPLOADS, mergedCsvUploads);
    },
    [latestCsvUploads, localStorageSet],
  );

  /*****************************************************************************
   * Aggregate variables and render.
   ****************************************************************************/
  // Can I render content instead of a component wide spinner?
  // `aggTablesLoaded` means the table objects have all of their associated objects attached.
  // This is variable is just renaming the reducer's variable `aggTablesLoaded`.
  const allLoaded = aggTablesLoaded;
  // Am I serving data from localStorage or a fresh copy from the API?
  const anyLocal =
    connectorsIsLocal ||
    tablesIsLocal ||
    transformsIsLocal ||
    recentsIsLocal ||
    favoritesIsLocal ||
    tagsIsLocal ||
    dbtRunConfigsIsLocal ||
    dbtDestinationTablesIsLocal ||
    latestCSVUploadsIsLocal;
  const anyError =
    connectorsError ||
    tablesError ||
    transformsError ||
    recentsError ||
    favoritesError ||
    tagsError ||
    dbtRunConfigsError ||
    dbtDestinationTablesError ||
    latestCSVUploadsError;

  return {
    connectors,
    hasConnector,
    connectorsByID,
    connectorsBySchema,
    tables, // AggTables with transforms and connectors appended by AggTableReducer.
    tablesByID,
    tablesByFullName,
    transforms,
    transformsByID,
    recentTables,
    favoriteTables,
    favoritesByTableID,
    tags,
    dbtRunConfigs,
    dbtRunConfigsByID,
    dbtDestinationTables,
    dbtDestinationTablesByID,
    latestCsvUploads,
    allLoaded,
    loadedConnectors,
    loadedTransforms,
    anyLocal,
    anyError,
    connectorsError,
    transformsError,
    loadedDbtRunConfigs,
    updateTables,
    updateTransforms,
    logRecent,
    addFavorite,
    removeFavorite,
    addTag,
    removeTag,
    updateTags,
    addDbtRunConfig,
    removeDbtRunConfig,
    updateDbtRunConfigs,
    addCsvUpload,
    removeCsvUpload,
    updateCsvUpload,
  };
}
