import { BannerState } from '../useBannerState';

import AIAssistantPromotionBanner, {
  AIAssistantPromotionBannerProps,
} from './banners/PromotionBanner/AIAssistantPromotionBanner';
import ReferralPromotionBanner, {
  ReferralPromotionBannerProps,
} from './banners/PromotionBanner/ReferralPromotionBanner';
import TrialBanner, { TrialBannerProps } from './banners/TrialBanner/TrialBanner';
import UsageWarningBanner, {
  UsageWarningBannerProps,
} from './banners/UsageWarningBanner/UsageWarningBanner';

export default function useShowBanner(bannerState: BannerState | undefined) {
  if (!bannerState) {
    return null;
  }

  if (bannerState.banner === 'UsageWarning') {
    return <UsageWarningBanner {...(bannerState.bannerProps as UsageWarningBannerProps)} />;
  }

  if (bannerState.banner === 'Trial') {
    return <TrialBanner {...(bannerState.bannerProps as TrialBannerProps)} />;
  }

  if (bannerState.banner === 'ReferralPromotion') {
    return <ReferralPromotionBanner {...(bannerState.bannerProps as ReferralPromotionBannerProps)} />;
  }

  if (bannerState.banner === 'AIAssistantPromotion') {
    return (
      <AIAssistantPromotionBanner {...(bannerState.bannerProps as AIAssistantPromotionBannerProps)} />
    );
  }

  return null;
}
