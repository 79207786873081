import React, { SyntheticEvent, useEffect, useState } from 'react';

import { Dictionary } from 'lodash';

import { AppConnector, UserProfile } from 'api/APITypes';
import { Dashboard } from 'api/dashboardAPI';
import AnimatedAngleIcon from 'components/primitives/icons/AnimatedAngleIcon/AnimatedAngleIcon';
import ConnectorIcon from 'components/primitives/icons/ConnectorIcon/ConnectorIcon';
import { ConnectorStatus, getConnectorStatus, lastRunForConnector } from 'model_helpers/connectorHelper';

import ConnectorRow from './ConnectorRow';
import StatusCol, { StatusColProps } from './StatusCol';

interface ConnectorRowGroupProps {
  service: string;
  connectors: AppConnector[];
  userProfile: UserProfile;
  urlSelectedFivetranId: string;
  showDashboardColumn: boolean;
  dashboardAvailable: boolean;
  dashboardsInstalledByConnectorID: Dictionary<Dashboard>;
}

type StatusCountsAgg = { [key in ConnectorStatus]: { count: number; props: StatusColProps } };

const ConnectorRowGroup = (props: ConnectorRowGroupProps) => {
  const {
    service,
    connectors,
    userProfile,
    urlSelectedFivetranId,
    showDashboardColumn,
    dashboardAvailable,
    dashboardsInstalledByConnectorID,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  // Open group if urlSelectedFivetranId is in the id query param
  useEffect(() => {
    const hasSelectedConnector = connectors
      .map((c) => c.fivetran_connector_id)
      .some((id: string) => id === urlSelectedFivetranId);
    if (hasSelectedConnector) {
      setIsOpen(true);
    }
  }, [urlSelectedFivetranId, connectors]);

  const statusCounts = connectors.map((c) => c.status);

  const statusAgg: StatusCountsAgg = statusCounts.reduce((acc: StatusCountsAgg, rowStatusObj: any) => {
    const { sync_state, setup_state } = rowStatusObj;
    const { lastRunFailed } = lastRunForConnector(rowStatusObj);
    const status = getConnectorStatus(setup_state, sync_state, lastRunFailed);
    acc[status as ConnectorStatus] = {
      count: (acc[status] && acc[status].count + 1) || 1,
      props: { setup_state, lastRunFailed, sync_state },
    };
    return acc;
  }, {} as StatusCountsAgg);

  const statusAggOrdered = [
    ...Object.entries(statusAgg).filter(([status]) => status === 'success'),
    ...Object.entries(statusAgg).filter(([status]) => status === 'syncing'),
    ...Object.entries(statusAgg).filter(([status]) => status === 'paused'),
    ...Object.entries(statusAgg).filter(([status]) => status === 'warning'),
    ...Object.entries(statusAgg).filter(([status]) => status === 'incomplete'),
    ...Object.entries(statusAgg).filter(([status]) => status === 'broken'),
    ...Object.entries(statusAgg).filter(([status]) => status === 'error'),
    ...Object.entries(statusAgg).filter(([status]) => status === 'NA'),
  ];

  return (
    <>
      <tr
        key={`row_group_${service}`}
        className={`${isOpen && 'bg-pri-gray-50'} hover:cursor-pointer`}
        onClick={(e: SyntheticEvent) => {
          setIsOpen(!isOpen);
        }}
      >
        <td className="!px-2 !w-[80px] !min-w-[80px] clearfix">
          <div className={`inline-block float-left mr-2 mt-[3px]`}>
            <AnimatedAngleIcon isOpen={isOpen} mode="accordion" />
          </div>

          <ConnectorIcon service={service} size={24} />
        </td>
        <td>
          <span>
            <div className="text-base font-medium text-pri-gray-700 inline-block">{service}</div>
            <div className="inline-block ml-4">{connectors.length} Connectors</div>
          </span>
        </td>
        <td></td>
        <td></td>
        <td className="w-[135px]">
          <div className="flex">
            {statusAggOrdered.map(([status, { count, props }]) => (
              <StatusCol key={status} {...props} count={count} />
            ))}
          </div>
        </td>
        {showDashboardColumn && <td className="text-center"></td>}
      </tr>

      {isOpen &&
        connectors.map((c) => (
          <ConnectorRow
            key={c.fivetran_connector_id}
            rowClassName="bg-pri-gray-50"
            {...c}
            selected={c.fivetran_connector_id === urlSelectedFivetranId}
            userProfile={userProfile}
            showDashboardColumn={showDashboardColumn}
            dashboardAvailable={dashboardAvailable}
            hasDashboard={!!dashboardsInstalledByConnectorID[c.id]}
          />
        ))}
    </>
  );
};
export default ConnectorRowGroup;
