import { AggTable } from 'api/APITypes';
import LinkIconButton from 'components/inputs/basic/Button/LinkIconButton';
import { getMostRecentRunConfig } from 'model_helpers/dbtAggTableHelper';
import { dbtRunConfigurationPath } from 'utils/PathMaker';

interface SummaryTabProps {
  table: AggTable;
}

export default function SummaryTab(props: SummaryTabProps) {
  const { table } = props;

  const { description } = table;

  return (
    <div className="w-full h-full max-h-full pt-4">
      <div className="w-full h-full max-h-full overflow-auto">
        <div className="font-medium">Description</div>
        <p className="mt-2 whitespace-pre-wrap">
          {description ? description : <span className="text-pri-gray-400">None</span>}
        </p>
        {table.type === 'dbt' && (
          <LinkIconButton
            icon="BoxArrowUpRight"
            text="GO TO DBT JOB"
            iconLast={true}
            iconSize={10}
            size="small"
            variant="lightDullTransparent"
            to={dbtRunConfigurationPath(getMostRecentRunConfig(table)?.id || '')}
            data-track={`Warehouse GoToDBTConfig`}
            target="_blank"
            className="mt-4"
          />
        )}
      </div>
    </div>
  );
}
