import React, { useState, useEffect, useCallback } from 'react';

import _ from 'lodash';

import API from 'api/API';
import {
  Transform,
  SaveableTransformProps,
  SaveableTransformAndAncestorProps,
  AggTable,
} from 'api/APITypes';
import { Column } from 'api/columnAPI';
import QueryEditor from 'components/query/QueryEditor';
import useQueryEditor from 'components/query/useQueryEditor';
import { DependencyEdge } from 'pages/tables/ShowTable/PipelineTab/PipelineEditor/PipelineEditor';

import TransformOptionsBar from './TransformOptionsBar/TransformOptionsBar';

const EVENT_PREFIX = 'TransformTab';

// This is the same as AggTable, but transform cannot be null
export interface TransformTable extends Omit<AggTable, 'transform'> {
  transform: Transform;
}

interface TransformTabProps {
  table: TransformTable;
  saving: boolean;
  hasSavingError: boolean;
  vertexDependencies: DependencyEdge[];
  saveTransform(transform: SaveableTransformProps): Promise<Transform> | undefined;
  onSaveTransformAndAncestors: (
    transformId: string, // The id of the transform to edit
    transformAndAncestorSaveProps: SaveableTransformAndAncestorProps,
  ) => Promise<{ transform: Transform; ancestors: DependencyEdge[] }>;
  setUnsavedSql(unsaved: boolean): void;
}

const TransformTab = (props: TransformTabProps) => {
  const {
    table,
    saving,
    vertexDependencies,
    saveTransform,
    onSaveTransformAndAncestors,
    setUnsavedSql,
  } = props;
  const [incrementalUpdateKeys, setIncrementalUpdateKeys] = useState<string[] | null>(null); // Names of columns produced by saved SQL
  const [validSql, setValidSql] = useState(true); // If the currently saved sql is valid and can get update keys
  const [loadingUpdateKeys, setLoadingUpdateKeys] = useState(true); // If the currently saved sql is valid and can get update keys

  const queryEditorState = useQueryEditor({
    eventPrefix: EVENT_PREFIX,
    errorOnDuplicateColumns: true,
    object: { id: table.transform.id, type: 'transform' },
  });

  const { onExportCsv, onExportGSheet } = queryEditorState.queryRunnerState;

  const { editorSql } = queryEditorState.sqlState;

  const fetchUpdateKeys = useCallback(() => {
    const api = new API();
    const postData = {
      sql: table.transform.sql,
      incremental: true, // We only care about the keys when incremental is true, so we can display them when user toggles incremental on
    };
    setLoadingUpdateKeys(true);
    api
      .post('api/generate_columns', postData)
      .then((response: any) => {
        const allColumns = response.data.columns.map((c: Column) => c.name);
        const setableColumns = _.difference(allColumns, ['_FIVETRAN_DELETED', '_FIVETRAN_SYNCED']);
        setIncrementalUpdateKeys(setableColumns);
        setValidSql(true);
      })
      .catch((error) => {
        setValidSql(false);
      })
      .finally(() => {
        setLoadingUpdateKeys(false);
      });
  }, [table.transform.sql]);

  // Refetch the incremental update keys every time the user saves the SQL
  // or toggles on incremental.
  useEffect(() => {
    fetchUpdateKeys();
  }, [fetchUpdateKeys]);

  const handleSaveSql = useCallback(
    (versionDescription?: string) => {
      const saveTransformPromise = saveTransform({
        sql: editorSql,
        version_description: versionDescription,
      });
      if (saveTransformPromise) {
        return saveTransformPromise.then((newTransform) => newTransform.sql);
      }
      return;
    },
    [editorSql, saveTransform],
  );

  const handleExportCsv = useCallback(
    () => onExportCsv(`${table.schema}_${table.name}.csv`),
    [onExportCsv, table.schema, table.name],
  );

  const handleExportGSheet = useCallback(
    () => onExportGSheet(`${table.schema}_${table.name}`, `${table.name}`),
    [onExportGSheet, table.schema, table.name],
  );

  return (
    <div className="h-full min-h-0 flex flex-col">
      <TransformOptionsBar
        table={table}
        incrementalUpdateKeys={incrementalUpdateKeys}
        validSql={validSql}
        loadingUpdateKeys={loadingUpdateKeys}
        vertexDependencies={vertexDependencies}
        onSaveTransformAndAncestors={onSaveTransformAndAncestors}
      />
      <QueryEditor
        queryEditorState={queryEditorState}
        canShowEditButtons={true}
        savedSql={table.transform.sql}
        isTransform={true}
        incremental={table.transform.incremental}
        transformTableName={table.name}
        description={table.description}
        eventPrefix={EVENT_PREFIX}
        saving={saving}
        isShown={true}
        onExportCsv={handleExportCsv}
        onExportGSheet={handleExportGSheet}
        onSaveSql={handleSaveSql}
        setUnsavedSql={setUnsavedSql}
      />
    </div>
  );
};

export default TransformTab;
