import { useMemo } from 'react';

import { Link } from 'react-router-dom';

import { Connector } from 'api/APITypes';
import SearchableCheckboxPicker from 'components/inputs/composite/SearchableCheckboxPicker/SearchableCheckboxPicker';
import ConnectorIcon from 'components/primitives/icons/ConnectorIcon/ConnectorIcon';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import { useUserProfile } from 'context/AuthContext';
import { humanSyncFrequency } from 'model_helpers/connectorHelper';

interface AllMarkedScheduleSettingsProps {
  connectors: Connector[];
  savedConnectorDependencyIDs: string[];
  unsavedConnectorDependencyIDs: string[];
  onToggleConnector: (connector: Connector) => void;
}

export default function AllMarkedScheduleSettings(props: AllMarkedScheduleSettingsProps) {
  const { connectors, savedConnectorDependencyIDs, unsavedConnectorDependencyIDs, onToggleConnector } =
    props;
  const { userProfile } = useUserProfile();

  const sortedConnectors = useMemo(() => {
    return [...connectors].sort((a, b) => {
      const aSaved = savedConnectorDependencyIDs.includes(a.id);
      const bSaved = savedConnectorDependencyIDs.includes(b.id);
      if (aSaved && !bSaved) {
        return -1;
      }
      if (!aSaved && bSaved) {
        return 1;
      }
      return a.schema.localeCompare(b.schema);
    });
  }, [connectors, savedConnectorDependencyIDs]);

  const getKey = (c: Connector) => c.id;
  const isChecked = (c: Connector) => unsavedConnectorDependencyIDs.includes(c.id);
  const hasChanged = (c: Connector) => {
    const saved = savedConnectorDependencyIDs.includes(c.id);
    const unsaved = unsavedConnectorDependencyIDs.includes(c.id);
    return saved !== unsaved;
  };
  const matchesFilter = (c: Connector, filter: string) =>
    c.schema.toLowerCase().includes(filter) || c.service.toLowerCase().includes(filter);
  const renderNoObjects = () => (
    <FixMeAlert
      alertClass="mt-2"
      heading="You don't have any conntectors yet."
      detail={
        <>
          {userProfile.company_role !== 'viewer' && (
            <p className="max-w-[500px] mt-2">
              Go to the{' '}
              <Link
                to={`/connectors`}
                data-track="AddDBTRunConfig NoConnectorsAlertLink"
                className="text-sec-blue-light-700 font-medium"
              >
                connectors page
              </Link>{' '}
              and add a connector.
            </p>
          )}
        </>
      }
    />
  );
  const renderNoMatchesFound = () => (
    <NoMatchesFoundAlert className="mt-2" heading="No matching connectors." />
  );
  const renderObject = (c: Connector) => {
    const { service, schema, sync_frequency } = c;
    return (
      <div className="ml-2 f-row-y-center whitespace-nowrap text-small">
        <ConnectorIcon service={service} size={24} />
        <div className="ml-2">{schema}</div>
        <div className="ml-2 text-pri-gray-400">{humanSyncFrequency(sync_frequency)}</div>
      </div>
    );
  };
  const getDescription = (c: Connector) => '';

  return (
    <SearchableCheckboxPicker
      objects={sortedConnectors}
      inputWrapperClass="pb-2"
      listClass="max-h-[240px]"
      autoFocus={false}
      getKey={getKey}
      isChecked={isChecked}
      hasChanged={hasChanged}
      onToggleCheck={onToggleConnector}
      matchesFilter={matchesFilter}
      renderNoObjects={renderNoObjects}
      renderNoMatchesFound={renderNoMatchesFound}
      renderObject={renderObject}
      getDescription={getDescription}
    />
  );
}
