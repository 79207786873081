import { useEffect, useState } from 'react';

import API from 'api/API';
import { DatabaseAccount } from 'api/APITypes';
import { ListboxOption } from 'components/inputs/formik_group/ListboxFormikGroup/ListboxFormikGroup';

// It's possible the parent could already have snowflake roles, so we don't need to fetch them here.
// Hooks cannot be called conditionally so we just return undefined here with the canSkipFetch arg.
export default function useSnowflakeRoles(databaseAccount: DatabaseAccount, canSkipFetch?: boolean) {
  const [listboxOptions, setListboxOptions] = useState<ListboxOption[]>([]);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const isByos = databaseAccount.type === 'snowflake' && databaseAccount.is_byos;

  useEffect(() => {
    if (canSkipFetch) {
      return;
    }
    if (databaseAccount.type === 'bigquery' || isByos) {
      return;
    }
    const api = new API();
    api
      .get('/api/snowflake_roles')
      .then((response) => {
        const unsortedRoles = response.data;
        const sortedRoles = sortRoles(unsortedRoles);

        let tempListboxOptions: ListboxOption[] = [];
        sortedRoles.forEach((role: string) => {
          tempListboxOptions.push({ value: role, label: role });
          // Add a divider after the MOZART_VIEWER role
          if (role === 'MOZART_VIEWER') {
            tempListboxOptions.push({
              value: 'POST_MOZART_VIEWER_DIVIDER',
              label: 'POST_MOZART_VIEWER_DIVIDER',
              isDivider: true,
            });
          }
        });

        setListboxOptions(tempListboxOptions);
      })
      .catch((e) => {
        setError('Unable to fetch Snowflake roles.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [databaseAccount.type, canSkipFetch, isByos]);

  return { listboxOptions, error, loading };
}

type SortPriority = {
  [key: string]: number;
};

const sortRoles = (roles: string[]) => {
  const sortPriority: SortPriority = {
    MOZART_ADMIN: 1,
    MOZART_EDITOR: 2,
    MOZART_VIEWER: 3,
  };
  return roles.sort((a, b) => {
    if (sortPriority[a] && sortPriority[b]) {
      return sortPriority[a] - sortPriority[b];
    } else if (sortPriority[a]) {
      return -1;
    } else if (sortPriority[b]) {
      return 1;
    } else {
      return a.localeCompare(b);
    }
  });
};
