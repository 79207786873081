import React from 'react';

import API from 'api/API';
import { NotificationEventType } from 'api/APITypes';
import Switch from 'components/inputs/basic/Switch/Switch';
import { useUserProfile, useDatabaseAccount } from 'context/AuthContext';

interface NotificationSubscriptionsProps {
  setError: (error: string) => void; // Error to display on base Settings.tsx page.
}

type NotificationEventTypeDisplayMap = {
  [key in NotificationEventType]: string;
};
type NotificationEventTypeWeightMap = {
  [key in NotificationEventType]: number;
};

// For new notification event types, we only want @mozartdata.com emails to get them initally. So define them here, and filter on this list below
const experimentNotificationTypes: string[] = [];

const alwaysExcludeNotificationTypes: string[] = ['overage', 'overage_warning'];

const notificationEventTypeDisplayMap: NotificationEventTypeDisplayMap = {
  transform_failure: 'Transform Failures',
  connector_initial_sync: 'Connector Initial Syncs',
  connector_failure: 'Connector Sync Failures',
  gsheet_failure: 'GSheet Failures',
  data_alert: 'Data Alerts',
  dbt_run_failure: 'DBT Run Failures',
};

const notificationEventTypeWeightMap: NotificationEventTypeWeightMap = {
  transform_failure: 1,
  connector_failure: 2,
  connector_initial_sync: 3,
  data_alert: 4,
  gsheet_failure: 5,
  dbt_run_failure: 6,
};

export const displayableEventType = (notificationEventType: NotificationEventType) => {
  return notificationEventTypeDisplayMap[notificationEventType];
};
export default function NotificationSubscriptions(props: NotificationSubscriptionsProps) {
  const { setError } = props;
  const { userProfile, setUserProfile } = useUserProfile();
  const databaseType = useDatabaseAccount().type;

  // Since subscriptions are stored as an array on userProfile, it's easier to have the Form pass in the new values than calculate them in the function
  const handleUpdateNotificationSubscription = (
    notificationEventType: NotificationEventType,
    inAppEnabled: Boolean,
    emailEnabled: Boolean,
  ) => {
    const api = new API();
    const postData = {
      notification_event_type: notificationEventType,
      in_app_enabled: inAppEnabled,
      email_enabled: emailEnabled,
    };
    api
      .patch(`/api/user_profiles/${userProfile.id}/update_notification_subscriptions`, postData)
      .then((response) => {
        setUserProfile(response.data);
      })
      .catch((error) => {
        setError('There was a problem updating your notification subscriptions.');
      });
  };

  return (
    <div className="mt-6">
      <table className="lineTable">
        <thead>
          <tr>
            <th style={{ width: '74%' }}>Type</th>
            <th className="text-center" style={{ width: '13%' }}>
              In-app
            </th>
            <th className="text-center" style={{ width: '13%' }}>
              Email
            </th>
          </tr>
        </thead>
        <tbody>
          {userProfile.notification_subscriptions
            .filter(
              (notification_subscription) =>
                !experimentNotificationTypes.includes(
                  notification_subscription.notification_event_type,
                ) || userProfile.user.email.includes('@mozartdata.com'),
            )
            .filter(
              (notification_subscription) =>
                !alwaysExcludeNotificationTypes.includes(
                  notification_subscription.notification_event_type,
                ),
            )
            .filter(
              (ns) =>
                // We want to filter out dbt and Data Alert subscriptions for byobq till those features work
                !['data_alert', 'dbt_run_failure'].includes(ns.notification_event_type) ||
                databaseType !== 'bigquery',
            )
            .sort((a, b) => {
              return (
                notificationEventTypeWeightMap[a.notification_event_type] -
                notificationEventTypeWeightMap[b.notification_event_type]
              );
            })
            .map((notification_subscription) => (
              <tr key={notification_subscription.notification_event_type}>
                <td>{displayableEventType(notification_subscription.notification_event_type)}</td>
                <td className="text-center">
                  <div className="f-center">
                    <Switch
                      name={`${notification_subscription.notification_event_type}_in_app`}
                      id={`${notification_subscription.notification_event_type}_in_app`}
                      checked={notification_subscription.in_app_enabled}
                      onChange={() =>
                        handleUpdateNotificationSubscription(
                          notification_subscription.notification_event_type,
                          !notification_subscription.in_app_enabled,
                          notification_subscription.email_enabled,
                        )
                      }
                    />
                  </div>
                </td>
                <td className="text-center">
                  <div className="f-center">
                    <Switch
                      name={`${notification_subscription.notification_event_type}_email`}
                      id={`${notification_subscription.notification_event_type}_email`}
                      checked={notification_subscription.email_enabled}
                      onChange={() =>
                        handleUpdateNotificationSubscription(
                          notification_subscription.notification_event_type,
                          notification_subscription.in_app_enabled,
                          !notification_subscription.email_enabled,
                        )
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
