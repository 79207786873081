/*
This component renders the fraction of the rows in the Warehouse
that would be visible at a given scroll displacement assuming
we rendered all of the rows in the scrollable area.
As the user scrolls, it rerenders a new subset of visible rows and 
offsets them by the scroll displacement.
It uses the same technique as https://github.com/bvaughn/react-window,
but is implmented here because we have Mozart specfic behaviour.
*/
import React, { useMemo, useRef, useState } from 'react';

import { useMeasure } from 'react-use';

import { CompanyRole } from 'api/APITypes';
import { Favorite, FavoritesByTableID } from 'api/favoriteAPI';
import { AggTable } from 'api/tableAPI';
import { pickHighlightFilter } from 'components/query/TableExplorer/highlightUtils';
import { useDatabaseAccount } from 'context/AuthContext';
import useAnimationFrame from 'hooks/useAnimationFrame';
import { KeywordLists } from 'utils/TableFilter';

import { TableRefMap } from '../WarehouseSearchReducer';

import { ColumnTypeRenderWidths } from './ColumnWidthTools';
import TableRow from './TableRow/TableRow';

import s from './TableTable.module.css';

// Tune these numbers wisely.
// Smaller renders faster.
// Default to rendering enough to cover the screen with some additional rows overflowing off the bottom of the screen.
const INIT_RENDER_COUNT = 40;
// We render a few more rows than we have to so that the next scroll might
// not trigger a row rerender. This should enable smoother slow scrolling.
const OVER_RENDER_COUNT = 10;

// The height of a <TableRow /> component.
const ROW_HEIGHT = 41;

interface ScrollableTableTableProps {
  tables: AggTable[];
  tableRefMap: TableRefMap;
  columnWidths: ColumnTypeRenderWidths;
  showIcon: boolean;
  useFullName: boolean;
  usingCache: boolean;
  favoritesByTableID: FavoritesByTableID;
  filterIncludes: KeywordLists;
  companyRole: CompanyRole;
  addFavorite: (favorite: Favorite) => void;
  removeFavorite: (favorite: Favorite) => void;
  setSnapshot(table: AggTable, snapshot: boolean): Promise<AggTable>;
  logRecent: (tableID: string) => void;
  onOpenTagPicker: (refKey: string) => void;
  onClickTag: (tagName: string) => void;
  onClickTable: (table: AggTable) => void;
}

export default function ScrollableTableTable(props: ScrollableTableTableProps) {
  const {
    tables,
    tableRefMap,
    columnWidths,
    showIcon,
    useFullName,
    usingCache,
    favoritesByTableID,
    filterIncludes,
    companyRole,
    addFavorite,
    removeFavorite,
    setSnapshot,
    logRecent,
    onOpenTagPicker,
    onClickTag,
    onClickTable,
  } = props;
  const databaseType = useDatabaseAccount().type;

  // Table rows highlight different things in different scenarios.
  // So, there are quite a few search filters.
  // `hasSchemaFilter` and `hasTableFilter` are required because
  // the `fullHighlightFitler` is automatically added to `schemaHighlightFilter` and `tableHighlightFilter`,
  // and we need to be able to detect when filter props come from `filterIncludes.s` or `filterIncludes.t`.
  const hasSchemaFilter = filterIncludes.s !== undefined;
  const hasTableFilter = filterIncludes.t !== undefined;
  const fullHighlightFilter = useMemo(
    () => pickHighlightFilter(filterIncludes, 'full_name'),
    [filterIncludes],
  );
  const schemaHighlightFilter = useMemo(
    () => pickHighlightFilter(filterIncludes, 'schema'),
    [filterIncludes],
  );
  const tableHighlightFilter = useMemo(
    () => pickHighlightFilter(filterIncludes, 'table'),
    [filterIncludes],
  );
  const descriptionHighlightFilter = useMemo(
    () => pickHighlightFilter(filterIncludes, 'description'),
    [filterIncludes],
  );

  const [measureRef, { height: measuredHeight }] = useMeasure<HTMLDivElement>();
  const scrollRef = useRef<HTMLDivElement>(null);
  const dataRef = useRef<HTMLTableElement>(null);
  // The scroll position at which we last calculated a subset of table rows
  // for React to render. We will not calculate a new subset of table rows
  // to render until the user has scrolled enough for the current of rendered rows
  // to no longer completely overlaps the visible scroll area.
  const [renderedYOffset, setRenderedYOffset] = useState(0);
  // The scroll position at which the animation frame last checked to see if
  // the current subset of table rows completely overlaps the visible scroll area.
  const checkedYOffsetRef = useRef(0);

  useAnimationFrame(() => {
    const scrollDiv = scrollRef.current;
    if (scrollDiv) {
      const newScrollY = scrollDiv.scrollTop;
      const oldScollY = checkedYOffsetRef.current;

      const dataTable = dataRef.current;
      if (dataTable && newScrollY !== oldScollY) {
        const newTableFirstPixel = dataTable.offsetTop;
        const tableHeight = dataTable.offsetHeight;
        const newTableLastPixel = newTableFirstPixel + tableHeight;

        const scrollFirstPixel = newScrollY;
        const scrollHeight = scrollDiv.offsetHeight;
        const scrollLastPixel = scrollFirstPixel + scrollHeight;
        // If the scroll area's box no longer completely overlaps all of the
        // renderedRows then trigger a rerender.
        if (newTableFirstPixel > scrollFirstPixel || newTableLastPixel < scrollLastPixel) {
          setRenderedYOffset(newScrollY);
        }
      }
      checkedYOffsetRef.current = newScrollY;
    }
  });

  const renderedRowRef = useRef({
    firstRow: 0,
    lastRow: 0,
    lastTables: [] as AggTable[],
    lastRenderedRows: [] as AggTable[],
  });

  const [renderedRows, tableDisplacement] = useMemo(() => {
    let { firstRow, lastRow, lastTables, lastRenderedRows } = renderedRowRef.current;
    let displacement = 0;
    let renderedRows = lastRenderedRows;

    // The DOM hasn't been rendered yet.
    // So, I don't know my height. Just render the first rows.
    if (measuredHeight === 0) {
      firstRow = 0;
      lastRow = Math.min(INIT_RENDER_COUNT, tables.length);
      renderedRows = tables.slice(firstRow, lastRow);
      displacement = 0;
    }
    // I know my height. I can do scroll math.
    else {
      // TODO:
      // Rows can be variable height depending on if they have tags or not.
      // This math is going to get more complicated in a future version.
      let firstVisibileRow = Math.floor(renderedYOffset / ROW_HEIGHT);
      let lastVisibileRow = firstVisibileRow + Math.ceil(measuredHeight / ROW_HEIGHT);
      lastVisibileRow += 1; // Add one to make sure I overflow the end of the window

      // Make sure indices are always in array bounds
      firstVisibileRow = Math.max(firstVisibileRow, 0);
      lastVisibileRow = Math.min(lastVisibileRow, tables.length - 1);

      // End is one more than array index
      lastVisibileRow = lastVisibileRow + 1;

      // Only slice a new renderedRow list if the current one
      // does not contain my desired range
      let rangeChanged = false;
      if (firstVisibileRow < firstRow || lastVisibileRow > lastRow) {
        rangeChanged = true;
        // Save a copy of old rows indexes
        const oldFirstRow = firstRow;
        const oldLastRow = firstRow;
        // Set new row indexes
        firstRow = firstVisibileRow;
        lastRow = lastVisibileRow;

        // Over render in the direction of scrolling so that slow scrolling will
        // have fewer rerenders and therefore smoother scrolling.
        if (firstRow < oldFirstRow) {
          firstRow -= OVER_RENDER_COUNT;
        }
        if (lastRow > oldLastRow) {
          lastRow += OVER_RENDER_COUNT;
        }

        // Make sure indices are always in array bounds
        firstRow = Math.max(firstRow, 0);
        lastRow = Math.min(lastRow, tables.length);
      }

      if (rangeChanged || tables !== lastTables) {
        renderedRows = tables.slice(firstRow, lastRow);
      }

      displacement = firstRow * ROW_HEIGHT;
    }

    renderedRowRef.current = {
      firstRow,
      lastRow,
      lastTables: tables,
      lastRenderedRows: renderedRows,
    };
    return [renderedRows, displacement];
  }, [measuredHeight, tables, renderedYOffset]);

  // These headers just make sure the widths of this table's columns are the same width as the header table's columns
  const memoizedHeaders = useMemo(() => {
    const className = '!h-0 !p-0';
    return (
      <thead>
        <tr>
          <th key="tfh-table" className={className} style={{ width: columnWidths.table }}></th>
          <th
            key="tfh-description"
            className={className}
            style={{ width: columnWidths.description }}
          ></th>
          <th key="tfh-scheduled" className={className} style={{ width: columnWidths.scheduled }}></th>
          <th key="tfh-status" className={className} style={{ width: columnWidths.status }}></th>
          <th key="tfh-last-run" className={className} style={{ width: columnWidths.lastRun }}></th>
          <th key="tfh-row-count" className={className} style={{ width: columnWidths.rowCount }}></th>
          {databaseType !== 'bigquery' && (
            <th key="tfh-snapshot" className={className} style={{ width: columnWidths.snapshot }}></th>
          )}
          <th key="tfh-query" className={className} style={{ width: columnWidths.query }}></th>
        </tr>
      </thead>
    );
  }, [columnWidths, databaseType]);

  const memoizedRows = useMemo<React.ReactNode>(
    () => (
      <tbody>
        {renderedRows.map((table: AggTable) => {
          const refKey = table.full_name;
          const refState = tableRefMap[refKey];
          return (
            <TableRow
              key={table.full_name}
              table={table}
              showIcon={showIcon}
              useFullName={useFullName}
              usingCache={usingCache}
              refKey={refKey}
              descRef={refState.descRef}
              scheduledRef={refState.scheduledRef}
              agoRef={refState.agoRef}
              tagPickerRef={refState.tagPickerRef}
              hasSchemaFilter={hasSchemaFilter}
              hasTableFilter={hasTableFilter}
              fullHighlightFilter={fullHighlightFilter}
              schemaHighlightFilter={schemaHighlightFilter}
              tableHighlightFilter={tableHighlightFilter}
              descriptionHighlightFilter={descriptionHighlightFilter}
              favorite={favoritesByTableID[table.id]}
              companyRole={companyRole}
              addFavorite={addFavorite}
              removeFavorite={removeFavorite}
              setSnapshot={setSnapshot}
              logRecent={logRecent}
              onOpenTagPicker={onOpenTagPicker}
              onClickTag={onClickTag}
              onClickTable={onClickTable}
            />
          );
        })}
      </tbody>
    ),
    [
      renderedRows,
      tableRefMap,
      showIcon,
      useFullName,
      usingCache,
      hasSchemaFilter,
      hasTableFilter,
      fullHighlightFilter,
      schemaHighlightFilter,
      tableHighlightFilter,
      descriptionHighlightFilter,
      favoritesByTableID,
      companyRole,
      addFavorite,
      removeFavorite,
      setSnapshot,
      logRecent,
      onOpenTagPicker,
      onClickTag,
      onClickTable,
    ],
  );

  let spacerHeight = tables.length * ROW_HEIGHT;

  /*
  STRUCTURE OVERVIEW:
  useMeasure() refs do not give access to ref.current.
  So, `measure-div` and `scroll-div` are broken into two divs each with their own ref.
  1. `measure-div` allows us to detect window resizes and rerender in that scenario.
  2. `scroll-div` is a fixed size. When it's content overflows it's size it becomes scrollable.
  3. `spacer-div` forces the `scroll-div` to be the size of the data table
       if all of the rows were rendered even though we only render a fraction of 
       the rows in the data table at any one time to. We render a fraction of the rows
       instead of thousands of rows to improve render performance.
  4. `data-table` renders the fraction of rows visible at the current scroll displacement.
     It is displaced in the `scroll-div` so that the current fraction of rows is always
     visible at the current scroll displacement.
  */
  const HEADER_HEIGHT = '36px';
  return (
    <div
      id="measure-div"
      ref={measureRef}
      className="w-full"
      style={{ height: `calc(100% - ${HEADER_HEIGHT}` }}
    >
      <div id="scroll-div" ref={scrollRef} className="w-full h-full relative overflow-auto">
        <div id="spacer-div" className="w-full" style={{ height: `${spacerHeight}px` }}></div>
        <table
          id="data-table"
          ref={dataRef}
          className={'blueGrayHeaderTable absolute ' + s.table}
          style={{ top: `${tableDisplacement}px` }}
        >
          {memoizedHeaders}
          {memoizedRows}
        </table>
      </div>
    </div>
  );
}
