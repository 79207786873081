import React, { useState } from 'react';

import { AxiosResponse } from 'axios';
import cn from 'classnames';
import { Formik } from 'formik';
import * as yup from 'yup';

import API from 'api/API';
import { AggTable } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import InputCopy from 'components/inputs/composite/InputCopy/InputCopy';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { handleSqlErrors } from 'utils/apiResponseFormatter';
import { patch } from 'utils/Array';
import { deslug } from 'utils/Form';

import { Worksheet, GSheetDestination, NewGSheetDestination } from '../SyncTabController';

const serviceAccountEmail =
  process.env.REACT_APP_SERVICE_ACCOUNT_EMAIL ||
  'SYSTEM ERROR: SET .env.XXX.REACT_APP_SERVICE_ACCOUNT_EMAIL';

const TEST_CONNECTION_ERROR_SLUGS = {
  NO_EDIT_PERMISSION:
    'Our service account does not have "Editor" privileges on the spreadsheet. Double check that you shared the spreadsheet with the email address above and that you gave that email "Editor" privileges. Also, double check you entered the correct spreadsheet url.',
};

const sheetTitleSchema = yup.object({
  worksheet_name: yup.string().trim().required('Required'),
  order_by: yup.string().trim(),
});

interface TestConnectionResponse {
  id: string;
  title: string;
  worksheets: Worksheet[];
}

export enum SyncStep {
  Share = 1,
  PickSheet = 2,
}

export interface GSheetDestinationModalProps {
  table: AggTable;
  existingDestinations: GSheetDestination[];
  destination: GSheetDestination | NewGSheetDestination;
  setDestinationsAndClose(newDestinations: GSheetDestination[]): void;
  onCancel(): void;
}

export default function GSheetDestinationModal(props: GSheetDestinationModalProps) {
  const { table, existingDestinations, destination, setDestinationsAndClose, onCancel } = props;
  const imNew = (destination as GSheetDestination).id === undefined;
  const [step, setStep] = useState(imNew ? SyncStep.Share : SyncStep.PickSheet);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [testing, setTesting] = useState(false);
  const [existingSheets, setExistingSheets] = useState<Worksheet[]>(
    imNew ? [] : (destination as GSheetDestination).worksheets,
  );
  const [showConfirmOverwriteSheet, setShowConfirmOverwriteSheet] = useState(false); // Show confirm modal
  const [overwriteConfirmedFor, setOverwriteConfirmedFor] = useState(''); // The confirm modal was confirmed for this worksheet_name

  // The yup validation schema has a closure on this error object.
  // If there is an error we update this object's contents.
  // This indirection is necessary to make yup return custom error messages that
  // change at validation time.
  let errorMessageParams = {
    spreadsheetTitle: 'UNSET',
  };
  const spreadsheetIDSchema = yup.object({
    spreadsheet_id: yup
      .string()
      .trim()
      .required('Required')
      .test({
        name: 'is-unique',
        message: `Table "${table.name}" is already synced to spreadsheet "\${spreadsheetTitle}".`, // eslint-disable-line no-template-curly-in-string
        params: errorMessageParams,
        test: function (value: string) {
          const existingDestination = existingDestinations.find((d) => d.spreadsheet_id === value);
          if (existingDestination) {
            errorMessageParams.spreadsheetTitle = existingDestination.spreadsheet_title;
            return false;
          }

          return true;
        },
      }),
  });

  const handleTestConnection = (values: { spreadsheet_id: string }) => {
    // 0.5. Clean the spreadsheet_id in case the user passes in a url
    const cleanedSpreadsheetId = cleanSpreadsheetId(values.spreadsheet_id);
    // 1. Verify we can connect to the google sheet with editor access.
    const api = new API();
    setTesting(true);
    api
      .post('/api/gsheet_destinations/test_connection', {
        spreadsheet_id: cleanedSpreadsheetId,
      })
      .then((response: AxiosResponse<TestConnectionResponse>) => {
        // 2. Save the spreadsheet's id and title.
        destination.spreadsheet_id = cleanedSpreadsheetId;
        destination.spreadsheet_title = response.data.title;

        // 3. Populate existingSheets with the list of sheets on the spreadsheet.
        //    This is used for verify writing over existing sheets on the next SyncStep.
        setExistingSheets(response.data.worksheets);

        // 4. Default the worksheet_name to schema.table_name.
        destination.worksheet_name = `${table.schema}.${table.name}`;

        setError('');
        setStep(SyncStep.PickSheet);
        analytics.track('SyncTab TestConnection');
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          setError(deslug(e.response.data.error, TEST_CONNECTION_ERROR_SLUGS));
        } else {
          setError('There was a problem adding this Google spreadsheet destination.');
        }
      })
      .finally(() => {
        setTesting(false);
      });
  };

  const addDestination = (newDestination: NewGSheetDestination) => {
    setSaving(true);
    const api = new API();
    api
      .post(`/api/gsheet_destinations`, newDestination)
      .then((response) => {
        setDestinationsAndClose([...existingDestinations, response.data]);
        analytics.track('SyncTab AddDestination');
      })
      .catch((e) => {
        if (e.response?.data?.non_field_errors) {
          setError(e.response.data.non_field_errors[0]);
        } else if (e.response?.data) {
          const errorSlug = e.response.data[0];
          setError(handleSqlErrors(errorSlug));
        } else {
          setError('There was a problem adding this Google spreadsheet destination.');
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const updateDestination = (destination: GSheetDestination) => {
    setSaving(true);
    const api = new API();
    const newValues = {
      worksheet_name: destination.worksheet_name,
      order_by: destination.order_by,
    };
    api
      .patch(`/api/gsheet_destinations/${destination.id}`, newValues)
      .then((response) => {
        const newDestinations = patch(
          existingDestinations,
          response.data,
          (d) => d.id === destination.id,
        );
        setDestinationsAndClose(newDestinations);
        analytics.track('SyncTab UpdateDestination');
      })
      .catch((e) => {
        setError('There was a problem updating this Google spreadsheet destination.');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleCancelConfirmOverwrite = () => {
    setShowConfirmOverwriteSheet(false);
    analytics.track('SyncTab CancelConfirmOverwriteSheet');
  };

  const doSave = (worksheetName: string, orderBy: string) => {
    destination.worksheet_name = worksheetName;
    destination.order_by = orderBy;

    if (imNew) {
      addDestination(destination as NewGSheetDestination);
    } else {
      updateDestination(destination as GSheetDestination);
    }
  };

  const handleConfirmOverwrite = (sheetTitle: string, orderBy: string) => {
    setShowConfirmOverwriteSheet(false);
    setOverwriteConfirmedFor(sheetTitle);
    doSave(sheetTitle, orderBy);
  };

  const handleSave = (values: { worksheet_name: string; order_by: string }) => {
    if (
      overwriteConfirmedFor !== values.worksheet_name &&
      existingSheets.find((s) => s.name === values.worksheet_name)
    ) {
      setShowConfirmOverwriteSheet(true);
      return;
    }
    doSave(values.worksheet_name, values.order_by);
  };

  let form = null;

  if (step === SyncStep.Share) {
    form = (
      <div>
        <h4 className="text-input-label">Step 1:</h4>
        <h5>Open the Google spreadsheet you want to sync to.</h5>

        <h4 className="text-input-label mt-5">Step 2:</h4>
        <h5>Share that spreadsheet with ‘Editor’ privileges to this email address:</h5>
        <InputCopy value={serviceAccountEmail} containerClass="mt-2" />

        <h4 className="text-input-label mt-5">Step 3:</h4>
        <h5>Copy the entire spreadsheet url into this form.</h5>
        <Formik
          validationSchema={spreadsheetIDSchema}
          onSubmit={handleTestConnection}
          initialValues={destination}
        >
          {({ handleSubmit }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <TextFormikGroup name="spreadsheet_id" placeholder="Spreadsheet URL" groupClass="mt-1" />

                {error && (
                  <Alert variant="error" className="mt-4">
                    {error}
                  </Alert>
                )}

                <div className="f-center mt-4">
                  <Button type="submit" variant="lightAction" spinning={testing}>
                    Test Connection
                  </Button>
                  <Button variant="lightDanger" onClick={onCancel} className="ml-4">
                    Cancel
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  } else if (step === SyncStep.PickSheet) {
    form = (
      <Formik validationSchema={sheetTitleSchema} onSubmit={handleSave} initialValues={destination}>
        {({ handleSubmit, values }) => {
          let exampleSQL = `SELECT * FROM ${table.full_name}`;
          if (values.order_by.trim()) {
            exampleSQL += ` ORDER BY ${values.order_by}`;
          }
          const noteDetailStart = 'Mozart syncs to your spreadsheet after every successful ';
          let noteDetail =
            noteDetailStart +
            'transform run. If a transform run fails, we will not overwrite the previous data.';
          if (table.type === 'unmanaged') {
            noteDetail = noteDetailStart + 'connector sync.';
          } else if (table.type === 'snapshot') {
            noteDetail = noteDetailStart + 'snapshot.';
          }
          return (
            <form noValidate onSubmit={handleSubmit}>
              {imNew && <h4 className="text-input-label">Step 4:</h4>}
              <h5>Pick a tab to sync to.</h5>
              <div>If the tab does not exist, it will be created.</div>

              <TextFormikGroup name="worksheet_name" placeholder="tab_name" groupClass="mt-2" />

              <h4 className="mt-4 text-input-label">Optional:</h4>
              <div className="">Include columns to order by.</div>

              <div>
                Example: <code>user_id desc, "UserName"</code>.
              </div>

              <div className="flex items-center mt-2">
                <div className="text-pri-gray-400">ORDER BY</div>
                <div style={{ flex: '1 0 auto' }}>
                  <TextFormikGroup name="order_by" placeholder="" groupClass="ml-2" />
                </div>
              </div>
              <div className="mt-2">
                <div>This sheet sync will export the result of:</div>
                <div>
                  <code>{exampleSQL}</code>
                </div>
              </div>

              <Alert variant="info" className="mt-4">
                <h5>Notes:</h5>
                <ol>
                  <li>
                    <strong>Do not put any other content on a spreadsheet tab Mozart syncs to.</strong>{' '}
                    Every time Mozart syncs to a spreadsheet tab all previous data on that tab will be
                    deleted.
                  </li>
                  <li className="mt-2">{noteDetail}</li>
                  <li className="mt-2">
                    Mozart will sync to your spreadsheet after you click the "Save" button on this form.
                  </li>
                </ol>
              </Alert>

              {error && (
                <Alert variant="error" className="mt-4">
                  {error}
                </Alert>
              )}

              <div className="f-center mt-4">
                <Button type="submit" variant="lightAction" spinning={saving}>
                  Save
                </Button>
                <Button variant="lightDanger" onClick={onCancel} className="ml-4">
                  Cancel
                </Button>
              </div>

              {showConfirmOverwriteSheet && (
                <ConfirmModal
                  header={
                    <span>
                      Tab <code>{values.worksheet_name}</code> already exists. When this table syncs it
                      will overwrite all of the data on this tab. Are you sure you want to overwrite tab{' '}
                      <code>{values.worksheet_name}</code>?
                    </span>
                  }
                  confirmText="Overwrite"
                  confirmVariant="darkDanger"
                  onCancel={handleCancelConfirmOverwrite}
                  onConfirm={() => handleConfirmOverwrite(values.worksheet_name, values.order_by)}
                />
              )}
            </form>
          );
        }}
      </Formik>
    );
  }

  const instructions = (
    <div className="bg-pri-gray-100 p-4">
      <h2 className="text-lg font-medium text-pri-gray-800">Instructions</h2>
      <h5>1. Open the Google speadsheet you want to sync to.</h5>
      <h5>2. Share your speadsheet with "Editor" privileges.</h5>
      <img
        src="/images/tutorials/sync_gsheet/share.png"
        alt="share button screenshot"
        className="image-fit"
      />
      <img
        src="/images/tutorials/sync_gsheet/set_as_editor.png"
        alt="editor setting screenshot"
        className="image-fit"
      />
      <h5 className="mt-2">3. Copy the entire spreadsheet url into the form.</h5>
      <img
        src="/images/tutorials/sync_gsheet/spreadsheet_url.png"
        alt="spreadsheet id in url screenshot"
        className="image-fit"
      />

      <h5 className="mt-2">4. Pick a tab to sync to.</h5>
      <img src="/images/tutorials/sync_gsheet/tab_name.png" alt="tab screenshot" className="image-fit" />
    </div>
  );

  const header = imNew
    ? 'New Google Spreadsheet Destination'
    : `Edit "${destination.spreadsheet_title}" Destination`;

  return (
    <Modal header={header} onClose={onCancel} fullscreen={imNew} cancelButton={true}>
      <div className={cn('h-full flex pl-4', { 'pr-4': !imNew })}>
        <div className={cn('pt-6 px-2 pb-4 min-w-[540px] overflow-y-auto', { 'w-[600px]': !imNew })}>
          {form}
        </div>
        {imNew && <div className="ml-2 overflow-y-auto">{instructions}</div>}
      </div>
    </Modal>
  );
}

export const cleanSpreadsheetId = (spreadsheetId: string) => {
  const matches = spreadsheetId.match(/[-\w]{25,}/);
  if (matches === null || matches.length !== 1) {
    // If we dont get exactly 1 match, return what was given and let the endpoint error (it will say to check spelling)
    return spreadsheetId;
  } else {
    return matches[0];
  }
};
