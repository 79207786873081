import { useBooleanFlag } from 'hooks/useFeatureFlags';
import useUsageFetch from 'pages/UsageV2/useUsageFetch';
import { getCurrentMonthValue, getTodayAndStartDate } from 'pages/UsageV2/Utils';

import { UsageWidgetState } from './UsageWidget/UsageWidget';

export default function useUsageWidget(): UsageWidgetState {
  const isUsageWidgetEnabled = useBooleanFlag('homepage_usage_widget');
  const { error, appQueryingData, connectorData, currentMonthPlan } =
    useUsageFetch(isUsageWidgetEnabled);

  /**
   * Track click event for the View Usage button. Navigation is handled by the parent component.
   */
  const handleClickViewUsage = () => {
    analytics.track('HomepageUsageWidget ViewUsage');
  };

  if (error) {
    return {
      allLoaded: true,
      anyError: true,
      currentMonthPlan,
      currentMonthMar: 0,
      currentMonthCompute: 0,
      onClickViewUsage: handleClickViewUsage,
    };
  }

  const [today] = getTodayAndStartDate('currentMonthDaily');

  if (appQueryingData && connectorData) {
    const currentMonthMar = getCurrentMonthValue(connectorData, today, 'MEASURED_DATE', 'MAR');
    const currentMonthCompute = getCurrentMonthValue(
      appQueryingData,
      today,
      'QUERY_DATE',
      'COMPUTE_CREDITS',
    );

    return {
      allLoaded: true,
      anyError: false,
      currentMonthPlan,
      currentMonthMar,
      currentMonthCompute,
      onClickViewUsage: handleClickViewUsage,
    };
  }

  return {
    allLoaded: false,
    anyError: false,
    currentMonthPlan,
    currentMonthMar: 0,
    currentMonthCompute: 0,
    onClickViewUsage: handleClickViewUsage,
  };
}
