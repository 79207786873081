import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function APIKeyInfoIcon() {
  const content = (
    <div>
      <div>
        <strong>Generate an API Key to use with Mozart Data's API.</strong>
      </div>
      <div className="mt-2">
        Set your API request's Authentication header to the generated string. For example,
        "Authentication: Basic 07VGtHhDXw5E0gAe780JwcR0B6fG57iDFC5Ak8egJOf8FYlew5q4KlBA".
      </div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} popoverProps={popoverProps} />;
}
