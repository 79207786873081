import React from 'react';

import { baseBubbleClassName } from './StackingBubbles';

const COLORS: { [fontColor: string]: string } = {
  '#e6f2ed': '#027a48', // pri-success-700
  '#e6f0f6': '#026aa2', // sec-blue-light-700
  '#e8effb': '#175cd3', // sec-blue-700
  '#ebebfa': '#3538cd', // sec-indigo-700
  '#eee9fb': '#5925dc', // sec-purple-700
  '#f9e7ed': '#c01048', // sec-rose-700
  '#f9ebe6': '#c4320a', // sec-orange-700
  '#ebecee': '#344054', // pri-gray-700
};

interface StackingBubbleProps {
  label: string;
  index: number;
  numItems: number;
}

const StackingBubble = (props: StackingBubbleProps) => {
  const { label, index, numItems } = props;
  const { character, colorsIndex } = getDeterministicLetterAndColor(label);
  const colorKey = Object.keys(COLORS)[colorsIndex];
  return (
    <div
      className={baseBubbleClassName}
      style={{
        backgroundColor: colorKey,
        color: COLORS[colorKey],
        left: `-${index * 10}px`,
        borderColor: COLORS[colorKey],
        zIndex: numItems - index,
      }}
    >
      {character}
    </div>
  );
};

// This function hashes the label to generate a deterministic value that can be used to select a color
const getDeterministicLetterAndColor = (label: string) => {
  const sum = label.split('').reduce((partialSum, c) => partialSum + c.charCodeAt(0), 0);
  const colorsIndex = sum % Object.keys(COLORS).length;
  const character = label === '' ? '' : label[0].toUpperCase();
  return { character, colorsIndex };
};

export default StackingBubble;
