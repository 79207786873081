import { useState } from 'react';

import API from 'api/API';
import { AppConnector } from 'api/APITypes';

import { usePatchOneConnector } from '../ConnectorUtils';

export interface TogglePausedState {
  submitting: boolean;
  error: string;
  paused: boolean;
  togglePaused: () => void;
}

export default function useTogglePausedState(connector: AppConnector): TogglePausedState {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const patchConnector = usePatchOneConnector();

  const { paused } = connector;

  const togglePaused = async () => {
    const api = new API();
    setError('');
    setSubmitting(true);

    try {
      const paused = !connector.paused;
      const response = await api.post(
        `api/fivetran/connector/${connector.fivetran_connector_id}/paused`,
        {
          paused: paused,
        },
      );
      patchConnector(response.data);
      analytics.track('ShowConnectorSummaryTab TogglePaused', { paused: paused });
    } catch (_e) {
      setError('There was a problem updating the connector');
    } finally {
      setSubmitting(false);
    }
  };

  return {
    submitting,
    error,
    paused,
    togglePaused,
  };
}
