import { Diagram3Fill } from 'react-bootstrap-icons';

import { Connector } from 'api/APITypes';
import { ReportTransform } from 'api/dashboardAPI';

export interface ReportTransformsProps {
  transforms: ReportTransform[];
  connector: Pick<Connector, 'id' | 'service' | 'schema'>;
}

export default function ReportTransforms(props: ReportTransformsProps) {
  const { transforms, connector } = props;

  return (
    <div className="w-full h-full pt-4 pl-6 bg-sec-purple-25">
      <div className="font-medium">Transforms created for this report:</div>
      <div className="w-full mt-3 overflow-auto" style={{ height: 'calc(100% - 36px)' }}>
        <div className="pb-4 pr-6 grid grid-cols-1 gap-y-1">
          {transforms.map((t) => {
            const fullName = `${t.report_schema_prefix}_${connector.schema}.${t.table_name}`;
            return (
              <div key={fullName} className="h-[24px] f-row-y-center">
                <div>
                  <Diagram3Fill size={18} color="var(--teal)" transform="rotate(90 0 0)" />
                </div>
                <span className="ml-1">{fullName}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
