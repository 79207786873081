import React from 'react';

import { useTitle } from 'react-use';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';

import AddRunConfigFormLayout from './AddRunConfigFormLayout';
import ConnectGitRepo from './ConnectGitRepo';
import useGitReposState from './useGitReposState';

const AddDBTRunConfig = () => {
  useTitle('Create dbt job');
  const {
    loading,
    loadingError,
    newestSshKey,
    repos,
    showConnectNewGitRepo,
    setShowConnectNewGitRepo,
    setRepos,
  } = useGitReposState();

  if (loading) {
    return <CenteredSpinner containerMinHeight="60vh" />;
  }

  if (loadingError) {
    return (
      <Alert variant="error" className="m-4">
        {loadingError}
      </Alert>
    );
  }

  return (
    <div>
      {newestSshKey && showConnectNewGitRepo && (
        <ConnectGitRepo
          publicKey={newestSshKey}
          repos={repos}
          setRepos={setRepos}
          setShowConnectNewGitRepo={setShowConnectNewGitRepo}
        />
      )}
      {repos.length > 0 && !showConnectNewGitRepo && (
        <AddRunConfigFormLayout repos={repos} setShowConnectNewGitRepo={setShowConnectNewGitRepo} />
      )}
    </div>
  );
};

export default AddDBTRunConfig;
