import { useContext, useMemo } from 'react';

import useDataAlerts from 'hooks/useDataAlerts';
import { calcGenericRunStatus } from 'model_helpers/connectorHelper';
import { TableModelsContext } from 'model_layer/TableModelsContext';

import { StatusWidgetState } from './StatusWidget/StatusWidget';
import { StatusWidgetItemData } from './StatusWidget/StatusWidgetItem/StatusWidgetItem';

export default function useStatusWidget(): StatusWidgetState {
  const {
    loadedConnectors,
    loadedTransforms,
    connectors,
    transforms,
    connectorsError,
    transformsError,
  } = useContext(TableModelsContext);
  const {
    loading: loadingDataAlerts,
    errorMessage: dataAlertsError,
    dataAlerts,
  } = useDataAlerts({
    eventPage: 'DashboardPage',
  });

  const connectorsData: StatusWidgetItemData = useMemo(() => {
    if (!loadedConnectors || connectorsError) {
      return {
        loaded: loadedConnectors,
        error: connectorsError,
        successCount: 0,
        failedCount: 0,
        totalCount: 0,
        disableEmptyState: false,
      };
    }

    const activeConnectors = connectors.filter(
      (c) =>
        ((c.status.setup_state === 'connected' && c.status.sync_state === 'scheduled') ||
          c.status.setup_state === 'broken') &&
        c.paused === false,
    );

    const successfulConnectors = activeConnectors.filter((c) => calcGenericRunStatus(c) === 'success');
    const failedConnectors = activeConnectors.filter((c) => calcGenericRunStatus(c) === 'error');
    const brokenConnectors = activeConnectors.filter((c) => c.status.setup_state === 'broken');

    return {
      loaded: loadedConnectors,
      error: connectorsError,
      successCount: successfulConnectors.length,
      failedCount: failedConnectors.length,
      brokenCount: brokenConnectors.length,
      totalCount: activeConnectors.length,
      disableEmptyState: false,
    };
  }, [loadedConnectors, connectors, connectorsError]);

  const transformsData: StatusWidgetItemData = useMemo(() => {
    if (!loadedTransforms || transformsError) {
      return {
        loaded: loadedTransforms,
        error: transformsError,
        successCount: 0,
        failedCount: 0,
        totalCount: 0,
        disableEmptyState: false,
      };
    }

    const activeTransforms = transforms.filter((t) => t.scheduled);
    const successfulTransforms = activeTransforms.filter(
      (t) => t.last_completed_run?.state === 'success',
    );
    const failedTransforms = activeTransforms.filter((t) => t.last_completed_run?.state === 'failed');

    return {
      loaded: loadedTransforms,
      error: transformsError,
      successCount: successfulTransforms.length,
      failedCount: failedTransforms.length,
      totalCount: activeTransforms.length,
      disableEmptyState: false,
    };
  }, [loadedTransforms, transforms, transformsError]);

  const dataAlertsData: StatusWidgetItemData = useMemo(() => {
    const loadedDataAlerts = !loadingDataAlerts && !!dataAlerts;

    if (!loadedDataAlerts && dataAlertsError) {
      return {
        loaded: loadedDataAlerts,
        error: dataAlertsError,
        successCount: 0,
        failedCount: 0,
        totalCount: 0,
        disableEmptyState: false,
      };
    }

    const activeAlerts = dataAlerts.filter((a) => a.enabled);
    const successfulAlerts = activeAlerts.filter((a) => a.last_table_test_run?.succeeded ?? false);
    const failedAlerts = activeAlerts.filter((a) => !!a.last_table_test_run?.error_message ?? false);

    return {
      loaded: loadedDataAlerts,
      error: dataAlertsError,
      successCount: successfulAlerts.length,
      failedCount: failedAlerts.length,
      totalCount: activeAlerts.length,
      disableEmptyState: false,
    };
  }, [loadingDataAlerts, dataAlerts, dataAlertsError]);

  return {
    connectorsData,
    transformsData,
    dataAlertsData,
  };
}
