import { createContext } from 'react';

import { OnboardingPopoverTourStep } from './OnboardingPopoverTourContext';

export interface UserPreferencesInterface {
  warehouseShowViews?: boolean;
  warehouseShowEmptyTables?: boolean;
  tableExplorerShowViews?: boolean;
  tableExplorerShowEmptyTables?: boolean;
  openSavedQueryTabIds?: string[];
  selectedSavedQueryId?: string;
  summaryTabHideAIDisclaimer?: boolean;
  pipelineCustomizeVertexKeys?: string[];
  currentPopoverTourStep?: OnboardingPopoverTourStep;
  lastPromotionBannerDismissedTime?: number;
  hasSeenPromotionReferralBanner?: boolean;
  hasSeenPromotionAIAssistantBanner?: boolean;
}

// Every value in the preferences is optional as the database starts with an empty object for each user.
export interface UserPreferencesContextInterface {
  initialLoadComplete: boolean;
  userPreferences: UserPreferencesInterface;
  updateUserPreferences: (newPreferences: UserPreferencesInterface) => void;
}

export const UserPreferencesContext = createContext<UserPreferencesContextInterface>({
  initialLoadComplete: false,
  userPreferences: {},
  updateUserPreferences: () => {},
});
