// Ideally a multiple of 16 OR some standard video size.
import { LabeledTab } from 'components/layouts/parts/TabRow/TabRow';

import { UsefulLink } from '../../UsefulLinks/UsefulLinks';

// However, youtube seems to gracefully resize it's video player iframe to any size.
export const VIDEO_WIDTH = 368;
export const VIDEO_HEIGHT = (VIDEO_WIDTH / 16) * 9;

interface LearnMore {
  label: string;
  url: string;
  segmentEvent: string;
}

export interface SetupMethodInstructionsProps {
  label: LabeledTab;
  videoUrl: string;
  steps: string[];
  learnMore: LearnMore;
}

export default function SetupMethodInstructions(props: SetupMethodInstructionsProps) {
  const { videoUrl, steps, learnMore } = props;

  let safeVideoUrl = videoUrl;
  // This prevents youtube from suggesting videos that aren't germane to Mozart Data.
  safeVideoUrl += '?rel=0';

  return (
    <div className="">
      <div style={{ width: '100%', height: 'auto', aspectRatio: '16 / 9' }}>
        <iframe
          title="Getting Started Video"
          width="100%"
          height="100%"
          src={safeVideoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <ol className="mt-4 pl-4 text-sm text-pri-gray-500 leading-relaxed list-decimal">
        {steps.map((step) => (
          <li key={step}>{step}</li>
        ))}
      </ol>
      <div className="mt-2">
        <UsefulLink label={learnMore.label} href={learnMore.url} segmentEvent={learnMore.segmentEvent} />
      </div>
    </div>
  );
}
