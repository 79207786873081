import { OnboardingPopoverTourStep } from 'model_layer/OnboardingPopoverTourContext';

export interface OnboardingTourStepConfig {
  key: OnboardingPopoverTourStep;
  heading: string;
  imgSrc: string;
  detail: string;
}

const TOUR_STEPS: OnboardingTourStepConfig[] = [
  {
    key: 'connectors',
    heading: 'Automatically sync all of your data',
    imgSrc: '/images/graphics/onboarding_popover_tour/connectors.svg',
    detail:
      'Sync data from more than 1,000 external apps and databases including HubSpot, Stripe, Google Ads, Postgres, and many more with a few clicks.',
  },
  {
    key: 'warehouse',
    heading: 'Central hub for storing all your data',
    imgSrc: '/images/graphics/onboarding_popover_tour/warehouse.svg',
    detail: 'All of your data in one place. Data discovery begins here.',
  },
  // 01/16/23 - This step was specked but removed to make the tour simpler for non-technical users and shorter.
  // {
  //   key: 'dbt',
  //   heading: 'Automated dbt',
  //   imgSrc: '/images/graphics/onboarding_popover_tour/dbt.svg',
  //   detail: 'Professional grade transforms for advanced data engineers.',
  // },
  {
    key: 'query',
    heading: 'Explore your data',
    imgSrc: '/images/graphics/onboarding_popover_tour/query.svg',
    detail: 'Dive into your data and get answers.',
  },
  {
    key: 'transforms',
    heading: 'Quick & Easy SQL Transforms',
    imgSrc: '/images/graphics/onboarding_popover_tour/transforms.svg',
    detail:
      'Transforms format raw data and/or combine data from multiple sources to make it easier to work with. Transforms automatically run on a schedule so your data is always fresh.',
  },
  {
    key: 'alerts',
    heading: 'Get Notified',
    imgSrc: '/images/graphics/onboarding_popover_tour/alerts.svg',
    detail: 'Stay ahead of your data with timely data quality & metrics checks.',
  },
  {
    key: 'integrations',
    heading: 'Connect your favorite tools to your data',
    imgSrc: '/images/graphics/onboarding_popover_tour/integrations.svg',
    detail: 'Connect Business Intelligence (BI), Reverse-ETL, or any other tools to use your data.',
  },
  {
    key: 'docs',
    heading: 'Helpful Docs & Videos',
    imgSrc: '/images/graphics/onboarding_popover_tour/documentation.svg',
    detail: 'Click the question mark to get docs relevant to the page you are on, videos, and more.',
  },
  // {
  //   key: 'analyst_support',
  //   heading: 'Award-winning Analyst Support',
  //   imgSrc: '/images/graphics/onboarding_popover_tour/analyst_support.svg',
  //   detail: 'Got questions? Ask for help.',
  // },
];

export default TOUR_STEPS;

const transformIndex = TOUR_STEPS.findIndex((s) => s.key === 'transforms');

export const nextTourStep = (
  isViewer: boolean,
  currentStep?: OnboardingPopoverTourStep,
): OnboardingPopoverTourStep => {
  if (currentStep === undefined) {
    return 'connectors';
  }
  const stepIndex = TOUR_STEPS.findIndex((s) => s.key === currentStep);
  let nextIndex = stepIndex + 1;
  // Viewers don't have an Add Menu for the Transform Popover to point to.
  // Skip over this step.
  if (isViewer && nextIndex === transformIndex) {
    nextIndex++;
  }
  if (nextIndex < TOUR_STEPS.length) {
    return TOUR_STEPS[nextIndex].key;
  }
  // The tour is over. Close it.
  else {
    return 'complete';
  }
};

export const previousTourStep = (
  isViewer: boolean,
  currentStep?: OnboardingPopoverTourStep,
): OnboardingPopoverTourStep => {
  if (currentStep === undefined) {
    return 'connectors';
  }
  const stepIndex = TOUR_STEPS.findIndex((s) => s.key === currentStep);
  let nextIndex = stepIndex - 1;
  // Viewers don't have an Add Menu for the Transform Popover to point to.
  // Skip over this step.
  if (isViewer && nextIndex === transformIndex) {
    nextIndex--;
  }
  if (nextIndex >= 0) {
    return TOUR_STEPS[nextIndex].key;
  } else {
    return TOUR_STEPS[0].key;
  }
};
