import React from 'react';

import s from './LoggedOutFormBackgroundLayout.module.css';

export interface LoggedOutFormBackgroundLayoutProps {
  children: React.ReactNode;
}

export default function LoggedOutFormBackgroundLayout(props: LoggedOutFormBackgroundLayoutProps) {
  let { children } = props;
  return (
    <div className={s.outerDiv}>
      <div className={s.backgroundGradient} />
      <img
        className="absolute left-[-41px] bottom-[-15px] lg:bottom-0 lg:left-0 z-[-10]"
        src="/images/backgrounds/LoggedOutFormBackgroundLayoutPatternLinesBottom.svg"
        alt=""
      />
      <img
        className="absolute top-[-23px] lg:top-0 right-[-36px] lg:right-0 z-[-10]"
        src="/images/backgrounds/LoggedOutFormBackgroundLayoutPatternLinesTop.svg"
        alt=""
      />
      {children}
    </div>
  );
}
