import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import { Connector } from 'api/APITypes';
import { Dashboard, ReportAvailable } from 'api/dashboardAPI';

import { authorizeGSheetPath, setupCompletePath } from '../pathUtils';
import { trackDashboard } from '../reportUtils';

import ReviewTransformsView from './ReviewTransformsView';

interface ReviewTransformsControllerProps {
  connector: Connector;
  dashboard: Dashboard;
  reportsAvailable: ReportAvailable[];
}

export default function ReviewTransformsController(props: ReviewTransformsControllerProps) {
  useTitle('Review Transforms');
  const { connector, dashboard, reportsAvailable } = props;
  const navigate = useNavigate();

  const handleGoToGSheetConfig = () => {
    if (dashboard.gsheet_spreadsheet_id) {
      trackDashboard('ReportBuilderReviewTransforms SkipAuthorizeGSheet', connector, dashboard);
      navigate(setupCompletePath(connector));
    } else {
      trackDashboard('ReportBuilderReviewTransforms GoToAuthorizeGSheet', connector, dashboard);
      navigate(authorizeGSheetPath(connector));
    }
  };

  return (
    <ReviewTransformsView
      connector={connector}
      dashboard={dashboard}
      reportsAvailable={reportsAvailable}
      onGoToGSheetConfig={handleGoToGSheetConfig}
    />
  );
}
