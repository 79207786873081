import { AggRecentTable } from 'api/tableAPI';
import TableIcon from 'components/primitives/icons/TableIcon/TableIcon';
import AgoHover from 'components/widgets/time/AgoHover/AgoHover';
import { parseIso } from 'utils/dateTime';

export interface RecentWorkWidgetItemProps {
  aggRecentTable: AggRecentTable;
  onClickRecentWorkWidgetItem: (url: string, aggRecentTable: AggRecentTable) => void;
}

export default function RecentWorkWidgetItem(props: RecentWorkWidgetItemProps) {
  const { aggRecentTable, onClickRecentWorkWidgetItem } = props;
  const { type, full_name } = aggRecentTable;
  const url =
    type === 'transform' ? `/tables/${aggRecentTable.id}/transform` : `/tables/${aggRecentTable.id}`;

  return (
    <tr
      role="button"
      onClick={() => onClickRecentWorkWidgetItem(url, aggRecentTable)}
      className="h-12 hover:bg-pri-gray-100 border-y border-pri-gray-200"
    >
      <td>
        <div className="f-row-y-center">
          <div className="w-[14px] min-w-[14px] mr-[6px]">
            <TableIcon table={aggRecentTable} size={14} variant="dullAction" />
          </div>
          <p className="hover:underline hover:text-sec-blue-gray-500 font-medium text-pri-gray-700 text-sm">
            {full_name}
          </p>
        </div>
      </td>
      <td>
        <div className="f-row-y-center justify-end">
          <div className="w-[95px] text-xs text-pri-gray-500">
            <AgoHover date={parseIso(aggRecentTable.last_visited_at)} />
          </div>
        </div>
      </td>
    </tr>
  );
}
