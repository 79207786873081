import React from 'react';

import cn from 'classnames';

import { cleanColor } from 'utils/String';

interface ProgressBarProps {
  numerator: number;
  denominator: number;
  barColor?: string;
  backgroundColor?: string;
  overageBarColor?: string;
  containerClassName?: string; // For things like width, height, and border
}

export default function ProgressBar(props: ProgressBarProps) {
  const { numerator, denominator, barColor, backgroundColor, overageBarColor, containerClassName } =
    props;

  const defaultContainerClassName = 'h-4 w-[160px]';

  const [firstWidthPercentage, secondWidthPercentage] = getPercentageBarWidths(numerator, denominator);

  const isOver100Percent = numerator > denominator;

  // If num > den, it's an overage and we introduce some spacing and a bar, we take this half from each bar
  // We subtract 10px (4px margin, 2px divider, 4px margin) total, so 5px from each width
  const actualFirstWidth = isOver100Percent
    ? `max(calc(${firstWidthPercentage}% - 5px), 1px)`
    : `${firstWidthPercentage}%`;
  const actualSecondWidth = isOver100Percent
    ? `max(calc(${secondWidthPercentage}% - 5px), 1px)`
    : `${secondWidthPercentage}%`;

  const actualBarColor = cleanColor(barColor ? barColor : '--sec-blue-gray-500');
  const actualBackgroundColor = cleanColor(backgroundColor ? backgroundColor : '--sec-blue-gray-200');
  const actualOverageBarColor = cleanColor(overageBarColor ? overageBarColor : '--pri-error-500');

  const secondBarBackgroundColor = isOver100Percent ? actualOverageBarColor : actualBackgroundColor;

  return (
    <div
      className={cn(containerClassName || defaultContainerClassName, 'f-row-y-center overflow-hidden')}
    >
      <div
        className={cn('h-full', { rounded: isOver100Percent })}
        style={{ width: actualFirstWidth, backgroundColor: actualBarColor }}
      />
      {isOver100Percent && <div className="mx-1 w-[2px] rounded bg-pri-gray-400 self-stretch" />}
      <div
        className={cn('h-full', { rounded: isOver100Percent })}
        style={{ width: actualSecondWidth, backgroundColor: secondBarBackgroundColor }}
      />
    </div>
  );
}

function getPercentageBarWidths(num: number, den: number) {
  if (num === 0 || den === 0) {
    return [0, 100];
  }
  const firstWidth = num <= den ? (num / den) * 100 : (den / num) * 100;
  const secondWidth = 100 - firstWidth;
  return [firstWidth, secondWidth];
}
