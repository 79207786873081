import { useState } from 'react';

import API from 'api/API';
import { AppConnector } from 'api/APITypes';

import { LocalSyncingState } from './useLocalSyncingState';

export interface SyncNowState {
  error: string;
  disabled: boolean;
  spinning: boolean;
  syncNow: () => void;
}

export default function useSyncNowState(
  connector: AppConnector,
  localSyncState: LocalSyncingState,
  resetPolling: () => void,
): SyncNowState {
  const [error, setError] = useState('');

  const disabled =
    connector.setupState === 'incomplete' || !!connector.disablingError || connector.paused;

  const { syncState } = connector;

  const [awaitingManualSyncStart, setAwaitingManualSyncStart] = useState(false);

  const spinning = syncState === 'syncing' || localSyncState.awaitingSync;
  const localSyncKey = `syncNow-${connector.fivetran_connector_id}`;

  // We need to check if awaitingManualSyncStart is true, since apparently setting it to false
  // causes a rerender even if it was already false.
  if (syncState === 'syncing' && awaitingManualSyncStart) {
    setAwaitingManualSyncStart(false);
    localSyncState.setNotAwaitingSync(localSyncKey);
  }

  const syncNow = async () => {
    const api = new API();
    setError('');
    setAwaitingManualSyncStart(true);
    localSyncState.setAwaitingSync(localSyncKey);

    try {
      await api.post(`api/fivetran/connector/${connector.fivetran_connector_id}/sync_now`, {});
      resetPolling();
      analytics.track('ShowConnectorSummaryTab SyncNow');
    } catch (_e) {
      setError('There was a problem syncing the connector');
      setAwaitingManualSyncStart(false);
      localSyncState.setNotAwaitingSync(localSyncKey);
    }
  };

  return {
    error,
    disabled,
    spinning,
    syncNow,
  };
}
