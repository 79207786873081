import { Connector } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import Alert from 'components/widgets/alerts/Alert/Alert';

import ConnectorLayout from '../ConnectorLayout';

export interface AuthorizeGSheetViewProps {
  connector: Pick<Connector, 'id' | 'service' | 'schema'>;
  saving: boolean;
  error: string;
  onAuthorize(): void;
}

export default function AuthorizeGSheetView(props: AuthorizeGSheetViewProps) {
  const { connector, saving, error, onAuthorize } = props;

  return (
    <ConnectorLayout connector={connector}>
      <h2 className="mt-10 text-xl font-medium">
        You are almost there! Please authorize your Google account so we can create a sheet for you.
      </h2>
      {error && (
        <Alert variant="error" className="mt-6">
          {error}
        </Alert>
      )}
      <div className="my-6 f-row-y-center">
        {/* <Button onClick={onGoBack}>Back To Transforms</Button> */}
        <Button onClick={onAuthorize} spinning={saving}>
          Authorize
        </Button>
      </div>
    </ConnectorLayout>
  );
}
