import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function IncrementalRunInfoIcon(props: { transformTable: string }) {
  const content = (
    <div>
      <div>
        <strong>Run this transform in incremental mode now.</strong>
      </div>
      <div className="mt-2">
        If the table{' '}
        <code className="ace-code">
          <span className="ace-keyword">{props.transformTable}</span>
        </code>{' '}
        already exists, this transform will insert or merge the results of running the query in
        incremental mode.
      </div>
      <div className="mt-2">
        If the table{' '}
        <code className="ace-code">
          <span className="ace-keyword">{props.transformTable}</span>
        </code>{' '}
        does not exist, this transform will create it and fill it with the results of running the
        transform in non-incremental mode.
      </div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} popoverProps={popoverProps} placement="top" />;
}
