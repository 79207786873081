import { useContext } from 'react';

import { useHistory } from 'react-router';

import { AggRecentTable } from 'api/tableAPI';
import { TableModelsContext } from 'model_layer/TableModelsContext';

import { RecentWorkWidgetState } from './RecentWorkWidget/RecentWorkWidget';

export default function useRecentWorkWidget(): RecentWorkWidgetState {
  const { allLoaded, anyError, recentTables } = useContext(TableModelsContext);
  const history = useHistory();

  const onClickRecentWorkWidgetItem = (url: string, aggRecentTable: AggRecentTable) => {
    analytics.track('HomepageRecentWorkWidget GoToRecentWorkItem', {
      tableId: aggRecentTable.id,
    });
    history.push(url);
  };

  /**
   * Track click event for the View Usage button. Navigation is handled by the parent component.
   */
  const onClickViewAll = () => {
    analytics.track('HomepageRecentWorkWidget ViewAllRecentWorkItems');
  };

  return {
    allLoaded,
    anyError,
    recentTables: recentTables.slice(0, 10),
    onClickRecentWorkWidgetItem,
    onClickViewAll,
  };
}
