import InputCopy from 'components/inputs/composite/InputCopy/InputCopy';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { CredentialsResponse } from './GenerateSnowflakeUser';

interface CredentialsDivProps {
  credentials: CredentialsResponse;
}

const CredentialsDiv = (props: CredentialsDivProps) => {
  const { credentials } = props;

  return (
    <div className="my-4">
      <Alert variant="warning" className="my-2">
        <div>
          Credentials generated successfully. Please copy the credentials below and save them in a secure
          location. We do not store these credentials and they cannot be retrieved again. However, you
          can generate new Snowflake Users at any time.
        </div>
      </Alert>
      <InputCopy
        isHiddenField={false}
        label="Username"
        value={credentials.username}
        analyticsEvent="IntegrationCredentials UsernameCopy"
        key="username"
        containerClass="py-4"
      />
      <InputCopy
        isHiddenField={true}
        label="Password"
        value={credentials.password}
        analyticsEvent="IntegrationCredentials PasswordCopy"
        key="password"
        containerClass="py-4"
      />
      <InputCopy
        isHiddenField={false}
        label="Role"
        value={credentials.role}
        analyticsEvent="IntegrationCredentials RoleCopy"
        key="role"
        containerClass="py-4"
      />
      <div className="mt-6 mb-2 p-4 bg-pri-gray-50 text-pri-gray-700">
        <div className="f-col">
          <div>This user was generated with these commands:</div>
          <div className="f-col text-sec-purple-500">
            <code className="mt-2">CREATE USER {credentials.username};</code>
            <code className="mt-2">
              GRANT ROLE {credentials.role} TO USER {credentials.username};
            </code>
            <code className="mt-2">
              ALTER USER {credentials.username} SET default_role={credentials.role}
            </code>
            <code>default_warehouse={credentials.warehouse}</code>
            <code>password='Password Given Above'</code>
            {credentials.first_name && <code>first_name='{credentials.first_name}'</code>}
            {credentials.last_name && <code>last_name='{credentials.last_name}'</code>}
            {credentials.email && <code>email='{credentials.email}'</code>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CredentialsDiv;
