// sort-imports-ignore
import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './App.css';

import hotKeyConfig from './hotKeyConfig';
import AuthenticatedApp from './AuthenticatedApp';
import Footer from 'components/page_parts/Footer/Footer';
import UnauthenticatedApp from './UnauthenticatedApp';
import { useAuth } from 'context/AuthContext';
import { env } from 'utils/env';
import { useHasFooter } from 'main/MainApp';

/*
Log a click event on links and buttons if it has an attribute of the form:
Format:  data-track="ComponentName Verb"
Example: data-track="RunRow ShowAdvanvedOptions"
OR
Format:  data-track="ComponentName ClickedOnNoun"
Example: data-track="Header Logout"
If the button has a click handler that runs event.stopPropagation()
you'll have to log the event in the click handler.
*/
function track(event: React.MouseEvent) {
  // A button might have icons or other elements inside of it.
  // Don't search for a data-track attribute past this depth
  const MAX_DEPTH = 3;
  let depth = 0;
  let target = event.target as HTMLElement;
  do {
    let track = target.dataset.track;
    if (track) {
      analytics.track(track);
      return;
    } else {
      const newTarget = target.parentElement;
      if (!newTarget) {
        return;
      }
      target = newTarget;
    }
    depth++;
  } while (depth < MAX_DEPTH);
}

hotKeyConfig();

function App() {
  const { isLoggedIn, userProfile } = useAuth();
  const hasFooter = useHasFooter();

  // Log every page vists to Segment
  // Use pathname as dependency because some routes like /query rewrite the path
  // when they load triggering and second navigation which we don't want to count
  let location = useLocation();
  useEffect(() => {
    analytics.page();
  }, [location.pathname]);

  // In dev, we use ngrok to host an https server. However, this breaks login auth.
  // So if we get the ngrok url, redirect to localhost with the same params.
  // ngrok http --region=us --hostname=devslackfrontend.ngrok.io 3000
  if (window.location.origin === 'https://devslackfrontend.ngrok.io') {
    return (
      <Route
        exact
        path="*"
        render={() => {
          window.location.href = `http://localhost:3000${window.location.pathname}${window.location.search}`;
          return null;
        }}
      />
    );
  }

  return (
    <div onClickCapture={track} className="min-h-screen flex flex-col justify-between">
      <Helmet>
        <title>Mozart Data</title>
        <meta name="description" content="The quickest way to build a modern data stack." />
        <meta
          name="keywords"
          content="Analytics, SQL, Modern Data Stack, Data Pipeline, Data Warehouse"
        />
        <meta name="author" content="Mozart Data" />
        <link
          rel="icon"
          href={
            env === 'development'
              ? '/favicon_development.png'
              : env === 'staging'
                ? '/favicon_staging.png'
                : '/favicon.ico?v=2'
          }
          sizes="16x16"
        />
        {isLoggedIn && (
          <meta name="viewport" content={`width=1280, initial-scale=${window.screen.width / 1280}`} />
        )}
      </Helmet>
      <div id="page-content">
        {isLoggedIn && userProfile ? (
          <AuthenticatedApp userProfile={userProfile} />
        ) : (
          <UnauthenticatedApp />
        )}
      </div>

      {/* Do not render footer on certain pages */}
      {hasFooter && <Footer />}
    </div>
  );
}

export default App;
