/**
 * Some of our icons are Bootstrap icons and some are custom SVGs.
 * This file takes care of the logistics of rendering either.
 */
import {
  Table,
  LayoutThreeColumns,
  Union,
  Funnel,
  Grid1x2,
  SortAlphaDown,
  Icon,
} from 'react-bootstrap-icons';

import { VertexType } from '../../model/FlowchartQueryModel';

/*******************************************************************************
 * ToolbarItemIcon
 ******************************************************************************/
export interface ToolbarItemIconProps {
  vertexType: VertexType;
  enabled: boolean;
  size: number;
}

const ToolbarItemIcon = (props: ToolbarItemIconProps) => {
  const { vertexType, enabled, size } = props;

  const iconColor = enabled ? 'var(--sec-blue-gray-500)' : 'var(--pri-gray-400)';

  if (vertexType === 'join') {
    return <JoinIcon size={size} color={iconColor} />;
  }

  const BootstrapIcon = BOOTSTRAP_ICON_MAP[vertexType];
  if (BootstrapIcon) {
    return <BootstrapIcon size={size} color={iconColor} />;
  }

  // We should never get here.
  return null;
};

export default ToolbarItemIcon;

/*******************************************************************************
 * Bootstrap Icons
 ******************************************************************************/
type IconMapVertexType = Exclude<VertexType, 'join'>;
const BOOTSTRAP_ICON_MAP: Record<IconMapVertexType, Icon> = {
  source_table: Table,
  select_columns: LayoutThreeColumns,
  union: Union,
  where: Funnel,
  group_by: Grid1x2,
  order_by: SortAlphaDown,
};
/*******************************************************************************
 * SVG Icons
 ******************************************************************************/
export interface SVGIconProps {
  color: string;
  size: number;
}

export const JoinIcon = (props: SVGIconProps) => {
  const { color, size } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#fv04hnxq5a)" fill={color}>
        <path d="M13.125 1.25c.249 0 .381.131.438.188a.584.584 0 0 1 .187.437v11.25a.569.569 0 0 1-.188.438.569.569 0 0 1-.437.187H1.875a.572.572 0 0 1-.438-.188.572.572 0 0 1-.187-.437V1.875c0-.249.11-.36.188-.438a.55.55 0 0 1 .437-.187h11.25zM1.875 0A1.875 1.875 0 0 0 0 1.875v11.25A1.875 1.875 0 0 0 1.875 15h11.25A1.875 1.875 0 0 0 15 13.125V1.875A1.875 1.875 0 0 0 13.125 0H1.875z" />
        <path d="M18.125 6.25c.249 0 .381.131.438.188a.584.584 0 0 1 .187.437v11.25a.569.569 0 0 1-.188.438.569.569 0 0 1-.437.187H6.875a.572.572 0 0 1-.438-.188.572.572 0 0 1-.187-.437V6.875c0-.249.11-.36.188-.438a.55.55 0 0 1 .437-.187h11.25zM6.875 5A1.875 1.875 0 0 0 5 6.875v11.25A1.875 1.875 0 0 0 6.875 20h11.25A1.875 1.875 0 0 0 20 18.125V6.875A1.875 1.875 0 0 0 18.125 5H6.875z" />
      </g>
      <defs>
        <clipPath id="fv04hnxq5a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
