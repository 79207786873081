import React from 'react';

import { Diagram3Fill, CameraFill, FiletypeCsv, InfoCircle } from 'react-bootstrap-icons';

import { AggTable } from 'api/APITypes';
import ConnectorIcon from 'components/primitives/icons/ConnectorIcon/ConnectorIcon';

import DbtIcon from '../DbtIcon/DbtIcon';
import { IconSize } from '../ImgIcon/ImgIcon';

export interface TableIconProps {
  table: AggTable;
  size: IconSize;
  variant?: 'teal' | 'dullAction';
}
export default function TableIcon(props: TableIconProps) {
  const { table, size, variant = 'teal' } = props;
  const color = variant === 'teal' ? 'var(--teal)' : 'var(--sec-blue-gray-500)';
  if (table.type === 'dbt') {
    return <DbtIcon size={size} />;
  } else if (table.type === 'transform') {
    return <Diagram3Fill size={size} color={color} transform="rotate(90 0 0)" />;
  } else if (table.type === 'snapshot') {
    return <CameraFill size={size} color={color} />;
  } else if (table.type === 'csv_upload') {
    return <FiletypeCsv size={size} color={color} />;
  } else {
    let service = '';
    if (table.connector) {
      service = table.connector.service;
    }

    if (service === '' && table.schema === 'information_schema') {
      return <InfoCircle size={size} color={color} />;
    }
    return <ConnectorIcon service={service} size={size} />;
  }
}
