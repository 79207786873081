import React, { useState, useEffect } from 'react';

import Button from 'components/inputs/basic/Button/Button';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import { SavedQuery } from 'pages/Query/useQueryTabs';

interface ShareQueryModalProps {
  savedQuery: SavedQuery;
  onClose(): void;
}

export default function ShareQueryModal(props: ShareQueryModalProps) {
  const { savedQuery, onClose } = props;
  const [copiedLink, setCopiedLink] = useState(false);

  useEffect(() => {
    copiedLink &&
      setTimeout(() => {
        setCopiedLink(!copiedLink);
      }, 1000);
  }, [copiedLink]);

  const handleCopyLink = () => {
    const url = `${window.location.origin}/query/${savedQuery.id}`;
    navigator.clipboard.writeText(url);
    setCopiedLink(!copiedLink);
    analytics.track('Query CopySavedQueryLink');
  };

  const footer = (
    <div className="w-full f-between">
      <TooltipTrigger tip="Copied" forceShow={copiedLink} placement="top">
        <Button size="small" variant="lightDullAction" onClick={handleCopyLink}>
          Copy Link
        </Button>
      </TooltipTrigger>
      <Button size="small" variant="darkDullAction" onClick={onClose}>
        Done
      </Button>
    </div>
  );
  return (
    <Modal footer={footer} onClose={onClose} cancelButton={true} containerClass="w-[400px] m-auto">
      <div className="h-full flex flex-col p-6">
        <div className="text-base font-medium">Share "{savedQuery.name}"</div>
        <div className="mt-1 text-sm">
          Anyone in your company with the link can view and edit this query.
        </div>
      </div>
    </Modal>
  );
}
