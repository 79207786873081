const iconUrl = (platform: string): string => {
  if (!platform) {
    // eslint-disable-next-line no-console
    console.warn('Missing logo');
    return '';
  }
  return `/images/bi_icons/${platform}.svg`;
};

export { iconUrl };
