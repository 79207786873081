/*
Hook that hold data alert logic that is shared between alerts page and alerts tab.
*/
import { useCallback, useContext, useEffect, useState } from 'react';

import API from 'api/API';
import { AggTable, QueryRunResults } from 'api/APITypes';
import { TableContext } from 'model_layer/TableContext';
import { handleSqlErrors } from 'utils/apiResponseFormatter';
import { patch } from 'utils/Array';

export const alertsHelpURL = 'https://help.mozartdata.com/docs/data-alerts';

export type DataAlertType = 'custom' | 'premade';

export type DataAlertMode = 'block' | 'notify';

interface APIDataAlert {
  id: string;
  type: DataAlertType;
  name: string;
  sql: string;
  enabled: boolean;
  mode: DataAlertMode;
  schedule: string;
  table: string; // Is actually the table_id, but we call it table because of django
  sql_changed_since_last_run: boolean;
  should_send_csv: boolean;
  last_table_test_run: DataAlertRun | null;
}

export interface DataAlertWithTable extends Omit<APIDataAlert, 'table'> {
  table: AggTable;
}

export interface DataAlertRun {
  id: string;
  created_at: string;
  succeeded: boolean;
  num_rows: number;
  mode: DataAlertMode;
  error_message: string;
  transform_run_id: string | null;
}

export interface SavableDataAlertProps {
  name?: string;
  sql?: string;
  enabled?: boolean;
  should_send_csv?: boolean;
  mode?: string;
}

export interface CreateDataAlertProps {
  table: string; // This is actually the table_id
  dataAlertName: string;
  sql: string;
}

interface useDataAlertsProps {
  eventPage: string;
  table?: AggTable;
}

export default function useDataAlerts(props: useDataAlertsProps) {
  const { eventPage, table } = props;
  const [apiDataAlerts, setAPIDataAlerts] = useState<APIDataAlert[] | null>(null);
  const [dataAlerts, setDataAlerts] = useState<DataAlertWithTable[]>([]);
  const [loading, setLoading] = useState(true); // Default to true since it needs to load on mount
  const [errorMessage, setErrorMessage] = useState('');
  const [saving, setSaving] = useState(false);
  const [selectedDataAlert, setSelectedDataAlert] = useState<DataAlertWithTable | null>(null);
  const [updateErrorMessage, setUpdateErrorMessage] = useState<string>('');
  const [createErrorMessage, setCreateErrorMessage] = useState<string>('');
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [failureLogs, setFailureLogs] = useState<QueryRunResults | null>(null);
  const [failureLogsErrorLines, setFailureLogsErrorLines] = useState<string[]>([]);
  // If we have failureLogs or failureLogserrorLines, which test do they belong to
  const [failureLogsDataAlert, setFailureLogsDataAlert] = useState<DataAlertWithTable | null>(null);
  const [loadingFailureLogs, setLoadingFailureLogs] = useState<boolean>(false);
  const [unsavedSql, setUnsavedSql] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { tables, isLoading: tableContextIsLoading } = useContext(TableContext);

  // Get the apiDataAlerts right away
  useEffect(() => {
    const api = new API();
    const url = table ? `/api/table_tests?table_id=${table.id}` : '/api/table_tests';
    api
      .get(url)
      .then((response) => {
        setAPIDataAlerts(response.data);
      })
      .catch(() => {
        setErrorMessage('There was a problem fetching data alerts.');
        setLoading(false);
      });
  }, [table]);

  // when we have apiDataAlerts and tableContext, we can make the dataAlerts
  useEffect(() => {
    if (!tableContextIsLoading && apiDataAlerts) {
      // If we have a table, it means we are on the alerts tab, so only get alerts for the one table
      const tablesList = table ? [table] : tables;
      const dataAlerts = apiDataAlerts
        .map((dataAlert: APIDataAlert) => {
          return convertAPIDataAlert(dataAlert, tablesList);
        })
        .sort(
          (a: DataAlertWithTable, b: DataAlertWithTable) =>
            a.table.full_name.localeCompare(b.table.full_name) || a.name.localeCompare(b.name),
        );

      setDataAlerts(dataAlerts);
      setLoading(false);
    }
  }, [table, tables, tableContextIsLoading, apiDataAlerts]);

  const catchDataAlertErrors = useCallback((e: any, errorSetter: (value: string) => void) => {
    if (e.response?.data?.sql) {
      const code = e.response.data.sql[0];
      errorSetter(handleSqlErrors(code));
    } else if (e.response?.data?.non_field_errors) {
      const code = e.response.data.non_field_errors[0];
      if (code === 'name_already_in_use') {
        errorSetter('Name is already in use.');
      } else if (code === 'non_transform_block_mode') {
        errorSetter('Block mode is only supported for transforms.');
      } else {
        errorSetter('There was a problem saving data alert.');
      }
    } else {
      errorSetter('There was a problem saving data alert.');
    }
  }, []);

  const handleUpdateDataAlert = useCallback(
    (dataAlert: DataAlertWithTable, savableProps: SavableDataAlertProps) => {
      setErrorMessage('');
      setSaving(true);
      const api = new API();
      return api
        .patch(`/api/table_tests/${dataAlert.id}`, savableProps)
        .then((response) => convertAPIDataAlert(response.data, tables))
        .then((newDataAlert) => {
          analytics.track(`${eventPage} UpdateDataAlert`);
          const newDataAlerts = patch(dataAlerts, newDataAlert, (tt) => tt.id === dataAlert.id);
          setDataAlerts(
            newDataAlerts.sort(
              (a: DataAlertWithTable, b: DataAlertWithTable) =>
                a.table.full_name.localeCompare(b.table.full_name) || a.name.localeCompare(b.name),
            ),
          );
          // If we updated the currently selected data alert, update that too
          if (selectedDataAlert && selectedDataAlert.id === newDataAlert.id) {
            setSelectedDataAlert(newDataAlert);
          }
          return newDataAlert;
        })
        .catch((e) => {
          catchDataAlertErrors(e, setUpdateErrorMessage);
          return Promise.reject(e);
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [dataAlerts, eventPage, selectedDataAlert, tables, catchDataAlertErrors],
  );

  const handleDeleteDataAlert = useCallback(
    (dataAlert: DataAlertWithTable) => {
      setErrorMessage('');
      const api = new API();
      api
        .delete(`/api/table_tests/${dataAlert.id}`, {})
        .then((response) => {
          analytics.track(`${eventPage} DeleteDataAlert`);
          const newDataAlerts = dataAlerts.filter((tt) => tt.id !== dataAlert.id);
          setDataAlerts(newDataAlerts);
        })
        .catch((e) => {
          setErrorMessage('There was a problem deleting data alert: ' + dataAlert.name);
        });
    },
    [dataAlerts, eventPage],
  );

  const handleCreateDataAlert = (values: CreateDataAlertProps) => {
    setCreateErrorMessage('');
    setSaving(true);
    let postData = {
      table: values.table,
      name: values.dataAlertName,
      sql: values.sql,
      enabled: true,
      mode: 'notify',
    };
    const api = new API();
    api
      .post(`/api/table_tests`, postData)
      .then((response) => {
        analytics.track(`${eventPage} CreateDataAlert`);
        const newDataAlert = convertAPIDataAlert(response.data, tables);
        const newDataAlerts = dataAlerts
          .concat([newDataAlert])
          .sort(
            (a, b) => a.table.full_name.localeCompare(b.table.full_name) || a.name.localeCompare(b.name),
          );
        setDataAlerts(newDataAlerts);
        setCreateModalOpen(false);
      })
      .catch((e) => {
        catchDataAlertErrors(e, setCreateErrorMessage);
      })
      .catch(() => {
        // Error handled above
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleSetUnsavedSql = (unsaved: boolean) => {
    setUnsavedSql(unsaved);
  };

  const handleShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmConfirmModal = () => {
    // This handles both create and edit modals
    if (createModalOpen) {
      analytics.track(`${eventPage} CloseCreateModal`);
      setCreateModalOpen(false);
      setCreateErrorMessage('');
    }
    if (selectedDataAlert !== null) {
      analytics.track(`${eventPage} CloseDataAlertModal`);
      setSelectedDataAlert(null);
      setUpdateErrorMessage('');
    }
    setShowConfirmModal(false);
  };

  const handleOpenCreateModal = () => {
    analytics.track(`${eventPage} OpenCreateModal`);
    setCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    if (unsavedSql) {
      handleShowConfirmModal();
    } else {
      analytics.track(`${eventPage} CloseCreateModal`);
      setCreateModalOpen(false);
      setCreateErrorMessage('');
    }
  };

  const handleCloseDataAlertModal = () => {
    if (unsavedSql) {
      handleShowConfirmModal();
    } else {
      analytics.track(`${eventPage} CloseDataAlertModal`);
      setSelectedDataAlert(null);
      setUpdateErrorMessage('');
    }
  };

  const handleSelectDataAlert = useCallback(
    (dataAlert: DataAlertWithTable) => {
      analytics.track(`${eventPage} SelectDataAlert`);
      setSelectedDataAlert(dataAlert);
    },
    [eventPage],
  );

  const handleCloseFailureLogsModal = () => {
    analytics.track(`${eventPage} CloseFailureLogsModal`);
    setFailureLogs(null);
    setFailureLogsErrorLines([]);
    setFailureLogsDataAlert(null);
  };

  const handleGetFailureLogs = useCallback(
    (dataAlert: DataAlertWithTable) => {
      if (dataAlert.last_table_test_run?.id) {
        analytics.track(`${eventPage} GetFailureResults`);
        setFailureLogs(null);
        setFailureLogsErrorLines([]);
        setFailureLogsDataAlert(dataAlert);
        setLoadingFailureLogs(true);
        const api = new API();
        api
          .get(
            `/api/table_tests/${dataAlert.id}/failure_logs?table_test_run_id=${dataAlert.last_table_test_run.id}`,
          )
          .then((response) => {
            setFailureLogsDataAlert(dataAlert);
            if (response.data.error_lines) {
              setFailureLogsErrorLines(response.data.error_lines);
            } else {
              setFailureLogs(response.data.results);
            }
          })
          .catch((e) => {
            setCreateErrorMessage('There was a problem fetching failure logs.');
            setFailureLogsDataAlert(null);
          })
          .finally(() => {
            setLoadingFailureLogs(false);
          });
      }
    },
    [eventPage],
  );

  return {
    loading,
    saving,
    errorMessage,
    dataAlerts,
    selectedDataAlert,
    updateErrorMessage,
    createErrorMessage,
    failureLogs,
    failureLogsErrorLines,
    failureLogsDataAlert,
    loadingFailureLogs,
    showConfirmModal,
    createModalOpen,
    setUpdateErrorMessage,
    handleUpdateDataAlert,
    handleDeleteDataAlert,
    handleCreateDataAlert,
    handleSetUnsavedSql,
    handleShowConfirmModal,
    handleCloseConfirmModal,
    handleConfirmConfirmModal,
    handleOpenCreateModal,
    handleCloseCreateModal,
    handleCloseDataAlertModal,
    handleSelectDataAlert,
    handleCloseFailureLogsModal,
    handleGetFailureLogs,
  };
}

const convertAPIDataAlert = (dataAlert: APIDataAlert, tables: AggTable[]) => {
  const table = tables.find((t) => t.id === dataAlert.table);
  let aggDataAlert = { ...dataAlert } as any;
  aggDataAlert.table = table;
  return aggDataAlert as DataAlertWithTable;
};
