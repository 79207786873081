import { useEffect, useState } from 'react';

import API from 'api/API';
import { DbtRun, DbtRunConfig } from 'api/dbtAPI';
import { usePoll } from 'hooks/useApi';

// Exponential backoff
export const DBT_POLLING_TIMES = [
  ...Array((1 * 60) / 2).fill(2), // 1 minute of every 2 seconds
  ...Array((2 * 60) / 5).fill(5), // 2 minutes of every 5 seconds
  ...Array((2 * 60) / 10).fill(10), // 2 minutes of every 10 seconds
  ...Array((2 * 60) / 30).fill(30), // 2 minutes of every 30 seconds
  10 * 60, // Every 10 minutes
];

export interface PaginatedDbtRuns {
  next: string | null;
  previous: string | null;
  results: DbtRun[];
}

const usePaginatedRuns = (runConfig: DbtRunConfig) => {
  const [paginatedRuns, setPaginatedRuns] = useState<PaginatedDbtRuns>({
    next: null,
    previous: null,
    results: [],
  });
  const [error, setError] = useState('');

  const handleLoadPreviousPage = () => {
    if (paginatedRuns.previous) {
      handleLoadDbtRunPage(paginatedRuns.previous);
      analytics.track('ShowDBTRunConfigRunTab LoadPreviousPage');
    }
  };

  const handleLoadNextPage = () => {
    if (paginatedRuns.next) {
      handleLoadDbtRunPage(paginatedRuns.next);
      analytics.track('ShowDBTRunConfigRunTab LoadNextPage');
    }
  };

  const handleLoadDbtRunPage = (url: string) => {
    setError('');
    const api = new API();
    api
      .get(url)
      .then((response) => response.data as PaginatedDbtRuns)
      .then((dbtResponse) => {
        setPaginatedRuns(dbtResponse);
      })
      .catch(() => {
        setError('Failed to fetch DBT Job Runs.');
      });
  };

  const handleSetRuns = (runs: DbtRun[]) => {
    setPaginatedRuns({ ...paginatedRuns, results: runs });
  };

  // Fetch runs when page loads and then poll for updates.
  const {
    data: polledPaginatedRuns,
    error: pollError,
    isLoading: pollIsLoading,
  } = usePoll<PaginatedDbtRuns>(
    `dbtConfigRunHistory/${runConfig.id}`,
    `/api/dbt_runs?configuration=${runConfig.id}`,
    DBT_POLLING_TIMES, // Poll with gradual backoff
    !error, // Poll as long as we have no errors
  );

  // Replace paginated runs as long as the user is on the first page.
  useEffect(() => {
    if (!pollIsLoading && !pollError && paginatedRuns.previous === null && polledPaginatedRuns) {
      setPaginatedRuns(polledPaginatedRuns);
    }
  }, [pollIsLoading, polledPaginatedRuns, pollError, paginatedRuns.previous]);

  return {
    loading: pollIsLoading,
    error: error || pollError,
    paginatedRuns,
    handleLoadPreviousPage,
    handleLoadNextPage,
    handleSetRuns,
  };
};

export default usePaginatedRuns;
