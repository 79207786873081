import React from 'react';

import cn from 'classnames';

interface DragBarProps {
  vertical?: boolean;
  className?: string;
}

export default function DragBar(props: DragBarProps) {
  const { vertical, className } = props;
  const dot = <div className="inline-block w-[4px] h-[4px] bg-pri-gray-400 rounded-full" />;
  const colClass = vertical ? 'grid-cols-2' : 'grid-cols-5';
  const dragBarClass = cn('w-fit grid gap-px', colClass, className);
  return (
    <div className="f-center h-full">
      <div className={dragBarClass}>
        {dot}
        {dot}
        {dot}
        {dot}
        {dot}
        {dot}
        {dot}
        {dot}
        {dot}
        {dot}
      </div>
    </div>
  );
}
