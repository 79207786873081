/*
This is a copy of every route in AuthenticatedApp that does not overlap with
a route in UnauthenticatedApp. UnauthenticatedApp will forward the user to
the login page instead of displaying a 404 if the user is logged out
and the route matches one of these routes.

When you add a new route to AuthenticatedApp, you also need to add that
route's path to this file.

HISTORY:
1. When we first wrote the auth code for Mozart the app was separated into
   an UnauthenticatedApp and an AuthenticatedApp so that there was no overlap
   between code run by users that were not logged in and those that were.
2. On May 18, 2021, we changed the code to redirect a not logged in user to
   the login page instead of the 404 page if route matched an AuthenticatedApp
   route. React Router recommends doing this on a per route basis with
   <PrivateRoute/> elements as shown at https://reactrouter.com/web/example/auth-workflow.
   We did not want to break the UnauthenticatedApp/AuthenticatedApp dichotomy at this time.
   Although, we are willing to revist this decision. An audit of, UnauthenticatedApp
   routes at this time showed that only one of those made logical sense in
   the AuthenticatedApp context. We think this was the right decision. 
   Although, we acknowledge and dislike the redundant definition of routes
   in this file.
*/

// Same order as routes in AuthenticatedApp.tsx
const authenticatedRoutes = [
  '/select-warehouse',
  '/setup-snowflake',
  '/setup-bigquery',
  '/connectors',
  '/connectors/:id/report_builder/:view',
  '/connectors/add',
  '/connectors/add/:service',
  '/connectors/:id',
  '/connectors/:id/:tab',
  '/csv/add',
  '/experiments',
  '/home',
  '/integrations',
  '/integrations/:platform',
  '/partnerships',
  '/getting-started',
  '/getting-started/:useCase',
  '/warehouse',
  '/tables/:id',
  '/tables/:id/:tab',
  '/transforms/add',
  '/transforms/:id',
  '/transforms/:id/:tab',
  '/trial-expired',
  '/temp/reset_demo',
  '/query/:id',
  '/query',
  '/users/add',
  '/users',
  '/users/:tab',
  '/settings',
  '/slack-app-installer-redirect',
  '/tags',
  '/usage',
  '/usage/:tab',
  '/notifications',
  '/alerts',
  '/user-actions',
  '/internal',
  '/internal/:tab',
  '/dbt/jobs/add',
  '/dbt',
  '/dbt/jobs/:id',
  '/dbt/jobs/:id/:tab',
  '/dbt/jobs/:id/runs/:runId',
];

export default authenticatedRoutes;
