import AgoHover from 'components/widgets/time/AgoHover/AgoHover';
import UserActionSentence from 'pages/user_actions/components/UserActionSentence/UserActionSentence';
import { pickLabel } from 'pages/user_actions/components/UserActionsTable/UserActionsTable';
import { UserAction } from 'pages/user_actions/usePagedUserActions';
import { parseIso } from 'utils/dateTime';

interface UserActionsWidgetItemProps {
  userAction: UserAction;
}

export default function UserActionsWidgetItem(props: UserActionsWidgetItemProps) {
  const { userAction } = props;
  return (
    <div className="mb-6">
      <div className="text-xs text-pri-gray-700 font-medium">
        {pickLabel(userAction.object_type).toUpperCase()}
      </div>
      <div className="mt-1 text-sm text-pri-gray-500">
        <UserActionSentence userAction={userAction} eventLocation="HomepageUserActionsWidget" />
      </div>
      <div className="mt-1 text-xs text-pri-gray-500">
        {`${userAction.user_name} • `}
        <AgoHover date={parseIso(userAction.created_at)} />
      </div>
    </div>
  );
}
