import React from 'react';

import { TableType } from 'api/tableAPI';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

interface GSheetDestinationInfoIconProps {
  type: TableType;
}

export default function GSheetDestinationInfoIcon(props: GSheetDestinationInfoIconProps) {
  const { type } = props;
  let action = 'transform run';
  let scheduleType = "transform's";
  if (type === 'unmanaged') {
    action = 'sync';
    scheduleType = "connector's sync";
  } else if (type === 'snapshot') {
    action = 'snapshot';
    scheduleType = 'snapshot';
  }
  const content = (
    <div>
      <div>
        <strong>
          Google Sheet Sync saves a table to one or more spreadsheets after every successful {action}.
        </strong>
      </div>
      <div className="mt-2">
        The frequency and timing of Google Sheet Syncs is controlled by the {scheduleType} schedule.
      </div>
      {type === 'transform' && (
        <div className="mt-2">
          If a transform run fails, it will not overwrite the last successful run's data in the google
          sheet.
        </div>
      )}
      <div className="mt-2">
        Google spreadsheets have a maximum size of 5,000,000 cells. Some tables may be too big to sync to
        a Google spreadsheet.
      </div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} popoverProps={popoverProps} />;
}
