import { useCallback, useEffect, useRef, useState } from 'react';

import API from 'api/API';
import { AggTable } from 'api/APITypes';
import { LIST_TABLES, setAPIPayload, getAPIPayload } from 'api/CacheKeys';
import { TableResponse, convertTables } from 'api/tableAPI';
import { useDatabaseAccount } from 'context/AuthContext';
import { updateManyByID } from 'utils/Array';
import { parseIso } from 'utils/dateTime';

import { maybeMockTables } from './maybeMock';

export default function useTableContext() {
  const [tables, setTables] = useState<AggTable[]>([]);
  const [isLoading, setIsLoading] = useState(true); // We have no tables from API.
  const [isLocal, setIsLocal] = useState(false); // We have tables from localStorage, but not the API.
  const [error, setError] = useState('');
  const [lastTablesChange, setLastTablesChange] = useState<Date | null>(null);
  const anyApiHasReturned = useRef(false); // When tables first load, has the api come back yet? So we don't overwrite it from localStorage cache
  const normalApiHasReturned = useRef(false); // Do not let the cached API overwrite the normal API.
  const apiRequestInProgress = useRef(false); // We don't want to refetch if we are currently waiting for a response
  const databaseType = useDatabaseAccount().type;

  const refreshTables = useCallback(() => {
    if (!apiRequestInProgress.current) {
      apiRequestInProgress.current = true;
      const api = new API();
      api
        .get(`/api/tables`, (table) => convertTables(table, databaseType))
        .then((response) => {
          anyApiHasReturned.current = true;
          normalApiHasReturned.current = true;
          const tables = maybeMockTables(response.data.tables);
          setTables(tables);
          setLastTablesChange(parseIso(response.data.last_tables_change));
          setIsLocal(false);
          setAPIPayload(LIST_TABLES, tables);
        })
        .catch((error) => {
          setError('Failed to load table list.');
        })
        .finally(() => {
          setIsLoading(false);
          apiRequestInProgress.current = false;
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Step 1:
  // Get table list from a previous normal API call out of localStorage.
  useEffect(() => {
    const tableList = getAPIPayload<AggTable[]>(LIST_TABLES);
    if (!anyApiHasReturned.current && tableList) {
      setTables(maybeMockTables(tableList));
      setIsLocal(true);
    }
  }, []);

  // Step 2:
  // Call the cached API to get a list of tables quickly so the app can render quickly.
  // Do not write to the localStorage cache.
  // Do not overwrite the normal API if that API comes back quicker.
  useEffect(() => {
    const api = new API();
    api
      .get(`/api/tables/cached`)
      .then((response) => {
        if (!normalApiHasReturned.current) {
          // Only update table list if we got a cache hit and the API returned data.
          if (response.data.tables && response.data.last_tables_change) {
            const cacheHit = response.data as TableResponse;
            const convertedResponse = convertTables(cacheHit, databaseType);

            anyApiHasReturned.current = true;
            setTables(maybeMockTables(convertedResponse.tables));
            setLastTablesChange(parseIso(convertedResponse.last_tables_change));
            setIsLocal(false);
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        // Silently eat this error.
        // If the normal API loads correctly we are still OK.
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Step 3:
  // Call the normal API to get the list of tables that is up to date and accurate.
  useEffect(() => {
    refreshTables();
  }, [refreshTables]);

  // Update or extend list of tables in table context.
  const updateTables = useCallback(
    (newTables: AggTable[]) => {
      setTables(updateManyByID(tables, newTables));
    },
    [tables],
  );

  const removeTable = useCallback(
    (tableToRemove: AggTable) => {
      setTables(tables.filter((t) => t.id !== tableToRemove.id));
    },
    [tables],
  );

  return {
    tables,
    isLoading,
    error,
    isLocal,
    lastTablesChange,
    updateTables,
    removeTable,
    refreshTables,
  };
}
