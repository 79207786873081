import React, { useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';

import { Tag, SaveableTagProps } from 'api/tagAPI';
import Button from 'components/inputs/basic/Button/Button';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Alert from 'components/widgets/alerts/Alert/Alert';
import ColorInput from 'pages/tags/ListTags/ColorInput';

import TagPill from '../TagPill';

import s from './EditTagForm.module.css';

export const COLUMN_WIDTHS = {
  name: '25%',
  description: '45%',
  color: '30%',
  descriptionAndColor: 'calc(40% + 40%)', // description + color
};
const BUTTONS_WIDTH = '145px';
const ACTUAL_COLOR_WIDTH = `calc(${COLUMN_WIDTHS.color} - ${BUTTONS_WIDTH})`;

export interface EditTagFormProps {
  tags: Tag[];
  initialValues: SaveableTagProps;
  saving: boolean;
  errorMessage: string;
  editMode: boolean;
  eventPage: string;
  handleCancel: () => void;
  handleSave: (values: SaveableTagProps, formikHelpers: FormikHelpers<SaveableTagProps>) => void;
}

export default function EditTagForm(props: EditTagFormProps) {
  return (
    <EditTagFormWrapper {...props}>
      {(props) => {
        const {
          saving,
          errorMessage,
          editMode,
          handleCancel,
          handleSubmit,
          values,
          errors,
          isValid,
          dirty,
          fakeTag,
          showColorPicker,
          setShowColorPicker,
          colorPickerTarget,
          handleColorPickerSetColor,
          handleOpenColorPicker,
          handleColorChange,
        } = props;
        return (
          <form noValidate onSubmit={handleSubmit} className={cn(s.form, { [s.editMode]: editMode })}>
            <TagPill
              tag={fakeTag}
              eventPage="ListTags"
              disableLink={true}
              className={cn('mt-4 mb-0', { 'ml-4': !editMode })}
            />
            <table className="lineTable">
              <tbody style={{ verticalAlign: 'top' }}>
                <tr className="whitespace-nowrap">
                  <td style={{ width: COLUMN_WIDTHS.name }}>
                    <TextFormikGroup
                      name="name"
                      placeholder="Tag name"
                      groupClass="p-0 pr-2 mb-0"
                      autoFocus
                      maxLength={20}
                    />
                  </td>
                  <td style={{ width: COLUMN_WIDTHS.description }}>
                    <TextFormikGroup
                      name="description"
                      placeholder="Description (optional)"
                      groupClass="p-0 pr-2 mb-0"
                      maxLength={200}
                    />
                  </td>
                  <td style={{ width: ACTUAL_COLOR_WIDTH }}>
                    <ColorInput
                      color={values.color}
                      showColorPicker={showColorPicker}
                      error={errors.color}
                      colorPickerTarget={colorPickerTarget}
                      onOpenColorPicker={handleOpenColorPicker}
                      onColorPickerSetColor={handleColorPickerSetColor}
                      onColorChange={handleColorChange}
                      setShowColorPicker={setShowColorPicker}
                    />
                  </td>
                  <td className="text-center" style={{ width: BUTTONS_WIDTH }}>
                    <div className="h-[42px] f-row-y-center justify-end">
                      <Button size="small" variant="darkDanger" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        size="small"
                        variant="save"
                        className="ml-2"
                        spinning={saving}
                        disabled={!dirty || !isValid}
                      >
                        Save
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {errorMessage && (
              <Alert variant="error" className="mt-3 mb-0">
                {errorMessage}
              </Alert>
            )}
          </form>
        );
      }}
    </EditTagFormWrapper>
  );
}

export interface TagFormProps
  extends FormikProps<SaveableTagProps>,
    Omit<EditTagFormProps, 'initialValues'> {
  fakeTag: Tag;
  showColorPicker: boolean;
  setShowColorPicker: React.Dispatch<React.SetStateAction<boolean>>;
  colorPickerTarget: React.RefObject<HTMLButtonElement>;
  handleColorPickerSetColor: (color: string) => void;
  handleOpenColorPicker: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleColorChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface EditTagFormWrapperProps extends EditTagFormProps {
  children: (props: TagFormProps) => React.ReactNode;
}

export function EditTagFormWrapper(props: EditTagFormWrapperProps) {
  const { children, ...propsFromParent } = props;
  const { tags, initialValues, editMode, eventPage, handleSave } = propsFromParent;

  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerTarget = useRef(null);

  const schema = useMemo(() => {
    const otherTagNames = tags.map((t) => t.name).filter((n) => n !== initialValues.name);
    return yup.object({
      name: yup.string().required('Required').trim().notOneOf(otherTagNames, 'Name is already in use.'),
      description: yup.string().trim(),
      color: yup
        .string()
        .trim()
        .uppercase()
        .required('Required')
        .matches(/#[0-9A-F]{6}/, 'Must be a color in the format #0000ff'),
    });
  }, [tags, initialValues]);

  return (
    <Formik validationSchema={schema} onSubmit={handleSave} initialValues={initialValues}>
      {(formikProps) => {
        const { handleChange, setFieldValue, values } = formikProps;
        const handleColorPickerSetColor = (color: string) => {
          setFieldValue('color', color);
          setShowColorPicker(false);
          analytics.track(
            editMode ? `${eventPage} EditColorPickerSetColor` : `${eventPage} AddColorPickerSetColor`,
          );
        };

        const handleOpenColorPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          setShowColorPicker(true);
          analytics.track(
            editMode
              ? `${eventPage} EditOpenColorPickerButton`
              : `${eventPage} AddOpenColorPickerButton`,
          );
        };

        // When the ColorPicker is open it covers up where the validation error message is rendered.
        // Before we call the form wide change handler, `handleChange()`,
        // close the ColorPicker if the user types an invalid color,
        // so the user can see the error message.
        const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const value = event.target.value;
          const newValues = {
            ...values,
            color: value,
          };
          schema.validate(newValues).catch((reason) => {
            if (reason && reason.path === 'color') {
              setShowColorPicker(false);
            }
          });

          // Now that we closed the color picker,
          // call the change handler for the entire form.
          handleChange(event);
        };

        const previewText = values.name.length ? values.name : 'Tag Preview';
        const fakeTag: Tag = {
          id: 'fake-tag-id',
          name: previewText,
          description: values.description,
          color: values.color,
          created_at: '',
          updated_at: '',
        };

        const renderFormProps: TagFormProps = {
          // Pass through props from parent.
          ...propsFromParent,
          // Pass in form values, errors, helpers, etc... from Formik.
          ...formikProps,
          // Below this line is props EditTagFormWrapperProps computes for it's child.
          fakeTag,
          showColorPicker,
          setShowColorPicker,
          colorPickerTarget,
          handleColorPickerSetColor,
          handleOpenColorPicker,
          handleColorChange,
        };

        return children(renderFormProps);
      }}
    </Formik>
  );
}
