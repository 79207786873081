import React from 'react';

import { Link } from 'react-router-dom';

import cn from 'classnames';
import tinycolor from 'tinycolor2';

import { Tag } from 'api/tagAPI';
import { warehouseTagSearch } from 'utils/PathMaker';

import { COLORS } from './ListTags/ColorPicker';

import s from './TagPill.module.css';

export type TagEventPage = 'ListTags' | 'SummaryTab' | 'Warehouse' | 'TableExplorer';

export interface TagPillProps {
  tag: Tag;
  eventPage: TagEventPage;
  disableLink?: boolean;
  className?: string;
  // The Warehouse uses it's own onClick handler because we tried
  // using a link to rewrite the Warehouse URL and that caused rerender
  // race conditions with the search box also rewriting the Warehouse URL.
  // If this parameter is omitted we generate a link to the Warehouse URL with
  // the tag search as a query parameter.
  onClick?: (tagName: string) => void;
}

export default function TagPill(props: TagPillProps) {
  const { tag, eventPage, disableLink, className, onClick } = props;
  const { name, description, color } = tag;
  const actualClass = cn(s.tagPill, { [s.disableLink]: disableLink }, className);
  const fontColor = pillFontColor(color);
  const eventName = `${eventPage} FindTagInWarehouse`;

  let actualOnClick = undefined;
  if (onClick) {
    actualOnClick = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      onClick(name);
      analytics.track(eventName);
    };
  }

  return (
    <span
      title={description}
      className={actualClass}
      style={{ color: fontColor, backgroundColor: color }}
      onClick={actualOnClick}
    >
      {disableLink || actualOnClick ? (
        name
      ) : (
        <Link
          to={warehouseTagSearch(name)}
          data-track={eventName}
          className="text-reset text-decoration-none"
        >
          {name}
        </Link>
      )}
    </span>
  );
}

export function pillFontColor(backgroundColor: string) {
  // If the given color is from our color picker, return our curated font color
  if (COLORS[backgroundColor]) {
    return COLORS[backgroundColor];
  }
  // otherwise, try to adjust the font color accordingly
  const tc = tinycolor(backgroundColor);
  const brightness = tc.getBrightness();
  if (brightness < 128) {
    const lightnessFactor = 60 - brightness / 5;
    return tc.lighten(lightnessFactor).toHexString();
  } else {
    const darknessFactor = (brightness - 127) / 4 + 20;
    return tc.darken(darknessFactor).toHexString();
  }
}
