import React, { useRef, useEffect } from 'react';

import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  TooltipModel,
  TooltipItem,
  ChartDataset,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';

import { UsageInterval } from 'api/usageAPI';
import { parseApiDate } from 'utils/dateTime';
import { formatNumber } from 'utils/String';

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  ChartDataLabels,
);

//--Chart Style Options--//
// @ts-ignore
Chart.defaults.font.family = "'PT Sans', sans-serif";
//--Chart Style Options--//

export interface UsageData {
  date: string;
  val: number;
}

interface UsageChartProps {
  interval: UsageInterval;
  yAxisLabel: string;
  hoverLabel: string;
  usageData: UsageData[];
  color: string;
}

export default function UsageChart(props: UsageChartProps) {
  const chartRef: React.RefObject<HTMLCanvasElement> = useRef(null);
  const myChart: any = useRef(undefined);
  const { interval, yAxisLabel, hoverLabel, usageData, color } = props;

  // Build the chart
  useEffect(() => {
    const myChartRef = chartRef.current?.getContext('2d');
    if (!myChartRef) {
      return;
    }

    if (typeof myChart.current !== 'undefined') {
      myChart.current.destroy();
    }

    const data = usageData.map((g) => g.val);
    const labels = usageData.map((g) => g.date);

    const datasets: ChartDataset<'bar', number[]>[] = [
      {
        label: hoverLabel,
        data,
        backgroundColor: color,
        maxBarThickness: 110,
      },
    ];

    // TODO: Fix typing
    const plugin: any = {
      legend: { display: false },
      datalabels: {
        display: data.length < 20, // If there are too many data points dont show labels cause they overlap and look bad
        anchor: 'end',
        align: 'end',
        color: color,
        formatter: (value: number) => formatNumber(value),
      },
      tooltip: {
        mode: 'x',
        intersect: false,
        callbacks: {
          title: function (this: TooltipModel<'bar'>, tooltipItems: TooltipItem<'bar'>[]) {
            const apiDate = tooltipItems[0].label as string;
            const date = parseApiDate(apiDate) as Date;
            return interval === 'monthly'
              ? dayjs(date).format('MMMM, YYYY')
              : dayjs(date).format('MMMM D, YYYY');
          },
        },
      },
    };

    const chartOptions: any = {
      responsive: true,
      aspectRatio: 4,
      scales: {
        x: {
          // @ts-ignore
          type: 'category',
          grid: { display: false },
        },
        y: {
          suggestedMax: Math.max(...data) * 1.05, // Add some top padding
          type: 'linear',
          beginAtZero: true,
          title: {
            display: true,
            text: yAxisLabel,
          },
        },
      },

      plugins: plugin,
    };

    myChart.current = new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: chartOptions,
    });
  }, [color, interval, yAxisLabel, hoverLabel, usageData]);

  return (
    <div className="relative w-full h-full py-4 px-0">
      <canvas id="myChart" ref={chartRef} width="100%" />
    </div>
  );
}

export function sortDate(a: string, b: string) {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}
