import React, { useState, useEffect, useRef, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { Column } from 'api/columnAPI';
import { AggTable } from 'api/tableAPI';
import LoadingPlaceholder from 'components/layouts/containers/LoadingPlaceholder/LoadingPlaceholder';
import Popover from 'components/overlay/Popover/Popover';
import PopperOverlay from 'components/overlay/PopperOverlay/PopperOverlay';
import { HoverClient, HoverClientMap, useHoverCallbacks } from 'hooks/useHoverCallbacks';
import useHoveredIndex from 'hooks/useHoveredIndex';
import { isFivetranColumn } from 'utils/dbName';

import OverlayArea, { useOverlayArea } from '../../../layouts/parts/OverlayArea/OverlayArea';

import ColumnHoverRow from './ColumnHoverRow/ColumnHoverRow';

export interface Sample extends Column {
  samples: any[];
}

export interface SampleMap {
  [key: string]: Sample;
}

const ROW_HEIGHT = 29;

interface TableDetailsProps {
  loading: boolean;
  error: string;
  table: AggTable;
  columns: Column[];
  columnSamples: SampleMap;
  height: number;
  highlightFilter: string | RegExp;
  onDoubleClickColumn(column: Column): void;
  renderColumnHover(column: Column, overlayRight: number): React.ReactNode;
}

const TableDetails = React.memo((props: TableDetailsProps) => {
  const {
    loading,
    error,
    table,
    columns,
    columnSamples,
    height,
    highlightFilter,
    onDoubleClickColumn,
    renderColumnHover,
  } = props;
  const { scrollRef, innerRef, overlayRight } = useOverlayArea();

  // Sort search matches to the top of the TableList.
  const sortedColumns = useMemo(() => {
    // Memoize includes highlightFilter status before sort.
    const columnIncludesFilter: { [columnID: string]: boolean } = {};
    columns.forEach((c, i) => {
      columnIncludesFilter[c.id] =
        typeof highlightFilter === 'string'
          ? c.name.includes(highlightFilter)
          : !!c.name.match(highlightFilter);
    });

    // sort() is an in place method.
    // So, create a copy to sort.
    const sortedColumns = [...columns];

    return sortedColumns.sort((a: Column, b: Column) => {
      // First, sort by filter match.
      const includesA = columnIncludesFilter[a.id];
      const includesB = columnIncludesFilter[b.id];
      if (includesA && !includesB) {
        return -1;
      }
      if (!includesA && includesB) {
        return 1;
      }

      // Second, sort Fivetran columns last.
      const isFivetranA = isFivetranColumn(a.name);
      const isFivetranB = isFivetranColumn(b.name);
      if (isFivetranA && !isFivetranB) {
        return 1;
      }
      if (!isFivetranA && isFivetranB) {
        return -1;
      }

      // Use ordinal_position to sort if there isn't a filter match.
      // Use ordinal_position to sort two columns that both filter match.
      return a.ordinal_position - b.ordinal_position;
    });
  }, [columns, highlightFilter]);

  const trackClickTableName = () => {
    analytics.track('TableDetails GoToTablePageViaTableNameClick', {
      table_id: table.id,
    });
  };

  let displayRowCount =
    table.num_rows !== -1
      ? `${table.num_rows.toLocaleString()} row${table.num_rows !== 1 ? 's' : ''}`
      : 'N/A';
  if (table.database_table_type === 'VIEW') {
    displayRowCount = 'View';
  }

  const heightPX = `${height}px`;

  return (
    <LoadingPlaceholder loading={loading} error={error} spinnerMinHeight={heightPX}>
      <OverlayArea
        className="tt-table-details-scrolling-area"
        style={{ height: heightPX }}
        scrollRef={scrollRef}
        innerRef={innerRef}
      >
        {/* Wrap row count to next line if TableExplorer is too narrow. */}
        <div className="tt-table-details-header">
          <Link
            to={table.type === 'transform' ? `/tables/${table.id}/transform` : `/tables/${table.id}`}
            target="_blank"
            onClickCapture={trackClickTableName}
            className="inline-block mr-2 hover:text-sec-blue-gray-500 hover:underline"
          >
            {table.full_name}
          </Link>
          <div className="inline-block py-[2px]"> ({displayRowCount})</div>
        </div>
        {sortedColumns && (
          <TableColumns
            columns={sortedColumns}
            columnSamples={columnSamples}
            highlightFilter={highlightFilter}
            overlayRight={overlayRight}
            scrollRef={scrollRef}
            onDoubleClickColumn={onDoubleClickColumn}
            renderColumnHover={renderColumnHover}
          />
        )}
      </OverlayArea>
    </LoadingPlaceholder>
  );
});

export default TableDetails;

interface TableColumnsProps {
  columns: Column[];
  columnSamples: SampleMap;
  highlightFilter: string | RegExp;
  overlayRight: number;
  scrollRef: React.MutableRefObject<null>;
  onDoubleClickColumn(column: Column): void;
  renderColumnHover(column: Column, overlayRight: number): React.ReactNode;
}

interface InnerTableColumnsProps extends TableColumnsProps {
  tableRef: React.MutableRefObject<null>;
  hoveredIndex: number;
}

/*
Wrap mouse hover listener around table content so table content
is only rerendered on when hoveredIndex changes.
*/
const TableColumns = (props: TableColumnsProps) => {
  const tableRef = useRef(null);
  const hoveredIndex = useHoveredIndex(tableRef, props.scrollRef, ROW_HEIGHT);
  return <InnerTableColumns {...props} tableRef={tableRef} hoveredIndex={hoveredIndex} />;
};

// State that only changes when the API returns a new list of columnSamples.
export interface ColumnSampleState {
  [key: string]: React.RefObject<HTMLTableRowElement>; // key = name
}

const InnerTableColumns = React.memo((props: InnerTableColumnsProps) => {
  const {
    columns,
    columnSamples,
    highlightFilter,
    overlayRight,
    tableRef,
    hoveredIndex,
    onDoubleClickColumn,
    renderColumnHover,
  } = props;

  const [columnRefs, setColumnRefs] = useState<ColumnSampleState>({});
  const [sampleHoverRef, setSampleHoverRef] = useState<React.RefObject<HTMLElement> | null>(null);
  const [description, setDescription] = useState<string>('');
  const [samples, setSamples] = useState<string[]>([]);

  // Build columnRefs
  useEffect(() => {
    const refs: ColumnSampleState = {};
    for (const c of columns) {
      refs[c.name] = React.createRef<HTMLTableRowElement>();
    }
    setColumnRefs(refs);
  }, [columns]);

  // Add one mouse listener to the entire page since putting a hover listener
  // on every cell is really expensive.
  const hoverClients = useMemo(() => {
    const sampleClient: HoverClient = {
      onEnter: (columnName: string) => {
        const uState = columnRefs[columnName] || null;
        const sample = columnSamples[columnName];
        if (uState && sample) {
          setSampleHoverRef(uState);
          let samples = sample.samples;
          samples = samples.filter((s) => s !== null && s !== undefined); // Remove any nulls
          const sampleStrings = samples.map((s) => String(s));
          setDescription(sample.description);
          setSamples(sampleStrings);
        }
      },
      onExit: () => {
        setSampleHoverRef(null);
      },
    };

    // Add a no-op HoverClient to the HoverRow buttons to disable the row's
    // HoverClient when the cursor hovers over the buttons.
    const disableClient: HoverClient = {
      onEnter: (columnName: string) => {
        setSampleHoverRef(null);
      },
      onExit: () => {},
    };

    const hoverClients: HoverClientMap = {
      dsample: sampleClient,
      disable: disableClient,
    };

    return hoverClients;
  }, [columnRefs, columnSamples]);
  const mouseRef = useHoverCallbacks(hoverClients);

  return (
    <div ref={mouseRef}>
      <table ref={tableRef} className="tt-table-details-columns">
        <tbody>
          {columns.map((c: Column, index: number) => (
            <ColumnHoverRow
              key={c.name + index}
              rowRef={columnRefs[c.name]}
              column={c}
              hovered={hoveredIndex === index}
              onDoubleClickColumn={onDoubleClickColumn}
              highlightFilter={highlightFilter}
              overlayRight={hoveredIndex === index ? overlayRight : 0}
              renderColumnHover={renderColumnHover}
            />
          ))}
        </tbody>
      </table>
      <PopperOverlay
        target={sampleHoverRef}
        show={sampleHoverRef !== null}
        placement="top"
        renderPopper={(renderPopperProps) => {
          const content = (
            <div className="flex f-col">
              {description && (
                <>
                  <strong>Description:</strong>
                  <div className="mb-4" style={{ whiteSpace: 'pre-wrap' }}>
                    {description}
                  </div>
                </>
              )}
              <strong>Sample Rows:</strong>
              {samples.length > 0 ? (
                <>
                  {samples.map((s, i) => (
                    <div key={i} className="tt-table-details-sample-row">
                      {s}
                    </div>
                  ))}
                </>
              ) : (
                <div key="ALL_NULL" className="tt-table-details-sample-row">
                  Zero non-NULL rows in first 100 rows.
                </div>
              )}
            </div>
          );

          return (
            <Popover
              content={content}
              popoverProps={{ style: { maxWidth: '400px' } }}
              {...renderPopperProps}
            />
          );
        }}
      />
    </div>
  );
});
