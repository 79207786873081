const snowflakeDatatypes = [
  'number',
  'decimal',
  'numeric',
  'int',
  'integer',
  'bigint',
  'smallint',
  'float',
  'float4',
  'float8',
  'double',
  'double precision',
  'real',
  'varchar',
  'char',
  'character',
  'string',
  'text',
  'binary',
  'varbinary',
  'boolean',
  'date',
  'datetime',
  'time',
  'timestamp',
  'timestamp_ltz',
  'timestamp_ntz',
  'timestamp_tz',
  'variant',
  'array',
  'object',
  'geography',
];

export default snowflakeDatatypes;
