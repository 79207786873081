import { AggTable } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import UserHover from 'components/overlay/UserHover/UserHover';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import { lastRunAgo, lastRunDateAsString, duration } from 'model_helpers/aggTableHelper';
import { calcGenericRunStatus } from 'model_helpers/csvUploadHelper';
import { formatDuration } from 'utils/dateTime';

import Section from './Section';

interface CSVUploadVersionProps {
  table: AggTable;
  onOpenReplaceCSVModal(): void;
}

export default function CSVUploadVersion(props: CSVUploadVersionProps) {
  const { table, onOpenReplaceCSVModal } = props;
  const { csvUpload } = table;

  if (!csvUpload) {
    return null;
  }

  const { file_name, error_message, creator_user_profile } = csvUpload;

  const rows: { [key: string]: React.ReactNode } = {
    Filename: (
      <div className="f-row-y-center">
        <div>{file_name}</div>
        <Button size="small" variant="darkDullAction" onClick={onOpenReplaceCSVModal} className="ml-4">
          Replace File
        </Button>
      </div>
    ),
    Status: <StatusIcon status={calcGenericRunStatus(csvUpload)} />,
  };

  if (error_message) {
    rows['Error'] = <span className="break-words">{error_message}</span>;
  }

  rows['Uploaded By'] = <UserHover userProfile={creator_user_profile} />;
  rows['Completed At'] = lastRunDateAsString(table);
  rows['Completed Ago'] = lastRunAgo(table);
  rows['Duration'] = formatDuration(duration(table)) || 'In Progress';

  return <Section title="Last Upload" rows={rows} />;
}
