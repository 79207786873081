import React from 'react';

import { ListboxOption } from 'components/inputs/basic/Listbox/Listbox';

import MozartUsersTabView from './MozartUsersTabView/MozartUsersTabView';
import useMozartUsers from './useMozartUsers';

export interface MozartUsersTabControllerProps {
  show: boolean;
  snowflakeRoleOptions: ListboxOption[];
  snowflakeRoleError: string;
  snowflakeRoleLoading: boolean;
}

export default function MozartUsersTabController(props: MozartUsersTabControllerProps) {
  const { show, snowflakeRoleOptions, snowflakeRoleError, snowflakeRoleLoading } = props;

  const usersProps = useMozartUsers(snowflakeRoleOptions, snowflakeRoleError, snowflakeRoleLoading);

  if (!show) {
    return null;
  }
  return <MozartUsersTabView {...usersProps} />;
}
