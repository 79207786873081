import React from 'react';

import Circle from './Circle';
import SpinningLogo from './SpinningLogo';

interface CenteredSpinnerProps {
  containerMinHeight?: string; // 100%, 60vh, 200px?
  containerSize?: string; // 24px, 100%?
  spinnerSize?: string; // 24px?
  containerClass?: string;
  spinnerClass?: string;
  type?: 'logo' | 'circle';
}

export default function CenteredSpinner(props: CenteredSpinnerProps) {
  const { containerMinHeight, containerSize, spinnerSize, containerClass, spinnerClass, type } = props;
  const containerStyle: any = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  if (containerMinHeight) {
    containerStyle.minHeight = containerMinHeight;
  }
  if (containerSize) {
    containerStyle.width = containerSize;
    containerStyle.height = containerSize;
  }
  let spinnerStyle: React.CSSProperties | undefined = undefined;
  if (spinnerSize) {
    spinnerStyle = {};
    spinnerStyle.width = spinnerSize;
    spinnerStyle.height = spinnerSize;
  }
  return (
    <div style={containerStyle} className={containerClass}>
      {type === 'circle' ? (
        <Circle style={spinnerStyle} className={spinnerClass} />
      ) : (
        <SpinningLogo style={spinnerStyle} className={spinnerClass} />
      )}
    </div>
  );
}
