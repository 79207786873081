import Button from 'components/inputs/basic/Button/Button';

interface TryLookerProps {
  handleShowAPIKeysIntegrationClick: () => void;
  databaseDisplayName: string;
}

const TryLooker = (props: TryLookerProps) => {
  const { handleShowAPIKeysIntegrationClick, databaseDisplayName } = props;
  return (
    <div className="px-[3.75rem] py-[2.5rem] flex flex-col bg-[--sec-blue-50]">
      <div className="flex flex-row">
        <img src="/images/graphics/integrations/mozart_looker.svg" alt="" />
        <div className="ml-8 text-pri-gray-500">
          <h2 className="text-lg font-medium">Try Mozart Data + Looker Integration</h2>
          <p className="my-2">Looker is a preferred BI partner of Mozart Data.</p>
          <p className="mt-2 mb-10">
            Linking Mozart to Looker will enable you to quickly set up a {databaseDisplayName} connection
            in Looker, see relevant links to Looker throughout Mozart, and more.
          </p>
          <Button onClick={handleShowAPIKeysIntegrationClick} variant="darkAction">
            Connect to Looker
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TryLooker;
