import React from 'react';

import cn from 'classnames';

interface RunOptionsLabelProps {
  label: string;
  leftValue: string;
  rightValue: string;
  warning?: boolean;
  className?: string;
}

export default function RunOptionsLabel(props: RunOptionsLabelProps) {
  const { label, leftValue, rightValue, warning, className } = props;

  return (
    <div className={cn('f-row-y-center text-sm', className)}>
      <div className="font-medium">{label}</div>
      <div
        className={cn(
          'f-between min-w-[192px] ml-2 py-1 px-2 rounded',
          {
            'bg-pri-warning-100 text-pri-warning-700': warning,
          },
          {
            'bg-pri-gray-100': !warning,
          },
        )}
      >
        <div className="text-ellipsis">{leftValue}</div>
        {rightValue && <div className="ml-2">{rightValue}</div>}
      </div>
    </div>
  );
}
