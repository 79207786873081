import React from 'react';

import { RouteComponentProps } from 'react-router';

import TabRow from 'components/layouts/parts/TabRow/TabRow';
import useTabs from 'hooks/useTabs';

import AccountsTab from './AccountsTab';
import AIAssistantHistoryTab from './AIAssistantHistory';
import CodeEditorTab from './CodeEditorTab';
import LookupTab from './LookupTab';
import { OpenAIUsageProvider } from './OpenAIUsageContext';

export const ACCOUNTS_TAB = 'accounts';
export const CODE_EDITOR_TAB = 'ide';
export const LOOKUP_TAB = 'lookup';
export const AI_ASSISTANT_HISTORY_TAB = 'AI Assistant History';
const TABS = [ACCOUNTS_TAB, CODE_EDITOR_TAB, LOOKUP_TAB, AI_ASSISTANT_HISTORY_TAB];

interface MatchParams {
  tab: string;
}

interface InternalToolsProps extends RouteComponentProps<MatchParams> {}

export default function InternalTools(props: InternalToolsProps) {
  const { currentTab, handleSelectTab } = useTabs(
    TABS,
    LOOKUP_TAB,
    '/internal/',
    'Internal',
    props.match.params.tab,
    'Internal',
  );

  return (
    <div className="p-4 justify-center full-height">
      <div className="full-height">
        <TabRow tabs={TABS} selectedTab={currentTab} onClick={handleSelectTab} />
        {currentTab === ACCOUNTS_TAB && <AccountsTab />}
        {currentTab === CODE_EDITOR_TAB && (
          <OpenAIUsageProvider>
            <CodeEditorTab />
          </OpenAIUsageProvider>
        )}
        {currentTab === LOOKUP_TAB && <LookupTab />}
        {currentTab === AI_ASSISTANT_HISTORY_TAB && <AIAssistantHistoryTab />}
      </div>
    </div>
  );
}
