import { matchPath } from 'react-router';

import History from 'history';

export default function useHelpUrl(currentPathObject: any, location: History.Location) {
  const matchHome = matchPath(location.pathname, {
    path: '/home',
  });
  const matchConnectors = matchPath(location.pathname, {
    path: '/connectors',
  });
  const matchWarehouse = matchPath(location.pathname, {
    path: '/warehouse',
  });
  const matchCSVUpload = matchPath(location.pathname, {
    path: '/csv/add',
  });
  const matchDBT = matchPath(location.pathname, {
    path: '/dbt',
  });
  const matchQuery = matchPath(location.pathname, {
    path: '/query',
  });
  const matchDataAlerts = matchPath(location.pathname, {
    path: '/alerts',
  });
  const matchTransforms = matchPath(location.pathname, {
    path: '/transforms',
  });
  const matchTables = matchPath(location.pathname, {
    path: '/tables/:id',
  });
  const matchIntegrations = matchPath(location.pathname, {
    path: '/integrations',
  });
  const matchUsers = matchPath(location.pathname, {
    path: '/users',
  });
  const matchConnectorUsage = matchPath(location.pathname, {
    path: '/usage/connectors',
  });
  const matchComputeUsage = matchPath(location.pathname, {
    path: '/usage/compute',
  });
  const matchStorageUsage = matchPath(location.pathname, {
    path: '/usage/storage',
  });
  const usagePreviouslyMatched = matchConnectorUsage || matchComputeUsage || matchStorageUsage;
  const matchAnyUsage =
    !usagePreviouslyMatched &&
    matchPath(location.pathname, {
      path: '/usage',
    });

  const BASE_PATH = 'https://help.mozartdata.com/docs';
  // const BASE_PATH = 'https://mozartdata-20219495.hs-sites.com/docs';

  if (matchHome) {
    return `${BASE_PATH}/home`;
  }
  if (matchConnectors) {
    return `${BASE_PATH}/connectors`;
  }
  if (matchCSVUpload) {
    return `${BASE_PATH}/csv-uploader`;
  }
  if (matchDBT) {
    return `${BASE_PATH}/dbt-core-integration`;
  }
  if (matchWarehouse) {
    return `${BASE_PATH}/warehouse`;
  }
  if (matchQuery) {
    return `${BASE_PATH}/queries`;
  }
  if (matchDataAlerts) {
    return `${BASE_PATH}/data-alerts`;
  }
  if (matchTransforms) {
    return `${BASE_PATH}/transforms`;
  }
  if (matchTables) {
    const match = matchPath(location.pathname, { path: '/tables/:id/:tab' });
    if (match) {
      // This should always match
      const params = match.params as { id: string; tab: string };
      const { id, tab } = params;
      if (currentPathObject && currentPathObject.id === id) {
        // Tabs common to all table types
        if (tab === 'summary') {
          return `${BASE_PATH}/tables`;
        }
        if (tab === 'alerts') {
          return `${BASE_PATH}/data-alerts`;
        }
        if (tab === 'sync') {
          return `${BASE_PATH}/google-sheet-sync`;
        }
        if (tab === 'pipeline') {
          return `${BASE_PATH}/pipeline-features`;
        }
        if (tab === 'uploads') {
          return `${BASE_PATH}/csv-uploader`;
        }
        // Transform specific tabs.
        if (currentPathObject.type === 'transform') {
          const transform = currentPathObject.transform;
          if (tab === 'versionHistory') {
            return `${BASE_PATH}/version-history`;
          }
          if (transform && tab === 'transform' && transform.incremental) {
            return `${BASE_PATH}/incremental-transforms`;
          }
          // Transform and Runs tabs
          return `${BASE_PATH}/transforms`;
        }
      }
    }
    // Default to table page overview
    return `${BASE_PATH}/tables`;
  }
  if (matchIntegrations) {
    return `${BASE_PATH}/integrations`;
  }
  if (matchUsers) {
    return `${BASE_PATH}/users`;
  }
  if (matchConnectorUsage) {
    return `${BASE_PATH}/usage#connector-usage`;
  }
  if (matchComputeUsage) {
    return `${BASE_PATH}/usage#compute-usage`;
  }
  if (matchStorageUsage) {
    return `${BASE_PATH}/usage#storage-usage`;
  }
  if (matchAnyUsage) {
    return `${BASE_PATH}/usage`;
  }

  return BASE_PATH;
}
