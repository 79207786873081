import { useEffect, useRef } from 'react';

import { usePrevious } from 'react-use';

// Ideally this function can tell when a user starts and stops a new search.
// The current algorithm is a less than perfect approximation of that.
export default function useTrackFilter(eventLocation: string, filter: string) {
  const prevFilter = usePrevious(filter);

  // This hook keeps it's own copy of filter and lastLongestReported
  // in a ref so it always has the most up to date copy of data.
  // If reportOnBlur() had a closure on filter the calling component
  // would render the previous version of reportOnBlur() that had
  // a closure on the previous version of filter.
  const reportOnBlurRef = useRef({ filter: '', lastLongestReported: '' });

  // Record the longest version of a filter string the user types.
  // Assume they are still typing until the search loses focus.
  // The calling component should place this method on it's input's onBlur handler.
  const reportOnBlur = () => {
    const { filter, lastLongestReported } = reportOnBlurRef.current;
    // DO NOT report if filter is empty.
    if (filter !== '') {
      // DO NOT report if filter is the same as or a shorter version of last reported string.
      const hasNotReportedLongest = lastLongestReported === '';
      const versionOfLastReported =
        filter.length <= lastLongestReported.length &&
        lastLongestReported.slice(0, filter.length) === filter;
      if (hasNotReportedLongest || !versionOfLastReported) {
        analytics.track(`${eventLocation} LongestSearchString`, { longest_search_string: filter });
        reportOnBlurRef.current.lastLongestReported = filter;
      }
    }
  };

  useEffect(() => {
    // Record the start of every new search
    if (prevFilter === '' && filter !== '') {
      analytics.track(`${eventLocation} NewSearch`);
    }

    // Copy the latest version of filter to local state
    reportOnBlurRef.current.filter = filter;
  }, [prevFilter, filter, eventLocation]);
  return reportOnBlur;
}
