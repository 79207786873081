/**
 * The vertical stack of components to the right of the TableExplorer
 * 1. SqlEditorControlBar
 * 2. SqlEditor, AIAssistant, and/or FlowchartEditor
 * 3. ResultsPane consisting of the RunResults and its associated toolbars
 */
import React, { useEffect, useCallback, useContext, useMemo, useState } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import { AggTable } from 'api/APITypes';
import ControlledDraggablePanes, {
  VerticalDraggablePanesState,
  calcTopNums,
} from 'components/layouts/containers/draggable_panes/ControlledDraggablePanes/ControlledDraggablePanes';
import useDraggablePanesState from 'components/layouts/containers/draggable_panes/useDraggablePanesState';
import { EditorType, QueryEditorState } from 'components/query/useQueryEditor';
import { SearchColumnContext } from 'model_layer/SearchColumnContext';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { AutoSavingState, SavedQuery } from 'pages/Query/useQueryTabs';
import { dimToPixNum, PixelSize } from 'utils/cssMath';
import { clamp } from 'utils/Math';

import EditorTypePicker from './EditorTypePicker';
import ResultsPane from './ResultsPane/ResultsPane';
import SqlEditorControlBar from './SqlEditorControlBar';
import useEditorHeight from './useEditorHeight';

export const CONTROL_BAR_HEIGHT = 44;
const TOP_MIN_HEIGHT: PixelSize = '0px';
const BOTTOM_MIN_HEIGHT: PixelSize = `${CONTROL_BAR_HEIGHT}px`;

interface SqlEditorStackProps {
  queryEditorState: QueryEditorState;
  editMode: boolean;
  autoRun?: boolean;
  isTransform: boolean;
  incremental?: boolean;
  transformTableName?: string;
  description?: string;
  canShowEditButtons: boolean;
  savedSql?: string;
  eventPrefix: string;
  saving?: boolean;
  showTableExplorer: boolean;
  autoSavingState?: AutoSavingState;
  isShown: boolean;
  savedQuery?: SavedQuery;
  onExportCsv: () => void;
  onExportGSheet: () => void;
  setEditMode: (editMode: boolean) => void;
  onSaveSql?(): Promise<string> | undefined;
  setUnsavedSql?: (unsaved: boolean) => void;
  setShowTableExplorer: React.Dispatch<React.SetStateAction<boolean>>;
}

export default React.memo(function SqlEditorStack(props: SqlEditorStackProps) {
  const {
    queryEditorState,
    editMode,
    autoRun,
    isTransform,
    incremental,
    transformTableName,
    description,
    canShowEditButtons,
    savedSql,
    eventPrefix,
    saving,
    showTableExplorer,
    autoSavingState,
    isShown,
    savedQuery,
    onExportCsv,
    onExportGSheet,
    setEditMode,
    onSaveSql,
    setUnsavedSql,
    setShowTableExplorer,
  } = props;

  const {
    isFlowchart,
    editorType,
    selectedTable,
    queryRunnerState,
    sqlState,
    assistantState,
    flowchartState,
    setEditorType,
    setSelectedTable,
  } = queryEditorState;

  const {
    addLimit,
    running,
    runningIncremental,
    exportingCsv,
    exportingGSheet,
    runResults,
    lastRunSql,
    toggleLimit,
    clearLastRun,
    setRunErrorLines,
    onCopyToClipboard,
  } = queryRunnerState;

  const {
    editorSql,
    selectedSql,
    sanitizedRunErrorLines,
    editorTools,
    setEditorSql,
    setSelectedSql,
    onRun,
  } = sqlState;

  const [showTableExplorerBeforeFlowchart, setShowTableExplorerBeforeFlowchart] =
    useState<boolean>(true);

  useHotkeys(
    'ctrl+enter, ⌘+enter',
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onRun(isTransform && !!incremental, true);
    },
    { enableOnTags: ['TEXTAREA', 'INPUT'], enabled: isShown, enableOnContentEditable: true },
    [onRun],
  );

  const { tables, tablesByFullName } = useContext(TableModelsContext);
  const { searchColumnsByTableID } = useContext(SearchColumnContext);

  const queryableTables = useMemo(
    () => tables.filter((table: AggTable) => table.django_thinks_exists_in_snowflake),
    [tables],
  );

  const handleSetEditorType = useCallback(
    (newType: EditorType) => {
      if (newType !== editorType) {
        // Hide the TableExplorer if the user switches into flowchart mode
        if (isFlowchart) {
          setShowTableExplorerBeforeFlowchart(showTableExplorer);
          setShowTableExplorer(false);
        }
        // Return TableExplorer to pre-flowchart mode state
        else if (isFlowchart) {
          setShowTableExplorer(showTableExplorerBeforeFlowchart);
        }
        setEditorType(newType);
      }
    },
    [
      isFlowchart,
      editorType,
      showTableExplorer,
      showTableExplorerBeforeFlowchart,
      setEditorType,
      setShowTableExplorer,
    ],
  );

  /*******************************************************************************
   * DraggablePanes Height Logic:
   * 1. The top pane is the EditorTypePicker, which displays an SqlEditor, ChatAIEditor, etc...
   * 2. The bottom pane is the ResultsPane, which displays RunResults & related control bar components.
   * The top pane is always visible.
   * The bottom pane is rendered after a query is run.
   ******************************************************************************/
  // We check autoRun here because otherwise the SQL height gets set to autorun level,
  // then overwritten by the resizableSqlEditor's useEffect before this variable changes to true
  const showResultsPane = !!(autoRun || runResults || running || sanitizedRunErrorLines.length > 0);

  const draggablePaneState = useDraggablePanesState(true, '100%') as VerticalDraggablePanesState;
  const {
    topHeight: editorHeight,
    setTopHeight: privateSetEditorHeight,
    containerSize,
  } = draggablePaneState;
  const numEditorHeight = dimToPixNum(editorHeight, containerSize.height);

  // If showResultsPane becomes false after a save, make sure the editor takes up the entire screen.
  const actualEditorHeight = showResultsPane ? numEditorHeight : containerSize.height;

  // The SqlEditor only takes heights in pixels.
  // You can't set SqlEditor's height to 100%; however,
  // the DraggablePanes component assumes its children have dimensions of 100%.
  // So, we need to clamp the height of the editor to the allowable space
  // so that it won't overflow the allowable space.
  const setEditorHeight = useCallback(
    (newHeight: number) => {
      const clampedNums = calcTopNums(
        true,
        containerSize.height,
        `${newHeight}px`,
        TOP_MIN_HEIGHT,
        BOTTOM_MIN_HEIGHT,
      );
      privateSetEditorHeight(clampedNums.clampedTopNum);
    },
    [privateSetEditorHeight, containerSize.height],
  );

  const handleMaximizeRunResults = useCallback(() => {
    analytics.track('SqlEditorStack MaximizeRunResults');
    setEditorHeight(0);
  }, [setEditorHeight]);

  const handleMinimizeRunResults = useCallback(() => {
    analytics.track('SqlEditorStack MinimizeRunResults');
    setEditorHeight(containerSize.height);
  }, [setEditorHeight, containerSize.height]);

  const handleSetEditorHeightHalfway = useCallback(() => {
    // This gets run after the sql is run and it ensures the RunResults are at least 50% of the screen
    const halfContainerHeight = containerSize.height * 0.5;
    const newEditorHeight = clamp(numEditorHeight, 0, halfContainerHeight);
    setEditorHeight(newEditorHeight);
  }, [numEditorHeight, containerSize.height, setEditorHeight]);

  // The results should expand when the query is run
  useEffect(() => {
    if (running) {
      handleSetEditorHeightHalfway();
    }
    // If we include handleSetEditorHeightHalfway as a dependency, the height gets stuck halfway while running and the user cannot drag
  }, [running]); // eslint-disable-line react-hooks/exhaustive-deps

  // When we autoRun set the editor height
  useEditorHeight({
    editorType,
    containerHeight: containerSize.height,
    autoRun,
    editorSql,
    setEditorHeight,
  });

  return (
    <div id="SqlEditorStack" className="h-full w-full flex flex-col">
      <SqlEditorControlBar
        editMode={editMode}
        editorSql={editorSql}
        selectedSql={selectedSql}
        isFlowchart={isFlowchart}
        editorType={editorType}
        addLimit={addLimit}
        running={running}
        runningIncremental={runningIncremental}
        exportingCsv={exportingCsv}
        exportingGSheet={exportingGSheet}
        lastRunSql={lastRunSql}
        isTransform={isTransform}
        incremental={!!incremental}
        transformTableName={transformTableName}
        description={description}
        canShowEditButtons={canShowEditButtons}
        savedSql={savedSql}
        eventPrefix={eventPrefix}
        saving={saving}
        showTableExplorer={showTableExplorer}
        autoSavingState={autoSavingState}
        isShown={isShown}
        savedQuery={savedQuery}
        flowchartControlBarProps={flowchartState.flowchartControlBarProps}
        setRunErrorLines={setRunErrorLines}
        toggleLimit={toggleLimit}
        onRun={onRun}
        setEditorSql={setEditorSql}
        setEditorType={handleSetEditorType}
        setEditMode={setEditMode}
        clearLastRun={clearLastRun}
        onSaveSql={onSaveSql}
        setUnsavedSql={setUnsavedSql}
        setShowTableExplorer={setShowTableExplorer}
      />
      <ControlledDraggablePanes
        id="SqlEditorStackDragablePanes"
        topMinHeight={TOP_MIN_HEIGHT}
        bottomMinHeight={BOTTOM_MIN_HEIGHT}
        {...draggablePaneState}
      >
        <EditorTypePicker
          isFlowchart={isFlowchart}
          editorType={editorType}
          eventPrefix={eventPrefix}
          editorHeight={actualEditorHeight}
          editMode={editMode}
          editorSql={editorSql}
          selectedSql={selectedSql}
          runErrorLines={sanitizedRunErrorLines}
          editorTools={editorTools}
          selectedTable={selectedTable}
          queryableTables={queryableTables}
          tablesByFullName={tablesByFullName}
          searchColumnsByTableID={searchColumnsByTableID}
          setEditorSql={setEditorSql}
          setSelectedSql={setSelectedSql}
          setSelectedTable={setSelectedTable}
          assistantState={assistantState}
          flowchartState={flowchartState}
        />
        {showResultsPane && (
          <ResultsPane
            running={running}
            exportingCsv={exportingCsv}
            exportingGSheet={exportingGSheet}
            runErrorLines={sanitizedRunErrorLines}
            runResults={runResults}
            lastRunSql={lastRunSql}
            isTransform={isTransform}
            isShown={isShown}
            editMode={editMode}
            onCopyToClipboard={onCopyToClipboard}
            onExportCsv={onExportCsv}
            onExportGSheet={onExportGSheet}
            onMaximizeRunResults={handleMaximizeRunResults}
            onMinimizeRunResults={handleMinimizeRunResults}
            onSetEditorHeightHalfway={handleSetEditorHeightHalfway} // Used to expand the results when view settings button is clicked
            setEditorSql={setEditorSql}
          />
        )}
      </ControlledDraggablePanes>
    </div>
  );
});
