import { useEffect, useState } from 'react';

import { cloneDeep, forEach, includes, isEmpty, mapValues, pick, pickBy } from 'lodash';

import API from 'api/API';
import { AppConnector, UserProfile } from 'api/APITypes';

import { connectorStartsPaused } from '../ConnectorUtils';

import useHistoricalResyncState, { HistoricalResyncState } from './useHistoricalResyncState';
import { LocalSyncingState } from './useLocalSyncingState';
import useSchemaChangePolicyState, {
  SchemaChangeHandling,
  SchemaChangePolicyState,
} from './useSchemaChangePolicyState';
import {
  SchemasAndTablesToSyncState,
  useSchemasAndTablesToSyncState,
} from './useSchemasAndTablesToSyncState';

export interface SchemaPayload {
  schema_change_handling: SchemaChangeHandling | null;
  schemas: Schemas;
}

export type Schemas = Record<string, Schema>;
export type Tables = Record<string, Table>;

export type Schema = { enabled: boolean; tables: Tables };

export interface Table {
  enabled: boolean;
  enabled_patch_settings: PatchSettings;
  columns?: ColumnConfigsObject;
}

interface PatchSettings {
  allowed: boolean;
  reason?: string;
  reason_code?: string; // Like NOT_SELECTABLE_CHILD_TABLE or SYSTEM_TABLE. I wasn't able to find a full list or translate into user-friendly language, so we mostly give a generic reason.
}

export interface APIPostAllSchemasConfig {
  schema_change_handling?: SchemaChangeHandling;
  schemas: {
    [key: string]: {
      enabled: boolean;
      tables?: {
        [key: string]: {
          enabled: boolean;
          columns?: SaveableColumnConfigsObject;
        };
      };
    };
  };
}

export interface SaveableColumnConfig {
  enabled: boolean;
  hashed: boolean;
}

export interface SaveableColumnConfigsObject {
  [key: string]: SaveableColumnConfig;
}

export interface ColumnConfig extends SaveableColumnConfig {
  enabled_patch_settings: PatchSettings;
}

export type ColumnConfigsObject = {
  [key: string]: ColumnConfig;
};

export interface ManageTablesTabState {
  connector: AppConnector;
  isViewer: boolean;
  savingDisabled: boolean;
  saving: boolean;
  loading: boolean;
  error: string;
  editMode: boolean;
  showPickTablesBeforeSyncingModal: boolean;
  schemaChangePolicy: SchemaChangePolicyState | null;
  schemasAndTablesToSync: SchemasAndTablesToSyncState | null;
  historicalResyncState: HistoricalResyncState;
  onEdit: () => void;
  onDiscard: () => void;
  onSave: () => void;
  onCancelPickTablesBeforeSyncing: () => void;
  onGotItPickTablesBeforeSyncing: () => void;
}

export default function useManageTablesTabState(
  connector: AppConnector,
  userProfile: UserProfile,
  localSyncState: LocalSyncingState,
  resetPolling: () => void,
  setShowConnectorSyncingModal: (show: boolean) => void,
): ManageTablesTabState {
  const {
    fivetran_connector_id,
    anyStatusError,
    service,
    paused,
    status,
    succeeded_at,
    startDisabledTables,
  } = connector;
  const { setup_state } = status;

  const isViewer = userProfile.company_role === 'viewer';

  // Some connectors, notably database connectors, start paused so you can pick the tables to sync before the connector's first sync.
  // When this happens we start manage tables in editMode.
  const startsPaused = connectorStartsPaused(service);
  const isInitialSync =
    startsPaused &&
    setup_state === 'connected' &&
    paused &&
    // The user might have unpaused an existing connector.
    // Only start in editMode on new connectors.
    succeeded_at === null &&
    !isViewer;

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [unsavedSchemaPayload, setUnsavedSchemaPayload] = useState<SchemaPayload | null>(null);
  const [savedSchemaPayload, setSavedSchemaPayload] = useState<SchemaPayload | null>(null);
  const [editMode, setEditMode] = useState(isInitialSync);
  const [showPickTablesBeforeSyncingModal, setShowPickTablesBeforeSyncingModal] =
    useState(isInitialSync);

  const savingDisabled = isViewer || !editMode || saving;

  useEffect(() => {
    const api = new API();
    api
      .post(`api/fivetran/connector/${fivetran_connector_id}/schemas/reload`, {})
      .then((response) => {
        setError('');
        const schemaPayload: SchemaPayload = response.data;
        disableTables(schemaPayload, startDisabledTables, isInitialSync);
        setUnsavedSchemaPayload(schemaPayload);
        setSavedSchemaPayload(cloneDeep(schemaPayload));
      })
      .catch(() => {
        setError('There was a problem fetching Connector schemas.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [fivetran_connector_id, anyStatusError, startDisabledTables, isInitialSync]);

  const unsavedPolicy = unsavedSchemaPayload?.schema_change_handling || null;
  const setUnsavedPolicy = (newPolicy: SchemaChangeHandling | null) => {
    setUnsavedSchemaPayload((prev) => (prev ? { ...prev, schema_change_handling: newPolicy } : null));
  };
  const schemaChangePolicy = useSchemaChangePolicyState({
    unsavedPolicy,
    setUnsavedPolicy,
  });

  const unsavedSchemas = unsavedSchemaPayload?.schemas || null;
  const setUnsavedSchemas = (unsavedSchemas: Schemas | null) => {
    setUnsavedSchemaPayload((prev) =>
      prev && unsavedSchemas ? { ...prev, schemas: unsavedSchemas } : null,
    );
  };
  const schemasAndTablesToSync = useSchemasAndTablesToSyncState({
    fivetran_connector_id,
    unsavedSchemas,
    setUnsavedSchemas,
  });
  const historicalResyncState = useHistoricalResyncState(
    connector,
    localSyncState,
    resetPolling,
    editMode,
    isViewer,
  );

  const onEdit = () => {
    setEditMode(true);
    analytics.track('ShowConnectorManageTablesTab EditManageTables');
  };

  const onDiscard = () => {
    setEditMode(false);
    setUnsavedSchemaPayload(cloneDeep(savedSchemaPayload));
    analytics.track('ShowConnectorManageTablesTab DiscardManageTables');
  };

  // This save method is used under normal editing an existing connector situations.
  const onSave = () => {
    setSaving(true);
    setError('');
    const api = new API();

    api
      .post(`api/fivetran/connector/${fivetran_connector_id}/schemas/modify`, {
        config: convertSchemaConfigToAPIPost(unsavedSchemaPayload!),
      })
      .then((response) => {
        setSavedSchemaPayload(response.data);
        analytics.track('ShowConnectorManageTablesTab SaveManageTables');
      })
      .catch(() => {
        setError('There was a problem saving the schema config.');
      })
      .finally(() => {
        setSaving(false);
        setEditMode(false);
      });
  };

  // Some connectors, notably database connectors, start paused so you can pick the tables to sync before the connector's first sync.
  // When this happens we start manage tables in editMode AND we use onSaveAndSync() instead of onSave().
  // This save method is used to save the tables to sync AND then unpause the connector triggering an automatic sync.
  const onSaveAndSync = async () => {
    setSaving(true);
    setError('');
    // prettyError is only used if we end up in the catch() block.
    // Each step of this method sets the potential prettyError before executing the API call that might trigger the error.
    let prettyError = '';
    const api = new API();

    // Step 1: Save the new table schema
    prettyError = 'Failed to save config changes.';
    api
      .post(`api/fivetran/connector/${fivetran_connector_id}/schemas/modify`, {
        config: convertSchemaConfigToAPIPost(unsavedSchemaPayload!),
      })
      .then((configResponse) => {
        const savedConfig = configResponse.data;
        setSavedSchemaPayload(savedConfig);
      })
      // Step 2: Unpause the new connector, triggering automatic sync
      .then(() => {
        prettyError = 'Failed to unpause connector.';

        return api.post(`api/fivetran/connector/${fivetran_connector_id}/paused`, {
          paused: false,
        });
      })
      .then(() => {
        analytics.track('ShowConnectorManageTablesTab SaveAndUnpauseManageTables');
        resetPolling();
        setShowConnectorSyncingModal(true);
      })
      .catch(() => {
        setError(prettyError);
      })
      .finally(() => {
        setSaving(false);
        setEditMode(false);
      });
  };

  const onCancelPickTablesBeforeSyncing = () => {
    setShowPickTablesBeforeSyncingModal(false);
    analytics.track('ShowConnectorManageTablesTab CancelPickTablesBeforeSyncingModal');
  };

  const onGotItPickTablesBeforeSyncing = () => {
    setShowPickTablesBeforeSyncingModal(false);
    analytics.track('ShowConnectorManageTablesTab GotItPickTablesBeforeSyncingModal');
  };

  return {
    connector,
    isViewer,
    savingDisabled,
    saving,
    loading,
    error: error || historicalResyncState.error || (schemasAndTablesToSync?.error ?? ''),
    editMode,
    showPickTablesBeforeSyncingModal,
    schemaChangePolicy,
    schemasAndTablesToSync,
    historicalResyncState,
    onEdit,
    onDiscard,
    onSave: isInitialSync ? onSaveAndSync : onSave,
    onCancelPickTablesBeforeSyncing,
    onGotItPickTablesBeforeSyncing,
  };
}

const convertSchemaConfigToAPIPost = (schemaPayload: SchemaPayload): APIPostAllSchemasConfig => {
  return {
    ...(schemaPayload.schema_change_handling
      ? { schema_change_handling: schemaPayload.schema_change_handling }
      : {}),
    schemas: mapValues(schemaPayload.schemas, (s) => ({
      enabled: s.enabled,
      // Fivetran throws an error if the schema is disabled and you send the list of tables.
      tables: s.enabled
        ? mapValues(
            // Fivetran doesn't want you to pass through any tables that are not patchable.
            pickBy(s.tables, (t) => t.enabled_patch_settings.allowed),
            (t) => {
              // Fivetran throws an error if the table is disabled and you send the list of columns.
              // Fivetran also throws an error if you try to set properties on a column where column.enabled_patch_settings.allowed==false
              let columns: undefined | SaveableColumnConfigsObject = undefined;
              if (t.enabled && t.columns) {
                const settableColumns: SaveableColumnConfigsObject = {};
                forEach(t.columns, (columnConfig, columnName) => {
                  if (columnConfig.enabled_patch_settings.allowed || columnConfig.enabled) {
                    settableColumns[columnName] = pick(columnConfig, ['enabled', 'hashed']);
                  }
                });
                columns = settableColumns;
              }
              return {
                enabled: t.enabled,
                columns,
              };
            },
          )
        : undefined,
    })),
  };
};

function disableTables(
  schemaPayload: SchemaPayload,
  startDisabledTables: string[] | null,
  isInitialSync: boolean,
): void {
  // e.g. we start some Shopify tables as disabled on initial sync
  if (!isInitialSync || !startDisabledTables || isEmpty(startDisabledTables)) {
    return;
  }

  forEach(schemaPayload.schemas, (schema) => {
    forEach(schema.tables, (table, tableName) => {
      if (includes(startDisabledTables, tableName)) {
        table.enabled = false;
      }
    });
  });
}
