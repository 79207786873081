import React, { useState } from 'react';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import { ListboxOption } from 'components/inputs/formik_group/ListboxFormikGroup/ListboxFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';
import useSnowflakeRoles from 'hooks/useSnowflakeRoles';
import { SnowflakeUser } from 'pages/users/ListUsers/SnowflakeUsersTab/useSnowflakeUsers';

import CredentialsDiv from './CredentialsDiv';
import GenerateSnowflakeUserModal from './GenerateSnowflakeUserModal';

export interface GenerateSnowflakeUserPostData {
  username?: string;
  password?: string;
  role?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
}

export interface CredentialsResponse {
  username: string;
  password: string;
  role: string;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  warehouse: string;
}

interface GenerateSnowflakeUserProps {
  showCredentialsInModal?: boolean;
  className?: string;
  // Parents can pass in the snowflake roles if they have them already
  givenSnowflakeRoleOptions?: ListboxOption[];
  givenSnowflakeRoleError?: string;
  givenSnowflakeRoleLoading?: boolean;
  onGenerateUser?: (newUser: SnowflakeUser) => void;
  onCloseCredentialsModal?: (newUser: SnowflakeUser) => void;
}

const GenerateSnowflakeUser = (props: GenerateSnowflakeUserProps) => {
  const {
    showCredentialsInModal,
    className,
    givenSnowflakeRoleOptions,
    givenSnowflakeRoleError,
    givenSnowflakeRoleLoading,
    onGenerateUser,
    onCloseCredentialsModal,
  } = props;
  // Credentials are only rendered at the time of user creation.
  const [credentials, setCredentials] = useState<CredentialsResponse | undefined>(undefined);
  // New user objects are passed to the parent component.
  const [newUser, setNewUser] = useState<SnowflakeUser | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState<string>('');

  const {
    userProfile: { company_role, snowflake_role },
  } = useUserProfile();
  const databaseAccount = useDatabaseAccount();

  const isByos = databaseAccount.type === 'snowflake' && databaseAccount.is_byos;

  const canSkipFetch =
    givenSnowflakeRoleOptions !== undefined &&
    givenSnowflakeRoleError !== undefined &&
    givenSnowflakeRoleLoading !== undefined;

  const {
    listboxOptions: fetchedSnowflakeRoleOptions,
    error: fetchedSnowflakeRoleError,
    loading: fetchedSnowflakeRoleLoading,
  } = useSnowflakeRoles(databaseAccount, canSkipFetch);

  // Since snowflake role options can be passed or fetched, consolidate the namespace here
  const snowflakeRoleOptions =
    givenSnowflakeRoleOptions !== undefined ? givenSnowflakeRoleOptions : fetchedSnowflakeRoleOptions;
  const snowflakeRoleError =
    givenSnowflakeRoleError !== undefined ? givenSnowflakeRoleError : fetchedSnowflakeRoleError;
  const snowflakeRoleLoading =
    givenSnowflakeRoleLoading !== undefined ? givenSnowflakeRoleLoading : fetchedSnowflakeRoleLoading;

  let generateSnowflakeUserPostDataInitialValues: GenerateSnowflakeUserPostData = {
    role: snowflake_role,
  };

  const openModal = () => {
    setModalOpen(true);
    analytics.track('GenerateSnowflakeUser OpenModal');
  };

  const closeModal = () => {
    setModalOpen(false);
    analytics.track('GenerateSnowflakeUser CloseModal');
  };

  const generateUser = (values: GenerateSnowflakeUserPostData) => {
    // The API will accept undefined values,
    // but it will not accept empty strings which one can create
    // by entering text in an input and then deleting it.
    const safeValues = {
      ...values,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      // DEPRECATED: Removing first, last, email options while we only allow LEGACY_SERVICE_ACCOUNT user types
      // first_name: values.first_name === '' ? undefined : values.first_name,
      // last_name: values.last_name === '' ? undefined : values.last_name,
      // email: values.email === '' ? undefined : values.email,
    };

    setLoading(true);
    const api = new API();
    api
      .post('/api/snowflake_user', safeValues)
      .then((response) => {
        // Credentials are only rendered at the time of user creation.
        const newCredentials = response.data as CredentialsResponse;
        // Snowflake seems to capitalize names later on.
        // So hack that now:
        newCredentials.username = newCredentials.username.toUpperCase();
        setCredentials(newCredentials);

        // New user objects are passed to the parent component.
        const newUser: SnowflakeUser = {
          name: newCredentials.username.toUpperCase(),
          default_role: newCredentials.role,
          created_on: new Date().toISOString(),
          last_success_login: '',
          disabled: 'false',
          first_name: newCredentials.first_name || '',
          last_name: newCredentials.last_name || '',
          email: newCredentials.email || '',
        };
        setNewUser(newUser);
        setModalOpen(false);
        if (onGenerateUser) {
          onGenerateUser(newUser);
        }
      })
      .catch((error) => {
        setError(error.response.data.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeCredentialsModal = () => {
    setNewUser(undefined);
    setCredentials(undefined);

    if (onCloseCredentialsModal && newUser) {
      onCloseCredentialsModal(newUser);
    }
  };

  const credentialsDiv = credentials && <CredentialsDiv credentials={credentials} />;

  // Just show the div if showCredentialsInModal is not true
  const renderCredentials = showCredentialsInModal ? (
    <Modal
      onClose={closeCredentialsModal}
      header="Snowflake User Credentials"
      cancelButton={true}
      containerClass="w-[900px]"
    >
      <div className="m-4">{credentialsDiv}</div>
    </Modal>
  ) : (
    credentialsDiv
  );

  return (
    <div className={className}>
      {modalOpen && (
        <GenerateSnowflakeUserModal
          initialValues={generateSnowflakeUserPostDataInitialValues}
          companyRole={company_role}
          snowflakeRoleOptions={snowflakeRoleOptions}
          snowflakeRoleError={snowflakeRoleError}
          snowflakeRoleLoading={snowflakeRoleLoading}
          saving={loading}
          error={error}
          onGenerateUser={generateUser}
          onClose={closeModal}
        />
      )}
      {credentials ? (
        renderCredentials
      ) : (
        <>
          {isByos && (
            <Alert variant="warning" className="mb-2">
              Mozart Data does not manage your Snowflake account. Please contact the administrator of
              your Snowflake account to get credentials.
            </Alert>
          )}
          <Button onClick={openModal} disabled={isByos}>
            Generate Snowflake User
          </Button>
        </>
      )}
    </div>
  );
};

export default GenerateSnowflakeUser;
