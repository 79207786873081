import React, { useMemo } from 'react';

import cn from 'classnames';

import { UsageInterval, StorageUsage } from 'api/usageAPI';
import { formatNumber } from 'utils/String';

import { CommonTabProps } from '../Usage';
import s from '../Usage.module.css';
import UsageChart, { UsageData, sortDate } from '../UsageChart';
import UsageTabLayout from '../UsageTabLayout/UsageTabLayout';

interface StorageTabProps extends CommonTabProps {
  storageUsage: StorageUsage[];
}

export default function StorageTab(props: StorageTabProps) {
  const { loading, error, interval, startDate, endDate, storageUsage, setInterval, setDateRange } =
    props;

  // Compute chart data
  const chartData = useMemo(() => {
    return sumComputeByDate(storageUsage);
  }, [storageUsage]);

  // Compute monthly totalStorage
  const totalStorage = useMemo(() => {
    return chartData.reduce((sum, usageData) => sum + usageData.val, 0);
  }, [chartData]);

  const handleSetInterval = (interval: UsageInterval) => {
    analytics.track('UsageStorageTab SetInterval', {
      interval,
    });
    setInterval(interval);
  };

  const handleSetDateRange = (startDate: string, endDate: string) => {
    analytics.track('UsageStorageTab SetDateRange', {
      startDate,
      endDate,
    });
    setDateRange(startDate, endDate);
  };

  let heading = 'Total storage used during date range';
  let headingValue = totalStorage;
  if (interval === 'daily') {
    heading = 'Average storage used during date range';
    headingValue = totalStorage / chartData.length;
  }

  let zeroText = '';
  let headerBox = null;
  let chart = null;

  if (chartData.length) {
    headerBox = (
      <div className={cn(s.colorlessHeaderBox, 'bg-sec-indigo-50', 'text-sec-indigo-700')}>
        <div className={s.totalRow}>
          <h4>{heading}:</h4>
          <div className={s.total}>{formatNumber(headingValue)} GB</div>
        </div>
        <a
          href="https://help.mozartdata.com/docs/usage#storage-usage"
          target="blank"
          data-track="Usage LinkToStorageHelp"
          className={s.helpLink}
        >
          How Storage Works
        </a>
      </div>
    );

    chart = (
      <UsageChart
        interval={interval}
        yAxisLabel="Gigabytes"
        hoverLabel="Storage"
        usageData={chartData}
        color={getComputedStyle(document.documentElement).getPropertyValue('--sec-indigo-700')}
      />
    );
  } else {
    zeroText = 'Zero storage during the selected date range.';
  }

  return (
    <UsageTabLayout
      loading={loading}
      error={error}
      zeroText={zeroText}
      interval={interval}
      startDate={startDate}
      endDate={endDate}
      setInterval={handleSetInterval}
      setDateRange={handleSetDateRange}
      headerBox={headerBox}
      chart={chart}
      table={null}
      tabType="storage"
    />
  );
}

// Calculate the total storage for every date.
function sumComputeByDate(usage: StorageUsage[]) {
  const sumDic: { [key: string]: number } = {};
  usage.forEach((u) => {
    if (!sumDic[u.measured_date]) {
      sumDic[u.measured_date] = 0;
    }
    sumDic[u.measured_date] += u.storage;
  });

  // Get list of sorted dates
  const sortedDates = Object.keys(sumDic).sort(sortDate);

  // Convert to the format the UsageChart wants.
  const graphData: UsageData[] = sortedDates.map((k) => ({ date: k, val: sumDic[k] }));
  return graphData;
}
