import React from 'react';

import { Calendar } from 'react-date-range';

import { parseApiDate, formatApiDate } from 'utils/dateTime';

import PrefabRanges from './PrefabRanges/PrefabRanges';

import s from './DateRangeCalendar.module.css';

export interface DateRangeCalendarProps {
  value: string; // In API format
  startDate: string;
  endDate: string;
  onSetDate: (date: string) => void;
  onSetRange: (start: string, end: string) => void;
  onClose: () => void;
}

export default function DateRangeCalendar(props: DateRangeCalendarProps) {
  const { value, startDate, endDate, onSetDate, onSetRange, onClose } = props;

  const handleSetDate = (date: Date) => {
    onSetDate(formatApiDate(date));
  };

  const valueAsDate = parseApiDate(value) as Date;

  return (
    <div className={s.picker}>
      <div className={s.pickerBox + ' shadow-sm'}>
        <PrefabRanges startDate={startDate} endDate={endDate} onSetRange={onSetRange} />
        <Calendar date={valueAsDate} onChange={handleSetDate} />
      </div>
      <div className={s.backDrop} onClick={onClose}></div>
    </div>
  );
}
