import React, { useState, useEffect, useCallback } from 'react';

import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { sortBy } from 'lodash';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import { ListboxOption } from 'components/inputs/formik_group/ListboxFormikGroup/ListboxFormikGroup';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import Alert from 'components/widgets/alerts/Alert/Alert';

import {
  buildSettingsConfig,
  SnowflakeSetting,
  SnowflakeSettingsEnum,
  SnowflakeSettingsConfigType,
} from './SnowflakeSettingsUtils';

export default function SnowflakeSettings() {
  const [snowflakeSettings, setSnowflakeSettings] = useState<SnowflakeSetting[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [snowflakeSettingsError, setSnowflakeSettingsError] = useState<string | null>(null);

  const updateSettingAtIndex = (settingIndex: number, update: object, settings: SnowflakeSetting[]) => {
    const newSettings: SnowflakeSetting[] = [
      ...settings.slice(0, settingIndex),
      { ...settings[settingIndex], ...update },
      ...settings.slice(settingIndex + 1),
    ];
    setSnowflakeSettings(newSettings);
  };

  const setSnowflakeParam = useCallback(
    (name: SnowflakeSettingsEnum, val: any, settings: SnowflakeSetting[]) => {
      setSnowflakeSettingsError(null);
      const settingIndex = settings.findIndex((obj: any) => obj.name === name);
      updateSettingAtIndex(settingIndex, { loading: true, error: null }, settings);

      const requestData = typeof val === 'string' ? val : val.target.value;

      const api = new API();

      api
        .patch('api/snowflake_settings', {
          [name.toUpperCase()]: requestData,
        })
        .then((resp) => resp.data)
        .then((newVal: SnowflakeSettingsConfigType) => {
          updateSettingAtIndex(
            settingIndex,
            {
              value: newVal[name.toUpperCase() as keyof SnowflakeSettingsConfigType],
              loading: false,
              editing: false,
            },
            settings,
          );
        })
        .catch((err) => {
          const status = err.response.status;
          const error = err.response.data.error;

          switch (status) {
            case 400:
              // set validation error on specifc field
              if (err.response.data?.new_value) {
                updateSettingAtIndex(
                  settingIndex,
                  { loading: false, error: err.response.data.new_value },
                  settings,
                );
              } else {
                setSnowflakeSettingsError(error);
              }
              break;
            case 500:
              setSnowflakeSettingsError(error);
              break;
          }
        });
    },
    [],
  );

  useEffect(() => {
    // Fetch all the current values for the settings
    // and map them into props/config.
    const getSnowflakeSettings = () => {
      setSnowflakeSettingsError(null);
      const api = new API();
      api
        .get('api/snowflake_settings')
        .then((response: AxiosResponse) => {
          const currentSettingsConfig = buildSettingsConfig(response.data);

          setSnowflakeSettings(sortBy(currentSettingsConfig, 'displayOrder'));
        })
        .catch((err) => {
          const error = err.response.data.error;
          setSnowflakeSettingsError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getSnowflakeSettings();
  }, []);

  return (
    <div className="mt-6">
      {snowflakeSettingsError && (
        <Alert variant="error" className="mb-4">
          {snowflakeSettingsError}
        </Alert>
      )}
      {loading && (
        <div className="h-[400px] pt-12 relative">
          <CenteredSpinner />
        </div>
      )}
      {!loading && (
        <table className="lineTable max-w-[900px] mx-auto">
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Account Parameter</th>
              <th className="text-left" style={{ width: '60%' }}>
                Current Value
              </th>
            </tr>
          </thead>
          <tbody>
            {snowflakeSettings.map(
              (
                {
                  SettingComponent,
                  description,
                  changeHandler,
                  loading,
                  type,
                  editing,
                  options,
                  name,
                  value,
                  ...props
                }: SnowflakeSetting,
                settingIndex: number,
              ) => {
                const SettingInput = SettingComponent as React.ElementType;

                return (
                  <tr key={name}>
                    <td>
                      <div className="flex">
                        <span className="text-black">{props.label}</span>
                        {description && (
                          <InfoIcon
                            placement="top"
                            content={<div>{description()}</div>}
                            containerClass="ml-1"
                            popoverProps={{ style: { maxWidth: '550px', fontSize: '1rem' } }}
                          />
                        )}
                      </div>
                    </td>
                    {editing === false && (
                      <td className="min-h-[79px] !h-auto">
                        <div className="f-row-y-center h-full">
                          <div className="w-[400px] min-h-[38px] f-row-y-center">
                            {
                              options?.find(({ value: optionValue, label }) => {
                                return optionValue === value;
                              })?.label
                            }
                          </div>
                          <div className="ml-4 flex w-[100px]">
                            {editing === false && (
                              <Button
                                variant="lightTransparent"
                                size="small"
                                className="!text-sec-blue-gray-500"
                                onClick={() => {
                                  updateSettingAtIndex(
                                    settingIndex,
                                    { editing: true },
                                    snowflakeSettings,
                                  );
                                }}
                              >
                                Edit
                              </Button>
                            )}
                          </div>
                        </div>
                      </td>
                    )}
                    {editing === true && (
                      <td className="min-h-[79px] !h-auto">
                        <Formik
                          initialValues={{ [name]: value }}
                          onSubmit={(values) => {
                            setSnowflakeParam(name, values[name], snowflakeSettings);
                          }}
                        >
                          {({ handleSubmit, isSubmitting }) => (
                            <>
                              <form
                                onSubmit={handleSubmit}
                                className="flex items-center content-center justify-left"
                                noValidate
                              >
                                {/* TODO: better factory method for generating this component  */}
                                <SettingInput
                                  {...{ ...props }}
                                  {...{ ...(Array.isArray(options) && { spinning: loading }) }}
                                  options={options as ListboxOption[]}
                                  name={name}
                                  disabled={isSubmitting || loading}
                                  groupClass="w-[400px] -mt-[18px]"
                                  size="large"
                                  labelClass="hidden"
                                />

                                <div className="ml-4 flex w-[100px]">
                                  <>
                                    <Button type="submit" variant="save" size="small" spinning={loading}>
                                      Save
                                    </Button>
                                    <Button
                                      variant="darkDanger"
                                      size="small"
                                      className="ml-2"
                                      disabled={loading}
                                      onClick={() => {
                                        updateSettingAtIndex(
                                          settingIndex,
                                          { editing: false },
                                          snowflakeSettings,
                                        );
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                </div>
                              </form>
                            </>
                          )}
                        </Formik>
                      </td>
                    )}
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}
