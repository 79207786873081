// This tab uses a series of useMemos or variable definitions to build a data pipeline.
// The end result is the datasets used for the chart and the table, which are not the same
import React, { useMemo } from 'react';

import { startOfMonth } from 'date-fns';
import dayjs from 'dayjs';

import { parseApiDate } from 'utils/dateTime';

import TabView from '../TabView';
import { ReportTypes } from '../Usage';
import { COLORS } from '../UsageChart';
import { StorageDailyRow } from '../useUsageFetch';
import { sortDate, getChartFormattedData, getDataAggregated } from '../Utils';

interface StorageTabProps {
  storageData: StorageDailyRow[];
  reportType: ReportTypes;
  today: Date;
  startDate: Date;
  setReportType: (newReportType: ReportTypes) => void;
}

// This component uses a series of useMemos to filter and group the data
export default function StorageTab(props: StorageTabProps) {
  const { storageData, reportType, today, startDate, setReportType } = props;

  const chartFormattedData = useMemo(() => {
    const unsortedData = getChartFormattedData(
      storageData,
      '', // We don't filter on anything for storage, so an empty string is fine here and the filterValue is null
      'MEASURED_DATE',
      null,
      startDate,
      today,
      reportType,
      ['MEASURED_MONTH'], // We are only grouping by month
    );
    if (reportType === 'lastYearMonthly') {
      return unsortedData.sort(sortByDateByMonth);
    } else {
      return unsortedData.sort(sortByDateByDate);
    }
  }, [storageData, reportType, today, startDate]);

  // This isnt really necessary for storage, but I'm putting it in to have consistent pipelines between tabs
  // My hope is I can combine them later if they are similar enough
  const dataGroupedByDate = useMemo(() => {
    const groupByCol = reportType === 'lastYearMonthly' ? 'MEASURED_MONTH' : 'MEASURED_DATE';
    return getDataAggregated(chartFormattedData, [groupByCol]);
  }, [chartFormattedData, reportType]);

  // Reformat the names of the columns
  const tableFormattedData = useMemo(() => {
    return dataGroupedByDate.map((row: any) => {
      const dateVal =
        reportType === 'lastYearMonthly'
          ? startOfMonth(new Date(row.MEASURED_MONTH))
          : new Date(row.MEASURED_DATE);
      return {
        DATE: dateVal,
        'STORAGE (GB)': Math.round(row.AVG_STORAGE_GB * 100) / 100,
      };
    });
  }, [reportType, dataGroupedByDate]);

  const datasets = useMemo(() => {
    return [
      {
        label: 'Storage',
        data: chartFormattedData.map((r: any) => r.AVG_STORAGE_GB),
        backgroundColor: COLORS[0],
        maxBarThickness: 110,
      },
    ];
  }, [chartFormattedData]);

  const labels = useMemo(() => {
    if (reportType === 'lastYearMonthly') {
      return chartFormattedData.map((r: any) =>
        dayjs(parseApiDate(r.MEASURED_MONTH)).format('MMMM D, YYYY'),
      );
    } else {
      return (chartFormattedData as StorageDailyRow[]).map((r) =>
        dayjs(parseApiDate(r.MEASURED_DATE)).format('MMMM D, YYYY'),
      );
    }
  }, [chartFormattedData, reportType]);

  const handleSetReportType = (newReportType: ReportTypes) => {
    setReportType(newReportType);
    analytics.track('Usage SetReportType', { type: newReportType, tab: 'Storage' });
  };

  return (
    <TabView
      tableData={tableFormattedData}
      datasets={datasets}
      labels={labels}
      startDate={startDate}
      today={today}
      reportType={reportType}
      onSetReportType={handleSetReportType}
      tab="storage"
    />
  );
}

function sortByDateByMonth(a: any, b: any) {
  const keyA = new Date(a.MEASURED_MONTH);
  const keyB = new Date(b.MEASURED_MONTH);
  // Compare the 2 dates
  return sortDate(keyA, keyB);
}

function sortByDateByDate(a: any, b: any) {
  const keyA = new Date(a.MEASURED_DATE);
  const keyB = new Date(b.MEASURED_DATE);
  // Compare the 2 dates
  return sortDate(keyA, keyB);
}
