import { TableType } from 'api/APITypes';

export function caselessCompare(a: string, b: string) {
  return a.localeCompare(b, 'en', { sensitivity: 'base' });
}

export const capitalize = (string: string) => {
  if (!string) return '';

  return string
    .toLowerCase()
    .split(' ')
    .map((word: string) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

export const pascalCaseSnakeCase = (string: string) => {
  return string
    .toLowerCase()
    .split('_')
    .map((word: string) => word[0].toUpperCase() + word.slice(1))
    .join('');
};

export function isUpperCase(string: string) {
  return string === string.toUpperCase();
}

export function isLowerCase(string: string) {
  return string === string.toLowerCase();
}

export function isMixedCase(string: string) {
  return string !== string.toLowerCase() && string !== string.toUpperCase();
}

// This is logically similar to `capitalize(type)`
// Did not want to break legacy stats that use "loaded" when we standardized on the API value "unmanaged".
// "loaded" and "unmanaged" are very nearly synonyms.
// "loaded" means an ETL process(probably Fivetran) loaded the table.
//          This is what we call these tables when talking to users since they know the term ETL.
// "unmanaged" means any table Mozart's code didn't create. This is probably
//             a table loaded by Fivetran but might include other tables.
export function eventType(type: TableType): string {
  let noun = 'Loaded';
  if (type === 'transform') {
    noun = 'Transform';
  } else if (type === 'snapshot') {
    noun = 'Snapshot';
  }
  return noun;
}

export function formatNumber(num: number | null) {
  return typeof num === 'number' ? Number(num).toLocaleString() : null;
}

export function formatToDollarAmount(num: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(num);
}

/**
 * Returns a split string including the separators.
 * splitInlcuding('database.schema..table.') === ['database', '.', 'schemea', '.', '.', 'table', '.']
 */
export function splitIncluding(s: string, separator: string) {
  if (separator.length !== 1) {
    throw new Error('splitIncluding() can only have one character separators at this point in time.');
  }
  const result = [];
  let i = 0;
  let k = 0;
  for (i = 0; i < s.length; i++) {
    if (s.slice(i, i + 1) === separator) {
      if (i > k) {
        result.push(s.slice(k, i));
      }
      k = i + 1;
      result.push(separator);
    }
  }
  if (i > k) {
    result.push(s.slice(k, i));
  }
  return result;
}

export function cleanColor(rawColor: string) {
  if (rawColor.startsWith('--')) {
    return `var(${rawColor})`;
  }
  return rawColor;
}
