// sort-imports-ignore
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'patches/yupPatches';

// CSS rules with equal specificity are applied in the order they are loaded in the browser.
// Import library css before app components so app components
// can overide these libraries without using !important.
import './index.css';
// TODO These should be in tailwind before utilites.
import 'components/tables/BlueGrayHeaderTable/BlueGrayHeaderTable.css';
import 'components/tables/LineTable/LineTable.css';

import '@fortawesome/fontawesome-svg-core/styles.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as serviceWorker from './serviceWorker';
import './segment'; // Order before AuthContext
import { ExperimentProvider } from 'context/ExperimentContext';
import { AuthProvider } from './context/AuthContext.tsx';
import history from './appHistory';
// Load App last so infrastructure is defined before all components are imported by App.
import App from './App';
// Servicebell was deprecated, but this code was left, commented out in case we need to re-enable it.
// Not critical. Load last.
// import './launchServiceBell';

// Sentry (Error Tracking)
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, release: process.env.REACT_APP_SENTRY_RELEASE });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false },
  },
});

const SSO_GOOGLE_CLIENT_ID =
  process.env.REACT_APP_SSO_GOOGLE_CLIENT_ID ||
  'SYSTEM ERROR: SET .env.XXX.REACT_APP_SSO_GOOGLE_CLIENT_ID';

// react-query hides its query client context object behind a private closure that it doesn't export.
// So, we get the queryClient in most places via the hook useQueryClient() which can read the QueryClientProvider.
// In a few places we have to grab it off the window object or I suppose we could write our own provider. ;)
window.queryClient = queryClient;

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={SSO_GOOGLE_CLIENT_ID}>
      <Router history={history}>
        {/* react router backward compatibility component https://github.com/remix-run/react-router/discussions/8753 */}
        <CompatRouter>
          <AuthProvider>
            <QueryClientProvider client={queryClient}>
              <ExperimentProvider>
                <App />
              </ExperimentProvider>
            </QueryClientProvider>
          </AuthProvider>
        </CompatRouter>
      </Router>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
