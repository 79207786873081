import {
  CameraFill as SnapshotIcon,
  Diagram3Fill as TransformIcon,
  Icon0Square as NeverRanIcon,
  PauseCircle as NotScheduledIcon,
  QuestionSquare as MissingIcon,
  Sunglasses as ViewIcon,
  Table as UnmanagedIcon,
  FiletypeCsv as UploadedCSVIcon,
} from 'react-bootstrap-icons';

import { ErrorColor, VertexShape } from '../useVertexConverter';

/* Colors */
export const COLORS = {
  PRI_GRAY_700: '#344054',
  PRI_GRAY_300: '#d0d5dd',
  WHITE: '#FFFFFF',
  BRAND_PURPLE: '#4f35b3',
  SEC_PURPLE_50: '#f4f3ff',
  SEC_BLUE_LIGHT_700: '#026aa2',
  PRI_SUCCESS_700: '#027a48',
  PRI_ERROR_700: '#b42318',
  PRI_ERROR_100: '#fee4e2',
  SEC_ORANGE_700: '#c4320a',
  SEC_ORANGE_100: '#ffead5',
  PRI_WARNING_700: '#b54708',
  PRI_WARNING_200: '#fedf89',
  PRI_WARNING_50: '#fffaeb',
};

/* Graph Style Constants */
export const GRAPH_PADDING = 20;

/* Node Style Constants */
export const LINE_HEIGHT = 1.25;
export const FONT_SIZE = 10;
export const LEGEND_FONT_SIZE = 14;
export const MAX_NUM_CHARS = 30;
export const LABEL_FONT_WEIGHT = 500;
export const SUBLABEL_FONT_WEIGHT = 300;

export const VERTEX_WIDTH = 240;
export const VERTEX_HEIGHT = 48;

export const BORDER_WIDTH = 0.5;
export const SELECTED_BORDER_WIDTH = 1.5;
export const BORDER_RADIUS = 10;

export const DEFAULT_BORDER_COLOR = COLORS.PRI_GRAY_300;
export const STYLES_BY_TYPE = {
  [VertexShape.Transform]: {
    bgColor: COLORS.WHITE,
    color: COLORS.SEC_BLUE_LIGHT_700,
    selectedBorderColor: COLORS.SEC_BLUE_LIGHT_700,
  },
  [VertexShape.DBT]: {
    bgColor: COLORS.WHITE,
    color: COLORS.SEC_BLUE_LIGHT_700,
    selectedBorderColor: COLORS.SEC_BLUE_LIGHT_700,
  },
  [VertexShape.Snapshot]: {
    bgColor: COLORS.WHITE,
    color: COLORS.SEC_BLUE_LIGHT_700,
    selectedBorderColor: COLORS.SEC_BLUE_LIGHT_700,
  },
  [VertexShape.Unmanaged]: {
    bgColor: COLORS.WHITE,
    color: COLORS.PRI_SUCCESS_700,
    selectedBorderColor: COLORS.PRI_SUCCESS_700,
  },
  [VertexShape.View]: {
    bgColor: COLORS.WHITE,
    color: COLORS.PRI_SUCCESS_700,
    selectedBorderColor: COLORS.PRI_SUCCESS_700,
  },
  [VertexShape.CSVUpload]: {
    bgColor: COLORS.WHITE,
    color: COLORS.PRI_SUCCESS_700,
    selectedBorderColor: COLORS.PRI_SUCCESS_700,
  },
  [VertexShape.CurrentVertex]: {
    bgColor: COLORS.SEC_PURPLE_50,
    color: COLORS.BRAND_PURPLE,
    selectedBorderColor: COLORS.BRAND_PURPLE,
  },
};

export const STYLES_BY_STATUS = {
  [ErrorColor.Normal]: {
    bgColor: COLORS.WHITE,
    color: COLORS.PRI_GRAY_700,
    selectedBorderColor: COLORS.PRI_GRAY_300,
  },
  [ErrorColor.NotScheduled]: {
    bgColor: COLORS.SEC_ORANGE_100,
    color: COLORS.SEC_ORANGE_700,
    selectedBorderColor: COLORS.SEC_ORANGE_700,
  },
  [ErrorColor.NeverRan]: {
    bgColor: COLORS.SEC_ORANGE_100,
    color: COLORS.SEC_ORANGE_700,
    selectedBorderColor: COLORS.SEC_ORANGE_700,
  },
  [ErrorColor.Missing]: {
    bgColor: COLORS.SEC_ORANGE_100,
    color: COLORS.SEC_ORANGE_700,
    selectedBorderColor: COLORS.SEC_ORANGE_700,
  },
  [ErrorColor.Warn]: {
    bgColor: COLORS.PRI_WARNING_50,
    color: COLORS.PRI_WARNING_700,
    selectedBorderColor: COLORS.PRI_WARNING_200,
  },
  [ErrorColor.Danger]: {
    bgColor: COLORS.PRI_ERROR_100,
    color: COLORS.PRI_ERROR_700,
    selectedBorderColor: COLORS.PRI_ERROR_700,
  },
};

export const ICON_SIZE = 20;
export const ICON_X = 12;
export const ICON_Y = 12;
const ICON_ROTATION = `rotate(90, 0, 0)`;

export const ICONS_BY_STATUS = {
  [ErrorColor.Normal]: null,
  [ErrorColor.NotScheduled]: (color: string) => (
    <NotScheduledIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />
  ),
  [ErrorColor.NeverRan]: (color: string) => (
    <NeverRanIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />
  ),
  [ErrorColor.Missing]: (color: string) => (
    <MissingIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />
  ),
  [ErrorColor.Warn]: null,
  [ErrorColor.Danger]: null,
};

export const ICONS_BY_TYPE = {
  [VertexShape.Transform]: (color: string) => (
    <TransformIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} transform={ICON_ROTATION} />
  ),
  [VertexShape.Snapshot]: (color: string) => (
    <SnapshotIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />
  ),
  [VertexShape.CSVUpload]: (color: string) => (
    <UploadedCSVIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />
  ),
  [VertexShape.Unmanaged]: (color: string) => (
    <UnmanagedIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />
  ),
  [VertexShape.View]: (color: string) => (
    <ViewIcon x={ICON_X} y={ICON_Y} size={ICON_SIZE} color={color} />
  ),
};

/** Stylesheet for Cytoscape component */
const stylesheet = [
  {
    selector: 'core',
    style: {
      'active-bg-size': 12,
    },
  },
  {
    selector: 'node',
    style: {
      shape: 'rectangle',
      backgroundOpacity: 0,
      width: VERTEX_WIDTH,
      height: (ele: any) => ele.data().vertexHeight || VERTEX_HEIGHT,
    },
  },
  {
    selector: 'edge',
    style: {
      width: 0.75,
      targetArrowShape: 'triangle',
      arrowScale: 0.8,
      lineColor: (ele: any) => ele.data().color,
      targetArrowColor: (ele: any) => ele.data().color,
    },
  },
  {
    // Highlight egdes on hover so it's easier to see which vertices they're connected to
    selector: 'edge.hovered',
    style: {
      lineColor: COLORS.SEC_BLUE_LIGHT_700,
      targetArrowColor: COLORS.SEC_BLUE_LIGHT_700,
      zIndex: 10,
    },
  },
  {
    selector: 'edge.loop',
    style: {
      sourceEndpoint: '60deg',
      targetEndpoint: '-60deg',
      loopDirection: '0deg',
      loopSweep: '-60deg',
    },
  },
  {
    selector: 'edge:not(.loop)',
    style: {
      curveStyle: 'taxi',
      taxiDirection: 'horizontal',
      taxiTurnMinDistance: '5px',
    },
  },
];

export default stylesheet;
