import { useState } from 'react';

import ReactDiffViewer from 'react-diff-viewer-continued';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Button from 'components/inputs/basic/Button/Button';
import IconButton from 'components/inputs/basic/Button/IconButton';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import FeedbackForm from 'pages/tables/ShowTable/SummaryTab/Description/AITableDescription/FeedbackForm';

export interface AIFixSqlModalProps {
  onClose: () => void;
  isLoading: boolean;
  takingLonger: boolean;
  sql: string;
  fixedSql: string;
  apiError: any;
  onSaveClick: () => void;
  onRegenerateClick: () => void;
  onFeedbackClick: (response: string) => void;
}

// TODO(ai-fix-sql): Port from here and VersionDiffModal.tsx to a shared component
const highlightSyntax = (str: string) => {
  if (!str) {
    return <></>;
  } else {
    return (
      <SyntaxHighlighter
        language="sql"
        style={tomorrow}
        customStyle={{ background: 'inherit', padding: '0' }}
        wrapLongLines={true}
      >
        {str}
      </SyntaxHighlighter>
    );
  }
};

const AIFixSqlModal = (props: AIFixSqlModalProps) => {
  const {
    onClose,
    isLoading,
    takingLonger,
    sql,
    fixedSql,
    apiError,
    onSaveClick,
    onRegenerateClick,
    onFeedbackClick,
  } = props;

  const [isSplitView, setIsSplitView] = useState(false);

  const onToggleSplitView = () => {
    setIsSplitView(!isSplitView);
  };

  const footer = (
    <div className="f-row-y-center justify-between w-full">
      {isLoading || apiError ? <div /> : <FeedbackForm onClick={onFeedbackClick} />}
      <div className="f-row-y-center space-x-2">
        <IconButton
          icon="ArrowClockwise"
          text="Regenerate"
          onClick={onRegenerateClick}
          variant="lightDullTransparent"
          size="small"
          disabled={isLoading}
        />
        <Button
          variant="darkDullAction"
          size="small"
          onClick={onSaveClick}
          disabled={isLoading || apiError !== null}
        >
          Apply Fix
        </Button>
      </div>
    </div>
  );

  const loadingContent = (
    <div className="f-col-center w-full h-full">
      <CenteredSpinner type="logo" spinnerSize="3rem" />
      <p className="text-center ml-2">Generating proposed fix...</p>
      <p className="text-center ml-2">
        Mozart AI is collecting metadata, so it may take some time to respond.
      </p>
      {takingLonger && <p className="text-center ml-2">This may take a while, thanks for waiting!</p>}
    </div>
  );

  const errorContent = (
    <div className="f-col-center w-full h-full">
      <Alert variant="error">{apiError}</Alert>
    </div>
  );

  const content = (
    <div className="p-4">
      <div className="f-row-y-center justify-end pb-4">
        <Button
          variant="lightDullAction"
          size="small"
          onClick={onToggleSplitView}
          className="min-w-[118px]"
        >
          {isSplitView ? 'Unified View' : 'Split View'}
        </Button>
      </div>
      <ReactDiffViewer
        rightTitle="Proposed SQL Fix"
        leftTitle={isSplitView ? 'Original SQL' : 'Proposed SQL Fix'}
        oldValue={sql}
        newValue={fixedSql}
        splitView={isSplitView}
        renderContent={highlightSyntax}
        styles={{
          titleBlock: {
            borderLeft: 'none !important',
            borderBottom: 'none',
            padding: '8px',
          },
        }}
      />
    </div>
  );

  return (
    <Modal
      header="Mozart AI"
      footer={footer}
      cancelButton
      onClose={onClose}
      containerClass="w-full lg:w-[1023px] h-[80vh]"
    >
      {isLoading ? loadingContent : apiError ? errorContent : content}
    </Modal>
  );
};

export default AIFixSqlModal;
