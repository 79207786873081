import React from 'react';

import { CheckLg } from 'react-bootstrap-icons';

import SchemaIconForDatabaseSearch from 'components/primitives/icons/SchemaIconForDatabaseSearch/SchemaIconForDatabaseSearch';
import { virtualSchemaKeyParts } from 'pages/Warehouse/DatabaseSearch2/DatabaseSearchReducer';
import { getSchemaTitle } from 'pages/Warehouse/DatabaseSearch2/schemaUtils';

import { highlightSearch } from '../../highlightUtils';

import FilterCount from './FilterCount';
import TableExplorerGenericExpando, {
  TableExplorerCommonExpandoProps,
} from './TableExplorerGenericExpando';

interface TableExplorerSchemaExpandoProps extends TableExplorerCommonExpandoProps {
  schemaKey: string;
  picked: boolean;
  highlightFilter: string | RegExp;
  onToggleSchema(schemaKey: string): void;
}

const TableExplorerSchemaExpando = React.memo((props: TableExplorerSchemaExpandoProps) => {
  const {
    // My Props
    schemaKey,
    picked,
    highlightFilter,
    onToggleSchema,
    isFiltering,
    unfilteredCount,

    // Stuff I pass to TableExplorerGenericExpando
    tables,
    ...passToGenericExpando
  } = props;

  const { virtualSchemaType, schema } = virtualSchemaKeyParts(schemaKey);
  const title = getSchemaTitle(schema);

  return (
    <TableExplorerGenericExpando
      isFiltering={isFiltering}
      unfilteredCount={unfilteredCount}
      tables={tables}
      hideSchemaInTableRow={true}
      {...passToGenericExpando}
    >
      <div
        className="tt-schema-row"
        onClick={() => {
          onToggleSchema(schemaKey);
        }}
      >
        {picked && <CheckLg size={16} className="mr-3" />}
        <SchemaIconForDatabaseSearch
          virtualSchemaType={virtualSchemaType}
          schema={schema}
          tables={tables}
          size={20}
        />
        <div className="font-medium ml-2">{highlightSearch(title, highlightFilter)}</div>
        <FilterCount
          isFiltering={isFiltering}
          filteredCount={tables.length}
          unfilteredCount={unfilteredCount}
        />
      </div>
    </TableExplorerGenericExpando>
  );
});

export default TableExplorerSchemaExpando;
