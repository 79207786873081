import React, { useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import CodeEditor from 'components/inputs/basic/CodeEditor/CodeEditor';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Alert from 'components/widgets/alerts/Alert/Alert';

const formSchema = Yup.object().shape({
  lookup_string: Yup.string().trim().required('Required'),
});

const initialValues = {
  lookup_string: '',
};

interface OutputBlockProps {
  lookup: any; // This is the api response payload as an object
}

function OutputBlock(props: OutputBlockProps) {
  const { lookup } = props;
  const type = lookup.type;
  const formattedData = JSON.stringify(lookup.data, null, 2);
  return (
    <div>
      <div>{type}</div>
      <div>
        <CodeEditor value={formattedData} height="200px" mode="json" disableGutters={true} />
      </div>
    </div>
  );
}

export default function LookupTab() {
  const [lookups, setLookups] = useState<any[]>([]);
  const [error, setError] = useState('');
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSubmit = (values: any) => {
    const formValues = formSchema.cast(values);
    setError('');
    // setIsSubmitting(true);
    const api = new API();
    api
      .post('api/internal/lookup', formValues)
      .then((response) => {
        // Add latest lookup to the front of the list
        setLookups((lookups) => [response.data, ...lookups]);
        analytics.track('InternalTools FormSubmit');
      })
      .catch((error) => {
        setError(error.response?.data);
      })
      .finally(() => {
        // setIsSubmitting(false);
      });
  };

  return (
    <div>
      {error && (
        <Alert className="mt-3" variant="error">
          {error}
        </Alert>
      )}
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit} validationSchema={formSchema}>
        {({ handleSubmit }) => (
          <div className="flex mt-4">
            <div className="w-[400px]">
              <form onSubmit={handleSubmit} noValidate>
                <TextFormikGroup name="lookup_string" />
                <Button
                  // XXX isSubmitting is broken
                  spinning={false}
                  type="submit"
                  variant="lightAction"
                  className="my-2"
                >
                  Submit
                </Button>
              </form>
            </div>
            <div className="ml-4 w-full">
              {lookups && lookups.map((l) => <OutputBlock lookup={l} />)}
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
