/*
This is vestigial redireciton.
I'm leaving it in to avoid making too many breaking changes at once.
I'll probably delete it one day.
Originally, this file contained the original Ace implementation of the code editor.
When we were transitioning from Ace to Code Mirror it would
decide to render the AceSqlEditor or th CodeMirrorSqlEditor based on the experiment state.
*/
import React from 'react';

import { AggTable } from 'api/APITypes';
import { SearchColumnsByTableID } from 'api/searchColumnAPI';
import { SelectedSql } from 'components/query/useSqlEditor';

import CodeMirrorSqlEditor from './CodeMirrorSqlEditor/CodeMirrorSqlEditor';

export interface SqlEditorTools {
  insertAtCursor: (str: string) => void;
  focus: () => void;
}

export interface SqlEditorProps {
  id?: string;
  editorHeightCSS: string;
  editMode: boolean;
  editorSql: string;
  selectedSql: SelectedSql;
  runErrorLines: string[];
  editorTools: SqlEditorTools;
  selectedTable: AggTable | null;
  queryableTables: AggTable[];
  tablesByFullName: { [fullName: string]: AggTable };
  searchColumnsByTableID: SearchColumnsByTableID;
  setEditorSql(sql: string, byUser: boolean): void;
  setSelectedSql(selectedSql: SelectedSql): void;
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>;
}

const SqlEditor = (props: SqlEditorProps) => {
  return <CodeMirrorSqlEditor {...{ ...props }} />;
};
export default SqlEditor;
