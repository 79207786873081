import { useState, useEffect } from 'react';

import API from 'api/API';
import { DbtSshKey, DbtRepository } from 'api/dbtAPI';

const useGitReposState = () => {
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<string>('');
  const [newestSshKey, setNewestSshKey] = useState<DbtSshKey | null>(null);
  const [repos, setRepos] = useState<DbtRepository[]>([]);
  const [showConnectNewGitRepo, setShowConnectNewGitRepo] = useState(false);

  useEffect(() => {
    const api = new API();

    const fetchOrMintSshKey = async () => {
      try {
        // Try fetching existing keys.
        const existingKeyResponse = await api.get('api/ssh_key_pairs?unused=true');
        const keys = existingKeyResponse.data as DbtSshKey[];
        if (keys.length > 0) {
          setNewestSshKey(keys[0]);
        }
        // If there aren't any mint a new one.
        else {
          const newKeyResponse = await api.post('api/ssh_key_pairs', {});
          const newKey = newKeyResponse.data as DbtSshKey;
          setNewestSshKey(newKey);
        }
      } catch (e) {
        setLoadingError('There was a problem getting ssh keys.');
      }
    };

    const fetchGitRepos = async () => {
      try {
        const response = await api.get('api/dbt_repositories');
        const repos = response.data as DbtRepository[];
        setRepos(repos);
        if (!repos.length) {
          setShowConnectNewGitRepo(true);
        }
      } catch (e) {
        setLoadingError('There was a problem getting git repos.');
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchOrMintSshKey(), fetchGitRepos()]);
      setLoading(false);
    };

    fetchData();
  }, []);

  return {
    loading,
    loadingError,
    newestSshKey,
    repos,
    showConnectNewGitRepo,
    setShowConnectNewGitRepo,
    setRepos,
  };
};

export default useGitReposState;
