import { Table } from 'react-bootstrap-icons';

import { allConnectors } from 'pages/connectors/ConnectorRegistry';

import ImgIcon, { IconSize } from '../ImgIcon/ImgIcon';
import PortableIcon from '../PortableIcon/PortableIcon';

export interface ConnectorIconProps {
  // service is normally a key in the ConnectorRegistry for which we have a known icon,
  // but it could be an unknown type so this component needs to handle unknowns.
  service: string;
  size: IconSize;
}

export default function ConnectorIcon(props: ConnectorIconProps) {
  const { service, size } = props;
  const connectorType = allConnectors[service];
  if (connectorType) {
    if (connectorType.vendor === 'portable') {
      return <PortableIcon service={service} size={size} />;
    } else if (connectorType.vendor === 'fivetran') {
      const icon = `/images/connector_icons/fivetran/${service}.svg`;
      return <ImgIcon src={icon} alt={`${connectorType.name} icon`} size={size} />;
    }
    // There isn't really a csv connector but there is a hack in the AddConnectorTypes.tsx
    // connectors list to make the upload CSV feature look like a connector option.
    else if (connectorType.vendor === 'csv') {
      const icon = `/images/connector_icons/csv/csv.svg`;
      return <ImgIcon src={icon} alt={`${connectorType.name} icon`} size={size} />;
    }
  }

  // The default fallback is here because someone could add a connector in Fivetran,
  // and that connector might not have been added to the ConnectorRegistry yet.
  return <Table size={size} color="var(--sec-blue-gray-500)" />;
}
