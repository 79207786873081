import React, { SyntheticEvent } from 'react';

import { ChevronDown } from 'react-bootstrap-icons';

import cn from 'classnames';

import s from './AnimatedAngleIcon.module.css';

interface AnimatedAngleIconProps {
  isOpen: boolean;
  mode: 'accordion' | 'button';
  size?: number;
  onClick?: (e: SyntheticEvent) => void;
  className?: string;
}

const AnimatedAngleIcon = (props: AnimatedAngleIconProps) => {
  const { isOpen, mode, size = 16, onClick, className } = props;
  const rotationClass = isOpen ? s.angleOpen : s.angleClosed;
  const modeClass = s[`${mode}Mode`];
  const rotationAndMode = `${rotationClass} ${modeClass}`;
  const actualClass = cn(className, rotationAndMode);

  const color = '#4e5ba6'; // var(--secondary-blue-gray-500) does not work here.
  return <ChevronDown color={color} size={size} className={actualClass} onClick={onClick} />;
};

export default AnimatedAngleIcon;
