/*
  Renders Popper on the target ref when show is true.
*/
import React, { useEffect } from 'react';

import usePopperRefs, { Placement, RenderPopper } from 'components/overlay/PopperTrigger/usePopperRefs';

export interface PopperOverlayProps {
  target: React.RefObject<HTMLElement> | HTMLElement | null; // target can be a ref or the element itself
  show: boolean;
  placement: Placement;
  renderPopper: RenderPopper;
}

const PopperOverlay = React.forwardRef((props: PopperOverlayProps, ref) => {
  const { target, show, placement, renderPopper } = props;
  const { referenceElement, setReferenceElement, ...renderPopperProps } = usePopperRefs(placement);

  var actualTarget: HTMLElement | null | undefined = undefined;
  // !('current' in target)) is used to check it's an HTMLElement and not a ref
  if (target === null || !('current' in target)) {
    actualTarget = target;
  } else if (target.current) {
    actualTarget = target.current;
  }

  useEffect(() => {
    if (actualTarget) {
      setReferenceElement(actualTarget);
    }
  }, [actualTarget, setReferenceElement]);

  const fewestRendersShow = show && actualTarget && !!referenceElement;

  return <>{fewestRendersShow && renderPopper(renderPopperProps)}</>;
});

export default PopperOverlay;
