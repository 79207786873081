import cn from 'classnames';

import IconButton from 'components/inputs/basic/Button/IconButton';

interface AIFixSqlButtonProps {
  onClick: () => void;
  className?: string;
}

const AIFixSqlButton = (props: AIFixSqlButtonProps) => {
  const { onClick, className } = props;

  return (
    <IconButton
      text="Fix SQL"
      icon="Stars"
      onClick={onClick}
      size="small"
      variant="darkDullAction"
      className={cn('mt-1', className)}
    />
  );
};

export default AIFixSqlButton;
