import { AxiosResponse } from 'axios';

import ListboxFormikGroup from 'components/inputs/formik_group/ListboxFormikGroup/ListboxFormikGroup';
import RadioFormikGroup from 'components/inputs/formik_group/RadioFormikGroup/RadioFormikGroup';

export enum SnowflakeSettingsEnum {
  STATEMENT_TIMEOUT_IN_SECONDS = 'STATEMENT_TIMEOUT_IN_SECONDS',
  WEEK_START = 'WEEK_START',
  TIMEZONE = 'TIMEZONE',
  WEEK_OF_YEAR_POLICY = 'WEEK_OF_YEAR_POLICY',
  DATA_RETENTION_TIME_IN_DAYS = 'DATA_RETENTION_TIME_IN_DAYS',
}

export type SnowflakeSettingsDataTypeEnum = 'STRING' | 'NUMBER';

export type SnowflakeSetting = {
  label: string;
  loading?: boolean;
  editing?: boolean;
  description?: () => string;
  name: SnowflakeSettingsEnum;
  value?: string;
  error?: string;
  type?: SnowflakeSettingsDataTypeEnum;
  changeHandler?: Function;
  SettingComponent?: React.ElementType;
  options?: Array<{ label: string; value: string }>;
  displayOrder?: number;
  [key: string]: any;
};

export type SnowflakeSettingsConfigType = {
  [key in SnowflakeSettingsEnum]: Omit<SnowflakeSetting, 'name'>;
};

// Map the 'key' from the api to the component and props in the frontend.
// Settings also need to be defined in the backend/views/SnowflakeSettingsView
// allowed_settings property so that there valeus can be validated before being set
// Note: the order of keys here is the dislpay order in the UI
export const SnowflakeSettingsMap: SnowflakeSettingsConfigType = {
  TIMEZONE: {
    label: 'Time Zone',
    description: () =>
      `Specifies your Snowflake server’s timezone, which is used for all query sessions. This doesn’t affect any source data, but will affect how different timestamp types render in your query results. It also affects functions such as CURRENT_DATE and CURRENT_TIMESTAMP`,
    SettingComponent: ListboxFormikGroup,
    displayOrder: 1,
    variant: 'gray',
  },
  STATEMENT_TIMEOUT_IN_SECONDS: {
    label: 'Statement Timeout',
    SettingComponent: ListboxFormikGroup,
    description: () =>
      `The amount of time after which a running SQL statement is canceled by the system. This applies to all queries being run by all warehouses.`,
    options: [
      { label: '1 hr', value: '3600' },
      { label: '6 hr', value: '21600' },
      { label: '12 hr', value: '43200' },
      { label: '1 day', value: '86400' },
      { label: '2 days', value: '172800' },
      { label: '6 days', value: '518400' },
    ],
    displayOrder: 2,
    variant: 'gray',
  },
  DATA_RETENTION_TIME_IN_DAYS: {
    label: 'Data Retention Time (days)',
    SettingComponent: ListboxFormikGroup,
    description: () => (
      <span>
        Number of days for which Snowflake retains historical data for performing Time Travel actions
        (SELECT, CLONE, UNDROP) for all databases, schemas, and tables in your Snowflake account.
        Increasing the number of days does increase storage costs. For more information, refer to{' '}
        <a
          className="underline"
          rel="noreferrer"
          target="_blank"
          href="https://docs.snowflake.com/en/user-guide/data-time-travel"
        >
          Understanding & Using Time Travel.
        </a>
      </span>
    ),
    options: [
      { label: '1 day', value: '1' },
      { label: '5 days', value: '5' },
      { label: '7 days', value: '7' },
      { label: '14 days', value: '14' },
      { label: '30 days', value: '30' },
      { label: '60 days', value: '60' },
      { label: '90 days', value: '90' },
    ],
    displayOrder: 3,
    variant: 'gray',
  },
  WEEK_START: {
    label: 'Week Start',
    SettingComponent: ListboxFormikGroup,
    description: () => (
      <span>
        <p>
          Specifies the first day of the week, which is used by week-related date functions such as
          DAYOFWEEK, and DATE_TRUNC, LAST_DAY, and DATEDIFF when used with a WEEK part.
        </p>
        <br />
        <p>
          The default is Legacy Snowflake, which is ISO-like semantics and begins the week on Sunday as
          day 0 and counts through Saturday as 6. The other option is to specify which day the week
          should start, where the first day of the week gets marked as 1 and the seventh day gets marked
          as 7. Note: If you manually select Sunday as the week start instead of using the Legacy
          Snowflake setting, Sunday will be 1 and Saturday will be 7 instead of 0 and 6 respectively.
        </p>
      </span>
    ),
    options: [
      { label: 'Legacy Snowflake', value: '0' },
      { label: 'Monday', value: '1' },
      { label: 'Tuesday', value: '2' },
      { label: 'Wednesday', value: '3' },
      { label: 'Thursday', value: '4' },
      { label: 'Friday', value: '5' },
      { label: 'Saturday', value: '6' },
      { label: 'Sunday', value: '7' },
    ],
    displayOrder: 4,
    variant: 'gray',
  },

  WEEK_OF_YEAR_POLICY: {
    label: 'Week of Year Policy',
    SettingComponent: RadioFormikGroup,
    description: () => (
      <span>
        <p>
          Defines whether a week only belongs to a year if at least four days of that week occur in that
          calendar year, or January 1st starts week 1 and December 31st is part of week 52 (or 53). This
          policy affects week-related date functions such as DAYOFWEEK, and DATE_TRUNC, LAST_DAY, and
          DATEDIFF when used with a WEEK part.
        </p>
        <br />
        <p>
          In the default setting, each full week only gets counted in one year, and a week needs to have
          4 days in it to count as a week. For example, if Sunday is the start of your week and January
          1st is a Friday, then January 1 is counted as belonging to week 52 (or 53) of the previous
          year, and January 8 belongs to week 1 of this year. If December 31 is a Tuesday, it belongs to
          week 1 of the following year.{' '}
        </p>
      </span>
    ),
    options: [
      {
        label: 'A week belongs to a year ONLY if at least 4 of the days in the week fall in that year.',
        value: '0',
      },
      {
        label:
          'January 1 always starts the first week of the year and December 31 is always in the last week of the year.',
        value: '1',
      },
    ],
    containerClass: '!h-[70px]',
  },
};

// adds '(default)' to the label if the value matches the default value
const labelWithDefault = (label: string, value: string, default_value: string) => {
  if (value === default_value) {
    return `${label} (default)`;
  }
  return label;
};

const mapOptions = (options: Array<{ label: string; value: string }>, default_value: string) => {
  return options.map(({ label, value }) => ({
    label: labelWithDefault(label, value, default_value),
    value,
  }));
};

// Given the api response map the 'key' to the component and props in the frontend
export const buildSettingsConfig = (data: AxiosResponse): SnowflakeSetting[] =>
  Object.entries(data).map(
    ([name, { options, current_value, type, description, default_value, ...currentValues }]: [
      any,
      any,
    ]) => {
      // build our options array for the ListboxGroup component
      const _options =
        SnowflakeSettingsMap[name as keyof SnowflakeSettingsConfigType]?.options ||
        options?.values.map((v: any) => ({ label: v, value: v }));

      const settingConfig: SnowflakeSetting = {
        ...currentValues,
        name: name.toLowerCase(),
        value: current_value,
        loading: false,
        editing: false,
        ...SnowflakeSettingsMap[name as keyof SnowflakeSettingsConfigType],
        // overide the options from the api to add in the '(default)' label
        ...(_options.length > 0 && { options: mapOptions(_options, default_value) }),
        description:
          SnowflakeSettingsMap[name as keyof SnowflakeSettingsConfigType]?.description ||
          (() => description),
      };
      return settingConfig;
    },
  );
