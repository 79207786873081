import { useState } from 'react';

import API from 'api/API';
import { SigmaIntegration } from 'api/APITypes';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';

import { IntegrationLayoutProps } from '../../IntegrationSnowflakeConfig';

import SigmaView, { SigmaAPIKeyFormValues } from './SigmaView';

export interface SigmaScreen {
  show: boolean;
  loading: boolean;
  error: string | null;
}

const SigmaController = (props: IntegrationLayoutProps) => {
  const { values } = props;
  const { userProfile, setUserProfile } = useUserProfile();
  const {
    company: { sigma_integration: sigmaIntegration },
  } = userProfile;
  const { type: databaseType, displayName: databaseDisplayName } = useDatabaseAccount();

  // API key form
  const [showAPIKeySetupForm, setShowAPIKeySetupForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Snowflake setup screen
  const [showSetupSnowflakeInSigmaForm, setShowSetupSnowflakeInSigmaForm] = useState(false);
  const [settingUpSnowflake, setSettingUpSnowflake] = useState(false);
  const [setupSnowflakeError, setSetupSnowflakeError] = useState<string | null>(null);

  const api = new API();

  const handleManualConnectionInstructionsClick = (newExpand: boolean) => {
    analytics.track(
      newExpand
        ? 'SigmaIntegration ExpandManualConnectionInstructions'
        : 'SigmaIntegration CollapseManualConnectionInstructions',
    );
  };

  const handleShowAPIKeysIntegrationClick = () => {
    const newShow = !showAPIKeySetupForm;
    setShowAPIKeySetupForm(newShow);
    analytics.track(
      newShow ? 'SigmaIntegration OpenSetupIntegration' : 'SigmaIntegration CloseSetupIntegration',
    );
  };

  const handleShowSnowflakeInSigmaClick = () => {
    const newShow = !showSetupSnowflakeInSigmaForm;
    setShowSetupSnowflakeInSigmaForm(newShow);
    analytics.track(
      newShow
        ? 'SigmaIntegration OpenSetupSnowflakeInSigma'
        : 'SigmaIntegration CloseSetupSnowflakeInSigma',
    );
  };

  const handleSubmitAPIKey = (formValues: SigmaAPIKeyFormValues) => {
    setIsLoading(true);
    setError(null);
    api
      .post('api/sigma_integrations', formValues)
      .then((resp) => {
        const sigmaIntegration = resp.data as SigmaIntegration;
        setUserProfile({
          ...userProfile,
          company: {
            ...userProfile.company,
            sigma_integration: sigmaIntegration,
          },
        });
        setShowAPIKeySetupForm(false);
        setShowSetupSnowflakeInSigmaForm(true);
        analytics.track('SigmaIntegration SetupAPIKeys');
      })
      .catch((err) => {
        if (err.response.data.detail) {
          setError(err.response.data.detail);
        } else {
          setError(JSON.stringify(err.response.data));
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleDoSetupSnowflakeInSigma = () => {
    setSettingUpSnowflake(true);
    setSetupSnowflakeError(null);
    api
      .post('api/sigma_integrations/create_database_connection', {})
      .then((resp) => {
        const sigmaIntegration = resp.data as SigmaIntegration;
        setUserProfile({
          ...userProfile,
          company: {
            ...userProfile.company,
            sigma_integration: sigmaIntegration,
          },
        });
        setShowSetupSnowflakeInSigmaForm(false);
        analytics.track('SigmaIntegration SetupSnowflakeInSigma');
      })
      .catch((err) => {
        if (err.response.data.detail) {
          setSetupSnowflakeError(err.response.data.detail);
        } else {
          setSetupSnowflakeError(JSON.stringify(err.response.data));
        }
      })
      .finally(() => setSettingUpSnowflake(false));
  };

  return SigmaView({
    values,
    apiKeyScreen: {
      show: showAPIKeySetupForm,
      loading: isLoading,
      error,
    },
    setupSnowflakeScreen: {
      show: showSetupSnowflakeInSigmaForm,
      loading: settingUpSnowflake,
      error: setupSnowflakeError,
    },
    handleManualConnectionInstructionsClick,
    handleShowAPIKeysIntegrationClick,
    handleShowSnowflakeInSigmaClick,
    handleSubmitAPIKey,
    handleDoSetupSnowflakeInSigma,
    databaseType,
    databaseDisplayName,
    sigmaIntegration,
  });
};

export default SigmaController;
