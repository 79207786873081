import { useCallback, useContext, useMemo } from 'react';

import { AggTable } from 'api/APITypes';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { TabKey } from 'pages/tables/ShowTable/ShowTable';
import { hasTab } from 'pages/tables/ShowTable/tabPicker';

import { Command, CommandType } from '../MozartCommandPalette';

export type TableCompoundCommandType =
  // Note: The suffix of every command that is prefixed with `table_` is a valid table tab key.
  | 'table_summary' // Compound Command: List tables, then go to summary tab of selected table.
  | 'table_transform' // Compound Command: List tranform tables, then go to transform tab of selected transform.
  | 'table_pipeline' // Compound Command: List tables, then go to pipeline tab of selected table.
  | 'table_run' // Compound Command: List tranforms tables, then go to runs tab of selected transform.
  | 'table_alerts' // Compound Command: List tables, then go to alerts tab of selected table.
  | 'table_versionHistory' // Compound Command: List tranform tables, then go to version history tab of selected transform.
  | 'table_uploads' // Compound Command: List CSV upload tables, then go to uploads tab of selected table.
  | 'table_sync' // Compound Command: List tables, then go to sheet sync tab of selected table.
  | 'table_userActions' // Compound Command: List tables, then go to user actions tab of selected table.
  | 'table_settings'; // Compound Command: List tables, then go to settings tab of selected table.

export interface TableCommand extends Command {
  table: AggTable;
}

export default function useTableCommands(
  isOpen: boolean,
  openTablePath: (this: TableCommand, modKeyPressed: boolean) => boolean,
  createCommandPathCommand: (name: string, type: CommandType) => Command,
) {
  const { tables, allLoaded, anyError } = useContext(TableModelsContext);

  const tableCommands = useMemo<TableCommand[]>(() => {
    if (isOpen && allLoaded && !anyError) {
      return tables.map<TableCommand>((table) => ({
        name: table.full_name,
        type: 'table',
        table,
        command: openTablePath,
      }));
    } else {
      return [];
    }
  }, [isOpen, allLoaded, anyError, tables, openTablePath]);

  const tableTabCommands = useMemo<Command[]>(() => {
    const summaryTab = createCommandPathCommand('Summary Tab', 'table_summary');
    const transformTab = createCommandPathCommand('Transform Tab', 'table_transform');
    const runsTab = createCommandPathCommand('Runs Tab', 'table_run');
    const pipelineTab = createCommandPathCommand('Pipeline Tab', 'table_pipeline');
    const alertsTab = createCommandPathCommand('Alerts Tab', 'table_alerts');
    const versionsTab = createCommandPathCommand('Versions Tab', 'table_versionHistory');
    const uploadsTab = createCommandPathCommand('CSV Uploads Tab', 'table_uploads');
    const sheetSyncTab = createCommandPathCommand('Sheet Sync Tab', 'table_sync');
    const userActionsTab = createCommandPathCommand('User Actions Tab', 'table_userActions');
    const settingsTab = createCommandPathCommand('Settings Tab', 'table_settings');
    return [
      summaryTab,
      transformTab,
      runsTab,
      pipelineTab,
      alertsTab,
      versionsTab,
      uploadsTab,
      sheetSyncTab,
      userActionsTab,
      settingsTab,
    ];
  }, [createCommandPathCommand]);

  const pickTablesForTab = useCallback(
    (tab: TabKey) => {
      return tableCommands.filter((tc) => hasTab(tc.table, tab));
    },
    [tableCommands],
  );

  return { tableCommands, tableTabCommands, pickTablesForTab };
}
