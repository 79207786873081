/*
Appends TableExplorer specific behavior to generic DatabaseSearchReducer.
*/
import { AggTable } from 'api/APITypes';
import {
  DatabaseSearchState,
  ReducerAction,
  Action,
  updateFilteredTables,
  reducer as baseReducer,
  initialState as baseInitialState,
} from 'pages/Warehouse/DatabaseSearch2/DatabaseSearchReducer';

export type {
  FilteredSchemaMap,
  SchemasExpandedMap,
} from 'pages/Warehouse/DatabaseSearch2/DatabaseSearchReducer';
export type ViewMode = 'schema' | 'list' | 'pinned' | 'recents' | 'favorites' | 'tag';
export type TableExplorerReducerAction =
  | ReducerAction
  | 'SET_VIEW_MODE'
  | 'SET_TABLE_NAMES_IN_SQL'
  | 'MANUALLY_PIN_TABLE';
export interface TableExplorerAction extends Omit<Action, 'type'> {
  type: TableExplorerReducerAction;
  viewMode?: ViewMode;
  tableNamesInSql?: string[];
  tableId?: string;
  pinned?: boolean;
}

export interface PinnedTableMap {
  [key: string]: boolean; // key = table_id
}

export interface TableExplorerSearchState extends DatabaseSearchState {
  tablesUsedInSql: string[];
  viewMode: ViewMode;
  tableNamesInSql: string[];
  tablesPinnedBySqlMap: PinnedTableMap;
  tablesManuallyPinnedMap: PinnedTableMap;
  tablesActuallyPinnedMap: PinnedTableMap; // tablesPinnedBySqlMap & tablesManuallyPinnedMap combined.
}

const getUnfilteredTables = (prevState: DatabaseSearchState) => {
  const {
    viewMode,
    unfilteredTables,
    unfilteredRecentTables,
    unfilteredFavoriteTables,
    tablesActuallyPinnedMap,
  } = prevState as TableExplorerSearchState;
  // Default to all tables.
  let newUnfilteredTables = unfilteredTables;
  if (viewMode === 'recents') {
    newUnfilteredTables = unfilteredRecentTables;
  } else if (viewMode === 'favorites') {
    newUnfilteredTables = unfilteredFavoriteTables;
  } else if (viewMode === 'pinned') {
    newUnfilteredTables = unfilteredTables.filter(
      (table: AggTable) => tablesActuallyPinnedMap[table.id],
    );
  }
  return newUnfilteredTables;
};

export const initialState: TableExplorerSearchState = {
  // Copy initial state from parent
  ...baseInitialState,
  // Override parent's default state
  hideEmptyTables: false,
  hideViews: false,
  // Append addtional behavior
  getUnfilteredTables,
  tablesUsedInSql: [],
  viewMode: 'schema',
  tableNamesInSql: [],
  tablesPinnedBySqlMap: {},
  tablesManuallyPinnedMap: {},
  tablesActuallyPinnedMap: {},
};

export function reducer(prevState: TableExplorerSearchState, action: TableExplorerAction) {
  if (action.type === 'SET_VIEW_MODE') {
    setMode(prevState, action.viewMode as ViewMode);
    return { ...prevState }; // New object forces rerender
  }
  if (action.type === 'SET_TABLE_NAMES_IN_SQL') {
    setTableNamesInSql(prevState, action.tableNamesInSql as string[]);
    return { ...prevState }; // New object forces rerender
  }
  if (action.type === 'MANUALLY_PIN_TABLE') {
    manuallyPinTable(prevState, action.tableId as string, action.pinned as boolean);
    return { ...prevState }; // New object forces rerender
  }
  if (action.type === 'SET_TABLE_LISTS') {
    const newState = baseReducer(prevState, action as Action) as TableExplorerSearchState;
    updateSqlPinnedTables(newState);
    updateActuallyPinnedTables(newState);
    return { ...newState }; // New object forces rerender
  }

  return baseReducer(prevState, action as Action) as TableExplorerSearchState;
}

function shouldExpandWithoutFilter(viewMode: ViewMode) {
  return viewMode === 'pinned' || viewMode === 'recents' || viewMode === 'favorites';
}

function setMode(prevState: TableExplorerSearchState, newViewMode: ViewMode) {
  prevState.viewMode = newViewMode;
  prevState.expandSchemaWithoutFilter = shouldExpandWithoutFilter(newViewMode);
  updateFilteredTables(prevState);
}

function setTableNamesInSql(prevState: TableExplorerSearchState, tableNamesInSql: string[]) {
  prevState.tableNamesInSql = tableNamesInSql;
  updateSqlPinnedTables(prevState);
  updateActuallyPinnedTables(prevState);
  updateFilteredTables(prevState, true);
}

function manuallyPinTable(prevState: TableExplorerSearchState, tableId: string, pinned: boolean) {
  const newPinnedTableIds: PinnedTableMap = { ...prevState.tablesManuallyPinnedMap };
  newPinnedTableIds[tableId] = pinned;
  prevState.tablesManuallyPinnedMap = newPinnedTableIds;
  updateActuallyPinnedTables(prevState);
  updateFilteredTables(prevState, true);
}

function updateSqlPinnedTables(prevState: TableExplorerSearchState) {
  const newPinnedTableIds: PinnedTableMap = {};
  prevState.unfilteredTables.forEach((t) => {
    if (prevState.tableNamesInSql.includes(t.full_name)) {
      newPinnedTableIds[t.id] = true;
    }
  });
  prevState.tablesPinnedBySqlMap = newPinnedTableIds;
}

// Merges tablesPinnedBySqlMap and tablesManuallyPinnedMap into one map.
function updateActuallyPinnedTables(prevState: TableExplorerSearchState) {
  const { tablesPinnedBySqlMap, tablesManuallyPinnedMap } = prevState;
  const newPinnedTableIds: PinnedTableMap = { ...tablesPinnedBySqlMap };
  Object.keys(tablesManuallyPinnedMap).forEach((k) => {
    if (tablesManuallyPinnedMap[k] === true) {
      newPinnedTableIds[k] = true;
    }
  });
  prevState.tablesActuallyPinnedMap = newPinnedTableIds;
}
