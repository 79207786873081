import React from 'react';

import { OverlayDelay } from 'components/overlay/OverlayTrigger/OverlayTrigger';
import PopperTrigger from 'components/overlay/PopperTrigger/PopperTrigger';
import { Placement } from 'components/overlay/PopperTrigger/usePopperRefs';
import Tooltip from 'components/overlay/Tooltip/Tooltip';

interface TooltipTriggerProps {
  tip: string | React.ReactNode;
  children: React.ReactElement;
  placement?: Placement;
  forceShow?: boolean;
  delay?: OverlayDelay;
}

export default function TooltipTrigger(props: TooltipTriggerProps) {
  const { tip, children, forceShow, delay } = props;
  const placement = props.placement || 'top';

  return (
    <PopperTrigger
      placement={placement}
      triggers="hover"
      forceShow={forceShow}
      delay={delay}
      renderPopper={(popperProps) => {
        return <Tooltip tip={tip} {...popperProps} />;
      }}
    >
      {children}
    </PopperTrigger>
  );
}
