/**
 * This file calculates a new AutocompleteConfig everytime the SqlConfig changes.
 **/
import { useMemo } from 'react';

import { SearchColumnsByTableID } from 'api/searchColumnAPI';
import { AggTable } from 'api/tableAPI';

import { Completion, acceptCompletion, completionStatus } from '@codemirror/autocomplete';
import { schemaCompletionSource, SQLConfig } from '@codemirror/lang-sql';
import { Prec } from '@codemirror/state';
import { keymap } from '@codemirror/view';

import { CustomCompletion } from './autocompleteConstants';
import useCompletionSources from './useCompletionSources';

// Sets the Tab key to accept the completion
// when the completion panel is open
export const autocompleteTabKeymap = Prec.high(
  keymap.of([
    {
      key: 'Tab',
      preventDefault: true,
      run(view) {
        if (completionStatus(view.state)) {
          acceptCompletion(view);
          return true;
        }
        return false;
      },
    },
  ]),
);

const useAutocompleteConfig = (
  sqlConfig: SQLConfig,
  tablesByFullName: { [fullName: string]: AggTable },
  searchColumnsByTableID: SearchColumnsByTableID,
) => {
  const { keywordCompletionSource, snowflakeFunctionSnippetCompletionSource, columnCompletionSource } =
    useCompletionSources(tablesByFullName, searchColumnsByTableID);

  const autocompleteConfig = useMemo(
    () => ({
      override: [
        schemaCompletionSource(sqlConfig), // Misleadingly named method. It returns a CompletionSource for the given sqlConfig.
        columnCompletionSource,
        snowflakeFunctionSnippetCompletionSource,
        keywordCompletionSource,
      ],
      icons: false, // TODO: At some point we should develop a set of icons for SQL and reenable this.
      maxRenderedOptions: 20,
      // These methods add markup into the completion results
      // docs: https://codemirror.net/docs/ref/#autocomplete.autocompletion^config.addToOptions
      addToOptions: [
        {
          // workaround to not have the autocomplete match on function args
          // this re-adds the args only when the suggestion is rendered
          render: (completion: Completion) => {
            const compl = completion as CustomCompletion;

            let functionLabel: HTMLElement | null = null;
            let currentLabel: HTMLElement | Element | null = null;

            if (compl.type === 'function') {
              const labels = document.getElementsByClassName('cm-completionLabel');
              // find the currentLable Node for the completion
              for (let labelIndex in labels) {
                if (labels[labelIndex].textContent === compl.label) {
                  currentLabel = labels.item(+labelIndex);
                }
              }

              if (currentLabel) {
                // clone the current label so we get the full
                // child node structure with match highlighting

                functionLabel = currentLabel.cloneNode(true) as HTMLElement;
                // append the function args
                functionLabel?.appendChild(document.createTextNode(`(${compl.args})`));
                functionLabel?.classList.add('cm-completionLabel-modified');
              } else {
                // create a full label without the text matching underlines
                functionLabel = document.createElement('span');
                functionLabel.appendChild(document.createTextNode(`${compl.label}(${compl.args})`));

                functionLabel?.classList.add('cm-completionLabel');
                functionLabel?.classList.add('cm-completionLabel-modified');
              }
            }

            return functionLabel;
          },
          position: 50,
        },
        {
          render: (completion: Completion) => {
            const p = document.createElement('p');
            p.className = 'cm-tootlip-hint-desc';
            // @ts-ignore
            p.textContent = completion.desc;
            // @ts-ignore
            return completion.desc && p;
          },
          position: 80,
        },
        {
          render: (completion: Completion) => {
            const compl = completion as CustomCompletion;
            const anchor = document.createElement('a');
            anchor.textContent = 'Snowflake Docs';

            // @ts-ignore
            anchor.setAttribute('src', compl.url);
            anchor.className = 'cm-tooltip-docs-link';

            // hack to open snowflake docs link
            // CodeMirror autocomplete catches all mousedown
            // events on completions and prevents their default
            anchor.addEventListener('mousedown', (e) => {
              if (compl.url) {
                window.open(compl.url, '_blank');
              }
              e.stopPropagation();
            });
            // @ts-ignore
            return completion.url && anchor;
          },
          position: 90,
        },
      ],
    }),
    [
      columnCompletionSource,
      snowflakeFunctionSnippetCompletionSource,
      keywordCompletionSource,
      sqlConfig,
    ],
  );
  return autocompleteConfig;
};

export default useAutocompleteConfig;
