import React, { useEffect, useState, useContext } from 'react';

import { RouteComponentProps } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import API from 'api/API';
import { NotificationContext } from 'context/NotificationContext';
import { PaginatedInAppNotification, InAppNotification } from 'pages/notifications/NotificationHelpers';

import ListNotificationsView from './ListNotificationsView';

interface MatchParams {}

interface NotificationsProps extends RouteComponentProps<MatchParams> {}

export default function Notifications(props: NotificationsProps) {
  useTitle('Notifications');

  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [markingAllSeen, setMarkingAllSeen] = useState(false);
  const [notificationsResp, setNotificationsResp] = useState<PaginatedInAppNotification | null>(null);

  // Get NotificationContext
  const { updatedNotifications, setUpdatedNotifications } = useContext(NotificationContext);

  const handleLoadNotifications = (apiUrl: string | null) => {
    if (apiUrl) {
      setLoading(true);
      const api = new API();
      api
        .get(apiUrl)
        .then((response) => {
          setNotificationsResp(response.data);
          analytics.track('Notifications NotificationsLoaded', {
            notification_count: response.data.count,
          });
        })
        .catch((e) => {
          setError('There was a problem fetching notifications.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // When the page loads, refetch notifications from scratch
  useEffect(() => {
    handleLoadNotifications('/api/in_app_notifications?ordering=-created_at');
  }, []);

  // This useEffect updates the items on the page when updatedNotifications changes
  // This is to coordinate when the nav bar also updates seen
  useEffect(() => {
    // Here we only update the notifications we are currently showing, rather than all that were marked as seen
    const updatedNotificationsIds = updatedNotifications.map(
      (notification: InAppNotification) => notification.id,
    );
    const mergeUpdatedNotifications = (oldNotificationsResp: PaginatedInAppNotification | null) => {
      if (oldNotificationsResp !== null) {
        const newResults = oldNotificationsResp.results.map((oldNotification) => {
          if (updatedNotificationsIds.includes(oldNotification.id)) {
            return updatedNotifications.find(
              (newNotification: InAppNotification) => newNotification.id === oldNotification.id,
            ) as InAppNotification;
          } else {
            return oldNotification;
          }
        });
        return {
          ...oldNotificationsResp,
          results: newResults,
          count: newResults.length,
        };
      } else {
        return oldNotificationsResp;
      }
    };
    setNotificationsResp((r) => mergeUpdatedNotifications(r));
  }, [updatedNotifications]);

  const handleMarkAllSeen = () => {
    const api = new API();
    setMarkingAllSeen(true);
    api
      .post('/api/in_app_notifications/mark_all_seen', {})
      .then((response) => {
        analytics.track('Notifications MarkAllSeen');
        // Set context with response so it updates both here and in the nav bar dropdown
        setUpdatedNotifications(response.data);
      })
      .catch((e) => {
        // TODO: Might want to have a error message if the button doesnt work
      })
      .finally(() => {
        setMarkingAllSeen(false);
      });
  };

  const handleNotificationClick = (notification: InAppNotification) => {
    // Mark the item as read before forwarding
    // Note: We dont need to bother updating notificationsResp because we are about to leave the page.
    // If there is ever a notification type without a link, we need to update notificationsResp
    const api = new API();
    api
      .patch(`/api/in_app_notifications/${notification.id}`, { seen: true })
      .then((response) => {
        // setUpdatedNotifications so Notifications page and the dropdown update
        // Note: setUpdatedNotifications expects an array
        setUpdatedNotifications([response.data]);
      })
      .catch((e) => {
        // TODO: Might want to have a error message if the button doesnt work
      });
    // TODO: Might want to track more data about the link that was clicked
    if (notification.url) {
      analytics.track('Notifications NotificationClick');
      navigate(notification.url);
    }
  };

  const handleLoadPreviousPage = () => {
    if (notificationsResp) {
      handleLoadNotifications(notificationsResp.previous);
      analytics.track('Notifications LoadPreviousPage');
    }
  };

  const handleLoadNextPage = () => {
    if (notificationsResp) {
      handleLoadNotifications(notificationsResp.next);
      analytics.track('Notifications LoadNextPage');
    }
  };

  return (
    <ListNotificationsView
      error={error}
      loading={loading}
      markingAllSeen={markingAllSeen}
      notificationsResp={notificationsResp}
      onNotificationClick={handleNotificationClick}
      onMarkAllSeen={handleMarkAllSeen}
      onLoadPreviousPage={handleLoadPreviousPage}
      onLoadNextPage={handleLoadNextPage}
    />
  );
}
