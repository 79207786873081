import StatusIcon, { Status } from 'components/primitives/icons/StatusIcon/StatusIcon';
import AgoHover from 'components/widgets/time/AgoHover/AgoHover';
import { SetupState, SyncState, getConnectorStatus } from 'model_helpers/connectorHelper';

export interface ConnectorStatusIndicatorProps {
  setupState: SetupState;
  syncState: SyncState;
  lastRunFailed: boolean;
  isHistoricalSync: boolean;
  lastRunAt: Date | null;
}

export default function ConnectorStatusIndicator(props: ConnectorStatusIndicatorProps) {
  const { setupState, syncState, lastRunFailed, isHistoricalSync, lastRunAt } = props;

  const getSyncStatusClassName = () => {
    if (syncState === 'syncing') {
      return 'text-pri-gray-500';
    }
    if (lastRunFailed) {
      return 'text-pri-error-700';
    }
    return 'text-pri-success-600';
  };

  const getSyncStatusText = () => {
    if (syncState === 'syncing') {
      return isHistoricalSync ? 'Historical Resync' : 'Syncing';
    }
    if (!lastRunAt) {
      return 'Connector has not synced yet';
    }
    if (lastRunFailed) {
      return 'Last Sync Failed';
    }
    return 'Last Sync Successful';
  };

  return (
    <div className="flex items-center space-x-2">
      {(lastRunAt || syncState === 'syncing') && (
        <div className="flex items-center space-x-2">
          <StatusIcon
            status={getConnectorStatus(setupState, syncState, lastRunFailed) as Status}
            rotate={true}
          />
          <div className={`${getSyncStatusClassName()} font-medium`}>{getSyncStatusText()}</div>
        </div>
      )}
      {lastRunAt && syncState !== 'syncing' && (
        <AgoHover className="text-pri-gray-500" date={lastRunAt} />
      )}
    </div>
  );
}
