/*
useSetupWarehouse() provides the startSetupWarehouse() method
to start setting up the user's warehouse.
After that method is called a background worker will setup
the user's warehouse.

After the start method is called, useSetupWarehouse()
polls setup status until setup is complete.
When setup is complete, it redirects the user to the /getting-started page.

FYI:
This files has unusually paranoid analytics.track() logging
that records starts and stops of the setup process
so that we can carefully analyze the signup funnel.
*/
import { useCallback, useEffect, useState } from 'react';

import API from 'api/API';
import { UserProfile } from 'api/APITypes';
import { useUserProfile } from 'context/AuthContext';
import { usePoll } from 'hooks/useApi';

import history from '../../appHistory';
import { WarehouseType, parseError } from '../../utils/byoSetupErrorHelpers';

// This location is passed to Segment tracking.
// It is similar in nature to the API type WarehouseType,
// but is formatted for Segment events and
// it passes distinct values for all of the different
// pathways a specific WarehouseType might be created.
export type SetupLocation =
  | 'CreateSnowflakeForUser' // WarehouseType = managed
  | 'CreateSnowflakeFromThoughtspotSignup' // WarehouseType = managed
  | 'SaveBYOSCredentials' // WarehouseType = byos
  | 'SaveBYOBQCredentials'; // WarehouseType = byobq

interface ManagedSetupProps {
  warehouse_type: WarehouseType;
}

// Props recorded by Formik
export interface ByosFormProps {
  password: string;
  account_identifier: string;
}

// Props sent to the API
export interface ByosSetupProps extends ManagedSetupProps, ByosFormProps {}

// Props recorded by Formik
export interface ByobqFormProps {
  project_id: string;
  credentials: string;
}

// Props sent to the API
export interface ByobqSetupProps extends ManagedSetupProps, ByobqFormProps {}

export type SetupProps = ByosSetupProps | ByobqSetupProps | ManagedSetupProps;

export type SetupWarehouseStatus = 'not_requested' | 'pending' | 'in_progress' | 'completed' | 'failed';
export interface SetupWarehouseStatusPayload {
  warehouse_setup_status: SetupWarehouseStatus;
  user_profile?: UserProfile;
  error?: string;
}

// Hook that checks if there is a warehouse setup in progress
// one time when the page first loads.
const useInitialCheckSetupWarehouse = (onPayload: (payload: SetupWarehouseStatusPayload) => void) => {
  const [checkIsLoading, setCheckIsLoading] = useState(true);
  const [checkError, setCheckError] = useState('');

  useEffect(() => {
    const api = new API();
    api
      .get('/api/poll_setup_warehouse')
      .then((response) => {
        const payload = response.data as SetupWarehouseStatusPayload;
        onPayload(payload);
      })
      .catch((e) => {
        setCheckError('Failed to check if you already have a database setup in progress.');
      })
      .finally(() => {
        setCheckIsLoading(false);
      });
  }, [onPayload]);

  return { checkIsLoading, checkError };
};

// Hook that manages API call to start warehouse setup
const useStartSetupWarehouse = (
  onPayload: (payload: SetupWarehouseStatusPayload) => void,
  eventLocation: SetupLocation,
) => {
  const [startIsLoading, setStartIsLoading] = useState(false);
  const [startError, setStartError] = useState('');
  // If the user reloads the page we don't want them to see the error from a previous attempt.
  // We do want the user to just retry warehouse setup.
  // Set this to true if this session started a setup porcess.
  const [startCanShowError, setStartCanShowError] = useState(false);

  const startSetupWarehouse = useCallback(
    (setupProps: SetupProps) => {
      setStartIsLoading(true);
      setStartError('');
      setStartCanShowError(true);
      analytics.track(`Signup ${eventLocation}Started`);

      const api = new API();

      return api
        .post('/api/poll_setup_warehouse', setupProps)
        .then((response) => {
          const payload = response.data as SetupWarehouseStatusPayload;
          onPayload(payload);
        })
        .catch((e) => {
          // This catch represents a network failure or an unknown failure queing the start of the setup process.
          // A warehouse setup error is reported at `SetupWarehouseStatusPayload.error`
          setStartError('There was an unknown error setting up your warehouse.');
          analytics.track(`Signup ${eventLocation}ErrorAtStart`);
        })
        .finally(() => {
          setStartIsLoading(false);
        });
    },
    [onPayload, eventLocation],
  );

  return { startSetupWarehouse, startIsLoading, startError, startCanShowError };
};

// Hook that manages polling until setup is complete
const usePollSetupWarehouseStatus = (
  setupRunning: boolean,
  setupStatus: SetupWarehouseStatusPayload,
  onPayload: (payload: SetupWarehouseStatusPayload) => void,
  eventLocation: SetupLocation,
) => {
  // Poll while setup is running
  const { data, isLoading, error } = usePoll<SetupWarehouseStatusPayload>(
    'setupWarehouseStatus',
    '/api/poll_setup_warehouse',
    [2],
    setupRunning,
  );

  // Parse the data returned from the polling
  useEffect(() => {
    if (data) {
      const payload = data as SetupWarehouseStatusPayload;
      onPayload(payload);
    }
  }, [onPayload, data, eventLocation]);

  // Log setup errors to Segment
  useEffect(() => {
    if (setupStatus.error) {
      analytics.track(`Signup ${eventLocation}ErrorAtPoll`, { error: setupStatus.error });
    }
  }, [setupStatus.error, eventLocation]);

  return { data, isLoading, error };
};

// This hook should be called by UI code.
// It contains all of the code needed by the UI.
const useSetupWarehouse = (warehouseType: WarehouseType, eventLocation: SetupLocation) => {
  const { setUserProfile } = useUserProfile();
  const [setupStatus, setSetupStatus] = useState<SetupWarehouseStatusPayload>({
    warehouse_setup_status: 'not_requested',
  });

  // Shared payload parser used by both start and poll methods.
  // The starting POST to /api/poll_setup_warehouse and
  // the polling GET to /api/poll_setup_warehouse
  // both return the same HTTP 200/201 payload.
  const onPayload = useCallback(
    (payload: SetupWarehouseStatusPayload) => {
      if (payload.user_profile) {
        const userProfile = payload.user_profile as UserProfile;
        setUserProfile(userProfile);
        analytics.track(`Signup ${eventLocation}Success`);
        history.push('/getting-started/new-account');
      } else {
        setSetupStatus(payload);
      }
    },
    [setUserProfile, eventLocation],
  );

  // When the app first loads, get the current warehouse setup status once
  // just incase the user started a warehouse setup on a previous visit.
  // We don't want them to get confused and think they have to start
  // a second create warehouse process.
  const { checkIsLoading, checkError } = useInitialCheckSetupWarehouse(onPayload);

  // Start API state
  const { startSetupWarehouse, startIsLoading, startError, startCanShowError } = useStartSetupWarehouse(
    onPayload,
    eventLocation,
  );

  // Poll until setup process suceeds or fails
  const setupRunning =
    setupStatus.warehouse_setup_status === 'pending' ||
    setupStatus.warehouse_setup_status === 'in_progress';
  usePollSetupWarehouseStatus(setupRunning, setupStatus, onPayload, eventLocation);

  // The UI shows a spinner when this is true
  const setupInProgress = startIsLoading || setupRunning;

  // Combine checkError, startError, and polling errors into one error string.
  // Use the first error encountered in the process.
  let setupError = checkError || startError;
  // Only show an error from the async polling process if this browser
  // session started the setup process.
  if (!setupError && setupStatus.error && startCanShowError && !startIsLoading) {
    setupError = parseError(warehouseType, setupStatus.error);
  }

  return {
    checkIsLoading,
    startSetupWarehouse,
    setupInProgress,
    setupError,
  };
};

export default useSetupWarehouse;
