import React, { useState } from 'react';

import { useHistory } from 'react-router';

import { Formik } from 'formik';

import API from 'api/API';
import { DbtSshKey, DbtRepository, DbtRepositoryCreateParams } from 'api/dbtAPI';
import Button from 'components/inputs/basic/Button/Button';
import CopyButton from 'components/inputs/basic/Button/CopyButton';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import Alert from 'components/widgets/alerts/Alert/Alert';

interface ConnectGitRepoProps {
  publicKey: DbtSshKey;
  repos: DbtRepository[];
  setRepos: (repos: DbtRepository[]) => void;
  setShowConnectNewGitRepo: (show: boolean) => void;
}

interface SubmitGitRepoProps {
  git_url: string;
}

const ConnectGitRepo = (props: ConnectGitRepoProps) => {
  const { publicKey, repos, setRepos, setShowConnectNewGitRepo } = props;
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const history = useHistory();

  const initialValues: SubmitGitRepoProps = {
    git_url: '',
  };

  const handleSave = async (values: SubmitGitRepoProps, actions: any) => {
    setSaving(true);
    setErrorMessage('');

    const api = new API();

    try {
      const postData: DbtRepositoryCreateParams = {
        ssh_key_pair: publicKey.id,
        git_url: values.git_url,
      };
      const response = await api.post('api/dbt_repositories', postData);
      const gitRepo = response.data as DbtRepository;
      setErrorMessage(null);
      setRepos([gitRepo, ...repos]);
      analytics.track('AddDBTRunConfig AddGitRepo');
      setShowConnectNewGitRepo(false);
    } catch (error: any) {
      if (error.response?.status === 400) {
        const data = error.response.data;
        // The array is a non-standard error object that can get returned when the git URL is invalid.
        if (Array.isArray(data)) {
          if (data.length > 0) {
            setErrorMessage(data[0]);
          }
        }
        // Form input validation errors
        else {
          actions.setErrors(data);
          if (data.non_field_errors) {
            setErrorMessage(data.non_field_errors);
          }
        }
      }
      // Unknown/unhandled issue
      else {
        setErrorMessage(`There was a problem saving your git repo.`);
      }
      setSaving(false);
    }
  };

  const handleCancelAddGitRepoAtEndOfForm = () => {
    if (repos.length) {
      setShowConnectNewGitRepo(false);
      analytics.track('AddDBTRunConfig CancelAddGitRepoAtEndOfForm');
    } else {
      analytics.track('AddDBTRunConfig CancelAddGitRepoAtEndOfFormBackToDBTIntegration');
      history.push('/integrations/dbt');
    }
  };

  const handleCopyKey = () => {
    navigator.clipboard.writeText(publicKey.git_public_key);
  };

  const numberClassName =
    'f-center w-[32px] h-[32px] font-medium text-xl bg-sec-blue-gray-500 text-white rounded-full';

  return (
    <CenteredLayout maxWidth="814px" title="Connect dbt Git Repo">
      <div className="f-row-y-center">
        <div className={numberClassName}>1</div>
        <div className="ml-2 font-medium">
          Add Mozart's public key to your GitHub, GitLab, or Bitbucket repo
        </div>
      </div>
      <div className="ml-10">
        <div className="mt-3">In GitHub, navigate to the repo and conduct the following steps:</div>
        <ul className="list-disc ml-8 mt-2 grid gap-1 grid-cols-1">
          <li>
            Go to the <code>Settings</code> tab
          </li>
          <li>
            Click <code>Deploy Keys</code> in the side menu
          </li>
          <li>
            Click <code>Add deploy key</code>
          </li>
          <li>
            Give the key a title and paste the ssh key below into the <code>Key</code> field.
          </li>
          <li>The key does not need write access.</li>
        </ul>
        {/*
        The Copy button is 4px taller than the label.
        There is some non-standard space tweaking here to make it "look right".
        */}
        <div className="mt-3 f-between">
          <div className="text-input-label">Mozart's ssh public key</div>
          <CopyButton onClick={handleCopyKey} className="-mt-1" />
        </div>
        <div className="py-4 px-6 bg-pri-gray-50 text-pri-gray-500 rounded break-words">
          {publicKey.git_public_key}
        </div>
        <div className="mt-2">
          Follow more detailed instructions for{' '}
          <a
            href="https://docs.github.com/en/developers/overview/managing-deploy-keys#setup-2"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sec-blue-light-600"
          >
            GitHub,
          </a>{' '}
          <a
            href="https://docs.gitlab.com/ee/user/project/deploy_keys/index.html#project-deploy-keys"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sec-blue-light-600"
          >
            GitLab
          </a>
          , or{' '}
          <a
            href="https://support.atlassian.com/bitbucket-cloud/docs/set-up-repository-access-keys-on-linux/#Provide-Bitbucket-Cloud-with-your-public-key"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sec-blue-light-600"
          >
            Bitbucket
          </a>
          .
        </div>
      </div>
      <div className="h-px mt-6 bg-pri-gray-200" />
      <div className="mt-6 f-row-y-center">
        <div className={numberClassName}>2</div>
        <div className="ml-2 font-medium">Provide the url to test the repo</div>
      </div>
      <div className="mt-2 ml-10">
        <Formik onSubmit={handleSave} initialValues={initialValues}>
          {({ handleSubmit, handleChange }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <TextFormikGroup
                  name="git_url"
                  placeholder="https://github.com/myCompany/myRepo"
                  onChange={handleChange}
                />

                {errorMessage && (
                  <Alert variant="error" className="mt-4">
                    {errorMessage}
                  </Alert>
                )}

                <div className="flex mt-4">
                  <Button
                    type="submit"
                    variant="lightAction"
                    style={{ width: '133px' }}
                    spinning={saving}
                  >
                    Test and Save
                  </Button>
                  <Button
                    variant="lightDanger"
                    onClick={handleCancelAddGitRepoAtEndOfForm}
                    className="ml-2"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </CenteredLayout>
  );
};

export default ConnectGitRepo;
