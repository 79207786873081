import { useContext } from 'react';

import { useTitle } from 'react-use';

import { useUserProfile } from 'context/AuthContext';
import { CompanySetupGuideContext } from 'model_layer/CompanySetupGuideContext';

import HomeView from './HomeView/HomeView';
import useRecentWorkWidget from './useRecentWorkWidget';
import useStatusWidget from './useStatusWidget';
import useUsageWidget from './useUsageWidget';
import useUserActionsWidget from './useUserActionsWidget';

export default function HomeController() {
  useTitle('Home');
  const statusWidgetState = useStatusWidget();
  const recentWorkWidgetState = useRecentWorkWidget();
  const userActionsWidgetState = useUserActionsWidget();
  const usageWidgetState = useUsageWidget();

  const { userProfile } = useUserProfile();
  const { company_role: role } = userProfile;
  const { show: showGuide } = useContext(CompanySetupGuideContext);

  return (
    <HomeView
      statusWidgetState={statusWidgetState}
      recentWorkWidgetState={recentWorkWidgetState}
      userActionsWidgetState={userActionsWidgetState}
      usageWidgetState={usageWidgetState}
      companyRole={role}
      showGuide={showGuide}
    />
  );
}
