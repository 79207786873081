import type { UserActionObjectType } from '../../usePagedUserActions';

import { AlertChangedSQLText, AlertHasBeenRenamedText } from './AlertActionSentence';
import {
  ConnectorChangedSyncFreqText,
  ConnectorHistoricalResyncText,
  ConnectorSyncNowText,
  ConnectorChangedSchemaText,
} from './ConnectorActionSentences';
import {
  DbtRunConfigChangedCommandsText,
  DbtRunConfigChangedScheduleText,
  DbtRunConfigChangedTargetSchemaText,
  DbtRunConfigManualRunText,
  DbtRunConfigRenamedText,
} from './DbtRunConfigActionSentence';
import { SnowflakeUserCreatedText, SnowflakeUserChangedText } from './SnowflakeUserActionSentence';
import {
  CSVUploadedToTableText,
  TableHasBeenRenamedText,
  TableSnapshotManualRunText,
  TableSnapshotToggledText,
} from './TableActionSentence';
import {
  TransformToggledScheduleText,
  TransformChangedScheduleText,
  TransformChangedIncrementalText,
  TransformManualRunText,
  TransformChangedSQLText,
  TransformChangedCreateAsText,
} from './TransformActionSentence';
import { ObjectDelta, ObjectLinkOrName, UserActionSentencePartProps } from './UserActionSentenceParts';

export default function UserActionSentence({ userAction, eventLocation }: UserActionSentencePartProps) {
  let { object_type, event_type } = userAction;

  const hasActionSentence: boolean =
    object_type in OBJECT_ACTION_TEMPLATE && event_type in OBJECT_ACTION_TEMPLATE[object_type];

  if (hasActionSentence) {
    return OBJECT_ACTION_TEMPLATE[object_type][event_type]({ userAction, eventLocation });
  } else {
    return <UnknownUserActionText userAction={userAction} eventLocation={eventLocation} />;
  }
}

/*******************************************************************************
 * Generic action sentence components
 ******************************************************************************/
// Ideally a every user action type has a custom sentence that is perfect for it.
// Use this if the server creates an action the frontent doesn't know about.
const UnknownUserActionText = ({ userAction, eventLocation }: UserActionSentencePartProps) => (
  <>
    <ObjectLinkOrName userAction={userAction} eventLocation={eventLocation} /> was{' '}
    <ObjectDelta value={userAction.event_type} />.
  </>
);

const ObjectHasBeenText = ({ userAction, eventLocation }: UserActionSentencePartProps) => (
  <>
    <ObjectLinkOrName userAction={userAction} eventLocation={eventLocation} /> has been{' '}
    <ObjectDelta value={userAction.event_type} />.
  </>
);

/*******************************************************************************
 * Build lookup table for all user action sentence components.
 ******************************************************************************/
const GENERIC_ACTION_TEMPLATES = {
  created: ObjectHasBeenText,
  deleted: ObjectHasBeenText,
};

type ObjectActionTemplate = {
  [key in UserActionObjectType]: {
    [key in string]: any;
  };
};

// These values are pulled <project_root>/event_logs/types.py
export const OBJECT_ACTION_TEMPLATE: ObjectActionTemplate = {
  connector: {
    ...GENERIC_ACTION_TEMPLATES,
    changed_sync_frequency: ConnectorChangedSyncFreqText,
    historical_resync: ConnectorHistoricalResyncText,
    sync_now: ConnectorSyncNowText,
    changed_schema: ConnectorChangedSchemaText,
    // initiated: I think this one was renamed and maybe we can migrate it to one of the other names.
  },
  table: {
    // GENERIC_ACTION_TEMPLATES do not apply to tables at present.
    snapshot_toggled: TableSnapshotToggledText,
    snapshot_manual_run: TableSnapshotManualRunText,
    renamed: TableHasBeenRenamedText,
    loaded_csv: CSVUploadedToTableText,
    deleted: ObjectHasBeenText,
  },
  transform: {
    ...GENERIC_ACTION_TEMPLATES,
    changed_schedule: TransformChangedScheduleText,
    incremental_updated: TransformChangedIncrementalText,
    changed_sql: TransformChangedSQLText,
    manual_run: TransformManualRunText,
    changed_create_as: TransformChangedCreateAsText,
    // We may need to keep the actions below this line for historical DB record purposes.
    // `toggled` was replaced by `changed_schedule` durring feature development.
    toggled: TransformToggledScheduleText,
    // `initiated` was replaced by `manual_run` durring feature development.
    initiated: TransformManualRunText,
  },
  // alert type is table_test type from the API
  table_test: {
    ...GENERIC_ACTION_TEMPLATES,
    renamed: AlertHasBeenRenamedText,
    changed_sql: AlertChangedSQLText,
  },
  dbt_run_configuration: {
    ...GENERIC_ACTION_TEMPLATES,
    changed_schedule: DbtRunConfigChangedScheduleText,
    manual_run: DbtRunConfigManualRunText,
    renamed: DbtRunConfigRenamedText,
    changed_dbt_commands: DbtRunConfigChangedCommandsText,
    changed_dbt_target_schema: DbtRunConfigChangedTargetSchemaText,
  },
  snowflake_user: {
    created_snowflake_user: SnowflakeUserCreatedText,
    changed_snowflake_user: SnowflakeUserChangedText,
    deleted: ObjectHasBeenText,
  },
};
