class APIKeyManager {
  private apiKey: string | null = null;

  constructor(private label: string) {}

  requestAPIKey(message: string): string | null {
    const key = window.prompt(`Enter your ${this.label} API Key:\n${message}`, '');
    if (!key) {
      return null;
    }

    const keep = window.confirm('Keep this for repeated use (OK) or dispose immediately (Cancel)?');
    if (keep) {
      this.apiKey = key;
      localStorage.setItem(`mozart-ide-${this.label}-key`, key);
    }
    return key;
  }

  getAPIKey(message: string): string | null {
    if (this.apiKey) {
      return this.apiKey;
    }
    let storedKey = localStorage.getItem(`mozart-ide-${this.label}-key`);
    if (storedKey) {
      this.apiKey = storedKey;
      return storedKey;
    }
    storedKey = this.requestAPIKey(message);
    return storedKey;
  }

  handleAPIKeyError(): void {
    const storedKey = localStorage.getItem(`mozart-ide-${this.label}-key`);
    if (storedKey) {
      localStorage.setItem(`mozart-ide-${this.label}-key-expired`, storedKey);
      localStorage.removeItem(`mozart-ide-${this.label}-key`);
      this.apiKey = null;
      alert(
        `Clearing the stored key for ${this.label}. You will have to populate a new one on the next request.`,
      );
    }
  }
}

export default APIKeyManager;
