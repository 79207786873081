import React, { ChangeEvent, useState } from 'react';

import { useBooleanFlag } from 'hooks/useFeatureFlags';
import { percentFormatted } from 'utils/Math';
import { formatToDollarAmount } from 'utils/String';

import { CompanyPlanRow } from '../useUsageFetch';

import LineItem from './LineItem';

interface EstimatorTabProps {
  currentInvoice: CompanyPlanRow | undefined;
}

interface State {
  mar: number;
  compute: number;
  cloud_service: number;
  storage: number;
}

export default function EstimatorTab(props: EstimatorTabProps) {
  const { currentInvoice } = props;
  const [values, setValues] = useState<State>({ mar: 0, compute: 0, cloud_service: 0, storage: 0 });

  const isUsage = currentInvoice?.IS_USAGE || false;
  const isAnnual = currentInvoice?.RECENT_ALLOTMENT_GRAIN === 'Annual';

  const isSonata = ['Sonata Free', 'Sonata Usage'].includes(currentInvoice?.PLAN_TYPE_MOST_RECENT || '');

  // This is used to allow users who are considering transitioning to usage based pricing to see our estimator
  const estimatorExperimentEnabled = useBooleanFlag('estimator_tab', false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    let newValue: number | string = parseFloat(value) || 0;
    if (newValue < 0) {
      newValue = 0;
    }
    if (value === '') {
      newValue = '';
    }
    setValues({ ...values, [id]: newValue });
  };

  let marAllotment = 0;
  let creditAllotment = 0;
  if (!isUsage && !estimatorExperimentEnabled) {
    marAllotment = isAnnual
      ? (currentInvoice?.SUM_FIVETRAN_MAR_ALLOTMENT || 0) * 12
      : currentInvoice?.SUM_FIVETRAN_MAR_ALLOTMENT || 0;
    creditAllotment = isAnnual
      ? (currentInvoice?.SUM_SNOWFLAKE_CREDITS_ALLOTMENT || 0) * 12
      : currentInvoice?.SUM_SNOWFLAKE_CREDITS_ALLOTMENT || 0;
  }

  let fivetranDiscount = currentInvoice?.MAX_FIVETRAN_DISCOUNT;
  let snowflakeDiscount = currentInvoice?.MAX_SNOWFLAKE_DISCOUNT;
  if (estimatorExperimentEnabled) {
    fivetranDiscount = 0.2;
    snowflakeDiscount = 0.1;
  }

  const billedMar = Math.max((values.mar || 0) - marAllotment, 0);
  const billedCredits = Math.max((values.compute || 0) - creditAllotment, 0);

  const fivetranPricingVersion = estimatorExperimentEnabled
    ? 'v2'
    : currentInvoice?.FIVETRAN_PRICING_VERSION_MOST_RECENT || 'v2';

  const marCost = calculateMARCost(billedMar, fivetranPricingVersion) * (1 - (fivetranDiscount || 0));
  const creditsCost = calculateCreditsCost(billedCredits) * (1 - (snowflakeDiscount || 0));
  const cloudServiceCost =
    calculateCreditsCost(values.cloud_service || 0) * (1 - (snowflakeDiscount || 0));
  const storageCost = calculateStorageCost(values.storage || 0) * (1 - (snowflakeDiscount || 0));

  const monthlyCost = currentInvoice?.SUM_MONTHLY_AMOUNT || 0;
  let planCost = isAnnual ? monthlyCost * 12 : monthlyCost;
  if (isUsage || estimatorExperimentEnabled) {
    planCost = 0;
  }
  const platformFee = estimatorExperimentEnabled ? 0 : currentInvoice?.PLATFORM_FEE_MOST_RECENT || 0;
  const additionalDiscount = estimatorExperimentEnabled
    ? 0
    : currentInvoice?.MAX_ADDITIONAL_DISCOUNT || 0;

  const sum =
    (marCost + creditsCost + cloudServiceCost + storageCost + planCost + platformFee) *
    (1 - additionalDiscount);

  const planCostDiv = planCost > 0 && (
    <div className="f-row-y-center">
      +<div className="mx-1 text-primary-700">${formatToDollarAmount(planCost)}</div>plan cost
    </div>
  );
  const platformFeeDiv = platformFee > 0 && (
    <div className="f-row-y-center">
      +<div className="mx-1 text-primary-700">${formatToDollarAmount(platformFee)}</div>platform fee
    </div>
  );
  const additionalDiscountDiv = additionalDiscount > 0 && (
    <div>x {percentFormatted(additionalDiscount, 1, 0)}% discount</div>
  );

  const showMarRow =
    currentInvoice?.IS_FIVETRAN_OVERAGE || isSonata || isUsage || estimatorExperimentEnabled;
  const showComputeRow =
    currentInvoice?.IS_SNOWFLAKE_OVERAGE || isSonata || isUsage || estimatorExperimentEnabled;

  return (
    <>
      <div className="my-4">
        Use the estimator below to project costs on your current plan. If you have any questions, please
        reach out to sales@mozartdata.com.
      </div>
      <div className="grid grid-cols-3 gap-4 my-6 mx-auto">
        {showMarRow && (
          <LineItem
            id="mar"
            value={values.mar}
            label="MAR"
            color="--sec-blue-700"
            cost={marCost}
            discount={fivetranDiscount}
            billedValue={billedMar}
            allotment={marAllotment}
            handleChange={handleChange}
          />
        )}

        {showComputeRow && (
          <LineItem
            id="compute"
            value={values.compute}
            label="Compute Credits"
            color="--pri-success-700"
            cost={creditsCost}
            discount={snowflakeDiscount}
            billedValue={billedCredits}
            allotment={creditAllotment}
            handleChange={handleChange}
          />
        )}

        {isUsage && (
          <>
            <LineItem
              id="cloud_service"
              value={values.cloud_service}
              label="Cloud Service Credits"
              color="--sec-indigo-700"
              cost={cloudServiceCost}
              discount={snowflakeDiscount}
              handleChange={handleChange}
            />{' '}
            <LineItem
              id="storage"
              value={values.storage}
              label="Storage (GB)"
              color="--sec-orange-700"
              cost={storageCost}
              discount={snowflakeDiscount}
              handleChange={handleChange}
            />
          </>
        )}
        <div></div>
        <div className="f-col my-auto ml-4 text-pri-gray-400">
          {planCostDiv}
          {platformFeeDiv}
          {additionalDiscountDiv}
        </div>

        <div className="f-col my-auto text-pri-gray-400">
          Total cost per {isAnnual ? 'year' : 'month'}:
          <div className="display-xs font-medium text-primary-700">${formatToDollarAmount(sum)}</div>
        </div>
        {isSonata && (
          <>
            {/* Empty divs are for grid spacing */}
            <div></div>
            <div></div>
            <div className="text-sm text-pri-gray-400">
              * Additional discounts available, please reach out to sales@mozartdata.com for more info.
            </div>
          </>
        )}
      </div>
    </>
  );
}

// This function is converted to Typescript from the python function in Snowflake for calculating MAR cost
const calculateMARCost = (mar: number, version: string) => {
  // Assert the inputs are valid
  if (mar < 0) {
    return 0;
  }
  if (!['v0', 'v1', 'v2'].includes(version)) {
    return 0;
  }

  // First determine which version logic to use
  if (version === 'v1') {
    if (mar < 200000) {
      return (mar / 200000) * 180;
    }
    if (mar < 500000) {
      return Math.pow(2.2944444, (Math.log10(mar) - Math.log10(200000)) / 0.3979400087) * 180;
    }
    if (mar < 1 * Math.pow(10, 6)) {
      return Math.pow(1.81598063, (Math.log10(mar) - Math.log10(500000)) / 0.3010299957) * 413;
    }
    if (mar < 2 * Math.pow(10, 6)) {
      return Math.pow(1.586666667, (Math.log10(mar) - Math.log10(1000000)) / 0.3010299957) * 750;
    }
    if (mar < 10 * Math.pow(10, 6)) {
      return Math.pow(3.0, (Math.log10(mar) - Math.log10(2000000)) / 0.6989700043) * 1190;
    }
    if (mar < 50 * Math.pow(10, 6)) {
      return Math.pow(1.944537815, (Math.log10(mar) - Math.log10(10000000)) / 0.6989700043) * 3570;
    } else {
      return 6942 * Math.pow(2, Math.log10(mar) - Math.log10(50000000));
    }
  } else if (version === 'v2') {
    if (mar < 1 * Math.pow(10, 6)) {
      return ((mar - 0) / (1 * Math.pow(10, 6))) * 750 + 0;
    }
    if (mar < 2 * Math.pow(10, 6)) {
      return ((mar - 1 * Math.pow(10, 6)) / (1 * Math.pow(10, 6))) * 302.6 + 750;
    }
    if (mar < 10 * Math.pow(10, 6)) {
      return ((mar - 2 * Math.pow(10, 6)) / (1 * Math.pow(10, 6))) * 263.15 + 1053;
    }
    if (mar < 100 * Math.pow(10, 6)) {
      return ((mar - 10 * Math.pow(10, 6)) / (1 * Math.pow(10, 6))) * 74.56 + 3158;
    }
    if (mar < 1000 * Math.pow(10, 6)) {
      return ((mar - 100 * Math.pow(10, 6)) / (1 * Math.pow(10, 6))) * 14.77 + 9868;
    }
    if (mar < 10000 * Math.pow(10, 6)) {
      return ((mar - 1000 * Math.pow(10, 6)) / (1 * Math.pow(10, 6))) * 3.2748 + 23157;
    }
    if (mar >= 10000 * Math.pow(10, 6)) {
      return ((mar - 10000 * Math.pow(10, 6)) / (1 * Math.pow(10, 6))) * 2.93 + 52630;
    }
    // Added else for typescript but all cases are covered above
    else {
      return 0;
    }
  } else {
    return 15.625 * Math.pow(mar, 0.301) * 1.5;
  }
};
const calculateCreditsCost = (credits: number) => {
  return credits * 3;
};
const calculateStorageCost = (storage: number) => {
  return (storage * 20) / 1000;
};
