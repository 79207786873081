/*
Why:
useApi.usePoll() triggers a rerender everytime the poll executes.
This hook only triggers a rerender when the state should update,
which makes it way better for app level model layers.

Example:

import {usePolling} from 'hooks/usePolling';

// FYI:
// It's a good idea to wrap everything you pass into usePolling in a useMemo or useCallback.
// That prevents the useEffect from calling the unmount code unneccessarily,
// which will reset the poll timer.
const onData = useCallback((data:OnDataType)=>{
  if(data.someCondition) {
    setExampleState(data.example);
  }
},[setExampleState])

usePolling<OnDataType>('/api/example', onData);
*/
import { useRef, useEffect } from 'react';

import { flatten } from 'lodash';

import API from 'api/API';

// The semi-exponential series of backoff times to poll with.
// FYI: Syncing a trivial spreadsheet takes about 40 seconds.
export const POLL_SECONDS = flatten([
  // Super responsive at first as backend jobs get queued up and change state
  [5, 5],
  // Attentive for two minutes.
  // Anything longer than 30 seconds and the user will space out.
  // However in practice, Fivetran needs a little over a minute to sync a gsheet.
  // Being attentive for two minutes instead of one is a debugging convenience.
  [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
  // Less attentive for another minute
  [20, 20, 20],
  // Back off as not to waste resources
  [60, 60],
  [120, 120],
  [5 * 60, 5 * 60],
  10 * 60,
]);

function pollTime(pollIndex: number, pollArray: number[]) {
  const safeIndex = pollIndex < pollArray.length ? pollIndex : pollArray.length - 1;
  return pollArray[safeIndex] * 1000;
}

export function usePolling<T>(
  apiPath: string,
  onData: (data: T) => void,
  pollArray: number[] = POLL_SECONDS,
  enabled: boolean = true,
) {
  // The current index in POLL_SECONDS
  // If pollIndex >= POLL_SECONDS.length we use the last index of POLL_SECONDS.
  const pollIndex = useRef(0);
  const timeoutID = useRef<any>(null);
  const onDataRef = useRef<any>(null);
  const hadError = useRef(false);

  useEffect(() => {
    const stopPolling = () => {
      if (timeoutID.current) {
        clearTimeout(timeoutID.current);
      }
      timeoutID.current = null;
    };

    onDataRef.current = onData;

    // Abort if not enabled
    if (!enabled) {
      stopPolling();
      return;
    }

    const poll = () => {
      const api = new API();
      api
        .get(apiPath)
        .then((response) => {
          if (onDataRef.current) {
            onDataRef.current(response.data);
          }

          // Increment pollIndex after a successful poll but before we queue another poll.
          // Do this here because useEffect unmounts will clear the polling timeout,
          // and we don't want to increase the polling timer until we do a successful poll.
          // WARNING:
          // This code assumes we won't restart polling if there was ever a polling error.
          // We don't want a series of changes in this file to result in throwing API exceptions
          // and not increasing the polling interval. In other words, it's important that
          // we poll less frequently as time goes on to protect the server.
          pollIndex.current++;
          queuePoll();
        })
        .catch((e: any) => {
          // 12/022/2022:
          // If api.get() throws an error, prevent it from bubbling up to the global error handler,
          // which results in unwanted Sentry spam.
          // It's not great that we are stopping polling but this is probably due to a lack of internet connectivity,
          // expiring auth token, or some other event that would cause the user to refresh the browser anyway.
          hadError.current = true;
          // eslint-disable-next-line no-console
          console.log(`usePolling for [${apiPath}] cancelled due to exception:`, e);
          stopPolling();
        });
    };

    const queuePoll = () => {
      if (!enabled || hadError.current) {
        return;
      }

      // The time to wait before polling in milliseconds
      const refetchInterval = pollTime(pollIndex.current, pollArray);

      timeoutID.current = setTimeout(poll, refetchInterval);
    };

    // Start polling
    queuePoll();

    // Stop polling if we unmount
    return stopPolling;
  }, [apiPath, onData, pollArray, enabled]);
}
