import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { AggTable, PickedItemKey, SchemaName, TableID } from 'api/tableAPI';
import { SchemaIconType, SchemaWithIconType } from 'components/primitives/icons/SchemaIcon/SchemaIcon';
import { useBooleanFlag } from 'hooks/useFeatureFlags';

export const isAggTable = (value: any): value is AggTable => {
  return typeof value === 'object' && value !== null && 'full_name' in value;
};

export interface AIAssistantTablePickerState {
  selectedTable: AggTable | null;
  setSelectedTable: Dispatch<SetStateAction<AggTable | null>>;
  pickedItems: Record<PickedItemKey, AggTable | SchemaIconType>;
  pickedTables: Record<TableID, AggTable>;
  pickedSchemas: Record<SchemaName, SchemaIconType>;
  pickTable: (table: AggTable) => void;
  unpickTable: (table: AggTable) => void;
  pickSchema: (schemaWithIconType: SchemaWithIconType) => void;
  unpickSchema: (schema: SchemaName) => void;
  enabled: boolean;
  schemaPickingEnabled: boolean;
  showModal: boolean;
  onOpen: () => void;
  onClearSelection: () => void;
  onClose: () => void;
}

export default function useAIAssistantTablePicker(): AIAssistantTablePickerState {
  const enabled = useBooleanFlag('ai_table_picker');
  const schemaPickingEnabled = useBooleanFlag('ai_table_picker_pick_schemas');
  // Single table that is clicked on to get metadata and sample data
  const [selectedTable, setSelectedTable] = useState<AggTable | null>(null);
  // Multiple tables or schemas that the user has deemed relevant
  // Key is either table id or schema name
  // Value is either AggTable or SchemaIconType
  const [pickedItems, setPickedItems] = useState<Record<string, AggTable | SchemaIconType>>({});

  const [showModal, setShowModal] = useState(false);

  const [pickedTables, pickedSchemas] = useMemo(() => {
    const tables: Record<TableID, AggTable> = {};
    const schemas: Record<SchemaName, SchemaIconType> = {};

    Object.entries(pickedItems).forEach(([key, value]) => {
      if (isAggTable(value)) {
        tables[key] = value;
      } else {
        schemas[key] = value;
      }
    });

    return [tables, schemas];
  }, [pickedItems]);

  const toSegmentProperties = useCallback((table: AggTable) => {
    return { full_name: table.full_name, id: table.id };
  }, []);

  const onOpen = useCallback(() => {
    analytics.track('AIPipelineAssistant OpenTablePicker');
    setShowModal(true);
  }, []);

  const onClearSelection = useCallback(() => {
    analytics.track('AIPipelineAssistant ClearTablePickerSelection');
    setPickedItems({});
  }, []);

  const onClose = useCallback(() => {
    analytics.track('AIPipelineAssistant CloseTablePicker', {
      tables: Object.values(pickedTables).map(toSegmentProperties),
      table_count: Object.keys(pickedTables).length,
      schemas: Object.keys(pickedSchemas),
      schema_count: Object.keys(pickedSchemas).length,
    });
    setShowModal(false);
  }, [pickedTables, pickedSchemas, toSegmentProperties]);

  const pickTable = useCallback(
    (table: AggTable) => {
      analytics.track('AIPipelineAssistant PickTable', toSegmentProperties(table));
      setPickedItems((prevItems) => ({ ...prevItems, [table.id]: table }));
    },
    [toSegmentProperties],
  );

  const unpickTable = useCallback(
    (table: AggTable) => {
      analytics.track('AIPipelineAssistant UnpickTable', toSegmentProperties(table));
      setPickedItems((prevItems) => {
        const newPickedItems = { ...prevItems };
        delete newPickedItems[table.id];
        return newPickedItems;
      });
    },
    [toSegmentProperties],
  );

  const pickSchema = useCallback((schemaWithIconType: SchemaWithIconType) => {
    const { schema, iconType } = schemaWithIconType;
    analytics.track('AIPipelineAssistant PickSchema', { schema });
    setPickedItems((prevItems) => ({ ...prevItems, [schema]: iconType }));
  }, []);

  const unpickSchema = useCallback((schema: SchemaName) => {
    analytics.track('AIPipelineAssistant UnpickSchema', { schema });
    setPickedItems((prevItems) => {
      const newPickedItems = { ...prevItems };
      delete newPickedItems[schema];
      return newPickedItems;
    });
  }, []);

  return {
    selectedTable,
    setSelectedTable,
    pickedItems,
    pickedTables,
    pickedSchemas,
    pickTable,
    unpickTable,
    pickSchema,
    unpickSchema,
    enabled,
    schemaPickingEnabled,
    showModal,
    onOpen,
    onClearSelection,
    onClose,
  };
}
