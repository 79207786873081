import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function CompanyRequire2FAInfoIcon() {
  const content = (
    <div>
      <div>
        <strong>Require that all active users in your company have 2FA set up and enabled.</strong>
      </div>
      <div className="mt-2">This will lock out all users that do not have 2FA set up.</div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} containerClass="ml-1" popoverProps={popoverProps} />;
}
