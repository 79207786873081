import { humanSyncFrequency } from 'model_helpers/connectorHelper';

import {
  ConnectorLinkOrName,
  ObjectDelta,
  UserActionSentencePartProps,
} from './UserActionSentenceParts';

export const ConnectorChangedSyncFreqText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => {
  const { old_value, new_value } = userAction.metadata;

  const oldValue = typeof old_value === 'number' ? humanSyncFrequency(Number(old_value)) : old_value;
  const newValue = typeof new_value === 'number' ? humanSyncFrequency(Number(new_value)) : new_value;
  return (
    <>
      Sync frequency for <ConnectorLinkOrName userAction={userAction} eventLocation={eventLocation} />{' '}
      changed from <ObjectDelta value={oldValue} /> to <ObjectDelta value={newValue} />.
    </>
  );
};

export const ConnectorHistoricalResyncText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => {
  return (
    <>
      <ConnectorLinkOrName userAction={userAction} eventLocation={eventLocation} /> was triggered to{' '}
      <ObjectDelta value="historical resync" />.
    </>
  );
};

export const ConnectorSyncNowText = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  return (
    <>
      <ConnectorLinkOrName userAction={userAction} eventLocation={eventLocation} /> was triggered to{' '}
      <ObjectDelta value="sync now" />.
    </>
  );
};

export const ConnectorChangedSchemaText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => {
  return (
    <>
      <ConnectorLinkOrName userAction={userAction} eventLocation={eventLocation} />{' '}
      <ObjectDelta value="changed schema" />.
    </>
  );
};
