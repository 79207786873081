import React, { useContext, useEffect, useRef, useState } from 'react';

import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import API from 'api/API';
import { DbtRunConfig } from 'api/dbtAPI';
import BackButton from 'components/inputs/basic/Button/BackButton';
import HeaderTabLayout from 'components/layouts/pages/HeaderTabLayout/HeaderTabLayout';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import { TabKeyType } from 'components/layouts/parts/TabRow/TabRow';
import Alert from 'components/widgets/alerts/Alert/Alert';
import HeaderLabel from 'components/widgets/HeaderLabel/HeaderLabel';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import {
  APIPipelinePayload,
  convertPayload,
} from 'pages/tables/ShowTable/PipelineTab/PipelineConverter';
import { Pipeline } from 'pages/tables/ShowTable/PipelineTab/PipelineEditor/PipelineEditor';
import PipelineTab from 'pages/tables/ShowTable/PipelineTab/PipelineTab';
import { capitalize } from 'utils/String';

import DocsTab from './DocsTab/DocsTab';
import RunTab from './RunTab/RunTab';
import SettingsTab from './SettingsTab/SettingsTab';
import UserActionsTab from './UserActionsTab/UserActionsTab';

export const TABS = ['settings', 'runs', 'pipeline', 'user_actions', 'docs'] as const;

type TabKey = (typeof TABS)[number];
const sanitizeTab = (unsafeTab: string | undefined) => {
  const castKey = unsafeTab as TabKey;
  return TABS.includes(castKey) ? castKey : 'settings';
};

interface MatchParams {
  id: string;
  tab: string;
  runID: string;
}

const ShowDBTRunConfig = (props: RouteComponentProps<MatchParams>) => {
  const { id, tab, runID } = useParams<MatchParams>();
  const [currentTab, setCurrentTab] = useState<string>(sanitizeTab(tab));
  const [loadingConfigError, setLoadingConfigError] = useState('');
  const [pipeline, setPipeline] = useState<Pipeline | null>(null);
  const [loadingPipeline, setLoadingPipeline] = useState(true);

  useTitle(`dbt job`);

  const { dbtRunConfigsByID, updateDbtRunConfigs, tablesByID, allLoaded, anyError } =
    useContext(TableModelsContext);
  const updateDbtRunConfigsRef = useRef(updateDbtRunConfigs);
  updateDbtRunConfigsRef.current = updateDbtRunConfigs;
  const runConfig = dbtRunConfigsByID[id];
  const history = useHistory();

  // Changing the tab in the URL path doesn't create a new instance of ShowDBTRunConfig, so navigate to correct tab
  useEffect(() => {
    setCurrentTab(sanitizeTab(tab));
  }, [tab]);

  // When the app loads, it loads all of the dbt run configs.
  // When this page loads, refetch this run config to make sure we have an up to date copy.
  useEffect(() => {
    setLoadingConfigError('');
    const api = new API();

    api
      .get(`api/dbt_run_configurations/${id}`)
      .then((response: any) => {
        const newRunConfig = response.data as DbtRunConfig;
        updateDbtRunConfigsRef.current([newRunConfig]);
      })
      .catch(() => {
        setLoadingConfigError('Failed to load dbt job.');
      });
  }, [id]);

  useEffect(() => {
    if (allLoaded) {
      const api = new API();
      api
        .get(`api/dbt_run_configurations/${id}/lineage`)
        .then((response) => {
          const data = response.data as APIPipelinePayload;
          const pipeline = convertPayload(data, null, tablesByID);
          setPipeline(pipeline);
        })
        .catch(() => {
          setLoadingConfigError('Failed to load dbt job pipeline.');
        })
        .finally(() => {
          setLoadingPipeline(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, allLoaded]);

  const setPath = (tab: string) => {
    let path = `/dbt/jobs/${id}`;
    if (tab !== 'settings') {
      path += `/${tab}`;
    }
    history.replace(path);
  };

  const handleSelectTab = (
    unsafeTab: string | null,
    event?: React.SyntheticEvent<unknown>,
    trackEvent?: string,
  ) => {
    const tab = unsafeTab || 'settings';
    trackEvent = trackEvent || `ShowDBTRunConfig Set${capitalize(tab)}Tab`;
    analytics.track(trackEvent);
    setCurrentTab(tab);
    setPath(tab);
  };

  const tabs: TabKeyType[] = [
    'settings',
    'runs',
    'pipeline',
    'docs',
    { key: 'user_actions', label: 'User Actions' },
  ];

  const handleBackToListRunConfigs = () => {
    analytics.track('ShowDBTRunConfig BackToListDBTRunConfigs');
    history.push('/dbt');
  };

  if (!allLoaded) {
    return <CenteredSpinner containerMinHeight="50vh" />;
  }

  const anyAPIError = anyError || loadingConfigError;
  if (anyAPIError) {
    return (
      <Alert variant="error" className="m-4">
        {anyAPIError}
      </Alert>
    );
  }

  if (!runConfig) {
    return (
      <Alert variant="kinda_bad" className="m-4">
        The given dbt job could not be found.
      </Alert>
    );
  }

  const header = (
    <div className="f-between">
      <div className="f-row-y-center">
        <HeaderLabel label="DBT JOB" />
        <div className="ml-2 text-xl font-medium">{runConfig.name}</div>
      </div>
      <BackButton text="All dbt jobs" onClick={handleBackToListRunConfigs} />
    </div>
  );

  return (
    <HeaderTabLayout
      header={header}
      tabs={tabs}
      currentTab={currentTab}
      onSelectTab={handleSelectTab}
      className="h-full"
    >
      {/* Only render the current tab. */}
      {currentTab === 'runs' && <RunTab runConfig={runConfig} runID={runID} />}
      {currentTab === 'settings' && <SettingsTab runConfig={runConfig} />}
      {currentTab === 'pipeline' && (
        <PipelineTab pipeline={pipeline} loadingPipeline={loadingPipeline} isDbtPipeline={true} />
      )}
      {currentTab === 'docs' && <DocsTab runConfig={runConfig} />}
      {currentTab === 'user_actions' && <UserActionsTab runConfig={runConfig} />}
    </HeaderTabLayout>
  );
};

export default ShowDBTRunConfig;
