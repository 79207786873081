import React, { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

import API from 'api/API';
import { TransformRun, AggTable, Transform } from 'api/APITypes';
import { APIColumn, Column, convertColumns } from 'api/columnAPI';
import { Table, convertTable } from 'api/tableAPI';
import Button from 'components/inputs/basic/Button/Button';
import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';
import { usePoll } from 'hooks/useApi';
import CreateOrReplaceNowInfoIcon from 'pages/tables/ShowTable/RunTab/CreateOrReplaceNowInfoIcon';
import { TransformTable } from 'pages/tables/ShowTable/ShowTable';

import IncrementalRunInfoIcon from './IncrementalRunInfoIcon';
import RebuildRunInfoIcon from './RebuildRunInfoIcon';
import RunHistoryChart from './RunHistoryChart';
import RunHistoryTable from './RunHistoryTable';

export interface PaginatedTransformRuns {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    most_recent_updated_at: string; // This should stay as string, so it's always in backend's timezone
    transform_runs: TransformRun[];
  };
}

interface TransformRunsPollResponse {
  updated_transform_runs: TransformRun[];
  table: Table;
  transform: Transform;
  columns: APIColumn[];
  most_recent_updated_at: string;
}

interface RunTabProps {
  table: TransformTable;
  creatingAs: boolean;
  creatingAsIncremental: boolean;
  setTableAndTransform: (table: AggTable | null, transform: Transform | null) => void;
  setColumns: React.Dispatch<React.SetStateAction<Column[] | null>>;
  onRunCreateAs: (fullBuild?: boolean) => void;
}

export default function RunTab(props: RunTabProps) {
  const { table, creatingAs, creatingAsIncremental, setTableAndTransform, setColumns, onRunCreateAs } =
    props;

  const [paginatedTransformRuns, setPaginatedTransformRuns] = useState<PaginatedTransformRuns | null>(
    null,
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingCancelRun, setLoadingCancelRun] = useState(false);

  const {
    userProfile: { company_role },
  } = useUserProfile();
  const databaseType = useDatabaseAccount().type;

  const pendingRuns = useMemo(() => {
    let resp: TransformRun[] = [];
    if (!!paginatedTransformRuns) {
      resp = paginatedTransformRuns.results.transform_runs.filter(
        (run) => run.state === 'created' || run.state === 'running',
      );
    }
    return resp;
  }, [paginatedTransformRuns]);

  const transformHasPendingRuns = pendingRuns.length > 0;

  let { data: updatedTransformRunsData, error: pollError } = usePoll<TransformRunsPollResponse>(
    `transformRunHistory/${table.transform.id}`,
    `/api/transforms/${table.transform.id}/update_runs?most_recent_updated_at=${paginatedTransformRuns?.results.most_recent_updated_at}`,
    [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 10, 10, 10, 10, 20, 20, 20, 30, 30, 30, 30, 30, 30, 30, 30, 600], // Poll with gradual backoff
    transformHasPendingRuns && !error, // Poll as long as we have pending runs and no errors
  );

  // When we get updatedTransformRuns from the poll, use it to update our run history
  useEffect(() => {
    if (pollError) {
      setError(pollError);
    } else if (updatedTransformRunsData) {
      if (updatedTransformRunsData.updated_transform_runs.length > 0) {
        const mergeUpdatedRuns = (oldRunHistoryResp: PaginatedTransformRuns | null) => {
          if (oldRunHistoryResp !== null && updatedTransformRunsData) {
            return {
              ...oldRunHistoryResp,
              results: {
                most_recent_updated_at: updatedTransformRunsData.most_recent_updated_at,
                transform_runs: _.unionBy(
                  updatedTransformRunsData.updated_transform_runs,
                  oldRunHistoryResp.results.transform_runs,
                  'id',
                ),
              },
            };
          } else {
            return oldRunHistoryResp;
          }
        };
        setPaginatedTransformRuns((r) => mergeUpdatedRuns(r));
      }
      const updatedTable = convertTable(updatedTransformRunsData.table, databaseType);
      const updatedTransform = updatedTransformRunsData.transform;
      setTableAndTransform(updatedTable, updatedTransform);

      // Also update the columns as they may have changed
      // Note: This does not update columns context, which will update itself from polling for changes
      setColumns(convertColumns(updatedTransformRunsData.columns, databaseType));

      // Set the loadingCancelRun state to false here
      // TODO: There might be inconsistencies where a poll is sent before a cancel is requested
      //           but the poll comes back after without the cancelled data.
      setLoadingCancelRun(false);
    }
  }, [updatedTransformRunsData, pollError]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLoadRuns = (url: string | null) => {
    if (url) {
      setLoading(true);
      const api = new API();
      api
        .get(url)
        .then((response) => {
          setPaginatedTransformRuns(response.data);
          analytics.track('RunTab RunsLoaded', {
            run_count: response.data.count,
          });
        })
        .catch((e) => {
          setError('There was a problem fetching transform runs.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!creatingAs) {
      handleLoadRuns(`/api/transform_runs?transform_id=${table.transform.id}`);
    }
  }, [table.transform.id, creatingAs]);

  const handleCancelRun = () => {
    setLoadingCancelRun(true);
    // This loop should only ever handle a single transform run since we only allow one
    //     pending run at a time, however, I'm looping here to be defensive.
    for (let transformRun of pendingRuns) {
      const api = new API();
      api
        .post(`/api/transform_runs/${transformRun.id}/cancel`, {})
        .then((response) => {
          // We let the polling update the transform runs list
        })
        .catch((e) => {
          setError('There was a problem cancelling the run.');
        })
        .finally(() => {
          // Note: we stay in loadingCancelRun state until a poll comes back
        });
    }
  };

  const disableIncrementalButton =
    (table.transform.incremental && creatingAs && !creatingAsIncremental) ||
    transformHasPendingRuns ||
    loading;
  const disbleFullReloadButton =
    (table.transform.incremental && creatingAs && creatingAsIncremental) ||
    transformHasPendingRuns ||
    loading;

  return (
    <TabLayout loading={false} error={''}>
      <div className="flex">
        {company_role !== 'viewer' && (
          <div>
            <h4 className="text-base font-medium text-sec-blue-gray-700">Run Manually</h4>
            <div className="flex items-center mt-2">
              <div className="whitespace-nowrap">
                {table.transform.incremental
                  ? 'Run Transform In Incremental Mode'
                  : 'Create or Replace Transform Table'}
              </div>
              <div className="ml-2 item-center">
                {table.transform.incremental ? (
                  <IncrementalRunInfoIcon transformTable={table.full_name} />
                ) : (
                  <CreateOrReplaceNowInfoIcon transformTable={table.full_name} />
                )}
              </div>
              <Button
                variant="darkDullAction"
                disabled={disableIncrementalButton}
                size="small"
                className="ml-2"
                spinning={creatingAs && !disableIncrementalButton}
                onClick={() => onRunCreateAs(false)}
              >
                Run
              </Button>
            </div>
            {table.transform.incremental && (
              <div className="flex items-center mt-2">
                <div className="whitespace-nowrap">Rebuild Transform Table</div>
                <div className="ml-2 items-center">
                  <RebuildRunInfoIcon transformTable={table.full_name} />
                </div>
                <Button
                  variant="darkDullAction"
                  disabled={disbleFullReloadButton}
                  size="small"
                  className="ml-2"
                  spinning={creatingAs && !disbleFullReloadButton}
                  onClick={() => onRunCreateAs(true)}
                >
                  Rebuild
                </Button>
              </div>
            )}
            {transformHasPendingRuns && (
              <div className="flex items-center mt-2">
                <div className="whitespace-nowrap">
                  Cancel Transform Run{pendingRuns.length > 1 && 's'}
                </div>
                <Button
                  variant="lightDanger"
                  disabled={!transformHasPendingRuns}
                  size="small"
                  className="ml-2"
                  spinning={loadingCancelRun}
                  onClick={handleCancelRun}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        )}
        {paginatedTransformRuns && (
          <div className="w-full mx-[60px]">
            <div style={{ height: '350px' }}>
              <RunHistoryChart runHistory={paginatedTransformRuns.results.transform_runs} />
            </div>
          </div>
        )}
      </div>
      <RunHistoryTable
        table={table}
        paginatedTransformRuns={paginatedTransformRuns}
        error={error}
        loading={loading}
        onLoadRuns={handleLoadRuns}
      />
    </TabLayout>
  );
}
