import { ParsedUrlQueryInput } from 'querystring';
import { format } from 'sql-formatter';
import url from 'url';

/*******************************************************************************
 * TableExplorer
 ******************************************************************************/
export function queryUrl(sql: string, autoRun: boolean, showAIAssistant: boolean) {
  const query: ParsedUrlQueryInput = {};
  if (query) {
    query.query = sql;
  }
  if (autoRun) {
    query.autoRun = true;
  }
  if (showAIAssistant) {
    query.showAIAssistant = true;
  }
  const queryUrl = url.format({
    pathname: '/query',
    query,
  });
  return queryUrl;
}

export function queryTable(fullName: string, autoRun: boolean) {
  const sql = `SELECT * FROM ${fullName}`;
  return queryUrl(sql, autoRun, false);
}

/*******************************************************************************
 * SQL
 ******************************************************************************/
export function formatSql(sql: string, dialect: 'bigquery' | 'snowflake') {
  // sql-formatter has a bug where it adds a space between `=` and `>`
  // sql-formatter has a bug where it adds a space between `|` and `|`
  // Here is the issue: https://github.com/zeroturnaround/sql-formatter/issues/135
  return format(sql, {
    keywordCase: 'upper',
    language: dialect,
  })
    .replaceAll(' = >', ' =>')
    .replaceAll(' | |', ' ||')
    .replaceAll(' & &', ' &&')
    .replaceAll(' ! =', ' !=');
}
