/*
 * Component that renders /query
 */
import React, { useMemo } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import cn from 'classnames';
import qs from 'query-string';

import WorkspaceLayout from 'components/layouts/pages/WorkspaceLayout/WorkspaceLayout';
import MutableTabBar from 'components/layouts/parts/MutableTabBar/MutableTabBar';

import ListQueriesModal from './ListQueriesModal';
import QueryTab from './QueryTab';
import ShareQueryModal from './ShareQueryModal';
import useQueryTabs from './useQueryTabs';

import 'components/query/Query.css';

const QUERY_PAGE_BASE_URL = '/query';

interface MatchParams {
  id?: string;
}

const QueryPage = (props: RouteComponentProps<MatchParams>) => {
  const { id: savedQueryId } = useParams();
  const location = useLocation();

  useTitle('Query');

  // Get sql from the url if it was passed, but not if an ID was also passed
  const newTabSql = useMemo(() => {
    if (!savedQueryId) {
      const parsed = qs.parse(location.search);
      const urlQuery = (parsed?.query as string) ?? null; // empty string is distinct from null
      return urlQuery;
    } else {
      return null;
    }
  }, [location.search, savedQueryId]);

  const autoRun = useMemo(() => {
    const parsed = qs.parse(location.search);
    return parsed?.autoRun === 'true';
  }, [location.search]);

  const autoShowAIAssistant = useMemo(() => {
    const parsed = qs.parse(location.search);
    return parsed?.showAIAssistant === 'true';
  }, [location.search]);

  const {
    selectedTabId,
    openTabs,
    allSavedQueries,
    editTabName,
    autoSavingState,
    creatingNewTab,
    shareQueryModalId,
    listQueriesModalOpen,
    cloningSavedQueryId,
    deletingSavedQueryId,
    addMenuOptions,
    menuOptions,
    handleClickTab,
    handleRemoveTab,
    handleDropTab,
    handleChangeName,
    handleChangeSql,
    handleCloseEditNameMode,
    handleClickNew,
    handleAddSavedQueryClone,
    handleDeleteSavedQuery,
    handleAddSavedQueryToOpenTabs,
    handleCloseShareQueryModal,
    handleCloseListQueriesModal,
    handleRefetchSavedQuery,
    savingFlowchart,
    savingFlowchartError,
    saveFlowchart,
  } = useQueryTabs(QUERY_PAGE_BASE_URL, savedQueryId, newTabSql);

  // If we havent loaded tabs yet, spin
  const spinning = openTabs.length === 0 || allSavedQueries.length === 0;

  return (
    <WorkspaceLayout basePath={QUERY_PAGE_BASE_URL} spinning={spinning}>
      <ListQueriesModal
        allSavedQueries={allSavedQueries}
        cloningSavedQueryId={cloningSavedQueryId}
        deletingSavedQueryId={deletingSavedQueryId}
        show={listQueriesModalOpen}
        onClose={handleCloseListQueriesModal}
        onAddSavedQueryToOpenTabs={handleAddSavedQueryToOpenTabs}
        onAddSavedQueryClone={handleAddSavedQueryClone}
        onDeleteSavedQuery={handleDeleteSavedQuery}
      />
      <div className="w-full px-1 pt-1 bg-pri-gray-200 flex">
        <MutableTabBar
          selectedTabId={selectedTabId}
          tabs={openTabs}
          editTabName={editTabName}
          creatingNewTab={creatingNewTab}
          addMenuOptions={addMenuOptions}
          menuOptions={menuOptions}
          onClickTab={handleClickTab}
          onRemoveTab={handleRemoveTab}
          onDropTab={handleDropTab}
          onChangeName={handleChangeName}
          onCloseEditNameMode={handleCloseEditNameMode}
          onClickNew={handleClickNew}
        />
      </div>
      {openTabs.map((tab) => (
        <div
          key={tab.id}
          // We always render the tab so we don't lose state between tab switches
          // We are using `h-0 w-0 absolute` instead of `hidden` because Safari wasn't repainting between renders
          className={cn(
            'w-full  min-h-0 flex',
            { 'h-0 w-0 absolute': tab.id !== selectedTabId },
            { 'h-full': tab.id === selectedTabId },
          )}
        >
          {shareQueryModalId === tab.id && (
            <ShareQueryModal savedQuery={tab} onClose={handleCloseShareQueryModal} />
          )}
          <QueryTab
            savedQuery={tab}
            autoSavingState={autoSavingState}
            autoRun={autoRun && tab.id === selectedTabId}
            isSelectedTab={tab.id === selectedTabId}
            onChangeSql={handleChangeSql}
            onRefetchSavedQuery={handleRefetchSavedQuery}
            savingFlowchart={savingFlowchart}
            savingFlowchartError={savingFlowchartError}
            saveFlowchart={saveFlowchart}
            autoShowAIAssistant={autoShowAIAssistant}
          />
        </div>
      ))}
    </WorkspaceLayout>
  );
};

export default QueryPage;
