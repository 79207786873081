import { AggTable } from 'api/APITypes';
import { DbtRunConfig } from 'api/dbtAPI';
import {
  getMostFrequentRunConfig,
  calcGenericRunStatus,
  lastRunDate,
} from 'model_helpers/dbtAggTableHelper';

import AbstractTableSorter, {
  SCHEDULE_FREQUENCY,
  LAST_RUN_AGO,
  LAST_RUN_STATUS,
} from './AbstractTableSorter';
import transformSorter from './TransformSorter';

export function scheduleSortValue(runConfig: DbtRunConfig | null) {
  if (!runConfig) {
    return SCHEDULE_FREQUENCY.NOT_APPLICABLE;
  }
  const { scheduled, schedule_mode, schedule } = runConfig;

  if (!scheduled) {
    return SCHEDULE_FREQUENCY.PAUSED_OR_NOT_SCHEDULED;
  }
  if (schedule_mode === 'cron') {
    return transformSorter.approximateScheduleFrequency(schedule);
  }
  if (schedule_mode === 'all_marked') {
    return SCHEDULE_FREQUENCY.AFTER_ALL;
  }

  return SCHEDULE_FREQUENCY.PAUSED_OR_NOT_SCHEDULED;
}

class DbtSorter extends AbstractTableSorter<AggTable> {
  schedule(table: AggTable): number {
    const runConfig = getMostFrequentRunConfig(table);
    return scheduleSortValue(runConfig);
  }

  lastRunAgo(table: AggTable) {
    const mostRecent = lastRunDate(table);
    if (mostRecent) {
      const now = new Date();
      return now.getTime() - mostRecent.getTime();
    }

    return LAST_RUN_AGO.NOT_APPLICABLE;
  }

  runStatus(table: AggTable) {
    const runStatus = calcGenericRunStatus(table);
    return LAST_RUN_STATUS[runStatus];
  }
}

const staticInstance = new DbtSorter();

export default staticInstance;
