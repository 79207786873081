/*
 * Conditionally renders a sidebar.
 *
 * Depending on the route this componet will decide to render
 * the sidebar in spit panes or fixed mode.
 */
import React, { useEffect } from 'react';

import cn from 'classnames';

import {
  HorizontalDraggablePanesState,
  HANDLE_WIDTH_NUM,
} from 'components/layouts/containers/draggable_panes/ControlledDraggablePanes/ControlledDraggablePanes';
import useDraggablePanesState from 'components/layouts/containers/draggable_panes/useDraggablePanesState';
import { dimToPixNum } from 'utils/cssMath';

import FixedSidebarLayout from './FixedSidebarLayout';
import SplitPanesSidebarLayout from './SplitPanesSidebarLayout';
import { PickedSidebar } from './usePickSidebar';

export interface SidebarLayoutProps {
  isWorkspace: boolean;
  children: React.ReactElement;
  showSplitPanesSidebar: boolean;
  showFixedSidebar: boolean;
  sidebarProps: PickedSidebar;
}

export default function SidebarLayout(props: SidebarLayoutProps) {
  const { isWorkspace, children, sidebarProps, showSplitPanesSidebar, showFixedSidebar } = props;
  const { startingWidth = 0, maxWidth = 0 } = sidebarProps || {};

  // There might not be a scrollbar on the first render but there could be one in a future render.
  // Since this value is saved in useState() we have to subtract if off now before
  // the width without a scrollbar is preserved in useState().
  // Doing math with `document.body.scrollWidth` will not work because it might be the same as window.innerWidth
  // on the first render but not subsequent renders.
  // Browser's scroll bars range from 12-17 pixels. Use the widest.
  // This math gurantees the sidebar a minimum width.
  // Let's see how long it is until I regret this.
  const scrollbarWidth = 17;
  const availableWidth = window.innerWidth - scrollbarWidth;
  const leftStartingWidth = availableWidth - HANDLE_WIDTH_NUM - startingWidth;
  const leftMinWidth = availableWidth - HANDLE_WIDTH_NUM - maxWidth;

  // Store the dragged width of the sidebar in a location that is shared by <SplitPlanesSidebarLayout/> and <FixedSidebarLayout/>
  const hookState = useDraggablePanesState(false, leftStartingWidth) as HorizontalDraggablePanesState;

  // The first time this component renders leftStartingWidth is the default value of zero
  // because it takes a render cycle or two to have sidebarProps be set.
  // This causes widths to be set wrong because useState() stores the first value it is passed.
  // So set the correct width when we know it.
  useEffect(() => {
    if (startingWidth !== 0) {
      hookState.setLeftWidth(leftStartingWidth);
    }
  }, [startingWidth, maxWidth]); // eslint-disable-line react-hooks/exhaustive-deps

  // <SplitPlanesSidebarLayout/> does width calculation in terms of the left pane width.
  // <FixedSidebarLayout/> needs us to convert left pane width into right pane width.
  const rightWidth =
    availableWidth - HANDLE_WIDTH_NUM - dimToPixNum(hookState.leftWidth, availableWidth);
  const setRightWidth = (rightWidth: number) => {
    hookState.setLeftWidth(availableWidth - HANDLE_WIDTH_NUM - rightWidth);
  };

  // The FixedSidebarLayout is actually absolute now.
  // We need this div to be relative to position it correctly.
  const className = cn('relative w-full h-full f-col', {
    shrink: isWorkspace,
    'min-h-0': isWorkspace, // This is the secret to making the flex box no include the header height.
    'flex-grow': !isWorkspace,
  });
  return (
    <div id="sidebar-layout" className={className}>
      <SplitPanesSidebarLayout
        isWorkspace={isWorkspace}
        mainContent={children}
        showSidebar={showSplitPanesSidebar}
        sidebarProps={sidebarProps}
        hookState={hookState}
        leftMinWidth={`${leftMinWidth}px`}
      />
      {showFixedSidebar && sidebarProps !== null && (
        <FixedSidebarLayout sidebarProps={sidebarProps} width={rightWidth} setWidth={setRightWidth} />
      )}
    </div>
  );
}
