import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';

const UserRowRow = (ability: string, editorCan: boolean, viewerCan: boolean) => (
  <tr>
    <td>{ability}</td>
    <td>
      <div className="f-center">
        <StatusIcon status="success" />
      </div>
    </td>
    <td>
      <div className="f-center">
        <StatusIcon status={editorCan ? 'success' : 'error'} />
      </div>
    </td>
    <td>
      <div className="f-center">
        <StatusIcon status={viewerCan ? 'success' : 'error'} />
      </div>
    </td>
  </tr>
);

export const UserRoleIconContent = (
  <div>
    <table className="blueGrayHeaderTable">
      <thead>
        <tr>
          <th style={{ width: '70%' }}>Ability</th>
          <th className="text-center" style={{ width: '15%' }}>
            Admins
          </th>
          <th className="text-center" style={{ width: '15%' }}>
            Editors
          </th>
          <th className="text-center" style={{ width: '15%' }}>
            Viewer
          </th>
        </tr>
      </thead>
      <tbody>
        {UserRowRow('View all tables in your warehouse', true, true)}
        {UserRowRow('View all data alerts in your warehouse', true, true)}
        {UserRowRow('View connectors', true, true)}
        {UserRowRow('Create, edit, run, and share saved queries', true, true)}
        {UserRowRow('Generate Snowflake users', true, true)}
        {UserRowRow('View usage', true, false)}
        {UserRowRow('Manage connectors', true, false)}
        {UserRowRow('Manage transforms', true, false)}
        {UserRowRow('Manage data alerts', true, false)}
        {UserRowRow('Generate Snowflake users with any role', false, false)}
        {UserRowRow('Manage Mozart users', false, false)}
        {UserRowRow('Manage company settings', false, false)}
      </tbody>
    </table>
  </div>
);
