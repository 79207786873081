import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from 'components/inputs/basic/Button/Button';
import TextAreaFormikGroup from 'components/inputs/formik_group/TextAreaFormikGroup/TextAreaFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import Alert from 'components/widgets/alerts/Alert/Alert';

import cls from '../SetupPage.module.css';
import { ByobqFormProps } from '../useSetupWarehouse';

interface SetupBYOBQViewProps {
  handleSubmit: (values: ByobqFormProps) => void;
  handleGoBack: () => void;
  isLoading: boolean;
  error?: string;
}

export default function SetupBYOBQView(props: SetupBYOBQViewProps) {
  const { isLoading, handleSubmit, handleGoBack, error } = props;
  const SetupBigQuerySchema = Yup.object().shape({
    project_id: Yup.string().required('Required'),
    credentials: Yup.string().required('Required'),
  });

  return (
    <CenteredContainer
      header={
        <>
          <h1 className="display-sm text-pri-gray-700">
            Follow the instructions to connect your BigQuery data warehouse to Mozart Data
          </h1>
          <Alert variant="info" className="!items-start my-4">
            <p>
              Mozart Data needs access to your BigQuery in order to sync and manage data and transforms.
            </p>
            <p>
              <a
                className="text-sec-blue-gray-500 hover:underline"
                href="https://help.mozartdata.com/docs/bigquery-mozart-data"
                target="_blank"
                rel="noreferrer"
              >
                Help Docs
              </a>
            </p>
          </Alert>
        </>
      }
      loading={false}
      maxWidth="814px"
    >
      <Formik
        initialValues={{ project_id: '', credentials: '' }}
        onSubmit={handleSubmit}
        validationSchema={SetupBigQuerySchema}
      >
        {({ handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <ol>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>
                  Enter the project ID of the Google Cloud Project you want to connect to Mozart Data
                </p>
                <TextFormikGroup
                  autoFocus
                  type="text"
                  disabled={isLoading}
                  className="w-[445px]"
                  name="project_id"
                  placeholder="example_project_id"
                  label={''}
                  groupClass="mt-4"
                />
              </li>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>In that project, enable the following APIs</p>
                <div className="f-col">
                  <code>
                    <a
                      className="hover:underline"
                      href="https://console.developers.google.com/apis/api/bigquery.googleapis.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      BigQuery API
                    </a>
                  </code>
                  <code>
                    <a
                      className="hover:underline"
                      href="https://console.developers.google.com/apis/api/cloudresourcemanager.googleapis.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cloud Resource Manager API
                    </a>
                  </code>
                </div>
              </li>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>
                  In that project, create a service account and grant the following roles
                </p>
                <div className="f-col">
                  {/* If updating this list, read and run one_off_commands/management/commands/generate_bigquery_permissions_to_roles.py */}
                  <code>BigQuery User</code>
                  <code>BigQuery Metadata Viewer</code>
                  <code>BigQuery Resource Viewer</code>
                  <code>BigQuery Data Editor</code>
                </div>
              </li>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>
                  Create a key for that service account and paste the JSON below
                </p>
                <TextAreaFormikGroup
                  disabled={isLoading}
                  className="w-[445px]"
                  name="credentials"
                  placeholder=""
                  label={''}
                  groupClass="mt-4"
                />
              </li>
            </ol>
            <div className="mt-4 flex justify-end">
              <Button onClick={handleGoBack} disabled={isLoading}>
                Back
              </Button>
              <Button
                className="ml-4"
                type="submit"
                disabled={isLoading || !isValid}
                spinning={isLoading}
              >
                Connect
              </Button>
            </div>
            {error && (
              <Alert variant="error" className="mt-4">
                {error}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </CenteredContainer>
  );
}
