/**
 * This file is a light weight experimental API.
 *
 * This file is spiritually very similar to react-query's QueryClient.
 * I briefly looked into using react-query's useQueryClient to use it's query cache instead of my own,
 * I opted not to do that. Wrapping QueryClient's promiseless API in a promise didn't seem worth it at the time.
 * This file is solidly in the "learn by doing" camp.
 */
import { AxiosResponse } from 'axios';

const apiCache: { [key: string]: any } = {};

export default function cachePromise<T>(key: string, fetchMethod: () => Promise<AxiosResponse<any>>) {
  const cacheOrFetchPromise = new Promise<T>((resolve, reject) => {
    // If the key is in the cache, return the cached value.
    const cachedData = apiCache[key];
    if (cachedData) {
      resolve(cachedData);
    }
    // If the key is NOT in the cache, call the fetchMethod.
    else {
      fetchMethod()
        .then((response) => {
          apiCache[key] = response.data;
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });

  return cacheOrFetchPromise;
}
