import React from 'react';

import { useTitle } from 'react-use';

import LinkButton from 'components/inputs/basic/Button/LinkButton';

import sadMozart from '../sadMozart.jpg';

interface Page404Props {
  isAuthenticated: boolean;
}

export default function Page404(props: Page404Props) {
  useTitle('Mozart: Page Not Found');

  return (
    <div className="mt-4 flex flex-col items-center">
      <h1 className="display-sm">Oh no!</h1>
      <img src={sadMozart} alt="sad-mozart-face" className="mt-2" style={{ height: '300px' }} />
      {!props.isAuthenticated && (
        <>
          <p className="text-lg mt-2">This page could not be found.</p>
          <p className="text-lg mt-2">
            If the page exists, you may need to log in or sign up to see it.
          </p>
          <div className="f-row-y-center mt-8">
            <LinkButton to="/login" onClick={() => analytics.track('404 GoToLoginPage')}>
              Log In
            </LinkButton>
            <LinkButton
              to="/signup"
              onClick={() => analytics.track('404 GoToSignupPage')}
              variant="lightDullTransparent"
              className="ml-2"
            >
              Sign Up
            </LinkButton>
          </div>
        </>
      )}
      {props.isAuthenticated && (
        <>
          <p className="text-lg mt-2">This page could not be found.</p>
          <LinkButton to="/" className="mt-12">
            Return To Home
          </LinkButton>
        </>
      )}
    </div>
  );
}
