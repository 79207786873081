import React, { useContext, useReducer, useCallback, useMemo, useState, useRef } from 'react';

import { difference, isEqual, union, unionWith } from 'lodash';

import { AggTable, Favorite, FavoritesByTableID, Tag } from 'api/APITypes';
import { SearchColumnsByTableID } from 'api/searchColumnAPI';
import DraggablePanes from 'components/layouts/containers/draggable_panes/DraggablePanes/DraggablePanes';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import { UserPreferencesContext } from 'model_layer/UserPreferencesContext';

// Use old Reducer to insure contract integrity
import {
  MOZART_ALL,
  MOZART_FAVORITES,
  MOZART_RECENT,
  MOZART_SNAPSHOTS,
  allTablesVirtualSchemaKeys,
  virtualSchemaKey,
} from '../DatabaseSearch2/DatabaseSearchReducer';

import PreviewPan from './PreviewPane/PreviewPane';
import SchemaList from './SchemaList/SchemaList';
import SearchBar from './SearchBar/SearchBar';
import TableTable from './TableTable/TableTable';
import { useFilterDispatches } from './useFilterDispatches';
import { usePreviewPaneData } from './usePreviewPaneData';
import {
  reducer,
  initialState,
  FilteredSchemaMap,
  MOZART_TRANSFORMS,
  virtualSchemaKeyParts,
} from './WarehouseSearchReducer';

const sortExceptionWeight: { [key: string]: number } = {
  MOZART_ALL: 1,
  MOZART_RECENT: 2,
  MOZART_FAVORITES: 3,
  MOZART_TRANSFORMS: 4,
  MOZART_SNAPSHOTS: 5,
};

// Curated schemas and transforms are more important than unmanaged tables.
// So sort them first.
export function getSortedSchemaKeys(
  filteredSchemasMap: FilteredSchemaMap,
  virtualSchemaKeysToSearch: string[],
) {
  // It's disorienting if a schema is selected in the SchemaList and then filtered out by the text search
  // causing it to disappear in the SchemaList.
  // This then becomes more confusing because you don't realize the selected schema is also filtering out tables.
  // Add a schema back in if it got filtered out by the text search.
  // Do not add the virtualSchemaKeysToSearch if there are no tables matching the text search at all.
  const filteredKeys = Object.keys(filteredSchemasMap);
  const unionedKeys = filteredKeys.length > 0 ? unionWith(filteredKeys, virtualSchemaKeysToSearch) : [];

  // Sort the schema keys
  return unionedKeys.sort((s1, s2) => {
    const { virtualSchemaType: vst1, schema: schema1 } = virtualSchemaKeyParts(s1);
    const { virtualSchemaType: vst2, schema: schema2 } = virtualSchemaKeyParts(s2);
    if (sortExceptionWeight[vst1] && sortExceptionWeight[vst2]) {
      return sortExceptionWeight[vst1] - sortExceptionWeight[vst2];
    } else if (sortExceptionWeight[vst1]) {
      return -1;
    } else if (sortExceptionWeight[vst2]) {
      return 1;
    } else {
      return schema1.localeCompare(schema2);
    }
  });
}

// Separate the the schemaKeys into the three sections visible on the Warehouse separated by dividers.
export function partitionSortedSchemaKeys(schemaKeys: string[]) {
  const curatedSchemaKeys: string[] = [];
  const transformSchemaKeys: string[] = [];
  const unmanagedSchemaKeys: string[] = [];

  schemaKeys.forEach((k) => {
    const { virtualSchemaType } = virtualSchemaKeyParts(k);
    if (virtualSchemaType === MOZART_ALL) {
      // Do not add MOZART_ALL to any partition. SchemaList has a special place for it.
    } else if (virtualSchemaType === MOZART_RECENT || virtualSchemaType === MOZART_FAVORITES) {
      curatedSchemaKeys.push(k);
    } else if (virtualSchemaType === MOZART_TRANSFORMS || virtualSchemaType === MOZART_SNAPSHOTS) {
      transformSchemaKeys.push(k);
    } else {
      unmanagedSchemaKeys.push(k);
    }
  });

  return { curatedSchemaKeys, transformSchemaKeys, unmanagedSchemaKeys };
}

interface DatabaseSearchProps {
  allTables: AggTable[];
  recentTables: AggTable[];
  favoriteTables: AggTable[];
  tablesIsLocal: boolean;
  searchColumnsByTableID: SearchColumnsByTableID;
  favoritesByTableID: FavoritesByTableID;
  tags: Tag[];
  hiddenFilter?: string;
  addFavorite: (favorite: Favorite) => void;
  removeFavorite: (favorite: Favorite) => void;
  setSnapshot(table: AggTable, snapshot: boolean): Promise<AggTable>;
  logRecent: (tableID: string) => void;
}

export default function DatabaseSearch(props: DatabaseSearchProps) {
  const {
    allTables,
    recentTables,
    favoriteTables,
    tablesIsLocal,
    searchColumnsByTableID,
    favoritesByTableID,
    tags,
    hiddenFilter,
    addFavorite,
    removeFavorite,
    setSnapshot,
    logRecent,
  } = props;

  const [reducerState, dispatch] = useReducer(reducer, {
    ...initialState,
    maxRecents: 20,
    addAllSchema: true,
    addCuratedSchemas: true,
  });

  const {
    loaded,
    filter,
    virtualSchemaKeysToSearch,
    hideEmptyTables,
    hideViews,
    tableRefMap,
    filteredSchemasMap,
    unfilteredSchemasMap,
    filterIncludes,
    isFiltering,
    filteredTables,
  } = reducerState;

  const { userPreferences, updateUserPreferences } = useContext(UserPreferencesContext);
  const [previewTable, setPreviewTable] = useState<AggTable | null>(null);
  const [maximizePreviewTable, setMaximizePreviewTable] = useState(false);
  const lastSelectedTableRef = useRef<string | null>(null);
  const previewPaneData = usePreviewPaneData(previewTable);

  const { handleToggleHideEmptyTables, handleToggleHideViews, handleFilterChange, handleTagClick } =
    useFilterDispatches(
      dispatch,
      filter,
      hiddenFilter,
      virtualSchemaKeysToSearch,
      hideEmptyTables,
      hideViews,
      allTables,
      recentTables,
      favoriteTables,
      searchColumnsByTableID,
      favoritesByTableID,
      userPreferences,
      updateUserPreferences,
    );

  const {
    sortedSchemaKeys,
    curatedSchemaKeys,
    transformSchemaKeys,
    unmanagedSchemaKeys,
    allSchemaKeys,
  } = useMemo(() => {
    const sortedSchemaKeys = getSortedSchemaKeys(filteredSchemasMap, virtualSchemaKeysToSearch);
    const { curatedSchemaKeys, transformSchemaKeys, unmanagedSchemaKeys } =
      partitionSortedSchemaKeys(sortedSchemaKeys);
    const allSchemaKeys = [...curatedSchemaKeys, ...transformSchemaKeys, ...unmanagedSchemaKeys];
    return {
      sortedSchemaKeys,
      curatedSchemaKeys,
      transformSchemaKeys,
      unmanagedSchemaKeys,
      allSchemaKeys,
    };
  }, [filteredSchemasMap, virtualSchemaKeysToSearch]);

  const useFullName = useMemo(() => {
    let useFullName = true;
    if (filteredTables.length > 0) {
      const firstSchema = filteredTables[0].schema;
      useFullName = filteredTables.some((t) => t.schema !== firstSchema);
    }
    return useFullName;
  }, [filteredTables]);

  const showIcon = useMemo(() => {
    let showIcon = false;

    const getIconType = (table: AggTable) => {
      return table.connector?.service || table.type;
    };

    if (filteredTables.length > 1) {
      const firstType = getIconType(filteredTables[0]);
      showIcon = filteredTables.some((t) => getIconType(t) !== firstType);
    }
    return showIcon;
  }, [filteredTables]);

  /*****************************************************************************
   * Input Callbacks
   ****************************************************************************/
  const handleOpenPreview = useCallback((table: AggTable) => {
    setPreviewTable(table);
    analytics.track('Warehouse OpenPreviewPane');
  }, []);

  const handleClosePreview = useCallback(() => {
    setMaximizePreviewTable(false);
    setPreviewTable(null);
    analytics.track('Warehouse ClosePreviewPane');
  }, []);

  const handleMazimizePreview = useCallback(() => {
    setMaximizePreviewTable(true);
    analytics.track('Warehouse MaximizePreviewPane');
  }, []);

  const handleNormalizePreview = useCallback(() => {
    setMaximizePreviewTable(false);
    analytics.track('Warehouse NormalizePreviewPane');
  }, []);

  const handleClickSchemaKey = useCallback(
    (schemaKey: string, metaKey: boolean, ctrlKey: boolean, shiftKey: boolean) => {
      let newKeys: string[] = [];
      // The union of All Tables plus any combination of virtualSchemaKeysToSearch or inputs is always All Tables.
      if (isEqual([schemaKey], allTablesVirtualSchemaKeys)) {
        newKeys = [schemaKey];
      } else {
        // If the user is holding down the metaKey, add or subtract the current item from the list.
        const editList = metaKey || ctrlKey || (shiftKey && lastSelectedTableRef.current === schemaKey);
        if (editList) {
          if (virtualSchemaKeysToSearch.includes(schemaKey)) {
            newKeys = virtualSchemaKeysToSearch.filter((k) => k !== schemaKey);
          } else {
            newKeys = [...virtualSchemaKeysToSearch, schemaKey];
          }
        }
        // If the user is holding down the shiftKey, add or substract the current range from the list.
        else if (shiftKey && lastSelectedTableRef.current !== null) {
          const lastIndex = allSchemaKeys.findIndex((k) => k === lastSelectedTableRef.current);
          const thisIndex = allSchemaKeys.findIndex((k) => k === schemaKey);
          if (lastIndex >= 0 && thisIndex >= 0) {
            const start = Math.min(lastIndex, thisIndex);
            const end = Math.max(lastIndex, thisIndex) + 1;
            const range = allSchemaKeys.slice(start, end);
            if (range.some((k) => !virtualSchemaKeysToSearch.includes(k))) {
              newKeys = union(virtualSchemaKeysToSearch, range);
            } else {
              newKeys = difference(virtualSchemaKeysToSearch, range);
            }
          }
        }
        // Default to setting the list to the item the user just clicked on
        else {
          newKeys = [schemaKey];
        }
      }
      lastSelectedTableRef.current = schemaKey;
      dispatch({ type: 'SET_VIRTUAL_SCHEMA_KEYS_TO_SEARCH', virtualSchemaKeysToSearch: newKeys });
    },
    [dispatch, virtualSchemaKeysToSearch, allSchemaKeys],
  );

  /*****************************************************************************
   * Calculate Render Variables
   ****************************************************************************/
  // FOOD FOR THOUGHT:
  // We may want counts at some point, and we may want to move this to the reducer
  // As of 07/19/2023, this just shows the Alert to encourage adding a transform.
  // So a dbt table is a transform for this purpose.
  const hasTransformTables = useMemo(() => {
    return allTables.some((t) => t.type === 'transform' || t.type === 'dbt');
  }, [allTables]);

  const hasUnmanagedTables = useMemo(() => {
    return allTables.some((t) => t.type === 'unmanaged');
  }, [allTables]);

  const hasHasOnlyRecents = isEqual(virtualSchemaKeysToSearch, [
    virtualSchemaKey(MOZART_RECENT, MOZART_RECENT),
  ]);

  return (
    <>
      <SearchBar
        filter={filter}
        hideEmptyTables={hideEmptyTables}
        hideViews={hideViews}
        onFilterChange={handleFilterChange}
        onToggleHideEmptyTables={handleToggleHideEmptyTables}
        onToggleHideViews={handleToggleHideViews}
      />
      {loaded /* Prevents UI blinking FixMeAlert */ && (
        <div style={{ position: 'relative', height: 'calc(100% - 78px)' }}>
          {!hasUnmanagedTables && filter === '' && sortedSchemaKeys.length === 0 ? (
            <FixMeAlert
              heading="Looks like your loaded tables are still syncing."
              detail="Your Loaded Tables will appear here after your connector finishes syncing them."
              cta="View Connectors"
              ctaLink="/connectors"
              dataTrack="Warehouse NoUnmanagedGoToConnectors"
            />
          ) : (
            <DraggablePanes
              vertical={true}
              topStartingHeight="40%"
              topMinHeight="20%"
              bottomMinHeight="20%"
              className="bg-pri-gray-200"
            >
              {!maximizePreviewTable && (
                <DraggablePanes
                  leftStartingWidth="20%"
                  leftMinWidth={`145px`}
                  rightMinWidth={`145px`}
                  className="bg-pri-gray-200"
                  hideDivider={true}
                >
                  <SchemaList
                    curatedSchemaKeys={curatedSchemaKeys}
                    transformSchemaKeys={transformSchemaKeys}
                    unmanagedSchemaKeys={unmanagedSchemaKeys}
                    isFiltering={isFiltering}
                    filterIncludes={filterIncludes}
                    virtualSchemaKeysToSearch={virtualSchemaKeysToSearch}
                    filteredSchemasMap={filteredSchemasMap}
                    unfilteredSchemasMap={unfilteredSchemasMap}
                    onClickSchema={handleClickSchemaKey}
                  />
                  <PaddingBox>
                    {sortedSchemaKeys.length === 0 ? (
                      <NoMatchesFoundAlert heading="No matching tables." />
                    ) : filteredTables.length === 0 ? (
                      <NoMatchesFoundAlert
                        heading="No matching tables."
                        detail="Select a different schema to see results."
                      />
                    ) : (
                      <TableTable
                        tables={filteredTables}
                        tableRefMap={tableRefMap}
                        showIcon={showIcon}
                        useFullName={useFullName}
                        usingCache={tablesIsLocal}
                        hasTransformTables={hasTransformTables}
                        hasHasOnlyRecents={hasHasOnlyRecents}
                        favoritesByTableID={favoritesByTableID}
                        recentTables={recentTables}
                        filterIncludes={filterIncludes}
                        tagCount={tags.length}
                        onClickTable={handleOpenPreview}
                        onClickTag={handleTagClick}
                        addFavorite={addFavorite}
                        removeFavorite={removeFavorite}
                        setSnapshot={setSnapshot}
                        logRecent={logRecent}
                      />
                    )}
                  </PaddingBox>
                </DraggablePanes>
              )}
              {previewTable && (
                <PreviewPan
                  table={previewTable}
                  maximized={maximizePreviewTable}
                  loadingSummary={previewPaneData.loadingSummary}
                  loadingError={previewPaneData.loadingError}
                  summaryData={previewPaneData.summaryData}
                  onClose={handleClosePreview}
                  onMaximize={handleMazimizePreview}
                  onNormalize={handleNormalizePreview}
                />
              )}
            </DraggablePanes>
          )}
        </div>
      )}
    </>
  );
}

interface PaddingBoxProps {
  children: React.ReactChild;
}
const PaddingBox = (props: PaddingBoxProps) => {
  return (
    <div className="w-full h-full relative bg-white px-4 pt-4">
      <div className="w-4 h-4 absolute top-[0px] left-[0px] bg-sec-blue-gray-50">
        <div className="w-full h-full rounded-tl-2xl bg-white"></div>
      </div>
      {props.children}
    </div>
  );
};
