// This page allows the user to submit their email to our backend and get an okta domain and client_id
import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';

import { Formik } from 'formik';
import * as Yup from 'yup';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import LinkButton from 'components/inputs/basic/Button/LinkButton';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import LoggedOutFormBackgroundLayout from 'components/layouts/pages/LoggedOutFormBackgroundLayout/LoggedOutFormBackgroundLayout';
import Alert from 'components/widgets/alerts/Alert/Alert';

interface MatchParams {}

interface OktaEmailSubmitProps extends RouteComponentProps<MatchParams> {}

const oktaEmailSubmitSchema = Yup.object().shape({
  email: Yup.string().email().trim().required('Required'),
});

const initialOktaEmailSubmitValue = {
  email: '',
};

const OktaEmailSubmit = (props: OktaEmailSubmitProps) => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  const handleOktaEmailSubmit = (values: any) => {
    const api = new API();
    const postData = oktaEmailSubmitSchema.cast(values);
    setError('');
    setSaving(true);
    api
      .post('api/validate_okta_email', postData)
      .then((response) => {
        const {
          data: { okta_client_id, okta_domain },
        } = response;
        window.location.href = `https://${okta_domain}/oauth2/v1/authorize?client_id=${okta_client_id}&redirect_uri=${window.location.origin}/login&scope=openid+email+profile&state=${postData.email}&response_type=code&response_mode=query`;
      })
      .catch((e) => {
        setError('There was a problem with your request.');
        setSaving(false);
      });
  };

  return (
    <LoggedOutFormBackgroundLayout>
      <div className="w-full h-full flex overflow-auto p-4">
        <div className="w-[444px] m-auto">
          <Helmet>
            <title>Sign In With Okta | Mozart Data</title>
            <meta name="description" content="Sign In With Okta | Mozart Data" />
          </Helmet>
          <Formik
            initialValues={initialOktaEmailSubmitValue}
            onSubmit={(values) => handleOktaEmailSubmit(values)}
            validationSchema={oktaEmailSubmitSchema}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="f-row-y-center mb-6">
                  <img className="h-[28px]" src="/images/logos/MozartDataLogoPurple.svg" alt="" />
                  <div className="mx-4 h-[28px] w-px bg-pri-gray-300" />
                  <img className="h-[38px]" src="/images/logos/oktaLogoWithText.png" alt="" />
                </div>
                {error && (
                  <Alert variant="error" className="mt-4">
                    {error}
                  </Alert>
                )}
                <TextFormikGroup
                  autoFocus
                  name="email"
                  type="email"
                  label="Email With Okta"
                  groupClass="mt-4 w-full"
                  placeholder="wolfgang@mozartdata.com"
                />
                <div className="f-row-y-center mt-4">
                  <Button
                    type="submit"
                    variant="lightAction"
                    className="w-[50%]"
                    spinning={saving}
                    data-track="OktaEmailSubmit Back"
                  >
                    Submit
                  </Button>
                  <LinkButton
                    to="/login"
                    variant="lightDanger"
                    className="text-center ml-4 w-[50%]"
                    data-track="OktaEmailSubmit Back"
                  >
                    Back
                  </LinkButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </LoggedOutFormBackgroundLayout>
  );
};

export default OktaEmailSubmit;
