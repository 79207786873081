import React, { useState } from 'react';

import IconButton from 'components/inputs/basic/Button/IconButton';
import Switch from 'components/inputs/basic/Switch/Switch';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import StatusIcon, { Status } from 'components/primitives/icons/StatusIcon/StatusIcon';
import DateHover from 'components/widgets/time/DateHover/DateHover';
import { useUserProfile } from 'context/AuthContext';
import uph from 'model_helpers/userProfileHelper';

import { GSheetDestination } from '../SyncTabController';

interface GSheetDestinationRowProps {
  destination: GSheetDestination;
  toggleDestination: (destination: GSheetDestination) => Promise<GSheetDestination>;
  editDestination: (destination: GSheetDestination) => void;
  deleteDestination: (destination: GSheetDestination) => Promise<void>;
}

export default function GSheetDestinationRow(props: GSheetDestinationRowProps) {
  const { destination, toggleDestination, editDestination, deleteDestination } = props;
  const {
    id,
    enabled,
    spreadsheet_id,
    spreadsheet_title,
    worksheet_id,
    worksheet_name,
    last_edited_by_user_profile,
    last_synced_at,
    last_sync_status,
    last_sync_error_message,
    order_by,
  } = destination;

  const [toggleEnableLoading, setToggleEnableLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const {
    userProfile: { company_role },
  } = useUserProfile();

  const sheetUrl = `https://docs.google.com/spreadsheets/d/${spreadsheet_id}/edit#gid=${worksheet_id}`;

  const displayOrderBy = order_by === '' ? 'N/A' : order_by;

  const handleConfirmDelete = () => {
    setConfirmDelete(true);
    analytics.track('SyncTab OpenConfirmDelete');
  };

  const handleCancelDelete = () => {
    setConfirmDelete(false);
    analytics.track('SyncTab CancelConfirmDelete');
  };

  const handleDelete = () => {
    setDeleting(true);
    deleteDestination(destination).finally(() => setDeleting(false));
  };

  const handleToggleEnabled = () => {
    setToggleEnableLoading(true);
    toggleDestination(destination).finally(() => {
      setToggleEnableLoading(false);
    });
  };

  const toStatusIconStatus = (status: 'success' | 'failure' | null): Status => {
    if (status === 'success') {
      return 'success';
    }
    if (status === 'failure') {
      return 'error';
    }
    return 'NA';
  };

  return (
    <React.Fragment key={id}>
      <tr>
        <td>
          <a
            href={sheetUrl}
            rel="noopener noreferrer"
            target="_blank"
            className="font-medium text-sm hover:underline"
          >
            {spreadsheet_title}
          </a>
        </td>
        <td>{worksheet_name}</td>
        <td>
          <DateHover date={last_synced_at} />
        </td>
        <td>
          <StatusIcon status={toStatusIconStatus(last_sync_status)} />
        </td>
        <td>{uph.displayName(last_edited_by_user_profile)}</td>
        <td>{displayOrderBy}</td>
        <td>
          <div className="f-center">
            <Switch
              name={`destination-switch-${id}`}
              spinning={toggleEnableLoading}
              checked={enabled}
              onChange={handleToggleEnabled}
              disabled={company_role === 'viewer'}
            />
          </div>
        </td>
        {company_role !== 'viewer' && (
          <td>
            <div className="f-center">
              <IconButton
                icon="PencilSquare"
                variant="lightDullTransparent"
                size="small"
                onClick={() => editDestination(destination)}
              />
              <IconButton
                icon="Trash"
                variant="lightDullTransparent"
                size="small"
                onClick={handleConfirmDelete}
                spinning={deleting}
              />
            </div>
            {confirmDelete && (
              <ConfirmModal
                header={
                  <span>
                    Are you sure you want to delete the sync to <code>{spreadsheet_title}</code>?
                  </span>
                }
                confirmText="Delete"
                confirmVariant="darkDanger"
                onCancel={handleCancelDelete}
                onConfirm={handleDelete}
              />
            )}
          </td>
        )}
      </tr>
      {last_sync_error_message && (
        <tr>
          <td colSpan={8} className="w-full whitespace-pre-line">
            <div className="p-4 bg-pri-gray-50 text-pri-error-700">{last_sync_error_message}</div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}
