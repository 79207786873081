import React, { createContext, useEffect, useState } from 'react';

interface TokenCounts {
  promptTokens: number;
  completionTokens: number;
}

interface OpenAIUsageContextType {
  tokenCounts: TokenCounts;
  setTokenCounts: React.Dispatch<React.SetStateAction<TokenCounts>>;
  model: string;
  setModel: React.Dispatch<React.SetStateAction<string>>;
}

const defaultState = {
  tokenCounts: { promptTokens: 0, completionTokens: 0 },
  setTokenCounts: () => {},
  model: '',
  setModel: () => {},
};

export const OpenAIUsageContext = createContext<OpenAIUsageContextType>(defaultState);

export const OpenAIUsageProvider: React.FC = ({ children }) => {
  const [tokenCounts, setTokenCounts] = useState<TokenCounts>({ promptTokens: 0, completionTokens: 0 });
  const [model, setModel] = useState('');

  useEffect(() => {
    // Retrieve the completion data from localStorage
    const completions = JSON.parse(localStorage.getItem('internal_ide_completions') || '[]');
    // Sum up the total prompt and completion tokens used
    const totalPromptTokens = completions.reduce(
      (acc: number, completion: any) => acc + completion.promptTokens,
      tokenCounts.promptTokens,
    );
    const totalCompletionTokens = completions.reduce(
      (acc: number, completion: any) => acc + completion.completionTokens,
      tokenCounts.completionTokens,
    );
    const modelUsed = completions[0]?.model || 'Unknown';
    setTokenCounts({ promptTokens: totalPromptTokens, completionTokens: totalCompletionTokens });
    setModel(modelUsed);

    localStorage.setItem('internal_ide_completions', JSON.stringify(completions));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OpenAIUsageContext.Provider value={{ tokenCounts, setTokenCounts, model, setModel }}>
      {children}
    </OpenAIUsageContext.Provider>
  );
};
