import { Dashboard } from 'api/dashboardAPI';
import { AggTable } from 'api/tableAPI';
import LinkButton from 'components/inputs/basic/Button/LinkButton';
import { queryTable } from 'components/query/queryUtil';

import { trackDashboard } from '../../reportUtils';

import { PartialConnector } from './TransformTable';

interface TableViewCellProps {
  table: AggTable;
  connector: PartialConnector;
  dashboard: Dashboard;
}

const TableViewCell = (props: TableViewCellProps) => {
  const { table, connector, dashboard } = props;
  const hasRun = table.transform?.last_completed_run?.state === 'success';

  const handleViewData = () => {
    trackDashboard('ReportBuilderShowReport ViewData', connector, dashboard);
  };

  return (
    <td>
      <div className="f-center">
        {hasRun && (
          <LinkButton
            to={queryTable(table.full_name, true)}
            target="_blank"
            onClick={handleViewData}
            size="small"
            variant="lightDullTransparent"
          >
            View Data
          </LinkButton>
        )}
      </div>
    </td>
  );
};

export default TableViewCell;
