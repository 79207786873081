import React from 'react';

import { AggTable } from 'api/tableAPI';
import LinkIconButton from 'components/inputs/basic/Button/LinkIconButton';
import TableStatusIcon from 'components/primitives/icons/TableStatusIcon/TableStatusIcon';
import {
  humanSyncFrequency,
  numRowsAsString,
  lastRunDateAsString,
  lastRunAgo,
} from 'model_helpers/aggTableHelper';
import { connectorPath } from 'utils/PathMaker';

import SidebarDescription from './SidebarDescription';
import VertexTableSection from './VertexTableSection';

interface UnmanagedSidebarContentProps {
  table: AggTable;
}

export default function UnmanagedSidebarContent(props: UnmanagedSidebarContentProps) {
  const { table } = props;
  const connector = table.connector;

  const statRows = [{ label: 'Rows', value: numRowsAsString(table) }];

  const lastSyncRows = [];
  if (connector) {
    lastSyncRows.push(
      { label: 'Frequency', value: humanSyncFrequency(table) },
      { label: 'Status', value: <TableStatusIcon table={table} /> },
      { label: 'Synced At', value: lastRunDateAsString(table) },
      { label: 'Synced Ago', value: lastRunAgo(table) },
    );
  }

  const trackGoToConnector = () => {
    analytics.track('PipelineEditor GoToConnector', {
      table_id: table.id,
      // connector_id is now fivetran_connector_id
      // we can't rename the tracking property since it is legacy and would damage analytics queries
      connector_id: connector?.fivetran_connector_id,
    });
  };

  return (
    <div>
      <SidebarDescription description={table.description} />
      <VertexTableSection title="Table Stats" rows={statRows} className="mt-6" />
      {connector && <VertexTableSection title="Last Sync" rows={lastSyncRows} className="mt-6" />}
      {connector && (
        <LinkIconButton
          icon="BoxArrowUpRight"
          text="GO TO CONNECTOR"
          iconLast={true}
          iconSize={10}
          size="small"
          variant="lightDullTransparent"
          to={connectorPath(connector.id)}
          onClickCapture={trackGoToConnector}
          target="_blank"
          className="mt-6"
        />
      )}
    </div>
  );
}
