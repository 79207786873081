import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import { Formik } from 'formik';
import * as Yup from 'yup';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import LoggedOutFormBackgroundLayout from 'components/layouts/pages/LoggedOutFormBackgroundLayout/LoggedOutFormBackgroundLayout';
import FormLayoutTitleRow from 'components/layouts/parts/FormLayoutTitleRow/FormLayoutTitleRow';
import Alert from 'components/widgets/alerts/Alert/Alert';

const requestResetSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const initialValues = {
  email: '',
};

const RequestReset = () => {
  const [sendSuccess, setSendSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleReset = (values: any) => {
    const transformValues = requestResetSchema.cast(values);
    const api = new API();
    setError('');
    api
      .post('api/request_reset_password', transformValues)
      .then(() => {
        setSendSuccess(true);
        analytics.track('RequestReset Send');
      })
      .catch((error) => {
        if (error.response?.data?.error) {
          setError(error.response.data.error);
        } else {
          setError(
            'Sorry! Something went wrong on our end, so your request could not be sent. Please try again or contact support.',
          );
        }
      });
  };

  return (
    <LoggedOutFormBackgroundLayout>
      <div className="w-full h-full flex overflow-auto p-4">
        <div className="w-[444px] m-auto">
          <Helmet>
            <title>Request password reset | Mozart Data</title>
            <meta name="description" content="Request password reset." />
          </Helmet>
          {sendSuccess ? (
            <Alert variant="no_results">
              If the email address is associated with a Mozart Data account, an email has been sent with
              instructions to reset your password.
            </Alert>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={handleReset}
              validationSchema={requestResetSchema}
            >
              {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <img className="h-[28px] mb-10" src="/images/logos/MozartDataLogoPurple.svg" alt="" />
                  <FormLayoutTitleRow
                    title="Password Reset"
                    linkText="Log In"
                    linkTo="/login"
                    linkDataTrack="RequestReset Login"
                  />
                  <TextFormikGroup
                    groupClass="w-75 mt-4"
                    labelClass="mb-3"
                    autoFocus
                    name="email"
                    label="Email"
                    placeholder="wolfgang@mozartdata.com"
                    type="email"
                  />
                  {error && (
                    <Alert variant="error" className="mt-4">
                      {error}
                    </Alert>
                  )}
                  <Button type="submit" variant="lightAction" className="mt-4">
                    Send reset password email
                  </Button>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </LoggedOutFormBackgroundLayout>
  );
};

export default RequestReset;
