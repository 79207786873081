/*
  Concrete expandos have a HAS-A relationship with TableExplorerGenericExpando.
  Concrete expandos must define ConcreteExpandoProps for TableExplorerGenericExpando.
  
  TableExplorerGenericExpando does the generic expanding and contracting of a "folder-like" item in a FolderExpandoList.
  That folder-like item could be a schema, tag, etc...
*/
import React, { useRef } from 'react';

import cn from 'classnames';

import { AggTable } from 'api/APITypes';
import { TableID } from 'api/tableAPI';

import { DisabledTablesByTableID, PassedToTableRowProps } from '../../TableNameList';
import TableRow from '../../TableRow/TableRow';

import accordion from './Accordion.module.css';

// Props common to the Generic and Concrete expandos
export interface TableExplorerCommonExpandoProps {
  // GenericExpando's props
  isOpen: boolean;
  isFiltering: boolean;
  tables: AggTable[];
  unfilteredCount: number;

  // Stuff GenericExpando uses to lookup TableRow props
  disabledTablesByID: DisabledTablesByTableID; // Used to calculate TableRow.disabled
  selectedTable: AggTable | null; // Used to calculate TableRow.selected
  pickedTables?: Record<TableID, AggTable>; // Used to calculate TableRow.picked

  // Stuff GenericExpando passes to TableRow props
  passedToTableRow: PassedToTableRowProps;
}

// Props a concrete expando must implement
export interface ConcreteExpandoProps {
  // The row that is the title of the folder(ie the stuff that does and does not collapse.)
  // This row should have a click handler on it that controls `isOpen`;
  children: React.ReactNode;
  hideSchemaInTableRow: boolean;
}

interface TableExplorerGenericExpandoProps
  extends TableExplorerCommonExpandoProps,
    ConcreteExpandoProps {}

const TableExplorerGenericExpando = React.memo((props: TableExplorerGenericExpandoProps) => {
  const {
    // My Props
    children,
    isOpen,
    tables,

    // Stuff GenericExpando uses to lookup TableRow props
    disabledTablesByID,
    selectedTable,
    pickedTables,

    // Stuff GenericExpando passes to TableRow props
    hideSchemaInTableRow,
    passedToTableRow,
  } = props;

  // Some schemas have a lot of rows which makes it real wasteful(AKA slow) to
  // render all the rows when the schema is closed on initial load; however,
  // the accordionClose animation doesn't work if the content just disappears.
  // So do not render rows until the schema is expanded, but keep rendered rows
  // and only hide with CSS animation when the schema is collapsed.
  const wasOpenRef = useRef(false);
  const renderOpen = isOpen || wasOpenRef.current;
  if (isOpen) {
    wasOpenRef.current = true;
  }

  return (
    <div>
      {children}
      <div
        className={cn(accordion.accordion, {
          [accordion.accordionOpen]: isOpen,
          [accordion.accordionClosed]: !isOpen,
        })}
      >
        {renderOpen && (
          <>
            {tables.map((table: AggTable) => {
              const disabled = disabledTablesByID[table.id] !== undefined;
              return (
                <TableRow
                  key={table.id}
                  table={table}
                  disabled={disabled}
                  selected={table.id === selectedTable?.id}
                  picked={!!pickedTables?.[table.id]}
                  inFolder={true}
                  hideSchema={hideSchemaInTableRow}
                  {...passedToTableRow}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
});

export default TableExplorerGenericExpando;
