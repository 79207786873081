/**
 * This file used to do more.
 * The hooks that calculated state used to live here.
 * but they had to be hoisted to a common ancestor.
 */
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';

import { FlowchartEditorState } from '../useFlowchartEditor';

import FlowchartEditor from './FlowchartEditor';

export interface SavedFlowchartEditorProps {
  flowchartState: FlowchartEditorState;
}

const SavedFlowchartEditor = (props: SavedFlowchartEditorProps) => {
  const { flowchartState } = props;
  const { objectsForSavedModelLoaded } = flowchartState;
  if (!objectsForSavedModelLoaded) {
    return <CenteredSpinner containerClass="w-full h-full bg-pri-gray-50" />;
  }

  return <FlowchartEditor {...flowchartState} />;
};

export default SavedFlowchartEditor;
