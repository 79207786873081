import { Link } from 'react-router-dom';

import { AggTable } from 'api/APITypes';
import IconButton from 'components/inputs/basic/Button/IconButton';
import HeaderLabel from 'components/widgets/HeaderLabel/HeaderLabel';
import { getHeaderLabel } from 'model_helpers/aggTableHelper';

export const HEADER_ROW_HEIGHT = 34;

interface HeaderRowProps {
  table: AggTable;
  maximized: boolean;
  onClose(): void;
  onMaximize(): void;
  onNormalize(): void;
}

export default function HeaderRow(props: HeaderRowProps) {
  const { table, maximized, onClose, onMaximize, onNormalize } = props;

  const { type } = table;

  let url;
  if (type === 'transform') {
    url = `/tables/${table.id}/transform`;
  } else {
    url = `/tables/${table.id}`;
  }

  const trackOnClick = () => {
    analytics.track('Warehouse ClickPreviewPaneTableLink', {
      table_id: table.id,
      table_type: table.type,
    });
  };

  return (
    <div className="flex justify-between" style={{ height: `${HEADER_ROW_HEIGHT}px` }}>
      <div className="f-row-y-center">
        <HeaderLabel label={getHeaderLabel(table)} />
        <Link
          to={url}
          onClickCapture={trackOnClick}
          className="ml-2 text-lg font-medium hover:underline hover:text-sec-blue-gray-500"
        >
          {table.full_name}
        </Link>
      </div>
      <div className="f-row-y-center">
        <div className="f-center">
          {maximized ? (
            <IconButton
              icon="ArrowBarDown"
              variant="lightDullTransparent"
              onClick={onNormalize}
              size="small"
            />
          ) : (
            <IconButton
              icon="ArrowBarUp"
              variant="lightDullTransparent"
              onClick={onMaximize}
              size="small"
            />
          )}
        </div>
        <div className="ml-2 f-center">
          <IconButton icon="XLg" variant="lightDullTransparent" onClick={onClose} size="small" />
        </div>
      </div>
    </div>
  );
}
