import React from 'react';

import cn from 'classnames';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';

import s from './Switch.module.css';

export interface SwitchProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  checked: boolean;
  id?: string;
  spinning?: boolean;
  containerClass?: string;
  disabled?: boolean;
}

export default function Switch(props: SwitchProps) {
  let { name, checked, id, spinning, className, containerClass, disabled, ...remainingProps } = props;

  const actualContainerClass = cn('f-center', s.container, containerClass);
  const actualClass = cn(
    s.switch,
    'focus:ring-4 focus:ring-offset-0 focus:ring-sec-purple-200',
    className,
  );

  return (
    <div className={actualContainerClass}>
      {spinning ? (
        <CenteredSpinner containerSize="24px" spinnerSize="18px" type="circle" />
      ) : (
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          className={actualClass}
          disabled={disabled}
          {...remainingProps}
        />
      )}
    </div>
  );
}
