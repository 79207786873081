import { useState, useCallback } from 'react';

import { AppConnector } from 'api/APITypes';

import { openConnectCard, connectorStartsPaused } from '../ConnectorUtils';

export type EventLocation = 'SummaryTab' | 'ConnectorHeader';

export interface FivetranConnectorSetupState {
  loading: boolean;
  error: string;
  openConnectCardForConnector: () => void;
  handleCompleteConnector: (eventLocation: EventLocation) => void;
  handleFixConnector: (eventLocation: EventLocation) => void;
  handleEditConnector: (eventLocation: EventLocation) => void;
}

export default function useFivetranConnectorSetup(connector: AppConnector): FivetranConnectorSetupState {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const openConnectCardForConnector = useCallback(async () => {
    openConnectCard({
      mozartConnectorId: connector.id,
      fivetranConnectorId: connector.fivetran_connector_id,
      startsPaused: connectorStartsPaused(connector.service),
      setError,
      setLoading,
    });
  }, [connector]);

  const handleCompleteConnector = useCallback(
    (eventLocation: EventLocation) => {
      analytics.track(`ShowConnector${eventLocation} ContinueSetup`);
      openConnectCardForConnector();
    },
    [openConnectCardForConnector],
  );

  const handleFixConnector = useCallback(
    (eventLocation: EventLocation) => {
      analytics.track(`ShowConnector${eventLocation} FixSetup`);
      openConnectCardForConnector();
    },
    [openConnectCardForConnector],
  );

  const handleEditConnector = useCallback(
    (eventLocation: EventLocation) => {
      analytics.track(`ShowConnector${eventLocation} EditConnector`);
      openConnectCardForConnector();
    },
    [openConnectCardForConnector],
  );

  return {
    loading,
    error,
    openConnectCardForConnector,
    handleCompleteConnector,
    handleFixConnector,
    handleEditConnector,
  };
}
