import { useMemo } from 'react';

import _ from 'lodash';

import { AggTable } from 'api/tableAPI';
import { getMostRecentRunConfig } from 'model_helpers/dbtAggTableHelper';

import { Vertex, Edge, Pipeline } from './PipelineEditor';

export enum ErrorColor {
  Normal = 1,
  NotScheduled = 2,
  NeverRan = 3,
  Missing = 4,
  Warn = 5,
  Danger = 6,
}

export enum VertexShape {
  Transform = 1,
  DBT = 2,
  Snapshot = 3,
  Unmanaged = 4,
  View = 5,
  CSVUpload = 6,
  CurrentVertex = 7,
}

// TODO Rename this to GraphVertex
export interface PipelineVertex extends Vertex {
  errorColor: ErrorColor;
  shape: VertexShape;
}

export type PipelineVertexByID = _.Dictionary<PipelineVertex>;

// TODO Rename this to useGraphVertexConverter
export default function useVertexConverter(pipeline: Pipeline) {
  return useMemo(() => {
    const { vertices, edges, currentTable } = pipeline;

    // Calculate each vertex's ancestors
    const vertexByID = _.keyBy(vertices, 'id');
    const vertexAncestorsByVertexID: { [key: string]: Vertex[] } = {};
    vertices.forEach((v) => {
      const myAncestors = edges
        .filter((e: Edge) => {
          return e.destination === v.id;
        })
        .map((e) => vertexByID[e.source]);
      vertexAncestorsByVertexID[v.id] = myAncestors;
    });

    // Calculate the vertex color for each vertex based on its error state
    // and the error state of it's ancestors.
    const memoizedVertexErrorColors = {};
    const pipelineVertices: PipelineVertex[] = vertices.map((v) => ({
      ...v,
      errorColor: getVertexColor(
        vertexByID,
        vertexAncestorsByVertexID,
        memoizedVertexErrorColors,
        {},
        v,
      ),
      shape: pickShape(v.table, currentTable?.id || null),
    }));
    const pipelineVerticesByID = _.keyBy(pipelineVertices, 'id');
    const pipelineVerticesByTableID: { [key: string]: PipelineVertex } = {};
    pipelineVertices.forEach((v) => {
      if (v.table) {
        pipelineVerticesByTableID[v.table.id] = v;
      }
    });

    // TODO Rename to graphVertices, etc...
    return { pipelineVertices, pipelineVerticesByID, pipelineVerticesByTableID };
  }, [pipeline]);
}

const getVertexColor = (
  vertedByID: _.Dictionary<Vertex>,
  vertexAncestorsByVertexID: { [key: string]: Vertex[] },
  // Stored results of previous calls of getVertexColor()
  memoizedVertexErrorColors: { [key: string]: ErrorColor },
  // To compute an ErrorColor we must first compute the ErrorColors of a node's ancestors.
  // Normally, a pipeline graph looks like some sort of pipeline or tree with a
  // clear set of first ancestors that have no ancestors themselves.
  // If you create a circular linage graph we need to keep track of which nodes
  // we have visited this search so we can stop recursing once we've visited every node in a cycle.
  visitedThisSearch: { [key: string]: boolean },
  v: Vertex,
): ErrorColor => {
  // Record visiting this node so we don't recurse infinitely around a cycle.
  visitedThisSearch[v.id] = true;

  // If we have already have a solution for this vertex, return that.
  const memoizedColor = memoizedVertexErrorColors[v.id];
  if (memoizedColor) {
    return memoizedColor;
  }

  const table = v.table;
  if (v.is_missing || !table) {
    memoizedVertexErrorColors[v.id] = ErrorColor.Missing;
    return ErrorColor.Missing;
  }

  if (table.type === 'transform') {
    const transform = table.transform;
    if (!transform) {
      memoizedVertexErrorColors[v.id] = ErrorColor.Missing;
      return ErrorColor.Missing;
    }
    if (transform.last_completed_run && transform.last_completed_run.state === 'failed') {
      memoizedVertexErrorColors[v.id] = ErrorColor.Danger;
      return ErrorColor.Danger;
    }
    // Dan wants transforms to be listed as NotScheduled if they are NotScheduled AND NeverRan.
    // So, this check is before the NeverRan check.
    if (transform.scheduled === false) {
      memoizedVertexErrorColors[v.id] = ErrorColor.NotScheduled;
      return ErrorColor.NotScheduled;
    }
    if (!transform.last_completed_run) {
      memoizedVertexErrorColors[v.id] = ErrorColor.NeverRan;
      return ErrorColor.NeverRan;
    }
  }

  if (table.type === 'dbt') {
    const runConfig = getMostRecentRunConfig(table);
    if (!runConfig) {
      memoizedVertexErrorColors[v.id] = ErrorColor.Missing;
      return ErrorColor.Missing;
    }
    if (runConfig.last_run && runConfig.last_run.state === 'failed') {
      memoizedVertexErrorColors[v.id] = ErrorColor.Danger;
      return ErrorColor.Danger;
    }
    // Dan wants transforms and therefore runConfigs to be listed as NotScheduled if they are NotScheduled AND NeverRan.
    // So, this check is before the NeverRan check.
    if (runConfig.scheduled === false) {
      memoizedVertexErrorColors[v.id] = ErrorColor.NotScheduled;
      return ErrorColor.NotScheduled;
    }
    if (!runConfig.last_run) {
      memoizedVertexErrorColors[v.id] = ErrorColor.NeverRan;
      return ErrorColor.NeverRan;
    }
  }

  // This node is not directly broken itself.
  // Its ErrorColor depends on its ancestors' ErrorColors.
  // Remove previously visited nodes from ancestors, so we don't infinitely recurse around a cycle.
  const ancestors = vertexAncestorsByVertexID[v.id].filter((a) => !visitedThisSearch[a.id]);
  if (ancestors.length === 0) {
    memoizedVertexErrorColors[v.id] = ErrorColor.Normal;
    return ErrorColor.Normal;
  }

  const ancestorErrorCodes = ancestors.map((a) =>
    getVertexColor(
      vertedByID,
      vertexAncestorsByVertexID,
      memoizedVertexErrorColors,
      visitedThisSearch,
      a,
    ),
  );

  const maxAncestorColor = _.max(ancestorErrorCodes) || ErrorColor.Normal;

  // A descendant's maxErrorColor is Warn.
  const myErrorColor = maxAncestorColor > ErrorColor.Normal ? ErrorColor.Warn : ErrorColor.Normal;
  memoizedVertexErrorColors[v.id] = myErrorColor;
  return myErrorColor;
};

export const pickShape = (table: AggTable | null, currentTableID: string | null) => {
  let shape = VertexShape.Unmanaged;
  if (table) {
    if (table.id === currentTableID) {
      shape = VertexShape.CurrentVertex;
    } else if (table.type === 'transform') {
      shape = VertexShape.Transform;
    } else if (table.type === 'dbt') {
      shape = VertexShape.DBT;
    } else if (table.type === 'snapshot') {
      shape = VertexShape.Snapshot;
    } else if (table.type === 'csv_upload') {
      shape = VertexShape.CSVUpload;
    }
    // The table's shape is primarily determined by the object that created it.
    // Fall back on rendering its status as a view if we have no idea what created it.
    else if (['VIEW', 'SECURE VIEW', 'MATERIALIZED VIEW'].includes(table.database_table_type)) {
      shape = VertexShape.View;
    }
  }
  return shape;
};
