import deepEqual from 'fast-deep-equal';
import { debounce } from 'lodash';

import { SelectedSql, DEFAULT_SELECTED_SQL } from 'components/query/useSqlEditor';

import { EditorSelection, EditorState } from '@codemirror/state';
import { ViewPlugin, ViewUpdate } from '@codemirror/view';

export const stateToSelection = (state: EditorState) => {
  const main = state.selection.main;
  const sql = state.sliceDoc(main.from, main.to);
  if (sql === '') {
    return { ...DEFAULT_SELECTED_SQL };
  }
  const line = state.doc.lineAt(main.from);
  const lineNum = line.number; // Starts at 1
  const columnNum = main.from - line.from; // Starts at 0

  const selectedSql: SelectedSql = {
    sql,
    line: lineNum,
    column: columnNum,
    setByEditor: true,
  };

  return selectedSql;
};

// Check if two selections are equal excluding the property `setByEditor`.
// `stateToSelection` always sets `setByEditor` to true which may be incorrect
// in the context of comparing if this is the same selection.
// So ignore that propery.
const isSelectedSqlEqual = (oldSelection: SelectedSql, newSelection: SelectedSql) => {
  const { setByEditor: newSBE, ...newSelect } = oldSelection;
  const { setByEditor: oldSBE, ...oldSelect } = newSelection;
  return deepEqual(newSelect, oldSelect);
};

/*
  Notifies useQueryEditor state that the user has selected text.
*/
const SelectSqlPlugin = (
  selectedSqlRef: React.MutableRefObject<SelectedSql>,
  setSelectedSql: (selectedSql: SelectedSql) => void,
) =>
  ViewPlugin.fromClass(
    class {
      // This fires every time the user types or moves the cursor with their mouse.
      update(update: ViewUpdate) {
        const selectedSql = selectedSqlRef.current;

        if (update.selectionSet) {
          const newSelection = stateToSelection(update.state);

          // Do not call setSelectSql if the selection is the same.
          if (!isSelectedSqlEqual(selectedSql, newSelection)) {
            // update() fires constantly as user drags mouse so debouce react updates.
            this.debouncedSelectSql(newSelection);
          }
        }

        // THIS IS A HACKY WORKAROUND TO REORDER UPDATES:
        // `useCodeMirror()` applies `extensions` and `onChange` listeners before dispatching a doc update.
        // In the "append and run" schenario this means we need to make sure the doc update was applied
        // before we try to select text that is outside the doc prior to the doc update being applied.
        // FOR FUTURE REFACTORING:
        // The surrounding React UI treats appending SQL and selecting that SQL as two steps that happen in sequence.
        // They should probably be one CodeMirror transaction that React dispatches to CodeMirror.
        // This is basically another manifestation of the should React's state or CodeMirror's state be the source of editor state truth.
        if (update.docChanged) {
          const state = update.state;
          const doc = state.doc;
          // Only select text that was the result of an outside the editor UI click, probably a button in TableExplorer.
          if (selectedSql.setByEditor === false && selectedSql.sql !== '') {
            // Check that the selection from react state isn't the same as the previous selection in CodeMirror.
            const previousEditorSelection = stateToSelection(update.state);
            if (!isSelectedSqlEqual(previousEditorSelection, selectedSql)) {
              const lineText = doc.line(selectedSql.line);
              const anchor = lineText.from;
              const head = lineText.from + selectedSql.sql.length;

              // Make sure the selection is in bounds
              if (doc.length >= head) {
                // We cannot start a new transaction until the current one is resolved.
                setTimeout(() => {
                  const transaction = update.view.state.update({
                    selection: EditorSelection.create([
                      EditorSelection.range(anchor, head),
                      EditorSelection.cursor(head),
                    ]),
                  });
                  update.view.dispatch(transaction);
                });
              }
            }
          }
        }
      }

      debouncedSelectSql = debounce((selectedSql: SelectedSql) => {
        setSelectedSql(selectedSql);
      }, 60);

      destroy() {
        this.debouncedSelectSql.cancel();
      }
    },
  );

export default SelectSqlPlugin;
