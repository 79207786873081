/**
 * Listboxes are for clicking on a member of a short predefined list.
 */
import React from 'react';

import { ChevronDown, Check } from 'react-bootstrap-icons';

import cn from 'classnames';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';

import { Listbox as HeadlessUiListbox } from '@headlessui/react';

export type ListboxValue = string | number | null;
export type ListboxSize = 'small' | 'medium' | 'large';

export interface ListboxOption {
  label: React.ReactNode;
  value: string | number | null;
  isDivider?: boolean;
  disabled?: boolean;
}

export interface ListboxProps {
  value: ListboxValue;
  label?: React.ReactNode;
  options: ListboxOption[];
  size?: ListboxSize;
  hasError?: boolean;
  spinning?: boolean;
  // There are two variants of Listbox, form and gray.
  // If variant is undefined, render the form variant.
  variant?: 'form' | 'gray' | 'white';
  // How wide should the spinner, input, and option list be?
  widthClass?: string;
  // The outermost class under any circumstance. Use for margin.
  containerClass?: string;
  // If we want to limit the max height of the options dropdown
  optionsMaxHeightClass?: string;
  // The class of the actual button.
  buttonClass?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange: (newVal: ListboxValue) => void;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export default function Listbox(props: ListboxProps) {
  let {
    value,
    label,
    options,
    size,
    hasError,
    spinning,
    variant,
    widthClass,
    containerClass,
    optionsMaxHeightClass,
    buttonClass,
    autoFocus,
    disabled,
    onChange,
    onClick,
  } = props;

  // Sanitize optional props
  variant = variant || 'form';
  size = size || 'large';
  hasError = hasError === true;
  widthClass = widthClass || 'w-full';
  optionsMaxHeightClass = optionsMaxHeightClass || 'max-h-[400px]';

  // Pick sizeClass
  let sizeClass = 'h-[46px]';
  if (size === 'medium') {
    sizeClass = 'h-[36px]';
  } else if (size === 'small') {
    sizeClass = 'h-[28px]';
  }

  if (spinning) {
    const allVariantsClass = `
      f-center
      rounded
    `;
    const formClass = `
      bg-pri-gray-200
    `;
    const grayClass = `
      bg-pri-gray-100
    `;
    const whiteClass = `
      bg-pri-gray-50
    `;
    let spinnerClass = formClass;
    if (variant === 'gray') {
      spinnerClass = grayClass;
    } else if (variant === 'white') {
      spinnerClass = whiteClass;
    }
    const spinnerSize = size !== 'large' ? '18px' : '24px';
    return (
      <div className={cn(allVariantsClass, sizeClass, spinnerClass, widthClass, containerClass)}>
        <CenteredSpinner
          containerSize="24px"
          spinnerSize={spinnerSize}
          type="circle"
          containerClass="mx-4"
        />
      </div>
    );
  }

  // If a label was not passed in, calculate it from the selected value and options
  let displayLabel = label;
  if (label === undefined && options) {
    displayLabel = options.find((o) => o.value === value)?.label;
  }

  const buttonSharedClass = 'relative w-full text-left rounded focus:outline-none';
  const buttonSharedClassNotDisabled = 'cursor-pointer';

  // Define sizeClasses
  const largeClass = 'h-[42px] pl-2 pr-[32px] text-base';
  const mediumClass = 'h-[36px] pl-3 pr-[32px] text-sm';
  const smallClass = 'h-[28px] pl-3 pr-[32px] text-sm';

  // Pick sizeClass
  sizeClass = largeClass;
  let verticalPaddingClass = 'py-2'; // variant === gray has thicker borders so this messes up vertical padding math which centers the text
  if (size === 'medium') {
    sizeClass = mediumClass;
    verticalPaddingClass = variant === 'form' || variant === 'white' ? 'py-[7px]' : 'py-[6px]';
  } else if (size === 'small') {
    sizeClass = smallClass;
    verticalPaddingClass = variant === 'form' || variant === 'white' ? 'py-[3px]' : 'py-[2px]';
  }

  // Define variant classes
  const formClass = `
  border
  text-pri-gray-700
  focus:ring-2
  `; // Does not overlap with disabled class
  const formNoError = 'border-pri-gray-300 focus:border-purple focus:ring-sec-purple-200';
  const formError = 'border-pri-error-700 focus:border-pri-error-700 focus:ring-pri-error-200';
  const formDisabled = 'border text-pri-gray-400 cursor-default focus:ring-2';

  const grayClass = `
  border-2
  text-sec-blue-gray-700
  bg-pri-gray-100
  font-medium
  `; // Does not overlap with disabled class
  const grayNoError = 'border-pri-gray-100 focus:border-sec-blue-gray-300 focus:ring-0';
  const grayError = 'border-pri-error-700 focus:border-pri-error-700 focus:ring-0';
  const grayDisabled = 'border-2 font-medium text-sec-blue-gray-400 bg-pri-gray-100 cursor-default';

  const whiteClass = `
  bg-white
  font-medium
  `; // Does not overlap with disabled class
  const whiteNoError = 'border-pri-gray-100 focus:border-sec-blue-gray-300 focus:ring-0';
  const whiteError = 'border border-pri-error-700 focus:border-pri-error-700 focus:ring-0';
  const whiteDisabled = 'font-medium text-pri-gray-400 bg-pri-gray-50 cursor-default';

  // Pick variant classes
  let defaultVariantClass = formClass;
  let variantNoErrorClass = formNoError;
  let variantErrorClass = formError;
  let variantDisabledClass = formDisabled;
  if (variant === 'gray') {
    defaultVariantClass = grayClass;
    variantNoErrorClass = grayNoError;
    variantErrorClass = grayError;
    variantDisabledClass = grayDisabled;
  }
  if (variant === 'white') {
    defaultVariantClass = whiteClass;
    variantNoErrorClass = whiteNoError;
    variantErrorClass = whiteError;
    variantDisabledClass = whiteDisabled;
  }
  const errorStatusClass = hasError ? variantErrorClass : variantNoErrorClass;

  const actualButtonClass = cn(
    buttonSharedClass,
    sizeClass,
    verticalPaddingClass,
    errorStatusClass,
    widthClass,
    buttonClass,
    {
      [buttonSharedClassNotDisabled]: !disabled,
      [defaultVariantClass]: !disabled,
      [variantDisabledClass]: disabled,
    },
  );
  const optionsClass = cn(
    'absolute z-[101] mt-1 overflow-auto rounded-[6px] bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
    widthClass,
    optionsMaxHeightClass,
  );

  const myBlur = (event: React.FocusEvent<HTMLButtonElement>, open: boolean) => {
    if (props.onBlur && !open) {
      props.onBlur(event);
    }
  };

  const onClickProps = { onClick };

  return (
    <HeadlessUiListbox
      disabled={disabled}
      as="div"
      value={value}
      onChange={onChange}
      className={containerClass}
    >
      {({ open }) => (
        <div className={cn('relative', widthClass)} {...onClickProps}>
          <HeadlessUiListbox.Button
            className={actualButtonClass}
            onBlur={(event: React.FocusEvent<HTMLButtonElement>) => myBlur(event, open)}
            autoFocus={autoFocus}
          >
            <span className="block truncate">{displayLabel}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDown size={16} color="var(--pri-gray-700)" />
            </span>
          </HeadlessUiListbox.Button>

          <HeadlessUiListbox.Options className={optionsClass}>
            {options &&
              options.map((o) => {
                return o?.isDivider ? (
                  <hr key={o.value} />
                ) : (
                  <HeadlessUiListbox.Option
                    key={o.value}
                    className={({ active }) => {
                      return cn('relative h-[32px] flex items-center select-none py-2 pl-10 pr-4', {
                        'bg-pri-gray-100 font-medium': active,
                        'cursor-default text-pri-gray-400': o.disabled,
                        'cursor-pointer': !o.disabled,
                      });
                    }}
                    value={o.value}
                    disabled={o.disabled}
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {o.label}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <Check size={24} color="var(--pri-gray-700)" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </HeadlessUiListbox.Option>
                );
              })}
          </HeadlessUiListbox.Options>
        </div>
      )}
    </HeadlessUiListbox>
  );
}
