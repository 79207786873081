/*
This hook manages all the state you would pass into ControlledDraggablePanes.
Most of the time you would use DraggablePanes because it manages state for you.
This hook is useful when the parent of ControlledDraggablePanes needs to control
the height or widths of the panes.
*/
import { useRef, useState } from 'react';

import { CSSSize } from 'utils/cssMath';

import useComponentSize from '@rehooks/component-size';

import {
  HorizontalDraggablePanesState,
  VerticalDraggablePanesState,
} from './ControlledDraggablePanes/ControlledDraggablePanes';

export default function useDraggablePanesState(
  vertical: boolean,
  firstChildStartingSize: CSSSize,
  containerRef?: React.MutableRefObject<null>,
) {
  // This state applies in horizontal mode.
  const [leftWidth, setLeftWidth] = useState<CSSSize>(firstChildStartingSize);
  // This state applies in vertical mode.
  const [topHeight, setTopHeight] = useState<CSSSize>(firstChildStartingSize);

  const defaultContainerRef = useRef(null);
  const usedContainerRef = containerRef || defaultContainerRef;
  const containerSize = useComponentSize(usedContainerRef);

  if (vertical) {
    return {
      topHeight,
      setTopHeight,
      containerRef: usedContainerRef,
      containerSize,
    } as VerticalDraggablePanesState;
  }

  return {
    leftWidth,
    setLeftWidth,
    containerRef: usedContainerRef,
    containerSize,
  } as HorizontalDraggablePanesState;
}
