import React, { useState, useCallback } from 'react';

import _ from 'lodash';

import IconButton from 'components/inputs/basic/Button/IconButton';
import { useUserProfile } from 'context/AuthContext';
import { CREATE_AS_TYPE_LABEL_MAP } from 'pages/tables/ShowTable/SummaryTab/SummaryTab';
import { displayCron } from 'utils/cron';

import RunOptionsLabel from './RunOptionLabel';
import { keyErrorMessage } from './RunOptionsModal/CreateAsTab';
import RunOptionsModal, { SetRunOptionProps } from './RunOptionsModal/RunOptionsModal';

export interface RunOptionsProps extends SetRunOptionProps {}

const scheduleModeOptionsMap: { [key: string]: string } = {
  cron: 'At Specified Time',
  all_marked: 'After All Marked Ancestors',
  any_marked: 'After Any Marked Ancestors',
};

export default function RunOptions(props: RunOptionsProps) {
  const { table, vertexDependencies, incrementalUpdateKeys, loadingUpdateKeys } = props;
  const {
    scheduled,
    schedule,
    schedule_mode,
    incremental,
    incremental_update_key,
    auto_rebuild_on_change,
    create_as,
    primary_key,
    cluster_by,
    statement_timeout_seconds,
  } = table.transform;
  const [showModal, setShowModal] = useState(false);
  const { userProfile } = useUserProfile();

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
    analytics.track('TransformTab OpenRunOptionsModal');
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    analytics.track('TransformTab CloseRunOptionsModal');
  }, []);

  const incrementalKey = incremental_update_key ? `Key: ${incremental_update_key}` : 'Key: None';
  const autoRebuild = auto_rebuild_on_change ? 'AUTO' : '';

  let scheduleLeftValue =
    schedule_mode === 'cron' ? displayCron(schedule) : scheduleModeOptionsMap[schedule_mode];
  if (!scheduled) {
    scheduleLeftValue = 'Off';
  }

  const anyAncestorsMarked = _.some(vertexDependencies, (e) => e.is_scheduling_dependency === true);
  const invalidSchedule =
    scheduled &&
    (schedule_mode === 'all_marked' || schedule_mode === 'any_marked') &&
    !anyAncestorsMarked;

  return (
    <div>
      <div className="f-row-y-center">
        <div className="flex items-center">
          <RunOptionsLabel
            label="Schedule"
            leftValue={scheduleLeftValue}
            rightValue=""
            warning={invalidSchedule}
          />
          {incremental && (
            <RunOptionsLabel
              label="Incremental"
              leftValue={incrementalKey}
              rightValue={autoRebuild}
              warning={
                !loadingUpdateKeys &&
                !!incremental_update_key &&
                !incrementalUpdateKeys?.includes(incremental_update_key)
              }
              className="ml-4"
            />
          )}
          <RunOptionsLabel
            label="Create As"
            leftValue={CREATE_AS_TYPE_LABEL_MAP[create_as]}
            rightValue=""
            className="ml-4"
          />
          {primary_key && (
            <RunOptionsLabel
              label="Primary Key"
              leftValue={primary_key}
              rightValue=""
              className="ml-4"
              warning={!loadingUpdateKeys && !!keyErrorMessage(primary_key, incrementalUpdateKeys)}
            />
          )}
          {cluster_by && (
            <RunOptionsLabel
              label="Cluster By"
              leftValue={cluster_by}
              rightValue=""
              className="ml-4"
              warning={!loadingUpdateKeys && !!keyErrorMessage(cluster_by, incrementalUpdateKeys)}
            />
          )}
          {statement_timeout_seconds && (
            <RunOptionsLabel
              label="Statement Timeout (sec)"
              leftValue={statement_timeout_seconds.toString()}
              rightValue=""
              className="ml-4"
            />
          )}
          {userProfile.company_role !== 'viewer' && (
            <IconButton
              icon="PencilSquare"
              size="small"
              variant="lightDullTransparent"
              onClick={handleOpenModal}
              className=""
            />
          )}
        </div>
      </div>
      {showModal && <RunOptionsModal {...props} onClose={handleCloseModal} />}
    </div>
  );
}
