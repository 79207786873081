import { useContext, useMemo } from 'react';

import { Connector } from 'api/APITypes';
import { TableModelsContext } from 'model_layer/TableModelsContext';

import { PathCommand } from './usePathCommands';

export interface ConnectorCommand extends PathCommand {
  connector: Connector;
}

export default function useConnectorCommands(
  isOpen: boolean,
  openPathCommand: (this: PathCommand, modKeyPressed: boolean) => boolean,
) {
  const { connectors, allLoaded, anyError } = useContext(TableModelsContext);

  const connectorCommands = useMemo<ConnectorCommand[]>(() => {
    if (isOpen && allLoaded && !anyError) {
      return connectors.map<ConnectorCommand>((connector) => ({
        name: connector.schema,
        path: `/connectors/${connector.id}`,
        type: 'connector',
        connector,
        command: openPathCommand,
      }));
    } else {
      return [];
    }
  }, [isOpen, allLoaded, anyError, connectors, openPathCommand]);

  return connectorCommands;
}
