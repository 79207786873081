import RCPPalette from 'react-command-palette';

import Mousetrap from 'mousetrap';

import { isMac } from 'utils/browser';

import { TableCompoundCommandType } from './commands/useTableCommands';

/*******************************************************************************
 * Override RCPPalette with some custom behavior so we can make Compound Commands.
 *
 * A Compound Command consist of:
 * 1A. The first command appends to the `commandPath`.
 * 1B. One the next render, a useEffect() changes the list of available commands
 *     based on the commandPath.
 * 2.  The second command does a normal command and resets the CommandPalette.
 ******************************************************************************/
export type CommandType =
  | 'list_help' // Compound Command: List all help pages, then go to the selected help page.
  | 'help' // Open help page.
  | 'path' // Navigate to path in app.
  | 'table' // Go to table's summary tab.
  | 'connector' // Go to connector's summary tab.
  | 'dbt_job' // Go to dbt job's settings tab.
  | TableCompoundCommandType
  | 'query_tables' // Compound Command: List tables that can be queried.
  | 'query_table'; // Compound Command: Go to query page and autorun querying this table.

export interface Command {
  name: string;
  type: CommandType;
  hotKeys?: string;
  command: (holdingMod: boolean) => boolean; // Return true if the CommandPalette should close after executing the command.
}

export default class MozartCommandPalette extends RCPPalette<any, any> {
  componentDidMount() {
    const { hotKeys, open, display } = this.props;

    this.setState({
      suggestions: [],
    });

    // Mousetrap ignores input fields by default.
    // We want a always open the command palette
    // because many windows open with the search input in focus.
    const originalStopCallback = Mousetrap.prototype.stopCallback;
    Mousetrap.prototype.stopCallback = function (
      e: Mousetrap.ExtendedKeyboardEvent,
      element: Element,
      combo: string,
    ) {
      if (combo === 'mod+p') {
        return false;
      }
      return originalStopCallback(e, element, combo);
    };

    // Use hot key to open command palette
    Mousetrap.bind(hotKeys, () => {
      this.handleOpenModal();
      // prevent default which opens Chrome dev tools command palatte
      return false;
    });

    if (open) return this.handleOpenModal();

    // because there's no modal when using inline the input should be focused
    if (display === 'inline') return this.focusInput();
    return true;
  }

  onSuggestionSelected(event: any, selected: { suggestion: Command }) {
    const { suggestion } = selected;
    if (typeof suggestion.command === 'function') {
      const holdingMod = isMac() ? event.metaKey : event.ctrlKey;
      const closeAfterCommand = suggestion.command(holdingMod);
      if (closeAfterCommand) {
        this.handleCloseModal(true);
      } else {
        this.setState({
          value: '',
        });
      }
    } else {
      throw new Error('command must be a function');
    }
  }

  handleOpenModal() {
    // @ts-ignore
    super.handleOpenModal();
    // Performance Optimization:
    // Tell parent we are open so it can do any expensive calculations it deferred.
    this.props.setIsOpen(true);
  }

  // If we are in a compound command, undo the compound command.
  // Otherwise, close.
  handleCloseModal(forceClose: boolean | undefined) {
    const { commandPath, backOneCommand, resetCommandPath } = this.props;
    if (forceClose !== true && commandPath.length > 0) {
      backOneCommand();
    } else {
      this.setState({
        showModal: false,
        isLoading: false,
      });
      this.props.setIsOpen(false);
      resetCommandPath();
    }
  }

  focusInput() {
    this.commandPaletteInput.input.focus();
    // MOZART: KNOCK OUT KEY BINDING.
    // There is already a key binding on the modal and this causes
    // handleCloseModal() to be called twice in a row,
    // which we do not want.

    // FIXME: apply "esc" on the modal instead of input
    // so that pressing esc on loading spinner works too
    // const { hotKeys } = this.props;
    // Mousetrap(this.commandPaletteInput.input).bind(
    //   ["esc"].concat(hotKeys),
    //   ()=> {
    //     this.handleCloseModal();
    //   }
    // );
  }
}
