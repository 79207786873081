import { useHistory, useLocation } from 'react-router';

import queryString from 'query-string';

import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import TextInput from 'components/inputs/basic/TextInput/TextInput';
import useTrackFilter from 'hooks/useTrackFilter';
import { useSearchFocus } from 'utils/React';

export type Filters = {
  search: string;
  status: string;
};

interface ListConnectorFiltersProps {
  values: Filters;
  onChange: (name: string, value: string) => void;
}

const ListConnectorFilters = (props: ListConnectorFiltersProps) => {
  const { values, onChange } = props;
  const reportOnBlur = useTrackFilter('ListConnectors', values.search);
  const [searchFilterRef] = useSearchFocus();
  const location = useLocation();
  const history = useHistory();

  /**
   * Update query string in URL using `history.replace()`
   * @param paramName Name of query string parameter to update
   * @param newFilter New value for filter
   * @param defaultValue Default value for filter. Used to determine when to remove query string
   * parameter from URL.
   *
   * TODO(homepage-v1): This util the same as in `useFilterDataAlerts.ts` and behaves similarly to the
   * one in `useSetSearchURL.ts`. Refactor to avoid duplicate.
   */
  const updateQueryString = (paramName: string, newFilter: string, defaultValue: string = '') => {
    const searchParams = queryString.parse(location.search);
    const filteredSearchParams = Object.entries(searchParams).reduce((acc, [key]) => {
      if (key !== paramName) {
        return { ...acc, [key]: searchParams[key] };
      }
      return acc;
    }, {});

    const newSearchParams = {
      ...filteredSearchParams,
      // Only add the param if it is not the default value (e.g. empty string)
      ...(newFilter === defaultValue ? {} : { [paramName]: newFilter }),
    };
    const newSearch = queryString.stringify(newSearchParams);
    history.replace({ search: newSearch });
  };

  const handleSearchFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange('search', event.target.value);
    updateQueryString('search', event.target.value);
  };

  const handleStatusFilterChange = (value: ListboxValue) => {
    onChange('status', value as string);
    updateQueryString('status', value as string, 'all');
    analytics.track('ListConnectors SetStatusFilter', { value });
  };

  return (
    <form className="f-row-y-center flex-wrap gap-4">
      <TextInput
        name="search"
        placeholder="Search"
        aria-label="Search connectors"
        value={values.search}
        onChange={handleSearchFilterChange}
        onBlur={reportOnBlur}
        ref={searchFilterRef}
        autoFocus
        className="w-[275px]"
        maxLength={200}
      />
      <Listbox
        value={values.status}
        onChange={handleStatusFilterChange}
        options={[
          { label: 'All Statuses', value: 'all' },
          { label: 'Succeeded', value: 'success' },
          { label: 'Failed', value: 'error' },
          { label: 'Paused', value: 'paused' },
          { label: 'Syncing', value: 'syncing' },
          { label: 'Setup Broken', value: 'broken' },
          { label: 'Setup Incomplete', value: 'incomplete' },
        ]}
        containerClass="w-[200px]"
        size="large"
      />
    </form>
  );
};

export default ListConnectorFilters;
