import { isEqual } from 'lodash';
import url from 'url';

import { UK_ALL, vskToUrlKey } from 'pages/Warehouse/DatabaseSearch2/urlSchema';

export function warehouseSearch(searchInput: string, virtualSchemaKeysToSearch?: string[]) {
  return warehousePathSearch('/warehouse', searchInput, virtualSchemaKeysToSearch);
}

export function warehousePathSearch(
  pathname: string,
  searchInput: string,
  virtualSchemaKeysToSearch?: string[],
) {
  const query: { filter?: string; schemas?: string } = {};
  if (searchInput) {
    query.filter = searchInput;
  }
  if (virtualSchemaKeysToSearch && virtualSchemaKeysToSearch.length) {
    const urlSchemaKeys = virtualSchemaKeysToSearch.map((vsk) => vskToUrlKey(vsk));
    // Do not write the default set of schemas to the URL.
    // That is implicit when the schemas property is not set.
    if (!isEqual(urlSchemaKeys, [UK_ALL])) {
      query.schemas = urlSchemaKeys.join(',');
    }
  }
  const warehousePath = url.format({
    pathname,
    query,
  });
  return warehousePath;
}

export function warehouseTagSearch(tagName: string) {
  const escapedName = tagName.includes(' ') ? `"${tagName}"` : tagName;
  return warehouseSearch(`tag:${escapedName}`);
}

export function tablePath(tableID: string, tab?: string, query?: any) {
  let objectPath = `/tables/${tableID}`;
  if (tab) {
    objectPath += `/${tab}`;
  }
  return url.format({
    pathname: objectPath,
    query,
  });
}

export function connectorPath(connectorID: string) {
  return `/connectors/${connectorID}`;
}

export function dbtRunConfigurationPath(dbtRunConfigurationID: string) {
  return url.format({
    pathname: `/dbt/jobs/${dbtRunConfigurationID}`,
  });
}

export function sqlDiffPath(tableID: string, versionID: string) {
  return tablePath(tableID, 'versionHistory', { version_id: versionID });
}
