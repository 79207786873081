/*
  Wraps ComboboxGroup and injects the Formik hook props into the input group.
*/
import React from 'react';

import { useField } from 'formik';

import ComboboxGroup, { ComboboxGroupProps } from 'components/inputs/group/ComboboxGroup/ComboboxGroup';

// We get value from Formik
export type SanitizedComboboxGroupProps = Omit<ComboboxGroupProps, 'value' | 'onChange'>;

export interface ComboboxFormikGroupProps extends SanitizedComboboxGroupProps {
  onChange?: (newVal: string) => void;
}

export default function ComboboxFormikGroup(props: ComboboxFormikGroupProps) {
  const { name, onChange, ...rest } = props;
  const [field, meta, helpers] = useField(name);
  const { name: fieldName, value: fieldValue, ...fieldRest } = field;

  // I could not get field.onBlur() to work because this is a button not a form element with a name.
  const onBlur = () => {
    helpers.setTouched(true, true);
  };

  // Convert Combobox change to one Formik change.
  const actualOnChange = onChange
    ? onChange
    : (newVal: string) => {
        helpers.setValue(newVal);
      };

  return (
    <ComboboxGroup
      name={name}
      value={meta.value}
      error={meta.touched && meta.error ? meta.error : ''}
      {...fieldRest}
      {...rest}
      onBlur={onBlur}
      onChange={actualOnChange}
    />
  );
}
