import React, { useState, useCallback, useEffect } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import Button from 'components/inputs/basic/Button/Button';
import ButtonGroup from 'components/inputs/basic/Button/ButtonGroup';
import ButtonMenu from 'components/inputs/basic/Button/ButtonMenu';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import { useUserProfile } from 'context/AuthContext';

import VersionDescriptionModal from './VersionDescriptionModal';

export interface EditButtonsProps {
  isTransform: boolean;
  editMode: boolean;
  editorSql: string;
  savedSql: string;
  eventPrefix: string;
  saving: boolean;
  lastRunSql: string;
  setEditMode: (editMode: boolean) => void;
  setEditorSql: (sql: string, byUser: boolean) => void;
  clearLastRun: () => void;
  onSaveSql: (versionDescription?: string) => Promise<string> | undefined;
  setUnsavedSql: (unsaved: boolean) => void;
}

export default function EditButtons(props: EditButtonsProps) {
  const {
    isTransform,
    editMode,
    editorSql,
    savedSql,
    eventPrefix,
    saving,
    lastRunSql,
    setEditMode,
    setEditorSql,
    clearLastRun,
    onSaveSql,
    setUnsavedSql,
  } = props;

  const [confirmDiscard, setConfirmDiscard] = useState(false); // true = Show the discard changes modal
  const [showVersionDescriptionModal, setShowVersionDescriptionModal] = useState(false);

  const { userProfile } = useUserProfile();

  const isSaved = editorSql === savedSql;

  useEffect(() => {
    setUnsavedSql(!isSaved);
  }, [isSaved, setUnsavedSql]);

  const handleDiscardAttempted = useCallback(() => {
    if (!isSaved) {
      analytics.track(`${eventPrefix} EditDiscardAttempted`);
      setConfirmDiscard(true);
    } else {
      analytics.track(`${eventPrefix} EditDiscarded`);
      setEditMode(savedSql === ''); // Use edit mode when saved SQL is blank
    }
  }, [isSaved, savedSql, eventPrefix, setEditMode]);

  const handleSaveSql = useCallback(
    (eventVerb: 'Saved' | 'SavedWithVersionDescription', versionDescription?: string) => {
      if (saving || isSaved) {
        return;
      }

      const savePromise = onSaveSql(versionDescription);
      if (savePromise) {
        savePromise
          .then((newSql) => {
            analytics.track(`${eventPrefix} ${eventVerb}`);
            setEditMode(newSql === ''); // Use edit mode when saved SQL is blank

            // The server does not have to save the exact same sql the frontend tried to save.
            // For example, it strips off leading and trailing white spaces.
            // To ensure consistency, set the editor to the SQL the server returned.
            setEditorSql(newSql, false);

            // Clear the run results if we saved sql that is different than the
            // sql used in the last run
            if (newSql !== lastRunSql) {
              clearLastRun();
            }
            setShowVersionDescriptionModal(false);
            return newSql;
          })
          .catch(() => {
            // Silently swallow error here. Error message is set in onSaveSql
          });
      }
    },
    [saving, isSaved, onSaveSql, eventPrefix, setEditMode, setEditorSql, lastRunSql, clearLastRun],
  );

  const handleEditSql = useCallback(() => {
    analytics.track(`${eventPrefix} Edit`);
    setEditMode(true);
  }, [eventPrefix, setEditMode]);

  const handleDiscardCancelled = () => {
    analytics.track(`${eventPrefix} EditDiscardCancelled`);
    setConfirmDiscard(false);
  };

  const handleDiscardConfirmed = () => {
    analytics.track(`${eventPrefix} EditDiscarded`);
    setEditMode(savedSql === ''); // Use edit mode when saved SQL is blank
    setConfirmDiscard(false);
    setEditorSql(savedSql, true);
  };

  const handleOpenVersionDescriptionModal = () => {
    analytics.track(`${eventPrefix} OpenVersionDescriptionModal`);
    setShowVersionDescriptionModal(true);
  };

  const handleCloseVersionDescriptionModal = () => {
    analytics.track(`${eventPrefix} CloseVersionDescriptionModal`);
    setShowVersionDescriptionModal(false);
  };

  const handleSaveWithVersionDescription = (versionDescription: string) => {
    handleSaveSql('SavedWithVersionDescription', versionDescription);
  };

  const handleSaveWithoutVersionDescription = useCallback(() => {
    handleSaveSql('Saved');
  }, [handleSaveSql]);

  useHotkeys(
    'ctrl+e, ⌘+e',
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (!editMode) {
        handleEditSql();
      }
    },
    { enableOnTags: ['TEXTAREA', 'INPUT'], enableOnContentEditable: true },
    [handleEditSql],
  );

  useHotkeys(
    'ctrl+s, ⌘+s',
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      handleSaveWithoutVersionDescription();
    },
    { enableOnTags: ['TEXTAREA', 'INPUT'], enableOnContentEditable: true },
    [onSaveSql],
  );

  useHotkeys(
    'ctrl+Escape, ⌘+Escape',
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      handleDiscardAttempted();
    },
    { enableOnTags: ['TEXTAREA', 'INPUT'], enableOnContentEditable: true },
    [handleDiscardAttempted],
  );

  const showDropdownButton = !isSaved && isTransform;

  const saveButton = (
    <Button
      size="small"
      variant="save"
      className={isSaved ? '' : 'ml-2'}
      spinning={saving}
      disabled={isSaved}
      onClick={handleSaveWithoutVersionDescription}
    >
      {isSaved ? 'Saved' : 'Save'}
    </Button>
  );

  return (
    <div className="f-row-y-center">
      {confirmDiscard && (
        <ConfirmModal
          header="Discard changes?"
          confirmText="Discard"
          confirmVariant="darkDanger"
          onCancel={handleDiscardCancelled}
          onConfirm={handleDiscardConfirmed}
        />
      )}
      {showVersionDescriptionModal && (
        <VersionDescriptionModal
          saving={saving}
          onClose={handleCloseVersionDescriptionModal}
          onSaveWithVersionDescription={handleSaveWithVersionDescription}
        />
      )}
      {!editMode && userProfile.company_role !== 'viewer' && (
        <Button size="small" variant="darkDullAction" onClick={handleEditSql}>
          Edit SQL
        </Button>
      )}
      {editMode && (
        <>
          {!isSaved && (
            <Button size="small" variant="darkDanger" disabled={saving} onClick={handleDiscardAttempted}>
              Discard Changes
            </Button>
          )}
          {showDropdownButton && (
            <ButtonGroup variant="darkDullAction">
              {saveButton}
              <ButtonMenu
                size="small"
                variant="save"
                dropdownOptions={[
                  { label: 'Save with Description', onClick: handleOpenVersionDescriptionModal },
                ]}
              />
            </ButtonGroup>
          )}
          {!showDropdownButton && saveButton}
        </>
      )}
    </div>
  );
}
