import React from 'react';

import IconButton from 'components/inputs/basic/Button/IconButton';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';

import { pillFontColor } from '../TagPill';

import ColorPicker from './ColorPicker';

export interface ColorInputProps {
  color: string;
  showColorPicker: boolean;
  error?: string;
  colorPickerTarget: React.RefObject<HTMLButtonElement>;
  className?: string;
  onOpenColorPicker(event: React.MouseEvent<HTMLButtonElement>): void;
  onColorPickerSetColor(color: string): void;
  onColorChange(event: React.ChangeEvent<HTMLInputElement>): void;
  setShowColorPicker: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ColorInput(props: ColorInputProps) {
  const {
    color,
    showColorPicker,
    error,
    colorPickerTarget,
    className,
    onOpenColorPicker,
    onColorPickerSetColor,
    onColorChange,
    setShowColorPicker,
  } = props;

  const buttonStyle = {
    width: '42px',
    height: '42px',
    padding: '0',
    borderRadius: '3px 0px 0px 3px',
    // Minimal attempt to make this look like the text input adjacent to it.
    // It's not perfect as far as hovers and what nots is concerned.
    borderColor: 'var(--pri-gray-300)',
    borderRight: 'none',
    // The user set color
    backgroundColor: color,
  };

  return (
    <div className={className}>
      <div className="f-row-y-center">
        <IconButton
          icon="PaletteFill"
          iconColor={pillFontColor(color)}
          variant="lightDullTransparent"
          size="large"
          ref={colorPickerTarget}
          onClick={onOpenColorPicker}
          style={buttonStyle}
        />
        <TextFormikGroup
          name="color"
          placeholder="#FF0000"
          onChange={onColorChange}
          error={undefined}
          maxLength={7}
          style={{
            width: 'unset',
            borderRadius: '0px 3px 3px 0px',
          }}
          onFocus={() => setShowColorPicker(true)}
        />
      </div>
      <div className="mt-1 text-sm text-pri-error-700" style={{ display: error ? 'block' : 'none' }}>
        {error}
      </div>
      <ColorPicker
        targetRef={colorPickerTarget}
        show={showColorPicker}
        closePopover={() => setShowColorPicker(false)}
        onPickColor={onColorPickerSetColor}
      />
    </div>
  );
}
