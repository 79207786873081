import React from 'react';

import { AggTable } from 'api/APITypes';
import { calcGenericRunStatus as connectorCalcGenericRunStatus } from 'model_helpers/connectorHelper';
import { calcGenericRunStatus as csvCalcGenericRunStatus } from 'model_helpers/csvUploadHelper';
import { calcGenericRunStatus as dbtCalcGenericRunStatus } from 'model_helpers/dbtAggTableHelper';

import StatusIcon from '../StatusIcon/StatusIcon';
import TransformRunIcon from '../TransformRunIcon/TransformRunIcon';

export interface TableStatusIconProps {
  table: AggTable;
}

export default function TableStatusIcon(props: TableStatusIconProps) {
  const { table } = props;
  if (table.transform) {
    return <TransformRunIcon run={table.transform.last_completed_run || null} />;
  }

  if (table.connector) {
    return <StatusIcon status={connectorCalcGenericRunStatus(table.connector)} />;
  }

  if (table.type === 'snapshot') {
    // Pretend snapshots never fail.
    return <StatusIcon status="success" />;
  }

  if (table.type === 'dbt') {
    return <StatusIcon status={dbtCalcGenericRunStatus(table)} />;
  }

  if (table.csvUpload) {
    return <StatusIcon status={csvCalcGenericRunStatus(table.csvUpload)} />;
  }

  return <StatusIcon status="NA" />;
}
