import React from 'react';

import { format } from 'date-fns';

import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import { parseApiDate } from 'utils/dateTime';

interface MonthRangeInputProps {
  value: string; // YYYY-MM-DD
  months: string[]; // Array of YYYY-MM-DD
  className?: string;
  invalid?: boolean;
  onSetDate: (date: string) => void;
}

const MONTH_YEAR_FORMAT = 'MMM, yyyy';

export default function MonthRangeInput(props: MonthRangeInputProps) {
  const { value, months, className, invalid, onSetDate } = props;

  return (
    <span className={className}>
      <Listbox
        value={value}
        onChange={(date: ListboxValue) => {
          onSetDate(date as string);
        }}
        variant="gray"
        size="medium"
        widthClass="w-[145px]"
        hasError={invalid}
        options={months.map((m) => {
          return { label: format(parseApiDate(m) as Date, MONTH_YEAR_FORMAT), value: m };
        })}
      />
    </span>
  );
}
