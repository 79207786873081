import { useState } from 'react';

import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import { Connector } from 'api/APITypes';
import dashboardAPI, { Dashboard } from 'api/dashboardAPI';
import executeFnWithSignedInGapi from 'utils/gapi';

import dashboardCache from '../dashboardCache';
import { setupCompletePath } from '../pathUtils';
import { trackDashboard } from '../reportUtils';

import AuthorizeGSheetView from './AuthorizeGSheetView';

interface AuthorizeGSheetControllerProps {
  connector: Connector;
  dashboard: Dashboard;
}

export default function AuthorizeGSheetController(props: AuthorizeGSheetControllerProps) {
  useTitle('Authorize Google Sheet');
  const { connector, dashboard } = props;
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleAuthorize = () => {
    if (saving) {
      return;
    }
    setSaving(true);

    trackDashboard('ReportBuilderAuthorizeGSheet AttemptAuthorizeStarted', connector, dashboard);

    // This requires being signed into gapi, so we build this function to be wrapped with the gapi signIn flow
    const updateDashboardWithGapiToken = (gapi_access_token: string) => {
      dashboardAPI
        .patch(dashboard.id, { gapi_access_token })
        .then((dashboard) => {
          dashboardCache.update(queryClient, dashboard);
          trackDashboard('ReportBuilderAuthorizeGSheet AttemptAuthorizeSucceeded', connector, dashboard);
          navigate(setupCompletePath(connector));
        })
        .catch((error) => {
          setSaving(false);
          setError('There was a problem saving the access token.');
          trackDashboard(
            'ReportBuilderAuthorizeGSheet AttemptAuthorizeFailedOnAPI',
            connector,
            dashboard,
          );
        });
    };

    const gSheetErrorFn = () => {
      setSaving(false);
      setError('Google Sheets Authentication Error');
      trackDashboard('ReportBuilderAuthorizeGSheet AttemptAuthorizeFailedOnGAPI', connector, dashboard);
    };

    // Do the google auth flow to get permission to add a gsheet on the user's account
    executeFnWithSignedInGapi(updateDashboardWithGapiToken, gSheetErrorFn);
  };

  return (
    <AuthorizeGSheetView
      connector={connector}
      saving={saving}
      error={error}
      onAuthorize={handleAuthorize}
    />
  );
}
