import { useState } from 'react';

import API from 'api/API';
import { AppConnector } from 'api/APITypes';

import { LocalSyncingState } from './useLocalSyncingState';

export type Scope = { [schemaName: string]: string[] };

export interface HistoricalResyncState {
  spinning: boolean;
  error: string;
  disabled: boolean;
  showHistoricalResyncModal: boolean;
  openHistoricalResyncModal: (newScope?: Scope) => void;
  closeHistoricalResyncModal: () => void;
  scope: Scope | undefined;
  performHistoricalResync: () => void;
}

export default function useHistoricalResyncState(
  connector: AppConnector,
  localSyncState: LocalSyncingState,
  resetPolling: () => void,
  editMode: boolean,
  isViewer: boolean,
): HistoricalResyncState {
  const [error, setError] = useState('');
  const [showHistoricalResyncModal, setShowHistoricalResyncModal] = useState(false);
  const [awaitingManualSyncStart, setAwaitingManualSyncStart] = useState(false);
  const [scope, setScope] = useState<Scope | undefined>(undefined);

  const disabled =
    connector.setupState === 'incomplete' || !!connector.disablingError || editMode || isViewer;

  const { syncState } = connector;
  const spinning = syncState === 'syncing' || awaitingManualSyncStart || localSyncState.awaitingSync;

  const localSyncKey = `historicalResync-${connector.fivetran_connector_id}`;

  if (syncState === 'syncing' && awaitingManualSyncStart) {
    setAwaitingManualSyncStart(false);
    localSyncState.setNotAwaitingSync(localSyncKey);
  }

  const openHistoricalResyncModal = (newScope?: Scope) => {
    analytics.track('ShowConnectorSummaryTab OpenHistoricalResyncModal');
    setScope(newScope);
    setShowHistoricalResyncModal(true);
  };

  const closeHistoricalResyncModal = () => {
    analytics.track('ShowConnectorSummaryTab CloseHistoricalResyncModal');
    setShowHistoricalResyncModal(false);
  };

  const performHistoricalResync = async () => {
    const api = new API();
    setError('');
    setAwaitingManualSyncStart(true);
    localSyncState.setAwaitingSync(localSyncKey);

    const postData = scope ? { scope: scope } : {};

    try {
      await api.post(
        `api/fivetran/connector/${connector.fivetran_connector_id}/historical_resync`,
        postData,
      );
      resetPolling();
      analytics.track('ShowConnectorSummaryTab StartHistoricalResync');
    } catch (_e) {
      setError('There was a problem syncing the connector');
      setAwaitingManualSyncStart(false);
      localSyncState.setNotAwaitingSync(localSyncKey);
    } finally {
      setShowHistoricalResyncModal(false);
    }
  };

  return {
    spinning,
    error,
    disabled,
    showHistoricalResyncModal,
    openHistoricalResyncModal,
    closeHistoricalResyncModal,
    performHistoricalResync,
    scope,
  };
}
