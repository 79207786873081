import { Fragment } from 'react';

import cn from 'classnames';

import Hotkey, { HotkeySize, HotkeyVariant } from '../Hotkey/Hotkey';

interface HotkeysProps {
  hotkeys: string; // Examples: "Enter", "⌘+Enter", "g,w"
  size?: HotkeySize;
  variant?: HotkeyVariant;
}

export default function Hotkeys(props: HotkeysProps) {
  const { hotkeys, size, variant } = props;
  return (
    <div className="inline-block">
      <div className="f-row-y-center">
        {hotkeys.split(',').map((combo, i) => (
          <Fragment key={combo + i}>
            {i > 0 && <span>&nbsp;</span>}
            {combo.split('+').map((comboPart, j) => (
              <Fragment key={comboPart + j}>
                {j > 0 && (
                  <span
                    className={cn({
                      'text-sm': size === 'small',
                      'text-pri-gray-500': variant === 'dull',
                    })}
                  >
                    &nbsp;+&nbsp;
                  </span>
                )}
                <Hotkey size={size} variant={variant}>
                  {comboPart}
                </Hotkey>
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
