import React, { useState } from 'react';

import { Experiments, defaultExperiments } from 'Experiments';
import { forEach } from 'lodash';

import { trySetItem } from 'api/CacheKeys';

export type { Experiments };

/*******************************************************************************
 * Functions for saving experiments in localStorage
 ******************************************************************************/
const EXPERIMENT_KEY = 'experiments';

const loadExperiments = () => {
  let actualExperiments = defaultExperiments;

  const serializeExperiments = window.localStorage.getItem(EXPERIMENT_KEY);
  if (serializeExperiments) {
    const previouslySavedExperiments = JSON.parse(serializeExperiments);
    const stillValidExperiments: { [key: string]: boolean | string[] } = {};
    const validKeys = Object.keys(defaultExperiments);
    forEach(previouslySavedExperiments, (v, k) => {
      if (validKeys.includes(k)) {
        stillValidExperiments[k] = v;
      }
    });
    actualExperiments = { ...defaultExperiments, ...stillValidExperiments };
  }

  return actualExperiments;
};

const saveExperiments = (experiments: Experiments) => {
  trySetItem(EXPERIMENT_KEY, experiments);
};

/*******************************************************************************
 * Context and Providers
 ******************************************************************************/
export interface ExperimentContextProps {
  experiments: Experiments;
  saveExperiments: (experiments: Experiments) => void;
}

const loadedExperiments = loadExperiments();
const ExperimentContext = React.createContext<ExperimentContextProps>({
  experiments: loadedExperiments,
  saveExperiments: () => {},
});
ExperimentContext.displayName = 'ExperimentContext';

const ExperimentProvider = (props: React.PropsWithChildren<{}>) => {
  const [experiments, setExperiments] = useState<Experiments>(loadedExperiments);

  const saveAndSetExperiments = (experiments: Experiments) => {
    saveExperiments(experiments);
    setExperiments(experiments);
  };

  const context = {
    experiments,
    saveExperiments: saveAndSetExperiments,
  };
  return <ExperimentContext.Provider value={context} {...props} />;
};

function useExperiments() {
  const context = React.useContext(ExperimentContext);
  if (context === undefined) {
    throw new Error('useExperiments must be used within a ExperimentContext');
  }
  return context;
}

export { loadedExperiments, ExperimentContext, ExperimentProvider, useExperiments };
