import React, { useState, useMemo, useEffect } from 'react';

import Button from 'components/inputs/basic/Button/Button';
import IconButton from 'components/inputs/basic/Button/IconButton';
import CodeEditor, { editorHeight } from 'components/inputs/basic/CodeEditor/CodeEditor';
import TextInput from 'components/inputs/basic/TextInput/TextInput';
import ConfirmDeleteModal from 'components/layouts/containers/modals/ConfirmDeleteModal/ConfirmDeleteModal';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Popover from 'components/overlay/Popover/Popover';
import PopperTrigger from 'components/overlay/PopperTrigger/PopperTrigger';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import SortableTable from 'components/tables/SortableTable/SortableTable';
import StackingBubbles from 'components/widgets/StackingBubbles/StackingBubbles';
import { useUserProfile } from 'context/AuthContext';
import { SavedQuery } from 'pages/Query/useQueryTabs';
import { formatAgoTime, parseMillisecondsAgo } from 'utils/dateTime';

const EDITOR_THEME_OVERRIDES = {
  '.cm-editor': {
    border: '3px solid blue !important',
    overflow: 'hidden',
    backgrounColor: 'transparent',
  },

  '.cm-scroller, .cm-gutters': {
    maxHeight: '48vh',
    backgroundColor: '#f3f2f3',
    overflow: 'auto',
  },
};

interface ListQueriesModalProps {
  allSavedQueries: SavedQuery[];
  cloningSavedQueryId: string;
  deletingSavedQueryId: string;
  show: boolean;
  onClose(): void;
  onAddSavedQueryToOpenTabs(savedQuery: SavedQuery): void;
  onAddSavedQueryClone(savedQuery: SavedQuery): void;
  onDeleteSavedQuery(savedQuery: SavedQuery): void;
}

export default function ListQueriesModal(props: ListQueriesModalProps) {
  const {
    allSavedQueries,
    cloningSavedQueryId,
    deletingSavedQueryId,
    show,
    onClose,
    onAddSavedQueryToOpenTabs,
    onAddSavedQueryClone,
    onDeleteSavedQuery,
  } = props;
  const [searchValue, setSearchValue] = useState('');
  const [shareQueryOpenId, setShareQueryOpenId] = useState('');
  const [copiedLink, setCopiedLink] = useState(false);
  const [showConfirmModalSavedQueryId, setShowConfirmModalSavedQueryId] = useState('');

  const { userProfile } = useUserProfile();

  useEffect(() => {
    copiedLink &&
      setTimeout(() => {
        setCopiedLink(!copiedLink);
      }, 1000);
  }, [copiedLink]);

  useEffect(() => {
    // close any open share rows when the modal closes
    if (!show) {
      setShareQueryOpenId('');
    }
  }, [show]);

  const handleCopyLink = (saveQueryId: string) => {
    const url = `${window.location.origin}/query/${saveQueryId}`;
    navigator.clipboard.writeText(url);
    setCopiedLink(!copiedLink);
    analytics.track('ListQueriesModal CopySavedQueryLink');
  };

  const handleOpenShareQuery = (savedQueryId: string) => {
    setShareQueryOpenId(savedQueryId);
    analytics.track('ListQueriesModal OpenSavedQuery');
  };

  const handleCloseShareQuery = () => {
    setShareQueryOpenId('');
    analytics.track('ListQueriesModal CloseSavedQuery');
  };

  const handleOpenConfirmModal = (savedQueryId: string) => {
    setShowConfirmModalSavedQueryId(savedQueryId);
    analytics.track('ListQueriesModal OpenConfirmModal');
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModalSavedQueryId('');
    analytics.track('ListQueriesModal CloseConfirmModal');
  };

  const handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const filteredSavedQueries = useMemo(() => {
    // Filter by names or sql
    return allSavedQueries.filter(
      (sq) =>
        sq.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        sq.sql.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [searchValue, allSavedQueries]);

  const showConfirmDeleteModalSavedQuery = useMemo(
    () => allSavedQueries.find((sq) => sq.id === showConfirmModalSavedQueryId),
    [allSavedQueries, showConfirmModalSavedQueryId],
  );

  const showConfirmModalSavedQueryHasOneSubscriber =
    showConfirmDeleteModalSavedQuery?.saved_query_to_user_profiles.length === 1;

  const dataRowRenderingFunc = (savedQuery: SavedQuery) => {
    const currentSavedQueryToUserProfile = savedQuery.saved_query_to_user_profiles.find(
      (sqtup) => sqtup.user_profile.id === userProfile.id,
    );
    const subscribers = savedQuery.saved_query_to_user_profiles
      .filter((sqtup) => sqtup.deleted === false)
      .sort((a, b) => {
        // Sort creators to the front and then order by last_updated
        return (
          +b.creator - +a.creator ||
          parseMillisecondsAgo(a.last_updated, Infinity) - parseMillisecondsAgo(b.last_updated, Infinity)
        );
      })
      .map((sqtup) => `${sqtup.user_profile.first_name} ${sqtup.user_profile.last_name}`);
    if (shareQueryOpenId === savedQuery.id) {
      return (
        <tr key={savedQuery.id} className="items-center bg-pri-gray-50">
          <td colSpan={6}>
            <div className="w-full f-center">
              <div className="whitespace-nowrap">
                Anyone in your company with the link can view and edit this query.
              </div>
              <TooltipTrigger tip="Copied" forceShow={copiedLink} placement="top">
                <Button
                  size="small"
                  variant="lightDullAction"
                  onClick={() => handleCopyLink(savedQuery.id)}
                  className="ml-2"
                >
                  Copy Link
                </Button>
              </TooltipTrigger>
              <Button
                size="small"
                variant="darkDullAction"
                onClick={handleCloseShareQuery}
                className="ml-2"
              >
                Done
              </Button>
            </div>
          </td>
        </tr>
      );
    } else {
      const usedByASingleUser = savedQuery.saved_query_to_user_profiles.length === 1;
      const numLines = savedQuery.sql.split(/\r\n|\r|\n/).length;
      return (
        <tr
          key={savedQuery.id}
          className="items-center cursor-pointer hover:bg-pri-gray-100"
          onClick={() => onAddSavedQueryToOpenTabs(savedQuery)}
        >
          <td style={{ overflowWrap: 'anywhere', padding: 0 }}>
            <PopperTrigger
              placement="auto"
              triggers="hover"
              popoverProps={{ style: { maxWidth: 'unset', overflow: 'hidden' } }} // Max width is hardcoded to 300px, so unset that here
              renderPopper={(popperProps) => {
                return (
                  <Popover
                    content={
                      <>
                        {savedQuery.sql === '' ? (
                          <div className="px-2 text-pri-gray-400">Empty</div>
                        ) : (
                          <div
                            className="w-[50vw]"
                            style={{ height: `${editorHeight(numLines + 1)}px`, maxHeight: '50vh' }}
                          >
                            <CodeEditor
                              value={savedQuery.sql}
                              height={`${editorHeight(numLines + 1)}px`}
                              mode="sql"
                              disableGutters={true}
                              themeOverrides={EDITOR_THEME_OVERRIDES}
                            />
                          </div>
                        )}
                      </>
                    }
                    {...popperProps}
                  />
                );
              }}
            >
              <div className="h-full w-full pl-2 f-row-y-center">{savedQuery.name}</div>
            </PopperTrigger>
          </td>
          <td style={{ overflowWrap: 'break-word' }}>
            {formatAgoTime(currentSavedQueryToUserProfile?.last_run)}
          </td>
          <td style={{ overflowWrap: 'break-word' }}>
            {formatAgoTime(currentSavedQueryToUserProfile?.last_updated)}
          </td>
          <td style={{ overflowWrap: 'break-word' }}>{formatAgoTime(savedQuery.created_at)}</td>
          <td style={{ maxWidth: '84px', overflow: 'hidden' }}>
            <StackingBubbles items={subscribers} placement="top" />
          </td>
          <td>
            <div className="f-row-y-center">
              <TooltipTrigger tip="Clone" placement="top">
                <IconButton
                  icon="Files"
                  variant="lightDullTab"
                  size="small"
                  spinning={savedQuery.id === cloningSavedQueryId}
                  onClick={() => onAddSavedQueryClone(savedQuery)}
                />
              </TooltipTrigger>
              <TooltipTrigger tip="Share" placement="top">
                <IconButton
                  icon="Link45deg"
                  variant="lightDullTab"
                  size="small"
                  onClick={() => handleOpenShareQuery(savedQuery.id)}
                />
              </TooltipTrigger>
              <TooltipTrigger tip={usedByASingleUser ? 'Delete' : 'Remove'} placement="top">
                <IconButton
                  icon="Trash"
                  variant="lightDullTab"
                  size="small"
                  onClick={() => handleOpenConfirmModal(savedQuery.id)}
                />
              </TooltipTrigger>
            </div>
          </td>
        </tr>
      );
    }
  };

  return show ? (
    <Modal header="Saved Queries" onClose={onClose} cancelButton={true} fullscreen={true}>
      {showConfirmDeleteModalSavedQuery && (
        <ConfirmDeleteModal
          header={showConfirmModalSavedQueryHasOneSubscriber ? 'Delete Query' : 'Remove Query'}
          confirmText={showConfirmModalSavedQueryHasOneSubscriber ? 'Delete' : 'Remove'}
          enableConfirm={true}
          deleting={deletingSavedQueryId === showConfirmDeleteModalSavedQuery.id}
          onCancel={handleCloseConfirmModal}
          onConfirm={() => onDeleteSavedQuery(showConfirmDeleteModalSavedQuery)}
        >
          {showConfirmModalSavedQueryHasOneSubscriber ? (
            <div className="w-[500px] p-4">
              You are the only user accessing this query. This action will permanently delete the query
              and it will be inaccessible via the share link. Proceed with deleting?
            </div>
          ) : (
            <div className="w-[500px] p-4">
              Other users are accessing this query. This action will remove the query from your list, but
              not delete it. As long as other users are accessing this query, you can re-add it via the
              share link. Proceed with removing?
            </div>
          )}
        </ConfirmDeleteModal>
      )}
      <div className="h-full flex flex-col p-6">
        <TextInput
          placeholder="Search"
          name="tableFilter"
          value={searchValue}
          onChange={handleChangeSearchValue}
          className="w-[286px]"
          maxLength={200}
          autoFocus
        />
        <SortableTable
          className="mt-4"
          headers={[
            {
              key: 'name',
              label: 'Name',
              THClassName: 'w-[30%]',
              getValueToSortOn: (savedQuery: SavedQuery) => savedQuery.name,
            },
            {
              key: 'last_run_by_me',
              label: 'Last Run by Me',
              getValueToSortOn: (savedQuery: SavedQuery) =>
                parseMillisecondsAgo(
                  savedQuery.saved_query_to_user_profiles.find(
                    (sqtup) => sqtup.user_profile.id === userProfile.id,
                  )?.last_run || null,
                  Infinity,
                ),
            },
            {
              key: 'last_update_by_me',
              label: 'Last Update by Me',
              getValueToSortOn: (savedQuery: SavedQuery) =>
                parseMillisecondsAgo(
                  savedQuery.saved_query_to_user_profiles.find(
                    (sqtup) => sqtup.user_profile.id === userProfile.id,
                  )?.last_updated || null,
                  Infinity,
                ),
            },
            {
              key: 'created_at',
              label: 'Created At',
              getValueToSortOn: (savedQuery: SavedQuery) =>
                parseMillisecondsAgo(savedQuery.created_at, Infinity),
            },
            {
              label: 'Subscribers',
            },
            { label: '', THClassName: 'w-[91px]' },
          ]}
          type="blueGrayHeaderTable"
          items={filteredSavedQueries}
          defaultSortConfig={{ key: 'last_run_by_me', direction: 'ascending' }}
          renderRow={dataRowRenderingFunc}
        />
      </div>
    </Modal>
  ) : null;
}
