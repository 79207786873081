import React, { useEffect } from 'react';

import { RouteComponentProps } from 'react-router';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import Button from 'components/inputs/basic/Button/Button';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import LoggedOutFormBackgroundLayout from 'components/layouts/pages/LoggedOutFormBackgroundLayout/LoggedOutFormBackgroundLayout';

interface MatchParams {
  useCase: string;
}

interface GettingStartedProps extends RouteComponentProps<MatchParams> {}

const GettingStarted = (props: GettingStartedProps) => {
  useTitle('Mozart: Getting Started');

  const navigate = useNavigate();
  const useCase = props.match.params.useCase;
  const isNewAccount = useCase === 'new-account';
  const buttonCopy = 'Go To Homepage';
  let VIDEO_URL = 'https://www.youtube.com/embed/Vvanu01sU30';
  // This prevents youtube from suggesting videos that aren't germane to Mozart Data.
  VIDEO_URL += '?rel=0';

  useEffect(() => {
    if (isNewAccount) {
      analytics.track('GettingStarted NewAccount');
    } else {
      analytics.track('GettingStarted InvitedUser');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /*
  A user would be forwarded to this page after:
    1. Creating a new Mozart account.
    2. Being added to an existing account by an admin of that account.
    3. Visiting this URL as a training reference or by accident.
  
  Where we should forward the user after they watch the video
  depends on how complete their account setup is and what the user's role is.
  
  On 02/22/2024, it was discussed if it was better to send new accounts directly
  to add a connector or to the homepage. We decided to send users to the homepage
  because the homepage and the CompanySetupGuide both have strong CTAs encouraging
  a user of a brand new account to add a connector. This option is also more consistent
  for all users and eaiser for Mozart staff and customers to reason about.
  */
  const handleNext = () => {
    analytics.track('GettingStarted GoToHomepage');
    navigate('/');
  };

  const VIDEO_WIDTH = 640;
  const VIDEO_HEIGHT = 360;
  const MAX_WIDTH = `${VIDEO_WIDTH + 32}px`;

  return (
    <LoggedOutFormBackgroundLayout>
      <CenteredContainer
        maxWidth={MAX_WIDTH}
        containerClass="!px-4 !py-[48px]"
        contentWrapperClass="!m-0"
      >
        <div>
          <h1 className="display-sm font-medium text-purple">Before getting started...</h1>
          <div className="mt-6 p-4 bg-white rounded shadow-[0_0_4px_0_rgba(0,0,0,0.1)]">
            {/* <h2 className="mb-4 text-lg font-medium">This video will help you...</h2> */}
            <iframe
              title="Getting Started Video"
              width={VIDEO_WIDTH}
              height={VIDEO_HEIGHT}
              src={VIDEO_URL}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div className="flex justify-end mt-8">
              <Button onClick={handleNext}>{buttonCopy}</Button>
            </div>
          </div>
        </div>
      </CenteredContainer>
    </LoggedOutFormBackgroundLayout>
  );
};

export default GettingStarted;
