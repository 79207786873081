/**
 * NOT USED AS OF 04/12/2023:
 * We are holding onto this code incase this comes back.
 * The initial version of ReportBuilder allowed users to pick any combination of reports.
 * Due to the low volume of charts on mostly related subjects for each connector as of April 2023,
 * the analysts merged all charts we had created for a given connector into one report.
 * So, there was no point in picking one of one reports.
 * This UI is now bypassed in favor of simpler UI.
 */
import { Connector } from 'api/APITypes';
import { ReportAvailable, ReportInstalled } from 'api/dashboardAPI';
import Button from 'components/inputs/basic/Button/Button';
import CheckboxCardGrid, {
  CheckboxCardProps,
} from 'components/inputs/composite/CheckboxCardGrid/CheckboxCardGrid';
import Alert from 'components/widgets/alerts/Alert/Alert';

import ConnectorLayout from '../ConnectorLayout';

import DeprecatedDeleteReportsModal from './DeprecatedDeleteReportsModal';

interface PickReportsViewProps {
  connector: Connector;
  reportsAvailable: ReportAvailable[];
  reportsPicked: ReportInstalled[];
  reportsToRemove: ReportInstalled[];
  isNew: boolean;
  saveDisabled: boolean;
  saving: boolean;
  savingError: string;
  showConfirmDelete: boolean;
  onChange(key: string, checked: boolean): void;
  onSave(): void;
  onCancel(): void;
  onDeleteConfirmed(): void;
  onDeleteCanceled(): void;
}

export default function PickReportsView(props: PickReportsViewProps) {
  const {
    connector,
    reportsAvailable,
    reportsPicked,
    reportsToRemove,
    isNew,
    saveDisabled,
    saving,
    savingError,
    showConfirmDelete,
    onChange,
    onSave,
    onCancel,
    onDeleteConfirmed,
    onDeleteCanceled,
  } = props;

  const reportCards: CheckboxCardProps[] = reportsAvailable.map((ra) => {
    const checked = reportsPicked.some((rp) => ra.key === rp.key);
    return {
      checked,
      title: ra.title,
      description: ra.description,
      onClick: () => onChange(ra.key, !checked),
    };
  });

  return (
    <ConnectorLayout connector={connector}>
      <h2 className="mt-10 text-xl font-medium">
        Would you like Mozart to create any of these reports for you?
      </h2>
      <div className="mt-6">
        <CheckboxCardGrid cards={reportCards} />
      </div>
      {savingError && (
        <Alert variant="error" className="mt-6">
          {savingError}
        </Alert>
      )}
      <div className="my-6 f-row-y-center">
        <Button onClick={onCancel}>Back To Connectors</Button>
        <Button onClick={onSave} spinning={saving} disabled={saveDisabled} className="w-[89px] ml-2">
          {isNew ? 'Create' : 'Update'}
        </Button>
      </div>
      {showConfirmDelete && (
        <DeprecatedDeleteReportsModal
          reportsAvailable={reportsAvailable}
          reportsToRemove={reportsToRemove}
          deleting={saving}
          error={savingError}
          onCancel={onDeleteCanceled}
          onConfirm={onDeleteConfirmed}
          connector={connector}
        />
      )}
    </ConnectorLayout>
  );
}
