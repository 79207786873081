import { find } from 'lodash';

import { AggTable } from 'api/APITypes';
import { isQuoted } from 'utils/dbName';
import { reservedWordDic } from 'utils/SnowflakeReservedWords';
import { splitIncluding } from 'utils/String';
import { isMixedCase } from 'utils/String';

import { Text } from '@codemirror/state';
import { EditorView, ViewPlugin } from '@codemirror/view';

import { wordIterator } from './wordIterator';

const DoubleClickPlugin = (
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>,
  queryableTables: AggTable[],
) =>
  ViewPlugin.fromClass(class {}, {
    eventHandlers: {
      dblclick: (e: MouseEvent, view: EditorView) => {
        const doc = view.state.doc;
        const selection = view.state.selection.main;
        const normalizedTable = getNormalizedFullNameFromDoc(doc, selection.from, selection.to);

        const foundTable = find(queryableTables, { full_name: normalizedTable }) as AggTable | undefined;

        if (foundTable) {
          setSelectedTable(foundTable);
          analytics.track('SqlEditor SelectTable');
        }
      },
    },
  });

/**
 * Selected text will be a single word as in "table_name" or a series of connected tokens
 * as in "schmea.table_name".
 * Do our best to find adjacent tokens of the table name the user clicked on.
 * Then normalize it to the key format in our data layer so we can look it up.
 * @param {Text} doc The Code Mirror Doc
 * @param {number} selectionFrom the start of the double clicked range.
 * @param {number} selectionTo the end of the double clicked range.
 * @returns {string} "schema.table" full_name we can use to look up tables in the data layer.
 */
export const getNormalizedFullNameFromDoc = (
  doc: Text,
  selectionFrom: number,
  selectionTo: number,
): string => {
  // Double clicking selects the text inside double quotes.
  // If there are double quotes expand the range to include the quotes.
  let quoteFrom = selectionFrom - 1;
  let quoteTo = selectionTo + 1;
  const selectionString = doc.sliceString(selectionFrom, selectionTo);
  const quoteString = doc.sliceString(quoteFrom, quoteTo);
  const searchFrom = quoteString === '"' + selectionString + '"' ? quoteFrom : selectionFrom;

  // Give the spot where the user clicked get all the tokens in the table name.
  const unsanitizedTableTokens = getUnsanitizedTableTokens(doc, searchFrom);
  // Sanitize the casing and quotes of the table tokens so we can look the table up in the data layer.
  let sanitizedTableTokens = unsanitizedTableTokens.map((t) => sanitizeSqlIdentifier(t));
  // If the user entered a three part name, just grab the last two.
  sanitizedTableTokens = sanitizedTableTokens.slice(-2);
  return sanitizedTableTokens.join('.');
};

/**
 * Returns a santized database identifier token in the format our model layer expects as a key.
 * @param {string} name an SQL identifier token as in `table_name` or `"table_name".
 * @returns {string} Sanitized version of the input string.
 */
export function sanitizeSqlIdentifier(name: string) {
  if (isQuoted(name)) {
    if (isMixedCase(name)) {
      return name;
    } else {
      const lowerNameStrippedQuotes = name.slice(1, -1).toLowerCase();
      if (reservedWordDic[lowerNameStrippedQuotes]) {
        return name;
      } else {
        return lowerNameStrippedQuotes;
      }
    }
  }
  return name.toLowerCase();
}

/**
 * Returns unsantized database identifier words in the give block of text.
 * @param {Text} text an SQL string that might contain parts of a "database.schema.table" identifier.
 * @param {number} searchFrom The index of the first character of the double clicked word.
 * @returns {string[]} The words that make up a "database.schema.table" string.
 */
export const getUnsanitizedTableTokens = (text: Text, searchFrom: number): string[] => {
  const SEARCH_LEN = 1000;
  const textBefore = text.slice(searchFrom - SEARCH_LEN, searchFrom);
  const textAfterafter = text.slice(searchFrom, searchFrom + SEARCH_LEN);
  const MAX_WORDS = 3;
  const wordsBefore = getIdentifierWords(textBefore, MAX_WORDS, -1);
  const wordsAfter = getIdentifierWords(textAfterafter, MAX_WORDS, 1);
  const words = [...wordsBefore.reverse(), ...wordsAfter];
  return words;
};

/**
 * Returns unsantized database identifier words in the give block of text.
 * @param {Text} text an SQL string that might contain parts of a "database.schema.table" identifier.
 * @param {number} maxWords The maximum number of words to accumulate before aborting the search.
 * @param {-1 | 1} direction -1 means search backwards. 1 means search forwards.
 * @returns {string[]} The words that make up a "database.schema.table" string.
 */
export const getIdentifierWords = (text: Text, maxWords: number, direction: -1 | 1): string[] => {
  const iteratorWords: string[] = [];
  let wordsAdded = 0;
  let wi = wordIterator(text, direction);
  let ir = wi.next();
  // If searching in reverse, we assume the first word will be a period.
  let expectingPeriod = direction === -1;
  // Parse text until we run out of text or find `maxWords`
  while (!ir.done && wordsAdded < maxWords) {
    const current = ir.value.trim();
    // Tokens might be separated by periods as in "database.schema.table".
    // So iterate over the tokens between periods.
    let splitWords = splitIncluding(current, '.');
    if (direction === -1) {
      splitWords = splitWords.reverse();
    }

    for (let w of splitWords) {
      // We hit an unexpected period.
      // This is invalid SQL.
      // Return nothing.
      if (!expectingPeriod && w === '.') {
        return [];
      }

      // We are missing a needed period.
      // This means we hit a word that is not part of the identifier name.
      // Return what we have accumulated so far.
      if (expectingPeriod && w !== '.') {
        return iteratorWords;
      }

      // We hit a period.
      // Go to the next token.
      if (w === '.') {
        expectingPeriod = !expectingPeriod;
        continue;
      }

      const [before, identifier, after] = parseMaybeIdentifier(w);

      // We hit something the parser doesn't like.
      // Return what we have so far.
      if (before === '' && identifier === '' && after === '') {
        return iteratorWords;
      }

      // We hit punctuation or some other invalid character that will stop us.
      // Return what we have so far.
      if ((direction === 1 && before !== '') || (direction === -1 && after !== '')) {
        return iteratorWords;
      }

      iteratorWords.push(identifier);
      wordsAdded++;
      expectingPeriod = !expectingPeriod;

      // We hit the word limit or invalid character.
      // Return what we have so far.
      if (
        wordsAdded >= maxWords ||
        (direction === 1 && after !== '') ||
        (direction === -1 && before !== '')
      ) {
        return iteratorWords;
      }
    }
    ir = wi.next();
  }

  // The last character we visited was a period.
  // Return nothing.
  if (!expectingPeriod) {
    return [];
  }

  return iteratorWords;
};

/**
 * Returns database identifiers between leading or trailing punctuation.
 * @param {string} maybeIdentifier an SQL string that might contain a database, schema, or table name.
 * @returns {[string, string, string]} the string before the identifier, the identifier, and the string after the identifier.
 * Note, spaces will be trimmed off.
 * The before and after string would be punctuation between the identifier and the next space or period.
 */
export const parseMaybeIdentifier = (maybeIdentifier: string) => {
  maybeIdentifier = maybeIdentifier.trim();
  const match = maybeIdentifier.match(/^([^\w_"]*)(([\w_]+)|(["]{1}[\w_]+["]{1}))([^\w_"]*)$/);
  if (match === null) {
    return ['', '', ''];
  }
  return [(match[1] as string).trim(), (match[2] as string).trim(), (match[5] as string).trim()];
};

export default DoubleClickPlugin;
