import React, { useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import API from 'api/API';
import QRCodeComponent from 'components/business_logic/QRCodeComponent/QRCodeComponent';
import Button from 'components/inputs/basic/Button/Button';
import Switch from 'components/inputs/basic/Switch/Switch';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import { useUserProfile } from 'context/AuthContext';
import { useExperiments } from 'context/ExperimentContext';

import ExternalAPIKey from './ExternalAPIKey';
import ProfileSettings, { SavableProfileValues } from './ProfileSettings/ProfileSettings';
import ResetTotpButton from './ResetTotpButton';
import TwoFAInfoIcon from './TwoFAInfoIcon';

const totpTokenSchema = Yup.object().shape({
  totp_token: Yup.string().trim().min(6, 'Exactly 6 digits').max(6, 'Exactly 6 digits'),
});

interface totpToken {
  totp_token: string; // totp = Time One-Time Password
}

interface UserSettingsProps {
  setError: (error: string) => void; // Error to display on base Settings.tsx page.
}

export default function UserSettings(props: UserSettingsProps) {
  const { setError } = props;
  const { userProfile, setUserProfile } = useUserProfile();
  const [totpData, setTotpData] = useState({ totp_url: '', totp_secret: '' });
  const [showQRCode, setShowQRCode] = useState(false);
  const [showTotpTokenModal, setShowTotpTokenModal] = useState(false);

  // Flag for switching Profile section between view and edit modes
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  // Used for button spinner while Profile edits are saving
  const [isSavingProfileEdit, setIsSavingProfileEdit] = useState(false);

  const { experiments } = useExperiments();

  const openQRCodeModal = () => {
    // If a user wants to open the QR Modal and we don't already have their totp_url and totp_secret, fetch them
    if (!totpData.totp_url || !totpData.totp_secret) {
      const api = new API();
      api.get(`/api/user_settings/get_2fa`).then((response) => {
        setTotpData(response.data.totp_data);
      });
    }
    setShowQRCode(true);
    analytics.track('UserSettings OpenQRCodeModal');
  };

  const closeQRCodeModal = () => {
    setShowQRCode(false);
    analytics.track('UserSettings CloseQRCodeModal');
  };

  const closeTotpTokenModal = () => {
    setShowTotpTokenModal(false);
    analytics.track('UserSettings CloseTotpTokenModal');
  };

  const submitTotpToken = (values: totpToken) => {
    const transformValues = totpTokenSchema.cast(values);
    setError('');
    const api = new API();
    const postData = {
      twofa_enabled: !userProfile.twofa_enabled,
      totp_token: transformValues.totp_token,
    };
    api
      .post(`/api/user_settings/set_2fa`, postData)
      .then((response) => {
        setUserProfile(response.data.user_profile);
        setTotpData(response.data.totp_data);
        analytics.track(postData.twofa_enabled ? 'UserSettings Enable2FA' : 'UserSettings Disable2FA');
      })
      .catch((e) => {
        if (e.response?.data?.error) {
          setError(e.response.data.error);
        } else if (e.response?.data?.non_field_errors) {
          setError(e.response.data.non_field_errors);
        } else {
          setError('There was a problem verifying your 2FA Token');
        }
      });
    closeTotpTokenModal();
  };

  const toggle2FA = () => {
    if (!userProfile.twofa_enabled) {
      // If the user doens't have 2FA, we need to get their totp secret and show them a QR code
      const api = new API();
      api
        .get(`/api/user_settings/get_2fa`)
        .then((response) => {
          setTotpData(response.data.totp_data);
          setShowTotpTokenModal(true);
        })
        .catch((e) => {
          if (e.response?.data?.non_field_errors) {
            setError(e.response.data.non_field_errors);
          } else {
            setError('There was a problem fetching your 2FA QR Code.');
          }
        });
    } else {
      // If they are trying to turn off 2FA, we don't need their totp secret, but just need to ask for their token
      setShowTotpTokenModal(true);
    }
  };

  // ************* START ProfileSettings handlers ***************
  const handleOpenEditProfile = () => {
    setIsEditingProfile(true);
  };

  const handleSaveEditProfile = (values: SavableProfileValues) => {
    const api = new API();
    setIsSavingProfileEdit(true);
    api
      .patch(`api/user_profiles/${userProfile.id}`, values)
      .then(({ data }) => {
        setUserProfile(data);
        setIsEditingProfile(false);
        analytics.track('UserSettings SaveUserProfile', values);
      })
      .catch((e) => {
        if (e.response?.data?.non_field_errors) {
          setError(e.response.data.non_field_errors);
        } else {
          setError('There was a problem updating your profile.');
        }
      })
      .finally(() => {
        setIsSavingProfileEdit(false);
      });
  };

  const handleCancelEditProfile = () => {
    setIsEditingProfile(false);
  };
  // ************* END ProfileSettings handlers ***************

  const totpTokenModal = (
    <Modal onClose={closeTotpTokenModal} header="2FA Token" cancelButton={true}>
      <div className="m-4 flex flex-col items-center">
        {!userProfile.twofa_enabled && (
          // If we don't have totpData, the user must be turning off 2FA, and we don't want to show a QR Code
          <>
            <p>
              Please register with a 2FA mobile app (Google Authenticator or similar) and submit a 2FA
              token to finish enabling 2FA.
            </p>
            <QRCodeComponent totp_secret={totpData.totp_secret} totp_url={totpData.totp_url} />
          </>
        )}
        <Formik
          initialValues={{ totp_token: '' }}
          onSubmit={(values) => submitTotpToken(values)}
          validationSchema={totpTokenSchema}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="flex flex-col items-center" noValidate>
              <TextFormikGroup name="totp_token" type="text" />
              <Button type="submit" variant="lightAction" className="mt-2" spinning={isSubmitting}>
                Submit
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );

  const QRCodeModal = (
    <Modal onClose={closeQRCodeModal} header="QR Code" cancelButton={true}>
      <div className="w-[350px] my-4 flex flex-col items-center">
        <QRCodeComponent totp_secret={totpData.totp_secret} totp_url={totpData.totp_url} />
      </div>
    </Modal>
  );

  return (
    <>
      {showQRCode && QRCodeModal}
      {showTotpTokenModal && totpTokenModal}
      <div className="mt-6 mb-4">
        <ProfileSettings
          isEditing={isEditingProfile}
          isSaving={isSavingProfileEdit}
          profileValues={{
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
          }}
          onEdit={handleOpenEditProfile}
          onSubmitEdit={handleSaveEditProfile}
          onCancelEdit={handleCancelEditProfile}
        />

        <div className="mt-5 mb-4">
          <div className="flex items-center">
            <Switch
              id="2fa-enabled-switch"
              name="twofa_enabled"
              checked={userProfile.twofa_enabled}
              onChange={toggle2FA}
              disabled={userProfile.company.require_2fa && userProfile.twofa_enabled}
            />
            <h2 className="text-xl text-pri-gray-700 ml-3">2-Factor Authentication</h2>
            <TwoFAInfoIcon />
          </div>
          {userProfile.twofa_enabled && (
            <div>
              <Button variant="lightAction" size="small" onClick={openQRCodeModal} className="m-1 mt-2">
                Show QRCode
              </Button>
              <ResetTotpButton setTotpData={setTotpData} setError={setError} />
            </div>
          )}
        </div>
        {experiments.externalAPI && <ExternalAPIKey setError={setError} />}
      </div>
    </>
  );
}
