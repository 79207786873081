import React from 'react';

import { XLg } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

import { Connector, DatabaseType } from 'api/APITypes';
import { AggTable } from 'api/tableAPI';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';
import { useDatabaseAccount } from 'context/AuthContext';
import { convertFullName } from 'utils/dbName';
import { eventType } from 'utils/String';

import { Pipeline, Vertex } from '../PipelineEditor';
import { getIsMissing } from '../PipelineGraph/utils/vertexBasicInfoUtils';
import { PipelineVertex } from '../useVertexConverter';

import CSVUploadContent from './CSVUploadContent';
import DBTSidebarContent from './DBTSidebarContent';
import SnapshotSidebarContent from './SnapshotSidebarContent';
import TransformSidebarContent from './TransformSidebarContent';
import UnmanagedSidebarContent from './UnmanagedSidebarContent';
import ViewSidebarContent from './ViewSidebarContent';

export const HEADING_CLASS = 'text-base font-medium text-sec-blue-gray-700';

interface VertexSidebarProps {
  pipeline: Pipeline;
  selectedVertex: PipelineVertex;
  tablesByID: { [key: string]: AggTable };
  connectorsByID: { [key: string]: Connector };
  onClose(): void;
}

export default function VertexSidebar(props: VertexSidebarProps) {
  const { pipeline, selectedVertex, tablesByID, connectorsByID, onClose } = props;
  const databaseType = useDatabaseAccount().type;

  return (
    <div className="h-full bg-white shadow-md">
      <div className="w-full px-3 py-2 f-between">
        <div className={HEADING_CLASS} style={{ maxWidth: 'calc(100% - 14px)' }}>
          {pickTitle(selectedVertex, databaseType)}
        </div>
        <XLg
          size={14}
          color="var(--pri-gray-500)"
          className="flex-none hover:cursor-pointer"
          onClick={onClose}
        />
      </div>
      <div className="w-full h-[2px] bg-pri-gray-50"></div>
      <div className="w-full overflow-auto" style={{ height: 'calc(100% - 42px)' }}>
        <Content
          pipeline={pipeline}
          selectedVertex={selectedVertex}
          tablesByID={tablesByID}
          connectorsByID={connectorsByID}
        />
      </div>
    </div>
  );
}

const pickTitle = (vertex: Vertex, databaseType: DatabaseType) => {
  const { table } = vertex;
  if (table === null) {
    return convertFullName(vertex.name || '', databaseType);
  }

  const eventName = `PipelineTab SideBarTitle${eventType(table.type)}Link`;

  return (
    <Link
      to={`/tables/${table.id}`}
      target="_blank"
      data-track={eventName}
      className="f-row-y-center hover:text-sec-blue-gray-500 hover:underline"
    >
      <div className="flex-initial text-ellipsis overflow-hidden">{table.full_name}</div>
    </Link>
  );
};

interface ContentProps {
  pipeline: Pipeline;
  selectedVertex: PipelineVertex;
  tablesByID: { [key: string]: AggTable };
  connectorsByID: { [key: string]: Connector };
}
const Content = (props: ContentProps) => {
  const { pipeline, selectedVertex, tablesByID, connectorsByID } = props;
  const table = selectedVertex?.table;
  const transform = table?.transform;
  let children: React.ReactNode = null;

  if (getIsMissing(selectedVertex) || !table) {
    children = (
      <FixMeAlert
        heading="Uh-Oh!"
        detail="This table appears to be missing. Maybe it was never created or it was deleted."
        variant="kinda_bad"
      />
    );
  } else if (table.type === 'transform' && transform) {
    children = (
      <TransformSidebarContent
        pipeline={pipeline}
        selectedVertex={selectedVertex}
        table={table}
        tablesByID={tablesByID}
      />
    );
  } else if (table.type === 'dbt') {
    children = <DBTSidebarContent table={table} connectorsByID={connectorsByID} />;
  } else if (table.type === 'snapshot' && table) {
    children = <SnapshotSidebarContent table={table} />;
  } else if (table.type === 'csv_upload' && table) {
    children = <CSVUploadContent table={table} />;
  }
  // View status is less important that what created the table.
  // So, order this near end but before unmanaged catch all.
  else if (['VIEW', 'SECURE VIEW', 'MATERIALIZED VIEW'].includes(table.database_table_type)) {
    children = <ViewSidebarContent table={table} />;
  } else if (table.type === 'unmanaged' && table) {
    children = <UnmanagedSidebarContent table={table} />;
  }

  return <>{children && <div className="px-3 py-2">{children}</div>}</>;
};
