import { useCallback, useEffect, useRef, useState } from 'react';

import API from 'api/API';
import { Transform } from 'api/APITypes';
import { setAPIPayload, getAPIPayload, LIST_TRANSFORMS } from 'api/CacheKeys';
import { updateManyByID } from 'utils/Array';
import { parseIso } from 'utils/dateTime';

import { maybeMockTransforms } from './maybeMock';

export default function useTransformContext() {
  const [transforms, setTransforms] = useState<Transform[]>([]);
  const [isLoading, setIsLoading] = useState(true); // We have no transforms from API.
  const [isLocal, setIsLocal] = useState(false); // We have transforms from localStorage, but not the API.
  const [error, setError] = useState('');
  const [lastTransformsChange, setLastTransformsChange] = useState<Date | null>(null);
  const apiHasReturned = useRef(false); // When transforms first load, has the api come back yet? So we don't overwrite it from cache
  const apiRequestInProgress = useRef(false); // We don't want to refetch if we are currently waiting for a response

  const refreshTransforms = useCallback(() => {
    if (!apiRequestInProgress.current) {
      apiRequestInProgress.current = true;
      const api = new API();
      api
        .get(`/api/transforms`)
        .then((response) => {
          apiHasReturned.current = true;
          const transforms = maybeMockTransforms(response.data.transforms);
          setTransforms(transforms);
          setLastTransformsChange(parseIso(response.data.last_transforms_change));
          setIsLocal(false);
          setAPIPayload(LIST_TRANSFORMS, transforms);
        })
        .catch((error) => {
          setError('Failed to load transform list.');
        })
        .finally(() => {
          setIsLoading(false);
          apiRequestInProgress.current = false;
        });
    }
  }, []);

  // Step 1:
  // Get transform list from a previous API call out of localStorage.
  useEffect(() => {
    const transformList = getAPIPayload<Transform[]>(LIST_TRANSFORMS);
    if (!apiHasReturned.current && transformList) {
      setTransforms(maybeMockTransforms(transformList));
      setIsLocal(true);
    }
  }, []);

  // Step 2:
  // Make an API call to get the list of transforms.
  useEffect(() => {
    refreshTransforms();
  }, [refreshTransforms]);

  // Update or extend list of transforms in transform context.
  const updateTransforms = useCallback(
    (newTransforms: Transform[]) => {
      setTransforms(updateManyByID(transforms, newTransforms));
    },
    [transforms],
  );

  const removeTransform = useCallback(
    (transformToRemove: Transform) => {
      setTransforms(transforms.filter((t) => t.id !== transformToRemove.id));
    },
    [transforms],
  );

  return {
    transforms,
    isLoading,
    error,
    isLocal,
    lastTransformsChange,
    updateTransforms,
    removeTransform,
    refreshTransforms,
  };
}
