import GenerateSnowflakeUser from 'components/business_logic/GenerateSnowflakeUser/GenerateSnowflakeUser';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useUserProfile } from 'context/AuthContext';

import ExternalLink from '../ExternalLink';
import { IntegrationLayoutProps } from '../IntegrationSnowflakeConfig';
import SnowflakeConfig from '../SnowflakeConfig';
import Steps from '../Steps';

import { defaultIntegrationMap } from './DefaultLayoutData';

interface MetabaseAlertProps {
  className?: string;
}

const MetabaseAlert = (props: MetabaseAlertProps) => {
  const { userProfile } = useUserProfile();
  const hasHostedMetabase = userProfile.company.metabase_integration?.is_active;

  // As of 07/24/2024, we only show this alert if hosting === true.
  // Leaving code in place incase someone changes their minds.
  // https://mozartdata.slack.com/archives/C012LKRM3S8/p1721840833548219
  if (!hasHostedMetabase) {
    return null;
  }

  const hostingMsg = hasHostedMetabase
    ? 'Mozart Data is hosting Metabase for you.'
    : 'Mozart Data can host Metabase for you.';

  return (
    <Alert variant="info" className={props.className}>
      <div className="w-full flex justify-start items-center">
        <div className="flex-col ml-4">
          <h3 className="font-medium">{hostingMsg}</h3>
          <p>The instructions below are for connecting your own instance of Metabase to Mozart Data.</p>
          {hasHostedMetabase ? (
            <p>To access your hosted Metabase, click on the Metabase link in the page header.</p>
          ) : (
            <p>
              If you would like us to host Metabase for you,{' '}
              <a className="text-sec-blue-light-700" href="mailto:support@mozartdata.com">
                Contact Support
              </a>
              , and we'll host it and set it up for you.
            </p>
          )}
        </div>
      </div>
    </Alert>
  );
};

const DefaultLayout = (props: IntegrationLayoutProps) => {
  const { values } = props;
  const { key, type, url, steps, bigQuerySteps, uiName, fields, bigQueryFields, ...rest } =
    defaultIntegrationMap[props.integration.key];
  const databaseType = values?.type;
  const actualSteps = databaseType === 'bigquery' && bigQuerySteps ? bigQuerySteps : steps;
  const actualFields = databaseType === 'bigquery' && bigQueryFields ? bigQueryFields : fields;
  const databaseDisplayName = databaseType === 'bigquery' ? 'BigQuery' : 'Snowflake';
  return (
    <>
      {key === 'metabase' && <MetabaseAlert className="mb-4" />}
      <Steps>
        {type === 'webapp' && (
          <li>
            Log into <ExternalLink href={url}>{uiName}</ExternalLink>.
          </li>
        )}
        {actualSteps.map((step, idx) => (
          <li key={idx}>{step}</li>
        ))}
      </Steps>
      <SnowflakeConfig
        {...rest}
        databaseDisplayName={databaseDisplayName}
        fields={actualFields}
        values={values}
      />
      {databaseType === 'snowflake' && <GenerateSnowflakeUser className="mt-4" />}
    </>
  );
};

export default DefaultLayout;
