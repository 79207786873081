import { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';

import { html } from '@codemirror/lang-html';
import { javascript } from '@codemirror/lang-javascript';
import { markdown } from '@codemirror/lang-markdown';
import { python } from '@codemirror/lang-python';
import { sql } from '@codemirror/lang-sql';
import { xml } from '@codemirror/lang-xml';
import { LanguageSupport, StreamLanguage } from '@codemirror/language';
import * as yamlMode from '@codemirror/legacy-modes/mode/yaml';

export const getLanguageExtension = (newVal: string | ListboxValue): LanguageSupport => {
  let languageExtension: LanguageSupport;

  switch (newVal) {
    case 'text':
      languageExtension = markdown();
      break;
    case 'snowflake':
      languageExtension = sql();
      break;
    case 'yml':
    case 'yaml':
      // below we enable yml support for CodeMirror v6 from the v5 language mode
      // the new LanguageSupport instance packages the CodeMirror v5 mode to be compatible as a CodeMirror v6 extension
      // @ts-ignore
      const yaml = new LanguageSupport(StreamLanguage.define(yamlMode.yaml));
      // @ts-ignore
      yaml.language.name = 'yaml';
      languageExtension = yaml;
      break;
    case 'json5':
    case 'json':
      languageExtension = javascript();
      break;
    case 'xml':
      languageExtension = xml();
      break;
    case 'html':
      languageExtension = html();
      break;
    case 'python':
      languageExtension = python();
      break;
    default:
      languageExtension = sql();
      break;
  }

  return languageExtension;
};
// This is an extremely simple language classifier.
// Better ones are available on NPM but we don't want to increase build size.
export function guessEditorLanguage(code: string) {
  code = code.toLowerCase();

  // json?
  if (code.slice(0, 1) === '{' && code.slice(-1) === '}') {
    return 'json5';
  }

  // yml?
  const firstLineBreakIdx = code.indexOf('\n');
  const firstLine = code.slice(0, firstLineBreakIdx || 1);
  if (firstLine.includes(':') && !firstLine.includes('{')) {
    return 'yml';
  }
  // html?
  let start = code.slice(0, 15);
  const htmlTags = ['html>', '<html', '<head>', '<body>', '<div>'];
  for (const t of htmlTags) {
    if (start.includes(t)) {
      return 'html';
    }
  }

  // xml?
  if (code.slice(0, 1) === '<' && code.slice(-1) === '>') {
    return 'xml';
  }
  // sql?
  start = code.slice(0, 6);
  const sqlVerbs = ['with', 'select', 'update', 'insert', 'create', '/*', '--'];
  for (const sv of sqlVerbs) {
    if (start.includes(sv)) {
      return 'snowflake';
    }
  }

  // Default to text
  return 'text';
}
