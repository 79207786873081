import React, { useMemo } from 'react';

import { CompanyRole, DatabaseType, UserProfile } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import TextInput from 'components/inputs/basic/TextInput/TextInput';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import Accordion from 'components/widgets/accordions/Accordion/Accordion';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import uph from 'model_helpers/userProfileHelper';

import { UserToSave, UsersWithValidSnowflakeRoles, companyRoleMap } from '../useMozartUsers';
import { SnowflakeRoleModalHookProps } from '../useSnowflakeRoleModal';

import SnowflakeRoleModal from './SnowflakeRoleModal';
import UserTable from './UserTable/UserTable';

export interface MozartUsersTabViewProps {
  userProfile: UserProfile | undefined;
  pageLoading: boolean;
  users: UserProfile[];
  usersWithValidSnowflakeRoles: UsersWithValidSnowflakeRoles;
  pageError: string;
  saveError: string;
  roleSpinners: { [key: string]: true | undefined };
  isActiveSpinners: { [email: string]: true | undefined };
  urlSelectedEmail: string;
  userFilter: string;
  filterRef: React.RefObject<HTMLInputElement>;
  userToSave: UserToSave | null;
  isDeactivatedUsersExpanded: boolean | null;
  snowflakeModalProps: SnowflakeRoleModalHookProps;
  databaseType: DatabaseType;
  onSaveRole: (userProfile: UserProfile, newRole: CompanyRole) => void;
  onOpenConfirmSaveIsActive: (userProfile: UserProfile, newIsActive: boolean) => void;
  onConfirmSaveIsActive: () => void;
  onCancelSaveIsActive: () => void;
  onDeactivatedUsersExpand: (newExpand: boolean) => void;
  reportOnBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFilterChange: (filter: string) => void;
  onAddUser: () => void;
}

export default function MozartUsersTabView(props: MozartUsersTabViewProps) {
  const {
    userProfile,
    pageLoading,
    users,
    usersWithValidSnowflakeRoles,
    pageError,
    saveError,
    roleSpinners,
    isActiveSpinners,
    urlSelectedEmail,
    userFilter,
    filterRef,
    userToSave,
    isDeactivatedUsersExpanded,
    snowflakeModalProps,
    databaseType,
    onSaveRole,
    onOpenConfirmSaveIsActive,
    onConfirmSaveIsActive,
    onCancelSaveIsActive,
    onDeactivatedUsersExpand,
    reportOnBlur,
    onFilterChange,
    onAddUser,
  } = props;

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilterChange(event.target.value.toLowerCase());
  };

  const imAdmin = userProfile?.company_role === 'admin';

  const Header = (
    <div className="flex justify-between">
      <div className="f-center">
        <TextInput
          ref={filterRef}
          placeholder="Search"
          name="search"
          value={userFilter}
          onChange={handleFilterChange}
          onBlur={reportOnBlur}
          autoFocus
          className="w-[284px]"
          maxLength={200}
        />
      </div>
      {imAdmin && (
        <Button variant="lightAction" onClick={onAddUser}>
          Add User
        </Button>
      )}
    </div>
  );

  // Calculate filtered users lists
  const [unfilteredDeactivatedUsers, filteredActiveUsers, filteredDeactivatedUsers] = useMemo(() => {
    const unfilteredActiveUsers = users.filter((userProfile: UserProfile) => userProfile.user.is_active);
    const unfilteredDeactivatedUsers = imAdmin
      ? users.filter((userProfile: UserProfile) => !userProfile.user.is_active)
      : [];
    let filteredActiveUsers = unfilteredActiveUsers;
    let filteredDeactivatedUsers = unfilteredDeactivatedUsers;
    if (userFilter) {
      filteredActiveUsers = filterUsers(filteredActiveUsers, userFilter);
      filteredDeactivatedUsers = filterUsers(filteredDeactivatedUsers, userFilter);
    }
    return [unfilteredDeactivatedUsers, filteredActiveUsers, filteredDeactivatedUsers];
  }, [users, userFilter, imAdmin]);

  // Calculate template properties
  const require2FA = !!userProfile?.company.require_2fa;
  const hasNonStandardSnowflakeRole = users.some((u) => {
    const expectedSnowflakeRole = companyRoleMap[u.company_role];
    return u.snowflake_role !== expectedSnowflakeRole;
  });
  const isByos =
    userProfile?.company?.database_account?.type === 'snowflake' &&
    userProfile.company.database_account.is_byos;
  const showSnowflakeRole = imAdmin && !isByos && hasNonStandardSnowflakeRole;

  const showContent = !pageLoading && !pageError;
  const noActiveUsersFound = filteredActiveUsers.length === 0;
  const noDeactivatedUsersFound = filteredDeactivatedUsers.length === 0;
  const noUsersFound = noActiveUsersFound && noDeactivatedUsersFound;

  // Expand if user is searching in filter or user explicitly expands deactivated users
  const isAccordionOpen =
    (isDeactivatedUsersExpanded === null && userFilter.length > 0) ||
    isDeactivatedUsersExpanded === true;

  return (
    <CenteredContainer header={Header} loading={pageLoading} loadingError={pageError || saveError}>
      {showContent && noUsersFound && (
        <NoMatchesFoundAlert heading="No matching users." className="my-4" />
      )}
      {showContent && !noUsersFound && (
        <>
          <UserTable
            imAdmin={imAdmin}
            require2FA={require2FA}
            showSnowflakeRole={showSnowflakeRole}
            filteredUsers={filteredActiveUsers}
            usersWithValidSnowflakeRoles={usersWithValidSnowflakeRoles}
            urlSelectedEmail={urlSelectedEmail}
            roleSpinners={roleSpinners}
            isActiveSpinners={isActiveSpinners}
            isByos={isByos}
            databaseType={databaseType}
            saveRole={onSaveRole}
            openConfirmSaveIsActive={onOpenConfirmSaveIsActive}
            openSnowflakeModal={snowflakeModalProps.openSnowflakeModal}
          />
          {noActiveUsersFound && (
            <NoMatchesFoundAlert heading="No matching active users." className="my-4" />
          )}
          {imAdmin && unfilteredDeactivatedUsers.length > 0 && (
            <Accordion
              header="Deactivated Users"
              startsExpanded={isAccordionOpen}
              onClick={onDeactivatedUsersExpand}
              className="mt-10 mx-[-1.5rem] mb-0"
            >
              <UserTable
                imAdmin={imAdmin}
                require2FA={require2FA}
                showSnowflakeRole={showSnowflakeRole}
                filteredUsers={filteredDeactivatedUsers}
                usersWithValidSnowflakeRoles={usersWithValidSnowflakeRoles}
                urlSelectedEmail={urlSelectedEmail}
                roleSpinners={roleSpinners}
                isActiveSpinners={isActiveSpinners}
                isByos={isByos}
                databaseType={databaseType}
                saveRole={onSaveRole}
                openConfirmSaveIsActive={onOpenConfirmSaveIsActive}
                openSnowflakeModal={snowflakeModalProps.openSnowflakeModal}
              />
              {noDeactivatedUsersFound && (
                <NoMatchesFoundAlert heading="No matching deactivated users." className="mt-4" />
              )}
            </Accordion>
          )}
        </>
      )}
      {userToSave && (
        <ConfirmModal
          saving={isActiveSpinners[userToSave.userProfile.user.email]}
          header={
            <span className="text-lg font-medium">
              Are you sure you want to {userToSave.newIsActive ? 'reactivate' : 'deactivate'}{' '}
              <code style={{ overflowWrap: 'anywhere' }}>
                {userToSave.userProfile.first_name} {userToSave.userProfile.last_name}
              </code>
              ?
            </span>
          }
          confirmText={userToSave.newIsActive ? 'Reactivate' : 'Deactivate'}
          confirmVariant="darkDanger"
          onCancel={onCancelSaveIsActive}
          onConfirm={onConfirmSaveIsActive}
        />
      )}
      {snowflakeModalProps.snowflakeModalUser !== null && (
        <SnowflakeRoleModal
          {...snowflakeModalProps}
          // The following line keeps Typescript from thinking snowflakeModalUser might be null.
          snowflakeModalUser={snowflakeModalProps.snowflakeModalUser}
        />
      )}
    </CenteredContainer>
  );
}

const filterUsers = (users: UserProfile[], filter: string) =>
  users.filter((u: UserProfile) => {
    return (
      u.user.email.toLowerCase().includes(filter) || uph.displayName(u).toLowerCase().includes(filter)
    );
  });
