import React, { ForwardedRef, useCallback, useLayoutEffect, useRef, useState } from 'react';

import cn from 'classnames';
import escapeHtml from 'escape-html';
import { clamp } from 'lodash';

import { PixelSize, pixToNum } from 'utils/cssMath';

import TextAreaInput, { TextAreaInputProps } from '../TextAreaInput/TextAreaInput';

// A TextArea that automatically grows to more lines if the user hits enter
// OR overflow-wrap wraps to the next line.
export interface ExpandingTextAreaInputProps extends Omit<TextAreaInputProps, 'rows'> {
  startRows?: number;
  maxRows?: number;
}

const ExpandingTextAreaInput = React.forwardRef<HTMLTextAreaElement, ExpandingTextAreaInputProps>(
  (props: ExpandingTextAreaInputProps, forwardedRef: ForwardedRef<HTMLTextAreaElement>) => {
    const {
      startRows = 1,
      maxRows = 5,
      value,
      className,
      // I'm not sure what this ref is but it messes up Typescript if it's mixed into `textAreaProps`.
      ref: ignoreThisRef,
      ...textAreaProps
    } = props;

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const typingSizeRef = useRef<HTMLDivElement>(null);
    const [rows, setRows] = useState(startRows);

    // Copy my ref onto the forwardedRef
    useLayoutEffect(() => {
      if (forwardedRef) {
        if (typeof forwardedRef === 'function') {
          forwardedRef(textAreaRef.current);
        } else {
          forwardedRef.current = textAreaRef.current;
        }
      }
    });

    // The vertical height of the TextArea content depends on
    // the number of new line characters and if overflow-wrap
    // has caused the line to wrap to the next line.
    // "As far as I know", the only way to get this size with Javascript
    // is to copy the content into an invisible HTML element that doesn't
    // scroll and measure the size of that element.
    const calcRowsFromTypingSizeDiv = useCallback(
      (value: string) => {
        if (textAreaRef.current && typingSizeRef.current) {
          // The TextArea the user is typing in
          const textEl = textAreaRef.current;
          // The div we are copying the user's typing into and measuring
          const sizeEl = typingSizeRef.current;

          // Copy the styles of textEl onto sizeEl so they will be the same size.
          const textCS = window.getComputedStyle(textEl);
          sizeEl.style.font = textCS.font;
          sizeEl.style.fontSize = textCS.fontSize;
          sizeEl.style.lineHeight = textCS.lineHeight;
          sizeEl.style.width = textCS.width;
          sizeEl.style.padding = textCS.padding;

          // Reset the content of sizeEl
          sizeEl.innerHTML = '';

          // Copy the content of textEl into sizeEl
          const lines = textEl.value.split('\n');
          lines.forEach((l) => {
            const lineEl = document.createElement('div');
            // Make sure the line takes up space if it's an empty line
            const correctedLine = l === '' ? 'X' : escapeHtml(l);
            lineEl.innerHTML = correctedLine;
            sizeEl.appendChild(lineEl);
          });

          // Now that sizeEl has been resized by it's new content,
          // measure how much height it displaces and set the number of rows.
          const sizeCS = window.getComputedStyle(sizeEl);
          const padding =
            pixToNum(sizeCS.paddingTop as PixelSize) + pixToNum(sizeCS.paddingBottom as PixelSize);
          const innerHeight = sizeEl.offsetHeight - padding;
          const lineHeight = pixToNum(sizeCS.lineHeight as PixelSize);
          const newRows = Math.floor(innerHeight / lineHeight);
          const clampedRows = clamp(newRows, startRows, maxRows);
          setRows(clampedRows);
        }
      },
      [startRows, maxRows],
    );

    useLayoutEffect(() => {
      calcRowsFromTypingSizeDiv(value);
    }, [value, calcRowsFromTypingSizeDiv]);

    // Chrome and Firefox do not have the same default CSS rules for height so we need to be explicit.
    const PADDING_AND_BORDERS = 18;
    const LINE_HEIGHT = 24;
    const height = PADDING_AND_BORDERS + LINE_HEIGHT * rows;

    return (
      <>
        <TextAreaInput
          ref={textAreaRef}
          rows={rows}
          value={value}
          className={cn('resize-none', className)}
          style={{ height: `${height}px` }}
          {...textAreaProps}
        />
        <div
          ref={typingSizeRef}
          className="h-auto fixed left-0 top-0 z-[-99999999] invisible bg-white"
          style={{ overflowWrap: 'anywhere' }}
        ></div>
      </>
    );
  },
);

export default ExpandingTextAreaInput;
