import React, { ForwardedRef } from 'react';

import { useFormikContext } from 'formik';

import { SaveableCSVUploadProps } from 'api/csvUploadAPI';
import { getEffectiveClass } from 'components/inputs/basic/Button/Button';

interface FileUploadProps {
  setErrorMessage: (message: string) => void;
}

export default React.forwardRef<HTMLInputElement, FileUploadProps>(function FileUpload(
  props: FileUploadProps,
  forwardedRef: ForwardedRef<HTMLInputElement>,
) {
  const { setErrorMessage } = props;
  const { setFieldValue, values, errors, touched } = useFormikContext<SaveableCSVUploadProps>();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files) {
      const file = files[0];
      setFieldValue('file', file);
      setErrorMessage('');
    }
  };

  // The Chrome browser console throws an `net::ERR_UPLOAD_FILE_CHANGED` error if you:
  // 1. Select an invalid file.
  // 2. Try and upload that file.
  // 3. Fix and save the file on the OS file system.
  // 4. Try to resubmit the form without changing the file in the browser file selector input.
  // This bug was marked won't fix: https://bugs.chromium.org/p/chromium/issues/detail?id=1086707
  //
  // Note:
  // React does not let you use a <input type="file"/> as a controlled input.
  // So we are doing input resetting the hacky way.
  // There may be room to improve this with the technique in this thread, but
  // I need to be pragmatic and get something working now.
  // https://stackoverflow.com/questions/76103230/proper-way-to-create-a-controlled-input-type-file-element-in-react
  //
  // Therefore, you should include this code snippet in your exception handler
  // to clear the file input if you get a failed upload.
  // if (e.message==='Network Error' && fileRef.current) {
  //   formikHelpers.setFieldValue('file', null); // Reset Formik value
  //   fileRef.current.value = ''; // Reset the value in the <input type="file"/> element
  // }
  //

  return (
    <div>
      <label
        htmlFor="file"
        className={getEffectiveClass('small', 'darkDullAction', false, false, false, {})}
      >
        Select File
      </label>
      <input
        ref={forwardedRef}
        className="hidden"
        id="file"
        name="file"
        type="file"
        onChange={handleFileChange}
        accept=".csv"
      />
      {touched.file && errors.file && (
        <div className="mt-1 text-pri-error-700 text-xs">{errors.file}</div>
      )}
      <span className="text-gray-600 ml-2">{values.file?.name}</span>
    </div>
  );
});

export function explainNetworkError(errorMessage: string) {
  return errorMessage === 'Network Error'
    ? "Network Error OR Browsers frequently report a Network Error if you make changes to a file after you have selected it in the browser's file selector. To fix this select the file again."
    : errorMessage;
}
