import React, { useMemo } from 'react';

import cn from 'classnames';

import useSortableTable, { SortConfig, SortFunctions, UseSortableTableProps } from './useSortableTable';

type TableStyleType = 'lineTable' | 'blueGrayHeaderTable';

export interface TableHeader {
  label: string;
  key?: string;
  getValueToSortOn?: (item: any) => any; // Given one of the items, output the value from the item that should be used for sorting.
  THClassName?: string; // For column width
  THAlign?: 'left' | 'center' | 'right' | 'justify' | 'char' | undefined;
  divClassName?: string; // For alignment (f-center)
}

interface SortableTableProps {
  headers: TableHeader[];
  items: any[]; // This is the data we loop over to make each row
  type?: TableStyleType;
  className?: string;
  defaultSortConfig?: SortConfig;
  renderRow: (item: any) => React.ReactNode;
}

export default function SortableTable<T>(props: SortableTableProps) {
  const { headers, items, type, className, defaultSortConfig, renderRow } = props;

  const sortFunctions = useMemo(() => {
    const sortFunctions: SortFunctions<T> = {};

    headers.forEach((h: TableHeader) => {
      if (h.key && h.getValueToSortOn) {
        sortFunctions[h.key] = h.getValueToSortOn;
      }
    });

    return sortFunctions;
  }, [headers]);

  const useSortableTableProps: UseSortableTableProps<T> = useMemo(
    () => ({
      unsortedItems: items,
      sortFunctions,
      defaultSortConfig,
    }),
    [items, sortFunctions, defaultSortConfig],
  );

  const { sortedItems, sortConfig, requestSort, getSortIcon } = useSortableTable(useSortableTableProps);

  return (
    <div className="overflow-auto h-full mt-4">
      {/* We need to overwrite the mt from the table class and put it on our div */}
      <table className={cn(type, className, 'mt-0')}>
        <thead>
          <tr>
            {headers.map((h) => {
              const sortIcon = getSortIcon(h.key);
              return (
                <th
                  key={h.label}
                  className={cn('sticky top-0 z-10', h.THClassName, {
                    'cursor-pointer': h.key,
                  })}
                  align={h.THAlign}
                  onClick={() => requestSort(h.key)}
                >
                  <div className={cn('f-row-y-center', h.divClassName)}>
                    <div>{h.label}</div>
                    <div className="w-[16px] ml-2">{sortConfig?.key === h.key ? sortIcon : null}</div>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{sortedItems.map((i) => renderRow(i))}</tbody>
      </table>
    </div>
  );
}
