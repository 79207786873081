import React from 'react';

import { X } from 'react-bootstrap-icons';

import cn from 'classnames';

export interface ModalProps {
  children?: React.ReactNode; // The body of the modal
  header?: React.ReactNode | string;
  footer?: React.ReactNode;
  containerClass?: string;
  fullscreen?: boolean;
  cancelButton?: boolean;
  onClose: () => void;
}

export default function Modal(props: ModalProps) {
  let { header, children, footer, containerClass, fullscreen, cancelButton, onClose } = props;
  const defaultContainerClass =
    'flex overflow-x-hidden border-0 rounded shadow-lg relative flex-col bg-white outline-none focus:outline-none z-[2001] text-pri-gray-700';
  const fullScreenClass = 'w-full h-[90vh] m-[50px]';
  const actualContainerClass = cn(
    defaultContainerClass,
    { [fullScreenClass]: fullscreen },
    containerClass,
  );

  if (typeof header === 'string') {
    header = <div className="text-xl font-medium">{header}</div>;
  }

  return (
    <div className="flex justify-center items-center fixed inset-0 z-[1000]">
      <div className={actualContainerClass}>
        {header && (
          <div className="f-between p-4 border-b border-solid border-blueGray-200 rounded-t">
            {header}
            {cancelButton && (
              <button onClick={onClose} className="w-5 h-6 p-[4px 0px 4px 4px]">
                <X size="24" color="var(--pri-gray-400)" />
              </button>
            )}
          </div>
        )}
        {children && (
          <div className="relative flex-auto overflow-y-auto h-full border-b border-solid border-blueGray-200">
            {children}
          </div>
        )}
        {footer && <div className="flex items-center justify-end p-4 rounded-b">{footer}</div>}
      </div>
      <div onClick={onClose} className="opacity-[.29] fixed inset-0 z-[2000] bg-black"></div>
    </div>
  );
}
