import React, { memo } from 'react';

import classNames from 'classnames';

import { ErrorColor, VertexShape } from '../../useVertexConverter';

import {
  BORDER_WIDTH,
  BORDER_RADIUS,
  DEFAULT_BORDER_COLOR,
  FONT_SIZE,
  ICON_SIZE,
  ICONS_BY_STATUS,
  ICONS_BY_TYPE,
  LABEL_FONT_WEIGHT,
  LEGEND_FONT_SIZE,
  LINE_HEIGHT,
  SELECTED_BORDER_WIDTH,
  SUBLABEL_FONT_WEIGHT,
  VERTEX_WIDTH,
  VERTEX_HEIGHT,
} from '../PipelineGraph.styles';
import { getVertexStyle } from '../utils/graphUtils';

export interface VertexData {
  id: string;
  /** Only optional for legend vertices, required otherwise */
  schemaName?: string;
  tableName: string;
  type: VertexShape;
  iconType:
    | VertexShape.Unmanaged
    | VertexShape.View
    | VertexShape.CSVUpload
    | VertexShape.Transform
    | VertexShape.DBT
    | VertexShape.Snapshot;
  status: ErrorColor;
  connectorIcon: string | null;
  customizedData?: { key: string; label: string; value: string }[];
  vertexHeight?: number;
  isSelected?: boolean;
  isLegend?: boolean;
  className?: string;
}

type Props = VertexData;

const PipelineVertex = (props: Props) => {
  const {
    schemaName,
    tableName,
    type,
    iconType,
    status,
    connectorIcon,
    customizedData = [],
    vertexHeight = VERTEX_HEIGHT,
    isSelected = false,
    isLegend = false,
    className = '',
  } = props;

  const style = getVertexStyle(type, status);
  const { bgColor, color, selectedBorderColor } = style;
  const borderColor = isSelected ? selectedBorderColor : DEFAULT_BORDER_COLOR;

  let encodedIcon = null;

  const FileIcon = (props: { src: string }) => (
    <img
      src={props.src}
      alt="table type icon"
      style={{
        width: `${ICON_SIZE}px`,
        height: `${ICON_SIZE}px`,
      }}
    />
  );

  const renderIconByType = (color: string) => {
    if (iconType === VertexShape.DBT) {
      encodedIcon = <FileIcon src="/images/bi_icons/dbt.svg" />;
    } else {
      encodedIcon = ICONS_BY_TYPE[iconType](color);
    }
    return encodedIcon;
  };

  /* 
    `getConnectorIcon` already has logic to determine when connector icon is applicable over using 
    status or generic type icons, so should use `connectorIcon` unless it's null
  */
  if (connectorIcon) {
    encodedIcon = <FileIcon src={connectorIcon} />;
  } else {
    const renderIconFn = ICONS_BY_STATUS[status] ?? renderIconByType;
    encodedIcon = renderIconFn(color);
  }

  const textContent = isLegend ? (
    <div
      style={{
        fontSize: LEGEND_FONT_SIZE,
        fontWeight: LABEL_FONT_WEIGHT,
      }}
    >
      {tableName}
    </div>
  ) : (
    <>
      <div
        style={{
          fontWeight: SUBLABEL_FONT_WEIGHT,
        }}
      >
        {schemaName}
      </div>
      <div
        style={{
          fontWeight: LABEL_FONT_WEIGHT,
        }}
      >
        {tableName}
      </div>
    </>
  );

  const width = isLegend ? 'auto' : `${VERTEX_WIDTH}px`;
  const cursor = isLegend ? 'auto' : 'pointer';
  const borderWidth = isSelected ? SELECTED_BORDER_WIDTH : BORDER_WIDTH;

  const textStyle = {
    color,
    fontSize: FONT_SIZE,
    fontFamily: 'Roboto, sans-serif',
    lineHeight: LINE_HEIGHT,
  };

  return (
    <div
      className={classNames('p-1', className)}
      style={{
        width,
        height: `${vertexHeight}px`,
        border: `${borderWidth}px solid ${borderColor}`,
        borderRadius: `${BORDER_RADIUS}px`,
        backgroundColor: bgColor,
        cursor,
      }}
    >
      <div className="f-row-y-center">
        <div className="p-2">{encodedIcon}</div>
        <div
          style={{
            ...textStyle,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            paddingRight: '8px',
          }}
        >
          {textContent}
        </div>
      </div>
      {/* TODO(pipeline-improv): Some vertices have current versions that are newer than the last completed
          run version, which can cause confusion when viewing customized data in the graph. Add a warning
          (in the node, beneath the customized data, or as a tooltip) to the graph to make this clear to the user.
      */}
      {customizedData.length > 0 ? (
        <div
          style={{
            ...textStyle,
            marginTop: '6px',
            padding: '6px 8px 8px',
            borderTop: `1px solid ${DEFAULT_BORDER_COLOR}`,
          }}
        >
          {customizedData.map(({ key, label, value }) => (
            <div key={key} className="whitespace-nowrap overflow-hidden text-ellipsis">
              <span
                style={{
                  fontWeight: LABEL_FONT_WEIGHT,
                }}
              >
                {label}:
              </span>{' '}
              {renderCustomizedDataValue(key, value)}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default memo(PipelineVertex);

const renderCustomizedDataValue = (key: string, value: string) => {
  if (key === 'description') {
    // TODO(pipeline-improv): If needed, create a tooltip component that works for Cytoscape instead
    //  of using native tooltip so styles match our PopperJS tooltip
    return <span title={value}>{value}</span>;
  }
  return value;
};
