/*
Attaches the sidebar to the side of the window with CSS position fixed.
We use this on pages like the Warehouse and QueryEditor that are too wide to
render the the sidebar beside the page's content.
Note, this component covers up any content rendered under the sidebar.
*/
import ControlledResizeableDrawer from 'components/layouts/containers/ControlledResizeableDrawer/ControlledResizeableDrawer';

import FloatingSidebarWrapper from './FloatingSidebarWrapper';
import { SidebarProps } from './usePickSidebar';

interface FixedSidebarLayoutProps {
  sidebarProps: SidebarProps;
  width: number;
  setWidth: (width: number) => void;
}

export default function FixedSidebarLayout(props: FixedSidebarLayoutProps) {
  const { sidebarProps, width, setWidth } = props;
  const { sidebar, minWidth, maxWidth } = sidebarProps || {};

  return (
    <ControlledResizeableDrawer
      show={true}
      width={width}
      setWidth={setWidth}
      minWidth={`${minWidth}px`}
      maxWidth={`${maxWidth}px`}
      shadow={false}
    >
      <FloatingSidebarWrapper>{sidebar}</FloatingSidebarWrapper>
    </ControlledResizeableDrawer>
  );
}
