import cn from 'classnames';

import Modal from 'components/layouts/containers/modals/Modal/Modal';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import FeedbackForm from 'pages/tables/ShowTable/SummaryTab/Description/AITableDescription/FeedbackForm';

export interface AIExplainSqlModalProps {
  isLoading: boolean;
  takingLonger: boolean;
  explanation: string;
  apiError: any;
  onClose: () => void;
  onFeedbackClick: (response: string) => void;
}

const AIExplainSqlModal = (props: AIExplainSqlModalProps) => {
  const { isLoading, takingLonger, explanation, apiError, onClose, onFeedbackClick } = props;

  const footer =
    isLoading || apiError ? null : (
      <div className="f-row-y-center w-full">
        <FeedbackForm onClick={onFeedbackClick} />
      </div>
    );

  const loadingContent = (
    <div className="f-col-center w-full h-full">
      <CenteredSpinner type="logo" spinnerSize="3rem" />
      <p className="text-center ml-2">Generating explanation...</p>
      <p className="text-center ml-2">
        Mozart AI is collecting metadata, so it may take some time to respond.
      </p>
      {takingLonger && <p className="text-center ml-2">This may take a while, thanks for waiting!</p>}
    </div>
  );

  const errorContent = (
    <div className="f-col-center w-full h-full">
      <Alert variant="error">There was an error generating an explanation. Please try again.</Alert>
    </div>
  );

  const content = <p className="p-4 whitespace-pre-line">{explanation}</p>;

  return (
    <Modal
      header="Mozart AI"
      footer={footer}
      cancelButton
      onClose={onClose}
      containerClass={cn(
        'w-full lg:w-[1023px] min-h-[300px] max-h-[80vh]',
        isLoading || apiError ? 'h-[300px]' : null,
      )}
    >
      {isLoading ? loadingContent : apiError ? errorContent : content}
    </Modal>
  );
};

export default AIExplainSqlModal;
