import React from 'react';

import ProgressBar from 'components/widgets/progress/ProgressBar/ProgressBar';
import { percentFormatted } from 'utils/Math';
import { formatNumber } from 'utils/String';

// import s from './ConnectorTable.module.css';

const PERCENTAGE_BAR_TOTAL_WIDTH = 160;

export interface ConnectorRow {
  connectorID: string;
  name: string;
  mar: number;
}

interface ConnectorTableProps {
  rows: ConnectorRow[];
  totalMar: number;
  onConnectorClick: (connectorID: string) => void;
}

export default function ConnectorTable(props: ConnectorTableProps) {
  const { rows, totalMar, onConnectorClick } = props;

  return (
    <table className="blueGrayHeaderTable">
      <thead>
        <tr>
          <th style={{ width: '50%' }}>Connector</th>
          <th style={{ width: '15%' }}>
            <div className="text-right pr-10">MAR</div>
          </th>
          <th style={{ width: '35%' }}>% of Total MAR</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row: ConnectorRow) => {
          return (
            <tr
              key={row.name}
              onClick={() => onConnectorClick(row.connectorID)}
              className="hover:cursor-pointer"
            >
              <td>{row.name}</td>
              <td>
                <div className="text-right pr-10">{formatNumber(row.mar)}</div>
              </td>
              <td>
                <div className="flex items-center">
                  <ProgressBar
                    numerator={row.mar}
                    denominator={totalMar}
                    containerClassName={`w-[${PERCENTAGE_BAR_TOTAL_WIDTH}px] h-[10px]`}
                  />
                  <div className="ml-2">{percentFormatted(row.mar, totalMar)}%</div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
