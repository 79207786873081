import React from 'react';

import cn from 'classnames';

import BackButton from 'components/inputs/basic/Button/BackButton';
import ProgressBar from 'components/widgets/progress/ProgressBar/ProgressBar';
import { percentFormatted } from 'utils/Math';
import { formatNumber } from 'utils/String';

// import s from './ConnectorTablesTable.module.css';

const PERCENTAGE_BAR_TOTAL_WIDTH = 160;

export interface TableRow {
  full_name: string;
  schema: string;
  name: string;
  mar: number;
}

interface ConnectorTablesTableProps {
  rows: TableRow[];
  connectorMar: number;
  totalMar: number;
  onBackClick: () => void;
}

export default function ConnectorTablesTable(props: ConnectorTablesTableProps) {
  const { rows, connectorMar, totalMar, onBackClick } = props;

  return (
    <div>
      <BackButton text="ALL CONNECTORS" onClick={onBackClick} />
      <table className={cn('blueGrayHeaderTable', 'mt-2')}>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Schema</th>
            <th style={{ width: '20%' }}>Table</th>
            <th style={{ width: '14%' }}>
              <div className="text-right pr-10">MAR</div>
            </th>
            <th style={{ width: '23%' }}>% of Connector MAR</th>
            <th style={{ width: '23%' }}>% of Total MAR</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row: TableRow) => {
            return (
              <tr key={row.full_name}>
                <td>{row.schema}</td>
                <td>{row.name}</td>
                <td>
                  <div className="text-right pr-10">{formatNumber(row.mar)}</div>
                </td>
                <td>
                  <div className="flex items-center">
                    <ProgressBar
                      numerator={row.mar}
                      denominator={connectorMar}
                      containerClassName={`w-[${PERCENTAGE_BAR_TOTAL_WIDTH}px] h-[10px]`}
                    />
                    <div className="ml-2">{percentFormatted(row.mar, connectorMar)}%</div>
                  </div>
                </td>
                <td>
                  <div className="flex items-center">
                    <ProgressBar
                      numerator={row.mar}
                      denominator={totalMar}
                      containerClassName={`w-[${PERCENTAGE_BAR_TOTAL_WIDTH}px] h-[10px]`}
                    />
                    <div className="ml-2">{percentFormatted(row.mar, totalMar)}%</div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
