import React from 'react';

import { NotificationContext, NotificationContextInterface } from 'context/NotificationContext';
import {
  CompanySetupGuideContext,
  CompanySetupGuideContextInterface,
} from 'model_layer/CompanySetupGuideContext';
import {
  OnboardingPopoverTourContext,
  OnboardingPopoverTourContextInterface,
} from 'model_layer/OnboardingPopoverTourContext';
import { SearchColumnContext, SearchColumnContextInterface } from 'model_layer/SearchColumnContext';
import { TableContext, TableContextInterface } from 'model_layer/TableContext';
import { TableModelsContext, TableModelsContextInterface } from 'model_layer/TableModelsContext';
import {
  UserPreferencesContext,
  UserPreferencesContextInterface,
} from 'model_layer/UserPreferencesContext';

interface AbstractAuthenticatedProvidersProps {
  userPreferencesContext: UserPreferencesContextInterface;
  notificationsContext: NotificationContextInterface;
  tableContext: TableContextInterface;
  tableModelsContext: TableModelsContextInterface;
  searchColumnContext: SearchColumnContextInterface;
  onboardingPopoverTourContext: OnboardingPopoverTourContextInterface;
  companySetupGuideContext: CompanySetupGuideContextInterface;
  AIPermissionModalProvider: React.FC<{ children: React.ReactNode }>;
  children: React.ReactNode;
}

export default function AbstractAuthenticatedProviders(props: AbstractAuthenticatedProvidersProps) {
  const {
    userPreferencesContext,
    notificationsContext,
    tableContext,
    tableModelsContext,
    searchColumnContext,
    onboardingPopoverTourContext,
    companySetupGuideContext,
    AIPermissionModalProvider,
    children,
  } = props;

  return (
    <AIPermissionModalProvider>
      <UserPreferencesContext.Provider value={userPreferencesContext}>
        <NotificationContext.Provider value={notificationsContext}>
          <TableContext.Provider value={tableContext}>
            <TableModelsContext.Provider value={tableModelsContext}>
              <SearchColumnContext.Provider value={searchColumnContext}>
                <OnboardingPopoverTourContext.Provider value={onboardingPopoverTourContext}>
                  <CompanySetupGuideContext.Provider value={companySetupGuideContext}>
                    {children}
                  </CompanySetupGuideContext.Provider>
                </OnboardingPopoverTourContext.Provider>
              </SearchColumnContext.Provider>
            </TableModelsContext.Provider>
          </TableContext.Provider>
        </NotificationContext.Provider>
      </UserPreferencesContext.Provider>
    </AIPermissionModalProvider>
  );
}
