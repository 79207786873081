import { useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom-v5-compat';

import qs from 'query-string';
import * as Yup from 'yup';

import { useAuth } from 'context/AuthContext';
import { deslug } from 'utils/Form';
import { getAnonymousId } from 'utils/osanoCountermeasures';

const SERVER_ERROR_SLUGS = {
  email_already_in_use: 'An account with that email already exists. Please login instead.',
  company_already_exists:
    'An account for your company already exists. Please contact a Mozart Data admin at your company or support@mozartdata.com.',
  invalid_password: 'Passwords must contain at least 8 characters.',
};

// const PHONE_NUMBER_REGEX = /^[0-9()+\-.\x20]{6,20}$/;

const signupSchema = Yup.object().shape({
  first_name: Yup.string().required('Required').trim(),
  last_name: Yup.string().required('Required').trim(),
  email: Yup.string().email('Invalid email').trim().lowercase().required('Required'),
  title: Yup.string().required('Required').trim(),
  company_name: Yup.string().required('Required').trim(),
  // 01/07/2023 - Hid company_size on the signup form.
  // company_size: Yup.string().required('Required').trim(),
  company_size: Yup.string().nullable().trim(),
  company_funding: Yup.string().nullable().trim(),
  marketing_referral: Yup.string().required('Required').trim(),
  // 01/07/2023 - Hid phone_number on the signup form.
  // phone_number: Yup.string().trim().matches(PHONE_NUMBER_REGEX, {
  //   message: 'Please enter a valid phone number.',
  //   excludeEmptyString: true,
  // }),
  phone_number: Yup.string().nullable().trim(),
  agree_to_terms: Yup.boolean().test(
    'is-agreed',
    'You must agree to the terms and conditions.',
    (value) => value === true,
  ),
});

const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  title: '',
  company_name: '',
  company_size: null,
  company_funding: null,
  marketing_referral: '',
  phone_number: null,
  agree_to_terms: false,
};

export function useSignup() {
  const [error, setError] = useState('');
  const { signup } = useAuth();
  const location = useLocation();

  // The initial form values are either default values or values passed in from the URL query parameters.
  const initialValues = useMemo(() => {
    const search = qs.parse(location.search);
    const initialValues = {
      ...defaultValues,
      first_name: search?.first_name || defaultValues.first_name,
      last_name: search?.last_name || defaultValues.last_name,
      email: search?.email || defaultValues.email,
      title: search?.title || defaultValues.title,
      company_name: search?.company_name || defaultValues.company_name,
      company_size: search?.company_size || defaultValues.company_size,
      company_funding: search?.company_funding || defaultValues.company_funding,
      marketing_referral: search?.marketing_referral || defaultValues.marketing_referral,
      phone_number: search?.phone_number || defaultValues.phone_number,
      marketplace_account_id: '',
    };
    return initialValues;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Record any tracking parameters passed in from the URL query parameters.
  const trackingParams = useMemo(() => {
    const search = qs.parse(location.search);
    const trackingParams = {
      // UTM Params
      utm_source: search?.utm_source || null,
      utm_medium: search?.utm_medium || null,
      utm_campaign: search?.utm_campaign || null,
      utm_term: search?.utm_term || null,
      utm_content: search?.utm_content || null,
      // Segment ID
      anonymous_id: getAnonymousId(),
    };

    return trackingParams;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmit = async (values: any, actions: any) => {
    analytics.track('Signup SignupAttempted');
    const signupValues: any = {
      ...signupSchema.cast(values),
      ...trackingParams,
    };
    // don't send agree_to_terms field to BE, this if FE validation
    delete signupValues.agree_to_terms;
    setError('');
    try {
      await signup(signupValues);
    } catch (error: any) {
      // Form validation errors
      if (error.response?.status === 400) {
        const data = error.response.data;
        setError(data.non_field_errors);
        actions.setErrors(deslug(data, SERVER_ERROR_SLUGS));
        analytics.track('Signup SignupServerFormValidationError');
      }
      // Something went very wrong. Think HTTP 500 and server stack traces
      else {
        setError('There was a problem creating your account.');
        analytics.track('Signup SignupServerError');
      }
    }
  };

  return {
    initialValues,
    signupSchema,
    handleFormSubmit,
    error,
  };
}
