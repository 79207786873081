import React, { ForwardedRef } from 'react';

import cn from 'classnames';
import classNames from 'classnames';

import Button, { ButtonProps, ButtonVariant, ButtonSize } from './Button';
import { DISABLED_CHEVRON_COLOR, ENABLED_CHEVRON_COLOR } from './ButtonMenu';
// If you need to add a new icon to the list of icons included in the build,
// add it to the list in this file.
import usedIcons from './IconButtonUsedIcons';

import s from './Button.module.css';

export type IconKey = keyof typeof usedIcons;

/*Either an IconKey (used for react-bootstrap-icons) or an SVG URL (used for custom icons) is required */
type IconKeyOrCustomIcon =
  | {
      icon: IconKey;
    }
  | {
      customIcon: React.ElementType;
    };

export type IconButtonProps = Omit<ButtonProps, 'children' | 'ref'> &
  IconKeyOrCustomIcon & {
    // TODO(jesus): To make buttons more accessible, make text a required prop after adding
    // sr-only text to all buttons that currently don't have text.
    text?: string;
    /** Hide text on screen - only for screen readers */
    textSrOnly?: boolean;
    iconLast?: boolean;
    iconColor?: string;
    iconSize?: number;
  };

// export type IconButtonProps = IconRequiredProp & IconButtonPartialProps;

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props: IconButtonProps, forwardedRef: ForwardedRef<HTMLButtonElement>) => {
    let {
      text,
      textSrOnly,
      iconLast,
      iconColor,
      iconSize,
      size,
      variant,
      disabled,
      className,
      ...buttonProps
    } = props;

    // We want to make sure that icon and customIcon are not passed down to the Button component.
    const { icon, customIcon, ...cleanButtonProps } = buttonProps as any;

    // Fix default values if they are undefined.
    size = size || 'large';
    variant = variant || 'lightAction';
    disabled = disabled || false;

    const actualClass = cn(className, s.icon);

    return (
      <Button
        ref={forwardedRef}
        size={size}
        variant={variant}
        disabled={disabled}
        className={actualClass}
        {...cleanButtonProps}
      >
        {'icon' in props ? (
          <IconButtonContent
            icon={props.icon}
            text={text}
            textSrOnly={textSrOnly}
            iconLast={iconLast}
            iconColor={iconColor}
            iconSize={iconSize}
            size={size}
            variant={variant}
            disabled={disabled}
          />
        ) : (
          <IconButtonContent
            customIcon={props.customIcon}
            text={text}
            textSrOnly={textSrOnly}
            iconLast={iconLast}
            iconColor={iconColor}
            iconSize={iconSize}
            size={size}
            variant={variant}
            disabled={disabled}
          />
        )}
      </Button>
    );
  },
);

export default IconButton;

export type IconButtonContentProps = IconKeyOrCustomIcon & {
  // TODO(jesus): To make buttons more accessible, make text a required prop after adding
  // sr-only text to all buttons that currently don't have text.
  text?: string;
  textSrOnly?: boolean;
  size: ButtonSize;
  variant: ButtonVariant;
  disabled: boolean;
  iconLast?: boolean;
  iconColor?: string;
  iconSize?: number;
};

const IconButtonContent = (props: IconButtonContentProps) => {
  let { text, textSrOnly, iconLast, iconColor, iconSize, size, variant, disabled } = props;

  if (!iconColor) {
    iconColor = disabled ? DISABLED_CHEVRON_COLOR[variant] : ENABLED_CHEVRON_COLOR[variant];
  } else if (iconColor === 'DO_NOT_SET') {
    iconColor = undefined;
  }
  if (!iconSize) {
    iconSize = size === 'large' || size === 'form' ? 16 : 14;
  }
  const iconProps = {
    color: iconColor,
    size: iconSize,
  };

  const IconTag = 'icon' in props ? usedIcons[props.icon] : props.customIcon;
  const iconComponent = <IconTag {...iconProps} />;

  return (
    <div className={classNames('f-center', iconLast ? 'flex-row-reverse' : null)}>
      {iconComponent}
      {text && (
        <div className={classNames(iconLast ? 'mr-[5px]' : 'ml-[5px]', textSrOnly && 'sr-only')}>
          {text}
        </div>
      )}
    </div>
  );
};

export { IconButtonContent };
