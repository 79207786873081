import { memo } from 'react';

import Listbox, { ListboxOption, ListboxValue } from 'components/inputs/basic/Listbox/Listbox';

import { TableColumn } from '../model/FlowchartQueryModel';

export interface ColumnInputProps {
  value: string;
  columnValues: TableColumn[];
  containerClass?: string;
  onChange: (value: string) => void;
}

const ColumnInput = memo((props: ColumnInputProps) => {
  const { columnValues, onChange, ...remainingProps } = props;
  const options: ListboxOption[] = columnValues.map((c) => ({
    label: c.name,
    value: c.name,
  }));
  const myOnChange = (newVal: ListboxValue) => {
    onChange(newVal as string);
  };
  // MAYBE TODO: Render Listbox options with custom rows that show type and description.
  return <Listbox options={options} onChange={myOnChange} {...remainingProps} />;
});

export default ColumnInput;
