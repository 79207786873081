import React from 'react';

import {
  ArrowClockwise,
  CheckCircle,
  DashCircle,
  HourglassSplit,
  PauseCircle,
  XCircle,
} from 'react-bootstrap-icons';

import cn from 'classnames';

import s from './StatusIcon.module.css';

/** Encompasses all types of status. Note that `broken` and `incomplete` are sub-statuses of `warning`
 * and are used for Connectors instead of `warning`.
 */
export type Status =
  | 'NA'
  | 'success'
  | 'error'
  | 'warning'
  | 'broken'
  | 'incomplete'
  | 'paused'
  | 'waiting'
  | 'syncing';
export interface StatusIconProps {
  status: Status;
  className?: string;
  rotate?: boolean;
}

const COLORS = {
  NA: 'text-pri-gray-400',
  success: 'border-pri-success-600',
  error: 'border-pri-error-700',
  warning: 'border-pri-warning-600',
  broken: 'border-pri-warning-600',
  incomplete: 'border-pri-warning-600',
  paused: 'border-pri-gray-400',
  waiting: 'border-pri-gray-400',
  syncing: 'border-sec-blue-light-600',
};

export default function StatusIcon(props: StatusIconProps) {
  let { status, className, rotate } = props;

  const colorClass = COLORS[status];
  const actualClass = cn(colorClass, className, {
    [s.syncRotate]: rotate && status === 'syncing',
  });

  if (status === 'NA') {
    return <div className={actualClass}>N/A</div>;
  }
  if (status === 'success') {
    return <CheckCircle color="var(--pri-success-600)" size={18} className={actualClass} />;
  }
  if (status === 'error') {
    return <XCircle color="var(--pri-error-600)" size={18} className={actualClass} />;
  }
  if (status === 'warning' || status === 'incomplete' || status === 'broken') {
    return <DashCircle color="var(--pri-warning-600)" size={18} className={actualClass} />;
  }
  if (status === 'paused') {
    return <PauseCircle color="var(--pri-gray-400)" size={18} className={actualClass} />;
  }
  if (status === 'waiting') {
    return <HourglassSplit color="var(--pri-gray-400)" size={18} className={actualClass} />;
  }
  return <ArrowClockwise color="var(--sec-blue-light-600)" size={18} className={actualClass} />;
}
