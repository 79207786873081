/*
Functions used by RunResultsTable.tsx
*/
import { Column } from 'api/columnAPI';
import { formatIso, formatDBDate, formatDBTime, agoIso } from 'utils/dateTime';

import { TimeFormat } from './RunResults';

export const formatTimestamp = (val: string, timeFormat: TimeFormat) => {
  if (timeFormat === 'HUMAN') {
    return formatIso(val);
  }
  if (timeFormat === 'AGO') {
    return agoIso(val);
  }
  return val;
};

export const formatDate = (val: string, timeFormat: TimeFormat) => {
  if (timeFormat === 'HUMAN') {
    return formatDBDate(val);
  }
  if (timeFormat === 'AGO') {
    return agoIso(val);
  }
  return val;
};

export const formatTime = (val: string, timeFormat: TimeFormat) => {
  if (timeFormat === 'HUMAN') {
    return formatDBTime(val);
  }

  // `timeFormat === 'AGO'` does not make logical sense on times because the day is unknown.
  // How do you know if the time is in the past or the future?
  // How many days in the past is it?
  // So just return val.
  return val;
};

// Returns the list of column indexes that have at least one non-empty cell
const filterEmptyIndexes = (indexes: number[], rows: any[][]) => {
  let indexList: number[] = [];
  for (let i = 0; i < indexes.length; i++) {
    const currentIndex = indexes[i];
    let hasIndex = false;
    for (let j = 0; j < rows.length; j++) {
      const v = rows[j][currentIndex];
      if (v !== null && v !== '') {
        hasIndex = true;
        break;
      }
    }
    if (hasIndex) {
      indexList.push(i);
    }
  }
  return indexList;
};

// Only return columns indexes in the selectedColumns list.
// Also return the columns indexes in the selectedColumns list order.
const filterBySelectedIndexes = (indexes: number[], selectedColumns: string[], columns: Column[]) => {
  let selectedIndexes: number[] = [];

  // Select selectedIndexes
  for (let i = 0; i < indexes.length; i++) {
    const currentIndex = indexes[i];
    const currentName = columns[currentIndex].name;
    if (selectedColumns.includes(currentName)) {
      selectedIndexes.push(i);
    }
  }

  // Sort selectedIndexes by selectedColumns order
  selectedIndexes.sort((a, b) => {
    const aName = columns[a].name;
    const bName = columns[b].name;
    const aIndex = selectedColumns.indexOf(aName);
    const bIndex = selectedColumns.indexOf(bName);
    return aIndex - bIndex;
  });

  return selectedIndexes;
};

const allIndexes = (columns: Column[]) => {
  let indexList: number[] = [];
  for (let i = 0; i < columns.length; i++) {
    indexList.push(i);
  }
  return indexList;
};

export const findColumnIndexes = (
  rows: any[][],
  columns: Column[],
  hideEmptyColumns: boolean,
  selectedColumns: string[],
  hideUnselectedColumns: boolean,
) => {
  // By default return all indexes in the order the DB returns them in.
  let indexes = allIndexes(columns);

  // If hiding unselected columns:
  // Only return columns indexes in the selectedColumns list.
  // Also return the columns indexes in the selectedColumns list order.
  if (hideUnselectedColumns) {
    indexes = filterBySelectedIndexes(indexes, selectedColumns, columns);
  }

  // Do not filter empty columns if there are no rows
  if (hideEmptyColumns && rows.length !== 0) {
    indexes = filterEmptyIndexes(indexes, rows);
  }

  return indexes;
};
