import { Field } from './fieldUtils';
import { DatabaseConfigInfoResponse } from './IntegrationSnowflakeConfig';
import SnowflakeConfigList from './SnowflakeConfigList';

export interface SnowflakeConfigInfoProps {
  fields: Field[];
  databaseDisplayName?: string;
  additionalFields?: Field[];
  additionalFieldsHeader?: string;
  values: DatabaseConfigInfoResponse | undefined;
}

const SnowflakeConfig = (props: SnowflakeConfigInfoProps) => {
  const { fields, additionalFields, additionalFieldsHeader, values, databaseDisplayName } = props;

  return (
    <>
      {values && (
        <>
          <SnowflakeConfigList
            header={`Your ${databaseDisplayName || 'Snowflake'} warehouse credentials`}
            fields={fields}
            values={values}
          />
          {additionalFields && (
            <SnowflakeConfigList
              header={additionalFieldsHeader || ''}
              fields={additionalFields}
              values={values}
              className="mt-5"
            />
          )}
        </>
      )}
    </>
  );
};

export default SnowflakeConfig;
