import { useState } from 'react';

import { InAppNotification } from 'pages/notifications/NotificationHelpers';

// FYI: Polling for notifications is done in NotificationDropdown.tsx, but the global state is here.
export default function useNotificationContext() {
  const [updatedNotifications, setUpdatedNotifications] = useState<InAppNotification[]>([]);

  return {
    updatedNotifications,
    setUpdatedNotifications,
  };
}
