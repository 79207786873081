import React, { useMemo } from 'react';

import { Form, Formik } from 'formik';

import Button from 'components/inputs/basic/Button/Button';
import RadioFormikGroup from 'components/inputs/formik_group/RadioFormikGroup/RadioFormikGroup';
import LoggedOutFormBackgroundLayout from 'components/layouts/pages/LoggedOutFormBackgroundLayout/LoggedOutFormBackgroundLayout';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';

import history from '../../../appHistory';

import { SetupProps } from '../useSetupWarehouse';

import DatabaseOptions from './DatabaseOptions';

interface SelectWarehouseViewProps {
  showUnmanagedOptions: boolean;
  checkIsLoading: boolean;
  setupInProgress: boolean;
  setupError: string;
  startSetupSnowflake: (setupProps: SetupProps) => void;
  goToUnmanagedOptions: () => void;
  goBackToManagedOption: () => void;
}

export default function SelectWarehouseView(props: SelectWarehouseViewProps) {
  const {
    showUnmanagedOptions,
    checkIsLoading,
    setupInProgress,
    setupError,
    startSetupSnowflake,
    goToUnmanagedOptions,
    goBackToManagedOption,
  } = props;

  const handleFormSubmit = (values: any) => {
    if (values.warehouse_type === 'unmanaged') {
      goToUnmanagedOptions();
    } else if (values.warehouse_type === 'managed') {
      analytics.track(`Signup SelectMozartWarehouse`);
      startSetupSnowflake({ warehouse_type: 'managed' });
    } else if (values.warehouse_type === 'byos') {
      analytics.track(`Signup SelectBYOSWarehouse`);
      history.push('/setup-snowflake');
    } else {
      analytics.track(`Signup SelectBYOBQWarehouse`);
      history.push('/setup-bigquery');
    }
  };

  // BE CAREFUL: Valid but unusual programming pattern here.
  // This code injects a different set of options into one view with one <RadioFormikGroup/>
  // depending on the state of `showUnmanagedOptions`.
  // This simulates there being two views each with its own <RadioFormikGroup/>.
  // TODO: After feature flag is removed, clean up this useMemo to no longer include old options
  // useMemo to handle changing radio options on the page
  const databaseChoices = useMemo(() => {
    // Show the two options for Snowflake and BigQuery for user to choose between
    if (showUnmanagedOptions) {
      return [
        {
          label: (
            <div>
              <p>Snowflake</p>
            </div>
          ),
          value: 'byos',
        },
        {
          label: (
            <div>
              <p>BigQuery</p>
            </div>
          ),
          value: 'byobq',
        },
      ];
    } else {
      // Show the options for managed snowflake setup or if the user has a database
      //   the other option will lead to a choice between data warehouses
      return [
        {
          label: (
            <div>
              <p className="font-medium text-primary-600">RECOMMENDED</p>
              <p>Mozart Data will set up and manage a Snowflake account for you.</p>
            </div>
          ),

          value: 'managed',
        },
        {
          label: (
            <div>
              <p>I already have a Snowflake or BigQuery account.</p>
            </div>
          ),
          value: 'unmanaged',
        },
      ];
    }
  }, [showUnmanagedOptions]);

  return (
    <LoggedOutFormBackgroundLayout>
      <div className="w-[940px] h-full flex justify-center items-center mt-6 mx-auto ">
        <img className="max-w-[320px]" src={'/images/graphics/databaseSelection.svg'} alt="" />
        <div className="w-1/2 ml-24">
          <h1 className="display-sm font-bold text-primary-600 text-center">Welcome to Mozart Data!</h1>
          {checkIsLoading ? (
            <div className="mt-8 text-center">
              <h3 className="text-xl font-medium">Checking database setup.</h3>
              <CenteredSpinner containerMinHeight="100px" />
            </div>
          ) : setupInProgress ? (
            <div className="mt-8 text-center">
              <h3 className="text-xl font-medium">Setting up your account.</h3>
              <p className="text-xl">This typically takes 60 seconds.</p>
              <CenteredSpinner containerMinHeight="100px" />
            </div>
          ) : (
            <Formik onSubmit={handleFormSubmit} initialValues={{ warehouse_type: 'managed' }}>
              {({ setFieldValue, handleSubmit, values }) => {
                // Disable the next button if we are not selecting a data warehouse when asked which one they have
                const disableNextButton =
                  showUnmanagedOptions && !['byos', 'byobq'].includes(values.warehouse_type);
                return (
                  <Form onSubmit={handleSubmit} className="flex justify-end flex-col">
                    <h2 className="text-center text-xl pt-8 pb-4">
                      {showUnmanagedOptions
                        ? 'Which type of data warehouse do you have?'
                        : "First, let's connect to a data warehouse!"}
                    </h2>
                    <RadioFormikGroup
                      name="warehouse_type"
                      options={databaseChoices}
                      optionsMarkup={(parentProps: any) => {
                        return (
                          <DatabaseOptions
                            options={databaseChoices}
                            setFieldValue={setFieldValue}
                            {...{ ...parentProps }}
                          />
                        );
                      }}
                    />
                    {setupError && (
                      <Alert variant="error" className="mt-4">
                        {setupError}
                      </Alert>
                    )}
                    <div className="f-row-y-center mt-4 inline-block self-end">
                      {showUnmanagedOptions && (
                        <Button className="mr-4" onClick={goBackToManagedOption}>
                          Back
                        </Button>
                      )}
                      <Button type="submit" disabled={disableNextButton}>
                        Next
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </LoggedOutFormBackgroundLayout>
  );
}
