import React, { useEffect, useState } from 'react';

import API from 'api/API';
import { DbtRunConfig } from 'api/dbtAPI';
import Button from 'components/inputs/basic/Button/Button';
import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import { useUserProfile } from 'context/AuthContext';

import RunLogModal from './RunLogModal';
import DBTRunTable from './RunTable';
import usePaginatedRuns from './usePaginatedRuns';
import useRunLog from './useRunLog';

interface RunTabProps {
  runConfig: DbtRunConfig;
  runID?: string;
}

const RunTab = (props: RunTabProps) => {
  const { runConfig, runID } = props;
  const [runNowSpinning, setRunNowSpinning] = useState(false);
  const [runNowError, setRunNowError] = useState('');
  const [fetchRunError, setFetchRunError] = useState('');

  const {
    userProfile: { company_role },
  } = useUserProfile();

  const {
    loading: paginatedRunsLoading,
    error: paginatedRunsError,
    paginatedRuns,
    handleLoadPreviousPage,
    handleLoadNextPage,
    handleSetRuns,
  } = usePaginatedRuns(runConfig);

  const {
    fetchingLogs,
    currentLogRun,
    logs,
    handleAPIUpdateCurrentLogRun,
    handleOpenLogs,
    handleCloseLogs,
    handleCopyLogs,
    handleDownloadLogs,
  } = useRunLog(runConfig, paginatedRunsLoading, paginatedRuns.results, setFetchRunError, runID);

  // If the DbtRun polling updates the currentRunLog,
  // make sure we update that object so users sees the updated status in the RunLogModal.
  useEffect(() => {
    if (currentLogRun) {
      const updatedRun = paginatedRuns.results.find((r) => r.id === currentLogRun.id);
      if (updatedRun) {
        handleAPIUpdateCurrentLogRun(updatedRun);
      }
    }
  }, [currentLogRun, paginatedRuns.results, handleAPIUpdateCurrentLogRun]);

  const handleRunNow = (runConfig: DbtRunConfig) => {
    setRunNowSpinning(true);
    setRunNowError('');
    const api = new API();
    api
      .post(`api/dbt_runs`, {
        configuration: runConfig.id,
        commands: runConfig.commands,
      })
      .then((response) => {
        handleSetRuns([response.data, ...paginatedRuns.results]);
        analytics.track('ShowDBTRunConfigRunTab RunNow');
      })
      .catch(() => {
        setRunNowError('Failed to run this dbt job.');
      })
      .finally(() => {
        setRunNowSpinning(false);
      });
  };

  const anyError = paginatedRunsError || runNowError || fetchRunError;

  return (
    <TabLayout loading={paginatedRunsLoading} error={anyError}>
      {company_role !== 'viewer' && (
        <Button
          size="small"
          variant="darkDullAction"
          onClick={() => handleRunNow(runConfig)}
          spinning={runNowSpinning}
          className="mt-2"
        >
          Run Manually
        </Button>
      )}

      <DBTRunTable
        paginatedRuns={paginatedRuns}
        onLoadPreviousPage={handleLoadPreviousPage}
        onLoadNextPage={handleLoadNextPage}
        onViewLogs={handleOpenLogs}
      />

      {currentLogRun && (
        <RunLogModal
          loading={fetchingLogs}
          run={currentLogRun}
          logs={logs || []}
          onClose={handleCloseLogs}
          onCopyLogs={handleCopyLogs}
          onDownloadLogs={handleDownloadLogs}
        />
      )}
    </TabLayout>
  );
};

export default RunTab;
