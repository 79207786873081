import { useState } from 'react';

import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import { Connector } from 'api/APITypes';
import { Dashboard, ReportAvailable } from 'api/dashboardAPI';

import { trackConnector } from '../../ConnectorUtils';

import { laterPath, reviewTransformsPath } from '../pathUtils';
import { trackDashboard } from '../reportUtils';
import useDashboardMutations from '../useDashboardMutations';

import PreviewReportView from './PreviewReportView';

interface PreviewReportControllerProps {
  connector: Connector;
  reportAvailable: ReportAvailable;
}

export default function PreviewReportController(props: PreviewReportControllerProps) {
  useTitle('Preview Report');
  const { connector, reportAvailable } = props;
  const navigate = useNavigate();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { createLoading, createError, createDashboard } = useDashboardMutations(connector);

  const handleLater = () => {
    trackConnector('ReportBuilderPreviewReport GoToLater', connector);
    navigate(laterPath(connector));
  };

  const handleCreate = () => {
    createDashboard([reportAvailable]).then((dashboard: Dashboard) => {
      trackDashboard('ReportBuilderPreviewReport CreateDashboard', connector, dashboard);
      navigate(reviewTransformsPath(connector));
    });
  };

  const handleShowPreviewModal = () => {
    setShowPreviewModal(true);
    trackConnector('ReportBuilderPreviewReport OpenPreviewModal', connector);
  };
  const handleHidePreviewModal = () => {
    setShowPreviewModal(false);
    trackConnector('ReportBuilderPreviewReport ClosePreviewModal', connector);
  };

  return (
    <PreviewReportView
      connector={connector}
      reportAvailable={reportAvailable}
      showPreviewModal={showPreviewModal}
      createLoading={createLoading}
      createError={createError}
      onShowPreviewModal={handleShowPreviewModal}
      onHidePreviewModal={handleHidePreviewModal}
      onLater={handleLater}
      onCreate={handleCreate}
    />
  );
}
