import { AggTable } from 'api/APITypes';
import {
  MOZART_ALL,
  MOZART_RECENT,
  MOZART_FAVORITES,
  MOZART_TRANSFORMS,
  MOZART_SNAPSHOTS,
  VirtualSchemaType,
} from 'pages/Warehouse/DatabaseSearch2/DatabaseSearchReducer';

import { IconSize } from '../ImgIcon/ImgIcon';
import SchemaIcon, { ConnectorIconType, SchemaIconType } from '../SchemaIcon/SchemaIcon';

export interface SchemaIconForDatabaseSearchProps {
  virtualSchemaType: VirtualSchemaType;
  schema: string;
  tables: AggTable[];
  size: IconSize;
  variant?: 'teal' | 'dullAction';
}

/*
Picks the schema icon type based on the virtualSchemaType passed in by the DatabaseSearchReducer OR
the type of tables in the schema.
*/
export default function SchemaIconForDatabaseSearch(props: SchemaIconForDatabaseSearchProps) {
  const { virtualSchemaType, schema, tables, ...rest } = props;
  const type = getSchemaType(virtualSchemaType, schema, tables);
  return <SchemaIcon type={type} {...rest} />;
}

export function getSchemaType(
  virtualSchemaType: VirtualSchemaType,
  schema: string,
  tables: AggTable[],
): SchemaIconType {
  if (
    virtualSchemaType === MOZART_ALL ||
    virtualSchemaType === MOZART_RECENT ||
    virtualSchemaType === MOZART_FAVORITES
  ) {
    return virtualSchemaType;
  } else if (tables.length && tables[0].type === 'dbt') {
    return 'dbt';
  } else if (virtualSchemaType === MOZART_TRANSFORMS || virtualSchemaType === MOZART_SNAPSHOTS) {
    return virtualSchemaType;
  } else if (tables.length && tables[0].type === 'csv_upload') {
    return 'csv_upload';
  } else {
    let service = '';
    // All tables in a schema have the same connector type but not necessarily the same connector instance.
    // So the schema icon is the same as the connector icon of the first table.
    if (tables.length && tables[0].connector) {
      service = tables[0].connector.service;
    }
    const connectorType: ConnectorIconType = {
      service,
    };
    if (service === '' && schema === 'information_schema') {
      return 'info_schema';
    }
    return connectorType;
  }
}
