import { RouteComponentProps } from 'react-router-dom';

import history from '../../../appHistory';

import useSetupWarehouse, { ByobqFormProps, ByobqSetupProps } from '../useSetupWarehouse';

import SetupBYOBQView from './SetupBYOBQView';

interface MatchParams {}
interface SetupBYOSControllerProps extends RouteComponentProps<MatchParams> {}

const SetupBYOBQController = (props: SetupBYOSControllerProps) => {
  const { checkIsLoading, startSetupWarehouse, setupInProgress, setupError } = useSetupWarehouse(
    'byos',
    'SaveBYOBQCredentials',
  );

  const handleGoBack = () => {
    analytics.track(`Signup BYOBQWarehouseGoBack`);
    history.push('/select-warehouse');
  };

  return (
    <SetupBYOBQView
      error={setupError}
      isLoading={checkIsLoading || setupInProgress}
      handleSubmit={(values: ByobqFormProps) => {
        const setupProps: ByobqSetupProps = { ...values, warehouse_type: 'byobq' };
        startSetupWarehouse(setupProps);
      }}
      handleGoBack={handleGoBack}
    />
  );
};

export default SetupBYOBQController;
