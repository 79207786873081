import React from 'react';

import cn from 'classnames';

import s from './Checkbox.module.css';

export interface CheckboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  checked: boolean;
  variant?: 'purple' | 'blue_gray';
  id?: string;
  label?: string;
  containerClass?: string;
  labelClass?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export default function Checkbox(props: CheckboxProps) {
  let {
    name,
    checked,
    disabled,
    variant = 'purple',
    id,
    label,
    className,
    containerClass,
    labelClass,
    onChange,
    ...remainingProps
  } = props;

  // Sanitize inputs
  const hasLabel = !!label;
  const safeID = id || Math.random().toString();

  const defaultContainerClass = 'inline h-6 flex items-center';
  const actualContainerClass = cn(defaultContainerClass, containerClass);

  let defaultInputClass = `
  w-4 h-4 m-1
  rounded-sm
  checked:rounded-sm
  focus:ring-4
  focus:ring-offset-0
  bg-none`;
  if (!disabled) {
    defaultInputClass += ' hover:cursor-pointer';
  }

  const hasLabelClass = 'mr-2';

  const purpleClass = `
  ${s.purple}
  border-pri-gray-500
  checked:border-purple
  focus:border-purple
  hover:border-purple
  focus:ring-sec-purple-200`;

  const purpleDisabledClass = `
  ${s.purpleDisabled}
  bg-pri-gray-100
  border-pri-gray-300
  checked:border-pri-gray-300
  focus:border-pri-gray-300
  hover:border-pri-gray-300
  focus:ring-sec-pri-gray-300`;

  const blueGrayClass = `
  ${s.blueGray}
  border-pri-gray-500
  checked:border-sec-blue-gray-500
  focus:border-sec-blue-gray-500
  hover:border-sec-blue-gray-500
  focus:ring-sec-blue-gray-200`;

  const blueGrayDisabledClass = `
  ${s.blueGrayDisabled}
  bg-white
  border-sec-blue-gray-200
  checked:border-sec-blue-gray-200
  focus:border-sec-blue-gray-200
  hover:border-sec-blue-gray-200
  focus:ring-sec-blue-gray-200`;

  const actualInputClass = cn(defaultInputClass, className, s.input, {
    [hasLabelClass]: hasLabel,
    [purpleClass]: variant === 'purple' && !disabled,
    [blueGrayClass]: variant === 'blue_gray' && !disabled,
    [purpleDisabledClass]: variant === 'purple' && !!disabled,
    [blueGrayDisabledClass]: variant === 'blue_gray' && !!disabled,
  });

  const defaultLabelClass = disabled ? 'text-pri-gray-400' : 'text-pri-gray-700 hover:cursor-pointer';
  const actualLabelClass = cn(defaultLabelClass, labelClass);

  return (
    <div className={actualContainerClass}>
      <input
        type="checkbox"
        id={safeID}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        className={actualInputClass}
        {...remainingProps}
      />
      {label && (
        <label htmlFor={safeID} className={actualLabelClass}>
          {label}
        </label>
      )}
    </div>
  );
}
