import { Formik, FormikHelpers, FormikValues } from 'formik';

import Button from 'components/inputs/basic/Button/Button';
import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import ComboboxFormikGroup from 'components/inputs/formik_group/ComboboxFormikGroup/ComboboxFormikGroup';
// import ListboxFormikGroup from 'components/inputs/formik_group/ListboxFormikGroup/ListboxFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import FormLayoutTitleRow from 'components/layouts/parts/FormLayoutTitleRow/FormLayoutTitleRow';
import Alert from 'components/widgets/alerts/Alert/Alert';

const TYPE_RESPONSE = 'Other (type response)';
const MARKETING_REFERRALS = [
  'Google',
  'LinkedIn',
  'Referral / Partner',
  'Blog / Podcast / Webinar',
  'Event',
  TYPE_RESPONSE,
];

// const COMPANY_SIZES = ['', '1-50', '51-100', '101-250', '251+'];
// const COMPANY_SIZE_VALUES = COMPANY_SIZES.map((o) => {
//   return { value: o, label: o };
// });

interface SignupFormikFormProps {
  initialValues: FormikValues;
  signupSchema: any;
  handleFormSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>,
  ) => void | Promise<any>;
  error: string;
  hideMarketingReferral?: boolean;
}

const SignupFormikForm = (props: SignupFormikFormProps) => {
  const { initialValues, signupSchema, handleFormSubmit, error, hideMarketingReferral } = props;

  return (
    <Formik initialValues={initialValues} onSubmit={handleFormSubmit} validationSchema={signupSchema}>
      {({ handleChange, handleSubmit, setFieldValue, isSubmitting, values, errors, touched }) => {
        const handleChangeMarketingReferral = (value: string) => {
          if (value === TYPE_RESPONSE) {
            value = '';
          }
          setFieldValue('marketing_referral', value);
        };
        return (
          <form onSubmit={handleSubmit} noValidate>
            <FormLayoutTitleRow
              title="Sign Up"
              linkText="Log In"
              linkTo="/login"
              linkDataTrack="Signup Login"
            />
            <div className="flex justify-between items-start mt-4">
              <TextFormikGroup
                autoFocus
                disabled={isSubmitting}
                name="first_name"
                label="First Name"
                groupClass="w-[100%]"
              />

              <TextFormikGroup
                disabled={isSubmitting}
                name="last_name"
                label="Last Name"
                groupClass="w-[100%] ml-4"
              />
            </div>

            <TextFormikGroup
              disabled={isSubmitting}
              type="email"
              name="email"
              label="Email"
              placeholder="wolfgang@mozart.com"
              groupClass="mt-4"
            />
            <div className="flex justify-between items-start mt-4">
              <TextFormikGroup
                disabled={isSubmitting}
                name="title"
                label="Job Title"
                groupClass="w-[100%]"
              />
              <TextFormikGroup
                disabled={isSubmitting}
                name="company_name"
                label="Company Name"
                groupClass="w-[100%] ml-4"
              />
            </div>
            {/*  01/07/2023 - Hid company_size and phone_number on the signup form. */}
            {/* <div className="flex justify-between items-start mt-4">
                  <ListboxFormikGroup
                    disabled={isSubmitting}
                    name="company_size"
                    label="Company Size"
                    options={COMPANY_SIZE_VALUES}
                    groupClass="w-[100%]"
                  />
                  <TextFormikGroup
                    disabled={isSubmitting}
                    type="tel"
                    name="phone_number"
                    label="Phone Number (optional)"
                    groupClass="w-[100%] ml-4"
                  />
                </div> */}
            {!hideMarketingReferral && (
              <ComboboxFormikGroup
                disabled={isSubmitting}
                options={MARKETING_REFERRALS}
                name="marketing_referral"
                label="How did you hear about us?"
                groupClass="mt-4"
                onChange={handleChangeMarketingReferral}
              />
            )}

            <div className="flex f-col mt-4">
              <div className="f-row-y-center">
                <Checkbox
                  disabled={isSubmitting}
                  name="agree_to_terms"
                  checked={values.agree_to_terms}
                  variant="purple"
                  onChange={handleChange}
                />
                <div className="text-pri-gray-500 ml-1">
                  I agree to the{' '}
                  <a
                    href="https://www.mozartdata.com/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sec-blue-light-700"
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.mozartdata.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sec-blue-light-700"
                  >
                    Privacy Policy
                  </a>
                  .
                </div>
              </div>
              {errors.agree_to_terms && touched.agree_to_terms && (
                <div className="mt-1 text-pri-error-700 text-xs">{errors.agree_to_terms}</div>
              )}
            </div>
            {error && (
              <Alert variant="error" className="mt-4">
                {error}
              </Alert>
            )}
            <Button
              spinning={isSubmitting}
              type="submit"
              variant="lightAction"
              className="mt-4"
              style={{ width: 'calc(50% - 8px)' }}
            >
              Sign Up
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default SignupFormikForm;
