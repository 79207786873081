/*
Stripped down version of AuthHeader that only includes the bare necessities, such as logging out.
Use this when account is disabled or account is not fully setup during signup process.
*/
import { useLocation } from 'react-router-dom';

import History from 'history';

import { UserProfile } from 'api/APITypes';
import { useAuth, useUserProfile } from 'context/AuthContext';
import { useExperiments, Experiments } from 'context/ExperimentContext';
import useHelpUrl from 'hooks/useHelpUrl';

import HeaderIcon from '../AuthHeader/AuthHeaderView/HeaderIcon/HeaderIcon';
import UserMenu from '../AuthHeader/AuthHeaderView/UserMenu/UserMenu';
import HeaderLayout from '../HeaderLayout/HeaderLayout';

interface AccountDisabledHeaderProps {}

const AccountDisabledHeader = (props: AccountDisabledHeaderProps) => {
  const { logout } = useAuth();
  const { userProfile } = useUserProfile();
  const location = useLocation();
  const { experiments } = useExperiments();
  return (
    <PropsOnlyAccountDisabledHeader
      {...props}
      userProfile={userProfile}
      logout={logout}
      location={location}
      experiments={experiments}
    />
  );
};

interface PropsOnlyAccountDisabledHeaderProps extends AccountDisabledHeaderProps {
  userProfile: UserProfile;
  logout: () => void;
  location: History.Location;
  experiments: Experiments;
}

export const PropsOnlyAccountDisabledHeader = (props: PropsOnlyAccountDisabledHeaderProps) => {
  const { userProfile, logout, location } = props;

  const helpUrl = useHelpUrl(null, location);

  const rightJustifiedDiv = (
    <div className="f-row-y-center">
      <a className="mx-2" href={helpUrl} target="blank" data-track="Header Help">
        <HeaderIcon type="help" />
      </a>

      <UserMenu accountDisabled={true} userProfile={userProfile} logout={logout} />
    </div>
  );

  return (
    <HeaderLayout
      logoPath={'/'}
      logoDataTrack="AccountDisabledHeader ClickLogo"
      left={null}
      right={rightJustifiedDiv}
      banner={null}
    />
  );
};

export default AccountDisabledHeader;
