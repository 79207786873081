import React, { Fragment } from 'react';

import cn from 'classnames';

import { Placement } from 'components/overlay/PopperTrigger/usePopperRefs';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';

import StackingBubble from './StackingBubble';

interface StackingBubblesProps {
  items: string[];
  placement?: Placement;
}

export const baseBubbleClassName = 'w-[28px] h-[28px] relative f-center rounded-full border';

const numBubblesToShow = 3;

const StackingBubbles = (props: StackingBubblesProps) => {
  const { items, placement } = props;
  return (
    <TooltipTrigger
      tip={
        <>
          {items.map((item, i) => {
            return (
              <Fragment key={i}>
                {item}
                <br />
              </Fragment>
            );
          })}
        </>
      }
      placement={placement}
    >
      <div className="flex w-fit">
        {items.slice(0, numBubblesToShow).map((item, index) => (
          <StackingBubble key={item} label={item} index={index} numItems={items.length} />
        ))}
        {items.length > 3 && (
          <div className="relative f-row-y-center left-[-44px] z-0">
            <div className={cn(baseBubbleClassName, 'bg-pri-gray-200 border-pri-gray-400 z-[3]')} />
            <div
              className={cn(
                baseBubbleClassName,
                'bg-pri-gray-200 border-pri-gray-400 left-[-24px] z-[2]',
              )}
            />
            <div
              className={cn(
                baseBubbleClassName,
                'bg-pri-gray-200 border-pri-gray-400 left-[-48px] z-[1]',
              )}
            />
          </div>
        )}
      </div>
    </TooltipTrigger>
  );
};

export default StackingBubbles;
