import React from 'react';

import Button from 'components/inputs/basic/Button/Button';
import Modal from 'components/layouts/containers/modals/Modal/Modal';

interface ConfirmHistoricalResyncConnectorModalProps {
  resyncing: boolean;
  scope?: { [schemaName: string]: string[] };
  onCancel(): void;
  onConfirm(scope?: { [schemaName: string]: string[] }): void;
}

export default function ConfirmHistoricalResyncConnectorModal(
  props: ConfirmHistoricalResyncConnectorModalProps,
) {
  const { resyncing, scope, onCancel, onConfirm } = props;

  const modalFooter = (
    <div className="flex">
      <Button variant="darkDanger" onClick={() => onConfirm(scope)} spinning={resyncing}>
        {scope ? 'Re-sync Table' : 'Re-sync Connector'}
      </Button>
      <Button variant="lightDanger" onClick={onCancel} className="ml-2">
        Cancel
      </Button>
    </div>
  );

  // This code assumes we only have 1 schema and 1 table in the scope
  const schemaName = scope ? Object.keys(scope)[0] : '';
  const tableName = scope ? scope[schemaName][0] : '';

  return (
    <Modal
      header="Are you absolutely sure you want to re-sync all historical data?"
      footer={modalFooter}
      onClose={onCancel}
    >
      <div className="p-4 text-left">
        <p>
          You have selected a full historical re-sync for{' '}
          {scope ? (
            <code>
              {schemaName}.{tableName}
            </code>
          ) : (
            'this connector'
          )}
          .
        </p>
        <p className="mt-4">
          This may take a long time to complete,
          <br />
          but it will not consume Monthly Active Rows (MAR).
        </p>
        <p className="mt-4">While the re-sync is being performed:</p>
        <ul className="ml-4 list-disc">
          <li>Updates of new data will slow or stop.</li>
          <li>No existing data will be deleted.</li>
        </ul>
        <p className="mt-4">
          <a className="text-sec-blue-light-500" href="mailto:support@mozartdata.com">
            Contact Support
          </a>{' '}
          if you are unsure about this.
        </p>
      </div>
    </Modal>
  );
}
