import Button from 'components/inputs/basic/Button/Button';

interface TrySigmaProps {
  handleShowAPIKeysIntegrationClick: () => void;
  databaseDisplayName: string;
}

const TrySigma = (props: TrySigmaProps) => {
  const { handleShowAPIKeysIntegrationClick, databaseDisplayName } = props;
  return (
    <div className="px-[3.75rem] py-[2.5rem] flex flex-col bg-[--sec-blue-50]">
      <div className="flex flex-row">
        <img src="/images/graphics/integrations/mozart_sigma.svg" alt="" />
        <div className="ml-8 text-pri-gray-500">
          <h2 className="text-lg font-medium">Try Mozart Data + Sigma Integration</h2>
          <p className="my-2">Sigma is a preferred BI partner of Mozart Data.</p>
          <p className="mt-2 mb-10">
            Linking Mozart to Sigma will enable you to quickly set up a {databaseDisplayName} connection
            in Sigma, see relevant links to Sigma throughout Mozart, and more.
          </p>
          <Button onClick={handleShowAPIKeysIntegrationClick} variant="darkAction">
            Connect to Sigma
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TrySigma;
