import React from 'react';

import API from 'api/API';
import { AggTable } from 'api/APITypes';
import ApiIconButton, {
  useApiIconState,
  ExtendersProps,
} from 'components/inputs/basic/Button/ApiIconButton';
import executeFnWithSignedInGapi from 'utils/gapi';
import { eventType } from 'utils/String';

interface ExportGSheetIconProps extends ExtendersProps {
  table: AggTable;
}

const ExportGSheetIcon = (props: ExportGSheetIconProps) => {
  const { spinning, setSpinning, errorState, setErrorState } = useApiIconState();
  const { table, eventPage } = props;
  const { id, type } = table;

  const handleExportGSheet = async () => {
    if (spinning) {
      return;
    }

    const api = new API();
    setSpinning(true);

    // This requires being signed into gapi, so we build this function to be wrapped with the signIn flow
    const callExportGSheetAPI = (gapi_access_token: string) => {
      const postData = {
        table_id: id,
        gapi_access_token: gapi_access_token,
      };
      api
        .post('api/gsheet_export/table', postData)
        .then((response) => {
          if (response.data.error_lines) {
            // This should never get called since the backend is controlling sql (currently, it's `select * from table`)
            const errorText = response.data.error_lines.map((line: string, i: number) => {
              return <p key={i}>{line}</p>;
            });
            setErrorState({
              show: true,
              header: 'Error',
              body: errorText,
            });
          } else {
            // Success Case
            analytics.track(`${eventPage} Export${eventType(type)}GSheet`);
            // After exporting to Google Sheets, open a new tab to the sheet
            window.open(
              `https://docs.google.com/spreadsheets/d/${response.data.spreadsheetId}/edit#gid=0`,
              '_blank',
              'noopener,noreferrer',
            );
          }
        })
        .catch((error) => {
          setErrorState({
            show: true,
            header: 'Server Error',
            body: 'There was a problem exporting the query.',
          });
        })
        .finally(() => {
          setSpinning(false);
        });
    };
    const gSheetErrorFn = () => {
      setErrorState({
        show: true,
        header: 'Google Sheets Authentication Error',
        body: 'There was a problem authenticating with Google Sheets.',
      });
      setSpinning(false);
    };
    // Do the google auth flow to get permission to add a gsheet on the user's account
    executeFnWithSignedInGapi(callExportGSheetAPI, gSheetErrorFn);
  };

  return (
    <ApiIconButton
      icon="Download"
      text="GSHEET"
      size="small"
      variant="lightDullTransparent"
      spinning={spinning}
      errorState={errorState}
      setErrorState={setErrorState}
      onClick={handleExportGSheet}
      tip="Export To GSheet"
    />
  );
};

export default ExportGSheetIcon;
