import React, { useCallback, useEffect, useState } from 'react';

import { Square, LayoutSplit } from 'react-bootstrap-icons';
import { useHotkeys } from 'react-hotkeys-hook';

import cn from 'classnames';

import Button from 'components/inputs/basic/Button/Button';
import ButtonGroup from 'components/inputs/basic/Button/ButtonGroup';
import ButtonMenu from 'components/inputs/basic/Button/ButtonMenu';
import IconButton from 'components/inputs/basic/Button/IconButton';
import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import OptionSelector, {
  OptionSelectorOption,
} from 'components/inputs/composite/OptionSelector/OptionSelector';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import { EditorType } from 'components/query/useQueryEditor';
import { useDatabaseAccount } from 'context/AuthContext';
import { useUserProfile } from 'context/AuthContext';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import { AutoSavingState, SavedQuery } from 'pages/Query/useQueryTabs';
import EditButtons from 'pages/tables/ShowTable/TransformTab/TransformOptionsBar/EditButtons';
import { prefillTransform } from 'pages/transforms/AddTransform';
import { convertUnsafeNametoSafeIndentifier } from 'utils/dbName';

import AIExplainSql from './AIExplainSql/AIExplainSql';
import FlowchartEditButtons, { FlowchartEditButtonsProps } from './FlowchartEditButtons';
import { formatSql } from './queryUtil';
import { CONTROL_BAR_HEIGHT } from './SqlEditorStack';
import { FlowchartControlBarProps } from './useFlowchartEditor';
import { SelectedSql } from './useSqlEditor';

// The width at which the SqlEditorControlBar can no longer compress and starts to deform.
export const MIN_QUERY_CRUSH_WIDTH = 590;
export const MIN_TRANSFORM_CRUSH_WIDTH = 735;
export const MIN_INCREMENTAL_CRUSH_WIDTH = 851;

interface SqlEditorControlBarProps {
  editMode: boolean;
  editorSql: string;
  selectedSql: SelectedSql;
  isFlowchart: boolean;
  editorType: EditorType;
  addLimit: boolean;
  running: boolean;
  runningIncremental: boolean;
  exportingCsv: boolean;
  exportingGSheet: boolean;
  lastRunSql: string;
  isTransform: boolean;
  incremental: boolean;
  transformTableName?: string;
  description?: string;
  canShowEditButtons: boolean;
  savedSql?: string;
  eventPrefix: string;
  saving?: boolean;
  showTableExplorer: boolean;
  autoSavingState?: AutoSavingState;
  isShown: boolean;
  savedQuery?: SavedQuery;
  flowchartControlBarProps?: FlowchartControlBarProps;

  setRunErrorLines: (runErrorLines: string[]) => void;
  toggleLimit: () => void;
  onRun: (incremental: boolean, recordEvent: boolean) => void;
  setEditorSql(sql: string, byUser: boolean): void;
  setEditorType: (newType: EditorType) => void;
  setEditMode: (editMode: boolean) => void;
  clearLastRun: () => void;
  onSaveSql?(): Promise<string> | undefined;
  setUnsavedSql?: (unsaved: boolean) => void;
  setShowTableExplorer: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SqlEditorControlBar(props: SqlEditorControlBarProps) {
  const [unformattedSql, setUnformattedSql] = useState('');
  const [formattedSql, setFormattedSql] = useState('');

  const {
    editMode,
    editorSql,
    selectedSql,
    isFlowchart,
    editorType,
    addLimit,
    running,
    runningIncremental,
    exportingCsv,
    exportingGSheet,
    lastRunSql,
    isTransform,
    incremental,
    transformTableName,
    description,
    canShowEditButtons,
    savedSql,
    eventPrefix,
    saving,
    showTableExplorer,
    autoSavingState,
    isShown,
    savedQuery,
    flowchartControlBarProps,
    setRunErrorLines,
    toggleLimit,
    onRun,
    setEditorSql,
    setEditorType,
    setEditMode,
    clearLastRun,
    onSaveSql,
    setUnsavedSql,
    setShowTableExplorer,
  } = props;
  const { userProfile } = useUserProfile();
  const databaseType = useDatabaseAccount().type;

  const showAIPipelineAssistant = useBooleanFlag('wolfgang', false) && !incremental;

  // If we are a flowchart editor set control bar props
  let flowchartEditButtonsProps: FlowchartEditButtonsProps | undefined = undefined;
  if (flowchartControlBarProps) {
    flowchartEditButtonsProps = {
      isTransform: false, // Transform concern. Ignore for now.
      editMode: true, // Transform concern. Ignore for now.
      eventPrefix,
      isSaved: flowchartControlBarProps.isSaved,
      isSaving: flowchartControlBarProps.isSaving,
      setEditMode: () => {}, // Transform concern. Ignore for now.
      onSave: (versionDescription?: string) => flowchartControlBarProps.onSave(),
      onDiscardChanges: flowchartControlBarProps.onDiscardChanges,
    };
  }

  useEffect(() => {
    if (formattedSql) {
      if (editorSql !== formattedSql) {
        setFormattedSql('');
        setUnformattedSql('');
      }
    }
  }, [editorSql, formattedSql]);

  const isSqlSelected = selectedSql.sql !== '';

  const isRunDisabled = (editorSql === '' && editorType === 'sql') || exportingCsv || exportingGSheet;
  const showDropdownButton =
    !running && !isSqlSelected && !isRunDisabled && userProfile.company_role !== 'viewer';
  const showRebuildButton = incremental && !isSqlSelected;
  let runText = isSqlSelected ? 'Run Selected' : 'Run';
  if (!isSqlSelected && isTransform && incremental) {
    runText = 'Run Incremental';
  }

  const saveText = isTransform ? 'Clone Transform' : 'Save As Transform';

  const wrappedToggleLimit = () => {
    analytics.track(!addLimit ? 'RunRow EnableLimit' : 'RunRow DisableLimit', {
      isTransform,
    });
    toggleLimit();
  };

  const handleRightMostButtonUserRun = () => {
    const runIncremental = isTransform && !!incremental;
    onRun(runIncremental, true);
  };

  const handleFullReload = () => {
    onRun(false, true);
  };

  const handleSaveAsTransform = () => {
    analytics.track('RunRow SaveAsTranform', {
      isTransform,
    });
    if (isTransform) {
      prefillTransform(transformTableName || '', 'mozart', 'BASE TABLE', description || '', editorSql); // ORs make TS happy
    } else {
      const cleanName = convertUnsafeNametoSafeIndentifier(savedQuery?.name || '');
      prefillTransform(cleanName, 'mozart', 'BASE TABLE', '', editorSql);
    }
  };

  const handleFormatSql = useCallback(() => {
    setUnformattedSql(editorSql);
    try {
      const tempFormattedSql = formatSql(editorSql, databaseType);
      setEditorSql(tempFormattedSql, true);
      setFormattedSql(tempFormattedSql);
      analytics.track(`RunRow FormatSQL`, {
        isTransform,
        beforeLines: editorSql.split('\n').length,
        beforeCharacters: editorSql.length,
        afterLines: tempFormattedSql.split('\n').length,
        afterCharacters: tempFormattedSql.length,
      });
    } catch (e) {
      setRunErrorLines([
        'The formatting engine cannot parse your query.',
        'Double check your query for syntax errors.',
      ]);
    }
  }, [editorSql, isTransform, setEditorSql, setRunErrorLines, databaseType]);

  useHotkeys(
    'alt+shift+f, option+shift+f',
    (event: KeyboardEvent) => {
      if (editorType === 'sql' && editMode && !incremental) {
        event.preventDefault();
        event.stopPropagation();
        handleFormatSql();
      }
    },
    { enableOnTags: ['TEXTAREA', 'INPUT'], enabled: isShown, enableOnContentEditable: true },
    [editorType, editMode, incremental, handleFormatSql],
  );

  const handleUndoFormatSql = () => {
    setEditorSql(unformattedSql, true);
    analytics.track(`RunRow UndoFormatSQL`, {
      isTransform,
      beforeLines: formattedSql.split('\n').length,
      beforeCharacters: formattedSql.length,
      afterLines: unformattedSql.split('\n').length,
      afterCharacters: unformattedSql.length,
    });
  };

  const handleShowTableExplorer = () => {
    analytics.track('SqlEditorControlBar ShowTableExplorer');
    setShowTableExplorer(true);
  };

  const handleSetEditorType = (newVal: string) => {
    const oldEditorType = editorType;
    const newEditorType = newVal as EditorType;
    setEditorType(newEditorType);

    // Enable editMode so AI can set SQL.
    // PS: We should just get rid of editMode and delete this block of code.
    if (canShowEditButtons) {
      if (!editMode && newEditorType === 'ai_assistant') {
        setEditMode(true);
      } else if (
        editMode &&
        oldEditorType === 'ai_assistant' &&
        newEditorType !== 'ai_assistant' &&
        editorSql === savedSql
      ) {
        setEditMode(false);
      }
    }

    analytics.track('SqlEditorControlBar SetEditorType', {
      oldEditorType,
      newEditorType,
      isTransform,
      canShowEditButtons,
    });
  };

  const editorTypeOptions: OptionSelectorOption[] = [{ displayName: 'SQL', value: 'sql' }];
  if (showAIPipelineAssistant) {
    editorTypeOptions.push({
      displayName: (
        <span className="f-row-y-center">
          <span>AI Assistant</span>
          <span className="ml-1 text-sec-blue-light-600 font-medium text-xs">Beta</span>
        </span>
      ),
      value: 'ai_assistant',
    });
  }

  const layoutOptions: OptionSelectorOption[] = [
    {
      displayName: (
        <span className="f-row-y-center">
          <Square size={20} color="var(--pri-gray-700)" />
        </span>
      ),
      value: 'flowchart',
    },
    {
      displayName: (
        <span className="f-row-y-center">
          <LayoutSplit size={20} color="var(--pri-gray-700)" />
        </span>
      ),
      value: 'flowchart_and_sql',
    },
  ];

  const disableFullReloadButton = running && runningIncremental;
  const disableIncrementalButton = running && !runningIncremental && incremental;

  // The width of a button changes based on the text inside it.
  // Sometimes the button has a dropdown menu in it, sometimes it does not.
  // This is the widest a button can appear to be(including the width of the dropdown if applicable).
  let buttonOrButtonGroupWidth = incremental ? 162 : 105;
  const dropdownAndDividerWidth = 29;
  // This is the width of the button excluded the dropdown and divider width.
  const buttonInsideGroupWidth = buttonOrButtonGroupWidth - dropdownAndDividerWidth;
  // Once again the width of the button changes if the text changes.
  if (isSqlSelected) {
    buttonOrButtonGroupWidth = 115;
  }

  const dropdownOptions = [{ label: saveText, onClick: handleSaveAsTransform }];

  const showTypePicker = !incremental && !isFlowchart && showAIPipelineAssistant;
  const showFlowchartLayoutPicker = !incremental && isFlowchart;

  const showEditButtons =
    canShowEditButtons &&
    saving !== undefined &&
    savedSql !== undefined &&
    onSaveSql !== undefined &&
    setUnsavedSql !== undefined;

  return (
    <div className="f-between items-center p-2 bg-white" style={{ height: CONTROL_BAR_HEIGHT }}>
      <div className="f-row-y-center">
        {showTypePicker && (
          <OptionSelector
            selectedOption={editorType}
            setSelectedOption={handleSetEditorType}
            options={editorTypeOptions}
            size="small"
          />
        )}
        {showFlowchartLayoutPicker && (
          <OptionSelector
            selectedOption={flowchartControlBarProps?.showSQL ? 'flowchart_and_sql' : 'flowchart'}
            setSelectedOption={(option) =>
              flowchartControlBarProps?.setShowSQL(option === 'flowchart_and_sql')
            }
            options={layoutOptions}
            size="small"
          />
        )}
        {!isFlowchart && autoSavingState !== undefined && (
          <div
            className={cn({
              'ml-4': showTypePicker,
              'text-pri-error-400': autoSavingState === 'Saving Error',
              'text-pri-gray-400': autoSavingState !== 'Saving Error',
            })}
          >
            {autoSavingState}
          </div>
        )}
        {showEditButtons && (
          <div className={cn('flex justify-end', { 'ml-4': showTypePicker })}>
            <EditButtons
              isTransform={isTransform}
              editMode={editMode}
              editorSql={editorSql}
              savedSql={savedSql}
              eventPrefix={eventPrefix}
              saving={saving}
              lastRunSql={lastRunSql}
              setEditMode={setEditMode}
              setEditorSql={setEditorSql}
              clearLastRun={clearLastRun}
              onSaveSql={onSaveSql}
              setUnsavedSql={setUnsavedSql}
            />
          </div>
        )}
        {flowchartEditButtonsProps && !flowchartEditButtonsProps.isSaved && (
          <div className={cn('flex justify-end ml-2')}>
            <FlowchartEditButtons {...flowchartEditButtonsProps} />
          </div>
        )}
      </div>
      <div className="flex items-center">
        {!isFlowchart && (
          <>
            <AIExplainSql sql={selectedSql.sql || editorSql} />
          </>
        )}
        {!isFlowchart && editMode && !incremental && (
          <>
            {editorSql !== '' && editorSql === formattedSql ? (
              <Button
                size="small"
                onClick={handleUndoFormatSql}
                variant="lightDullAction"
                className="mr-4"
              >
                Undo Format
              </Button>
            ) : (
              <Button
                disabled={editorSql === ''}
                size="small"
                onClick={handleFormatSql}
                variant="lightDullAction"
                className="mr-4"
              >
                Format SQL
              </Button>
            )}
          </>
        )}
        {!isFlowchart && (
          <>
            <div className="flex mr-4">
              <Checkbox
                name="addLimit"
                checked={addLimit}
                variant="blue_gray"
                onChange={wrappedToggleLimit}
                className="mr-2 align-self-center"
              />
              <div className="text-pri-gray-700">Limit 100</div>
            </div>

            {showRebuildButton && (
              <Button
                onClick={handleFullReload}
                spinning={running && !disableFullReloadButton}
                disabled={isRunDisabled || disableFullReloadButton}
                style={{ width: `${buttonOrButtonGroupWidth}px` }}
                size="small"
                variant="darkDullAction"
                className="mr-4"
              >
                Run Rebuild SQL
              </Button>
            )}
            {showDropdownButton && (
              <ButtonGroup variant="darkDullAction" style={{ width: `${buttonOrButtonGroupWidth}px` }}>
                <Button
                  onClick={handleRightMostButtonUserRun}
                  spinning={running && !disableIncrementalButton}
                  disabled={isRunDisabled || disableIncrementalButton}
                  size="small"
                  variant="darkDullAction"
                  style={{ width: `${buttonInsideGroupWidth}px` }}
                >
                  {runText}
                </Button>
                <ButtonMenu size="small" variant="darkDullAction" dropdownOptions={dropdownOptions} />
              </ButtonGroup>
            )}
            {!showDropdownButton && (
              <Button
                onClick={handleRightMostButtonUserRun}
                spinning={running && !disableIncrementalButton}
                disabled={isRunDisabled || disableIncrementalButton}
                size="small"
                variant="darkDullAction"
                style={{ width: `${buttonOrButtonGroupWidth}px` }}
              >
                {runText}
              </Button>
            )}
          </>
        )}
        {!isFlowchart && !showTableExplorer && (
          <TooltipTrigger tip="Show Table Explorer">
            <IconButton
              icon="ArrowBarLeft"
              variant="lightDullTransparent"
              size="small"
              onClick={handleShowTableExplorer}
              className="ml-2"
            />
          </TooltipTrigger>
        )}
      </div>
    </div>
  );
}
