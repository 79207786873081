import PageButtons from 'components/inputs/basic/Button/PageButtons';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';

import type { PagedLocation, UserAction } from '../../usePagedUserActions';

import UserActionsTable, { TableVariant } from './UserActionsTable';

interface PagedUserActionsTableProps {
  userActions: UserAction[];
  variant: TableVariant;
  title?: string;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  forceHideFooterButtons?: boolean;
  hideLabels?: boolean;
  eventLocation: PagedLocation;
  onLoadPreviousPage: () => void;
  onLoadNextPage: () => void;
}

const PagedUserActionsTable = ({
  userActions,
  variant,
  title,
  forceHideFooterButtons,
  hideLabels,
  eventLocation,
  ...pageButtonProps
}: PagedUserActionsTableProps) => {
  const hideFooterButtonsDueToShortList = userActions.length <= 5;
  const hideFooterButtons = forceHideFooterButtons
    ? Boolean(forceHideFooterButtons)
    : hideFooterButtonsDueToShortList;

  return (
    <>
      {userActions.length === 0 ? (
        <NoObjectsAlert
          heading="Mozart hasn't logged any User Actions for this object yet."
          detail="User Actions were added as a feature on October 20th, 2022. You are seeing this message either because your account is brand new or because the last time you changed a setting was before October 20th, 2022."
        />
      ) : (
        <div>
          <div className="f-between">
            <div className="font-medium text-sec-blue-gray-700">{title}</div>
            {(pageButtonProps.hasNextPage || pageButtonProps.hasPreviousPage) && (
              <PageButtons {...pageButtonProps} />
            )}
          </div>

          <UserActionsTable
            userActions={userActions}
            variant={variant}
            hideLabels={hideLabels}
            eventLocation={eventLocation}
            className="mt-4"
          />

          {(pageButtonProps.hasNextPage || pageButtonProps.hasPreviousPage) && !hideFooterButtons && (
            <PageButtons className="float-right mt-4" {...pageButtonProps} />
          )}
        </div>
      )}
    </>
  );
};

export default PagedUserActionsTable;
