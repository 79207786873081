import React, { useContext, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import cn from 'classnames';
import _ from 'lodash';

import TextInput from 'components/inputs/basic/TextInput/TextInput';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import LabeledHorizontalRule from 'components/layouts/parts/LabeledHorizontalRule/LabeledHorizontalRule';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import useTrackFilter from 'hooks/useTrackFilter';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { useSearchFocus } from 'utils/React';

import { addableConnectors, ConnectorType } from '../ConnectorRegistry';

import ConnectorGrid from './ConnectorGrid';
import ManualSetupModal from './ManualSetupModal';

interface AddConnectorTypesProps {}

export default function AddConnectorTypes(props: AddConnectorTypesProps) {
  useTitle('Add Connector List');
  const { loadedConnectors, connectors } = useContext(TableModelsContext);
  const [connectorFilter, setConnectorFilter] = useState('');

  const navigate = useNavigate();
  const reportOnBlur = useTrackFilter('AddConnectorTypes', connectorFilter);
  const [filterRef] = useSearchFocus();
  const [manualSetupConnector, setManualSetupConnector] = useState<ConnectorType | null>(null);

  const sortedConnectors = useMemo(
    () => _.fromPairs(_.toPairs(addableConnectors).sort((a, b) => a[1].name.localeCompare(b[1].name))),
    [],
  );

  // Build Frequent Connectors if customer has passed the threshold to show frequentConnectors.
  const frequentConnectors = useMemo(() => {
    let frequentConnectors: Record<string, ConnectorType> = {};
    let connectorCounts: { [key: string]: number } = {};
    let frequentConnectorCounts: { [key: string]: number } = {};

    // Count how many connectors of each type the company has.
    connectors.forEach((c) => {
      if (!connectorCounts[c.service]) {
        connectorCounts[c.service] = 0;
      }
      connectorCounts[c.service] += 1;
    });

    // Add connectors that have been used enough to be counted as frequent.
    const MIN_FREQUENT_COUNT = 3;
    _.forEach(connectorCounts, (count, key) => {
      if (count >= MIN_FREQUENT_COUNT) {
        frequentConnectorCounts[key] = connectorCounts[key];
      }
    });

    // Sort frequent connectors by number of times they are used.
    // Truncate list size so it never gets too long.
    const MAX_FREQUENT = 6;
    const sortedPairs = _.sortBy(_.toPairs(frequentConnectorCounts), 1).reverse().slice(0, MAX_FREQUENT);
    sortedPairs.forEach((pair) => {
      frequentConnectors[pair[0]] = sortedConnectors[pair[0]];
    });

    return frequentConnectors;
  }, [connectors, sortedConnectors]);

  // Build Popular Connectors:
  // Query to get most popular connectors: https://app.mozartdata.com/query/14c931c3-5da5-4808-b582-396d691f5edf
  // This list is inspired by the raw analytics but hand currated and hand ordered to show some diversity of what's possible.
  let popularConnectors: Record<string, ConnectorType> = {
    google_sheets: addableConnectors['google_sheets'],
    google_analytics_4: addableConnectors['google_analytics_4'],
    hubspot: addableConnectors['hubspot'],
    stripe: addableConnectors['stripe'],
    salesforce: addableConnectors['salesforce'],
    shopify: addableConnectors['shopify'],
  };

  let filteredConnectors = sortedConnectors;
  if (connectorFilter) {
    const filterFunc = (connectorType: ConnectorType, service: string) => {
      return (
        service.toLowerCase().includes(connectorFilter.toLowerCase()) ||
        connectorType.name.toLowerCase().includes(connectorFilter.toLowerCase())
      );
    };
    filteredConnectors = _.pickBy(sortedConnectors, filterFunc);
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConnectorFilter(event.target.value.toLowerCase());
  };

  const handleSelectConnector = (service: string, category: 'frequent' | 'popular' | 'all') => {
    const connectorType = addableConnectors[service];
    if (
      connectorType.vendor === 'portable' ||
      connectorType.vendor === 'custom' ||
      (connectorType.vendor === 'fivetran' && connectorType.lite)
    ) {
      setManualSetupConnector(connectorType);
      analytics.track('AddConnectorTypes OpenManualSetupModal', {
        service,
        category,
        vendor: connectorType.vendor,
      });
    } else {
      analytics.track('AddConnectorTypes Select', {
        service,
        category,
        vendor: connectorType.vendor,
      });
      if (connectorType.vendor === 'csv') {
        navigate(`/csv/add`);
        return;
      }
      navigate(`/connectors/add/${service}`);
    }
  };

  const handleCloseManualModal = () => {
    setManualSetupConnector(null);
    analytics.track('AddConnectorTypes CloseManualSetupModal');
  };

  // Business Logic:
  // 1. If there is a non-empty search filter, do not show Frequent or Most Popular sections.
  // 2. If the company has Frequent connectors, show that section before all connectors.
  //    Presumably, the user knows what they are doing and we are streamlining theme adding more instances
  //    of a connector type they already have.
  // 3. If there is no Frequent section, the account is probably brand new or new-ish.
  //    Show the Most Popular connector section to encourage the user to add connectors that are
  //    easy to set up so the user gets a quick win. These are also the connectors the analyst team
  //    is good at working with so if these familiar connectors get connected the analysts get a quick win.
  const noSearch = connectorFilter.length === 0;
  const hasFrequent = noSearch && Object.keys(frequentConnectors).length > 0;
  const hasMostPopular = noSearch && !hasFrequent;
  const hasConnectors = Object.keys(filteredConnectors).length > 0;
  const showAllLabel = hasFrequent || hasMostPopular;

  return (
    <CenteredLayout maxWidth="814px" loading={!loadedConnectors} contentWrapperClass="mt-[-24px]">
      <div className="sticky top-0 py-6 bg-white">
        <h1 className="display-sm text-pri-gray-700">
          Select the type of data source you want to connect to Mozart
        </h1>
        <div className="pt-2">
          <TextInput
            name="search"
            ref={filterRef}
            placeholder="Search"
            value={connectorFilter}
            onChange={handleFilterChange}
            onBlur={reportOnBlur}
            autoFocus
            style={{ maxWidth: '275px' }}
            maxLength={200}
          />
        </div>
      </div>
      {hasFrequent && (
        <>
          {<LabeledHorizontalRule label="Your Most Frequent" theme="primary" />}
          <ConnectorGrid
            connectors={frequentConnectors}
            onSelectConnector={(service: string) => handleSelectConnector(service, 'frequent')}
            className="mt-2"
          />
        </>
      )}
      {hasMostPopular && (
        <>
          {<LabeledHorizontalRule label="Most Popular" theme="primary" />}
          <ConnectorGrid
            connectors={popularConnectors}
            onSelectConnector={(service: string) => handleSelectConnector(service, 'popular')}
            className="mt-2"
          />
        </>
      )}
      {hasConnectors ? (
        <>
          {showAllLabel && (
            <LabeledHorizontalRule label="All Connectors" theme="primary" className="mt-8" />
          )}
          <ConnectorGrid
            connectors={filteredConnectors}
            onSelectConnector={(service: string) => handleSelectConnector(service, 'all')}
            className={cn({ 'mt-2': showAllLabel })}
          />
        </>
      ) : (
        <NoMatchesFoundAlert
          heading="No matching connectors."
          detail="Not finding what you're looking for? Please contact support@mozartdata.com. We can probably find a solution to copy your data."
        />
      )}
      {manualSetupConnector && (
        <ManualSetupModal connector={manualSetupConnector} onClose={handleCloseManualModal} />
      )}
    </CenteredLayout>
  );
}
