import classNames from 'classnames';

import RadioInput from 'components/inputs/basic/RadioInput/RadioInput';
import { RadioInputOption } from 'components/inputs/basic/RadioInputs/RadioInputs';

interface DatabaseOptionsProps {
  onChange: () => {};
  setFieldValue: (name: string, value: any) => void;
  value: any;
  remainingProps: any;
  name: string;
  options: RadioInputOption[];
}

const DatabaseOptions = ({
  options,
  onChange,
  setFieldValue,
  value,
  name,
  remainingProps,
}: DatabaseOptionsProps) => {
  const handleRadioClick = (
    e: React.MouseEvent<HTMLElement>,
    name: string,
    opt: { value: string },
  ): void => {
    setFieldValue(name, opt.value);
  };
  return (
    <div>
      {options.map((opt) => {
        const isChecked = opt.value === value;
        const containerClass = classNames(
          'hover:cursor-pointer max-w-[470px] p-4 mb-4  border-solid rounded flex items-center relative',
          {
            'border-2 border-pri-gray-300 ': !isChecked,
            'border-2 font-bold border-primary-600 bg-sec-purple-50': isChecked,
          },
        );
        return (
          <div
            key={opt.value}
            onClick={(e) => {
              handleRadioClick(e, name, opt);
            }}
            className={containerClass}
          >
            <RadioInput
              key={opt.value}
              name={name}
              value={opt.value}
              onChange={onChange}
              onClick={(e) => {
                handleRadioClick(e, name, opt);
              }}
              checked={opt.value === value}
              labelClass="sr-only"
              label={opt.label}
              {...remainingProps}
            />
            {opt.label}
          </div>
        );
      })}
    </div>
  );
};

export default DatabaseOptions;
