import React, { useRef, useEffect, useMemo } from 'react';

import {
  Chart,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  TimeScale,
  Filler,
  Legend,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';

import { TransformRun } from 'api/APITypes';
import { backfillNulls } from 'utils/Array';
import { millisecondsToSeconds, durationFromIsoTimes } from 'utils/dateTime';

Chart.register(
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  TimeScale,
  Filler,
  Legend,
  Title,
  Tooltip,
  ChartDataLabels,
);

//--Chart Style Options--//
// @ts-ignore
Chart.defaults.font.family = "'PT Sans', sans-serif";
//--Chart Style Options--//

interface RunHistoryChartProps {
  runHistory: TransformRun[];
}

const DURATION_COLOR = '#0ba5ec'; // var(--sec-blue-light-500)
const NUM_ROWS_COLOR = '#12b76a'; // var(--pri-success-500)
const FAILURE_COLOR = 'rgba(217, 45, 32, 0.18)'; //var(--pri-error-500) with 0.18 opacity

export default function RunHistoryChart(props: RunHistoryChartProps) {
  const chartRef: React.RefObject<HTMLCanvasElement> = useRef(null);
  const myChart: any = useRef(undefined);

  const { runHistory } = props;
  const completedRuns = useMemo(
    () =>
      runHistory.filter((result) => {
        const state = result.state || 'running';
        return state === 'failed' || state === 'success';
      }),
    [runHistory],
  );

  const durationDataset = useMemo(
    () => ({
      label: 'Duration',
      yAxisID: 'duration',
      data: completedRuns.map((result) =>
        millisecondsToSeconds(
          durationFromIsoTimes(
            result.snowflake_query?.start_time || null,
            result.snowflake_query?.end_time || null,
          ),
        ),
      ),
      fill: false,
      borderColor: DURATION_COLOR,
      backgroundColor: DURATION_COLOR,
    }),
    [completedRuns],
  );

  const numRowsDataset = useMemo(
    () => ({
      label: 'Row Count',
      yAxisID: 'rowCount',
      data: backfillNulls(completedRuns.map((result) => result.snowflake_query?.num_rows || null)),
      fill: false,
      borderColor: NUM_ROWS_COLOR,
      backgroundColor: NUM_ROWS_COLOR,
    }),
    [completedRuns],
  );

  const failureDataset = useMemo(
    () => ({
      label: 'Failure',
      yAxisID: 'failure',
      data: completedRuns.map((result) => (result.state === 'success' ? 0 : 1)), // We reverse the boolean here because area under the curve is red
      fill: true,
      stepped: 'after',
      pointRadius: 0,
      borderWidth: 0,
      borderColor: FAILURE_COLOR,
      backgroundColor: FAILURE_COLOR,
    }),
    [completedRuns],
  );

  // result should always have start_time if it gets here since we filter out queued runs, but default to run object created at time for happy typescript and defensive coding
  const labels = useMemo(
    () =>
      completedRuns.map((result) =>
        dayjs(result.snowflake_query?.start_time || result.created_at).toDate(),
      ),
    [completedRuns],
  );

  // Build the chart
  useEffect(() => {
    const myChartRef = chartRef.current?.getContext('2d');
    if (!myChartRef) {
      return;
    }

    if (typeof myChart.current !== 'undefined') {
      myChart.current.destroy();
    }

    myChart.current = new Chart(myChartRef, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [numRowsDataset, durationDataset, failureDataset],
      },
      options: {
        scales: {
          x: {
            // @ts-ignore
            type: 'time',
            time: {
              displayFormats: {
                millisecond: 'MMM dd, hh:mm a',
                second: 'MMM dd, hh:mm a',
                minute: 'MMM dd, hh:mm a',
                hour: 'MMM dd, hh:mm a',
                day: 'MMM dd, hh:mm a',
                week: 'MMM dd, hh:mm a',
                month: 'MMM dd, hh:mm a',
                quarter: 'MMM dd, hh:mm a',
                year: 'MMM dd, hh:mm a',
              },
            },
            distribution: 'linear',
            title: {
              display: true,
              text: 'Start Time',
            },
            grid: { display: false },
            ticks: {
              autoSkipPadding: 50,
            },
          },
          duration: {
            title: {
              display: true,
              text: 'Duration (seconds)',
            },
            type: 'linear',
            position: 'left',
            offset: true,
            grid: { display: false }, // TODO: When we upgrade to chart.js v3, we can use count to match duration and numrow gridlines and show them again
            beginAtZero: true,
            ticks: {
              precision: 1,
            },
          },
          rowCount: {
            title: {
              display: true,
              text: 'Row Count',
            },
            // @ts-ignore
            type: 'linear',
            position: 'right',
            offset: true,
            gridLines: { display: false },
            ticks: {
              // @ts-ignore
              precision: 0,
            },
            min: 0,
          },
          failure: {
            type: 'linear',
            position: 'right',
            display: false,
            max: 1,
            min: 0,
          },
        },
        animation: { duration: 0 },
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0, // 0 disables bezier curves
          },
          point: {
            radius: 3,
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          tooltip: {
            mode: 'nearest',
            intersect: false,
          },
          legend: {
            fullSize: false,
            position: 'right',
          },
        },
      },
    });
  }, [durationDataset, numRowsDataset, failureDataset, labels]);

  return (
    <div className="relative w-full h-full py-4 px-0">
      <canvas id="myChart" ref={chartRef} width="100%" />
    </div>
  );
}
