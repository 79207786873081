import { useCallback, useEffect, useState } from 'react';

import { isEqual } from 'lodash';

import API from 'api/API';

import { UserPreferencesInterface } from './UserPreferencesContext';

export default function useUserPreferencesContext() {
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [userPreferences, setUserPreferences] = useState<UserPreferencesInterface>({});

  const getUserPreferences = useCallback(() => {
    const api = new API();
    api.get('/api/user/frontend_preferences').then((response) => {
      if (response) {
        setUserPreferences(response.data);
        setInitialLoadComplete(true);
      }
    });
    // Not catching errors for now
  }, []);

  // Get the user preferences from the backend
  useEffect(() => {
    getUserPreferences();
  }, [getUserPreferences]);

  // Replace the user preferences
  const updateUserPreferences = useCallback(
    (changedPreferences: Partial<UserPreferencesInterface>) => {
      // If the preferences haven't changed, don't make an API call
      if (
        Object.keys(changedPreferences).every((key) =>
          isEqual(
            userPreferences[key as keyof UserPreferencesInterface],
            changedPreferences[key as keyof UserPreferencesInterface],
          ),
        )
      ) {
        return;
      }
      const api = new API();
      api
        .patch('/api/user/frontend_preferences', changedPreferences)
        .then((response) => {
          setUserPreferences(response.data);
        })
        .catch(() => {
          // Silently, catch API error so it doesn't get reported in Sentry.
          // The UI that uses this method doesn't have a good place to render the error at this time.
        });
    },
    [userPreferences],
  );

  return {
    initialLoadComplete,
    userPreferences,
    updateUserPreferences,
  };
}
