import React, { memo } from 'react';

import { GripVertical } from 'react-bootstrap-icons';

import cn from 'classnames';

import IconButton from 'components/inputs/basic/Button/IconButton';
import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import { BaseSearchableCheckboxPickerRowProps } from 'components/inputs/composite/SearchableCheckboxPicker/BaseSearchableCheckboxPicker';
import TextInputGroup from 'components/inputs/group/TextInputGroup/TextInputGroup';
import Popover from 'components/overlay/Popover/Popover';
import PopperTrigger from 'components/overlay/PopperTrigger/PopperTrigger';

import { ColumnStatus, SelectorColumn } from '../useColumnSelector';

import SelectColumnRowHoverCard from './SelectColumnsRowHoverCard/SelectColumnsRowHoverCard';

// Type serves an a reminder of what type of string to use.
export type ColumnValueID = SelectorColumn['id'];

export interface ColumnRowObject {
  columnValue: SelectorColumn;
  isChecked: boolean;
  hasChanged: boolean;
  aliasError: string;
  samples: string[];
  isBeingDragged: boolean;
  isDraggedOver: boolean;
}

// Props that do not change from row to row
// Wrap all of these methods in useCallback() so ColumnSearchableCheckboxPickerRow memoization will work.
export interface ColumnSearchableCheckboxPickerEveryRowProps {
  onToggleCheck(o: ColumnRowObject): void;
  onDragStart: (column: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onDragEnd: () => void;
  onDragOver: (event: React.DragEvent<HTMLSpanElement>) => void;
  onDragEnter: (enteredColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onDragLeave: (leftColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onDrop: (droppedOnColumn: ColumnValueID, event: React.DragEvent<HTMLSpanElement>) => void;
  onDelete: (column: ColumnValueID) => void;
  onUpdateAlias: (column: ColumnValueID, alias: string) => void;
  showSocket?: boolean;
}

export interface ColumnSearchableCheckboxPickerRowProps
  extends BaseSearchableCheckboxPickerRowProps<
    ColumnRowObject,
    ColumnSearchableCheckboxPickerEveryRowProps
  > {}

const ColumnSearchableCheckboxPickerRow = memo((props: ColumnSearchableCheckboxPickerRowProps) => {
  const {
    checkboxName,
    object,
    onToggleCheck,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
    onDelete,
    onUpdateAlias,
    showSocket,
  } = props;

  const { columnValue, isChecked, hasChanged, aliasError, samples, isBeingDragged, isDraggedOver } =
    props.object;

  const { id, name, alias, socket, status } = columnValue;

  const handleOnChangeAlias = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateAlias(id, event.target.value);
  };

  const handleOnClickAlias = (event: React.MouseEvent<HTMLInputElement>) => {
    // Prevent row click handler from checking/unchecking the row
    event.stopPropagation();
  };

  const involvedInDrag = isBeingDragged || isDraggedOver;

  const outerClass = cn('w-full px-0 py-2 flex flex-column hover:bg-pri-gray-100', {
    'bg-sec-blue-gray-100': hasChanged && !isDraggedOver,
    'opacity-25': isBeingDragged,
    'border border-sec-blue-gray-500': isBeingDragged,
    'bg-pri-success-200': isDraggedOver,
    'shadow-[0_0_0_0.3rem_var(--pri-success-400)]': isDraggedOver,
    'cursor-grab': !isBeingDragged,
    // Known Limitation:
    // Chromium browsers have a bug where you cannot override the dragging cursor.
    // This does work in Firefox.
    'cursor-grabbing': isBeingDragged,
  });

  // On joins we display a socketElement so you know which table the column came from.
  const socketElement = showSocket && (
    <>
      <span className="text-pri-gray-500">{`${socket === 'left' ? '(L)' : '(R)'}`}</span>
      <span> </span>
    </>
  );

  // If the column was added or deleted in the parent, notify the user.
  let statusElement = null;
  if (status === ColumnStatus.MISSING) {
    statusElement = (
      <div className="f-row-y-center">
        <div className="text-pri-error-700 text-xs">This column is missing from my input node(s).</div>
        <IconButton
          icon="Trash"
          variant="dangerTransparent"
          size="small"
          className=""
          onClick={() => onDelete(id)}
        />
      </div>
    );
  } else if (status === ColumnStatus.NEW) {
    statusElement = (
      <div className="text-pri-success-700 text-xs">
        This column was added to my input node(s) since the last time this node was updated.
      </div>
    );
  }

  return (
    <div
      onClick={() => onToggleCheck(object)}
      className={outerClass}
      draggable={true}
      onDragStart={(e) => onDragStart(id, e)}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragEnter={(e) => onDragEnter(id, e)}
      onDragLeave={(e) => onDragLeave(id, e)}
      onDrop={(e) => onDrop(id, e)}
    >
      <div className="w-full flex justify-between items-center">
        <GripVertical size={16} color="var(--pri-gray-500)" className="flex-none" />
        <Checkbox
          variant="blue_gray"
          name={checkboxName}
          checked={isChecked}
          readOnly={true} // onChange is handled by the entire row, so the input is readOnly
          className="ml-1 flex-none"
        />
        <div className="p-1 flex-1 overflow-hidden">
          <div className="w-full f-between">
            <div className="flex-1 overflow-hidden">
              <PopperTrigger
                forceShow={involvedInDrag ? false : undefined}
                placement="top"
                triggers="hover"
                delay={{ show: 400, hide: 50 }}
                popoverProps={{ style: { maxWidth: '400px' } }}
                renderPopper={(popperProps) => {
                  return (
                    <Popover
                      content={<SelectColumnRowHoverCard columnValue={columnValue} samples={samples} />}
                      {...popperProps}
                    />
                  );
                }}
              >
                <div className="flex flex-col">
                  <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {socketElement}
                    {name}
                  </div>
                  {statusElement}
                </div>
              </PopperTrigger>
            </div>
            <TextInputGroup
              name={name}
              value={alias || ''}
              error={aliasError}
              onChange={handleOnChangeAlias}
              onClick={handleOnClickAlias}
              // These two drag props prevent you from dragging the entire row when your mouse is dragging to select text in the TextInput.
              draggable={true}
              onDragStart={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              groupClass="w-[300px] ml-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ColumnSearchableCheckboxPickerRow;
