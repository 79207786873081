import React, { useContext } from 'react';

import { AggTable } from 'api/tableAPI';
import LinkIconButton from 'components/inputs/basic/Button/LinkIconButton';
import { numRowsAsString, lastRunDateAsString, lastRunAgo } from 'model_helpers/aggTableHelper';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { getSnapshotsSourcesFullName } from 'utils/dbName';

import SidebarDescription from './SidebarDescription';
import VertexTableSection from './VertexTableSection';

interface SnapshotSidebarContentProps {
  table: AggTable;
}

export default function SnapshotSidebarContent(props: SnapshotSidebarContentProps) {
  const { table } = props;
  const { tables } = useContext(TableModelsContext);

  const rows = [
    { label: 'Rows', value: numRowsAsString(table) },
    { label: 'Snapshot At', value: lastRunDateAsString(table) },
    { label: 'Snapshot Ago', value: lastRunAgo(table) },
  ];

  // This snapshot table's source table if it exists.
  const snapshotsSourcesFullName = getSnapshotsSourcesFullName(table.full_name);
  const sourceTable: AggTable | null =
    tables.find((t) => t.full_name === snapshotsSourcesFullName) || null;

  const trackGoToSourceTable = () => {
    analytics.track('PipelineEditor GoToSnapshotSourceTable', {
      snapshot_table_id: table.id,
      source_table_id: sourceTable?.id,
    });
  };

  return (
    <div>
      <SidebarDescription description={table.description} />
      <VertexTableSection title="Last Snapshot" rows={rows} className="mt-6" />
      {sourceTable && (
        <LinkIconButton
          icon="BoxArrowUpRight"
          text="GO TO SOURCE TABLE"
          iconLast={true}
          iconSize={10}
          size="small"
          variant="lightDullTransparent"
          to={`/tables/${sourceTable.id}`}
          onClickCapture={trackGoToSourceTable}
          target="_blank"
          className="mt-6"
        />
      )}
    </div>
  );
}
