// When someone loading the usage page we fetch a bit over a year's worth of compute
//   data daily grouped by app_querying, connector data daily grouped by connector,
//   billing data by month, and will add storage data later.
// From something like a years worth of daily compute grouped by app_querying we can
//   use the pipelines in Compute tab, for example, to show just the last 60 days, or the entire year
//   grouped by month. This saves us from having to request more data from snowflake for most things.
import { useEffect, useState, useMemo, useCallback } from 'react';

import { subMonths, startOfMonth } from 'date-fns';

import API from 'api/API';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import { parseSqlResults } from 'utils/apiResponseFormatter';
import { formatApiDate } from 'utils/dateTime';

export interface UsagePostData {
  start_date?: string; // XXX TODO: start and enddate should not be optional
  end_date?: string;
  usage?: string;
  app_querying?: string;
  query_text_order_by?: 'compute_credits' | 'cloud_service_credits';
  sql?: string; // XXX TODO: This should be removed before shipping
}

export interface AppQueryingMonthlyRow {
  QUERY_MONTH: string;
  APP_QUERYING: string;
  NUM_RUNS: number;
  COMPUTE_CREDITS: number;
  CLOUD_SERVICE_CREDITS: number;
  TIME: number;
}

export interface AppQueryingDailyRow extends AppQueryingMonthlyRow {
  QUERY_DATE: string;
}

export interface CompanyPlanRow {
  index: number;
  MONTH_START: string;
  MOZART_COMPANY_ID: string;
  MOZART_COMPANY_NAME: string;
  PLAN_START_DATE: string;
  IS_USAGE: boolean;
  RECENT_ALLOTMENT_GRAIN: 'Monthly' | 'Annual' | null;
  RECENT_ANNUAL_START_DATE: string | null;
  PLAN_TYPE_MOST_RECENT: string;
  IS_CURRENT_MONTH: boolean;
  FIVETRAN_PRICING_VERSION_MOST_RECENT: string;
  MAX_SNOWFLAKE_DISCOUNT: number;
  MAX_FIVETRAN_DISCOUNT: number;
  MAX_ADDITIONAL_DISCOUNT: number;
  SUM_MONTHLY_AMOUNT: number;
  SUM_SNOWFLAKE_CREDITS_ALLOTMENT: number;
  SUM_FIVETRAN_MAR_ALLOTMENT: number;
  IS_SNOWFLAKE_OVERAGE: boolean;
  SUM_SNOWFLAKE_OVERAGE_NET: number;
  IS_FIVETRAN_OVERAGE: boolean;
  SUM_FIVETRAN_OVERAGE_NET: number;
  SUM_MONTHLY_INVOICE: number;
  PLATFORM_FEE_MOST_RECENT: number;
  SUM_COMPUTE_NET: number;
  SUM_FIVETRAN_NET: number;
  SUM_CLOUD_SERVICES_NET: number;
  MAX_STORAGE_NET: number;
  SUM_CREDITS_CLOUD_SERVICES: number;
  MAX_AVG_STORAGE_GB: number;
  SUM_CREDITS_COMPUTE: number;
  SUM_CREDITS_BILLED: number;
  SUM_MAR: number;
}

export interface ConnectorMonthlyRow {
  CONNECTOR_NAME: string;
  CONNECTOR_ID: string;
  MEASURED_MONTH: string;
  MAR: number;
}

export interface ConnectorDailyRow extends ConnectorMonthlyRow {
  MEASURED_DATE: string;
}

export interface StorageMonthlyRow {
  DATABASE_NAME: string;
  MEASURED_MONTH: string;
  AVG_STORAGE_GB: number;
}

export interface StorageDailyRow extends StorageMonthlyRow {
  MEASURED_DATE: string;
}

export const TODAY = new Date();
export const A_YEAR_AGO = startOfMonth(subMonths(TODAY, 12));

export default function useUsageFetch(enabled: boolean) {
  const [companyPlanByMonth, setCompanyPlanByMonth] = useState<CompanyPlanRow[] | null>(null);
  const [appQueryingData, setAppQueryingData] = useState<AppQueryingDailyRow[] | null>(null);
  const [connectorData, setConnectorData] = useState<ConnectorDailyRow[] | null>(null);
  const [storageData, setStorageData] = useState<StorageDailyRow[] | null>(null);

  const [error, setError] = useState('');

  const isUsageV2Enabled = useBooleanFlag('usage-v2', false);

  const safeFetchAndFormatData = useCallback(
    (url: string, postData: UsagePostData) => {
      setError('');
      const api = new API();
      return api
        .post(url, postData)
        .then((response) => {
          /*
          Due to the limbo state Usage v2 is currently in, there are two formats for the results based on 
          whether or not Usage v2 is enabled for the user. This check is to handle the two different formats.

          TODO(usage-v2): Remove this check once Usage v2 is fully enabled.
        */
          const formattedInColsAndRows =
            url.indexOf('billing_monthly') >= 0 ||
            url.indexOf('app_compute_usage_daily') >= 0 ||
            (isUsageV2Enabled && url.indexOf('connector_usage') >= 0) ||
            (isUsageV2Enabled && url.indexOf('storage_usage') >= 0);

          if (!formattedInColsAndRows) {
            return response.data;
          }

          const structuredRows = parseSqlResults(response.data.results) as any[];
          return structuredRows;
        })
        .catch((e) => {
          setError('There was a problem fetching usage data.');
          return Promise.reject(e);
        });
    },
    [isUsageV2Enabled],
  );

  // Fetch Company Plan Data
  useEffect(() => {
    if (enabled) {
      safeFetchAndFormatData('/api/usages/billing_monthly', {
        start_date: formatApiDate(A_YEAR_AGO),
        end_date: formatApiDate(TODAY),
      }).then((formattedData) => {
        setCompanyPlanByMonth(formattedData);
      });
    }
  }, [enabled, safeFetchAndFormatData]);

  // Fetch Compute Data by App Querying
  useEffect(() => {
    if (enabled) {
      safeFetchAndFormatData('/api/usages/app_compute_usage_daily', {
        start_date: formatApiDate(A_YEAR_AGO),
        end_date: formatApiDate(TODAY),
      }).then((formattedData) => {
        setAppQueryingData(formattedData);
      });
    }
  }, [enabled, safeFetchAndFormatData]);

  // Fetch MAR Data by Connector
  useEffect(() => {
    if (enabled) {
      safeFetchAndFormatData('/api/usages/connector_usage', {
        usage: 'daily',
        start_date: formatApiDate(A_YEAR_AGO),
        end_date: formatApiDate(TODAY),
      }).then((formattedData) => {
        setConnectorData(formattedData);
      });
    }
  }, [enabled, safeFetchAndFormatData]);

  // Fetch Storage Data
  useEffect(() => {
    if (enabled) {
      safeFetchAndFormatData('/api/usages/storage_usage', {
        usage: 'daily',
        start_date: formatApiDate(A_YEAR_AGO),
        end_date: formatApiDate(TODAY),
      }).then((formattedData) => {
        setStorageData(formattedData);
      });
    }
  }, [enabled, safeFetchAndFormatData]);

  const currentMonthPlan = useMemo(() => {
    if (companyPlanByMonth) {
      return (
        companyPlanByMonth.find((r) => {
          return r.MONTH_START === formatApiDate(startOfMonth(TODAY));
        }) || null
      );
    } else {
      return null;
    }
  }, [companyPlanByMonth]);

  return {
    error,
    companyPlanByMonth,
    appQueryingData,
    connectorData,
    storageData,
    currentMonthPlan,
    safeFetchAndFormatData,
    setError,
  };
}
