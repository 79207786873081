/*
The API returns data the frontend does not like. Convert it.
*/
import { AggTable } from 'api/tableAPI';

import { Vertex, Edge, Pipeline } from './PipelineEditor/PipelineEditor';

// There are three Vertex cases from the API:
// 1. Table exists so we get the name from the table and vertex name is null
// 2. Table doesnt exist so we have name set to whatever the user input in the sql. Probably looks like a full name (schema.table_name)
// 3. Table was soft deleted. The table exists in the backend, but the frontend wont be able to find it in the model context. In this case the vertex name is calculated in the backend serializer as the full name of the soft-deleted table.
export interface APIVertex {
  id: string; // ID of vertex
  // ID of table this vertex represents. This can be null if SQL references a table that doesn't exist.
  table: string | null;
  is_missing: boolean;
  name: string | null; // name will be null if we have a table that we expect to find in context
}

export interface APIPipelinePayload {
  vertices: APIVertex[];
  edges: Edge[];
}

interface VertexIdToGeneration {
  [key: string]: number;
}

function convertVertex(
  v: APIVertex,
  tablesbyID: { [key: string]: AggTable },
  vertexIdToGenerationMap: VertexIdToGeneration,
): Vertex {
  if (v.table === null) {
    return v as Vertex;
  } else {
    const table = tablesbyID[v.table] || null;
    return {
      id: v.id,
      table: table,
      is_missing: v.is_missing,
      name: v.name, // FYI: If the table exists, v.name will be null and the code will use table.full_name.
      generation: vertexIdToGenerationMap[v.id] || 0,
    };
  }
}

// TODO We may want to revist this when fixing generations.
// This makes sure the edge destination is always of a greater generation than the source.
function generateGenerationMap(p: APIPipelinePayload): VertexIdToGeneration {
  const { edges } = p;
  let vertexIdToGenerationMap: VertexIdToGeneration = {};
  // This is an n^2 algorithm.
  // If there is a cycle, generations could get pushed out to the upper bound (but not to infinity).
  for (let generation = 0; generation < edges.length; generation++) {
    for (const edge of edges) {
      if (
        (vertexIdToGenerationMap[edge.source] || 0) === generation &&
        (vertexIdToGenerationMap[edge.destination] || 0) <= generation
      ) {
        vertexIdToGenerationMap[edge.destination] = generation + 1;
      }
    }
  }
  return vertexIdToGenerationMap;
}

export function convertPayload(
  p: APIPipelinePayload,
  currentTable: AggTable | null,
  tablesbyID: { [key: string]: AggTable },
): Pipeline {
  const vertexIdToGenerationMap = generateGenerationMap(p);
  return {
    vertices: p.vertices.map((v) => convertVertex(v, tablesbyID, vertexIdToGenerationMap)),
    edges: p.edges,
    currentTable,
  };
}
