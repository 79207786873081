/*
Adds a drawer component that floats above containing element content.
Right now it attaches to the right side of the containing element
and can be resized on the left edge of this component.
This can be generalized to any direction in the future.
*/
import React, { useState } from 'react';

import cn from 'classnames';
import { Resizable } from 're-resizable';

import { HANDLE_WIDTH } from '../draggable_panes/ControlledDraggablePanes/ControlledDraggablePanes';

type Width = `${number}px` | `${number}%`;

export interface ControlledResizeableDrawerProps {
  show: boolean;
  width: number;
  children: React.ReactNode;
  minWidth?: Width;
  maxWidth?: Width;
  shadow?: boolean;
  setWidth: (width: number) => void;
}

export default function ControlledResizeableDrawer(props: ControlledResizeableDrawerProps) {
  const {
    show,
    width,
    minWidth = '100px',
    maxWidth = '600px',
    shadow = true,
    setWidth,
    children,
  } = props;
  const [resizing, setResizing] = useState(false);

  const handleResize = (
    event: MouseEvent | TouchEvent,
    direction: any,
    refToElement: HTMLElement,
    delta: any,
  ) => {
    setWidth(refToElement.offsetWidth);
  };

  const handleClass = cn('z-[902] hover:bg-sec-blue-gray-300', {
    'bg-sec-blue-gray-300': resizing,
  });

  // Completely hide the drawer if it's toggled off
  if (!show) {
    return null;
  }

  return (
    <div
      className={cn('absolute right-0 top-0 z-[901] h-full', {
        'shadow-[-2px_0_4px_0_rgba(0,0,0,0.1)]': shadow,
      })}
    >
      <Resizable
        size={{
          width: `${width}px`,
          height: '100%',
        }}
        minWidth={minWidth}
        maxWidth={maxWidth}
        minHeight="100%"
        maxHeight="100%"
        enable={{
          left: true,
        }}
        handleClasses={{ left: handleClass }}
        handleStyles={{
          left: {
            width: HANDLE_WIDTH,
            height: '100%',
            left: `-${HANDLE_WIDTH}`,
            top: '0',
          },
        }}
        onResize={handleResize}
        onResizeStart={() => {
          setResizing(true);
        }}
        onResizeStop={() => {
          setResizing(false);
        }}
      >
        {children}
      </Resizable>
    </div>
  );
}
