import Button from 'components/inputs/basic/Button/Button';
import Modal from 'components/layouts/containers/modals/Modal/Modal';

import { ConnectorType } from '../ConnectorRegistry';

interface ManualSetupModalProps {
  connector: ConnectorType;
  onClose(): void;
}

export default function ManualSetupModal(props: ManualSetupModalProps) {
  const { connector, onClose } = props;

  const modalFooter = (
    <div className="flex">
      <Button variant="lightDanger" onClick={onClose} className="ml-2">
        Close
      </Button>
    </div>
  );

  return (
    <Modal header="Manual Connector Setup Required" footer={modalFooter} onClose={onClose}>
      <div className="p-4 text-left">
        {connector?.vendor === 'portable' ? (
          <>
            <p>
              Connector type <code>{connector.name}</code> is provided by our partner Portable.
            </p>
            <p className="mt-4">
              Please contact support@mozartdata.com to set up Portable or for further help.
            </p>
            <p className="mt-4">
              If you have previously set up Portable, you can use the same account for this connector.
            </p>
          </>
        ) : connector?.vendor === 'fivetran' && connector.lite ? (
          <>
            <p>
              Connector type <code>{connector?.name}</code> may be available in Beta or Private Preview
              mode via our partner Fivetran.
            </p>
            <p className="mt-4">
              Please contact support@mozartdata.com to see if we can get it enabled for your account.
            </p>
          </>
        ) : (
          <>
            <p>
              Connector type <code>{connector?.name}</code> must be setup manually by Mozart staff.
            </p>
            <p className="mt-4">Please contact support@mozartdata.com for help.</p>
            <p className="mt-4">This process is quick and easy. It just can't be automated yet.</p>
          </>
        )}
      </div>
    </Modal>
  );
}
