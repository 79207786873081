const reservedWords = [
  'account',
  'all',
  'alter',
  'and',
  'any',
  'as',
  'between',
  'by',
  'case',
  'cast',
  'check',
  'column',
  'connect',
  'connection',
  'constraint',
  'create',
  'cross',
  'current',
  'current_date',
  'current_time',
  'current_timestamp',
  'current_user',
  'database',
  'delete',
  'distinct',
  'drop',
  'else',
  'exists',
  'false',
  'following',
  'for',
  'from',
  'full',
  'grant',
  'group',
  'gscluster',
  'having',
  'ilike',
  'in',
  'increment',
  'inner',
  'insert',
  'intersect',
  'into',
  'is',
  'issue',
  'join',
  'lateral',
  'left',
  'like',
  'localtime',
  'localtimestamp',
  'minus',
  'natural',
  'not',
  'null',
  'of',
  'on',
  'or',
  'order',
  'organization',
  'qualify',
  'regexp',
  'revoke',
  'right',
  'rlike',
  'row',
  'rows',
  'sample',
  'schema',
  'select',
  'set',
  'some',
  'start',
  'table',
  'tablesample',
  'then',
  'to',
  'trigger',
  'true',
  'try_cast',
  'union',
  'unique',
  'update',
  'using',
  'values',
  'view',
  'when',
  'whenever',
  'where',
  'with',
];

// Same as `reservedWords` except:
//   1. Words that are annoying in autocomplete, such as 'as', are removed.
//   2. Words that are not applicable to transforms, such as 'delete', are removed.
//   3. Words that are never used in practice are removed.
//      Query for figuring this out:
//      https://app.mozartdata.com/query?query=SELECT%20*%20FROM%20mozart.snowflake_words_used%0AWHERE%20is_reserved%3Dtrue%20AND%20word_score%20%3C%2010%0Aorder%20by%20word
const autocompleteReservedWords = [
  // 'account',
  // 'all',
  // 'alter',
  // 'and',
  // 'any',
  // 'as',
  'between',
  // 'by',
  'case',
  // 'cast',
  'check',
  'column',
  // 'connect',
  // 'connection',
  // 'constraint',
  // 'create',
  'cross',
  'current',
  'current_date',
  'current_time',
  'current_timestamp',
  // 'current_user',
  // 'database',
  // 'delete',
  'distinct',
  // 'drop',
  'else',
  // 'exists',
  // 'false',
  'following',
  // 'for',
  'from',
  'full',
  // 'grant',
  'group',
  // 'gscluster',
  'having',
  'ilike',
  // 'in',
  // 'increment',
  'inner',
  // 'insert',
  // 'intersect',
  // 'into',
  // 'is',
  'issue',
  'join',
  'lateral',
  'left',
  'like',
  // 'localtime',
  // 'localtimestamp',
  // 'minus',
  'natural',
  // 'not',
  // 'null',
  // 'of',
  // 'on',
  // 'or',
  'order',
  // 'organization',
  'qualify',
  'regexp',
  // 'revoke',
  'right',
  'rlike',
  // 'row',
  // 'rows',
  // 'sample',
  // 'schema',
  'select',
  // 'set',
  'some',
  'start',
  // 'table',
  // 'tablesample',
  // 'then',
  // 'to',
  // 'trigger',
  // 'true',
  // 'try_cast',
  'union',
  'unique',
  // 'update',
  'using',
  // 'values',
  // 'view',
  'when',
  // 'whenever',
  'where',
  'with',
];

export type ReservedWordDic = {
  [key: string]: boolean;
};

// Build lookup table from array
const reservedWordDic: ReservedWordDic = {};
for (const w of reservedWords) {
  reservedWordDic[w] = true;
}

export default reservedWords;
export { reservedWordDic, autocompleteReservedWords };
