import { useLayoutEffect, useMemo, useRef } from 'react';

import parser from 'ansi-style-parser';

import { DbtRun } from 'api/dbtAPI';
import CopyButton from 'components/inputs/basic/Button/CopyButton';
import IconButton from 'components/inputs/basic/Button/IconButton';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import Duration from 'components/widgets/time/Duration/Duration';
import { calcGenericRunStatusFromState } from 'model_helpers/dbtRunConfigHelper';

const BETTER_COLORS: { [key: string]: string } = {
  red: 'var(--pri-error-700)',
  yellow: 'var(--pri-warning-700)',
  green: 'var(--pri-success-700)',
};

interface RunLogModalProps {
  loading: boolean;
  run: DbtRun;
  logs: string[];
  onClose(): void;
  onCopyLogs(): void;
  onDownloadLogs(): void;
}

const RunLogModal = (props: RunLogModalProps) => {
  const { loading, run, logs, onClose, onCopyLogs, onDownloadLogs } = props;
  const { created_at, completed_at, state } = run;
  const lastRowRef = useRef<HTMLDivElement>(null);

  // When a new log line is appended, it might get rendered out the bottom of the scroll window.
  // Scroll the log window so the most recent log line is always in view.
  useLayoutEffect(() => {
    if (lastRowRef.current) {
      lastRowRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [logs]); // eslint-disable-line react-hooks/exhaustive-deps

  const parsedLogs = useMemo(() => {
    return logs.map((l) => parser(l));
  }, [logs]);

  // Useful debugging code:
  // console.log(logs.join('\n'))
  // logs.forEach(l=> {
  //   parser(l).forEach(p=> console.log(p.styles))
  // })

  return (
    <Modal onClose={onClose} cancelButton={true} header="Logs" fullscreen={true}>
      {loading ? (
        <CenteredSpinner containerMinHeight="60vh" />
      ) : (
        <div className="w-full h-full f-col">
          <div className="py-2 px-4 f-between border-b border-solid border-blueGray-200">
            <div className="f-row-y-center">
              <span className="font-medium">Status</span>
              <span className="ml-2">
                <StatusIcon status={calcGenericRunStatusFromState(state)} rotate={true} />
              </span>
              <span className="ml-4 font-medium">Duration</span>
              <span className="ml-2">
                <Duration start={created_at} end={completed_at} interval={1000} />
              </span>
            </div>
            <div className="f-row-y-center">
              <CopyButton onClick={onCopyLogs} text="Copy" />
              <IconButton
                icon="Download"
                size="small"
                variant="lightDullTransparent"
                onClick={onDownloadLogs}
                className="ml-2"
                text="Download"
              />
            </div>
          </div>
          {/* We might want to bring this back. */}
          {/* <textarea
            style={{
              width: '100%',
              height: '100%',
              overflowY: 'auto',
            }}
            className="font-mono"
            readOnly
            value={logs.join('\n')}
          /> */}
          <div
            ref={lastRowRef}
            className="w-full h-full py-2 px-4 overflow-auto font-mono text-sm whitespace-pre"
          >
            {parsedLogs.map((parsedChunks, i) => (
              <div key={i} ref={i === parsedLogs.length - 1 ? lastRowRef : undefined}>
                {parsedChunks.map((chunk, j) => {
                  // This is a hacky color look up but I think it will work.
                  // This assumes we only get colors back and not other styles like bold, italics, or background colors.
                  // This assumtion seems to be the case as of Aug 3, 2023.
                  const ansiColor = chunk.styles.length > 0 ? chunk.styles[0] : 'UNKNOWN';
                  const betterColor = BETTER_COLORS[ansiColor];
                  const style = { color: betterColor };
                  return (
                    <span key={j} style={style}>
                      {chunk.text}
                    </span>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default RunLogModal;
