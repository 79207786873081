import { FlowchartQueryModel, FlowchartVertex, SourceTable } from '../model/FlowchartQueryModel';

import ToolbarItem, { ToolbarItemDefinition } from './ToolbarItem/ToolbarItem';

export interface ToolbarProps {
  flowchartModel: FlowchartQueryModel;
}

const Toolbar = (props: ToolbarProps) => {
  const { vertices } = props.flowchartModel;
  const items: ToolbarItemDefinition[] = [
    {
      id: 'source_table',
      title: 'Source Table',
      description: 'All queries start from the raw data in one or more source tables.',
      img: undefined, // We decided not to have an img for this step.
    },
    {
      id: 'select_columns',
      title: 'Pick Columns',
      description: 'Select, rename, and reorder columns.',
      img: 'select_columns.svg',
    },
    // {
    //   id: 'union',
    //   title: 'Union Tables',
    //   description: 'Combine the rows of two tables that have the same columns into one table.',
    //   img: 'union.png',
    // },
    {
      id: 'join',
      title: 'Join Tables',
      description: 'Combine the rows of tables based on matching column values.',
      img: 'join.svg',
    },
    // {
    //   id: 'where',
    //   title: 'Filter Table',
    //   description: 'Filter a table to only the rows that meet certain criteria.',
    //   img: 'join_tables.png',
    // },
    // {
    //   id: 'group_by',
    //   title: 'Group Rows By Column',
    //   description:
    //     'Group rows by like column values and perform aggregate functions on the groups like averages.',
    //   img: 'group_by.png',
    // },
    // {
    //   id: 'order_by',
    //   title: 'Order Rows',
    //   description: 'Order the rows in your table by column values.',
    //   img: 'order_by.png',
    // },
  ];

  const isItemEnabled = (item: ToolbarItemDefinition, vertices: FlowchartVertex[]): boolean => {
    if (item.id === 'source_table') {
      return true;
    }

    return vertices.some((v) => v.type === 'source_table' && (v as SourceTable).table !== null);
  };

  return (
    <div className="">
      <div className="w-[44px] p-1 grid grid-cols-1 gap-0 rounded bg-sec-blue-gray-100">
        {items.map((i) => (
          <ToolbarItem key={i.id} {...i} enabled={isItemEnabled(i, vertices)} />
        ))}
      </div>
    </div>
  );
};

export default Toolbar;
