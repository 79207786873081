import { ForwardedRef, forwardRef } from 'react';

import { Link } from 'react-router-dom';

import cn from 'classnames';

import { UserProfile } from 'api/APITypes';
import { useDatabaseAccount } from 'context/AuthContext';
import { OnboardingPopoverTourRefs } from 'model_layer/OnboardingPopoverTourContext';

interface PrivateLinkProps {
  userProfile: UserProfile;
  currentPath: string;
  onboardingPopoverTourRefs: OnboardingPopoverTourRefs;
}

const PrivateLinks = (props: PrivateLinkProps) => {
  const {
    userProfile: {
      company: { metabase_integration, dbt_integration_enabled, sigma_integration, looker_integration },
    },
    currentPath,
    onboardingPopoverTourRefs,
  } = props;
  const databaseType = useDatabaseAccount().type;

  const isViewer = props.userProfile.company_role === 'viewer';

  const baseLinkClass = 'text-pri-gray-300 hover:text-white mr-4';

  return (
    <div>
      <HeaderLink
        ref={onboardingPopoverTourRefs.connectors}
        baseLinkClass={baseLinkClass}
        path="/connectors"
        label="Connectors"
        dataTrack="Header Connectors"
        isCurrentPath={currentPath === '/connectors'}
      />
      <HeaderLink
        ref={onboardingPopoverTourRefs.warehouse}
        baseLinkClass={baseLinkClass}
        path="/warehouse"
        label="Warehouse"
        dataTrack="Header Warehouse"
        isCurrentPath={currentPath === '/warehouse'}
      />
      <HeaderLink
        ref={onboardingPopoverTourRefs.query}
        baseLinkClass={baseLinkClass}
        path="/query"
        label="Query"
        dataTrack="Header Query"
        isCurrentPath={currentPath.slice(0, 6) === '/query'}
      />
      <HeaderLink
        ref={onboardingPopoverTourRefs.dbt}
        baseLinkClass={baseLinkClass}
        path={dbt_integration_enabled ? '/dbt' : '/integrations/dbt'}
        label="dbt"
        dataTrack="Header DBT"
        isCurrentPath={currentPath === '/dbt' || currentPath === '/integrations/dbt'}
      />
      {databaseType !== 'bigquery' && (
        <HeaderLink
          ref={onboardingPopoverTourRefs.alerts}
          baseLinkClass={baseLinkClass}
          path="/alerts"
          label="Alerts"
          dataTrack="Header DataAlerts"
          isCurrentPath={currentPath === '/alerts'}
        />
      )}
      <HeaderLink
        ref={onboardingPopoverTourRefs.integrations}
        baseLinkClass={baseLinkClass}
        path="/integrations"
        label="Integrations"
        dataTrack="Header Integrations"
        isCurrentPath={currentPath === '/integrations'}
      />
      {!isViewer && databaseType !== 'bigquery' && (
        <HeaderLink
          baseLinkClass={baseLinkClass}
          path="/usage"
          label="Usage"
          dataTrack="Header Usage"
          isCurrentPath={currentPath.match('^/usage.*') !== null}
        />
      )}
      {sigma_integration && (
        <a
          href={sigma_integration.app_url}
          className={baseLinkClass}
          rel="noopener noreferrer"
          target="_blank"
          data-track="Header Sigma"
        >
          Sigma
        </a>
      )}
      {looker_integration && (
        <a
          href={looker_integration.base_url}
          className={baseLinkClass}
          rel="noopener noreferrer"
          target="_blank"
          data-track="Header Looker"
        >
          Looker
        </a>
      )}

      {metabase_integration?.is_active && (
        <a
          href={metabase_integration.url}
          className={baseLinkClass}
          rel="noopener noreferrer"
          target="_blank"
          data-track="Header Metabase"
        >
          Metabase
        </a>
      )}
    </div>
  );
};

interface HeaderLinkProps {
  baseLinkClass: string;
  path: string;
  label: string;
  dataTrack: string;
  isCurrentPath: boolean;
}

const HeaderLink = forwardRef<HTMLAnchorElement, HeaderLinkProps>(
  (props: HeaderLinkProps, forwardedRef: ForwardedRef<HTMLAnchorElement>) => {
    const { baseLinkClass, path, label, dataTrack, isCurrentPath } = props;
    const currentLinkClass = 'text-silver font-medium';
    const actualClass = cn(baseLinkClass, { [currentLinkClass]: isCurrentPath });
    return (
      <Link ref={forwardedRef} to={path} data-track={dataTrack} className={actualClass}>
        {label}
      </Link>
    );
  },
);

export default PrivateLinks;
