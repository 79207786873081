import { useCallback, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';

import { AggTable } from 'api/tableAPI';

import { EditVertex } from '../../FlowchartEditor';
import { FlowchartQueryModel, FlowchartVertex, SourceTable } from '../../model/FlowchartQueryModel';
import { pickAlias } from '../../query_builder/queryBuilder';

import { SourceTableModalProps } from './SourceTableModal/SourceTableModal';

export interface SourceTableState extends EditVertex<SourceTable, SourceTableModalProps> {}

export default function useSourceTable(
  updateVertex: (vertex: FlowchartVertex) => void,
  handleSelectVertex: (vertex: FlowchartVertex) => void,
  handleSetColumnsForTable: (vertex: SourceTable, table: AggTable) => void,
  flowchartQueryModel: FlowchartQueryModel,
): SourceTableState {
  const { vertices } = flowchartQueryModel;
  const [vertex, setVertex] = useState<SourceTable | null>(null);

  const onEditVertex = useCallback((vertex: SourceTable) => {
    setVertex(vertex);
  }, []);

  const onCancel = useCallback(() => {
    setVertex(null);
  }, []);

  const onSave = useCallback(
    (table: AggTable) => {
      if (vertex) {
        const updatedVertex = cloneDeep(vertex);
        updatedVertex.table = table;
        updatedVertex.alias = pickAlias(table, vertices);

        updateVertex(updatedVertex);
        handleSelectVertex(updatedVertex);
        handleSetColumnsForTable(updatedVertex, table);
        setVertex(null);
      }
    },
    [vertex, vertices, updateVertex, handleSelectVertex, handleSetColumnsForTable],
  );

  const modalProps: SourceTableModalProps | null = useMemo(() => {
    if (vertex) {
      const newModalProps: SourceTableModalProps = {
        vertex,
        flowchartQueryModel,
        onCancel,
        onSave,
      };

      return newModalProps;
    }

    return null;
  }, [vertex, flowchartQueryModel, onCancel, onSave]);

  const result: SourceTableState = useMemo(
    () => ({
      modalProps,
      onEditVertex,
    }),
    [modalProps, onEditVertex],
  );

  return result;
}
