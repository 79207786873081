import copy from 'copy-to-clipboard';

import IconButton from 'components/inputs/basic/Button/IconButton';

import styles from './SQLBox.module.css';

export type SQLCommand = {
  comment: string;
  commands: string[];
};

interface SQLBoxProps {
  sqlCommands: SQLCommand[];
  copyEvent: string;
}

export default function SQLBox(props: SQLBoxProps) {
  const { sqlCommands, copyEvent } = props;

  const handleCopySql = () => {
    copy(
      // concat the SQL commands to a string
      Object.values(sqlCommands)
        .map((sql) => {
          return sql.comment + '\n' + sql.commands.join('\n');
        })
        .join('\n'),
    );
    analytics.track(copyEvent);
  };

  return (
    <div className={styles.SQLBox}>
      <IconButton
        icon="Files"
        text=""
        className="!absolute top-4 right-4"
        variant="lightTransparent"
        onClick={handleCopySql}
      ></IconButton>
      <ol className={styles.list}>
        {sqlCommands.map((sql) => (
          <li className={styles.listItem} key={sql.comment}>
            <p>{sql.comment}</p>
            {sql.commands.map((cmd: string) => (
              <p key={cmd}>{cmd}</p>
            ))}
          </li>
        ))}
      </ol>
    </div>
  );
}
