/*
TextAreaInput prefixed by label element and followed by an error element.
*/
import React from 'react';

import TextAreaInput from 'components/inputs/basic/TextAreaInput/TextAreaInput';

import InputGroup, { InputGroupParentProps } from '../InputGroup';

export type SanitizedTextAreaProps = Omit<
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
  'key' | 'name' | 'value'
>;

export interface TextAreaInputGroupProps extends InputGroupParentProps, SanitizedTextAreaProps {
  value: string;
  rows?: number;
}

export default function TextAreaInputGroup(props: TextAreaInputGroupProps) {
  const {
    ref, // Not used. Just don't want it included in ...remainingProps.
    name,
    rows,
    label,
    postLabelElement,
    key,
    groupClass,
    groupStyle,
    labelClass,
    labelStyle,
    error,
    superscriptDetail,
    superscriptDetailClass,
    subscriptDetail,
    subscriptDetailClass,
    ...remainingProps
  } = props;
  const hasError = typeof error === 'string' && error.length > 0;

  return (
    <InputGroup
      name={name}
      input={<TextAreaInput rows={rows} name={name} hasError={hasError} {...remainingProps} />}
      superscriptDetail={superscriptDetail}
      superscriptDetailClass={superscriptDetailClass}
      subscriptDetail={subscriptDetail}
      subscriptDetailClass={subscriptDetailClass}
      error={error}
      key={key}
      label={label}
      postLabelElement={postLabelElement}
      groupClass={groupClass}
      groupStyle={groupStyle}
      labelClass={labelClass}
      labelStyle={labelStyle}
    />
  );
}
