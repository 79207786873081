import React from 'react';

import cn from 'classnames';

import s from './RadioInput.module.css';

export interface RadioInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  value: string;
  label: string | React.ReactNode;
  containerClass?: string;
  labelClass?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export default function RadioInput(props: RadioInputProps) {
  const {
    name,
    value,
    label,
    checked,
    onChange,
    className,
    containerClass,
    labelClass,
    disabled,
    ...remainingProps
  } = props;

  const defaultContainerClass = 'inline h-6 flex items-center';
  const actualContainerClass = cn(defaultContainerClass, containerClass);

  const defaultInputClass = `
    m-1 mr-2
    border-pri-gray-500 hover:border-purple
    focus:border-purple focus:ring-4 focus:ring-offset-0 focus:ring-sec-purple-200
    checked:bg-purple checked:border-purple
    hover:cursor-pointer
    bg-none`;
  const actualInputClass = cn(defaultInputClass, className, s.input);

  const defaultLabelClass = 'text-pri-gray-700 hover:cursor-pointer';
  const disabledLabelClass = 'cursor-default text-pri-gray-400';
  const actualLabelClass = cn(
    { [defaultLabelClass]: !disabled },
    { [disabledLabelClass]: disabled },
    labelClass,
  );

  const id = `${name}_${value}_id`;

  return (
    <div className={actualContainerClass}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className={actualInputClass}
        disabled={disabled}
        {...remainingProps}
      />
      <label htmlFor={id} className={actualLabelClass}>
        {label}
      </label>
    </div>
  );
}
