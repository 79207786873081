/*
 * Spacing template that renders a center div with optional:
 * header, title, loading indicator, and error message.
 */
import React from 'react';

import cn from 'classnames';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';

export interface CenteredContainerProps {
  // Replace the default header with this one.
  // This replaces the use of `title`.
  header?: React.ReactNode;
  // Main H1 page title of default header
  title?: string;
  // Is the page waiting for an API response before it can render?
  loading?: boolean;
  // Only use this error if the page failed to load OR
  // you are extremely confident the user is scrolled to the top of the page.
  // Form submit buttons and other actions should display errors near
  // the button that got clicked because that button and the loadingError
  // may not be visible at the same time.
  // It might make sense for this to be an array of errors.
  // Don't change until that becomes necessary.
  loadingError?: string;

  // The main content of the page
  children?: React.ReactNode;

  containerClass?: string;
  contentWrapperClass?: string;
  childrenWrapperClass?: string;

  // The max-width of the centered column as a CSS prop.
  // For page layouts, it's much better if this is in px.
  maxWidth?: string;

  contentClassName?: string;
}

export default function CenteredContainer(props: CenteredContainerProps) {
  const {
    title,
    loading,
    loadingError,
    header,
    children,
    containerClass,
    contentWrapperClass,
    childrenWrapperClass,
    maxWidth,
    contentClassName,
  } = props;

  return (
    <div className={cn('w-full flex justify-center pt-6 px-6', containerClass)}>
      <div className={cn('w-full', contentClassName)} style={{ maxWidth: maxWidth }}>
        {header}
        {!header && title && <h1 className="display-sm text-pri-gray-700">{title}</h1>}
        <div className={cn('my-6', contentWrapperClass)}>
          {loading && <CenteredSpinner containerMinHeight="50vh" />}
          {!loading && loadingError && (
            <Alert variant="error" className="mb-6">
              {loadingError}
            </Alert>
          )}
          {!loading && <div className={childrenWrapperClass}>{children}</div>}
        </div>
      </div>
    </div>
  );
}
