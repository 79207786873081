import { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import qs from 'query-string';

import API from 'api/API';
import { getNewFlowchart } from 'components/query/FlowchartEditor/model/FlowchartQueryModel';
import { SavedFlowchartQueryModel } from 'components/query/FlowchartEditor/model/SavedFlowchartQueryModel';
import { useUserProfile } from 'context/AuthContext';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import { UserPreferencesContext } from 'model_layer/UserPreferencesContext';
import { prefillTransform } from 'pages/transforms/AddTransform';
import { patch } from 'utils/Array';
import { convertUnsafeNametoSafeIndentifier } from 'utils/dbName';

interface MinimalUserProfile {
  id: string;
  first_name: string;
  last_name: string;
}

interface SavedQueryToUserProfile {
  id: string;
  created_at: string;
  updated_at: string;
  deleted: boolean;
  deleted_at: string;
  user_profile: MinimalUserProfile;
  last_run: string;
  last_updated: string;
  creator: boolean;
}

export interface SavedQuery {
  id: string;
  created_at: string;
  updated_at: string;
  saved_query_to_user_profiles: SavedQueryToUserProfile[];
  sql: string;
  name: string;
  flowchart_model: SavedFlowchartQueryModel | null;
}

export type AutoSavingState = 'Unsaved' | 'Saving...' | 'Saved' | 'Saving Error';

export default function useQueryTabs(
  queryPageBaseUrl: string,
  savedQueryId?: string,
  newTabSql?: string | null,
) {
  const [selectedTabId, setSelectedTabId] = useState('');
  const [allSavedQueries, setAllSavedQueries] = useState<SavedQuery[]>([]);
  const allSavedQueriesRef = useRef(allSavedQueries);
  // Update the reference to allSavedQueries every render,
  // so `const save = useCallback()` can have an empty dependency array; and therefore,
  // prevent lots of expensive components from rerendering more than they need to.
  allSavedQueriesRef.current = allSavedQueries;
  const [openTabs, setOpenTabs] = useState<SavedQuery[]>([]);
  const [editTabName, setEditTabName] = useState(false);
  const [autoSavingState, setAutoSavingState] = useState<AutoSavingState>('Saved');
  const [creatingNewTab, setCreatingNewTab] = useState(false);
  const [shareQueryModalId, setShareQueryModalId] = useState('');
  const [listQueriesModalOpen, setListQueriesModalOpen] = useState(false);
  const [cloningSavedQueryId, setCloningSavedQueryId] = useState('');
  const [deletingSavedQueryId, setDeletingSavedQueryId] = useState('');
  const {
    initialLoadComplete: userPreferencesLoaded,
    userPreferences,
    updateUserPreferences,
  } = useContext(UserPreferencesContext);

  const {
    userProfile: { company_role },
  } = useUserProfile();

  const history = useHistory();
  const flowchartEditorEnabled = useBooleanFlag('flowchart_editor', false);

  // On Load:
  // Fetch all saveableQueries from BE and merge with frontendPreferences saveableQueryTabs
  // If there are none, create a new empty tab
  useEffect(() => {
    // Wait for userPreferences
    if (userPreferencesLoaded) {
      const api = new API();
      api
        .get('/api/saved_queries')
        .then((response) => {
          return response.data as SavedQuery[];
        })
        .then((apiSavedQueries) => {
          const apiSavedQueryById = _.keyBy(apiSavedQueries, 'id');
          let initalTabs =
            userPreferences.openSavedQueryTabIds?.map((id) => apiSavedQueryById[id]).filter((x) => x) ||
            [];
          if (savedQueryId) {
            if (apiSavedQueryById[savedQueryId]) {
              // If we got passed in a savedQueryId and we have it in apiSavedQueries, make sure it's in initialTabs and selected
              if (!userPreferences.openSavedQueryTabIds?.includes(savedQueryId)) {
                // We need to add the savedQueryId to initial tabs if it's not in there
                initalTabs = [apiSavedQueryById[savedQueryId], ...initalTabs];
              }
              handleSetTabs(initalTabs);
              setAllSavedQueries(apiSavedQueries);
              handleSelectTab(savedQueryId, true);
            } else {
              // If we don't have it in savedQueries, fetch it from the backend and error if it isn't available
              const fetchIndividualQueryApi = new API();
              return fetchIndividualQueryApi
                .get(`/api/saved_queries/${savedQueryId}`)
                .then((response) => {
                  return response.data as SavedQuery;
                })
                .then((newSavedQuery) => {
                  setAllSavedQueries([...apiSavedQueries, newSavedQuery]);
                  handleSetTabs([newSavedQuery, ...initalTabs]);
                  handleSelectTab(newSavedQuery.id, true);
                })
                .catch((error) => {
                  // Someone tried to request a savedQuery that does not exist or they have no access to, so show them 404 page
                  history.push('/404');
                });
            }
          } else if (newTabSql !== undefined && newTabSql !== null) {
            // If we got sql in the url, create a new tab with that SQL
            createNewSavedQuery(newTabSql)
              .then((response) => {
                return response.data as SavedQuery;
              })
              .then((newSavedQuery) => {
                setAllSavedQueries([...apiSavedQueries, newSavedQuery]);
                handleSetTabs([newSavedQuery, ...initalTabs]);
                handleSelectTab(newSavedQuery.id, true);
              });
          }
          // If the user has no tabs, create a new one
          else if (initalTabs.length === 0) {
            createNewSavedQuery()
              .then((response) => {
                return response.data as SavedQuery;
              })
              .then((newSavedQuery) => {
                setAllSavedQueries([...apiSavedQueries, newSavedQuery]);
                handleSetTabs([newSavedQuery]);
                handleSelectTab(newSavedQuery.id, true);
              });
          } else {
            setAllSavedQueries(apiSavedQueries);
            handleSetTabs(initalTabs);
            const tabIdToSelect =
              userPreferences.selectedSavedQueryId &&
              initalTabs.find((t) => t.id === userPreferences.selectedSavedQueryId)
                ? userPreferences.selectedSavedQueryId
                : initalTabs[0].id;
            handleSelectTab(tabIdToSelect, true); // Autoselect first tab if the user does not have a valid tab stored in userPreferences
          }
        });
    }
  }, [userPreferencesLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  /*******************************************************************************
   * AUTOSAVING LOGIC START
   ******************************************************************************/
  const save = useCallback((savedQueryId: string, sql?: string, name?: string) => {
    setAutoSavingState('Saving...');
    const postData = { sql, name };
    const api = new API();
    api
      .patch(`/api/saved_queries/${savedQueryId}`, postData)
      .then((response) => response.data as SavedQuery)
      .then((updatedSavedQuery) => {
        setAutoSavingState('Saved');
        // Replace the old value from allSavedQueries with the new one
        const newAllSavedQueries = patch(
          allSavedQueriesRef.current,
          updatedSavedQuery,
          (sq) => sq.id === updatedSavedQuery.id,
        );
        setAllSavedQueries(newAllSavedQueries);
        return updatedSavedQuery;
      })
      .catch((error) => {
        setAutoSavingState('Saving Error');
      });
  }, []);

  const handleSetTabs = useCallback(
    (tabs: SavedQuery[]) => {
      // Wrapper for setTabs so we also always set the userPreferences and asserts we don't have duplicates
      setOpenTabs(_.uniqBy(tabs, 'id'));
      updateUserPreferences({ openSavedQueryTabIds: tabs.map((t) => t.id) });
    },
    [updateUserPreferences],
  );

  // This debounced save should get called in the onChange handler for sql and name
  const debouncedSave = useMemo(
    () =>
      _.debounce((savedQueryId: string, sql?: string, name?: string) => {
        save(savedQueryId, sql, name);
      }, 1000),
    [save],
  );

  // When allSavedQueries is updated, make sure openTabs does not contain any tabs that no longer exist
  useEffect(() => {
    if (_.differenceBy(openTabs, allSavedQueries, 'id').length > 0) {
      handleSetTabs(_.intersectionBy(openTabs, allSavedQueries, 'id'));
    }
    // We cannot add openTabs to the dependencies or it causes an infinite loop
  }, [allSavedQueries, handleSetTabs]); // eslint-disable-line react-hooks/exhaustive-deps

  /*******************************************************************************
   * FLOWCHART LOGIC START
   *
   * TODO:
   * At this point in time it is too complicated for me to reason about autosaving
   * flowcharts. Do manual save as intermediate step before trying to wrap
   * my head around autosaving.
   ******************************************************************************/
  const [savingFlowchart, setSavingFlowchart] = useState(false);
  const [savingFlowchartError, setSavingFlowchartError] = useState('');
  const saveFlowchart = useCallback(
    (
      savedQueryId: string,
      flowchart_model: SavedFlowchartQueryModel,
      sql: string,
    ): Promise<SavedFlowchartQueryModel> => {
      setSavingFlowchart(true);
      setSavingFlowchartError('');
      const postData = { sql, flowchart_model };
      const api = new API();
      return api
        .patch(`/api/saved_queries/${savedQueryId}`, postData)
        .then((response) => {
          const updatedSavedQuery = response.data as SavedQuery;
          // Replace the old value from allSavedQueries with the new one
          const newAllSavedQueries = patch(
            allSavedQueriesRef.current,
            updatedSavedQuery,
            (sq) => sq.id === updatedSavedQuery.id,
          );
          setAllSavedQueries(newAllSavedQueries);
          return updatedSavedQuery.flowchart_model as SavedFlowchartQueryModel;
        })
        .catch((error) => {
          setSavingFlowchartError('Saving Error');
          throw error;
        })
        .finally(() => {
          setSavingFlowchart(false);
        });
    },
    [],
  );
  /*******************************************************************************
   * FLOWCHART LOGIC END
   ******************************************************************************/

  const handleChangeSql = useCallback(
    (tabId: string, sql: string) => {
      setAutoSavingState('Unsaved');
      debouncedSave(tabId, sql, undefined);
    },
    [debouncedSave],
  );

  const handleRefetchSavedQuery = useCallback((savedQueryId: string) => {
    const api = new API();
    api
      .get(`/api/saved_queries/${savedQueryId}`)
      .then((response) => {
        return response.data as SavedQuery;
      })
      .then((updatedSavedQuery) => {
        // Replace the old value from allSavedQueries with the new one
        const newAllSavedQueries = patch(
          allSavedQueriesRef.current,
          updatedSavedQuery,
          (sq) => sq.id === updatedSavedQuery.id,
        );
        setAllSavedQueries(newAllSavedQueries);
        // I don't think we currently have a need to also update tabs
      });
  }, []);

  // When we close the editName we want to save the name, but we do not want to allow saving empty names, so reset the name to the previous value if it is empty
  const handleCloseEditName = () => {
    // Only bother setting editTabName to false and saving potential changes if editTabMode was actually true before
    if (editTabName) {
      setEditTabName(false);
      const selectedOpenTab = openTabs.find((t) => t.id === selectedTabId);
      const selectedSavedQuery = allSavedQueries.find((sq) => sq.id === selectedTabId);
      if (selectedOpenTab && selectedSavedQuery) {
        if (selectedOpenTab.name === '') {
          // When closing, if the name is empty, revert it to the name stored in allSavedQueries
          const tabIndex = openTabs.findIndex((tab) => tab.id === selectedTabId);
          const updatedTab = { ...openTabs[tabIndex], name: selectedSavedQuery.name };
          const newTabs = [...openTabs.slice(0, tabIndex), updatedTab, ...openTabs.slice(tabIndex + 1)];
          handleSetTabs(newTabs);
          // We do not push changes to the backend since they were invalid, but we are no longer unsaved so update autoSavingState
          setAutoSavingState('Saved');
        } else {
          // If it's not blank, push changes to the backend, which also updates allSavedQueries
          save(selectedTabId, undefined, selectedOpenTab.name);
        }
      }
    }
  };

  const handleSelectTab = (tabId: string, keepAutoRun?: boolean) => {
    // Wrapper for setSelectedTabId so we also set the url whenever the selected tab changes
    setSelectedTabId(tabId);
    // We want to keep autoRun on the URL on initial loads so autoRun does not get set to false before we can set the SQL height
    // But when we click between tabs, it's okay to clean them off
    let params = '';
    if (keepAutoRun) {
      const parsed = qs.parse(history.location.search);
      const autoRunParam = (parsed?.autoRun as string) || '';
      if (autoRunParam === 'true') {
        params = '?autoRun=true';
      }
    }
    const urlPath = `${queryPageBaseUrl}/${tabId}${params}`;
    if (history.location.pathname !== urlPath) {
      history.replace(urlPath);
    }
    // Update user preference for currently selected tab
    updateUserPreferences({ selectedSavedQueryId: tabId });
  };

  const createNewSavedQuery = (
    sql?: string,
    name?: string,
    flowchart_model?: SavedFlowchartQueryModel,
  ) => {
    const postName = name || 'Untitled Query';
    const postData = { name: postName, sql, flowchart_model };
    const api = new API();
    return api.post('/api/saved_queries', postData);
  };

  const handleClickTab = (tabId: string) => {
    if (tabId === selectedTabId) {
      // If the tab is already selected, put it in editMode
      setEditTabName(true);
      analytics.track('Query OpenTabRename');
    } else {
      // Otherwise select it
      handleSelectTab(tabId);
      // If we are selecting a new tab, make sure editMode is false
      handleCloseEditName();
      analytics.track('Query SelectTab');
    }
  };

  const handleCloseEditNameMode = () => {
    handleCloseEditName();
    analytics.track('Query CloseTabRename');
  };

  const handleRemoveTab = (tabId: string, track?: boolean) => {
    // If we are closing a tab, make sure editMode is false
    handleCloseEditName();
    const newTabs = openTabs.filter((t) => t.id !== tabId);
    // If the selectedTab is to be removed, first select a new tab (we will arbitratily choose the first tab for now)
    if (tabId === selectedTabId) {
      const firstTabId = newTabs[0].id;
      handleSelectTab(firstTabId);
    }
    handleSetTabs(newTabs);
    if (track) {
      analytics.track('Query RemoveTab');
    }
  };

  const handleChangeName = (tabId: string, event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoSavingState('Unsaved');
    const tabIndex = openTabs.findIndex((tab) => tab.id === tabId);
    const newName = event.target.value;
    // The name cannot be more than 255 characters
    if (newName.length > 255) {
      return;
    }
    // Update the UI immediately
    const updatedTab = { ...openTabs[tabIndex], name: newName };
    const newTabs = [...openTabs.slice(0, tabIndex), updatedTab, ...openTabs.slice(tabIndex + 1)];
    handleSetTabs(newTabs);
    // Saving for name happens when edit mode closes
  };

  const handleDropTab = (draggedTabId: string, droppedOnTabId: string) => {
    // Do not allow dropping on myself
    if (draggedTabId === droppedOnTabId) {
      return;
    }
    const draggedTab = openTabs.find((tab) => tab.id === draggedTabId);
    const droppedOnTab = openTabs.find((tab) => tab.id === droppedOnTabId);
    if (!draggedTab || !droppedOnTab) {
      return;
    }
    // Rearrange the list in the new drag order.
    const draggedWasBeforeDroppedOn =
      openTabs.findIndex((tab) => tab.id === draggedTabId) <
      openTabs.findIndex((tab) => tab.id === droppedOnTabId);
    const noDropped = openTabs.filter((t) => t.id !== draggedTabId);
    const droppedOnIndex = noDropped.findIndex((tab) => tab.id === droppedOnTabId);
    const before = noDropped.slice(0, droppedOnIndex);
    const after = noDropped.slice(droppedOnIndex + 1);
    const insert = draggedWasBeforeDroppedOn ? [droppedOnTab, draggedTab] : [draggedTab, droppedOnTab];
    const newOrder = [...before, ...insert, ...after];
    handleSetTabs(newOrder);
    analytics.track('Query DropTab');
  };

  const handleClickNew = (isFlowchart: boolean) => {
    setCreatingNewTab(true);
    createNewSavedQuery(undefined, undefined, isFlowchart ? getNewFlowchart() : undefined)
      .then((response) => {
        return response.data as SavedQuery;
      })
      .then((newSavedQuery) => {
        setAllSavedQueries([...allSavedQueries, newSavedQuery]);
        // Add new tab after currently selected tab. Since we want to insert in the middle, we cannot use utils.Array.patchOrAppend
        const selectedTabIndex = openTabs.findIndex((tab) => tab.id === selectedTabId);
        const newOrder = [
          ...openTabs.slice(0, selectedTabIndex + 1),
          ...[newSavedQuery],
          ...openTabs.slice(selectedTabIndex + 1),
        ];
        handleSetTabs(newOrder);
        // New tabs should be selected by default
        handleSelectTab(newSavedQuery.id);
        analytics.track('Query AddNewTab', { numOpenTabs: newOrder.length, isFlowchart });
      })
      .finally(() => {
        setCreatingNewTab(false);
      });
  };

  const handleAddSavedQueryToOpenTabs = (savedQuery: SavedQuery) => {
    // If the tab is already open, select it
    if (openTabs.includes(savedQuery)) {
      handleSelectTab(savedQuery.id);
    } else {
      // Otherwise add and select it
      handleSetTabs([savedQuery, ...openTabs]);
      handleSelectTab(savedQuery.id);
    }
    setListQueriesModalOpen(false);
    analytics.track('Query AddSavedQueryToOpenTabs');
  };

  const handleAddSavedQueryClone = (savedQuery: SavedQuery) => {
    setCloningSavedQueryId(savedQuery.id);
    const cloneName = `${savedQuery.name} - Clone`;
    const newSQL = savedQuery.flowchart_model ? undefined : savedQuery.sql;
    const newFlowchart = savedQuery.flowchart_model ? savedQuery.flowchart_model : undefined;
    createNewSavedQuery(newSQL, cloneName, newFlowchart)
      .then((response) => {
        return response.data as SavedQuery;
      })
      .then((newSavedQuery) => {
        setAllSavedQueries([...allSavedQueries, newSavedQuery]);
        // Add new tab after currently selected tab. Since we want to insert in the middle, we cannot use utils.Array.patchOrAppend
        const selectedTabIndex = openTabs.findIndex((tab) => tab.id === selectedTabId);
        const newOrder = [
          ...openTabs.slice(0, selectedTabIndex + 1),
          ...[newSavedQuery],
          ...openTabs.slice(selectedTabIndex + 1),
        ];
        handleSetTabs(newOrder);
        // New tabs should be selected by default
        handleSelectTab(newSavedQuery.id);
        analytics.track('Query CloneSavedQuery');
        // Right now this function is called only from the view all modal, so close it after executing.
        setListQueriesModalOpen(false);
      })
      .finally(() => {
        setCloningSavedQueryId('');
      });
  };

  const handleDeleteSavedQuery = (savedQuery: SavedQuery) => {
    setDeletingSavedQueryId(savedQuery.id);
    const api = new API();
    return api
      .delete(`/api/saved_queries/${savedQuery.id}`, {})
      .then((response) => {
        // If the tabs we want to delete is the only tab open, open a new tab before deleting it
        if (openTabs.length === 1 && openTabs[0].id === savedQuery.id) {
          createNewSavedQuery()
            .then((response) => {
              return response.data as SavedQuery;
            })
            .then((newSavedQuery) => {
              handleCloseEditName();
              handleSetTabs([newSavedQuery]);
              // New tabs should be selected by default
              handleSelectTab(newSavedQuery.id);
              setAllSavedQueries([
                ...allSavedQueries.filter((sq) => sq.id !== savedQuery.id),
                newSavedQuery,
              ]);
            });
        } else {
          const newAllSavedQueries = allSavedQueries.filter((sq) => sq.id !== savedQuery.id);
          handleRemoveTab(savedQuery.id, false);
          setAllSavedQueries(newAllSavedQueries);
        }
        analytics.track('Query DeleteSavedQuery');
      })
      .finally(() => {
        setDeletingSavedQueryId('');
      });
  };

  /*******************************************************************************
   * MODAL MANAGEMENT START
   ******************************************************************************/
  const handleOpenShareQueryModal = (tabId: string) => {
    setShareQueryModalId(tabId);
    analytics.track('Query OpenShareQueryModal');
  };

  const handleCloseShareQueryModal = () => {
    setShareQueryModalId('');
    analytics.track('Query CloseShareQueryModal');
  };

  const handleOpenListQueriesModal = () => {
    setListQueriesModalOpen(true);
    analytics.track('Query OpenListQueriesModal');
  };

  const handleCloseListQueriesModal = () => {
    setListQueriesModalOpen(false);
    analytics.track('Query CloseListQueriesModal');
  };

  const handleSaveAsTransform = () => {
    const selectedSavedQuery = allSavedQueries.find((sq) => sq.id === selectedTabId);
    const cleanName = convertUnsafeNametoSafeIndentifier(selectedSavedQuery?.name || '');
    prefillTransform(cleanName, 'mozart', 'BASE TABLE', '', selectedSavedQuery?.sql || ''); // ORs make TS happy
  };

  const addMenuOptions = [{ label: 'SQL', onClick: () => handleClickNew(false) }];
  if (flowchartEditorEnabled) {
    addMenuOptions.push({ label: 'No-Code', onClick: () => handleClickNew(true) });
  }

  const menuOptions = [
    { label: 'View all queries', onClick: handleOpenListQueriesModal },
    {
      label: 'Clone this query',
      onClick: () => {
        const selectedSavedQuery = allSavedQueries.find((sq) => sq.id === selectedTabId);
        if (selectedSavedQuery) {
          handleAddSavedQueryClone(selectedSavedQuery);
        }
      },
    },
    { label: 'Share this query', onClick: () => handleOpenShareQueryModal(selectedTabId) },
    {
      label: 'Save as a Transform',
      onClick: handleSaveAsTransform,
      disabled: company_role === 'viewer',
    },
  ];

  return {
    selectedTabId,
    openTabs,
    allSavedQueries,
    editTabName,
    autoSavingState,
    creatingNewTab,
    shareQueryModalId,
    listQueriesModalOpen,
    cloningSavedQueryId,
    deletingSavedQueryId,
    addMenuOptions,
    menuOptions,
    handleClickTab,
    handleRemoveTab,
    handleDropTab,
    handleChangeName,
    handleChangeSql,
    handleCloseEditNameMode,
    handleClickNew,
    handleAddSavedQueryClone,
    handleDeleteSavedQuery,
    handleAddSavedQueryToOpenTabs,
    handleCloseShareQueryModal,
    handleCloseListQueriesModal,
    handleRefetchSavedQuery,
    savingFlowchart,
    savingFlowchartError,
    saveFlowchart,
  };
}
