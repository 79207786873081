/*******************************************************************************
 * BaseSearchableCheckboxPicker is an abstract searchable picker where you can completely
 * reimplement the row component.
 ******************************************************************************/
import React, { useState } from 'react';

import cn from 'classnames';

import Button from 'components/inputs/basic/Button/Button';
import TextInput, { TextInputProps } from 'components/inputs/basic/TextInput/TextInput';

import s from './SearchableCheckboxPicker.module.css';

/**
 * Every implementation of rowComponent must implement BaseSearchableCheckboxPickerRowProps.
 * SearchableObject = The object represented by each row.
 * UniqueRowProps = Props that are different for each row.
 * EveryRowProps = Props that are the same for every row.
 */
export type BaseSearchableCheckboxPickerRowProps<SearchableObject, EveryRowProps> =
  UniqueRowProps<SearchableObject> & EveryRowProps;

export interface UniqueRowProps<SearchableObject> {
  object: SearchableObject;
  checkboxName: string;
}

// Props related to the rendering of the list parts of the component.
export interface BaseSearchableCheckboxPickerListProps<SearchableObject> {
  objects: SearchableObject[];
  containerClass?: string;
  inputWrapperClass?: string;
  listClass?: string; // You probably want to use this to set the maxHeight of the options list
  listObjectsClass?: string; // Might need to set this to overflow-auto
  textInputProps?: Partial<Omit<TextInputProps, 'ref'>>;
  autoFocus?: boolean;
  getKey(o: SearchableObject, idx: number): string; // Returns id or some other unique key
  matchesFilter(o: SearchableObject, filter: string): boolean;
  renderNoObjects(): React.ReactNode; // You probably want to render a <NoObjectsAlert/> or a <FixMeAlert/> depending on how big of a deal this is.
  renderNoMatchesFound(): React.ReactNode; // You probably want to render a <NoMatchesFountAlert/>
  renderHeaderRow?(): React.ReactNode;
  onSelectAll?(): void;
  onDeselectAll?(): void;
}

// Props related to rendering each row of the list.
// Subclasses probably want to take the specify their own implementations of these.
export interface BaseSearchableCheckboxPickerRowComponentProps<SearchableObject, EveryRowProps> {
  rowComponent: React.FC<BaseSearchableCheckboxPickerRowProps<SearchableObject, EveryRowProps>>;
  everyRowComponentProps: EveryRowProps;
}

// Every prop this component needs to render.
export interface BaseSearchableCheckboxPickerProps<SearchableObject, EveryRowProps>
  extends BaseSearchableCheckboxPickerListProps<SearchableObject>,
    BaseSearchableCheckboxPickerRowComponentProps<SearchableObject, EveryRowProps> {}

export const BaseSearchableCheckboxPicker = <SearchableObject extends object, RowProps>(
  props: BaseSearchableCheckboxPickerProps<SearchableObject, RowProps>,
) => {
  const {
    objects,
    containerClass,
    inputWrapperClass,
    listClass,
    listObjectsClass,
    textInputProps,
    autoFocus = true,
    getKey,
    matchesFilter,
    renderNoObjects,
    renderNoMatchesFound,
    renderHeaderRow,
    onSelectAll,
    onDeselectAll,
    rowComponent,
    everyRowComponentProps,
  } = props;

  const [filter, setFilter] = useState<string>(
    textInputProps?.value ? textInputProps?.value.toString() : '',
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFilter(event.target.value);
  };

  let filteredObjects = objects;
  if (filter) {
    filteredObjects = objects.filter((o: SearchableObject) => {
      return matchesFilter(o, filter.toLowerCase());
    });
  }

  const actualInputWrapperClass = cn(inputWrapperClass, {
    'f-row-y-center': onSelectAll || onDeselectAll,
  });

  return (
    <div className={containerClass}>
      <div className={actualInputWrapperClass}>
        <TextInput
          name="object-search"
          placeholder="Search"
          autoFocus={autoFocus}
          autoComplete="off"
          maxLength={50}
          {...textInputProps}
          value={filter}
          onChange={handleFilterChange}
        />
        {onSelectAll && (
          <Button
            onClick={onSelectAll}
            size="small"
            className="ml-2 !px-2"
            variant="lightDullTransparent"
          >
            Select All
          </Button>
        )}
        {onDeselectAll && (
          <Button
            onClick={onDeselectAll}
            size="small"
            className="ml-2 !px-2"
            variant="lightDullTransparent"
          >
            Deselect All
          </Button>
        )}
      </div>
      <div className={cn(s.list, listClass)}>
        {objects.length === 0 ? (
          renderNoObjects()
        ) : (
          <>
            {filteredObjects.length === 0 ? (
              renderNoMatchesFound()
            ) : (
              <div className={listObjectsClass}>
                {renderHeaderRow && renderHeaderRow()}
                {filteredObjects.map((t, idx) => {
                  const key = getKey(t, idx);
                  return React.createElement(rowComponent, {
                    key,
                    object: t,
                    checkboxName: key,
                    ...everyRowComponentProps,
                  });
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BaseSearchableCheckboxPicker;
