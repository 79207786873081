import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function CreateOrReplaceNowInfoIcon(props: { transformTable: string }) {
  const content = (
    <div>
      <div>
        <strong>Run this transform now.</strong>
      </div>
      <div className="mt-1">
        This transform writes the results of the query on the Transform tab to table{' '}
        <code className="ace-code">
          <span className="ace-keyword">{props.transformTable}</span>
        </code>
      </div>
      <div className="mt-1">
        If{' '}
        <code className="ace-code">
          <span className="ace-keyword">{props.transformTable}</span>
        </code>{' '}
        does not exist, this transform will create it.
      </div>
      <div className="mt-1">
        If{' '}
        <code className="ace-code">
          <span className="ace-keyword">{props.transformTable}</span>
        </code>{' '}
        already exists, this transform will replace the previous run's results.
      </div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} popoverProps={popoverProps} placement="top" />;
}
