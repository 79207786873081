import hotkeys from 'hotkeys-js';

// Keys that are pressed in combination with Control or ⌘
// depending on if its Windows or Mac
const META_COMBOS = ['Enter', '.', 'e', 's', 'Escape'];

// By default hotkeys are not enabled for INPUT SELECT TEXTAREA elements.
// hotkeys.filter is a global property so we have to set up a global white
// list of hotkey combos that ignore the default behaviour here.
const COMBOS: { [key: string]: string[] } = {
  Meta: META_COMBOS,
  Control: META_COMBOS,
};

export default function hotKeyConfig() {
  let lastKey = 'NOT_SET';
  const defaultFilter = hotkeys.filter;

  hotkeys.filter = function (event: KeyboardEvent) {
    const target = event.target as any;
    const currentKey = event.key;
    const isCombo = COMBOS[lastKey] && COMBOS[lastKey].includes(currentKey);
    const isButton =
      (target.nodeName === 'BUTTON' || target.nodeName === 'INPUT') && target.type === 'button';
    lastKey = currentKey;
    return isCombo || isButton || defaultFilter(event);
  };
}
