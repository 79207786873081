export const SetupSnowflakeErrors = {
  missing_grant: (missingGrantsString: string) =>
    `The user MOZARTADMIN is missing the following Snowflake grants: ${missingGrantsString}. Please ensure all steps in the instruction are followed.`,
  invalid_identifier: () =>
    'The Snowflake account identifier or url appears to be invalid. Please try again. You can copy the url from the Snowflake account log-in page.',
  authentication: () =>
    'Unable to connect to your Snowflake account with the provided credentials. Your credentials or account url may be incorrect.',
  invalid_role: () =>
    'There was an error with the Mozart Snowflake role. Please ensure all steps in the instruction are followed.',
  unknown: () =>
    'There was an error connecting to your account. Please contact Mozart Data for support.',
};

export const SetupBigQueryErrors = {
  missing_role: (missingRolesString: string) =>
    `The service account is missing the following BigQuery roles: ${missingRolesString}. Please ensure all steps in the instruction are followed.`,
  invalid_project: () =>
    'The BigQuery project identifier appears to be invalid. Please try again. You can copy the project identifier from the BigQuery console.',
  invalid_key: () =>
    'There was an error with the service account key. Please ensure all steps in the instruction are followed.',
};

// This type is the type of setup passed to the API.
export type WarehouseType = 'managed' | 'byos' | 'byobq';

interface ByosSetupErrorGrants {
  error_type: 'missing_grant';
  details: { missing_grants: string[] };
}

interface ByosSetupErrorOther {
  error_type: 'invalid_identifier' | 'authentication' | 'invalid_role' | 'unknown';
  details: never;
}

type ByosSetupError = ByosSetupErrorGrants | ByosSetupErrorOther;

interface ByobqSetupErrorRoles {
  error_type: 'missing_role';
  details: { missing_roles: string[] };
}

interface ByobqSetupErrorOther {
  error_type: 'invalid_project' | 'invalid_key';
  details: never;
}

type ByobqSetupError = ByobqSetupErrorRoles | ByobqSetupErrorOther;

// Parse error codes returned from the server
// The POST and GET to /api/poll_setup_warehouse both return the same payload format.
export const parseError = (warehouseType: WarehouseType, errorString: string) => {
  // Empty string means no error
  if (errorString === '') {
    return errorString;
  }

  // The backend can return errors as JSON objects serialized as strings for example:
  // '{"error_type": "missing_grant", "details": {"missing_grants": ["CREATE USER ON ACCOUNT"]}, "raw_message": "mycause"}'
  // OR arbitrary sentences.
  // Try to parse the object and then run an errorFunction which makes a pretty user facing message.
  try {
    const errorObject = JSON.parse(errorString);
    const englishError = convertStructuredErrorToSentences(warehouseType, errorObject);
    if (englishError) {
      return englishError;
    }
  } catch {
    // Because JSON parsing failed this is an error string that is not a JSON object.
    // Just return errorString.
  }
  return 'There was an error setting up your warehouse: ' + errorString;
};

export function convertStructuredErrorToSentences(warehouseType: WarehouseType, error: any): string {
  if (error.error_type) {
    const byosError = error as ByosSetupError;
    const byobqError = error as ByobqSetupError;
    const errorFunction =
      warehouseType === 'byobq'
        ? SetupBigQueryErrors[byobqError.error_type]
        : SetupSnowflakeErrors[byosError.error_type];
    if (errorFunction) {
      const errorSentence = errorFunction(byosError.details?.missing_grants?.join(', '));
      return errorSentence;
    }
  }
  return '';
}
