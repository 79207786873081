import { useState, useEffect } from 'react';

import { AggTable } from 'api/APITypes';
import csvUploadAPI, { CSVUpload } from 'api/csvUploadAPI';
import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import { usePoll } from 'hooks/useApi';

import CSVUploadHistoryTable from './CSVUploadHistoryTable';

interface CSVUploadHistoryTabProps {
  table: AggTable;
}

export default function CSVUploadHistoryTab(props: CSVUploadHistoryTabProps) {
  /*  For simplicity just start polling the upload history every time someone clicks the tab and stop polling if no pending loads.
      If they have an upload in progress typically they will be watching the spinner on the Replace File modal.
      If they come to this tab again we'll fetch their new upload along with the rest.
  */
  const { table } = props;
  const [csvUploads, setCsvUploads] = useState<CSVUpload[]>([]);
  const [error, setError] = useState<string>('');
  const [keepPolling, setKeepPolling] = useState<boolean>(true);

  const {
    data: pollData,
    error: pollError,
    isError: isPollError,
    isLoading: isPollLoading,
  } = usePoll('csvUploads', () => csvUploadAPI.getUploadsForTable(table.id), [2], keepPolling);

  useEffect(() => {
    if (isPollError) {
      setError('There was an error loading CSV upload history.');
      setKeepPolling(false);
    }
    if (!isPollLoading && pollData) {
      const csvUploads = pollData as CSVUpload[];
      setCsvUploads(csvUploads);
      setError('');
      const hasAnyPending = csvUploads.some(
        (upload) => upload.state === 'pending' || upload.state === 'uploaded',
      );
      setKeepPolling(hasAnyPending);
    }
  }, [pollData, pollError, isPollError, isPollLoading]);

  return (
    <TabLayout loading={isPollLoading} error={error}>
      {/* Assuming that you can't get to this tab without at least loading the initial CSV, so not
      bothering to make a "there's nothing here" message. */}
      {!!csvUploads.length && <CSVUploadHistoryTable csvUploads={csvUploads} />}
    </TabLayout>
  );
}
