import React, { useMemo } from 'react';

import { addMonths, lastDayOfMonth } from 'date-fns';

import { UsageInterval } from 'api/usageAPI';
import BackButton from 'components/inputs/basic/Button/BackButton';
import DateRangePicker from 'components/inputs/basic/DateRangePicker/DateRangePicker';
import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import MonthRangePicker from 'components/inputs/basic/MonthRangePicker/MonthRangePicker';
import { UsageTab } from 'pages/Usage/Usage';
import { parseApiDate, formatApiDate } from 'utils/dateTime';

import s from './UsageFilter.module.css';

export interface UsageFilterProps {
  interval: UsageInterval;
  startDate: string;
  endDate: string;
  connectorName?: string;
  setInterval: (interval: UsageInterval) => void;
  setDateRange: (startDate: string, endDate: string) => void;
  onBack?: () => void;
  tabType: UsageTab;
}

export default function UsageFilter(props: UsageFilterProps) {
  const { interval, startDate, endDate, connectorName, setInterval, setDateRange, onBack, tabType } =
    props;

  const handleChangeInterval = (interval: ListboxValue) => {
    setInterval(interval as UsageInterval);
  };

  // Calculate the list of possible months for the MonthRangePicker
  const [firstDaysOfMonth, lastDaysOfMonth] = useMemo(() => {
    const startOfFirstMonth = parseApiDate('2020-06-01') as Date; // Our oldest transform was created on 2020-06-14.
    const today = parseApiDate(formatApiDate(new Date())) as Date;
    const lastDayOfThisMonth = lastDayOfMonth(today);

    // Make a list of the first days of the month from the start of Mozart to today.
    const firstDaysOfMonth: string[] = [];
    let currentMonth = startOfFirstMonth;
    while (currentMonth < lastDayOfThisMonth) {
      firstDaysOfMonth.push(formatApiDate(currentMonth));
      currentMonth = addMonths(currentMonth, 1);
    }

    // Sort in reverse chronological order
    firstDaysOfMonth.reverse();

    // Convert the first days of the month into a corresponding last days of the same month.
    const lastDaysOfMonth = firstDaysOfMonth.map((firstDayString) => {
      const firstDay = parseApiDate(firstDayString) as Date;
      const lastDay = lastDayOfMonth(firstDay);
      return formatApiDate(lastDay);
    });

    return [firstDaysOfMonth, lastDaysOfMonth];
  }, []);

  return (
    <div className="f-between py-4">
      <div className="f-center">
        {onBack && (
          <>
            <BackButton text="ALL CONNECTORS" onClick={onBack} />
            <div className="w-[1px] h-[24px] ml-2 mr-4 bg-pri-gray-200" />
          </>
        )}
        {tabType === 'connector' && (
          <div className="text-xl tracking-wide text-pri-gray-700">
            {connectorName ? connectorName : 'All Connectors'}
          </div>
        )}
      </div>
      <div className={s.filterBox}>
        <Listbox
          value={interval}
          onChange={handleChangeInterval}
          variant="gray"
          size="medium"
          widthClass="w-[125px]"
          containerClass="mr-4"
          autoFocus={true}
          options={[
            { label: 'Monthly', value: 'monthly' },
            { label: 'Daily', value: 'daily' },
          ]}
        />
        {interval === 'monthly' ? (
          <MonthRangePicker
            startDate={startDate}
            endDate={endDate}
            startMonths={firstDaysOfMonth}
            endMonths={lastDaysOfMonth}
            setDateRange={setDateRange}
          />
        ) : (
          <DateRangePicker startDate={startDate} endDate={endDate} setDateRange={setDateRange} />
        )}
      </div>
    </div>
  );
}
