import { useContext } from 'react';

import { useHistory } from 'react-router';

import API from 'api/API';
import { DbtRepository, DbtRunConfig, DbtRunConfigUpsertParams } from 'api/dbtAPI';
import BackButton from 'components/inputs/basic/Button/BackButton';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import { TableModelsContext } from 'model_layer/TableModelsContext';

import RunConfigForm from '../shared/RunConfigForm/RunConfigForm';

interface AddRunConfigFormLayoutProps {
  repos: DbtRepository[];
  setShowConnectNewGitRepo: (show: boolean) => void;
}

const AddRunConfigFormLayout = (props: AddRunConfigFormLayoutProps) => {
  const { repos, setShowConnectNewGitRepo } = props;
  const history = useHistory();
  const { dbtRunConfigs, addDbtRunConfig } = useContext(TableModelsContext);

  const handleOpenAddNewRepo = () => {
    setShowConnectNewGitRepo(true);
    analytics.track('AddDBTRunConfig OpenAddGitRepo');
  };

  // RunConfigFormProps catches this function's exceptions.
  const doSave = async (newValues: DbtRunConfigUpsertParams) => {
    const api = new API();
    const response = await api.post('api/dbt_run_configurations', newValues);
    const newDbtConfig = response.data as DbtRunConfig;
    addDbtRunConfig(newDbtConfig);
    analytics.track('AddDBTRunConfig Create', newValues);
    history.push('/dbt');
  };

  const handleBackClick = () => {
    if (dbtRunConfigs.length > 0) {
      analytics.track('AddDBTRunConfig BackToListDBTRunConfigs');
      history.push('/dbt');
    } else {
      analytics.track('AddDBTRunConfig BackToDBTIntegration');
      history.push('/integrations/dbt');
    }
  };

  const handleCancel = () => {
    if (dbtRunConfigs.length > 0) {
      analytics.track('AddDBTRunConfig CancelToDBTRunConfigs');
      history.push('/dbt');
    } else {
      analytics.track('AddDBTRunConfig CancelToDBTIntegration');
      history.push('/integrations/dbt');
    }
  };

  // Initial form values
  const DEFAULT_COMMANDS = ['dbt deps', 'dbt run'];
  const DEFAULT_SCHEDULE = '0 9 * * *';
  const initialValues: DbtRunConfigUpsertParams = {
    name: '',
    dbt_repository: props.repos?.length ? props.repos[0].id : '',
    target_schema: '',
    target_database: '',
    commands: DEFAULT_COMMANDS,
    dbt_version: '1.7',
    scheduled: true,
    schedule_mode: 'all_marked',
    schedule: DEFAULT_SCHEDULE,
    connector_dependencies: [],
    custom_git_branch: '',
  };

  const UpsertRunConfigurationHeader = (
    <div className="f-between">
      <h1 className="display-sm text-pri-gray-700">Create new job</h1>
      <BackButton
        text={dbtRunConfigs.length > 0 ? 'Back to dbt overview' : 'Back to dbt integration'}
        onClick={handleBackClick}
      />
    </div>
  );

  return (
    <CenteredLayout maxWidth="814px" header={UpsertRunConfigurationHeader}>
      <RunConfigForm
        initialValues={initialValues}
        doSave={doSave}
        onCancel={handleCancel}
        repos={repos}
        onOpenNewRepoForm={handleOpenAddNewRepo}
      />
    </CenteredLayout>
  );
};

export default AddRunConfigFormLayout;
