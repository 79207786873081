/*
 * Type definitions, API methods, and conversion utilities for Usage.
 */
import API from 'api/API';

/*******************************************************************************
 * Types
 ******************************************************************************/
export type UsageInterval = 'monthly' | 'daily';

export interface MarByDate {
  mar: number;
  measured_date: string;
}

export interface ConnectorUsage extends MarByDate {
  connector_id: string;
  connector_name: string;
}

export interface TableUsage extends MarByDate {
  schema_name: string;
  table_name: string;
}

export interface ComputeUsage {
  compute_credits: number;
  database_name: string;
  measured_date: string;
}

export interface StorageUsage {
  storage: number;
  database_name: string;
  measured_date: string;
}

/*******************************************************************************
 * APIs
 ******************************************************************************/
const usageAPI = {
  getConnectorUsage: (interval: UsageInterval, startDate: string, endDate: string) => {
    const api = new API();
    return api.post(`/api/usages/connector_usage`, {
      usage: interval,
      start_date: startDate,
      end_date: endDate,
    });
  },

  getConnectorTablesUsage: (connectorID: string, startDate: string, endDate: string) => {
    const api = new API();
    return api.post(`/api/usages/${connectorID}/connector_table_usage`, {
      start_date: startDate,
      end_date: endDate,
    });
  },

  getComputeUsage: (interval: UsageInterval, startDate: string, endDate: string) => {
    const api = new API();
    return api.post(`/api/usages/compute_usage`, {
      usage: interval,
      start_date: startDate,
      end_date: endDate,
    });
  },

  getStorageUsage: (interval: UsageInterval, startDate: string, endDate: string) => {
    const api = new API();
    return api.post(`/api/usages/storage_usage`, {
      usage: interval,
      start_date: startDate,
      end_date: endDate,
    });
  },
};
export default usageAPI;

/*******************************************************************************
 * Conversion Utilites
 ******************************************************************************/
/* None. */
