import React, { useContext } from 'react';

import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { TransformTable } from 'pages/tables/ShowTable/ShowTable';
import PagedUserActionsTable from 'pages/user_actions/components/UserActionsTable/PagedUserActionsTable';
import usePagedUserActions from 'pages/user_actions/usePagedUserActions';

interface UserActionsTabProps {
  table: TransformTable;
}

export default function UserActionsTab(props: UserActionsTabProps) {
  const tableModelContext = useContext(TableModelsContext);
  const { isLoading, error, ...pagedProps } = usePagedUserActions('TableUserActionsTab', {
    table: props.table.id,
  });

  return (
    <TabLayout loading={isLoading && tableModelContext.allLoaded} variant="wide_padding" error={error}>
      {!error && <PagedUserActionsTable {...pagedProps} title={'User Actions'} variant="blueGray" />}
    </TabLayout>
  );
}
