/*
This file is the page router when a user's account
is not disabled or in an incomplete setup state.
*/
// sort-imports-ignore
import React, { useState } from 'react';

import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';

import { UserProfile } from 'api/APITypes';
import { useDatabaseAccount } from 'context/AuthContext';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import AddConnectorForm from 'pages/connectors/AddConnectorForm/AddConnectorForm';
import AddConnectorTypes from 'pages/connectors/AddConnectorTypes/AddConnectorTypes';
import ListConnectors from 'pages/connectors/ListConnectors/ListConnectors';
import PickReportsRouter from 'pages/connectors/ReportBuilder/PickReportsRouter';
import ShowConnectorController from 'pages/connectors/ShowConnector/ShowConnectorController';
import AddCSV from 'pages/csv/AddCSV';
import ListDataAlertsController from 'pages/data_alerts/ListDataAlertsController';
import AddDBTRunConfig from 'pages/dbt/AddDBTRunConfig/AddDBTRunConfig';
import ListDBTRunConfigs from 'pages/dbt/ListDBTRunConfigs/ListDBTRunConfigs';
import ShowDBTRunConfig from 'pages/dbt/ShowDBTRunConfig/ShowDBTRunConfig';
import GettingStarted from 'pages/GettingStarted';
import HomeController from 'pages/Home/HomeController';
import IntegrationSnowflakeConfig from 'pages/Integrations/IntegrationSnowflakeConfig/IntegrationSnowflakeConfig';
import ListIntegrations from 'pages/Integrations/ListIntegrations';
import InternalTools from 'pages/internal/InternalTools';
import ListExperiments from 'pages/ListExperiments';
import ListNotificationsController from 'pages/notifications/ListNotificationsController';
import Page404 from 'pages/Page404';
import Partnerships from 'pages/Partnerships/Partnerships';
import QueryPage from 'pages/Query/Query';
import ResetDemoPage from 'pages/ResetDemo';
import Settings from 'pages/settings/Settings';
import SlackAppInstallerRedirect from 'pages/SlackAppInstallerRedirect';
import ShowTable from 'pages/tables/ShowTable/ShowTable';
import ListTags from 'pages/tags/ListTags/ListTags';
import AddTransform from 'pages/transforms/AddTransform';
import RedirectTransform from 'pages/transforms/RedirectTransform';
import TrialExpired from 'pages/TrialExpired';
import Usage from 'pages/Usage/Usage';
import { Usage as UsageV2 } from 'pages/UsageV2/Usage';
import ListUserActionsController from 'pages/user_actions/ListUserActions/ListUserActionsController';
import AddUser from 'pages/users/AddUser';
import ListUsers from 'pages/users/ListUsers/ListUsers';
import Warehouse from 'pages/Warehouse/Warehouse';
import APIAuthenticatedProviders from 'providers/APIAuthenticatedProviders';

import unauthenticatedRoutes from '../unauthenticatedRoutes';

import MainAppLayout from './MainAppLayout';
import { useAppHotkeys } from './useAppHotkeys';

interface MainAppProps {
  userProfile: UserProfile;
}

const MainApp = (props: MainAppProps) => {
  const { userProfile } = props;
  const userIsStaff = props.userProfile.user.is_staff;
  const [currentPathObject, setCurrentPathObject] = useState<any>(null);
  const { showHotkeys, setShowHotkeys } = useAppHotkeys();
  const databaseType = useDatabaseAccount().type;

  const showTable = (routeProps: any) => {
    return <ShowTable {...routeProps} setCurrentPathObject={setCurrentPathObject} />;
  };

  const showConnector = (routeProps: any) => {
    return <ShowConnectorController {...routeProps} setCurrentPathObject={setCurrentPathObject} />;
  };

  const hasPartnerfleet = useBooleanFlag('partnerfleet');

  const rootUrl = databaseType === 'bigquery' ? '/warehouse' : '/home';

  /*
  ATTENTION:
  When you add a <Route/> to this file you also need to:
  1. Add it's path to authenticatedRoutes.ts
  2. If applicable, add it to HIDE_TRIAL_BANNER_ROUTES at the bottom of this file.
  3. If applicable, add it to SHOW_FIXED_SIDEBAR_ROUTES at the bottom of this file.
     This might apply if your component does not use <CenteredLayout/> or some other layout component,
     that automatically adds the CompanySetupGuide.
  */
  return (
    <APIAuthenticatedProviders>
      <MainAppLayout
        currentPathObject={currentPathObject}
        showHotkeys={showHotkeys}
        setShowHotkeys={setShowHotkeys}
      >
        <Switch>
          <CompatRoute path="/" exact>
            <Redirect to={{ pathname: rootUrl }} />
          </CompatRoute>
          <CompatRoute path="/connectors" exact component={ListConnectors} />
          <CompatRoute path="/connectors/:id/report_builder/:view" exact component={PickReportsRouter} />
          <CompatRoute path="/connectors/add" exact component={AddConnectorTypes} />
          <CompatRoute path="/connectors/add/:service" exact component={AddConnectorForm} />
          <CompatRoute path="/connectors/:id" exact render={showConnector} />
          <CompatRoute path="/connectors/:id/:tab" exact render={showConnector} />
          <CompatRoute path="/csv/add" exact component={AddCSV} />
          <CompatRoute path="/experiments" exact component={ListExperiments} />
          <CompatRoute path="/home" exact component={HomeController} />
          <CompatRoute path="/integrations" exact component={ListIntegrations} />
          <CompatRoute path="/integrations/:platform" exact component={IntegrationSnowflakeConfig} />
          {hasPartnerfleet && <CompatRoute path="/partnerships" exact component={Partnerships} />}
          <CompatRoute path="/getting-started" exact component={GettingStarted} />
          <CompatRoute path="/getting-started/:useCase" component={GettingStarted} />
          <CompatRoute path="/warehouse" exact component={Warehouse} />
          <CompatRoute path="/tables/:id" exact render={showTable} />
          <CompatRoute path="/tables/:id/:tab" exact render={showTable} />
          <CompatRoute path="/transforms/add" exact component={AddTransform} />
          <CompatRoute path="/transforms/:id" exact component={RedirectTransform} />
          <CompatRoute path="/transforms/:id/:tab" exact component={RedirectTransform} />
          <CompatRoute path="/trial-expired" exact component={TrialExpired} />
          <Route path="/temp/reset_demo" exact component={ResetDemoPage} />
          <CompatRoute path="/query/:id" exact component={QueryPage} />
          <CompatRoute path="/query" exact component={QueryPage} />
          <CompatRoute path="/users/add" exact component={AddUser} />
          <CompatRoute path="/users" exact component={ListUsers} />
          <CompatRoute path="/users/:tab" exact component={ListUsers} />
          <CompatRoute path="/settings" exact component={Settings} />
          <CompatRoute
            path="/slack-app-installer-redirect"
            exact
            component={SlackAppInstallerRedirect}
          />
          <CompatRoute path="/tags" exact component={ListTags} />
          {databaseType !== 'bigquery' && (
            <CompatRoute
              path="/usage"
              exact
              component={userProfile?.feature_flags['usage-v2'] ? UsageV2 : Usage}
            />
          )}
          {databaseType !== 'bigquery' && (
            <CompatRoute
              path="/usage/:tab"
              exact
              component={userProfile?.feature_flags['usage-v2'] ? UsageV2 : Usage}
            />
          )}
          <CompatRoute path="/notifications" exact component={ListNotificationsController} />
          {databaseType !== 'bigquery' && (
            <CompatRoute path="/alerts" exact component={ListDataAlertsController} />
          )}
          <CompatRoute path="/user-actions" exact component={ListUserActionsController} />
          {userIsStaff && <Route path="/internal" exact component={InternalTools} />}
          {userIsStaff && <Route path="/internal/:tab" exact component={InternalTools} />}
          <CompatRoute path="/dbt/jobs/add" exact component={AddDBTRunConfig} />
          <CompatRoute path="/dbt" exact component={ListDBTRunConfigs} />
          <CompatRoute path="/dbt/jobs/:id" exact component={ShowDBTRunConfig} />
          <CompatRoute path="/dbt/jobs/:id/:tab" exact component={ShowDBTRunConfig} />
          <CompatRoute path="/dbt/jobs/:id/:tab/:runID" exact component={ShowDBTRunConfig} />
          {unauthenticatedRoutes.map((ur) => (
            <CompatRoute key={ur} path={ur} component={() => <Redirect to={{ pathname: '/' }} />} />
          ))}
          <Route>
            <Page404 isAuthenticated={true} />
          </Route>
        </Switch>
      </MainAppLayout>
    </APIAuthenticatedProviders>
  );
};

export default MainApp;

/*******************************************************************************
 * OPTIONAL CONTENT LISTS:
 * The lists below control if optional layout content is added or removed from given routes.
 ******************************************************************************/
// Do not add anything from HIDE_TRIAL_BANNER_ROUTES to the other lists.
// Block banners, popover tours, and sidebar guides on these routes because they are special cases.
export const HIDE_TRIAL_BANNER_ROUTES = [
  '/select-snowflake',
  '/getting-started',
  '/getting-started/invited-user',
  '/getting-started/new-account',
  '/trial-expired',
];

// Pages come in two layouts:
// 1. default
//    A. Acts like a normal webpage.
//    B. The default.
//    C. The most common.
//    D. Minimum height of 100vh.
//    E. Overflow height scrolls indefinitely in browser viewport.
// 2. workspace
//    A. Designed to simulate the workspace of an IDE, spreadsheet, or other productivity desktop app.
//    B. Very wide.
//    C. Very information dense.
//    D. Have exact height of 100vh.
//    E. These pages must handle their own overflow scrolling within their allotted 100vh.
export const WORKSPACE_ROUTES = [
  '/warehouse',
  '/tables/:id',
  '/tables/:id/:tab',
  '/dbt/jobs/:id',
  '/dbt/jobs/:id/:tab',
  '/query',
  '/query/:id',
];

// These are routes that match WORKSPACE_ROUTES but should not be included.
export const WORKSPACE_BLACKLIST = ['/connectors/add', '/connectors/add/:service', '/dbt/jobs/add'];

// On some pages we hide the footer because there is just no room for it.
export const NO_FOOTER_ROUTES = [...WORKSPACE_ROUTES];

// The content of some routes is so wide that we render sidebars on top of the page content.
export const SHOW_FIXED_SIDEBAR_ROUTES = [
  ...WORKSPACE_ROUTES,
  '/connectors/:id',
  '/connectors/:id/:tab',
  '/alerts',
  '/users',
];

export function useIsWorkspaceRoute(): boolean {
  const isWorkspace = useRouteMatch(WORKSPACE_ROUTES) !== null;
  const isBlacklisted = useRouteMatch(WORKSPACE_BLACKLIST) !== null;
  return isWorkspace && !isBlacklisted;
}

export function useHasFixedSidebarRoute(): boolean {
  const hasFixedSidebar = useRouteMatch(SHOW_FIXED_SIDEBAR_ROUTES) !== null;
  const isBlacklisted = useRouteMatch(WORKSPACE_BLACKLIST) !== null;
  return hasFixedSidebar && !isBlacklisted;
}

export function useHasFooter(): boolean {
  const hasFooter = useRouteMatch(NO_FOOTER_ROUTES) === null;
  const isBlacklisted = useRouteMatch(WORKSPACE_BLACKLIST) !== null;
  return hasFooter || isBlacklisted;
}
