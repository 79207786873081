/**
 * DO NOT COPY THIS FILE'S TECHNIQUES.
 * This was an experiment to see if react-query's mutation interface
 * was a good replacement for other ways we cache query results
 * and code model layers. The code feels cumbersome in practice.
 *
 * This file is a reusable set of API mutations for several pages
 * in the ReportBuilder project.
 */
import { useQueryClient } from 'react-query';

import { Connector } from 'api/APITypes';
import { Dashboard, NewDashboard, UpdatableDashboardProps, ReportInstalled } from 'api/dashboardAPI';
import { useDeleteMutation, usePatchMutation, usePostMutation } from 'hooks/useApi';

import dashboardCache from './dashboardCache';

export default function useDashboardMutations(connector: Connector, dashboard?: Dashboard) {
  const queryClient = useQueryClient();
  const createMutation = usePostMutation<Dashboard, unknown, NewDashboard>(`/api/dashboards`);
  const updateMutation = usePatchMutation<Dashboard, unknown, UpdatableDashboardProps>(
    `/api/dashboards/${dashboard?.id}`,
  );
  const deleteMutation = useDeleteMutation(`/api/dashboards/${dashboard?.id}`);

  const { isLoading: createLoading, isError: createIsError } = createMutation;
  const createError: string = createIsError ? 'Failed to create dashboard.' : '';
  const { isLoading: updateLoading, isError: updateIsError } = updateMutation;
  const updateError: string = updateIsError ? 'Failed to update dashboard.' : '';
  const { isLoading: deleteLoading, isError: deleteIsError } = deleteMutation;
  const deleteError: string = deleteIsError ? 'Failed to delete dashboard.' : '';

  const createDashboard = (reportsPicked: ReportInstalled[]) => {
    return createMutation
      .mutateAsync({ connector: connector.id, reports: reportsPicked })
      .then((dashboard: Dashboard) => {
        dashboardCache.create(queryClient, dashboard);
        return dashboard;
      })
      .catch((e) => Promise.reject(e)); // Catch error so it doesn't bubble up. Error is set by mutation props.
  };

  const updateDashboard = (reportsPicked: ReportInstalled[]) => {
    return updateMutation
      .mutateAsync({ reports: reportsPicked })
      .then((dashboard: Dashboard) => {
        dashboardCache.update(queryClient, dashboard);
        return dashboard;
      })
      .catch((e) => Promise.reject(e)); // Catch error so it doesn't bubble up. Error is set by mutation props.
  };

  const deleteDashboard = () => {
    return deleteMutation
      .mutateAsync()
      .then(() => {
        dashboardCache.delete(queryClient, dashboard as Dashboard);
      })
      .catch((e) => Promise.reject(e)); // Catch error so it doesn't bubble up. Error is set by mutation props.
  };

  return {
    createLoading,
    updateLoading,
    deleteLoading,
    createError,
    updateError,
    deleteError,
    createDashboard,
    updateDashboard,
    deleteDashboard,
  };
}
