const snowflakeFunctions = [
  'abs',
  'acos',
  'acosh',
  'add_months',
  'all_user_names',
  'any_value',
  'approx_count_distinct',
  'approx_percentile',
  'approx_percentile_accumulate',
  'approx_percentile_combine',
  'approx_percentile_estimate',
  'approx_top_k',
  'approx_top_k_accumulate',
  'approx_top_k_combine',
  'approx_top_k_estimate',
  'approximate_jaccard_index',
  'approximate_similarity',
  'array_agg',
  'array_append',
  'array_cat',
  'array_compact',
  'array_construct',
  'array_construct_compact',
  'array_contains',
  'array_insert',
  'array_intersection',
  'array_position',
  'array_prepend',
  'array_size',
  'array_slice',
  'array_to_string',
  'arrays_overlap',
  'as_array',
  'as_binary',
  'as_boolean',
  'as_char',
  'as_varchar',
  'as_date',
  'as_decimal',
  'as_number',
  'as_double',
  'as_real',
  'as_integer',
  'as_object',
  'as_time',
  'as_timestamp',
  'as_timestamp_ltz',
  'as_timestamp_ntz',
  'as_timestamp_tz',
  'ascii',
  'asin',
  'asinh',
  'atan',
  'atan2',
  'atanh',
  'automatic_clustering_history',
  'avg',
  'base64_decode_binary',
  'base64_decode_string',
  'base64_encode',
  'between',
  'bit_length',
  'bitand',
  'bitand_agg',
  'bitnot',
  'bitor',
  'bitor_agg',
  'bitshiftleft',
  'bitshiftright',
  'bitxor',
  'bitxor_agg',
  'booland',
  'booland_agg',
  'boolnot',
  'boolor',
  'boolor_agg',
  'boolxor',
  'boolxor_agg',
  'case',
  'cast',
  'cbrt',
  'ceil',
  'charindex',
  'check_json',
  'check_xml',
  'chr',
  'char',
  'coalesce',
  'collate',
  'collation',
  'compress',
  'concat',
  'concat_ws',
  'conditional_change_event',
  'conditional_true_event',
  'contains',
  'convert_timezone',
  'copy_history',
  'corr',
  'cos',
  'cosh',
  'cot',
  'count',
  'count_if',
  'covar_pop',
  'covar_samp',
  'cume_dist',
  'current_account',
  'current_available_roles',
  'current_client',
  'current_database',
  'current_date',
  'current_region',
  'current_role',
  'current_schema',
  'current_schemas',
  'current_session',
  'current_statement',
  'current_time',
  'current_timestamp',
  'current_transaction',
  'current_user',
  'current_version',
  'current_warehouse',
  'data_transfer_history',
  'database_refresh_history',
  'database_refresh_progress',
  'database_refresh_progress_by_job',
  'database_storage_usage_history',
  'date_from_parts',
  'date_part',
  'date_trunc',
  'dateadd',
  'datediff',
  'dayname',
  'decode',
  'decompress_binary',
  'decompress_string',
  'decrypt',
  'decrypt_raw',
  'degrees',
  'dense_rank',
  'div0',
  'editdistance',
  'encrypt',
  'encrypt_raw',
  'endswith',
  'equal_null',
  'exp',
  'explain_json',
  'external_functions_history',
  'external_table_files',
  'external_table_file_registration_history',
  'extract',
  'factorial',
  'first_value',
  'flatten',
  'floor',
  'generator',
  'get',
  'get_ddl',
  'get_ignore_case',
  'get_object_references',
  'get_path',
  'getbit',
  'greatest',
  'grouping',
  'grouping_id',
  'hash',
  'hash_agg',
  'haversine',
  'hex_decode_binary',
  'hex_decode_string',
  'hex_encode',
  'hll',
  'hll_accumulate',
  'hll_combine',
  'hll_estimate',
  'hll_export',
  'hll_import',
  'hour',
  'iff',
  'ifnull',
  'ilike',
  'initcap',
  'insert',
  'invoker_role',
  'invoker_share',
  'is_array',
  'is_binary',
  'is_boolean',
  'is_char',
  'is_varchar',
  'is_date',
  'is_date_value',
  'is_decimal',
  'is_double',
  'is_real',
  'is_granted_to_invoker_role',
  'is_integer',
  'is_null_value',
  'is_object',
  'is_role_in_session',
  'is_time',
  'is_timestamp',
  'is_timestamp_ltz',
  'is_timestamp_ntz',
  'is_timestamp_tz',
  'json_extract_path_text',
  'kurtosis',
  'lag',
  'last_day',
  'last_query_id',
  'last_transaction',
  'last_value',
  'lead',
  'least',
  'left',
  'length',
  'len',
  'listagg',
  'ln',
  'localtime',
  'localtimestamp',
  'log',
  'login_history',
  'login_history_by_user',
  'lower',
  'lpad',
  'ltrim',
  'materialized_view_refresh_history',
  'md5',
  'md5_hex',
  'md5_binary',
  'md5_number',
  'median',
  'min',
  'max',
  'minhash',
  'minhash_combine',
  'minute',
  'mod',
  'mode',
  'monthname',
  'next_day',
  'normal',
  'nth_value',
  'ntile',
  'nullif',
  'nullifzero',
  'nvl',
  'nvl2',
  'object_agg',
  'object_construct',
  'object_delete',
  'object_insert',
  'object_keys',
  'object_pick',
  'octet_length',
  'parse_ip',
  'parse_json',
  'parse_url',
  'parse_xml',
  'percent_rank',
  'percentile_cont',
  'percentile_disc',
  'pi',
  'pipe_usage_history',
  'policy_references',
  'position',
  'pow, power',
  'previous_day',
  'query_history',
  'query_history_by_session',
  'query_history_by_user',
  'query_history_by_warehouse',
  'radians',
  'random',
  'randstr',
  'rank',
  'ratio_to_report',
  'regexp',
  'regexp_count',
  'regexp_instr',
  'regexp_like',
  'regexp_replace',
  'regexp_substr',
  'regr_avgx',
  'regr_avgy',
  'regr_count',
  'regr_intercept',
  'regr_r2',
  'regr_slope',
  'regr_sxx',
  'regr_sxy',
  'regr_syy',
  'regr_valx',
  'regr_valy',
  'repeat',
  'replace',
  'replication_usage_history',
  'rest_event_history',
  'result_scan',
  'reverse',
  'right',
  'rlike',
  'round',
  'row_number',
  'rpad',
  'rtrim',
  'rtrimmed_length',
  'search_optimization_history',
  'second',
  'seq1',
  'seq2',
  'seq4',
  'seq8',
  'sha1',
  'sha1_hex',
  'sha1_binary',
  'sha2',
  'sha2_hex',
  'sha2_binary',
  'sign',
  'sin',
  'sinh',
  'skew',
  'soundex',
  'space',
  'split',
  'split_part',
  'split_to_table',
  'sqrt',
  'square',
  'st_area',
  'st_asewkb',
  'st_asewkt',
  'st_asgeojson',
  'st_aswkb',
  'st_asbinary',
  'st_aswkt',
  'st_astext',
  'st_azimuth',
  'st_centroid',
  'st_collect',
  'st_contains',
  'st_coveredby',
  'st_covers',
  'st_dimension',
  'st_disjoint',
  'st_distance',
  'st_dwithin',
  'st_envelope',
  'st_geogfromgeohash',
  'st_geographyfromwkb',
  'st_geographyfromwkt',
  'st_geohash',
  'st_hausdorffdistance',
  'st_intersects',
  'st_length',
  'st_makeline',
  'st_makepoint',
  'st_point',
  'st_makepolygon',
  'st_polygon',
  'st_npoints',
  'st_numpoints',
  'st_perimeter',
  'st_simplify',
  'st_srid',
  'st_within',
  'st_x',
  'st_xmax',
  'st_xmin',
  'st_y',
  'st_ymax',
  'st_ymin',
  'stage_storage_usage_history',
  'startswith',
  'stddev',
  'stddev_pop',
  'stddev_samp',
  'strip_null_value',
  'strtok',
  'strtok_split_to_table',
  'strtok_to_array',
  'substr',
  'substring',
  'sum',
  'sysdate',
  'system$abort_session',
  'system$abort_transaction',
  'system$behavior_change_bundle_status',
  'system$cancel_all_queries',
  'system$cancel_query',
  'system$clustering_depth',
  'system$clustering_information',
  'system$clustering_ratio — deprecated',
  'system$current_user_task_name',
  'system$database_refresh_history',
  'system$database_refresh_progress',
  'system$database_refresh_progress_by_job',
  'system$disable_behavior_change_bundle',
  'system$enable_behavior_change_bundle',
  'system$estimate_search_optimization_costs',
  'system$explain_json_to_text',
  'system$explain_plan_json',
  'system$generate_saml_csr',
  'system$generate_scim_access_token',
  'system$get_aws_sns_iam_policy',
  'system$get_predecessor_return_value',
  'system$get_privatelink_config',
  'system$get_snowflake_platform_info',
  'system$last_change_commit_time',
  'system$migrate_saml_idp_registration',
  'system$pipe_force_resume',
  'system$pipe_status',
  'system$set_return_value',
  'system$show_oauth_client_secrets',
  'system$stream_get_table_timestamp',
  'system$stream_has_data',
  'system$task_dependents_enable',
  'system$typeof',
  'system$user_task_cancel_ongoing_executions',
  'system$verify_external_oauth_token',
  'system$wait',
  'system$whitelist',
  'system$whitelist_privatelink',
  'tan',
  'tanh',
  'task_dependents',
  'task_history',
  'time_from_parts',
  'time_slice',
  'timeadd',
  'timediff',
  'timestamp_from_parts',
  'timestampadd',
  'timestampdiff',
  'to_array',
  'to_binary',
  'to_boolean',
  'to_char',
  'to_varchar',
  'to_date',
  'date',
  'to_decimal',
  'to_number',
  'to_numeric',
  'to_double',
  'to_geography',
  'to_json',
  'to_object',
  'to_time',
  'time',
  'to_timestamp',
  'to_timestamp_ltz',
  'to_timestamp_ntz',
  'to_timestamp_tz',
  'to_variant',
  'to_xml',
  'translate',
  'trim',
  'truncate',
  'trunc',
  'try_base64_decode_binary',
  'try_base64_decode_string',
  'try_cast',
  'try_hex_decode_binary',
  'try_hex_decode_string',
  'try_parse_json',
  'try_to_binary',
  'try_to_boolean',
  'try_to_date',
  'try_to_decimal',
  'try_to_number',
  'try_to_numeric',
  'try_to_double',
  'try_to_geography',
  'try_to_time',
  'try_to_timestamp',
  'try_to_timestamp_ltz',
  'try_to_timestamp_ntz',
  'try_to_timestamp_tz',
  'typeof',
  'unicode',
  'uniform',
  'upper',
  'uuid_string',
  'validate',
  'validate_pipe_load',
  'var_pop',
  'var_samp',
  'variance',
  'variance_samp',
  'variance_pop',
  'warehouse_load_history',
  'warehouse_metering_history',
  'width_bucket',
  'xmlget',
  'year',
  'day',
  'week',
  'month',
  'quarter',
  'zeroifnull',
  'zipf',
];

// All Snowflake Functions except:
//   1. Words that are annoying in autocomplete, such as 'seq1', are removed.
//   2. Words that are never used in practice are removed.
//      Query for figuring this out:
//      https://app.mozartdata.com/query?query=SELECT%20*%20FROM%20mozart.snowflake_words_used%0AWHERE%20is_reserved%3Dfalse%20AND%20word_score%20%3C%205%0Aorder%20by%20word

const autocompleteSnowflakeFunctions = [
  'abs',
  // 'acos',
  // 'acosh',
  'add_months',
  // 'all_user_names',
  // 'any_value',
  // 'approx_count_distinct',
  // 'approx_percentile',
  // 'approx_percentile_accumulate',
  // 'approx_percentile_combine',
  // 'approx_percentile_estimate',
  // 'approx_top_k',
  // 'approx_top_k_accumulate',
  // 'approx_top_k_combine',
  // 'approx_top_k_estimate',
  // 'approximate_jaccard_index',
  // 'approximate_similarity',
  'array_agg',
  // 'array_append',
  // 'array_cat',
  // 'array_compact',
  'array_construct',
  // 'array_construct_compact',
  // 'array_contains',
  // 'array_insert',
  // 'array_intersection',
  // 'array_position',
  // 'array_prepend',
  'array_size',
  // 'array_slice',
  'array_to_string',
  // 'arrays_overlap',
  // 'as_array',
  // 'as_binary',
  // 'as_boolean',
  // 'as_char',
  // 'as_varchar',
  // 'as_date',
  // 'as_decimal',
  // 'as_number',
  // 'as_double',
  // 'as_real',
  // 'as_integer',
  // 'as_object',
  // 'as_time',
  // 'as_timestamp',
  // 'as_timestamp_ltz',
  // 'as_timestamp_ntz',
  // 'as_timestamp_tz',
  // 'ascii',
  // 'asin',
  // 'asinh',
  // 'atan',
  // 'atan2',
  // 'atanh',
  // 'automatic_clustering_history',
  'avg',
  // 'base64_decode_binary',
  // 'base64_decode_string',
  // 'base64_encode',
  // 'between',
  // 'bit_length',
  // 'bitand',
  // 'bitand_agg',
  // 'bitnot',
  // 'bitor',
  // 'bitor_agg',
  // 'bitshiftleft',
  // 'bitshiftright',
  // 'bitxor',
  // 'bitxor_agg',
  // 'booland',
  // 'booland_agg',
  // 'boolnot',
  // 'boolor',
  // 'boolor_agg',
  // 'boolxor',
  // 'boolxor_agg',
  // 'case',
  'cast',
  // 'cbrt',
  'ceil',
  'charindex',
  // 'check_json',
  // 'check_xml',
  // 'chr',
  'char',
  'coalesce',
  // 'collate',
  // 'collation',
  // 'compress',
  'concat',
  // 'concat_ws',
  // 'conditional_change_event',
  // 'conditional_true_event',
  'contains',
  'convert_timezone',
  // 'copy_history',
  // 'corr',
  'cos', // OMG! As of 2/14/2022, a user has never used this. WTF!
  // 'cosh',
  // 'cot',
  'count',
  'count_if',
  // 'covar_pop',
  // 'covar_samp',
  // 'cume_dist',
  // 'current_account',
  // 'current_available_roles',
  // 'current_client',
  // 'current_database',
  'current_date',
  // 'current_region',
  // 'current_role',
  // 'current_schema',
  // 'current_schemas',
  // 'current_session',
  // 'current_statement',
  'current_time', // Not used, seems like we should keep.
  'current_timestamp',
  // 'current_transaction',
  // 'current_user',
  // 'current_version',
  // 'current_warehouse',
  // 'data_transfer_history',
  // 'database_refresh_history',
  // 'database_refresh_progress',
  // 'database_refresh_progress_by_job',
  // 'database_storage_usage_history',
  'date_from_parts',
  'date_part',
  'date_trunc',
  'dateadd',
  'datediff',
  'dayname',
  // 'decode',
  // 'decompress_binary',
  // 'decompress_string',
  // 'decrypt',
  // 'decrypt_raw',
  // 'degrees',
  'dense_rank',
  // 'div0',
  // 'editdistance',
  // 'encrypt',
  // 'encrypt_raw',
  // 'endswith',
  // 'equal_null',
  // 'exp',
  // 'explain_json',
  // 'external_functions_history',
  // 'external_table_files',
  // 'external_table_file_registration_history',
  'extract',
  // 'factorial',
  'first_value',
  'flatten',
  'floor', // Not used, seems like we should keep.
  // 'generator',
  // 'get',
  // 'get_ddl',
  'get_ignore_case',
  // 'get_object_references',
  // 'get_path',
  // 'getbit',
  'greatest',
  // 'grouping',
  // 'grouping_id',
  // 'hash',
  // 'hash_agg',
  'haversine', // Not used, seems like we should keep.
  // 'hex_decode_binary',
  // 'hex_decode_string',
  // 'hex_encode',
  // 'hll',
  // 'hll_accumulate',
  // 'hll_combine',
  // 'hll_estimate',
  // 'hll_export',
  // 'hll_import',
  'hour',
  'iff',
  'ifnull',
  'ilike',
  'initcap',
  // 'insert',
  // 'invoker_role',
  // 'invoker_share',
  'is_array', // A lot of the is_TYPE methos aren't used. I'm keeping them.
  'is_binary',
  'is_boolean',
  'is_char',
  'is_varchar',
  'is_date',
  'is_date_value',
  'is_decimal',
  'is_double',
  'is_real',
  // 'is_granted_to_invoker_role',
  'is_integer',
  'is_null_value',
  'is_object',
  // 'is_role_in_session',
  'is_time',
  'is_timestamp',
  'is_timestamp_ltz',
  'is_timestamp_ntz',
  'is_timestamp_tz',
  'json_extract_path_text',
  // 'kurtosis',
  'lag',
  'last_day',
  // 'last_query_id',
  // 'last_transaction',
  'last_value',
  'lead',
  'least',
  'left',
  'length',
  'len',
  'listagg',
  'ln',
  // 'localtime',
  // 'localtimestamp',
  'log',
  // 'login_history',
  // 'login_history_by_user',
  'lower',
  'lpad',
  'ltrim',
  // 'materialized_view_refresh_history',
  // 'md5',
  // 'md5_hex',
  // 'md5_binary',
  // 'md5_number',
  'median',
  'min',
  'max',
  // 'minhash',
  // 'minhash_combine',
  // 'minute',
  'mod', // Not used, seems like we should keep.
  'mode',
  'monthname',
  'next_day',
  // 'normal',
  'nth_value',
  'ntile',
  'nullif',
  // 'nullifzero',
  'nvl',
  // 'nvl2',
  // 'object_agg',
  'object_construct',
  // 'object_delete',
  // 'object_insert',
  'object_keys',
  'object_pick',
  // 'octet_length',
  'parse_ip',
  'parse_json',
  'parse_url',
  'parse_xml',
  'percent_rank',
  // 'percentile_cont',
  // 'percentile_disc',
  // 'pi',
  // 'pipe_usage_history',
  // 'policy_references',
  // 'position',
  // 'pow, power',
  'previous_day',
  // 'query_history',
  // 'query_history_by_session',
  // 'query_history_by_user',
  // 'query_history_by_warehouse',
  'radians',
  'random',
  // 'randstr',
  'rank',
  // 'ratio_to_report',
  // 'regexp',
  // 'regexp_count',
  'regexp_instr',
  'regexp_like',
  'regexp_replace',
  // 'regexp_substr',
  // 'regr_avgx',
  // 'regr_avgy',
  // 'regr_count',
  // 'regr_intercept',
  // 'regr_r2',
  // 'regr_slope',
  // 'regr_sxx',
  // 'regr_sxy',
  // 'regr_syy',
  // 'regr_valx',
  // 'regr_valy',
  // 'repeat',
  'replace',
  // 'replication_usage_history',
  // 'rest_event_history',
  // 'result_scan',
  // 'reverse',
  'right',
  'rlike', // Not used, seems like we should keep.
  'round',
  'row_number',
  // 'rpad',
  // 'rtrim',
  // 'rtrimmed_length',
  // 'search_optimization_history',
  // 'second',
  // 'seq1',
  // 'seq2',
  // 'seq4',
  // 'seq8',
  // 'sha1',
  // 'sha1_hex',
  // 'sha1_binary',
  // 'sha2',
  // 'sha2_hex',
  // 'sha2_binary',
  // 'sign',
  'sin', // Not used, seems like we should keep.
  // 'sinh',
  // 'skew',
  // 'soundex',
  // 'space',
  'split',
  'split_part',
  // 'split_to_table',
  'sqrt', // Not used, seems like we should keep.
  'square', // Not used, seems like we should keep.
  // 'st_area',
  // 'st_asewkb',
  // 'st_asewkt',
  // 'st_asgeojson',
  // 'st_aswkb',
  // 'st_asbinary',
  // 'st_aswkt',
  // 'st_astext',
  // 'st_azimuth',
  // 'st_centroid',
  // 'st_collect',
  // 'st_contains',
  // 'st_coveredby',
  // 'st_covers',
  // 'st_dimension',
  // 'st_disjoint',
  // 'st_distance',
  // 'st_dwithin',
  // 'st_envelope',
  // 'st_geogfromgeohash',
  // 'st_geographyfromwkb',
  // 'st_geographyfromwkt',
  // 'st_geohash',
  // 'st_hausdorffdistance',
  // 'st_intersects',
  // 'st_length',
  // 'st_makeline',
  // 'st_makepoint',
  // 'st_point',
  // 'st_makepolygon',
  // 'st_polygon',
  // 'st_npoints',
  // 'st_numpoints',
  // 'st_perimeter',
  // 'st_simplify',
  // 'st_srid',
  // 'st_within',
  // 'st_x',
  // 'st_xmax',
  // 'st_xmin',
  // 'st_y',
  // 'st_ymax',
  // 'st_ymin',
  // 'stage_storage_usage_history',
  // 'startswith',
  'stddev', // Not used, seems like we should keep.
  // 'stddev_pop',
  // 'stddev_samp',
  // 'strip_null_value',
  // 'strtok',
  // 'strtok_split_to_table',
  // 'strtok_to_array',
  'substr',
  'substring',
  'sum',
  // 'sysdate',
  // 'system$abort_session',
  // 'system$abort_transaction',
  // 'system$behavior_change_bundle_status',
  // 'system$cancel_all_queries',
  // 'system$cancel_query',
  // 'system$clustering_depth',
  // 'system$clustering_information',
  // 'system$clustering_ratio — deprecated',
  // 'system$current_user_task_name',
  // 'system$database_refresh_history',
  // 'system$database_refresh_progress',
  // 'system$database_refresh_progress_by_job',
  // 'system$disable_behavior_change_bundle',
  // 'system$enable_behavior_change_bundle',
  // 'system$estimate_search_optimization_costs',
  // 'system$explain_json_to_text',
  // 'system$explain_plan_json',
  // 'system$generate_saml_csr',
  // 'system$generate_scim_access_token',
  // 'system$get_aws_sns_iam_policy',
  // 'system$get_predecessor_return_value',
  // 'system$get_privatelink_config',
  // 'system$get_snowflake_platform_info',
  // 'system$last_change_commit_time',
  // 'system$migrate_saml_idp_registration',
  // 'system$pipe_force_resume',
  // 'system$pipe_status',
  // 'system$set_return_value',
  // 'system$show_oauth_client_secrets',
  // 'system$stream_get_table_timestamp',
  // 'system$stream_has_data',
  // 'system$task_dependents_enable',
  // 'system$typeof',
  // 'system$user_task_cancel_ongoing_executions',
  // 'system$verify_external_oauth_token',
  // 'system$wait',
  // 'system$whitelist',
  // 'system$whitelist_privatelink',
  'tan', // Not used, seems like we should keep.
  // 'tanh',
  // 'task_dependents',
  // 'task_history',
  // 'time_from_parts',
  // 'time_slice',
  'timeadd', // Not used, seems like we should keep.
  'timediff',
  // 'timestamp_from_parts',
  'timestampadd', // Not used, seems like we should keep.
  'timestampdiff',
  // Most of the to_TYPE methods aren't used. Keeping them.
  'to_array',
  'to_binary',
  'to_boolean',
  'to_char',
  'to_varchar',
  'to_date',
  'date',
  'to_decimal',
  'to_number',
  'to_numeric',
  'to_double',
  'to_geography',
  'to_json',
  'to_object',
  'to_time',
  'time',
  'to_timestamp',
  'to_timestamp_ltz',
  'to_timestamp_ntz',
  'to_timestamp_tz',
  'to_variant',
  'to_xml',
  // 'translate',
  'trim',
  // 'truncate',
  // 'trunc',
  // 'try_base64_decode_binary',
  // 'try_base64_decode_string',
  'try_cast',
  // 'try_hex_decode_binary',
  // 'try_hex_decode_string',
  'try_parse_json',
  // 'try_to_binary',
  'try_to_boolean',
  'try_to_date',
  // 'try_to_decimal',
  'try_to_number',
  // 'try_to_numeric',
  // 'try_to_double',
  // 'try_to_geography',
  // 'try_to_time',
  // 'try_to_timestamp',
  // 'try_to_timestamp_ltz',
  // 'try_to_timestamp_ntz',
  // 'try_to_timestamp_tz',
  // 'typeof',
  // 'unicode',
  // 'uniform',
  'upper',
  // 'uuid_string',
  // 'validate',
  // 'validate_pipe_load',
  // 'var_pop',
  // 'var_samp',
  // 'variance',
  // 'variance_samp',
  // 'variance_pop',
  // 'warehouse_load_history',
  // 'warehouse_metering_history',
  // 'width_bucket',
  // 'xmlget',
  'year',
  'day',
  'week',
  'month',
  'quarter',
  'zeroifnull',
  // 'zipf',
];

export default snowflakeFunctions;
export { autocompleteSnowflakeFunctions };
