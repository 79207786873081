import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import { Connector } from 'api/APITypes';

import { trackConnector } from '../../ConnectorUtils';

import { previewReportPath } from '../pathUtils';

import CreateLaterView from './CreateLaterView';

interface CreateLaterControllerProps {
  connector: Connector;
}

export default function CreateLaterController(props: CreateLaterControllerProps) {
  useTitle('Create Report Later');
  const { connector } = props;
  const navigate = useNavigate();

  const handleGoBack = () => {
    trackConnector('ReportBuilderCreateLater GoBackToPreview', connector);
    navigate(previewReportPath(connector));
  };

  const handleGoToConnectors = () => {
    trackConnector('ReportBuilderCreateLater GoToListConnectors', connector);
    navigate('/connectors');
  };

  return (
    <CreateLaterView
      connector={connector}
      onGoBack={handleGoBack}
      onGoToConnectors={handleGoToConnectors}
    />
  );
}
