// This file contains util functions used by multiple tabs in the usage page
import { subMonths, startOfMonth } from 'date-fns';
import dayjs from 'dayjs';
import _ from 'lodash';

import { parseApiDate } from 'utils/dateTime';

import { ReportTypes } from './Usage';
import { COLORS } from './UsageChart';

export function getTodayAndStartDate(reportType: ReportTypes) {
  const today = new Date();
  // Default to reportType === 'lastYearMonthly'
  let startDate = startOfMonth(subMonths(today, 12));
  if (reportType === 'currentMonthDaily') {
    startDate = startOfMonth(today);
  } else if (reportType === 'last2Months') {
    startDate = startOfMonth(subMonths(today, 2));
  }
  return [today, startDate];
}

export function sortDate(a: any, b: any) {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}

function getDataFilteredByColumn(data: any, filterColumnName: string, filterValue: string | null) {
  return filterValue === null
    ? data
    : data.filter((r: any) => {
        return r[filterColumnName] === filterValue;
      });
}

function getDataFilteredByDate(data: any, filterColumnName: string, today: Date, startDate: Date) {
  return data.filter((r: any) => {
    const measuredDate = new Date(r[filterColumnName]);
    return measuredDate >= startDate && measuredDate <= today;
  });
}

export function getDataAggregated(data: any, columnsToAggregateBy: string[]) {
  // If we are grouping by more than 1 column, we need to concat the values to have a single column to group by with lodash
  // If we only have 1 groupByColumn, we can just pass the data thru
  let dataToGroup = data;
  let groupByColumnName = columnsToAggregateBy[0];
  if (columnsToAggregateBy.length > 1) {
    dataToGroup = data.map((r: any) => {
      const groupedColValue = columnsToAggregateBy.map((n) => r[n]).join('');
      return { ...r, groupedCol: groupedColValue };
    });
    groupByColumnName = 'groupedCol';
  }

  return _(dataToGroup)
    .groupBy(groupByColumnName)
    .map((array, groupedCol) => {
      let aggregatedObject: any = {};
      Object.entries(array[0]).forEach((e) => {
        const [key, value] = e;
        if (typeof value === 'number' || typeof value === 'bigint') {
          aggregatedObject[key] = _.sumBy(array, key);
        } else {
          aggregatedObject[key] = value;
        }
      });
      return aggregatedObject;
    })
    .value();
}

export function getChartFormattedData(
  data: any,
  filterColumnName: string,
  dateColumnName: string,
  filterValue: string | null,
  startDate: Date,
  today: Date,
  reportType: ReportTypes,
  groupByColumns: string[],
) {
  const filteredData = getDataFilteredByColumn(data, filterColumnName, filterValue);
  const dateFilteredData = getDataFilteredByDate(filteredData, dateColumnName, today, startDate);
  // Then group by month if necessary
  if (reportType === 'lastYearMonthly') {
    return getDataAggregated(dateFilteredData, groupByColumns);
  } else {
    return dateFilteredData;
  }
}

export function getSortedDatasetLabels(
  chartFormattedData: any,
  dataGroupedByDataset: any,
  datasetColumnName: string,
  columnToSortBy: string,
) {
  return _.uniq(chartFormattedData.map((r: any) => r[datasetColumnName])).sort((a, b) => {
    const aTotal =
      dataGroupedByDataset.find((r: any) => {
        return r[datasetColumnName] === a;
      })[columnToSortBy] || 0;
    const bTotal =
      dataGroupedByDataset.find((r: any) => {
        return r[datasetColumnName] === b;
      })[columnToSortBy] || 0;
    if (aTotal === bTotal) {
      return 0;
    }
    return aTotal < bTotal ? 1 : -1;
  });
}

export function getBudgetDataset(companyPlanByMonth: any, budgetColumnName: string, today: Date) {
  // Add company budget if they are budget based on monthly view
  // XXX TODO: maybe only do this if there are any non-usage months?
  if (companyPlanByMonth) {
    const dateFilteredBudgetData = companyPlanByMonth
      .filter((r: any) => {
        const currentDate = parseApiDate(r['MONTH_START']);
        const budgetStartDate = startOfMonth(subMonths(today, 12));
        return currentDate && currentDate >= budgetStartDate && currentDate <= today;
      })
      .sort((a: any, b: any) => sortDate(a.MONTH_START, b.MONTH_START))
      .map((r: any) => {
        if (r.IS_USAGE) {
          return null;
        } else {
          return r[budgetColumnName];
        }
      });
    return {
      label: 'Budget',
      type: 'line',
      data: dateFilteredBudgetData,
      backgroundColor: 'transparent',
      borderColor: 'rgba(2, 106, 162, 0.4)',
      pointStyle: false,
      maxBarThickness: 110,
    };
  } else {
    return null;
  }
}

export function getDataGrouped(data: any, groupByColumn: string) {
  let groupedByDate: any = {};
  data.forEach((r: any) => {
    if (groupedByDate[r[groupByColumn]] === undefined) {
      groupedByDate[r[groupByColumn]] = [r];
    } else {
      groupedByDate[r[groupByColumn]].push(r);
    }
  });
  return groupedByDate;
}

export function getDatasets(
  datasetLabels: any[],
  chartDataGroupedByDate: {
    [date: string]: any[];
  },
  datasetColumnName: string,
  datasetSortColumnName: string,
) {
  let newDatasets = datasetLabels.map((l, index) => {
    const dataForSpecificDataset = Object.entries(chartDataGroupedByDate)
      .sort((a, b) => sortDate(a[0], b[0]))
      .map((entry: any) => {
        // Check if entry[1] is defined, and if it's an array or array-like object
        if (!entry[1] || !Array.isArray(entry[1])) {
          return null;
        }
        const foundElement = entry[1].find((r: any) => r[datasetColumnName] === l);
        // Check if the foundElement is defined and has the required property before accessing it
        if (!foundElement || !(datasetSortColumnName in foundElement)) {
          return null;
        }
        return foundElement[datasetSortColumnName];
      });
    return {
      label: l,
      data: dataForSpecificDataset,
      backgroundColor: COLORS[index] || 'grey',
      maxBarThickness: 110,
    };
  });
  // If there are more than the cutoff # of datasets, group the tail into an other category
  const OTHER_CATEGORY_CUTOFF_INDEX = 10;
  if (newDatasets.length > OTHER_CATEGORY_CUTOFF_INDEX) {
    // For every dataset with an index over our threshold,
    //   zip and sum the data for that dataset into an other category for display in the chart
    const datasetsToMerge = newDatasets.slice(OTHER_CATEGORY_CUTOFF_INDEX - 1);
    const sumOtherCategoryData = _.zipWith(...datasetsToMerge.map((ds) => ds.data), (...args) => {
      return _.sum(args);
    });
    const otherDataset = {
      label: 'Other',
      data: sumOtherCategoryData,
      backgroundColor: COLORS[-1],
      maxBarThickness: 110,
    };
    newDatasets = [...newDatasets.slice(0, OTHER_CATEGORY_CUTOFF_INDEX - 1), otherDataset];
  }
  return newDatasets;
}

export function getCurrentMonthValue(data: any, today: Date, dateColName: string, valueColName: string) {
  return _.sum(
    data
      .filter((r: any) => {
        const currentDate = new Date(r[dateColName]);
        const currentMonthStartDate = startOfMonth(today);
        return currentDate >= currentMonthStartDate;
      })
      .map((r: any) => r[valueColName]),
  );
}

export function getLabels(
  chartDataGroupedByDate: { [date: string]: any[] },
  companyPlanByMonth?: any[] | null,
) {
  const dateFormat = 'MMMM D, YYYY';
  const labelsWithoutUsageStars = Object.keys(chartDataGroupedByDate).sort(sortDate);
  if (companyPlanByMonth) {
    const hasBothUsageAndNonUsageMonths =
      companyPlanByMonth.some((p) => p.IS_USAGE === true) &&
      companyPlanByMonth.some((p) => p.IS_USAGE === false);
    return labelsWithoutUsageStars.map((l) => {
      const formattedLabelDate = dayjs(parseApiDate(l)).format(dateFormat);
      const monthIsUsage = companyPlanByMonth.find((p) => {
        // The months are strings, but formatted slightly differently, so parse and reformat to make them comparable
        return dayjs(parseApiDate(p.MONTH_START)).format(dateFormat) === formattedLabelDate;
      })?.IS_USAGE;
      if (monthIsUsage && hasBothUsageAndNonUsageMonths) {
        return formattedLabelDate + ' *';
      } else {
        return formattedLabelDate;
      }
    });
  } else {
    return labelsWithoutUsageStars.map((l) => dayjs(parseApiDate(l)).format(dateFormat));
  }
}
