import { useMemo } from 'react';

import { syntaxHighlighting, HighlightStyle } from '@codemirror/language';
import { Extension } from '@codemirror/state';
import { EditorView } from '@codemirror/view';
import { tags as t } from '@lezer/highlight';

// FYI, nay WARNING:
// This component does some unexpected things
// because it was originally designed to be used exclusively with CodeMirrorSqlEditor.
// Not refactoring things right now.
// 1. The highlightStyleOverride does SQL Syntax highlighting for both enabled and disabled states.
// 2. The original CodeMirrorSqlEditor was designed to use the default theme
//    and then optionally inject the returned disabledTheme if editMode===true.
export interface CodeMirrorThemeConfig {
  highlightStyleOverride?: Array<any>;
  disabledThemeOverride?: {
    [selector: string]: object;
  };
}
export interface CodeMirrorThemeCalculation {
  // Always include this extension. See big comment above:
  syntaxHighlightStyle: Extension;
  // Include this extension when editMode===false. See big comment above:
  disabledTheme: Extension;
}

// This hook memoizes it's params and returns a memoized value to prevent CodeMirrorSQLEditor
// from re-compute it's extensions everytime a user types a character.
const useCodeMirrorTheme = (userConfig?: CodeMirrorThemeConfig): CodeMirrorThemeCalculation => {
  const defaultThemeConfig: CodeMirrorThemeConfig = useMemo(
    () => ({
      highlightStyleOverride: userConfig?.highlightStyleOverride || [],
      disabledThemeOverride: userConfig?.disabledThemeOverride || {},
    }),
    [userConfig],
  );

  return useMemo(() => {
    // Defines sytnax highlighting colors
    // Available tags can be found at https://lezer.codemirror.net/docs/ref/#highlight.tags
    const syntaxHighlightStyle = HighlightStyle.define([
      { tag: t.comment, color: '#8E908C' },
      { tag: [t.string, t.special(t.brace)], color: '#718C00' },
      { tag: t.number, color: '#F5871F' },
      { tag: t.keyword, color: '#8959A8' },
      { tag: t.operator, color: '#3E999F' },
      ...(defaultThemeConfig.highlightStyleOverride || []),
      // { tag: t.bracket, color: '#12b76a' },// maybe use this for incremental transforms brackets?
    ]);

    // disabled theme since CodeMirror doesn't add a
    // css class for it's read-only mode
    const disabledTheme = EditorView.theme(
      {
        ...DISABLED_THEME_STYLES,
        ...defaultThemeConfig.disabledThemeOverride,
      },
      { dark: false },
    );
    return { syntaxHighlightStyle: syntaxHighlighting(syntaxHighlightStyle), disabledTheme };
  }, [defaultThemeConfig]);
};

export default useCodeMirrorTheme;

export const DISABLED_THEME_STYLES = {
  '.cm-scroller': {
    backgroundColor: '#f3f2f3',
  },
};
