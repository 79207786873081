/*
Appends Warehouse specific behavior to generic DatabaseSearchReducer.
*/
import React from 'react';

import { AggTable } from 'api/APITypes';

import {
  DatabaseSearchState,
  Action,
  TableLists,
  reducer as baseReducer,
  initialState as baseInitialState,
  setTableLists as baseSetTables,
} from '../DatabaseSearch2/DatabaseSearchReducer';

export type {
  FilteredSchemaMap,
  SchemasExpandedMap,
  VirtualSchemaType,
} from '../DatabaseSearch2/DatabaseSearchReducer';
export {
  MOZART_ALL,
  MOZART_RECENT,
  MOZART_FAVORITES,
  MOZART_TRANSFORMS,
  MOZART_SNAPSHOTS,
  UNMANAGED_SCHEMA,
  virtualSchemaKeyParts,
} from '../DatabaseSearch2/DatabaseSearchReducer';

interface WarhouseSearchState extends DatabaseSearchState {
  // Map keys to DOM refs for the hover listener to look up.
  tableRefMap: TableRefMap;
}

interface TableRefState {
  table: AggTable;
  descRef: React.RefObject<HTMLDivElement>;
  scheduledRef: React.RefObject<HTMLTableCellElement>;
  agoRef: React.RefObject<HTMLSpanElement>;
  tagPickerRef: React.RefObject<HTMLDivElement>;
}

// Maps table keys to DOM refs.
// This is used as a look up table for hover listeners.
export interface TableRefMap {
  [key: string]: TableRefState; // key = schema+full_name
}

export const initialState: WarhouseSearchState = {
  ...baseInitialState,
  tableRefMap: {},
};

export function reducer(prevState: WarhouseSearchState, action: Action) {
  if (action.type === 'SET_TABLE_LISTS') {
    setTableLists(prevState, action.tableLists as TableLists);
    return { ...prevState }; // New object forces rerender
  }

  return baseReducer(prevState, action) as WarhouseSearchState;
}

function setTableLists(prevState: WarhouseSearchState, tableLists: TableLists) {
  baseSetTables(prevState, tableLists);
  buildTableRefMap(prevState);
}

function buildTableRefMap(prevState: WarhouseSearchState) {
  const newTableRefMap: TableRefMap = {};

  for (const t of prevState.unfilteredTables) {
    const descRef = React.createRef<HTMLDivElement>();
    const scheduledRef = React.createRef<HTMLTableCellElement>();
    const agoRef = React.createRef<HTMLSpanElement>();
    const tagPickerRef = React.createRef<HTMLDivElement>();
    newTableRefMap[t.full_name] = {
      table: t,
      descRef,
      scheduledRef,
      agoRef,
      tagPickerRef,
    };
  }
  prevState.tableRefMap = newTableRefMap;
}
