import { useState } from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import TextAreaFormikGroup from 'components/inputs/formik_group/TextAreaFormikGroup/TextAreaFormikGroup';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { convertStructuredErrorToSentences } from 'utils/byoSetupErrorHelpers';

interface UpdateBigQuerySecretFormProps {
  secret: string;
}

const schema = yup.object({
  secret: yup.string().required('Required').trim(),
});

export default function UpdateBigQuerySecretForm() {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [updated, setUpdated] = useState(false);
  const initialValues: UpdateBigQuerySecretFormProps = { secret: '' };

  const submitForm = (values: UpdateBigQuerySecretFormProps, actions: any) => {
    setSaving(true);
    setError('');
    const api = new API();
    api
      .post('api/bigquery_secret', { secret: values.secret })
      .then(() => {
        setSaving(false);
        setUpdated(true);
        actions.resetForm();
      })
      .catch((error) => {
        setSaving(false);
        const displayableError = convertStructuredErrorToSentences('byobq', error.response.data);
        setError(displayableError || 'There was an error updating the key');
      });
  };

  return (
    <div className="ml-3">
      <div className="text-xl text-pri-gray-700">Update Service Account Key</div>
      {error && (
        <Alert variant="error" className="mb-4">
          {error}
        </Alert>
      )}
      {updated && (
        <Alert variant="info" className="mb-4">
          Key updated successfully
        </Alert>
      )}
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitForm}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="m-t2">
              <p className="">Paste the JSON key below</p>
              <TextAreaFormikGroup
                disabled={saving}
                className="w-[445px] h-[150px]"
                name="secret"
                placeholder=""
                label={''}
                groupClass="mt-4"
              />
              <div className="mt-2">
                <Button type="submit" spinning={saving}>
                  Submit
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
