// If you need to add a new icon to the list of icons included in the build,
// add it to the list in this file.
import BulletedStep, { BulletedStepProps } from '../BulletedStep/BulletedStep';

import usedIcons from './iconStepUsedIcons';

export type IconKey = keyof typeof usedIcons;

interface IconStepProps extends Omit<BulletedStepProps, 'bullet'> {
  icon: IconKey;
}

export default function IconStep(props: IconStepProps) {
  const { icon, ...rest } = props;
  const IconElement = usedIcons[icon];
  const bullet = <IconElement size={20} />;

  return <BulletedStep {...rest} bullet={bullet} />;
}
