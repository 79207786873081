import { UserProfile, VersionUserProfile } from 'api/APITypes';

const userProfileHelper = {
  displayName: (profile: UserProfile | VersionUserProfile) => {
    // GitHubUsers from the backend only have full_name
    return 'full_name' in profile ? profile.full_name : `${profile.first_name} ${profile.last_name}`;
  },
};

export default userProfileHelper;
