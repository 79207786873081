import React, { useMemo, useState } from 'react';

import { UserProfile } from 'api/APITypes';
import ListboxGroup, { ListboxValue } from 'components/inputs/group/ListboxGroup/ListboxGroup';
import ConfirmDeleteModal from 'components/layouts/containers/modals/ConfirmDeleteModal/ConfirmDeleteModal';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { companyRoleMap } from '../useMozartUsers';
import { SnowflakeRoleModalHookProps } from '../useSnowflakeRoleModal';

export interface SnowflakeRoleModalProps
  extends Omit<SnowflakeRoleModalHookProps, 'openSnowflakeModal' | 'snowflkeModalUser'> {
  snowflakeModalUser: UserProfile;
}

export default function SnowflakeRoleModal(props: SnowflakeRoleModalProps) {
  const {
    snowflakeRoleOptions,
    snowflakeRoleError,
    snowflakeRoleLoading,
    snowflakeRoleSaving,
    snowflakeRoleSavingError,
    snowflakeModalUser,
    onSaveSnowflakeRole,
    closeSnowflakeModal,
  } = props;

  const [snowflakeRole, setSnowflakeRole] = useState(snowflakeModalUser.snowflake_role);

  // Append default to the role that matches the expected Mozart Role so that Admins can easily set the default role.
  const optionsWithDefault = useMemo(() => {
    const expectedSnowflakeRole = companyRoleMap[snowflakeModalUser.company_role];
    return snowflakeRoleOptions.map((option) => {
      if (option.value === expectedSnowflakeRole) {
        return {
          value: option.value,
          label: (
            <span>
              {option.value}
              <span className="ml-1 text-pri-gray-400">(default)</span>
            </span>
          ),
        };
      }
      return option;
    });
  }, [snowflakeRoleOptions, snowflakeModalUser.company_role]);

  const handleChangeSnowflakeRole = (snowflakeRole: ListboxValue) => {
    setSnowflakeRole(snowflakeRole as string);
  };

  const handleSaveSnowflakeRole = () => {
    onSaveSnowflakeRole(snowflakeRole);
  };

  const anyError = snowflakeRoleError || snowflakeRoleSavingError;

  return (
    <ConfirmDeleteModal
      header="Snowflake Role"
      onCancel={closeSnowflakeModal}
      onConfirm={handleSaveSnowflakeRole}
      enableConfirm={snowflakeModalUser.snowflake_role !== snowflakeRole}
      confirmText="Save"
      deleting={snowflakeRoleSaving}
    >
      <div className="w-[800px] h-[550px] p-4 flex flex-col">
        {snowflakeRoleLoading ? (
          <CenteredSpinner containerMinHeight="100%" />
        ) : (
          <>
            <Alert variant="info">
              The Snowflake Role is used to regulate access to data in your data warehouse, which is an
              instance of a Snowflake database. We use this role when you make queries to your Snowflake
              database via the Mozart App as well as to check that you have permission to access data
              when making changes to Mozart settings that access your Snowflake database, for example
              when editing the SQL of a transform. While this role will usually match your "Mozart Role",
              it may be any role in Snowflake if a higher level of control is desired.
            </Alert>
            {anyError && (
              <Alert variant="error" className="mt-4">
                {anyError}
              </Alert>
            )}
            <ListboxGroup
              label="Snowflake Role"
              name="snowflake_role"
              value={snowflakeRole}
              options={optionsWithDefault}
              hasError={!!snowflakeRoleError}
              error={snowflakeRoleError}
              variant="gray"
              size="medium"
              onChange={handleChangeSnowflakeRole}
              disabled={!snowflakeModalUser.user.is_active}
              widthClass="w-[400px]"
              optionsMaxHeightClass="max-h-[300px]"
              groupClass="mt-4"
            />
          </>
        )}
      </div>
    </ConfirmDeleteModal>
  );
}
