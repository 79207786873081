/*
This is the default signup form that most people see in most circumstances.
*/
import { Helmet } from 'react-helmet';

import LoggedOutFormBackgroundLayout from 'components/layouts/pages/LoggedOutFormBackgroundLayout/LoggedOutFormBackgroundLayout';

import SignupLeftSideCopy from './SignupLeftSideCopy';
import SignupRightSideForm from './SignupRightSideForm';

interface SignupProps {}

const Signup = (props: SignupProps) => {
  return (
    <LoggedOutFormBackgroundLayout>
      <Helmet>
        <title>Signup for Mozart Data | Mozart Data</title>
        <meta
          name="description"
          content="Sign up for Mozart Data and deploy your own data stack in just a few minutes. Start free with Mozart Data today."
        />
      </Helmet>
      <div className="w-full h-full p-4 flex overflow-auto">
        <div className="w-full lg:w-auto m-auto flex flex-col lg:flex-row">
          <SignupLeftSideCopy />
          <SignupRightSideForm />
        </div>
      </div>
    </LoggedOutFormBackgroundLayout>
  );
};

export default Signup;
