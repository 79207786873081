import React from 'react';

import dayjs from 'dayjs';

import BackButton from 'components/inputs/basic/Button/BackButton';
import OptionSelector from 'components/inputs/composite/OptionSelector/OptionSelector';

import { ReportTypes, UsageTabs } from './Usage';
import UsageChart from './UsageChart';
import UsageProgressBar from './UsageProgressBar';
import { UsageTable } from './UsageTable';
import { CompanyPlanRow } from './useUsageFetch';

const NAME_MAPPINGS = {
  summary: { tableName: 'NA', unitType: 'NA', chartBy: 'NA' },
  connectors: { tableName: 'All Connectors', unitType: 'MAR', chartBy: 'Connector' },
  compute: { tableName: 'All Apps', unitType: 'Compute Credits', chartBy: 'App' },
  storage: { tableName: 'Storage', unitType: 'Storage', chartBy: 'Date' },
  cloud_service: { tableName: 'All Apps', unitType: 'Cloud Service Credits', chartBy: 'App' },
  estimator: { tableName: 'NA', unitType: 'NA', chartBy: 'NA' },
};

// XXX TODO: Fix types
interface TabViewProps {
  loadingTable?: boolean;
  tableData: any;
  datasets: any;
  labels: string[];
  startDate: Date;
  today: Date;
  selectedFilter?: string | null;
  reportType: ReportTypes;
  currentMonthPlan?: CompanyPlanRow | null;
  currentMonthValue?: number; // XXX TODO: Maybe rename this
  tab: UsageTabs;
  onSetReportType: (newReportType: ReportTypes) => void;
  onSelectFilter?: (newFilter: string | null) => void;
  onRowClick?: (row: any) => void;
}

// This component uses a series of useMemos to filter and group the data
export default function TabView(props: TabViewProps) {
  const {
    loadingTable,
    tableData,
    datasets,
    labels,
    startDate,
    today,
    selectedFilter,
    reportType,
    currentMonthPlan,
    currentMonthValue,
    tab,
    onSetReportType,
    onSelectFilter,
    onRowClick,
  } = props;

  const chartTitle = `Trend of ${NAME_MAPPINGS[tab].unitType} ${
    selectedFilter ? `for ${selectedFilter}` : `by ${NAME_MAPPINGS[tab].chartBy}`
  }`;

  const dateRangeOptions = [
    { displayName: 'Current Month', value: 'currentMonthDaily' },
    { displayName: 'Last 2 Months', value: 'last2Months' },
    { displayName: 'Last Year', value: 'lastYearMonthly' },
  ];

  return (
    <div className="f-col">
      {currentMonthPlan && currentMonthValue !== undefined && !currentMonthPlan.IS_USAGE && (
        <UsageProgressBar
          currentMonthPlan={currentMonthPlan}
          currentMonthValue={currentMonthValue}
          tab={tab}
        />
      )}
      <div className="f-col">
        <div className="mt-4 f-row-y-center">
          <OptionSelector
            selectedOption={reportType}
            options={dateRangeOptions}
            setSelectedOption={(newValue: string) => onSetReportType(newValue as ReportTypes)}
          />
          <div className="ml-4 text-base text-pri-gray-700">
            {dayjs(startDate).format('MMMM D, YYYY')} - {dayjs(today).format('MMMM D, YYYY')}
          </div>
        </div>
        <div className="mt-6 font-medium">{chartTitle}</div>
        {datasets && labels && <UsageChart datasets={datasets} labels={labels} tab={tab} />}
        <div className="mt-4 f-row-y-center">
          {selectedFilter && (
            <>
              <BackButton
                text="ALL APPS"
                onClick={onSelectFilter ? () => onSelectFilter(null) : () => {}}
              />
              <div className="w-[1px] h-[24px] mr-2 bg-pri-gray-200" />
            </>
          )}
          <div>{selectedFilter ? selectedFilter : NAME_MAPPINGS[tab].tableName}</div>
        </div>
        <UsageTable
          tab={tab}
          loading={loadingTable && !!selectedFilter} // We don't need to spin if loadingTable is still true, but we no longer have a selectedFilter
          data={tableData}
          onRowClick={onRowClick}
        />
      </div>
    </div>
  );
}
