/*
This is the TableExplorer used in form inputs.

Note that it memoizes everything to reduce the number of rerenders.
The TableExplorer component hierarchy is rather expensive.
*/
import React, { useCallback, useEffect } from 'react';

import { AggTable } from 'api/APITypes';
import { Column } from 'api/columnAPI';
import { SchemaName, TableID } from 'api/tableAPI';
import { SchemaIconType, SchemaWithIconType } from 'components/primitives/icons/SchemaIcon/SchemaIcon';
import TableExplorerBase from 'components/query/TableExplorer/TableExplorerBase';
import useTableExplorerReducer from 'components/query/TableExplorer/useTableExplorerReducer';

import SchemaRowHoverOverlay from './SchemaRowHoverOverlay';
import TableRowHoverOverlay from './TableRowHoverOverlay';

interface TablePickerExplorerProps {
  selectedTable: AggTable | null;
  tableDraggable: boolean;
  tablesNamesUsedInSql?: string[];
  hideTabs?: boolean;
  multiPick?: {
    pickedTables: Record<TableID, AggTable>;
    pickedSchemas: Record<SchemaName, SchemaIconType>;
    onUnpickTable(table: AggTable): void;
    onUnpickSchema(schema: SchemaName): void;
    onPickSchema(schemaWithIconType: SchemaWithIconType): void;
    schemaPickingEnabled: boolean;
  };
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>;
  onPickTable(table: AggTable): void;
  customTablesToDisableFilter?: (table: AggTable) => boolean;
}

const TablePickerExplorer = React.memo((props: TablePickerExplorerProps) => {
  const { tablesNamesUsedInSql, multiPick, onPickTable, customTablesToDisableFilter } = props;
  const { pickedTables, pickedSchemas, onUnpickTable, onPickSchema, onUnpickSchema } = multiPick ?? {};
  const multiPickEnabled = multiPick !== undefined;

  const tableExplorerReduction = useTableExplorerReducer(
    'TablePickerExplorer',
    customTablesToDisableFilter,
  );
  const { disabledTablesByID, setTableNamesInSql } = tableExplorerReduction;

  /*******************************************************************************
   * Overrides relevant to TablePicker
   ******************************************************************************/
  useEffect(() => {
    if (tablesNamesUsedInSql !== undefined) {
      setTableNamesInSql(tablesNamesUsedInSql);
    }
  }, [tablesNamesUsedInSql, setTableNamesInSql]);

  /*******************************************************************************
   * Overrides relevant to TableRows In TableList(s)
   ******************************************************************************/
  const handlePickTable = useCallback(
    (table: AggTable) => {
      if (pickedTables?.[table.id]) {
        return onUnpickTable?.(table);
      }
      return onPickTable(table);
    },
    [onPickTable, pickedTables, onUnpickTable],
  );

  const handlePickSchema = useCallback(
    (schemaWithIconType: SchemaWithIconType) => {
      const { schema } = schemaWithIconType;
      if (multiPickEnabled) {
        if (pickedSchemas?.[schema]) {
          return onUnpickSchema?.(schema);
        }
        return onPickSchema?.(schemaWithIconType);
      }
    },
    [multiPickEnabled, pickedSchemas, onUnpickSchema, onPickSchema],
  );

  const handleDoubleClickTable = useCallback((table: AggTable) => {
    // Do nothing for now. Yay!
  }, []);

  const renderTableHover = useCallback(
    (
      hoveredIndex: number,
      hoveredTable: AggTable,
      selectedTable: AggTable | null,
      overlayRight: number,
    ) => {
      const rowDisabled = disabledTablesByID[hoveredTable.id] !== undefined;

      return (
        <TableRowHoverOverlay
          hoveredIndex={hoveredIndex}
          table={hoveredTable}
          selected={hoveredTable.id === selectedTable?.id}
          disabled={rowDisabled}
          picked={!!pickedTables?.[hoveredTable.id]}
          overlayRight={overlayRight}
          onPickTable={handlePickTable}
        />
      );
    },
    [disabledTablesByID, pickedTables, handlePickTable],
  );

  const renderSchemaHover = useCallback(
    (hoveredIndex: number, hoveredSchema: SchemaWithIconType, overlayRight: number) => {
      return (
        <SchemaRowHoverOverlay
          hoveredIndex={hoveredIndex}
          schemaWithIconType={hoveredSchema}
          overlayRight={overlayRight}
          picked={!!pickedSchemas?.[hoveredSchema.schema]}
          onPickSchema={handlePickSchema}
        />
      );
    },
    [pickedSchemas, handlePickSchema],
  );

  /*******************************************************************************
   * Overrides relevant to ColumnRows in TableDetails
   ******************************************************************************/
  const renderColumnHover = useCallback((column: Column, overlayRight: number) => {
    // Do nothing for now. Yay!
    return null;
  }, []);

  const handleDoubleClickColumn = useCallback((column: Column) => {
    // Do nothing for now. Yay!
  }, []);

  return (
    <TableExplorerBase
      showPinned={tablesNamesUsedInSql && tablesNamesUsedInSql.length > 0}
      onlyAutoPin={true}
      pickedTables={pickedTables}
      pickedSchemas={pickedSchemas}
      {...props}
      tableExplorerReduction={tableExplorerReduction}
      eventLocation="TablePickerExplorer"
      onDoubleClickTable={handleDoubleClickTable}
      onDoubleClickColumn={handleDoubleClickColumn}
      renderTableHover={renderTableHover}
      renderSchemaHover={renderSchemaHover}
      renderColumnHover={renderColumnHover}
    />
  );
});

export default TablePickerExplorer;
