import { useMemo } from 'react';

import { pickHighlightFilter } from 'components/query/TableExplorer/highlightUtils';
import { FilteredSchemaMap } from 'pages/Warehouse/DatabaseSearch2/DatabaseSearchReducer';
import { KeywordLists } from 'utils/TableFilter';

import SchemaItem from './SchemaItem';

interface SchemaGroupProps {
  schemaKeys: string[];
  isFiltering: boolean;
  filterIncludes: KeywordLists;
  virtualSchemaKeysToSearch: string[];
  filteredSchemasMap: FilteredSchemaMap;
  unfilteredSchemasMap: FilteredSchemaMap;
  onClickSchema(schemaKey: string, metaKey: boolean, ctrlKey: boolean, shiftKey: boolean): void;
}

export default function SchemaGroup({
  schemaKeys,
  isFiltering,
  filterIncludes,
  virtualSchemaKeysToSearch,
  filteredSchemasMap,
  unfilteredSchemasMap,
  onClickSchema,
}: SchemaGroupProps) {
  const highlightFilter = useMemo(() => pickHighlightFilter(filterIncludes, 'schema'), [filterIncludes]);

  return (
    <div className="">
      {schemaKeys.map((schemaKey) => (
        <SchemaItem
          key={schemaKey}
          schemaKey={schemaKey}
          selected={virtualSchemaKeysToSearch.includes(schemaKey)}
          highlightFilter={highlightFilter}
          isFiltering={isFiltering}
          iconTables={unfilteredSchemasMap[schemaKey] || []}
          filteredCount={filteredSchemasMap[schemaKey]?.length || 0}
          unfilteredCount={unfilteredSchemasMap[schemaKey]?.length || 0}
          onClickSchema={onClickSchema}
        />
      ))}
    </div>
  );
}
