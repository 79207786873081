import React, { useEffect, useState } from 'react';

import cn from 'classnames';

import AnimatedAngleIcon from 'components/primitives/icons/AnimatedAngleIcon/AnimatedAngleIcon';

import styles from './Accordion.module.css';

interface Props {
  children: React.ReactNode;
  header: string;
  /** Updates `isOpen` when updated */
  startsExpanded?: boolean;
  onClick?: (newExpand: boolean) => void;
  className: string;
}

const Accordion = (props: Props) => {
  const { children, header, startsExpanded = false, onClick = () => {}, className } = props;

  const [isOpen, setIsOpen] = useState(startsExpanded);

  useEffect(() => {
    setIsOpen(startsExpanded);
  }, [startsExpanded]);

  const handleExpand = (newExpand: boolean) => {
    setIsOpen(newExpand);
    onClick(newExpand);
  };

  return (
    <div className={cn(styles.container, className)}>
      <div
        role="button"
        onClick={() => handleExpand(!isOpen)}
        className="py-3 px-6 f-row-y-center f-between"
      >
        <h2 className="mt-4 mx-0 mb-2 text-xl">{header}</h2>
        <AnimatedAngleIcon mode="button" isOpen={isOpen} size={24} />
      </div>
      <div
        className={cn(styles.accordion, {
          [styles.open]: isOpen,
        })}
      >
        <div className={styles.inner}>
          <div className={styles.body}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
