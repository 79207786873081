import { AggTable } from 'api/APITypes';
import { calcGenericRunStatus, lastUploadDate } from 'model_helpers/csvUploadHelper';

import AbstractTableSorter, {
  SCHEDULE_FREQUENCY,
  LAST_RUN_AGO,
  LAST_RUN_STATUS,
} from './AbstractTableSorter';

class CsvUploadSorter extends AbstractTableSorter<AggTable> {
  schedule(table: AggTable): number {
    return SCHEDULE_FREQUENCY.NOT_APPLICABLE;
  }

  lastRunAgo(table: AggTable) {
    const mostRecent = table.csvUpload ? lastUploadDate(table.csvUpload) : null;
    if (mostRecent) {
      const now = new Date();
      return now.getTime() - mostRecent.getTime();
    }

    return LAST_RUN_AGO.NOT_APPLICABLE;
  }

  runStatus(table: AggTable) {
    const runStatus = table.csvUpload ? calcGenericRunStatus(table.csvUpload) : 'NA';
    return LAST_RUN_STATUS[runStatus];
  }
}

const staticInstance = new CsvUploadSorter();

export default staticInstance;
