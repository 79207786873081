import React, { ForwardedRef } from 'react';

import cn from 'classnames';

import { IconKey, IconButtonContent } from './IconButton';
import LinkButton, { LinkButtonProps } from './LinkButton';

import s from './Button.module.css';

export interface LinkIconButtonProps extends Omit<LinkButtonProps, 'children' | 'ref'> {
  icon: IconKey;
  text?: string;
  iconLast?: boolean;
  iconSize?: number;
}

const LinkIconButton = React.forwardRef<HTMLAnchorElement, LinkIconButtonProps>(
  (props: LinkIconButtonProps, forwardedRef: ForwardedRef<HTMLAnchorElement>) => {
    let { icon, text, iconLast, iconSize, disabled, size, variant, className, ...buttonProps } = props;

    // Fix default values if they are undefined.
    size = size || 'large';
    variant = variant || 'lightAction';
    disabled = disabled || false;

    const actualClass = cn(className, s.icon);

    return (
      <LinkButton
        ref={forwardedRef}
        size={size}
        variant={variant}
        className={actualClass}
        {...buttonProps}
      >
        <IconButtonContent
          icon={icon}
          text={text}
          iconLast={iconLast}
          iconSize={iconSize}
          size={size}
          variant={variant}
          disabled={disabled}
        />
      </LinkButton>
    );
  },
);

export default LinkIconButton;
