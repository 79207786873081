/*
Renders a Portable connector icon.

SEE COMMENTS AT TOP OF build_portable_sprite.js
for design decision explanation.
*/
import { allConnectors } from 'pages/connectors/ConnectorRegistry';

import { IconSize, classesBySize } from '../ImgIcon/ImgIcon';

import spriteSheet from './portableSprite';

export interface PortableIconProps {
  service: string;
  size: IconSize;
}

// Portable icons are not all squares.
// Some of them are wide rectangle shaped because they are images of words.
// Additionally, the sprite sheet packer places sprites as tightly as it can.
// So we need to render just the current sprite and center it in a box:
export default function PortableIcon(props: PortableIconProps) {
  const { service, size } = props;
  const { sheetProps, sprites } = spriteSheet;
  const connectorType = allConnectors[service];

  // Step 1: Draw a div the size of the icon we want.
  const centeringBoxClass = 'f-center ' + classesBySize[size];

  // Step 2: Draw an image that just renders the part of the sprite sheet this sprite uses.
  const transparentPixel =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  const spritesPerWidth = sheetProps.width / sheetProps.spriteSize;
  const positionScale = size / sheetProps.spriteSize;
  const sprite = sprites[service];
  const imgStyle: React.CSSProperties = {
    width: positionScale * sprite.width,
    height: positionScale * sprite.height,
    backgroundImage: `url(${sheetProps.url})`,
    backgroundSize: `${spritesPerWidth * size}px`,
    backgroundPosition: `-${positionScale * sprite.x}px -${positionScale * sprite.y}px`,
  };

  return (
    <div className={centeringBoxClass}>
      <img src={transparentPixel} alt={`${connectorType.name} icon`} style={imgStyle} />
    </div>
  );
}
