import React, { useEffect, useCallback, useMemo } from 'react';

import { AggTable, FavoritesByTableID } from 'api/APITypes';
import { SearchColumnsByTableID } from 'api/searchColumnAPI';
import useDebounce from 'hooks/useDebounce';
import { UserPreferencesInterface } from 'model_layer/UserPreferencesContext';

import { Action } from '../DatabaseSearch2/DatabaseSearchReducer';

import useSetSearchURL from './useSetSearchURL';

export const useFilterDispatches = (
  dispatch: React.Dispatch<Action>,
  filter: string,
  hiddenFilter: string | undefined,
  virtualSchemaKeysToSearch: string[],
  hideEmptyTables: boolean,
  hideViews: boolean,
  allTables: AggTable[],
  recentTables: AggTable[],
  favoriteTables: AggTable[],
  searchColumnsByTableID: SearchColumnsByTableID,
  favoritesByTableID: FavoritesByTableID,
  userPreferences: UserPreferencesInterface,
  updateUserPreferences: (newPreferences: UserPreferencesInterface) => void,
) => {
  useEffect(() => {
    dispatch({ type: 'SET_HIDDEN_FILTER_AND_UPDATE', hiddenFilter });
  }, [dispatch, hiddenFilter]);
  /*****************************************************************************
   * Update the URL as the user types in the search filter input
   * OR clicks on schemas in the SchemaList.
   * Clicking on tags, various buttons in the UI, or bookmarked URLs will set the filter in the URL.
   * In this case, set the search filter input to match the URL parameters.
   ****************************************************************************/
  const setFilter = useCallback(
    (filter: string) => {
      dispatch({ type: 'SET_FILTER_AND_UPDATE', filter });
    },
    [dispatch],
  );

  const setVirtualSchemaKeysToSearch = useCallback(
    (virtualSchemaKeysToSearch: string[]) => {
      dispatch({ type: 'SET_VIRTUAL_SCHEMA_KEYS_TO_SEARCH', virtualSchemaKeysToSearch });
    },
    [dispatch],
  );

  useSetSearchURL(filter, setFilter, virtualSchemaKeysToSearch, setVirtualSchemaKeysToSearch);

  /*****************************************************************************
   * Load API data into DatabaseSearchReducer
   ****************************************************************************/
  useEffect(() => {
    const tableLists = {
      allTables,
      recentTables,
      favoriteTables,
      // We don't have disabled tables in the warehouse, so set to empty list
      disabledTables: [],
    };
    dispatch({ type: 'SET_TABLE_LISTS', tableLists });
  }, [dispatch, allTables, recentTables, favoriteTables]);

  useEffect(() => {
    dispatch({ type: 'SET_SEARCH_COLUMNS_BY_TABLE_ID', searchColumnsByTableID });
  }, [dispatch, searchColumnsByTableID]);

  useEffect(() => {
    dispatch({ type: 'SET_FAVORITES_BY_TABLE_ID', favoritesByTableID });
  }, [dispatch, favoritesByTableID]);

  useEffect(() => {
    if (userPreferences.warehouseShowViews !== undefined) {
      dispatch({ type: 'SET_HIDE_VIEWS', hideViews: !userPreferences.warehouseShowViews });
    }
    if (userPreferences.warehouseShowEmptyTables !== undefined) {
      dispatch({
        type: 'SET_HIDE_EMPTY_TABLES',
        hideEmptyTables: !userPreferences.warehouseShowEmptyTables,
      });
    }
  }, [dispatch, userPreferences]);

  /*****************************************************************************
   * User input handlers
   ****************************************************************************/
  const handleToggleHideEmptyTables = () => {
    const hide = !hideEmptyTables;
    dispatch({ type: 'SET_HIDE_EMPTY_TABLES', hideEmptyTables: hide });
    analytics.track(hide ? 'Warehouse HideEmptyTables' : 'Warehouse ShowEmptyTables');
    updateUserPreferences({ warehouseShowEmptyTables: !hide });
  };

  const handleToggleHideViews = () => {
    const hide = !hideViews;
    dispatch({ type: 'SET_HIDE_VIEWS', hideViews: hide });
    analytics.track(hide ? 'Warehouse HideViews' : 'Warehouse ShowViews');
    updateUserPreferences({ warehouseShowViews: !hide });
  };

  const debouncedUpdateFilteredTables = useDebounce(
    useCallback(() => {
      dispatch({ type: 'UPDATE_FILTERED_TABLES' });
    }, [dispatch]),
    30,
    useMemo(() => ({ maxWait: 60 }), []),
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // Experimentally, extremely fast typing is about 0.05s a keystroke.
    // Normal typing is 0.2s a keystroke.
    // Rendering the entire list of warehouse tables is expensive.
    // Reduce the number of renders of the entire table list we do while the user
    // is actively typing separating the updating of the search filter and
    // the updating of the resulting list of warehouse tables into two discreate
    // actions. The former is updated immediately, and the later is debounced
    // to reduce excessive renders of list of warehouse tables.
    const newFilter = event.target.value.toLowerCase();
    dispatch({ type: 'SET_FILTER_ONLY', filter: newFilter });
    // Percieved performance tweak:
    // If the filter is empty, immediately update the list of tables.
    // Otherwise, assume the user is actively typing and debounce the the update.
    if (newFilter === '') {
      dispatch({ type: 'UPDATE_FILTERED_TABLES' });
    } else {
      debouncedUpdateFilteredTables();
    }
  };

  const handleTagClick = useCallback(
    (tagName: string) => {
      const escapedName = tagName.includes(' ') ? `"${tagName}"` : tagName;
      const filter = `tag:${escapedName}`;
      dispatch({ type: 'SET_FILTER_AND_UPDATE', filter });
    },
    [dispatch],
  );

  return {
    handleToggleHideEmptyTables,
    handleToggleHideViews,
    handleFilterChange,
    handleTagClick,
  };
};
