import React, { useMemo } from 'react';

import { Dictionary, keyBy, mapValues } from 'lodash';

import GenerateSnowflakeUser from 'components/business_logic/GenerateSnowflakeUser/GenerateSnowflakeUser';
import { ListboxOption } from 'components/inputs/basic/Listbox/Listbox';
import TextInput from 'components/inputs/basic/TextInput/TextInput';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';

import { SnowflakeUser, SnowflakeUserSaveableFields } from '../useSnowflakeUsers';

import SnowflakeUsersTable from './SnowflakeUsersTable/SnowflakeUsersTable';
import UpdateSnowflakeUserModal from './UpdateSnowflakeUserModal';

export type SnowflakeUsersWithValidRoles = Dictionary<boolean>;

export interface SnowflakeUsersTabViewProps {
  // SnowflakeRole related
  snowflakeRoleOptions: ListboxOption[];
  snowflakeRoleError: string;
  snowflakeRoleLoading: boolean;

  // User list related
  snowflakeUsers: SnowflakeUser[];
  snowflakeUsersError: string;
  snowflakeUsersLoading: boolean;
  filterRef: React.RefObject<HTMLInputElement>;
  userFilter: string;
  onFilterChange(filter: string): void;

  // Add user related
  addedUserName: string;
  onGenerateUser(newUser: SnowflakeUser): void;
  onCloseCredentialsModal(newUser: SnowflakeUser): void;

  // Edit user related
  editModalUser: SnowflakeUser | null;
  editModalError: string;
  editModalSaving: boolean;
  onEditUser(user: SnowflakeUser): void;
  onCloseEditModal: () => void;
  onEditModalSave: (username: string, newValues: SnowflakeUserSaveableFields) => void;

  // Delete user related
  confirmDeleteUser: string | null;
  deletingUser: string | null;
  onDeleteUser(name: string): void;
  onConfirmDeleteUser(): void;
  onCancelDeleteUser(): void;
}

export default function SnowflakeUsersTabView(props: SnowflakeUsersTabViewProps) {
  const {
    // SnowflakeRole related
    snowflakeRoleOptions,
    snowflakeRoleError,
    snowflakeRoleLoading,

    // User list related
    snowflakeUsers,
    snowflakeUsersError,
    snowflakeUsersLoading,
    filterRef,
    userFilter,
    onFilterChange,

    // Add user related
    addedUserName,
    onGenerateUser,
    onCloseCredentialsModal,

    // Edit user related
    editModalUser,
    editModalError,
    editModalSaving,
    onEditUser,
    onCloseEditModal,
    onEditModalSave,

    // Delete user related
    confirmDeleteUser,
    deletingUser,
    onDeleteUser,
    onConfirmDeleteUser,
    onCancelDeleteUser,
  } = props;

  const filteredUsers = useMemo(
    () =>
      snowflakeUsers.filter((user) => {
        return (
          user.name.toLowerCase().includes(userFilter) ||
          user.first_name.toLowerCase().includes(userFilter) ||
          user.last_name.toLowerCase().includes(userFilter) ||
          user.email.toLowerCase().includes(userFilter) ||
          user.default_role.toLowerCase().includes(userFilter)
        );
      }),
    [snowflakeUsers, userFilter],
  );

  const snowflakeUsersWithValidRoles: SnowflakeUsersWithValidRoles = useMemo(() => {
    const userByName = keyBy(snowflakeUsers, 'name');
    // snowflakeRoleLoading takes much longer to load than the list of users.
    // While still loading snowflakeRoleLoading, fill the list with all true to
    // prevent roles from blinking red.
    const validUsers = snowflakeRoleLoading
      ? mapValues(userByName, () => true)
      : mapValues(userByName, (user) =>
          snowflakeRoleOptions.some((role) => role.value === user.default_role),
        );
    return validUsers;
  }, [snowflakeUsers, snowflakeRoleLoading, snowflakeRoleOptions]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilterChange(event.target.value.toLowerCase());
  };

  const Header = (
    <div className="flex justify-between">
      <div className="f-center">
        <TextInput
          ref={filterRef}
          placeholder="Search"
          name="search"
          value={userFilter}
          onChange={handleFilterChange}
          autoFocus
          className="w-[284px]"
          maxLength={200}
        />
      </div>
      <GenerateSnowflakeUser
        showCredentialsInModal={true}
        givenSnowflakeRoleOptions={snowflakeRoleOptions}
        givenSnowflakeRoleError={snowflakeRoleError}
        givenSnowflakeRoleLoading={snowflakeRoleLoading}
        onGenerateUser={onGenerateUser}
        onCloseCredentialsModal={onCloseCredentialsModal}
      />
    </div>
  );

  return (
    <CenteredContainer
      header={Header}
      loading={snowflakeUsersLoading}
      loadingError={snowflakeUsersError}
    >
      {snowflakeUsers.length === 0 ? (
        <NoObjectsAlert
          className="my-4"
          heading="You don't have any Snowflake users yet."
          detail="Snowflake users created on the Integrations page will show up here."
        />
      ) : filteredUsers.length === 0 ? (
        <NoMatchesFoundAlert heading="No matching users." className="my-4" />
      ) : (
        <SnowflakeUsersTable
          snowflakeUsers={filteredUsers}
          snowflakeUsersWithValidRoles={snowflakeUsersWithValidRoles}
          addedUserName={addedUserName}
          onEditUser={onEditUser}
          onDeleteUser={onDeleteUser}
        />
      )}
      {editModalUser && (
        <UpdateSnowflakeUserModal
          snowflakeUser={editModalUser}
          snowflakeRoleOptions={snowflakeRoleOptions}
          snowflakeRoleError={snowflakeRoleError}
          snowflakeRoleLoading={snowflakeRoleLoading}
          savingError={editModalError}
          saving={editModalSaving}
          onClose={onCloseEditModal}
          onUpdateUser={onEditModalSave}
        />
      )}
      {confirmDeleteUser !== null && (
        <ConfirmModal
          header={
            <span className="text-lg font-medium">
              Are you sure you want to delete the Snowflake user {confirmDeleteUser}?
            </span>
          }
          confirmText="Delete"
          confirmVariant="darkDanger"
          onCancel={onCancelDeleteUser}
          onConfirm={onConfirmDeleteUser}
          saving={deletingUser !== null}
        />
      )}
    </CenteredContainer>
  );
}
