import { ClosableBanner } from '../ClosableBanner/ClosableBanner';

export const USAGE_WARNING_KEY = 'usageWarningLastClosedOn';
export const USAGE_WARNING_EVENT_LOCATION = 'HeaderUsageWarningBanner';

export interface UsageWarningBannerProps {
  scheduleLink: string;
  onClose(): void;
}

export const UsageWarningBanner = (props: UsageWarningBannerProps) => {
  return (
    <ClosableBanner {...props} eventLocation={USAGE_WARNING_EVENT_LOCATION} closeText="Remind Me Later">
      <span>
        <span>You have crossed your monthly usage limit.</span>
        <a
          href={props.scheduleLink}
          rel="noopener noreferrer"
          target="_blank"
          data-track={`${USAGE_WARNING_EVENT_LOCATION} LinkToScheduleCall`}
          className="ml-2 underline hover:pointer"
        >
          Please schedule a call to ensure no impacts to your account.
        </a>
      </span>
    </ClosableBanner>
  );
};

export default UsageWarningBanner;
