/*
Hook that sets URL path, Page Title, and Tab state for tabbed pages.
*/
import { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useTitle } from 'react-use';

import { capitalize } from 'lodash';

import { TabKeyType } from 'components/layouts/parts/TabRow/TabRow';

export default function useTabs(
  tabs: TabKeyType[], // List of valid tab keys
  defaultTab: string, // Use this tab key if the user calls `handleSelectTab()` with a value that is not in `tabs`
  preTabPath: string, // Everything before the tab in the URL. If the tab is located at the URL `/usage/:tab` then this is `/usage/`.
  titleBase: string, // When you change tabs, we change the page title. The title is set to the current tab name prefixed by this value.
  routeTab: string, // The tab from the URL path. The `tab` in the URL `/usage/:tab`.
  eventLocation: string, // The location we use when reporting segment events with analytics.track().
) {
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const history = useHistory();

  // Function that return the defaultTab if an invalid tab was passed in.
  const sanitizeTab = useCallback(
    (unsafeTab: string | null) => {
      const castKey = unsafeTab as string;
      const flatTabs = tabs.map((t) => {
        if (typeof t === 'string') {
          return t;
        } else {
          return t.key;
        }
      });
      return flatTabs.includes(castKey) ? castKey : defaultTab;
    },
    [tabs, defaultTab],
  );

  // At pageload, set the tab based on the URL path.
  useEffect(() => {
    const safeTab = sanitizeTab(routeTab);
    const urlPath = `${preTabPath}${safeTab}`;
    if (history.location.pathname !== urlPath) {
      history.replace(urlPath);
    }
    setCurrentTab(safeTab);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // When the tab changes, change the page title.
  useTitle(`${titleBase} - ${capitalize(currentTab)}`);

  const handleSelectTab = useCallback(
    (tab: string, event?: React.SyntheticEvent<unknown>) => {
      const trackEvent = `${eventLocation} Set${capitalize(tab)}Tab`;
      analytics.track(trackEvent);
      const urlPath = `${preTabPath}${tab}`;
      history.push(urlPath);
      setCurrentTab(tab);
    },
    [history, eventLocation, preTabPath],
  );

  return { currentTab, handleSelectTab };
}
