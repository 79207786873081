import React from 'react';

import cn from 'classnames';
import { Formik, FormikValues } from 'formik';

import Button from 'components/inputs/basic/Button/Button';
import TextAreaInput from 'components/inputs/basic/TextAreaInput/TextAreaInput';

import AITableDescriptionButton from './AITableDescription/AITableDescriptionButton';
import AITableDescriptionWidget from './AITableDescription/AITableDescriptionWidget';

interface EditDescriptionFormProps {
  initialValue: string;
  isSaving: boolean;
  isAIEnabled: boolean;
  generatedText?: string;
  generatedTextError?: any;
  isGeneratingText: boolean;
  onClickAIButton: () => void;
  onCloseAI: () => void;
  onGenerateText: () => void;
  onUseGeneratedTextClick: (eventName: string, updatedDescription?: string) => void;
  onFeedbackClick: (response: string) => void;
  onSubmit: (values: FormikValues) => void;
  onCancel: () => void;
}

const EditDescriptionForm = (props: EditDescriptionFormProps) => {
  const {
    initialValue,
    isSaving,
    isAIEnabled,
    generatedText,
    generatedTextError,
    isGeneratingText,
    onClickAIButton,
    onCloseAI,
    onGenerateText,
    onUseGeneratedTextClick,
    onFeedbackClick,
    onSubmit,
    onCancel,
  } = props;

  const handleCopyText = () => {
    navigator.clipboard.writeText(generatedText ?? '');
    onUseGeneratedTextClick('SummaryTab CopyAIDescription');
  };

  const handleAppendText = (
    currentValue: string,
    setFieldValueFn: (name: string, value: string) => void,
  ) => {
    // Either start appended text in a new paragraph if there is text in the original description
    // or mimic a replace if there is no original description.
    const spacing = currentValue.length > 0 ? '\n\n' : '';
    const updatedDescription = currentValue + spacing + generatedText;
    setFieldValueFn('description', updatedDescription);
    onUseGeneratedTextClick('SummaryTab AppendAIDescription', updatedDescription);
  };

  const handleReplaceText = (setFieldValueFn: (name: string, value: string) => void) => {
    const updatedDescription = generatedText ?? '';
    setFieldValueFn('description', updatedDescription);
    onUseGeneratedTextClick('SummaryTab ReplaceAIDescription', updatedDescription);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        description: initialValue,
      }}
      enableReinitialize
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextAreaInput
            name="description"
            autoFocus
            onFocus={(event: React.FocusEvent<HTMLTextAreaElement>) => {
              // Move cursor to end of textbox hack:
              const length = event.target.value.length;
              event.target.setSelectionRange(length, length);
            }}
            rows={7}
            placeholder="Table Description"
            onChange={handleChange}
            value={values.description}
            className="mb-2"
          />
          <div className={cn('flex', isAIEnabled ? 'flex-col' : 'flex-row justify-between')}>
            {isAIEnabled ? (
              <AITableDescriptionWidget
                onClose={onCloseAI}
                isGeneratingText={isGeneratingText}
                generatedText={generatedText}
                generatedTextError={generatedTextError}
                onGenerateText={onGenerateText}
                onCopyText={handleCopyText}
                onAppendText={() => handleAppendText(values.description, setFieldValue)}
                onReplaceText={() => handleReplaceText(setFieldValue)}
                onFeedbackClick={onFeedbackClick}
              />
            ) : (
              <AITableDescriptionButton onClick={onClickAIButton} />
            )}
            <div className="flex">
              <Button
                type="submit"
                variant="lightAction"
                disabled={values.description === initialValue}
                spinning={isSaving}
                className="mr-2"
              >
                Save
              </Button>
              <Button variant="lightDanger" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default EditDescriptionForm;
