import React from 'react';

import API from 'api/API';
import { AggTable } from 'api/APITypes';
import ApiIconButton, {
  useApiIconState,
  ExtendersProps,
} from 'components/inputs/basic/Button/ApiIconButton';
import { downloadFile } from 'utils/React';
import { eventType } from 'utils/String';

interface ExportCsvIconProps extends ExtendersProps {
  table: AggTable;
}

const ExportCsvIcon = (props: ExportCsvIconProps) => {
  const { spinning, setSpinning, errorState, setErrorState } = useApiIconState();
  const { table, eventPage } = props;
  const { schema, name, id, type } = table;

  const handleExportCsv = async () => {
    if (spinning) {
      return;
    }

    const api = new API();
    setSpinning(true);

    let postData = {
      table_id: id,
    };

    try {
      const response = await api.post('api/csv_export/table', postData);

      if (response.data.error_lines) {
        const errorText = response.data.error_lines.map((line: string, i: number) => {
          return <p key={i}>{line}</p>;
        });
        setErrorState({
          show: true,
          header: 'SQL Error',
          body: errorText,
        });
      } else {
        analytics.track(`${eventPage} Export${eventType(type)}CSV`);
        const fileName = `${schema}_${name}.csv`;
        downloadFile(fileName, response.data);
      }
    } catch (error) {
      setErrorState({
        show: true,
        header: 'Server Error',
        body: 'There was a problem exporting the query.',
      });
    } finally {
      setSpinning(false);
    }
  };

  return (
    <ApiIconButton
      icon="Download"
      text="CSV"
      size="small"
      variant="lightDullTransparent"
      spinning={spinning}
      errorState={errorState}
      setErrorState={setErrorState}
      onClick={handleExportCsv}
      tip="Export to CSV"
    />
  );
};

export default ExportCsvIcon;
