/*
The CompanySetupGuide shows users a list of things the first user at a company needs to do to get the most out of Mozart.
It can be rendered beside the content of pages if the page content is narrow OR
on top of page content if the page content is very wide.
*/
import React from 'react';
import { useContext } from 'react';

import cn from 'classnames';

// import BackButton from 'components/inputs/basic/Button/BackButton';
import IconButton from 'components/inputs/basic/Button/IconButton';
import { APIStepKey, CompanySetupGuideContext, UIStepKey } from 'model_layer/CompanySetupGuideContext';

import CompleteSetupBox from './CompleteSetupBox/CompleteSetupBox';
import GuideStep from './GuideStep/GuideStep';
import { VIDEO_WIDTH } from './GuideStep/SetupMethodInstructions/SetupMethodInstructions';
import guideSteps from './guideSteps';
import UsefulLinks from './UsefulLinks/UsefulLinks';

const PADDING = 16;
const totalPadding = PADDING * 2;
export const MIN_WIDTH = 245; // Things get crunched below this width
export const MAX_WIDTH = 550;
export const STARTING_WIDTH = VIDEO_WIDTH + totalPadding;

interface CompanySetupGuideProps {}

export default React.memo(function CompanySetupGuide(props: CompanySetupGuideProps) {
  const {
    steps,
    openStep,
    currentTab,
    setupComplete,
    setShow,
    setStep,
    setOpenStep,
    setCurrentTab,
    onComplete,
  } = useContext(CompanySetupGuideContext);

  const handleToggleOpenStep = (step: UIStepKey | null) => {
    setOpenStep(openStep === null ? step : null);
    analytics.track(openStep === null ? 'CompanySetupGuide OpenStep' : 'CompanySetupGuide CloseStep', {
      step: step,
    });
  };

  const handleSetTab = (tab: string) => {
    setCurrentTab(tab);
    analytics.track('CompanySetupGuide SetTab', {
      tab,
    });
  };

  const handleToggleComplete = (step: UIStepKey) => {
    // You cannot complete useful_links. Do nothing.
    if (step === 'useful_links') {
      return;
    }

    const newComplete = !steps[step];

    setStep(step, newComplete).then(() => {
      // Close a step after you complete it.
      if (newComplete) {
        setOpenStep(null);

        // If the setup guide renders as a sidebar,
        // the main window might be scrolled such that when the current
        // GuideStep collapses no content is visible.
        // So, scroll back to the top of the page.
        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }

      analytics.track(
        newComplete ? 'CompanySetupGuide MarkStepComplete' : 'CompanySetupGuide MarkStepIncomplete',
        {
          step: step,
        },
      );
    });
  };

  const handleHideGuide = () => {
    setShow(false);
    analytics.track('CompanySetupGuide HideGuide');
  };

  const handleCompleteSetup = () => {
    onComplete();
    analytics.track('CompanySetupGuide CompleteSetup');
  };

  const showStep = (step: UIStepKey) => openStep === null || openStep === step;

  return (
    <div
      className={cn(
        'w-full h-full flex flex-col flex-grow items-start pt-4 bg-pri-gray-100 text-pri-gray-700',
        {
          'pb-5': openStep === null,
        },
      )}
    >
      <div className="w-full px-5 f-between flex-none">
        <div className="text-large font-medium">Company Setup Guide</div>
        <IconButton
          icon="XLg"
          variant="lightDullTransparent"
          className="relative left-4"
          onClick={handleHideGuide}
          size="small"
        />
      </div>
      {setupComplete && openStep === null && (
        <div className="w-full px-4 pt-4">
          <CompleteSetupBox onComplete={handleCompleteSetup} />
        </div>
      )}
      <div className="w-full h-[1px] mt-4 flex-none bg-white"></div>
      {guideSteps.map(
        (step) =>
          showStep(step.key) && (
            <GuideStep
              key={step.key}
              number={step.number}
              label={step.label}
              detail={step.detail}
              checked={!!steps[step.key as APIStepKey]}
              open={openStep === step.key}
              currentTab={currentTab}
              instructions={step.instructions}
              toggleOpen={() => handleToggleOpenStep(step.key)}
              setCurrentTab={handleSetTab}
              toggleComplete={() => handleToggleComplete(step.key)}
            />
          ),
      )}
      {showStep('useful_links') && (
        <UsefulLinks
          isOpen={openStep === 'useful_links'}
          onToggleOpenStep={() => handleToggleOpenStep('useful_links')}
        />
      )}
    </div>
  );
});
