import { DbtRun } from 'api/dbtAPI';
import PageButtons from 'components/inputs/basic/Button/PageButtons';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';

import RunTableRow from './RunTableRow';
import { PaginatedDbtRuns } from './usePaginatedRuns';

interface RunTableProps {
  paginatedRuns: PaginatedDbtRuns;
  onLoadPreviousPage(): void;
  onLoadNextPage(): void;
  onViewLogs(run: DbtRun): void;
}

const RunTable = (props: RunTableProps) => {
  const { paginatedRuns, onLoadPreviousPage, onLoadNextPage, onViewLogs } = props;

  return (
    <div>
      <div className="h-[36px] f-between mt-2">
        <h4 className="text-base font-medium text-sec-blue-gray-700">Run History</h4>
        <PageButtons
          hasPreviousPage={!!paginatedRuns.previous}
          hasNextPage={!!paginatedRuns.next}
          onLoadPreviousPage={onLoadPreviousPage}
          onLoadNextPage={onLoadNextPage}
        />
      </div>
      {paginatedRuns.results.length === 0 && (
        <FixMeAlert
          heading="This dbt job has never been run."
          detail={
            <p className="max-w-[500px] mt-2">
              Click the <code className="text-pri-gray-700">Run Manually</code> button to run it.
            </p>
          }
          alertClass="mt-2"
        />
      )}
      {paginatedRuns.results.length > 0 && (
        <table className="lineTable mt-2">
          <thead>
            <tr className="whitespace-nowrap">
              <th>Start Time</th>
              <th>End Time</th>
              <th>
                <div className="f-center">Status</div>
              </th>
              <th>Duration</th>
              <th>Scheduled</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {paginatedRuns.results.map((run) => (
              <RunTableRow key={run.id} run={run} onViewLogs={onViewLogs} />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default RunTable;
