/**
 * Composes the RunResults and its associated toolbars into one component.
 */
import React, { useState, useEffect, useCallback } from 'react';

import { QueryRunResults } from 'api/APITypes';

import RunResults, { TimeFormat } from '../RunResults/RunResults';

import AdvancedRunResultsOptions from './AdvancedRunResultsOptions';
import Columnizer from './Columnizer/Columnizer';
import ResultsControlBar from './ResultsControlBar';

interface ResultsPaneProps {
  running: boolean;
  exportingCsv: boolean;
  exportingGSheet: boolean;
  runErrorLines: string[];
  runResults: QueryRunResults | null;
  lastRunSql: string;
  isTransform: boolean;
  isShown: boolean;
  editMode: boolean;

  onCopyToClipboard: () => void;
  onExportCsv: () => void;
  onExportGSheet: () => void;
  onMaximizeRunResults: () => void;
  onMinimizeRunResults: () => void;
  onSetEditorHeightHalfway: () => void;
  setEditorSql: (sql: string, byUser: boolean) => void;
}

export default React.memo(function ResultsPane(props: ResultsPaneProps) {
  const {
    running,
    exportingCsv,
    exportingGSheet,
    runErrorLines,
    runResults,
    lastRunSql,
    isTransform,
    isShown,
    editMode,
    onCopyToClipboard,
    onExportCsv,
    onExportGSheet,
    onMaximizeRunResults,
    onMinimizeRunResults,
    onSetEditorHeightHalfway,
    setEditorSql,
  } = props;

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [timeFormat, setTimeFormat] = useState<TimeFormat>('TIMESTAMP');
  const [maxLines, setMaxLines] = useState<number>(1);
  const [hideEmptyColumns, setHideEmptyColumns] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [hideUnselectedColumns, setHideUnselectedColumns] = useState(false);

  useEffect(() => {
    setSelectedColumns([]);
    setHideUnselectedColumns(false);
  }, [runResults]);

  const handleToggleAdvanced = useCallback(() => {
    setShowAdvanced(!showAdvanced);
  }, [showAdvanced]);

  const handleToggleColumn = useCallback(
    (column: string) => {
      if (selectedColumns.includes(column)) {
        analytics.track('Columnizer UnselectColumn');
        const newColumns = selectedColumns.filter((c) => c !== column);
        if (newColumns.length === 0) {
          setHideUnselectedColumns(false);
        }
        setSelectedColumns(newColumns);
      } else {
        analytics.track('Columnizer SelectColumn');
        setSelectedColumns([...selectedColumns, column]);
      }
    },
    [selectedColumns],
  );

  const handleUnselectColumn = useCallback(
    (column: string) => {
      // Do not analytics.track() in this method.
      // Put analytics.track() in this method's caller.
      const newColumns = selectedColumns.filter((c) => c !== column);
      if (newColumns.length === 0) {
        setHideUnselectedColumns(false);
      }
      setSelectedColumns(newColumns);
    },
    [selectedColumns],
  );

  const handleInvertColumns = useCallback(() => {
    analytics.track('Columnizer InvertColumns');
    const allColumns = runResults ? runResults.columns.map((c) => c.name) : [];
    const newColumns = allColumns.filter((c) => !selectedColumns.includes(c));
    if (newColumns.length === 0) {
      setHideUnselectedColumns(false);
    }
    setSelectedColumns(newColumns);
  }, [runResults, selectedColumns]);

  const handleSelectAllColumns = useCallback(() => {
    // Do not analytics.track() in this method.
    // Put analytics.track() in this method's caller.

    // Maintain selected order if some columns were already selected.
    const allColumns = [...selectedColumns];

    // Append unselected columns to the end.
    const allPossibleColumns = runResults ? runResults.columns.map((c) => c.name) : [];
    allPossibleColumns.forEach((c) => {
      if (!allColumns.includes(c)) {
        allColumns.push(c);
      }
    });
    setSelectedColumns(allColumns);
  }, [runResults, selectedColumns]);

  const handleUnselectAllColumns = useCallback(() => {
    // Do not analytics.track() in this method.
    // Put analytics.track() in this method's caller.

    setSelectedColumns([]);
    setHideUnselectedColumns(false);
  }, []);

  const handleToggleAllColumns = useCallback(() => {
    if (runResults && selectedColumns.length < runResults.columns.length) {
      analytics.track('Columnizer SelectAllColumns');
      handleSelectAllColumns();
    } else {
      analytics.track('Columnizer UnselectAllColumns');
      handleUnselectAllColumns();
    }
  }, [runResults, selectedColumns, handleSelectAllColumns, handleUnselectAllColumns]);

  const handleDropColumn = useCallback(
    (draggedColumn: string, droppedOnColumn) => {
      // Do not allow dropping on myself
      if (draggedColumn === droppedOnColumn) {
        return;
      }
      analytics.track('Columnizer DropColumnTag');

      // Rearrange the list in the new drag order.
      const draggedWasBeforeDroppedOn =
        selectedColumns.indexOf(draggedColumn) < selectedColumns.indexOf(droppedOnColumn);
      const noDropped = selectedColumns.filter((c) => c !== draggedColumn);
      const droppedOnIndex = noDropped.indexOf(droppedOnColumn);
      const before = noDropped.slice(0, droppedOnIndex);
      const after = noDropped.slice(droppedOnIndex + 1);
      const insert = draggedWasBeforeDroppedOn
        ? [droppedOnColumn, draggedColumn]
        : [draggedColumn, droppedOnColumn];
      const newOrder = [...before, ...insert, ...after];
      setSelectedColumns(newOrder);
    },
    [selectedColumns],
  );

  return (
    <div id="ResultsPane" className="h-full flex flex-col">
      <ResultsControlBar
        running={running}
        exportingCsv={exportingCsv}
        exportingGSheet={exportingGSheet}
        runResults={runResults}
        lastRunSql={lastRunSql}
        isTransform={isTransform}
        showAdvanced={showAdvanced}
        onCopyToClipboard={onCopyToClipboard}
        onExportCsv={onExportCsv}
        onExportGSheet={onExportGSheet}
        onToggleAdvanced={handleToggleAdvanced}
        onMaximizeRunResults={onMaximizeRunResults}
        onMinimizeRunResults={onMinimizeRunResults}
        onSetEditorHeightHalfway={onSetEditorHeightHalfway} // Used to expand the results when view settings button is clicked
      />
      {showAdvanced && (
        <AdvancedRunResultsOptions
          timeFormat={timeFormat}
          setTimeFormat={setTimeFormat}
          maxLines={maxLines}
          setMaxLines={setMaxLines}
          hideEmptyColumns={hideEmptyColumns}
          setHideEmptyColumns={setHideEmptyColumns}
        />
      )}
      {selectedColumns.length > 0 && (
        <Columnizer
          selectedColumns={selectedColumns}
          hideUnselectedColumns={hideUnselectedColumns}
          onUnselectColumn={handleUnselectColumn}
          onInvertColumns={handleInvertColumns}
          onUnselectAllColumns={handleUnselectAllColumns}
          onDropColumn={handleDropColumn}
          setHideUnselectedColumns={setHideUnselectedColumns}
        />
      )}
      {runResults && runResults.rows.length >= 2000 && (
        <div className="text-gray-400 px-2 pb-2 bg-white border-x" style={{ fontSize: '0.9rem' }}>
          Mozart only returns the first 2000 rows.
        </div>
      )}
      <RunResults
        loading={running}
        lastRunSql={lastRunSql}
        runErrorLines={runErrorLines}
        runResults={runResults}
        timeFormat={timeFormat}
        maxLines={maxLines}
        hideEmptyColumns={hideEmptyColumns}
        selectedColumns={selectedColumns}
        hideUnselectedColumns={hideUnselectedColumns}
        isShown={isShown}
        onToggleColumn={handleToggleColumn}
        onToggleAllColumns={handleToggleAllColumns}
        editMode={editMode}
        setEditorSql={setEditorSql}
      />
    </div>
  );
});
