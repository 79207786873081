import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { AggTable } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';
import { useUserProfile } from 'context/AuthContext';
import useDataAlerts, { alertsHelpURL } from 'hooks/useDataAlerts';

import CreateDataAlertModal from './CreateDataAlertModal';
import { getLastRunMessage } from './shared/DataAlertHelper';
import DataAlertTable from './shared/DataAlertTable/DataAlertTable';
import EditDataAlertModal from './shared/EditDataAlertModal';
import FailureLogsModal from './shared/FailureLogsModal';

interface DataAlertsTabProps {
  table: AggTable;
}

type LocationState = { createAlert?: boolean };

export default function DataAlertsTab(props: DataAlertsTabProps) {
  const { table } = props;
  const { userProfile } = useUserProfile();

  const {
    loading,
    saving,
    errorMessage,
    dataAlerts,
    selectedDataAlert,
    updateErrorMessage,
    createErrorMessage,
    failureLogs,
    failureLogsErrorLines,
    failureLogsDataAlert,
    loadingFailureLogs,
    showConfirmModal,
    createModalOpen,
    setUpdateErrorMessage,
    handleUpdateDataAlert,
    handleDeleteDataAlert,
    handleCreateDataAlert,
    handleSetUnsavedSql,
    handleCloseConfirmModal,
    handleConfirmConfirmModal,
    handleOpenCreateModal,
    handleCloseCreateModal,
    handleCloseDataAlertModal,
    handleSelectDataAlert,
    handleCloseFailureLogsModal,
    handleGetFailureLogs,
  } = useDataAlerts({
    eventPage: 'TableTestTab',
    table: table,
  });

  const location = useLocation<LocationState>();
  useEffect(() => {
    const createAlert = location.state?.createAlert;
    if (createAlert) {
      handleOpenCreateModal();
      // Clear location state so that the modal doesn't reopen on refresh
      window.history.replaceState({}, '');
    }

    location.state = {};
  }, [location, handleOpenCreateModal]);

  const lastRunData = getLastRunMessage(table, 'text-base font-medium text-sec-blue-gray-700');
  const sortedAlerts = dataAlerts.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <TabLayout loading={loading} error={errorMessage}>
      {selectedDataAlert && (
        <EditDataAlertModal
          table={table}
          dataAlert={selectedDataAlert}
          saving={saving}
          updateErrorMessage={updateErrorMessage}
          showConfirmModal={showConfirmModal}
          setUpdateErrorMessage={setUpdateErrorMessage}
          onCloseDataAlertModal={handleCloseDataAlertModal}
          onUpdateDataAlert={handleUpdateDataAlert}
          onSetUnsavedSql={handleSetUnsavedSql}
          onCloseConfirmModal={handleCloseConfirmModal}
          onConfirmConfirmModal={handleConfirmConfirmModal}
        />
      )}
      <CreateDataAlertModal
        createModalOpen={createModalOpen}
        table={table}
        createErrorMessage={createErrorMessage}
        saving={saving}
        showConfirmModal={showConfirmModal}
        onCloseCreateModal={handleCloseCreateModal}
        onCreateDataAlert={handleCreateDataAlert}
        onUpdateDataAlert={handleUpdateDataAlert}
        onSetUnsavedSql={handleSetUnsavedSql}
        onCloseConfirmModal={handleCloseConfirmModal}
        onConfirmConfirmModal={handleConfirmConfirmModal}
      />
      <FailureLogsModal
        loadingFailureLogs={loadingFailureLogs}
        failureLogs={failureLogs}
        failureLogsErrorLines={failureLogsErrorLines}
        failureLogsDataAlert={failureLogsDataAlert}
        table={table}
        onCloseFailureLogsModal={handleCloseFailureLogsModal}
      />
      <div>
        <div className="f-between pb-4">
          {lastRunData}
          {userProfile.company_role !== 'viewer' && (
            <Button variant="darkDullAction" size="small" onClick={handleOpenCreateModal}>
              Create Alert
            </Button>
          )}
        </div>
        {dataAlerts.length > 0 ? (
          <div className="mb-[90px] shadow-sm">
            <DataAlertTable
              dataAlerts={sortedAlerts}
              isOverallAlertsPage={false}
              isTransformOnTablePage={table.type === 'transform'}
              analyticsLocation="TableTestTab"
              rowProps={{
                onUpdateDataAlert: handleUpdateDataAlert,
                onDeleteDataAlert: handleDeleteDataAlert,
                onSelectDataAlert: handleSelectDataAlert,
                onGetFailureLogs: handleGetFailureLogs,
              }}
            />
          </div>
        ) : (
          <NoObjectsAlert
            detail={
              <p className="font-medium">
                {userProfile.company_role === 'viewer'
                  ? 'No Data Alerts. Viewers cannot create Data Alerts. Please contact an admin to create a Data Alert.'
                  : "You haven't set up an alert."}{' '}
                Learn how to create an alert{' '}
                <a
                  href={alertsHelpURL}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-sec-blue-light-700"
                >
                  here
                </a>
                .
              </p>
            }
          />
        )}
      </div>
    </TabLayout>
  );
}
