/*
  VSCode style tab row which allows scrolling, adding, deleting, and moving of tabs.
*/
import React, { useRef, useEffect } from 'react';

import { X } from 'react-bootstrap-icons';

import cn from 'classnames';

import { Tab } from './MutableTabBar';

interface MutableTabProps {
  tabs: Tab[];
  tab: Tab;
  selectedTabId: string;
  editTabName: boolean; // If the selected tab's name is being edited
  draggingTabId: string;
  dragEnteredId: string;
  onClickTab: (tabId: string) => void;
  onDragStart: (tabId: string, event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnd: () => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnter: (tabId: string) => void;
  onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (droppedOnTabId: string, event: React.DragEvent<HTMLDivElement>) => void;
  onRemoveTab: (tabId: string, track?: boolean) => void;
  onChangeName: (tabId: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseEditNameMode: () => void;
}

const MutableTab = (props: MutableTabProps) => {
  const {
    tabs,
    tab,
    selectedTabId,
    editTabName,
    draggingTabId,
    dragEnteredId,
    onClickTab,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
    onRemoveTab,
    onChangeName,
    onCloseEditNameMode,
  } = props;

  const tabRef = useRef<HTMLDivElement>(null);

  // Once a tab appears, auto-scroll to it if it is selected (new tabs are selected when they are added to the list)
  useEffect(() => {
    if (tabRef.current && tab.id === selectedTabId) {
      tabRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [tabRef, selectedTabId, tab.id]);

  const isRenaming = tab.id === selectedTabId && editTabName;
  const canDrag = !isRenaming && tabs.length > 1;

  const className = cn(
    'h-[40px] p-2 f-between font-medium text-sm border-x border-inherit first:border-l-0 last:border-r-0',
    {
      'opacity-50 cursor-grabbing': tab.id === draggingTabId,
      'bg-pri-gray-50': tab.id === dragEnteredId,
      'bg-white text-sec-blue-gray-500': selectedTabId === tab.id && tab.id !== draggingTabId,
      'cursor-grab': selectedTabId === tab.id && tab.id !== draggingTabId && canDrag,
      'bg-pri-gray-100 cursor-pointer hover:bg-pri-gray-50':
        selectedTabId !== tab.id && tab.id !== dragEnteredId,
      'cursor-pointer': !canDrag,
    },
  );

  return (
    <div
      ref={tabRef}
      className={className}
      onClick={() => onClickTab(tab.id)}
      draggable={canDrag}
      onDragStart={(e) => onDragStart(tab.id, e)}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDragEnter={() => onDragEnter(tab.id)}
      onDragLeave={onDragLeave}
      onDrop={(e) => onDrop(tab.id, e)}
      onMouseDown={(e) => {
        if (e.button === 1 && tabs.length > 1) {
          // Close if the middle button was clicked on a tab and it was not the last tab
          onRemoveTab(tab.id, true);
        }
      }}
    >
      {isRenaming ? (
        <div className="h-[20px] p-0 border-none" style={{ width: tab.name.length + 1 + 'ch' }}>
          <input
            type="text"
            name="tabName"
            value={tab.name}
            onChange={(e) => onChangeName(tab.id, e)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onCloseEditNameMode();
              }
            }}
            autoFocus={true}
            onBlur={onCloseEditNameMode}
            className="h-full w-full p-0 text-sm border-none focus:ring-transparent"
          />
        </div>
      ) : (
        <div className="min-w-[42px] f-row-y-center whitespace-nowrap">{tab.name}</div>
      )}
      {tabs.length > 1 && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemoveTab(tab.id);
          }}
          className="ml-2 f-row-y-center cursor-pointer hover:bg-pri-gray-200"
        >
          <X size="24" color="var(--pri-gray-400)" />
        </button>
      )}
    </div>
  );
};

export default MutableTab;
