import TextInput from 'components/inputs/basic/TextInput/TextInput';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';

import { Scope } from '../useHistoricalResyncState';
import { SchemasAndTablesToSyncState } from '../useSchemasAndTablesToSyncState';

import ToggleableSchema from './ToggleableSchema';

// TODO: John: Ensure that we uncheck startDisabledTables by default for new Shopify connectors.

export default function SchemasAndTablesToSync(props: {
  schemasAndTablesToSync: SchemasAndTablesToSyncState;
  startDisabledTables: string[] | null;
  savingDisabled: boolean;
  saving: boolean;
  openHistoricalResyncModal: (newScope?: Scope) => void;
  resyncSpinning: boolean;
  resyncDisabled: boolean;
}) {
  const {
    schemasAndTablesToSync: {
      schemasToDisplay,
      filter,
      schemasExpanded,
      onFilterChange,
      onToggleExpandSchema,
      onToggleExpandTable,
      onToggleSchema,
      onToggleTable,
      onToggleEnableColumn,
      onToggleHashColumn,
    },
    openHistoricalResyncModal,
    resyncSpinning,
    resyncDisabled,
    savingDisabled,
    saving,
    startDisabledTables,
  } = props;

  return (
    <div className="mt-[24px]">
      <h5 className="text-sm font-medium">Schemas and Tables to Sync</h5>{' '}
      <div className="mt-1">
        <TextInput
          name="table-filter"
          placeholder="Filter schemas and tables"
          value={filter}
          onChange={onFilterChange}
          style={{ width: '250px' }}
          className="mt-2"
        />
      </div>
      <div className="mt-2" />
      {Object.keys(schemasToDisplay).length === 0 ? (
        <NoMatchesFoundAlert heading="No matching schemas or tables." />
      ) : (
        Object.entries(schemasToDisplay).map(([schemaName, schema]) => {
          const tables = schema.tables || {};
          return (
            <ToggleableSchema
              key={schemaName}
              schemaName={schemaName}
              schema={schema}
              tables={tables}
              schemaExpanded={schemasExpanded[schemaName]}
              onToggleExpandSchema={onToggleExpandSchema}
              onToggleSchema={onToggleSchema}
              onToggleExpandTable={onToggleExpandTable}
              onToggleTable={onToggleTable}
              onToggleEnableColumn={onToggleEnableColumn}
              onToggleHashColumn={onToggleHashColumn}
              onOpenHistoricalResyncModal={openHistoricalResyncModal}
              resyncDisabled={resyncDisabled}
              resyncSpinning={resyncSpinning}
              savingDisabled={savingDisabled}
              saving={saving}
              startDisabledTables={startDisabledTables}
            />
          );
        })
      )}
    </div>
  );
}
