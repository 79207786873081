import { CaretRight, CaretRightFill } from 'react-bootstrap-icons';

import classNames from 'classnames';

import { SocketType } from '../../model/FlowchartQueryModel';

import { VERTEX_HEIGHT, COLORS } from '../Flowchart.styles';
import { getVertexStyle } from '../utils/graphUtils';

const SOCKET_SIZE = 18;

export interface SocketProps {
  type: SocketType;
  plugged: boolean; // Is an edge connected to this vertex at this socket?
}

const Socket = (props: SocketProps) => {
  const { type, plugged } = props;
  const halfSocket = SOCKET_SIZE / 2;

  const style: React.CSSProperties = {};

  // Hand tune these by look because icons don't look centered at their center point.
  // Remember the parent has padding when doing math.
  const childShift = '-9px';
  const parentShift = '-10px';

  if (type === 'child') {
    style.right = childShift;
  } else {
    style.left = parentShift;
  }

  // Our current icon is not 100% centered.
  // Tweak it to look better
  const SHIFT = -1;

  if (type === 'single' || type === 'child') {
    style.top = `${VERTEX_HEIGHT / 2 - halfSocket + SHIFT}px`;
  } else if (type === 'left') {
    style.top = `${VERTEX_HEIGHT / 3 - halfSocket + SHIFT}px`;
  } else if (type === 'right') {
    style.top = `${(VERTEX_HEIGHT * 2) / 3 - halfSocket + 2 * SHIFT}px`;
  }

  const fillColor = plugged ? COLORS.PRI_SUCCESS_200 : COLORS.PRI_WARNING_200;

  return (
    <div className={classNames('absolute z-[100]')} style={style}>
      <CaretRight color={getVertexStyle().color} size={SOCKET_SIZE} className="absolute z-[1]" />
      <CaretRightFill color={fillColor} size={SOCKET_SIZE} className="absolutez-[0]" />
    </div>
  );
};

export default Socket;
