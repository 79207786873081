import React, { useEffect, useState } from 'react';

import { format, lastDayOfMonth, addMonths, differenceInDays } from 'date-fns';
import { pick } from 'lodash';

import { TODAY } from 'pages/UsageV2/useUsageFetch';
import { parseApiDate } from 'utils/dateTime';
import { formatNumber, formatToDollarAmount } from 'utils/String';

import { UsageMode } from '../layout/LayoutModel';

import { cycleFormat } from './BillingCycleDates';
import CostPieChart, { COST_LABELS_MAP } from './CostPieChart';

interface PlanSummaryProps {
  currentInvoice?: any;
  mode: UsageMode;
}

const PlanSummaryLayout: any = {
  Allotment: (props: PlanSummaryProps) => {
    const { currentInvoice, mode } = props;
    const [daysUntilBillingCycleEnd, setDaysUntilBillingCycleEnd] = useState('');
    const [billingCycleEndDate, setBillingCycleEndDate] = useState('');

    useEffect(() => {
      if (currentInvoice) {
        const lastDayOfBillingCycle =
          currentInvoice['RECENT_ALLOTMENT_GRAIN'] === 'Annual'
            ? lastDayOfMonth(
                addMonths(parseApiDate(currentInvoice['RECENT_ANNUAL_START_DATE']) as Date, 11),
              )
            : lastDayOfMonth(parseApiDate(currentInvoice['MONTH_START']) as Date);
        const formattedLastDay = format(lastDayOfBillingCycle as Date, cycleFormat);
        setBillingCycleEndDate(formattedLastDay);

        const today = new Date();
        const daysUntilBillingCycleEnd = formatNumber(
          differenceInDays(lastDayOfBillingCycle, today) + 1,
        ) as string;
        setDaysUntilBillingCycleEnd(daysUntilBillingCycleEnd);
      }
    }, [currentInvoice]);

    const planType = currentInvoice['RECENT_ALLOTMENT_GRAIN'];
    const marAllotment =
      planType === 'Annual'
        ? currentInvoice['SUM_FIVETRAN_MAR_ALLOTMENT'] * 12
        : currentInvoice['SUM_FIVETRAN_MAR_ALLOTMENT'];
    const creditAllotment =
      planType === 'Annual'
        ? currentInvoice['SUM_SNOWFLAKE_CREDITS_ALLOTMENT'] * 12
        : currentInvoice['SUM_SNOWFLAKE_CREDITS_ALLOTMENT'];

    const hasOverageCosts =
      currentInvoice['SUM_SNOWFLAKE_OVERAGE_NET'] + currentInvoice['SUM_FIVETRAN_OVERAGE_NET'] > 0;

    return (
      <div className="flex flex-col justify-between w-[260px] h-[300px]">
        {hasOverageCosts && mode !== 'annual' ? (
          <span>
            <h2 className="text-xl font-medium">Current cost</h2>
            <p className="display-lg font-medium text-sec-purple-700 py-2">
              ${formatToDollarAmount(currentInvoice['SUM_MONTHLY_INVOICE'])}
            </p>
            <p>as of {format(TODAY, cycleFormat)}</p>
          </span>
        ) : (
          <span>
            <h2 className="text-xl font-medium">Days remaining</h2>
            <p className="display-lg font-medium text-sec-purple-700 py-2">
              {daysUntilBillingCycleEnd || '0'}
            </p>
            <p>days to {billingCycleEndDate}</p>
          </span>
        )}
        <div className="mt-10 ">
          {hasOverageCosts && mode !== 'annual' && (
            <div className="mb-2">
              <p className="uppercase text-sm font-bold text-sec-purple-700">Monthly Base Cost</p>$
              {formatToDollarAmount(currentInvoice['SUM_MONTHLY_AMOUNT'])}
            </div>
          )}
          <p className="uppercase text-sm font-bold text-sec-purple-700">{planType} MAR</p>
          {formatNumber(marAllotment)}
          <p className="uppercase text-sm font-bold text-sec-purple-700 mt-2">
            {planType} COMPUTE CREDITS
          </p>
          {formatNumber(creditAllotment)}
        </div>
      </div>
    );
  },
  Cost: (props: PlanSummaryProps) => {
    const { currentInvoice } = props;
    const costsColumns = pick(currentInvoice, Object.keys(COST_LABELS_MAP));
    // @ts-ignore
    const costTotal = Object.entries(costsColumns).reduce((acc, [, value]) => acc + +value, 0);
    const platformFee = currentInvoice['PLATFORM_FEE_MOST_RECENT'];
    return (
      <div className="flex flex-col justify-between w-[388px] h-[480px]">
        <span>
          <h2 className="text-xl font-medium">Current cost</h2>
          <p className="display-lg font-medium text-sec-purple-700 py-2">
            ${formatToDollarAmount(currentInvoice['SUM_MONTHLY_INVOICE'])}
          </p>
          <p>as of {format(TODAY, cycleFormat)}</p>
          {platformFee && platformFee > 0 && (
            <p>
              (includes <span className="text-sec-purple-700">${formatToDollarAmount(platformFee)}</span>{' '}
              platform fee)
            </p>
          )}
        </span>
        <div className="flex flex-col justify-center">
          <h3 className="text-xl font-medium">Cost breakdown </h3>
          <div className="flex">
            <CostPieChart currentInvoice={currentInvoice} />
            <ul className="mt-4">
              {Object.entries(costsColumns).map(([key, value]) => (
                <li
                  className="border-b border-solid border-pri-gray-200 py-2 font-medium"
                  style={{ color: COST_LABELS_MAP[key].color }}
                >
                  {/* @ts-ignore */}
                  {COST_LABELS_MAP[key].label} {((value / costTotal) * 100).toFixed(2)}%
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  },
  Sonata: (props: PlanSummaryProps) => {
    return (
      <div className="flex flex-col justify-between w-[388px] h-[268px]">
        <span>
          <h2 className="text-xl font-medium">Current Plan</h2>
          <p className="display-lg font-medium text-sec-purple-700 py-2">Sonata</p>
        </span>
        <div className="mt-10 ">
          <p className="uppercase text-sm font-bold text-sec-purple-700">MONTHLY MAR ALLOTMENT</p>
          {formatNumber(props.currentInvoice['SUM_FIVETRAN_MAR_ALLOTMENT'])}
          <p className="uppercase text-sm font-bold text-sec-purple-700 mt-2">
            MONTHLY COMPUTE CREDITS ALLOTMENT
          </p>
          {formatNumber(props.currentInvoice['SUM_SNOWFLAKE_CREDITS_ALLOTMENT'])}
        </div>
      </div>
    );
  },
};

const PlanSummary = (props: PlanSummaryProps) => {
  const { mode } = props;

  let layoutMode = 'Allotment';
  if (mode === 'cost') {
    layoutMode = 'Cost';
  } else if (mode === 'sonata') {
    layoutMode = 'Sonata';
  }

  return (
    <div className="max-w-[388px] h-fit flex basis-1/2 mr-4 p-4 bg-sec-purple-25 ">
      {PlanSummaryLayout?.[layoutMode]?.(props) || ''}
    </div>
  );
};

export default PlanSummary;
