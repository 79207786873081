import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import { uniqBy } from 'lodash';

import { DbtRunConfig, cleanDbtRepository } from 'api/dbtAPI';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import { calcGenericRunStatus, displaySchedule } from 'model_helpers/dbtRunConfigHelper';
import { warehouseSearch } from 'utils/PathMaker';

interface DBTRunConfigTableProps {
  runConfigs: DbtRunConfig[];
}

function DBTRunConfigTable(props: DBTRunConfigTableProps) {
  const { runConfigs } = props;

  const showRepoColumn = useMemo(() => {
    // rc.dbt_repository.id does not appear to be the same for multiple runConfigs that use the same repo
    // so use git_repository_name instead.
    // This data anomaly exists on my dev box, but not on our prod account.
    const uniqRunConfigs = uniqBy(runConfigs, (rc) => rc.dbt_repository.git_repository_name);
    return uniqRunConfigs.length > 1;
  }, [runConfigs]);

  return (
    <table className="lineTable">
      <thead>
        <tr className="whitespace-nowrap">
          <th>Job</th>
          {showRepoColumn && <th>Git Repo</th>}
          <th>Schedule</th>
          <th>
            <div className="f-center">Last Run Status</div>
          </th>
          <th>Target Schema</th>
        </tr>
      </thead>
      <tbody>
        {runConfigs.map((runConfig) => {
          const { id, name, target_schema, dbt_repository } = runConfig;
          const { displayName: repoDisplayName, link: repoLink } = cleanDbtRepository(dbt_repository);
          return (
            <tr key={id} className="whitespace-nowrap">
              <td>
                <Link
                  to={{
                    pathname: `/dbt/jobs/${id}`,
                  }}
                  className="font-medium hover:underline hover:text-sec-blue-gray-500"
                  data-track="ListDBTRunConfigs LinkToConfig"
                >
                  {name}
                </Link>
              </td>
              {showRepoColumn && (
                <td>
                  {repoLink ? (
                    <div className="flex items-center">
                      <a
                        href={repoLink}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:underline hover:text-sec-blue-gray-500"
                        data-track="ListDBTRunConfigs LinkToGitRepo"
                      >
                        {repoDisplayName}
                      </a>
                      {dbt_repository.git_repository_owner === 'MozartData' && (
                        <InfoIcon
                          placement="top"
                          content={
                            <div
                              style={{ whiteSpace: 'pre-line' }}
                            >{`We set up a dbt repo to get you started.\n\nWe could transfer this repo to your own GitHub organization in a few clicks if you want. Just ask!`}</div>
                          }
                          containerClass="ml-2"
                          popoverProps={{ style: { maxWidth: '550px', fontSize: '1rem' } }}
                        />
                      )}
                    </div>
                  ) : (
                    { repoDisplayName }
                  )}
                </td>
              )}
              <td>{displaySchedule(runConfig)}</td>
              <td>
                <div className="f-center">
                  <StatusIcon status={calcGenericRunStatus(runConfig)} />
                </div>
              </td>
              <td>
                <Link
                  to={warehouseSearch(`s:${target_schema}`)}
                  target="_blank"
                  className="hover:underline hover:text-sec-blue-gray-500"
                  data-track="ListDBTRunConfigs LinkToWarehouseSearch"
                >
                  {target_schema}
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default DBTRunConfigTable;
