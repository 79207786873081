/**
 * This is the master file for AggTable sorting.
 * It detects what type of object created the given table and
 * delegates calculating the sort value to the table's
 * creator's sort file.
 */
import { AggTable } from 'api/tableAPI';

import AbstractTableSorter, {
  SCHEDULE_FREQUENCY,
  LAST_RUN_AGO,
  LAST_RUN_STATUS,
} from './AbstractTableSorter';
import ConnectorSorter from './ConnectorSorter';
import CsvUploadSorter from './CsvUploadSorter';
import DbtSorter from './DbtSorter';
import SnapshotSorter from './SnapshotSorter';
import TransformSorter from './TransformSorter';

class AggTableSorter extends AbstractTableSorter<AggTable> {
  // Returns the numeric value to use to sort the tables's schedule in minutes.
  // The closer to negative infinity the more frequent the sort value.
  schedule(table: AggTable): number {
    if (table.type === 'transform' && table.transform) {
      return TransformSorter.schedule(table.transform);
    }

    if (table.type === 'snapshot') {
      return SnapshotSorter.schedule(table);
    }

    if (table.type === 'unmanaged' && table.connector) {
      return ConnectorSorter.schedule(table.connector);
    }

    if (table.type === 'dbt') {
      return DbtSorter.schedule(table);
    }

    if (table.type === 'csv_upload' && table.csvUpload) {
      return CsvUploadSorter.schedule(table);
    }

    return SCHEDULE_FREQUENCY.NOT_APPLICABLE;
  }

  // Returns how long ago a table updated in milliseconds.
  // The closer to zero the more recent the sort value.
  lastRunAgo(table: AggTable): number {
    if (table.type === 'transform' && table.transform) {
      return TransformSorter.lastRunAgo(table.transform);
    }

    if (table.type === 'snapshot') {
      return SnapshotSorter.lastRunAgo(table);
    }

    if (table.type === 'unmanaged' && table.connector) {
      return ConnectorSorter.lastRunAgo(table.connector);
    }

    if (table.type === 'dbt') {
      return DbtSorter.lastRunAgo(table);
    }

    if (table.type === 'csv_upload') {
      return CsvUploadSorter.lastRunAgo(table);
    }

    return LAST_RUN_AGO.NOT_APPLICABLE;
  }

  runStatus(table: AggTable): number {
    if (table.type === 'transform' && table.transform) {
      return TransformSorter.runStatus(table.transform);
    }

    if (table.type === 'snapshot') {
      return SnapshotSorter.runStatus(table);
    }

    if (table.type === 'unmanaged' && table.connector) {
      return ConnectorSorter.runStatus(table.connector);
    }

    if (table.type === 'dbt') {
      return DbtSorter.runStatus(table);
    }

    if (table.type === 'csv_upload') {
      return CsvUploadSorter.runStatus(table);
    }

    return LAST_RUN_STATUS.NA;
  }
}

const staticInstance = new AggTableSorter();

export default staticInstance;
