import { Transform, TransformVersion } from 'api/APITypes';
import UserHover from 'components/overlay/UserHover/UserHover';
import TransformRunIcon from 'components/primitives/icons/TransformRunIcon/TransformRunIcon';
import { agoIso, formatDurationFromIsoTimes, formatIso } from 'utils/dateTime';

import Section from './Section';

import s from './SummaryTab.module.css';

interface TransformVersionRowsProps {
  title: string;
  version: TransformVersion;
}

function TransformVersionRows(props: TransformVersionRowsProps) {
  const { title, version } = props;
  return (
    <Section
      title={title}
      rows={{
        Version: version.version_number,
        'Saved By': <UserHover userProfile={version.created_by} />,
        'Saved At': formatIso(version.created_at),
        'Saved Ago': agoIso(version.created_at),
      }}
    />
  );
}

interface TransformRunAndVersionProps {
  transform: Transform;
}

export default function TransformRunAndVersion(props: TransformRunAndVersionProps) {
  const { transform } = props;
  const lastCompletedRun = transform.last_completed_run;
  const lastRunIsCurrentVersion =
    !!lastCompletedRun &&
    lastCompletedRun.version.version_number === transform.current_version.version_number;
  return lastCompletedRun ? (
    <div className="mt-4 flex-col">
      <Section
        title="Last Completed Run"
        rows={{
          Status: <TransformRunIcon run={lastCompletedRun} rotate={true} />,
          'Start Time': formatIso(lastCompletedRun.snowflake_query?.start_time || null),
          'Started Ago': agoIso(lastCompletedRun.snowflake_query?.start_time || null),
          Duration: formatDurationFromIsoTimes(
            lastCompletedRun.snowflake_query?.start_time || null,
            lastCompletedRun.snowflake_query?.end_time || null,
          ),
        }}
      />
      {lastRunIsCurrentVersion ? (
        <TransformVersionRows
          title="Last Completed Run & Current Version"
          version={lastCompletedRun.version}
        />
      ) : (
        <>
          <TransformVersionRows title="Last Completed Run Version" version={lastCompletedRun.version} />
          <TransformVersionRows title="Current Version" version={transform.current_version} />
        </>
      )}
    </div>
  ) : (
    <div className="mt-4 flex-col">
      <div className="p-2">
        <div className={s.statHeading}>Last Completed Run</div>
        <div className="mt-2 text-pri-gray-400">Never Run</div>
      </div>
    </div>
  );
}
