/*
 * Hooks and Typescript type checks are much easier to write if we can guarnatee
 * the connector object is not null.
 * This controller owns all of the logic we can do once we know connector is not null.
 */
import { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { AppConnector } from 'api/APITypes';
import { useUserProfile } from 'context/AuthContext';
import { capitalize } from 'utils/String';

import { ConnectorHeaderProps } from './ConnectorHeader/ConnectorHeader';
import ShowConnectorView from './ShowConnectorView';
import useFivetranConnectorSetup from './useFivetranConnectorSetup';
import useLocalSyncingState from './useLocalSyncingState';
import useLogsTabState from './useLogsTabState';
import useManageTablesTabState from './useManageTablesTabState';
import useSummaryTabState from './useSummaryTabState';
import useSyncModalState from './useSyncModalState';

import 'components/query/Query.css';

export const TABS = ['summary', 'logs', 'manage_tables', 'user_actions'];

export type TabKey = (typeof TABS)[number];
const sanitizeTab = (unsafeTab: string | undefined): TabKey => {
  const castKey = unsafeTab as TabKey;
  return TABS.includes(castKey) ? castKey : 'summary';
};

interface ShowHasConnectorProps {
  connector: AppConnector;
  setPollingEnabled: (enabled: boolean) => void;
  resetPolling: () => void;
  pathTab?: string;
}

export default function ShowHasConnector(props: ShowHasConnectorProps) {
  const { connector, setPollingEnabled, pathTab, resetPolling } = props;

  const [currentTab, setCurrentTab] = useState<TabKey>(sanitizeTab(pathTab));

  const history = useHistory();
  const { userProfile } = useUserProfile();

  const localSyncState = useLocalSyncingState();

  /*****************************************************************************
   * Read the tab parameter coming in from the URL, and load the appropriate content.
   ****************************************************************************/
  // Changing the tab in the URL path doesn't create a new instance of ShowHasConnector, so navigate
  useEffect(() => {
    setCurrentTab(sanitizeTab(pathTab));
  }, [pathTab]);

  /*****************************************************************************
   * Load the state for each of the tabs and modals.
   ****************************************************************************/
  const connectorSetupState = useFivetranConnectorSetup(connector);
  const headerState: ConnectorHeaderProps = {
    connector,
    userProfile,
    connectorSetup: connectorSetupState,
  };

  const summaryTabState = useSummaryTabState(
    connector,
    userProfile,
    localSyncState,
    setPollingEnabled,
    connectorSetupState,
    resetPolling,
  );
  const logsTabState = useLogsTabState(connector);
  const syncModalState = useSyncModalState(connector);
  const manageTablesTabState = useManageTablesTabState(
    connector,
    userProfile,
    localSyncState,
    resetPolling,
    syncModalState.setShowConnectorSyncingModal,
  );

  /*****************************************************************************
   * UI Methods
   ****************************************************************************/
  const handleSelectTab = (unsafeTab: string) => {
    const tab = sanitizeTab(unsafeTab);
    analytics.track(`ShowConnector Set${capitalize(tab)}Tab`);
    setCurrentTab(tab);
    setPath(tab);
  };

  const setPath = (tab: string) => {
    let path = `/connectors/${connector.id}`;
    if (tab !== 'summary') {
      path += `/${tab}`;
    }
    history.replace(path);
  };

  return (
    <ShowConnectorView
      connector={connector}
      currentTab={currentTab}
      headerState={headerState}
      summaryTabState={summaryTabState}
      logsTabState={logsTabState}
      manageTablesTabState={manageTablesTabState}
      syncModalState={syncModalState}
      onSelectTab={handleSelectTab}
    />
  );
}
