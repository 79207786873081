import { useCallback, useMemo, useRef } from 'react';

import { isEqual } from 'lodash';

import { AggTable, SchemaName, TableID } from 'api/tableAPI';
import { SchemaIconType } from 'components/primitives/icons/SchemaIcon/SchemaIcon';
import { virtualSchemaKeyParts } from 'pages/Warehouse/DatabaseSearch2/virtualSchema';
import { KeywordLists } from 'utils/TableFilter';

import { pickHighlightFilter } from '../../highlightUtils';

import TableExplorerSchemaExpando from '../expandos/TableExplorerSchemaExpando';

import GenericExpandoList, {
  ConcreteListResponsibility,
  GenericExpandoListProps,
  RenderExpandoProps,
} from './GenericExpandoList';

interface SchemaExpandoListProps
  extends Omit<GenericExpandoListProps, keyof ConcreteListResponsibility> {
  filterIncludes: KeywordLists;
  onToggleSchema(schemaKey: string): void;
  pickedTables?: Record<TableID, AggTable>;
  pickedSchemas?: Record<SchemaName, SchemaIconType>;
}

const SchemaExpandoList = (props: SchemaExpandoListProps) => {
  const { onToggleSchema, filterIncludes, pickedTables, pickedSchemas, ...rest } = props;
  const tableDicsBySchemaRef = useRef<Record<SchemaName, Record<TableID, AggTable>>>({});

  const highlightFilter = useMemo(() => pickHighlightFilter(filterIncludes, 'schema'), [filterIncludes]);

  const renderExpando = useCallback(
    (props: RenderExpandoProps) => {
      const { key, ...rest } = props;
      return (
        <TableExplorerSchemaExpando
          {...rest}
          key={key}
          schemaKey={key}
          highlightFilter={highlightFilter}
          onToggleSchema={onToggleSchema}
        />
      );
    },
    [onToggleSchema, highlightFilter],
  );

  const isExpandoPicked = useCallback(
    (expandoKey: string): boolean => {
      const { schema } = virtualSchemaKeyParts(expandoKey);
      return !!pickedSchemas?.[schema];
    },
    [pickedSchemas],
  );

  // Calculate the picked tables for each expando so picking a table will only
  // rerender one expando.
  const pickedTablesBySchema = useMemo(() => {
    const tableDicsBySchema: Record<SchemaName, Record<TableID, AggTable>> = {};
    if (pickedTables) {
      // Step 1: Sort pickedTables by schema.
      Object.values(pickedTables).forEach((t) => {
        if (!tableDicsBySchema[t.schema]) {
          tableDicsBySchema[t.schema] = {};
        }
        tableDicsBySchema[t.schema][t.id] = t;
      });

      // Step 2: If the list of pickedTables for a schema is the same as last time,
      // resuse the old object so we don't trigger a rerender.
      Object.entries(tableDicsBySchema).forEach(([schema, list]) => {
        const prev = tableDicsBySchemaRef.current[schema];
        if (isEqual(prev, list)) {
          tableDicsBySchema[schema] = prev;
        }
      });

      // Step 3: Save tableDicsBySchema so we can do Step 2 next time.
      tableDicsBySchemaRef.current = tableDicsBySchema;
    }
    return tableDicsBySchema;
  }, [pickedTables]);

  const getPickedTables = useCallback(
    (expandoKey: string): Record<TableID, AggTable> | undefined => {
      const { schema } = virtualSchemaKeyParts(expandoKey);
      return pickedTablesBySchema[schema];
    },
    [pickedTablesBySchema],
  );

  // This struct doesn't do anything except organization and early type safety
  const concreteProps: ConcreteListResponsibility = {
    renderExpando,
    isExpandoPicked,
    getPickedTables,
  };

  return <GenericExpandoList {...rest} {...concreteProps} />;
};

export default SchemaExpandoList;
