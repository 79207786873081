import React, { useCallback, useState } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { AggTable } from 'api/APITypes';
import { Column, ColumnsByTableID } from 'api/columnAPI';
import AlertModal from 'components/layouts/containers/modals/AlertModal/AlertModal';
import ConfirmDeleteModal from 'components/layouts/containers/modals/ConfirmDeleteModal/ConfirmDeleteModal';
import Popover from 'components/overlay/Popover/Popover';
import PopperOverlay from 'components/overlay/PopperOverlay/PopperOverlay';
import DismissibleAlert from 'components/widgets/alerts/DismissibleAlert/DismissibleAlert';

import { InteractiveFlowchartProps } from '../useFlowchartEditor';
import { FlowchartQueryModelTools } from '../useFlowchartQueryModelTools';
import { EditorRunProps } from '../useSqlEditor';

// import { useLogChanged } from 'utils/React';
import Flowchart from './Flowchart/Flowchart';
import { getErrorIconID } from './Flowchart/FlowchartVertex/FlowchartVertex';
import JoinModal from './form_ui/join/JoinModal';
import useJoin from './form_ui/join/useJoin';
import SelectColumnsModal from './form_ui/select_columns/SelectColumnsModal/SelectColumnsModal';
import useSelectColumns from './form_ui/select_columns/useSelectColumns';
import SourceTableModal from './form_ui/source_table/SourceTableModal/SourceTableModal';
import useSourceTable from './form_ui/source_table/useSourceTable';
import { errorsToSqlComments } from './model/errorsToSql';
import {
  BiParentVertex,
  Edge,
  FlowchartQueryModel,
  FlowchartVertex,
  getVertex,
  isDescendant,
  Join,
  MonoParentVertex,
  SelectColumns,
  SourceTable,
  VertexType,
} from './model/FlowchartQueryModel';
import { validateFrom } from './model/flowchartQueryModelValidator';
import { pickAlias } from './query_builder/queryBuilder';
import Toolbar from './Toolbar/Toolbar';

// The modal props of every vertex type should extend this interface.
export interface BaseModalProps<SavableProps> {
  onSave: (savableProps: SavableProps) => void;
  onCancel: () => void;
}

// All vertex hooks should extend this interface.
export interface EditVertex<TypeOfVertex, TypeOfModalProps> {
  modalProps: TypeOfModalProps | null; // Render if modalProps is not null.
  onEditVertex: (vertex: TypeOfVertex) => void;
}

export interface FlowchartEditorProps {
  activeFQM: FlowchartQueryModel; // The flowchart the user is actively editing.
  flowchartModelTools: FlowchartQueryModelTools;
  tablesByID: Record<string, AggTable>;
  columnsByTableID: ColumnsByTableID;
  interactiveFlowchartProps: InteractiveFlowchartProps;
  error: string;
  selectedVertexID: string | null;
  selectedEdgeID: string | null;
  setSelectedVertexID: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedEdgeID: React.Dispatch<React.SetStateAction<string | null>>;
  setVertexIDLoading: (vertexID: string, loading: boolean) => void;
  cachingSetAndRunSQL: (sql: string, skipRunWithNull?: boolean) => Promise<EditorRunProps>;
  vertexToSQL: (vertex: FlowchartVertex) => Promise<string>;
  setError: (error: string) => void;
  fetchColumns: (tableID: string) => Promise<{ columns: Column[]; columnsByTableID: ColumnsByTableID }>;
}

const FlowchartEditor = React.memo((props: FlowchartEditorProps) => {
  const {
    activeFQM,
    flowchartModelTools,
    tablesByID,
    interactiveFlowchartProps,
    error,
    selectedVertexID,
    selectedEdgeID,
    setSelectedVertexID,
    setSelectedEdgeID,
    setVertexIDLoading,
    cachingSetAndRunSQL,
    vertexToSQL,
    setError,
    fetchColumns,
  } = props;
  const [showDeleteVertexModal, setShowDeleteVertexModal] = useState(false);
  const [showDeleteEdgeModal, setShowDeleteEdgeModal] = useState(false);
  const [gotItAlert, setGotItAlert] = useState('');
  const [errorPopoverTarget, setErrorPopoverTarget] = useState<HTMLElement | null>(null);
  const [popoverErrors, setPopoverErrors] = useState<string[]>([]);
  const { addVertex, updateVertex, deleteVertex, addEdge, deleteEdge } = flowchartModelTools;
  const { vertices } = activeFQM;
  const { loadingVertexIDs, glottingVertexID } = interactiveFlowchartProps;
  // eslint-disable-next-line no-console
  // console.log('activeFQM', activeFQM);
  // useLogChanged('FlowchartEditor.props', props);
  // useLogChanged('activeFQM', activeFQM);

  const selectedVertex = activeFQM.vertices.find((v) => v.id === selectedVertexID);
  const selectedEdge = activeFQM.edges.find((e) => e.id === selectedEdgeID);

  useHotkeys(
    'del, backspace',
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (selectedVertex) {
        setShowDeleteVertexModal(true);
      } else if (selectedEdge) {
        setShowDeleteEdgeModal(true);
      }
    },
    [selectedVertex, selectedEdge],
  );

  const handleSelectVertex = useCallback(
    (vertex: FlowchartVertex) => {
      // You clicked on something. Reset error state.
      setError('');
      // Cytoscape can send multiple single clicks in succession with one actual click.
      if (glottingVertexID === null) {
        setSelectedVertexID(vertex.id);

        // If there are errors, render those and skip SQL generation.
        const errors = validateFrom(activeFQM, vertex);
        if (errors) {
          const sql = errorsToSqlComments(errors);
          return cachingSetAndRunSQL(sql, true);
        }

        vertexToSQL(vertex)
          .then((sql) => {
            return cachingSetAndRunSQL(sql);
          })
          .catch((e: any) => {
            // eslint-disable-next-line no-console
            console.log('  handleSelectVertex.catch()', e);
            setError(e?.message || 'Error converting model to SQL. ');
          });
      }
    },
    [glottingVertexID, activeFQM, setSelectedVertexID, vertexToSQL, cachingSetAndRunSQL, setError],
  );

  const handleSelectEdge = useCallback(
    (edge: Edge) => {
      // You clicked on something. Reset error state.
      setError('');

      setSelectedEdgeID(edge.id);
    },
    [setSelectedEdgeID, setError],
  );

  const handleSetColumnsForTable = (vertex: SourceTable, table: AggTable) => {
    setVertexIDLoading(vertex.id, true);
    setSelectedVertexID(vertex.id);
    fetchColumns(table.id)
      .then(({ columns }) => {
        const updatedVertex = cloneDeep(vertex);
        updatedVertex.columns = columns;
        updateVertex(updatedVertex);
      })
      .catch(() => {
        setError(`Failed to load columns for table ${table.full_name}`);
      })
      .finally(() => {
        setVertexIDLoading(vertex.id, false);
        setSelectedVertexID(vertex.id);
      });
  };

  const sourceTableState = useSourceTable(
    updateVertex,
    handleSelectVertex,
    handleSetColumnsForTable,
    activeFQM,
  );
  const selectColumnsState = useSelectColumns(activeFQM, updateVertex, handleSelectVertex);
  const joinState = useJoin(activeFQM, updateVertex, handleSelectVertex);

  const handleDoubleClickVertex = (vertex: FlowchartVertex) => {
    if (vertex.type === 'source_table') {
      sourceTableState.onEditVertex(vertex as SourceTable);
    } else if (vertex.type === 'select_columns') {
      selectColumnsState.onEditVertex(vertex as SelectColumns);
    } else if (vertex.type === 'join') {
      joinState.onEditVertex(vertex as Join);
    }
  };

  const handleAddToolbarItem = (
    type: VertexType,
    position: { x: number; y: number },
    tableID?: string,
  ) => {
    const id = uuidv4();

    // Initialize type specific properties
    if (type === 'source_table') {
      const newSourceTable = new SourceTable(id, position);
      addVertex(newSourceTable);
      setSelectedVertexID(newSourceTable.id);
      const table = tablesByID[tableID || ''] || null;
      // Vertex was added from TableExplorer (or App's model layer doesn't have tableID)
      if (table) {
        newSourceTable.table = table;
        newSourceTable.alias = pickAlias(table, vertices);
        handleSetColumnsForTable(newSourceTable, table);
      }
      // Vertex was added from the Toolbar
      else {
        sourceTableState.onEditVertex(newSourceTable);
      }
      return;
    }

    if (type === 'select_columns') {
      const newSelectColumns = new SelectColumns(id, position);
      addVertex(newSelectColumns);
      setSelectedVertexID(newSelectColumns.id);
      return;
    }

    if (type === 'join') {
      const newJoin = new Join(id, position);
      addVertex(newJoin);
      setSelectedVertexID(newJoin.id);
      return;
    }

    alert('Node type not implemented yet.');
  };

  // Typically, this will auto open the edit modal when the final
  // parent socket is connected.
  const handleToVertexLinked = useCallback(
    (toVertex: FlowchartVertex) => {
      const { type } = toVertex;
      if (type === 'select_columns') {
        selectColumnsState.onEditVertex(toVertex as SelectColumns);
      } else if (type === 'join') {
        const join = toVertex as Join;
        if (join.leftParentID && join.rightParentID) {
          joinState.onEditVertex(join);
        }
      }
    },
    [selectColumnsState, joinState],
  );

  const handleAddEdge = useCallback(
    (fromID: string, toID: string, isTopHalf: boolean) => {
      let fromVertex = getVertex(activeFQM, fromID);
      let toVertex = getVertex(activeFQM, toID);

      // Only draw and edge from a vertex to a vertex.
      if (!(fromVertex && toVertex)) {
        return;
      }

      // Don't draw an edge if one of the vertexes is still loading
      if (loadingVertexIDs[fromVertex.id] === true || loadingVertexIDs[toVertex.id] === true) {
        return;
      }

      // The direction you draw the vertex dictates the direction that data flows.
      // User testing showed that new users sometimes drag edges in the incorrect direction,
      // and get confused when nothing happens even though they technically gave an invalid input.
      // Help them do the right thing if we can.
      if (toVertex.type === 'source_table') {
        // If a user dragged an edge from a source table to another source table,
        // do nothing. This is invalid input.
        if (fromVertex.type === 'source_table') {
          setGotItAlert('You cannot drag a link from a source table to another source table.');
          return;
        }
        // If a user dragged an edge from a non-source table to a source table,
        // swap the vertex order.
        else {
          const tempToID = toID;
          toID = fromID;
          fromID = tempToID;
          const tempToVertex = toVertex;
          toVertex = fromVertex;
          fromVertex = tempToVertex;
        }
      }

      // Do nothing if this edge already exists
      const existingEdge = activeFQM.edges.find(
        (e) =>
          e.sourceID === fromID &&
          e.destinationID === toID &&
          (e.destinationSocket === 'single' ||
            (e.destinationSocket === 'left' && isTopHalf) ||
            (e.destinationSocket === 'right' && !isTopHalf)),
      );
      if (existingEdge) {
        setGotItAlert('This link already exists.');
        return;
      }

      // Ensure we have a DAG. Do not let the user create a cycle.
      if (isDescendant(activeFQM, toVertex, fromVertex)) {
        setGotItAlert('You cannot create a loop.');
        return;
      }

      // A user can replace existing edges by drawing new ones.
      // Before we draw a new edge, delete the old relevant edges
      // that were going to and from the same drag locations
      // and any data that is relevant to the deleted edge connection.
      const oldEdges = activeFQM.edges.filter((e) => {
        // There is only one edge leaving a vertex, so always pick these edges.
        if (e.sourceID === fromID) {
          return true;
        }
        if (e.destinationID === toID) {
          // There is only one edge entering a single vertex, so always pick these edges.
          if (e.destinationSocket === 'single') {
            return true;
          }
          // BiParent destinations can have multiple incoming edges.
          // So pick the edge that is going to the relevant slot as indicated by drop position.
          if (toVertex.isBiParent()) {
            if (isTopHalf && e.destinationSocket === 'left') {
              return true;
            }
            if (isTopHalf === false && e.destinationSocket === 'right') {
              return true;
            }
          }
        }
        return false;
      });
      oldEdges.forEach((e) => {
        // The oldEdges might go to and from different vertices than the ones the user dragged to and from.
        // So lookup the vertices on either end of the edge.
        const edgeFromVertex =
          e.sourceID === fromVertex.id
            ? fromVertex
            : activeFQM.vertices.find((v) => v.id === e.sourceID);
        const edgeToVertex =
          e.destinationID === toVertex.id
            ? toVertex
            : activeFQM.vertices.find(
                (v) =>
                  (v as MonoParentVertex).singleParentID === edgeFromVertex?.id ||
                  (v as BiParentVertex).leftParentID === edgeFromVertex?.id ||
                  (v as BiParentVertex).rightParentID === edgeFromVertex?.id,
              );
        if (edgeFromVertex && edgeToVertex) {
          deleteEdge(e, edgeFromVertex, edgeToVertex);
        }
      });

      fromVertex.childID = toVertex.id;
      updateVertex(fromVertex);

      const newEdge: Edge = {
        id: uuidv4(),
        sourceID: fromID,
        destinationID: toID,
        destinationSocket: 'left', // We will correctly set this further down.
      };

      if (toVertex.isBiParent()) {
        const biParentVertex = toVertex as BiParentVertex;
        if (isTopHalf) {
          biParentVertex.leftParentID = fromVertex.id;
          newEdge.destinationSocket = 'left';
        } else {
          biParentVertex.rightParentID = fromVertex.id;
          newEdge.destinationSocket = 'right';
        }
        updateVertex(biParentVertex);
      } else {
        const monoParentVertex = toVertex as MonoParentVertex;
        if (monoParentVertex.singleParentID === null) {
          monoParentVertex.singleParentID = fromVertex.id;
          newEdge.destinationSocket = 'single';
          updateVertex(monoParentVertex);
        }
      }

      addEdge(newEdge);
      setSelectedEdgeID(newEdge.id);
      handleToVertexLinked(toVertex);
    },
    [
      activeFQM,
      loadingVertexIDs,
      addEdge,
      deleteEdge,
      updateVertex,
      setSelectedEdgeID,
      handleToVertexLinked,
    ],
  );

  const handleCancelDeleteVertex = useCallback(() => {
    setShowDeleteVertexModal(false);
  }, []);

  const handleConfirmDeleteVertex = useCallback(() => {
    if (selectedVertex) {
      deleteVertex(selectedVertex);
      setSelectedVertexID(null);
    }
    setShowDeleteVertexModal(false);
  }, [selectedVertex, deleteVertex, setSelectedVertexID]);

  const handleCancelDeleteEdge = useCallback(() => {
    setShowDeleteEdgeModal(false);
  }, []);

  const handleConfirmDeleteEdge = useCallback(() => {
    if (selectedEdge) {
      const fromVertex = activeFQM.vertices.find((v) => v.id === selectedEdge.sourceID);
      const toVertex = activeFQM.vertices.find((v) => v.id === selectedEdge.destinationID);
      if (fromVertex && toVertex) {
        deleteEdge(selectedEdge, fromVertex, toVertex);
        setSelectedEdgeID(null);
      }
    }
    setShowDeleteEdgeModal(false);
  }, [selectedEdge, activeFQM.vertices, deleteEdge, setSelectedEdgeID]);

  const handleMouseEnterErrorIcon = useCallback((vertex: FlowchartVertex) => {
    const iconID = getErrorIconID(vertex.id);
    const iconElement = document.getElementById(iconID);
    setErrorPopoverTarget(iconElement);
    setPopoverErrors(vertex.validateFlat() || []);
  }, []);

  const handleMouseExitErrorIcon = useCallback((vertex: FlowchartVertex) => {
    setErrorPopoverTarget(null);
    setPopoverErrors([]);
  }, []);

  return (
    <div className="relative w-full h-full p-4 flex bg-pri-gray-50">
      <Toolbar flowchartModel={activeFQM} />
      <Flowchart
        flowchartModel={activeFQM}
        interactiveFlowchartProps={interactiveFlowchartProps}
        onAddToolbarItem={handleAddToolbarItem}
        onSelectVertex={handleSelectVertex}
        onDoubleClickVertex={handleDoubleClickVertex}
        onAddEdge={handleAddEdge}
        onSelectEdge={handleSelectEdge}
        onMouseEnterErrorIcon={handleMouseEnterErrorIcon}
        onMouseExitErrorIcon={handleMouseExitErrorIcon}
      />
      {sourceTableState.modalProps !== null && <SourceTableModal {...sourceTableState.modalProps} />}
      {selectColumnsState.modalProps !== null && (
        <SelectColumnsModal {...selectColumnsState.modalProps} />
      )}
      {showDeleteVertexModal && selectedVertex && (
        <ConfirmDeleteModal
          header="Are you sure you want to delete this node?"
          children={null}
          enableConfirm={true}
          confirmText="Delete"
          deleting={false}
          onCancel={handleCancelDeleteVertex}
          onConfirm={handleConfirmDeleteVertex}
        />
      )}
      {showDeleteEdgeModal && selectedEdge && (
        <ConfirmDeleteModal
          header="Are you sure you want to delete this edge?"
          children={null}
          enableConfirm={true}
          confirmText="Delete"
          deleting={false}
          onCancel={handleCancelDeleteEdge}
          onConfirm={handleConfirmDeleteEdge}
        />
      )}
      {joinState.modalProps !== null && <JoinModal {...joinState.modalProps} />}
      {error && (
        <div className="absolute left-0 bottom-0 w-full">
          <DismissibleAlert
            show={error !== ''}
            variant="kinda_bad"
            className="rounded-none"
            onClose={() => setError('')}
          >
            {error}
          </DismissibleAlert>
        </div>
      )}
      <PopperOverlay
        target={{ current: errorPopoverTarget }}
        show={!!errorPopoverTarget}
        placement="top"
        renderPopper={(renderPopperProps) => {
          return (
            <Popover
              title={'You must fix these errors.'}
              content={
                <div>
                  {popoverErrors.map((e, i) => (
                    <p key={`${i}_${e}`}>{e}</p>
                  ))}
                </div>
              }
              popoverProps={{ style: { maxWidth: '400px' } }}
              {...renderPopperProps}
            />
          );
        }}
      />
      {gotItAlert && (
        <AlertModal header={gotItAlert} closeText="Got It!" onClose={() => setGotItAlert('')} />
      )}
    </div>
  );
});

export default FlowchartEditor;
