import { UserProfile } from 'api/APITypes';
import IconButton from 'components/inputs/basic/Button/IconButton';

interface BrokenSetupProps {
  anyStatusError: string;
  userProfile: UserProfile;
  actionLoading: boolean;
  handleCompleteConnector: () => void;
  handleFixConnector: () => void;
  textSizeClass?: string;
}

export default function BrokenSetup(props: BrokenSetupProps) {
  const {
    anyStatusError,
    userProfile,
    actionLoading,
    handleCompleteConnector,
    handleFixConnector,
    textSizeClass,
  } = props;
  const resolvedTextSizeClass = textSizeClass || 'text-sm';
  return (
    <div className="flex">
      <div className={`self-center text-pri-error-700 ${resolvedTextSizeClass}`}>{anyStatusError}</div>
      {anyStatusError === 'Setup is incomplete.' && userProfile.company_role !== 'viewer' && (
        <IconButton
          icon="ChevronRight"
          text="CONTINUE SETUP"
          iconLast={true}
          spinning={actionLoading}
          onClick={handleCompleteConnector}
          variant="dangerTransparent"
          size="small"
          className="ml-1"
        />
      )}
      {anyStatusError === 'Setup is broken.' && userProfile.company_role !== 'viewer' && (
        <IconButton
          icon="ChevronRight"
          text="FIX SETUP"
          iconLast={true}
          spinning={actionLoading}
          onClick={handleFixConnector}
          variant="dangerTransparent"
          size="small"
          className="ml-1"
        />
      )}
    </div>
  );
}
