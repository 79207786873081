/*
Main App level hotkeys.

Other places the bind hotkeys:
1. The CommandPalette binds its own global open me hotkey.
2. The QueryEditor binds run and save hotkeys.
*/
import { useState } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import history from '../appHistory';

const hotkeyBindings: Record<string, Record<string, string>> = {
  g: {
    h: '/home',
    c: '/connectors',
    w: '/warehouse',
    q: '/query',
    d: '/dbt',
    i: '/integrations',
    u: '/users',
  },
  a: {
    c: '/connectors/add',
    u: '/users/add',
    t: '/transforms/add',
    d: '/dbt/jobs/add',
    v: '/csv/add',
  },
};

export function useAppHotkeys() {
  const [showHotkeys, setShowHotkeys] = useState(false);

  let lastKey = 'NOT_SET';

  // Navigate if the last key was a g and the current key is a navigate hotkey
  useHotkeys(
    '*',
    (event: KeyboardEvent) => {
      const currentKey = event.key;
      const firstHotkeyBinding = hotkeyBindings[lastKey];
      const secondHotkeyBinding = firstHotkeyBinding?.[currentKey];
      if (secondHotkeyBinding) {
        event.preventDefault();
        event.stopPropagation();
        setShowHotkeys(false);
        history.push(secondHotkeyBinding);
      }
      lastKey = currentKey;
    },
    [],
  );

  // Open help
  useHotkeys(
    'shift+/',
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setShowHotkeys(true);
    },
    [],
  );

  return { showHotkeys, setShowHotkeys };
}
