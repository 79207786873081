import React from 'react';

import { AggTable } from 'api/tableAPI';
import CodeEditor, { clampedEditorHeight } from 'components/inputs/basic/CodeEditor/CodeEditor';

import SidebarDescription from './SidebarDescription';
import VertexTableSection from './VertexTableSection';

interface ViewSidebarContentProps {
  table: AggTable;
}

export default function ViewSidebarContent(props: ViewSidebarContentProps) {
  const { table } = props;

  const statRows: { label: string; value: string }[] = [];

  if (table.view_sql) {
    const lines = table.view_sql.split(/\r\n|\r|\n/).length + 1;
    const editorHeight = `${clampedEditorHeight(lines, 5, 30)}px`;
    return (
      <div className="f-col h-full w-full">
        <SidebarDescription description={table.description} />
        <div className="mb-2 mt-6 font-medium">View Sql</div>
        <CodeEditor value={table.view_sql} height={editorHeight} mode="sql" disableGutters={true} />
      </div>
    );
  }
  return (
    <div>
      <SidebarDescription description={table.description} />
      <VertexTableSection title="This table is a View." rows={statRows} className="mt-6" />
    </div>
  );
}
