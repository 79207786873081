/**
 * Type definitions, API methods, and conversion utilities for SearchColumns.
 * SearchColumns are the subset of Column props used to do searching by
 * Warehouse, TableExplorer, SQLEditor autocomplete et al.
 */
import API from 'api/API';
import { convertColumnName } from 'utils/dbName';

import { DatabaseType } from './APITypes';

/*******************************************************************************
 * Types
 ******************************************************************************/
export interface SearchColumn {
  name: string;
  type: string;
}

export interface SearchColumnsByTableID {
  [key: string]: SearchColumn[];
}

export interface APISearchColumn extends SearchColumn {
  table_id: string;
}

/*******************************************************************************
 * APIs
 ******************************************************************************/
const searchColumnAPI = {
  // Get all columns for a user in an object like {tableId: [columns]}
  fetchColumnsByTableID: (databaseType: DatabaseType) => {
    const api = new API();
    return api.get(`/api/columns?search=true`, (apiColumns) =>
      convertAPIColumnsByTableId(apiColumns, databaseType),
    );
  },
  // Get array of all columns for a single table
  fetchTableColumns: (tableId: string, databaseType: DatabaseType) => {
    const api = new API();
    return api.get(`/api/columns?search=true&table_id=${tableId}`, (resp) =>
      convertTableAPIColumns(resp, databaseType),
    );
  },
};
export default searchColumnAPI;

/*******************************************************************************
 * Conversion Utilites
 ******************************************************************************/
function convertColumn(apiColumn: APISearchColumn, databaseType: DatabaseType) {
  const column: SearchColumn = {
    name: convertColumnName(apiColumn.name, databaseType),
    type: apiColumn.type,
  };
  return column;
}

// This maps just a list of columns to a formatted list of columns
export function convertColumns(unformattedColumns: APISearchColumn[], databaseType: DatabaseType) {
  return unformattedColumns.map((col) => convertColumn(col, databaseType));
}

// This converts a list of columns into a formatted list of columns
function convertTableAPIColumns(
  apiColumnsResp: { last_columns_change: string; columns: APISearchColumn[] },
  databaseType: DatabaseType,
) {
  return apiColumnsResp.columns.map((col) => convertColumn(col, databaseType));
}

// This converts a list of columns into a dictionary of tableId to list of formatted columns
export function convertAPIColumnsByTableId(
  apiColumnsResp: {
    last_columns_change: string;
    columns: APISearchColumn[];
  },
  databaseType: DatabaseType,
): {
  last_columns_change: string;
  columns: SearchColumnsByTableID;
} {
  const columnDic: SearchColumnsByTableID = {};
  for (const c of apiColumnsResp.columns) {
    if (!columnDic[c.table_id]) {
      columnDic[c.table_id] = [];
    }
    columnDic[c.table_id].push(convertColumn(c, databaseType));
  }
  return { last_columns_change: apiColumnsResp.last_columns_change, columns: columnDic };
}
