import { cloneDeep } from 'lodash';

import { AggTable } from 'api/tableAPI';

import { TableColumn } from '../model/FlowchartQueryModel';

export function forEachDuplicateColumn(
  columnValues: TableColumn[],
  onDuplicate: (duplicate: TableColumn, duplicateName: string) => void,
  onlyPicked: boolean = false,
): void {
  columnValues.forEach((cv, myIndex) => {
    if (onlyPicked && cv.picked === false) {
      return;
    }

    const myName = cv.alias || cv.name;

    // Search every column before me to see if myName has already been used.
    let imADuplicateOf: TableColumn | null = null;
    for (let i = 0; i < myIndex; i++) {
      const them = columnValues[i];
      const theirName = them.alias || them.name;
      if (theirName === myName && (onlyPicked === false || them.picked)) {
        imADuplicateOf = them;
        break;
      }
    }
    if (imADuplicateOf) {
      onDuplicate(cv, myName);
    }
  });
}

export function aliasDuplicateColumns(availableColumnValues: TableColumn[]): TableColumn[] {
  const clone = cloneDeep(availableColumnValues);
  const onDuplicate = (cv: TableColumn) => {
    // TODO: When we support calculated fields allow getUniqueAlias to work without table object
    if (cv.table) {
      cv.alias = getUniqueAlias(cv.name, cv.table);
    }
  };
  forEachDuplicateColumn(clone, onDuplicate);
  return clone;
}

export function getUniqueAlias(columnName: string, table: AggTable): string {
  const tableSuffix = `${table.schema}_${table.name}`;
  return `${columnName}__${tableSuffix}`;
}
