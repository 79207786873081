import React, { useState } from 'react';

import { ConnectorLog } from 'api/connectorsAPI';
import AnimatedAngleIcon from 'components/primitives/icons/AnimatedAngleIcon/AnimatedAngleIcon';
import DateHover from 'components/widgets/time/DateHover/DateHover';

import LogStatusIcon from './LogStatusIcon/LogStatusIcon';
import MessageRow from './MessageRow/MessageRow';

interface LogRowProps {
  log: ConnectorLog;
  startingIsOpen?: boolean; // This is a testing convenience
}

export default function LogRow(props: LogRowProps) {
  const { log, startingIsOpen } = props;
  const [isOpen, setIsOpen] = useState(startingIsOpen || false);

  const handleToggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div key={log.sync_id} className="f-col font-mono text-sm">
      <div onClick={handleToggleAccordion} className="f-row-y-center hover:cursor-pointer">
        <AnimatedAngleIcon isOpen={isOpen} mode="accordion" className="m-1" />
        <div className="p-2">{<LogStatusIcon status={log.status} />}</div>
        <div className="p-2">
          <DateHover date={log.start_timestamp} />
        </div>
      </div>
      <div className="pl-6">
        {isOpen &&
          log.messages.map((message, i) => (
            <MessageRow key={i + message.timestamp} isFirst={i === 0} message={message} />
          ))}
      </div>
    </div>
  );
}
