import { AggTable, TransformRun } from 'api/APITypes';
import { Status } from 'components/primitives/icons/StatusIcon/StatusIcon';
import { DataAlertWithTable } from 'hooks/useDataAlerts';
import { agoIso, formatIso, parseIso } from 'utils/dateTime';
import { formatNumber } from 'utils/String';

export const getLastRunMessage = (table: AggTable, className?: string) => {
  let lastRunData = <div></div>;
  let lastCompletedRun: TransformRun | null = null;
  if (table.type === 'transform') {
    // If it's a transform we want to display the last transform run message
    if (table.transform?.last_completed_run) {
      lastCompletedRun = table.transform.last_completed_run;
      const atTime = lastCompletedRun.snowflake_query?.start_time || lastCompletedRun.created_at || null;
      lastRunData = (
        <div className={className}>
          The last transform run {lastCompletedRun.state === 'success' ? 'succeeded' : 'failed'}{' '}
          {agoIso(atTime)} ({formatIso(atTime)}).
        </div>
      );
    } else if (table.transform?.last_completed_run === null) {
      // If the transform exists but the last run is null, it has never run
      lastRunData = <div className={className}>This transform has never run.</div>;
    }
  } else if (table.type === 'snapshot') {
    if (table.last_snapshot_at) {
      const atTime = table.last_snapshot_at;
      lastRunData = (
        <div className={className}>
          This snapshot last ran {agoIso(atTime)} ({formatIso(atTime)}).
        </div>
      );
    }
    // last_snapshot_at should always exist for snapshot tables, but lets be defensive
    else {
      lastRunData = <div className={className}>This snapshot has never run.</div>;
    }
  } else if (table.type === 'unmanaged' && table.connector) {
    if (table.connector.succeeded_at) {
      const atTime = table.connector.succeeded_at;
      lastRunData = (
        <div className={className}>
          This table last synced {agoIso(atTime)} ({formatIso(atTime)}).
        </div>
      );
    } else {
      lastRunData = <div className={className}>This table has never synced.</div>;
    }
  }
  return lastRunData;
};

export const getLastAlertActionAndEnableResultsButton = (
  dataAlert: DataAlertWithTable,
  table: AggTable,
) => {
  const lastDataAlertRun = dataAlert.last_table_test_run;
  let lastAlertAction = '';
  let enableResultsButton = false;
  let status: Status = 'NA';
  if (lastDataAlertRun) {
    if (table.type === 'transform' && lastDataAlertRun.mode === 'block') {
      const lastCompletedRun = table.transform?.last_completed_run; // Should never be undefined
      // Block tests must be associated with a transform run
      // If the test was block on a transform, check if the test ran on the last transform run
      if (lastCompletedRun && lastCompletedRun.id === lastDataAlertRun.transform_run_id) {
        if (lastDataAlertRun.error_message && lastDataAlertRun.num_rows === -1) {
          // Table Test ran and failed with an error
          lastAlertAction = 'Ran in Revert and Notify mode on last transform run and failed with error.';
          enableResultsButton = true;
        } else if (lastDataAlertRun.num_rows > 0) {
          lastAlertAction = `Ran in Revert and Notify mode on last transform run and failed, returning ${formatNumber(
            lastDataAlertRun.num_rows,
          )} row${lastDataAlertRun.num_rows === 1 ? '' : 's'}.`;
          enableResultsButton = true;
        } else if (lastDataAlertRun.num_rows === 0) {
          lastAlertAction =
            'Ran in Revert and Notify mode on last transform run and passed, returning 0 rows.';
        }
      } else {
        lastAlertAction = 'This alert did not run on the last transform run.';
      }
    }
    if (lastDataAlertRun.mode === 'notify') {
      // Notifies are loosely tied to runs/syncs, so we just check if the test happened after the last sync
      let lastSyncTime = null;
      let action = '';
      if (table.type === 'transform') {
        lastSyncTime =
          table.transform?.last_completed_run?.snowflake_query?.start_time ||
          table.transform?.last_completed_run?.created_at ||
          null;
        action = 'transform run';
      } else if (table.type === 'snapshot') {
        lastSyncTime = table.last_snapshot_at || null;
        action = 'snapshot';
      } else if (table.type === 'unmanaged' && !!table.connector) {
        // Unmanaged tables without connectors shouldn't be able to be in notify mode
        lastSyncTime = table.connector.succeeded_at || null;
        action = 'sync';
      }
      const parsedLastSyncTime = parseIso(lastSyncTime);
      const parsedLastTestRunTime = parseIso(lastDataAlertRun.created_at);
      const displayMode = table.type === 'transform' ? 'in Notify mode ' : '';
      if (parsedLastSyncTime && parsedLastTestRunTime && parsedLastTestRunTime > parsedLastSyncTime) {
        if (lastDataAlertRun.error_message && lastDataAlertRun.num_rows === -1) {
          lastAlertAction = `Ran ${displayMode}after the last ${action} and failed with error.`;
          enableResultsButton = true;
        } else {
          lastAlertAction = `Ran ${displayMode}after the last ${action}, returning ${formatNumber(
            lastDataAlertRun.num_rows,
          )} row${lastDataAlertRun.num_rows === 1 ? '' : 's'}.`;
          enableResultsButton = lastDataAlertRun.num_rows > 0;
        }
      } else {
        lastAlertAction = `This alert has not run since the last ${action}.`;
      }
    }
    status = enableResultsButton ? 'error' : 'success';
    // TODO: Add if mode is schedule here VvV when schedule mode is available
  } else {
    lastAlertAction = 'This alert has never run.';
  }
  return [lastAlertAction, enableResultsButton, status] as [string, boolean, Status];
};
