import { Link } from 'react-router-dom';

import { Dashboard } from 'api/dashboardAPI';
import { AggTable } from 'api/tableAPI';

import { trackDashboard } from '../../reportUtils';

import { PartialConnector } from './TransformTable';

interface TableNameCellProps {
  table: AggTable;
  connector: PartialConnector;
  dashboard: Dashboard;
}

const TableNameCell = (props: TableNameCellProps) => {
  const { table, connector, dashboard } = props;

  const handleGoToTransform = () => {
    trackDashboard('ReportBuilderShowReport GoToTransform', connector, dashboard);
  };

  return (
    <td className="font-medium text-ellipsis">
      <Link
        to={`/tables/${table.id}/transform`}
        target="_blank"
        onClick={handleGoToTransform}
        className="hover:text-sec-blue-gray-500"
      >
        {table.full_name}
      </Link>
    </td>
  );
};

export default TableNameCell;
