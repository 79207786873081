import React, { useMemo, useState, useEffect } from 'react';

import { RouteComponentProps } from 'react-router';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import TabRow, { TabKeyType } from 'components/layouts/parts/TabRow/TabRow';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useUserProfile } from 'context/AuthContext';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import useTabs from 'hooks/useTabs';

import CloudServiceTab from './CloudServiceTab/CloudServiceTab';
import ComputeTab from './ComputeTab/ComputeTab';
import ConnectorTab from './ConnectorTab/ConnectorTab';
import EstimatorTab from './EstimatorTab/EstimatorTab';
import StorageTab from './StorageTab/StorageTab';
import SummaryTab from './SummaryTab/SummaryTab';
import useUsageFetch from './useUsageFetch';
import { CompanyPlanRow } from './useUsageFetch';
import { getTodayAndStartDate } from './Utils';

export const SUMMARY_TAB = 'summary';
export const CONNECTOR_TAB = 'connectors';
export const COMPUTE_TAB = 'compute';
export const STORAGE_TAB = 'storage';
export const CLOUD_SERVICE_TAB = 'cloud_service';
export const ESTIMATOR_TAB = 'estimator';

export type UsageTabs = 'summary' | 'connectors' | 'compute' | 'storage' | 'cloud_service' | 'estimator';

export type ReportTypes = 'lastYearMonthly' | 'last2Months' | 'currentMonthDaily';

interface MatchParams {
  tab: string;
}

interface UsageProps extends RouteComponentProps<MatchParams> {}

export function Usage(props: UsageProps) {
  const [reportType, setReportType] = useState<ReportTypes>('currentMonthDaily');
  const [currentInvoice, setCurrentInvoice] = useState<CompanyPlanRow>();

  const {
    userProfile: {
      company: { database_account },
    },
  } = useUserProfile();

  const {
    error,
    companyPlanByMonth,
    appQueryingData,
    connectorData,
    storageData,
    currentMonthPlan,
    safeFetchAndFormatData,
    setError,
  } = useUsageFetch(true);

  useEffect(() => {
    if (companyPlanByMonth?.length) {
      const newCurrentInvoice = companyPlanByMonth.find(({ IS_CURRENT_MONTH }) => IS_CURRENT_MONTH);
      setCurrentInvoice(newCurrentInvoice);
    }
  }, [companyPlanByMonth]);

  const [today, startDate] = useMemo(() => {
    return getTodayAndStartDate(reportType);
  }, [reportType]);

  const isByos = database_account?.type === 'snowflake' && database_account.is_byos;
  const estimatorExperimentEnabled = useBooleanFlag('estimator_tab', false);

  // The order of tabs here matters
  const tabs = useMemo(() => {
    let t: TabKeyType[] = [SUMMARY_TAB, CONNECTOR_TAB, COMPUTE_TAB];
    if (isByos) {
      return [CONNECTOR_TAB];
    } else if (currentInvoice?.IS_USAGE) {
      t = [...t, { key: CLOUD_SERVICE_TAB, label: 'Cloud Service' }, STORAGE_TAB];
    }
    // We don't want to show the estimator tab for BYOS accounts, or customers who don't have a usage-based plan of any kind
    const showEstimatorTab =
      isByos === false &&
      (currentInvoice?.IS_USAGE ||
        currentInvoice?.IS_SNOWFLAKE_OVERAGE ||
        currentInvoice?.IS_FIVETRAN_OVERAGE ||
        ['Sonata Free', 'Sonata Usage'].includes(currentInvoice?.PLAN_TYPE_MOST_RECENT || '') ||
        estimatorExperimentEnabled);
    if (showEstimatorTab) {
      t = [...t, ESTIMATOR_TAB];
    }
    return t;
  }, [
    isByos,
    estimatorExperimentEnabled,
    currentInvoice?.IS_USAGE,
    currentInvoice?.IS_SNOWFLAKE_OVERAGE,
    currentInvoice?.IS_FIVETRAN_OVERAGE,
    currentInvoice?.PLAN_TYPE_MOST_RECENT,
  ]);

  const { currentTab, handleSelectTab } = useTabs(
    tabs,
    isByos ? CONNECTOR_TAB : SUMMARY_TAB,
    '/usage/',
    'Usage',
    props.match.params.tab,
    'Usage',
  );

  if (appQueryingData === null || connectorData === null || storageData === null) {
    return <CenteredSpinner containerMinHeight="60vh" />;
  }

  const handleChangeTab = (tab: string) => {
    handleSelectTab(tab);
  };

  return (
    <div className="px-40">
      {error && (
        <Alert className="mt-4" variant="error">
          {error}
        </Alert>
      )}
      <h1 className="w-full py-6 text-pri-gray-700 display-sm">Usage</h1>
      <TabRow tabs={tabs} selectedTab={currentTab} onClick={handleChangeTab} />
      {currentTab === SUMMARY_TAB && companyPlanByMonth?.length && (
        <SummaryTab companyPlanByMonth={companyPlanByMonth} currentInvoice={currentInvoice} />
      )}
      {currentTab === CONNECTOR_TAB && (
        <ConnectorTab
          connectorData={connectorData}
          companyPlanByMonth={companyPlanByMonth}
          currentMonthPlan={currentMonthPlan}
          reportType={reportType}
          today={today}
          startDate={startDate}
          setReportType={setReportType}
          safeFetchAndFormatData={safeFetchAndFormatData}
          setError={setError}
        />
      )}
      {currentTab === COMPUTE_TAB && (
        <ComputeTab
          appQueryingData={appQueryingData}
          companyPlanByMonth={companyPlanByMonth}
          currentMonthPlan={currentMonthPlan}
          reportType={reportType}
          today={today}
          startDate={startDate}
          setReportType={setReportType}
          safeFetchAndFormatData={safeFetchAndFormatData}
          setError={setError}
        />
      )}
      {currentTab === STORAGE_TAB && (
        <StorageTab
          storageData={storageData}
          reportType={reportType}
          today={today}
          startDate={startDate}
          setReportType={setReportType}
        />
      )}
      {currentTab === CLOUD_SERVICE_TAB && (
        <CloudServiceTab
          appQueryingData={appQueryingData}
          reportType={reportType}
          today={today}
          startDate={startDate}
          setReportType={setReportType}
          safeFetchAndFormatData={safeFetchAndFormatData}
          setError={setError}
        />
      )}
      {currentTab === ESTIMATOR_TAB && companyPlanByMonth?.length && (
        <EstimatorTab currentInvoice={currentInvoice} />
      )}
    </div>
  );
}
