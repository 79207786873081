// Add more values here as needed.
// Doing size by class because `style={{}}` attributes in elements
// is bad for performance when hundreds can get loaded on a page in a loop.
export type IconSize = 14 | 16 | 20 | 24 | 32 | 36 | 48;
export const classesBySize: { [key: number]: string } = {
  14: 'w-[14px] h-[14px]',
  16: 'w-4 h-4',
  20: 'w-5 h-5',
  24: 'w-6 h-6',
  32: 'w-8 h-8',
  36: 'w-9 h-9',
  48: 'w-12 h-12',
};

export interface ImgIconProps {
  src: string;
  alt: string;
  size: IconSize;
  className?: string;
  style?: React.CSSProperties; // style props are bad for React performace. Try to avoid using this prop in a long loop.
}

export default function ImgIcon(props: ImgIconProps) {
  const { src, alt, size, className, style } = props;
  let actualClass = 'object-contain ' + classesBySize[size];
  if (className) {
    actualClass += ' ' + className;
  }

  return <img src={src} alt={alt} className={actualClass} style={style} />;
}
