import React from 'react';

import IconButton from 'components/inputs/basic/Button/IconButton';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';

import FeedbackForm from './FeedbackForm';

interface AITableDescriptionWidgetProps {
  onClose: () => void;
  isGeneratingText: boolean;
  generatedText?: string;
  generatedTextError?: any;
  onGenerateText: () => void;
  onCopyText: () => void;
  onAppendText: () => void;
  onReplaceText: () => void;
  onFeedbackClick: (response: string) => void;
}

type WidgetContent = JSX.Element;

const AITableDescriptionWidget = (props: AITableDescriptionWidgetProps) => {
  const {
    onClose,
    isGeneratingText,
    generatedText = null,
    generatedTextError = null,
    onGenerateText,
    onCopyText,
    onAppendText,
    onReplaceText,
    onFeedbackClick,
  } = props;

  const loadingContent = (
    <div className="f-row-y-center">
      <CenteredSpinner type="circle" spinnerSize="1.5rem" />
      <p className="text-center ml-2">Mozart AI is working on it...</p>
    </div>
  );

  const errorContent = (
    <p className="text-center ml-2">There was an error generating a description. Please try again.</p>
  );

  const generatedTextContent = (
    <div className="flex flex-col">
      <p>{generatedText}</p>
      <hr className="my-2" />
      <FeedbackForm onClick={onFeedbackClick} />
    </div>
  );

  let content: WidgetContent = generatedTextContent;
  if (isGeneratingText) {
    content = loadingContent;
  } else if (generatedTextError) {
    content = errorContent;
  }

  return (
    <div className="bg-sec-blue-gray-50 p-4 mb-2">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-sec-blue-gray-700 font-medium">Mozart AI</h2>
        <IconButton icon="XLg" onClick={onClose} variant="lightDullTransparent" size="small" />
      </div>
      <div className="bg-white mb-2 min-h-[100px] flex justify-center items-center p-4">{content}</div>
      <div className="flex justify-between">
        <IconButton
          icon="ArrowClockwise"
          text="Regenerate"
          // Don't want to pass `event` arg to `onGenerateText` as it expects `id` arg for first-time
          // generation and will throw an error.
          // TODO(ai-desc): Refactor so we don't have to do this.
          onClick={() => onGenerateText()}
          variant="lightDullTransparent"
          size="small"
          disabled={isGeneratingText}
        />
        <div className="flex flex-row">
          <IconButton
            icon="Files"
            text="Copy"
            onClick={onCopyText}
            variant="lightDullTransparent"
            size="small"
            disabled={isGeneratingText || !generatedText}
          />
          <IconButton
            icon="Clipboard2Plus"
            text="Append"
            onClick={onAppendText}
            variant="lightDullTransparent"
            size="small"
            disabled={isGeneratingText || !generatedText}
          />
          <IconButton
            icon="ArrowLeftRight"
            text="Replace"
            onClick={onReplaceText}
            variant="lightDullTransparent"
            size="small"
            disabled={isGeneratingText || !generatedText}
          />
        </div>
      </div>
    </div>
  );
};

export default AITableDescriptionWidget;
