import { FolderSymlink, Question } from 'react-bootstrap-icons';

import ConnectorIcon from 'components/primitives/icons/ConnectorIcon/ConnectorIcon';
import SchemaIcon from 'components/primitives/icons/SchemaIcon/SchemaIcon';
import SchemaIconForDatabaseSearch from 'components/primitives/icons/SchemaIconForDatabaseSearch/SchemaIconForDatabaseSearch';
import {
  MOZART_TRANSFORMS,
  UNMANAGED_SCHEMA,
} from 'pages/Warehouse/DatabaseSearch2/DatabaseSearchReducer';

import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConnectorCommand } from './commands/useConnectorCommands';
import { TableCommand } from './commands/useTableCommands';
import { Command } from './MozartCommandPalette';

export function CommandPaletteRowIcon(props: { command: Command }) {
  const { command } = props;
  const SIZE = 24;
  const SIZE_STYLE = { width: `${SIZE}px`, height: `${SIZE}px` };
  if (command.type === 'table' || command.type === 'query_table') {
    const tc = command as TableCommand;
    const { type, schema } = tc.table;
    const virtualSchemaType = type === 'transform' ? MOZART_TRANSFORMS : UNMANAGED_SCHEMA;
    const schemaType = type === 'transform' ? MOZART_TRANSFORMS : schema;
    const schemaIcon = (
      <SchemaIconForDatabaseSearch
        virtualSchemaType={virtualSchemaType}
        schema={schemaType}
        tables={[tc.table]}
        size={SIZE}
        variant="dullAction"
      />
    );
    return schemaIcon;
  } else if (command.type === 'dbt_job') {
    const schemaIcon = <SchemaIcon type="dbt" size={SIZE} variant="dullAction" />;
    return schemaIcon;
  } else if (command.type === 'connector') {
    const cc = command as unknown as ConnectorCommand;
    return <ConnectorIcon service={cc.connector.service} size={SIZE} />;
  } else if (command.type === 'help' || command.type === 'list_help') {
    return (
      <div className="border-[2px] border-purple f-center rounded-[12px]" style={SIZE_STYLE}>
        <Question color="var(--purple)" size={SIZE} />
      </div>
    );
  } else if (command.type.slice(0, 6) === 'table_') {
    return <FolderSymlink size={SIZE} color="var(--purple)" />;
  } else if (command.type === 'query_tables') {
    return <FontAwesomeIcon icon={faDatabase} style={SIZE_STYLE} />;
  } else {
    return <img style={SIZE_STYLE} src={'/images/logos/logo_purple.svg'} alt={`Mozart Data Logo`} />;
  }
}
