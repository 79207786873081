import { CSVUpload } from 'api/csvUploadAPI';
import { Status as GenericRunStatus } from 'components/primitives/icons/StatusIcon/StatusIcon';
import { parseIso } from 'utils/dateTime';

import userProfileHelper from './userProfileHelper';

export function calcGenericRunStatus(upload: CSVUpload): GenericRunStatus {
  const { state } = upload;
  if (state === 'success') {
    return 'success';
  } else if (state === 'failure') {
    return 'error';
  }
  return 'syncing';
}

export function lastUploadDate(upload: CSVUpload) {
  const date = upload.updated_at;
  return parseIso(date);
}

export function duration(upload: CSVUpload) {
  const startDate = parseIso(upload.created_at);
  const endDate = parseIso(upload.updated_at);
  const finished = upload.state === 'success' || upload.state === 'failure';
  if (finished && startDate && endDate) {
    return endDate.getTime() - startDate.getTime();
  }
  return null;
}

export function lastCompletedRunVersionSavedBy(upload: CSVUpload): string {
  const createdBy = upload.creator_user_profile;
  return userProfileHelper.displayName(createdBy);
}

export function lastCompletedRunVersionSavedAt(upload: CSVUpload): Date | null {
  return lastUploadDate(upload);
}
