/*
This file renders the Header, Modals, PopoverTours, Sidebars, and
any other widgets that might apply to all or most routes in the MainApp.

COMMENTARY:
02/28/2024:
  There are two main approaches:
  1. The <Route/> renders the entire page, the page layout component(this componet) is inside the main page component,
     and the page component is responsible for deciding what optional features to turn on and off
     by passing props to the page layout component inside of it.
     This approach might be the least error prone for programmers.
  2. The the page layout component(this componet) is the parent of the <Route/> renders.
     The <Route/> just renders the main content pane and this file renders optional features shared by all pages.

  We have chosen the later approach for two reasons. We can pivot to the first approach in the future
  if that appears to be prudent.
  1. Legacy code, renders the <Footer/> in `App.tsx`. So this approach is less disruptive to existing code.
  2. This could reduce rerendering of shared components on page navigation if we memoize them.
*/
// sort-imports-ignore
import AIPermissionModal from 'components/page_parts/AIPermissionModal/AIPermissionModal';
import { FOOTER_HEIGHT } from 'components/page_parts/Footer/Footer';
import AuthHeaderController from 'components/page_parts/headers/AuthHeader/AuthHeaderController';
import HotkeyModal from 'components/page_parts/HotKeyModal/HotkeyModal';
import OnboardingPopoverTour from 'components/page_parts/OnboardingPopoverTour/OnboardingPopoverTour';
import useAIPermissionModalContext from 'model_layer/useAIPermissionModalContext';

import { useIsWorkspaceRoute } from './MainApp';
import SidebarLayoutController from './SidebarLayout/SidebarLayoutController';

interface MainAppLayoutProps {
  currentPathObject: any;
  showHotkeys: boolean;
  setShowHotkeys: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactElement;
}

const MainAppLayout = (props: MainAppLayoutProps) => {
  const { currentPathObject, showHotkeys, setShowHotkeys, children } = props;
  const isWorkspace = useIsWorkspaceRoute();
  const { showAIPermissionModal, setShowAIPermissionModal } = useAIPermissionModalContext();
  const minHeight = isWorkspace ? '100vh' : `calc(100vh - ${FOOTER_HEIGHT}px)`;
  const style = isWorkspace
    ? {
        height: minHeight,
        minHeight,
        maxHeight: minHeight,
      }
    : { minHeight };
  return (
    <div id="main-app-layout" className="f-col" style={style}>
      <AuthHeaderController currentPathObject={currentPathObject} setShowHotkeys={setShowHotkeys} />

      <SidebarLayoutController>{children}</SidebarLayoutController>

      <OnboardingPopoverTour />

      {showHotkeys && (
        <HotkeyModal
          onCancel={() => {
            setShowHotkeys(false);
          }}
        />
      )}

      {showAIPermissionModal && (
        <AIPermissionModal
          onCancel={() => {
            setShowAIPermissionModal(false);
          }}
        />
      )}
    </div>
  );
};

export default MainAppLayout;
