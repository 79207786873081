/*
 * Type definitions, API methods, and conversion utilities for Tags.
 */
import API from 'api/API';

/*******************************************************************************
 * Types
 ******************************************************************************/
export interface Tag {
  id: string;
  name: string;
  description: string;
  color: string;
  created_at: string;
  updated_at: string;
}

export interface SaveableTagProps {
  name: string;
  description: string;
  color: string;
}

/*******************************************************************************
 * APIs
 ******************************************************************************/
const tagAPI = {
  // Add a tag
  add: (saveableProps: SaveableTagProps) => {
    const api = new API();
    return api.post(`/api/tags`, saveableProps);
  },
  // Update a tag
  update: (id: string, tagProps: SaveableTagProps) => {
    const api = new API();
    return api.patch(`/api/tags/${id}`, tagProps);
  },
  // Delete a tag
  delete: (tagID: string) => {
    const api = new API();
    return api.delete(`/api/tags/${tagID}`, {});
  },
};
export default tagAPI;

/*******************************************************************************
 * Conversion Utilites
 ******************************************************************************/
/* None. */
