/**
 * Current authentication approach is a username/password pair.
 *
 * This auth client manages what data is stored in AuthStorage.
 * */
import { AxiosResponse } from 'axios';

import API from './API';
import { UserProfile } from './APITypes';
import {
  deleteLoginState,
  setLocalStorageToken,
  setLocalStorageUserProfile,
  setHasLoggedIn,
} from './AuthStorage';

export interface ISignup {
  company_name: string;
  email: string;
  password: string;
}

// username is email
export interface ILogin {
  username?: string;
  password?: string;
  google_credential?: string;
  okta_code?: string;
}

interface ILoginResponse {
  expiry?: string;
  token?: string;
  user_profile?: UserProfile;
  error?: string;
}

export interface ILogin2FA {
  // username is email
  username?: string;
  password?: string;
  google_credential?: string;
  okta_code?: string;
  totp_token: string; // totp = Time One-Time Password
}

export interface IResetPassword {
  password: string;
  reset_token: string;
  totp_token?: string;
}

async function resetPassword(formValues: IResetPassword): Promise<any> {
  // theoretically can't delete a password that has not been set yet
  // but leaving this for consistency
  deleteLoginState();
  const api = new API();
  return await api.post('api/reset_password', formValues).then((response) => {
    return require2FAorSetLoginDetailsToLocalStorage(response);
  });
}

async function login(loginData: ILogin): Promise<any> {
  deleteLoginState();
  const api = new API();
  return await api.post('api/login', loginData).then((response: AxiosResponse) => {
    return require2FAorSetLoginDetailsToLocalStorage(response);
  });
}

async function login2FA(login2FAData: ILogin2FA): Promise<any> {
  deleteLoginState();
  const api = new API();
  return await api.post('api/login_2fa', login2FAData).then((response: AxiosResponse) => {
    if (response.data) {
      setLoginDetailsToLocalStorage(response.data);
      return response.data.user_profile;
    }
  });
}

async function signup(signupData: ISignup): Promise<any> {
  deleteLoginState();
  const api = new API();
  const response = await api.post('api/signup', signupData).then((response: AxiosResponse) => {
    if (response.data) {
      setLoginDetailsToLocalStorage(response.data);
    }
    return response.data;
  });
  return response;
}

async function logout(): Promise<AxiosResponse> {
  const api = new API();
  const response = await api.post('api/logout', {}).finally(() => deleteLoginState());
  return response;
}

function setLoginDetailsToLocalStorage(data: ILoginResponse) {
  const { expiry, token, user_profile } = data;

  if (token && expiry) {
    setLocalStorageToken({ token, expiry });
  }

  if (user_profile) {
    setLocalStorageUserProfile(user_profile);
  }

  setHasLoggedIn();
}

const require2FAorSetLoginDetailsToLocalStorage = (response: AxiosResponse) => {
  // TODO:maybe throw an error here?
  if (!response.data) return;
  // If user requires 2FA, give back loginData used so we can initialize the 2FA form with it
  if (response.data['require_2FA']) {
    return { require_2FA: true, totp_data: response.data['totp_data'] || null };
  }
  // Otherwise, login as usual
  setLoginDetailsToLocalStorage(response.data);
  return response.data.user_profile;
};

export { login, login2FA, logout, signup, resetPassword };
