import React from 'react';

import cn from 'classnames';

declare global {
  interface Window {
    Osano: any;
  }
}

export const FOOTER_HEIGHT = 34;

export default function Footer() {
  const linkClass = 'mx-3 text-xs leading-[1.5] text-pri-gray-700 whitespace-nowrap';
  const commonClasses = 'w-full min-w-fit items-center bg-silver';
  const mobileClasses = 'grid grid-cols-2 auto-rows-[34px] justify-items-center';
  const largeClasses = 'lg:flex lg:h-[34px] lg:justify-center';
  return (
    <footer className={cn(commonClasses, mobileClasses, largeClasses)}>
      <span className={`col-span-2 ${linkClass}`}>©2020-{new Date().getFullYear()} Mozart Data</span>
      <a
        href="https://www.mozartdata.com/terms-of-service"
        rel="noopener noreferrer"
        target="_blank"
        className={linkClass}
        data-track="Footer TermsOfUse"
      >
        Terms of Use
      </a>
      <a
        href="https://www.mozartdata.com/privacy-policy"
        rel="noopener noreferrer"
        target="_blank"
        className={linkClass}
        data-track="Footer PrivacyPolicy"
      >
        Privacy Policy
      </a>
      <button
        onClick={() => window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')}
        className={linkClass}
        data-track="Footer OpenStoragePreferences"
      >
        Cookie Preferences
      </button>
      <a
        href="mailto:support@mozartdata.com"
        rel="noopener noreferrer"
        target="_blank"
        className={linkClass}
        data-track="Footer ContactSupport"
      >
        Contact Support
      </a>
    </footer>
  );
}
