import { DbtRunConfig } from 'api/dbtAPI';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import PagedUserActionsTable from 'pages/user_actions/components/UserActionsTable/PagedUserActionsTable';
import usePagedUserActions from 'pages/user_actions/usePagedUserActions';

interface UserActionsTabProps {
  runConfig: DbtRunConfig;
}

const UserActionsTab = (props: UserActionsTabProps) => {
  const { runConfig } = props;
  const { isLoading, error, ...pagedProps } = usePagedUserActions('DBTUserActionsTab', {
    object_type: 'dbt_run_configuration',
    object_id: runConfig.id,
  });

  return (
    <CenteredContainer
      maxWidth="100%"
      contentWrapperClass="!m-0"
      loading={isLoading}
      loadingError={error}
    >
      {!error && (
        <PagedUserActionsTable
          {...pagedProps}
          title={'User Actions'}
          variant="blueGray"
          hideLabels={true}
        />
      )}
    </CenteredContainer>
  );
};

export default UserActionsTab;
