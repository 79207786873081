/* Utils that help coalesce and display basic vertex data in a pipeline graph */
import { truncate } from 'lodash';

import { DatabaseType } from 'api/APITypes';
import { iconUrl } from 'pages/connectors/ConnectorRegistry';
import { convertTableName, convertSchemaName } from 'utils/dbName';

import { Vertex } from '../../PipelineEditor';
import { PipelineVertex, VertexShape, ErrorColor } from '../../useVertexConverter';

import { MAX_NUM_CHARS } from '../PipelineGraph.styles';

export const getTableName = (vertex: PipelineVertex, databaseType: DatabaseType) => {
  const { table, name } = vertex;

  let tableName = '';
  if (table) {
    tableName = table.name;
  } else if (name) {
    // If table is deleted or in the midst of syncing, pull table name from `vertex.name`
    const splitVertexName = name.split('.');
    tableName = convertTableName(splitVertexName[1] ?? '', databaseType);
  }

  return truncate(tableName, { length: MAX_NUM_CHARS });
};

export const getSchemaName = (vertex: PipelineVertex, databaseType: DatabaseType) => {
  const { table, name } = vertex;

  let schemaName = '';
  if (table) {
    schemaName = table.schema;
  } else if (name) {
    // If table is deleted or in the midst of syncing, pull schema name from `vertex.name`
    const splitVertexName = name.split('.');
    schemaName = convertSchemaName(splitVertexName[0] ?? '', databaseType);
  }

  return truncate(schemaName, { length: MAX_NUM_CHARS });
};

/**
 * Returns vertex type to determine which icon to use
 *
 * TODO(pipeline-improv): This is temporary until vertex converter is cleaned up to cater to new
 * pipeline graph implementation only. Remove util once it's no longer needed.
 * @param vertex Vertex data
 * @returns
 */
export const getVertexIconType = (vertex: PipelineVertex) => {
  const { shape, table } = vertex;
  const { database_table_type, type } = table ?? {};

  if (shape !== VertexShape.CurrentVertex) {
    return shape;
  }

  if (type === 'transform') {
    return VertexShape.Transform;
  } else if (type === 'dbt') {
    return VertexShape.DBT;
  } else if (type === 'snapshot') {
    return VertexShape.Snapshot;
  } else if (type === 'csv_upload') {
    return VertexShape.CSVUpload;
  }
  // Shape is primarily determined by the object that created the table.
  // Fall back on showing view if there is no such object.
  else if (database_table_type === 'VIEW') {
    return VertexShape.View;
  }

  return VertexShape.Unmanaged;
};

/**
 * Returns connector icon to use as schema icon in vertex or null if connector is unknown or if this
 * is not applicable to vertex
 */
export const getConnectorIcon = (vertex: PipelineVertex | null) => {
  if (!vertex) {
    return null;
  }
  const { table, shape, errorColor } = vertex;
  const { schema, connector } = table ?? {};
  /**
   * Applicable statuses for using connector icons
   */
  const applicableStatuses = [ErrorColor.Normal, ErrorColor.Danger, ErrorColor.Warn];
  const appliesToVertex =
    (shape === VertexShape.Unmanaged || shape === VertexShape.CurrentVertex) &&
    applicableStatuses.indexOf(errorColor) > -1;

  if (appliesToVertex && !!schema && !!connector) {
    // This should not have to handle portable connectors yet.
    // So this does not need to consider the portable connector sprite sheet.
    const icon = iconUrl(connector.service);
    return !!icon ? icon : null;
  }

  return null;
};

/**
 * Determines if vertex is missing, meaning:
 *  1. The table never existed
 *  2. The table was deleted
 *  3. The table was soft-deleted (i.e. table is null)
 *  4. The table was created more recently than the last API table fetch (i.e. table is null)
 * @param vertex
 * @returns True if vertex is missing based on the criteria above, false otherwise
 */
export const getIsMissing = (vertex: Vertex) => {
  return vertex.is_missing || !vertex.table;
};
