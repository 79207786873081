import React, { useState } from 'react';

import API from 'api/API';
import { UserProfile } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import Switch from 'components/inputs/basic/Switch/Switch';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useUserProfile } from 'context/AuthContext';
import CompanyAllowAIInfoIcon from 'pages/settings/CompanyAllowAIInfoIcon';
import CompanyRequire2FAInfoIcon from 'pages/settings/CompanyRequire2FAInfoIcon';
import CompanyRequireSSOInfoIcon from 'pages/settings/CompanyRequireSSOInfoIcon';
import DeleteFivetranDeletedInfoIcon from 'pages/settings/DeleteFivetranDeletedInfoIcon';

import { CompanyUpdateProps } from './Settings';

interface CompanySettingsProps {
  onUpdateCompany: (companyUpdateProps: CompanyUpdateProps, trackingText: string) => Promise<void>;
  setError: (error: string) => void; // Error to display on base Settings.tsx page.
}

export default function CompanySettings(props: CompanySettingsProps) {
  const { onUpdateCompany, setError } = props;
  const { userProfile } = useUserProfile();

  const [showCompany2FAModal, setShowCompany2FAModal] = useState(false);
  const [activeUsersWithout2FA, setActiveUsersWithout2FA] = useState<UserProfile[]>([]);

  const requireOktaIsDisabled = !userProfile.company.okta_client_id || !userProfile.company.okta_domain;

  // Don't let the user lock themselves out
  let currentUserNot2FAEnabled = activeUsersWithout2FA
    .map((uProfile) => uProfile.user.email)
    .includes(userProfile.user.email);

  const closeCompany2FAModal = () => {
    setShowCompany2FAModal(false);
    analytics.track('CompanySettings CloseCompany2FAModal');
  };

  const handleUpdateCompany = (companyUpdateProps: CompanyUpdateProps, trackingText: string) => {
    setError('');
    onUpdateCompany(companyUpdateProps, trackingText)
      .catch((e) => {
        if (e.response?.data?.non_field_errors) {
          setError(e.response.data.non_field_errors);
        } else {
          setError('There was a problem updating your setting.');
        }
      })
      .finally(() => {
        setShowCompany2FAModal(false);
      });
  };

  const tryToggleCompany2FA = () => {
    if (userProfile.company.require_2fa) {
      // If they user is turning off company.require_2fa, we don't need to check if users have 2fa enabled, so just turn it off
      handleUpdateCompany(
        { require_2fa: !userProfile.company.require_2fa },
        !userProfile.company.require_2fa
          ? 'CompanySettings EnableCompanyRequire2FA'
          : 'CompanySettings DisableCompanyRequire2FA',
      );
    } else {
      // If we are turning company.require_2fa, let's check if any users don't have 2fa enabled because it will lock them out.
      const api = new API();
      api
        .get('/api/user_profiles')
        .then((response) => {
          const tempActiveUsersWithout2FA = response.data.filter(
            (userProfile: UserProfile) => !userProfile.twofa_enabled && userProfile.user.is_active,
          );
          setActiveUsersWithout2FA(tempActiveUsersWithout2FA);
          if (tempActiveUsersWithout2FA.length > 0) {
            // Some users will be locked out, so open the company2FAModal and make sure the admin wants to do this.
            setShowCompany2FAModal(true);
          } else {
            // Everyone in the company has 2FA, so we can just toggle on company.require_2fa
            handleUpdateCompany(
              { require_2fa: !userProfile.company.require_2fa },
              !userProfile.company.require_2fa
                ? 'CompanySettings EnableCompanyRequire2FA'
                : 'CompanySettings DisableCompanyRequire2FA',
            );
          }
        })
        .catch((e) => {
          if (e.response?.data?.non_field_errors) {
            setError(e.response.data.non_field_errors);
          } else {
            setError('There was a problem updating your 2FA setting.');
          }
        });
    }
  };

  const toggleAllowAI = () => {
    handleUpdateCompany(
      { allow_ai: !userProfile.company.allow_ai },
      !userProfile.company.require_2fa
        ? 'CompanySettings EnableAllowAI'
        : 'CompanySettings DisableAllowAI',
    );
  };

  const notifyUsers2FA = () => {
    const api = new API();
    api
      .get('api/companies/notify_users_2fa')
      .then((response) => {
        setShowCompany2FAModal(false);
        analytics.track('CompanySettings NotifyUsers2FA');
      })
      .catch((e) => {
        if (e.response?.data?.non_field_errors) {
          setError(e.response.data.non_field_errors);
        } else {
          setError('There was a problem updating your 2FA setting.');
        }
      });
  };

  const modalFooter = (
    <div className="w-full mt-4 flex items-center justify-end">
      <Button
        className="mx-1"
        variant="lightDanger"
        onClick={() => {
          handleUpdateCompany(
            { require_2fa: !userProfile.company.require_2fa },
            !userProfile.company.require_2fa
              ? 'CompanySettings EnableCompanyRequire2FA'
              : 'CompanySettings DisableCompanyRequire2FA',
          );
        }}
        disabled={currentUserNot2FAEnabled}
      >
        Lock them out
      </Button>
      <Button variant="lightAction" onClick={notifyUsers2FA}>
        Email Users
      </Button>
      <Button className="mx-1" variant="lightDanger" onClick={closeCompany2FAModal}>
        Cancel
      </Button>
    </div>
  );

  const company2FAModal = showCompany2FAModal && (
    <Modal
      footer={modalFooter}
      onClose={closeCompany2FAModal}
      header="Are you sure?"
      containerClass="max-h-[90vh]"
    >
      <div className="m-4 flex flex-col items-center">
        {currentUserNot2FAEnabled && (
          <Alert variant="error">
            You must enable 2FA in your User Settings before you can enable "Require 2-Factor
            Authentication" for your company.
          </Alert>
        )}
        <div className="m-4">
          <p>
            Are you sure you want to enforce 2FA for your entire company? The active users below do not
            have 2FA enabled on their accounts and enabling this feature will lock them out of Mozart
            Data.
          </p>
          <p className="mt-2">
            We can email the users to notify them that they should enable 2FA without yet enforcing it.
          </p>
        </div>
        <table className="lineTable">
          <thead>
            <tr>
              <th className="whitespace-nowrap" style={{ width: '100%' }}>
                User
              </th>
            </tr>
          </thead>
          <tbody>
            {activeUsersWithout2FA.map((activeUserWithout2FA) => (
              <tr key={activeUserWithout2FA.user.email}>
                <td>{activeUserWithout2FA.user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
  return (
    <div className="mt-6">
      {userProfile.company_role !== 'admin' && (
        <Alert variant="info">Only admins can change these settings.</Alert>
      )}
      {company2FAModal}
      <div className="flex items-center mt-4 mb-4">
        <Switch
          id="company-2fa-required-switch"
          name="require_2fa"
          checked={userProfile.company.require_2fa}
          onChange={tryToggleCompany2FA}
          disabled={userProfile.company_role !== 'admin'}
        />
        <h2 className="text-xl text-pri-gray-700 ml-3">Require 2-Factor Authentication</h2>
        <CompanyRequire2FAInfoIcon />
      </div>
      <div className="flex items-center my-4">
        <Switch
          id="company-delete-fivetran-deleted-switch"
          name="delete_fivetran_deleted"
          checked={userProfile.company.delete_fivetran_deleted}
          onChange={() => {
            handleUpdateCompany(
              {
                delete_fivetran_deleted: !userProfile.company.delete_fivetran_deleted,
              },
              !userProfile.company.delete_fivetran_deleted
                ? 'CompanySettings EnableDeleteFivetranDeleted'
                : 'CompanySettings DisableDeleteFivetranDeleted',
            );
          }}
          disabled={userProfile.company_role !== 'admin'}
        />
        <h2 className="text-xl text-pri-gray-700 ml-3">Auto-Delete _fivetran_deleted Rows</h2>
        <DeleteFivetranDeletedInfoIcon />
      </div>
      <div className="flex items-center mt-4 mb-4">
        <Switch
          id="company-allow-ai-switch"
          name="allow_ai"
          checked={userProfile.company.allow_ai}
          onChange={toggleAllowAI}
          disabled={userProfile.company_role !== 'admin'}
        />
        <h2 className="text-xl text-pri-gray-700 ml-3">Allow AI</h2>
        <CompanyAllowAIInfoIcon />
      </div>
      {/* Extra margin on the bottom of this page to prevent box from clipping the dropdown */}
      <div className="f-row-y-center mt-4 mb-[128px]">
        <h2 className="text-xl text-pri-gray-700 mr-1">Require SSO</h2>
        <CompanyRequireSSOInfoIcon />
        <Listbox
          value={userProfile.company.sso_type}
          onChange={(value: ListboxValue) => {
            handleUpdateCompany(
              { sso_type: value as 'google' | 'okta' | null },
              'CompanySettings UpdateCompanySSOType',
            );
          }}
          variant="white"
          size="medium"
          containerClass="w-[120px] ml-2 f-row-y-center border border-pri-gray-300 rounded"
          optionsMaxHeightClass="max-h-[210px]"
          options={[
            { value: null, label: 'Off' },
            { value: 'google', label: 'Google' },
            { value: 'okta', label: 'Okta', disabled: requireOktaIsDisabled },
          ]}
          disabled={userProfile.company_role !== 'admin'}
        />
      </div>
    </div>
  );
}
