import React, { useMemo } from 'react';

import { format, lastDayOfMonth, addMonths } from 'date-fns';

import { CompanyPlanRow } from 'pages/UsageV2/useUsageFetch';
import { parseApiDate } from 'utils/dateTime';

export const cycleFormat = 'MM/dd/yyyy';
const cycleDateFormatter = (date: Date) => format(date, cycleFormat);

interface BillingCycleDatesProps {
  currentInvoice: CompanyPlanRow;
  companyPlanByMonth: CompanyPlanRow[];
}

const BillingCycleDates = ({ currentInvoice }: BillingCycleDatesProps) => {
  const currentBillingDates = useMemo(() => {
    if (currentInvoice?.['RECENT_ALLOTMENT_GRAIN'] === 'Annual' && !currentInvoice?.['IS_USAGE']) {
      const planStart = parseApiDate(currentInvoice['RECENT_ANNUAL_START_DATE']) as Date;
      const planEnd = lastDayOfMonth(addMonths(planStart, 11));
      return {
        startDate: cycleDateFormatter(planStart),
        endDate: cycleDateFormatter(planEnd),
      };
    } else if (currentInvoice?.['MONTH_START']) {
      const planStart = parseApiDate(currentInvoice['MONTH_START']) as Date;
      const planEnd = lastDayOfMonth(planStart);
      return {
        startDate: cycleDateFormatter(planStart),
        endDate: cycleDateFormatter(planEnd),
      };
    } else {
      return {
        startDate: '',
        endDate: '',
      };
    }
  }, [currentInvoice]);

  return (
    <span>
      {' '}
      Current Cycle: {currentBillingDates.startDate} - {currentBillingDates.endDate}
    </span>
  );
};
export default BillingCycleDates;
