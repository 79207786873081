import {
  BiParentVertex,
  FlowchartQueryModel,
  FlowchartVertex,
  getVertex,
  Join,
  MonoParentVertex,
  SelectColumns,
  SourceTable,
} from '../model/FlowchartQueryModel';

import { FlowchartExpression } from './FlowchartExpression';

/**
 * @param fqm The entire FlowchartQueryModel
 * @param vertex The vertex to start building FlowchartExpression from.
 * 1. A FlowchartExpression has many optional properties.
 * 2. This method recursively goes up the tree of ancestors until it encounters
 *    some scenario it cannot handle. If that happens it throws an exception.
 *    Note, the UI should not let the user construct a scenario this function cannot handle.
 *
 * @returns The FlowchartExpression represented by the given vertex.
 */
export function buildFlowchartExpression(
  fqm: FlowchartQueryModel,
  vertex: FlowchartVertex,
  nameGenerator: CTENameGenerator = new CTENameGenerator(),
): FlowchartExpression {
  /*******************************************************************************
   * Base Case
   ******************************************************************************/
  if (vertex.type === 'source_table') {
    const sourceTable = vertex as SourceTable;
    if (sourceTable.table === null) {
      throw new Error(ERROR_SET_SOURCE_TABLE);
    } else {
      return {
        rootVertex: sourceTable,
        from: sourceTable,
        fullNameOrCTEName: sourceTable.table.full_name,
      };
    }
  }

  /*******************************************************************************
   * Set Current Vertex First
   ******************************************************************************/
  const expressionSoFar: FlowchartExpression = { rootVertex: vertex };
  if (vertex.type === 'select_columns') {
    const selectColumns = vertex as SelectColumns;
    if (selectColumns.selectedColumns) {
      expressionSoFar.select = selectColumns;
    }
  } else if (vertex.type === 'join') {
    const join = vertex as Join;
    if (join.leftColumn === '') {
      throw new Error(ERROR_SET_LEFT_COLUMN);
    }
    if (join.rightColumn === '') {
      throw new Error(ERROR_SET_RIGHT_COLUMN);
    }
    expressionSoFar.join = join;
  }

  // TODO: Add support for more vertex types

  /*******************************************************************************
   * Recurse Ancestors
   ******************************************************************************/
  if (vertex.isMonoParent()) {
    const monoParent = vertex as MonoParentVertex;
    if (monoParent.singleParentID === null) {
      throw new Error(ERROR_DISCONNECTED_SOCKET);
    }
    const singleParent = getVertex(fqm, monoParent.singleParentID);
    const parentExpression = buildFlowchartExpression(fqm, singleParent, nameGenerator);
    return mergeExpression(expressionSoFar, parentExpression, nameGenerator);
  }

  if (vertex.isBiParent()) {
    const biParent = vertex as BiParentVertex;
    if (biParent.leftParentID === null) {
      throw new Error(ERROR_DISCONNECTED_SOCKET);
    }

    if (biParent.rightParentID === null) {
      throw new Error(ERROR_DISCONNECTED_SOCKET);
    }
    const leftParent = getVertex(fqm, biParent.leftParentID);
    const rightParent = getVertex(fqm, biParent.rightParentID);
    const leftExpression = buildFlowchartExpression(fqm, leftParent, nameGenerator);
    const rightExpression = buildFlowchartExpression(fqm, rightParent, nameGenerator);
    // Reversing order of left and right so that left is first in the stack.
    let merged = mergeExpression(expressionSoFar, rightExpression, nameGenerator);
    merged = mergeExpression(expressionSoFar, leftExpression, nameGenerator);
    return merged;
  }

  return expressionSoFar;
}

export const ERROR_SET_SOURCE_TABLE = 'You must set the table on your source table.';
export const ERROR_DISCONNECTED_SOCKET = 'You must connect every parent socket.';
export const ERROR_SET_LEFT_COLUMN = 'You must set the left column of a join.';
export const ERROR_SET_RIGHT_COLUMN = 'You must set the right column of a join.';

// If the two expressions are mergable without a CTE, it merges them.
// If a CTE is required to merge them, myAncestor is converted to a CTE.
export function mergeExpression(
  me: FlowchartExpression,
  myAncestor: FlowchartExpression,
  nameGenerator: CTENameGenerator,
): FlowchartExpression {
  if (me.select) {
    if (myAncestor.select) {
      return prependCTE(me, myAncestor, nameGenerator);
    } else if (myAncestor.join) {
      if (myAncestor.join.selectedColumns) {
        return prependCTE(me, myAncestor, nameGenerator);
      } else {
        return { ...myAncestor, ...me };
      }
    } else {
      return { ...myAncestor, ...me };
    }
  } else if (me.join) {
    if (myAncestor.select || myAncestor.join) {
      return prependCTE(me, myAncestor, nameGenerator);
    } else {
      // My ancestors are SourceTables which are defined on `me.join`.
      return me;
    }
  }

  // I've got unset properties that make me a no-op or
  // I'm behavior that hasn't been implemented yet.
  // Just return my ancestor.
  return myAncestor;
}

export function prependCTE(
  me: FlowchartExpression,
  myAncestor: FlowchartExpression,
  nameGenerator: CTENameGenerator,
): FlowchartExpression {
  myAncestor.fullNameOrCTEName = nameGenerator.next();
  if (me.select) {
    me.from = myAncestor.fullNameOrCTEName;
  }
  if (!me.ctes) {
    me.ctes = [];
  }
  me.ctes.unshift(myAncestor);

  // Move myAncestor's ctes to me so there is only one stack of CTEs
  if (myAncestor.ctes) {
    me.ctes = [...myAncestor.ctes, ...me.ctes];
    myAncestor.ctes = undefined;
  }
  return me;
}

export class CTENameGenerator {
  count: number = 0;
  next() {
    this.count++;
    return `cte_${this.count}`;
  }
}
