import React, { useState } from 'react';

import Modal from 'components/layouts/containers/modals/Modal/Modal';
import TabRow from 'components/layouts/parts/TabRow/TabRow';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import { isMac } from 'utils/browser';

export interface HotkeyModalProps {
  onCancel(): void;
}

export default function HotkeyModal(props: HotkeyModalProps) {
  let { onCancel } = props;
  const [currentTab, setCurrentTab] = useState('navigation');
  const hasCommandBar = useBooleanFlag('command_bar');

  const tabs = [
    { key: 'navigation', label: 'Navigation' },
    { key: 'editor', label: 'Editor' },
    { key: 'advancedSearch', label: 'Advanced Search' },
  ];

  const modKey = isMac() ? '⌘' : 'Ctrl';

  const tabContent = (
    <>
      {currentTab === 'navigation' && (
        <div className="mt-4">
          <p className="font-medium">
            If an input has focus you will have to <code>Tab</code> out of it before these keyboard
            shortcuts will work.
          </p>
          <table className="blueGrayHeaderTable mt-1">
            <thead>
              <tr>
                <th>Action</th>
                <th>Shortcut</th>
              </tr>
            </thead>
            <tbody>
              {hasCommandBar && (
                <tr>
                  <td>Open Command Palette</td>
                  <td>
                    <code>{modKey}</code> + <code>p</code>
                  </td>
                </tr>
              )}
              <tr>
                <td>Show this help modal</td>
                <td>
                  <code>Shift</code> + <code>?</code>
                </td>
              </tr>
              <tr>
                <td>Focus Search Box</td>
                <td>
                  <code>{modKey}</code> + <code>.</code>
                </td>
              </tr>
              <tr>
                <td>Go to Connectors</td>
                <td>
                  <code>g</code> <code>c</code>
                </td>
              </tr>
              <tr>
                <td>Go to Warehouse</td>
                <td>
                  <code>g</code> <code>w</code>
                </td>
              </tr>
              <tr>
                <td>Go to Query</td>
                <td>
                  <code>g</code> <code>q</code>
                </td>
              </tr>
              <tr>
                <td>Go to Integrations</td>
                <td>
                  <code>g</code> <code>i</code>
                </td>
              </tr>
              <tr>
                <td>Go to Users</td>
                <td>
                  <code>g</code> <code>u</code>
                </td>
              </tr>
              <tr>
                <td>Add Connector</td>
                <td>
                  <code>a</code> <code>c</code>
                </td>
              </tr>
              <tr>
                <td>Add Transform</td>
                <td>
                  <code>a</code> <code>t</code>
                </td>
              </tr>
              <tr>
                <td>Add CSV</td>
                <td>
                  <code>a</code> <code>v</code>
                </td>
              </tr>
              <tr>
                <td>Add dbt Job</td>
                <td>
                  <code>a</code> <code>d</code>
                </td>
              </tr>
              <tr>
                <td>Add User</td>
                <td>
                  <code>a</code> <code>u</code>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {currentTab === 'editor' && (
        <div className="mt-4">
          <p>
            <div className="font-medium">
              The query editor supports all the{' '}
              <a
                href="https://codemirror.net/docs/ref/#commands"
                rel="noopener noreferrer"
                target="_blank"
                className="text-sec-purple-700"
              >
                shortcuts you'd normally expect in a code editor.
              </a>
            </div>
            <br />
            <span className="font-medium">Notably:</span>
            <br />
            <span className="ml-4">
              <code>⌘</code> + <code>f</code> will open search.
            </span>
            <br />
            <span className="ml-4">
              <code>CTRL</code> + <code>Space</code> will open autocomplete.
            </span>
            <br />
            <span className="ml-4">
              <code>⌘</code> + <code>/</code> will comment out code.
            </span>
            <br />
          </p>
          <p className="mt-4">
            <div className="font-medium">Tabbing:</div>
            Normally you press <code>Tab</code> to move between inputs on a browser.
            <br />
            You have to press <code>Option</code> and <code>Tab</code> or <code>Escape</code> then{' '}
            <code>Tab</code> to tab out of the query editor.
          </p>
          <p className="mt-4">
            <div className="font-medium">
              The following commands can be run when the query editor has focus:
            </div>
          </p>
          <table className="blueGrayHeaderTable mt-1">
            <thead>
              <tr>
                <th>Action</th>
                <th>Shortcut</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Run query</td>
                <td>
                  <code>{modKey}</code> + <code>Return</code>
                </td>
              </tr>
              <tr>
                <td>Edit query</td>
                <td>
                  <code>{modKey}</code> + <code>e</code>
                </td>
              </tr>
              <tr>
                <td>Save query</td>
                <td>
                  <code>{modKey}</code> + <code>s</code>
                </td>
              </tr>
              <tr>
                <td>Format query</td>
                <td>
                  <code>{isMac() ? '⌥' : 'Alt'}</code> + <code>Shift</code> + <code>f</code>
                </td>
              </tr>
              <tr>
                <td>Discard Changes</td>
                <td>
                  <code>{modKey}</code> + <code>Esc</code>
                </td>
              </tr>
              <tr>
                <td>Search Tables</td>
                <td>
                  <code>{modKey}</code> + <code>.</code>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {currentTab === 'advancedSearch' && (
        <div className="mt-4">
          <p>
            <div className="font-medium">The Query Editor and Warehouse support advanced search.</div>
            <br />
            By default, the search box searches schemas, tables, and columns for matches.
          </p>
          <div className="f-col mt-4">
            <div className="font-medium">Search Schemas</div>
            <img
              className="w-[425px]"
              src="/images/help/advanced_search/search_schemas.png"
              alt="search schemas"
            />
          </div>
          <div className="f-col mt-4">
            <div className="font-medium">Search Tables</div>
            <img
              className="w-[425px]"
              src="/images/help/advanced_search/search_tables.png"
              alt="search tables"
            />
          </div>
          <div className="f-col mt-4">
            <div className="font-medium">Search Columns</div>
            <img
              className="w-[425px]"
              src="/images/help/advanced_search/search_columns.png"
              alt="search columns"
            />
          </div>
        </div>
      )}
    </>
  );

  return (
    <Modal header="Keyboard Shortcuts" onClose={onCancel} fullscreen={true} cancelButton={true}>
      <div id="hotkey-modal-body" className="p-4">
        <TabRow tabs={tabs} selectedTab={currentTab} onClick={setCurrentTab} />
        {tabContent}
      </div>
    </Modal>
  );
}
