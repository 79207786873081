// Docs: https://developers.google.com/identity/oauth2/web/reference/js-reference

const GSHEET_CLIENT_ID =
  process.env.REACT_APP_GSHEET_CLIENT_ID || 'SYSTEM ERROR: SET .env.XXX.REACT_APP_GSHEET_CLIENT_ID';

// Right now we just accept the scope to modify drive files, so we can hard-code it
const SCOPE_GOOGLE_DRIVE_FILE_EDIT = 'https://www.googleapis.com/auth/drive.file';

const executeFnWithSignedInGapi = (fn: Function, errorFn: Function = () => {}) => {
  window.google.accounts.oauth2
    .initTokenClient({
      client_id: GSHEET_CLIENT_ID,
      scope: SCOPE_GOOGLE_DRIVE_FILE_EDIT,
      prompt: '', // This prompt means the user only has to sign in once and on subsequent requests google will automatically log in
      // @ts-ignore: error_callback exists, but the type definitions have not been updated to reflect, so we need to ignore in typescript
      error_callback: errorFn,
      callback: (tokenResponse) => {
        // We also have access to error_description and error_uri here
        if (tokenResponse.error) {
          errorFn();
        } else {
          fn(tokenResponse.access_token);
        }
      },
    })
    .requestAccessToken();
};

export default executeFnWithSignedInGapi;
