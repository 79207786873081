import React, { useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from 'components/inputs/basic/Button/Button';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useUserProfile } from 'context/AuthContext';

import { CompanyUpdateProps } from './Settings';

const validationSchema = Yup.object().shape({
  okta_client_id: Yup.string().trim(),
  okta_domain: Yup.string().trim(),
  okta_client_secret: Yup.string().trim(),
});

interface OktaIntegrationProps {
  onUpdateCompany: (companyUpdateProps: CompanyUpdateProps, trackingText: string) => Promise<void>;
}

export default function OktaIntegration(props: OktaIntegrationProps) {
  const { onUpdateCompany } = props;
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { userProfile } = useUserProfile();

  const { okta_client_id, okta_domain } = userProfile.company;

  let oktaInitialValues: CompanyUpdateProps = { okta_client_id, okta_domain };

  const handleClickEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleUpdateCompany = (values: CompanyUpdateProps, trackingText: string) => {
    setIsSaving(true);
    setError('');
    onUpdateCompany(values, trackingText)
      .catch((e) => {
        if (e.response?.data?.non_field_errors) {
          setError(e.response.data.non_field_errors);
        } else {
          setError('There was a problem updating your setting.');
        }
      })
      .finally(() => {
        setIsSaving(false);
        setIsEditing(false);
      });
  };

  const displayOktaClientID = <div className="ml-2 font-normal">{okta_client_id}</div> || (
    <div className="ml-2 font-normal text-pri-gray-400">Not Set</div>
  );
  const displayOktaDomain = <div className="ml-2 font-normal">{okta_domain}</div> || (
    <div className="ml-2 font-normal text-pri-gray-400">Not Set</div>
  );

  const modalBody = isEditing && (
    <Modal onClose={handleCancelEdit} header="Enter Okta Credentials" cancelButton={true}>
      <div className="f-col w-[500px] p-4">
        {error && (
          <Alert className="mx-4 mb-2" variant="error">
            {error}
          </Alert>
        )}
        <Formik
          initialValues={oktaInitialValues}
          onSubmit={(values) => handleUpdateCompany(values, 'OktaSettings Save')}
          onReset={handleCancelEdit}
          validationSchema={validationSchema}
        >
          {({ handleChange, handleSubmit, handleReset }) => (
            <form onSubmit={handleSubmit} onReset={handleReset}>
              <TextFormikGroup
                type="text"
                name="okta_client_id"
                label="Client ID"
                placeholder="client_id"
                onChange={handleChange}
              />
              <TextFormikGroup
                type="text"
                name="okta_domain"
                label="Domain"
                groupClass="mt-2"
                placeholder="domain.okta.com"
                onChange={handleChange}
              />
              <TextFormikGroup
                type="text"
                name="okta_client_secret"
                label="Secret"
                groupClass="mt-2"
                onChange={handleChange}
              />
              <div className="mt-2">
                <a
                  href="https://help.mozartdata.com/docs/setting-up-okta#Okta-credentials"
                  target="_blank"
                  rel="noreferrer"
                  className="text-sec-blue-light-700 font-medium text-sm hover:underline hover:cursor-pointer"
                >
                  Where do I find my Okta credentials?
                </a>
              </div>
              <div className="f-row-y-center mt-4">
                <Button type="submit" variant="lightAction" size="form" spinning={isSaving}>
                  Save
                </Button>
                <Button
                  variant="lightDanger"
                  className="ml-2"
                  size="form"
                  onClick={handleCancelEdit}
                  disabled={isSaving}
                >
                  Cancel
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );

  return (
    <>
      {isEditing && modalBody}
      <div className="f-between" style={{ borderBottom: '1px solid #dee2e6', padding: '0.75rem' }}>
        <div className="flex">
          <div className="f-center">
            <img alt="" height="25" width="100" src={'/images/logos/oktaLogoWithText.png'} />
          </div>
          <div className="ml-4 text-pri-gray-500">
            <div className="f-col w-fit p-2">
              <div className="f-row-y-center font-medium">Client ID: {displayOktaClientID}</div>
              <div className="f-row-y-center font-medium">Domain: {displayOktaDomain}</div>
            </div>
          </div>
        </div>
        <Button onClick={handleClickEdit} disabled={userProfile.company_role !== 'admin'}>
          Configure
        </Button>
      </div>
    </>
  );
}
