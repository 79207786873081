import cn from 'classnames';

import Button from 'components/inputs/basic/Button/Button';

export interface CompleteSetupBoxProps {
  className?: string;
  onComplete(): void;
}

export default function CompleteSetupBox(props: CompleteSetupBoxProps) {
  const { className, onComplete } = props;

  return (
    <div
      className={cn(
        'w-full p-4 flex flex-col items-center bg-white rounded shadow-[0_2px_4px_0_rgba(0,0,0,0.1)]',
        className,
      )}
    >
      <h2 className="text-lg font-medium text-pri-success-600">Great Job!</h2>
      <div className="mt-1 text-sm text-pri-gray-500 text-center">
        You've completed basic setup.
        <br />
        Let us know if you have any questions.
      </div>
      <Button variant="darkDullAction" size="small" onClick={onComplete} className="mt-4">
        Complete Setup
      </Button>
    </div>
  );
}
