import React, { useCallback, useState, useRef } from 'react';

import { Connector } from 'api/APITypes';
import { AggTable } from 'api/tableAPI';
import DraggablePanes from 'components/layouts/containers/draggable_panes/DraggablePanes/DraggablePanes';

import PipelineGraph from './PipelineGraph/PipelineGraph';
import PipelineLegend from './PipelineLegend/PipelineLegend';
import useVertexConverter, { PipelineVertex } from './useVertexConverter';
import VertexSidebar from './VertexSidebar/VertexSidebar';

export interface Vertex {
  id: string; // ID of vertex
  table: AggTable | null; // table is null when SQL references a table that doesn't exist
  // How many previous generations of tables does this transform/snapshot depend on?
  // Unmanaged tables are always generation 0. // TODO FYI This is in flux
  // Transforms are generation 1 or greater.
  generation: number;
  is_missing: boolean;
  name: string | null;
}

export interface Edge {
  id: string; // Edge ID
  source: string; // Source vertex ID
  destination: string; // Destination vertex ID
  is_scheduling_dependency: boolean; // If the edge is a scheduling dependency for it's destination
}

export interface DependencyEdge extends Edge {
  source_name: string; // Full Table Name of Source
}

// All of the graph data that defines a pipeline graph
export interface Pipeline {
  vertices: Vertex[];
  edges: Edge[];
  currentTable: AggTable | null;
}

// Pipeline data plus component properties
interface PipelineEditorProps {
  pipeline: Pipeline;
  tablesByID: { [key: string]: AggTable };
  connectorsByID: { [key: string]: Connector };
}

export function getDirectDownstreamVertices(pipeline: Pipeline, currentTableId: string): Vertex[] {
  const currentVertexId = pipeline.vertices.find((v) => v.table?.id === currentTableId)?.id;
  const edgesFromCurrentTable = pipeline.edges
    .filter((e) => e.source === currentVertexId)
    .map((e) => e.destination);
  return pipeline.vertices.filter((v) => edgesFromCurrentTable.includes(v.id));
}

export default function PipelineEditor(props: PipelineEditorProps) {
  const { pipeline, tablesByID, connectorsByID } = props;
  const { currentTable } = pipeline;
  const { pipelineVertices, pipelineVerticesByID, pipelineVerticesByTableID } =
    useVertexConverter(pipeline);

  // The vertex passed in by the /tables page
  const initialVertex = currentTable ? pipelineVerticesByTableID[currentTable.id] : null;

  const legendRef = useRef(null);

  // The vertex the sidebar is currently showing
  const [selectedVertex, setSelectedVertex] = useState<PipelineVertex | null>(initialVertex);

  const handleSelectVertex = useCallback(
    (selectedVertexID: string) => {
      const selectedVertex = pipelineVerticesByID[selectedVertexID];
      setSelectedVertex(selectedVertex);
      analytics.track('PipelineEditor SetSelectedVertex');
    },
    [pipelineVerticesByID],
  );

  const handleDoubleClickVertex = useCallback(
    (selectedVertexID: string) => {
      const selectedVertex = pipelineVerticesByID[selectedVertexID];
      if (selectedVertex.table) {
        analytics.track('PipelineEditor DoubleClickVertex');
        window.open(`/tables/${selectedVertex.table.id}`, '_blank');
      }
    },
    [pipelineVerticesByID],
  );

  const handleOnCloseSizebar = () => {
    setSelectedVertex(null);
    analytics.track('PipelineEditor CloseSidebar');
  };

  return (
    <div className="flex w-full h-full bg-pri-gray-50">
      <DraggablePanes
        style={{ width: '100%', height: '100%' }}
        leftStartingWidth="70%"
        leftMinWidth="50%"
      >
        <div className="w-full h-full">
          <PipelineGraph
            pipeline={pipeline}
            pipelineVertices={pipelineVertices}
            initialVertexId={initialVertex?.id || null}
            legendRef={legendRef}
            onSelectVertex={handleSelectVertex}
            onDoubleClickVertex={handleDoubleClickVertex}
          />
          <PipelineLegend
            ref={legendRef}
            pipelineVertices={pipelineVertices}
            initialVertex={initialVertex}
          />
        </div>
        {selectedVertex && (
          <VertexSidebar
            pipeline={pipeline}
            selectedVertex={selectedVertex}
            tablesByID={tablesByID}
            connectorsByID={connectorsByID}
            onClose={handleOnCloseSizebar}
          />
        )}
      </DraggablePanes>
    </div>
  );
}
