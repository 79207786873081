import { Completion } from '@codemirror/autocomplete';

export interface CustomCompletion extends Completion {
  desc?: string;
  url?: string;
  args?: string;
}

// These values help boost the order of autocomplete results
// and can range from -99 to 99
// DOCS: https://codemirror.net/docs/ref/#autocomplete.Completion.boost
export const AUTOCOMPLETE_SEARCH_BOOST = {
  localVar: 20,
  // Columns only complete when it is appropriate for them to exist so set their boost above schemas.
  columns: 10,
  // Adam thinks keywords should sort first because you usually start typing a section with "SELECT" or "WHERE"
  // There are two values for keywords so we can sort high priority keys before low priority ones, as in WHERE before WHEN.
  keywordHigh: 6,
  keywordLow: 5,
  // The "schema" prefix of a "schema.table" completion has a boost score of 0 that we cannot change.
  // Since, we want schema prefixes to sort first all boosts with less priority have to be less than zero.
  // `childCompletions` on https://github.com/codemirror/lang-sql/blob/main/src/complete.ts#L124 sets default boost.
  transform: -10, // Transforms are more important than other tables.
  tables: -20,
  functions: -40,
  fivetranColumn: -50,
};
