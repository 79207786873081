import { DbtRun } from 'api/dbtAPI';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import DateHover from 'components/widgets/time/DateHover/DateHover';
import Duration from 'components/widgets/time/Duration/Duration';
import { calcGenericRunStatusFromState } from 'model_helpers/dbtRunConfigHelper';

interface RunTableRowProps {
  run: DbtRun;
  onViewLogs(run: DbtRun): void;
}

const RunTableRow = (props: RunTableRowProps) => {
  const { run, onViewLogs } = props;
  const { id, created_at, completed_at, state, is_manual } = run;

  return (
    <tr key={id} className="whitespace-nowrap">
      <td>
        <DateHover date={created_at} interval={run.state === 'running' ? 1000 : undefined} />
      </td>
      <td>
        <DateHover date={completed_at} />
      </td>
      <td>
        <div className="f-center">
          <StatusIcon status={calcGenericRunStatusFromState(state)} rotate={true} />
        </div>
      </td>
      <td>
        <Duration start={created_at} end={completed_at} interval={1000} />
      </td>
      <td>{is_manual ? 'Manual' : 'Scheduled'}</td>
      <td>
        {/* This is probably going to change to a <Link/> */}
        <a
          className="text-sm font-medium text-sec-blue-gray-500 hover:cursor-pointer"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            onViewLogs(run);
          }}
        >
          View Logs
        </a>
      </td>
    </tr>
  );
};

export default RunTableRow;
