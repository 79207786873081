import React, { forwardRef, ForwardedRef, useEffect, useRef, useState, useCallback } from 'react';

import cn from 'classnames';

import IconButton from 'components/inputs/basic/Button/IconButton';

export type HandleCopy = (
  value: string,
  targetRef: React.MutableRefObject<HTMLButtonElement | null>,
) => void;

interface CopyButtonProps
  extends Omit<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'onCopy' | 'ref'
  > {
  value: string;
  onCopy: HandleCopy;
}

const CopyButton = forwardRef(
  (props: CopyButtonProps, forwardedRef: ForwardedRef<HTMLButtonElement>) => {
    const { value, onCopy, className, ...buttonProps } = props;
    const tipTarget = useRef<HTMLButtonElement | null>(null);

    // Make sure forwardedRef from <TooltipTrigger> and my ref are aligned.
    const setMyRef = (instance: HTMLButtonElement | null) => {
      if (forwardedRef) {
        if (typeof forwardedRef === 'function') {
          forwardedRef(instance);
        } else {
          forwardedRef.current = instance;
        }
      }
      tipTarget.current = instance;
    };

    return (
      <IconButton
        ref={setMyRef}
        icon="Files"
        onClick={(e: any) => {
          e.stopPropagation();
          if (tipTarget.current) {
            onCopy(value, tipTarget);
          }
        }}
        variant="lightDullTransparent"
        size="small"
        className={cn(className)}
        {...buttonProps}
      />
    );
  },
);

// This should be used by CopyButton's parent
function useCopyButton() {
  const [copiedRef, setCopiedRef] = useState<React.MutableRefObject<HTMLButtonElement | null> | null>(
    null,
  );
  const [timeout, setStateTimeout] = useState<null | NodeJS.Timeout>(null);
  const [showCopied, setShowCopied] = useState(false);

  const handleCopy: HandleCopy = useCallback(
    (value, targetRef) => {
      navigator.clipboard.writeText(value);

      if (timeout) {
        clearTimeout(timeout);
      }

      const newTimeout = setTimeout(() => {
        setStateTimeout(null);
        setShowCopied(false);
      }, 1000);

      setShowCopied(true);
      setCopiedRef(targetRef);
      setStateTimeout(newTimeout);
    },
    [timeout],
  );

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeout]);

  return { showCopied, copiedRef, handleCopy };
}

export default CopyButton;
export { useCopyButton };
