import React from 'react';

import { trySetItem } from 'api/CacheKeys';

interface Protip {
  fade: string;
  heading: string | React.ReactNode;
  image: string | string[];
  url: string;
}

// Protips are arranged in descending order from most basic and most useful first
// to most advanced and least likely to be used.
const protips: Protip[] = [
  /*******************************************************************************
   * Group 1:
   * Getting Very Basic Help
   *
   * Note: I'm calling the fade property of the help section "Protip", because
   *       all variations of "Get Help", sound condescending.
   ******************************************************************************/
  {
    fade: 'Protip',
    heading: 'Help opens the documentation for the page you are currently on.',
    image: 'question_mark_opens_docs',
    url: 'https://help.mozartdata.com/docs/getting-started',
  },
  {
    fade: 'Protip',
    heading: 'Ask a professional analyst for help.',
    image: 'freshchat',
    url: 'https://help.mozartdata.com/docs/getting-started',
  },
  /*******************************************************************************
   * Group 2:
   * Articles that are useful to new users
   ******************************************************************************/
  {
    fade: 'Great Read',
    heading: null,
    image: 'read_how_to_be_data_driven',
    url: 'https://www.mozartdata.com/post/how-to-be-a-data-driven-company',
  },
  {
    fade: 'Great Read',
    heading: null,
    image: 'read_a_practical_guide_to_data_analysis',
    url: 'https://www.mozartdata.com/post/data-analysis',
  },
  {
    fade: 'Great Read',
    heading: null,
    image: 'read_what_are_the_best_ways_to_transform_large_data_sets',
    url: 'https://www.mozartdata.com/post/what-are-the-best-ways-to-transform-large-data-sets',
  },
  /*******************************************************************************
   * Group 3:
   * Enumerate "Large Features" new users should be aware of
   ******************************************************************************/
  {
    fade: 'Useful Feature',
    heading: 'Version History shows you how your transform has changed',
    image: ['new_feature_version_history', 'new_feature_version_history_diff'],
    url: 'https://help.mozartdata.com/docs/version-history',
  },
  {
    fade: 'Useful Feature',
    heading: 'Data Pipelines show you where your data comes from.',
    image: 'new_feature_data_lineage',
    url: 'https://help.mozartdata.com/docs/pipeline-features',
  },
  {
    fade: 'Useful Feature',
    heading: 'Notifications tell you when something important happens.',
    image: 'new_feature_notifications',
    url: 'https://help.mozartdata.com/docs/notifications',
  },
  {
    fade: 'Useful Feature',
    heading: 'Alerts notify you when your data is broken.',
    image: 'new_feature_data_alerts',
    url: 'https://help.mozartdata.com/docs/data-alerts',
  },
  {
    fade: 'Useful Feature',
    heading: 'Sync your table to Google Sheets.',
    image: 'new_feature_google_sheet_sync',
    url: 'https://help.mozartdata.com/docs/google-sheet-sync',
  },
  {
    fade: 'Useful Feature',
    heading: 'Incremental Transforms only process new data.',
    image: 'new_feature_incremental_transforms',
    url: 'https://help.mozartdata.com/docs/incremental-transforms',
  },
  {
    fade: 'Useful Feature',
    heading: 'Favorite your transforms to find them faster.',
    image: 'new_feature_favorites',
    url: 'https://help.mozartdata.com/docs/december-release-notes#favorites',
  },
  {
    fade: 'Useful Feature',
    heading: 'Tags organize your transforms.',
    image: 'new_feature_tags',
    url: 'https://help.mozartdata.com/docs/january-release-notes#tags',
  },
  {
    fade: 'Useful Feature',
    heading: 'Custom Transform Schedules',
    image: 'new_feature_custom_transform_schedules',
    url: 'https://help.mozartdata.com/docs/december-release-notes#custom-transform-schedules',
  },
  {
    fade: 'Useful Feature',
    heading: 'Slack Notifications',
    image: 'new_feature_slack_notifications',
    url: 'https://help.mozartdata.com/docs/january-release-notes#slack-notifications',
  },

  /*******************************************************************************
   * Group 4:
   * Enumerate "New features", these will probably graduate to Group 3, "Large Features".
   ******************************************************************************/
  {
    fade: 'New Feature',
    heading: 'Run dbt Core on Mozart',
    image: 'new_feature_dbt',
    url: 'https://help.mozartdata.com/docs/dbt-core-integration',
  },
  {
    fade: 'New Feature',
    heading: 'Store and manage your transforms in GitHub',
    image: 'new_feature_github_integration',
    url: 'https://help.mozartdata.com/docs/using-mozart-data-with-github',
  },
  /*******************************************************************************
   * Group 5:
   * Progressively more complicated power user protips.
   ******************************************************************************/
  {
    fade: 'Protip',
    heading: (
      <div>
        <code>SHIFT+?</code> opens keyboard shortcuts.
      </div>
    ),
    image: 'shift_question_mark',
    url: 'https://help.mozartdata.com/docs/power-users-cheat-sheet#keyboard-shortcuts',
  },
  {
    fade: 'Protip',
    heading: (
      <div>
        <code>⌘+Enter</code> runs the query in the editor.
      </div>
    ),
    image: 'shift_question_mark',
    url: 'https://help.mozartdata.com/docs/power-users-cheat-sheet#keyboard-shortcuts',
  },
  {
    fade: 'Protip',
    heading: 'Advanced Search helps you find what you are looking for.',
    image: 'warehouse_advanced_search',
    url: 'https://help.mozartdata.com/docs/power-users-cheat-sheet#advanced-search',
  },
  {
    fade: 'Protip',
    heading: 'Select SQL and Run Selected',
    image: 'run_selected',
    url: 'https://help.mozartdata.com/docs/power-users-cheat-sheet#run-selected',
  },
  {
    fade: 'Protip',
    heading: 'Hover over columns to see sample data.',
    image: 'sample_rows_with_arrow',
    url: 'https://help.mozartdata.com/docs/power-users-cheat-sheet#sample-data',
  },
  {
    fade: 'Protip',
    heading: 'Query distinct column values.',
    image: 'query_distinct_column_values',
    url: 'https://help.mozartdata.com/docs/power-users-cheat-sheet#query-distinct-column-values',
  },
  {
    fade: 'Protip',
    heading: 'Double-Click on tables in the editor to see their table details.',
    image: 'double_click_show_table_details',
    url: 'https://help.mozartdata.com/docs/power-users-cheat-sheet#double-click-editor-table-name',
  },
  {
    fade: 'Protip',
    heading: 'Save query as transform.',
    image: 'save_as_transform',
    url: 'https://help.mozartdata.com/docs/power-users-cheat-sheet#save-as-transform',
  },

  /*******************************************************************************
   * Group 6:
   * Ways to save money.
   ******************************************************************************/
  {
    fade: 'Save Money',
    heading: "Turn down a transform's schedule frequency.",
    image: 'schedule_transforms_less_often',
    url: 'https://help.mozartdata.com/docs/how-to-save-money#schedule-transform-runs-less-often',
  },
  {
    fade: 'Save Money',
    heading: 'Use Incremental Transforms on large tables.',
    image: 'new_feature_incremental_transforms',
    url: 'https://help.mozartdata.com/docs/how-to-save-money#incremental-transforms',
  },
  {
    fade: 'Save Money',
    heading: 'Use Manage Tables to exclude tables and columns that trigger MAR.',
    image: 'pick_tables',
    url: 'https://help.mozartdata.com/docs/how-to-save-money#schedule-transform-runs-less-often',
  },

  /*******************************************************************************
   * Group 7:
   * Promotions
   ******************************************************************************/
  {
    fade: 'Referral Program',
    heading: null,
    image: 'referral_program',
    url: 'https://www.mozartdata.com/referral-program',
  },
];

export const LAST_TIP_KEY = 'protipIndex';

// Start at zero.
// Loop through entire sequence.
// Startover at zero once we get to the end.
const getNextTipIndex = () => {
  // Base case: The user has never seen a tip.
  // Start at zero.
  let nextIndex = 0;

  // If the user has seen a tip before, show the next tip.
  const storedIndex = window.localStorage.getItem(LAST_TIP_KEY);
  if (storedIndex) {
    let lastIndex = JSON.parse(storedIndex);
    nextIndex = (lastIndex + 1) % protips.length;
  }

  // Record the tip we just showed
  trySetItem(LAST_TIP_KEY, nextIndex);

  return nextIndex;
};

const getProtip = () => {
  // Useful debugging hack:
  // return Object.values(protips).filter(t=>t.image==='referral_program')[0];
  return protips[getNextTipIndex()];
};

export { protips, getProtip };
