/**
 * This file contains completions that do not depend on the user's
 * editor contents or list of tables.
 **/
import { autocompleteSnowflakeFunctions } from 'utils/SnowflakeFunctions';
import { autocompleteReservedWords } from 'utils/SnowflakeReservedWords';

import { Completion } from '@codemirror/autocomplete';
import { snippetCompletion } from '@codemirror/autocomplete';

import { AUTOCOMPLETE_SEARCH_BOOST, CustomCompletion } from './autocompleteConstants';
import SnowflakeCompletions from './SnowflakeCompletions.json';

// Give these words higher sorting priority because they are more likely to be used
// that words that start with the same letter and are sorted alphabetically prior to these.
const HIGH_PRIORITY_WORDS = ['from', 'select', 'where'];

// We autocomplete on a subset of the reserved words because small words like "AS" are annoying to autocomplete on AND
// words like "DROP" do not apply to the editor in Mozart's UI.
const keywordCompletions: Completion[] = autocompleteReservedWords.map(
  (keyword: string): Completion => ({
    label: keyword.toUpperCase(),
    type: 'keyword',
    detail: 'Reserved Word',
    boost: HIGH_PRIORITY_WORDS.includes(keyword)
      ? AUTOCOMPLETE_SEARCH_BOOST.keywordHigh
      : AUTOCOMPLETE_SEARCH_BOOST.keywordLow,
  }),
);

const buildSnippet = (label: string, completion: CustomCompletion): CustomCompletion =>
  snippetCompletion(label, completion);

// Takes the list of all Snowflake functions and turns them into snippet completions.
// CodeMirror autocomplete suggestion matching
// https://github.com/codemirror/autocomplete/blob/main/src/filter.tsgithub.com/codemirror/autocomplete/blob/main/src/filter.ts
const snowlflakeFunctionSnippetsCompletions: CustomCompletion[] = SnowflakeCompletions.functions
  // Filter down all the functions to only the ones that make sense to use in the app
  .filter((func) => autocompleteSnowflakeFunctions.includes(func.name))
  .map(({ name, args, desc, url }): CustomCompletion => {
    let arugements = args
      .split(',')
      .map((arg) => arg.replace('\u003c', '${').replace('\u003e', '}'))
      .join(',');

    return buildSnippet(name + '(' + arugements + ')', {
      // args get added to the label when they are presented in addToOptions
      label: name,
      args,
      type: 'function',
      desc,
      url,
      boost: AUTOCOMPLETE_SEARCH_BOOST.functions,
    });
  });

export { keywordCompletions, snowlflakeFunctionSnippetsCompletions };
