import React, { forwardRef, useMemo, Ref } from 'react';

import { some } from 'lodash';

import PipelineVertex, { VertexData } from '../PipelineGraph/PipelineVertex/PipelineVertex';
import {
  getConnectorIcon,
  getIsMissing,
  getVertexIconType,
} from '../PipelineGraph/utils/vertexBasicInfoUtils';
import { PipelineVertex as PipelineVertexType, ErrorColor, VertexShape } from '../useVertexConverter';

import { getTableType } from './utils';

import styles from './PipelineLegend.module.css';

export type LegendVertexData = VertexData & { shouldRender: () => boolean };

interface PipelineLegendProps {
  pipelineVertices: PipelineVertexType[];
  initialVertex: PipelineVertexType | null;
}

const PipelineLegend = forwardRef((props: PipelineLegendProps, ref: Ref<HTMLDivElement>) => {
  const { pipelineVertices, initialVertex } = props;

  const legendVertices: LegendVertexData[] = useMemo(
    () =>
      [
        {
          id: 'legend-unmanaged',
          tableName: 'Connector Table',
          type: VertexShape.Unmanaged,
          iconType: VertexShape.Unmanaged,
          status: ErrorColor.Normal,
          connectorIcon: getConnectorIcon(initialVertex),
          shouldRender: () =>
            some(pipelineVertices, (v) => v.shape === VertexShape.Unmanaged && !getIsMissing(v)),
        },
        {
          id: 'legend-view',
          tableName: 'View',
          type: VertexShape.View,
          iconType: VertexShape.View,
          status: ErrorColor.Normal,
          connectorIcon: null,
          shouldRender: () => some(pipelineVertices, (v) => v.shape === VertexShape.View),
        },
        {
          id: 'legend-csv-upload',
          tableName: 'Uploaded CSV',
          type: VertexShape.CSVUpload,
          iconType: VertexShape.CSVUpload,
          status: ErrorColor.Normal,
          connectorIcon: getConnectorIcon(initialVertex),
          shouldRender: () => some(pipelineVertices, (v) => v.shape === VertexShape.CSVUpload),
        },
        {
          id: 'legend-transform',
          tableName: 'Transform',
          type: VertexShape.Transform,
          iconType: VertexShape.Transform,
          status: ErrorColor.Normal,
          connectorIcon: null,
          shouldRender: () =>
            some(
              pipelineVertices,
              (v) => v.shape === VertexShape.Transform && v.errorColor === ErrorColor.Normal,
            ),
        },
        {
          id: 'legend-snapshot',
          tableName: 'Snapshot',
          type: VertexShape.Snapshot,
          iconType: VertexShape.Snapshot,
          status: ErrorColor.Normal,
          connectorIcon: null,
          shouldRender: () => some(pipelineVertices, (v) => v.shape === VertexShape.Snapshot),
        },
        initialVertex && {
          id: 'legend-current',
          tableName: `Current ${getTableType(initialVertex)}`,
          type: VertexShape.CurrentVertex,
          iconType: getVertexIconType(initialVertex),
          status: initialVertex.errorColor,
          connectorIcon: getConnectorIcon(initialVertex),
          shouldRender: () =>
            (initialVertex.table && initialVertex.table.type !== 'transform') ||
            initialVertex.errorColor === ErrorColor.Normal,
        },
        {
          id: 'legend-failed',
          tableName: 'Failed',
          type: VertexShape.Transform,
          iconType: VertexShape.Transform,
          status: ErrorColor.Danger,
          connectorIcon: null,
          shouldRender: () => some(pipelineVertices, (v) => v.errorColor === ErrorColor.Danger),
        },
        {
          id: 'legend-not-scheduled',
          tableName: 'Not Scheduled',
          type: VertexShape.Transform,
          iconType: VertexShape.Transform,
          status: ErrorColor.NotScheduled,
          connectorIcon: null,
          shouldRender: () => some(pipelineVertices, (v) => v.errorColor === ErrorColor.NotScheduled),
        },
        {
          id: 'legend-never-ran',
          tableName: 'Never Ran',
          type: VertexShape.Transform,
          iconType: VertexShape.Transform,
          status: ErrorColor.NeverRan,
          connectorIcon: null,
          shouldRender: () => some(pipelineVertices, (v) => v.errorColor === ErrorColor.NeverRan),
        },
        {
          id: 'legend-missing',
          tableName: 'Missing',
          type: VertexShape.Transform,
          iconType: VertexShape.Transform,
          status: ErrorColor.Missing,
          connectorIcon: null,
          shouldRender: () => some(pipelineVertices, (v) => v.errorColor === ErrorColor.Missing),
        },
        {
          id: 'legend-failed-ancestor',
          tableName: 'Has Suspect Ancestor',
          type: VertexShape.Transform,
          iconType: VertexShape.Transform,
          status: ErrorColor.Warn,
          connectorIcon: null,
          shouldRender: () => some(pipelineVertices, (v) => v.errorColor === ErrorColor.Warn),
        },
      ].filter((x) => x !== null) as LegendVertexData[], // remove legend-current if no initialVertex. assert to TS that this list has no nulls after the filter
    [initialVertex, pipelineVertices],
  );

  const renderedLegendVertices = useMemo(() => {
    return legendVertices
      .filter((v) => v.shouldRender())
      .map(({ shouldRender, ...v }) => (
        <PipelineVertex key={v.id} {...v} isLegend className={styles.item} />
      ));
  }, [legendVertices]);

  return (
    <div ref={ref} className={styles.container}>
      <div className="flex">{renderedLegendVertices}</div>
    </div>
  );
});

export default PipelineLegend;
