import ClosableBanner, { ClosableBannerProps } from '../ClosableBanner/ClosableBanner';

export interface AbstractPromotionBannerProps extends ClosableBannerProps {}

// All implementations of AbstractPromotionBanner should set the banner's children and implement these props.
export interface ConcretePromotionBannerProps extends Omit<AbstractPromotionBannerProps, 'children'> {}

export const AbstractPromotionBanner = (props: AbstractPromotionBannerProps) => {
  return <ClosableBanner {...props} />;
};

export default AbstractPromotionBanner;
