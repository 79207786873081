import { useHistory } from 'react-router-dom';

import { AppConnector, UserProfile } from 'api/APITypes';
import { SetupState } from 'model_helpers/connectorHelper';
import { warehouseSearch } from 'utils/PathMaker';

import { fivetranConnectors } from '../ConnectorRegistry';

import useConnectorSyncs, { ConnectorSyncsState } from './useConnectorSyncs';
import useDeleteConnectorState, { DeleteConnectorState } from './useDeleteConnectorState';
import { FivetranConnectorSetupState } from './useFivetranConnectorSetup';
import useHistoricalResyncState, { HistoricalResyncState } from './useHistoricalResyncState';
import { LocalSyncingState } from './useLocalSyncingState';
import useSyncFrequencyState, { SyncFrequencyState } from './useSyncFrequencyState';
import useSyncNowState, { SyncNowState } from './useSyncNowState';
import useTogglePausedState, { TogglePausedState } from './useTogglePausedState';

export interface SummaryTabState {
  historicalResync: HistoricalResyncState;
  syncFrequency: SyncFrequencyState;
  connectorSyncs: ConnectorSyncsState;
  togglePaused: TogglePausedState;
  syncNow: SyncNowState;
  deleteConnector: DeleteConnectorState;
  connectorSetup: FivetranConnectorSetupState;
  setupState: SetupState;
  findInWarehouse: () => void;
  goToUsage: () => void;
  isViewer: boolean;
}

export default function useSummaryTabState(
  connector: AppConnector,
  userProfile: UserProfile,
  localSyncState: LocalSyncingState,
  setPollingEnabled: (enabled: boolean) => void,
  connectorSetup: FivetranConnectorSetupState,
  resetPolling: () => void,
): SummaryTabState {
  const isViewer = userProfile.company_role === 'viewer';
  const historicalResyncState = useHistoricalResyncState(
    connector,
    localSyncState,
    resetPolling,
    false,
    isViewer,
  );
  const syncFrequencyState = useSyncFrequencyState(connector);
  const connectorSyncsState = useConnectorSyncs(connector);
  const togglePausedState = useTogglePausedState(connector);
  const syncNowState = useSyncNowState(connector, localSyncState, resetPolling);
  const deleteConnectorState = useDeleteConnectorState(connector, setPollingEnabled);

  const history = useHistory();

  const findInWarehouse = () => {
    analytics.track('ShowConnectorSummaryTab FindInWarehouse');
    const searchInput =
      fivetranConnectors[connector.service]?.syncScope === 'table'
        ? connector.schema
        : `s:${connector.schema}`;
    history.push(warehouseSearch(searchInput));
  };

  const goToUsage = () => {
    // TODO maybe but probably not: deep linking to the connector specific usage
    // TODO change to /usage/connectors when people are on usage v2? solve better somehow?
    analytics.track('ShowConnectorSummaryTab GoToUsage');
    history.push('/usage/connector');
  };

  return {
    connectorSetup,
    connectorSyncs: connectorSyncsState,
    historicalResync: historicalResyncState,
    syncFrequency: syncFrequencyState,
    togglePaused: togglePausedState,
    syncNow: syncNowState,
    deleteConnector: deleteConnectorState,
    setupState: connector.setupState,
    findInWarehouse,
    goToUsage,
    isViewer,
  };
}
