import React from 'react';

import { ScheduleMode } from 'api/tableAPI';
import Switch from 'components/inputs/basic/Switch/Switch';
import RadioTabPanelBox, {
  TabKeyType,
} from 'components/inputs/composite/RadioTabPanelBox/RadioTabPanelBox';
import { TransformTable } from 'pages/tables/ShowTable/ShowTable';

import AllMarkedSettings from './AllMarkedSettings';
import CronSettings from './CronSettings';
import { UnsavedTransform, DependenciesById } from './RunOptionsModal';

interface ScheduleTabProps {
  table: TransformTable;
  unsavedTransform: UnsavedTransform;
  unsavedDependencies: DependenciesById;
  setUnsavedDependencies: React.Dispatch<React.SetStateAction<DependenciesById>>;
  setUnsavedTransform: React.Dispatch<React.SetStateAction<UnsavedTransform>>;
}

export default function ScheduleTab(props: ScheduleTabProps) {
  const { table, unsavedTransform, unsavedDependencies, setUnsavedDependencies, setUnsavedTransform } =
    props;
  const scheduleMode = unsavedTransform.schedule_mode;

  const handleToggleScheduled = () => {
    const newScheduled = !unsavedTransform.scheduled;
    setUnsavedTransform({ ...unsavedTransform, scheduled: newScheduled });
  };

  const handleSetMode = (newMode: string) => {
    setUnsavedTransform({ ...unsavedTransform, schedule_mode: newMode as ScheduleMode });
  };

  // Compute props for RadioTabs
  let tabs: TabKeyType[] = [
    { key: 'all_marked', label: 'After all selected ancestors (recommended)' },
    { key: 'cron', label: 'At a specific time' },
  ];

  return (
    <div className="h-full min-h-0 flex flex-col">
      <div className="text-sm text-pri-gray-500">
        Scheduling automatically runs a transform on the specified schedule. This transform writes the
        results of the query on the Transform tab to table <code>{table.full_name}</code>. Every run will
        overwrite the previous run's results.
      </div>
      <div className="f-row-y-center mt-4 mb-[6px]">
        <Switch
          id="enable-schedule-switch"
          name="enable"
          checked={unsavedTransform.scheduled}
          onChange={handleToggleScheduled}
        />
        <h2 className="text-base font-medium text-sec-blue-gray-500 ml-2">SCHEDULE</h2>
      </div>
      <RadioTabPanelBox
        tabs={tabs}
        selectedTab={scheduleMode}
        enabled={unsavedTransform.scheduled}
        onClick={handleSetMode}
      >
        <div className="w-full h-full p-4">
          {scheduleMode === 'all_marked' && (
            <AllMarkedSettings
              unsavedTransform={unsavedTransform}
              unsavedDependencies={unsavedDependencies}
              setUnsavedDependencies={setUnsavedDependencies}
            />
          )}
          {scheduleMode === 'cron' && (
            <CronSettings
              unsavedTransform={unsavedTransform}
              setUnsavedTransform={setUnsavedTransform}
            />
          )}
        </div>
      </RadioTabPanelBox>
    </div>
  );
}
