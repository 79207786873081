import React from 'react';

import cn from 'classnames';

interface HeaderLabelProps {
  label: string;
  className?: string;
}

export default function HeaderLabel(props: HeaderLabelProps) {
  return (
    <div
      className={cn(
        'inline-block p-2 text-xs font-medium text-sec-blue-gray-700 whitespace-nowrap rounded bg-pri-gray-100',
        props.className,
      )}
    >
      {props.label}
    </div>
  );
}
