/*
Reusable wrapper so inputs will have the same
label and error message styles.

Every form input in this directory should wrap it's content in this component.
*/
import React, { CSSProperties, ReactNode } from 'react';

import cn from 'classnames';

// Inputs that call InputGroup will extend this in their props
export interface InputGroupParentProps {
  name: string;
  key?: React.Key | undefined;
  label?: string;
  superscriptDetail?: string | React.ReactNode;
  superscriptDetailClass?: string;
  subscriptDetail?: string | React.ReactNode;
  subscriptDetailClass?: string;
  /** Element to display right after label text i.e. left side of the input group.
   * E.g. An info icon with a tooltip to give more information about the input.
   */
  postLabelElement?: React.ReactNode;
  /** Action element to display on the other side of the label i.e. right side of the input group.
   * E.g. A link to a different page.
   */
  postLabelActionElement?: React.ReactNode;
  groupClass?: string;
  groupStyle?: React.CSSProperties;
  labelClass?: string;
  labelStyle?: React.CSSProperties;
  error?: string;
}

export interface InputGroupProps extends InputGroupParentProps {
  input: React.ReactNode;
}

export interface InputLabelProps {
  name: string;
  actualLabelClass: string;
  labelStyle: CSSProperties | undefined;
  label: string;
  postLabelElement: ReactNode;
}

export default function InputGroup(props: InputGroupProps) {
  const {
    name,
    input,
    error,
    key,
    label,
    postLabelElement,
    postLabelActionElement,
    groupClass,
    groupStyle,
    labelClass,
    labelStyle,
    superscriptDetail,
    superscriptDetailClass,
    subscriptDetail,
    subscriptDetailClass,
  } = props;

  return (
    <div key={key} className={groupClass} style={groupStyle}>
      <GroupLabel
        name={name}
        hasSuperscriptDetail={!!superscriptDetail}
        label={label}
        labelClass={labelClass}
        labelStyle={labelStyle}
        postLabelElement={postLabelElement}
        postLabelActionElement={postLabelActionElement}
      />
      <GroupSuperscriptDetail
        superscriptDetail={superscriptDetail}
        superscriptDetailClass={superscriptDetailClass}
      />
      {input}
      <GroupError error={error} />
      <GroupSubscriptDetail
        subscriptDetail={subscriptDetail}
        subscriptDetailClass={subscriptDetailClass}
      />
    </div>
  );
}

export interface GroupLabelProps {
  name: string;
  hasSuperscriptDetail?: boolean;
  label?: string;
  labelContainerClass?: string;
  labelClass?: string;
  labelStyle?: React.CSSProperties;
  /** Element to display right after label text i.e. left side of the input group.
   * E.g. An info icon with a tooltip to give more information about the input.
   */
  postLabelElement?: React.ReactNode;
  /** Action element to display on the other side of the label i.e. right side of the input group.
   * E.g. A link to a different page.
   */
  postLabelActionElement?: React.ReactNode;
}

export function GroupLabel(props: GroupLabelProps) {
  const {
    name,
    hasSuperscriptDetail,
    labelContainerClass,
    labelClass,
    labelStyle,
    label,
    postLabelElement,
    postLabelActionElement,
  } = props;
  const actualLabelClass = cn('block text-input-label text-nowrap', labelClass);

  const Label = ({ name, actualLabelClass, labelStyle, label, postLabelElement }: InputLabelProps) => (
    <div
      className={cn(
        'max-w-full h-[18px] f-between',
        { 'mb-1': !hasSuperscriptDetail },
        labelContainerClass,
      )}
    >
      <div className="max-w-full f-row-y-center">
        <label htmlFor={name} className={actualLabelClass} style={labelStyle}>
          {label}
        </label>
        {postLabelElement}
      </div>
      {postLabelActionElement ? <div className="flex-none mb-1">{postLabelActionElement}</div> : null}
    </div>
  );

  return <>{label && <Label {...{ name, actualLabelClass, labelStyle, label, postLabelElement }} />}</>;
}

export interface GroupErrorProps {
  error?: string;
}

export function GroupError(props: GroupErrorProps) {
  const { error } = props;
  return <>{error && <div className="mt-1 text-pri-error-700 text-xs">{error}</div>}</>;
}

export interface GroupSuperscriptDetailProps {
  superscriptDetail?: string | React.ReactNode;
  superscriptDetailClass?: string;
}

export function GroupSuperscriptDetail(props: GroupSuperscriptDetailProps) {
  const { superscriptDetail, superscriptDetailClass } = props;
  const actualSubscriptDetailClass = cn(
    'block text-xs normal-case text-pri-gray-500 mb-1',
    superscriptDetailClass,
  );
  return <>{superscriptDetail && <p className={actualSubscriptDetailClass}>{superscriptDetail}</p>}</>;
}

export interface GroupSubscriptDetailProps {
  subscriptDetail?: string | React.ReactNode;
  subscriptDetailClass?: string;
}

export function GroupSubscriptDetail(props: GroupSubscriptDetailProps) {
  const { subscriptDetail, subscriptDetailClass } = props;
  const actualSubscriptDetailClass = cn(
    'block text-xs normal-case text-pri-gray-500 mt-1',
    subscriptDetailClass,
  );
  return <>{subscriptDetail && <p className={actualSubscriptDetailClass}>{subscriptDetail}</p>}</>;
}
