import { useEffect, useState } from 'react';

import url from 'url';

import API from 'api/API';
import { VersionUserProfile } from 'api/APITypes';

export type UserActionObjectType =
  | 'transform'
  | 'connector'
  | 'table'
  | 'table_test'
  | 'dbt_run_configuration'
  | 'snowflake_user';

export type GenericEventType = 'created' | 'deleted' | 'renamed';

export type ConnectorEventType =
  | 'changed_sync_frequency'
  | 'historical_resync'
  | 'sync_now'
  | 'changed_schema';

export type TableEventType = 'snapshot_toggled' | 'snapshot_manual_run';

export type TransformEventType =
  | 'changed_sql'
  | 'changed_schedule'
  | 'incremental_updated'
  | 'manual_run';

export type DeprecatedEventType = 'toggled' | 'initiated';

type SnowflakeUserType = 'created_snowflake_user';

export type UserActionEventType =
  | GenericEventType
  | ConnectorEventType
  | TableEventType
  | TransformEventType
  | DeprecatedEventType
  | SnowflakeUserType;

export interface UserAction {
  id: string;
  object_type: UserActionObjectType;
  object_id?: string | null;
  object_name: string;
  event_type: UserActionEventType;
  user_name: string;
  event_version: number;
  // maybe just better as an any type
  metadata: { [key: string]: { [key: string]: any } } | { [key: string]: any };
  created_at: string;
  done_by: VersionUserProfile;
  [name: string]: any;
}

export type PagedUserActionsModel = {
  userActions: UserAction[];
  error: string;
  isLoading: boolean;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  eventLocation: PagedLocation;
  onLoadPreviousPage: () => void;
  onLoadNextPage: () => void;
  onLoadFirstPage?: () => void;
  onLoadLastPage?: () => void;
};

export interface UserActionsPagingData {
  count: number;
  next: string | null;
  previous: string | null;
  results: UserAction[];
}

export interface UsePagedUserActionsParams {
  object_type?: UserActionObjectType;
  object_id?: string;
  page_size?: number;
  // Return table, transform, and alert actions for this table from API.
  table?: string;
  page?: number;
}

export type PagedLocation =
  | 'ListUserActions'
  | 'TableUserActionsTab'
  | 'DBTUserActionsTab'
  | 'ConnectorUserActionsModal'
  | 'ConnectorUserActionsTab'
  | 'HomepageUserActionsWidget';

const getUserActionsURL = (urlParams: UsePagedUserActionsParams | undefined) => {
  return url.format({
    pathname: 'api/user_action_event_logs',
    query: {
      ...urlParams,
    },
  });
};

const computeLastPageURL = (urlParams: UsePagedUserActionsParams | undefined, numRecords: number) => {
  // page size of 50 is set in backend/paginations.py as a constant
  const pageSize = urlParams?.page_size || 50;
  const lastPageNum = Math.ceil(numRecords / pageSize);
  return getUserActionsURL({ ...urlParams, page: lastPageNum });
};

const usePagedUserActions = (
  eventLocation: PagedLocation,
  urlParams?: UsePagedUserActionsParams,
): PagedUserActionsModel => {
  const { object_type, object_id, page_size, table, page } = urlParams || {};
  const initialURL = getUserActionsURL(urlParams);
  const [currentUrl, setCurrentUrl] = useState<string>(initialURL);
  const [firstPageURL, setFirstPageURL] = useState<string>(initialURL);
  const [lastPageURL, setLastPageURL] = useState<string | null>(null);
  const [userActionsPagingData, setUserActionsPagingData] = useState<UserActionsPagingData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>('');

  // Whenever the base url props change, reset the first page url, and go back to
  // the first page. We have to null out lastPageURL because we calculate it based
  // on the number of records, which we get after loading the first page
  useEffect(() => {
    const baseURL = getUserActionsURL({ object_type, object_id, page_size, table, page });
    setFirstPageURL(baseURL);
    setLastPageURL(null);
    setCurrentUrl(baseURL);
  }, [object_type, object_id, page_size, table, page]);

  // When we load data, we need to recalculate the last page url if its unset since it depends on both
  // the number of records which comes from the API, and the base params which may change.
  useEffect(() => {
    if (userActionsPagingData && !lastPageURL) {
      setLastPageURL(
        computeLastPageURL(
          { object_type, object_id, page_size, table, page },
          userActionsPagingData.count,
        ),
      );
    }
  }, [lastPageURL, userActionsPagingData, object_type, object_id, page_size, table, page]);

  const handleLoadUserEvents = async (apiUrl: string) => {
    setIsLoading(true);
    const api = new API();
    return api
      .get(apiUrl)
      .then((response) => {
        setUserActionsPagingData(response.data);
        return response.data;
      })
      .catch((_e) => {
        setError('There was a problem loading your User Actions');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Load the desired page of User Events whenever the url changes
  useEffect(() => {
    const fetchUserActions = async () => {
      await handleLoadUserEvents(currentUrl);
    };
    fetchUserActions();
  }, [currentUrl]);

  const handleLoadFirstPage = () => {
    setCurrentUrl(firstPageURL);
    analytics.track(`${eventLocation} LoadFirstPage`);
  };

  // Curry our function so that urlParams are in scope when executed.
  const handleLoadLastPage = () => {
    if (lastPageURL) {
      setCurrentUrl(lastPageURL);
      analytics.track(`${eventLocation} LoadLastPage`);
    }
  };

  const handleLoadPreviousPage = () => {
    if (userActionsPagingData?.previous) {
      setCurrentUrl(userActionsPagingData.previous);
      analytics.track(`${eventLocation} LoadPreviousPage`);
    }
  };

  const handleLoadNextPage = () => {
    if (userActionsPagingData?.next) {
      setCurrentUrl(userActionsPagingData.next);
      analytics.track(`${eventLocation} LoadNextPage`);
    }
  };

  return {
    userActions: userActionsPagingData ? userActionsPagingData.results : [],
    isLoading,
    error,
    hasPreviousPage: !!userActionsPagingData?.previous,
    hasNextPage: !!userActionsPagingData?.next,
    eventLocation,
    onLoadPreviousPage: handleLoadPreviousPage,
    onLoadNextPage: handleLoadNextPage,
    onLoadFirstPage: handleLoadFirstPage,
    onLoadLastPage: handleLoadLastPage,
  };
};

export default usePagedUserActions;
