import { useCallback, useEffect, useState } from 'react';

import API from 'api/API';
import { parseSqlResults } from 'utils/apiResponseFormatter';
import { useSearchFocus } from 'utils/React';

import { SnowflakeUsersTabViewProps } from './SnowflakeUsersTabView/SnowflakeUsersTabView';

export interface SnowflakeUser {
  name: string;
  default_role: string;
  created_on: string;
  last_success_login: string;
  disabled: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface SnowflakeUserSaveableFields {
  default_role?: string;
  disabled?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
}

type ExportUseSnowflakeUser = Omit<
  SnowflakeUsersTabViewProps,
  'snowflakeRoleOptions' | 'snowflakeRoleError' | 'snowflakeRoleLoading'
>;

export default function useSnowflakeUsers() {
  const [snowflakeUsers, setSnowflakeUsers] = useState<SnowflakeUser[]>([]);
  const [snowflakeUsersError, setSnowflakeUsersError] = useState('');
  const [snowflakeUsersLoading, setSnowflakeUsersLoading] = useState(true);
  const [confirmDeleteUser, setConfirmDeleteUser] = useState<string | null>(null);
  const [deletingUser, setDeletingUser] = useState<string | null>(null);
  const [editModalError, setEditModalError] = useState('');
  const [editModalSaving, setEditModalSaving] = useState(false);
  const [editModalUser, setEditModalUser] = useState<SnowflakeUser | null>(null);
  // Set this when you add a user to the list so that the added user's row flashes green so users see it.
  const [addedUserName, setAddedUserName] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [filterRef] = useSearchFocus();

  // Fetch Snowflake users from API when page first loads.
  useEffect(() => {
    const api = new API();
    api
      .get('/api/snowflake_user')
      .then((response) => {
        const structuredRows = parseSqlResults(response.data.results) as unknown as SnowflakeUser[];
        setSnowflakeUsers(structuredRows);
      })
      .catch((error) => {
        setSnowflakeUsersError('Failed to load snowflake users list.');
      })
      .finally(() => {
        setSnowflakeUsersLoading(false);
      });
  }, []);

  const onGenerateUser = useCallback(
    (newUser: SnowflakeUser) => {
      setSnowflakeUsers([newUser, ...snowflakeUsers]);
      analytics.track('ListSnowflakeUsers AddUser');
    },
    [snowflakeUsers],
  );

  // This starts the new user added green flash
  const onCloseCredentialsModal = useCallback((newUser: SnowflakeUser) => {
    setAddedUserName(newUser.name);
    analytics.track('ListSnowflakeUsers CloseCredentialsModal');
  }, []);

  const onEditUser = useCallback((user: SnowflakeUser) => {
    setEditModalUser(user);
    analytics.track('ListSnowflakeUsers OpenUpdateModal');
  }, []);

  const onCloseEditModal = () => {
    setEditModalUser(null);
    analytics.track('ListSnowflakeUsers CloseUpdateModal');
  };

  const onEditModalSave = (username: string, newValues: SnowflakeUserSaveableFields) => {
    setEditModalError('');
    setEditModalSaving(true);
    // Only post the values that are not empty and are not the same as the original values.
    const orginalValues = snowflakeUsers.find((u) => u.name === username);
    const postData: SnowflakeUserSaveableFields = {};
    Object.keys(newValues).forEach((key) => {
      const castKey = key as keyof SnowflakeUserSaveableFields;
      const newVal = newValues[castKey];
      const oldVal = orginalValues?.[castKey];
      if (newVal && newVal !== oldVal) {
        postData[castKey] = newVal;
      }
    });
    const api = new API();
    api
      .patch(`/api/snowflake_user/${username}`, postData)
      .then((response) => {
        const structuredRows = parseSqlResults(response.data.results) as unknown as SnowflakeUser[];
        setSnowflakeUsers(structuredRows);
        setEditModalUser(null);
        analytics.track('ListSnowflakeUsers UpdateUser');
      })
      .catch((error) => {
        setEditModalError('Failed to update snowflake user.');
      })
      .finally(() => {
        setEditModalSaving(false);
      });
  };

  const onDeleteUser = useCallback((name: string) => {
    setConfirmDeleteUser(name);
    analytics.track('ListSnowflakeUsers OpenDeleteUserModal');
  }, []);

  const onConfirmDeleteUser = () => {
    setSnowflakeUsersError('');
    setDeletingUser(confirmDeleteUser);
    const api = new API();
    api
      .delete(`/api/snowflake_user/${confirmDeleteUser}`, {})
      .then(() => {
        setSnowflakeUsers(
          snowflakeUsers.filter((snowflakeUser) => snowflakeUser.name !== confirmDeleteUser),
        );
        analytics.track('ListSnowflakeUsers ConfirmDeleteUser');
      })
      .catch((error) => {
        setSnowflakeUsersError('Failed to delete snowflake user.');
      })
      .finally(() => {
        setDeletingUser(null);
        setConfirmDeleteUser(null);
      });
  };

  const onCancelDeleteUser = () => {
    setConfirmDeleteUser(null);
    analytics.track('ListSnowflakeUsers CancelDeleteUserModal');
  };

  const onFilterChange = (filter: string) => {
    setUserFilter(filter);
  };

  const result: ExportUseSnowflakeUser = {
    // User list related
    snowflakeUsers,
    snowflakeUsersError,
    snowflakeUsersLoading,
    filterRef,
    userFilter,
    onFilterChange,

    // Add user related
    addedUserName,
    onGenerateUser,
    onCloseCredentialsModal,

    // Edit user related
    editModalUser,
    editModalError,
    editModalSaving,
    onEditUser,
    onCloseEditModal,
    onEditModalSave,

    // Delete user related
    confirmDeleteUser,
    deletingUser,
    onDeleteUser,
    onConfirmDeleteUser,
    onCancelDeleteUser,
  };

  return result;
}
