import { ListboxOption } from 'components/inputs/basic/Listbox/Listbox';

import SnowflakeUsersTabView from './SnowflakeUsersTabView/SnowflakeUsersTabView';
import useSnowflakeUsers from './useSnowflakeUsers';

interface SnowflakeUsersTabControllerProps {
  show: boolean;
  snowflakeRoleOptions: ListboxOption[];
  snowflakeRoleError: string;
  snowflakeRoleLoading: boolean;
}

export default function SnowflakeUsersTabController(props: SnowflakeUsersTabControllerProps) {
  const { show, snowflakeRoleOptions, snowflakeRoleError, snowflakeRoleLoading } = props;

  const snowflakeUsersProps = useSnowflakeUsers();

  if (!show) {
    return null;
  }

  return (
    <SnowflakeUsersTabView
      {...snowflakeUsersProps}
      snowflakeRoleOptions={snowflakeRoleOptions}
      snowflakeRoleError={snowflakeRoleError}
      snowflakeRoleLoading={snowflakeRoleLoading}
    />
  );
}
