import PageButton from 'components/inputs/basic/Button/PageButton';

interface PagedButtonsProps {
  className?: string;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  onLoadPreviousPage: () => void;
  onLoadNextPage: () => void;
  onLoadFirstPage?: () => void;
  onLoadLastPage?: () => void;
}

const PageButtons = ({
  className,
  hasPreviousPage,
  hasNextPage,
  onLoadPreviousPage,
  onLoadNextPage,
  onLoadFirstPage,
  onLoadLastPage,
}: PagedButtonsProps) => (
  <div className={className}>
    {onLoadFirstPage && (
      <PageButton
        direction="first"
        hasPage={hasPreviousPage}
        onClick={onLoadFirstPage}
        className="mr-2"
      />
    )}
    <PageButton direction="prev" hasPage={hasPreviousPage} onClick={onLoadPreviousPage} />
    <PageButton direction="next" hasPage={hasNextPage} onClick={onLoadNextPage} className="ml-1" />
    {onLoadLastPage && (
      <PageButton direction="last" hasPage={hasNextPage} onClick={onLoadLastPage} className="ml-2" />
    )}
  </div>
);

export default PageButtons;
