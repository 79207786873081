import React, { useCallback } from 'react';

import { SchemaWithIconType } from 'components/primitives/icons/SchemaIcon/SchemaIcon';
import { ROW_HEIGHT } from 'components/query/TableExplorer/TableRow/TableRow';

import Button from '../../Button/Button';

interface SchemaRowHoverOverlayProps {
  hoveredIndex: number;
  schemaWithIconType: SchemaWithIconType;
  picked: boolean;
  overlayRight: number;
  onPickSchema(schemaWithIconType: SchemaWithIconType): void;
}

const SchemaRowHoverOverlay = React.memo((props: SchemaRowHoverOverlayProps) => {
  const { hoveredIndex, schemaWithIconType, picked, overlayRight, onPickSchema } = props;

  const handlePickSchema = useCallback(
    (e: any) => {
      e.stopPropagation();
      onPickSchema(schemaWithIconType);
    },
    [schemaWithIconType, onPickSchema],
  );

  return (
    <div
      className="tt-table-buttons"
      style={{
        height: `${ROW_HEIGHT}px`,
        top: `${hoveredIndex * ROW_HEIGHT}px`,
        right: `${overlayRight}px`,
      }}
    >
      <div className={'f-row-y-center'}>
        <Button onClick={handlePickSchema} size="small" variant="darkDullAction" className="mr-1">
          {picked ? 'Remove' : 'Select'}
        </Button>
      </div>
    </div>
  );
});

export default SchemaRowHoverOverlay;
