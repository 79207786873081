interface ExternalLinkProps {
  href: string;
  children: React.ReactNode;
}

export default function ExternalLink(props: ExternalLinkProps) {
  const { href, children } = props;
  return (
    <a
      className="font-medium text-sec-blue-light-700"
      rel="noopener noreferrer"
      target="_blank"
      href={href}
    >
      {children}
    </a>
  );
}
