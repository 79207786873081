/**
 * We need a resuable validator that can be reused on:
 * 1. The FlowchartGraph
 * 2. The vertex editing forms
 * 3. Before important operations like a save
 *
 * This file is a place holder until I can build something better with
 * yup or turn FlowchartQueryModel and FlowchartQueryVertex into classes
 * with validate methods. FlowchartQueryModel would recursively call
 * FlowchartQueryVertex.validate() on all of it's vertices as well as
 * check for global validation as we are doing below.
 *
 * Err...There are two notions of "Valid":
 * 1. Are a vertex's sockets satisfied so that I can open the edit form?
 *    This is currently implemented at FlowchartQueryModel.canEdit()
 * 2. Are all of my vertex's edit forms correctly filled out so that I can generate SQL?
 *    This is currently implemented in an adhoc way in each form.
 */
import { FlowchartQueryModel, FlowchartVertex, getAncestorModelFrom } from './FlowchartQueryModel';

export type ErrorDic = { [inputKey: string]: string | ErrorDic };
export interface VertexErrorsPair {
  vertex: FlowchartVertex;
  errors: string[] | ErrorDic;
}
export interface ErrorObject {
  vertex: VertexErrorsPair[];
  global: string[];
}

export function validate(model: FlowchartQueryModel): ErrorObject | null {
  // Base Case: No errors
  let result: ErrorObject = {
    vertex: [],
    global: [],
  };

  // Validate each vertex
  model.vertices.forEach((vertex) => {
    const errors = vertex.validate();
    if (errors) {
      result.vertex.push({
        vertex,
        errors,
      });
    }
  });

  // Make sure there is one childless vertex to calculate SQL for when doing a save-like-operation.
  // We don't know the source of truth if there are two or more vertices without children.
  const childlessVertices = model.vertices.filter((v) => v.childID === null);
  // If you start validating from a vertex in the middle of the flowchart,
  // childlessVertices.length might be zero.
  if (childlessVertices.length > 1) {
    result.global = ['You must have exactly one childless vertex.'];
  }

  if (result.vertex.length === 0 && result.global.length === 0) {
    return null;
  }

  return result;
}

// Prune off any vertices that aren't ancestors of the given vertex and valdiate
// the remaining vertices.
export function validateFrom(model: FlowchartQueryModel, vertex: FlowchartVertex): ErrorObject | null {
  return validate(getAncestorModelFrom(model, vertex));
}
