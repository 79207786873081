/*
This component should be used on most routes.
It always renders the mainContent.
It might render a DraggablePanes containing the mainContent and a sidebar.
*/
import { useRef } from 'react';

import {
  DefaultSizes,
  DEFAULT_SIZES,
  HorizontalDraggablePanesState,
} from 'components/layouts/containers/draggable_panes/ControlledDraggablePanes/ControlledDraggablePanes';
import ControlledDraggablePanes from 'components/layouts/containers/draggable_panes/ControlledDraggablePanes/ControlledDraggablePanes';
import { PixelSize } from 'utils/cssMath';

import useComponentSize from '@rehooks/component-size';

import FloatingSidebarWrapper from './FloatingSidebarWrapper';
import { PickedSidebar } from './usePickSidebar';

export interface SplitPanesSidebarLayoutProps {
  isWorkspace: boolean;
  mainContent: React.ReactElement;
  showSidebar: boolean;
  sidebarProps: PickedSidebar;
  hookState: HorizontalDraggablePanesState;
  leftMinWidth: PixelSize;
}

// This is a short circuited version of SplitPanesSidebarLayout
// that skips a lot of unnecessary work if there isn't a sidebar to render.
export default function SplitPanesSidebarLayout(props: SplitPanesSidebarLayoutProps) {
  const { isWorkspace, mainContent, showSidebar } = props;
  const mainContentRef = useRef<HTMLDivElement | null>(null);

  // Size the mainContentDiv based on what kind of route this.
  const mainContentClass = isWorkspace
    ? 'w-full h-full min-h-full max-h-full f-col flex-grow'
    : 'min-h-full';
  const mainContentDiv = (
    <div id="main-content-div" className={mainContentClass} ref={mainContentRef}>
      {mainContent}
      {/* 02/29/2024 - John: Keep my debugging code around for a couple of months until we are confident we debugged all of the issues */}
      {/* <div id="default-test-div" className="w-[500px] h-[200px] bg-[yellow]">Default Test Div</div> */}
      {/* <div id="workspace-test-div" className="w-full h-full flex flex-col flex-grow">
        <div id="block-test-div" className="w-full h-full overflow-auto">
          <div className="w-[500px] h-[2000px] bg-[yellow]">Workspace Test Div</div>
        </div>
      </div> */}
    </div>
  );

  // If we do not need the sidebar, it's time to short circuit for two reasons:
  // 1. The full version of this component has resize listeners that cause extra rerenders
  //    to accruately size the sidebar.
  // 2. Normally, <ControlledDraggablePanes/> can initialize with a null second component,
  //    but we do not know our sidebars width until the sidebar is picked, which takes
  //    a couple of render cycles.
  //    The code was set to default to a sidebar width of zero
  //    which messes up <ControlledDraggablePanes/>'s calculations.
  //    If this code is edited in the future we need to set a non-zero default width.
  if (!showSidebar) {
    return mainContentDiv;
  }

  return (
    <SplitPanesSidebarLayoutTwoColumns
      {...props}
      mainContentDiv={mainContentDiv}
      mainContentRef={mainContentRef}
    />
  );
}

interface SplitPanesSidebarLayoutTwoColumnsProps {
  mainContentDiv: React.ReactElement;
  mainContentRef: React.MutableRefObject<HTMLDivElement | null>;
  showSidebar: boolean;
  sidebarProps: PickedSidebar;
  hookState: HorizontalDraggablePanesState;
  leftMinWidth: PixelSize;
}

function SplitPanesSidebarLayoutTwoColumns(props: SplitPanesSidebarLayoutTwoColumnsProps) {
  const { showSidebar, sidebarProps, hookState, leftMinWidth, mainContentDiv, mainContentRef } = props;
  const { sidebar, minWidth = 0 } = sidebarProps || {};
  const layoutRef = useRef<HTMLDivElement | null>(null);

  /*******************************************************************************
   * START GROSS HEIGHT HACKS
   * 1. The layoutRef div's height is going to be the max height of mainContent and the sidebar.
   *    Since layoutRef div's height is not explicitly set using `height: 100%` in a child div will not work.
   *    This is very annoying and creates lots of complications.
   * 2. <ControlledDraggablePanes/> is implemented as a row direction flexbox.
   *    As far as I can tell, flexboxes will not expand to take up avaialable space along their cross axis,
   *    unless the height of an ancestor is explicitly set as outlined in the first comment.
   *    So, in this case the flexbox in the sidebar will not use all of it's available height.
   *    It will render as the height of it's contents.
   *    This is why we are explicitly setting the sidebar's minHeight to equal the
   *    height of the the mainContent div after the mainContent div renders a variable height.
   ******************************************************************************/

  // Trigger a rerender when the mainContent changes size.
  // This fixes a bug where the sidebar does not take up the whole height
  // of the layoutRef div after the mainContent component changes height.
  useComponentSize(mainContentRef);

  // Content should be at least as tall as the browser viewport minus the header(s) and footer.
  const getMaxHeight = () => {
    const layoutHeight = layoutRef.current?.offsetHeight || 0;
    const mainContentHeight = mainContentRef.current?.offsetHeight || 0;
    const maxHeight = Math.max(layoutHeight, mainContentHeight);
    return maxHeight;
  };

  // Inject styles into the <ControlledDraggablePanes/> mainContent div so it will be
  // as tall as the viewport minus the header(s) and footer.
  const getDefaultSizes = (): DefaultSizes => {
    const maxHeight = getMaxHeight();
    const minHeight = maxHeight > 0 ? `${maxHeight}px` : DEFAULT_SIZES.minHeight;
    const defaultSizes: DefaultSizes = { ...DEFAULT_SIZES, minHeight };
    return defaultSizes;
  };

  // Inject styles into the <ControlledDraggablePanes/> secondChild div so it will be
  // as tall as the viewport minus the header(s) and footer or the mainContent.
  const getSecondChildStyles = (): React.CSSProperties => {
    const maxHeight = getMaxHeight();
    const minHeight = maxHeight > 0 ? `${maxHeight}px` : '100%';
    const cssProps: React.CSSProperties = {
      height: '100%',
      minHeight,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      zIndex: 901, // Make sure second column renders ontop of other content.
    };
    return cssProps;
  };
  /*******************************************************************************
   * END GROSS HEIGHT HACKS
   ******************************************************************************/

  /*
  03/04/2024:
  In a dev env when the CompanySetupGuide is enabled, 
  this `<ControlledDraggablePanes/>` with a mainContentDiv of `<ListConnectors/>' causes:
  Error: ResizeObserver loop completed with undelivered notifications.
  See more detailed explanation in ListConnectors.tsx.
  This could happen on other pages that render fractional element heights.
  */
  return (
    <div id="split-panes-sidebar-layout" className="w-full h-full f-col flex-grow" ref={layoutRef}>
      <ControlledDraggablePanes
        id="split-panes-sidebar-layout-panes"
        leftMinWidth={leftMinWidth}
        rightMinWidth={`${minWidth}px`}
        className="min-h-full"
        getDefaultSizes={getDefaultSizes}
        getSecondChildStyles={getSecondChildStyles}
        {...hookState}
      >
        {mainContentDiv}
        {showSidebar && <FloatingSidebarWrapper>{sidebar}</FloatingSidebarWrapper>}
      </ControlledDraggablePanes>
    </div>
  );
}
