import { createContext } from 'react';

import { AggTable } from 'api/APITypes';

export interface TableContextInterface {
  tables: AggTable[];
  isLoading: boolean;
  error: string;
  isLocal: boolean;
  updateTables: (newTables: AggTable[]) => void;
  removeTable: (tableToRemove: AggTable) => void;
}

export const TableContext = createContext<TableContextInterface>({
  tables: [],
  isLoading: false,
  error: '',
  isLocal: false,
  updateTables: () => {},
  removeTable: () => {},
});
