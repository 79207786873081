/*******************************************************************************
 * This is the top level callable component of the CommandPalette feature.
 * It defines all the commands and initializes the component.
 ******************************************************************************/
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { queryTable } from 'components/query/queryUtil';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { TabKey } from 'pages/tables/ShowTable/ShowTable';

import CommandPaletteHeader from './CommandPaletteHeader';
import CommandPaletteRow from './CommandPaletteRow';
import CommandPaletteTrigger from './CommandPaletteTrigger';
import useConnectorCommands from './commands/useConnectorCommands';
import useDbtJobCommands, { DbtJobCommand } from './commands/useDbtJobCommands';
import useHelpCommands, { HelpCommand } from './commands/useHelpCommands';
import usePathCommands, { PathCommand } from './commands/usePathCommands';
import useTableCommands, { TableCommand } from './commands/useTableCommands';
import MozartCommandPalette, { Command, CommandType } from './MozartCommandPalette';

import mozartTheme from './MozartTheme.module.css';

/*******************************************************************************
 * Types for Commands related to Mozart Objects
 ******************************************************************************/

export interface QueryTableCommand extends TableCommand, PathCommand {}

// Stores the previously executed command when doing compound commands
// with the `commandPath`.
export interface CommandPathItem extends Omit<Command, 'command'> {}

/*******************************************************************************
 * CommandPalette Component
 ******************************************************************************/
interface CommandPaletteProps {
  setShowHotkeys: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CommandPalette(props: CommandPaletteProps) {
  const history = useHistory();
  const location = useLocation();
  const [commandPath, setCommandPath] = useState<CommandPathItem[]>([]); // Stores the list of previously executed commands when doing compound commands.
  const [commands, setCommands] = useState<Command[]>([]); // The list of commands searchable in the CommandPalette.
  const commandPathRef = useRef<CommandPathItem[]>([]); // Access to commandPath for useCallback functions so updates don't trigger rerenders.
  const pushCommandPathRef = useRef((command: CommandPathItem) => false); // Access to add to commandPath for useCallback functions so updates don't trigger rerenders.
  // The MozartCommandPalette tells us we are open.
  // The child component passing state to it's parent is not ideal.
  // This is a hack to deal with the fact that react-command-palette inputs
  // can change the commandPath and list of commands.
  // We defer expensive computations(ie making commands based on list of objects from the API) until we are open.
  const [isOpen, setIsOpen] = useState(false);
  const { setShowHotkeys } = props;
  const { tables, allLoaded, anyError } = useContext(TableModelsContext);

  /*******************************************************************************
   * Navigation Command Functions
   ******************************************************************************/
  const openInternalPath = useCallback(
    function (path: string, modKeyPressed: boolean) {
      if (modKeyPressed) {
        window.open(path, '_blank', 'noopener,noreferrer');
      } else {
        history.push(path);
      }
    },
    [history],
  );

  const openExternalPath = useCallback(function (path: string, modKeyPressed: boolean) {
    if (modKeyPressed) {
      window.open(path, '_blank', 'noopener,noreferrer');
    } else {
      window.location.href = path;
    }
  }, []);

  const openPathCommand = useCallback(
    function (this: PathCommand, modKeyPressed: boolean) {
      if (this.path.slice(0, 1) === '/') {
        openInternalPath(this.path, modKeyPressed);
      } else {
        openExternalPath(this.path, modKeyPressed);
      }
      return true;
    },
    [openInternalPath, openExternalPath],
  );

  // Command that goes to table.
  const openTablePath = useCallback(
    function (this: TableCommand, modKeyPressed: boolean) {
      const { type, id } = this.table;
      let path = `/tables/${id}`;

      if (commandPathRef.current.length === 1) {
        const commandType = commandPathRef.current[0].type;

        // Command path is following a command that applies to all types of tables.
        if (commandType.slice(0, 6) === 'table_') {
          const tableTab = commandType.slice(6);
          path += `/${tableTab}`;
        }
        // Command path is following a command that applies to just transforms.
        else if (commandType.slice(0, 10) === 'transform_') {
          const transformTab = commandType.slice(10);
          path += `/${transformTab}`;
        }
      } else if (type === 'transform') {
        path += '/transform';
      }

      openInternalPath(path, modKeyPressed);
      return true;
    },
    [openInternalPath],
  );

  // Command that goes to dbt job.
  const openDbJobPath = useCallback(
    function (this: DbtJobCommand, modKeyPressed: boolean) {
      const { id } = this.dbtJob;
      let path = `/dbt/jobs/${id}`;

      openInternalPath(path, modKeyPressed);
      return true;
    },
    [openInternalPath],
  );

  /*******************************************************************************
   * Tools for building Compound Commands:
   * 1A. The first command appends to the `commandPath`.
   * 1B. On the next render, a useEffect() changes the list of available commands
   *     based on the commandPath.
   * 2.  The second command does a normal command and resets the CommandPalette.
   ******************************************************************************/
  // Update some refs so useCallback functions won't be redefined on every render.
  useEffect(() => {
    commandPathRef.current = commandPath;
    pushCommandPathRef.current = (commandPathItem: CommandPathItem) => {
      setCommandPath([...commandPath, commandPathItem]);
      return false;
    };
  }, [commandPath]);

  // Add path command to the header's `commandPath`.
  // A useEffect will then recompute the list of available commands.
  const createCommandPathCommand = useCallback((name: string, type: CommandType): Command => {
    return {
      name,
      type,
      command: () => {
        return pushCommandPathRef.current({ name, type });
      },
    };
  }, []);

  /*******************************************************************************
   * Compute Command types in hooks
   ******************************************************************************/
  const helpCommands = useHelpCommands(openExternalPath);
  const pathCommands = usePathCommands(openPathCommand);
  const { tableCommands, tableTabCommands, pickTablesForTab } = useTableCommands(
    isOpen,
    openTablePath,
    createCommandPathCommand,
  );
  const connectorCommands = useConnectorCommands(isOpen, openPathCommand);
  const dbtJobCommands = useDbtJobCommands(isOpen, openDbJobPath);

  /*******************************************************************************
   * One off Compound Commands not built in hooks.
   ******************************************************************************/
  const listAllHelpCommands = useMemo<Command>(
    () => createCommandPathCommand('List Help Pages', 'list_help'),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const queryTables = useMemo<Command>(
    () => createCommandPathCommand('Query Table', 'query_tables'),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  /*******************************************************************************
   *  One off Normal Commands not built in hooks.
   ******************************************************************************/
  const hotKeys = useMemo<HelpCommand>(() => {
    return {
      name: 'Keyboard Shortcuts/Hotkeys',
      type: 'help',
      url: 'N/A',
      path: 'N/A',
      hotKeys: 'Shift+?',
      command: () => {
        setShowHotkeys(true);
        return true;
      },
    };
  }, [setShowHotkeys]);

  /*******************************************************************************
   * Compute the commands to show at this point in time.
   * EVERYTHING IN THIS METHOD IS ORDERED FROM FIRST TO LAST.
   ******************************************************************************/
  // The default set of commands if the commandPath array is empty.
  const defaultCommands = useMemo<Command[]>(() => {
    if (!isOpen) {
      return [];
    }

    let commandsShownAfterTablesLoad: Command[] = [];
    if (allLoaded && !anyError) {
      commandsShownAfterTablesLoad = [...tableTabCommands, queryTables];
    }

    return [
      ...pathCommands,
      ...commandsShownAfterTablesLoad,
      listAllHelpCommands,
      ...helpCommands,
      hotKeys,
      ...tableCommands,
      ...dbtJobCommands,
      ...connectorCommands,
    ];
  }, [
    isOpen,
    allLoaded,
    anyError,
    listAllHelpCommands,
    helpCommands,
    tableTabCommands,
    queryTables,
    pathCommands,
    hotKeys,
    tableCommands,
    connectorCommands,
    dbtJobCommands,
  ]);

  // Set the list of commands based on the commandPath.
  // Then sort the list of commands based on the app route.
  useEffect(() => {
    // Do not do anything expensive if the command palette is not open.
    // Return zero commands.
    if (!isOpen) {
      setCommands([]);
      return;
    }

    // There is no command in the commandPath by default.
    // Use the default commands unless there is an item in the commandPath.
    let actualCommands = defaultCommands;

    // The commandPath has a command.
    // Set the list of commands based on the commandPath item.
    // At present we only support one command in the path.
    // This code was written with the intention of one day supporting multiple commands like:
    // /open_in_a_new_window/go_to_lineage_tab/table_name
    if (commandPath.length === 1) {
      const commandType = commandPath[0].type;
      if (commandType === 'list_help') {
        actualCommands = helpCommands;
      } else if (commandType.slice(0, 6) === 'table_') {
        const tab = commandType.slice(6) as TabKey;
        actualCommands = pickTablesForTab(tab);
      } else if (commandType === 'query_tables') {
        const queryTableCommands = tables.map<QueryTableCommand>((table) => ({
          name: table.full_name,
          type: 'query_table',
          table,
          path: queryTable(table.full_name, true),
          command: openPathCommand,
        }));
        actualCommands = queryTableCommands;
      } else {
        // Unknown condition. Reset.
        setCommandPath([]);
      }
    }

    // Reorder the commands based on route.
    // If the command is relevant to the current URL path or usecase move it
    // to the start of the list.
    const first: Command[] = [];
    const last: Command[] = [];
    for (const c of actualCommands) {
      let isContextRelevant = false;

      // Sort relevant path commands to start of list.
      if (c.type === 'path') {
        const pc = c as unknown as PathCommand;
        const contextAwarePaths: { [key: string]: string } = {
          '/transforms/add': '/warehouse',
          '/connectors/add': '/connectors',
          '/users/add': '/users',
        };
        const pathToShowCommandOn = contextAwarePaths[pc.path];
        if (pathToShowCommandOn && location.pathname.includes(pathToShowCommandOn)) {
          isContextRelevant = true;
        }
      }

      // Sort relevant help command to start of list.
      if (c.type === 'help') {
        const hc = c as unknown as HelpCommand;
        if (location.pathname.includes(hc.path)) {
          isContextRelevant = true;
        }
      }

      if (isContextRelevant) {
        first.push(c);
      } else {
        last.push(c);
      }
    }

    const sortedCommands = [...first, ...last];
    setCommands(sortedCommands);
  }, [
    isOpen,
    defaultCommands,
    helpCommands,
    tableCommands,
    pickTablesForTab,
    commandPath,
    openPathCommand,
    tables,
    location.pathname,
  ]);

  const goBackOnePathCommand = useCallback(
    function () {
      setCommandPath(commandPath.slice(0, -1));
    },
    [commandPath],
  );

  const resetCommandPath = useCallback(function () {
    setCommandPath([]);
  }, []);

  return (
    <MozartCommandPalette
      commands={commands}
      setIsOpen={setIsOpen}
      header={CommandPaletteHeader({
        commandPath,
      })}
      renderCommand={CommandPaletteRow}
      closeOnSelect={true}
      resetInputOnOpen={true}
      placeholder="Type a command"
      hotKeys="mod+p"
      maxDisplayed={50}
      trigger={<CommandPaletteTrigger />}
      commandPath={commandPath}
      backOneCommand={goBackOnePathCommand}
      resetCommandPath={resetCommandPath}
      theme={{
        modal: mozartTheme.modal,
        overlay: mozartTheme.overlay,
        header: mozartTheme.header,
        container: mozartTheme.container,
        content: mozartTheme.content,
        containerOpen: mozartTheme.containerOpen,
        input: mozartTheme.input,
        inputOpen: mozartTheme.inputOpen,
        inputFocused: mozartTheme.inputFocused,
        spinner: mozartTheme.spinner,
        suggestionsContainer: mozartTheme.suggestionsContainer,
        suggestionsContainerOpen: mozartTheme.suggestionsContainerOpen,
        suggestionsList: mozartTheme.suggestionsList,
        suggestion: mozartTheme.suggestion,
        suggestionFirst: mozartTheme.suggestionFirst,
        suggestionHighlighted: mozartTheme.suggestionHighlighted,
        trigger: mozartTheme.trigger,
      }}
    />
  );
}
