import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function AIDisclaimerInfoIcon() {
  const content = (
    <div>
      <ul className="list-disc m-auto pl-4">
        <li>
          Mozart AI provides table descriptions based on data structure and SQL text. Verify for accuracy
          and context-specific nuance before saving.
        </li>
        <li>
          OpenAI accesses table metadata and SQL text to generate the table descriptions, but will not
          access the contents within the dataset. OpenAI may record any information provided in the SQL
          text, so please omit any PII or sensitive data not intended for third-party sharing.
        </li>
      </ul>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon containerClass="pt-1 ml-1" content={content} popoverProps={popoverProps} />;
}
