import { useState } from 'react';

import { useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import { Connector } from 'api/APITypes';
import { Dashboard, ReportAvailable } from 'api/dashboardAPI';
import { useUserProfile } from 'context/AuthContext';

import { spreadsheetPath } from '../pathUtils';
import { trackDashboard } from '../reportUtils';
import useDashboardMutations from '../useDashboardMutations';

import ShowReportView from './ShowReportView';

interface ShowReportControllerProps {
  connector: Connector;
  dashboard: Dashboard;
  reportsAvailable: ReportAvailable[];
}

export default function ShowReportController(props: ShowReportControllerProps) {
  useTitle('Show Report');
  const { connector, dashboard, reportsAvailable } = props;

  const navigate = useNavigate();
  const { userProfile } = useUserProfile();
  const { deleteLoading, deleteError, deleteDashboard } = useDashboardMutations(connector, dashboard);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleOpenSpreadsheet = () => {
    if (dashboard.gsheet_spreadsheet_id) {
      window.open(spreadsheetPath(dashboard.gsheet_spreadsheet_id), '_blank', 'noopener,noreferrer');
      trackDashboard('ReportBuilderShowReport OpenReport', connector, dashboard);
    }
  };

  const handleBackToConnectors = () => {
    trackDashboard('ReportBuilderShowReport BackToListConnectors', connector, dashboard);
    navigate('/connectors');
  };

  const handleDeleteAttempted = () => {
    setShowConfirmDelete(true);
    trackDashboard('ReportBuilderShowReport OpenConfirmDelete', connector, dashboard);
  };

  const handleDeleteCanceled = () => {
    setShowConfirmDelete(false);
    trackDashboard('ReportBuilderShowReport CancelConfirmDelete', connector, dashboard);
  };

  const handleDeleteConfirmed = () => {
    deleteDashboard().then(() => {
      trackDashboard('ReportBuilderShowReport DeleteDashboard', connector, dashboard);
      navigate('/connectors');
    });
  };

  return (
    <ShowReportView
      connector={connector}
      dashboard={dashboard}
      reportsAvailable={reportsAvailable}
      showConfirmDelete={showConfirmDelete}
      deleting={deleteLoading}
      deleteError={deleteError}
      userProfile={userProfile}
      onOpenSpreadsheet={handleOpenSpreadsheet}
      onBackToConnectors={handleBackToConnectors}
      onDeleteAttempted={handleDeleteAttempted}
      onDeleteCanceled={handleDeleteCanceled}
      onDeleteConfirmed={handleDeleteConfirmed}
    />
  );
}
