import { AggTable } from 'api/tableAPI';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import TransformRunIcon from 'components/primitives/icons/TransformRunIcon/TransformRunIcon';

interface StatusCellProps {
  table: AggTable | null;
}

const StatusCell = (props: StatusCellProps) => {
  const { table } = props;

  const run = table?.transform ? table.transform.last_completed_run : null;

  return (
    <td>
      <div className="f-center">
        {run ? <TransformRunIcon run={run} /> : <StatusIcon status="waiting" />}
      </div>
    </td>
  );
};

export default StatusCell;
