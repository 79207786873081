import { useContext } from 'react';

import { useTitle } from 'react-use';

import { TableModelsContext } from 'model_layer/TableModelsContext';

import usePagedUserActions from '../usePagedUserActions';

import ListUserActionsView from './ListUserActionsView';

const ListUserActionsController = () => {
  useTitle('User Actions');
  const tableModelContext = useContext(TableModelsContext);
  const { isLoading, ...pagedProps } = usePagedUserActions('ListUserActions');

  // COMMENTARY:
  // We need to know if the tables have been deleted or not so we can link to it or not.
  // We can either alter the API to return if the model still exists or
  // decide if it exists or not based on if it's stored in the tableModelContext.
  // Linking to it won't work unless the object is in the tableModelContext, so run with this?
  return <ListUserActionsView isLoading={isLoading && tableModelContext.allLoaded} {...pagedProps} />;
};

export default ListUserActionsController;
