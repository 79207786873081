import { QueryClient } from 'react-query';

import { Dashboard, LIST_DASHBOARDS } from 'api/dashboardAPI';
import { patchOrAppend } from 'utils/Array';

function create(queryClient: QueryClient, dashboard: Dashboard) {
  queryClient.setQueryData<Dashboard[]>(LIST_DASHBOARDS, (old) => {
    return old ? [...old, dashboard] : [dashboard];
  });
}

function update(queryClient: QueryClient, dashboard: Dashboard) {
  queryClient.setQueryData<Dashboard[]>(LIST_DASHBOARDS, (old) => {
    const newDashboards = patchOrAppend(old || [], dashboard, (d: Dashboard) => d.id === dashboard.id);
    return newDashboards;
  });
}

function deleteDashboard(queryClient: QueryClient, dashboard: Dashboard) {
  queryClient.setQueryData<Dashboard[]>(LIST_DASHBOARDS, (old) => {
    return (old || []).filter((d) => d.id !== dashboard?.id);
  });
}

const dashboardCache = {
  create,
  update,
  delete: deleteDashboard,
};

export default dashboardCache;
