import GenerateSnowflakeUser from 'components/business_logic/GenerateSnowflakeUser/GenerateSnowflakeUser';
import CodeCopy, { editorHeight } from 'components/inputs/composite/CodeCopy/CodeCopy';

import ExternalLink from '../ExternalLink';
import { Field } from '../fieldUtils';
import { IntegrationLayoutProps } from '../IntegrationSnowflakeConfig';
import SnowflakeConfig from '../SnowflakeConfig';
import Steps from '../Steps';

const braze_test = `SELECT
  SYSDATE() as UPDATED_AT,
  'test@test.com' as EXTERNAL_ID,
  '{"id":1,"name":"Alice"}' as payload`;

const BrazeLayout = (props: IntegrationLayoutProps) => {
  const { integration, values } = props;

  const fields: Field[] = [
    { uiName: 'Snowflake Account Locator', key: 'base_account_name' },
    { uiName: 'Warehouse', key: 'warehouse' },
    { uiName: 'Database', key: 'database' },
  ];

  return (
    <>
      <Steps>
        <li>
          If Mozart created your Snowflake database for you, you will not have sufficient priviliges to
          run the command in the final step of this process. It is recommended that you contact Mozart
          Support and ask to schedule a call with someone that can "alter users" so they can run this
          command for you when you reach that step in the process. If you are using Mozart on your own
          Snowflake database and you have admin privileges on that database, you may proceed.
        </li>
        <li>
          Go to <ExternalLink href="https://dashboard.braze.com/">{integration.uiName}</ExternalLink>.
        </li>
        <li>
          On the left navigation bar under Integrations, select <code>Technology Partners</code>.
        </li>
        <li>
          Search and select <code>Snowflake</code> from the All Technology Partners box.
        </li>
        <li>
          The{' '}
          <ExternalLink href="https://www.braze.com/docs/user_guide/data_and_analytics/user_data_collection/cloud_ingestion/snowflake/">
            Snowflake setup instructions
          </ExternalLink>{' '}
          instruct you to create a new database, schema, role, and user for Braze to use. You should be
          able to use the warehouse and user Mozart already created for you. So you can skip these steps.
        </li>
        <li>
          <span>
            You do need some data to sync. In Mozart, create a transform called{' '}
            <code>braze_users_attributes_sync</code>, give it the following SQL, and run the transform.
            This is just sample SQL to prove the Braze sync is working. You can change the transform's
            SQL to return real data later.
          </span>
          <CodeCopy
            label="braze_users_attributes_sync.sql"
            value={braze_test}
            mode="sql"
            height={`${editorHeight(5)}px`}
            containerClass="pt-4"
          />
        </li>
        <li>
          Click <code>Create new import sync</code>.
        </li>
        <li>
          Click the <code>Create new credentials</code> option.
        </li>
        <li>
          Copy the Snowflake warehouse credentials below into the Braze setup form. Use the schema and
          table name for the transform you just created. If you do not already have a Snowflake username,
          password, and role that you want to use for this integration, you can generate one using the
          "Generate Snowflake User" button below.
        </li>
        <li>
          Click <code>Set up sync details</code>.
        </li>
        <li>
          Set the Integration Name to whatever you want and enter Contact Email(s) to notify the contacts
          of any integration issues.
        </li>
        <li>
          Select the <code>Data Type</code> and <code>Frequency</code> for the integration.
        </li>
        <li>
          Then click <code>Test connection</code>.
        </li>
        <li>
          At this point Braze, will ask you to run an SQL command to add an RSA key to a user. Open an
          SQL console on your Snowflake database and run the command Braze gave you. Your user may not
          have sufficient privileges to run this ALTER command. If that happens contact Mozart Support.
        </li>
        <li>
          Click <code>Test connection</code>. Once successful, you will see the sample dataset in the
          Data Preview box.
        </li>
        <li>You are all set.</li>
      </Steps>
      <div className="p-2 bg-white">
        <SnowflakeConfig fields={fields} values={values} />
      </div>
      <GenerateSnowflakeUser className="mt-4" />
    </>
  );
};

export default BrazeLayout;
