import { memo } from 'react';

import { Bar } from 'react-chartjs-2';

import { ScaleChartOptions } from 'chart.js';

import { ConnectorSync, ConnectorSyncStatus } from 'api/connectorsAPI';

export interface SyncHistoryChartProps {
  connectorSyncs: ConnectorSync[];
}

const arePropsEqual = (prevProps: SyncHistoryChartProps, nextProps: SyncHistoryChartProps) => {
  if (prevProps.connectorSyncs.length !== nextProps.connectorSyncs.length) {
    return false;
  }

  if (prevProps.connectorSyncs.length === 0) {
    return true;
  }

  const prevLastSync = prevProps.connectorSyncs[prevProps.connectorSyncs.length - 1];
  const nextLastSync = nextProps.connectorSyncs[nextProps.connectorSyncs.length - 1];

  return prevLastSync.end_timestamp === nextLastSync.end_timestamp;
};

const SyncHistoryChart = memo(function SyncHistoryChart(props: SyncHistoryChartProps) {
  const { connectorSyncs } = props;
  // Function to calculate duration in seconds
  const getDuration = (start: string | null, end: string | null) => {
    if (!start || !end) return 0;
    const startTime = new Date(start);
    const endTime = new Date(end);
    const seconds = (endTime.getTime() - startTime.getTime()) / 1000;
    return seconds;
  };

  const getBarColor = (status: ConnectorSyncStatus) => {
    switch (status) {
      case 'SUCCESSFUL':
      case 'RESCHEDULED':
        return '#12b76a';
      case 'FAILURE':
      case 'FAILURE_WITH_TASK':
      case 'CANCELED':
        return '#f04438';
      default:
        return 'gray';
    }
  };

  // We need to generate the adjusted min and max dates for the chart so the first and last bars don't get cut off
  let minDate = new Date();
  let maxDate = new Date();

  if (connectorSyncs.length > 0) {
    const timestamps = connectorSyncs
      .map((d) => {
        if (!d.start_timestamp) return 0;
        return new Date(d.start_timestamp).getTime();
      })
      .sort();
    const intervals = timestamps.slice(1).map((time, i) => time - timestamps[i]);
    // Fall back to divide by 1 if intervals is empty list
    const averageInterval = intervals.reduce((a, b) => a + b, 0) / (intervals.length || 1);

    // Fraction of the interval to extend on each side
    const extension = averageInterval * 0.6; // Adjust this factor as needed

    minDate = new Date(timestamps[0] - extension);
    maxDate = new Date(timestamps[timestamps.length - 1] + extension);
  }

  // Processing data for the chart
  const chartData = {
    labels: connectorSyncs.map((item) => item.start_timestamp),
    datasets: [
      {
        label: 'Duration',
        data: connectorSyncs.map((item) => getDuration(item.start_timestamp, item.end_timestamp)),
        backgroundColor: connectorSyncs.map((item) => getBarColor(item.status)),
      },
    ],
  };

  const maxDuration = Math.max(...chartData.datasets[0].data);
  const stepSize = Math.max(60, maxDuration / 8); // At most 8 ticks but no smaller than 1 minute

  const options: ScaleChartOptions = {
    scales: {
      x: {
        type: 'time',
        // @ts-ignore
        time: {
          displayFormats: {
            minute: 'MMM dd, hh:mm a',
          },
          unit: 'minute',
        },
        min: minDate.toISOString(), // Set adjusted min date
        max: maxDate.toISOString(), // Set adjusted max date
        distribution: 'linear',
        // @ts-ignore
        title: {
          display: false,
        },
        grid: { display: false },
        // @ts-ignore
        ticks: {
          autoSkipPadding: 50,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      // @ts-ignore
      y: {
        beginAtZero: true,
        // @ts-ignore
        title: {
          display: false,
        },
        // @ts-ignore
        ticks: {
          // Callback function to convert the label from seconds to minutes
          callback: function (value, index, values) {
            // @ts-ignore
            return Math.round(value / 60) + ' min'; // Converts seconds to minutes
          },
          stepSize: stepSize,
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const sync = connectorSyncs[context.dataIndex];

            if (
              sync &&
              (sync.status === 'FAILURE' || sync.status === 'FAILURE_WITH_TASK') &&
              sync.reason
            ) {
              return sync.reason;
            }

            if (sync && sync.status === 'PENDING') {
              return 'Sync is running';
            }

            let label = context.dataset.label || '';
            let value = context.parsed.y;
            if (label) {
              label += ': ';
            }
            if (value !== null) {
              if (value >= 60) {
                value = Math.round(value / 60);
                label += Math.round(context.parsed.y / 60) + ' minute';
              } else {
                label += context.parsed.y + ' second';
              }

              if (value !== 1) {
                label += 's';
              }
            }
            return label;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  // @ts-ignore
  return <Bar data={chartData} options={options} />;
}, arePropsEqual);

export default SyncHistoryChart;
