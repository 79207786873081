import { useState } from 'react';

import CodeEditor from 'components/inputs/basic/CodeEditor/CodeEditor';
import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import { guessEditorLanguage } from 'components/query/CodeMirrorSqlEditor/CodeMirrorLanguageUtils';

export interface CodeModalProps {
  header?: string;
  code: string;
  onClose(): void;
}

export default function CodeModal(props: CodeModalProps) {
  let { header, code, onClose } = props;
  const [mode, setMode] = useState<ListboxValue>(guessEditorLanguage(code));

  const handleChangeMode = (newVal: ListboxValue) => {
    setMode(newVal);
    analytics.track('CodeModal SetMode');
  };

  return (
    <Modal header={header} onClose={onClose} cancelButton={true} containerClass="w-full m-[50px]">
      <div className="p-4">
        <div className="f-row-y-center">
          <div className="text-lg">Format</div>
          <Listbox
            value={mode}
            options={[
              { label: 'Text', value: 'text' },
              { label: 'SQL', value: 'snowflake' },
              { label: 'JSON', value: 'json5' },
              { label: 'HTML', value: 'html' },
              { label: 'XML', value: 'xml' },
            ]}
            variant="gray"
            size="medium"
            onChange={handleChangeMode}
            widthClass="w-[160px]"
            containerClass="ml-2"
          />
        </div>
        <div style={{ height: 'calc(80vh - 220px)' }} className="mt-4">
          <CodeEditor value={code} height={'calc(80vh - 220px)'} mode={mode as string} />
        </div>
      </div>
    </Modal>
  );
}
