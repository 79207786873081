import { ObjectJSON, SnowflakeUsersLink, UserActionSentencePartProps } from './UserActionSentenceParts';

export const SnowflakeUserCreatedText = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  const { new_data } = userAction.metadata;

  return (
    <>
      <SnowflakeUsersLink userAction={userAction} eventLocation={eventLocation} /> was created with
      properties
      <br />
      <ObjectJSON value={new_data} />.
    </>
  );
};

export const SnowflakeUserChangedText = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  const { new_data } = userAction.metadata;

  return (
    <>
      Properties for <SnowflakeUsersLink userAction={userAction} eventLocation={eventLocation} /> were
      changed to
      <br />
      <ObjectJSON value={new_data} />.
    </>
  );
};
