import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import cn from 'classnames';

import API from 'api/API';
import { Connector, Tag } from 'api/APITypes';
import { Column } from 'api/columnAPI';
import { CSVUpload } from 'api/csvUploadAPI';
import { AggTable, CreateAsType, DatabaseTableType, Table, Transform, convertTable } from 'api/tableAPI';
import ExportCsvIcon from 'components/api_icon_buttons/ExportCsvIcon/ExportCsvIcon';
import ExportGSheetIcon from 'components/api_icon_buttons/ExportGSheetIcon/ExportGSheetIcon';
import IconButton from 'components/inputs/basic/Button/IconButton';
import LinkIconButton from 'components/inputs/basic/Button/LinkIconButton';
import Switch from 'components/inputs/basic/Switch/Switch';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';
import { getMostFrequentRunConfig } from 'model_helpers/dbtAggTableHelper';
import { SearchColumnContext } from 'model_layer/SearchColumnContext';
import { TableContext } from 'model_layer/TableContext';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import RunConfigScheduleWidget from 'pages/dbt/ShowDBTRunConfig/SettingsTab/RunConfigScheduleWidget';
import SnapshotInfoIcon from 'pages/tables/ShowTable/SummaryTab/SnapshotInfoIcon';
import ViewerSnapshotModal from 'pages/Warehouse/DatabaseSearch2/TableTable/TableRow/ViewerShanpshotModal';
import { handleSqlErrors } from 'utils/apiResponseFormatter';
import { formatIso } from 'utils/dateTime';
import { snapshotFullName, getSnapshotsSourcesFullName } from 'utils/dbName';
import { connectorPath, dbtRunConfigurationPath } from 'utils/PathMaker';
import { formatNumber } from 'utils/String';

import { APIPipelinePayload } from '../PipelineTab/PipelineConverter';
import { DependencyEdge } from '../PipelineTab/PipelineEditor/PipelineEditor';

import { ColumnsTable } from './ColumnsTable';
import CSVUploadVersion from './CSVUploadVersion';
import Description from './Description/Description';
import ReplaceCSVModal from './ReplaceCSVModal';
import TagList from './TagList';
import TransformRunAndVersion from './TransformRunAndVersion';
import TransformScheduleWidget from './TransformScheduleWidget';

import s from './SummaryTab.module.css';

const DATABASE_TABLE_TYPE_LABEL_MAP: Record<DatabaseTableType, string> = {
  'BASE TABLE': 'Table',
  VIEW: 'View',
  'SECURE VIEW': 'Secure View',
  'MATERIALIZED VIEW': 'Materialized View',
  'LOCAL TEMPORARY': 'Local Temporary Table',
  'GLOBAL TEMPORARY': 'Global Temporary Table',
};

export const CREATE_AS_TYPE_LABEL_MAP: Record<CreateAsType, string> = {
  'BASE TABLE': 'Table',
  VIEW: 'View',
  'SECURE VIEW': 'Secure View',
};

interface SummaryTabProps {
  table: AggTable;
  loadingColumns: boolean;
  columns: Column[] | null;
  setColumns: (columns: Column[]) => void;
  savingDescription: boolean;
  allTags: Tag[];
  loadingToggleSnapshot: boolean;
  vertexDependencies: DependencyEdge[];
  apiPipeline: APIPipelinePayload;
  tablesByID: { [key: string]: AggTable };
  connectorsByID: { [key: string]: Connector };
  showCantSnapshotModal: boolean;
  onSaveDescription: (description: string, onSaveSuccess: () => void) => void;
  toggleSnapshot(event: React.ChangeEvent<HTMLInputElement>): void;
  onCloseCantSnapshotModal(): void;
  loadColumns(tableId: string): void;
}

export default function SummaryTab(props: SummaryTabProps) {
  const {
    table,
    loadingColumns,
    columns,
    savingDescription,
    allTags,
    loadingToggleSnapshot,
    vertexDependencies,
    apiPipeline,
    tablesByID,
    connectorsByID,
    showCantSnapshotModal,
    onSaveDescription,
    toggleSnapshot,
    onCloseCantSnapshotModal,
    setColumns,
    loadColumns,
  } = props;
  const [loadingSnapshotManuallyNow, setLoadingSnapshotManuallyNow] = useState(false);
  const history = useHistory();
  const { userProfile } = useUserProfile();
  const databaseType = useDatabaseAccount().type;
  const { tables, updateTables } = useContext(TableContext);
  const { refreshColumnsForTable } = useContext(SearchColumnContext);
  const { updateCsvUpload } = useContext(TableModelsContext);
  const snapshotTableFullName = snapshotFullName(table);
  const [snapshotTable, setSnapshotTable] = useState<AggTable | null>(null);
  const [showConfirmSnapshotNow, setShowConfirmSnapshotNow] = useState(false);
  const [snapshotNowError, setSnapshotNowError] = useState<string | null>(null);
  const [showReplaceCSVFileModal, setShowReplaceCSVFileModal] = useState(false);

  useEffect(() => {
    setSnapshotTable(tables.find((t) => t.full_name === snapshotTableFullName) || null);
  }, [tables, snapshotTableFullName]);

  const handleScheduleSnapshotNow = () => {
    setLoadingSnapshotManuallyNow(true);
    setSnapshotNowError(null);
    const api = new API();
    api
      .get(`/api/tables/${table.id}/snapshot_manually_now`)
      .then((response) => updateTables([convertTable(response.data as Table, databaseType)]))
      .catch((e) => {
        if (e.response?.data) {
          const snapshotNowErrorSlug = e.response.data[0];
          if (snapshotNowErrorSlug === 'cannot_snapshot_snapshot_table') {
            // First check for cannot_snapshot_snapshot_table
            setSnapshotNowError('You cannot snapshot a snapshot table.');
          } else if (snapshotNowErrorSlug === 'table_does_not_exist') {
            // Next check if the table exists
            setSnapshotNowError('Snapshot failed because the table does not exist.');
          } else {
            setSnapshotNowError(handleSqlErrors(snapshotNowErrorSlug));
          }
        } else {
          setSnapshotNowError('There was a problem snapshotting the table.');
        }
      })
      .finally(() => {
        setLoadingSnapshotManuallyNow(false);
        setShowConfirmSnapshotNow(false);
      });
  };

  const handleSuccessfulCSVUpload = (csvUpload: CSVUpload) => {
    // The CSV has been uploaded at this point.
    // Close the modal so the user is unblocked ASAP.
    // Then refresh the list of columns in the background.
    updateCsvUpload(csvUpload);
    setShowReplaceCSVFileModal(false);

    // Refresh the list of columns on the table page
    loadColumns(csvUpload.table);
    // Refresh the list of columns in the global search context
    refreshColumnsForTable(csvUpload.table);
    analytics.track('SummaryTab ReplaceCSV', {
      tableId: csvUpload.table,
    });
  };

  const handleOpenReplaceCSVModal = () => {
    setShowReplaceCSVFileModal(true);
    analytics.track('SummaryTab OpenReplaceCSVModal');
  };

  const handleCloseReplaceCSVModal = () => {
    setShowReplaceCSVFileModal(false);
    analytics.track('SummaryTab CloseReplaceCSVModal');
  };

  const tableFullName = table.full_name;
  const isTransform = table.type === 'transform';
  const isDBT = table.type === 'dbt';
  const isSnapshot = table.type === 'snapshot';
  const isCSVUpload = table.type === 'csv_upload';

  let snapshotRow = null; // Used by non-snapshot tables
  let goToSourceRow = null; // Used by snapshot tables
  let dbtRow = null; // Used by dbt tables
  let connectorRow = null; // Used by unmanaged tables with connectors
  let scheduleRow = null; // Used by transform tables
  let createAsRow = null; // Used by transform tables
  let viewSqlRow = null; // Used by views

  // Build the Schedule Row for transform tables
  if (isTransform) {
    const transform = table.transform as Transform;
    const { create_as } = transform;
    scheduleRow = (
      <tr>
        <td className="align-top">
          <div className={s.statHeading}>Schedule</div>
        </td>
        <td>
          <TransformScheduleWidget
            table={table}
            vertexDependencies={vertexDependencies}
            vertices={apiPipeline.vertices}
            tablesByID={tablesByID}
          />
        </td>
      </tr>
    );
    createAsRow = (
      <tr>
        <td>
          <div className={s.statHeading}>Create As</div>
        </td>
        <td>{CREATE_AS_TYPE_LABEL_MAP[create_as]}</td>
      </tr>
    );
  }

  // Build the Schedule Row for dbt tables
  if (isDBT) {
    const runConfig = getMostFrequentRunConfig(table);
    scheduleRow = (
      <tr>
        <td className="align-top">
          <div className={s.statHeading}>Schedule</div>
        </td>
        <td>
          {runConfig ? (
            <RunConfigScheduleWidget runConfig={runConfig} connectorsByID={connectorsByID} />
          ) : (
            'MISSING TRY REFRESHING'
          )}
        </td>
      </tr>
    );
  }

  if (isSnapshot) {
    // This snapshot table's source table if it exists.
    const snapshotsSourcesFullName = getSnapshotsSourcesFullName(table.full_name);
    const sourceTable: AggTable | null =
      tables.find((t) => t.full_name === snapshotsSourcesFullName) || null;

    const handleGoToSourceTable = () => {
      // This assumes we are viewing a snapshot table and want to go to its source table
      if (sourceTable) {
        analytics.track('SummaryTab GoToSourceTable');
        history.push(`/tables/${sourceTable.id}`);
      }
    };
    if (sourceTable) {
      goToSourceRow = (
        <tr>
          <td>
            <div className={s.statHeading}>Source Table</div>
          </td>
          <td>
            <IconButton
              icon="ChevronRight"
              text="GO TO SOURCE TABLE"
              iconLast={true}
              size="small"
              variant="lightDullTransparent"
              onClick={handleGoToSourceTable}
            />
          </td>
        </tr>
      );
    }
  } else {
    const handleGoToSnapshotTable = () => {
      // This assumes we are viewing a source table and want to go to its snapshot table
      if (snapshotTable) {
        analytics.track('SummaryTab GoToSnapshotTable');
        history.push(`/tables/${snapshotTable.id}`);
      }
    };
    snapshotRow = (
      <div className="flex-col p-2">
        <div className="flex items-center">
          {databaseType !== 'bigquery' && (
            <>
              <Switch
                name="snapshot-switch"
                id="snapshot-switch"
                checked={table.snapshot}
                onChange={toggleSnapshot}
                spinning={loadingToggleSnapshot}
              />
              {showCantSnapshotModal && <ViewerSnapshotModal onClose={onCloseCantSnapshotModal} />}
              <div className="mx-2 text-base font-medium text-sec-blue-gray-500">SNAPSHOT</div>
              <SnapshotInfoIcon
                tableFullName={tableFullName}
                snapshotTableFullName={snapshotTableFullName}
              />
              {userProfile.company_role !== 'viewer' && table.snapshot && (
                <IconButton
                  icon="CameraFill"
                  text="SNAPSHOT NOW"
                  size="small"
                  variant="lightDullTransparent"
                  onClick={() => setShowConfirmSnapshotNow(true)}
                  className="ml-2"
                />
              )}
              {snapshotTable !== null && (
                <IconButton
                  icon="ChevronRight"
                  text="GO TO SNAPSHOT"
                  iconLast={true}
                  size="small"
                  variant="lightDullTransparent"
                  onClick={handleGoToSnapshotTable}
                  className="ml-2"
                />
              )}
            </>
          )}

          {table.django_thinks_exists_in_snowflake && (
            <>
              {databaseType !== 'bigquery' && <div className="h-[28px] w-[1px] mx-4 bg-pri-gray-200" />}
              <ExportCsvIcon table={table} eventPage="SummaryTab" />
              <ExportGSheetIcon table={table} eventPage="SummaryTab" />
            </>
          )}
        </div>
        {snapshotNowError && (
          <Alert variant="error" className="mb-6 mt-4">
            {snapshotNowError}
          </Alert>
        )}
        {isTransform && !table.django_thinks_exists_in_snowflake && table.snapshot && (
          <Alert variant="error" className="mt-2">
            {
              'This transform will not be snapshotted until it has successfully run. You can create the table now by clicking the "Create or Replace Transform Table" button on the "Runs" tab.'
            }
          </Alert>
        )}
      </div>
    );
  }

  // Build out dbtRow
  if (isDBT) {
    const runConfig = getMostFrequentRunConfig(table);
    if (runConfig) {
      dbtRow = (
        <tr>
          <td>
            <div className={s.statHeading}>DBT Config</div>
          </td>
          <td>
            <div className="flex">
              <LinkIconButton
                icon="ChevronRight"
                text="GO TO DBT JOB"
                iconLast={true}
                size="small"
                variant="lightDullTransparent"
                to={dbtRunConfigurationPath(runConfig.id)}
                data-track={`SummaryTab GoToDBTConfig`}
              />
            </div>
          </td>
        </tr>
      );
    }
  }

  // Build out connectorRow
  if (table.type === 'unmanaged' && !!table.connector) {
    connectorRow = (
      <tr>
        <td>
          <div className={s.statHeading}>Connector</div>
        </td>
        <td>
          <div className="flex">
            <LinkIconButton
              icon="ChevronRight"
              text="GO TO CONNECTOR"
              iconLast={true}
              size="small"
              variant="lightDullTransparent"
              to={connectorPath(table.connector.id)}
              data-track={`SummaryTab GoToConnector`}
            />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <div className="h-full flex mt-4">
      <div className="flex-1 pl-2">
        <table className={s.statTable}>
          <tbody>
            <tr className="align-top">
              <td>
                <div className={cn(s.statHeading, 'f-row-y-center mt-2')}>
                  <div>Description</div>
                  <InfoIcon
                    content="Table descriptions saved in Mozart are written to Snowflake as comments on the table."
                    containerClass="ml-1"
                    popoverProps={{ style: { maxWidth: '700px' } }}
                  />
                </div>
              </td>
              <td className="w-full">
                <Description
                  table={table}
                  description={table.description}
                  isSaving={savingDescription}
                  onSaveDescription={onSaveDescription}
                />
              </td>
            </tr>
            <tr>
              <td>
                <div className={s.statHeading}>Tags</div>
              </td>
              <td>
                <TagList allTags={allTags} table={table} />
              </td>
            </tr>
            {scheduleRow}
            {dbtRow}
            {connectorRow}
            {table.database_table_type !== 'VIEW' && (
              <tr>
                <td>
                  <div className={s.statHeading}>Rows</div>
                </td>
                <td>
                  <div>{table.num_rows === -1 ? 'N/A' : formatNumber(table.num_rows)}</div>
                </td>
              </tr>
            )}
            {table.django_thinks_exists_in_snowflake && (
              <tr>
                <td>
                  <div className={s.statHeading}>Table Type</div>
                </td>
                <td>{DATABASE_TABLE_TYPE_LABEL_MAP[table.database_table_type]}</td>
              </tr>
            )}
            {createAsRow}
            {isSnapshot && (
              <tr>
                <td>
                  <div className={s.statHeading}>Last Snapshot Time</div>
                </td>
                <td>{table.last_snapshot_at ? formatIso(table.last_snapshot_at) : 'Never'}</td>
              </tr>
            )}
            {goToSourceRow}
            {viewSqlRow}
          </tbody>
        </table>
        {snapshotRow}
        {isTransform && <TransformRunAndVersion transform={table.transform as Transform} />}
        {isCSVUpload && (
          <CSVUploadVersion table={table} onOpenReplaceCSVModal={handleOpenReplaceCSVModal} />
        )}
      </div>
      <ColumnsTable
        table={table}
        columns={columns}
        loadingColumns={loadingColumns}
        setColumns={setColumns}
      />
      {showConfirmSnapshotNow && (
        <ConfirmModal
          header={<span className="text-lg font-medium">Are you sure you want to snapshot now?</span>}
          body={
            <div className="mx-4 my-1">
              Clicking the button several times may result in multiple snapshots. Snapshots run every
              night at 3:05 am PT, but the nightly run may be skipped if the table was snapshotted within
              the past 12 hours.
            </div>
          }
          confirmText="Snapshot"
          confirmVariant="lightAction"
          onCancel={() => setShowConfirmSnapshotNow(false)}
          onConfirm={handleScheduleSnapshotNow}
          saving={loadingSnapshotManuallyNow}
          containerClass="w-[600px] break-words"
        />
      )}
      {showReplaceCSVFileModal && (
        <ReplaceCSVModal
          onCloseCSVModal={handleCloseReplaceCSVModal}
          onSuccessfulCSVUpload={handleSuccessfulCSVUpload}
          table={table}
        />
      )}
    </div>
  );
}
