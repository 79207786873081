import SignupFormikForm from './SignupFormikForm';
import { useSignup } from './useSignup';

interface SignupRightSideFormProps {}

const SignupRightSideForm = (props: SignupRightSideFormProps) => {
  const formProps = useSignup();

  return (
    <div className="w-full lg:min-w-[444px] lg:w-[444px] mt-8 lg:mt-0 lg:mx-2 p-4 bg-white rounded shadow-[0_0_4px_0_rgba(0,0,0,0.1)]">
      <SignupFormikForm {...formProps} />
    </div>
  );
};

export default SignupRightSideForm;
