/**
 * Same as <CenteredSpinner/> except it also shows protips.
 */
import React, { useEffect, useState } from 'react';

import _ from 'lodash';

import { protips, getProtip } from './Protips';

import ps from './ProtipSpinner.module.css';

interface ProtipSpinnerProps {}

export default function ProtipSpinnerSpinner(props: ProtipSpinnerProps) {
  const [currentTip, setCurrentTip] = useState(protips[0]);

  // When the component first loads,
  // look up the next protip to render from localStorage.
  useEffect(() => {
    setCurrentTip(getProtip());
  }, []);

  const { fade, heading, image, url } = currentTip;
  let images = _.castArray(image);

  const onClick = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
    analytics.track('ProtipSpinner TipClick', currentTip);
  };

  return (
    <div className={ps.protipSpinner}>
      <div className={ps.flexCenter}>
        <div className={ps.tipCenterDiv} onClick={onClick}>
          <h5 className="text-input-label">{fade}:</h5>
          {heading && <h5 className="text-lg font-medium mt-1 mb-2">{heading}</h5>}
          <div className={ps.tipImgDiv}>
            {images.map((i) => {
              const imgPath = `/images/protips/${i}.png`;
              return (
                <div key={i} className={ps.tipImgSizeDiv}>
                  <img src={imgPath} alt="protip screenshot" className={ps.tipImg} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
