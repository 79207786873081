/*
  This is a fork of the DateRangeInput from 'react-date-range'
  that renders the date in the same way as a 'react-date-range'.DateRangePicker does.
*/
import React, { PureComponent } from 'react';

import classnames from 'classnames';
import { format, parse, isValid } from 'date-fns';

import TextInput from 'components/inputs/basic/TextInput/TextInput';
import { parseApiDate, formatApiDate } from 'utils/dateTime';

import s from './DateRangeInput.module.css';

interface DateRangeInputProps {
  controlRef?: React.RefObject<HTMLInputElement>;
  name: string;
  value: string; // In API format
  className?: string;
  externalInvalid?: boolean;
  onFocus: () => void;
  onSetDate: (date: string, event?: React.FocusEvent<HTMLInputElement>) => void;
  onSetRange: (start: string, end: string) => void;
}

interface DateRangeInputState {
  invalid: boolean;
  changed: boolean;
  value: string; // In UI format, `DEFAULT_FORMAT`
  open: boolean;
}

const DEFAULT_FORMAT = 'MMM d, yyyy';

class DateRangeInput extends PureComponent<DateRangeInputProps, DateRangeInputState> {
  constructor(props: DateRangeInputProps) {
    super(props);

    this.state = {
      invalid: false,
      changed: false,
      value: this.formatDate(props.value),
      open: false,
    };
  }

  componentDidUpdate(prevProps: DateRangeInputProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.formatDate(this.props.value) });
    }
  }

  formatDate(date: string) {
    const value = parseApiDate(date);
    if (value && isValid(value)) {
      return format(value, DEFAULT_FORMAT);
    }
    return '';
  }

  update(value: string, event?: React.FocusEvent<HTMLInputElement>) {
    const { invalid, changed } = this.state;

    if (invalid || !changed || !value) {
      return;
    }

    const { onSetDate } = this.props;
    const parsed = parse(value, DEFAULT_FORMAT, new Date());

    if (isValid(parsed)) {
      this.setState({ changed: false }, () => onSetDate(formatApiDate(parsed), event));
    } else {
      this.setState({ invalid: true });
    }
  }

  onKeyDown = (e: React.KeyboardEvent) => {
    const { value } = this.state;

    if (e.key === 'Enter') {
      this.update(value);
    }
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: e.target.value, changed: true, invalid: false });
  };

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.update(this.state.value, event);
  };

  render() {
    const { name, controlRef: innerRef, className, externalInvalid, onFocus } = this.props;
    const { value, invalid } = this.state;

    return (
      <span className={classnames('rdrDateRangeInput', className)}>
        <TextInput
          name={name}
          ref={innerRef}
          value={value}
          hasError={invalid || externalInvalid}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={onFocus}
          className={s.input}
        />
        {/* {invalid && <span className="rdrWarning">&#9888;</span>} */}
      </span>
    );
  }
}

export default React.forwardRef((props: DateRangeInputProps, ref) => (
  <DateRangeInput controlRef={ref as any} {...props} />
));
