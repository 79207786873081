import { useEffect, useState } from 'react';

import { useUserProfile } from 'context/AuthContext';
import useAIPermissionModalContext from 'model_layer/useAIPermissionModalContext';

import AIFixSqlButton from './AIFixSqlButton';
import AIFixSqlModal from './AIFixSqlModal';
import useAIFixSql from './useAIFixSql';

interface AIFixSqlProps {
  sql: string;
  sqlError: string;
  editMode: boolean;
  setEditorSql: (sql: string, byUser: boolean) => void;
}

const AIFixSql = (props: AIFixSqlProps) => {
  const { sql, sqlError, editMode, setEditorSql } = props;

  const [showModal, setShowModal] = useState(false);
  const [feedbackResponse, setFeedbackResponse] = useState<string | null>(null);
  const [appliedSqlFix, setAppliedSqlFix] = useState(false);

  const { userProfile } = useUserProfile();
  const {
    company: { allow_ai: allowAI },
  } = userProfile;

  const { setShowAIPermissionModal } = useAIPermissionModalContext();

  const { isLoading, takingLonger, responseId, fixedSql, apiError, onGenerateSql } = useAIFixSql({
    sql,
    sqlError,
  });

  // Reset appliedSqlFix when SQL is updated in editor and no longer matches fix
  useEffect(() => {
    if (sql !== fixedSql) {
      setAppliedSqlFix(false);
    }
  }, [sql, fixedSql]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    if (!allowAI) {
      setShowAIPermissionModal(true);
    } else {
      onGenerateSql();
      setShowModal(true);
    }
  };

  const handleSaveClick = () => {
    analytics.track('SqlEditor ApplyAIFixSql', {
      response_id: responseId,
      original_sql: sql,
      original_sql_error: sqlError,
      fixed_sql: fixedSql,
      feedback_response: feedbackResponse ?? null,
    });

    setEditorSql(fixedSql, true);
    setShowModal(false);
    setAppliedSqlFix(true);
  };

  const handleFeedbackClick = (response: string) => {
    setFeedbackResponse(response);
    analytics.track('SqlEditor SubmitAIFixSqlFeedback', {
      response_id: responseId,
      original_sql: sql,
      original_sql_error: sqlError,
      fixed_sql: fixedSql,
      feedback_response: response,
    });
  };

  // Hide AI Fix SQL button if not in edit mode or if the fix was already applied
  if (!editMode || appliedSqlFix) {
    return null;
  }

  return (
    <>
      <AIFixSqlButton onClick={handleOpenModal} />
      {showModal && (
        <AIFixSqlModal
          onClose={handleCloseModal}
          isLoading={isLoading}
          takingLonger={takingLonger}
          sql={sql}
          fixedSql={fixedSql}
          apiError={apiError}
          onSaveClick={handleSaveClick}
          onRegenerateClick={onGenerateSql}
          onFeedbackClick={handleFeedbackClick}
        />
      )}
    </>
  );
};

export default AIFixSql;
