/*
Combobox prefixed by label element and followed by an error element.
*/
import React from 'react';

import Combobox, { ComboboxProps } from 'components/inputs/basic/Combobox/Combobox';

import InputGroup, { InputGroupParentProps } from '../InputGroup';

type SanitizedComboboxProps = Omit<ComboboxProps, 'key'>;

export interface ComboboxGroupProps extends InputGroupParentProps, SanitizedComboboxProps {
  label: string;
}

export default function ComboboxGroup(props: ComboboxGroupProps) {
  const {
    name,
    value,
    label,
    options,
    postLabelElement,
    groupClass,
    groupStyle,
    labelClass,
    labelStyle,
    error,
    ...remainingProps
  } = props;

  const hasError = typeof error === 'string' && error.length > 0;
  const input = <Combobox value={value} options={options} hasError={hasError} {...remainingProps} />;

  return (
    <InputGroup
      name={name}
      input={input}
      error={error}
      label={label}
      postLabelElement={postLabelElement}
      groupClass={groupClass}
      groupStyle={groupStyle}
      labelClass={labelClass}
      labelStyle={labelStyle}
    />
  );
}
