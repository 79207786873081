import React, { useEffect, useState } from 'react';

import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import qs from 'query-string';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useUserProfile } from 'context/AuthContext';

interface SlackkAppInstallerRedirectProps extends RouteComponentProps {}

const SlackAppInstallerRedirect = (props: SlackkAppInstallerRedirectProps) => {
  useTitle('Mozart: Redirecting...');
  // Extract code query param that was passed via the Slack OAuth Slack redirect flow

  const location = useLocation();
  const [error, setError] = useState('');
  const code = qs.parse(location.search)?.code;
  const errorFromSlack = qs.parse(location.search)?.error;
  const { setUserProfile } = useUserProfile();
  const history = useHistory();
  const navigate = useNavigate();

  const settingsPath = `/settings`;

  const handleGoToSettings = () => {
    analytics.track('SlackAppInstallerRedirect GoToSettings');
    navigate(settingsPath);
  };

  // We want to install the app with the received code and then redirect to settings page
  useEffect(() => {
    if (code) {
      const api = new API();
      const postData = {
        slack_oauth_redirect_code: code,
      };
      api
        .post('/api/company/install_slack_app', postData)
        .then((response) => {
          setUserProfile(response.data.user_profile);
          navigate(settingsPath);
        })
        .catch((e) => {
          setError('There was a problem installing the slack app.');
        });
    } else {
      if (errorFromSlack) {
        if (errorFromSlack === 'access_denied') {
          // If the error is access_denied, it means the user clicked Cancel on the Slack auth page, so just go back to settings
          navigate(settingsPath);
        }
        setError(`Slack App failed to install with error: ${errorFromSlack}`);
      } else {
        setError('No code was returned by Slack. Please contact support.');
      }
    }
  }, [history, code, errorFromSlack, settingsPath, setUserProfile, navigate]);

  return error ? (
    <CenteredContainer maxWidth="600px">
      <div className="f-col items-center">
        <Alert variant="error">{error}</Alert>
        <Button onClick={handleGoToSettings} className="mt-2">
          Return To Settings
        </Button>
      </div>
    </CenteredContainer>
  ) : (
    <CenteredSpinner containerMinHeight="60vh" />
  );
};

export default SlackAppInstallerRedirect;
