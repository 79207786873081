/*
  Button used by RadioTabColumn.
  You probably want to use RadioTabPanelBox instead of this.
*/
import cn from 'classnames';

import FakeRadioButton from 'components/inputs/fake/FakeRadioButton/FakeRadioButton';
import { AbstractTabProps } from 'components/layouts/parts/TabRow/Tab';

export type { TabKeyType } from 'components/layouts/parts/TabRow/TabRow';

interface RadioTabProps extends AbstractTabProps {
  enabled: boolean;
}

const RadioTab = (props: RadioTabProps) => {
  const { label, selected, enabled, onClick } = props;
  const alwaysClasses = 'w-full h-full p-2 f-row-y-center border-0 outline-0 text-sm';
  const allEnabledStates = 'hover:cursor-pointer';
  const allDisabledStates = 'text-pri-gray-400';
  const enabledClasses = {
    selectedTabClasses: 'bg-sec-blue-gray-100 font-medium',
    unselectedTabClasses: 'bg-pri-gray-50',
  };
  const disabledClasses = {
    selectedTabClasses: 'bg-pri-gray-100 font-medium',
    unselectedTabClasses: 'bg-pri-gray-50',
  };

  const selectionStateClasses = enabled ? enabledClasses : disabledClasses;

  const containerClass = cn(alwaysClasses, {
    [allEnabledStates]: enabled,
    [allDisabledStates]: !enabled,
    [selectionStateClasses.selectedTabClasses]: selected,
    [selectionStateClasses.unselectedTabClasses]: !selected,
  });

  return (
    <button className={containerClass} onClick={onClick} type="button">
      <div className="shrink-0 ">
        <FakeRadioButton selected={selected} enabled={enabled} />
      </div>
      <div className="ml-2">{label}</div>
    </button>
  );
};

export default RadioTab;
