import React, { useMemo } from 'react';

import cn from 'classnames';

import { UsageInterval, ConnectorUsage, TableUsage } from 'api/usageAPI';
import { formatNumber } from 'utils/String';

import { CommonTabProps } from '../Usage';
import s from '../Usage.module.css';
import UsageChart, { UsageData, sortDate } from '../UsageChart';
import UsageTabLayout from '../UsageTabLayout/UsageTabLayout';

import ConnectorTable, { ConnectorRow } from './ConnectorTable/ConnectorTable';
import ConnectorTablesTable, { TableRow } from './ConnectorTablesTable/ConnectorTablesTable';

interface ConnectorTabProps extends CommonTabProps {
  connectorID: string;
  connectorUsage: ConnectorUsage[];
  connectorTablesUsage: TableUsage[];
  setConnectorID: (connectorID: string) => void;
}

export default function ConnectorTab(props: ConnectorTabProps) {
  const {
    loading,
    error,
    interval,
    startDate,
    endDate,
    connectorID,
    connectorUsage,
    connectorTablesUsage,
    setInterval,
    setDateRange,
    setConnectorID,
  } = props;

  // Compute chart data for connectors
  const connectorGraphData = useMemo(() => {
    return sumMarByDate(connectorUsage);
  }, [connectorUsage]);

  // Compute table component data for connectors
  const connectorTableRows = useMemo(() => {
    return sumMarByConnector(connectorUsage);
  }, [connectorUsage]);

  // Compute chart data for tables in connector
  const connectorTablesGraphData = useMemo(() => {
    return sumMarByDate(connectorTablesUsage);
  }, [connectorTablesUsage]);

  // Compute table component data for tables in connector
  const connectorTablesTableRows = useMemo(() => {
    return sumMarByConnectorTables(connectorTablesUsage);
  }, [connectorTablesUsage]);

  // Compute total MAR
  const totalMar = useMemo(() => {
    return connectorGraphData.reduce((sum, usageData) => sum + usageData.val, 0);
  }, [connectorGraphData]);

  // Compute selected connector total MAR
  const selectedConnectorTotalMar = useMemo(() => {
    let sum = 0;
    if (connectorID) {
      sum = connectorUsage.reduce((sum, conUsage) => {
        if (conUsage.connector_id === connectorID) {
          return sum + conUsage.mar;
        }
        return sum;
      }, 0);
    }
    return sum;
  }, [connectorID, connectorUsage]);

  // Pick rendered data based on data query parameters
  let renderedGraphData = connectorGraphData;
  if (connectorID) {
    renderedGraphData = connectorTablesGraphData;
  }

  // Set the connectorName
  let connectorName = '';
  if (connectorID) {
    connectorName = connectorUsage.find((c) => c.connector_id === connectorID)?.connector_name || '';
  }

  const handleSetInterval = (interval: UsageInterval) => {
    analytics.track('UsageConnectorTab SetInterval', {
      interval,
    });
    setInterval(interval);
  };

  const handleSetDateRange = (startDate: string, endDate: string) => {
    analytics.track('UsageConnectorTab SetDateRange', {
      startDate,
      endDate,
    });
    setDateRange(startDate, endDate);
  };

  const handleSelectConnector = (connectorID: string) => {
    analytics.track('UsageConnectorTab SelectConnector');
    setConnectorID(connectorID);

    if (interval === 'monthly') {
      setInterval('daily' as UsageInterval);
    }
  };

  const handleTableGoBack = () => {
    analytics.track('UsageConnectorTab TableGoBackToConnectors');
    setConnectorID('');
  };

  let handleFilterGoBack: undefined | (() => void) = undefined;
  if (connectorID) {
    handleFilterGoBack = () => {
      analytics.track('UsageConnectorTab FilterGoBackToConnectors');
      setConnectorID('');
    };
  }

  let zeroText = '';
  let headerBox = null;
  let chart = null;
  let table = null;

  if (renderedGraphData.length) {
    headerBox = (
      <div className={cn(s.colorlessHeaderBox, 'bg-sec-blue-50', 'text-sec-blue-700')}>
        <div className={s.totalRows}>
          <div className={s.totalRow}>
            <h4>Total MAR used during date range:</h4>
            <div className={s.total}>{formatNumber(totalMar)}</div>
          </div>
          {connectorName && (
            <div className={s.totalRow}>
              <h4>{`Total ${connectorName} MAR used during date range:`}</h4>
              <div className={s.total}>{formatNumber(selectedConnectorTotalMar)}</div>
            </div>
          )}
        </div>
        <a
          href="https://help.mozartdata.com/docs/usage#connector-usage"
          target="blank"
          data-track="Usage LinkToMARHelp"
          className={s.helpLink}
        >
          How MAR Works
        </a>
      </div>
    );

    chart = (
      <UsageChart
        interval={interval}
        yAxisLabel="MAR"
        hoverLabel="MAR"
        usageData={renderedGraphData}
        color={getComputedStyle(document.documentElement).getPropertyValue('--sec-blue-700')}
      />
    );
    if (connectorID) {
      table = (
        <ConnectorTablesTable
          rows={connectorTablesTableRows}
          connectorMar={selectedConnectorTotalMar}
          totalMar={totalMar}
          onBackClick={handleTableGoBack}
        />
      );
    } else {
      table = (
        <ConnectorTable
          rows={connectorTableRows}
          totalMar={totalMar}
          onConnectorClick={handleSelectConnector}
        />
      );
    }
  } else {
    zeroText = 'Zero MAR used during the selected date range.';
  }

  return (
    <UsageTabLayout
      loading={loading}
      error={error}
      zeroText={zeroText}
      interval={interval}
      startDate={startDate}
      endDate={endDate}
      connectorName={connectorName}
      setInterval={handleSetInterval}
      setDateRange={handleSetDateRange}
      onBack={handleFilterGoBack}
      headerBox={headerBox}
      chart={chart}
      table={table}
      tabType="connector"
    />
  );
}

// Calculate the total MAR for every date.
function sumMarByDate(usage: (ConnectorUsage | TableUsage)[]) {
  // TO INVESTIGATE #1:
  // On dev the API can return null values. Not sure if this holds true on staging or prod.
  const filteredUsage = usage.filter((u) => {
    const castUsage = u as ConnectorUsage;
    if (castUsage.connector_id !== undefined && castUsage.connector_id === null) {
      return false;
    }
    return u.mar !== null;
  });

  // Calculate the total MAR for every date.
  const sumDic: { [key: string]: number } = {};
  filteredUsage.forEach((u) => {
    if (!sumDic[u.measured_date]) {
      sumDic[u.measured_date] = 0;
    }
    sumDic[u.measured_date] += u.mar;
  });

  // Get list of sorted dates
  const sortedDates = Object.keys(sumDic).sort(sortDate);

  // Convert to the format the UsageChart wants.
  const graphData: UsageData[] = sortedDates.map((k) => ({ date: k, val: sumDic[k] }));
  return graphData;
}

// Calculate the total MAR for every connector.
function sumMarByConnector(usage: ConnectorUsage[]) {
  // TO INVESTIGATE #1:
  // On dev the API can return null values. Not sure if this holds true on staging or prod.
  const filteredUsage = usage.filter((u) => u.connector_id !== null && u.mar !== null);

  // Calculate the total MAR for every connector.
  const sumDic: { [key: string]: ConnectorRow } = {};
  filteredUsage.forEach((u) => {
    if (!sumDic[u.connector_id]) {
      sumDic[u.connector_id] = { connectorID: u.connector_id, name: u.connector_name, mar: 0 };
    }
    sumDic[u.connector_id].mar += u.mar;
  });

  const tableData = Object.values(sumDic);

  // Sort by MAR descending
  tableData.sort((a, b) => b.mar - a.mar);

  return tableData;
}

// Calculate the total MAR for every connector.
function sumMarByConnectorTables(usage: TableUsage[]) {
  // Calculate the total MAR for every connector.
  const sumDic: { [key: string]: TableRow } = {};
  usage.forEach((u) => {
    const full_name = `${u.schema_name}.${u.table_name}`;
    if (!sumDic[full_name]) {
      sumDic[full_name] = { full_name: full_name, schema: u.schema_name, name: u.table_name, mar: 0 };
    }
    sumDic[full_name].mar += u.mar;
  });

  const tableData = Object.values(sumDic);

  // Sort by MAR descending
  tableData.sort((a, b) => b.mar - a.mar);

  return tableData;
}
