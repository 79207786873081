import React, { useRef, useState, useContext } from 'react';

import { RouteComponentProps } from 'react-router';
import { useTitle } from 'react-use';

import { AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';

import tagAPI, { Tag, SaveableTagProps } from 'api/tagAPI';
import Button from 'components/inputs/basic/Button/Button';
import TextInput from 'components/inputs/basic/TextInput/TextInput';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';
import { useUserProfile } from 'context/AuthContext';
import useTrackFilter from 'hooks/useTrackFilter';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { useSearchFocus } from 'utils/React';
import { caselessCompare } from 'utils/String';

import { randomColor } from './ColorPicker';
import EditTagForm, { COLUMN_WIDTHS } from './EditTagForm';
import TagRow from './TagRow';

import s from './ListTags.module.css';

const ACTION_WIDTH = '83px';
const ACTUAL_DESCRIPTION_WIDTH = `calc(${COLUMN_WIDTHS.descriptionAndColor} - ${ACTION_WIDTH})`;

interface MatchParams {}

interface ListTagsProps extends RouteComponentProps<MatchParams> {}

export default function ListTags(props: ListTagsProps) {
  useTitle('Tags');

  // Filter state
  const [tagFilter, setTagFilter] = useState('');
  const reportOnBlur = useTrackFilter('ListTags', tagFilter);
  const [filterRef] = useSearchFocus();

  // Add tag form state
  const [showAddTag, setShowAddTag] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const tagsAddedThisPageVisit = useRef<Tag[]>([]); // Tags the user has added without navigating away from this page.
  const {
    userProfile: { company_role },
  } = useUserProfile();

  const { allLoaded, anyError, tags, addTag, removeTag, updateTags } = useContext(TableModelsContext);

  const handleOpenNewTagEditor = () => {
    setShowAddTag(true);
    setErrorMessage('');
    analytics.track('ListTags OpenNewTagEditor');
  };

  const handleSaveNewTag = (
    saveableProps: SaveableTagProps,
    formikHelpers: FormikHelpers<SaveableTagProps>,
  ) => {
    setSaving(true);
    tagAPI
      .add(saveableProps)
      .then((response: AxiosResponse<Tag>) => {
        const newTag = response.data;
        tagsAddedThisPageVisit.current.unshift(newTag);
        addTag(newTag);
        setShowAddTag(false);
        analytics.track('ListTags CreateTag');
      })
      .catch((error) => {
        // Form validation errors
        if (error.response?.status === 400) {
          const data = error.response.data;
          setErrorMessage(data.non_field_errors);
          formikHelpers.setErrors(data);
        } else {
          setErrorMessage('Error saving tag.');
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleCancelNewTagEditor = () => {
    setShowAddTag(false);
    setErrorMessage('');
    analytics.track('ListTags CancelNewTagEditor');
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagFilter(event.target.value.toLowerCase());
  };

  const Header = (
    <div className={s.header}>
      <div className="flex items-center">
        <h1 className="display-sm">Tags</h1>
        <TextInput
          name="search"
          ref={filterRef}
          id={s.filter}
          placeholder="Search"
          value={tagFilter}
          onChange={handleFilterChange}
          onBlur={reportOnBlur}
          autoFocus
          className="ml-4"
          maxLength={200}
        />
      </div>
      {company_role !== 'viewer' && (
        <Button onClick={handleOpenNewTagEditor} variant="lightAction">
          Add Tag
        </Button>
      )}
    </div>
  );

  let filteredTags = tags;
  if (tagFilter) {
    filteredTags = tags.filter((t: Tag) => {
      return t.name.toLowerCase().includes(tagFilter) || t.description.toLowerCase().includes(tagFilter);
    });
  }

  const sortedTags = sortTags(filteredTags, tagsAddedThisPageVisit.current);

  return (
    <CenteredLayout header={Header} loading={!allLoaded} loadingError={anyError} maxWidth="95%">
      {allLoaded && (
        <div>
          {showAddTag && (
            <div className={s.addTagBox}>
              <EditTagForm
                tags={tags}
                initialValues={{
                  name: '',
                  description: '',
                  color: randomColor(),
                }}
                saving={saving}
                errorMessage={errorMessage}
                editMode={false}
                eventPage="ListTags"
                handleCancel={handleCancelNewTagEditor}
                handleSave={handleSaveNewTag}
              />
            </div>
          )}
          {tags.length > 0 && sortedTags.length > 0 && (
            <table className="lineTable">
              <thead>
                <tr className="whitespace-nowrap">
                  <th style={{ width: COLUMN_WIDTHS.name }}>Tag</th>
                  <th style={{ width: ACTUAL_DESCRIPTION_WIDTH }}>Description</th>
                  {company_role !== 'viewer' && (
                    <th className="text-center" style={{ width: ACTION_WIDTH }}>
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {sortedTags.map((t: Tag) => (
                  <TagRow
                    key={t.id}
                    {...t}
                    tags={tags}
                    addedThisPageVisit={tagsAddedThisPageVisit.current.includes(t)}
                    updateTags={updateTags}
                    removeTag={removeTag}
                  />
                ))}
              </tbody>
            </table>
          )}
          {tags.length > 0 && sortedTags.length === 0 && (
            <NoMatchesFoundAlert heading="No matching tags." />
          )}
          {tags.length === 0 && !showAddTag && (
            <NoObjectsAlert
              heading="You don't have any tags yet."
              detail="Tags allow you to organize your tables by use case, project, status, or any way you want."
            />
          )}
        </div>
      )}
    </CenteredLayout>
  );
}

const sortTags = (tags: Tag[], addedTags: Tag[]) => {
  const sortedTags = [...tags];
  sortedTags.sort((a: Tag, b: Tag) => {
    // Sort added tags first.
    const aIndex = addedTags.indexOf(a);
    const bIndex = addedTags.indexOf(b);
    // Both tags have been added.
    if (aIndex > -1 && bIndex > -1) {
      return aIndex < bIndex ? -1 : 1;
    }
    // Only A has been added
    if (aIndex > -1) {
      return -1;
    }
    // Only B has been added
    if (bIndex > -1) {
      return 1;
    }
    // Sort alphabetically
    return caselessCompare(a.name, b.name);
  });
  return sortedTags;
};
