import React, { useCallback } from 'react';

import { AggTable } from 'api/APITypes';

import HoveredIndexWrapper from './HoveredIndexWrapper';
import TableRow from './TableRow/TableRow';

export type DisabledTablesByTableID = { [tableId: string]: AggTable };

export interface PassedToTableRowProps {
  highlightFilter: string | RegExp;
  onSetTable(table: AggTable): void;
  onDoubleClickTable(table: AggTable): void;
}

interface PassedToInnerTableNameListProps {
  filteredTables: AggTable[];
  selectedTable: AggTable | null;
  disabledTablesByID: DisabledTablesByTableID;
  passedToTableRow: PassedToTableRowProps;
}

interface TableNameListProps extends PassedToInnerTableNameListProps {
  maxHeight: string;
  renderTableHover?(
    hoveredIndex: number,
    hoveredTable: AggTable,
    selectedTable: AggTable | null,
    overlayRight: number,
  ): React.ReactNode;
}

interface InnerTableNameListProps extends PassedToInnerTableNameListProps {}

const TableNameList = React.memo((props: TableNameListProps) => {
  const { filteredTables, maxHeight, renderTableHover, selectedTable, ...rest } = props;

  const getHoveredItem = useCallback(
    (hoveredIndex: number) => {
      return filteredTables[hoveredIndex];
    },
    [filteredTables],
  );

  return (
    <HoveredIndexWrapper
      maxHeight={maxHeight}
      renderTableHover={renderTableHover}
      selectedTable={selectedTable}
      getHoveredItem={getHoveredItem}
      renderedTables={
        <InnerTableNameList filteredTables={filteredTables} selectedTable={selectedTable} {...rest} />
      }
    />
  );
});

const InnerTableNameList = React.memo((props: InnerTableNameListProps) => {
  const { filteredTables, selectedTable, disabledTablesByID, passedToTableRow } = props;

  return (
    <div className="tt-table-list-name-list">
      {filteredTables.map((table: AggTable) => {
        const disabled = disabledTablesByID[table.id] !== undefined;
        return (
          <TableRow
            key={table.id}
            table={table}
            disabled={disabled}
            selected={table.id === selectedTable?.id}
            {...passedToTableRow}
          />
        );
      })}
    </div>
  );
});

export default TableNameList;
