import React from 'react';

export interface File {
  filename: string;
  content: string;
  sendToGPT: boolean;
  locked: boolean;
}

interface FilePanelProps {
  files: File[];
  onToggleSend: (index: number) => void;
  onToggleLock: (index: number) => void;
  onSelect: (filename: string) => void;
  onClear: (filename: string) => void;
}

const sortWeight = (a: any) => (a.content ? 0 : 1);

const buildFileTree = (files: File[]) => {
  const tree: Record<string, any> = {};
  files.forEach((file) => {
    const parts = file.filename.split('/');
    let current = tree;
    parts.forEach((part, index) => {
      if (!current[part]) {
        current[part] = index === parts.length - 1 ? file : {};
      }
      current = current[part];
    });
  });
  const sortTree = (node: any): any => {
    if (node._isFolder) {
      delete node._isFolder;
      const keys = Object.keys(node).sort((a, b) => sortWeight(node[a]) - sortWeight(node[b]));
      return keys.reduce((obj: any, key: string) => ({ ...obj, [key]: sortTree(node[key]) }), {});
    }
    return node;
  };

  return sortTree(tree);
};

const FilePanel: React.FC<FilePanelProps> = ({
  files,
  onToggleSend,
  onToggleLock,
  onSelect,
  onClear,
}) => {
  const fileTree = buildFileTree(files);

  const folderStyle = {
    backgroundColor: '#f0f0f0',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '5px 10px',
    margin: '5px 0',
    boxShadow: 'inset 0 0 10px #ccc',
  };

  const renderTree = (node: Record<string, any>) => {
    const sortedKeys = Object.keys(node).sort((a, b) => sortWeight(node[a]) - sortWeight(node[b]));
    return sortedKeys.map((key) => {
      const isFolder = typeof node[key] === 'object' && !node[key].content;
      const paddingStyle = isFolder ? {} : { paddingLeft: '20px', ...folderStyle };
      const elementKey = `${key}`;
      if (isFolder) {
        return (
          <div key={elementKey} style={paddingStyle}>
            <div style={folderStyle}>{key.split('/').pop()}</div>
            <div style={{ paddingLeft: '5px' }}>{renderTree(node[key])}</div>
          </div>
        );
      } else {
        const fileNameOnly = node[key].filename.split('/').pop();
        return (
          <div key={key} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
            <input
              type="checkbox"
              checked={node[key].sendToGPT}
              onChange={() =>
                onToggleSend(files.findIndex((file) => file.filename === node[key].filename))
              }
              style={{ marginRight: '5px' }}
              title={
                node[key].sendToGPT ? `GPT can see ${fileNameOnly}` : `GPT cannot see ${fileNameOnly}`
              }
            />
            <button
              onClick={() =>
                onToggleLock(files.findIndex((file) => file.filename === node[key].filename))
              }
              title={
                node[key].locked ? `GPT cannot change ${fileNameOnly}` : `GPT can change ${fileNameOnly}`
              }
            >
              {node[key].locked ? '🔒' : '🔓'}
            </button>
            <button
              onClick={() => onClear(node[key].filename)}
              style={{ marginRight: 'auto' }}
              title={`Declutter this panel by forgetting ${fileNameOnly}`}
            >
              🗑️
            </button>
            <span
              onClick={() => onSelect(node[key].filename)}
              style={{
                marginRight: '5px',
                cursor: 'pointer',
                color: node[key].sendToGPT ? 'inherit' : '#D3D3D3',
              }}
            >
              {fileNameOnly}
            </span>
          </div>
        );
      }
    });
  };

  return <div className="file-panel">{renderTree(fileTree)}</div>;
};

export default FilePanel;
