/*
  Button used by CheckboxCardGrid.
  You probably want to use CheckboxCardGrid instead of this.
*/
import cn from 'classnames';

import FakeCheckbox from 'components/inputs/fake/FakeCheckbox/FakeCheckbox';

export interface VisibleCheckboxCardProps {
  checked: boolean;
  title: string;
  description: string;
}

export interface CheckboxCardProps extends VisibleCheckboxCardProps {
  onClick(): void;
}

const CheckboxCard = (props: CheckboxCardProps) => {
  const { checked, title, description, onClick } = props;

  const selectionStateClasses = {
    selectedTabClasses: 'bg-sec-purple-50 border-2 border-purple',
    unselectedTabClasses: 'bg-white border border-pri-gray-300',
  };

  const containerClass = cn('w-full h-full outline-0 rounded hover:cursor-pointer', {
    [selectionStateClasses.selectedTabClasses]: checked,
    [selectionStateClasses.unselectedTabClasses]: !checked,
  });

  // The border is wider when the button is checked, which makes this box narrower, which can shift text to the next line.
  // Add padding when the border is narrower so the content of the box is not displaced.
  const borderCompensationClass = checked ? undefined : 'p-[1px]';
  const paddingClass = 'pl-2 py-4 pr-4 f-row-y-center';

  return (
    <button className={containerClass} onClick={onClick}>
      <div className={borderCompensationClass}>
        <div className={paddingClass}>
          <div className="w-4 h-4 shrink-0">
            <FakeCheckbox checked={checked} />
          </div>
          <div className="ml-4 text-left text-normal">
            <h1 className="font-medium">{title}</h1>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </button>
  );
};

export default CheckboxCard;
