import _ from 'lodash';

import { CompanyPlanRow } from 'pages/UsageV2/useUsageFetch';
import { parseApiDate } from 'utils/dateTime';

import BillingCycleDates from './BillingCycleDates';
import PlanSummary from './PlanSummary';
import UsageMetricWidget from './UsageMetricWidget';

export type AllWidgetsENUM =
  | 'CurrentCycleDates'
  | 'PlanSummary'
  | 'MAR'
  | 'Compute'
  | 'Storage'
  | 'CloudService'
  | 'EmptyMetricsCard';

// TODO: make value a component type
export type AvailableLayoutWidgetsType = {
  [key in AllWidgetsENUM]: any;
};

const LayoutWidgets: AvailableLayoutWidgetsType = {
  CurrentCycleDates: BillingCycleDates,
  PlanSummary,
  // TODO: better typing
  MAR: ({ currentInvoice, mode, companyPlanByMonth }: any) => {
    const allotmentGrain = currentInvoice?.['RECENT_ALLOTMENT_GRAIN'];
    const header = allotmentGrain === 'Annual' ? 'Annual MAR used' : 'MAR used this month';

    let annualUnitsUsed = 0;
    if (allotmentGrain === 'Annual' && currentInvoice?.['RECENT_ANNUAL_START_DATE']) {
      const annualStartDate = parseApiDate(currentInvoice?.['RECENT_ANNUAL_START_DATE']);
      const annualPlanMonths = companyPlanByMonth.filter((p: CompanyPlanRow) => {
        const monthStart = parseApiDate(p.MONTH_START);
        return monthStart && annualStartDate && monthStart >= annualStartDate;
      });
      annualUnitsUsed = _.sumBy(annualPlanMonths, 'SUM_MAR');
    }

    return (
      <UsageMetricWidget
        containerClass="w-full flex flex-col justify-between"
        headerText={header}
        mode={mode}
        units="active rows"
        cost={currentInvoice?.['SUM_FIVETRAN_NET']}
        monthlyUnitsUsed={currentInvoice?.['SUM_MAR']}
        monthlyAllotment={currentInvoice?.['SUM_FIVETRAN_MAR_ALLOTMENT']}
        annualUnitsUsed={annualUnitsUsed}
        annualAllotment={currentInvoice?.['SUM_FIVETRAN_MAR_ALLOTMENT'] * 12}
        showOverage={currentInvoice?.['IS_FIVETRAN_OVERAGE'] && allotmentGrain !== 'Annual'}
        overageCost={currentInvoice?.['SUM_FIVETRAN_OVERAGE_NET']}
        backgroundColor="--sec-blue-50"
        progressBarBackgroundColor="--sec-blue-100"
        textAndBarColor="--sec-blue-700"
      />
    );
  },
  Compute: ({ mode, currentInvoice, companyPlanByMonth }: any) => {
    const allotmentGrain = currentInvoice?.['RECENT_ALLOTMENT_GRAIN'];
    const header =
      allotmentGrain === 'Annual' ? 'Annual Compute Credits used' : 'Compute Credits used this month';

    let annualUnitsUsed = 0;
    if (allotmentGrain === 'Annual' && currentInvoice?.['RECENT_ANNUAL_START_DATE']) {
      const annualStartDate = parseApiDate(currentInvoice?.['RECENT_ANNUAL_START_DATE']);
      const annualPlanMonths = companyPlanByMonth.filter((p: CompanyPlanRow) => {
        const monthStart = parseApiDate(p.MONTH_START);
        return monthStart && annualStartDate && monthStart >= annualStartDate;
      });
      annualUnitsUsed = _.sumBy(annualPlanMonths, 'SUM_CREDITS_BILLED');
    }

    return (
      <UsageMetricWidget
        containerClass="w-full flex flex-col justify-between"
        headerText={header}
        units="compute credits"
        cost={currentInvoice?.['SUM_COMPUTE_NET']}
        monthlyUnitsUsed={currentInvoice?.['SUM_CREDITS_BILLED']}
        monthlyAllotment={currentInvoice?.['SUM_SNOWFLAKE_CREDITS_ALLOTMENT']}
        annualUnitsUsed={annualUnitsUsed}
        annualAllotment={currentInvoice?.['SUM_SNOWFLAKE_CREDITS_ALLOTMENT'] * 12}
        showOverage={currentInvoice?.['IS_SNOWFLAKE_OVERAGE'] && allotmentGrain !== 'Annual'}
        overageCost={currentInvoice?.['SUM_SNOWFLAKE_OVERAGE_NET']}
        mode={mode}
        backgroundColor="--pri-success-50"
        progressBarBackgroundColor="--pri-success-100"
        textAndBarColor="--pri-success-700"
      />
    );
  },
  Storage: ({ currentInvoice, mode }: any) => (
    <UsageMetricWidget
      containerClass="basis-1/4 flex flex-col flex-1 justify-between"
      mode={mode}
      headerText="Storage used this month"
      units="GB"
      cost={currentInvoice?.['MAX_STORAGE_NET']}
      monthlyUnitsUsed={currentInvoice?.['MAX_AVG_STORAGE_GB']}
      monthlyAllotment={0} // not used
      annualUnitsUsed={0} // not used
      annualAllotment={0} // not used
      showOverage={false} // not used
      overageCost={0} // not used
      backgroundColor="--sec-orange-50"
      progressBarBackgroundColor="--sec-orange-100"
      textAndBarColor="--sec-orange-700"
    />
  ),
  CloudService: ({ currentInvoice, mode }: any) => (
    <UsageMetricWidget
      containerClass="basis-1/4 flex flex-col flex-1 justify-between"
      mode={mode}
      headerText="Cloud Service Credits used this month"
      units="cloud service credits"
      cost={currentInvoice?.['SUM_CLOUD_SERVICES_NET']}
      monthlyUnitsUsed={currentInvoice?.['SUM_CREDITS_CLOUD_SERVICES']}
      monthlyAllotment={0} // not used
      annualUnitsUsed={0} // not used
      annualAllotment={0} // not used
      showOverage={false} // not used
      overageCost={0} // not used
      backgroundColor="--sec-indigo-50"
      progressBarBackgroundColor="--sec-indigo-100"
      textAndBarColor="--sec-indigo-700"
    />
  ),
  EmptyMetricsCard: () => (
    <div className="p-4 w-full h-[300px] flex flex-col bg-gray-50 col-span-2">
      <img
        className="w-1/2 mx-auto block h-[200px] mt-4"
        src="/images/usage/Usage_Sonata_Empty.svg"
        alt=""
      />
      <p className="text-center text-lg font-bold">
        Usage data is updated daily, please check back later.
      </p>
    </div>
  ),
};

export default LayoutWidgets;
