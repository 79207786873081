import React from 'react';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';

interface LoadingPlaceholderProps {
  loading: boolean;
  error: string;
  spinnerMinHeight: string;
  children: React.ReactNode;
}

export default function LoadingPlaceholder(props: LoadingPlaceholderProps) {
  const { loading, error, spinnerMinHeight, children } = props;
  if (loading) {
    return <CenteredSpinner containerMinHeight={spinnerMinHeight} />;
  }
  if (error) {
    return <Alert variant="error">{error}</Alert>;
  }

  return <>{children}</>;
}
