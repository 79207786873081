import React, { useState, useRef, useEffect } from 'react';

import { useScroll } from 'react-use';

import cn from 'classnames';

import useComponentSize from '@rehooks/component-size';

import s from './OverlayArea.module.css';

/*
 * This used to be a a component that called a render prop
 * but it was broken into a hook and a component to reduce
 * rerenders.
 */
export function useOverlayArea() {
  const [overlayRight, setOverlayRight] = useState(0);

  // For scrollable area with css overflow
  const scrollRef = useRef(null);
  const scrollSize = useComponentSize(scrollRef);
  const scroll = useScroll(scrollRef);

  // For expanding area inside scrollable area
  // This div is a wrapper around all scrollable content
  // that you can add content to.
  const innerRef = useRef(null);
  const innerSize = useComponentSize(innerRef);

  // Calculate Overlay Right
  // FYI: It does not appear that useComonentSize() resizes when you change the
  // browser window size. Good enough for now.
  useEffect(() => {
    // Browser's scroll bars range from 12-17 pixels. Use the widest.
    const scrollbarWidth = 17;
    let barAdjustment = innerSize.width > scrollSize.width ? scrollbarWidth : 0;
    let right = Math.max(innerSize.width - scrollSize.width - scroll.x + barAdjustment, 0);
    setOverlayRight(right);
  }, [scrollSize.width, scroll.x, innerSize.width]);

  return { scrollRef, innerRef, overlayRight };
}

interface OverlayAreaProps {
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  scrollRef: React.MutableRefObject<any>;
  innerRef: React.MutableRefObject<any>;
}

export default function OverlayArea(props: OverlayAreaProps) {
  let { className, style, children, scrollRef, innerRef } = props;
  className = className || '';
  className = cn(s.scrolling, className);

  return (
    <div ref={scrollRef} className={className} style={style}>
      <div ref={innerRef} className={s.expanding}>
        {children}
      </div>
    </div>
  );
}
