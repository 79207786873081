/*
Listbox prefixed by label element and followed by an error element.
*/
import React from 'react';

import Listbox, { ListboxProps } from 'components/inputs/basic/Listbox/Listbox';

import InputGroup, { InputGroupParentProps } from '../InputGroup';

export type { ListboxOption, ListboxValue, ListboxSize } from 'components/inputs/basic/Listbox/Listbox';

type SanitizedListboxProps = Omit<ListboxProps, 'key'>;

export interface ListboxGroupProps extends InputGroupParentProps, SanitizedListboxProps {
  label: string;
}

export default function ListboxGroup(props: ListboxGroupProps) {
  const {
    name,
    value,
    label,
    options,
    postLabelElement,
    postLabelActionElement,
    superscriptDetail,
    superscriptDetailClass,
    subscriptDetail,
    subscriptDetailClass,
    groupClass,
    groupStyle,
    labelClass,
    labelStyle,
    error,
    ...inputProps
  } = props;

  const hasError = typeof error === 'string' && error.length > 0;
  const input = <Listbox value={value} options={options} hasError={hasError} {...inputProps} />;

  const groupProps = {
    name,
    label,
    options,
    postLabelElement,
    postLabelActionElement,
    superscriptDetail,
    superscriptDetailClass,
    subscriptDetail,
    subscriptDetailClass,
    groupClass,
    groupStyle,
    labelClass,
    labelStyle,
    error,
  };

  return <InputGroup input={input} {...groupProps} />;
}
