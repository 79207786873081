import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';

import PagedUserActionsTable from '../components/UserActionsTable/PagedUserActionsTable';
import { PagedUserActionsModel } from '../usePagedUserActions';

const ListUserActionsView = ({ error, isLoading, ...rest }: PagedUserActionsModel) => {
  return (
    <CenteredLayout
      loadingError={error}
      loading={isLoading}
      maxWidth="1110px"
      header={<h1 className="display-sm">User Actions</h1>}
    >
      <PagedUserActionsTable variant="line" {...rest} />
    </CenteredLayout>
  );
};

export default ListUserActionsView;
