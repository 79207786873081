import cronstrue from 'cronstrue';

export const scheduleOptionsMap: { [key: string]: string } = {
  '0 * * * *': 'Every Hour',
  '0 */2 * * *': 'Every 2 Hours',
  '0 */6 * * *': 'Every 6 Hours', // This is the default schedule
  '0 */12 * * *': 'Every 12 Hours',
  '0 9 * * *': 'Once a Day',
  '0 9 * * 0': 'Once a Week',
  '0 9 1 * *': 'Once a Month',
};

export function displayCron(schedule: string, mapOnly: boolean = false) {
  let ds = '';
  if (schedule in scheduleOptionsMap) {
    ds = scheduleOptionsMap[schedule];
  } else if (mapOnly) {
    return schedule;
  } else {
    ds = cronstrue.toString(schedule);
    // If cronstrue gives us a HH:MM time, add (UTC) at the end
    const hh_mm_regex = '(1[0-2]|0?[1-9]):([0-5][0-9])';
    if (ds.search(hh_mm_regex) !== -1) {
      ds = ds + ' (UTC)';
    }
  }
  return ds;
}
