import React from 'react';

import { Link } from 'react-router-dom';

import DevModeBanner from 'components/page_parts/headers/HeaderLayout/DevModeBanner/DevModeBanner';

export interface HeaderLayoutProps {
  logoPath: string;
  logoDataTrack: string;
  left: React.ReactNode;
  right: React.ReactNode;
  banner: React.ReactNode;
}

export default function HeaderLayout(props: HeaderLayoutProps) {
  const { logoPath, logoDataTrack, left, right, banner } = props;
  const justifyClass = !!left ? 'justify-between' : 'justify-end';
  return (
    <header className="w-full min-w-fit">
      <DevModeBanner />
      <div className="w-full px-4 py-2 flex justify-start bg-primary-700">
        <Link to={logoPath} data-track={logoDataTrack}>
          <img
            className="w-[40px] h-[40px] min-w-[40px] min-h-[40px]"
            src={'/images/logos/logo.svg'}
            alt={`Mozart Data Logo`}
          />
        </Link>
        <div className={`w-full ml-6 flex items-center ${justifyClass}`}>
          {left}
          {right}
        </div>
      </div>
      {banner}
    </header>
  );
}
