import { useState, useEffect, useRef } from 'react';

import api from 'api/sqlAIAPI';

interface useAIExplainSqlProps {
  sql: string;
}

const useAIExplainSql = (props: useAIExplainSqlProps) => {
  const { sql } = props;

  const [responseId, setResponseId] = useState('');
  const [explanation, setExplanation] = useState('');
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [takingLonger, setTakingLonger] = useState<boolean>(false);
  const timer: React.MutableRefObject<NodeJS.Timeout | null> = useRef(null);

  useEffect(() => {
    if (isLoading) {
      // Set timer to show loading message after 7 seconds
      timer.current = setTimeout(() => {
        setTakingLonger(true);
      }, 7000);
    } else {
      setTakingLonger(false);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [isLoading, timer]);

  const handleGenerateExplanation = async () => {
    setIsLoading(true);

    try {
      const response = await api.generateSqlExplanation({ sql });
      const responseData = response.data;

      setResponseId(responseData.gpt_response_id);
      setExplanation(responseData.explanation);
      setApiError(null);

      analytics.track('SqlEditor GenerateAIExplainSql', {
        response_id: responseData.gpt_response_id,
        sql,
        explanation: responseData.explanation,
      });
    } catch (error: any) {
      const errorData =
        error?.response?.data ?? 'There was an error generating the explanation. Please try again.';

      setResponseId('');
      setExplanation('');
      setApiError(errorData);

      analytics.track('SqlEditor GenerateAIExplainSqlError', {
        sql,
        error: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    takingLonger,
    responseId,
    explanation,
    apiError,
    onGenerateExplanation: handleGenerateExplanation,
  };
};

export default useAIExplainSql;
