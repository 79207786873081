import { Link } from 'react-router-dom';

import { AggTable } from 'api/APITypes';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import RunResultsTable from 'components/query/RunResults/RunResultsTable';
import Alert from 'components/widgets/alerts/Alert/Alert';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';

import { SummaryData } from '../usePreviewPaneData';

interface PreviewPaneProps {
  table: AggTable;
  loadingSummary: boolean;
  loadingError: string;
  summaryData: SummaryData | null;
}

export default function PreviewPane(props: PreviewPaneProps) {
  const { table, loadingSummary, loadingError, summaryData } = props;

  const { type, transform } = table;
  const isTransform = type === 'transform';
  const neverRan = isTransform && !transform?.last_completed_run;

  // loadingError is an API error.
  // error_lines is an SQL syntax problem or any other Snowflake error.
  let anyError: React.ReactNode = loadingError;
  if (summaryData?.error_lines) {
    anyError = summaryData.error_lines.map((line: string, i: number) => {
      return <p key={i}>{line}</p>;
    });
  }

  return (
    <>
      {!neverRan && !loadingSummary && !anyError && summaryData ? (
        <div className="w-full h-full">
          <RunResultsTable
            columns={summaryData.results.columns}
            rows={summaryData.results.rows}
            timeFormat={'TIMESTAMP'}
            maxLines={1}
            hideEmptyColumns={false}
            // This props are only used by RunResults. Pass in no-op parameters to make code compile. /
            selectedColumns={[]}
            hideUnselectedColumns={false}
            hideBorder={true}
            isShown={true}
            onToggleColumn={(column: string) => {}}
            onToggleAllColumns={() => {}}
          />
        </div>
      ) : (
        <div className="w-full h-full p-4">
          {loadingSummary && !neverRan && !anyError && (
            <div className="w-full h-full f-center">
              <CenteredSpinner containerMinHeight="100%" />
            </div>
          )}
          {anyError && <Alert variant="error">{anyError}</Alert>}
          {neverRan && (
            <FixMeAlert
              heading="This transform has never been run."
              detail={
                <p className="max-w-[400px] mt-2">
                  Go to this transform's{' '}
                  <Link
                    to={`/transforms/${table.transform?.id}/run`}
                    data-track="Warehouse TransformNeverRanScheduleLink"
                    className="text-sec-blue-light-700 font-medium"
                  >
                    Runs Tab
                  </Link>{' '}
                  and click the <code className="text-pri-gray-700">Run</code> button to create table{' '}
                  <code className="text-pri-gray-700">{table.full_name}</code>.
                </p>
              }
            />
          )}
        </div>
      )}
    </>
  );
}
