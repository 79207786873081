import { Link } from 'react-router-dom';

import cn from 'classnames';

import { UserProfile } from 'api/APITypes';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import { env } from 'utils/env';

import { Menu } from '@headlessui/react';

import HeaderIcon from '../HeaderIcon/HeaderIcon';

import menuStyle from 'components/widgets/Menu/Menu.module.css';

const divider = <div className="h-px w-full bg-pri-gray-200" />;

interface UserMenuProps {
  accountDisabled: boolean;
  userProfile: UserProfile;
  logout: () => void;
}

export const UserMenu = (props: UserMenuProps) => {
  const { accountDisabled, userProfile, logout } = props;

  const hasPartnerfleet = useBooleanFlag('partnerfleet');
  const databaseType = userProfile.company.database_account?.type;

  const isViewer = userProfile.company_role === 'viewer';

  return (
    <Menu as="div" className={menuStyle.menu}>
      <Menu.Button className="ml-2" id="nav-user-dropdown">
        <HeaderIcon type="user" />
      </Menu.Button>
      <Menu.Items className={cn('min-w-[150px]', menuStyle.menuItems)}>
        <div className="p-3">
          <div className="whitespace-nowrap text-purple">
            {userProfile.first_name} {userProfile.last_name}
          </div>
          <div className="text-pri-gray-500">{userProfile.user.email}</div>
          <div className="text-pri-gray-500">{userProfile.company.name}</div>
        </div>
        {divider}
        {!accountDisabled && (
          <>
            <Menu.Item>
              <Link to="/users" data-track="Header ListUsers" className={menuStyle.menuItem}>
                Users
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/settings" data-track="Header Settings" className={menuStyle.menuItem}>
                Settings
              </Link>
            </Menu.Item>
            {!isViewer && databaseType !== 'bigquery' && (
              <Menu.Item>
                <Link to="/usage/fivetran" data-track="Header Usage" className={menuStyle.menuItem}>
                  Usage
                </Link>
              </Menu.Item>
            )}
            {divider}
            <Menu.Item>
              <Link to="/user-actions" data-track="Header UserActions" className={menuStyle.menuItem}>
                User Actions
              </Link>
            </Menu.Item>
          </>
        )}

        {userProfile.user.email.endsWith('@mozartdata.com') && (
          <>
            <Menu.Item>
              <Link to="/experiments" data-track="Header Experiments" className={menuStyle.menuItem}>
                Experiments
              </Link>
            </Menu.Item>
            {userProfile.user.is_staff && (
              <Menu.Item>
                <Link
                  to="/internal/lookup"
                  data-track="Header InternalTools"
                  className={menuStyle.menuItem}
                >
                  Internal Tools
                </Link>
              </Menu.Item>
            )}
            {(env === 'production' || env === 'staging') && (
              <a
                href={
                  env === 'production'
                    ? 'https://app.launchdarkly.com/default/production/features'
                    : 'https://app.launchdarkly.com/default/staging/features'
                }
                rel="noopener noreferrer"
                target="_blank"
                className={menuStyle.menuItem}
              >
                LaunchDarkly
              </a>
            )}
          </>
        )}
        {!accountDisabled && (
          <>
            {divider}
            {hasPartnerfleet && (
              <Menu.Item>
                <Link
                  to="/partnerships"
                  data-track="Header Partners"
                  className={'text-purple ' + menuStyle.menuItem}
                >
                  Partners
                </Link>
              </Menu.Item>
            )}
            <Menu.Item>
              <a
                href="https://www.mozartdata.com/referral-program"
                rel="noopener noreferrer"
                target="_blank"
                data-track="Header ReferralProgram"
                className={'text-purple ' + menuStyle.menuItem}
              >
                Referral Program
              </a>
            </Menu.Item>
          </>
        )}
        {divider}
        <Menu.Item>
          <div onClick={logout} className={menuStyle.menuItem}>
            Log Out
          </div>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export default UserMenu;
