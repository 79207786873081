import { useCallback, useEffect } from 'react';

import API from 'api/API';
import { useUserProfile } from 'context/AuthContext';
import { usePolling } from 'hooks/usePolling';
import { parseIso } from 'utils/dateTime';

interface UpdatePollingProps {
  tablesIsLoading: boolean;
  lastTablesChange: Date | null;
  refreshTables: () => void;
  columnsIsLoading: boolean;
  lastColumnsChange: Date | null;
  refreshColumns: () => void;
  transformsIsLoading: boolean;
  lastTransformsChange: Date | null;
  refreshTransforms: () => void;
}

export default function useUpdatePolling(props: UpdatePollingProps) {
  const { userProfile } = useUserProfile();
  const {
    tablesIsLoading,
    lastTablesChange,
    refreshTables,
    columnsIsLoading,
    lastColumnsChange,
    refreshColumns,
    transformsIsLoading,
    lastTransformsChange,
    refreshTransforms,
  } = props;

  // Step 1:
  // Trigger the user's database to sync, in case it hasn't for a while and something's changed.
  // The backend throttles database syncs, so it may not execute this sync immediately.
  useEffect(() => {
    const api = new API();
    api.post('/api/tables/schedule_database_sync', {});
  }, []);

  // Step 2:
  // Poll for last_tables_change and last_columns_change.
  // Trigger a refresh if they are newer than our client's copies.
  // Only enable this poll when the initial loading of the APIs is complete.
  const pollingEnabled =
    !tablesIsLoading &&
    !columnsIsLoading &&
    !transformsIsLoading &&
    Boolean(userProfile?.company?.database_account);

  // FYI:
  // This used to be a sequence that started polling frequently and had exponential decay.
  // On 04/16/2024, we turned the polling time way down to try and improve performance.
  // See commit e94d861118ec5e0235a4cefe315e8541f9cdce08 for change.
  const pollingIntervalSequence = [600];

  // If the polling payload says our local state is out of date, trigger a refetch.
  const onPolledChangeTimes = useCallback(
    (changeTimes: any) => {
      // If we have poll data, and lastTablesChange and lastColumnsChange are not null (meaning we got tables and column data once from api)
      // If the poll shows we have newer data in django than we have in the frontend, refetch
      const polledLastTablesChange = parseIso(changeTimes.last_tables_change);
      const polledLastColumnsChange = parseIso(changeTimes.last_columns_change);
      const polledLastTransformsChange = parseIso(changeTimes.last_transforms_change);
      // If a lastChange is null, it means django has never synced to snowflake (new account)
      //   or it hasn't synced since this project merged.
      // In either case, our frontend shouldn't be out of sync with the backend, so don't refresh.
      if (polledLastTablesChange && polledLastTablesChange > (lastTablesChange as Date)) {
        refreshTables();
      }
      if (polledLastColumnsChange && polledLastColumnsChange > (lastColumnsChange as Date)) {
        refreshColumns();
      }
      if (polledLastTransformsChange && polledLastTransformsChange > (lastTransformsChange as Date)) {
        refreshTransforms();
      }
    },
    [
      lastTablesChange,
      lastColumnsChange,
      lastTransformsChange,
      refreshTables,
      refreshColumns,
      refreshTransforms,
    ],
  );

  // Start polling
  usePolling(
    '/api/tables/get_last_models_changes',
    onPolledChangeTimes,
    pollingIntervalSequence,
    pollingEnabled,
  );
}
