/*
  This is an abstraction.
  TooltipTrigger, Tooltip, or Popover probably does what you want.
  
  This is a fork of https://github.com/react-bootstrap/react-bootstrap/blob/master/src/OverlayTrigger.tsx

  We forked because:
  1. We wanted wanted to drop all dependence on react-bootstrap.
  2. We wanted to be able to easily edit things.
*/
import React, { useEffect, useState } from 'react';
import { cloneElement, useCallback, useRef } from 'react';

import useTimeout from '@restart/hooks/useTimeout';

// hover_click_lock = Hovering opens the trigger. If you click on the element it will stay open until you click it again, even if you hover out.
export type OverlayTriggerType = 'hover' | 'hover_click_lock' | 'click' | 'focus';

export type OverlayDelay = number | { show: number; hide: number };

export type OverlayTriggerRenderProps = {
  ref: React.Ref<any>;
};

export interface OverlayTriggerProps {
  children: React.ReactElement | ((props: OverlayTriggerRenderProps) => React.ReactNode);
  renderOverlay: () => React.ReactNode;
  triggers?: OverlayTriggerType | OverlayTriggerType[]; // Events that trigger the overlay to show.
  delay?: OverlayDelay;
  forceShow?: boolean; // If not undefined, ignore the trigger conditions and show or hide the tip.
}

const OverlayTrigger = React.forwardRef((props: OverlayTriggerProps, ref) => {
  const { children, forceShow, renderOverlay } = props;
  const triggers = normalizeTriggers(props.triggers);
  const delay = normalizeDelay(props.delay);

  const hasClickLock = triggers.includes('hover_click_lock');
  const timeout = useTimeout();
  const hoverStateRef = useRef<string>('');
  const [show, setShow] = useState(false);
  const [wasClicked, setWasClicked] = useState(false);

  const { onFocus, onBlur, onClick } =
    typeof children !== 'function' ? React.Children.only(children).props : ({} as any);

  // Hide the tooltip if the trigger was disabled.
  useEffect(() => {
    if (forceShow === false && (show || wasClicked)) {
      setShow(false);
      setWasClicked(false);
    }
  }, [show, wasClicked, forceShow]);

  const handleShow = useCallback(() => {
    if (forceShow === false) {
      return;
    }

    timeout.clear();
    hoverStateRef.current = 'show';

    if (delay.show === 0) {
      setShow(true);
      return;
    }

    timeout.set(() => {
      if (forceShow === undefined && hoverStateRef.current === 'show') {
        setShow(true);
      }
    }, delay.show);
  }, [delay.show, forceShow, timeout]);

  const handleHide = useCallback(() => {
    if (forceShow === false) {
      return;
    }

    timeout.clear();
    hoverStateRef.current = 'hide';

    if (delay.hide === 0) {
      setShow(false);
      return;
    }

    timeout.set(() => {
      if (forceShow === undefined && hoverStateRef.current === 'hide') {
        setShow(false);
      }
    }, delay.hide);
  }, [delay.hide, forceShow, timeout]);

  const handleFocus = useCallback(
    (...args: any[]) => {
      handleShow();
      onFocus?.(...args);
    },
    [handleShow, onFocus],
  );

  const handleBlur = useCallback(
    (...args: any[]) => {
      handleHide();
      onBlur?.(...args);
    },
    [handleHide, onBlur],
  );

  const handleClick = useCallback(
    (...args: any[]) => {
      if (forceShow === undefined) {
        if (wasClicked) {
          setWasClicked(false);
          setShow(false);
        } else {
          setWasClicked(true);
          setShow(true);
        }
        onClick?.(...args);
      }
    },
    [onClick, forceShow, wasClicked],
  );

  const handleOnEnter = useCallback(() => {
    handleShow();
  }, [handleShow]);

  const handleOnLeave = useCallback(() => {
    // User can click the icon to hold it open until they click it again.
    if (!(hasClickLock && wasClicked)) {
      handleHide();
    }
  }, [hasClickLock, wasClicked, handleHide]);

  const handleOnDragStart = useCallback(() => {
    // User can click the icon to hold it open until they click it again.
    if (!(hasClickLock && wasClicked)) {
      handleHide();
    }
  }, [hasClickLock, wasClicked, handleHide]);

  const triggerProps: any = {
    ref,
  };

  if (triggers.indexOf('click') !== -1 || triggers.indexOf('hover_click_lock') !== -1) {
    triggerProps.onClick = handleClick;
  }

  if (triggers.indexOf('focus') !== -1) {
    triggerProps.onFocus = handleFocus;
    triggerProps.onBlur = handleBlur;
  }

  if (triggers.indexOf('hover') !== -1 || triggers.indexOf('hover_click_lock') !== -1) {
    // onPointer events work on disabled elements. onMouse events do not.
    triggerProps.onPointerEnter = handleOnEnter;
    triggerProps.onPointerLeave = handleOnLeave;
    triggerProps.onDragStart = handleOnDragStart;
  }

  const actuallyShow = forceShow === undefined ? show : forceShow;

  return (
    <>
      {typeof children === 'function' ? children(triggerProps) : cloneElement(children, triggerProps)}
      {actuallyShow && renderOverlay()}
    </>
  );
});

function normalizeDelay(delay?: OverlayDelay) {
  if (delay !== undefined) {
    return typeof delay === 'object'
      ? delay
      : {
          show: delay,
          hide: delay,
        };
  }
  return { show: 250, hide: 250 };
}

function normalizeTriggers(propsTriggers?: OverlayTriggerType | OverlayTriggerType[]) {
  let triggers = propsTriggers === undefined ? ['focus', 'click'] : propsTriggers;
  if (typeof triggers === 'string') {
    triggers = [triggers];
  }
  return triggers;
}

export default OverlayTrigger;
