/*
Picks which sidebar to render.
*/
import React, { useContext } from 'react';

import { useLocation } from 'react-router';

import CompanySetupGuide, {
  MAX_WIDTH,
  MIN_WIDTH,
  STARTING_WIDTH,
} from 'components/page_parts/CompanySetupGuide/CompanySetupGuide';
import { HIDE_TRIAL_BANNER_ROUTES } from 'main/MainApp';
import { CompanySetupGuideContext } from 'model_layer/CompanySetupGuideContext';

export interface SidebarProps {
  sidebar: React.ReactNode;
  startingWidth: number;
  minWidth: number;
  maxWidth: number;
}

export type PickedSidebar = SidebarProps | null;

export default function usePickSidebar(): PickedSidebar {
  const { pathname } = useLocation();
  const { show } = useContext(CompanySetupGuideContext);

  if (HIDE_TRIAL_BANNER_ROUTES.includes(pathname)) {
    return null;
  }

  if (show) {
    const sidebar: SidebarProps = {
      sidebar: <CompanySetupGuide />,
      startingWidth: STARTING_WIDTH,
      minWidth: MIN_WIDTH,
      maxWidth: MAX_WIDTH,
    };
    return sidebar;
  }

  return null;
}
