/*
  Wraps PasswordInputGroup and injects the Formik hook props into the PasswordInputGroup.
*/
import React from 'react';

import { useField } from 'formik';

import PasswordInputGroup, {
  TextInputGroupProps,
} from 'components/inputs/group/PasswordInputGroup/PasswordInputGroup';

// We get value from Formik
export type SanitizedTextInputGroupProps = Omit<TextInputGroupProps, 'value'>;

export interface TextFormikGroupProps extends SanitizedTextInputGroupProps {}

export default function PasswordFormikGroup(props: TextFormikGroupProps) {
  const { name, ...rest } = props;
  const [field, meta] = useField(name);
  const { name: fieldName, value: fieldValue, ...fieldRest } = field;

  return (
    <PasswordInputGroup
      name={name}
      value={meta.value}
      error={meta.touched && meta.error ? meta.error : ''}
      {...fieldRest}
      {...rest}
    />
  );
}
