import React from 'react';

import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import { formatApiDate, formatDate, parseIso } from 'utils/dateTime';

import { AgoHoverProps, useAgo } from '../AgoHover/AgoHover';

// Format time means including time in the tooltip, not just the date.
interface DateHoverProps extends AgoHoverProps {
  formatTime?: boolean;
}

export default function DateHover(props: DateHoverProps) {
  const { date, interval, disableInterval, className, formatTime = true } = props;
  const dateObj = typeof date === 'string' ? parseIso(date) : date;
  const ago = useAgo(dateObj, interval, disableInterval);

  if (!dateObj) {
    return null;
  }

  const formatted = formatTime ? formatDate(dateObj) : formatApiDate(dateObj, 'MMM D, YYYY');

  return (
    <TooltipTrigger tip={ago}>
      <span data-iso-time={dateObj.toISOString()} data-ago={ago} className={className}>
        {formatted}
      </span>
    </TooltipTrigger>
  );
}
