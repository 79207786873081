import React from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { UserProfile } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';

import styles from './ProfileSettings.module.css';

export type SavableProfileValues = Pick<UserProfile, 'first_name' | 'last_name'>;

interface ProfileSettingsProps {
  isEditing: boolean;
  isSaving: boolean;
  profileValues: SavableProfileValues;
  onEdit: () => void;
  onSubmitEdit: (values: SavableProfileValues) => void;
  onCancelEdit: () => void;
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Please enter a first name.'),
  last_name: Yup.string().required('Please enter a last name.'),
});

const ProfileSettings = (props: ProfileSettingsProps) => {
  const { isEditing, isSaving, profileValues, onEdit, onSubmitEdit, onCancelEdit } = props;
  const { first_name: firstName, last_name: lastName } = profileValues;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 className="text-xl text-pri-gray-700 mt-2 mb-2">Profile</h2>
      </div>
      <div className="mb-8">
        {isEditing ? (
          <Formik
            initialValues={profileValues}
            onSubmit={onSubmitEdit}
            onReset={onCancelEdit}
            validationSchema={validationSchema}
          >
            {({ handleChange, handleSubmit, handleReset, isSubmitting }) => (
              <form onSubmit={handleSubmit} onReset={handleReset} className={styles.form}>
                <TextFormikGroup type="text" name="first_name" onChange={handleChange} />
                <TextFormikGroup type="text" name="last_name" onChange={handleChange} />
                <div className={styles.buttonRow}>
                  <Button
                    type="submit"
                    variant="lightAction"
                    size="form"
                    spinning={isSubmitting || isSaving}
                  >
                    Save
                  </Button>
                  <Button
                    variant="lightDanger"
                    className="ml-2"
                    size="form"
                    onClick={onCancelEdit}
                    disabled={isSaving}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        ) : (
          <div
            onClick={onEdit}
            className="w-fit p-2 cursor-pointer rounded hover:bg-pri-gray-100 active:bg-pri-gray-200"
          >
            {firstName} {lastName}
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileSettings;
