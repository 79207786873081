import React, { useState } from 'react';

import Button from 'components/inputs/basic/Button/Button';
import TextAreaInput from 'components/inputs/basic/TextAreaInput/TextAreaInput';
import Modal from 'components/layouts/containers/modals/Modal/Modal';

export interface EditButtonsProps {
  saving: boolean;
  onClose(): void;
  onSaveWithVersionDescription(versionDescription: string): void;
}

export default function EditButtons(props: EditButtonsProps) {
  const { saving, onClose, onSaveWithVersionDescription } = props;
  const [versionDescription, setVersionDescription] = useState<string>('');

  const handleChangeVersionDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setVersionDescription(event.target.value);
  };

  const handleSaveWithVersionDescription = () => {
    onSaveWithVersionDescription(versionDescription);
  };

  return (
    <Modal
      header="Enter Version Description"
      onClose={onClose}
      cancelButton={true}
      containerClass="w-[600px]"
    >
      <div className="flex flex-col p-4">
        <div className="flex justify-center">
          <TextAreaInput
            className="resize-none"
            maxLength={280}
            rows={3}
            name="versionDescription"
            value={versionDescription || ''}
            onChange={handleChangeVersionDescription}
            disabled={saving}
          />
        </div>
        <div className="flex justify-end mt-2">
          <Button
            variant="save"
            onClick={handleSaveWithVersionDescription}
            spinning={saving}
            disabled={!versionDescription}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
