import { useMemo } from 'react';

import { useQueryClient, QueryClient } from 'react-query';

import _ from 'lodash';
import url from 'url';

import API from 'api/API';
import { AppConnector, Connector } from 'api/APITypes';
import { LIST_CONNECTORS } from 'api/CacheKeys';
import { GetProps, useGet, usePoll } from 'hooks/useApi';
import {
  SetupState,
  calcSyncState,
  lastRunForConnector,
  taskWarningForStatus,
} from 'model_helpers/connectorHelper';
import { maybeMockConnectors } from 'model_layer/maybeMock';

import { fivetranConnectors } from './ConnectorRegistry';

function getConnectorQueryKey(djangoConnectorID: string) {
  return `connector-${djangoConnectorID}`;
}

export function useGetConnectors(friendlyErrorMsg?: string) {
  friendlyErrorMsg = friendlyErrorMsg || 'There was a problem loading your connectors.';
  let getProps = useGet(LIST_CONNECTORS, 'api/fivetran/connectors');
  return useSanitizeConnectorProps(getProps, friendlyErrorMsg);
}

export function usePollConnectors(friendlyErrorMsg?: string) {
  friendlyErrorMsg = friendlyErrorMsg || 'There was a problem loading your connectors.';
  const { resetPolling, ...rest } = usePoll<Connector[]>(LIST_CONNECTORS, 'api/fivetran/connectors');
  return { ...useSanitizeConnectorProps(rest, friendlyErrorMsg), resetPolling };
}

export function usePollConnector(
  djangoConnectorID: string | null,
  friendlyErrorMsg?: string,
  enabled?: boolean,
) {
  const {
    resetPolling,
    data: polledData,
    isLoading,
    isError,
    error,
  } = usePoll<Connector>(
    getConnectorQueryKey(djangoConnectorID || ''),
    `api/connectors/${djangoConnectorID}/fivetran_connector`,
    undefined,
    enabled,
  );
  // We need an additional memo here since useSanitizeConnectorProps takes an array but this function
  // polls a single connector, so we need to avoid constructing a new array out of the same connector
  // over and over, which would cause re-renders of components that use this.
  const connectorArray = useMemo(() => (polledData ? [polledData] : undefined), [polledData]);
  const sanitized = useSanitizeConnectorProps(
    { isLoading, isError, data: connectorArray, error },
    friendlyErrorMsg || 'There was a problem loading your connector.',
  );
  return {
    connector: sanitized.connectors.length ? sanitized.connectors[0] : null,
    isLoading: sanitized.isLoading,
    isError: sanitized.isError,
    error: sanitized.error,
    resetPolling,
  };
}

function sanitizeConnectorProps(
  { isLoading, isError, data }: GetProps<Connector[]>,
  friendlyErrorMsg: string,
) {
  const connectors = maybeMockConnectors(data || []).map(connectorToAppConnector);
  const strError = isError ? friendlyErrorMsg : '';
  return { isLoading, isError, connectors, error: strError };
}

function useSanitizeConnectorProps(
  { isLoading, isError, data, error }: GetProps<Connector[]>,
  friendlyErrorMsg: string,
) {
  /* We need to memoize this because otherwise we will get a new object every time, which will cause
    re-renders of components that use this. */
  return useMemo(() => {
    return sanitizeConnectorProps({ isLoading, isError, data, error }, friendlyErrorMsg);
  }, [isLoading, isError, data, friendlyErrorMsg, error]);
}

export function connectorToAppConnector(connector: Connector): AppConnector {
  const { status, service } = connector;
  const { paused: unusedPaused, ...rest } = connector; // we are defining paused again later, so throwing away this value
  const setupState = status.setup_state as SetupState;
  const syncState = calcSyncState(connector);
  const setupError = setupState !== 'connected' ? `Setup is ${setupState}.` : '';
  const lastRun = lastRunForConnector(connector);
  const { lastRunError } = lastRun;
  const { twError } = taskWarningForStatus(status);
  const disablingError = setupError || twError;
  const anyStatusError = setupError || lastRunError || twError;
  const startDisabledTables = fivetranConnectors[service]?.startDisabledTables ?? null;

  const paused = syncState === 'paused';
  const syncing = syncState === 'syncing';
  const isHistoricalSync = status.is_historical_sync;

  return {
    paused,
    syncing,
    setupState,
    syncState,
    disablingError,
    anyStatusError,
    lastRun,
    isHistoricalSync,
    startDisabledTables,
    ...rest,
  };
}

export function patch(queryClient: QueryClient, fivetran_connector_id: string, con: Connector) {
  queryClient.setQueryData(LIST_CONNECTORS, (oldData: Connector[] | undefined): Connector[] => {
    if (oldData === undefined) {
      return [con];
    }

    const i = oldData.findIndex((v) => v.fivetran_connector_id === fivetran_connector_id);

    if (i === -1) {
      return oldData;
    }

    const start = oldData.slice(0, i);
    const end = oldData.slice(i + 1);
    return [...start, con, ...end];
  });
}

export function patchOneConnector(queryClient: QueryClient, con: Connector) {
  queryClient.setQueryData(getConnectorQueryKey(con.id), con);
}

export function remove(queryClient: QueryClient, fivetran_connector_id: string) {
  queryClient.setQueryData(LIST_CONNECTORS, (oldData: Connector[] | undefined): Connector[] => {
    if (oldData === undefined) {
      return [];
    }

    const i = oldData.findIndex((v) => v.fivetran_connector_id === fivetran_connector_id);

    if (i === -1) {
      return oldData;
    }

    const start = oldData.slice(0, i);
    const end = oldData.slice(i + 1);
    return [...start, ...end];
  });
}

export function usePatch() {
  const queryClient = useQueryClient();
  return _.partial(patch, queryClient);
}

export function useRemove() {
  const queryClient = useQueryClient();
  return _.partial(remove, queryClient);
}

export function usePatchOneConnector() {
  const queryClient = useQueryClient();
  return _.partial(patchOneConnector, queryClient);
}

export function connectCardUrl(
  mozartConnectorID: string,
  token: string,
  startsPaused: boolean, // Start paused so the user can pick tables to sync before syncing
) {
  const tab = startsPaused ? 'manage_tables' : 'summary';
  // Fivetran will append an `id` query parameter to this URL when it
  // redirects the user back to this url.
  const redirectUrl = url.format({
    protocol: window.location.protocol,
    host: window.location.host,
    pathname: `/connectors/${mozartConnectorID}/${tab}`,
    query: { from_fivetran: true },
  });

  const cardUrl = url.format({
    protocol: 'https',
    host: 'fivetran.com/connect-card/setup',
    query: { redirect_uri: redirectUrl, auth: token },
  });

  return cardUrl;
}

export type LoggableConnector = Pick<Connector, 'id' | 'fivetran_connector_id'>;

export function trackConnector(
  event: string,
  connector: LoggableConnector,
  overrides?: Record<string, unknown>,
) {
  const connector_id = connector.id;
  const fivetran_connector_id = connector.fivetran_connector_id;
  trackConnectorID(event, connector_id, fivetran_connector_id, overrides);
}

export function trackConnectorID(
  event: string,
  connector_id: string,
  fivetran_connector_id: string,
  overrides?: any,
) {
  analytics.track(event, {
    connector_id,
    fivetran_connector_id,
    ...overrides,
  });
}

export const openConnectCard = async (props: {
  mozartConnectorId: string;
  fivetranConnectorId: string;
  startsPaused: boolean;
  setError: (error: string) => void;
  setLoading: (loading: boolean) => void;
}) => {
  const { mozartConnectorId, fivetranConnectorId, startsPaused, setError, setLoading } = props;
  const api = new API();

  setError('');
  setLoading(true);

  try {
    const response = await api.get(`api/fivetran/connector/token/${fivetranConnectorId}`);
    // Stop spinning. There is a brief time before the redirect, but it will not spin if the user presses the browser back button from the Fivetran ConnectCard in Chrome.
    setLoading(false);
    window.location.href = connectCardUrl(mozartConnectorId, response.data.token, startsPaused);
  } catch (error) {
    setError('Failed to get connect token.');
    setLoading(false);
  }
};

export function connectorStartsPaused(service: string) {
  const connectorType = fivetranConnectors[service];
  return connectorType?.startPaused || connectorType?.syncScope === 'prefixed_schemas';
}
