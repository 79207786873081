import { QueryRunResults } from 'api/APITypes';

export default class QueryRunCache {
  // QueryRunResults = We have query results
  // null = The query has errors and we didn't run it
  // undefined = We have no previous record of this query
  private cache: Map<string, QueryRunResults | null>;

  constructor() {
    this.cache = new Map<string, QueryRunResults>();
  }

  get(query: string): QueryRunResults | null | undefined {
    return this.cache.get(query);
  }

  set(query: string, results: QueryRunResults | null): void {
    this.cache.set(query, results);
  }
}
