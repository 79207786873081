import { TableColumn } from '../../../model/FlowchartQueryModel';

import VertexModal from '../../VertexModal/VertexModal';

import ColumnSelector from './ColumnSelector/ColumnSelector';
import useColumnSelector, { selectionsToSave } from './ColumnSelector/useColumnSelector';

export interface SelectColumnsModalProps {
  pickTitle: string;
  availableColumns: TableColumn[];
  selectedColumns?: TableColumn[];
  onCancel(): void;
  onSave(columnSelections?: TableColumn[]): void;
}

const SelectColumnsModal = (props: SelectColumnsModalProps) => {
  const { pickTitle, availableColumns, selectedColumns, onCancel, onSave } = props;
  const columnSelectorState = useColumnSelector(availableColumns, selectedColumns);
  const { currentSelections, formError } = columnSelectorState;

  // Save undefined if the user is using the default set of columns.
  // Save the entire list if a single prop is changed or
  // if the order of the columns in the list changes.
  const handleSave = () => {
    const toSave = selectionsToSave(availableColumns, currentSelections);
    onSave(toSave);
  };

  return (
    <VertexModal
      header={pickTitle}
      containerClass="w-[700px] h-[90%]"
      error={formError}
      onCancel={onCancel}
      onSave={handleSave}
    >
      <div className="h-full">
        <ColumnSelector enableScrollListMode={true} {...columnSelectorState} />
      </div>
    </VertexModal>
  );
};

export default SelectColumnsModal;
