/*
 * Type definitions, API methods, and conversion utilities for Columns.
 */
import { useQuery } from 'react-query';

import API from 'api/API';
import { useDatabaseAccount } from 'context/AuthContext';
import { convertColumnName } from 'utils/dbName';

import { DatabaseType } from './APITypes';

/*******************************************************************************
 * Types
 ******************************************************************************/
export interface Column {
  id: string;
  name: string;
  type: string;
  description: string;
  ordinal_position: number;
  is_primary_key: boolean;
  pk_to_fks?: string[];
  fk_to_pks?: string[];
}

export interface ColumnsByTableID {
  [tableID: string]: Column[];
}

export interface APIColumn extends Column {
  table_id: string;
}

export interface SaveableColumnProps {
  description?: string;
}

/*******************************************************************************
 * APIs
 ******************************************************************************/
const columnAPI = {
  // Get all columns for a user in an object like {tableId: [columns]}
  fetchColumnsByTableID: (databaseType: DatabaseType) => {
    const api = new API();
    return api.get(`/api/columns`, (apiColumns) => convertAPIColumnsByTableId(apiColumns, databaseType));
  },
  // Get array of all columns for a single table
  fetchTableColumns: (tableId: string, databaseType: DatabaseType) => {
    const api = new API();
    return api.get(`/api/columns?table_id=${tableId}`, (resp) =>
      convertTableAPIColumns(resp, databaseType),
    );
  },
  // Update a single column's updatable fields
  updateTableColumn: (
    columnId: string,
    updateProps: SaveableColumnProps,
    databaseType: DatabaseType,
  ) => {
    const api = new API();
    return api.patch(`/api/columns/${columnId}`, updateProps, (col) => convertColumn(col, databaseType));
  },
};
export default columnAPI;

/*******************************************************************************
 * APIs that checks and sets react-query's queryCache
 ******************************************************************************/
export const columnCacheAPI = {
  // Get array of all columns for a single table
  fetchTableColumns: (tableId: string, databaseType: DatabaseType): Promise<Column[]> => {
    const cachedColumns = window.queryClient.getQueryData<Column[]>(getColumnCacheKey(tableId));
    if (cachedColumns) {
      return Promise.resolve(cachedColumns);
    }
    const api = new API();
    return api
      .get(`/api/columns?table_id=${tableId}`, (resp) => convertTableAPIColumns(resp, databaseType))
      .then((resp) => {
        return Promise.resolve(resp.data as Column[]);
      });
  },
};

/*******************************************************************************
 * Hook to get a tables columns from react-query's queryCache or the API
 ******************************************************************************/
export const getColumnCacheKey = (tableId: string) => `/api/columns?table_id=${tableId}`;

type AnnoyingQueryOptions = any; // It was a pain to make TS happy here without being insanely verbose.
export function useGetColumns(tableId: string | undefined, options?: AnnoyingQueryOptions) {
  const databaseType = useDatabaseAccount().type;

  // The API doesn't fetch if tableId is undefined,
  // but we need to do this to make Typescript happy.
  const stringId = tableId || 'MAKE_TS_HAPPY';

  async function get() {
    try {
      const response = await columnAPI.fetchTableColumns(stringId, databaseType);
      return response.data;
    } catch (e: any) {
      throw e;
    }
  }

  const { isLoading, isError, data } = useQuery(getColumnCacheKey(stringId), get, {
    enabled: tableId !== undefined,
    ...options,
  });

  // Return our own type because react-query's types are a nightmare to extend.
  return {
    isLoading,
    // User friendly error
    error: isError ? 'Failed to fetch columns.' : '',
    // When the API has not yet loaded a value, most of our app expects nulls instead of undefineds.
    // Let's do that here.
    data: (data as Column[]) || null,
  };
}

export const updateColumnCache = (tableId: string, columns: Column[]) => {
  window.queryClient.setQueryData(getColumnCacheKey(tableId), columns);
};

/*******************************************************************************
 * Conversion Utilites
 ******************************************************************************/
function convertColumn(apiColumn: APIColumn, databaseType: DatabaseType) {
  const column: Column = {
    id: apiColumn.id,
    name: convertColumnName(apiColumn.name, databaseType),
    type: apiColumn.type,
    description: apiColumn.description,
    ordinal_position: apiColumn.ordinal_position,
    is_primary_key: apiColumn.is_primary_key || false,
    pk_to_fks: apiColumn.pk_to_fks,
    fk_to_pks: apiColumn.fk_to_pks,
  };
  return column;
}

// This maps just a list of columns to a formatted list of columns
export function convertColumns(unformattedColumns: APIColumn[], databaseType: DatabaseType) {
  return unformattedColumns.map((col) => convertColumn(col, databaseType));
}

// This converts a list of columns into a formatted list of columns
function convertTableAPIColumns(
  apiColumnsResp: { last_columns_change: string; columns: APIColumn[] },
  databaseType: DatabaseType,
) {
  return apiColumnsResp.columns.map((col) => convertColumn(col, databaseType));
}

// This converts a list of columns into a dictionary of tableId to list of formatted columns
export function convertAPIColumnsByTableId(
  apiColumnsResp: {
    last_columns_change: string;
    columns: APIColumn[];
  },
  databaseType: DatabaseType,
): {
  last_columns_change: string;
  columns: ColumnsByTableID;
} {
  const columnDic: ColumnsByTableID = {};
  for (const c of apiColumnsResp.columns) {
    if (!columnDic[c.table_id]) {
      columnDic[c.table_id] = [];
    }
    columnDic[c.table_id].push(convertColumn(c, databaseType));
  }
  return { last_columns_change: apiColumnsResp.last_columns_change, columns: columnDic };
}
