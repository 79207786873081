import React, { useEffect, useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import cn from 'classnames';

import API from 'api/API';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { NotificationContext } from 'context/NotificationContext';
import { usePoll } from 'hooks/useApi';
import { InAppNotification, PaginatedInAppNotification } from 'pages/notifications/NotificationHelpers';
import { agoDate, parseIso } from 'utils/dateTime';

import { Menu } from '@headlessui/react';

import HeaderIcon from '../HeaderIcon/HeaderIcon';

import menuStyle from 'components/widgets/Menu/Menu.module.css';

interface NotificationDropdownMenuProps {
  items: JSX.Element;
  header: string;
}

const divider = <div className="h-px w-full bg-pri-gray-200" />;
const dot = <div className="h-[6px] w-[6px] min-w-[6px] rounded-full bg-sec-purple-700" />;

function NotificationDropdownMenu(props: NotificationDropdownMenuProps) {
  const { items, header } = props;
  const history = useHistory();

  const handleClickGoToNotificationsPage = () => {
    analytics.track('NotificationDropdown GoToNotificationsPage');
    history.push('/notifications');
  };

  return (
    <Menu.Items as="div" className={cn(menuStyle.menuItems, 'text-sm')}>
      <Menu.Item>
        <div
          className={cn(menuStyle.menuItem, 'rounded-t-[6px]')}
          onClick={handleClickGoToNotificationsPage}
        >
          <div className="p-1 font-medium">{header}</div>
        </div>
      </Menu.Item>
      {divider}
      {items}
      {divider}
      <Menu.Item>
        <div className={menuStyle.menuItem} onClick={handleClickGoToNotificationsPage}>
          <div className="font-medium">Go To Notifications Page</div>
        </div>
      </Menu.Item>
    </Menu.Items>
  );
}

interface NotificationDropdownItemProps {
  message: React.ReactNode;
  url: string | null;
  id: string;
}

function NotificationDropdownItem(props: NotificationDropdownItemProps) {
  const { message, url, id } = props;
  const history = useHistory();

  // Get NotificationContext
  const { setUpdatedNotifications } = useContext(NotificationContext);

  const handleClick = () => {
    // Mark the item as read before forwarding
    const api = new API();
    api
      .patch(`/api/in_app_notifications/${id}`, { seen: true })
      .then((response) => {
        // setUpdatedNotifications so Notifications page and the dropdown update
        // Note: setUpdatedNotifications expects an array
        setUpdatedNotifications([response.data]);
      })
      .catch((e) => {
        // TODO Might want to have a error message if the button doesnt work
      });
    if (url) {
      history.push(url);
    }
  };
  return (
    <>
      <Menu.Item>
        <div
          onClick={handleClick}
          className={menuStyle.menuItem}
          style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}
        >
          {dot}
          <div style={{ marginLeft: '10px' }}>{message}</div>
        </div>
      </Menu.Item>
    </>
  );
}

export default function NotificationDropdown() {
  const [error, setError] = useState('');
  const [unseenNotificationsResp, setUnseenNotificationsResp] =
    useState<PaginatedInAppNotification | null>(null);

  // Get NotificationContext
  const { updatedNotifications } = useContext(NotificationContext);

  let { data: polledUnseenNotificationsResp, error: pollError } = usePoll<PaginatedInAppNotification>(
    'unseenNotifications',
    '/api/in_app_notifications?seen=false&ordering=-created_at',
    [300], // Poll every 5 minutes
  );

  // When we poll and get polledUnseenNotificationsResp, setUnseenNotificationsResp with it
  useEffect(() => {
    if (pollError) {
      setError('There was a problem fetching notifications.');
    } else if (polledUnseenNotificationsResp !== undefined) {
      setUnseenNotificationsResp(polledUnseenNotificationsResp);
    }
  }, [polledUnseenNotificationsResp, pollError]);

  // This useEffect updates the items in the dropdown when updatedNotifications changes
  useEffect(() => {
    // If the notification was updated with seen=true, remove it from the list, otherwise merge the update
    const updatedNotificationsIds = updatedNotifications.map(
      (notification: InAppNotification) => notification.id,
    );
    const mergeUpdatedNotifications = (
      oldUnseenNotificationsResp: PaginatedInAppNotification | null,
    ) => {
      if (oldUnseenNotificationsResp !== null) {
        const newResults = oldUnseenNotificationsResp.results
          .map((oldUnseenNotification) => {
            if (updatedNotificationsIds.includes(oldUnseenNotification.id)) {
              return updatedNotifications.find(
                (newNotification: InAppNotification) => newNotification.id === oldUnseenNotification.id,
              ) as InAppNotification;
            } else {
              return oldUnseenNotification;
            }
          })
          .filter((notification: InAppNotification) => !notification.seen);
        return {
          ...oldUnseenNotificationsResp,
          results: newResults,
          count: newResults.length,
        };
      } else {
        return oldUnseenNotificationsResp;
      }
    };
    setUnseenNotificationsResp((r) => mergeUpdatedNotifications(r));
  }, [updatedNotifications]);

  let menuItems = (
    <NotificationDropdownMenu
      header={'Notifications'}
      items={<CenteredSpinner containerMinHeight="100%" />}
    />
  );
  let hasMessageDot = false;

  if (error) {
    menuItems = (
      <NotificationDropdownMenu
        header={'Notifications'}
        items={
          <Menu.Item>
            <Alert variant="error" className={cn(menuStyle.menuItem, 'rounded-none')}>
              {error}
            </Alert>
          </Menu.Item>
        }
      />
    );
  } else if (!unseenNotificationsResp || unseenNotificationsResp.count === 0) {
    menuItems = (
      <NotificationDropdownMenu
        header={'Notifications'}
        items={
          <Menu.Item>
            <Alert variant="no_results" className={cn(menuStyle.menuItem, 'rounded-none')}>
              No new notifications.
            </Alert>
          </Menu.Item>
        }
      />
    );
  } else if (unseenNotificationsResp && unseenNotificationsResp.count > 0) {
    hasMessageDot = true;
    menuItems = (
      <NotificationDropdownMenu
        header={`${unseenNotificationsResp.count} New Notifications`}
        items={
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
            {unseenNotificationsResp.results.map((notification: InAppNotification, index: number) => (
              <NotificationDropdownItem
                key={notification.id}
                url={notification.url}
                message={notificationText(notification)}
                id={notification.id}
              />
            ))}
          </div>
        }
      />
    );
  }

  return (
    <Menu as="div" className={menuStyle.menu}>
      <Menu.Button className="ml-2" id="nav-notification-dropdown">
        <HeaderIcon type="notifications" dotColor={hasMessageDot ? 'var(--gold)' : undefined} />
      </Menu.Button>
      {menuItems}
    </Menu>
  );
}

// Notification.tsx and NotificationDropdown.tsx have their own implementations
// of this method so we can vary messages by context.
export const notificationText = (notification: InAppNotification) => {
  const { notification_event, created_at } = notification;
  const { notification_event_type, name } = notification_event;
  const nameElement = <code className="text-sm">{name}</code>;
  const agoElement = <code className="text-sm">{agoDate(parseIso(created_at))}</code>;
  if (notification_event_type === 'transform_failure') {
    return (
      <span>
        Transform {nameElement} failed {agoElement}.
      </span>
    );
  } else if (notification_event_type === 'connector_initial_sync') {
    return (
      <span>
        Connector {nameElement} finished its initial sync {agoElement}.
      </span>
    );
  } else if (notification_event_type === 'data_alert') {
    const [dataAlertName, tableName] = name.split(' on ');
    return (
      <span>
        Data alert <code className="text-sm">{dataAlertName}</code> on{' '}
        <code className="text-sm">{tableName}</code> returned results {agoElement}.
      </span>
    );
  } else if (notification_event_type === 'gsheet_failure') {
    return (
      <span>
        GSheet Sync from table <code className="text-sm">{name}</code> failed {agoElement}.
      </span>
    );
  } else if (notification_event_type === 'dbt_run_failure') {
    return (
      <span>
        dbt run from configuration <code className="text-sm">{name}</code> failed {agoElement}.
      </span>
    );
  } else if (notification_event_type === 'connector_failure') {
    return (
      <span>
        Connector {nameElement} failed {agoElement}.
      </span>
    );
  } else {
    return <span>Unknown notification type {agoElement}.</span>;
  }
};
