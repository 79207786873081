import { useEffect, useRef } from 'react';

import { Chart, PieController, ArcElement } from 'chart.js';

Chart.register(PieController, ArcElement);
//--Chart Style Options--//
// @ts-ignore
Chart.defaults.font.family = "'PT Sans', sans-serif";
//--Chart Style Options--//

Chart.overrides['doughnut'].plugins.legend.display = false;

export const COST_LABELS_MAP: any = {
  SUM_FIVETRAN_NET: { label: 'MAR', color: '#175cd3', order: 1 },
  SUM_COMPUTE_NET: { label: 'Compute', color: '#027a48', order: 2 },
  SUM_CLOUD_SERVICES_NET: { label: 'Cloud Service', color: '#3538cd', order: 3 },
  MAX_STORAGE_NET: { label: 'Storage', color: '#c4320a', order: 4 },
};

const CostPieChart = (props: any) => {
  const chartRef: React.RefObject<HTMLCanvasElement> = useRef(null);
  const myChart: any = useRef(undefined);

  useEffect(() => {
    const myChartRef = chartRef.current?.getContext('2d');
    if (!myChartRef) {
      return;
    }

    if (typeof myChart.current !== 'undefined') {
      myChart.current.destroy();
    }

    const dataset = Object.entries(props.currentInvoice)
      .filter(([key, value]) => Object.keys(COST_LABELS_MAP).includes(key))
      .sort((a: any, b: any) => {
        const aOrder = COST_LABELS_MAP[a[0]].order;
        const bOrder = COST_LABELS_MAP[b[0]].order;
        return aOrder - bOrder;
      });

    const data = dataset.map(([, value]) => value);
    const backgroundColor = dataset.map(([key]) => COST_LABELS_MAP[key].color);

    myChart.current = new Chart(myChartRef, {
      type: 'doughnut',
      options: {
        cutout: '70%',
        plugins: {
          tooltip: {
            enabled: false, // <-- this option disables tooltip
          },
          datalabels: { display: false },
        },
        scales: {
          x: { display: false },
          y: { display: false },
        },
      },
      data: {
        // @ts-ignore
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor,
            hoverOffset: 4,
          },
        ],
        // labels: dataset.map(([key]) => COST_LABELS_MAP[key].label),
      },
    });
  }, [props.currentInvoice]);

  return (
    <div className="relative w-[200px] h-[200px] py-4 px-0 ">
      <canvas id="myChart" ref={chartRef} width="100%" />
    </div>
  );
};

export default CostPieChart;
