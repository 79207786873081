import React, { useContext, useEffect, useState } from 'react';

import history from 'appHistory';

import API from 'api/API';
import { deleteCaches } from 'api/CacheKeys';
import ObjectNotificationSettings from 'components/business_logic/ObjectNotificationSettings/ObjectNotificationSettings';
import Button from 'components/inputs/basic/Button/Button';
import ConfirmDeleteModal from 'components/layouts/containers/modals/ConfirmDeleteModal/ConfirmDeleteModal';
import { SPINNER_MIN_HEIGHT } from 'components/layouts/containers/TabLayout/TabLayout';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';
import { TableContext } from 'model_layer/TableContext';
import { AggTable, TableModelsContext } from 'model_layer/TableModelsContext';
import { TransformContext } from 'model_layer/TransformContext';
import { TransformTable } from 'pages/tables/ShowTable/ShowTable';

interface SettingsTabProps {
  table: TransformTable;
  setErrorMessage(errorMsg: string): void;
}

export default function SettingsTab(props: SettingsTabProps) {
  const { removeTable } = useContext(TableContext);
  const { removeTransform } = useContext(TransformContext);
  const { removeCsvUpload } = useContext(TableModelsContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [subscribed, setSubscribed] = useState(false);
  const [savingSubscription, setSavingSubscription] = useState(false);
  const { table, setErrorMessage } = props;
  const { userProfile } = useUserProfile();
  const databaseType = useDatabaseAccount().type;

  const isCSVUpload = table.type === 'csv_upload';
  const isTransform = table.type === 'transform';
  const hasNotifications = !isCSVUpload;
  const canDelete = isTransform || isCSVUpload;

  useEffect(() => {
    if (isCSVUpload) {
      setLoading(false);
    } else {
      const api = new API();
      api
        .get(`/api/tables/${table.id}/get_subscription`)
        .then((response) => {
          setSubscribed(!response.data.is_opted_out);
        })
        .catch((e) => {
          setErrorMessage("There was a problem fetching this table's notification settings.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [table.id, setErrorMessage, isCSVUpload]);

  if (loading) {
    return <CenteredSpinner containerMinHeight={SPINNER_MIN_HEIGHT} />;
  }

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const deleteCSV = async (table: AggTable) => {
    const api = new API();
    await api.delete(`/api/tables/${table.id}`, {});

    if (table.csvUpload) {
      removeCsvUpload(table.csvUpload);
    }

    removeTable(table);
  };

  const deleteTransform = async (table: AggTable) => {
    const api = new API();
    if (table.transform) {
      await api.delete(`/api/transforms/${table.transform.id}`, {
        delete_table: true,
      });

      removeTransform(table.transform);
    }

    removeTable(table);
  };

  const doDelete = async () => {
    setDeleting(true);
    const deleteFunction = isCSVUpload ? deleteCSV : deleteTransform;
    try {
      await deleteFunction(table);
      analytics.track('SettingsTab Delete');
      deleteCaches();
      history.push('/warehouse');
    } catch (error) {
      setErrorMessage(`There was an error deleting your ${isCSVUpload ? 'table' : 'Transform'}.`);
    } finally {
      setDeleting(false);
      setShowDeleteModal(false);
    }
  };

  const handleToggleSubscription = () => {
    setSavingSubscription(true);
    const api = new API();
    api
      .patch(`/api/tables/${table.id}/patch_subscription`, { is_opted_out: subscribed })
      .then((response) => {
        setSubscribed(!response.data.is_opted_out);
      })
      .catch((e) => {
        setErrorMessage("There was a problem changing this table's notification settings.");
      })
      .finally(() => {
        setSavingSubscription(false);
      });
  };

  let deleteHeading: string;
  let deleteButtonText: string;
  let deleteObjectName: string;
  let deleteConfirmElement: JSX.Element;
  if (isTransform) {
    deleteHeading = table.django_thinks_exists_in_snowflake
      ? 'Delete this transform and the table it created'
      : 'Delete this transform';
    deleteButtonText = 'Delete Transform';
    deleteObjectName = 'transform';
    deleteConfirmElement = table.django_thinks_exists_in_snowflake ? (
      <>
        <span>
          This will permanently delete the transform <code>{table.full_name}</code> and the table it
          created.
        </span>
        <br />
        This will break any transforms that query the table this transform created.
      </>
    ) : (
      <span>
        This will permanently delete the transform <strong>{table.full_name}</strong>.
      </span>
    );
  } else {
    deleteHeading = 'Delete this table';
    deleteButtonText = 'Delete Table';
    deleteObjectName = 'table';
    deleteConfirmElement = (
      <span>
        This will permanently delete the table <strong>{table.full_name}</strong>.
      </span>
    );
  }

  return (
    <div className="mt-8 f-center">
      <div className="w-[50%] f-col">
        {!loading && hasNotifications && (
          <ObjectNotificationSettings
            isSubscribed={subscribed}
            savingSubscription={savingSubscription}
            handleToggleSubscription={handleToggleSubscription}
            currentStateExplanation={`You are ${
              subscribed ? '' : 'not '
            }subscribed to email notifications about this table (${
              isTransform ? 'Transform failures, ' : ''
            }Sheet Sync failures${databaseType !== 'bigquery' ? ', Alerts' : ''}).`}
          />
        )}

        {canDelete && (
          <>
            {hasNotifications && (
              <div className="h-px flex-grow-0 transform rotate-180 bg-gray-200 my-4" />
            )}
            <div className="mb-1 text-base font-medium text-sec-blue-gray-700">Danger Zone</div>
            <div className="f-between p-4 bg-pri-error-100 rounded">
              <div className="mr-16 text-pri-gray-500">
                {deleteHeading}. This operation cannot be undone.
              </div>
              <Button
                variant="darkDanger"
                onClick={openDeleteModal}
                spinning={false}
                disabled={userProfile.company_role === 'viewer'}
              >
                {deleteButtonText}
              </Button>
            </div>
          </>
        )}

        {showDeleteModal && (
          <ConfirmDeleteModal
            header="Are you absolutely sure?"
            confirmText={`I understand the consequences, delete this ${deleteObjectName}`}
            enableConfirm={true}
            deleting={deleting}
            onCancel={closeDeleteModal}
            onConfirm={doDelete}
          >
            <div className="p-4">
              This action <strong>cannot</strong> be undone.
              <br />
              {deleteConfirmElement}
            </div>
          </ConfirmDeleteModal>
        )}
      </div>
    </div>
  );
}
