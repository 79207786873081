/*
RadioInputs prefixed by label element and followed by an error element.
*/
import RadioInputs, { RadioInputsProps } from 'components/inputs/basic/RadioInputs/RadioInputs';

import InputGroup, { InputGroupParentProps } from '../InputGroup';

export type { RadioInputOption } from 'components/inputs/basic/RadioInputs/RadioInputs';

export interface RadioInputGroupProps extends InputGroupParentProps, RadioInputsProps {
  /** Number of columns to render in top-down order */
  numCols?: number;
  // custom radio button markup component
  optionsMarkup?: (props: any) => React.ReactNode;
  disabled?: boolean;
}

interface DefaultOptionsMarkupProps extends RadioInputsProps {
  numCols?: number;
}

export const DefaultOptionsMarkup = ({
  options,
  numCols = 1,
  containerStyle,
  disabled,
  ...rest
}: DefaultOptionsMarkupProps) => (
  <RadioInputs
    containerStyle={{
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateRows: `repeat(${Math.ceil(options.length / numCols)}, 1fr)`,
      gridTemplateColumns: `repeat(${numCols}, 1fr)`,
      ...containerStyle,
    }}
    options={options}
    disabled={disabled}
    {...rest}
  />
);

export default function RadioInputGroup(props: RadioInputGroupProps) {
  const {
    name,
    value,
    onChange,
    numCols = 1,
    label,
    options,
    postLabelElement,
    groupClass,
    groupStyle,
    labelClass,
    labelStyle,
    optionsMarkup,
    error,
    sharedRadioInputProps,
    containerStyle,
    ...remainingProps
  } = props;

  return (
    <InputGroup
      name={name}
      input={
        optionsMarkup?.({ onChange, value, name, ...remainingProps }) || (
          <DefaultOptionsMarkup
            {...{ onChange, numCols, value, containerStyle, name, options, sharedRadioInputProps }}
          />
        )
      }
      error={error}
      label={label}
      postLabelElement={postLabelElement}
      groupClass={groupClass}
      groupStyle={groupStyle}
      labelClass={labelClass}
      labelStyle={labelStyle}
      {...remainingProps}
    />
  );
}
