/**
 * This is the legacy version of DeleteReportsModal
 * that renders each report installed as a separate list of transforms.
 */
import { Connector } from 'api/APITypes';
import { ReportAvailable, ReportInstalled, ReportTransform } from 'api/dashboardAPI';
import ConfirmDeleteModal from 'components/layouts/containers/modals/ConfirmDeleteModal/ConfirmDeleteModal';
import Alert from 'components/widgets/alerts/Alert/Alert';

interface DeleteReportsModalProps {
  reportsAvailable: ReportAvailable[];
  reportsToRemove: ReportInstalled[];
  deleting: boolean;
  error: string;
  onCancel(): void;
  onConfirm(): void;
  connector: Connector;
}

export default function DeleteReportsModal(props: DeleteReportsModalProps) {
  const { reportsAvailable, reportsToRemove, deleting, error, onCancel, onConfirm, connector } = props;
  const fullReportsToRemove = reportsToRemove.map(
    (rtr) => reportsAvailable.find((ra) => ra.key === rtr.key) as ReportAvailable,
  );

  return (
    <ConfirmDeleteModal
      header="Delete the following reports?"
      enableConfirm={true}
      confirmText="Delete"
      deleting={deleting}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <div className="p-4">
        <div>
          Deleting these reports will delete the relevant tabs in the dashboard and their corresponding
          transforms:
        </div>
        {fullReportsToRemove.map((r) => (
          <div key={r.key} className="mt-4 grid grid-cols-1 gap-y-7">
            <ReportTransforms
              key={r.key}
              title={r.title}
              transforms={r.transforms}
              connector={connector}
            />
          </div>
        ))}
        {error && (
          <Alert variant="error" className="mt-4">
            {error}
          </Alert>
        )}
      </div>
    </ConfirmDeleteModal>
  );
}

export interface ReportTransformsProps {
  title: string;
  transforms: ReportTransform[];
  connector: Pick<Connector, 'id' | 'service' | 'schema'>;
}

function ReportTransforms(props: ReportTransformsProps) {
  const { title, transforms, connector } = props;

  return (
    <div className="relative">
      <div className="absolute h-7 px-4 ml-7 z-10 flex-row-y-center bg-white text-purple text-lg">
        {title}
      </div>
      <div className="mt-[13px] pt-5 pr-5 pb-5 pl-11 grid grid-cols-1 gap-y-2 border border-purple rounded-lg">
        {transforms.map((t) => {
          const fullName = `${t.report_schema_prefix}_${connector.schema}.${t.table_name}`;
          return (
            <div key={fullName} className="">
              {fullName}
            </div>
          );
        })}
      </div>
    </div>
  );
}
