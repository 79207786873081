import React from 'react';

import { usePrevious } from 'react-use';

import cn from 'classnames';

import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';

import s from './AdvancedSearch.module.css';

interface AdvancedSearchProps {
  show: boolean;
  hideEmptyTables: boolean;
  hideViews: boolean;
  onChangeHideEmptyTables: () => void;
  onChangeHideViews: () => void;
}

export default function AdvancedSearch(props: AdvancedSearchProps) {
  const { show, hideEmptyTables, hideViews, onChangeHideEmptyTables, onChangeHideViews } = props;
  const prevShow = usePrevious(show);
  return (
    <div
      className={cn(s.accordion, {
        [s.accordionOpen]: show,
        [s.accordionClosed]: !show,
        [s.animateOpen]: show && prevShow === false,
        [s.animateClosed]: !show && prevShow === true,
      })}
    >
      <div className={'text-sm ' + s.advancedSearch}>
        <div className="flex mb-2">
          <Checkbox
            onChange={onChangeHideEmptyTables}
            name="showEmptyTablesCheckbox"
            label="Show Empty Tables"
            checked={!hideEmptyTables}
            variant="purple"
          />
          <Checkbox
            onChange={onChangeHideViews}
            name="showViewsCheckbox"
            label="Show Views"
            checked={!hideViews}
            variant="purple"
            containerClass="ml-4"
          />
        </div>
        <table className={`${s.table}`}>
          <tbody>
            <tr>
              <td>
                <code className="text-sm">user</code>
              </td>
              <td>
                The default search. Search schema names, table names, and column names for{' '}
                <code className={'text-sm ' + s.pattern}>user</code>.
              </td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">s:salesforce</code>
              </td>
              <td>
                Search schema names for <code className={'text-sm ' + s.pattern}>salesforce</code>.
              </td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">t:dim_users</code>
              </td>
              <td>
                Search table names for <code className={'text-sm ' + s.pattern}>dim_users</code>.
              </td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">c:user_id</code>
              </td>
              <td>
                Search column names for <code className={'text-sm ' + s.pattern}>user_id</code>.
              </td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">td:dashboard</code>
              </td>
              <td>
                <div>
                  Search table descriptions for <code className={'text-sm ' + s.pattern}>dashboard</code>
                  .
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">tag:project</code>
              </td>
              <td>
                Search for tables having a tag containing{' '}
                <code className={'text-sm ' + s.pattern}>project</code>.
              </td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">q:"GROUP BY user_id"</code>
              </td>
              <td>
                Search transform SQL for <code className={'text-sm ' + s.pattern}>GROUP BY user_id</code>
                .
              </td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">u:sarah</code>
              </td>
              <td>
                Tables that were last saved by <code className={'text-sm ' + s.pattern}>sarah</code>.
              </td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">t:sales c:user_id</code>
              </td>
              <td>AND together any two search terms by separating them with a space.</td>
            </tr>
            <tr>
              <td></td>
              <td>
                Table names that contain <code className={'text-sm ' + s.pattern}>sales</code> AND column
                names that contain <code className={'text-sm ' + s.pattern}>user_id</code>.
              </td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">t:user t:sales -t:monthly</code>
              </td>
              <td>Negate any search term by prefixing it with a minus sign.</td>
            </tr>
            <tr>
              <td></td>
              <td>
                Table names that contain <code className={'text-sm ' + s.pattern}>user</code> AND{' '}
                <code className={'text-sm ' + s.pattern}>sales</code> AND DO NOT contain{' '}
                <code className={'text-sm ' + s.pattern}>monthly</code>.
              </td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">f:user f:sales f:monthly</code>
              </td>
              <td>
                <code className={'text-sm ' + s.pattern}>f</code> is an alias for the default search.
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                Searches schema names, table names, and column names for{' '}
                <code className={'text-sm ' + s.pattern}>user</code> AND{' '}
                <code className={'text-sm ' + s.pattern}>sales</code> AND{' '}
                <code className={'text-sm ' + s.pattern}>monthly</code>.
              </td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:favorite</code>
              </td>
              <td>Your favorite tables.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:connector</code>
              </td>
              <td>Tables that are created by connectors.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:csv</code>
              </td>
              <td>Tables that are uploaded CSVs.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:transform</code>
              </td>
              <td>Tables that are Mozart Transforms.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:dbt</code>
              </td>
              <td>Tables that are created by dbt jobs.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:unmanaged</code>
              </td>
              <td>Tables that are in your Snowflake database that Mozart does not create.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:snapshotted</code>
              </td>
              <td>Tables that have snapshotting enabled.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:scheduled</code>
              </td>
              <td>Mozart Transforms that are scheduled.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:incremental</code>
              </td>
              <td>Mozart Transforms that are incremental.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:failed</code>
              </td>
              <td>Mozart Transforms that failed their last run.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:table</code>
              </td>
              <td>Only return tables.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:view</code>
              </td>
              <td>Only return views.</td>
            </tr>
            <tr>
              <td>
                <code className="text-sm">is:materialized</code>
              </td>
              <td>Only return materialized views.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
