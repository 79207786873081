import { useState } from 'react';

import { Link } from 'react-router-dom';

import API from 'api/API';
import Switch from 'components/inputs/basic/Switch/Switch';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useUserProfile } from 'context/AuthContext';
import CompanyAllowAIInfoIcon from 'pages/settings/CompanyAllowAIInfoIcon';

export interface AIPermissionModalProps {
  onCancel(): void;
}

export default function AIPermissionModal(props: AIPermissionModalProps) {
  const { onCancel } = props;
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);
  const { userProfile, updateCompany } = useUserProfile();
  const { company_role, company } = userProfile;

  const toggleAllowAI = () => {
    setError('');
    setSaving(true);
    const api = new API();
    api
      .patch(`api/companies/${company.id}`, { allow_ai: !company.allow_ai })
      .then((response) => {
        updateCompany(response.data);
        analytics.track(
          !company.allow_ai ? 'AIPermissionModal EnableAllowAI' : 'AIPermissionModal DisableAllowAI',
        );
      })
      .catch((e) => {
        if (e.response?.data?.non_field_errors) {
          setError(e.response.data.non_field_errors);
        } else {
          setError('There was a problem updating your setting.');
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Modal header="Allow AI" onClose={onCancel} cancelButton={true}>
      <div id="allow-ai-modal-body" className="p-4">
        {company_role === 'admin' ? (
          <div>
            <div>
              You must give us permission on behalf of your company to send metadata to OpenAI in order
              to use this feature.
            </div>
            <div>
              This setting can always be toggled in the{' '}
              <Link
                to="/settings?tab=company"
                className="text-sec-blue-gray-500 hover:underline"
                target="_blank"
                rel="noopener"
              >
                company settings
              </Link>{' '}
              page.
            </div>
            <div className="flex items-center mt-4 mb-4">
              <Switch
                id="company-allow-ai-switch"
                name="allow_ai"
                checked={company.allow_ai}
                onChange={toggleAllowAI}
                disabled={company_role !== 'admin'}
                spinning={saving}
              />
              <h2 className="text-xl text-pri-gray-700 ml-3">Allow AI</h2>
              <CompanyAllowAIInfoIcon />
            </div>
          </div>
        ) : (
          <div>
            This feature is powered by AI and may send data to OpenAI. A Mozart Admin at you company must
            toggle on the company setting to give up permission to send data to OpenAI.
          </div>
        )}
        {error && (
          <Alert variant="error" className="mt-4">
            {error}
          </Alert>
        )}
      </div>
    </Modal>
  );
}
