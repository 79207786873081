import { useLayoutEffect, useRef } from 'react';

import cn from 'classnames';
import { debounce } from 'lodash';

import Button from 'components/inputs/basic/Button/Button';
import IconButton from 'components/inputs/basic/Button/IconButton';
import ExpandingTextAreaInput from 'components/inputs/basic/ExpandingTextAreaInput/ExpandingTextAreaInput';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import TableCheckedCenterIcon from 'components/primitives/icons/TableCheckedCenterIcon/TableCheckedCenterIcon';
import { AIAssistantState } from 'components/query/useAIAssistant';
import Alert from 'components/widgets/alerts/Alert/Alert';

import AIAssistantTablePickerModal from './AIAssistantTablePickerModal';
import AIChatHistory from './AIChatHistory';

const AIAssistantEditor = (props: AIAssistantState) => {
  const {
    question,
    messages,
    askingAssistant,
    assistantError,
    loadingChatHistory,
    cancellingQuestion,
    setSqlAndRun,
    handleCancelQuestion,
    actuallyShowChatAdvice,
    handleClickSubmit,
    handleChangeQuestion,
    handleKeySubmit,
    tablePicker,
  } = props;

  const chatWindowRef = useRef<HTMLDivElement>(null); // This is for auto-scrolling to the bottom on the chat window
  const lastOffsetHeightRef = useRef(0);

  // The user might be dragging component edge or browser edge to resize something.
  // Debounce scroll to keep things responsive.
  const scrollToBottom = debounce(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTo({
        top: chatWindowRef.current.scrollHeight,
        behavior: 'auto',
      });
    }
  }, 100);

  // When a new chat gets added, scroll to the bottom
  useLayoutEffect(
    () => {
      // 08/13/2024:
      // KNOWN ISSUE: ADVISE NOT WORRY ABOUT FOR A WHILE
      // 1. Clicking the setSQLAndRun button can cause the <QueryEditor/> to unmount and remount <AIAssistantEditor/>(or any of it's child components)
      // 2. This is a know structural issue with <QueryEditor/> that John has spent a long time trying to fix to no avail.
      // 3. It is maybe possible to get this button to be less likely to cause the unmount. Actually fixing the <QueryEditor/>
      //    is an inherently hard problem.
      // 4. I believe the code in this file to be correct provided that <AIAssstantEditor /> is only mounted once
      //    over its lifetime and `messages` is only updated when a scroll is appropriate.
      //
      // How to reproduce:
      // 1. Load an AI chat with many messages.
      // 2. Scroll to the top.
      // 3. Click the setSQLAndRun() button.
      // 4. BUG: This component will get unmounted and remounted causing `scrollToBottom()` to be run inappropriately.
      scrollToBottom();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages],
  );

  // When the window shrinks, scroll to the bottom.
  // 08/13/2024: John put this here for a good reason, but forgot exactly why
  // and is becoming less certain we should still have this.
  // Don't delete this on just for the sake of less code. This was important once.
  useLayoutEffect(() => {
    const el = chatWindowRef.current;
    if (el) {
      const windowShrank = lastOffsetHeightRef.current > el.offsetHeight;
      if (windowShrank) {
        scrollToBottom();
      }
      lastOffsetHeightRef.current = el.offsetHeight;
    }
  });

  if (loadingChatHistory) {
    return (
      <div className="w-full h-full flex f-col-center">
        <div>Loading chat history...</div>
      </div>
    );
  }

  // It looks bad when the cancel button and the spinner button aren't the same size.
  const sameButtonSize = 'w-[88px]';

  const userAskedFirstQuestion = !!messages.length && messages[messages.length - 1].role === 'user';

  return (
    <div className="w-full h-full flex flex-col justify-between bg-sec-blue-gray-100">
      <div ref={chatWindowRef} className="w-full h-full overflow-auto flex flex-col">
        <AIChatHistory
          messages={messages}
          actuallyShowChatAdvice={actuallyShowChatAdvice}
          setSqlAndRun={setSqlAndRun}
        />
      </div>
      <div className="p-2 flex flex-col border-t-[1px] border-solid border-sec-blue-gray-50">
        {askingAssistant && (
          <div className="f-row-y-center mb-2">
            <CenteredSpinner spinnerSize="12px" type="circle" />
            {/* Padding set to match textarea's border + padding. */}
            <div className="text-sm px-[9px]">
              The assistant is collecting metadata and verifying SQL, so it may take some time to
              respond...
            </div>
          </div>
        )}
        <form onSubmit={handleClickSubmit}>
          <div className="f-row-y-center">
            <div className="w-full relative">
              <ExpandingTextAreaInput
                name="question"
                value={question}
                onChange={handleChangeQuestion}
                onKeyDown={handleKeySubmit}
                disabled={askingAssistant}
                className={tablePicker.enabled ? 'pr-8' : undefined}
              />
              {/*
              The mockups don't 100% line up with our standard button sizes.
              Manually tweaking sizes to make things aesthetically pleasing.
              Also, our buttons traditionally have more horizontal padding than vertical padding
              which makes them rectangular. When the button is jammed in the
              corner of the textarea's border it looks better when it is square,
              most especially when hover changes the background color.
              Also, the icon is not perfectly square which creates optical illusions.
              That is why the right padding is more than the bottom padding.
              */}
              {tablePicker.enabled && (
                <div className="absolute bottom-[5px] right-[6px]">
                  <IconButton
                    {...(Object.keys(tablePicker.pickedItems).length > 0
                      ? { customIcon: TableCheckedCenterIcon }
                      : { icon: 'Table' })}
                    size="small"
                    iconSize={16}
                    variant="lightTransparent"
                    className="!h-[32px] !p-[7px]"
                    onClick={tablePicker.onOpen}
                  />
                </div>
              )}
            </div>
            {askingAssistant ? (
              <Button
                spinning={cancellingQuestion}
                variant="lightDanger"
                size="form"
                onClick={handleCancelQuestion}
                className={cn(sameButtonSize, 'ml-2 flex-shrink-0')}
              >
                Cancel
              </Button>
            ) : (
              <Button
                type="submit"
                variant="darkDullAction"
                size="form"
                className={cn('ml-2 self-end', { [sameButtonSize]: askingAssistant })}
                disabled={askingAssistant || (!question && !userAskedFirstQuestion)}
              >
                Ask
              </Button>
            )}
          </div>
        </form>
        {assistantError && (
          <Alert variant="error" className="mt-2">
            {assistantError}
          </Alert>
        )}
        {tablePicker.showModal && (
          <AIAssistantTablePickerModal
            selectedTable={tablePicker.selectedTable}
            onClearSelection={tablePicker.onClearSelection}
            onClose={tablePicker.onClose}
            setSelectedTable={tablePicker.setSelectedTable}
            pickTable={tablePicker.pickTable}
            unpickTable={tablePicker.unpickTable}
            pickedTables={tablePicker.pickedTables}
            pickedItems={tablePicker.pickedItems}
            pickSchema={tablePicker.pickSchema}
            unpickSchema={tablePicker.unpickSchema}
            pickedSchemas={tablePicker.pickedSchemas}
            schemaPickingEnabled={tablePicker.schemaPickingEnabled}
          />
        )}
      </div>
    </div>
  );
};

export default AIAssistantEditor;
