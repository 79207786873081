import { createContext } from 'react';

import { CompanySetupGuideOverrides } from 'api/APITypes';

export type APIStepKey = keyof Omit<CompanySetupGuideOverrides, 'id'>;
export type UIStepKey = APIStepKey | 'useful_links';

export interface StepSetters {}

export interface CompanySetupGuideContextInterface {
  enabled: boolean; // Whether the CompanySetupGuide is available, regardless of whether it is currently shown.
  show: boolean; // Whether the CompanySetupGuide is actively rendered in the right hand column or hidden.
  steps: CompanySetupGuideOverrides; // The individual steps recorded on the server.
  openStep: UIStepKey | null; // The step currently open in the CompanySetupGuide UI.
  currentTab: string;
  setupComplete: boolean; // Have all of the individual steps been completed?
  setShow: (val: boolean) => void;
  setStep: (step: APIStepKey, value: boolean) => Promise<void>;
  setOpenStep: (step: UIStepKey | null) => void;
  setCurrentTab: (tab: string) => void;
  onComplete: () => void;
  onShowGuide: () => void;
  startGuide: () => void;
  endGuide: () => void;
}

// Default this to complete so that existing customers can never accidentally see it.
export const CompanySetupGuideContext = createContext<CompanySetupGuideContextInterface>({
  enabled: false,
  show: false,
  steps: {
    id: 'NOT_SET_YET',
    get_data: true,
    explore_data: true,
    transform_data: true,
    use_data: true,
  },
  openStep: null,
  currentTab: '',
  setupComplete: false,
  setShow: (val: boolean) => {},
  setStep: (val: APIStepKey, value: boolean) => Promise.resolve(),
  setOpenStep: (step: UIStepKey | null) => {},
  setCurrentTab: (tab: string) => {},
  onComplete: () => {},
  onShowGuide: () => {},
  startGuide: () => {},
  endGuide: () => {},
});
