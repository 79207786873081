import React from 'react';

import { Link } from 'react-router-dom';

import Button from 'components/inputs/basic/Button/Button';
import IconButton from 'components/inputs/basic/Button/IconButton';
import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import Switch from 'components/inputs/basic/Switch/Switch';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import SqlPreviewEditor from 'components/widgets/SqlPreviewEditor/SqlPreviewEditor';
import { useUserProfile } from 'context/AuthContext';
import { DataAlertWithTable } from 'hooks/useDataAlerts';
import { getLastAlertActionAndEnableResultsButton } from 'pages/tables/ShowTable/DataAlertTab/shared/DataAlertHelper';
import { formatNumber } from 'utils/String';

interface DataAlertRowViewProps {
  dataAlert: DataAlertWithTable;
  isOverallAlertsPage: boolean;
  loadingSetMode: boolean;
  loadingSetEnabled: boolean;
  loadingSetAttachCSV: boolean;
  confirmDelete: boolean;
  setMode: (mode: ListboxValue) => void;
  onToggleEnabled: () => void;
  onToggleAttachCSV: () => void;
  onCancelDelete: () => void;
  onOpenDeleteDataAlertModal: () => void;
  onConfirmDeleteDataAlert: () => void;
  onSelectDataAlert: (dataAlert: DataAlertWithTable) => void;
  onGetFailureLogs: (dataAlert: DataAlertWithTable) => void;
}

export default function DataAlertRowView(props: DataAlertRowViewProps) {
  const {
    dataAlert,
    isOverallAlertsPage,
    loadingSetMode,
    loadingSetEnabled,
    loadingSetAttachCSV,
    confirmDelete,
    setMode,
    onToggleEnabled,
    onToggleAttachCSV,
    onCancelDelete,
    onOpenDeleteDataAlertModal,
    onConfirmDeleteDataAlert,
    onSelectDataAlert,
    onGetFailureLogs,
  } = props;

  const { table } = dataAlert;

  const {
    userProfile: { company_role },
  } = useUserProfile();

  const [lastTestAction, enableResultsButton, status] = getLastAlertActionAndEnableResultsButton(
    dataAlert,
    table,
  );

  let buttonRows = 'N/A';
  if (enableResultsButton) {
    // If there are no rows, but hasResults is true, it must be an error message
    buttonRows = 'View Error';
    if (dataAlert.last_table_test_run?.num_rows && dataAlert.last_table_test_run.num_rows >= 0) {
      buttonRows = `${formatNumber(dataAlert.last_table_test_run.num_rows)} Row${
        dataAlert.last_table_test_run.num_rows === 1 ? '' : 's'
      }`;
    }
  }

  const dataAlertModeOptions = [
    { value: 'notify', label: 'Notify' },
    { value: 'block', label: 'Revert and Notify' },
  ];

  return (
    <tr style={{ height: '100%', verticalAlign: 'top' }}>
      {/* As far as I can tell this <td> is just here to indent data alert names. */}
      {isOverallAlertsPage && <td></td>}
      <td style={{ overflowWrap: 'anywhere' }}>
        {isOverallAlertsPage ? (
          <Link
            to={`/tables/${dataAlert.table.id}/alerts`}
            target="_blank"
            data-track="DataAlertsPage DataAlertClick"
            className="min-h-9 f-row-y-center font-medium hover:text-sec-blue-gray-500 hover:cursor-pointer"
          >
            {dataAlert.name}
          </Link>
        ) : (
          <div className="min-h-9 f-row-y-center">{dataAlert.name}</div>
        )}
      </td>
      {/*
      Table widths are weird.
      Browsers will expand cells past their percentage width if the content is wider than that,
      but if you set an arbitrarily small pixel width it will use the percentage width.
      This hack was enabled on Oct 6, 2023. Let's see how long it is until I regret this.
      */}
      <td style={{ height: '100%', width: '1px', maxWidth: '1px' }}>
        <SqlPreviewEditor sql={dataAlert.sql} addHeightOffset={dataAlert.sql_changed_since_last_run} />
        {dataAlert.sql_changed_since_last_run ? (
          <div className="mt-1 text-pri-gray-400 text-left text-sm font-medium whitespace-pre-line">
            SQL has changed since the last transform run.
          </div>
        ) : null}
      </td>
      <td>
        {<StatusIcon status={status} className="mb-2" />}
        {lastTestAction}
      </td>
      <td>
        <div className="h-9 f-row-y-center">
          <Button
            disabled={!enableResultsButton}
            size="small"
            onClick={() => onGetFailureLogs(dataAlert)}
            className="min-w-[102px]"
          >
            {buttonRows}
          </Button>
        </div>
      </td>
      {(isOverallAlertsPage || table.type === 'transform') && (
        <td>
          {table.type === 'transform' && company_role !== 'viewer' ? (
            <Listbox
              value={dataAlert.mode}
              spinning={loadingSetMode}
              size="medium"
              variant="gray"
              onChange={setMode}
              widthClass="w-full"
              options={dataAlertModeOptions}
            />
          ) : (
            <div className="h-9 ml-2 f-row-y-center">
              {dataAlertModeOptions.find((o) => o.value === dataAlert.mode)?.label}
            </div>
          )}
        </td>
      )}
      <td>
        <div className="h-9 f-center">
          <Switch
            name="enabled-switch"
            spinning={loadingSetEnabled}
            id={`enabled-switch-${dataAlert.id}`}
            checked={dataAlert.enabled}
            onChange={onToggleEnabled}
            disabled={company_role === 'viewer'}
          />
        </div>
      </td>
      <td>
        <div className="h-9 f-center">
          <Switch
            name="attach-csv"
            spinning={loadingSetAttachCSV}
            id={`attach-csv-switch-${dataAlert.id}`}
            checked={dataAlert.should_send_csv}
            onChange={onToggleAttachCSV}
            disabled={company_role === 'viewer'}
          />
        </div>
      </td>
      {company_role !== 'viewer' && (
        <td>
          <div className="h-9 f-center">
            <IconButton
              icon="PencilSquare"
              variant="lightDullTransparent"
              size="small"
              onClick={() => onSelectDataAlert(dataAlert)}
            />
            <IconButton
              icon="Trash"
              variant="lightDullTransparent"
              size="small"
              onClick={onOpenDeleteDataAlertModal}
            />
          </div>
          {confirmDelete && (
            <ConfirmModal
              header={
                <span className="text-lg font-medium">
                  Are you sure you want to delete the alert{' '}
                  <code style={{ overflowWrap: 'anywhere' }}>{dataAlert.name}</code>?
                </span>
              }
              confirmText="Delete"
              confirmVariant="darkDanger"
              onCancel={onCancelDelete}
              onConfirm={onConfirmDeleteDataAlert}
            />
          )}
        </td>
      )}
    </tr>
  );
}
