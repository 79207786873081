import React from 'react';

import { TransformRun } from 'api/APITypes';
import TransformRunIcon from 'components/primitives/icons/TransformRunIcon/TransformRunIcon';
import DateHover from 'components/widgets/time/DateHover/DateHover';
import Duration from 'components/widgets/time/Duration/Duration';
import { CREATE_AS_TYPE_LABEL_MAP } from 'pages/tables/ShowTable/SummaryTab/SummaryTab';
import { formatNumber } from 'utils/String';

interface RunHistoryTableRowProps {
  run: TransformRun;
  errorColSpan: number;
  transformHasIncremental: boolean;
  transformHasInserts: boolean;
  transformHasUpdates: boolean;
}

export default function RunHistoryTableRow(props: RunHistoryTableRowProps) {
  const { run, errorColSpan, transformHasIncremental, transformHasInserts, transformHasUpdates } = props;

  const hasError = run.state === 'failed';

  let errorMessage = null;
  if (hasError) {
    errorMessage = run.error_message || 'No error message provided.';
  }

  let start = run.created_at;
  let end = null;
  let durationAdjustment = 0;
  let numRows = null;

  if (run.snowflake_query) {
    // start_time seems to be null until the run completes
    if (run.snowflake_query.start_time) {
      start = run.snowflake_query.start_time;
    }
    end = run.snowflake_query.end_time;
    if (run.snowflake_query.time_queued) {
      durationAdjustment = run.snowflake_query.time_queued * -1;
    }
    numRows = formatNumber(run.snowflake_query.num_rows) || '';
  }

  let rowsInserted = '';
  let rowsUpdated = '';
  if (run.incremental && run.state === 'success') {
    rowsInserted = formatNumber(run.num_rows_inserted || 0) as string;
    rowsUpdated = formatNumber(run.num_rows_updated || 0) as string;
  }

  const scheduledRow = run.run_manually
    ? run.created_by
      ? `Manual by ${run.created_by.first_name} ${run.created_by.last_name}`
      : 'Manual'
    : 'Scheduled';

  return (
    <React.Fragment key={run.id}>
      <tr>
        <td className="text-left">
          <DateHover date={start} interval={run.state === 'running' ? 1000 : undefined} />
        </td>
        <td className="text-left">
          <DateHover date={end} />
        </td>
        <td className="f-center">
          <TransformRunIcon run={run} rotate={true} />
        </td>
        <td className="text-left">
          <Duration start={start} end={end} adjustment={durationAdjustment} interval={1000} />
        </td>
        <td className="text-right">{numRows}</td>
        {transformHasIncremental && (
          <td className="text-left">{run.incremental ? 'Incremental' : 'Rebuild'}</td>
        )}
        {transformHasInserts && <td className="text-right">{rowsInserted}</td>}
        {transformHasUpdates && <td className="text-right">{rowsUpdated}</td>}
        <td className="text-right">{run.version.version_number}</td>
        <td className="text-left">{scheduledRow}</td>
        <td className="text-left">{CREATE_AS_TYPE_LABEL_MAP[run.create_as]}</td>
      </tr>
      {hasError && (
        <tr>
          <td colSpan={errorColSpan} className="w-full whitespace-pre-line">
            <div className="p-4 bg-pri-gray-50 text-pri-error-700">{errorMessage}</div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}
