/*****************************************************************************
 * If the user was just redirected from a Fivetran Connect Card back to Mozart,
 * open a modal that lets the user know their connector is syncing or
 * that they need to pick tables before syncing has started.
 ****************************************************************************/
import { useState } from 'react';

import { useHistory } from 'react-router';

import queryString from 'query-string';

import { AppConnector } from 'api/APITypes';

import { connectorStartsPaused } from '../ConnectorUtils';

export type EventTab = 'SummaryTab' | 'ManageTablesTab';

export interface SyncModalState {
  showConnectorSyncingModal: boolean;
  setShowConnectorSyncingModal(show: boolean): void;
  onCancel(eventTab: EventTab): void;
  onGotIt(eventTab: EventTab): void;
  onAddConnector(eventTab: EventTab): void;
}

export default function useSyncModalState(connector: AppConnector): SyncModalState {
  const history = useHistory();

  // Decide if the modal should open on page load.
  // Connectors that start paused are forwarded to the ManageTablesTab and
  // the PickTablesBeforeSyncingModal renders instead of the ConnectorSyncingModal.
  const searchParams = queryString.parse(history.location.search);
  const fromFivetran = searchParams.from_fivetran === 'true';
  const startsPaused = connectorStartsPaused(connector.service);
  const openModalOnPageLoad = fromFivetran && !startsPaused;

  const [showConnectorSyncingModal, setShowConnectorSyncingModal] = useState(openModalOnPageLoad);

  const onCancel = (eventTab: EventTab) => {
    setShowConnectorSyncingModal(false);
    analytics.track(`ShowConnector${eventTab} CancelConnectorSyncingModal`);
  };

  const onGotIt = (eventTab: EventTab) => {
    setShowConnectorSyncingModal(false);
    analytics.track(`ShowConnector${eventTab} GotIt`);
  };

  const onAddConnector = (eventTab: EventTab) => {
    analytics.track(`ShowConnector${eventTab} AddAnotherConnector`);
    history.push('/connectors/add');
  };

  return {
    showConnectorSyncingModal,
    setShowConnectorSyncingModal,
    onCancel,
    onGotIt,
    onAddConnector,
  };
}
