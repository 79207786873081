import React, { useCallback, useMemo, useRef } from 'react';

import { Table } from 'react-bootstrap-icons';
import CytoscapeComponent from 'react-cytoscapejs';
import { renderToString } from 'react-dom/server';

import cytoscape, { Position, SingularElementArgument } from 'cytoscape';

import useCytoscapeSetup from 'hooks/useCytoscapeSetup';
import { isPointInsideElement } from 'utils/dom';

import { InteractiveFlowchartProps } from '../../useFlowchartEditor';

import { Edge, FlowchartQueryModel, FlowchartVertex, VertexType } from '../model/FlowchartQueryModel';

import stylesheet, { GRAPH_PADDING, VERTEX_OPERATION_WIDTH } from './Flowchart.styles';
import FlowchartControls from './FlowchartControls/FlowchartControls';
import HtmlFlowchartVertex, { getErrorIconID, VertexData } from './FlowchartVertex/FlowchartVertex';
import useFlowchartBuilder from './hooks/useFlowchartBuilder';
import { getZoomLevels } from './utils/graphUtils';

// Add some methods to the cytoscape.Core type.
// The methods are added to the Javascript object in setUpWithCY().
declare module 'cytoscape' {
  interface Core {
    toModelPosition(renderedPosition: Position): Position;
    toRenderedPosition(modelPosition: Position): Position;
    nodeAt(modelPosition: Position): SingularElementArgument | null;
    draggingEdgeStartNodeID?: string;
    movingNodeID?: string;
  }
}

/*******************************************************************************
 * Cytoscape "On Mount" methods that are called once when cytoscape mounts.
 * This arrangement makes it clear they are outside the react render loop
 * and do not have closures on the react component's contents.
 *
 * BE CAREFUL:
 * The event names and element selector syntaxes in this method looks like DOM syntaxes.
 * They are actually Cytoscape syntaxes that operate on graph elements.
 * The events returned are Cystoscape events which return an `originalEvent`(DOM Event).
 ******************************************************************************/
const setupListenersOnMount = (
  cy: cytoscape.Core,
  onSelectVertexRef: React.MutableRefObject<(vertex: FlowchartVertex) => void>,
  onDoubleClickVertexRef: React.MutableRefObject<(vertex: FlowchartVertex) => void>,
  onAddEdgeRef: React.MutableRefObject<(fromID: string, toID: string, isTopHalf: boolean) => void>,
  onSelectEdgeRef: React.MutableRefObject<(edge: Edge) => void>,
  onMouseEnterErrorIconRef: React.MutableRefObject<(vertex: FlowchartVertex) => void>,
  onMouseExitErrorIconRef: React.MutableRefObject<(vertex: FlowchartVertex) => void>,
) => {
  /* Graph Events */
  cy.ready(() => {
    // Unselect all nodes on graph when re-rendering (e.g. on local env reload)
    if (cy.$(':selected').length > 0) {
      cy.$(':selected').unselect();
    }

    cy.nodes().grabify();

    // Don't allow edges to be selectable
    cy.edges().unselectify();
  });

  // TODO: This logic applies to pipelines not query flowcharts,
  // but it doesn't matter yet because we don't load saved flowcharts yet.
  cy.on('layoutstop', () => {
    // Fit graph to screen
    cy.fit(undefined, GRAPH_PADDING);

    const ZOOM_LEVELS = getZoomLevels(cy);

    // Set min zoom level to size of graph or mid-level zoom to add some padding for smaller graphs
    const minZoom = Math.min(ZOOM_LEVELS.low, ZOOM_LEVELS.mid);
    cy.minZoom(minZoom);
    // Set max zoom level to high-level zoom or `minZoom` to lock-in how far you can zoom in on smaller graphs
    const maxZoom = Math.max(ZOOM_LEVELS.high, minZoom);
    cy.maxZoom(maxZoom);

    const graphWidth = cy.extent().w;
    // Hack to describe graphs with 4 or more vertices
    const WIDTH_THRESHOLD = VERTEX_OPERATION_WIDTH * 5;

    // Pan and zoom to currently selected node if graph is large enough. Otherwise, set initial zoom
    // to full graph size
    if (graphWidth > WIDTH_THRESHOLD) {
      cy.zoom(ZOOM_LEVELS.mid);
      cy.center(cy.$(':selected'));
    } else {
      cy.zoom(minZoom);
      cy.center();
    }
  });

  /*******************************************************************************
   * Tap and drag related user events
   ******************************************************************************/
  cy.on('tap', (event) => {
    // Don't allow deselecting nodes when clicking on the background
    if (event.target === cy) {
      cy.nodes().unselectify();
    } else {
      cy.nodes().selectify();
    }
  });

  cy.on('tap', 'node', (event) => {
    event.stopPropagation();
    const { vertex } = event.target.data();

    // I spent at least a day trying to do something more elegant than this.
    // Since the UI is a moving target as of 07/01/24, this is good enough for now.
    // The Cytoscape renders nodes independly of React; however,
    // the node's contents are rendered with React.
    // Cytoscape's click handler captures clicks before the React node contents can.
    // So we have to build the editButton's click handler into this entire
    // node's click handler and check if the click was inside the button.
    // Also, note that cytoscape's selector syntax looks like jQuery DOM selector syntax,
    // but it is actually querying cytoscape graph element data.
    const buttonElement = document.getElementById(`editButton-${vertex.id}`);
    const isEditButtonClick =
      buttonElement &&
      isPointInsideElement(
        { x: event.originalEvent.clientX, y: event.originalEvent.clientY },
        buttonElement,
      );
    if (isEditButtonClick) {
      onDoubleClickVertexRef.current(vertex);
      return;
    }

    // Also open edit modal on error click
    const errorElement = document.getElementById(getErrorIconID(vertex.id));
    const isErrorIconClick =
      errorElement &&
      isPointInsideElement(
        { x: event.originalEvent.clientX, y: event.originalEvent.clientY },
        errorElement,
      );
    if (isErrorIconClick) {
      onDoubleClickVertexRef.current(vertex);
      return;
    }

    onSelectVertexRef.current(vertex);
  });

  cy.on('dbltap', 'node', (event) => {
    event.stopPropagation();
    const { vertex } = event.target.data();
    onDoubleClickVertexRef.current(vertex);
  });

  // We record the drag start here.
  // tapend events do not happen once you drag the cursor outside of the node.
  // So we need to listen for those on the global event loop.
  cy.on('tapstart', 'node', (event) => {
    const { id } = event.target.data();
    if (event.originalEvent.shiftKey) {
      cy.movingNodeID = id;
    } else {
      cy.draggingEdgeStartNodeID = id;
    }
  });

  cy.on('tapend', 'node', (event) => {
    const data = event.target.data();
    const myID = data.id;
    const draggingEdgeStartNodeID = cy.draggingEdgeStartNodeID;

    if (draggingEdgeStartNodeID) {
      // Forget the dragstart
      cy.draggingEdgeStartNodeID = undefined;

      // I drug over myself.
      // Let the global handler move me.
      if (myID === draggingEdgeStartNodeID) {
        return;
      }

      // FYI: Numbers increase as you go towards the bottom of the screen.
      const isTopHalf = event.position.y <= data.vertex.position.y;

      // I had to do a hack because onAddEdge() wasn't getting updated.
      onAddEdgeRef.current(draggingEdgeStartNodeID, myID, isTopHalf);
    }
  });

  cy.on('tapend', (event) => {
    if (cy.draggingEdgeStartNodeID) {
      // Forget the dragstart
      cy.draggingEdgeStartNodeID = undefined;
    }
    if (cy.movingNodeID) {
      // Forget the dragstart
      cy.movingNodeID = undefined;
    }
  });

  cy.on('tapdrag', (event) => {
    const movingNodeID = cy.movingNodeID;

    if (movingNodeID) {
      // Move the node
      const node = cy.$(`#${movingNodeID}`);
      if (node) {
        node.position(event.position);
      }
    }
  });

  /*******************************************************************************
   * Edge related events
   ******************************************************************************/
  cy.on('mouseover', 'edge', (event) => {
    const { id } = event.target.data();
    cy.$(`#${id}`).addClass('hovered');
  });

  cy.on('mouseout', 'edge', (event) => {
    const { id } = event.target.data();
    cy.$(`#${id}`).removeClass('hovered');
  });

  cy.on('tap', 'edge', (event) => {
    event.stopPropagation();
    const { edge } = event.target.data();
    onSelectEdgeRef.current(edge);
  });

  /*******************************************************************************
   * ErrorIcon hover events
   *
   * The React rendered node contents do not exist inside the cytoscape node, so:
   * 1. Use cytoscape to listen for mousemove events on the node.
   * 2. Then check the entire browser DOM to see if the cursor is ontop of the error icon.
   ******************************************************************************/
  let overErrorIcon = false;
  cy.on('mousemove', 'node', (event) => {
    const { id, vertex } = event.target.data();
    const errorElement = document.getElementById(getErrorIconID(id));
    if (errorElement) {
      const insideErrorElement = isPointInsideElement(
        { x: event.originalEvent.clientX, y: event.originalEvent.clientY },
        errorElement,
      );
      if (overErrorIcon === false && insideErrorElement) {
        overErrorIcon = true;
        onMouseEnterErrorIconRef.current(vertex);
      } else if (overErrorIcon === true && !insideErrorElement) {
        overErrorIcon = false;
        onMouseExitErrorIconRef.current(vertex);
      }
    }
  });
};

const setupNodeHTMLLabelOnMount = (cy: cytoscape.Core) => {
  //@ts-ignore
  cy.nodeHtmlLabel(
    [
      {
        query: 'node',
        tpl(data: VertexData) {
          return renderToString(
            <HtmlFlowchartVertex
              id={data.id}
              vertex={data.vertex}
              isLoading={data.isLoading}
              isSelected={data.isSelected}
            />,
          );
        },
      },
    ],
    {
      enablePointerEvents: true,
    },
  );
};

const appendCYUtilitiesOnMount = (cy: cytoscape.Core) => {
  // Add two methods to cy:
  // modelPosition is the position in the cy data model.
  // renderedPosition is the position on the viewport div after the graph has been panned and/or zoomed.
  // cy.pan() returns renderedPostion units.
  // Convert a rendered position to a model position
  cy.toModelPosition = (renderedPosition: Position): Position => {
    const pan = cy.pan();
    const zoom = cy.zoom();
    return {
      x: (renderedPosition.x - pan.x) / zoom,
      y: (renderedPosition.y - pan.y) / zoom,
    };
  };

  // Convert a model position to a rendered position
  cy.toRenderedPosition = (modelPosition: Position): Position => {
    const pan = cy.pan();
    const zoom = cy.zoom();
    return {
      x: modelPosition.x * zoom + pan.x,
      y: modelPosition.y * zoom + pan.y,
    };
  };

  cy.nodeAt = (modelPosition: Position): SingularElementArgument | null => {
    const droppedOnNodes = cy.nodes().filter((n) => {
      const box = n.boundingBox();
      return (
        box.x1 <= modelPosition.x &&
        box.x2 >= modelPosition.x &&
        box.y1 <= modelPosition.y &&
        box.y2 >= modelPosition.y
      );
    });

    return droppedOnNodes.length > 0 ? droppedOnNodes.first() : null;
  };
};

/*******************************************************************************
 * The Flowchart Component
 ******************************************************************************/
interface FlowchartProps {
  flowchartModel: FlowchartQueryModel;
  interactiveFlowchartProps: InteractiveFlowchartProps;
  onAddToolbarItem: (type: VertexType, position: Position, tableID?: string) => void;
  onSelectVertex: (vertex: FlowchartVertex) => void;
  onDoubleClickVertex: (vertex: FlowchartVertex) => void;
  onAddEdge: (fromID: string, toID: string, isTopHalf: boolean) => void;
  onSelectEdge: (edge: Edge) => void;
  onMouseEnterErrorIcon: (vertex: FlowchartVertex) => void;
  onMouseExitErrorIcon: (vertex: FlowchartVertex) => void;
}

const Flowchart = React.memo((props: FlowchartProps) => {
  const {
    flowchartModel,
    interactiveFlowchartProps,
    onAddToolbarItem,
    onSelectVertex,
    onDoubleClickVertex,
    onAddEdge,
    onSelectEdge,
    onMouseEnterErrorIcon,
    onMouseExitErrorIcon,
  } = props;
  const cyRef = useRef<cytoscape.Core | null>(null);
  const dropDivRef = useRef<HTMLDivElement | null>(null);

  useCytoscapeSetup();

  // For some reason my callbacks always had a closure on an out of date version of onAddEdge.
  // So I'm passing it as a ref as a hacky fix.
  const onAddEdgeRef = useRef(onAddEdge);
  onAddEdgeRef.current = onAddEdge;
  const onSelectVertexRef = useRef(onSelectVertex);
  onSelectVertexRef.current = onSelectVertex;
  const onDoubleClickVertexRef = useRef(onDoubleClickVertex);
  onDoubleClickVertexRef.current = onDoubleClickVertex;
  const onSelectEdgeRef = useRef(onSelectEdge);
  onSelectEdgeRef.current = onSelectEdge;
  const onMouseEnterErrorIconRef = useRef(onMouseEnterErrorIcon);
  onMouseEnterErrorIconRef.current = onMouseEnterErrorIcon;
  const onMouseExitErrorIconRef = useRef(onMouseExitErrorIcon);
  onMouseExitErrorIconRef.current = onMouseExitErrorIcon;

  const hasDoneOnMountSetupRef = useRef<boolean>(false);

  const { graphElements } = useFlowchartBuilder(flowchartModel, interactiveFlowchartProps);

  // Notes:
  // 1. setupWithCY needs to be idempotent. It is called on every render of <CytoscapeComponent/>.
  // 2. setUpWithCY does not get called until there is at least one graph element.
  const setUpWithCY = useCallback((cy: cytoscape.Core) => {
    // setupWIthCY is called on every render
    // Everything inside this if statement should only be done on mount.
    if (hasDoneOnMountSetupRef.current === false) {
      // Give React a reference to cy
      cyRef.current = cy;

      // Give developers a debugging reference to cy
      //@ts-ignore
      window.cy = cy;

      setupNodeHTMLLabelOnMount(cy);
      setupListenersOnMount(
        cy,
        onSelectVertexRef,
        onDoubleClickVertexRef,
        onAddEdgeRef,
        onSelectEdgeRef,
        onMouseEnterErrorIconRef,
        onMouseExitErrorIconRef,
      );
      appendCYUtilitiesOnMount(cy);

      hasDoneOnMountSetupRef.current = true;
    }
  }, []);

  /* Event handlers for graph controls */
  const handleZoom = (zoomLevelDelta: number) => {
    const cy = cyRef.current;
    if (cy) {
      const { x1, x2, y1, y2 } = cy.extent();
      const viewportCenter = {
        x: (x1 + x2) / 2,
        y: (y1 + y2) / 2,
      };
      cy.zoom({ level: cy.zoom() + zoomLevelDelta, position: viewportCenter });
    }
  };

  const handleZoomIn = () => {
    handleZoom(0.2);
  };

  const handleZoomOut = () => {
    handleZoom(-0.2);
  };

  const handleResetView = () => {
    const cy = cyRef.current;
    if (cy) {
      cy.fit(cy.nodes(), GRAPH_PADDING);
      if (cy.zoom() > 1) {
        cy.zoom(1);
        cy.center();
      }
    }
  };

  const graphHeight = `100%`;

  /**
   * Memoized to avoid re-renders until user refreshes the graph
   * TODO(flowchart-improv): This is a temporary fix to avoid jumping the user around the graph
   * when it reloads (i.e. when transforms or tables are re-fetched). Refactor to persist viewport
   * data for re-renders.
   */
  const graphComponent = useMemo(() => {
    // eslint-disable-next-line no-console
    console.log('RENDERING graphComponent', graphElements, graphHeight);
    return graphElements.length === 0 ? null : (
      <>
        {/* @ts-ignore */}
        <CytoscapeComponent
          elements={graphElements}
          layout={undefined}
          style={{ width: '100%', height: graphHeight }}
          stylesheet={stylesheet}
          cy={setUpWithCY}
          wheelSensitivity={0.8}
          // Disables multi-selecting nodes and edges
          boxSelectionEnabled={false}
          // Disables drag-and-drop of nodes
          autoungrabify
        />
      </>
    );
  }, [graphElements, graphHeight, setUpWithCY]);

  // Convert drag event offset which is relative to page
  // to offset relative to div.
  const getDivOffset = (divEle: HTMLDivElement, event: React.DragEvent<HTMLDivElement>): Position => {
    const rect = divEle.getBoundingClientRect();
    const offsetX = event.pageX - rect.left;
    const offsetY = event.pageY - rect.top;
    return { x: offsetX, y: offsetY };
  };

  // On 2024-05-22, John spent a long time trying to figure out how to put drop events direcly
  // on cytoscape nodes and could not figure it out. It's not documented and drop
  // events don't seem to pass through in an undocumented way because cytoscape has its own
  // artificial event layer.
  // So we are capturing drop events on the containing div and converting the div coordinates
  // to cytoscape model coordinates.
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    // event.stopPropagation();
    event.preventDefault();
    if (dropDivRef.current) {
      const divOffset = getDivOffset(dropDivRef.current, event);

      // We don't get a cyRef until there is at least one graph element.
      if (cyRef.current) {
        const cyModelPosition = cyRef.current.toModelPosition(divOffset);
        handleDropAtPosition(event, cyModelPosition);
      }
      // There isn't an existing graph.
      // Drop the element at the divOffset because that is the same as the cyModelPosition at this point.
      else {
        handleDropAtPosition(event, divOffset);
      }
    }
  };

  const handleDropAtPosition = (event: React.DragEvent<HTMLDivElement>, modelPosition: Position) => {
    const plainData = event.dataTransfer.getData('text/plain');
    const parsedData = plainData.slice(0, 1) === '{' ? JSON.parse(plainData) : plainData;
    // parsedData is a string so it must be a tableID.
    if (typeof parsedData === 'string') {
      if (cyRef.current) {
        // The drop happened ontop of an existing node.
        // const nodeAtPos = cyRef.current.nodeAt(modelPosition);
        // if (nodeAtPos) {
        // Historically, we could drop things on nodes.
        // Leaving code here as an example in case we want to do it again.
        //onDropTableOnVertex(nodeAtPos.data().vertex, parsedData);
        // }
        // The drop happend on the canvas
        // else {
        onAddToolbarItem('source_table', modelPosition, parsedData);
        // }
      }
      // This is the first node because we don't have a cyRef.
      // Therefore, the drop happend on the canvas.
      else {
        onAddToolbarItem('source_table', modelPosition, parsedData);
      }
    }
    // parseData is an object so its an item dropped from the toolbar
    else if (typeof parsedData === 'object' && parsedData.dropType === 'ToolbarItem') {
      onAddToolbarItem(parsedData.vertexType, modelPosition);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    // event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div className="w-full h-full">
      {/* 
        Set flowchart controls' z-index to be below modal components (i.e. below 50 - not sure exactly
        why 50) but above Cytoscape canvas widget
      */}
      <FlowchartControls
        className="absolute top-0 right-0 z-[40]"
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        onResetView={handleResetView}
      />
      <div
        ref={dropDivRef}
        className="w-full h-full relative"
        onDropCapture={handleDrop}
        onDragOverCapture={handleDragOver}
      >
        {graphComponent}
        {graphElements.length === 0 && (
          <div className="w-full h-full absolute f-center">
            <div className="flex flex-col items-center">
              <Table size={24} color="var(--sec-blue-gray-500)" />
              <p className="mt-4 text-medium text-pri-gray-500 font-medium">
                Drag a table from the toolbar onto the canvas.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default Flowchart;
