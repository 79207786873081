import { capitalize } from 'lodash';

import { Column } from 'api/columnAPI';
import { AggTable } from 'api/tableAPI';

import { validateJoin } from '../form_ui/join/useJoin';
import { validateColumnSelections } from '../form_ui/select_columns/SelectColumnsModal/ColumnSelector/useColumnSelector';

import { ErrorDic } from './flowchartQueryModelValidator';
import { SavedEdge } from './SavedFlowchartQueryModel';

/**
 * FlowchartQueryModel is used to:
 * 1. Render the Flowchart UI components.
 * 2. Fill in Flowchart vertex forms, suggest values, and validate input.
 * 3. Compute the SQL the Flowchart represents with the queryBuilder.
 *
 * This flowchart can either be:
 * 1. A complete flowchart that results in valid SQL that runs.
 * 2. A complete flowchart that results in valid SQL that does not run
 *    because it references a table or column that does not exist.
 * 3. A work in progress that does not result in valid SQL.
 *
 * A FlowchartQueryModel can be converted to a SavedFlowchartQueryModel and visa versa.
 * A VALID FlowchartQueryModel can be converted to an SQL query and visa versa.
 *
 * FlowchartQueryModels are like SavedFlowchartQueryModels but have additional information appended to them:
 * 1. Table and Column objects from the API.
 * 2. Possible options for vertex forms.
 * 3. User selections in vertex forms.
 * 4. Generally anything else the editor might need to associate with
 *    a vertex in order to compute a SQL query.
 */

// This is the primary interface of the React UI and the queryBuilder.
export interface FlowchartQueryModel {
  vertices: FlowchartVertex[];
  edges: Edge[];
}

/*******************************************************************************
 * FlowchartVertices have:
 * 1. Child objects.
 * 2. Some subclasses have parent objects.
 * 3. Their Typescript types have shorter easier names than SavedFlowchartQueryModel vertices since they will be worked on more.
 ******************************************************************************/
export type VertexType =
  | 'source_table'
  | 'select_columns'
  | 'union'
  | 'join'
  | 'where'
  | 'group_by'
  | 'order_by';
export class FlowchartVertex {
  id: string;
  type: VertexType = 'source_table'; // All subclasses should override this.
  position: { x: number; y: number };
  childID: string | null = null;
  constructor(id: string, position: { x: number; y: number } = { x: 0, y: 0 }) {
    this.id = id;
    this.position = position;
  }

  // `Plugged` means there is an edge connected to the applicable socket.
  // The `Child` socket is the output socket of a vertex.
  isChildPlugged(): boolean {
    return this.childID !== null;
  }

  unplug(linkedVertex: FlowchartVertex): void {
    if (this.childID === linkedVertex.id) {
      this.childID = null;
    }
  }
  /*******************************************************************************
   * These are the default "do nothing" implementations of the UI's interface.
   * Subclasses should override the methods that are applicable to them.
   * Even though some of these methods are only applicable to one subclass,
   * it is believed defining them for every vertex will prevent excessive
   * type checking and type casting in other files.
   ******************************************************************************/
  // Does the vertex have one parent node(AKA input node)?
  isMonoParent(): boolean {
    return false;
  }
  isSingleParentPlugged(): boolean {
    return false;
  }
  // Does the vertex have two inputs(left and right)?
  isBiParent(): boolean {
    return false;
  }
  isLeftParentPlugged(): boolean {
    return false;
  }
  isRightParentPlugged(): boolean {
    return false;
  }
  // Returns null if not the parent.
  // Returns the socket of the parent.
  parentIDToSocket(maybeParentID: string): DestinationSocketType | null {
    return null;
  }
  // Returns null if the socket is not defined or the parent ID is null.
  // Returns ID of parent at the given socket.
  socketToParentID(socket: DestinationSocketType): string | null {
    return null;
  }
  // All of the vertex's inputs are satisfied and we can open the vertex edit form.
  canEdit(): boolean {
    return false;
  }
  hasSelectColumns(): boolean {
    return false;
  }

  /*******************************************************************************
   * Validation:
   * 1. Subclasses should probably reimplement `validateInputs()` and `validateForm()`.
   * 2. Subclasses should probably not reimplement `validate()` and `validateFlat()`.
   ******************************************************************************/
  // @returns a list of errors or null for no errors.
  validate(): ErrorDic | string[] | null {
    // Step 1:
    // Validate my inputs.
    // As of 08/08/24, we are just making sure the vertex's input sockets are plugged.
    // I suspect it will become evident that we need to recursively search all ancestors for errors.
    // Deferring that work until it becomes evident it must be done.
    const inputErrors = this.validateInputs();
    if (inputErrors) {
      return inputErrors;
    }
    // Step 2:
    // Check that all of the required form inputs are non-empty and are valid.
    const formErrors = this.validateForm();
    if (formErrors) {
      return formErrors;
    }
    return null;
  }

  // `validate()` can return a structured object and you might want a flat list.
  validateFlat(): string[] | null {
    const errors = this.validate();
    if (errors === null || Array.isArray(errors)) {
      return errors;
    }
    return flattenErrorDic(errors);
  }

  // As of 08/08/24, we are just making sure the vertex's input sockets are plugged.
  // I suspect it will become evident that we need to recursively search all ancestors for errors.
  // Deferring that work until it becomes evident it must be done.
  // @returns a list of errors or null for no errors.
  validateInputs(): string[] | null {
    return null;
  }

  // Validate the form inputs you would see if you tried to edit a node.
  // @returns a dictionary of errors or null for no errors.
  validateForm(): ErrorDic | null {
    return null;
  }

  /*******************************************************************************
   * Utilities
   ******************************************************************************/
  prettyType(): string {
    return this.type
      .split('_')
      .map((w) => capitalize(w))
      .join(' ');
  }
}

// MonoParentVertices have one parent node(AKA input node).
// Typically, these mutate a data set in some way,
// such as picking columns, filtering, sorting, etc...
export class MonoParentVertex extends FlowchartVertex {
  singleParentID: string | null = null;

  unplug(linkedVertex: FlowchartVertex): void {
    super.unplug(linkedVertex);
    if (this.singleParentID === linkedVertex.id) {
      this.singleParentID = null;
    }
  }

  isMonoParent(): boolean {
    return true;
  }
  isSingleParentPlugged(): boolean {
    return this.singleParentID !== null;
  }
  parentIDToSocket(maybeParentID: string): DestinationSocketType | null {
    if (maybeParentID === this.singleParentID) {
      return 'single';
    }
    return null;
  }
  socketToParentID(socket: DestinationSocketType): string | null {
    if (socket === 'single') {
      return this.singleParentID;
    }
    return null;
  }
  canEdit(): boolean {
    return this.isSingleParentPlugged();
  }
  validateInputs(): string[] | null {
    if (!this.isSingleParentPlugged()) {
      return ['Connect my input'];
    }
    return null;
  }
}

// BiParentVertices have two parent nodes(AKA input nodes).
// Typically, these are nodes that combine two data sets,
// such as in an SQL JOIN or UNION.
export class BiParentVertex extends FlowchartVertex {
  // The names of `leftParentID` and `rightParentID` have their origin in SQL JOIN terminology
  // where a join has a left and a right side.
  // In the UI, the left parent is the top socket and the right parent is the bottom socket.
  leftParentID: string | null = null;
  rightParentID: string | null = null;

  unplug(linkedVertex: FlowchartVertex): void {
    super.unplug(linkedVertex);
    if (this.leftParentID === linkedVertex.id) {
      this.leftParentID = null;
    }
    if (this.rightParentID === linkedVertex.id) {
      this.rightParentID = null;
    }
  }

  isBiParent(): boolean {
    return true;
  }
  isLeftParentPlugged(): boolean {
    return this.leftParentID !== null;
  }
  isRightParentPlugged(): boolean {
    return this.rightParentID !== null;
  }
  parentIDToSocket(maybeParentID: string): DestinationSocketType | null {
    if (maybeParentID === this.leftParentID) {
      return 'left';
    } else if (maybeParentID === this.rightParentID) {
      return 'right';
    }
    return null;
  }
  socketToParentID(socket: DestinationSocketType): string | null {
    if (socket === 'left') {
      return this.leftParentID;
    } else if (socket === 'right') {
      return this.rightParentID;
    }
    return null;
  }
  canEdit(): boolean {
    return this.isLeftParentPlugged() && this.isRightParentPlugged();
  }
  validateInputs(): string[] | null {
    const leftPlugged = this.isLeftParentPlugged();
    const rightPlugged = this.isRightParentPlugged();
    if (!leftPlugged && !rightPlugged) {
      return ['Connect my inputs'];
    } else if (!leftPlugged) {
      return ['Connect my first input'];
    } else if (!rightPlugged) {
      return ['Connect my second input'];
    }
    return null;
  }
}

// Any Vertex type that allows you to select columns should implement HasSelectColumns.
export interface HasSelectColumns {
  // The order of DB columns matters; therefore, the order of this array matters.
  // If this value is undefined you get all of the available columns as in `SELECT *`.
  selectedColumns?: TableColumn[];

  // Creates some type safety if you implement this interface
  hasSelectColumns(): true;
}

export class SourceTable extends FlowchartVertex {
  type: VertexType = 'source_table';
  table: AggTable | null = null;
  alias: string | null = null;
  columns: Column[] = [];

  canEdit(): boolean {
    return true;
  }

  validateForm(): ErrorDic | null {
    if (!this.table) {
      return { general: 'Set my table' };
    }
    return null;
  }
}

export interface Selectable {
  // `name` is not unique if a user joins two tables with the same column name.
  // or if the user manually creates a column with the same value for some unexplicable reason.
  // So let's assign every column an ID that is guaranteed to be unique within a FlowchartQueryModel.
  id: string;

  picked: boolean; // Did the user select or unselect this column?
  alias?: string; // Should we rename the column to anything?
}
export interface TableColumn extends Selectable {
  // The name of the column this vertex inherits from its ancestor.
  // This value should continue to work if:
  // 1. The ancestor is replaced by another table with a column of the same name.
  // 2. The ancestor is removed and later replaced by itself.
  // Vertex validation should fail if the ancestor is null or the ancestor does not have a column with this name.
  name: string;
  // The ancestory socket this column comes from.
  socket: DestinationSocketType;
  table: AggTable | null; // Set if the vertex's ancestry is connected to a source_table
  column: Column | null; // Set if the vertex's ancestry is connected to a source_table
}

// Hypothetical Future Feature:
// CalcualtedFields are an escape hatch to allow advanced users to inject
// arbitrary SQL into No-Code as a ColumnValue.
export interface CalculatedField extends Selectable {
  /**
   * `value` can be:
   * 1. A specific column name like: `user_id`
   * 2. A wildcard: `*`
   * 3. A formula like: `sin(column_name) + 5`
   * 4. A query like: `(SELECT d.department_name
   *                   FROM departments d
   *                   WHERE d.department_id = e.department_id) AS department_name`
   */
  value: string;
}

export type ColumnValue = TableColumn | CalculatedField;

export class SelectColumns extends MonoParentVertex implements HasSelectColumns {
  type: VertexType = 'select_columns';
  selectedColumns?: TableColumn[];

  hasSelectColumns(): true {
    return true;
  }

  validateForm(): ErrorDic | null {
    if (this.selectedColumns) {
      const [aliasErrors, formError] = validateColumnSelections(this.selectedColumns);
      if (Object.keys(aliasErrors).length > 0) {
        return { aliasErrors: aliasErrors };
      }
      if (formError) {
        return { general: formError };
      }
    }
    return null;
  }
}

// Unions have no additional properties at present because the
// unioned tables are defined by thier parent vertices.
export class Union extends BiParentVertex {
  type: VertexType = 'union';
}

// We are only supporting very simple joins where the comparison is
// a simple equality as in `table_a.column = table_b.column`
export class Join extends BiParentVertex implements HasSelectColumns {
  type: VertexType = 'join';
  joinType: JoinType = 'INNER';
  leftColumn: string = '';
  rightColumn: string = '';
  selectedColumns?: TableColumn[];

  hasSelectColumns(): true {
    return true;
  }

  validateForm(): ErrorDic | null {
    const [joinErrors, aliasErrors, formError] = validateJoin(
      this.leftColumn,
      this.rightColumn,
      this.selectedColumns,
    );
    const allErrors: ErrorDic = {
      ...joinErrors,
    };
    if (Object.keys(aliasErrors).length > 0) {
      return (allErrors.aliasErrors = aliasErrors);
    }
    if (Object.keys(allErrors).length > 0) {
      return allErrors;
    }
    if (formError) {
      return { general: formError };
    }
    return null;
  }
}
export type JoinType = 'INNER' | 'LEFT INNER' | 'RIGHT INNER' | 'OUTER';

export class Where extends MonoParentVertex {
  type: VertexType = 'where';
  // TODO: Right now this is just ANDing together.
  // We eventually need arbitrary groupings of AND/OR
  filter: Filter[] = [];
}

export interface Filter {
  leftValue: string;
  comparison: Comparison;
  rightValue: string;
}

// TODO: What about IS?
export type Comparison = '<' | '>' | '<=' | '>=' | '=' | '<>';

export class GroupBy extends MonoParentVertex {
  type: VertexType = 'group_by';
  groupBy: string[] = []; // An array of column names.
}

export class OrderBy extends MonoParentVertex {
  type: VertexType = 'order_by';
  orderBy: Order[] = [];
}

export interface Order {
  column: string;
  direction: 'ASC' | 'DESC';
  nullsLast: boolean;
}

// At present there isn't a difference between a Flowchart edge and a SavedFlowchart edge,
// but I want to go ahead and set up a future proof type system.
export type Edge = SavedEdge;
export type DestinationSocketType = 'single' | 'left' | 'right';
export type SocketType = DestinationSocketType | 'child';

/**
 * HELPER METHODS:
 * TODO: Consider making FlowchartVertex a Class and making these class methods.
 * Not doing now to keep current PR on one topic.
 */
export function getNewFlowchart(): FlowchartQueryModel {
  return {
    vertices: [],
    edges: [],
  };
}

export function getVertex(fqm: FlowchartQueryModel, vertexID: string): FlowchartVertex {
  const found = fqm.vertices.find((v) => v.id === vertexID);
  if (!found) {
    throw new Error(
      `vertices.find(id:${vertexID}) returned undefined. This is represents a programming error.`,
    );
  }
  return found;
}

// This is somewhat redundant with validate() but it makes for a better API in the save() method.
export function getFinalVertex(model: FlowchartQueryModel): FlowchartVertex {
  const childlessVertices = model.vertices.filter((v) => v.childID === null);
  if (childlessVertices.length !== 1) {
    throw new Error('You must have exactly one childless vertice.');
  }

  return childlessVertices[0];
}

export function getAncestorModelFrom(
  fqm: FlowchartQueryModel,
  vertex: FlowchartVertex,
): FlowchartQueryModel {
  const vertices = getAncestorVerticiesFrom(fqm, vertex);
  const verticeIDs = vertices.map((v) => v.id);
  const edges = fqm.edges.filter(
    (e) => verticeIDs.includes(e.sourceID) || verticeIDs.includes(e.sourceID),
  );
  const newModel: FlowchartQueryModel = {
    vertices,
    edges,
  };
  return newModel;
}

export function getAncestorVerticiesFrom(
  fqm: FlowchartQueryModel,
  vertex: FlowchartVertex | null | undefined,
): FlowchartVertex[] {
  // Base Case:
  if (vertex === null || vertex === undefined) {
    return [];
  }

  const singleParentID = (vertex as MonoParentVertex).singleParentID;
  const leftParentID = (vertex as BiParentVertex).leftParentID;
  const rightParentID = (vertex as BiParentVertex).rightParentID;

  const singleParent = fqm.vertices.find((v) => v.id === singleParentID);
  const leftParent = fqm.vertices.find((v) => v.id === leftParentID);
  const rightParent = fqm.vertices.find((v) => v.id === rightParentID);

  return [
    vertex,
    getAncestorVerticiesFrom(fqm, singleParent),
    getAncestorVerticiesFrom(fqm, leftParent),
    getAncestorVerticiesFrom(fqm, rightParent),
  ].flat();
}

export function isDescendant(
  fqm: FlowchartQueryModel,
  from: FlowchartVertex,
  maybeDescendant: FlowchartVertex,
): boolean {
  // Base Case: Found.
  if (from.id === maybeDescendant.id) {
    return true;
  }

  // Base Case: No more children to search.
  if (from.childID === null) {
    return false;
  }

  // Recurse on the child.
  const child = fqm.vertices.find((v) => v.id === from.childID);
  if (child) {
    return isDescendant(fqm, child, maybeDescendant);
  }

  // Child is missing. This should not happen.
  return false;
}

export function flattenErrorDic(errorDic: ErrorDic): string[] {
  return Object.values(errorDic)
    .map((e) => {
      return typeof e === 'string' ? e : flattenErrorDic(e);
    })
    .flat();
}
