import { PromotionKey } from '../../../promotionBannerState';

import AbstractPromotionBanner, { ConcretePromotionBannerProps } from './AbstractPromotionBanner';

export const REFERRAL_PROMOTION_KEY: PromotionKey = 'hasSeenPromotionReferralBanner';
export const REFERRAL_EVENT_LOCATION = 'HeaderReferralPromotionBanner';

export interface ReferralPromotionBannerProps extends ConcretePromotionBannerProps {}

export const ReferralPromotionBanner = (props: ReferralPromotionBannerProps) => {
  return (
    <AbstractPromotionBanner {...props} eventLocation={REFERRAL_EVENT_LOCATION}>
      <span>
        <span>
          Love Mozart Data? Share your love with friends and earn a $500 referral bonus (up from $250)
          for each successful demo completed.
        </span>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdA-jRgBwsF-2eQR3dnq1POLT78tpLrzm1Mg1H8lHUWeD0b2A/viewform"
          rel="noopener noreferrer"
          target="_blank"
          data-track={`${REFERRAL_EVENT_LOCATION} LinkToProgramDetails`}
          className="ml-2 underline hover:pointer"
        >
          Click here to get started.
        </a>
      </span>
    </AbstractPromotionBanner>
  );
};

export default ReferralPromotionBanner;
