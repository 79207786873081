import React from 'react';

import { Connector } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import { conName } from 'pages/connectors/ConnectorRegistry';
import { integerFormatDuration } from 'utils/dateTime';
import { pascalCaseSnakeCase } from 'utils/String';

import { TabKey } from '../ShowHasConnectorController';
import { EventTab } from '../useSyncModalState';

import { avgConnectorTimes } from './connectorSyncTimes';

export interface ConnectorSyncingModalProps {
  connector: Connector;
  currentTab: TabKey;
  onCancel(eventTab: EventTab): void;
  onGotIt(eventTab: EventTab): void;
  onAddConnector(eventTab: EventTab): void;
}

export default function ConnectorSyncingModal(props: ConnectorSyncingModalProps) {
  const { connector, currentTab, onCancel, onGotIt, onAddConnector } = props;
  const eventTab = `${pascalCaseSnakeCase(currentTab)}Tab` as EventTab;

  const modalFooter = (
    <div className="flex justify-end">
      <Button variant="lightTransparent" onClick={() => onGotIt(eventTab)}>
        Got It
      </Button>
      <Button variant="lightAction" onClick={() => onAddConnector(eventTab)} className="ml-2" autoFocus>
        Add Another Connector
      </Button>
    </div>
  );

  return (
    <Modal
      onClose={() => onCancel(eventTab)}
      header={
        <div className="text-xl font-medium">
          Your connector{' '}
          <code className="text-purple" style={{ overflowWrap: 'anywhere' }}>
            {connector.schema}
          </code>{' '}
          will sync shortly.
        </div>
      }
      footer={modalFooter}
      containerClass="max-w-[650px]"
    >
      <div className="p-4">
        <div className="p-4">
          <div className="font-medium text-pri-gray-600">How Syncing Works:</div>
          {/*FYI: list-decimal screws up padding. So compensate with extra padding.*/}
          <ol className="list-decimal pl-4 text-pri-gray-500">
            <li>{pickSyncTimeMessage(connector)}</li>
            <li className="">
              Your synced data will be visible in your warehouse once syncing is complete.
            </li>
            <li className="">You will get an email once syncing is complete.</li>
          </ol>
        </div>
      </div>
    </Modal>
  );
}

function pickSyncTimeMessage(connector: Connector) {
  // Default to generic message where we don't know the expected sync time.
  let message = `The amount of time it takes to sync a data source varies depending on the type of connector and the amount of data in your data source.
  This can be anywhere from a minute to several hours.`;

  // If a connector has been synced more than a handful of times,
  // we have an average sync time. Use that.
  const expectedSyncTime = lookupInitialSyncTime(connector);
  if (expectedSyncTime) {
    const { service } = connector;

    // In most cases, the time is estimated in integer minutes, hours, or days.
    // If the sync time is measured in seconds, say something conversational
    // because "37 seconds" sounds bizarrely precise.
    const APPROXIMATE_THRESHOLD = 2 * 60 * 1000; // 2 minutes
    const formattedExpectedTime =
      expectedSyncTime > APPROXIMATE_THRESHOLD
        ? `about ${integerFormatDuration(expectedSyncTime)}`
        : `a few minutes`;

    message = `${conName(
      service,
    )} connectors typically take ${formattedExpectedTime} to sync. This time varies greatly depending on the amount of data in your data source.`;
  }

  return message;
}

function lookupInitialSyncTime(connector: Connector) {
  const expectedTime = avgConnectorTimes.find((ct) => ct.service === connector.service);
  if (!expectedTime) {
    return 0;
  }

  return expectedTime.seconds * 1000;
}
