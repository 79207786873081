/**
 * The master query editor layout component that most pages should use.
 * Controls the shared state and relative positions of the various
 * editor sub-components such as:
 * 1. SqlEditor
 * 2. FlowchartEditor
 * 3. RunResults
 * 4. TableExplorer
 * 5. etc...
 *
 * This component mostly composes the SqlEditorStack and the TableExplorer in a DraggablePane.
 */
import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import cn from 'classnames';

import DraggablePanes from 'components/layouts/containers/draggable_panes/DraggablePanes/DraggablePanes';
import {
  MIN_QUERY_CRUSH_WIDTH,
  MIN_TRANSFORM_CRUSH_WIDTH,
  MIN_INCREMENTAL_CRUSH_WIDTH,
} from 'components/query/SqlEditorControlBar';
import SqlEditorStack from 'components/query/SqlEditorStack';
import TableExplorer from 'components/query/TableExplorer/TableExplorer';
import { MIN_VIEW_TOGGLE_WIDTH } from 'components/query/TableExplorer/ViewModeToggle/ViewModeToggle';
import { QueryEditorState } from 'components/query/useQueryEditor';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import { AutoSavingState, SavedQuery } from 'pages/Query/useQueryTabs';

export type TimeFormat = 'TIMESTAMP' | 'HUMAN' | 'AGO';

interface QueryEditorProps {
  queryEditorState: QueryEditorState;
  canShowEditButtons: boolean;
  autoRun?: boolean;
  savedSql?: string;
  isTransform: boolean;
  incremental?: boolean;
  transformTableName?: string;
  description?: string;
  eventPrefix: string;
  saving?: boolean;
  autoSavingState?: AutoSavingState;
  // Query.tsx renders a QueryEditor in each tab.
  // This is true on the currently active tab.
  // On pages with only one instance of the QueryEditor this is always true.
  isShown: boolean;
  savedQuery?: SavedQuery;
  initialEditMode?: boolean;
  onExportCsv: () => void;
  onExportGSheet: () => void;
  onSaveSql?(): Promise<string> | undefined;
  setUnsavedSql?: (unsaved: boolean) => void;
}

export default React.memo(function QueryEditor(props: QueryEditorProps) {
  const {
    queryEditorState,
    canShowEditButtons,
    autoRun,
    savedSql,
    isTransform,
    incremental,
    transformTableName,
    description,
    eventPrefix,
    saving,
    autoSavingState,
    isShown,
    savedQuery,
    initialEditMode,
    onExportCsv,
    onExportGSheet,
    onSaveSql,
    setUnsavedSql,
  } = props;

  const { isFlowchart, selectedTable, sqlState, setSelectedTable } = queryEditorState;

  const {
    editorSql,
    editorTools,
    tablesNamesUsedInSql,
    setEditorSql,
    onInsertAtCursor,
    onInsertTableQuery,
    onInsertColumnQuery,
  } = sqlState;

  const flowchartEditorEnabled = useBooleanFlag('flowchart_editor', false);
  const [editMode, setEditMode] = useState(initialEditMode || false); // true = User can edit sql
  const [showTableExplorer, setShowTableExplorer] = useState(true);

  const actualEditMode = useMemo(() => {
    return canShowEditButtons ? editMode : true;
  }, [canShowEditButtons, editMode]);

  const setEditModeAndFocus = useCallback(
    (editMode: boolean) => {
      setEditMode(editMode);
      if (editMode) {
        // Wait for React rerender after setEditMode() is called.
        setTimeout(() => editorTools.focus(), 10);
      }
    },
    [editorTools],
  );

  useHotkeys(
    'ctrl+e, ⌘+e',
    (event: KeyboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setEditModeAndFocus(true);
    },
    { enableOnTags: ['TEXTAREA', 'INPUT'], enabled: isShown, enableOnContentEditable: true },
    [setEditModeAndFocus],
  );

  useEffect(() => {
    // Empty SQL opens edit mode if it's an option
    if (canShowEditButtons && !editMode && savedSql === '') {
      setEditModeAndFocus(true);
    }
  }, [canShowEditButtons, editMode, savedSql, setEditModeAndFocus]);

  useEffect(() => {
    // If actualEditMode is not true and the sql changes, it must have changed from another user or auto SQL updater, so show that immediately
    if (!actualEditMode && savedSql !== editorSql && savedSql) {
      setEditorSql(savedSql, false);
    }
  }, [actualEditMode, savedSql, editorSql, setEditorSql]);

  let leftMinWidth = MIN_QUERY_CRUSH_WIDTH;
  if (isTransform) {
    leftMinWidth = incremental ? MIN_INCREMENTAL_CRUSH_WIDTH : MIN_TRANSFORM_CRUSH_WIDTH;
  }
  const rightMinWidth = MIN_VIEW_TOGGLE_WIDTH + 16 + 46; // toggle, padding, minimize button.

  const tableDraggable = flowchartEditorEnabled && isFlowchart;

  return (
    // We do not want a top padding if there are tabs, which we know we have if there is a savedQuery
    <div className={cn('w-full h-full min-h-0 p-1 bg-pri-gray-200', { 'pt-0': !!savedQuery })}>
      <DraggablePanes
        leftStartingWidth="70%"
        leftMinWidth={`${leftMinWidth}px`}
        rightMinWidth={`${rightMinWidth}px`}
      >
        <SqlEditorStack
          queryEditorState={queryEditorState}
          editMode={actualEditMode}
          autoRun={autoRun}
          isTransform={isTransform}
          incremental={incremental}
          transformTableName={transformTableName}
          description={description}
          canShowEditButtons={canShowEditButtons}
          savedSql={savedSql}
          eventPrefix={eventPrefix}
          saving={saving}
          showTableExplorer={showTableExplorer}
          autoSavingState={autoSavingState}
          isShown={isShown}
          savedQuery={savedQuery}
          onExportCsv={onExportCsv}
          onExportGSheet={onExportGSheet}
          setEditMode={setEditModeAndFocus}
          onSaveSql={onSaveSql}
          setUnsavedSql={setUnsavedSql}
          setShowTableExplorer={setShowTableExplorer}
        />
        {showTableExplorer && !isFlowchart && (
          <TableExplorer
            editMode={actualEditMode}
            selectedTable={selectedTable}
            tableDraggable={tableDraggable}
            tablesNamesUsedInSql={tablesNamesUsedInSql}
            setSelectedTable={setSelectedTable}
            onInsertAtCursor={onInsertAtCursor}
            onInsertTableQuery={onInsertTableQuery}
            onInsertColumnQuery={onInsertColumnQuery}
            setShowTableExplorer={setShowTableExplorer}
          />
        )}
      </DraggablePanes>
    </div>
  );
});
