import React from 'react';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';

import { ButtonSize } from './Button';

export interface ButtonSpinnerProps {
  size: ButtonSize;
}

export default function ButtonSpinner(props: ButtonSpinnerProps) {
  const { size } = props;
  const SPINNER_SIZE = {
    large: ['mx-4', 'my-[9px]'].join(' '),
    form: ['mx-4', 'my-[9px]'].join(' '),
    small: ['mx-4', 'my-1'].join(' '),
    field: ['mx-4', 'my-1'].join(' '),
  };
  const containerSize = size === 'large' || size === 'form' ? '24px' : '20px';
  const spinnerSize = size === 'large' || size === 'form' ? '24px' : '16px'; // Tune spinner size to look good

  // TODO:
  // Spinner needs to have contrast with background color

  return (
    <>
      {/* 
        Make sure button maintains a minimum size.
        &nbsp; fixes a very strange alignment bug that looks like "position: relative, top: -4px".
      */}
      <div className={SPINNER_SIZE[size]} style={{ width: containerSize, height: containerSize }}>
        &nbsp;
      </div>
      {/* Center the spinner over the button. */}
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '0',
        }}
      >
        <CenteredSpinner
          containerSize={'100%'}
          spinnerSize={spinnerSize}
          type="circle"
          spinnerClass="opacity-75"
        />
      </div>
    </>
  );
}
