/*
 * Conditionally renders a sidebar.
 *
 * Depending on the route this componet will decide to render
 * the sidebar in spit panes or fixed mode.
 */
import React from 'react';

import { useHasFixedSidebarRoute, useIsWorkspaceRoute } from 'main/MainApp';

import SidebarLayout from './SidebarLayout';
import usePickSidebar from './usePickSidebar';

export interface SidebarLayoutControllerProps {
  children: React.ReactElement;
}

export default function SidebarLayoutController(props: SidebarLayoutControllerProps) {
  const { children } = props;

  const isWorkspace = useIsWorkspaceRoute();
  const hasFixedSidebar = useHasFixedSidebarRoute();
  const sidebarProps = usePickSidebar();
  const showSidebar = sidebarProps !== null;
  const showSplitPanesSidebar = showSidebar && !hasFixedSidebar;
  const showFixedSidebar = showSidebar && hasFixedSidebar;

  // The FixedSidebarLayout is actually absolute now.
  // We need this div to be relative to position it correctly.
  return (
    <SidebarLayout
      isWorkspace={isWorkspace}
      children={children}
      showSplitPanesSidebar={showSplitPanesSidebar}
      showFixedSidebar={showFixedSidebar}
      sidebarProps={sidebarProps}
    />
  );
}
