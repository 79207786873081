import CodeEditor, { clampedEditorHeight } from 'components/inputs/basic/CodeEditor/CodeEditor';

const EDITOR_THEME_OVERRIDES = {
  '.cm-scroller': {
    backgroundColor: '#f3f2f3',
  },
};

interface SqlPreviewEditorProps {
  sql: string;
  addHeightOffset?: boolean;
}

export default function SqlPreviewEditor(props: SqlPreviewEditorProps) {
  const { sql, addHeightOffset } = props;
  const MIN_ROWS = 3;
  const MAX_ROWS = 8;
  const ROW_HEIGHT = 16.8;
  const MAX_HEIGHT = MAX_ROWS * ROW_HEIGHT;
  const SQL_CHANGED_HEIGHT = 24; // The text is 20px plus a top margin of 4px
  const sqlLines = sql.split(/\r\n|\r|\n/).length;
  let height = clampedEditorHeight(sqlLines, MIN_ROWS, MAX_ROWS);
  if (addHeightOffset && height + SQL_CHANGED_HEIGHT > MAX_HEIGHT) {
    height -= SQL_CHANGED_HEIGHT;
  }

  return (
    <CodeEditor value={sql} height={`${height}px`} mode="sql" themeOverrides={EDITOR_THEME_OVERRIDES} />
  );
}
