import React, { useRef, useEffect, useState, useMemo } from 'react';

import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  ChartDataset,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';

import OptionSelector from 'components/inputs/composite/OptionSelector/OptionSelector';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import { CompanyPlanRow, TODAY } from 'pages/UsageV2/useUsageFetch';
import { getBudgetDataset } from 'pages/UsageV2/Utils';
import { parseApiDate } from 'utils/dateTime';
import { formatNumber } from 'utils/String';

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  ChartDataLabels,
);

//--Chart Style Options--//
// @ts-ignore
Chart.defaults.font.family = "'PT Sans', sans-serif";
//--Chart Style Options--//

type ChartType = 'Cost' | 'MAR' | 'Compute';

interface MonthlyUsageChartProps {
  companyPlanByMonth: CompanyPlanRow[] | null;
  currentInvoice: CompanyPlanRow | undefined;
}

// XXX TODO: Add budget line for allotment plans and make sure we dont have an indefinite spinner for Sonata no usage data view
export default function MonthlyUsageChart(props: MonthlyUsageChartProps) {
  const { companyPlanByMonth, currentInvoice } = props;

  const isUsage = currentInvoice?.IS_USAGE === true;

  const [chartType, setChartType] = useState<ChartType>(isUsage ? 'Cost' : 'MAR');
  const chartRef: React.RefObject<HTMLCanvasElement> = useRef(null);
  const myChart: any = useRef(undefined);

  let chartOptions = [
    { displayName: 'MAR', value: 'MAR' },
    { displayName: 'Compute', value: 'Compute' },
  ];
  if (isUsage) {
    chartOptions = [{ displayName: 'Cost', value: 'Cost' }, ...chartOptions];
  }

  const { labels, datasets } = useMemo(() => {
    if (companyPlanByMonth === null) {
      return { labels: [], datasets: [] };
    }
    if (chartType === 'Cost') {
      return processDataForCostChart(companyPlanByMonth);
    } else {
      return processDataForMAROrComputeChart(companyPlanByMonth, chartType);
    }
  }, [companyPlanByMonth, chartType]);

  // Build the chart
  useEffect(() => {
    if (companyPlanByMonth !== null) {
      const myChartRef = chartRef.current?.getContext('2d');
      if (!myChartRef) {
        return;
      }

      if (typeof myChart.current !== 'undefined') {
        myChart.current.destroy();
      }

      myChart.current = new Chart(myChartRef, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
            tooltip: {
              mode: 'x',
              intersect: false,
            },
            title: {
              display: true,
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
              grid: { display: false },
            },
            y: {
              stacked: true,
              beginAtZero: true,
              title: {
                display: true,
                text: chartType,
              },
              ticks: {
                callback: (value: string | number) => {
                  if (chartType === 'Cost') {
                    if (typeof value === 'string') {
                      return `$${value}`;
                    } else {
                      return `$${formatNumber(value)}`;
                    }
                  } else if (typeof value === 'number') {
                    return formatNumber(value);
                  } else {
                    return value;
                  }
                },
              },
            },
          },
          aspectRatio: 4,
        },
      });
    }
  }, [datasets, labels, chartType, companyPlanByMonth]);

  if (companyPlanByMonth === null) {
    return <CenteredSpinner containerMinHeight="60vh" />;
  }

  return (
    <div className="f-col mt-10">
      <div className="f-row-y-center">
        <div className="mr-4 text-lg font-medium">Monthly Usage Trend</div>
        <OptionSelector
          selectedOption={chartType}
          options={chartOptions}
          setSelectedOption={(newValue: string) => setChartType(newValue as ChartType)}
        />
      </div>
      <div className="relative w-full h-full py-4 px-0">
        <canvas id="myChart" ref={chartRef} width="100%" />
      </div>
    </div>
  );
}

const processDataForCostChart = (companyMonthlyData: CompanyPlanRow[]) => {
  const unformattedLabels: string[] = [];
  const marData: number[] = [];
  const computeData: number[] = [];
  const cloudServiceData: number[] = [];
  const storageData: number[] = [];

  companyMonthlyData.forEach((item) => {
    unformattedLabels.push(item.MONTH_START);
    marData.push(item.SUM_FIVETRAN_NET);
    computeData.push(item.SUM_COMPUTE_NET);
    cloudServiceData.push(item.SUM_CLOUD_SERVICES_NET);
    storageData.push(item.MAX_STORAGE_NET);
  });

  const commonDatasetProps = { borderWidth: 2, borderColor: 'transparent', barPercentage: 0.5 };

  const marDataset = {
    label: 'MAR',
    data: marData,
    backgroundColor: '#175cd3',
    ...commonDatasetProps,
  };

  const computeDataset = {
    label: 'Compute',
    data: computeData,
    backgroundColor: '#027a48',
    ...commonDatasetProps,
  };

  const cloudServiceDataset = {
    label: 'Cloud Service',
    data: cloudServiceData,
    backgroundColor: '#3538cd',
    ...commonDatasetProps,
  };

  const storageDataset = {
    label: 'Storage',
    data: storageData,
    backgroundColor: '#c4320a',
    ...commonDatasetProps,
  };

  const datasets = [marDataset, computeDataset, cloudServiceDataset, storageDataset];

  const labels = formatLabels(unformattedLabels);

  return { labels, datasets };
};

const processDataForMAROrComputeChart = (companyMonthlyData: CompanyPlanRow[], chartType: ChartType) => {
  const unformattedLabels: string[] = [];
  const data: number[] = [];

  companyMonthlyData.forEach((item) => {
    unformattedLabels.push(item.MONTH_START);
    data.push(chartType === 'MAR' ? item.SUM_MAR : item.SUM_CREDITS_BILLED);
  });

  const dataset: ChartDataset = {
    type: 'bar',
    label: chartType,
    data: data,
    backgroundColor: chartType === 'MAR' ? '#175cd3' : '#027a48',
    barPercentage: 0.5,
  };

  let datasets: ChartDataset[] = [dataset];
  // If we are not on usage, we have allotments to show as budgets lines
  if (companyMonthlyData.some((m) => !m.IS_USAGE)) {
    const columnName =
      chartType === 'Compute' ? 'SUM_SNOWFLAKE_CREDITS_ALLOTMENT' : 'SUM_FIVETRAN_MAR_ALLOTMENT';
    const budgetDataset = getBudgetDataset(companyMonthlyData, columnName, TODAY);
    if (budgetDataset !== null) {
      datasets.push(budgetDataset as ChartDataset);
    }
  }

  const labels = formatLabels(unformattedLabels);

  return { labels, datasets };
};

const formatLabels = (unformattedLabels: string[]) => {
  return unformattedLabels.map((l) => dayjs(parseApiDate(l)).format('MMMM YYYY'));
};
