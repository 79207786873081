import React, { useState, useEffect } from 'react';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import InputCopy from 'components/inputs/composite/InputCopy/InputCopy';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import { useUserProfile } from 'context/AuthContext';
import APIKeyInfoIcon from 'pages/settings/APIKeyInfoIcon';

interface KeyData {
  id: string;
  password: string;
  auth_header: string;
}

interface ExternalAPIKeyProps {
  setError: (error: string) => void; // Error to display on base Settings.tsx page.
}

export default function ExternalAPIKey(props: ExternalAPIKeyProps) {
  const { setError } = props;
  const { userProfile } = useUserProfile();
  const [loading, setLoading] = useState(true);
  const [oneTimeKeyData, setOneTimeKeyData] = useState<KeyData | null>(null); // This data should only be displayed once to the user
  const [apiKeyIds, setAPIKeyIds] = useState<string[]>([]); // This is a list so we can easily support multiple keys in the future

  useEffect(() => {
    const api = new API();
    api
      .get(`/api/user_settings/get_api_keys`)
      .then((response) => {
        setAPIKeyIds(response.data.api_key_ids);
      })
      .catch((error) => {
        setError('There was a problem fetching your API Keys');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setError]);

  if (loading) {
    return <CenteredSpinner containerMinHeight="100%" />;
  }

  const generateAPIKey = () => {
    const api = new API();
    api
      .get(`/api/user_settings/generate_external_api_key`)
      .then((response) => {
        setOneTimeKeyData(response.data.new_key_data);
        setAPIKeyIds(response.data.api_key_ids);
      })
      .catch((e) => {
        setError('There was a problem generating an API key.');
      });
  };

  const deleteApiKey = (apiKeyId: string) => {
    const api = new API();
    const postData = { api_key_id: apiKeyId };
    api
      .post(`/api/user_settings/delete_external_api_key`, postData)
      .then((response) => {
        // If the ID of the key to delete is the same as the one we just generated and are showing, also stop showing it
        if (oneTimeKeyData && apiKeyId === oneTimeKeyData.id) {
          setOneTimeKeyData(null);
        }
        setAPIKeyIds(response.data.api_key_ids);
      })
      .catch((error) => {
        setError('There was a problem deleting your API Key');
      });
  };

  const keyTable = (
    <table className="lineTable">
      <thead>
        <tr>
          <th className="whitespace-nowrap" style={{ border: 'none', width: '75%' }}>
            Key ID
          </th>
          <th className="whitespace-nowrap" style={{ border: 'none', width: '25%' }}>
            Delete
          </th>
        </tr>
      </thead>
      <tbody>
        {apiKeyIds.map((apiKeyId) => (
          <tr key={apiKeyId}>
            <td>{apiKeyId}</td>
            <td>
              <Button variant="lightDanger" onClick={() => deleteApiKey(apiKeyId)}>
                Delete
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  // TODO: Add button to copy key to clipboard
  const oneTimeDisplay = (
    <>
      {oneTimeKeyData && (
        <div className="mt-4 bg-pri-gray-50 rounded p-4">
          <p>
            This API Key's data will only be displayed once. When you refresh or navigate away from this
            page, we cannot show you your actual API Key again, only the key's ID. Please copy this
            information and save it somewhere secure.
          </p>
          <p>
            Shown below is the key's id, the raw password, and the base64 encoded id and password joined
            by a colon (:) and prepended by 'Basic '. This last value is exactly what is expected as your
            authentication header in HTTP requests.
          </p>
          <div>
            <InputCopy label="ID" value={oneTimeKeyData.id} containerClass="py-4" />
            <InputCopy label="Password" value={oneTimeKeyData.password} containerClass="py-4" />
            <InputCopy
              label="Authentication Header"
              value={oneTimeKeyData.auth_header}
              containerClass="py-4"
            />
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="mt-4">
      <div className="flex items-center">
        <h2 className="text-xl text-pri-gray-700">Mozart Data API Key</h2>
        <div className="ml-1">
          <APIKeyInfoIcon />
        </div>
      </div>
      <div>
        {oneTimeDisplay}
        {apiKeyIds.length > 0 ? (
          keyTable
        ) : (
          <Button
            onClick={generateAPIKey}
            disabled={userProfile.company_role !== 'admin'}
            className="mt-2"
          >
            Generate API Key
          </Button>
        )}
      </div>
    </div>
  );
}
