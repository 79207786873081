import React, { CSSProperties, useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import deepEqual from 'fast-deep-equal';

import { AggTable, Tag } from 'api/APITypes';
import tableAPI from 'api/tableAPI';
import Button from 'components/inputs/basic/Button/Button';
import SearchableCheckboxPicker from 'components/inputs/composite/SearchableCheckboxPicker/SearchableCheckboxPicker';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import TagPill from 'pages/tags/TagPill';
import { TagEventPage } from 'pages/tags/TagPill';
import { caselessCompare } from 'utils/String';

import s from './TagPicker.module.css';

type TagMap = { [key: string]: boolean };

interface TagPickerProps {
  table: AggTable;
  eventPage: TagEventPage;
  style?: CSSProperties;
  onClosePicker: () => void;
  onCreateNew: () => void;
  onAfterSaveTags: () => void;
}

export default function TagPicker(props: TagPickerProps) {
  const { table, eventPage, style, onClosePicker, onCreateNew, onAfterSaveTags } = props;

  const [sortedTags, setSortedTags] = useState<Tag[]>([]);
  const [savedTags, setSavedTags] = useState<TagMap>({}); // The tags the API thinks the table has.
  const [unsavedTags, setUnsavedTags] = useState<TagMap>({}); // savedTags plus any unsaved changes the user has made.
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { tags: allTags, updateTables } = useContext(TableModelsContext);
  const {
    userProfile: { company_role },
  } = useUserProfile();
  const databaseType = useDatabaseAccount().type;

  useEffect(() => {
    // Calculate saved tag map.
    const savedTags: TagMap = {};
    allTags.forEach((t) => (savedTags[t.id] = table.tags.includes(t.id)));
    setSavedTags(savedTags);

    // 1. Sort selected tags before unselected tags.
    // 2. Then sort tags alphabetically.
    const sortedTags = [...allTags];
    sortedTags.sort((a: Tag, b: Tag) => {
      if (savedTags[a.id] && !savedTags[b.id]) {
        return -1;
      }
      if (!savedTags[a.id] && savedTags[b.id]) {
        return 1;
      }
      return caselessCompare(a.name, b.name);
    });
    setSortedTags(sortedTags);

    // Clone saved tags into unsaved tags.
    // savedTags and unsavedTags are the same until the user makes changes.
    setUnsavedTags({ ...savedTags });
  }, [allTags, table]);

  let handleToggleTag = (tag: Tag) => {
    const tagAdded = !unsavedTags[tag.id];
    unsavedTags[tag.id] = tagAdded;
    setUnsavedTags({ ...unsavedTags });
    analytics.track(tagAdded ? `${eventPage} TagPickerAddTag` : `${eventPage} TagPickerRemoveTag`);
  };

  const handleSaveTagSet = () => {
    setSaving(true);
    const tagIDs = Object.keys(unsavedTags).filter((tagID) => unsavedTags[tagID]);
    tableAPI
      .setTags(table.id, tagIDs, databaseType)
      .then((response) => {
        const newTable = response.data;
        updateTables([newTable]);
        onAfterSaveTags();
        analytics.track(`${eventPage} TagPickerSaveTags`);
      })
      .catch((error) => {
        setErrorMessage('Failed to save tags.');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleOpenCreateTagModal = (event: React.MouseEvent) => {
    event.preventDefault();
    onCreateNew();
  };

  const hasChanges = !deepEqual(savedTags, unsavedTags);

  const getKey = (t: Tag) => t.id;
  const isChecked = (t: Tag) => unsavedTags[t.id];
  const hasChanged = (t: Tag) => savedTags[t.id] !== unsavedTags[t.id];
  const matchesFilter = (t: Tag, filter: string) =>
    t.name.toLowerCase().includes(filter) || t.description.toLowerCase().includes(filter);
  const renderNoObjects = () => (
    <div className="p-4">
      <p className="text-lg font-medium">You don't have any tags yet.</p>
      <p className="mt-1">
        Tags allow you to organize your tables by use case, project, status, or any way you want.
      </p>
      {company_role !== 'viewer' && (
        <Button variant="lightAction" onClick={onCreateNew} className="mt-4">
          Add Tag
        </Button>
      )}
    </div>
  );
  const renderNoMatchesFound = () => (
    <div className="px-2 pb-2">
      <NoMatchesFoundAlert heading="No matching tags." />
    </div>
  );
  const renderObject = (t: Tag) => (
    <TagPill key={t.id} tag={t} eventPage={eventPage} className="mr-1 my-1" />
  );
  const getDescription = (t: Tag) => t.description || '';

  return (
    <div className={s.tagPicker} style={style}>
      <div className={s.pickerBox}>
        <SearchableCheckboxPicker
          textInputProps={{
            id: 'tag-search',
            name: 'tag-search',
          }}
          objects={sortedTags}
          inputWrapperClass="f-center p-2"
          listClass="max-h-[300px]"
          getKey={getKey}
          isChecked={isChecked}
          hasChanged={hasChanged}
          onToggleCheck={handleToggleTag}
          matchesFilter={matchesFilter}
          renderNoObjects={renderNoObjects}
          renderNoMatchesFound={renderNoMatchesFound}
          renderObject={renderObject}
          getDescription={getDescription}
        />
        {allTags.length > 0 && (
          <div>
            {errorMessage && (
              <Alert variant="error" className="m-2">
                {errorMessage}
              </Alert>
            )}
            {hasChanges ? (
              <div className={s.footerButton} onClick={handleSaveTagSet}>
                {saving ? <CenteredSpinner type="circle" spinnerSize="24px" /> : <>Apply</>}
              </div>
            ) : (
              <>
                <Link to="/tags" onClick={handleOpenCreateTagModal} className={s.footerButton}>
                  Create New
                </Link>
                <Link
                  to="/tags"
                  data-track={`${eventPage} TagPickerFooterGoToManageTags`}
                  className={s.footerButton}
                >
                  Manage Tags
                </Link>
              </>
            )}
          </div>
        )}
      </div>
      <div className={s.backdrop} onClick={onClosePicker}></div>
    </div>
  );
}

export function guessPickerHeight(tagCount: number) {
  const EMPTY_HEIGHT = 234;
  const ROW_HEIGHT = 48;
  const MAX_LIST_HEIGHT = 300;
  const NON_LIST_HEIGHT = 142;
  if (tagCount <= 0) {
    return EMPTY_HEIGHT;
  }
  return Math.min(tagCount * ROW_HEIGHT, MAX_LIST_HEIGHT) + NON_LIST_HEIGHT;
}
