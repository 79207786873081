/**
 * Type definitions, API methods, and conversion utilities for Table Descriptions AI.
 */
import { AxiosResponse } from 'axios';

import API from 'api/API';

/*******************************************************************************
 * Types
 ******************************************************************************/
type Response = {
  table_description: string;
  generated_description_record_id: string;
};

/*******************************************************************************
 * APIs
 ******************************************************************************/
const descriptionAIAPI = {
  generateDescription: (tableId: string): Promise<AxiosResponse<Response, any>> => {
    const api = new API();
    return api.get(`/api/table/generate_table_description_with_gpt/${tableId}`);
  },
};
export default descriptionAIAPI;

/*******************************************************************************
 * Conversion Utilites
 ******************************************************************************/
/* None. */
