import React, { useContext, useMemo, useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { InitialSaveableCSVUploadProps, SaveableCSVUploadProps } from 'api/csvUploadAPI';
import { AggTable } from 'api/tableAPI';
import useUploadCSV from 'api_hooks/useUploadCSV';
import Button from 'components/inputs/basic/Button/Button';
import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import TextAreaFormikGroup from 'components/inputs/formik_group/TextAreaFormikGroup/TextAreaFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import { GroupError } from 'components/inputs/group/InputGroup';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useUserProfile } from 'context/AuthContext';
import { TableContext } from 'model_layer/TableContext';
import SchemaCombobox from 'pages/transforms/SchemaCombobox';
import { lowerIdentifier } from 'utils/dbName';
import { valDBIdentifier, valSchemaName } from 'utils/Validators';

import FileUpload, { explainNetworkError } from './FileUpload';

export default function AddCSV() {
  useTitle('Upload CSV');

  const history = useHistory();

  const {
    userProfile: { company_role },
  } = useUserProfile();

  const initialValues: InitialSaveableCSVUploadProps = {
    schema: 'csvs',
    table_name: '',
    description: '',
    overwrite: false,
    file: null, // If you select a file and then open file picker again and cancel, you get null
  };

  const { tables } = useContext(TableContext);
  const { saveCSV, savingCSV, csvErrorMessage, setCSVErrorMessage } = useUploadCSV();
  const fileRef = useRef<HTMLInputElement | null>(null);

  const tablesBySchema: {
    [schema: string]: {
      [name: string]: AggTable;
    };
  } = useMemo(
    () =>
      tables.reduce(
        (acc, table) => {
          const schema = lowerIdentifier(table.schema);
          const name = lowerIdentifier(table.name);

          if (!acc[schema]) {
            acc[schema] = {};
          }

          acc[schema][name] = table;

          return acc;
        },
        {} as { [schema: string]: { [name: string]: AggTable } },
      ),
    [tables],
  );

  const findTable = (schema: string, table: string): AggTable | undefined => {
    return tablesBySchema[schema]?.[table];
  };

  const schema = yup.object({
    schema: valSchemaName('Schema').trim(),
    table_name: valDBIdentifier('Table Name')
      .trim()
      .test(
        'cannot-overwrite-non-csv-files',
        'This table was not originally created via CSV upload so it cannot be replaced.',
        (tableName, context) => {
          const tableWithName = findTable(context.parent.schema, tableName);
          const tableDoesNotExist = tableWithName === undefined;
          const isCSV = tableWithName?.type === 'csv_upload';
          const isValid = tableDoesNotExist || isCSV;
          return isValid;
        },
      ),
    overwrite: yup
      .boolean()
      .test(
        'must-check-overwrite',
        'You must check this box to replace a CSV table with a new file.',
        (overwrite, context) => {
          const tableWithName = findTable(context.parent.schema, context.parent.table_name);
          const tableDoesNotExist = tableWithName === undefined;
          const isCSV = tableWithName?.type === 'csv_upload';
          const isValid = tableDoesNotExist || (isCSV && overwrite);
          return isValid;
        },
      ),
    description: yup.string(),
    file: yup.mixed().required('Required'),
  });

  const handleSave = async (
    values: InitialSaveableCSVUploadProps,
    formikHelpers: FormikHelpers<InitialSaveableCSVUploadProps>,
  ) => {
    if (!values.file) {
      // Should never get here due to form validation.
      setCSVErrorMessage('File required');
      return;
    }

    saveCSV(values as SaveableCSVUploadProps)
      .then((csvUpload) => {
        analytics.track('AddCSV Save', {
          tableId: csvUpload.table,
        });
        history.push(`/tables/${csvUpload.table}`);
      })
      .catch((e: Error) => {
        // FIRST RESPONSIBILITY:
        // Eat error so it doesn't bubble up to global exception handler.
        // saveCSV() already sets csvErrorMessage.

        // SECOND RESPONSIBILITY:
        // This fixes a Chrome bug.
        // See comments in FileUpload.tsx for details.
        if (e.message === 'Network Error' && fileRef.current) {
          formikHelpers.setFieldValue('file', null); // Reset Formik value
          fileRef.current.value = ''; // Reset the value in the <input type="file"/> element
        }
      });
  };

  const handleCancel = () => {
    analytics.track('AddCSV Cancel');
    history.goBack();
  };

  return (
    <CenteredLayout maxWidth="814px" title="Upload CSV">
      <Alert variant="info" className="mb-4">
        <p>
          Please make sure the first row in the CSV file is the header row (column names). Use double
          quotes to enclose any values that contain a comma.
          <br />
          <br />
          Having trouble?{' '}
          <a
            href="https://help.mozartdata.com/docs/csv-uploader"
            className="text-sec-blue-light-700"
            target="_blank"
            rel="noreferrer"
          >
            Read our docs
          </a>{' '}
          or contact Support at{' '}
          <a href="mailto:support@mozartdata.com" className="text-sec-blue-light-700">
            support@mozartdata.com
          </a>
          .
        </p>
      </Alert>
      <Formik validationSchema={schema} onSubmit={handleSave} initialValues={initialValues}>
        {({ handleSubmit, handleChange, values, isValid, errors }) => {
          // Don't let unnoticed spaces mess up input validation
          const trimmedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            event.target.value = event.target.value.trim();
            handleChange(event);
          };

          const tableWithName = findTable(values.schema, values.table_name);
          const showOverwriteCheckbox = tableWithName?.type === 'csv_upload';

          return (
            <form noValidate onSubmit={handleSubmit}>
              <div className="w-full grid grid-cols-2 gap-4">
                <SchemaCombobox schemaValue={values.schema} tableType="csv_upload" />
                <TextFormikGroup
                  name="table_name"
                  label="Table Name"
                  placeholder="engaged_users"
                  onChange={trimmedChange}
                  groupClass="w-full"
                />
              </div>
              {showOverwriteCheckbox && (
                <div className="mt-4">
                  <div className="f-row-y-center">
                    <Checkbox
                      id="overwrite-checkbox"
                      name="overwrite"
                      checked={!!values.overwrite}
                      onChange={handleChange}
                    />
                    <p className="ml-1">
                      Replace{' '}
                      <Link
                        to={`/tables/${tableWithName.id}`}
                        target="_blank"
                        data-track="AddCSV GoToExistingTable"
                        className="!text-purple font-medium cursor-pointer"
                      >
                        table with this name
                      </Link>{' '}
                      that already exists.
                    </p>
                  </div>
                  <GroupError error={errors.overwrite} />
                </div>
              )}
              <div className="flex flex-col items-start">
                <label className="block text-input-label mt-2 mb-1">Upload CSV</label>
                <FileUpload ref={fileRef} setErrorMessage={setCSVErrorMessage} />
              </div>
              <TextAreaFormikGroup
                name="description"
                label="A brief description of this table"
                rows={4}
                placeholder="All users that used the app in the last week"
                groupClass="mt-4"
              />
              {csvErrorMessage && (
                <Alert variant="error" className="mt-4">
                  {explainNetworkError(csvErrorMessage)}
                </Alert>
              )}
              <div className="flex mt-4">
                <Button
                  type="submit"
                  variant="lightAction"
                  style={{ width: '214px' }}
                  spinning={savingCSV}
                  disabled={company_role === 'viewer' || !isValid}
                >
                  Upload
                </Button>
                <Button
                  onClick={handleCancel}
                  variant="lightDanger"
                  className="ml-4"
                  style={{ width: '214px' }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </CenteredLayout>
  );
}
