import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

import { Dictionary, keyBy, mapValues } from 'lodash';
import queryString from 'query-string';

import API from 'api/API';
import { UserProfile, CompanyRole } from 'api/APITypes';
import { ListboxOption } from 'components/inputs/basic/Listbox/Listbox';
import { useAuth, useDatabaseAccount } from 'context/AuthContext';
import useTrackFilter from 'hooks/useTrackFilter';
import { mapReplace } from 'utils/Array';
import { useSearchFocus } from 'utils/React';

import useSnowflakeRoleModal from './useSnowflakeRoleModal';

export type UsersWithValidSnowflakeRoles = Dictionary<boolean>;

export const companyRoleMap = {
  admin: 'MOZART_ADMIN',
  editor: 'MOZART_EDITOR',
  viewer: 'MOZART_VIEWER',
};

export interface UserToSave {
  userProfile: UserProfile;
  newIsActive: boolean;
}

export default function useMozartUsers(
  snowflakeRoleOptions: ListboxOption[],
  snowflakeRoleError: string,
  snowflakeRoleLoading: boolean,
) {
  const { userProfile } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(true);
  const [users, setUsers] = useState<UserProfile[]>([]);
  const [pageError, setPageError] = useState('');
  const [saveError, setSaveError] = useState('');
  const [roleSpinners, setRoleSpinners] = useState({});
  const [isActiveSpinners, setIsActiveSpinners] = useState<{ [email: string]: true | undefined }>({});
  const [urlSelectedEmail] = useState(queryString.parse(location.search).email as string);
  const [userFilter, setUserFilter] = useState('');
  const reportOnBlur = useTrackFilter('ListUsers', userFilter);
  const [filterRef] = useSearchFocus();
  const [userToSave, setUserToSave] = useState<UserToSave | null>(null);
  // null means user hasn't set this value
  const [isDeactivatedUsersExpanded, setIsDeactivatedUsersExpanded] = useState<boolean | null>(null);

  const databaseType = useDatabaseAccount().type;

  // Fetch users from API
  useEffect(() => {
    (async () => {
      const api = new API();

      try {
        const response = await api.get('/api/user_profiles');
        const apiUsers = response.data;
        sortUsers(apiUsers, urlSelectedEmail);
        setUsers(apiUsers);
      } catch (error) {
        setPageError('Failed to load users list.');
      } finally {
        setPageLoading(false);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const usersWithValidSnowflakeRoles: UsersWithValidSnowflakeRoles = useMemo(() => {
    const userProfilesByID = keyBy(users, 'id');
    // snowflakeRoleLoading takes much longer to load than the list of users.
    // While still loading snowflakeRoleLoading, fill the list with all true to
    // prevent roles from blinking red.
    const validUsers = snowflakeRoleLoading
      ? mapValues(userProfilesByID, () => true)
      : mapValues(userProfilesByID, (user) =>
          snowflakeRoleOptions.some((role) => role.value === user.snowflake_role),
        );
    return validUsers;
  }, [users, snowflakeRoleLoading, snowflakeRoleOptions]);

  const save = useCallback(
    (email: string, is_active: boolean, company_role: CompanyRole, snowflake_role: string) => {
      const api = new API();

      const payload = {
        email,
        is_active,
        company_role,
        snowflake_role,
      };

      return api.post('api/company/users/update', payload).then((response) => {
        const apiUsers = response.data.users;
        // Replace current user list with API response.
        // Maintain current order so user isn't disoriented.
        // Note: New users will not be added since they don't have a place in the old list.
        const patchedUsers = mapReplace(
          users,
          apiUsers,
          (a: UserProfile, b: UserProfile) => a.user.email === b.user.email,
        );
        setUsers(patchedUsers);
      });
      // WARN: It's the callers responsibility to catch errors.
    },
    [users],
  );

  const onSaveRole = useCallback(
    (userProfile: UserProfile, newRole: CompanyRole) => {
      const { email, is_active } = userProfile.user;
      const { snowflake_role, company_role } = userProfile;
      // If snowflake_role currently matches company_role, update the snowflake_role too.
      let newSnowflakeRole = snowflake_role;
      if (snowflake_role === companyRoleMap[company_role]) {
        newSnowflakeRole = companyRoleMap[newRole];
      }
      setRoleSpinners({ ...roleSpinners, [email]: true });
      setSaveError('');
      save(email, is_active, newRole, newSnowflakeRole)
        .then(() => {
          analytics.track('ListUsers SetRole', {
            role: newRole,
            snowflake_role: newSnowflakeRole,
          });
        })
        .catch((error) => {
          if (error.response?.data?.error) {
            setSaveError(error.response.data.error);
          } else {
            setSaveError('Failed to update user.');
          }
        })
        .finally(() => {
          setRoleSpinners({ ...roleSpinners, [email]: undefined });
        });
    },
    [roleSpinners, save],
  );

  const snowflakeModalProps = useSnowflakeRoleModal(
    save,
    snowflakeRoleOptions,
    snowflakeRoleError,
    snowflakeRoleLoading,
  );

  const onOpenConfirmSaveIsActive = useCallback((userProfile: UserProfile, newIsActive: boolean) => {
    setUserToSave({ userProfile, newIsActive });
    analytics.track(
      newIsActive ? 'ListUsers OpenConfirmEnableActive' : 'ListUsers OpenConfirmDisableActive',
    );
  }, []);

  const onConfirmSaveIsActive = useCallback(() => {
    if (userToSave === null) {
      return;
    }

    const { userProfile, newIsActive } = userToSave;
    const { user, company_role, snowflake_role } = userProfile;
    const { email } = user;
    setIsActiveSpinners({ ...isActiveSpinners, [email]: true });
    setSaveError('');
    save(email, newIsActive, company_role, snowflake_role)
      .then(() => {
        analytics.track(newIsActive ? 'ListUsers EnableActive' : 'ListUsers DisableActive');
      })
      .catch((error) => {
        if (error.response?.data?.error) {
          setSaveError(error.response.data.error);
        } else {
          setSaveError('Failed to update user.');
        }
      })
      .finally(() => {
        setUserToSave(null);
        setIsActiveSpinners({ ...isActiveSpinners, [email]: undefined });
      });
  }, [isActiveSpinners, userToSave, save]);

  const onCancelSaveIsActive = () => {
    if (userToSave === null) {
      return;
    }

    analytics.track(
      userToSave.newIsActive
        ? 'ListUsers CancelConfirmEnableActive'
        : 'ListUsers CancelConfirmDisableActive',
    );
    setUserToSave(null);
  };

  const onDeactivatedUsersExpand = (newExpand: boolean) => {
    setIsDeactivatedUsersExpanded(newExpand);
    analytics.track(
      newExpand ? 'ListUsers ExpandDeactivatedUsers' : 'ListUsers CollapseDeactivatedUsers',
    );
  };

  const onFilterChange = (filter: string) => {
    setUserFilter(filter);
  };

  const onAddUser = () => {
    analytics.track('ListUsers Add');
    navigate('/users/add');
  };

  return {
    userProfile,
    pageLoading,
    users,
    usersWithValidSnowflakeRoles,
    pageError,
    saveError,
    roleSpinners,
    isActiveSpinners,
    urlSelectedEmail,
    userFilter,
    filterRef,
    userToSave,
    isDeactivatedUsersExpanded,
    snowflakeModalProps,
    databaseType,
    onSaveRole,
    onOpenConfirmSaveIsActive,
    onConfirmSaveIsActive,
    onCancelSaveIsActive,
    onDeactivatedUsersExpand,
    reportOnBlur,
    onFilterChange,
    onAddUser,
  };
}

const sortUsers = (users: UserProfile[], urlSelectedEmail: string) => {
  // Sort users
  users.sort((a: UserProfile, b: UserProfile) => {
    // Sort selected user to start of list
    if (a.user.email === urlSelectedEmail) {
      return -1;
    } else if (b.user.email === urlSelectedEmail) {
      return 1;
    }

    // Otherwise, sort alphabetically
    return a.user.email.toLowerCase() < b.user.email.toLowerCase() ? -1 : 1;
  });
};
