import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from 'components/inputs/basic/Button/Button';
import PasswordFormikGroup from 'components/inputs/formik_group/PasswordFormikGroup/PasswordFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { LookerAPIKeyFormValues } from './LookerView';

import cls from 'pages/byo/SetupPage.module.css';

interface APIKeyFormProps {
  handleSubmitAPIKey: (values: LookerAPIKeyFormValues) => void;
  handleShowAPIKeysIntegrationClick: () => void;
  loading: boolean;
  error: string | null;
}

const SetupSnowflakeSchema = Yup.object().shape({
  url: Yup.string().required('Required'),
  client_id: Yup.string().required('Required'),
  client_secret: Yup.string().required('Required'),
});

const APIKeyForm = (props: APIKeyFormProps) => {
  const { handleSubmitAPIKey, loading, error, handleShowAPIKeysIntegrationClick } = props;
  return (
    <CenteredContainer>
      <Formik
        initialValues={{ url: '', client_id: '', client_secret: '' }}
        onSubmit={handleSubmitAPIKey}
        validationSchema={SetupSnowflakeSchema}
      >
        {({ handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <ol>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>Enter the url of your Looker instance</p>
                <TextFormikGroup
                  type="text"
                  disabled={loading}
                  className="w-[445px]"
                  name="url"
                  placeholder="https://yourcompany.cloud.looker.com"
                  label="Looker instance url"
                  groupClass="mt-4"
                />
              </li>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>Enter your API Key</p>
                <p className="mt-2">
                  In the Looker Admin panel, go to the Users list. Find or create a User with Admin
                  permissions. Click the User and under API Keys click "Edit Keys". Click "New API Key"
                  and copy the Client ID and Client Secret to the form below.
                </p>
                <p className="mt-2">
                  See{' '}
                  <a
                    href="https://cloud.google.com/looker/docs/admin-panel-users-users#api_keys"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={cls.setupStepsItemTitleLink}
                    data-track="LookerIntegration ClickGenerateClientCredentialsDocs"
                  >
                    the docs
                  </a>
                  .
                </p>
                <TextFormikGroup
                  type="text"
                  disabled={loading}
                  className="w-[445px]"
                  name="client_id"
                  placeholder=""
                  label="Client ID"
                  groupClass="mt-4"
                />
                <PasswordFormikGroup
                  name="client_secret"
                  disabled={loading}
                  placeholder=""
                  label="Client Secret"
                  className="w-[445px]"
                  groupClass="my-4"
                />
              </li>
            </ol>
            <div className="mt-4 flex justify-end">
              <Button
                variant="lightDanger"
                onClick={handleShowAPIKeysIntegrationClick}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button className="ml-4" type="submit" disabled={loading || !isValid} spinning={loading}>
                Test and Save API Key
              </Button>
            </div>
            {error && (
              <Alert variant="error" className="mt-4">
                {error}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </CenteredContainer>
  );
};

export default APIKeyForm;
