import React from 'react';

import cn from 'classnames';

import ConnectorIcon from 'components/primitives/icons/ConnectorIcon/ConnectorIcon';
import PlatformCard, { PlatformCardProps } from 'components/widgets/PlatformCard/PlatformCard';

import { ConnectorType } from '../ConnectorRegistry';

interface ConnectorGridProps {
  connectors: Record<string, ConnectorType>;
  onSelectConnector: (service: string) => void;
  className?: string;
}

export default function ConnectorGrid(props: ConnectorGridProps) {
  const { connectors, onSelectConnector, className } = props;

  return (
    <div className={cn('grid grid-cols-3 gap-x-4 gap-y-6', className)}>
      {Object.entries(connectors).map(([service, connectorType]) => (
        <ConnectorCard
          key={service}
          service={service}
          name={connectorType.name}
          onClick={() => onSelectConnector(service)}
        />
      ))}
    </div>
  );
}

// Passing a dynamic React Node in as a prop slows down performance noticeably.
// This wrapper saves a lot of memory allocation.
// Memoizing should also help on rerenders as the user types.
interface ConnectorCardProps extends Omit<PlatformCardProps, 'icon'> {
  service: string;
}

const ConnectorCard = React.memo((props: ConnectorCardProps) => {
  const { service, ...rest } = props;

  return <PlatformCard icon={<ConnectorIcon service={service} size={48} />} {...rest} />;
});
