/*
 * Component that renders a Connector for viewing and editing.
 */
import { AppConnector } from 'api/APITypes';
import HeaderTabLayout from 'components/layouts/pages/HeaderTabLayout/HeaderTabLayout';
import { TabKeyType } from 'components/layouts/parts/TabRow/TabRow';

// import { useUserProfile } from 'context/AuthContext';
import ConnectorHeader, { ConnectorHeaderProps } from './ConnectorHeader/ConnectorHeader';
import ConnectorSyncingModal from './ConnectorSyncingModal/ConnectorSyncingModal';
import LogsTab from './LogsTab/LogsTab';
import ManageTablesTab from './ManageTablesTab/ManageTablesTab';
import { TabKey } from './ShowHasConnectorController';
import SummaryTab from './SummaryTab/SummaryTab';
import { LogsTabState } from './useLogsTabState';
import { ManageTablesTabState } from './useManageTablesTabState';
import UserActionsTab from './UserActionsTab/UserActionsTab';
import { SummaryTabState } from './useSummaryTabState';
import { SyncModalState } from './useSyncModalState';

import 'components/query/Query.css';

interface ShowConnectorViewProps {
  connector: AppConnector;
  currentTab: TabKey;
  headerState: ConnectorHeaderProps;
  summaryTabState: SummaryTabState;
  logsTabState: LogsTabState;
  manageTablesTabState: ManageTablesTabState;
  syncModalState: SyncModalState;
  onSelectTab(tab: string | null): void;
}

export default function ShowConnectorView(props: ShowConnectorViewProps) {
  const {
    connector,
    currentTab,
    headerState,
    summaryTabState,
    logsTabState,
    manageTablesTabState,
    syncModalState,
    onSelectTab,
  } = props;

  const { showConnectorSyncingModal, ...restOfSyncModalState } = syncModalState;

  const tabs: TabKeyType[] = [
    'summary',
    'logs',
    { key: 'manage_tables', label: 'Manage Tables' },
    { key: 'user_actions', label: 'User Actions' },
  ];

  return (
    <HeaderTabLayout
      header={<ConnectorHeader {...headerState} />}
      tabs={tabs}
      currentTab={currentTab}
      onSelectTab={onSelectTab}
    >
      {/* Only render the current tab. */}
      {currentTab === 'summary' && <SummaryTab {...summaryTabState} />}
      {currentTab === 'logs' && <LogsTab {...logsTabState} />}
      {currentTab === 'manage_tables' && <ManageTablesTab {...manageTablesTabState} />}
      {currentTab === 'user_actions' && <UserActionsTab connector={connector} />}
      {showConnectorSyncingModal && (
        <ConnectorSyncingModal connector={connector} currentTab={currentTab} {...restOfSyncModalState} />
      )}
    </HeaderTabLayout>
  );
}
