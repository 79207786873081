import IconButton from 'components/inputs/basic/Button/IconButton';
import SchemaIcon, { SchemaIconType } from 'components/primitives/icons/SchemaIcon/SchemaIcon';

interface AIAssistantTablePickerPickedItemProps {
  name: string;
  iconType?: SchemaIconType;
  onUnpickItem: () => void;
}

export default function AIAssistantTablePickerPickedItem(props: AIAssistantTablePickerPickedItemProps) {
  const { name, iconType, onUnpickItem } = props;
  return (
    <div className="bg-pri-gray-100 text-sm text-pri-gray-900 h-9 w-auto inline-flex flex-row justify-start items-center p-2 rounded">
      {iconType && (
        <div className="mr-2">
          <SchemaIcon type={iconType} size={20} />
        </div>
      )}
      {name}
      <IconButton
        icon="X"
        size="small"
        iconSize={16}
        variant="lightDullTab"
        className="!h-5 !p-0 ml-4"
        onClick={onUnpickItem}
      />
    </div>
  );
}
