import React from 'react';

import { UserProfile } from 'api/APITypes';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

import DataAlertModeInfoIcon from './DataAlertModeInfoIcon';

interface DataAlertTableHeaderProps {
  userProfile: UserProfile;
  isOverallAlertsPage: boolean;
  isTransformOnTablePage: boolean;
}

const DataAlertTableHeader = (props: DataAlertTableHeaderProps) => {
  const {
    userProfile: { company_role },
    isOverallAlertsPage,
    isTransformOnTablePage,
  } = props;

  return (
    <thead>
      <tr className="whitespace-nowrap">
        {isOverallAlertsPage && <th className="w-4"></th>}
        <th className="w-[18%]">Name</th>
        <th className="w-[32%]">Alert SQL</th>
        <th className="w-[14%]">Last Action</th>
        <th>Last Results</th>
        {(isOverallAlertsPage || isTransformOnTablePage) && (
          <th className="w-[200px]">
            <div className="f-row-y-center">
              <span>Action on Results</span>
              <span className="ml-1">
                <DataAlertModeInfoIcon />
              </span>
            </div>
          </th>
        )}
        <th className="text-center">Enable</th>
        <th>
          <div className="f-center">
            <span>Attach CSV</span>
            <span className="ml-1">
              <InfoIcon
                content={
                  <div className="whitespace-normal text-left">
                    Include any rows that match the Alert's SQL as an attachment to Alert Notifications
                    sent to Slack or email. Please consider whether the data is OK to share outside of
                    Mozart.
                  </div>
                }
                popoverProps={{ style: { maxWidth: '550px', fontSize: '1rem' } }}
                placement="left"
              />
            </span>
          </div>
        </th>
        {company_role !== 'viewer' && <th className="text-center"></th>}
      </tr>
    </thead>
  );
};

export default DataAlertTableHeader;
