import React from 'react';

import { AggTable, QueryRunResults } from 'api/APITypes';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import RunResults from 'components/query/RunResults/RunResults';
import { DataAlertWithTable } from 'hooks/useDataAlerts';

interface FailureLogsModalProps {
  loadingFailureLogs: boolean;
  failureLogs: QueryRunResults | null;
  failureLogsErrorLines: string[];
  failureLogsDataAlert: DataAlertWithTable | null;
  table?: AggTable;
  onCloseFailureLogsModal: () => void;
}

export default function FailureLogsModal(props: FailureLogsModalProps) {
  const {
    loadingFailureLogs,
    failureLogs,
    failureLogsErrorLines,
    failureLogsDataAlert,
    table,
    onCloseFailureLogsModal,
  } = props;
  const showFailureLogsModal = !!(
    (loadingFailureLogs || failureLogs || failureLogsErrorLines) &&
    failureLogsDataAlert &&
    table
  );
  if (!showFailureLogsModal) {
    return null;
  } else {
    const failureLogsModalHeader = (
      <div className="flex text-xl">
        <span className="font-medium">
          {table.full_name} - {failureLogsDataAlert.name}{' '}
        </span>
        <span className="ml-2 text-pri-gray-400">(Limit 2000 Rows)</span>
      </div>
    );
    return (
      <Modal
        header={failureLogsModalHeader}
        onClose={onCloseFailureLogsModal}
        fullscreen={true}
        cancelButton={true}
      >
        <div className="h-full w-full p-4 overflow-hidden">
          {loadingFailureLogs && <CenteredSpinner spinnerSize="126px" containerClass="my-4" />}
          <RunResults
            loading={loadingFailureLogs}
            lastRunSql={failureLogsDataAlert.sql}
            runErrorLines={failureLogsErrorLines}
            runResults={failureLogs}
            timeFormat={'TIMESTAMP'}
            maxLines={1} // This is not the max result rows, rather than max lines displayed in a cell
            hideEmptyColumns={false}
            selectedColumns={[]}
            hideUnselectedColumns={false}
            isShown={true}
            editMode={false}
            onToggleColumn={(c) => {}}
            onToggleAllColumns={() => {}}
            setEditorSql={() => {}}
          />
        </div>
      </Modal>
    );
  }
}
