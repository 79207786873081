/*
 * Reusable Layout for pages that need to take up the entire browser window but not have their
 * content go below the page fold. Notable examples of this layout are the Warehouse and Query pages.
 * It is assumed that the contents of these layouts is scrollable.
 */
import { HTMLAttributes } from 'react';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';

export interface WorkspaceLayoutProps extends HTMLAttributes<HTMLDivElement> {
  basePath: string;
  spinning?: boolean;
}

export default function WorkspaceLayout(props: WorkspaceLayoutProps) {
  const { basePath, spinning, children, style, ...rest } = props;

  // Expand to all of the space
  const className = 'w-full h-full flex flex-col flex-grow';

  if (spinning) {
    return <CenteredSpinner containerMinHeight="100%" containerClass={className} />;
  }

  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
}
