import { useEffect } from 'react';

import cytoscape from 'cytoscape';
import elk from 'cytoscape-elk';
import nodeHtmlLabel from 'cytoscape-node-html-label';

let areExtensionsSet = false;

/** Register Cytoscape extensions globally */
const useCytoscapeSetup = () => {
  useEffect(() => {
    if (!areExtensionsSet) {
      cytoscape.use(elk);
      nodeHtmlLabel(cytoscape);
      areExtensionsSet = true;
    }
  }, []);

  return null;
};

export default useCytoscapeSetup;
