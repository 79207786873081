import cn from 'classnames';

import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import TabRow from 'components/layouts/parts/TabRow/TabRow';

import CheckableNumberStep from './CheckableNumberStep/CheckableNumberStep';
import SetupMethodInstructions, {
  SetupMethodInstructionsProps,
} from './SetupMethodInstructions/SetupMethodInstructions';

export interface GuideStepProps {
  number: number;
  label: string;
  checked: boolean;
  open: boolean;
  currentTab: string;
  detail: string;
  instructions: SetupMethodInstructionsProps[];
  toggleOpen(): void;
  setCurrentTab(tab: string): void;
  toggleComplete(): void;
}

export default function GuideStep(props: GuideStepProps) {
  const {
    number,
    label,
    checked,
    open,
    currentTab,
    detail,
    instructions,
    toggleOpen,
    setCurrentTab,
    toggleComplete,
  } = props;
  const tabs = instructions.map((i) => i.label);

  return (
    <CheckableNumberStep
      number={number}
      checked={checked}
      label={label}
      isOpen={open}
      onClick={toggleOpen}
      className={cn('border-b-[1px] border-white', { 'h-full shrink grow-1 overflow-hidden': open })}
    >
      <div className="w-full h-full overflow-y-auto">
        <div className="flex flex-col px-4 mb-4">
          <div className="mb-4 p-2 flex-none bg-pri-gray-50 text-sm text-pri-gray-500 rounded">
            {detail}
          </div>

          {tabs.length > 1 && (
            <TabRow
              tabs={tabs}
              selectedTab={currentTab}
              onClick={setCurrentTab}
              variant="bgGray"
              className="mb-4"
            />
          )}
          {instructions.map((i) =>
            i.label.key === currentTab ? <SetupMethodInstructions key={label} {...i} /> : null,
          )}
          <Checkbox
            name="complete"
            checked={checked}
            variant="blue_gray"
            label="Mark As Complete"
            containerClass="relative left-[-4px] mt-4"
            onChange={toggleComplete}
          />
        </div>
      </div>
    </CheckableNumberStep>
  );
}
