/*
  Controls tasks relevant to SQLEditor in the larger QueryEditor.
  
  Responsible for:
  1. Setting and getting sql and selected sql in the SQLEditor.
  2. Any other state relevant to the SQL window.
  3. Opertations that involve mutating the SQLEditor's sql.
  4. Creating run methods that utilize the SQLEditor's content.
*/
import { useState, useCallback, useContext, useMemo, useRef, useEffect } from 'react';

import deepEqual from 'fast-deep-equal';
import { cloneDeep, debounce } from 'lodash';

import { AggTable, QueryRunResults } from 'api/APITypes';
import { SqlEditorTools } from 'components/query/SqlEditor';
import { useDatabaseAccount } from 'context/AuthContext';
import { SearchColumnContext } from 'model_layer/SearchColumnContext';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { sanitizedTablesFromQuery } from 'utils/jsSqlParser';

import { QueryRunnerState, RunProps } from './useQueryRunner';

export interface SelectedSql {
  sql: string; // The selected string
  line: number; // First row is 1
  column: number; // First column is 0
  setByEditor: boolean; // true = set by typing in the editor. false = set by a component outside the editor.
}

export const DEFAULT_SELECTED_SQL = {
  sql: '',
  line: 1,
  column: 0,
  setByEditor: false,
};

export interface EditorRunProps extends RunProps {
  editorSql: string;
  selectedSql: SelectedSql;
}

export interface SqlEditorHookProps {
  queryRunnerState: QueryRunnerState;
  eventPrefix: string;
  initialSql?: string;
  savedQueryId?: string;
  onChangeSql?: (savedQueryId: string, sql: string) => void;
  onRefetchSavedQuery?: (savedQueryId: string) => void; // This refreshed data on a single savedQuery, to call after handleRun
  errorOnDuplicateColumns?: boolean;
}

export interface SqlEditorState {
  editorSql: string;
  selectedSql: SelectedSql;
  userHasTypedSql: boolean;
  tablesNamesUsedInSql: string[];
  editorTools: SqlEditorTools;
  sanitizedRunErrorLines: string[];
  getLatestEditorSql: () => string;
  setEditorSql: (sql: string, byUser: boolean) => void;
  setSelectedSql: (selectedSql: SelectedSql) => void;
  onInsertAtCursor: (text: string) => void;
  onRun: (incremental: boolean, recordEvent: boolean) => Promise<EditorRunProps>;
  setSqlAndRun: (sql: string) => Promise<EditorRunProps>;
  setSqlAndSimulateRun: (sql: string, runResults: QueryRunResults | null) => EditorRunProps;
  appendAndRunQuery: (query: string) => Promise<EditorRunProps>;
  onInsertTableQuery: (table: AggTable) => Promise<EditorRunProps>;
  onInsertColumnQuery: (fullName: string, column: string) => Promise<EditorRunProps>;
}

export default function useSqlEditor(props: SqlEditorHookProps): SqlEditorState {
  // ATTENTION: ATYPICAL BEHAVIOUR HERE:
  // `editorSql` and things derived from it are stored as Refs because
  // we do not want to define a new version of handleRun() every time the user types a letter.
  // This will trigger a render of expensive components, mainly TableExplorer.
  // useQueryEditor() renders every time the user types via setEditorSql() in order to update
  // SqlEditor and RunRow; however, great care must be taken to only render RunResults and
  // and TableExplorer when their memoized props need updating because both of these components
  // are very expensive to render.
  const {
    queryRunnerState,
    eventPrefix,
    initialSql,
    savedQueryId,
    onChangeSql,
    onRefetchSavedQuery,
    errorOnDuplicateColumns,
  } = props;

  const editorSqlRef = useRef<string>(initialSql || ''); // The entire editors contents
  const selectedSqlRef = useRef<SelectedSql>({ ...DEFAULT_SELECTED_SQL });
  const lastRunSelectedSqlRef = useRef<SelectedSql>({ ...DEFAULT_SELECTED_SQL });
  const tablesNamesUsedInSqlRef = useRef<string[]>([]);

  // Reimplement React.forceUpdate() so we can force an update after a Ref is updated.
  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  // Normal State Hooks:
  const [userHasTypedSql, setUserHasTypedSql] = useState<boolean>(false);

  // Query Running State:
  const { runErrorLines, run, simulateRun } = queryRunnerState;

  // Context State
  const tableModelContext = useContext(TableModelsContext);
  const searchColumnContext = useContext(SearchColumnContext);
  const databaseType = useDatabaseAccount().type;

  // SqlEditor sets these when it mounts
  const editorTools: SqlEditorTools = useMemo(() => {
    return {
      insertAtCursor: (str: string) => {},
      focus: () => {},
    };
  }, []);

  const updateTablesUsedInSql = useMemo(() => {
    return debounce(
      (sql: string) => {
        const stringsThatLookLikeTableNames = sanitizedTablesFromQuery(sql, databaseType);
        // Only update TableExplorer if the matched pattern is actually the name of a real table and
        // not just a string that looks like a table name.
        // This is a perfomance optimization to keep TableExplorer from rerendering as the user types.
        let tableNamesThatExist = stringsThatLookLikeTableNames.filter((tableName) => {
          return tableModelContext.tablesByFullName[tableName];
        });
        // Hacky race condition bug fix:
        // If the /query page reads a table list out of the URL or localStorage on first load
        // just accept the list because this code will get called before tables is loaded.
        // TableExplorerReducer also checks that names are valid so this won't cause a bug in TableExplorer.
        if (tableModelContext.tables.length === 0) {
          tableNamesThatExist = stringsThatLookLikeTableNames;
        }
        if (!deepEqual(tablesNamesUsedInSqlRef.current, tableNamesThatExist)) {
          tablesNamesUsedInSqlRef.current = tableNamesThatExist;
          forceUpdate();
        }
      },
      250,
      { leading: true },
    );
  }, [forceUpdate, tableModelContext.tablesByFullName, tableModelContext.tables.length, databaseType]);

  // When the list of tables changes, update the tables used in the sql.
  useEffect(() => {
    updateTablesUsedInSql(editorSqlRef.current);
  }, [updateTablesUsedInSql]);

  // Return the exact contents of the SQLEditor.
  // `editorSql` may be lagging due to render cycles.
  const getLatestEditorSql = useCallback(() => {
    return editorSqlRef.current;
  }, []);

  const setEditorSql = useCallback(
    (sql: string, byUser: boolean) => {
      editorSqlRef.current = sql;
      updateTablesUsedInSql(sql);
      setUserHasTypedSql(byUser);
      if (savedQueryId && onChangeSql) {
        // If this is for a savedQuery, update the savedQuery info
        onChangeSql(savedQueryId, sql);
      }
      // Force SqlEditor and RunRow to update every time the editorSql changes.
      // This will update TableExplorer on the rare occation tablesNamesUsedInSql also changes.
      // This should not update RunResults.
      forceUpdate();
    },
    [updateTablesUsedInSql, savedQueryId, forceUpdate, onChangeSql],
  );

  const setSelectedSql = useCallback(
    (selectedSql: SelectedSql) => {
      // Do not start an rerender cycle if the selectedSql is the same.
      if (!deepEqual(selectedSql, selectedSqlRef.current)) {
        selectedSqlRef.current = selectedSql;
        // Force SqlEditor and RunRow to update every time the selectedSql changes.
        // This should not update TableExplorer or RunResults.
        forceUpdate();
      }
    },
    [forceUpdate],
  );

  const handleInsertAtCursor = useCallback(
    (text: string) => {
      editorTools.insertAtCursor(text);
    },
    [editorTools],
  );

  const handleRun = useCallback(
    (incremental: boolean, recordEvent: boolean): Promise<EditorRunProps> => {
      // Save a copy of selectedSqlRef so we can sanitize line numbers later
      lastRunSelectedSqlRef.current = cloneDeep(selectedSqlRef.current);

      // Pick SQL to run
      const editorSql = editorSqlRef.current;
      const selectedSql = selectedSqlRef.current.sql;
      const useSelected = selectedSql !== '';
      const sqlToRun = useSelected ? selectedSql : editorSql;

      return (
        run(sqlToRun, incremental, recordEvent, savedQueryId, errorOnDuplicateColumns)
          .then((runProps) => {
            const editorRunProps: EditorRunProps = {
              ...runProps,
              editorSql,
              selectedSql: selectedSqlRef.current,
            };
            return editorRunProps;
          })
          // TODO This finally should be moved to the QueryPage as it is not generic QueryEditor behavior
          .finally(() => {
            // If we have a savedQueryId, refresh it so we get the lastest last_run value
            if (savedQueryId && onRefetchSavedQuery) {
              onRefetchSavedQuery(savedQueryId);
            }
          })
      );
    },
    [run, savedQueryId, onRefetchSavedQuery, errorOnDuplicateColumns],
  );

  const setSqlAndRun = useCallback(
    (sql: string) => {
      setEditorSql(sql, true);
      setSelectedSql(DEFAULT_SELECTED_SQL);
      return handleRun(false, false);
    },
    [setEditorSql, setSelectedSql, handleRun],
  );

  const setSqlAndSimulateRun = useCallback(
    (sql: string, runResults: QueryRunResults | null) => {
      setEditorSql(sql, true);
      setSelectedSql(DEFAULT_SELECTED_SQL);
      simulateRun(sql, runResults);

      const simulatedRunProps: EditorRunProps = {
        editorSql: sql,
        selectedSql: { ...DEFAULT_SELECTED_SQL },
        ranSql: sql,
        results: runResults,
      };

      return simulatedRunProps;
    },
    [setEditorSql, setSelectedSql, simulateRun],
  );

  const appendAndRunQuery = useCallback(
    (query: string) => {
      if (editorSqlRef.current === '') {
        setEditorSql(query, true);
      } else {
        const beforeQuery = `${editorSqlRef.current}\n\n`;
        const newSql = beforeQuery + query;
        const appendedQueryStartLine = beforeQuery.split('\n').length;
        setEditorSql(newSql, true);
        const newSelectedSql = {
          sql: query,
          line: appendedQueryStartLine,
          column: 0,
          setByEditor: false,
        };
        setSelectedSql(newSelectedSql);
      }
      return handleRun(false, false);
    },
    [setEditorSql, setSelectedSql, handleRun],
  );

  const handleInsertTableQuery = useCallback(
    (table: AggTable) => {
      let query = `SELECT * FROM ${table.full_name}`;
      // if still loading, skip and use the default query
      if (!searchColumnContext.isLoading) {
        const columns = searchColumnContext.searchColumnsByTableID[table.id];
        // if can't find columns or we think there are none, use the default query
        if (columns && columns.length > 0) {
          const columnNames = columns.map((c) => c.name);
          query = `SELECT\n  ${columnNames.join(',\n  ')}\nFROM ${table.full_name}`;
        }
      }
      return appendAndRunQuery(query);
    },
    // don't change the query if column load completes in the middle of it
    [appendAndRunQuery], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleInsertColumnQuery = useCallback(
    (fullName: string, column: string) => {
      const query = `SELECT ${column}, count(*) as count\nFROM ${fullName}\nGROUP BY ${column}\nORDER BY count DESC`;
      return appendAndRunQuery(query);
    },
    [appendAndRunQuery],
  );

  // SelectedSql is a construct of useQueryEditor, not useQueryRunner.
  // If we selected SQL instead of using the contents of the entire SqlEditor,
  // the line numbers might be wrong. Adjust them.
  const sanitizedRunErrorLines = useMemo(() => {
    if (runErrorLines.length > 0) {
      const selectedSql = lastRunSelectedSqlRef.current.sql;
      const useSelected = selectedSql !== '';
      const newRunErrorLines = useSelected
        ? sanitizeErrorLineNumber(
            runErrorLines,
            lastRunSelectedSqlRef.current.line,
            lastRunSelectedSqlRef.current.column,
          )
        : runErrorLines;
      // We cannot enumerate the countless types of SQL syntax errors users might create.
      // Log them, so we can program the `SnowflakeLinter` to highlight the most common ones.
      // These errors seem to be two lines.
      let firstCode = null;
      let secondCode = null;
      const firstLine = newRunErrorLines[0] || null;
      const secondLine = newRunErrorLines[1] || null;
      if (firstLine) {
        const codeMatches = firstLine.match(/(.{1,7}) \((.{1,7})\):/i);
        if (codeMatches) {
          firstCode = codeMatches[1];
          secondCode = codeMatches[2];
        }
      }
      analytics.track(`${eventPrefix} LogSqlSyntaxError`, {
        firstCode,
        secondCode,
        firstLine, // The first is a generic error message.
        secondLine, // The second contains the part of the code that is triggering the error.
      });

      return newRunErrorLines;
    } else {
      return [];
    }
  }, [runErrorLines, eventPrefix]);

  return {
    editorSql: editorSqlRef.current,
    selectedSql: selectedSqlRef.current,
    userHasTypedSql,
    tablesNamesUsedInSql: tablesNamesUsedInSqlRef.current,
    sanitizedRunErrorLines,
    editorTools,
    getLatestEditorSql,
    setEditorSql,
    setSelectedSql,
    onInsertAtCursor: handleInsertAtCursor,
    onInsertTableQuery: handleInsertTableQuery,
    onInsertColumnQuery: handleInsertColumnQuery,
    onRun: handleRun,
    setSqlAndRun,
    setSqlAndSimulateRun,
    appendAndRunQuery,
  };
}

// If we run SQL on selected text,
// the line and column numbers are wrong because the API
// returns numbers relative to the selected text,
// not the entire docuement.
// Convert numbers to be relative to entire document.
const sanitizeErrorLineNumber = (
  errorLines: string[],
  selectedSqlLine: number,
  selectedSqlColumn: number,
) => {
  const safeLines = errorLines.map((l) => {
    const lineNumberMatches = l.match(/line (\d+) at position (\d+)/);
    if (lineNumberMatches) {
      let lineNumber = Number(lineNumberMatches[1]);
      let columnNumber = Number(lineNumberMatches[2]);
      lineNumber = selectedSqlLine + lineNumber - 1;
      columnNumber = selectedSqlColumn + columnNumber;
      l = l.replace(lineNumberMatches[0], `line ${lineNumber} at position ${columnNumber}`);
    }
    return l;
  });
  return safeLines;
};
