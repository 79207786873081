/**
 * The API returns a SavedFlowchartQueryModel that has object IDs instead of fully
 * hydrated objects. This file convert a SavedFlowchartQueryModel into a normal
 * FlowchartQueryModel the UI can use.
 */
import { useEffect, useRef, useState } from 'react';

import deepEqual from 'fast-deep-equal';
import { cloneDeep } from 'lodash';

import { ColumnsByTableID } from 'api/columnAPI';
import useMemoObject from 'hooks/useMemoObject';

import { FlowchartQueryModel, getNewFlowchart } from './FlowchartEditor/model/FlowchartQueryModel';
import { savedModelToFlowchartModel } from './FlowchartEditor/model/flowchartQueryModelConverter';
import { SavedFlowchartQueryModel } from './FlowchartEditor/model/SavedFlowchartQueryModel';
import { FlowchartAPILoaderState } from './useFlowchartAPILoader';

export interface FlowchartModelConverterState {
  savedFQM: FlowchartQueryModel;
  columnsByTableID: ColumnsByTableID;
  objectsForSavedModelLoaded: boolean;
}

export type FlowchartModelConverterProps = Omit<FlowchartAPILoaderState, 'fetchColumns'>;

const useFlowchartModelConverter = (
  props: FlowchartModelConverterProps,
): FlowchartModelConverterState => {
  const { savedFlowchartModel, tablesByID, columnsByTableID, objectsForSavedModelLoaded } = props;

  // The FlowchartQueryModel at the last save checkpoint
  const [savedFQM, setSavedFQM] = useState<FlowchartQueryModel>(getNewFlowchart());

  // The last SavedFlowcharQueryModel we converted.
  // We want to be very careful we don't replay this conversion and overwrite
  // the user's unsaved changes.
  const lastConvertedModelRef = useRef<SavedFlowchartQueryModel>(getNewFlowchart());

  // Convert from a SavedFlowchartQueryModel to a FlowchartQueryModel
  // once after the data layer has loaded.
  // This logic is suspect and might change, especially in the context of different edit environments(transform, query, alert)
  // because the user might lose their work in progress.
  // We only want to execute this conversion on objectsForSavedModelLoaded and when
  // a user takes a deliberate saving action.
  useEffect(
    () => {
      if (
        savedFlowchartModel &&
        objectsForSavedModelLoaded &&
        !deepEqual(savedFlowchartModel, lastConvertedModelRef.current)
      ) {
        const converted = savedModelToFlowchartModel(savedFlowchartModel, tablesByID, columnsByTableID);
        setSavedFQM(converted);
        lastConvertedModelRef.current = cloneDeep(converted);
      }
    },
    // `tablesByID` and `columnsByTableID` should be set before `objectsForSavedModelLoaded` is true.
    // We do not want to recompute `converted` if `tablesByID` and `columnsByTableID` change after
    // we have computed `converted`
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [savedFlowchartModel, objectsForSavedModelLoaded],
  );

  const convertedState = useMemoObject<FlowchartModelConverterState>({
    savedFQM,
    columnsByTableID,
    objectsForSavedModelLoaded,
  });

  return convertedState;
};

export default useFlowchartModelConverter;
