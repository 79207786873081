import { useEffect, useState } from 'react';

import API from 'api/API';
import { AppConnector } from 'api/APITypes';
import { ConnectorSync } from 'api/connectorsAPI';

export interface ConnectorSyncsState {
  systemAlert: string | null;
  connectorSyncs: ConnectorSync[];
  loading: boolean;
  error: string;
}

export default function useConnectorSyncsState(connector: AppConnector): ConnectorSyncsState {
  const [systemAlert, setSystemAlert] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [connectorSyncs, setConnectorSyncs] = useState<ConnectorSync[]>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    setError('');
    const api = new API();
    api
      .get(`api/connector_syncs?connector=${connector.id}`)
      .then((response) => {
        // NOTE: We could use `count` and `next` keys to get more syncs, but to start we can just show the first page
        const syncs = (response.data.results as ConnectorSync[]).filter(
          (sync) => sync.status !== 'PENDING' && sync.start_timestamp && sync.end_timestamp,
        );
        setConnectorSyncs(syncs);
        const lastSync: ConnectorSync | undefined = syncs[0];
        if (!lastSync) {
          setSystemAlert(null);
        } else if (
          (lastSync.status === 'FAILURE' || lastSync.status === 'FAILURE_WITH_TASK') &&
          lastSync.reason
        ) {
          setSystemAlert(lastSync.reason);
        } else {
          setSystemAlert(null);
        }
      })
      .catch(() => {
        setError('Failed to load connector syncs');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [connector]);

  return { connectorSyncs, systemAlert, loading, error };
}
