/*
  DBT Commands form field wrapped in Formik hook props
*/
import React, { useMemo } from 'react';

import { ArrayHelpers, FieldArray, useField } from 'formik';

import IconButton from 'components/inputs/basic/Button/IconButton';
import TextFormikGroup, {
  SanitizedTextInputGroupProps,
} from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import DragAndDropList, { SortableListItem } from 'components/widgets/DragAndDropList/DragAndDropList';

export interface CommandsFormikGroupProps extends SanitizedTextInputGroupProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export default function CommandsFormikGroup(props: CommandsFormikGroupProps) {
  const { name, label, groupClass, setFieldValue, ...rest } = props;
  const [field, meta] = useField(name);
  const { name: fieldName, value: fieldValue, ...fieldRest } = field;

  /**
   * Updates form value with new re-ordered list
   * @param newDataList Updated data list of sortable items
   */
  const updateValue = (newDataList: SortableListItem[]) => {
    const newValues = newDataList.map((data) => data.value);
    setFieldValue(fieldName, newValues);
  };

  const dataList = useMemo(
    () =>
      fieldValue.map((value: string, index: number) => {
        return {
          id: `${fieldName}.${index}`,
          name: `${fieldName}.${index}`,
          label: `DBT Command ${index + 1}`,
          value: value,
          index: index,
        };
      }),
    [fieldValue, fieldName],
  );

  return (
    <FieldArray
      name={fieldName}
      error={meta.touched && meta.error ? meta.error : ''}
      {...fieldRest}
      {...rest}
    >
      {(arrayHelpers: ArrayHelpers) => (
        <fieldset className={groupClass}>
          <legend className="mb-1 block text-input-label">{label}</legend>
          <DragAndDropList
            dataList={dataList}
            gripButtonClass="self-start h-[42px]"
            setDataList={updateValue}
            renderListItem={(data: SortableListItem) => {
              return (
                <div className="f-row-y-center">
                  <div className="flex-auto">
                    <TextFormikGroup name={data.id} aria-label={data.label} />
                  </div>
                  {fieldValue.length > 1 ? (
                    <IconButton
                      icon="XLg"
                      text={`Remove ${data.label}`}
                      textSrOnly
                      onClick={() => {
                        arrayHelpers.remove(data.index);
                      }}
                      variant="lightDullTransparent"
                      size="field"
                      className="ml-2 flex-none self-start"
                    />
                  ) : null}
                </div>
              );
            }}
          />
          <IconButton
            icon="Plus"
            text="Add command"
            onClick={() => arrayHelpers.push('')}
            variant="lightDullTransparent"
            size="form"
            className="flex"
          />
        </fieldset>
      )}
    </FieldArray>
  );
}
