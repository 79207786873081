import { CompanyPlanRow } from 'pages/UsageV2/useUsageFetch';

import { AvailableLayoutWidgetsType } from '../widgets/LayoutWidgets';

import { ComponentFlags, LayoutModelType } from './LayoutModel';
import LayoutRules from './LayoutRules';

export interface UsageState {
  currentInvoice: CompanyPlanRow | undefined;
  companyPlanByMonth: CompanyPlanRow[];
}

// Given a rule, condition, and state, determine if the rule condition is met
// by executing a rule from the layout rules
const isActive = (model: LayoutModelType, state: UsageState): Boolean => {
  if (model.operator === 'and') {
    return model.conditions.every((c) => Boolean(LayoutRules[c.condition]?.(c, state)));
  } else {
    return model.conditions.some((c) => Boolean(LayoutRules[c.condition]?.(c, state)));
  }
};

/* 
 Determines which component to show
*/
const activateFeatures = ({
  featureFlags,
  featureSet,
  state,
}: {
  featureFlags: ComponentFlags;
  featureSet: AvailableLayoutWidgetsType;
  state: UsageState;
}): AvailableLayoutWidgetsType => {
  const includedFeatures = Object.keys(featureFlags);
  let resp: any = {};
  Object.entries(featureSet).forEach((i) => {
    const [featureName, featureComponent] = i;
    if (includedFeatures.includes(featureName)) {
      // If the model includes the component, define it with the correct arguments
      resp[featureName] = featureComponent({ ...state, mode: featureFlags[featureName].mode });
    } else {
      // If the model doesn't include it, we still need it in the output, but defined as null so if doesn't show up
      resp[featureName] = null;
    }
  });
  return resp as AvailableLayoutWidgetsType;
};

/* Given our state, features, and rules return a layout 
with our activated components to be rendered to the page */
export const useLayoutRules = ({
  state,
  features,
  models,
}: {
  models: LayoutModelType[];
  features: AvailableLayoutWidgetsType;
  state: UsageState;
}) => {
  const activeModels = models.filter((model) => isActive(model, state));
  // We pick the first active model
  let activeModel;
  if (activeModels.length === 0) {
    // We have no active models, show the Sonata with no billing data model
    activeModel = models[0];
  } else {
    // Otherwise, show the first active model (This shouldn't happen, but including to be defensive)
    activeModel = activeModels[0];
  }
  return activateFeatures({
    featureFlags: activeModel.result,
    featureSet: features,
    state: state,
  });
};
