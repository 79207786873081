/*
_.debounce() in hook format.
*/
import { useEffect, useRef } from 'react';

import { debounce, DebouncedFunc, DebounceSettings } from 'lodash';

export default function useDebounce<T extends (...args: any) => any>(
  func: T,
  wait?: number,
  options?: DebounceSettings,
) {
  const funcRef = useRef<DebouncedFunc<T>>(debounce(() => {}, 0));

  useEffect(() => {
    // console.log(`useDebounce - ${Math.floor(performance.now())} - INIT `, wait)
    funcRef.current = debounce(func, wait, options);
    return () => {
      funcRef.current.cancel();
    };
  }, [func, wait, options]);

  return funcRef.current;
}
