import React from 'react';

import { Connector } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import { conName } from 'pages/connectors/ConnectorRegistry';

export interface PickTablesBeforeSyncingModalProps {
  connector: Connector;
  onCancel(): void;
  onGotIt(): void;
}

export default function PickTablesBeforeSyncingModal(props: PickTablesBeforeSyncingModalProps) {
  const { connector, onCancel, onGotIt } = props;
  const { service } = connector;

  const modalFooter = (
    <div className="flex justify-end">
      <Button variant="lightAction" onClick={onGotIt}>
        Got It
      </Button>
    </div>
  );

  return (
    <Modal
      onClose={onCancel}
      header={`Pick tables before syncing`}
      footer={modalFooter}
      containerClass="max-w-[450px]"
    >
      <div className="p-4">
        {`${conName(
          service,
        )} connectors start paused so that you may pick the tables you want to sync before syncing starts.`}
      </div>
    </Modal>
  );
}
