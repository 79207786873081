import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { AggTable } from 'model_layer/TableModelsContext';

export type CreateAlertSource = 'HomepageStatusWidget' | 'DataAlertsPage' | 'MoreActionsButton';

const useCreateDataAlerts = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState<AggTable | null>(null);

  const history = useHistory();

  const handleOpen = (eventLocation: CreateAlertSource) => {
    analytics.track(`${eventLocation} OpenCreateAlertModal`);
    setIsCreateModalOpen(true);
  };

  const handleClose = (eventLocation: CreateAlertSource) => {
    analytics.track(`${eventLocation} CloseCreateAlertModal`);
    setIsCreateModalOpen(false);
    setSelectedTable(null);
  };

  const handleSubmit = (table: AggTable, eventLocation: CreateAlertSource) => {
    analytics.track(`${eventLocation} SelectCreateAlertModal`, {
      table_id: table.id,
      table_name: table.full_name,
    });
    setIsCreateModalOpen(false);

    history.push(`/tables/${table.id}/alerts`, { createAlert: true });
  };

  return {
    isCreateModalOpen,
    selectedTableCreateAlertModal: selectedTable,
    onOpenCreateAlertModal: handleOpen,
    onCloseCreateAlertModal: handleClose,
    setSelectedTableCreateAlertModal: setSelectedTable,
    onSubmitCreateAlertModal: handleSubmit,
  };
};

export default useCreateDataAlerts;
