import React from 'react';

import HeaderLayout from '../HeaderLayout/HeaderLayout';

interface UnauthHeaderProps {}

const UnauthHeader = (props: UnauthHeaderProps) => {
  return (
    <HeaderLayout
      logoPath="/signup"
      logoDataTrack="Header LogoSignup"
      left={null}
      right={null}
      banner={null}
    />
  );
};

export default UnauthHeader;
