import React, { useEffect, useState } from 'react';

import { RouteComponentProps, useHistory } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import { groupBy } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';

import { AppConnector, Connector } from 'api/APITypes';
import { deleteCaches } from 'api/CacheKeys';
import { useGetDashboardsAvailable, useGetDashboardsInstalled } from 'api/dashboardAPI';
import Button from 'components/inputs/basic/Button/Button';
// For testing hack below:
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import { useUserProfile } from 'context/AuthContext';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import { ConnectorStatus, getConnectorStatus } from 'model_helpers/connectorHelper';
import { connectorPath } from 'utils/PathMaker';

import { conName } from '../ConnectorRegistry';
import { usePollConnectors } from '../ConnectorUtils';
import { previewReportPath } from '../ReportBuilder/pathUtils';

import ConnectorRow from './ConnectorRow';
import ConnectorRowGroup from './ConnectorRowGroup';
import ListConnectorFilters, { Filters } from './ListConnectorFilters';

interface MatchParams {}

interface ListConnectorsProps extends RouteComponentProps<MatchParams> {}

export default function ListConnectors(props: ListConnectorsProps) {
  useTitle('Connectors');
  const navigate = useNavigate();
  const location = useLocation();
  const { userProfile } = useUserProfile();
  const ootbEnabled = useBooleanFlag('ootb_connector_page', false);
  const history = useHistory();

  // Fetch connector list and then keep polling for updates.
  const {
    isLoading: connectorsLoading,
    error: connectorsLoadingError,
    connectors,
  } = usePollConnectors();

  // Fetch dashboards available.
  const { dashboardsAvailableLoading, dashboardsAvailableError, dashboardsAvailable } =
    useGetDashboardsAvailable();

  // Fetch dashboards installed.
  const { dashboardsInstalledLoading, dashboardsInstalledError, dashboardsInstalledByConnectorID } =
    useGetDashboardsInstalled();

  const pageLoading = connectorsLoading || dashboardsAvailableLoading || dashboardsInstalledLoading;
  const pageLoadingError =
    connectorsLoadingError || dashboardsAvailableError || dashboardsInstalledError;

  // When a user completes a connector card, Fivetran forwards the user's browser to:
  // /connectors?id=FIVETRAN_CONNECTOR_ID
  // Record the FIVETRAN_CONNECTOR_ID here.
  // Mozart buttons and links also use this trick to redirect to a selected connector in some work flows.
  // So the `from_fivetran` tells if the user was forwarded from us or Fiveran.
  const searchParams = queryString.parse(location.search);
  const [urlSelectedFivetranId] = useState(searchParams.id as string);
  const [fromFivetran] = useState(searchParams.from_fivetran === 'true');

  // 02/02/24:
  // Legacy Notifications and perhaps other things have URLs of the form /connectors?id=FIVETRAN_CONNECTOR_ID
  // Redirect them to the correct URL.
  useEffect(() => {
    if (urlSelectedFivetranId && !connectorsLoading) {
      const connector = connectors.find((c) => c.fivetran_connector_id === urlSelectedFivetranId);
      if (connector) {
        history.push(connectorPath(connector.id));
      } else {
        // If the connector can't be found we just show the user the ListConnectors page.
      }
    }
  }, [urlSelectedFivetranId, connectorsLoading, connectors, history]);

  // If it's a brand new connector that has an OOTB Dashboard, forward to Dashboard setup.
  useEffect(() => {
    if (
      ootbEnabled &&
      urlSelectedFivetranId &&
      fromFivetran &&
      dashboardsAvailableLoading === false &&
      connectorsLoading === false
    ) {
      const connector = connectors.find((c) => c.fivetran_connector_id === urlSelectedFivetranId);
      if (connector) {
        const dashboard = dashboardsAvailable[connector.service];
        if (dashboard) {
          navigate(previewReportPath(connector));
        }
      }
    }
  }, [
    ootbEnabled,
    urlSelectedFivetranId,
    fromFivetran,
    dashboardsAvailableLoading,
    dashboardsAvailable,
    connectorsLoading,
    connectors,
    navigate,
  ]);

  // Filters
  const [filters, setFilters] = useState<Filters>({
    search: (searchParams.search as string) ?? '',
    status: (searchParams.status as ConnectorStatus) ?? 'all',
  });
  const handleFilterChange = (name: string, value: string) => {
    setFilters({ ...filters, [name]: value.toLowerCase() });
  };
  // For search only

  // You just added a new connector,
  // blow away the warehouse and TableList caches
  if (urlSelectedFivetranId) {
    deleteCaches();
  }

  sortAndSetConnectors(connectors, urlSelectedFivetranId);

  // Hack to test last sync failed UI:
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_HACK_DATA) {
    let f = connectors[4];
    if (f) {
      f.failed_at = moment(new Date()).toISOString();
    }
    f = connectors[6];
    if (f) {
      f.status.sync_state = 'rescheduled';
    }
  }

  const handleAddFirstConnector = () => {
    analytics.track('ListConnectors AddFirst');
    navigate('/connectors/add');
  };

  const handleAddConnector = () => {
    analytics.track('ListConnectors Add');
    navigate('/connectors/add');
  };

  const handleUploadCSVNoConnectors = () => {
    analytics.track('ListConnectors UploadCSVNoConnectors');
    navigate('/csv/add');
  };

  const handleUploadCSV = () => {
    analytics.track('ListConnectors UploadCSV');
    navigate('/csv/add');
  };

  // Special UI to get user to add their first connector
  if (!pageLoading && connectors.length === 0 && !pageLoadingError) {
    return (
      <CenteredLayout maxWidth="814px" loading={pageLoading} loadingError={pageLoadingError}>
        <div className="f-col-center ">
          <h2 className="display-sm">Add a connector to sync data to your warehouse</h2>
          <img
            src="/images/graphics/onboarding_popover_tour/connectors.svg"
            alt="step 1 add connector"
            className="mt-6 ml-4"
            style={{ width: '525px' }}
          />
          <div className="mt-8">
            <Button variant="lightTransparent" onClick={handleUploadCSVNoConnectors} className="mr-2">
              Upload CSV
            </Button>
            <Button onClick={handleAddFirstConnector}>Add Connector</Button>
          </div>
        </div>
      </CenteredLayout>
    );
  }

  const Header = (
    <div className="f-between pb-2 pt-1">
      <ListConnectorFilters values={filters} onChange={handleFilterChange} />
      <div>
        {userProfile.company_role !== 'viewer' && (
          <Button variant="lightTransparent" onClick={handleUploadCSV} className="mr-2">
            Upload CSV
          </Button>
        )}
        <Button onClick={handleAddConnector}>
          {userProfile.company_role === 'viewer' ? 'View Connectors' : 'Add Connector'}
        </Button>
      </div>
    </div>
  );

  let filteredConnectors = connectors;
  if (filters.search || filters.status !== 'all') {
    filteredConnectors = connectors
      .filter((c: AppConnector) => {
        const name = conName(c.service).toLowerCase();
        return c.schema.toLowerCase().includes(filters.search) || name.includes(filters.search);
      })
      .filter((c: AppConnector) => {
        const { setupState, lastRun, syncState } = c;
        const connectorStatus = getConnectorStatus(setupState, syncState, lastRun.lastRunFailed);
        return connectorStatus.toLowerCase() === filters.status || filters.status === 'all';
      });
  }
  const groupedConnectors = groupBy(filteredConnectors, (c: Connector) => c.service);

  /*
  NOTE ON TABLE WIDTH, 04/21/2023:
  It's possible but unlikely that a user might have a very long value in the schema column such as:
  `google_sheets.matt_testing_connector_syncing_to_warehouse`.
  This will cause the table with `table-layout:auto` to overflow the width of it's containing div.
  The page will just become wider to accomodate the wider table,
  but the alignment of the Add Connector button will look wonky to the extremely attentive eye
  because the button is aligned to the right edge of the div.
  This effect is made more probable by the addition of the Insight Composer column which takes considerable space.
  I've decided not to do anything to truncate the schema column because
  this scenario is very unlikely with a normal user's data,
  and I think you'd want to see the entire schema column in this case.
  */

  /*
  03/04/2024:
  Error: ResizeObserver loop completed with undelivered notifications.
  I have tried and failed to fix this bug for 3 days.
  It is relatively contained as it only interrupts user interaction in a DEV ENV,
  on this page, when the CompanySetupGuide sidebar is enabled.
  So I think, it's safe to ignore.

  What happens:
  1. The 1px borders on table cells cause browsers to do subpixel rendering which might result in a
     table that is 2000.5px tall even though all of the cells are integer heights. There is no
     way to prevent the subpixel rendering AND keep the existing borders as far as I know.
  2. Simply changing the borders to 2px stops the subpixel rendering but that looks bad.
  3. The fractional height causes the browser to throw the:
     Error: ResizeObserver loop completed with undelivered notifications.
  4. You can demonstrate the bug by:
     1. Run `csgContext.startGuide()` in your browser console.
     2. Uncommenting the `<div id="bug-demonstration-div">` below.
     3. Comment out the table.
     4. Refresh the page.
  */

  return (
    <CenteredLayout
      // This is the width at which a list with both setup incomplete and Report Builder does not get crushed.
      // This width will crush CompanySetupGuide. So try to keep it small.
      // It's tuned with a little bit of safety margin incase this list somehow gets a bit bigger.
      maxWidth="960px"
      header={Header}
      loading={pageLoading}
      loadingError={pageLoadingError}
    >
      {!pageLoading && !pageLoadingError && (
        <div>
          {filteredConnectors.length === 0 ? (
            <NoMatchesFoundAlert heading="No matching connectors." />
          ) : (
            // <div id="bug-demonstration-div" className="w-[500px] h-[2000.5px] bg-[yellow]">Demonstrate ResizeObserver Bug with fractional height.</div>
            <table className="lineTable">
              {/* Add bottom margin to make room for dot menu. */}
              <thead>
                <tr>
                  <th></th>
                  <th className="!pl-4">Schema</th>
                  <th className="whitespace-nowrap">Sync Frequency</th>
                  <th className="whitespace-nowrap">Last Sync</th>
                  <th className="w-[102px] whitespace-nowrap text-left">Sync Status</th>
                  {ootbEnabled && <th className=""></th>}
                </tr>
              </thead>
              <tbody>
                {/* 
                If there are many connectors of the same type, we group them. 
                If there are only a handful or a single connector of a given type, we list them individually.
                In practice, it's annoying to have collapsed connector groups on groups of 2-3 connectors.
                */}
                {Object.entries(groupedConnectors).map(([service, connectors]) => (
                  <React.Fragment key={service}>
                    {connectors.length > 3 ? (
                      <ConnectorRowGroup
                        key={service}
                        service={service}
                        connectors={connectors}
                        userProfile={userProfile}
                        showDashboardColumn={ootbEnabled}
                        dashboardAvailable={!!dashboardsAvailable[service]}
                        dashboardsInstalledByConnectorID={dashboardsInstalledByConnectorID}
                        urlSelectedFivetranId={urlSelectedFivetranId}
                      />
                    ) : (
                      <>
                        {connectors.map((c) => (
                          <ConnectorRow
                            key={c.fivetran_connector_id}
                            {...c}
                            selected={c.fivetran_connector_id === urlSelectedFivetranId}
                            userProfile={userProfile}
                            showDashboardColumn={ootbEnabled}
                            dashboardAvailable={!!dashboardsAvailable[c.service]}
                            hasDashboard={!!dashboardsInstalledByConnectorID[c.id]}
                          />
                        ))}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </CenteredLayout>
  );
}

const sortAndSetConnectors = (cons: Connector[], urlSelectedFivetranId: string) => {
  // Sort selectedConnector to start of list
  // Otherwise, sort alphabetically
  cons.sort((a: Connector, b: Connector) => {
    if (a.fivetran_connector_id === urlSelectedFivetranId) {
      return -1;
    } else if (b.fivetran_connector_id === urlSelectedFivetranId) {
      return 1;
    }

    return a.schema.toLowerCase() < b.schema.toLowerCase() ? -1 : 1;
  });
};
