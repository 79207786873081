import React, { useContext, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { AggTable } from 'api/APITypes';
import { useAuth, useUserProfile } from 'context/AuthContext';
import { useExperiments } from 'context/ExperimentContext';
import { CompanySetupGuideContext } from 'model_layer/CompanySetupGuideContext';
import { OnboardingPopoverTourContext } from 'model_layer/OnboardingPopoverTourContext';

import AuthHeaderView from './AuthHeaderView/AuthHeaderView';
import { HelpMenuProps } from './AuthHeaderView/HelpMenu/HelpMenu';
import { useBannerState } from './useBannerState';

export interface AuthHeaderControllerProps {
  currentPathObject: AggTable | null;
  setShowHotkeys: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthHeaderController = (props: AuthHeaderControllerProps) => {
  const { currentPathObject } = props;
  const { logout } = useAuth();
  const { userProfile } = useUserProfile();
  const location = useLocation();
  const { experiments } = useExperiments();
  const optContext = useContext(OnboardingPopoverTourContext);
  const csgContext = useContext(CompanySetupGuideContext);
  const bannerState = useBannerState();

  const helpMenuProps = useMemo(() => {
    const helpMenuProps: HelpMenuProps = {
      location,
      currentPathObject: currentPathObject,
      optContext,
      csgContext,
    };
    return helpMenuProps;
  }, [location, currentPathObject, csgContext, optContext]);

  return (
    <AuthHeaderView
      {...props}
      userProfile={userProfile}
      logout={logout}
      location={location}
      experiments={experiments}
      onboardingPopoverTourRefs={optContext.refs}
      bannerState={bannerState}
      helpMenuProps={helpMenuProps}
    />
  );
};

export default AuthHeaderController;
