import React, { useState } from 'react';

import cn from 'classnames';

import IconButton from 'components/inputs/basic/Button/IconButton';

import CopyButton from '../../basic/Button/CopyButton';

interface InputCopyProps {
  label?: string;
  value: string;
  isHiddenField?: boolean;
  multiline?: boolean;
  analyticsEvent?: string;
  containerClass?: string;
}

/**
 * Input with copy to clipboard functionality.
 * */
const InputCopy = ({
  label,
  value,
  isHiddenField,
  multiline,
  analyticsEvent,
  containerClass,
}: InputCopyProps) => {
  const [hidden, setHidden] = useState(true);

  const copyValue = () => {
    navigator.clipboard.writeText(value);
    if (analyticsEvent) {
      analytics.track(analyticsEvent);
    }
  };

  const toggleVisible = () => {
    setHidden(!hidden);
  };
  const displayValue = isHiddenField && hidden ? value.replace(/./g, '•') : value;

  const defaultClass = 'border-b border-pri-gray-200';
  const actualClass = cn(defaultClass, containerClass);

  return (
    <div className={actualClass}>
      {label && (
        <div className="h-[18px] mb-1 flex items-center">
          <label className="block text-purple text-xs font-medium tracking-[1px] uppercase">
            {label}
          </label>
          {isHiddenField && (
            <IconButton
              icon={hidden ? 'EyeSlashFill' : 'EyeFill'}
              variant="lightDullTransparent"
              size="small"
              onClick={toggleVisible}
            />
          )}
        </div>
      )}

      <div className="f-between">
        <div
          className={cn(
            'mr-2 text-pri-gray-500 w-[calc(100%-44px)]',
            multiline ? 'whitespace-pre-wrap' : 'whitespace-nowrap overflow-x-auto',
          )}
        >
          {displayValue}
        </div>
        <CopyButton onClick={copyValue} />
      </div>
    </div>
  );
};

export default InputCopy;
