/*
Wrapper for making API requests to our backend.

Example usage:

import API from './api/API';

const api = new API();

try {
    let response = await api.get('api/xyz/');
    console.log("got response: " + response);

    // Alternatively, if you need to convert the format of the response
    // you could run:
    let response = await api.get('api/transforms/1', converters.transform);
} catch(error) {
    this.setState({error: "There was a problem loading data."});
};

*/
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import url from 'url';

import { deleteLoginState, getLocalStorageToken } from './AuthStorage';

function getBaseUrl() {
  // Use REACT_APP_API_URL from command line
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }

  // Default to dev URL
  let baseUrl = `http://${window.location.hostname}:8000/`;

  // Use window.location if not in dev mode.
  if (process.env.NODE_ENV !== 'development') {
    baseUrl = url.format({
      protocol: window.location.protocol,
      host: window.location.host,
      pathname: '/',
    });
  }
  return baseUrl;
}

export type DataConverter = (data: any) => any;

type Headers = {
  Authorization?: string;
};

export default class API {
  instance: AxiosInstance;

  constructor(mergedConfig?: AxiosRequestConfig) {
    const baseUrl = getBaseUrl();

    const headers: Headers = {};
    const token = getLocalStorageToken();
    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    const baseConfig: AxiosRequestConfig = {
      baseURL: baseUrl,
      headers,
    };
    const actualConfig = { ...baseConfig, ...mergedConfig };

    this.instance = axios.create(actualConfig);
    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          /*
          The user's auth token is invalid.
          Log them out.
          */
          deleteLoginState();
          analytics.track('API Logout');
          // Page refresh to reset app memory state.
          window.location.href = '/login';
          throw new Error('User is logged out. Forwarding to login page.');
        } else if (
          error.response?.status === 403 &&
          error.response?.data?.detail === 'company_is_disabled'
        ) {
          /*
          The user's account is disabled.
          Redirect them to /trial-expired page.
          
          A user's account could be disabled for several reasons.
          MVP code is to always forward any disabled account to the /trial-expired page.
          In the unlikely event an account is disabled for another reason,
          customer support will manually sort things out.

          To Test:
          python manage.py shell_plus
          
          userProfile = UserProfile.objects.first()
          print(user)
          company = userProfile.company
          print(company)
          company.is_enabled = False
          company.save()
          */

          // When the page loads we fetch /api/user_profile
          // Do no redirect if we are already on /trial-expired
          // as this will fetch /api/user_profile again and start
          // an infinite loop.
          if (!window.location.href.includes('/trial-expired')) {
            analytics.track('API TrialExpired');
            window.location.href = '/trial-expired';
          }

          throw new Error('Trial expired. Forwarding to trial expired page.');
        }
        throw error;
      },
    );
  }

  async post(relativeUrl: string, data: any, convert?: DataConverter) {
    let serverResponse = await this.instance.post(relativeUrl, data);
    return convertResponse(serverResponse, convert);
  }

  async patch(relativeUrl: string, data: any, convert?: DataConverter) {
    let serverResponse = await this.instance.patch(relativeUrl, data);
    return convertResponse(serverResponse, convert);
  }

  async put(relativeUrl: string, data: any, convert?: DataConverter) {
    let serverResponse = await this.instance.put(relativeUrl, data);
    return convertResponse(serverResponse, convert);
  }

  async get(relativeUrl: string, convert?: DataConverter) {
    const serverResponse = await this.instance.get(relativeUrl);
    return convertResponse(serverResponse, convert);
  }

  async delete(relativeUrl: string, payload: any, convert?: DataConverter) {
    let serverResponse = await this.instance.delete(relativeUrl, { data: payload });
    return convertDeleteResponse(serverResponse, convert);
  }
}

async function convertDeleteResponse(response: AxiosResponse, convert?: DataConverter) {
  if (convert && response.status === 204) {
    response.data = convert(response.data);
  }
  return response;
}

// FYI: convertResponse will not work if the API returns 200 and a response.data.error
// TODO: We should get rid of that bad convention of response.data.error
async function convertResponse(response: AxiosResponse, convert?: DataConverter) {
  if (convert && response.status === 200) {
    response.data = convert(response.data);
  }
  return response;
}
