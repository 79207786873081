import React from 'react';

import { VersionUserProfile } from 'api/APITypes';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import uph from 'model_helpers/userProfileHelper';

interface UserHoverProps {
  userProfile: VersionUserProfile;
}

export default function UserHover(props: UserHoverProps) {
  const { userProfile } = props;
  const name = uph.displayName(userProfile);
  const email = userProfile.user.email;

  return (
    <TooltipTrigger tip={email}>
      <span>{name}</span>
    </TooltipTrigger>
  );
}
