import { HouseDoorFill, Check } from 'react-bootstrap-icons';

import MeritBadges from './MeritBadges';

interface SignupLeftSideCopyProps {}

const SignupLeftSideCopy = (props: SignupLeftSideCopyProps) => {
  return (
    <div className="w-full lg:min-w-[444px] lg:w-[444px] lg:mx-2 lg:mt-[4px]">
      <div className="f-row-y-center">
        <img className="h-[28px]" src="/images/logos/logo_purple.svg" alt="" />
        <h1 className="ml-2 display-xs text-purple" style={{ fontWeight: 800 }}>
          MOZART DATA SONATA
        </h1>
      </div>
      <div className="display-xs mt-8" style={{ maxWidth: '375px' }}>
        Pricing composed to help startups work with their data
      </div>
      <div className="f-row-y-center text-xl mt-6">
        <div className="w-4 h-4 f-center mr-2 bg-teal rounded">
          <Check size="14" color="var(--white)" />
        </div>
        FREE
      </div>
      <div className="mt-1">No credit card required</div>
      <div className="mt-1">No implementation fee</div>
      <div className="f-row-y-center text-xl mt-6">
        <div className="w-4 h-4 f-center mr-2 bg-teal rounded">
          <Check size="14" color="var(--white)" />
        </div>
        Complete platform access
      </div>
      <div className="mt-1">Unlimited users, connectors, &amp; transforms</div>
      <div className="f-row-y-center text-xl mt-6">
        <div className="w-4 h-4 f-center mr-2 bg-teal rounded">
          <Check size="14" color="var(--white)" />
        </div>
        Premium analyst support
      </div>
      <div className="mt-1">Our analysts are here to help you get started</div>

      <div className="mt-6 max-w-[400px]">
        <MeritBadges />
      </div>

      <a
        className="w-fit f-row-y-center mt-6 text-purple font-medium text-sm"
        href="https://www.mozartdata.com"
        data-track="Signup LinkToMarketingSite"
      >
        <HouseDoorFill size="14" color="var(--purple)" />
        <div className="ml-2">MOZART DATA WEBSITE</div>
      </a>
    </div>
  );
};

export default SignupLeftSideCopy;
