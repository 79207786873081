/*
  HTML that looks like a checkbox input but isn't an input.
*/
import { Check } from 'react-bootstrap-icons';

import cn from 'classnames';

const FakeCheckbox = (props: { checked: boolean }) => {
  const { checked } = props;

  const fakeCheckSize = 'w-4 h-4 rounded';

  return checked ? (
    <div className={cn(fakeCheckSize, 'f-center bg-purple')}>
      <Check size={16} color={'white'} />
    </div>
  ) : (
    <div className={cn(fakeCheckSize, 'border-[1px] border-pri-gray-500 bg-white')}></div>
  );
};

export default FakeCheckbox;
