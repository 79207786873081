// sort-imports-ignore
import React from 'react';
import { Redirect, Route, Switch, useLocation, matchPath } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';

import { getHasLoggedIn } from 'api/AuthStorage';
import authenticatedRoutes from './authenticatedRoutes';
import EmailVerification from './pages/EmailVerification';
import ListExperiments from 'pages/ListExperiments';
import Login from 'pages/Login';
import UnauthHeader from 'components/page_parts/headers/UnauthHeader/UnauthHeader';
import Page404 from 'pages/Page404';
import ResetPassword from 'pages/ResetPassword';
import RequestReset from 'pages/RequestReset';
import Signup from 'pages/signup/Signup';
import SignupSonata from 'pages/signup/SignupSonata';
import OktaEmailSubmit from 'pages/OktaEmailSubmit';
import SignupThoughtspot from 'pages/signup/SignupThoughtspot';
import SignupGCPMarketplace from 'pages/signup/SignupGCPMarketplace';

const UnauthenticatedApp = () => {
  const location = useLocation();
  const NON_HEADER_ROUTES = [
    '/email-verification',
    '/signup',
    '/signup-sonata',
    '/signup-thoughtspot',
    '/signup-gcpmarketplace',
    '/login',
    '/request-reset',
    '/reset-password/:id',
    '/set-password/:id',
  ];

  const showHeader = !NON_HEADER_ROUTES.some((route) =>
    matchPath(location.pathname, {
      path: route,
    }),
  );

  return (
    <>
      {showHeader && <UnauthHeader />}
      <PublicRoutes />
    </>
  );
};

/*
ATTENTION:
When you add a <Route/> to this file you also need to add it's path to
unauthenticatedRoutes.ts
*/
const PublicRoutes = () => {
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={() => {
          return getHasLoggedIn() ? <Redirect to="/login" /> : <Redirect to="/signup" />;
        }}
      />
      <CompatRoute path="/email-verification" exact component={EmailVerification} />
      <CompatRoute path="/experiments" exact component={ListExperiments} />
      <CompatRoute path="/login" exact component={Login} />
      <CompatRoute path="/okta_email_submit" exact component={OktaEmailSubmit} />
      <CompatRoute path="/request-reset" exact component={RequestReset} />
      {/* reset-password and set-password point to the same component, but look different */}
      <CompatRoute path="/reset-password/:id" exact component={ResetPassword} />
      <CompatRoute path="/set-password/:id" exact component={ResetPassword} />
      <CompatRoute path="/signup" exact component={Signup} />
      <CompatRoute path="/signup-sonata" exact component={SignupSonata} />
      <CompatRoute path="/signup-thoughtspot" exact component={SignupThoughtspot} />
      <CompatRoute path="/signup-gcpmarketplace" exact component={SignupGCPMarketplace} />
      <Route
        path={authenticatedRoutes}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )}
      />
      <Route>
        <Page404 isAuthenticated={false} />
      </Route>
    </Switch>
  );
};

export default UnauthenticatedApp;
