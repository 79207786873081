import React from 'react';

import { RangeWithKey, StaticRange, CommonCalendarProps, Range, DefinedRange } from 'react-date-range';

import { subDays, subMonths, lastDayOfMonth, setDate } from 'date-fns';

import { parseApiDate, formatApiDate } from 'utils/dateTime';

export interface PrefabRangesProps {
  startDate: string;
  endDate: string;
  onSetRange: (start: string, end: string) => void;
}

// Same as `react-date-range.RangeWithKey` except Dates cannot be undefined.
export interface SafeRange {
  startDate: Date;
  endDate: Date;
  key: 'selection';
}

export default function PrefabRanges(props: PrefabRangesProps) {
  const { startDate, endDate, onSetRange } = props;

  const currentRange: SafeRange = {
    startDate: parseApiDate(startDate) as Date,
    endDate: parseApiDate(endDate) as Date,
    key: 'selection',
  };

  const handleChangeRange = (item: any) => {
    const range = (item as { selection: RangeWithKey }).selection as SafeRange;
    onSetRange(formatApiDate(range.startDate), formatApiDate(range.endDate));
  };

  const today = parseApiDate(formatApiDate(new Date())) as Date;
  const lastThirtyDays = createStaticRange('Last 30 Days', subDays(today, 29), today);
  const lastSixtyDays = createStaticRange('Last 60 Days', subDays(today, 59), today);
  const lastNinetyDays = createStaticRange('Last 90 Days', subDays(today, 89), today);
  const lastYearOfDays = createStaticRange('Last 365 Days', subDays(today, 364), today);
  const firstDayOfLastMonth = setDate(subMonths(today, 1), 1);
  const lastDayOfLastMonth = lastDayOfMonth(firstDayOfLastMonth);
  const lastMonth = createStaticRange('Last Month', firstDayOfLastMonth, lastDayOfLastMonth);

  const staticRanges: StaticRange[] = [
    lastThirtyDays,
    lastSixtyDays,
    lastNinetyDays,
    lastYearOfDays,
    lastMonth,
  ];

  return (
    <>
      {/* @ts-ignore, `DefinedRange` is not defined as a Component in the types file. */}
      <DefinedRange
        ranges={[currentRange]}
        staticRanges={staticRanges}
        inputRanges={[]}
        onChange={handleChangeRange}
      />
    </>
  );
}

function createStaticRange(label: string, startDate: Date, endDate: Date) {
  return {
    label,
    isSelected: (range: Range) => {
      return (
        (range.startDate as Date).getTime() === startDate.getTime() &&
        (range.endDate as Date).getTime() === endDate.getTime()
      );
    },
    range: (props: CommonCalendarProps) => ({
      startDate,
      endDate,
      key: 'selection',
    }),
  };
}
