/*
This is a DraggablePanes component that does not maintain its own size state.
It renders at whatever size it is told to by its props.
If the parent of this component doesn't need to control size state,
use DraggablePanes instead.
*/
import React, { useState } from 'react';

import cn from 'classnames';
import { clamp } from 'lodash';
import { Resizable } from 're-resizable';

import { dimToPixNum, StringSize } from 'utils/cssMath';

import { ComponentSize } from '@rehooks/component-size';

export const HANDLE_WIDTH_NUM = 4;
export const HANDLE_WIDTH = `${HANDLE_WIDTH_NUM}px`;

export interface DefaultSizes {
  width: string | number;
  height: string | number;
  minWidth: string | undefined;
  maxWidth: string | undefined;
  minHeight: string | undefined;
  maxHeight: string | undefined;
}

export const DEFAULT_SIZES: DefaultSizes = {
  width: '100%',
  height: '100%',
  minWidth: undefined,
  maxWidth: '100%',
  minHeight: undefined,
  maxHeight: '100%',
};

export const DEFAULT_SECOND_CHILD_STYLES: React.CSSProperties = {
  width: '100%',
  height: '100%',
};

export interface BasicDraggablePanesProps {
  // If two children are provided it renders them both with a draggable divider between the two children.
  // The draggable divider controls how much of the shared space is allotted to each child.
  // If one child is provided it will take up the entire space.
  // It's assumed the children are programmed to gracefully take up all the space they are allotted.
  children: React.ReactNode[];
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  hideDivider?: boolean;
  getDefaultSizes?: () => DefaultSizes;
  getSecondChildStyles?: () => React.CSSProperties;
}

export interface BothDirectionsState {
  containerRef: React.MutableRefObject<null>;
  containerSize: ComponentSize;
}

export interface HorizontalDraggablePanesState extends BothDirectionsState {
  leftWidth: StringSize;
  setLeftWidth(width: number): void;
  // The maxWidths are calculated from the minWidths.
  // The leftMaxWidth is (100% - rightMinWidth - the divider width) and visa versa.
  leftMinWidth?: StringSize;
  rightMinWidth?: StringSize;
}

export interface VerticalDraggablePanesState extends BothDirectionsState {
  topHeight: StringSize;
  setTopHeight(width: number): void;
  // The maxHeights are calculated from the minHeights.
  // The topMaxHeight is (100% - bottomMinHeight - the divider height) and visa versa.
  topMinHeight?: StringSize;
  bottomMinHeight?: StringSize;
}

export interface HorizontalDraggablePanesProps
  extends BasicDraggablePanesProps,
    HorizontalDraggablePanesState {}
export interface VerticalDraggablePanesProps
  extends BasicDraggablePanesProps,
    VerticalDraggablePanesState {}

export type ControlledDraggablePanesProps = HorizontalDraggablePanesProps | VerticalDraggablePanesProps;

export default function ControlledDraggablePanes(props: ControlledDraggablePanesProps) {
  const {
    children,
    id,
    className,
    style,
    hideDivider = false,
    containerRef,
    containerSize,
    getDefaultSizes,
    getSecondChildStyles,
  } = props;
  const {
    leftWidth,
    setLeftWidth,
    leftMinWidth = '10%',
    rightMinWidth = '10%',
  } = props as HorizontalDraggablePanesProps;
  const {
    topHeight,
    setTopHeight,
    topMinHeight = '10%',
    bottomMinHeight = '10%',
  } = props as VerticalDraggablePanesProps;

  const vertical = (props as VerticalDraggablePanesProps).setTopHeight !== undefined;

  const [resizing, setResizing] = useState(false);

  // If the containerSize is known, convert dimensions to pixels so we can do math.
  const { leftNum, leftMaxNum, clampedLeftNum } = calcLeftNums(
    vertical,
    containerSize.width,
    leftWidth,
    leftMinWidth,
    rightMinWidth,
  );

  const { topNum, topMaxNum, clampedTopNum } = calcTopNums(
    vertical,
    containerSize.height,
    topHeight,
    topMinHeight,
    bottomMinHeight,
  );

  // Use default widths and heights until we know the containerSize,
  // at which point we can convert CSS units to pixels and do math.
  let leftMaxWidth = '90%';
  let clampedLeft = leftWidth;
  if (!vertical && containerSize.width > 0) {
    leftMaxWidth = `${leftMaxNum}px`;
    clampedLeft = `${clampedLeftNum}px`;
  }

  let topMaxHeight = '90%';
  let clampedTop = topHeight;
  if (vertical && containerSize.height > 0) {
    topMaxHeight = `${topMaxNum}px`;
    clampedTop = `${clampedTopNum}px`;
  }

  // Other components might pass chilren components that are undefineds, nulls, false, etc...
  // Filter to only the valid children.
  const validChildren = children.filter((c) => typeof c === 'object' && c !== null);

  const showSecondChild = !!validChildren[1];
  const firstChild = validChildren[0];
  const secondChild = validChildren[1];

  const draggableHorizontalPanesClass = cn(
    'flex h-full w-full overflow-hidden',
    { 'flex-col': vertical },
    className,
  );

  const handleResize = (
    event: MouseEvent | TouchEvent,
    direction: any,
    refToElement: HTMLElement,
    delta: any,
  ) => {
    if (vertical) {
      setTopHeight(refToElement.offsetHeight);
    } else {
      setLeftWidth(refToElement.offsetWidth);
    }
  };

  const defaultSizes = getDefaultSizes ? getDefaultSizes() : DEFAULT_SIZES;

  // Calculate the dimensions of the second child.
  let dividerStyle = {};
  let secondChildStyle = getSecondChildStyles
    ? getSecondChildStyles()
    : { ...DEFAULT_SECOND_CHILD_STYLES };
  const handleCorrection = hideDivider ? '0px' : HANDLE_WIDTH;
  if (showSecondChild) {
    if (vertical) {
      let cssTopHeight = topHeight;
      if (containerSize.height > 0) {
        cssTopHeight = `${Math.min(topNum, topMaxNum)}px`;
      }
      dividerStyle = { width: '100%', height: HANDLE_WIDTH, minHeight: HANDLE_WIDTH };
      secondChildStyle.height = `calc((100% - ${cssTopHeight}) - ${handleCorrection})`;
    } else {
      let cssLeftWidth = leftWidth;
      if (containerSize.width > 0) {
        cssLeftWidth = `${Math.min(leftNum, leftMaxNum)}px`;
      }
      dividerStyle = { width: HANDLE_WIDTH, minWidth: HANDLE_WIDTH, height: '100%' };
      secondChildStyle.width = `calc((100% - ${cssLeftWidth}) - ${handleCorrection})`;
    }
  }

  const setWidths = showSecondChild && !vertical;
  const setHeights = showSecondChild && vertical;
  const handleClass = cn('z-[900] hover:bg-sec-blue-gray-300', {
    'bg-sec-blue-gray-300': resizing,
  });

  return (
    <div ref={containerRef} id={id} className={draggableHorizontalPanesClass} style={style}>
      {/* If there is only one child, just render it.*/}
      {!showSecondChild && firstChild}

      {/* If there are two children, wrap the first child in a Resizable, followed by the second child.*/}
      {showSecondChild && (
        <>
          <Resizable
            style={{ position: 'relative' }}
            size={{
              width: setWidths ? clampedLeft : defaultSizes.width,
              height: setHeights ? clampedTop : defaultSizes.height,
            }}
            minWidth={setWidths ? leftMinWidth : defaultSizes.minWidth}
            maxWidth={setWidths ? leftMaxWidth : defaultSizes.maxWidth}
            minHeight={setHeights ? topMinHeight : defaultSizes.minHeight}
            maxHeight={setHeights ? topMaxHeight : defaultSizes.maxHeight}
            enable={{
              right: setWidths,
              bottom: setHeights,
            }}
            handleClasses={{ right: handleClass, bottom: handleClass }}
            handleStyles={{
              right: setWidths
                ? {
                    width: HANDLE_WIDTH,
                    height: '100%',
                    right: `-${HANDLE_WIDTH}`,
                    top: '0',
                  }
                : undefined,
              bottom: setHeights
                ? {
                    width: '100%',
                    height: HANDLE_WIDTH,
                    bottom: `-${HANDLE_WIDTH}`,
                    left: '0',
                  }
                : undefined,
            }}
            onResize={handleResize}
            onResizeStart={() => {
              setResizing(true);
            }}
            onResizeStop={() => {
              setResizing(false);
            }}
          >
            {firstChild}
          </Resizable>
          {/*
          This is just a spacer that takes up space to help with layout math.
          See handleClass and handleStyles for the interactive handle styling.
          */}
          {!hideDivider && <div style={dividerStyle}></div>}
          <div style={secondChildStyle}>{secondChild}</div>
        </>
      )}
    </div>
  );
}

export function calcLeftNums(
  vertical: boolean,
  containerWidth: number,
  leftWidth: StringSize,
  leftMinWidth: StringSize,
  rightMinWidth: StringSize,
) {
  // This component can freely take dimensions in px or %.
  // We can only convert % to pixels when we know the containerSize.
  // We convert everything to number of pixels before doing "size math".
  // Default everything to zero if we can't calculate them.
  let leftNum = 0;
  let leftMinNum = 0;
  let rightMinNum = 0;
  let leftMaxNum = 0;

  // If the caller sets the width to something greater than the max width,
  // clamp it to an allowable range.
  let clampedLeftNum = 0;

  if (!vertical && containerWidth > 0) {
    leftNum = dimToPixNum(leftWidth, containerWidth);
    leftMinNum = dimToPixNum(leftMinWidth, containerWidth);
    rightMinNum = dimToPixNum(rightMinWidth, containerWidth);
    leftMaxNum = containerWidth - rightMinNum - HANDLE_WIDTH_NUM;

    clampedLeftNum = clamp(leftNum, leftMinNum, leftMaxNum);
  }

  return {
    leftNum,
    leftMinNum,
    rightMinNum,
    leftMaxNum,
    clampedLeftNum,
  };
}

export function calcTopNums(
  vertical: boolean,
  containerHeight: number,
  topHeight: StringSize,
  topMinHeight: StringSize,
  bottomMinHeight: StringSize,
) {
  // This component can freely take dimensions in px or %.
  // We can only convert % to pixels when we know the containerSize.
  // We convert everything to number of pixels before doing "size math".
  // Default everything to zero if we can't calculate them.
  let topNum = 0;
  let topMinNum = 0;
  let bottomMinNum = 0;
  let topMaxNum = 0;

  // If the caller sets the height to something greater than the max height,
  // clamp it to an allowable range.
  let clampedTopNum = 0;

  if (vertical && containerHeight > 0) {
    topNum = dimToPixNum(topHeight, containerHeight);
    topMinNum = dimToPixNum(topMinHeight, containerHeight);
    bottomMinNum = dimToPixNum(bottomMinHeight, containerHeight);
    topMaxNum = containerHeight - bottomMinNum - HANDLE_WIDTH_NUM;

    clampedTopNum = clamp(topNum, topMinNum, topMaxNum);
  }

  return {
    topNum,
    topMinNum,
    bottomMinNum,
    topMaxNum,
    clampedTopNum,
  };
}
