/*
 * Type definitions, API methods, and conversion utilities for Dashboards.
 */
import { keyBy } from 'lodash';

import { VersionUserProfile } from 'api/APITypes';
import DataAPI from 'api/DataAPI';
import { useSafeGet } from 'hooks/useApi';

/*******************************************************************************
 * Types
 ******************************************************************************/
export interface ReportAvailable {
  key: string; // Example: "CUSTOMER_RETENTION"
  title: string; // Example: "Customer Retention"
  description: string; // Example: "Retention of customers broken down by month."
  transforms: ReportTransform[];
}

export interface ReportInstalled {
  key: string; // Example: "CUSTOMER_RETENTION"
}

export interface DashboardsAvailableByFivetranService {
  [service: string]: ReportAvailable[];
}

export interface DashboardInstalledByConnector {
  [connectorID: string]: Dashboard;
}

export interface NewDashboard {
  connector: string;
  reports: ReportInstalled[];
}

export interface UpdatableDashboardProps {
  reports?: ReportInstalled[];
  gapi_access_token?: string;
}

export interface Dashboard {
  id: string;
  created_at: string;
  updated_at: string;
  initial_setup_email_sent_at: string | null;
  connector: string;
  reports: Report[];
  gsheet_spreadsheet_id: string | null;
  created_by: VersionUserProfile;
}

export interface Report {
  id: string;
  created_at: string;
  updated_at: string;
  key: string;
  transforms: string[];
}

export interface ReportTransform {
  report_schema_prefix: string;
  table_name: string;
  description: string;
  gsheet_sync: boolean;
}

/*******************************************************************************
 * APIs
 ******************************************************************************/
const dashboardAPI = {
  create: (newDashboardProps: NewDashboard) => {
    const api = new DataAPI();
    return api.post(`/api/dashboards`, newDashboardProps);
  },

  patch: (id: string, dashboardProps: UpdatableDashboardProps) => {
    const api = new DataAPI();
    return api.patch(`/api/dashboards/${id}`, dashboardProps);
  },
};
export default dashboardAPI;

/*******************************************************************************
 * Conversion Utilites
 ******************************************************************************/
// Not implemented

/*******************************************************************************
 * React Query Hooks
 ******************************************************************************/
export function useGetDashboardsAvailable(friendlyErrorMsg?: string) {
  friendlyErrorMsg = friendlyErrorMsg || 'Failed to load dashboards available.';
  const url = '/api/report_map';
  const { isLoading, error, data } = useSafeGet<DashboardsAvailableByFivetranService>(
    url,
    url,
    {},
    friendlyErrorMsg,
  );
  return {
    dashboardsAvailableLoading: isLoading,
    dashboardsAvailableError: error,
    dashboardsAvailable: data,
  };
}

export const LIST_DASHBOARDS = '/api/dashboards';

export function useGetDashboardsInstalled(friendlyErrorMsg?: string) {
  friendlyErrorMsg = friendlyErrorMsg || 'Failed to load dashboards installed.';
  const { isLoading, error, data } = useSafeGet<Dashboard[]>(
    LIST_DASHBOARDS,
    LIST_DASHBOARDS,
    [],
    friendlyErrorMsg,
  );
  const dashboardsInstalled = data;
  const dashboardsInstalledByConnectorID = keyBy(dashboardsInstalled, 'connector');

  return {
    dashboardsInstalledLoading: isLoading,
    dashboardsInstalledError: error,
    dashboardsInstalled,
    dashboardsInstalledByConnectorID,
  };
}
