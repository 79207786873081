import React from 'react';

import cn from 'classnames';

import { RenderPopperProps } from 'components/overlay/PopperTrigger/usePopperRefs';

import s from './Popover.module.css';

export interface PopoverProps extends RenderPopperProps {
  title?: string;
  content: React.ReactNode;
}

export default function Popover(props: PopoverProps) {
  const { title, content, setPopperElement, setArrowElement, popperStyles, popperAttributes } = props;

  const popoverProps = props.popoverProps || {};
  const { className, ...restPopoverProps } = popoverProps;
  const defaultClasses = `${s.box} text-base font-normal text-pri-gray-900 drop-shadow-xl`;
  const actualClasses = cn(defaultClasses, className);

  return (
    <div
      ref={setPopperElement}
      className={s.popover}
      style={popperStyles.popper}
      {...popperAttributes.popper}
    >
      <div ref={setArrowElement} className={s.arrow} style={{ ...popperStyles.arrow }} />
      <div className={actualClasses} {...restPopoverProps}>
        {title && <h2 className="text-pri-gray-700 display-sx font-medium mb-2">{title}</h2>}
        {content}
      </div>
    </div>
  );
}
