import React from 'react';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';

export const SPINNER_MIN_HEIGHT = '40vh';

type TabVariant = 'normal' | 'wide_padding' | 'no_padding';
const PADDING_CLASS: Record<TabVariant, string> = {
  normal: 'p-4',
  wide_padding: 'py-4 px-[30px]',
  no_padding: 'p-0',
};

export interface TabLayoutProps {
  loading: boolean;
  error: string;
  children: React.ReactNode;
  variant?: TabVariant;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
}

export default function TabLayout(props: TabLayoutProps) {
  const { loading, error, children, variant = 'normal', containerStyle } = props;

  if (loading) {
    return <CenteredSpinner containerMinHeight={SPINNER_MIN_HEIGHT} />;
  }

  const containerClass = props.containerClass || PADDING_CLASS[variant];

  return (
    <div className={containerClass} style={containerStyle}>
      {error && (
        <Alert variant="error" className="mb-4">
          {error}
        </Alert>
      )}
      {children}
    </div>
  );
}
