import React from 'react';

import cn from 'classnames';

import IconButton from 'components/inputs/basic/Button/IconButton';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import DateHover from 'components/widgets/time/DateHover/DateHover';

import { SnowflakeUser } from '../../../useSnowflakeUsers';

export interface SnowflakeUsersRowProps {
  snowflakeUser: SnowflakeUser;
  validRole: boolean;
  selected: boolean;
  onEditUser: (user: SnowflakeUser) => void;
  onDeleteUser: (user: string) => void;
}

const SnowflakeUsersRow = React.memo((props: SnowflakeUsersRowProps) => {
  const { snowflakeUser, validRole, selected, onEditUser, onDeleteUser } = props;

  const cellClassName = 'overflow-hidden text-ellipsis whitespace-nowrap';
  let roleTip = snowflakeUser.default_role;
  if (!validRole) {
    roleTip += ' is missing.';
  }

  return (
    <tr key={snowflakeUser.name} className={cn({ newListItem: selected })}>
      <td className="max-w-[220px]">
        <TooltipTrigger tip={snowflakeUser.name}>
          <div className={cellClassName}>{snowflakeUser.name}</div>
        </TooltipTrigger>
      </td>
      <td className="max-w-[220px]">
        <div className="f-row-y-center">
          <TooltipTrigger tip={roleTip}>
            <div className={cn(cellClassName, { 'text-pri-error-700': !validRole })}>
              {snowflakeUser.default_role}
            </div>
          </TooltipTrigger>
          {!validRole && (
            <InfoIcon
              containerClass="ml-1"
              content="This user has a role that does not exist. You can assign the user a valid role with the edit button on the right side of this row."
            />
          )}
        </div>
      </td>
      <td className="max-w-[220px]">
        <TooltipTrigger tip={snowflakeUser.first_name}>
          <div className={cellClassName}>{snowflakeUser.first_name}</div>
        </TooltipTrigger>
      </td>
      <td className="max-w-[220px]">
        <TooltipTrigger tip={snowflakeUser.last_name}>
          <div className={cellClassName}>{snowflakeUser.last_name}</div>
        </TooltipTrigger>
      </td>
      <td className="max-w-[220px]">
        <TooltipTrigger tip={snowflakeUser.email}>
          <div className={cellClassName}>{snowflakeUser.email}</div>
        </TooltipTrigger>
      </td>
      <td>
        <div className="f-center">
          <StatusIcon status={snowflakeUser.disabled === 'true' ? 'error' : 'success'} />
        </div>
      </td>
      <td className="max-w-[110px]">
        <DateHover date={snowflakeUser.created_on} formatTime={false} />
      </td>
      <td className="max-w-[110px]">
        <DateHover date={snowflakeUser.last_success_login} formatTime={false} />
      </td>
      <td>
        <div className="f-row-y-center">
          <IconButton
            icon="PencilSquare"
            variant="lightDullTransparent"
            size="small"
            onClick={() => onEditUser(snowflakeUser)}
          />
          <IconButton
            icon="Trash"
            variant="lightDullTransparent"
            size="small"
            onClick={() => onDeleteUser(snowflakeUser.name)}
          />
        </div>
      </td>
    </tr>
  );
});

export default SnowflakeUsersRow;
