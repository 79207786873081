/**
 * Generic sortable hook implementation so that any component that
 * renders it's own JSX implementation of a table can reuse the same
 * sort logic.
 */
import React, { useCallback, useMemo, useState } from 'react';

import { SortDownAlt, SortUp } from 'react-bootstrap-icons';

export type SortConfigDirection = 'ascending' | 'descending';

export interface SortConfig {
  key: string;
  direction: SortConfigDirection;
}

export interface SortFunctions<T> {
  [key: string]: (item: T) => any; // Given one of the items, output the value from the item that should be used for sorting.
}

export interface UseSortableTableProps<T> {
  unsortedItems: T[];
  sortFunctions: SortFunctions<T>;
  defaultSortConfig?: SortConfig;
}

export default function useSortableTable<T>(props: UseSortableTableProps<T>) {
  const { unsortedItems, sortFunctions, defaultSortConfig } = props;
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(defaultSortConfig || null);

  const sortedItems = useMemo(() => {
    let sortedItems = [...unsortedItems];
    const currentKeysGetValueFunc = sortFunctions[sortConfig?.key as string];
    if (sortConfig !== null && currentKeysGetValueFunc) {
      sortedItems.sort((a, b) => {
        const valueA = currentKeysGetValueFunc(a);
        const valueB = currentKeysGetValueFunc(b);
        if (valueA < valueB) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedItems;
  }, [unsortedItems, sortFunctions, sortConfig]);

  const requestSort = useCallback(
    (key: string | undefined) => {
      if (key !== undefined) {
        let direction: SortConfigDirection = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key, direction });
      }
    },
    [sortConfig],
  );

  const getSortIcon = useCallback(
    (key: string | undefined) => {
      if (key === undefined || !sortConfig || sortConfig.key !== key) {
        return null;
      }
      return sortConfig.direction === 'ascending' ? (
        <SortDownAlt size="16" color="var(--pri-gray-500)" />
      ) : (
        <SortUp size="16" color="var(--pri-gray-500)" />
      );
    },
    [sortConfig],
  );

  return { sortedItems, sortConfig, requestSort, getSortIcon };
}
