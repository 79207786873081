import React, { useState } from 'react';

import { AggTable, CompanyRole, Favorite } from 'api/APITypes';
import { useDatabaseAccount } from 'context/AuthContext';

import {
  TableName,
  Description,
  Scheduled,
  LastRunSuccessful,
  AgoTime,
  RowCount,
  ActionButtons,
  Snapshot,
} from './TableRowCell';

interface TableRowProps {
  table: AggTable;
  showIcon: boolean;
  useFullName: boolean;
  usingCache: boolean;
  refKey: string;
  descRef: React.RefObject<HTMLDivElement>;
  scheduledRef: React.RefObject<HTMLTableCellElement>;
  agoRef: React.RefObject<HTMLSpanElement>;
  tagPickerRef: React.RefObject<HTMLDivElement>;
  hasSchemaFilter: boolean;
  hasTableFilter: boolean;
  fullHighlightFilter: string | RegExp;
  schemaHighlightFilter: string | RegExp;
  tableHighlightFilter: string | RegExp;
  descriptionHighlightFilter: string | RegExp;
  favorite?: Favorite;
  companyRole: CompanyRole;
  addFavorite: (favorite: Favorite) => void;
  removeFavorite: (favorite: Favorite) => void;
  setSnapshot(table: AggTable, snapshot: boolean): Promise<AggTable>;
  logRecent: (tableID: string) => void;
  onOpenTagPicker: (refKey: string) => void;
  onClickTag: (tagName: string) => void;
  onClickTable: (table: AggTable) => void;
}

const TableRow = React.memo((props: TableRowProps) => {
  const {
    table,
    showIcon,
    useFullName,
    usingCache,
    refKey,
    descRef,
    scheduledRef,
    agoRef,
    tagPickerRef,
    hasSchemaFilter,
    hasTableFilter,
    fullHighlightFilter,
    schemaHighlightFilter,
    tableHighlightFilter,
    descriptionHighlightFilter,
    favorite,
    companyRole,
    addFavorite,
    removeFavorite,
    setSnapshot,
    logRecent,
    onOpenTagPicker,
    onClickTag,
    onClickTable,
  } = props;
  let { id, full_name, type, snapshot } = table;
  const [snapshotLoading, setSnapshotLoading] = useState(false);
  const [showCantSnapshotModal, setShowCantSnapshotModal] = useState(false);
  const databaseType = useDatabaseAccount().type;

  const handleSetSnapshot = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (companyRole === 'viewer') {
      setShowCantSnapshotModal(true);
      analytics.track('Warehouse ViewerAttemptedSnapshot');
    } else {
      setSnapshotLoading(true);
      setSnapshot(table, !snapshot)
        .then((updatedTable) => {
          analytics.track(!snapshot ? 'Warehouse EnableSnapshot' : 'Warehouse DisableSnapshot');
        })
        .catch(() => {
          // Silently swallow the error. The error message is set in Warehouse.tsx by saveTable
        })
        .finally(() => {
          setSnapshotLoading(false);
        });
    }
  };

  const handleClickTable = (event: React.MouseEvent<HTMLTableRowElement>) => {
    event.stopPropagation();
    onClickTable(table);
  };

  const handleCloseCantSnapshotModal = () => {
    setShowCantSnapshotModal(false);
    analytics.track('Warehouse ViewerClosedCantSnapshotModal');
  };

  return (
    <tr
      data-table-id={id}
      data-full-name={full_name}
      onClick={handleClickTable}
      className="cursor-pointer"
    >
      <TableName
        table={table}
        showIcon={showIcon}
        useFullName={useFullName}
        hasSchemaFilter={hasSchemaFilter}
        hasTableFilter={hasTableFilter}
        fullHighlightFilter={fullHighlightFilter}
        schemaHighlightFilter={schemaHighlightFilter}
        tableHighlightFilter={tableHighlightFilter}
      />
      <Description
        table={table}
        refKey={refKey}
        descRef={descRef}
        descriptionHighlightFilter={descriptionHighlightFilter}
        onTagClick={onClickTag}
      />
      <Scheduled table={table} usingCache={usingCache} refKey={refKey} scheduledRef={scheduledRef} />
      <LastRunSuccessful table={table} usingCache={usingCache} />
      <AgoTime table={table} usingCache={usingCache} refKey={refKey} agoRef={agoRef} />
      <RowCount type={type} table={table} usingCache={usingCache} logRecent={logRecent} />
      {databaseType !== 'bigquery' && (
        <Snapshot
          table={table}
          usingCache={usingCache}
          spinning={snapshotLoading}
          showCantSnapshotModal={showCantSnapshotModal}
          onSetSnapshot={handleSetSnapshot}
          onCloseCantSnapshotModal={handleCloseCantSnapshotModal}
        />
      )}
      <ActionButtons
        type={type}
        table={table}
        refKey={refKey}
        usingCache={usingCache}
        favorite={favorite}
        tagPickerRef={tagPickerRef}
        companyRole={companyRole}
        addFavorite={addFavorite}
        removeFavorite={removeFavorite}
        onOpenTagPicker={onOpenTagPicker}
        logRecent={logRecent}
      />
    </tr>
  );
});

export default TableRow;
