import React from 'react';

import _ from 'lodash';

import PageButton from 'components/inputs/basic/Button/PageButton';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';
import { TransformTable } from 'pages/tables/ShowTable/ShowTable';

import RunHistoryTableRow from './RunHistoryTableRow';
import { PaginatedTransformRuns } from './RunTab';

const TABLE_HEIGHT = 'max(390px, calc(100vh - 680px))';

interface RunHistoryTableProps {
  table: TransformTable;
  paginatedTransformRuns: PaginatedTransformRuns | null;
  error: string;
  loading: boolean;
  onLoadRuns: (url: string | null) => void;
}

export default function RunHistoryTable(props: RunHistoryTableProps) {
  const { table, paginatedTransformRuns, error, loading, onLoadRuns } = props;

  const handleLoadPreviousPage = () => {
    if (paginatedTransformRuns?.previous) {
      onLoadRuns(paginatedTransformRuns.previous);
      analytics.track('RunTab LoadPreviousPage');
    }
  };

  const handleLoadNextPage = () => {
    if (paginatedTransformRuns?.next) {
      onLoadRuns(paginatedTransformRuns.next);
      analytics.track('RunTab LoadNextPage');
    }
  };
  return (
    <>
      <div className="h-[36px] f-between mt-1">
        <h4 className="text-base font-medium text-sec-blue-gray-700">Run History</h4>
        <div className="flex justify-end">
          <PageButton
            direction="prev"
            hasPage={!!paginatedTransformRuns && !!paginatedTransformRuns.previous}
            onClick={handleLoadPreviousPage}
          />
          <PageButton
            direction="next"
            hasPage={!!paginatedTransformRuns && !!paginatedTransformRuns.next}
            onClick={handleLoadNextPage}
            className="ml-2"
          />
        </div>
      </div>
      {/* RunHistory writes its own columns. */}
      <div>
        <RunHistoryTableContent
          table={table}
          paginatedTransformRuns={paginatedTransformRuns}
          error={error}
          loading={loading}
          onLoadRuns={onLoadRuns}
        />
      </div>
    </>
  );
}

function RunHistoryTableContent(props: RunHistoryTableProps) {
  const { table, paginatedTransformRuns, error, loading } = props;

  if (loading) {
    return <CenteredSpinner containerMinHeight={TABLE_HEIGHT} />;
  }

  if (error) {
    return (
      <Alert variant="error" className="mt-2">
        There was a problem loading the run history.
      </Alert>
    );
  }

  if (!paginatedTransformRuns || paginatedTransformRuns.count === 0) {
    return (
      <FixMeAlert
        heading="This transform has never been run."
        detail={
          <p className="max-w-[500px] mt-2">
            Click the <code className="text-pri-gray-700">Run</code> button to create table{' '}
            <code className="text-pri-gray-700">{table.full_name}</code>.
          </p>
        }
      />
    );
  }

  let transformHasInserts = false;
  let transformHasUpdates = false;
  let transformHasIncremental = false;
  if (!!paginatedTransformRuns) {
    transformHasInserts = _.some(
      paginatedTransformRuns.results.transform_runs,
      (run) => run.incremental && run.num_rows_inserted !== null,
    );
    transformHasUpdates = _.some(
      paginatedTransformRuns.results.transform_runs,
      (run) => run.incremental && run.num_rows_updated !== null,
    );
    transformHasIncremental = _.some(
      paginatedTransformRuns.results.transform_runs,
      (run) => run.incremental,
    );
  }

  let errorColSpan = 8;
  if (transformHasInserts) {
    errorColSpan++;
  }
  if (transformHasUpdates) {
    errorColSpan++;
  }
  if (transformHasIncremental) {
    errorColSpan++;
  }

  return (
    <>
      <div
        className="mt-1"
        style={{
          overflow: 'auto',
          height: TABLE_HEIGHT,
        }}
      >
        {/* table-fix-head text-center */}
        <table className="blueGrayHeaderTable">
          <thead>
            <tr className="whitespace-nowrap">
              <th className="text-left">Start Time</th>
              <th className="text-left">End Time</th>
              <th className="text-center">Status</th>
              <th className="text-left">Duration</th>
              <th className="text-right">Row Count</th>
              {transformHasIncremental && <th className="text-left">Run Type</th>}
              {transformHasInserts && <th className="text-right">Inserts</th>}
              {transformHasUpdates && <th className="text-right">Updates</th>}
              <th className="text-right">Version</th>
              <th className="text-left">Scheduled</th>
              <th className="text-left">Created As</th>
            </tr>
          </thead>
          <tbody>
            {paginatedTransformRuns.results.transform_runs
              .sort((a, b) => {
                return a.created_at > b.created_at ? -1 : 1;
              })
              .map((run) => (
                <RunHistoryTableRow
                  key={run.id}
                  run={run}
                  errorColSpan={errorColSpan}
                  transformHasIncremental={transformHasIncremental}
                  transformHasInserts={transformHasInserts}
                  transformHasUpdates={transformHasUpdates}
                />
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
