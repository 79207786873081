import React, { useState } from 'react';

import { ButtonVariant } from 'components/inputs/basic/Button/Button';
import IconButton, { IconKey } from 'components/inputs/basic/Button/IconButton';
import usedIcons from 'components/inputs/basic/Button/IconButtonUsedIcons';

type ResponseOption = {
  label: string;
  value: string;
  icon: IconKey;
  variant: ButtonVariant;
  color: string;
};

interface FeedbackFormProps {
  onClick: (response: string) => void;
}

const FeedbackForm = (props: FeedbackFormProps) => {
  const { onClick } = props;

  const [response, setResponse] = useState<string | null>(null);

  const RESPONSE_OPTIONS: ResponseOption[] = [
    {
      label: 'Yes',
      value: 'yes',
      icon: 'EmojiSmile',
      variant: 'saveTransparent',
      color: 'pri-success-700',
    },
    {
      label: 'Somewhat',
      value: 'somewhat',
      icon: 'EmojiExpressionless',
      variant: 'warningTransparent',
      color: 'pri-warning-700',
    },
    {
      label: 'No',
      value: 'no',
      icon: 'EmojiFrown',
      variant: 'dangerTransparent',
      color: 'pri-error-700',
    },
  ];

  const handleResponseClick = (response: string) => {
    setResponse(response);
    onClick(response);
  };

  const renderResponse = (response: string) => {
    const { label, icon, color } = RESPONSE_OPTIONS.find((option) => option.value === response) ?? {};

    const IconTag = usedIcons[icon ?? 'EmojiExpressionless'];

    return (
      <div className={`f-row-y-center font-medium text-sm text-${color} px-2`}>
        <IconTag />
        <span className="ml-[5px]">{label}</span>
      </div>
    );
  };

  return (
    <div className="flex flex-row-reverse items-center">
      <div className="flex">
        {!!response
          ? renderResponse(response)
          : RESPONSE_OPTIONS.map(({ value, label, icon, variant }) => (
              <IconButton
                key={value}
                icon={icon}
                text={label}
                variant={variant}
                size="small"
                onClick={() => handleResponseClick(value)}
              />
            ))}
      </div>
      <div className="text-sec-blue-gray-700 text-xs text-pri-gray-500 font-medium mr-1">
        {response ? 'Thanks for the feedback!' : 'Was this helpful?'}
      </div>
    </div>
  );
};

export default FeedbackForm;
