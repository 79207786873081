/* 
Returns the index of the row with the mouse over it in a table in a scrollable area.
Returns -1 if the mouse is not over a row.

React will unmount and remount individual rows when props change.
So, putting `mouseover` listeners on an individual rows results in elements blinking.
Instead, put a mouse listener on the parent of all the rows.

Use mouse position instead of mousemove event because the API or the filter
could change the rows under a stationary mouse.

useMouseAndScroll() is throttled to requestAnimationFrame.
*/
import { RefObject, useCallback, useState } from 'react';

import useMouseAndScroll, { MouseAndScrollState } from 'hooks/useMouseAndScroll';

const useHoveredIndex = (
  innerRef: RefObject<Element>,
  scrollRef: RefObject<Element>,
  rowHeight: number,
): number => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const onMouseOrScroll = useCallback(
    (mouseState: MouseAndScrollState) => {
      const { innerElY, innerElW, innerElH, scrollElX, scrollElY, scrollElW, scrollElH } = mouseState;

      let nextIndex = -1;

      // When the page first loads all the values returned by useMouseAndScroll can be 0.
      // So ignore useMouseAndScroll until the HTML renders and these values are valid.
      if (innerElW >= 0 && innerElH >= 0 && scrollElW >= 0 && scrollElH >= 0) {
        // Update hoveredIndex if mouse is over scrollRef.
        if (scrollElX >= 0 && scrollElY >= 0 && scrollElX <= scrollElW && scrollElY <= scrollElH) {
          nextIndex = Math.floor(innerElY / rowHeight);
        }
      }

      setHoveredIndex(nextIndex);
    },
    [rowHeight],
  );

  useMouseAndScroll(innerRef, scrollRef, onMouseOrScroll);

  return hoveredIndex;
};

export default useHoveredIndex;
