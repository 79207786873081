import React from 'react';

import Button, { ButtonProps } from 'components/inputs/basic/Button/Button';
import Modal from 'components/layouts/containers/modals/Modal/Modal';

export interface ConfirmModalProps {
  saving?: boolean;
  header?: React.ReactNode;
  body?: string | React.ReactNode;
  cancelText?: string;
  confirmText: string;
  confirmVariant: ButtonProps['variant'];
  onCancel(): void;
  onConfirm(): void;
  containerClass?: string;
}

export default function ConfirmModal(props: ConfirmModalProps) {
  let {
    saving,
    header,
    body,
    cancelText,
    confirmText,
    confirmVariant,
    onCancel,
    onConfirm,
    containerClass,
  } = props;
  cancelText = cancelText ?? 'Cancel';
  confirmText = confirmText ?? 'Confirm';

  const modalFooter = (
    <div className="flex justify-end">
      <Button variant={confirmVariant} onClick={onConfirm} spinning={saving}>
        {confirmText}
      </Button>
      <Button variant="lightDanger" onClick={onCancel} className="ml-2" autoFocus>
        {cancelText}
      </Button>
    </div>
  );
  return (
    <Modal onClose={onCancel} header={header} footer={modalFooter} containerClass={containerClass}>
      {body && <div>{body}</div>}
    </Modal>
  );
}
