import React, { Fragment } from 'react';

import cn from 'classnames';

import { useUserProfile } from 'context/AuthContext';
import { DataAlertWithTable } from 'hooks/useDataAlerts';

import DataAlertRowController, {
  DataAlertRowControllerProps,
} from './DataAlertRow/DataAlertRowController';
import DataAlertTableBannerRowView from './DataAlertTableBannerRowView';
import DataAlertTableHeader from './DataAlertTableHeader';

type EveryDataRowProps = Omit<
  DataAlertRowControllerProps,
  'table' | 'dataAlert' | 'isOverallAlertsPage' | 'analyticsLocation'
>;

interface DataAlertTableProps {
  dataAlerts: DataAlertWithTable[];
  isOverallAlertsPage: boolean;
  isTransformOnTablePage?: boolean;
  analyticsLocation: 'TableTestTab' | 'DataAlertsPage';
  className?: string;
  rowProps: EveryDataRowProps;
}

// We want user typing to rerender fast.
// Only rerender the list of rows when the filters change the filteredDataAlerts array.
const DataAlertTable = React.memo((props: DataAlertTableProps) => {
  const {
    dataAlerts,
    isOverallAlertsPage,
    isTransformOnTablePage,
    analyticsLocation,
    className,
    rowProps,
  } = props;

  const { userProfile } = useUserProfile();

  if (dataAlerts.length === 0) {
    return null;
  }

  const tableClass = isOverallAlertsPage ? 'lineTable' : 'blueGrayHeaderTable';

  return (
    <table className={cn(tableClass, className)}>
      <DataAlertTableHeader
        userProfile={userProfile}
        isOverallAlertsPage={isOverallAlertsPage}
        isTransformOnTablePage={!!isTransformOnTablePage}
      />
      <tbody>
        {dataAlerts.map((dataAlert: DataAlertWithTable, index: number) => {
          const includeBanner =
            isOverallAlertsPage &&
            (index === 0 || dataAlerts[index - 1].table.id !== dataAlert.table.id);
          return (
            <Fragment key={dataAlert.id}>
              {includeBanner && <DataAlertTableBannerRowView table={dataAlert.table} />}
              <DataAlertRowController
                dataAlert={dataAlert}
                isOverallAlertsPage={isOverallAlertsPage}
                analyticsLocation={analyticsLocation}
                {...rowProps}
              />
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
});

export default DataAlertTable;
