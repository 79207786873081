import React from 'react';

import { Key, KeyFill } from 'react-bootstrap-icons';

import { Column } from 'api/columnAPI';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

interface DBKeyInfoIconProps {
  column: Column;
}

export function DBKeyInfoIcon(props: DBKeyInfoIconProps) {
  const { column } = props;
  // For some reason, typescript wont let me reuse these has... variables in infoIconContent
  const hasFkToPks = column.fk_to_pks !== undefined && column.fk_to_pks.length !== 0;
  const hasPkToFks = column.pk_to_fks !== undefined && column.pk_to_fks.length !== 0;
  const showKey = column.is_primary_key || hasFkToPks || hasPkToFks;
  if (!showKey) {
    return null;
  }
  const infoIconContent = (
    <div className="f-col">
      {column.is_primary_key && <div className="mb-2 font-medium">This column is a Primary Key</div>}
      {column.fk_to_pks !== undefined && column.fk_to_pks.length !== 0 && (
        <div className="mb-2">
          <div className="font-medium mb-1">
            This column is a Foreign Key to{' '}
            {column.fk_to_pks.length === 1 ? 'this Primary Key' : 'these Primary Keys'}:
          </div>
          <div className="f-col">
            {column.fk_to_pks.map((k) => (
              <div className="ml-2 mb-1">{k.toLowerCase()}</div>
            ))}
          </div>
        </div>
      )}
      {column.pk_to_fks !== undefined && column.pk_to_fks.length !== 0 && (
        <div>
          <div className="font-medium mb-1">
            This column is a Primary Key to{' '}
            {column.pk_to_fks.length === 1 ? 'this Foreign Key' : 'these Foreign Keys'}:
          </div>
          <div className="f-col">
            {column.pk_to_fks.map((k) => (
              <div className="ml-2 mb-1">{k.toLowerCase()}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
  return (
    <InfoIcon
      icon={column.is_primary_key ? KeyFill : Key}
      iconRotate={45}
      content={infoIconContent}
      containerClass="ml-1"
      popoverProps={{ style: { maxWidth: '700px' } }}
    />
  );
}
