import React, { useEffect, useState, useMemo } from 'react';

import { RouteComponentProps } from 'react-router';

import { subMonths, lastDayOfMonth, startOfMonth, setDate } from 'date-fns';

import API from 'api/API';
import { UsageInterval } from 'api/usageAPI';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import TabRow from 'components/layouts/parts/TabRow/TabRow';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useUserProfile } from 'context/AuthContext';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import useTabs from 'hooks/useTabs';
import EstimatorTab from 'pages/UsageV2/EstimatorTab/EstimatorTab';
import SummaryTab from 'pages/UsageV2/SummaryTab/SummaryTab';
import { parseSqlResults } from 'utils/apiResponseFormatter';
import { parseApiDate, formatApiDate } from 'utils/dateTime';

import { A_YEAR_AGO, CompanyPlanRow, TODAY } from '../UsageV2/useUsageFetch';

import ComputeTab from './ComputeTab/ComputeTab';
import ConnectorTab from './ConnectorTab/ConnectorTab';
import StorageTab from './StorageTab/StorageTab';
import { UsageFilterProps } from './UsageTabLayout/UsageFilter/UsageFilter';
import useUsageFetch from './useUsageFetch';

export const SUMMARY_TAB = 'summary';
export const CONNECTOR_TAB = 'connector';
export const COMPUTE_TAB = 'compute';
export const STORAGE_TAB = 'storage';
export const ESTIMATOR_TAB = 'estimator';

export type UsageTab =
  | typeof SUMMARY_TAB
  | typeof CONNECTOR_TAB
  | typeof COMPUTE_TAB
  | typeof STORAGE_TAB;

export interface CommonTabProps extends Omit<UsageFilterProps, 'tabType'> {
  loading: boolean;
  error: string;
}

interface MatchParams {
  tab: string;
}

interface UsageProps extends RouteComponentProps<MatchParams> {}

export default function Usage(props: UsageProps) {
  /******************** State that controls what data is fetched from the API ********************/
  const [interval, setInterval] = useState<UsageInterval>('daily');
  const [startDate, setStartDate] = useState(defaultStartDay());
  const [endDate, setEndDate] = useState(defaultEndDay());
  const [connectorID, setConnectorID] = useState('');
  const [companyPlanByMonth, setCompanyPlanByMonth] = useState<CompanyPlanRow[] | null>(null);
  const [billingError, setBillingError] = useState('');

  const {
    userProfile: {
      company: { database_account },
    },
  } = useUserProfile();

  const isByos = database_account?.type === 'snowflake' && database_account.is_byos;

  let tabs = isByos ? [CONNECTOR_TAB] : [SUMMARY_TAB, CONNECTOR_TAB, COMPUTE_TAB, STORAGE_TAB]; // The order here matters

  useEffect(() => {
    if (isByos === false) {
      setBillingError('');
      const api = new API();
      api
        .post('/api/usages/billing_monthly', {
          start_date: formatApiDate(A_YEAR_AGO),
          end_date: formatApiDate(TODAY),
        })
        .then((response) => {
          const structuredRows = parseSqlResults(response.data.results) as CompanyPlanRow[];
          setCompanyPlanByMonth(structuredRows);
          return structuredRows;
        })
        .catch((e) => {
          setBillingError('There was a problem fetching summary usage data.');
          return Promise.reject(e);
        });
    }
  }, [isByos]);

  const currentInvoice = useMemo(() => {
    return companyPlanByMonth?.find(({ IS_CURRENT_MONTH }) => IS_CURRENT_MONTH);
  }, [companyPlanByMonth]);

  const estimatorExperimentEnabled = useBooleanFlag('estimator_tab', false);
  // We don't want to show the estimator tab for BYOS accounts, or customers who don't have a usage-based plan of any kind
  const showEstimatorTab =
    isByos === false &&
    (currentInvoice?.IS_USAGE ||
      currentInvoice?.IS_SNOWFLAKE_OVERAGE ||
      currentInvoice?.IS_FIVETRAN_OVERAGE ||
      ['Sonata Free', 'Sonata Usage'].includes(currentInvoice?.PLAN_TYPE_MOST_RECENT || '') ||
      estimatorExperimentEnabled);

  if (showEstimatorTab) {
    tabs = [...tabs, ESTIMATOR_TAB];
  }

  const { currentTab, handleSelectTab } = useTabs(
    tabs,
    isByos ? CONNECTOR_TAB : SUMMARY_TAB,
    '/usage/',
    'Usage',
    props.match.params.tab,
    'Usage',
  );

  const { loading, error, connectorUsage, connectorConnectorTablesUsage, computeUsage, storageUsage } =
    useUsageFetch(currentTab, interval, startDate, endDate, connectorID);

  const handleChangeTab = (tab: string) => {
    if (tab !== CONNECTOR_TAB) {
      setConnectorID('');
    }
    handleSelectTab(tab);
  };

  const handleSetInterval = (interval: UsageInterval) => {
    setInterval(interval);
    if (interval === 'monthly') {
      setConnectorID('');
      setStartDate(defaultStartMonth());
      setEndDate(defaultEndMonth());
    } else {
      setStartDate(defaultStartDay());
      setEndDate(defaultEndDay());
    }
  };

  const handleSetRange = (startDate: string, endDate: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const commonTabProps: CommonTabProps = {
    loading,
    error,
    interval,
    startDate,
    endDate,
    setInterval: handleSetInterval,
    setDateRange: handleSetRange,
  };

  return (
    <div className="px-40">
      {billingError && (
        <Alert className="mt-4" variant="error">
          {billingError}
        </Alert>
      )}
      <h1 className="w-full py-6 text-pri-gray-700 display-sm">Usage</h1>
      <TabRow tabs={tabs} selectedTab={currentTab} onClick={handleChangeTab} />
      {currentTab === SUMMARY_TAB && (
        <>
          {companyPlanByMonth === null ? (
            <CenteredSpinner containerClass="mt-12"></CenteredSpinner>
          ) : (
            <SummaryTab currentInvoice={currentInvoice} companyPlanByMonth={companyPlanByMonth} />
          )}
        </>
      )}
      {currentTab === CONNECTOR_TAB && (
        <ConnectorTab
          {...commonTabProps}
          connectorID={connectorID}
          connectorUsage={connectorUsage}
          connectorTablesUsage={connectorConnectorTablesUsage}
          setConnectorID={setConnectorID}
        />
      )}
      {currentTab === COMPUTE_TAB && <ComputeTab {...commonTabProps} computeUsage={computeUsage} />}
      {currentTab === STORAGE_TAB && <StorageTab {...commonTabProps} storageUsage={storageUsage} />}
      {currentTab === ESTIMATOR_TAB && (
        <>
          {companyPlanByMonth === null ? (
            <CenteredSpinner containerClass="mt-12"></CenteredSpinner>
          ) : (
            <EstimatorTab
              currentInvoice={companyPlanByMonth.find(({ IS_CURRENT_MONTH }) => IS_CURRENT_MONTH)}
            />
          )}
        </>
      )}
    </div>
  );
}

function defaultStartMonth() {
  const today = parseApiDate(formatApiDate(new Date())) as Date;
  const firstDayOfMonthsAgo = setDate(subMonths(today, 11), 1);
  return formatApiDate(firstDayOfMonthsAgo);
}

function defaultEndMonth() {
  const today = parseApiDate(formatApiDate(new Date())) as Date;
  const lastDay = lastDayOfMonth(today);
  return formatApiDate(lastDay);
}

function defaultStartDay() {
  const today = parseApiDate(formatApiDate(new Date())) as Date;
  const firstDay = startOfMonth(today);
  return formatApiDate(firstDay);
}

function defaultEndDay() {
  const today = parseApiDate(formatApiDate(new Date())) as Date;
  return formatApiDate(today);
}
