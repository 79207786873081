import { useMeasure } from 'react-use';

import CodeEditor from 'components/inputs/basic/CodeEditor/CodeEditor';

interface SqlTabProps {
  sql: string;
}

export default function SqlTab(props: SqlTabProps) {
  const { sql } = props;

  const [codeEditorContainerRef, { height: codeEditorHeight }] = useMeasure<HTMLDivElement>();

  return (
    <div ref={codeEditorContainerRef} className="w-full h-full">
      {codeEditorHeight > 0 && (
        <CodeEditor value={sql} height={`${codeEditorHeight}px`} mode="sql" disableGutters={false} />
      )}
    </div>
  );
}
