import { useState, useEffect } from 'react';

import { EditorType } from 'components/query/useQueryEditor';
import { clamp } from 'utils/Math';

const PADDING_HEIGHT = 4;
const ROW_HEIGHT = 16.8;
const MIN_HEIGHT = 0;
function calcEditorHeight(rowCount: number) {
  return rowCount * ROW_HEIGHT + PADDING_HEIGHT * 2;
}
function toEvenRowHeight(height: number) {
  return calcEditorHeight(Math.floor(height / ROW_HEIGHT));
}

interface UseEditorHeightProps {
  editorType: EditorType;
  containerHeight: number;
  autoRun?: boolean;
  editorSql: string;
  setEditorHeight: (height: number) => void;
}

const useEditorHeight = (props: UseEditorHeightProps) => {
  const { editorType, containerHeight, autoRun, editorSql, setEditorHeight } = props;
  const [hasSetDefaultHeight, setHasSetDefaultHeight] = useState<boolean>(false);

  // Sets the initial editor height if autoRun is true
  useEffect(() => {
    // Only update the height if the container height > 0 so we do not update height while the tab is hidden
    if (containerHeight > 0) {
      if (!hasSetDefaultHeight) {
        if (editorType === 'sql' && editorSql !== '' && !!autoRun) {
          // If in autoRun mode make the query window as small as possible so the user can read RunResults.
          let sqlLines = editorSql.split('\n').length + 2; // Add 2 additional lines for padding
          sqlLines = Math.max(sqlLines, 5); // Make sure editor is always noticeable.
          const sqlHeight = calcEditorHeight(sqlLines);
          const maxSqlAutorunRatio = 0.5;
          const maxSqlExpand = toEvenRowHeight(containerHeight * maxSqlAutorunRatio);
          const newEditorHeight = clamp(sqlHeight, MIN_HEIGHT, maxSqlExpand);

          setEditorHeight(newEditorHeight);
        }
        setHasSetDefaultHeight(true);
      }
    }
  }, [editorType, editorSql, containerHeight, autoRun, hasSetDefaultHeight, setEditorHeight]);
};

export default useEditorHeight;
