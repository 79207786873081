import React, { useState, useEffect } from 'react';

import API from 'api/API';
import IconButton from 'components/inputs/basic/Button/IconButton';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import SortableTable, { TableHeader } from 'components/tables/SortableTable/SortableTable';
import Accordion from 'components/widgets/accordions/Accordion/Accordion';

import DatabaseListingAccountModal from './DatabaseListingAccountModal';
import UpdateAccountModal from './UpdateAccountModal';
import { SnowflakeAccount, SnowflakeDatabase, fetchMozartAccounts } from './useAccounts';

const columns: TableHeader[] = [
  { label: 'Organization', key: 'organization' },
  { label: 'Name', key: 'name' },
  { label: 'Provider', key: 'provider' },
  { label: 'Region', key: 'region' },
  { label: 'Locator', key: 'locator' },
  { label: 'Identifier', key: 'identifier' },
  { label: 'Database', key: 'database' },
  { label: 'UUID', key: 'id' },
  { label: 'Actions' },
];

interface AccountRowProps {
  account: SnowflakeAccount;
}

interface TableProps {
  columns: TableHeader[];
  items: SnowflakeAccount[];
}

export default function AccountsTab() {
  const [activeAccounts, setActiveAccounts] = useState<SnowflakeAccount[]>([]);
  const [deletedAccounts, setDeletedAccounts] = useState<SnowflakeAccount[]>([]);
  const [confirmDeleteAccount, setConfirmDeleteAccount] = useState<SnowflakeAccount | null>(null);
  const [deletingAccount, setDeletingAccount] = useState<SnowflakeAccount | null>(null);
  const [modalOpenAccount, setModalOpenAccount] = useState<SnowflakeAccount | null>(null);
  const [databaseListingModalOpenAccount, setDatabaseListingModalOpenAccount] =
    useState<SnowflakeAccount | null>(null);
  const [isDeletedAccountsExpanded, setIsDeletedAccountsExpanded] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const onOpenDatabaseListingModal = (account: SnowflakeAccount) => {
    setDatabaseListingModalOpenAccount(account);
  };

  const onCloseDatabaseListingModal = () => {
    setDatabaseListingModalOpenAccount(null);
  };

  const onOpenModal = (account: SnowflakeAccount) => {
    setModalOpenAccount(account);
  };

  const onCloseModal = () => {
    setModalOpenAccount(null);
  };

  const onDeleteAccount = (account: SnowflakeAccount) => {
    setConfirmDeleteAccount(account);
  };

  const onCancelDeleteAccount = () => {
    setConfirmDeleteAccount(null);
  };

  const onConfirmDeleteAccount = () => {
    setErrorMessage('');
    setDeletingAccount(confirmDeleteAccount);
    const api = new API();
    api
      .delete(`/api/snowflake_accounts/${confirmDeleteAccount!.id}`, {})
      .then(() => {
        setActiveAccounts(activeAccounts.filter((account) => account.id !== confirmDeleteAccount!.id));
        setDeletedAccounts([confirmDeleteAccount!, ...deletedAccounts]);
      })
      .catch((error) => {
        setErrorMessage('Failed to delete SnowflakeAccount model object.');
      })
      .finally(() => {
        setDeletingAccount(null);
        setConfirmDeleteAccount(null);
      });
  };

  const onDeletedAccountsExpand = (newExpand: boolean) => {
    setIsDeletedAccountsExpanded(newExpand);
  };

  function renderDatabases(databases: SnowflakeDatabase[]): JSX.Element {
    if (databases.length === 0) {
      return <span className="text-gray-400">N/A</span>;
    } else if (databases.length === 1) {
      return <span>{databases[0].name}</span>;
    } else {
      const hoverText = databases.map((db, index) => `${index + 1}: ${db.name}`).join('\n');
      return <span title={hoverText}>{`${databases.length} databases`}</span>;
    }
  }

  function AccountRow(props: AccountRowProps) {
    const { account } = props;
    return (
      <tr key={account.id}>
        <td key="organization" className="flex-1 text-center border-r">
          {account.organization_name || '???'}
        </td>
        <td key="name" className="flex-1 text-center border-r">
          {account.name}
          <IconButton
            icon="Files"
            variant="lightDullTransparent"
            size="small"
            onClick={() => onOpenDatabaseListingModal(account)}
          />
        </td>
        <td key="provider" className="flex-1 text-center">
          {account.provider}
        </td>
        <td key="region" className="flex-1 text-center">
          {account.region}
        </td>
        <td key="locator" className="flex-1 text-center">
          {account.locator}
        </td>
        <td key="identifier" className="flex-1 text-center border-r">
          {account.identifier}
        </td>
        <td key="database" className="flex-1 text-center">
          {renderDatabases(account.databases)}
        </td>
        <td key="id" className="flex-1 text-center">
          {account.id}
        </td>
        {account.deleted ? null : (
          <td key="actions" className="flex-1 text-center">
            <IconButton
              icon="PencilSquare"
              variant="lightDullTransparent"
              size="small"
              onClick={() => onOpenModal(account)}
            />
            <IconButton
              icon="Trash"
              variant="lightDullTransparent"
              size="small"
              spinning={deletingAccount === account}
              onClick={() => onDeleteAccount(account)}
            />
          </td>
        )}
      </tr>
    );
  }

  const SortableAccountsTable: React.FC<TableProps> = ({ columns, items }) => {
    return (
      <SortableTable
        className="mt-4"
        headers={columns.map((column) => {
          if (column.key) {
            return {
              key: column.key,
              label: column.label,
              divClassName: 'w-min',
              THClassName: '!whitespace-normal',
              THAlign: 'center',
              getValueToSortOn: (account: SnowflakeAccount) =>
                account?.[column.key as keyof SnowflakeAccount] ?? '',
            };
          }
          return { label: column.label };
        })}
        type="blueGrayHeaderTable"
        items={items}
        defaultSortConfig={{ key: 'name', direction: 'descending' }}
        renderRow={(account: SnowflakeAccount) => <AccountRow account={account} />}
      />
    );
  };

  useEffect(() => {
    (async () => {
      try {
        const fetchedAccounts = await fetchMozartAccounts();
        setActiveAccounts(fetchedAccounts.filter((account) => !account.deleted));
        setDeletedAccounts(fetchedAccounts.filter((account) => account.deleted));
      } catch (error) {
        setErrorMessage('Failed to load accounts list.');
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorMessage) {
    return <div>Error: {errorMessage}</div>;
  }

  // Expand if user is searching in filter or user explicitly expands deactivated accounts.
  // Note (todo?): we don't have a filter currently. Still there is a null vs undefined issue in play.
  const isAccordionOpen = isDeletedAccountsExpanded === true;

  return (
    <div className="ml-4 w-full">
      <SortableAccountsTable columns={columns} items={activeAccounts} />
      {deletedAccounts.length > 0 && (
        <Accordion
          header="Deleted Accounts"
          startsExpanded={isAccordionOpen}
          onClick={onDeletedAccountsExpand}
          className="mt-10 mx-[-1.5rem] mb-0 w-full"
        >
          <SortableAccountsTable
            columns={columns.filter((column) => column.key !== 'actions')}
            items={deletedAccounts}
          />
        </Accordion>
      )}
      {databaseListingModalOpenAccount && (
        <DatabaseListingAccountModal
          account={databaseListingModalOpenAccount}
          closeModal={onCloseDatabaseListingModal}
        />
      )}
      {modalOpenAccount && (
        <UpdateAccountModal
          account={modalOpenAccount}
          providerError={'Select Snowflake'}
          providerLoading={false}
          providerOptions={[
            { value: 'snowflake', label: 'SNOWFLAKE' },
            { value: 'bigquery', label: 'BIGQUERY' },
          ]}
          closeModal={onCloseModal}
        />
      )}
      {confirmDeleteAccount !== null && (
        <ConfirmModal
          header={
            <span className="text-lg font-medium">
              Are you sure you want to delete the SnowflakeAccount model{' '}
              {confirmDeleteAccount ? confirmDeleteAccount.id : '...'}?
            </span>
          }
          confirmText="Delete"
          confirmVariant="darkDanger"
          onCancel={onCancelDeleteAccount}
          onConfirm={onConfirmDeleteAccount}
          saving={deletingAccount !== null}
        />
      )}
    </div>
  );
}
