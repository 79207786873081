import IconStep from '../GuideStep/IconStep/IconStep';

interface UsefulLinksProps {
  isOpen: boolean;
  onToggleOpenStep(): void;
}

export default function UsefulLinks(props: UsefulLinksProps) {
  const { isOpen, onToggleOpenStep } = props;

  return (
    <IconStep
      icon="Link45deg"
      label="Useful Links"
      isOpen={isOpen}
      className="border-b-[1px] border-white"
      onClick={onToggleOpenStep}
    >
      <div className="w-full px-4 pb-4 flex flex-col">
        <UsefulLink
          label="Help Docs"
          href="https://help.mozartdata.com/docs"
          segmentEvent="CompanySetupGuide LinkToHelpDocs"
        />
        <UsefulLink
          label="Warehouse"
          href="https://help.mozartdata.com/docs/warehouse"
          segmentEvent="CompanySetupGuide LinkToWarehouseDocs"
        />
        <UsefulLink
          label="Snapshotting"
          href="https://help.mozartdata.com/docs/snapshotting"
          segmentEvent="CompanySetupGuide LinkToSnapshottingDocs"
        />
        <UsefulLink
          label="Data Alerts"
          href="https://help.mozartdata.com/docs/data-alerts"
          segmentEvent="CompanySetupGuide LinkToDataAlertsDocs"
        />
        <UsefulLink
          label="Mozart Data Users & Roles"
          href="https://help.mozartdata.com/docs/users"
          segmentEvent="CompanySetupGuide LinkToUserDocs"
        />
        <UsefulLink
          label="Optimizing Usage from the Start"
          href="https://help.mozartdata.com/docs/optimizing-usage-from-the-start"
          segmentEvent="CompanySetupGuide LinkToOptimizingUsageFromTheStartDocs"
        />
        <UsefulLink
          label="FAQs"
          href="https://help.mozartdata.com/docs/faq"
          segmentEvent="CompanySetupGuide LinkToFAQDocs"
        />
      </div>
    </IconStep>
  );
}

export const UsefulLink = (props: { label: string; href: string; segmentEvent: string }) => (
  <a
    className="text-sm text-sec-blue-light-700 hover:underline"
    href={props.href}
    target="_blank"
    rel="noreferrer"
    data-track={props.segmentEvent}
  >
    {props.label}
  </a>
);
