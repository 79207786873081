import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from 'components/inputs/basic/Button/Button';
import PasswordFormikGroup from 'components/inputs/formik_group/PasswordFormikGroup/PasswordFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import Alert from 'components/widgets/alerts/Alert/Alert';

import cls from '../SetupPage.module.css';
import { ByosFormProps } from '../useSetupWarehouse';

import sqlCommands from './SetupBYOSCommands';
import SQLBox from './SQLBox/SQLBox';

interface SetupBYOSViewProps {
  handleSubmit: (values: ByosFormProps) => void;
  handleGoBack: () => void;
  isLoading: boolean;
  error?: string;
}

export default function SetupBYOSView(props: SetupBYOSViewProps) {
  const { isLoading, handleSubmit, handleGoBack, error } = props;
  const SetupSnowflakeSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
    account_identifier: Yup.string().required('Required'),
  });

  return (
    <CenteredContainer
      header={
        <>
          <h3 className="text-primary-600 pb-2">ADMIN CREDENTIALS REQUIRED</h3>
          <h1 className="display-sm text-pri-gray-700">
            Follow the instructions to connect your Snowflake data warehouse to Mozart Data
          </h1>
          <Alert variant="info" className="!items-start my-4">
            <p className="mb-4">
              You will need permissions to use the <strong> ACCOUNTADMIN</strong> role on Snowflake.
            </p>

            <p>If you have a Snowflake network policy enabled, please allow these addresses access: </p>
            <span>
              <code className="inline-block w-auto bg-white mr-4 px-2 my-2">34.29.78.127/32</code>
              <code className="inline-block w-auto bg-white px-2 my-2">34.27.39.101/32</code>
            </span>
            <p>
              To learn how to set up a network policy,
              <a
                className="pl-2 text-sec-blue-gray-500 underline"
                href="https://docs.snowflake.com/en/user-guide/network-policies"
                target="_blank"
                rel="noreferrer"
              >
                read these instructions
              </a>
            </p>
          </Alert>
        </>
      }
      loading={false}
      maxWidth="814px"
    >
      <Formik
        initialValues={{ password: '', account_identifier: '' }}
        onSubmit={handleSubmit}
        validationSchema={SetupSnowflakeSchema}
      >
        {({ handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <ol>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>
                  Enter the Snowflake account URL for your organization (
                  <a
                    href="https://help.mozartdata.com/docs/setting-up-data-warehouse#AccountIdentifier"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={cls.setupStepsItemTitleLink}
                    data-track="Signup ClickBYOSAccountIdentifierHelp"
                  >
                    see docs
                  </a>
                  ).
                </p>
                <TextFormikGroup
                  autoFocus
                  type="text"
                  disabled={isLoading}
                  className="w-[445px]"
                  name="account_identifier"
                  placeholder="https://<acct_identifier>.snowflakecomputing.com"
                  label={''}
                  groupClass="mt-4"
                />
              </li>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>
                  Set a password for the MOZARTADMIN Snowflake user.
                </p>
                <PasswordFormikGroup
                  name="password"
                  disabled={isLoading}
                  placeholder=""
                  className="w-[445px]"
                  subscriptDetail="This will be used for Mozart Data to access the database."
                  subscriptDetailClass={cls.passwordSubscript}
                  groupClass="my-4"
                />
              </li>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>
                  Copy and run the SQL below in the Snowflake query runner. Remember to replace
                  &lt;mypassword&gt; with the secure password you entered above.
                </p>
                <SQLBox sqlCommands={sqlCommands} copyEvent="Signup CopyBYOSSQL" />
              </li>
            </ol>
            <div className="mt-4 flex justify-end">
              <Button onClick={handleGoBack} disabled={isLoading}>
                Back
              </Button>
              <Button
                className="ml-4"
                type="submit"
                disabled={isLoading || !isValid}
                spinning={isLoading}
              >
                Set Up
              </Button>
            </div>
            {error && (
              <Alert variant="error" className="mt-4">
                {error}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </CenteredContainer>
  );
}
