import { AppConnector, UserProfile } from 'api/APITypes';
import ConnectorIcon from 'components/primitives/icons/ConnectorIcon/ConnectorIcon';
import BrokenSetup from 'pages/connectors/ListConnectors/BrokenSetup';

import { conName } from '../../ConnectorRegistry';

import { FivetranConnectorSetupState } from '../useFivetranConnectorSetup';

import ConnectorStatusIndicator from './ConnectorStatusIndicator';

export interface ConnectorHeaderProps {
  connector: AppConnector;
  userProfile: UserProfile;
  connectorSetup: FivetranConnectorSetupState;
}

export default function ConnectorHeader(props: ConnectorHeaderProps) {
  const { connector, userProfile, connectorSetup } = props;
  const {
    service,
    schema,
    anyStatusError,
    setupState,
    syncState,
    lastRun: { lastRunFailed, lastRunAt },
    isHistoricalSync,
  } = connector;

  const getStatusMarkup = () => {
    if (anyStatusError) {
      return (
        <BrokenSetup
          anyStatusError={anyStatusError}
          userProfile={userProfile}
          actionLoading={connectorSetup.loading}
          handleCompleteConnector={() => connectorSetup.handleCompleteConnector('ConnectorHeader')}
          handleFixConnector={() => connectorSetup.handleFixConnector('ConnectorHeader')}
          textSizeClass="text-md"
        />
      );
    }
    return (
      <ConnectorStatusIndicator
        {...{
          anyStatusError,
          setupState,
          syncState,
          lastRunFailed,
          isHistoricalSync,
          lastRunAt,
        }}
      />
    );
  };

  return (
    <div>
      <div className="f-between">
        <div className="flex items-center space-x-2">
          <ConnectorIcon service={service} size={24} />
          <div className="text-base font-medium text-pri-gray-700">{schema}</div>
          <div className="font-small text-pri-gray-500">{conName(service)}</div>
          <div>
            <div className="h-[28px] w-[1px] mx-2 bg-pri-gray-200" />
          </div>
          {getStatusMarkup()}
        </div>
      </div>
    </div>
  );
}
