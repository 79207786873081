import React, { useContext, useState } from 'react';

import { AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';

import tableAPI, { AggTable } from 'api/tableAPI';
import tagAPI, { Tag, SaveableTagProps } from 'api/tagAPI';
import Button from 'components/inputs/basic/Button/Button';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useDatabaseAccount } from 'context/AuthContext';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import ColorInput from 'pages/tags/ListTags/ColorInput';
import { randomColor } from 'pages/tags/ListTags/ColorPicker';
import { EditTagFormWrapper } from 'pages/tags/ListTags/EditTagForm';
import TagPill, { TagEventPage } from 'pages/tags/TagPill';

export interface CreateTagModalProps {
  table: AggTable;
  eventPage: TagEventPage;
  onAfterCreate: () => void;
  onCancel: () => void;
}

export default function CreateTagModal(props: CreateTagModalProps) {
  const { table, eventPage, onAfterCreate, onCancel } = props;
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { tags, addTag, updateTables } = useContext(TableModelsContext);
  const databaseType = useDatabaseAccount().type;

  const initialValues: SaveableTagProps = {
    name: '',
    description: '',
    color: randomColor(),
  };

  const handleSaveNewTag = (
    saveableProps: SaveableTagProps,
    formikHelpers: FormikHelpers<SaveableTagProps>,
  ) => {
    setSaving(true);
    tagAPI
      .add(saveableProps)
      .then((response: AxiosResponse<Tag>) => {
        const newTag = response.data;
        addTag(newTag);
        return newTag;
      })
      .then((newTag) => {
        const newTagIDs = [...table.tags, newTag.id];
        return tableAPI.setTags(table.id, newTagIDs, databaseType).then((response) => {
          const newTable = response.data;
          updateTables([newTable]);
          onAfterCreate();
          analytics.track(`${eventPage} CreateTagModalCreate`);
        });
      })
      .catch((error) => {
        // Form validation errors
        if (error.response?.status === 400) {
          const data = error.response.data;
          setErrorMessage(data.non_field_errors);
          formikHelpers.setErrors(data);
        } else {
          setErrorMessage('Error saving tag.');
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <EditTagFormWrapper
      {...props}
      tags={tags}
      initialValues={initialValues}
      saving={saving}
      errorMessage={errorMessage}
      editMode={false}
      handleSave={handleSaveNewTag}
      handleCancel={onCancel}
    >
      {(props) => {
        const {
          saving,
          errorMessage,
          handleCancel,
          handleSubmit,
          values,
          errors,
          isValid,
          dirty,
          fakeTag,
          showColorPicker,
          setShowColorPicker,
          colorPickerTarget,
          handleColorPickerSetColor,
          handleOpenColorPicker,
          handleColorChange,
        } = props;
        const modalFooter = (
          <div className="flex items-center">
            <Button variant="lightDanger" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="lightAction"
              className="ml-2"
              spinning={saving}
              disabled={!dirty || !isValid}
            >
              Save
            </Button>
          </div>
        );
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Modal
              header="Add Tag"
              footer={modalFooter}
              onClose={handleCancel}
              containerClass="w-[600px]"
            >
              <div className="p-4">
                <TagPill tag={fakeTag} eventPage={eventPage} disableLink={true} />
                <TextFormikGroup
                  name="name"
                  placeholder="Tag name"
                  autoFocus
                  maxLength={20}
                  className="mt-3"
                />
                <TextFormikGroup
                  name="description"
                  placeholder="Description (optional)"
                  maxLength={200}
                  className="mt-4"
                />
                <ColorInput
                  color={values.color}
                  showColorPicker={showColorPicker}
                  error={errors.color}
                  colorPickerTarget={colorPickerTarget}
                  className="mt-4"
                  onOpenColorPicker={handleOpenColorPicker}
                  onColorPickerSetColor={handleColorPickerSetColor}
                  onColorChange={handleColorChange}
                  setShowColorPicker={setShowColorPicker}
                />
                {errorMessage && (
                  <Alert variant="error" className="mt-4">
                    {errorMessage}
                  </Alert>
                )}
              </div>
            </Modal>
          </form>
        );
      }}
    </EditTagFormWrapper>
  );
}
