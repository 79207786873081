import { Text } from '@codemirror/state';

interface WordIterator extends Iterator<string> {}

/**
 * Efficiently traverses a Code Mirror Text in the specified direction for tokens across new lines.
 * @param {Text} text The Text to traverse.
 * @param {-1 | 1} direction -1 means search backwards. 1 means search forwards.
 * @returns {WordIterator} An iterator that give you the next token in the Text.
 */
export function wordIterator(text: Text, direction: 1 | -1): WordIterator {
  const lineIter = text.iter(direction);
  let line: string[] = [];

  return {
    next(): IteratorResult<string> {
      while (line.length === 0) {
        const nextLine = lineIter.next();
        if (nextLine.done) {
          return {
            value: '',
            done: true,
          };
        }
        if (nextLine.lineBreak) {
          continue;
        }
        line = nextLine.value.split(' ');
        if (direction === -1) {
          line = line.reverse();
        }
      }

      return {
        value: line.shift() as string,
        done: false,
      };
    },
  };
}
