import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { useTitle } from 'react-use';

import _ from 'lodash';

import API from 'api/API';
import { AggTable, SaveableTableProps } from 'api/APITypes';
import { convertTable } from 'api/tableAPI';
import WorkspaceLayout from 'components/layouts/pages/WorkspaceLayout/WorkspaceLayout';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useDatabaseAccount } from 'context/AuthContext';
import { SearchColumnContext } from 'model_layer/SearchColumnContext';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import { handleSqlErrors } from 'utils/apiResponseFormatter';

// import { useBooleanFlag } from 'hooks/useFeatureFlags';
import DatabaseSearch2 from './DatabaseSearch2/DatabaseSearch2';
import WelcomeNoData from './WelcomeNoData';

// import useLogPropChanges from 'hooks/useLogPropChanges';

export interface WarehouseProps extends RouteComponentProps {
  hiddenFilter?: string;
}

export default function Warehouse(props: WarehouseProps) {
  useTitle('Warehouse');
  const [error, setError] = useState('');

  /*****************************************************************************
   * Load Table Model Layer
   ****************************************************************************/
  const tableModelsContext = useContext(TableModelsContext);
  const {
    hasConnector,
    connectorsBySchema,
    tables, // AggTables with transforms and connectors appended by AggTableReducer.
    recentTables,
    favoriteTables,
    allLoaded,
    anyLocal,
    anyError,
    favoritesByTableID,
    tags,
    addFavorite,
    removeFavorite,
    updateTables,
    logRecent,
  } = tableModelsContext;

  const databaseType = useDatabaseAccount().type;

  // Comes in handy for debugging:
  // useLogPropChanges('WarehouseProps', props, true);
  // useLogPropChanges('TableModelsContext', tableModelContext, true);

  useEffect(() => {
    if (anyError) {
      setError(anyError);
    }
  }, [anyError]);

  // Every account gets information_schema before they sync a connector.
  // Show <WelcomeNoData/> component if information_schema is the user's only schema.
  const hasNonInfoSchemaTables = useMemo(() => {
    return _.some(tables, (t) => t.schema !== 'information_schema');
  }, [tables]);

  /*****************************************************************************
   * Load Columns from API
   * Columns are special because they only get used for search.
   * So they don't get dispatched to the Warehouse Reducer, and
   * they don't effect loading spinners.
   ****************************************************************************/
  const {
    searchColumnsByTableID,
    error: columnsError,
    // isLocal: columnsIsLocal,
    // isLoading: columnsIsLoading,
  } = useContext(SearchColumnContext);

  useEffect(() => {
    if (columnsError) {
      setError(columnsError);
    }
  }, [columnsError]);

  /*****************************************************************************
   * Log how many of each object type the user sees
   ****************************************************************************/
  const hasLoggedAPIs = useRef(false);
  useEffect(() => {
    if (allLoaded && !anyLocal && !hasLoggedAPIs.current) {
      hasLoggedAPIs.current = true;

      let schemas = _.uniqBy(tables, 'schema').length;

      analytics.track('Warehouse LoadedAPIs', {
        tables: tables.length,
        transforms: _.countBy(tables, (t) => t.type === 'transform').true,
        dbts: _.countBy(tables, (t) => t.type === 'dbt').true,
        snapshots: _.countBy(tables, (t) => t.type === 'snapshot').true,
        unmanaged: _.countBy(tables, (t) => t.type === 'unmanaged').true,
        connectors: Object.keys(connectorsBySchema).length,
        schemas,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        availableWidth: window.screen.availWidth,
        availableHeight: window.screen.availHeight,
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }
  }, [tables, connectorsBySchema, allLoaded, anyLocal]);

  /*****************************************************************************
   * Saving Tables(Currently just snapshot prop)
   ****************************************************************************/
  const saveTable = useCallback(
    (table: AggTable, tableProps: SaveableTableProps) => {
      setError('');
      const api = new API();

      const payload = {
        ...tableProps,
      };

      return api
        .patch(`api/tables/${table.id}`, payload, (table) => convertTable(table, databaseType))
        .then((response) => response.data as AggTable)
        .then((updatedTable) => {
          updateTables([updatedTable]);
          logRecent(table.id);
          return updatedTable;
        })
        .catch((e) => {
          if (e.response?.data?.snapshot) {
            const snapshotErrorSlug = e.response.data.snapshot[0];
            // First check for cannot_snapshot_snapshot_table
            if (snapshotErrorSlug === 'cannot_snapshot_snapshot_table') {
              setError('You are not allowed to toggle snapshots on for a snapshot table.');
            } else {
              setError(handleSqlErrors(snapshotErrorSlug));
            }
          } else if (e.response?.data?.schema) {
            setError(e.response.data.schema[0]);
          } else if (e.response?.data?.non_field_errors) {
            setError(e.response.data.non_field_errors);
          } else {
            setError('There was a problem saving the table.');
          }
          return Promise.reject(e);
        });
    },
    [updateTables, logRecent, databaseType],
  );

  // Not patching the warehouseCache because snapshot is going to be
  // one of the props we only show after the API returns.
  const setSnapshot = useCallback(
    (table: AggTable, snapshot: boolean) => {
      const tableProps = {
        snapshot,
      };
      return saveTable(table, tableProps);
    },
    [saveTable],
  );

  /*****************************************************************************
   * Render
   ****************************************************************************/
  if (allLoaded && !hasConnector && !hasNonInfoSchemaTables) {
    return <WelcomeNoData />;
  }

  return (
    <WorkspaceLayout basePath="/warehouse" spinning={!allLoaded}>
      {error && (
        <Alert variant="error" className="mt-4 mx-4">
          {error}
        </Alert>
      )}

      <DatabaseSearch2
        allTables={tables}
        recentTables={recentTables}
        favoriteTables={favoriteTables}
        tablesIsLocal={anyLocal}
        searchColumnsByTableID={searchColumnsByTableID}
        favoritesByTableID={favoritesByTableID}
        tags={tags}
        hiddenFilter={props.hiddenFilter}
        addFavorite={addFavorite}
        removeFavorite={removeFavorite}
        setSnapshot={setSnapshot}
        logRecent={logRecent}
      />
    </WorkspaceLayout>
  );
}
