import React from 'react';

import cn from 'classnames';

import IconButton, { IconButtonProps } from 'components/inputs/basic/Button/IconButton';

type TagPickerIconProps = Omit<IconButtonProps, 'icon' | 'customIcon'> & {
  tagPickerRef: React.RefObject<HTMLDivElement>;
  refKey: string;
  disabled?: boolean;
  iconSize?: number;
  containerClass?: string;
  onOpenTagPicker: (refKey: string) => void;
};

const TagPickerIcon = (props: TagPickerIconProps) => {
  const {
    tagPickerRef,
    refKey,
    disabled,
    iconSize = 18,
    containerClass,
    className,
    onOpenTagPicker,
    ...iconButtonProps
  } = props;

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onOpenTagPicker(refKey);
  };

  // Overlay.target does not accept a ref object of type RefObject<HTMLDivElement>.
  // So wrap, svg in div.
  return (
    <div ref={tagPickerRef} data-tpk={refKey} className={containerClass}>
      <IconButton
        icon="Tag"
        iconColor={disabled ? 'var(--pri-gray-300)' : 'var(--pri-gray-500)'}
        iconSize={iconSize}
        onClick={disabled ? undefined : handleOnClick}
        size="small"
        variant="lightDullTransparent"
        className={cn(className, { 'hover:cursor-pointer': !disabled })}
        {...iconButtonProps}
      />
    </div>
  );
};

export default TagPickerIcon;
