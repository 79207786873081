import React, { useContext } from 'react';

import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';
import { TableModelsContext } from 'model_layer/TableModelsContext';

import PipelineEditor, { Pipeline } from './PipelineEditor/PipelineEditor';

interface PipelineTabProps {
  pipeline: Pipeline | null;
  loadingPipeline: boolean;
  isDbtPipeline?: boolean;
}

export default function PipelineTab(props: PipelineTabProps) {
  const { pipeline, loadingPipeline, isDbtPipeline } = props;
  const { tablesByID, connectorsByID } = useContext(TableModelsContext);

  const TAB_HEIGHT = 36;

  return (
    <TabLayout
      loading={loadingPipeline}
      error={''}
      variant="no_padding"
      containerStyle={{ width: '100%', height: `calc(100% - ${TAB_HEIGHT}px)` }}
    >
      {pipeline && pipeline.vertices.length > 0 && (
        <PipelineEditor pipeline={pipeline} tablesByID={tablesByID} connectorsByID={connectorsByID} />
      )}
      {pipeline && pipeline.vertices.length === 0 && isDbtPipeline && (
        <FixMeAlert
          heading="This dbt job does not have a pipeline graph yet."
          detail={
            <p className="mt-2">
              You must run your dbt job to get a pipeline graph. To run your dbt job, go to the Runs tab
              and click the <code className="text-pri-gray-700">Run Manually</code> button.
            </p>
          }
          alertClass="m-4"
          additionalWordClass="max-w-[500px]"
        />
      )}
      {/* TODO(pipeline-improv): Add empty state? */}
    </TabLayout>
  );
}
