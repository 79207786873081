/*
 * Reusable Layout for Show[ObjectType] pages that have a header on top of tabs.
 */
import cn from 'classnames';

import TabRow, { TabKeyType } from 'components/layouts/parts/TabRow/TabRow';
import Alert from 'components/widgets/alerts/Alert/Alert';

export interface HeaderTabLayoutProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  header: React.ReactChild;
  tabs: TabKeyType[];
  currentTab: string;
  contentClass?: string;
  // A loading error will replace the entire page with an alert.
  // A form save should print an alert right above the form submit button.
  // This is for an error that doesn't fit cleanly into either place.
  afterLoadError?: string;
  onSelectTab(tab: string | null): void;
}

export default function HeaderTabLayout(props: HeaderTabLayoutProps) {
  const { header, tabs, currentTab, contentClass, afterLoadError, onSelectTab, children, ...divProps } =
    props;

  return (
    <div className="h-full f-col" {...divProps}>
      <div className="px-4 pt-4">
        {afterLoadError && (
          <Alert variant="error" className="mb-2">
            {afterLoadError}
          </Alert>
        )}
        {header}
      </div>
      {tabs.length > 0 && (
        <div className={cn('min-h-0 h-full f-col pt-4', contentClass)}>
          <TabRow tabs={tabs} selectedTab={currentTab} onClick={onSelectTab} leadingEdgeWidth={16} />
          {children}
        </div>
      )}
    </div>
  );
}
