import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function SnapshotInfoIcon(props: {
  tableFullName: string;
  snapshotTableFullName: string;
}) {
  const content = (
    <div>
      <div>
        <strong>Snapshotting saves a copy of a table every day at 3:05am PT.</strong>
      </div>
      <div className="mt-1">
        This saves{' '}
        <code className="ace-code">
          <span className="ace-keyword">{props.tableFullName}</span>
        </code>{' '}
        to{' '}
        <code className="ace-code">
          <span className="ace-keyword">{props.snapshotTableFullName}</span>
        </code>
      </div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} popoverProps={popoverProps} />;
}
