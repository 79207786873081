/*
  Controls tasks relevant to AIAssistantEditor in the larger QueryEditor.
*/
import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';

import API from 'api/API';
import { useUserProfile } from 'context/AuthContext';
import useAIPermissionModalContext from 'model_layer/useAIPermissionModalContext';

import useAIAssistantTablePicker, { AIAssistantTablePickerState } from './useAIAssistantTablePicker';
import { EditorRunProps } from './useSqlEditor';

export interface AIAssistantState {
  messages: AIAssistantChatMessage[];
  question: string;
  askingAssistant: boolean;
  assistantError: string;
  loadingChatHistory: boolean;
  cancellingQuestion: boolean;
  handleCancelQuestion: () => void;
  setSqlAndRun: (sql: string) => Promise<EditorRunProps>;
  actuallyShowChatAdvice: boolean;
  handleClickSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleChangeQuestion: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleKeySubmit: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  tablePicker: AIAssistantTablePickerState;
}

export interface AIAssistantChatMessage {
  role: 'user' | 'assistant';
  message: string;
}

interface AIAssistantProps {
  getLatestEditorSql: () => string;
  setSqlAndRun: (sql: string) => Promise<EditorRunProps>;
  showAIAssistant: () => void;
  chatReferenceObjectId: string;
  isHidden?: boolean;
}

export default function useAIAssistant(props: AIAssistantProps): AIAssistantState {
  const { getLatestEditorSql, setSqlAndRun, chatReferenceObjectId, isHidden, showAIAssistant } = props;
  const [askingAssistant, setAskingAssistant] = useState(false);
  const [assistantError, setAssistantError] = useState('');
  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState<AIAssistantChatMessage[]>([]);
  const [loadingChatHistory, setLoadingChatHistory] = useState(false);
  const [cancellingQuestion, setCancellingQuestion] = useState(false);

  const { userProfile } = useUserProfile();
  const {
    company: { allow_ai: allowAI },
  } = userProfile;

  const tablePickerState = useAIAssistantTablePicker();

  // When the model loads, if it includes a savedQueryId, try to get the chat history, otherwise start a new chat
  useEffect(() => {
    if (allowAI && !isHidden) {
      const params = `object_id=${chatReferenceObjectId}`;
      setLoadingChatHistory(true);
      const api = new API();

      api
        .get(`api/ai_pipeline_assistant?${params}&exists_only=true`)
        .then((resp) => {
          if (resp.data.exists) {
            showAIAssistant();
          }
        })
        .catch((e) => {
          // Do nothing for now
        });

      api
        .get(`api/ai_pipeline_assistant?${params}`)
        .then((resp) => {
          const chatHistory = resp.data['chat_history'] as AIAssistantChatMessage[];
          if (chatHistory.length > 0) {
            setMessages(chatHistory);
          }
        })
        .catch((e) => {
          setAssistantError('Unable to fetch chat history.');
        })
        .finally(() => {
          setLoadingChatHistory(false);
        });
    }
    // adding showAIAssistant to dependencies causes an infinite loop
  }, [allowAI, chatReferenceObjectId, isHidden]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAskAssistant = useCallback(
    async (question: string) => {
      setAskingAssistant(true);
      setAssistantError('');

      const api = new API();

      const postGeneratorData = {
        user_input: question,
        table_picker_tables: Object.values(tablePickerState.pickedTables).map(
          (table) => table.full_name,
        ),
        table_picker_schemas: Object.keys(tablePickerState.pickedSchemas),
        sql_editor: getLatestEditorSql(),
        object_id: chatReferenceObjectId,
      };
      api
        .post('api/ai_pipeline_assistant', postGeneratorData)
        .then((resp) => {
          setQuestion('');
          setMessages((prevChat) => {
            return [...prevChat, { role: 'assistant', message: resp.data['assistant_response'] }];
          });
        })
        .catch((e) => {
          if (e.response && e.response.data === 'MOZART_INTERNAL_ERROR_RUN_IN_PROGRESS') {
            setAssistantError(
              'There is already a run in progress. Please try waiting a minute or two and refreshing this page.',
            );
          } else if (e.response && e.response.data === 'MOZART_INTERNAL_ERROR_RUN_CANCELLED') {
            // If the run was cancelled, remove the last chat entry, which is the run that was cancelled's message
            setMessages((prevMessages) => prevMessages.slice(0, -1));
          } else {
            setAssistantError('There was a problem creating your SQL.');
          }
        })
        .finally(() => {
          setAskingAssistant(false);
          setCancellingQuestion(false);
        });
    },
    [
      chatReferenceObjectId,
      getLatestEditorSql,
      tablePickerState.pickedTables,
      tablePickerState.pickedSchemas,
    ],
  );

  const handleCancelQuestion = useCallback(async () => {
    setCancellingQuestion(true);

    const api = new API();

    const postGeneratorData = {
      object_id: chatReferenceObjectId,
    };
    api
      .post('api/ai_pipeline_assistant/cancel_ai_user_question', postGeneratorData)
      .then((resp) => {
        // Since this endpoint doesn't actually cancel the run, it just tells the other thread to cancel,
        //   we need to wait for the other thread to finish before we can remove the run in progress message
      })
      .catch((e) => {
        setAssistantError('There was a problem canceling the run.');
        setCancellingQuestion(false);
      });
  }, [chatReferenceObjectId]);

  const [showChatAdvice, setShowChatAdvice] = useState(true);
  const { setShowAIPermissionModal } = useAIPermissionModalContext();
  const {
    userProfile: {
      company: { allow_ai },
    },
  } = useUserProfile();

  const doSubmit = useCallback(
    (question: string) => {
      // If the user does not have permission to use the AI assistant, show the permission modal
      if (!allow_ai) {
        setShowAIPermissionModal(true);
      } else {
        setShowChatAdvice(false);
        setMessages((prevChat) => {
          return [...prevChat, { role: 'user', message: question }];
        });
        handleAskAssistant(question);
      }
    },
    [allow_ai, handleAskAssistant, setShowAIPermissionModal],
  );

  const handleClickSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      doSubmit(question);
    },
    [doSubmit, question],
  );

  const handleChangeQuestion = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(event.target.value);
  }, []);

  const handleKeySubmit = useCallback(
    (event: KeyboardEvent) => {
      if (!event.shiftKey && event.key === 'Enter' && question !== '') {
        doSubmit(question);
      }
    },
    [doSubmit, question],
  );

  const actuallyShowChatAdvice = showChatAdvice && messages.length === 0;

  return {
    messages,
    question,
    askingAssistant,
    assistantError,
    loadingChatHistory,
    cancellingQuestion,
    handleCancelQuestion,
    setSqlAndRun,
    actuallyShowChatAdvice,
    handleClickSubmit,
    handleChangeQuestion,
    handleKeySubmit,
    tablePicker: tablePickerState,
  };
}
