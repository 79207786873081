/*
This is a copy of every route in UnauthenticatedApp that does not overlap with
a route in AuthenticatedApp. AuthenticatedApp will forward the user to
the warehouse page instead of displaying a 404 if the user is logged in
and the route matches one of these routes.

This file is the logical inverse of authenticatedRoutes.ts. See the comments
at the top of that file for further explaination.
*/

// Same order as routes in UnauthenticatedApp.tsx
const unauthenticatedRoutes = [
  '/signup',
  '/signup-sonata',
  '/signup-thoughtspot',
  '/email-verification',
  '/experiments',
  '/login',
  '/request-reset',
  '/reset-password/:id',
  '/set-password/:id',
];

export default unauthenticatedRoutes;
