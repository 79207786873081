import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import cn from 'classnames';

import { AggTable } from 'api/APITypes';
import columnAPI, { Column, updateColumnCache } from 'api/columnAPI';
import Button from 'components/inputs/basic/Button/Button';
import TextAreaInput from 'components/inputs/basic/TextAreaInput/TextAreaInput';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';
import { patch } from 'utils/Array';

import { DBKeyInfoIcon } from './DBKeyInfoIcon';

import s from './SummaryTab.module.css';

interface ColumnsTableProps {
  table: AggTable;
  loadingColumns: boolean;
  columns: Column[] | null;
  setColumns: (columns: Column[]) => void;
}

export function ColumnsTable(props: ColumnsTableProps) {
  const { table, loadingColumns, columns, setColumns } = props;
  const [savingColumn, setSavingColumn] = useState(false);
  const [saveDescriptionError, setSaveDescriptionError] = useState('');
  const [selectedColumn, setSelectedColumn] = useState<Column | null>(null);
  const [description, setDescription] = useState<string>(
    selectedColumn ? selectedColumn.description : '',
  );
  const { userProfile } = useUserProfile();
  const databaseType = useDatabaseAccount().type;

  const handleSaveDescription = () => {
    setSaveDescriptionError('');
    setSavingColumn(true);
    if (columns && selectedColumn) {
      columnAPI
        .updateTableColumn(selectedColumn.id, { description: description }, databaseType)
        .then((response) => {
          analytics.track('SummaryTab UpdateColumn');
          const newColumns = patch(columns, response.data, (tt) => tt.id === selectedColumn.id);
          setColumns(newColumns);
          setSelectedColumn(null);
          updateColumnCache(table.id, newColumns);
        })
        .catch((e) => {
          setSaveDescriptionError('There was an error saving the description.');
        })
        .finally(() => {
          setSavingColumn(false);
        });
    }
  };

  const handleCloseColumnModal = () => {
    analytics.track(`SummaryTab CloseColumnModal`);
    setSaveDescriptionError('');
    setSelectedColumn(null);
  };

  const handleSelectColumn = (column: Column | null) => {
    analytics.track(`SummaryTab SelectColumn`);
    setDescription(column?.description || '');
    setSelectedColumn(column);
  };

  const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  let columnModal = null;
  if (selectedColumn) {
    columnModal = (
      <Modal
        header={<div>{selectedColumn.name}</div>}
        onClose={handleCloseColumnModal}
        containerClass="w-1/2"
      >
        <form>
          <div className="m-4 flex-col">
            <TextAreaInput
              name="columnDescription"
              autoFocus
              onFocus={(event: React.FocusEvent<HTMLTextAreaElement>) => {
                // Move cursor to end of textbox hack:
                const length = event.target.value.length;
                event.target.setSelectionRange(length, length);
              }}
              rows={7}
              placeholder="Column Description"
              onChange={handleChangeDescription}
              value={description}
              className="w-full"
            />
            {saveDescriptionError && (
              <div className="flex mt-1 text-pri-error-700 justify-end">{saveDescriptionError}</div>
            )}
            <div className="flex mt-2 justify-end">
              <Button
                variant="lightAction"
                onClick={handleSaveDescription}
                className="ml-2"
                style={{ width: '90px' }}
                spinning={savingColumn}
              >
                Save
              </Button>
              <Button
                variant="lightDanger"
                onClick={handleCloseColumnModal}
                className="ml-2"
                style={{ width: '90px' }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    );
  }

  return (
    <div className="flex-1 mr-2">
      {columnModal}
      <div className={s.statHeading}>Columns</div>
      <div className="mt-2">
        {loadingColumns ? (
          <CenteredSpinner containerMinHeight="30vh" />
        ) : (
          <>
            {columns !== null && columns.length > 0 ? (
              <table className="blueGrayHeaderTable">
                <thead>
                  <tr>
                    {databaseType === 'snowflake' && (
                      <th className="whitespace-nowrap" style={{ width: '5%' }}></th>
                    )}
                    <th className="whitespace-nowrap" style={{ width: '25%' }}>
                      Name
                    </th>
                    <th className="whitespace-nowrap" style={{ width: '10%' }}>
                      Type
                    </th>
                    <th className="whitespace-nowrap" style={{ width: '60%' }}>
                      <div className="f-row-y-center">
                        <div>Description</div>
                        <InfoIcon
                          content="Column descriptions saved in Mozart are written to Snowflake as comments on the column."
                          containerClass="ml-1"
                          popoverProps={{ style: { maxWidth: '700px' } }}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {columns
                    .sort((a, b) => a.ordinal_position - b.ordinal_position)
                    .map((column) => {
                      return (
                        <tr key={column.id} className="hover:bg-pri-gray-50">
                          {databaseType === 'snowflake' && (
                            <td>
                              <DBKeyInfoIcon column={column} />
                            </td>
                          )}
                          <td>{column.name}</td>
                          <td>{column.type}</td>
                          <td className="!p-0">
                            <div
                              className={cn('my-1 p-1 flex items-center', {
                                'rounded cursor-pointer hover:bg-pri-gray-100 active:bg-pri-gray-200':
                                  userProfile.company_role !== 'viewer',
                              })}
                              onClick={
                                userProfile.company_role === 'viewer'
                                  ? undefined
                                  : () => handleSelectColumn(column)
                              }
                            >
                              {column.description || (
                                <div className="items-center text-pri-gray-400">None</div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : (
              <FixMeAlert
                heading="This table has no columns."
                detail={
                  table.type === 'transform' &&
                  !table.transform?.last_completed_run && (
                    <p className="max-w-[400px] mt-2">
                      Go to this transform's{' '}
                      <Link
                        to={`/transforms/${table.transform?.id}/run`}
                        data-track="Warehouse TransformNeverRanScheduleLink"
                        className="text-sec-blue-light-700 font-medium"
                      >
                        Runs Tab
                      </Link>{' '}
                      and click the <code className="text-pri-gray-700">Run</code> button to create table{' '}
                      <code className="text-pri-gray-700">{table.full_name}</code>.
                    </p>
                  )
                }
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
