import React, { useEffect } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import API from 'api/API';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';

interface MatchParams {
  id: string;
  tab?: string;
}

interface RedirectTransformProps extends RouteComponentProps<MatchParams> {}

const RedirectTransform = (props: RedirectTransformProps) => {
  useTitle('Mozart: Redirecting...');
  let { id, tab } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let urlPath = `/404`;
    if (id) {
      const api = new API();
      api
        .get(`api/transforms/${id}/transform_and_table`)
        .then((response: any) => {
          const tableID = response.data.table.id;
          const invalidTab = !tab || tab === 'schedule';
          const actualTab = invalidTab ? 'transform' : tab;
          urlPath = `/tables/${tableID}/${actualTab}`;
        })
        .finally(() => {
          navigate(urlPath);
        });
    } else {
      navigate(urlPath);
    }
  }, [id, tab, navigate]);

  return <CenteredSpinner containerMinHeight="100vh" />;
};

export default RedirectTransform;
