import React from 'react';

import { ButtonVariant } from 'components/inputs/basic/Button/Button';
import ButtonGroup from 'components/inputs/basic/Button/ButtonGroup';
import IconButton from 'components/inputs/basic/Button/IconButton';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';

import { ViewMode } from '../TableExplorerReducer';

import s from './ViewModeToggle.module.css';

export const MIN_VIEW_TOGGLE_WIDTH = 5 * 46;

export interface ViewModeToggleProps {
  mode: ViewMode;
  showPinned?: boolean;
  onlyAutoPin?: boolean;
  setMode: (mode: ViewMode) => void;
}

const ViewModeToggle = (props: ViewModeToggleProps) => {
  const { mode, showPinned = true, onlyAutoPin = false, setMode } = props;

  // Props used by all IconButtons
  const variant = 'lightDullTab' as ButtonVariant;
  const sharedProps = {
    variant,
    iconSize: 20,
    className: s.iconButton,
    iconColor: 'DO_NOT_SET',
  };

  // The default delay is annoying when there are several adjacent hover targets
  // that you are quickly mousing over.
  // Make it slower.
  const delay = { show: 500, hide: 300 };

  return (
    <ButtonGroup variant={variant} className="w-full">
      <TooltipTrigger delay={delay} tip="All tables.">
        <IconButton
          icon={mode === 'schema' ? 'FolderFill' : 'Folder'}
          forceActive={mode === 'schema'}
          onClick={() => setMode('schema')}
          {...sharedProps}
        />
      </TooltipTrigger>
      {showPinned && (
        <TooltipTrigger
          delay={delay}
          tip={
            onlyAutoPin
              ? 'Tables used in your query.'
              : 'Tables used in your query or manually pinned by you.'
          }
        >
          <IconButton
            icon={mode === 'pinned' ? 'PinFill' : 'Pin'}
            forceActive={mode === 'pinned'}
            onClick={() => setMode('pinned')}
            {...sharedProps}
          />
        </TooltipTrigger>
      )}
      <TooltipTrigger delay={delay} tip="Your recent tables.">
        <IconButton
          icon={mode === 'recents' ? 'ClockFill' : 'Clock'}
          forceActive={mode === 'recents'}
          onClick={() => setMode('recents')}
          {...sharedProps}
        />
      </TooltipTrigger>
      <TooltipTrigger delay={delay} tip="Your favorite tables.">
        <IconButton
          icon={mode === 'favorites' ? 'StarFill' : 'Star'}
          forceActive={mode === 'favorites'}
          onClick={() => setMode('favorites')}
          {...sharedProps}
        />
      </TooltipTrigger>
      <TooltipTrigger delay={delay} tip="Tables grouped by tag.">
        <IconButton
          icon={mode === 'tag' ? 'TagFill' : 'Tag'}
          forceActive={mode === 'tag'}
          onClick={() => setMode('tag')}
          {...sharedProps}
        />
      </TooltipTrigger>
    </ButtonGroup>
  );
};

export default ViewModeToggle;
