/**
 * This file calculates a new SqlConfig everytime the API data changes.
 **/
import { useMemo } from 'react';

import { AggTable } from 'api/APITypes';
import { SearchColumnsByTableID } from 'api/searchColumnAPI';
import snowflakeDatatypes from 'utils/SnowflakeDataTypes';
import reservedWords from 'utils/SnowflakeReservedWords';

import { Completion } from '@codemirror/autocomplete';
import { SQLDialect, SQLConfig, SQLNamespace } from '@codemirror/lang-sql';
import { foldNodeProp, foldInside } from '@codemirror/language';

import { tableToCompletion } from './objectToCompletion';

const SnowflakeDialect = SQLDialect.define({
  types: snowflakeDatatypes.join(' '),
  keywords: reservedWords.join(' '),
});

// Make our SQLDialect fold on parentheses blocks.
const originalParser = SnowflakeDialect.language.parser;
const hackedParser = originalParser.configure({
  props: [
    foldNodeProp.add({
      // Disable code folding on statements(ie the first line of a query).
      Statement(tree) {
        return null;
      },
      Parens: foldInside,
    }),
  ],
});
// @ts-ignore This parser is not readonly because we are hacking it.
SnowflakeDialect.language.parser = hackedParser;

const useSqlConfig = (queryableTables: AggTable[], searchColumnsByTableID: SearchColumnsByTableID) => {
  // Dictionary of column completions by table name:
  // https://github.com/codemirror/lang-sql#user-content-sqlconfig.schema
  // { "schema.table": [ {label, type, detail} ]}
  // CodeMirror is expecting the value of each key to be an array of columns.
  // It is not practical for us do this for hundreds of thousands of columns,
  // but we still need to return this object's keys to get completions on the
  // schema and tables.
  // Newer versions of CodeMirror do this slightly differently.
  // This code may change.
  const tableCompletionsByFullName: SQLNamespace = useMemo(() => {
    // This empty array takes the place of the array of columns in the table that CodeMirror is expecting.
    // This array disables column completion after the user types `schema.table.`.
    const sharedEmptyArray: readonly (string | Completion)[] = [];
    const result: SQLNamespace = {};
    queryableTables.forEach(({ full_name }) => {
      result[full_name] = sharedEmptyArray;
    });

    return result;
  }, [queryableTables]);

  const tableCompletions: readonly Completion[] = useMemo(
    () => queryableTables.map(tableToCompletion),
    [queryableTables],
  );

  const sqlConfig: SQLConfig = useMemo(
    () => ({
      // `schema` is CodeMirror's implementation of completing any part of "schema.table.column".
      // It does not complete schemas because we disabled that by passing an empty array.
      // CodeMirror automatically creates the `Completion` object for schemas and tables.
      // We cannot override this without defining all of the completion objects at editor load and
      // that would probably be too expensive considering one company has 10,000 tables.
      // Although, we do that in `tableCompletions` so it might not be that bad.
      // It does not read the `Completion` objects we put in the `tables` key.
      // As a result, we cannot set `detail` and `boost` for schemas.
      // See: `childCompletions` on https://github.com/codemirror/lang-sql/blob/main/src/complete.ts#L124 sets default boost.
      schema: tableCompletionsByFullName,

      // Including this allows you to complete on just a table name as in `commit`
      // instead of just a schema name followed by a table name as in `github.com...`
      tables: tableCompletions,
      dialect: SnowflakeDialect,
      upperCaseKeywords: true,
    }),
    [tableCompletionsByFullName, tableCompletions],
  );

  return sqlConfig;
};

export default useSqlConfig;
