import { queryUrl } from 'components/query/queryUtil';

import { PromotionKey } from '../../../promotionBannerState';

import AbstractPromotionBanner, { ConcretePromotionBannerProps } from './AbstractPromotionBanner';

export const AI_ASSISTANT_PROMOTION_KEY: PromotionKey = 'hasSeenPromotionAIAssistantBanner';
export const AI_ASSISTANT_EVENT_LOCATION = 'HeaderAIAssistantPromotionBanner';

export interface AIAssistantPromotionBannerProps extends ConcretePromotionBannerProps {}

export const AIAssistantPromotionBanner = (props: AIAssistantPromotionBannerProps) => {
  return (
    <AbstractPromotionBanner {...props} eventLocation={AI_ASSISTANT_EVENT_LOCATION}>
      <span className="flex items-center">
        <span className="font-bold">🎉 New: Write SQL faster with our AI Assistant!</span>
        {/* Used a href instead of Link cuz I want to open a new Query tab even if we are already on the Query page
        If you use Link and click the banner from the Query page, your browser goes to
        http://localhost:3000/query?query=&showAIAssistant=true but a new query is not opened, nor is the AI assistant opened. */}
        <a
          href={queryUrl('', false, true)}
          data-track={`${AI_ASSISTANT_EVENT_LOCATION} TryAIAssistant`}
          className="ml-1 underline hover:pointer"
        >
          <div className="f-center font-bold">Click here to try it now.</div>
        </a>
      </span>
    </AbstractPromotionBanner>
  );
};

export default AIAssistantPromotionBanner;
