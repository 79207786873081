import cn from 'classnames';

import {
  MOZART_ALL,
  FilteredSchemaMap,
  virtualSchemaKey,
} from 'pages/Warehouse/DatabaseSearch2/DatabaseSearchReducer';
import { KeywordLists } from 'utils/TableFilter';

import SchemaDivider from './SchemaDivider';
import SchemaGroup from './SchemaGroup';

import s from './SchemaList.module.css';

interface SchemaListProps {
  curatedSchemaKeys: string[];
  transformSchemaKeys: string[];
  unmanagedSchemaKeys: string[];
  isFiltering: boolean;
  filterIncludes: KeywordLists;
  virtualSchemaKeysToSearch: string[];
  filteredSchemasMap: FilteredSchemaMap;
  unfilteredSchemasMap: FilteredSchemaMap;
  onClickSchema(schemaKey: string, metaKey: boolean, ctrlKey: boolean, shiftKey: boolean): void;
}

export default function SchemaList(props: SchemaListProps) {
  const {
    curatedSchemaKeys,
    transformSchemaKeys,
    unmanagedSchemaKeys,
    isFiltering,
    filterIncludes,
    virtualSchemaKeysToSearch,
    filteredSchemasMap,
    unfilteredSchemasMap,
    onClickSchema,
  } = props;

  const schemaGroupProps = {
    isFiltering,
    filterIncludes,
    virtualSchemaKeysToSearch,
    filteredSchemasMap,
    unfilteredSchemasMap,
    onClickSchema,
  };

  const schemaSectionClass = 'pl-4 mt-1';
  return (
    <div className={cn('w-full h-full overflow-auto bg-sec-blue-gray-50 select-none', s.schemaList)}>
      <div className="min-w-full min-h-full inline-block">
        <div className="h-4"></div>
        <SchemaGroup schemaKeys={[virtualSchemaKey(MOZART_ALL, MOZART_ALL)]} {...schemaGroupProps} />
        {curatedSchemaKeys.length > 0 && (
          <div className={schemaSectionClass}>
            <SchemaDivider label="MY TABLES" />
            <SchemaGroup schemaKeys={curatedSchemaKeys} {...schemaGroupProps} />
          </div>
        )}
        {transformSchemaKeys.length > 0 && (
          <div className={schemaSectionClass}>
            <SchemaDivider label="TRANSFORM SCHEMAS" />
            <SchemaGroup schemaKeys={transformSchemaKeys} {...schemaGroupProps} />
          </div>
        )}
        {unmanagedSchemaKeys.length > 0 && (
          <div className={schemaSectionClass}>
            <SchemaDivider label="CONNECTED SCHEMAS" />
            <SchemaGroup schemaKeys={unmanagedSchemaKeys} {...schemaGroupProps} />
          </div>
        )}
      </div>
    </div>
  );
}
