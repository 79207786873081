import { forwardRef } from 'react';

import cn from 'classnames';
import History from 'history';

import { AggTable } from 'api/tableAPI';
import useHelpUrl from 'hooks/useHelpUrl';
import { CompanySetupGuideContextInterface } from 'model_layer/CompanySetupGuideContext';
import { OnboardingPopoverTourContextInterface } from 'model_layer/OnboardingPopoverTourContext';

import { Menu } from '@headlessui/react';

import HeaderIcon from '../HeaderIcon/HeaderIcon';

import menuStyle from 'components/widgets/Menu/Menu.module.css';

export interface HelpMenuProps {
  location: History.Location;
  currentPathObject: AggTable | null;
  optContext: OnboardingPopoverTourContextInterface;
  csgContext: CompanySetupGuideContextInterface;
}

export const HelpMenu = forwardRef<HTMLAnchorElement, HelpMenuProps>(
  (props: HelpMenuProps, forwardedRef) => {
    const { location, currentPathObject, optContext, csgContext } = props;
    const { loaded, onRestart } = optContext;
    const { show, onShowGuide } = csgContext;

    const helpUrl = useHelpUrl(currentPathObject, location);
    const restartPopoverTour = () => {
      if (loaded) {
        onRestart('HeaderHelpMenu RestartOnboardingPopoverTour');
      }
    };

    const showCompanySetupGuide = () => {
      onShowGuide();
      analytics.track('HeaderHelpMenu ShowCompanySetupGuide');
    };

    return (
      <Menu ref={forwardedRef} as="div" className={menuStyle.menu}>
        <Menu.Button className="f-row-y-center ml-2" id="nav-plus-dropdown">
          <HeaderIcon type="help" className="bg-silver" />
        </Menu.Button>
        <Menu.Items className={cn('min-w-[150px]', menuStyle.menuItems)}>
          <Menu.Item>
            <a
              href={helpUrl}
              target="blank"
              data-track="HeaderHelpMenu OpenHelpDocs"
              className={cn('w-full', menuStyle.menuItem)}
            >
              Open Help Docs
            </a>
          </Menu.Item>
          <Menu.Item>
            <a
              href="/getting-started"
              target="blank"
              data-track="HeaderHelpMenu OpenGettingStartedVideo"
              className={cn('w-full', menuStyle.menuItem)}
            >
              Watch Getting Started Video
            </a>
          </Menu.Item>
          {loaded && (
            <Menu.Item>
              <button onClick={restartPopoverTour} className={cn('w-full', menuStyle.menuItem)}>
                Start Product Tour
              </button>
            </Menu.Item>
          )}
          {!show && (
            <Menu.Item>
              <button onClick={showCompanySetupGuide} className={cn('w-full', menuStyle.menuItem)}>
                Show Company Setup Guide
              </button>
            </Menu.Item>
          )}
          <div className="h-px w-full bg-pri-gray-200" />
          <Menu.Item>
            <div className={cn('w-full !h-[64px] flex-col !items-start', menuStyle.menuItem)}>
              <div>Contact Us</div>
              <a href="mailto:support@mozartdata.com" className="text-purple ">
                support@mozartdata.com
              </a>
            </div>
          </Menu.Item>
        </Menu.Items>
      </Menu>
    );
  },
);

export default HelpMenu;
