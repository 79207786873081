import React, { useEffect } from 'react';

import { CreateAsType } from 'api/tableAPI';
import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import TextInputGroup from 'components/inputs/group/TextInputGroup/TextInputGroup';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useDatabaseAccount } from 'context/AuthContext';
import { TransformTable } from 'pages/tables/ShowTable/ShowTable';

import { UnsavedTransform } from './RunOptionsModal';

interface CreateAsTabProps {
  table: TransformTable;
  unsavedTransform: UnsavedTransform;
  validSql: boolean;
  incrementalUpdateKeys: string[] | null;
  setUnsavedTransform: React.Dispatch<React.SetStateAction<UnsavedTransform>>;
  setDisableSave: (disable: boolean) => void;
}

export const tableAndViewDescription = (
  <>
    <p>
      Transforms can create three types of objects in your warehouse: <code>tables</code>,{' '}
      <code>views</code>, and <code>secure views</code>.
    </p>
    <p className="mt-4">
      <code>Tables</code> are the default transform type. They must be run to refresh their contents,
      which can then be more quickly and cheaply accessed by other queries compared to <code>views</code>
      .
    </p>
    <p className="mt-4">
      <code>Views</code> do not store the transform's results but can be queried as if they were{' '}
      <code>tables</code> and will run every time they are queried. That makes them more expensive and
      slow when used, but ensures the freshest data and prevents potentially unnecessary runs.
    </p>
    <p className="mt-4">
      <code>Secure views</code> are a special type of view with extra security features that can be
      useful in cases such as sharing data with external parties. They are slightly less efficient than
      regular <code>views</code>.
    </p>
  </>
);

export const keyErrorMessage = (key: string | null, possibleKeys: string[] | null) => {
  if (key === null) {
    return undefined;
  }
  if (possibleKeys === null) {
    return 'Cannot fetch possible keys';
  }
  const cleanKeys = key
    .split(',')
    .map((k) => k.trim().toUpperCase())
    .filter((k) => k.length > 0);
  const badKeys = cleanKeys.filter((k) => !possibleKeys.includes(k));
  if (badKeys.length > 0) {
    return `Invalid column(s): ${badKeys.join(', ')}`;
  }
  return undefined;
};

export default function CreateAsTab(props: CreateAsTabProps) {
  const {
    table,
    unsavedTransform,
    validSql,
    incrementalUpdateKeys,
    setUnsavedTransform,
    setDisableSave,
  } = props;
  const { create_as, primary_key, cluster_by, statement_timeout_seconds } = unsavedTransform;
  const [primaryKeyError, setPrimaryKeyError] = React.useState<string | undefined>(
    keyErrorMessage(primary_key, incrementalUpdateKeys),
  );
  const [clusterByError, setClusterByError] = React.useState<string | undefined>(
    keyErrorMessage(cluster_by, incrementalUpdateKeys),
  );
  const databaseType = useDatabaseAccount().type;

  useEffect(() => {
    setDisableSave(!!primaryKeyError || !!clusterByError);
  }, [primaryKeyError, clusterByError]); // eslint-disable-line react-hooks/exhaustive-deps

  const setCreateAs = (newValue: ListboxValue) => {
    const createAs = newValue as CreateAsType;
    setUnsavedTransform({
      ...unsavedTransform,
      create_as: createAs,
    });
  };

  const handlePrimaryKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnsavedTransform({
      ...unsavedTransform,
      primary_key: e.currentTarget.value || null,
    });
    setPrimaryKeyError(keyErrorMessage(e.currentTarget.value, incrementalUpdateKeys));
  };

  const handleClusterByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnsavedTransform({
      ...unsavedTransform,
      cluster_by: e.currentTarget.value || null,
    });

    setClusterByError(keyErrorMessage(e.currentTarget.value, incrementalUpdateKeys));
  };

  const handleStatmentTimeoutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Strip out anything that's not a digit and cast to an integer
    const cleanST = e.currentTarget.value.replace(/\D/g, '');
    const newSTS = cleanST === '' ? null : parseInt(cleanST, 10);
    setUnsavedTransform({
      ...unsavedTransform,
      statement_timeout_seconds: newSTS || null,
    });
  };

  return (
    <div className="h-full min-h-0 flex flex-col">
      <div className="text-base font-medium text-pri-gray-500">Object Type</div>
      <div className="mt-2 text-sm text-pri-gray-500">{tableAndViewDescription}</div>
      <div className="w-[210px] f-row-y-center mt-4">
        <Listbox
          value={create_as}
          onChange={setCreateAs}
          variant="white"
          size="medium"
          containerClass="mt-2 w-full f-row-y-center border border-pri-gray-300 rounded"
          optionsMaxHeightClass="max-h-[210px]"
          options={[
            { value: 'BASE TABLE', label: 'Table' },
            { value: 'VIEW', label: 'View' },
            { value: 'SECURE VIEW', label: 'Secure View' },
          ]}
        />
      </div>
      {table.transform.incremental && unsavedTransform.create_as !== 'BASE TABLE' && (
        <Alert variant="warning" className="mt-4">
          Saving Create As as 'View' or 'Secure View' will also disable incremental mode.
        </Alert>
      )}
      {(unsavedTransform.primary_key || unsavedTransform.cluster_by) &&
        unsavedTransform.create_as !== 'BASE TABLE' && (
          <Alert variant="warning" className="mt-4">
            Saving Create As as 'View' or 'Secure View' will have the values of Primary Key and Cluster
            By Keys be ignored.
          </Alert>
        )}
      {databaseType !== 'bigquery' && (
        <>
          <div className="mt-8 f-row-y-center">
            <div className="whitespace-nowrap text-base font-medium text-pri-gray-500">
              Advanced Options
            </div>
            <div className="ml-2 h-[1px] w-full bg-pri-gray-200" />
          </div>
          <div className="mt-4 text-sm font-medium text-pri-gray-500">Primary Key</div>
          <div className="mt-2 text-sm text-pri-gray-500">
            A <code>Primary Key</code> can be defined as metadata for use cases like reverse ETL. Please
            note that Snowflake doesn't actually enforce primary key uniqueness itself. You likely don't
            need to set this unless you are doing a reverse ETL. Define the <code>Primary Key</code> as a
            comma-separated list of columns. The order of the columns matters.
          </div>
          <div className="w-[210px] f-row-y-center mt-4">
            <TextInputGroup
              name="primaryKey"
              onChange={handlePrimaryKeyChange}
              value={!validSql ? 'Invalid SQL' : primary_key || ''}
              disabled={!validSql || unsavedTransform.create_as !== 'BASE TABLE'}
              error={primaryKeyError}
            />
          </div>
          <div className="mt-8 text-sm font-medium text-pri-gray-500">Cluster By Keys</div>
          <div className="mt-2 text-sm text-pri-gray-500">
            <code>Cluster By Keys</code> can be used by Snowflake to optimize query performance for very
            large tables. You typically don't need to set this. Define <code>Cluster By Keys</code> as a
            comma-separated list of columns. The order of the columns matters.
          </div>
          <div className="w-[210px] f-row-y-center mt-4 pb-8">
            <TextInputGroup
              name="clusterBy"
              onChange={handleClusterByChange}
              value={!validSql ? 'Invalid SQL' : cluster_by || ''}
              disabled={!validSql || unsavedTransform.create_as !== 'BASE TABLE'}
              error={clusterByError}
            />
          </div>
          <div className="text-sm font-medium text-pri-gray-500">Statement Timeout (seconds)</div>
          <div className="mt-2 text-sm text-pri-gray-500">
            <code>Statement Timeout</code> can be used to set the maximum execution time of a query in
            seconds. If a query runs longer than the specified time, it will be canceled. Leave blank for
            no timeout.
          </div>
          <div className="w-[210px] f-row-y-center mt-4 pb-8">
            <TextInputGroup
              name="statementTimeout"
              onChange={handleStatmentTimeoutChange}
              value={statement_timeout_seconds || ''}
            />
          </div>
        </>
      )}
    </div>
  );
}
