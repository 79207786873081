import { uniq, sortBy } from 'lodash';

import { DatabaseType } from 'api/APITypes';
import { fullNameToTableKey, isQuoted } from 'utils/dbName';
import { reservedWordDic } from 'utils/SnowflakeReservedWords';
import { isUpperCase } from 'utils/String';

function tablesNamesFromQuery(sql: string, databaseType: DatabaseType): string[] {
  let regexp: RegExp;
  if (databaseType === 'snowflake') {
    regexp =
      /([^a-zA-Z0-9$_."]|^)(((([a-zA-Z0-9$_]+)|("[a-zA-Z0-9$_]+"))\s*\.\s*){1,2}(([a-zA-Z0-9$_]+)|("[a-zA-Z0-9$_]+")))([^a-zA-Z0-9$_."]|$)/g;
  } else {
    // anything with one or two dots.
    regexp = /[a-zA-Z0-9_`-]+\.[a-zA-Z0-9_`-]+(?:\.[a-zA-Z0-9_`-]+)?/g;
  }
  const tables: string[] = [];
  const matches = sql.matchAll(regexp);
  for (const m of matches as any) {
    tables.push(m[databaseType === 'snowflake' ? 2 : 0]);
  }
  return tables;
}
export function sanitizedTablesFromQuery(sql: string, databaseType: DatabaseType): string[] {
  const unsafeTables = tablesNamesFromQuery(sql, databaseType);
  return sanitizeTableNames(unsafeTables, databaseType);
}

// Converts tables to normalized casing, removes duplicates, and orders.
function sanitizeTableNames(unsafeTables: string[], databaseType: DatabaseType): string[] {
  let safeTables = unsafeTables.map((t) => sanitizeFullName(t, databaseType));
  safeTables = sortBy(uniq(safeTables));
  return safeTables;
}

// Convert what the user typed in the editor into a key that will match a table.full_name
// in the data model reducer after the app converted the API response and
// loaded the converted API full_names in the reducer.
function sanitizeFullName(fullName: string, databaseType: DatabaseType) {
  const tableObject = fullNameToTableKey(fullName);
  return (
    sanitizeIdentifier(tableObject.schema.trim(), databaseType) +
    '.' +
    sanitizeIdentifier(tableObject.name.trim(), databaseType)
  );
}

function sanitizeIdentifier(name: string, databaseType: DatabaseType) {
  if (databaseType === 'bigquery') {
    return name;
  }

  if (isQuoted(name)) {
    const strippedLowerName = name.slice(1, -1).toLowerCase();
    // Return reserved words in current case wrapped in quotes.
    if (reservedWordDic[strippedLowerName]) {
      return name;
    }
    // If it's all uppercase, strip the quotes and lowercase it.
    if (isUpperCase(name)) {
      return strippedLowerName;
    }
    // Otherwise return it wrapped in quotes.
    return name;
  }

  // If there are no quotes, lowercase it.
  return name.toLowerCase();
}

export function JSSqlParserFunctionsToTest() {
  // Special export just for tests. I want to keep these functions private to this file otherwise
  return { tablesNamesFromQuery, sanitizeFullName };
}
