import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { useTitle } from 'react-use';

import history from 'appHistory';
import { Formik } from 'formik';
import * as yup from 'yup';

import API from 'api/API';
import { convertTable, CreateAsType } from 'api/tableAPI';
import Button from 'components/inputs/basic/Button/Button';
import ListboxFormikGroup from 'components/inputs/formik_group/ListboxFormikGroup/ListboxFormikGroup';
import TextAreaFormikGroup from 'components/inputs/formik_group/TextAreaFormikGroup/TextAreaFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';
import { TableContext } from 'model_layer/TableContext';
import SchemaCombobox from 'pages/transforms/SchemaCombobox';
import { handleSqlErrors } from 'utils/apiResponseFormatter';
import { valDBIdentifier, valSchemaName } from 'utils/Validators';

import CreateAsInfoIcon from './CreateAsInfoIcon';

const schema = yup.object({
  tableName: valDBIdentifier('Table Name').required('Required'),
  schema: valSchemaName('Schema').trim(),
  createAs: yup.string().required('Required'),
  description: yup.string(),
});

export interface AddTransformProps {
  tableName: string;
  schema: string;
  createAs: CreateAsType;
  description: string;
  sql?: string;
}

export default function AddTransform() {
  useTitle('Create Transform');

  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const {
    userProfile: { company_role },
  } = useUserProfile();
  const databaseType = useDatabaseAccount().type;

  const { updateTables } = useContext(TableContext);

  let initialValues = {
    tableName: '',
    schema: '',
    createAs: 'BASE TABLE' as CreateAsType,
    description: '',
  };

  // Query page or clone transform button might have passed us initial values
  if (prefillForm) {
    initialValues = prefillForm;
  }

  const handleSave = async (values: AddTransformProps, actions: any) => {
    setSaving(true);

    // Clear saved value here because React rerenders components multiple times
    // in dev mode.
    prefillForm = undefined;

    const api = new API();

    try {
      let postData = {
        table_name: values.tableName,
        schema: values.schema.trim(), // We don't trim onChange, but we do on submit
        description: values.description,
        sql: values.sql,
        create_as: values.createAs,
      };
      const response = await api.post('api/transforms', postData);
      analytics.track('AddTransform Save', postData);
      const transform = response.data;
      // Creating a transform also creates a table. Fetch this so we can update TableContext
      api
        .get(`api/tables/${transform.table_id}`, (table) => convertTable(table, databaseType))
        .then((resp) => {
          const table = resp.data;
          updateTables([table]);
          history.push(`/tables/${table.id}/transform`);
        })
        .catch((e) => {
          setErrorMessage('There was a problem creating your transform.');
        });
    } catch (error: any) {
      // Form validation errors
      if (error.response?.status === 400) {
        if (error.response?.data?.sql) {
          const sqlError = error.response.data.sql[0];
          const sqlErrorMessage = handleSqlErrors(sqlError);
          setErrorMessage(sqlErrorMessage);
          actions.setErrors(sqlErrorMessage);
        } else {
          const data = error.response.data;
          setErrorMessage(data.non_field_errors);
          actions.setErrors(data);
        }
      }
      // Something went very wrong. Think HTTP 500 and server stack traces
      else {
        setErrorMessage('There was a problem creating your transform.');
      }
      setSaving(false);
    }
  };

  const handleCancel = () => {
    analytics.track('AddTransform Cancel');
    history.goBack();
  };

  return (
    <CenteredLayout maxWidth="814px" title="Create Transform">
      <Formik validationSchema={schema} onSubmit={handleSave} initialValues={initialValues}>
        {({ handleSubmit, handleChange, values }) => {
          // Don't let unnoticed spaces mess up input validation
          const trimmedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            event.target.value = event.target.value.trim();
            handleChange(event);
          };
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div className="w-full grid grid-cols-2 gap-4">
                <SchemaCombobox schemaValue={values.schema} tableType="transform" />
                <TextFormikGroup
                  name="tableName"
                  label="Table Name"
                  placeholder="engaged_users"
                  onChange={trimmedChange}
                  groupClass="w-full"
                />
              </div>
              <ListboxFormikGroup
                name="createAs"
                label="Create As"
                variant="white"
                size="large"
                groupClass="mt-4"
                containerClass="w-full f-row-y-center border border-pri-gray-300 rounded"
                optionsMaxHeightClass="max-h-[210px]"
                options={[
                  { value: 'BASE TABLE', label: 'Table' },
                  { value: 'VIEW', label: 'View' },
                  { value: 'SECURE VIEW', label: 'Secure View' },
                ]}
                postLabelElement={<CreateAsInfoIcon />}
              />
              <TextAreaFormikGroup
                name="description"
                label="A brief description of this table"
                rows={4}
                placeholder="All users that used the app in the last week"
                groupClass="mt-4"
              />
              {errorMessage && (
                <Alert variant="error" className="mt-4">
                  {errorMessage}
                </Alert>
              )}
              <div className="flex mt-4">
                <Button
                  type="submit"
                  variant="lightAction"
                  style={{ width: '214px' }}
                  spinning={saving}
                  disabled={company_role === 'viewer'}
                >
                  Create
                </Button>
                <Button
                  onClick={handleCancel}
                  variant="lightDanger"
                  className="ml-4"
                  style={{ width: '214px' }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </CenteredLayout>
  );
}

// Allow other components to prefill the transform form.
let prefillForm: AddTransformProps | undefined = undefined;
export function prefillTransform(
  tableName: string,
  schema: string,
  createAs: CreateAsType,
  description: string,
  sql: string,
): void {
  prefillForm = {
    tableName,
    schema,
    createAs,
    description,
    sql,
  };
  history.push('/transforms/add');
}
