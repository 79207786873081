import TabLayout from 'components/layouts/containers/TabLayout/TabLayout';
import { Connector } from 'model_layer/TableModelsContext';
import PagedUserActionsTable from 'pages/user_actions/components/UserActionsTable/PagedUserActionsTable';
import usePagedUserActions from 'pages/user_actions/usePagedUserActions';

interface UserActionsTabProps {
  connector: Connector;
}

const UserActionsTab = (props: UserActionsTabProps) => {
  const { connector } = props;
  const { isLoading, error, ...pagedProps } = usePagedUserActions('ConnectorUserActionsTab', {
    object_type: 'connector',
    object_id: connector.id,
  });

  return (
    <TabLayout variant="wide_padding" loading={isLoading} error={error}>
      {!error && (
        <PagedUserActionsTable
          {...pagedProps}
          title={'User Actions'}
          variant="blueGray"
          hideLabels={true}
        />
      )}
    </TabLayout>
  );
};

export default UserActionsTab;
