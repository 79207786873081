/*
  Use <NoMatchesFoundAlert/> when a list has objects, but
  the search filter has removed all of them.
*/
import React from 'react';

import Alert from 'components/widgets/alerts/Alert/Alert';

interface NoMatchesFoundAlertProps {
  heading: string;
  detail?: string;
  className?: string;
}

export default function NoMatchesFoundAlert(props: NoMatchesFoundAlertProps) {
  const { heading, detail, className } = props;
  const actualHeading = heading;
  const actualDetail = detail || 'Clear the search input to see results.';
  const actualClass = className;
  return (
    <Alert variant="no_results" className={actualClass}>
      <h6 className="text-xl font-medium">{actualHeading}</h6>
      <p className="text-base">{actualDetail}</p>
    </Alert>
  );
}
