import { useTitle } from 'react-use';

import cn from 'classnames';

import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';

import styles from './Partnerships.module.css';

interface PartnershipsProps {}

export default function Partnerships(props: PartnershipsProps) {
  useTitle('Partnerships');

  // Partnerfleet renders as a percentage of the window width.
  // We want out header to be the same size.
  // We copied their media queries into the attached CSS file to simulate their resizing.
  const header = (
    <div className="f-center">
      <h1 className={cn('display-sm text-pri-gray-700', styles.container, styles.header)}>Partners</h1>
    </div>
  );

  // We need to give the Partnerfleet iframe some height.
  // This is probably going to take some adjusting and be adjusted as our partnership catalog grows.
  const iframeHeight = `calc(100vh + 500px)`;

  return (
    <CenteredContainer header={header} maxWidth="100%" contentWrapperClass="mt-0">
      <iframe
        title="Partnerships"
        src="https://mozartdata-connectors.partnerfleet.app/"
        className="w-full h-[100vh]"
        style={{ height: iframeHeight }}
      />
    </CenteredContainer>
  );
}
