import React, { ChangeEvent } from 'react';

import TextInputGroup from 'components/inputs/group/TextInputGroup/TextInputGroup';
import { percentFormatted } from 'utils/Math';
import { formatToDollarAmount, formatNumber } from 'utils/String';
import { cleanColor } from 'utils/String';

interface LineItemProps {
  id: string;
  value: number;
  cost: number;
  label: string;
  color: string;
  discount?: number;
  allotment?: number;
  billedValue?: number;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function LineItem(props: LineItemProps) {
  const {
    id,
    value,
    cost,
    label,
    color,
    discount = 0,
    allotment = 0,
    billedValue,
    handleChange,
  } = props;

  const textColor = cleanColor(color);

  return (
    <>
      <TextInputGroup
        id={id}
        name={id}
        label={label}
        onChange={handleChange}
        value={value}
        type="number"
        className="mb-4"
      />
      <div className="f-col my-auto ml-4">
        {allotment > 0 && billedValue !== undefined && (
          <>
            <div className="f-row-y-center text-sm text-pri-gray-400">
              -
              <div className="mx-1 text-base font-medium" style={{ color: textColor }}>
                {formatNumber(allotment)}
              </div>{' '}
              {label} Allotment
            </div>
            <div className="f-row-y-center text-sm text-pri-gray-400">
              =
              <div className="mx-1 text-base font-medium" style={{ color: textColor }}>
                {formatNumber(billedValue)}
              </div>{' '}
              Billed {label}
            </div>
          </>
        )}

        {discount > 0 && (
          <div className="text-sm text-pri-gray-400">x {percentFormatted(discount, 1, 0)}% discount</div>
        )}
      </div>
      <div className="f-row-y-center text-xl font-medium" style={{ color: textColor }}>
        = ${formatToDollarAmount(cost)}
      </div>
    </>
  );
}
