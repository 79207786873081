import { Connector } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';

import PictureSentenceLayout from '../PictureSentenceLayout';

export interface SetupCompleteViewProps {
  connector: Pick<Connector, 'id' | 'service' | 'schema'>;
  onAddConnector(): void;
  onGoToConnectors(): void;
}

export default function SetupCompleteView(props: SetupCompleteViewProps) {
  const { connector, onAddConnector, onGoToConnectors } = props;

  return (
    <PictureSentenceLayout
      connector={connector}
      img="/images/graphics/ootb/stacking_boxes.svg"
      sentence="We are working on the reports, you will get an email notification when the transforms and report are available."
    >
      <div className="f-row-y-center">
        <Button onClick={onAddConnector}>Add Another Connector</Button>
        <Button onClick={onGoToConnectors} variant="lightDullTransparent" className="ml-2">
          Go To Connectors
        </Button>
      </div>
    </PictureSentenceLayout>
  );
}
