import React, { useState } from 'react';

import QRCode from 'qrcode.react';

import s from './QRCodeComponent.module.css';

interface QRCodeProps {
  totp_secret: string;
  totp_url: string;
}

export default function QRCodeComponent(props: QRCodeProps) {
  const { totp_secret, totp_url } = props;
  const [totpKeyVisible, setTotpKeyVisible] = useState(false);

  const showTotpKey = () => {
    setTotpKeyVisible(true);
    analytics.track('UserSettings DisplayTotpKey');
  };

  return (
    <>
      <div className="m-2">{totp_url ? <QRCode value={totp_url} /> : null}</div>
      <span className="m-2">
        {totpKeyVisible ? (
          totp_secret
        ) : (
          <button className={s.show2FAKeyButton} onClick={showTotpKey}>
            Show 2FA Key
          </button>
        )}
      </span>
    </>
  );
}
