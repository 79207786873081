import React from 'react';

import Button from 'components/inputs/basic/Button/Button';
import Modal from 'components/layouts/containers/modals/Modal/Modal';

export interface ConfirmDeleteModalProps {
  header?: string;
  children: React.ReactNode;
  confirmText: string;
  enableConfirm: boolean;
  deleting: boolean;
  onCancel(): void;
  onConfirm(): void;
}

export default function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
  const { header, children, confirmText, enableConfirm, deleting, onCancel, onConfirm } = props;

  const modalFooter = (
    <div className="flex justify-left">
      <Button variant="darkDanger" disabled={!enableConfirm} onClick={onConfirm} spinning={deleting}>
        {confirmText}
      </Button>
      <Button variant="lightDanger" onClick={onCancel} className="ml-2">
        Cancel
      </Button>
    </div>
  );
  return (
    <Modal onClose={onCancel} footer={modalFooter} header={header}>
      {children}
    </Modal>
  );
}
