// Our apis sometimes return sql results in a format that is not easy to work with, but better for the wire.
// This function converts the results into a more usable format.
// It also adds an `index` property to each row.
// {results: {rows: [...], columns: [{name: '', type: ''}...]}} -> [{index: 0, column1_name: 'column1_value'...}...]
export function parseSqlResults<RowType>(results: {
  rows: RowType[][];
  columns: {
    name: string;
    type: string;
  }[];
}) {
  return results.rows.map((row: RowType[], rowIndex: number) => {
    return {
      ...Object.fromEntries(results.columns.map((c, i) => [c.name, row[i]])),
      index: rowIndex,
    };
  });
}

export function handleSqlErrors(sqlError: string) {
  if (sqlError === 'This field may not be blank.') {
    return 'SQL cannot be blank.';
  } else if (sqlError === 'invalid_sql') {
    return 'The SQL is not valid.';
  } else if (sqlError === 'permission_error') {
    return "You do not have permission to run this SQL, or the SQL references tables that don't exist.";
  } else if (sqlError === 'invalid_role') {
    return 'Your Snowflake role is not valid. Please contact your administrator.';
  } else {
    return 'There was a problem saving.';
  }
}
