import Button from 'components/inputs/basic/Button/Button';

interface EditButtonsProps {
  editMode: boolean;
  saving: boolean;
  isViewer: boolean;
  onEdit: () => void;
  onDiscard: () => void;
  onSave: () => void;
}

export default function EditButtons(props: EditButtonsProps) {
  const { editMode, saving, isViewer, onEdit, onDiscard, onSave } = props;
  return editMode ? (
    <div className="ml-2">
      <Button size="small" variant="darkDanger" disabled={saving} onClick={onDiscard}>
        Discard Changes
      </Button>
      <Button size="small" variant="save" disabled={saving} onClick={onSave} className="ml-2">
        Save
      </Button>
    </div>
  ) : (
    <Button size="small" variant="darkDullAction" onClick={onEdit} disabled={isViewer} className="ml-2">
      Edit
    </Button>
  );
}
