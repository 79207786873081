import { DatabaseConfigInfoResponse } from './IntegrationSnowflakeConfig';

// All integrations use the same data to connect to snowflake
// but they have you enter it in various different formatted strings
// some of which are reused across many apps.
// FieldKey is all unique key for every possible input type in every integration.
export type FieldKey =
  // API Values
  | 'account_name'
  | 'base_account_name'
  | 'database'
  | 'full_account_name'
  | 'platform'
  | 'password'
  | 'region'
  | 'username'
  | 'rolename'
  | 'warehouse'
  | 'snowflake_admin_username'
  | 'snowflake_admin_password'
  // Synthetic Values used to make various integration UI's happy.
  // These are computed from API Values.
  | 'mozart'
  | 'host'
  | 'platform_region'
  | 'region.platform'
  | 'db_schema'
  | 'looker_additional_params'
  | 'looker_bigquery_dataset'
  | 'sisense_jdbc'
  // Some integrations are case sensitive:
  | 'upper.database'
  | 'upper.warehouse'
  // BigQuery stuff
  | 'project_id'
  | 'region';

export interface Field {
  uiName: string;
  key: FieldKey;
}

const fieldValue = (response: DatabaseConfigInfoResponse, key: FieldKey): string => {
  if (response.type === 'bigquery') {
    return response[key] || '';
  }

  if (key.slice(0, 6) === 'upper.') {
    return fieldValue(response, key.slice(6) as FieldKey).toUpperCase();
  }

  // Check for Synthetic values
  if (key === 'mozart') {
    return 'mozart';
  } else if (key === 'host') {
    return `${response['account_name']}.snowflakecomputing.com`;
  } else if (key === 'platform_region') {
    return `${response['platform']} - ${response['region']}`;
  } else if (key === 'region.platform') {
    return `${response['region']}.${response['platform']}`;
  } else if (key === 'db_schema') {
    return `mozart`;
  } else if (key === 'looker_additional_params') {
    const accountPart = response['base_account_name']
      ? `account=${response['base_account_name'].toUpperCase()}&`
      : '';
    return `${accountPart}warehouse=${response['warehouse'].toUpperCase()}&db=${response[
      'database'
    ].toUpperCase()}`;
  } else if (key === 'sisense_jdbc') {
    return `jdbc:snowflake://${fieldValue(response, 'host')}?db=${fieldValue(
      response,
      'database',
    )}&warehouse=${fieldValue(response, 'warehouse')}`;
  }

  // Default to API value
  return response[key] || '';
};

export { fieldValue };
