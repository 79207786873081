import React, { useState } from 'react';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import { useUserProfile } from 'context/AuthContext';

import SlackAppInstallerInfoIcon from './SlackAppInstallerInfoIcon';

interface SlackAppInstallerProps {
  setError: (error: string) => void; // Error to display on base Settings.tsx page.
}

export default function SlackAppInstaller(props: SlackAppInstallerProps) {
  const { setError } = props;
  const [uninstallLoading, setUninstallLoading] = useState(false);
  const { userProfile, setUserProfile } = useUserProfile();

  const UninstallSlack = () => {
    const api = new API();
    setUninstallLoading(true);
    api
      .post('/api/company/uninstall_slack_app', {})
      .then((response) => {
        setUserProfile(response.data.user_profile);
        analytics.track('CompanySettings UninstallSlackApp');
      })
      .catch((e) => {
        setError('There was a problem uninstalling your Slack app.');
      })
      .finally(() => {
        setUninstallLoading(false);
      });
  };

  // This is dynamic because we need a different redirect url for each environment.
  // Default to prod
  let redirectUrl = 'https%3A%2F%2Fapp.mozartdata.com%2Fslack-app-installer-redirect';
  let client_id = '1045273334023.2825081572453';

  if (process.env.NODE_ENV === 'development') {
    // ngrok http --region=us --hostname=devslackfrontend.ngrok.io 3000
    redirectUrl = 'https%3A%2F%2Fdevslackfrontend.ngrok.io%2Fslack-app-installer-redirect';
    client_id = '1045273334023.3000134276898';
  } else if (window.location.host === 'stg-mozartdata.uc.r.appspot.com') {
    redirectUrl = 'https%3A%2F%2Fstg-mozartdata.uc.r.appspot.com%2Fslack-app-installer-redirect';
    client_id = '1045273334023.3000134276898';
  }
  const authorizeUrl = `https://slack.com/oauth/v2/authorize?client_id=${client_id}&redirect_uri=${redirectUrl}&scope=chat:write,files:write,channels:read,groups:read&user_scope=`;

  return (
    <>
      <div className="f-row-y-center" style={{ borderBottom: '1px solid #dee2e6', padding: '0.75rem' }}>
        <img
          alt=""
          height="25"
          width="100"
          src="https://cdn.bfldr.com/5H442O3W/at/pl546j-7le8zk-838dm2/Slack_RGB.svg?auto=webp&format=svg"
        />
        {userProfile.company.slack_integration?.is_app_installed ? (
          <div className="f-between w-full">
            <div className="ml-3" style={{ color: 'grey', fontSize: '1.25rem' }}>
              Connected to {userProfile.company.slack_integration.workspace_name}
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <Button
                className="ml-2"
                variant="lightDanger"
                spinning={uninstallLoading}
                onClick={UninstallSlack}
                disabled={userProfile.company_role !== 'admin'}
              >
                Uninstall
              </Button>
            </div>
          </div>
        ) : (
          <div className="f-between w-full">
            <div className="f-row-y-center">
              <div className="ml-4 text-pri-gray-500 text-[1.25rem]">
                Get notifications relevant to your Mozart Data account
              </div>
              <SlackAppInstallerInfoIcon />
            </div>
            <a className="w-fit" href={authorizeUrl} data-track="CompanySettings InstallSlackApp">
              <Button noClickGuard disabled={userProfile.company_role !== 'admin'}>
                Install
              </Button>
            </a>
          </div>
        )}
      </div>
    </>
  );
}
