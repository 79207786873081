import { MOZART_ALL, MOZART_RECENT, MOZART_FAVORITES } from '../DatabaseSearch2/DatabaseSearchReducer';

const schemaTitles: { [key: string]: string } = {
  [MOZART_ALL]: 'All',
  [MOZART_RECENT]: 'Recent',
  [MOZART_FAVORITES]: 'Favorites',
};

export function getSchemaTitle(schema: string) {
  return schemaTitles[schema] || schema;
}
