import { Transform, TransformRunState } from 'api/APITypes';
import { Status as GenericRunStatus } from 'components/primitives/icons/StatusIcon/StatusIcon';
import { displayCron } from 'utils/cron';
import { durationFromIsoTimes, parseIso } from 'utils/dateTime';

import userProfileHelper from './userProfileHelper';

export function displaySchedule(transform: Transform) {
  const { scheduled, schedule_mode, schedule } = transform;
  if (!scheduled) {
    return 'Off';
  }
  if (schedule_mode === 'cron') {
    return displayCron(schedule);
  }
  if (schedule_mode === 'all_marked') {
    return 'After All Marked Ancestors';
  }
  if (schedule_mode === 'any_marked') {
    return 'After Any Marked Ancestors';
  }

  return 'ERROR';
}

export interface TransformRunFraction {
  state: TransformRunState;
  error_message: string | null;
}

export function calcGenericRunStatusFromRun(run: TransformRunFraction | null): GenericRunStatus {
  if (!run) {
    return 'NA';
  }
  const { state } = run;
  if (state === 'success') {
    return 'success';
  } else if (state === 'failed') {
    return 'error';
  }
  return 'syncing';
}

export function calcGenericRunStatus(transform: Transform): GenericRunStatus {
  return calcGenericRunStatusFromRun(transform.last_completed_run);
}

export function lastRunDate(transform: Transform) {
  const date = transform.last_completed_run
    ? transform.last_completed_run.snowflake_query?.start_time || transform.last_completed_run.created_at
    : null;
  return parseIso(date);
}

export function duration(transform: Transform): number | null {
  const { snowflake_query } = transform.last_completed_run ?? {};
  const { start_time, end_time } = snowflake_query ?? {};
  if (start_time && end_time) {
    return durationFromIsoTimes(start_time, end_time);
  }
  return null;
}

export function lastCompletedRunVersionSavedBy(transform: Transform): string {
  const { created_by: createdBy } = transform.last_completed_run?.version ?? {};

  if (!createdBy) {
    return 'N/A';
  }

  return userProfileHelper.displayName(createdBy);
}

export function lastCompletedRunVersionSavedAt(transform: Transform): Date | null {
  const createdAt = transform.last_completed_run?.version?.created_at || null;
  return parseIso(createdAt);
}
