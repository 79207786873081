import { DbtRunConfig, DbtRunState } from 'api/dbtAPI';
import { Status as GenericRunStatus } from 'components/primitives/icons/StatusIcon/StatusIcon';
import { displayCron } from 'utils/cron';
import { durationFromIsoTimes, parseIso } from 'utils/dateTime';

export function displaySchedule(runConfig: DbtRunConfig | null) {
  if (!runConfig) {
    return 'Missing Job';
  }
  const { scheduled, schedule_mode, schedule } = runConfig;
  if (!scheduled) {
    return 'Off';
  }
  if (schedule_mode === 'cron') {
    return displayCron(schedule);
  }
  if (schedule_mode === 'all_marked') {
    return 'After All Marked Ancestors';
  }

  // This should never happen.
  // This is an combination of API data that the frontend is not programmed to handle.
  return 'ERROR';
}

export function calcGenericRunStatus(runConfig: DbtRunConfig | null): GenericRunStatus {
  if (runConfig && runConfig.last_run) {
    return calcGenericRunStatusFromState(runConfig.last_run.state);
  }
  return 'NA';
}

export function calcGenericRunStatusFromState(state: DbtRunState): GenericRunStatus {
  if (state === 'success') {
    return 'success';
  } else if (state === 'failed') {
    return 'error';
  } else if (state === 'created') {
    return 'paused';
  } else if (state === 'running') {
    return 'syncing';
  }

  // This should never happen.
  // This is an combination of API data that the frontend is not programmed to handle.
  return 'NA';
}

export function lastRunDate(runConfig: DbtRunConfig | null) {
  if (runConfig && runConfig.last_run) {
    return parseIso(runConfig.last_run.created_at);
  }
  return null;
}

export function duration(runConfig: DbtRunConfig | null): number | null {
  const { created_at, completed_at } = runConfig?.last_run ?? {};
  if (created_at && completed_at) {
    return durationFromIsoTimes(created_at, completed_at);
  }
  return null;
}

export function lastCompletedRunVersionSavedBy(runConfig: DbtRunConfig | null): string {
  // runConfig.last_run does not store this property yet.
  return 'N/A';
}

export function lastCompletedRunVersionSavedAt(runConfig: DbtRunConfig | null): Date | null {
  // runConfig.last_run does not store this property yet.
  return null;
}
