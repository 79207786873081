import { createContext } from 'react';

export type OnboardingPopoverTourStep =
  | 'never_started'
  | 'connectors'
  | 'warehouse'
  | 'transforms'
  | 'dbt'
  | 'query'
  | 'alerts'
  | 'integrations'
  | 'docs'
  // | 'analyst_support'
  | 'complete';

export interface OnboardingPopoverTourRefs {
  connectors: React.MutableRefObject<HTMLAnchorElement | null>;
  warehouse: React.MutableRefObject<HTMLAnchorElement | null>;
  transforms: React.MutableRefObject<HTMLAnchorElement | null>;
  dbt: React.MutableRefObject<HTMLAnchorElement | null>;
  query: React.MutableRefObject<HTMLAnchorElement | null>;
  alerts: React.MutableRefObject<HTMLAnchorElement | null>;
  integrations: React.MutableRefObject<HTMLAnchorElement | null>;
  docs: React.MutableRefObject<HTMLAnchorElement | null>;
  // analyst_support: React.MutableRefObject<HTMLButtonElement | null>;
}

export interface OnboardingPopoverTourContextInterface {
  loaded: boolean;
  currentStep: OnboardingPopoverTourStep;
  onNext(): void;
  onPrevious(): void;
  onSkip(): void;
  onRestart(segmentEvent: string): void;
  refs: OnboardingPopoverTourRefs;
}

export const OnboardingPopoverTourContext = createContext<OnboardingPopoverTourContextInterface>({
  loaded: false,
  currentStep: 'never_started',
  onNext: () => {},
  onPrevious: () => {},
  onSkip: () => {},
  onRestart: () => {},
  refs: {
    connectors: { current: null },
    warehouse: { current: null },
    transforms: { current: null },
    dbt: { current: null },
    query: { current: null },
    alerts: { current: null },
    integrations: { current: null },
    docs: { current: null },
    // analyst_support: { current: null },
  },
});
