/*
Wrapper around useGet() that prefers to return data returned by useGet().
If useGet() doesn't return data, it tries to find data cached in localStorage.
*/
import { useCallback, useEffect, useMemo } from 'react';

import { useQueryClient } from 'react-query';

import { DataConverter } from 'api/API';
import { setAPIPayload } from 'api/CacheKeys';
import { trySetItem } from 'api/CacheKeys';
import { useGet, GetProps } from 'hooks/useApi';

export interface LocalStorageGetProps extends GetProps<any> {
  data: any;
  isLocal: boolean;
}

export default function useLocalStorageGet(
  key: string,
  apiPath: string,
  convert?: DataConverter,
): LocalStorageGetProps {
  // Call API
  let state = useGet(key, apiPath, convert);
  let { isError, data } = state;

  // Write useGet() response to localStorage
  useEffect(() => {
    if (!isError && data) {
      setAPIPayload(key, data);
    }
  }, [key, isError, data]);

  // If useGet() did not return any data,
  // try to get data out of localStorage
  const [isLocal, remoteOrLocalData] = useMemo(() => {
    let isLocal = false;
    let remoteOrLocalData = data;

    if (!isError && !data) {
      const cachedData = window.localStorage.getItem(key);
      if (cachedData) {
        isLocal = true;
        remoteOrLocalData = JSON.parse(cachedData);
      }
    }
    return [isLocal, remoteOrLocalData];
  }, [key, isError, data]);

  return { ...state, data: remoteOrLocalData, isLocal };
}

// Return a function that sets both the userQuery cache and the localStorage cache.
export function useLocalStorageSet() {
  const queryClient = useQueryClient();
  const localStorageSet = useCallback(
    (key: string, value: any) => {
      queryClient.setQueriesData(key, value);
      trySetItem(key, value);
    },
    [queryClient],
  );
  return localStorageSet;
}
