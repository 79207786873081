import React from 'react';

import { SnowflakeUser } from '../../useSnowflakeUsers';

import { SnowflakeUsersWithValidRoles } from '../SnowflakeUsersTabView';

import SnowflakeUsersRow from './SnowflakeUsersRow/SnowflakeUsersRow';

interface SnowflakeUsersTableProps {
  snowflakeUsers: SnowflakeUser[];
  snowflakeUsersWithValidRoles: SnowflakeUsersWithValidRoles;
  addedUserName: string;
  onEditUser: (user: SnowflakeUser) => void;
  onDeleteUser: (user: string) => void;
}

const SnowflakeUsersTable = React.memo((props: SnowflakeUsersTableProps) => {
  const { snowflakeUsers, snowflakeUsersWithValidRoles, addedUserName, onEditUser, onDeleteUser } =
    props;

  return (
    <table className="lineTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Default Role</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th className="text-center">Enabled</th>
          <th>Created On</th>
          <th>Last Login</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {snowflakeUsers.map((snowflakeUser: SnowflakeUser) => (
          <SnowflakeUsersRow
            key={snowflakeUser.name}
            snowflakeUser={snowflakeUser}
            validRole={!!snowflakeUsersWithValidRoles[snowflakeUser.name]}
            selected={snowflakeUser.name === addedUserName}
            onEditUser={onEditUser}
            onDeleteUser={onDeleteUser}
          />
        ))}
      </tbody>
    </table>
  );
});

export default SnowflakeUsersTable;
