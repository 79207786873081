import { RouteComponentProps } from 'react-router-dom';

import history from '../../../appHistory';

import useSetupWarehouse, { ByosFormProps, ByosSetupProps } from '../useSetupWarehouse';

import SetupBYOSView from './SetupBYOSView';

interface MatchParams {}
interface SetupBYOSControllerProps extends RouteComponentProps<MatchParams> {}

const SetupBYOSController = (props: SetupBYOSControllerProps) => {
  const { checkIsLoading, startSetupWarehouse, setupInProgress, setupError } = useSetupWarehouse(
    'byos',
    'SaveBYOSCredentials',
  );

  const handleGoBack = () => {
    analytics.track(`Signup BYOSWarehouseGoBack`);
    history.push('/select-warehouse');
  };

  return (
    <SetupBYOSView
      error={setupError}
      isLoading={checkIsLoading || setupInProgress}
      handleSubmit={(values: ByosFormProps) => {
        const setupProps: ByosSetupProps = { ...values, warehouse_type: 'byos' };
        startSetupWarehouse(setupProps);
      }}
      handleGoBack={handleGoBack}
    />
  );
};

export default SetupBYOSController;
