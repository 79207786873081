import { createContext } from 'react';

import {
  AggTable,
  Connector,
  ConnectorsBySchema,
  Transform,
  Favorite,
  FavoritesByTableID,
  Tag,
} from 'api/APITypes';
import { CSVUpload } from 'api/csvUploadAPI';
import { DbtDestinationTable, DbtRunConfig } from 'api/dbtAPI';
import { AggRecentTable } from 'api/tableAPI';

// Re-export some types:
export type { Connector, AggTable, Transform, ConnectorsBySchema, Favorite, FavoritesByTableID, Tag };

export interface TableModelsContextInterface {
  connectors: Connector[];
  hasConnector: boolean;
  connectorsByID: { [id: string]: Connector }; // ID is Mozart UUID, not Fivetran ID.
  connectorsBySchema: ConnectorsBySchema;
  tables: AggTable[]; // AggTables with transforms and connectors appended by AggTableReducer.
  tablesByID: { [id: string]: AggTable };
  tablesByFullName: { [fullName: string]: AggTable };
  transforms: Transform[];
  transformsByID: { [id: string]: Transform };
  recentTables: AggRecentTable[];
  favoriteTables: AggTable[];
  favoritesByTableID: FavoritesByTableID;
  tags: Tag[];
  dbtRunConfigs: DbtRunConfig[];
  dbtRunConfigsByID: { [id: string]: DbtRunConfig };
  dbtDestinationTables: DbtDestinationTable[];
  dbtDestinationTablesByID: { [id: string]: DbtDestinationTable };
  latestCsvUploads: CSVUpload[];
  allLoaded: boolean;
  loadedConnectors: boolean;
  loadedTransforms: boolean;
  anyLocal: boolean;
  anyError: string;
  connectorsError: string;
  transformsError: string;
  loadedDbtRunConfigs: boolean;
  updateTables: (updatedTables: AggTable[]) => void;
  updateTransforms: (newTransforms: Transform[]) => void;
  logRecent: (tableID: string) => void;
  addFavorite: (favorite: Favorite) => void;
  removeFavorite: (favorite: Favorite) => void;
  addTag: (tag: Tag) => void;
  removeTag: (tag: Tag) => void;
  updateTags: (tags: Tag[]) => void;
  addDbtRunConfig: (dbtRunConfig: DbtRunConfig) => void;
  removeDbtRunConfig: (dbtRunConfig: DbtRunConfig) => void;
  updateDbtRunConfigs: (dbtRunConfigs: DbtRunConfig[]) => void;
  addCsvUpload: (upload: CSVUpload) => void;
  removeCsvUpload: (upload: CSVUpload) => void;
  updateCsvUpload: (upload: CSVUpload) => void;
}

export const TableModelsContext = createContext<TableModelsContextInterface>({
  connectors: [],
  // True seems like a better default than false in this particular case.
  hasConnector: true,
  connectorsByID: {},
  connectorsBySchema: {},
  tables: [],
  tablesByID: {},
  tablesByFullName: {},
  transforms: [],
  transformsByID: {},
  recentTables: [],
  favoriteTables: [],
  favoritesByTableID: {},
  tags: [],
  dbtRunConfigs: [],
  dbtRunConfigsByID: {},
  dbtDestinationTables: [],
  dbtDestinationTablesByID: {},
  latestCsvUploads: [],
  allLoaded: false,
  loadedConnectors: false,
  loadedTransforms: false,
  anyLocal: false,
  anyError: '',
  connectorsError: '',
  transformsError: '',
  loadedDbtRunConfigs: false,
  updateTables: (updatedTables: AggTable[]) => {},
  updateTransforms: (newTransforms: Transform[]) => {},
  logRecent: (tableID: string) => {},
  addFavorite: (favorite: Favorite) => {},
  removeFavorite: (favorite: Favorite) => {},
  addTag: (tag: Tag) => {},
  removeTag: (tag: Tag) => {},
  updateTags: (tags: Tag[]) => {},
  addDbtRunConfig: (dbtRunConfig: DbtRunConfig) => {},
  removeDbtRunConfig: (dbtRunConfig: DbtRunConfig) => {},
  updateDbtRunConfigs: (dbtRunConfig: DbtRunConfig[]) => {},
  addCsvUpload: (upload: CSVUpload) => {},
  removeCsvUpload: (upload: CSVUpload) => {},
  updateCsvUpload: (upload: CSVUpload) => {},
});
