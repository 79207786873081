import { useContext, useMemo } from 'react';

import { DbtRunConfig } from 'api/dbtAPI';
import { TableModelsContext } from 'model_layer/TableModelsContext';

import { Command } from '../MozartCommandPalette';

export interface DbtJobCommand extends Command {
  dbtJob: DbtRunConfig;
}

export default function useDbtJobCommands(
  isOpen: boolean,
  openDbJobPath: (this: DbtJobCommand, modKeyPressed: boolean) => boolean,
) {
  const { dbtRunConfigs, allLoaded, anyError } = useContext(TableModelsContext);

  const dbtRunConfigCommands = useMemo<DbtJobCommand[]>(() => {
    if (isOpen && allLoaded && !anyError) {
      return dbtRunConfigs.map<DbtJobCommand>((dbtRunConfig) => ({
        name: dbtRunConfig.name,
        type: 'dbt_job',
        dbtJob: dbtRunConfig,
        command: openDbJobPath,
      }));
    } else {
      return [];
    }
  }, [isOpen, allLoaded, anyError, dbtRunConfigs, openDbJobPath]);

  return dbtRunConfigCommands;
}
