/**
 * Tools to do CRUD operations and useful calculations on the active FlowchartQueryModel.
 */
import { useCallback, useMemo } from 'react';

import { cloneDeep } from 'lodash';

import { patch } from 'utils/Array';

import {
  BiParentVertex,
  Edge,
  FlowchartQueryModel,
  FlowchartVertex,
  MonoParentVertex,
} from './FlowchartEditor/model/FlowchartQueryModel';

export interface FlowchartQueryModelTools {
  addVertex(vertex: FlowchartVertex): void;
  updateVertex(vertex: FlowchartVertex): void;
  deleteVertex(vertex: FlowchartVertex): void;
  addEdge(edge: Edge): void;
  deleteEdge(edge: Edge, fromVertex: FlowchartVertex, toVertex: FlowchartVertex): void;
}

const useFlowchartQueryModelTools = (
  setFlowchartQueryModel: React.Dispatch<React.SetStateAction<FlowchartQueryModel>>,
): FlowchartQueryModelTools => {
  const addVertex = useCallback(
    (newVertex: FlowchartVertex) => {
      setFlowchartQueryModel((flowchartQueryModel) => {
        const newState = {
          ...flowchartQueryModel,
          vertices: [...flowchartQueryModel.vertices, newVertex],
        };
        return newState;
      });
    },
    [setFlowchartQueryModel],
  );

  // Makes new objects to force render
  const updateVertex = useCallback(
    (updatedVertex: FlowchartVertex) => {
      setFlowchartQueryModel((flowchartQueryModel) => {
        const updatedVertices = patch(
          flowchartQueryModel.vertices,
          // Always create a new object to force rerenders
          cloneDeep(updatedVertex),
          (v) => v.id === updatedVertex.id,
        );
        return { ...flowchartQueryModel, vertices: updatedVertices };
      });
    },
    [setFlowchartQueryModel],
  );

  const deleteVertex = useCallback(
    (vertex: FlowchartVertex) => {
      setFlowchartQueryModel((oldState) => {
        // First, make new vertices so every vertice is forced to rerender.
        let vertices = cloneDeep(oldState.vertices);

        // Filter the deleted vertex out of verticies and edges
        vertices = vertices.filter((v) => v.id !== vertex.id);
        const edges = oldState.edges.filter(
          (e) => e.sourceID !== vertex.id && e.destinationID !== vertex.id,
        );

        // Unlink any vertex that is connected to the deleted vertex.
        vertices.forEach((v) => {
          v.unplug(vertex);
        });

        return {
          ...oldState,
          vertices,
          edges,
        };
      });
    },
    [setFlowchartQueryModel],
  );

  const addEdge = useCallback(
    (newEdge: Edge) =>
      setFlowchartQueryModel((oldState) => ({
        ...oldState,
        edges: [...oldState.edges, newEdge],
      })),
    [setFlowchartQueryModel],
  );

  // This method takes fromVertex and toVertex as parameters because it is problematic if
  // this method has a closure on flowchartQueryModel,
  // has to look fromVertex and toVertex up in flowchartQueryModel,
  // and then causes a write race condition with other code that has a closure on flowchartQueryModel.
  const deleteEdge = useCallback(
    (edge: Edge, fromVertex: FlowchartVertex, toVertex: FlowchartVertex) => {
      // 1. Delete the edge.
      setFlowchartQueryModel((oldState) => ({
        ...oldState,
        edges: oldState.edges.filter((e) => e.id !== edge.id),
      }));

      // 2. Delete the fromVertex's reference to the toVertex
      if (fromVertex) {
        fromVertex.childID = null;
        updateVertex(fromVertex);
      }

      // 3. Delete toVertex's reference to the fromVertex
      if (toVertex) {
        if (edge.destinationSocket === 'single') {
          (toVertex as MonoParentVertex).singleParentID = null;
          updateVertex(toVertex);
        } else if (edge.destinationSocket === 'left') {
          (toVertex as BiParentVertex).leftParentID = null;
          updateVertex(toVertex);
        } else if (edge.destinationSocket === 'right') {
          (toVertex as BiParentVertex).rightParentID = null;
          updateVertex(toVertex);
        }
      }

      // 4. Reset any internal state of the toVertex
      // that would be invalid after it loses the edge's connection.

      // I'm not sure we want to reset toVertex state in practice.
      // This deletes work you might want to redo by reconnecting the edge.
      // if (toVertex) {
      //   if (toVertex.type === 'select_columns') {
      //     const select = toVertex as SelectColumns;
      //     select.selectedColumns = undefined;
      //     updateVertex(select);
      //   } else if (toVertex.type === 'join') {
      //     const join = toVertex as Join;
      //     if (edge.destinationSocket === 'left') {
      //       join.leftColumn = '';
      //     }
      //     if (edge.destinationSocket === 'right') {
      //       join.rightColumn = '';
      //     }
      //     updateVertex(join);
      //   }
      // }
    },
    [setFlowchartQueryModel, updateVertex],
  );

  return useMemo(
    () => ({
      setFlowchartQueryModel,
      addVertex,
      updateVertex,
      deleteVertex,
      addEdge,
      deleteEdge,
    }),
    [setFlowchartQueryModel, addVertex, updateVertex, deleteVertex, addEdge, deleteEdge],
  );
};

export default useFlowchartQueryModelTools;
