import { Connector, ConnectorStatus as APIConnectorStatus } from 'api/APITypes';
import { Status as GenericRunStatus } from 'components/primitives/icons/StatusIcon/StatusIcon';
import { agoDate, formatDate, parseIso } from 'utils/dateTime';

export type SetupState = 'connected' | 'broken' | 'incomplete';
export type SyncState = 'scheduled' | 'syncing' | 'paused' | 'rescheduled';

export function calcSyncState(connector: Connector): SyncState {
  const { succeeded_at, failed_at, status } = connector;
  let { setup_state, sync_state } = status;

  // When you first create a connector Fivetran
  // does not set the value of sync_state to syncing before
  // the Fivetran connect-card process redirects the user back
  // to Mozart and we query the Fivetran connectors list for sync_state.
  // So our definition of isSyncing is lenient in order to make the
  // syncing spinner render after you first create a connection.
  if (
    setup_state === 'connected' &&
    failed_at === null &&
    succeeded_at === null &&
    sync_state === 'scheduled'
  ) {
    sync_state = 'syncing';
  }

  return sync_state as SyncState;
}

export function lastRunForConnector(connector: Connector) {
  const { succeeded_at, failed_at } = connector;

  let lastRunError = '';
  let succeededAt = parseIso(succeeded_at);
  let failedAt = parseIso(failed_at);
  let lastRunAt = succeededAt;
  let lastRunFailed = false;
  if (failedAt && (!succeededAt || failedAt > succeededAt)) {
    lastRunError = 'Last sync failed.';
    if (succeededAt) {
      lastRunError += ` Last successful sync was ${agoDate(lastRunAt)} on ${formatDate(succeededAt)}`;
    }
    lastRunAt = failedAt;
    lastRunFailed = true;
  }

  return { lastRunAt, lastRunFailed, lastRunError };
}

/*
Fivetran has tasks and warnings.
Tasks are actually the worse of the two.

Tasks represent a connector being configured incorrectly.
Tasks must be fixed before the connector will work.
Some tasks are ignored because they accompany
a status code that is already handled.

Warnings represent a hicup in the system like a malformed file.
Some warnings are ignored because they are noise the user cannot fix.

We only show tasks and warnings that are know to Mozart and vetted on the whitelist:
https://docs.google.com/spreadsheets/d/1tlaOpqprQcxn5Yngo2iJ0xv07ZMeHlLg7fLQvLR-n3A

We are only going to show the most important task or warning at this point in time.

We are going to reword Fivetran's messages to be more helpful.
*/
export function taskWarningForStatus(status: APIConnectorStatus): { twCode: string; twError: string } {
  let twCode = '';
  let twError = '';
  let { warnings } = status;
  const firstWarning = warnings[0];
  if (firstWarning) {
    if (firstWarning.code === 'fix_replication_stopped') {
      twCode = firstWarning.code;
      twError =
        'Fix replication stopped. It looks like replication was stopped by an administrator. We need your database administrator to resume replication.';
    }
  }

  return { twCode, twError };
}

export type ConnectorStatus =
  | 'error'
  | 'syncing'
  | 'paused'
  | 'success'
  | 'broken'
  | 'incomplete'
  | 'NA';

export function getConnectorStatus(
  setupState: SetupState,
  syncState: SyncState,
  lastRunFailed: boolean,
): ConnectorStatus {
  let status: ConnectorStatus = 'NA';

  if (setupState === 'broken' && (syncState === 'scheduled' || syncState === 'rescheduled')) {
    status = 'broken';
  } else if (setupState === 'incomplete') {
    status = 'incomplete';
  } else if (syncState === 'paused') {
    status = 'paused';
  } else if (syncState === 'syncing') {
    status = 'syncing';
  } else if (syncState === 'scheduled' || syncState === 'rescheduled') {
    status = lastRunFailed ? 'error' : 'success';
  }

  return status;
}

export function calcGenericRunStatus(connector: Connector): GenericRunStatus {
  const { status } = connector;
  const setupState = status.setup_state as SetupState;
  const syncState = calcSyncState(connector);
  const { lastRunFailed } = lastRunForConnector(connector);
  return getConnectorStatus(setupState, syncState, lastRunFailed);
}

export const SYNC_FREQUENCIES = [
  { value: 1, label: 'Every minute' },
  { value: 5, label: 'Every 5 minutes' },
  { value: 15, label: 'Every 15 minutes' },
  { value: 30, label: 'Every 30 minutes' },
  { value: 60, label: 'Every hour' },
  { value: 120, label: 'Every 2 hours' },
  { value: 360, label: 'Every 6 hours' },
  { value: 720, label: 'Every 12 hours' },
  { value: 1440, label: 'Every 24 hours' },
];

export const DAILY_SYNC_UTC_TIMES = [
  { value: '00:00', label: '00:00 UTC' },
  { value: '00:30', label: '00:30 UTC' },
  { value: '01:00', label: '01:00 UTC' },
  { value: '01:30', label: '01:30 UTC' },
  { value: '02:00', label: '02:00 UTC' },
  { value: '02:30', label: '02:30 UTC' },
  { value: '03:00', label: '03:00 UTC' },
  { value: '03:30', label: '03:30 UTC' },
  { value: '04:00', label: '04:00 UTC' },
  { value: '04:30', label: '04:30 UTC' },
  { value: '05:00', label: '05:00 UTC' },
  { value: '05:30', label: '05:30 UTC' },
  { value: '06:00', label: '06:00 UTC' },
  { value: '06:30', label: '06:30 UTC' },
  { value: '07:00', label: '07:00 UTC' },
  { value: '07:30', label: '07:30 UTC' },
  { value: '08:00', label: '08:00 UTC' },
  { value: '08:30', label: '08:30 UTC' },
  { value: '09:00', label: '09:00 UTC' },
  { value: '09:30', label: '09:30 UTC' },
  { value: '10:00', label: '10:00 UTC' },
  { value: '10:30', label: '10:30 UTC' },
  { value: '11:00', label: '11:00 UTC' },
  { value: '11:30', label: '11:30 UTC' },
  { value: '12:00', label: '12:00 UTC' },
  { value: '12:30', label: '12:30 UTC' },
  { value: '13:00', label: '13:00 UTC' },
  { value: '13:30', label: '13:30 UTC' },
  { value: '14:00', label: '14:00 UTC' },
  { value: '14:30', label: '14:30 UTC' },
  { value: '15:00', label: '15:00 UTC' },
  { value: '15:30', label: '15:30 UTC' },
  { value: '16:00', label: '16:00 UTC' },
  { value: '16:30', label: '16:30 UTC' },
  { value: '17:00', label: '17:00 UTC' },
  { value: '17:30', label: '17:30 UTC' },
  { value: '18:00', label: '18:00 UTC' },
  { value: '18:30', label: '18:30 UTC' },
  { value: '19:00', label: '19:00 UTC' },
  { value: '19:30', label: '19:30 UTC' },
  { value: '20:00', label: '20:00 UTC' },
  { value: '20:30', label: '20:30 UTC' },
  { value: '21:00', label: '21:00 UTC' },
  { value: '21:30', label: '21:30 UTC' },
  { value: '22:00', label: '22:00 UTC' },
  { value: '22:30', label: '22:30 UTC' },
  { value: '23:00', label: '23:00 UTC' },
  { value: '23:30', label: '23:30 UTC' },
];

export function humanSyncFrequency(syncFrequency: number) {
  const syncFreqObj = SYNC_FREQUENCIES.find((f) => f.value === syncFrequency);
  return syncFreqObj?.label || `${syncFrequency} minutes`;
}

export function lastRunDate(connector: Connector) {
  let parsedSucceededAt = null;
  let parsedFailedAt = null;
  if (connector.succeeded_at) {
    parsedSucceededAt = parseIso(connector.succeeded_at);
  }
  if (connector.failed_at) {
    parsedFailedAt = parseIso(connector.failed_at);
  }
  if (parsedSucceededAt && parsedFailedAt) {
    return parsedSucceededAt > parsedFailedAt ? parsedSucceededAt : parsedFailedAt;
  }
  if (parsedSucceededAt) {
    return parsedSucceededAt;
  }
  if (parsedFailedAt) {
    return parsedFailedAt;
  }

  return null;
}

export function lastCompletedRunVersionSavedBy(connector: Connector): string {
  return 'N/A';
}

export function lastCompletedRunVersionSavedAt(connector: Connector): Date | null {
  return null;
}
