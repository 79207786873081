import { LookerIntegration } from 'api/APITypes';
import GenerateSnowflakeUser from 'components/business_logic/GenerateSnowflakeUser/GenerateSnowflakeUser';
import Accordion from 'components/widgets/accordions/Accordion/Accordion';

import ExternalLink from '../../ExternalLink';
import { DatabaseConfigInfoResponse } from '../../IntegrationSnowflakeConfig';
import SnowflakeConfig from '../../SnowflakeConfig';
import Steps from '../../Steps';

import { defaultIntegrationMap } from '../DefaultLayoutData';

import APIKeyForm from './APIKeyForm';
import ConnectSnowflake from './ConnectSnowflake';
import { LookerScreen } from './LookerController';
import TryLooker from './TryLooker';
import YouAreAlreadyUsingLooker from './YouAreAlreadyUsingLooker';

export interface LookerAPIKeyFormValues {
  url: string;
  client_id: string;
  client_secret: string;
}

export interface LookerViewProps {
  values: DatabaseConfigInfoResponse | undefined;
  apiKeyScreen: LookerScreen;
  setupSnowflakeScreen: LookerScreen;
  handleManualConnectionInstructionsClick: (newExpand: boolean) => void;
  handleShowAPIKeysIntegrationClick: () => void;
  handleShowSnowflakeInLookerClick: () => void;
  handleSubmitAPIKey: (values: LookerAPIKeyFormValues) => void;
  handleDoSetupSnowflakeInLooker: () => void;
  databaseType: 'bigquery' | 'snowflake';
  databaseDisplayName: string;
  lookerIntegration: LookerIntegration | null;
}

const LookerView = (props: LookerViewProps) => {
  const {
    values,
    apiKeyScreen,
    setupSnowflakeScreen,
    handleManualConnectionInstructionsClick,
    handleShowAPIKeysIntegrationClick,
    handleShowSnowflakeInLookerClick,
    handleSubmitAPIKey,
    handleDoSetupSnowflakeInLooker,
    databaseType,
    databaseDisplayName,
    lookerIntegration,
  } = props;
  const { key, type, url, steps, bigQuerySteps, fields, bigQueryFields, uiName, ...rest } =
    defaultIntegrationMap['looker'];
  const actualSteps = databaseType === 'bigquery' && bigQuerySteps ? bigQuerySteps : steps;
  const actualFields = databaseType === 'bigquery' && bigQueryFields ? bigQueryFields : fields;

  if (apiKeyScreen.show) {
    return (
      <APIKeyForm
        handleSubmitAPIKey={handleSubmitAPIKey}
        loading={apiKeyScreen.loading}
        error={apiKeyScreen.error}
        handleShowAPIKeysIntegrationClick={handleShowAPIKeysIntegrationClick}
      />
    );
  }

  if (setupSnowflakeScreen.show) {
    return (
      <ConnectSnowflake
        handleShowSnowflakeInLookerClick={handleShowSnowflakeInLookerClick}
        handleDoSetupSnowflakeInLooker={handleDoSetupSnowflakeInLooker}
        settingUpSnowflake={setupSnowflakeScreen.loading}
        setupSnowflakeError={setupSnowflakeScreen.error}
        databaseDisplayName={databaseDisplayName}
      />
    );
  }

  return (
    <div>
      {lookerIntegration ? (
        <YouAreAlreadyUsingLooker
          lookerIntegration={lookerIntegration}
          handleShowSnowflakeInLookerClick={handleShowSnowflakeInLookerClick}
          databaseDisplayName={databaseDisplayName}
        />
      ) : (
        <TryLooker
          handleShowAPIKeysIntegrationClick={handleShowAPIKeysIntegrationClick}
          databaseDisplayName={databaseDisplayName}
        />
      )}
      <Accordion
        header="Connect to Looker manually"
        onClick={handleManualConnectionInstructionsClick}
        className="mt-6"
      >
        <Steps containerClass="bg-pri-gray-50 pb-4 pr-4 rounded">
          <li>
            Log into <ExternalLink href={url}>{uiName}</ExternalLink>.
          </li>
          {actualSteps.map((step, idx) => (
            <li key={idx}>{step}</li>
          ))}
        </Steps>
        <SnowflakeConfig
          {...rest}
          fields={actualFields}
          databaseDisplayName={databaseDisplayName}
          values={values}
        />
        {databaseType === 'snowflake' && <GenerateSnowflakeUser className="mt-4" />}
      </Accordion>
    </div>
  );
};

export default LookerView;
