import React, { useState, useEffect, useCallback } from 'react';

import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';

import cn from 'classnames';
import { Formik } from 'formik';
import qs from 'query-string';
import * as Yup from 'yup';

import { ILogin, ILogin2FA } from 'api/APIAuth';
import QRCodeComponent from 'components/business_logic/QRCodeComponent/QRCodeComponent';
import Button from 'components/inputs/basic/Button/Button';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import LoggedOutFormBackgroundLayout from 'components/layouts/pages/LoggedOutFormBackgroundLayout/LoggedOutFormBackgroundLayout';
import FormLayoutTitleRow from 'components/layouts/parts/FormLayoutTitleRow/FormLayoutTitleRow';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useAuth } from 'context/AuthContext';

import { GoogleLogin } from '@react-oauth/google';

interface TotpData {
  totp_secret: string;
  totp_url: string;
}

const loginSchema = Yup.object().shape({
  username: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().trim().min(8, 'At least 8 characters').required('Required'),
});

const login2FASchema = Yup.object().shape({
  username: Yup.string().email('Invalid email'),
  password: Yup.string().trim().min(8, 'At least 8 characters'),
  google_credential: Yup.string(),
  okta_code: Yup.string(),
  totp_token: Yup.string()
    .trim()
    .min(6, 'Exactly 6 digits')
    .max(6, 'Exactly 6 digits')
    .required('Required'),
});

const initialValues: ILogin2FA = {
  username: '',
  password: '',
  totp_token: '',
};

const Login = (props: RouteComponentProps) => {
  const myLocation = useLocation();
  const { from } = myLocation.state || { from: { pathname: undefined, search: undefined } };
  const history = useHistory();

  // Handle urls with and without query parameters:
  //   /query
  //   /query?query=SELECT%20*%20FROM%20schema.table_name
  let forwardToAfterLogin: string = from.pathname;
  if (from.pathname && from.search) {
    forwardToAfterLogin = from.pathname + from.search;
  }

  // this state controls whether or not the password field is obscured
  const [error, setError] = useState('');
  const [mozartLoggingIn, setMozartLoggingIn] = useState(false);
  const [googleLoggingIn, setGoogleLoggingIn] = useState(false);
  const [oktaLoggingIn, setOktaLoggingIn] = useState(false);
  const [loggingIn2FA, setLoggingIn2FA] = useState(false);
  const [isOpen2FA, setIsOpen2FA] = useState(false);
  const [initial2FAValues, setInitial2FAValues] = useState(initialValues);
  const [totpData, setTotpData] = useState<TotpData | null>(null);
  const { login, login2FA } = useAuth();
  const isFromResetPage = qs.parse(myLocation.search)?.reset === 'true';
  const oktaCode = qs.parse(myLocation.search)?.code as string;
  const oktaState = qs.parse(myLocation.search)?.state as string;

  const open2FAModal = () => {
    analytics.track('Login Open2FA');
    setIsOpen2FA(true);
  };

  const close2FAModal = () => {
    analytics.track('Login Close2FA');
    setIsOpen2FA(false);
  };

  const handleLogin = useCallback(
    (form: ILogin) => {
      setError('');
      return login(form, () => forwardToAfterLogin)
        .then((response) => {
          if (response.require_2FA) {
            setInitial2FAValues(form as ILogin2FA);
            setTotpData(response.totp_data);
            open2FAModal();
          }
        })
        .catch((e) => {
          if (form.google_credential || form.okta_code) {
            setError('There was a problem logging into your account with SSO.');
          } else {
            setError(
              'Unable to log in with provided credentials. Your credentials may be incorrect, your account may be deactivated, or your company may require you to log in with SSO.',
            );
          }
        });
    },
    [forwardToAfterLogin, login],
  );

  const anyLoggingIn = mozartLoggingIn || googleLoggingIn || oktaLoggingIn;
  useEffect(() => {
    if (oktaCode && !anyLoggingIn && !error && !isOpen2FA) {
      setOktaLoggingIn(true);
      const form = { okta_code: oktaCode, username: oktaState };
      handleLogin(form).finally(() => {
        setOktaLoggingIn(false);
      });
    }
  }, [oktaCode, anyLoggingIn, error, isOpen2FA, oktaState, handleLogin]);

  const handleClickOktaButton = () => {
    history.push('/okta_email_submit');
  };

  const handleMozartFormSubmit = (values: any) => {
    setMozartLoggingIn(true);
    const postData = loginSchema.cast(values);
    handleLogin(postData).finally(() => {
      setMozartLoggingIn(false);
    });
  };

  const handleGoogleFormSubmit = (values: any) => {
    setGoogleLoggingIn(true);
    handleLogin(values).finally(() => {
      setGoogleLoggingIn(false);
    });
  };

  const handleFormSubmit2FA = (values: any) => {
    const postData = login2FASchema.cast(values);
    setError('');
    setLoggingIn2FA(true);
    login2FA(postData, () => forwardToAfterLogin)
      .catch((e) => {
        if (e.response?.data?.error === 'The given 2FA token is not valid.') {
          setError('The given 2FA token is not valid.');
        } else if (postData.google_credential || postData.okta_code) {
          setError('There was a problem logging into your account with SSO.');
        } else {
          setError(
            'Unable to log in with provided credentials. Your credentials may be incorrect, your account may be deactivated, or your company may require you to log in with SSO.',
          );
        }
        close2FAModal();
      })
      .finally(() => {
        setLoggingIn2FA(false);
      });
  };

  const twoFAModal = (
    <Modal onClose={close2FAModal} header="2-Factor Authentication">
      <Formik
        initialValues={initial2FAValues}
        onSubmit={handleFormSubmit2FA}
        validationSchema={login2FASchema}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            {totpData ? (
              <div className="py-6 px-10 f-col items-center">
                <p>
                  Please register with a 2FA mobile app (Google Authenticator or similar) and submit a
                  2FA token to finish enabling 2FA.
                </p>
                <QRCodeComponent totp_secret={totpData.totp_secret} totp_url={totpData.totp_url} />
                <TextFormikGroup autoFocus name="totp_token" type="text" groupClass="mt-4 w-full" />
              </div>
            ) : (
              <div className="py-6 px-10 f-col items-center">
                <p>Please input the token from your authentication app on your phone.</p>
                <TextFormikGroup autoFocus name="totp_token" type="text" groupClass="mt-4 w-full" />
              </div>
            )}
            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <Button variant="lightDanger" onClick={close2FAModal}>
                Close
              </Button>
              <Button type="submit" variant="lightAction" spinning={loggingIn2FA} className="ml-4">
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );

  return (
    <LoggedOutFormBackgroundLayout>
      <div className="w-full h-full min-h-full p-4 flex overflow-auto">
        <div className="w-full lg:w-[444px] m-auto">
          <Helmet>
            <title>Login to your Mozart Data Account | Mozart Data</title>
            <meta
              name="description"
              content="Login to your mozart data account and start managing your data across all your data sources in one place."
            />
          </Helmet>
          {isOpen2FA && twoFAModal}
          {isFromResetPage && (
            <Alert variant="no_results" className="mb-4">
              <div>Your password was reset successfully.</div>
              <div>Please log in to continue.</div>
            </Alert>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={handleMozartFormSubmit}
            validationSchema={loginSchema}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <img className="h-[28px] mb-10" src="/images/logos/MozartDataLogoPurple.svg" alt="" />
                <FormLayoutTitleRow
                  title="Log In"
                  linkText="Sign Up"
                  linkTo="/signup"
                  linkDataTrack="Login Signup"
                />
                <TextFormikGroup
                  autoFocus
                  type="email"
                  name="username"
                  label="Email"
                  placeholder="wolfgang@mozartdata.com"
                  groupClass="mt-4"
                />

                <TextFormikGroup
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="At least 8 characters"
                  groupClass="mt-4"
                />
                {error && (
                  <Alert variant="error" className="mt-4">
                    {error}
                  </Alert>
                )}
                <div className="f-row-y-center mt-4">
                  <Button
                    type="submit"
                    variant="lightAction"
                    spinning={mozartLoggingIn}
                    disabled={googleLoggingIn || oktaLoggingIn}
                    className="w-[214px]"
                  >
                    Log In
                  </Button>
                  <Link
                    className="text-sec-blue-light-700 ml-4"
                    to="/request-reset"
                    data-track="Login ForgotPassword"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </form>
            )}
          </Formik>
          <div className="w-full f-center my-2 text-base">or</div>
          <div className="f-row-y-center w-full">
            <GoogleButton
              disabled={mozartLoggingIn || oktaLoggingIn}
              handleLogin={handleGoogleFormSubmit}
              setError={setError}
            />
            <OktaButton
              disabled={mozartLoggingIn || googleLoggingIn}
              handleClick={handleClickOktaButton}
            />
          </div>
        </div>
      </div>
    </LoggedOutFormBackgroundLayout>
  );
};

export default Login;

/*******************************************************************************
 * Login Buttons
 ******************************************************************************/
interface GoogleButtonProps extends EnabledGoogleLoginProps {
  disabled: boolean;
}
const GoogleButton = ({ disabled, ...enabledProps }: GoogleButtonProps) => {
  return disabled ? <DisabledGoogleLogin /> : <EnabledGoogleLogin {...enabledProps} />;
};

// The contents of the GoogleLogin button is very dynamic depending on circumstances.
// It also doesn't let you disable it or restyle it.
// So we are just going to build a fake DisabledGoogleLogin.
const DisabledGoogleLogin = () => {
  return (
    <SSOButton
      logoSrc="/images/logos/googleLogo.png"
      logoAlt="Google Logo"
      text="Sign in with Google"
      disabled={true}
      handleClick={() => {}}
    />
  );
};

interface EnabledGoogleLoginProps {
  handleLogin: (values: any) => void;
  setError: (value: React.SetStateAction<string>) => void;
}
const EnabledGoogleLogin = ({ handleLogin, setError }: EnabledGoogleLoginProps) => {
  return (
    <GoogleLogin
      onSuccess={(credentialResponse) => {
        const form = { google_credential: credentialResponse['credential'] };
        handleLogin(form);
      }}
      onError={() => {
        setError('An error occured when attempting to login using Google SSO');
      }}
      width={214}
      containerProps={{ className: 'SSOSpinnerClassName' }}
    />
  );
};

interface OktaButtonProps {
  disabled: boolean;
  handleClick: () => void;
}

const OktaButton = (props: OktaButtonProps) => {
  return (
    <SSOButton
      logoSrc="/images/logos/oktaLogo.png"
      logoAlt="Okta Logo"
      text="Sign in with Okta"
      className="ml-4"
      {...props}
    />
  );
};

interface SSOButtonProps {
  logoSrc: string;
  logoAlt: string;
  text: string;
  disabled: boolean;
  className?: string;
  handleClick: (values: any) => void;
}

const SSOButton = (props: SSOButtonProps) => {
  const { logoSrc, logoAlt, text, disabled, className, handleClick } = props;

  const SSOBaseClassName = 'h-[40px] w-[214px] rounded border';
  const colorClass = disabled
    ? 'bg-pri-gray-100 text-pri-gray-400 border-pri-gray-100'
    : 'border-pri-gray-300 hover:bg-pri-gray-100';

  return (
    <button
      className={cn(SSOBaseClassName, colorClass, className)}
      onClick={handleClick}
      disabled={disabled}
    >
      <div className="f-center">
        <img className="w-[18px] h-[18px] min-w-[18px] min-h-[18px]" src={logoSrc} alt={logoAlt} />
        <span className="ml-3">{text}</span>
      </div>
    </button>
  );
};
