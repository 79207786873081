import { createContext } from 'react';

import { Transform } from 'api/APITypes';

export interface TransformContextInterface {
  transforms: Transform[];
  isLoading: boolean;
  error: string;
  isLocal: boolean;
  updateTransforms: (newTransforms: Transform[]) => void;
  removeTransform: (transformToRemove: Transform) => void;
}

export const TransformContext = createContext<TransformContextInterface>({
  transforms: [],
  isLoading: false,
  error: '',
  isLocal: false,
  updateTransforms: () => {},
  removeTransform: () => {},
});
