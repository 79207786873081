import React, { useState } from 'react';

import { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import { DataAlertWithTable, SavableDataAlertProps } from 'hooks/useDataAlerts';

import DataAlertRowView from './DataAlertRowView';

export interface DataAlertRowControllerProps {
  dataAlert: DataAlertWithTable;
  isOverallAlertsPage: boolean;
  analyticsLocation: 'TableTestTab' | 'DataAlertsPage';
  onUpdateDataAlert: (
    dataAlert: DataAlertWithTable,
    savableProps: SavableDataAlertProps,
  ) => Promise<DataAlertWithTable>;
  onDeleteDataAlert: (dataAlert: DataAlertWithTable) => void;
  onSelectDataAlert: (dataAlert: DataAlertWithTable) => void;
  onGetFailureLogs: (dataAlert: DataAlertWithTable) => void;
}

export default function DataAlertRowController(props: DataAlertRowControllerProps) {
  const {
    dataAlert,
    isOverallAlertsPage,
    analyticsLocation,
    onUpdateDataAlert,
    onDeleteDataAlert,
    onSelectDataAlert,
    onGetFailureLogs,
  } = props;
  const [loadingSetMode, setLoadingSetMode] = useState(false);
  const [loadingSetEnabled, setLoadingSetEnabled] = useState(false);
  const [loadingSetAttachCSV, setLoadingSetAttachCSV] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const setMode = (mode: ListboxValue) => {
    const postData = { mode: mode as string };
    const tracking =
      mode === 'block' ? `${analyticsLocation} SetBlockMode` : `${analyticsLocation} SetNotifyMode`;
    setLoadingSetMode(true);
    onUpdateDataAlert(dataAlert, postData)
      .then((newDataAlert) => {
        analytics.track(tracking);
      })
      .catch(() => {
        // Error handled in onUpdateDataAlert
      })
      .finally(() => {
        setLoadingSetMode(false);
      });
  };

  const handleToggleEnabled = () => {
    const newEnabled = !dataAlert.enabled;
    const postData = { enabled: newEnabled };
    setLoadingSetEnabled(true);
    onUpdateDataAlert(dataAlert, postData)
      .then((newDataAlert) => {
        analytics.track(
          newEnabled ? `${analyticsLocation} EnableTest` : `${analyticsLocation} DisableTest`,
        );
      })
      .catch(() => {
        // Error handled in onUpdateDataAlert
      })
      .finally(() => {
        setLoadingSetEnabled(false);
      });
  };

  const handleToggleAttachCSV = () => {
    const newAttachCSV = !dataAlert.should_send_csv;
    const postData = { should_send_csv: newAttachCSV };
    setLoadingSetAttachCSV(true);
    onUpdateDataAlert(dataAlert, postData)
      .then((newDataAlert) => {
        analytics.track(
          newAttachCSV
            ? `${analyticsLocation} EnableAttachCSV`
            : `${analyticsLocation} DisableAttachCSV`,
        );
      })
      .catch(() => {
        // Error handled in onUpdateDataAlert
      })
      .finally(() => {
        setLoadingSetAttachCSV(false);
      });
  };

  const handleCancelDelete = () => {
    analytics.track(`${analyticsLocation} CancelDelete`);
    setConfirmDelete(false);
  };

  const handleOpenDeleteDataAlertModal = () => {
    analytics.track(`${analyticsLocation} OpenConfirmDeleteModal`);
    setConfirmDelete(true);
  };

  const handleConfirmDeleteDataAlert = () => {
    // Tracking for actual delete is handling in onDeleteDataAlert
    onDeleteDataAlert(dataAlert);
    setConfirmDelete(false);
  };

  return (
    <DataAlertRowView
      dataAlert={dataAlert}
      isOverallAlertsPage={isOverallAlertsPage}
      loadingSetMode={loadingSetMode}
      loadingSetEnabled={loadingSetEnabled}
      loadingSetAttachCSV={loadingSetAttachCSV}
      confirmDelete={confirmDelete}
      setMode={setMode}
      onToggleEnabled={handleToggleEnabled}
      onToggleAttachCSV={handleToggleAttachCSV}
      onCancelDelete={handleCancelDelete}
      onOpenDeleteDataAlertModal={handleOpenDeleteDataAlertModal}
      onConfirmDeleteDataAlert={handleConfirmDeleteDataAlert}
      onSelectDataAlert={onSelectDataAlert}
      onGetFailureLogs={onGetFailureLogs}
    />
  );
}
