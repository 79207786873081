import React, { useState } from 'react';

import cn from 'classnames';

import { ConnectorLogMessage } from 'api/connectorsAPI';
import AnimatedAngleIcon from 'components/primitives/icons/AnimatedAngleIcon/AnimatedAngleIcon';

interface MessageRowProps {
  isFirst: boolean;
  message: ConnectorLogMessage;
  startingIsOpen?: boolean; // This is a testing convenience
}

export default function MessageRow(props: MessageRowProps) {
  const { isFirst, message, startingIsOpen } = props;
  const [isOpen, setIsOpen] = useState(startingIsOpen || false);

  const handleToggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const bgColor = () => {
    if (message.event === 'SEVERE') {
      return 'bg-pri-error-200';
    }
    if (message.event === 'WARNING') {
      return 'bg-pri-warning-200';
    }
    return 'bg-pri-gray-100';
  };

  const stringifiedMessageData = JSON.stringify(message.message_data || message, null, ' ');

  return (
    <div key={message.timestamp} className="f-col font-mono text-sm">
      {isFirst && <Divider />}
      <div onClick={handleToggleAccordion} className="h-[44px] f-row-y-center hover:cursor-pointer">
        {/*
          The AnimatedAngleIcon is two pixels narrower than the LogStatusIcon in LogRow.
          Which if you want to center the AnimatedAngleIcon relative to the center of the LogStatusIcon
          is 2px / 2 = 1px of addtional space(8px + 1px = 9px)
        */}
        <AnimatedAngleIcon className="m-1 ml-[9px] flex-shrink-0" isOpen={isOpen} mode="accordion" />
        <div className={cn('p-1 m-1 rounded', bgColor())}>{message.message_event}</div>
        <div className="whitespace-nowrap overflow-hidden text-ellipsis">{stringifiedMessageData}</div>
      </div>
      {isOpen && (
        <>
          {/* 29px for the AnimatedAngleIcon + 4px of margin on the next div = 33px of margin here to align the div edges. */}
          <span className="ml-[33px] mb-1 p-2 bg-pri-gray-100 rounded text-sec-blue-gray-700 whitespace-pre-wrap">
            {JSON.stringify(message, null, 2)}
          </span>
        </>
      )}
      <Divider />
    </div>
  );
}

function Divider() {
  return <div className="w-full h-[1px] bg-pri-gray-200"></div>;
}
