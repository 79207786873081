/**
 * Type definitions, API methods, and conversion utilities for Fix SQL AI and Explain SQL AI.
 */
import { AxiosResponse } from 'axios';

import API from 'api/API';

/*******************************************************************************
 * Types
 ******************************************************************************/
type FixSqlRequestParams = {
  sql: string;
  sql_error: string;
};

type FixSqlResponse = {
  gpt_response_id: string;
  fixed_sql: string;
};

type ExplainSqlRequestParams = {
  sql: string;
};

type ExplainSqlResponse = {
  gpt_response_id: string;
  explanation: string;
};

/*******************************************************************************
 * Fix SQL APIs
 ******************************************************************************/
const fixSqlAIAPI = {
  generateSqlFix: (params: FixSqlRequestParams): Promise<AxiosResponse<FixSqlResponse, any>> => {
    const api = new API();
    return api.post(`/api/transforms/fix_sql_with_gpt`, params);
  },
};

const explainSqlAIAPI = {
  generateSqlExplanation: (
    params: ExplainSqlRequestParams,
  ): Promise<AxiosResponse<ExplainSqlResponse, any>> => {
    const api = new API();
    return api.post(`/api/transforms/generate_sql_explanation_with_gpt`, params);
  },
};

const sqlAIAPI = {
  ...fixSqlAIAPI,
  ...explainSqlAIAPI,
};

export default sqlAIAPI;
