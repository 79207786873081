import React from 'react';

import { QueryRunResults } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import CopyButton from 'components/inputs/basic/Button/CopyButton';
import IconButton from 'components/inputs/basic/Button/IconButton';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import AnimatedAngleIcon from 'components/primitives/icons/AnimatedAngleIcon/AnimatedAngleIcon';

export const CONTROL_BAR_HEIGHT = 44;

interface RunRowProps {
  running: boolean;
  exportingCsv: boolean;
  exportingGSheet: boolean;
  runResults: QueryRunResults | null;
  lastRunSql: string;
  isTransform: boolean;
  showAdvanced: boolean;
  onCopyToClipboard: () => void;
  onExportCsv: () => void;
  onExportGSheet: () => void;
  onToggleAdvanced: () => void;
  onMaximizeRunResults: () => void;
  onMinimizeRunResults: () => void;
  onSetEditorHeightHalfway: () => void;
}

const ResultsControlBar = React.memo((props: RunRowProps) => {
  const {
    running,
    exportingCsv,
    exportingGSheet,
    runResults,
    lastRunSql,
    isTransform,
    showAdvanced,
    onCopyToClipboard,
    onExportCsv,
    onExportGSheet,
    onToggleAdvanced,
    onMaximizeRunResults,
    onMinimizeRunResults,
    onSetEditorHeightHalfway,
  } = props;

  const showResultButtons = !running && lastRunSql !== '' && runResults;

  const wrappedHandleToggleAdvanced = () => {
    analytics.track(!showAdvanced ? 'RunRow ShowAdvancedOptions' : 'RunRow HideAdvancedOptions', {
      isTransform,
    });
    if (!showAdvanced) {
      onSetEditorHeightHalfway();
    }
    onToggleAdvanced();
  };

  return (
    <div className="f-between items-center p-2 bg-white" style={{ height: CONTROL_BAR_HEIGHT }}>
      <div className="f-row-y-center">
        <div className="text-base font-medium">Result Table</div>
        {runResults && (
          <p className="text-pri-gray-500 font-medium mx-2">{`${runResults.rows.length} rows`}</p>
        )}
        {showResultButtons && (
          <div className="f-row-y-center ml-6">
            <CopyButton onClick={onCopyToClipboard} text="COPY" />
            <IconButton
              icon="Download"
              text="CSV"
              variant="lightDullTransparent"
              size="small"
              onClick={onExportCsv}
              spinning={exportingCsv}
              disabled={running}
            />
            <IconButton
              icon="Download"
              text="GSHEET"
              variant="lightDullTransparent"
              size="small"
              onClick={onExportGSheet}
              spinning={exportingGSheet}
              disabled={running}
            />
          </div>
        )}
      </div>
      <div className="f-row-y-center">
        {showResultButtons && (
          <Button
            variant="lightDullTransparent"
            size="small"
            onClick={wrappedHandleToggleAdvanced}
            className="min-w-[134px] mr-2"
          >
            <div className="f-row-y-center">
              <div className="mr-2 text-xs font-medium">VIEW SETTINGS</div>
              <AnimatedAngleIcon isOpen={showAdvanced} mode="button" />
            </div>
          </Button>
        )}
        <div className="f-row-y-center">
          <TooltipTrigger tip="Maximize SQL Editor">
            <IconButton
              icon="ArrowBarDown"
              variant="lightDullTransparent"
              size="small"
              onClick={onMinimizeRunResults}
            />
          </TooltipTrigger>{' '}
          <TooltipTrigger tip="Maximize Results">
            <IconButton
              icon="ArrowBarUp"
              variant="lightDullTransparent"
              size="small"
              onClick={onMaximizeRunResults}
            />
          </TooltipTrigger>
        </div>
      </div>
    </div>
  );
});

export default ResultsControlBar;
