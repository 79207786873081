/*
HTML <input type="radio" /> does not behave like other HTML input elements.
To make a single usable input from the user's perspective you create 
a list of <input type="radio" /> elements with the same name.
Each <input type="radio" /> in this list is an option from the user's perspective.

<RadioInput /> is our React wrapper around <input type="radio" />.

Given a list of options, this component renders a list of <RadioInput />s,
so a single React component can function as a single input from the user's perspective
but actually render many <RadioInputs />.
*/
import React, { CSSProperties, ChangeEventHandler } from 'react';

import RadioInput, { RadioInputProps } from 'components/inputs/basic/RadioInput/RadioInput';

export interface RadioInputOption {
  label: string | React.ReactNode;
  value: string;
}

export interface RadioInputsProps {
  /*******************************************************************************
   * This component will create a <RadioInput/> for every element in the `options` list.
   ******************************************************************************/
  options: RadioInputOption[];
  // Value is used to set the `checked` property of every <RadioInput/>
  value: string;

  /*******************************************************************************
   * The following props are common to every <RadioInput/> created from `options`.
   ******************************************************************************/
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  // Pass these props to every <RadioInput/> created from the `options` list.
  sharedRadioInputProps?: Partial<RadioInputProps>;

  /*******************************************************************************
   * It assumed you will implement containerClass or containerStyle to control
   * the layout and spacing of the <RadioInput/>s.
   * See the RadioInput.stories.tsx for examples.
   ******************************************************************************/
  containerClass?: string;
  containerStyle?: CSSProperties;
  disabled?: boolean;
}

export const RadioInputs = ({
  name,
  value,
  options,
  onChange,
  containerClass,
  containerStyle,
  sharedRadioInputProps,
  disabled,
}: RadioInputsProps) => (
  <div className={containerClass} style={containerStyle}>
    {options.map((o) => (
      <RadioInput
        key={o.value}
        name={name}
        value={o.value}
        onChange={onChange}
        checked={o.value === value}
        label={o.label}
        disabled={disabled}
        {...sharedRadioInputProps}
      />
    ))}
  </div>
);

export default RadioInputs;
