/*
 * Component that renders a Connector for viewing and editing.
 */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { useParams } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';

import { usePollConnector } from '../ConnectorUtils';

import ShowHasConnectorController from './ShowHasConnectorController';

import 'components/query/Query.css';

interface MatchParams {
  id: string;
  tab: string;
}

interface ShowConnectorProps extends RouteComponentProps<MatchParams> {
  setCurrentPathObject: React.Dispatch<React.SetStateAction<any>>;
}

export default function ShowConnector(props: ShowConnectorProps) {
  const { setCurrentPathObject } = props;
  const [pollingEnabled, setPollingEnabled] = useState(true);
  const { id, tab } = useParams();
  const connectorId = id as string; // we'll always access from urls like http://localhost:3000/connectors/467f2420-e9fc-4ac5-a811-05e7999f29f7

  /*****************************************************************************
   * Fetch the Connector from the API
   ****************************************************************************/
  const {
    isLoading,
    connector,
    error: loadingError,
    resetPolling,
  } = usePollConnector(connectorId, undefined, pollingEnabled);

  useTitle(connector?.schema ?? 'Connector');

  // Pass the current table to AuthHeader
  useEffect(() => {
    setCurrentPathObject(connector);
  }, [setCurrentPathObject, connector]);

  /*****************************************************************************
   * Render connector === null contengencies
   ****************************************************************************/
  if (isLoading) {
    return <CenteredSpinner containerMinHeight="60vh" />;
  }

  if (loadingError) {
    // The whole page is an error message if we failed an initial API load.
    return (
      <Alert className="m-4" variant="error">
        {loadingError}
      </Alert>
    );
  }

  if (connector === null) {
    return <NoObjectsAlert className="m-4" heading="Unknown Connector ID." />;
  }

  /*****************************************************************************
   * The connector is defined.
   ****************************************************************************/
  return (
    <ShowHasConnectorController
      connector={connector}
      pathTab={tab}
      setPollingEnabled={setPollingEnabled}
      resetPolling={resetPolling}
    />
  );
}
