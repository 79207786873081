export default function MeritBadges() {
  // The sizes of the icons are hand tuned to look nice because the images have different shapes and padding.
  return (
    <div className="flex justify-between">
      <img
        src="/images/badges/g2_2024_best_support.png"
        alt="g2 best support badge"
        className="w-[50px]"
      />
      <img
        src="/images/badges/g2_2024_best_usability.png"
        alt="g2 best usability badge"
        className="w-[50px]"
      />
      <img
        src="/images/badges/g2_2024_most_recommend.png"
        alt="g2 most likely to recommend badge"
        className="w-[50px]"
      />
      <div className="py-[5px]">
        <img src="/images/badges/gdpr_white.svg" alt="gdpr compliant badge" className="h-[55px]" />
      </div>
      <div className="py-[5px]">
        <img src="/images/badges/soc2.png" alt="soc2 compliant badge" className="w-[55px] h-[55px]" />
      </div>
    </div>
  );
}
