const Steps = (props: { containerClass?: string; children: React.ReactNode }) => {
  let { containerClass, children } = props;
  containerClass = containerClass || 'bg-pri-gray-50 p-4 rounded';
  return (
    <div className={containerClass}>
      <div className="text-xl font-medium">Integration Instructions</div>
      <ol className="ml-4 list-decimal">{children}</ol>
    </div>
  );
};

export default Steps;
