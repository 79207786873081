import React, { useState } from 'react';

import ReactDiffViewer from 'react-diff-viewer-continued';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import sql from 'react-syntax-highlighter/dist/esm/languages/hljs/sql';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { TransformVersion } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import PageButton from 'components/inputs/basic/Button/PageButton';
import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import UserHover from 'components/overlay/UserHover/UserHover';
import HeaderLabel from 'components/widgets/HeaderLabel/HeaderLabel';
import { formatDate, parseIso } from 'utils/dateTime';

SyntaxHighlighter.registerLanguage('sql', sql);

interface VersionDiffModalProps {
  transformName: string;
  versionHistory: TransformVersion[];
  handleCloseDiffModal: () => void;
  initialDiffVersion: number;
}

const highlightSyntax = (str: string) => {
  if (!str) {
    return <></>;
  } else {
    return (
      <SyntaxHighlighter
        language="sql"
        style={tomorrow}
        customStyle={{ background: 'inherit', padding: '0' }}
        wrapLongLines={true}
      >
        {str}
      </SyntaxHighlighter>
    );
  }
};

export default function VersionDiffModal(props: VersionDiffModalProps) {
  const { transformName, versionHistory, handleCloseDiffModal, initialDiffVersion } = props;
  const [splitView, setSplitView] = useState(false);
  const [beforeVersion, setBeforeVersion] = useState<number>(initialDiffVersion - 1);
  const [afterVersion, setAfterVersion] = useState<number>(initialDiffVersion);

  const beforeVersionObj = versionHistory?.find((version) => version.version_number === beforeVersion);
  const afterVersionObj = versionHistory?.find((version) => version.version_number === afterVersion);
  const beforeSql = beforeVersionObj?.sql || '';
  const afterSql = afterVersionObj?.sql || '';
  const afterVersionDescription = afterVersionObj?.description || '';

  const versionNumberList = versionHistory.map((version) => version.version_number);

  const handleToggleSplitView = () => {
    analytics.track(splitView ? 'VersionDiffViewer SetUnifiedView' : 'VersionDiffViewer SetSplitView');
    setSplitView(!splitView);
  };

  const handleBeforeChangeVersion = (newVal: ListboxValue) => {
    setBeforeVersion(Number(newVal));
    analytics.track('VersionDiffViewer ChangeBeforeVersion');
  };

  const handleAfterChangeVersion = (newVal: ListboxValue) => {
    setAfterVersion(Number(newVal));
    analytics.track('VersionDiffViewer ChangeAfterVersion');
  };

  const handleNextButton = () => {
    setBeforeVersion(afterVersion);
    setAfterVersion(afterVersion + 1);
    analytics.track('VersionDiffViewer ClickNextButton');
  };

  const handlePreviousButton = () => {
    setBeforeVersion(afterVersion - 2);
    setAfterVersion(afterVersion - 1);
    analytics.track('VersionDiffViewer ClickPreviousButton');
  };

  const beforeVersionSelectorFormGroup = (
    <VersionSelect
      value={beforeVersion}
      versionHistory={versionHistory}
      onChange={handleBeforeChangeVersion}
    />
  );

  const afterVersionSelectorFormGroup = (
    <VersionSelect
      value={afterVersion}
      versionHistory={versionHistory}
      onChange={handleAfterChangeVersion}
    />
  );

  const beforeVersionSelect = splitView ? (
    <div className="flex items-center bg-primary-gray-25">{beforeVersionSelectorFormGroup}</div>
  ) : (
    <div className="flex items-center bg-primary-gray-25">
      {beforeVersionSelectorFormGroup}
      <div className="w-[1px] h-[68px] bg-pri-gray-200 mx-4" />
      {afterVersionSelectorFormGroup}
    </div>
  );

  const afterVersionSelect = <div className="flex items-center">{afterVersionSelectorFormGroup}</div>;

  const modalHeader = (
    <div className="f-row-y-center">
      <HeaderLabel label="VERSION DIFF" />
      <h1 className="text-xl font-medium ml-2">{transformName}</h1>
    </div>
  );

  return (
    <Modal header={modalHeader} onClose={handleCloseDiffModal} fullscreen={true} cancelButton={true}>
      <div className="">
        <div className="f-row-y-center justify-end px-4 py-2">
          <Button
            variant="lightDullAction"
            size="small"
            onClick={handleToggleSplitView}
            style={{ float: 'right', minWidth: '118px' }}
          >
            {splitView ? 'Unified View' : 'Split View'}
          </Button>
          <div className="w-[1px] h-[28px] bg-pri-gray-200 ml-4" />
          <PageButton
            direction="prev"
            hasPage={afterVersion > 1}
            onClick={handlePreviousButton}
            className="ml-4"
          />
          <PageButton
            direction="next"
            hasPage={afterVersion < Math.max(...versionNumberList)}
            onClick={handleNextButton}
            className="ml-2"
          />
        </div>
        <div className="px-4 py-2 flex">
          <div className="font-bold">Description</div>
          <div className="ml-2">
            {afterVersionDescription ? (
              afterVersionDescription
            ) : (
              <span className="text-pri-gray-400">None</span>
            )}
          </div>
        </div>
        <div className="px-4 pb-4">
          <ReactDiffViewer
            rightTitle={afterVersionSelect}
            leftTitle={beforeVersionSelect}
            oldValue={beforeSql}
            newValue={afterSql}
            splitView={splitView}
            renderContent={highlightSyntax}
            styles={{
              titleBlock: {
                borderLeft: 'none !important',
                borderBottom: 'none',
                padding: '8px',
              },
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

interface VersionSelectProps {
  value: number;
  versionHistory: TransformVersion[];
  onChange: (newVal: ListboxValue) => void;
}

const VersionSelect = (props: VersionSelectProps) => {
  const { value, versionHistory, onChange } = props;
  const currentVersion = versionHistory.find((v) => v.version_number === value);
  const labelClass = 'min-w-[58px] font-medium font-[Roboto] mr-2';
  return (
    <div className="f-col">
      <div className="flex items-center">
        <div className={labelClass}>Version</div>
        <Listbox
          value={value}
          onChange={onChange}
          variant="gray"
          size="medium"
          options={versionHistory.map((version) => {
            return {
              value: version.version_number,
              label: `${version.version_number} (${formatDate(parseIso(version?.created_at || ''))})`,
            };
          })}
        />
      </div>
      <div className="flex items-center py-2">
        <div className={labelClass}>Author</div>
        <span className="font-[Roboto] text-pri-gray-700">
          {currentVersion && <UserHover userProfile={currentVersion.created_by} />}
        </span>
      </div>
    </div>
  );
};
