/*
Hook to power search of multi-input search tables.
When the time comes, this hook should be generalized to filter any list view.
Right now we are deliberately keeping things simple and only worrying about 
Data Alerts.

This hook's state could theoretically be in some Redux like data store.
I don't want to create another Redux Reducer at this moment, and I
don't have time to research a Redux alternative, so using useState().
*/
import { useMemo, useRef, useState, RefObject } from 'react';

import { useHistory, useLocation } from 'react-router';

import deepEqual from 'fast-deep-equal';
import queryString from 'query-string';

import { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import { DataAlertWithTable } from 'hooks/useDataAlerts';
import { getLastAlertActionAndEnableResultsButton } from 'pages/tables/ShowTable/DataAlertTab/shared/DataAlertHelper';
import { useSearchFocus } from 'utils/React';

type StatusFilterValue = 'all' | 'succeeded' | 'failed' | 'never_ran';

interface FilterProps {
  unfilteredDataAlerts: DataAlertWithTable[];
  tableFilter: string;
  alertFilter: string;
  sqlFilter: string;
  statusFilter: string;
  onlyEnabledFilter: boolean;
}
export interface FilterDataAlerts {
  tableFilterRef: RefObject<HTMLInputElement>;
  alertFilterRef: RefObject<HTMLInputElement>;
  sqlFilterRef: RefObject<HTMLInputElement>;
  statusFilterRef: RefObject<HTMLInputElement>;
  tableFilter: string;
  alertFilter: string;
  sqlFilter: string;
  statusFilter: string;
  onlyEnabledFilter: boolean;
  onTableFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAlertFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSqlFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStatusFilterChange: (status: ListboxValue) => void;
  onOnlyEnabledFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filteredDataAlerts: DataAlertWithTable[];
  onFilterBlur: () => void;
}

export default function useFilterDataAlerts(unfilteredDataAlerts: DataAlertWithTable[]) {
  const [tableFilterRef] = useSearchFocus();
  const location = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(location.search);

  const alertFilterRef = useRef<HTMLInputElement>(null);
  const sqlFilterRef = useRef<HTMLInputElement>(null);
  const statusFilterRef = useRef<HTMLInputElement>(null);
  const [tableFilter, setTableFilter] = useState((searchParams.table as string) ?? '');
  const [alertFilter, setAlertFilter] = useState((searchParams.alert as string) ?? '');
  const [sqlFilter, setSqlFilter] = useState((searchParams.sql as string) ?? '');
  const [statusFilter, setStatusFilter] = useState<StatusFilterValue>(
    (searchParams.status as StatusFilterValue) ?? 'all',
  );
  const [onlyEnabledFilter, setOnlyEnabledFilter] = useState(
    searchParams.enabled === 'false' ? false : true,
  );
  const lastFilteredDataAlerts = useRef<DataAlertWithTable[]>([]);

  // Update the filtered list every time unfilteredDataAlerts or one of the search criteria is updated.
  const filteredDataAlerts = useMemo(() => {
    const newFilteredDataAlerts = filterAlerts({
      unfilteredDataAlerts,
      tableFilter,
      alertFilter,
      sqlFilter,
      statusFilter,
      onlyEnabledFilter,
    });
    // Do not return a new array object unless the list's contents change.
    // A new array object with identical contents will trigger an unnecessary rerender.
    if (deepEqual(newFilteredDataAlerts, lastFilteredDataAlerts.current)) {
      return lastFilteredDataAlerts.current;
    } else {
      lastFilteredDataAlerts.current = newFilteredDataAlerts;
      return newFilteredDataAlerts;
    }
  }, [unfilteredDataAlerts, tableFilter, alertFilter, sqlFilter, statusFilter, onlyEnabledFilter]);

  const onFilterBlur = () => {
    // TODO: Log search inputs in Segment once all inputs are blurred
  };

  /**
   * Update query string in URL using `history.replace()`
   * @param paramName Name of query string parameter to update
   * @param newFilter New value for filter
   * @param defaultValue Default value for filter. Used to determine when to remove query string
   * parameter from URL.
   *
   * TODO(homepage-v1): This util the same as in `ListConnectorFilters.tsx` and behaves similarly
   * to the one in `useSetSearchURL.ts`. Refactor to avoid duplicate.
   */
  const updateQueryString = (paramName: string, newFilter: string, defaultValue: string = '') => {
    const searchParams = queryString.parse(location.search);
    const filteredSearchParams = Object.entries(searchParams).reduce((acc, [key]) => {
      if (key !== paramName) {
        return { ...acc, [key]: searchParams[key] };
      }
      return acc;
    }, {});

    const newSearchParams = {
      ...filteredSearchParams,
      // Only add the param if it is not the default value (e.g. empty string)
      ...(newFilter === defaultValue ? {} : { [paramName]: newFilter }),
    };
    const newSearch = queryString.stringify(newSearchParams);
    history.replace({ search: newSearch });
  };

  const onTableFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newFilter = event.target.value.toLowerCase();
    setTableFilter(newFilter);
    updateQueryString('table', newFilter);
  };

  const onAlertFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newFilter = event.target.value.toLowerCase();
    setAlertFilter(newFilter);
    updateQueryString('alert', newFilter);
  };

  const onSqlFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newFilter = event.target.value.toLowerCase();
    setSqlFilter(newFilter);
    updateQueryString('sql', newFilter);
  };

  const onStatusFilterChange = (status: ListboxValue): void => {
    const newFilter = typeof status === 'string' ? (status as StatusFilterValue) : 'all';
    setStatusFilter(newFilter);
    updateQueryString('status', newFilter, 'all');
  };

  const onOnlyEnabledFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newFilter = event.target.checked;
    setOnlyEnabledFilter(newFilter);
    updateQueryString('onlyEnabled', newFilter.toString(), 'true');
  };

  return {
    tableFilterRef,
    alertFilterRef,
    sqlFilterRef,
    statusFilterRef,
    tableFilter,
    alertFilter,
    sqlFilter,
    statusFilter,
    onlyEnabledFilter,
    filteredDataAlerts,
    onTableFilterChange,
    onAlertFilterChange,
    onSqlFilterChange,
    onStatusFilterChange,
    onOnlyEnabledFilterChange,
    onFilterBlur,
  };
}

const filterAlerts = (props: FilterProps) => {
  const { unfilteredDataAlerts, tableFilter, alertFilter, sqlFilter, statusFilter, onlyEnabledFilter } =
    props;

  const filteredAlerts = unfilteredDataAlerts.filter((a) => {
    const tableFilterMatch = a.table.full_name.toLowerCase().includes(tableFilter);
    const alertFilterMatch = a.name.toLowerCase().includes(alertFilter);
    const sqlFilterMatch = a.sql.toLowerCase().includes(sqlFilter);

    const alertStatus = getLastAlertActionAndEnableResultsButton(a, a.table)[2];
    const statusFilterMatch =
      statusFilter === 'all' ||
      (statusFilter === 'succeeded' && alertStatus === 'success') ||
      (statusFilter === 'failed' && alertStatus === 'error') ||
      (statusFilter === 'never_ran' && alertStatus === 'NA');

    const onlyEnabledFilterMatch = !onlyEnabledFilter || a.enabled;

    return (
      tableFilterMatch &&
      alertFilterMatch &&
      sqlFilterMatch &&
      statusFilterMatch &&
      onlyEnabledFilterMatch
    );
  });

  return filteredAlerts;
};
