export const schemaChangeHandlingMap = {
  ALLOW_ALL: 'Sync new tables and columns by default',
  ALLOW_COLUMNS: 'Sync new columns in existing tables only by default',
  BLOCK_ALL: "Don't sync new tables or columns by default",
};
export type SchemaChangeHandling = keyof typeof schemaChangeHandlingMap;

export interface SchemaChangePolicyState {
  onChangePolicy: (event: React.ChangeEvent<HTMLInputElement>) => void;
  unsavedPolicy: SchemaChangeHandling;
}

export default function useSchemaChangePolicyState(props: {
  unsavedPolicy: SchemaChangeHandling | null;
  setUnsavedPolicy: (newValue: SchemaChangeHandling | null) => void;
}): SchemaChangePolicyState | null {
  const { unsavedPolicy, setUnsavedPolicy } = props;

  if (unsavedPolicy === null) {
    return null;
  }

  const onChangePolicy = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changePolicy = event.target.value as SchemaChangeHandling;
    setUnsavedPolicy(changePolicy);
    analytics.track('ShowConnectorManageTablesTab SetChangePolicy', { changePolicy });
  };

  return {
    onChangePolicy,
    unsavedPolicy,
  };
}
