import API from 'api/API';

export interface SnowflakeDatabase {
  name: string;
  snowflake_account: string;
}

export interface SnowflakeAccount {
  organization_name?: string;
  name: string;
  locator: string;
  identifier: string;
  provider?: string;
  region?: string;
  id?: string;
  deleted?: boolean;
  databases: SnowflakeDatabase[];
}

export async function fetchAllDatabases(api: API): Promise<SnowflakeDatabase[]> {
  const response = await api.get('/api/snowflake_databases');
  return response.data;
}

export async function fetchMozartAccounts(): Promise<SnowflakeAccount[]> {
  const api = new API();
  const accountsResponse = await api.get('/api/snowflake_accounts');
  const accounts = accountsResponse.data.map((account: any) => ({
    organization_name: account.organization?.name,
    name: account.name,
    locator: account.base_account, // Ideally we would rename the django colname to locator.
    identifier: account.identifier,
    region: account.region,
    provider: 'Snowflake',
    id: account.id,
    deleted: account.deleted,
    databases: [],
    isFromMozart: true,
  }));

  const allDatabases = await fetchAllDatabases(api);

  for (const account of accounts) {
    account.databases = allDatabases.filter((db) => db.snowflake_account === account.id);
  }

  return accounts;
}
