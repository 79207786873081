import cn from 'classnames';

import HeaderLabel from 'components/widgets/HeaderLabel/HeaderLabel';
import AgoHover from 'components/widgets/time/AgoHover/AgoHover';
import uph from 'model_helpers/userProfileHelper';
import { parseIso } from 'utils/dateTime';

import type { PagedLocation, UserAction, UserActionObjectType } from '../../usePagedUserActions';

import UserActionSentence from '../UserActionSentence/UserActionSentence';

// TODO: Extract this pattern into a component in another PR and replace
// components/tables/LineTable & components/tables/LineTable with one component
// that sets the class in the root <table> element and then renders children.
export type TableVariant = 'line' | 'blueGray';
interface UserActionsTableProps {
  userActions: UserAction[];
  variant: TableVariant;
  className?: string;
  hideLabels?: boolean;
  eventLocation: PagedLocation;
}

const UserActionsTable = ({
  userActions,
  variant,
  className,
  hideLabels,
  eventLocation,
}: UserActionsTableProps) => (
  <table
    className={cn(
      { lineTable: variant === 'line', blueGrayHeaderTable: variant === 'blueGray' },
      className,
    )}
  >
    <thead>
      <tr>
        <th>Action</th>
        {!hideLabels && <th></th>}
        <th className="w-[20%]">User</th>
        <th className="w-[15%]">Ago</th>
      </tr>
    </thead>

    <tbody>
      {userActions.map((userAction: UserAction) => (
        <tr key={userAction.id}>
          {!hideLabels && (
            <td className="align-top">
              <HeaderLabel className="uppercase font-bold" label={pickLabel(userAction.object_type)} />{' '}
            </td>
          )}
          <td className="text-black" style={{ wordBreak: 'break-word' }}>
            <span className="pt-1 inline-block">
              <UserActionSentence userAction={userAction} eventLocation={eventLocation} />
            </span>
          </td>

          <td>{uph.displayName(userAction.done_by)}</td>
          <td>
            <AgoHover date={parseIso(userAction.created_at)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default UserActionsTable;

export const pickLabel = (objectType: UserActionObjectType) => {
  if (objectType === 'table_test') {
    return 'Alert';
  }
  if (objectType === 'dbt_run_configuration') {
    return 'DBT Job';
  }
  if (objectType === 'snowflake_user') {
    return 'Snowflake User';
  }
  return objectType;
};
