import { memo } from 'react';

import { AIAssistantChatMessage } from '../useAIAssistant';
import { EditorRunProps } from '../useSqlEditor';

import AIAssistantRow from './AIAssistantRow';
import AssistantAdviceRow from './AssistantAdviceRow';

interface AIChatHistoryProps {
  messages: AIAssistantChatMessage[];
  actuallyShowChatAdvice: boolean;
  setSqlAndRun: (sql: string) => Promise<EditorRunProps>;
}

const AIChatHistory = (props: AIChatHistoryProps) => {
  const { messages, actuallyShowChatAdvice, setSqlAndRun } = props;

  return (
    <div>
      {actuallyShowChatAdvice && <AssistantAdviceRow />}
      {messages.map((m, i) => (
        <AIAssistantRow key={i} message={m} setSqlAndRun={setSqlAndRun} />
      ))}
    </div>
  );
};

export default memo(AIChatHistory);
