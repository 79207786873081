import React, { useEffect, useState } from 'react';

import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import { formatDate, agoDate, parseIso } from 'utils/dateTime';

export interface AgoHoverProps {
  date: string | Date | null;
  interval?: number; // miliseconds
  disableInterval?: boolean; // Lots of intervals are slow. Disable on long lists.
  className?: string;
}

export default function AgoHover(props: AgoHoverProps) {
  const { date, interval, disableInterval, className } = props;
  const dateObj = typeof date === 'string' ? parseIso(date) : date;
  const ago = useAgo(dateObj, interval, disableInterval);

  if (!dateObj) {
    return null;
  }

  const formated = formatDate(dateObj);

  return (
    <TooltipTrigger tip={formated}>
      <span data-iso-time={dateObj.toISOString()} data-human-time={formated} className={className}>
        {ago}
      </span>
    </TooltipTrigger>
  );
}

const ONCE_A_MINUTE = 1000 * 60;
export function useAgo(date: Date | null, interval: number = ONCE_A_MINUTE, disableInterval?: boolean) {
  const [ago, setAgo] = useState(agoDate(date));

  useEffect(() => {
    // Immediately set ago
    setAgo(agoDate(date));

    // Set an interval to update ago
    if (!disableInterval) {
      const timer = setInterval(() => {
        setAgo(agoDate(date));
      }, interval);
      return () => {
        clearInterval(timer);
      };
    }
  }, [date, disableInterval, interval]);

  return ago;
}
