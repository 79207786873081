import cn from 'classnames';

import { TabVariant } from './TabRow';

interface VariantClasses {
  allTabsClasses: string;
  selectedTabClasses: string;
  unselectedTabClasses: string;
}

const VARIANT_CLASSES: { [key: string]: VariantClasses } = {
  default: {
    allTabsClasses:
      'h-9 p-4 f-center rounded rounded-b-none border-[pri-gray-200] whitespace-nowrap outline-0',
    selectedTabClasses: 'border border-b-0 text-purple font-medium',
    unselectedTabClasses: 'border-b text-pri-gray-500',
  },
  lightDull: {
    allTabsClasses: 'h-[26px] px-2 py-1 f-center text-sm font-bold whitespace-nowrap outline-0',
    selectedTabClasses: 'bg-white text-sec-blue-gray-500',
    unselectedTabClasses: 'bg-pri-gray-50 text-pri-gray-500',
  },
  bgGray: {
    allTabsClasses: 'h-[34px] px-2 py-1 f-center text-sm font-medium whitespace-nowrap outline-0',
    selectedTabClasses: 'border-b-2 border-sec-blue-gray-500 bg-pri-gray-100 text-sec-blue-gray-500',
    unselectedTabClasses: 'border-b-2 border-pri-gray-100 bg-pri-gray-100 text-pri-gray-400',
  },
};

export interface AbstractTabProps {
  label: string;
  selected: boolean;
  onClick: () => void;
}

export interface TabProps extends AbstractTabProps {
  variant: TabVariant;
}

const Tab = (props: TabProps) => {
  const { label, selected, variant, onClick } = props;
  const variantClasses = VARIANT_CLASSES[variant];
  const classNames = cn(variantClasses.allTabsClasses, {
    [variantClasses.selectedTabClasses]: selected,
    [variantClasses.unselectedTabClasses]: !selected,
  });
  return (
    <button className={classNames} onClick={onClick}>
      {label}
    </button>
  );
};

export default Tab;
