import React, { useState } from 'react';

import IconButton from 'components/inputs/basic/Button/IconButton';
import LinkButton from 'components/inputs/basic/Button/LinkButton';
import LinkIconButton from 'components/inputs/basic/Button/LinkIconButton';
import TextInput from 'components/inputs/basic/TextInput/TextInput';
import { useUserProfile } from 'context/AuthContext';
import useTrackFilter from 'hooks/useTrackFilter';
import { useSearchFocus } from 'utils/React';

import AdvancedSearch from './AdvancedSearch/AdvancedSearch';

import s from './SearchBar.module.css';

interface SearchBarProps {
  filter: string;
  hideEmptyTables: boolean;
  hideViews: boolean;
  onFilterChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onToggleHideEmptyTables(): void;
  onToggleHideViews(): void;
}

export default function SearchBar(props: SearchBarProps) {
  const {
    filter,
    hideEmptyTables,
    hideViews,
    onFilterChange,
    onToggleHideEmptyTables,
    onToggleHideViews,
  } = props;
  const reportOnBlur = useTrackFilter('Warehouse', filter);
  const [filterRef] = useSearchFocus();
  const [showAdvanced, setShowAdvanced] = useState(false);
  const {
    userProfile: { company_role },
  } = useUserProfile();

  const handleToggleShowAdvanced = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const show = !showAdvanced;
    setShowAdvanced(show);
    analytics.track(show ? 'Warehouse ShowAdvancedSearch' : 'Warehouse HideAdvancedSearch');
  };

  return (
    <div className="p-4 bg-sec-blue-gray-50">
      <div className="f-between">
        <div className="f-row-y-center" style={{ flex: '1 fit-content' }}>
          <TextInput
            ref={filterRef}
            placeholder="Search"
            name="search"
            value={filter}
            onChange={onFilterChange}
            onBlur={reportOnBlur}
            className={s.filterInput}
            maxLength={200}
            autoFocus
          />

          <IconButton
            onClick={handleToggleShowAdvanced}
            icon={showAdvanced ? 'ChevronDoubleUp' : 'ChevronDoubleDown'}
            text={showAdvanced ? 'Hide Advanced' : 'Show Advanced'}
            variant="lightTransparent"
            size="small"
            className="ml-[6px]"
          />

          <LinkIconButton
            to="/tags"
            data-track="Warehouse LinkToManageTags"
            icon="Tag"
            text={company_role === 'viewer' ? 'View Tags' : 'Manage Tags'}
            variant="lightTransparent"
            size="small"
          />
        </div>
        {company_role !== 'viewer' && (
          <LinkButton
            variant="lightAction"
            className="ml-4"
            to="/transforms/add"
            data-track="Warehouse CreateTransform"
          >
            Create Transform
          </LinkButton>
        )}
      </div>

      {
        <AdvancedSearch
          show={showAdvanced}
          hideEmptyTables={hideEmptyTables}
          hideViews={hideViews}
          onChangeHideEmptyTables={onToggleHideEmptyTables}
          onChangeHideViews={onToggleHideViews}
        />
      }
    </div>
  );
}
