/*
Controls which schemas are shown and expanded in the Warehouse
based on filter status and Expand All button.

COMMENTARY:
The APIs returning at different times causes this reducer to do redundant work
every time a new API comes back as well as causing extra React rerenders.
There are several functions in this file that recompute the entire table/schema 
state on all API prop updates. This is somewhat wasteful but results in
fewer bugs and simpler code. Revisit this decision if perf is a problem.
It seems pretty fast as of 02/10/2021.
*/
import { Dictionary, isEqual, keyBy } from 'lodash';

import { AggTable, FavoritesByTableID } from 'api/APITypes';
import { SearchColumnsByTableID } from 'api/searchColumnAPI';
import { DisabledTablesByTableID } from 'components/query/TableExplorer/TableNameList';
import { pickFilterFunc, KeywordLists } from 'utils/TableFilter';

import * as virtualSchema from './virtualSchema';

export * from './virtualSchema';

const {
  MOZART_ALL,
  MOZART_RECENT,
  MOZART_FAVORITES,
  MOZART_TRANSFORMS,
  MOZART_SNAPSHOTS,
  UNMANAGED_SCHEMA,
  virtualSchemaKey,
  virtualSchemaKeyParts,
} = virtualSchema;

export interface DatabaseSearchState {
  // The API returns up to 20 recents.
  // Prune recent list to not more than this number.
  maxRecents: number;
  // Add All schema
  addAllSchema: boolean;
  // Add Recent, and Favorite schemas
  addCuratedSchemas: boolean;
  // Are we waiting for API data to return before rendering?
  loaded: boolean;
  hasEmptyTables: boolean;
  hasViews: boolean;
  // The filter input's value
  filter: string;
  // A secret filter developers can add to views
  hiddenFilter: string | undefined;
  // Which schemas should the search include. This is an OR relationship.
  virtualSchemaKeysToSearch: string[];
  // Filter matches the UI might want to highlight
  filterIncludes: KeywordLists;
  // Should we auto-expand schemas if there is no search filter?
  expandSchemaWithoutFilter: boolean;
  // Many tables synced by connectors have zero rows. Hide them by default.
  // This will not hide empty transforms.
  // This will not hide views since their row_count is unknown.
  hideEmptyTables: boolean;
  // Hide database views(excluding 'information_schema').
  hideViews: boolean;
  // The value of the "Expand/Collapse All" link.
  allSchemasExpanded: boolean;
  // User has set, filter or allSchemasExpanded
  userHasEditedFilterInAnyWay: boolean;
  // Schema expand state and tables listed in schema state are separate variables
  // because they can be changed independently.
  // This stores which schemas are expanded.
  schemasExpandedMap: SchemasExpandedMap;
  tagsExpandedMap: SchemasExpandedMap;
  filteredTables: AggTable[];
  // Keys are schemas that still have tables after the filter operation.
  // Values are an array of tables in the schema that match the filter criteria.
  filteredSchemasMap: FilteredSchemaMap;
  filteredTagsMap: FilteredSchemaMap;
  filteredTablesCount: number;
  filteredTablesInTagsCount: number;
  isFiltering: boolean; // Updated when `filteredSchemasMap` is updated, not when `filter` is updated.
  unfilteredSchemasMap: FilteredSchemaMap;
  unfilteredTagsMap: FilteredSchemaMap;
  unfilteredTablesCount: number;
  unfilteredTablesInTagsCount: number;
  unfilteredRecentsByTableID: Dictionary<AggTable>;
  // Used to by children to inject behaviour into reducer
  getUnfilteredTables(prevState: DatabaseSearchState): AggTable[];
  /*****************************************************************************
  Everything below this line is inputs the reducer needs a reference to,
  but is not something the reducer intends to return to its consumer.
  *****************************************************************************/
  // In this file "unfiltered" in generally means all data returned by the API
  // before the UI has had a chance to filter out any data.
  // All tables returned by the API(transforms, snapshots, and unmanaged),
  // before the UI has filtered any tables.
  unfilteredTables: AggTable[];
  unfilteredRecentTables: AggTable[];
  unfilteredFavoriteTables: AggTable[];
  searchColumnsByTableID: SearchColumnsByTableID;
  favoritesByTableID: FavoritesByTableID;
  disabledTablesByID: DisabledTablesByTableID;
}

export interface SchemasExpandedMap {
  [key: string]: boolean; // key = schemaKey or tag, value = isExpanded
}

// Maps of tables to render after applying the search filter
export interface FilteredSchemaMap {
  // key = schemaKey or tag, value = List of tables in that schema that match filter
  [key: string]: AggTable[];
}

export const allTablesVirtualSchemaKeys = [virtualSchemaKey(MOZART_ALL, MOZART_ALL)];

export const initialState: DatabaseSearchState = {
  maxRecents: 20,
  addAllSchema: false,
  addCuratedSchemas: false,
  loaded: false,
  hasEmptyTables: false,
  hasViews: false,
  filter: '',
  hiddenFilter: undefined,
  virtualSchemaKeysToSearch: allTablesVirtualSchemaKeys,
  filterIncludes: {},
  expandSchemaWithoutFilter: false,
  hideEmptyTables: true,
  hideViews: false,
  allSchemasExpanded: false,
  userHasEditedFilterInAnyWay: false,
  schemasExpandedMap: {},
  tagsExpandedMap: {},
  filteredTables: [],
  filteredSchemasMap: {},
  filteredTagsMap: {},
  filteredTablesCount: 0,
  filteredTablesInTagsCount: 0,
  isFiltering: false,
  unfilteredSchemasMap: {},
  unfilteredTagsMap: {},
  unfilteredTablesCount: 0,
  unfilteredTablesInTagsCount: 0,
  unfilteredRecentsByTableID: {},
  getUnfilteredTables: (prevState: DatabaseSearchState) => prevState.unfilteredTables,
  unfilteredTables: [],
  unfilteredRecentTables: [],
  unfilteredFavoriteTables: [],
  searchColumnsByTableID: {},
  favoritesByTableID: {},
  disabledTablesByID: {},
};

export type ReducerAction =
  | 'SET_FILTER_AND_UPDATE'
  | 'SET_HIDDEN_FILTER_AND_UPDATE'
  | 'SET_FILTER_ONLY'
  | 'SET_VIRTUAL_SCHEMA_KEYS_TO_SEARCH'
  | 'UPDATE_FILTERED_TABLES'
  | 'SET_HIDE_EMPTY_TABLES'
  | 'SET_HIDE_VIEWS'
  | 'SET_TABLE_LISTS'
  | 'SET_SEARCH_COLUMNS_BY_TABLE_ID'
  | 'SET_FAVORITES_BY_TABLE_ID'
  | 'SET_ALL_SCHEMAS_EXPANDED'
  | 'SET_SINGLE_SCHEMA_EXPANDED'
  | 'SET_SINGLE_TAG_EXPANDED';

export interface TableLists {
  allTables: AggTable[];
  recentTables: AggTable[];
  favoriteTables: AggTable[];
  disabledTables: AggTable[];
}

export interface Action {
  type: ReducerAction;
  filter?: string;
  hiddenFilter?: string;
  virtualSchemaKeysToSearch?: string[];
  hideEmptyTables?: boolean;
  hideViews?: boolean;
  tableLists?: TableLists;
  searchColumnsByTableID?: SearchColumnsByTableID;
  favoritesByTableID?: FavoritesByTableID;
  schemaKey?: string;
  expanded?: boolean;
  tag?: string;
}

export function reducer(prevState: DatabaseSearchState, action: Action) {
  switch (action.type) {
    case 'SET_FILTER_AND_UPDATE':
      setFilterAndUpdate(prevState, action.filter as string);
      break;
    case 'SET_HIDDEN_FILTER_AND_UPDATE':
      setHiddenFilterAndUpdate(prevState, action.hiddenFilter as string);
      break;
    case 'SET_FILTER_ONLY':
      setFilterOnly(prevState, action.filter as string);
      break;
    case 'SET_VIRTUAL_SCHEMA_KEYS_TO_SEARCH':
      setSchemaKeysToSearch(prevState, action.virtualSchemaKeysToSearch as string[]);
      break;
    case 'UPDATE_FILTERED_TABLES':
      updateFilteredTables(prevState);
      break;
    case 'SET_HIDE_EMPTY_TABLES':
      setHideEmptyTables(prevState, action.hideEmptyTables as boolean);
      break;
    case 'SET_HIDE_VIEWS':
      setHideViews(prevState, action.hideViews as boolean);
      break;
    case 'SET_TABLE_LISTS':
      setTableLists(prevState, action.tableLists as TableLists);
      break;
    case 'SET_SEARCH_COLUMNS_BY_TABLE_ID':
      setSearchColumnsByTableID(prevState, action.searchColumnsByTableID as SearchColumnsByTableID);
      break;
    case 'SET_FAVORITES_BY_TABLE_ID':
      setFavoritesByTableID(prevState, action.favoritesByTableID as FavoritesByTableID);
      break;
    case 'SET_ALL_SCHEMAS_EXPANDED':
      setAllSchemasExpanded(prevState, action.expanded as boolean);
      break;
    case 'SET_SINGLE_SCHEMA_EXPANDED':
      setSchemaExpanded(prevState, action.schemaKey as string, action.expanded as boolean);
      break;
    case 'SET_SINGLE_TAG_EXPANDED':
      setTagExpanded(prevState, action.tag as string, action.expanded as boolean);
      break;
    default:
      throw new Error();
  }

  // Return new object to force a rerender.
  // Only the changed properties should be new objects.
  // So, memoized calculations should not be recomputed
  // unless their inputs were changed.
  return { ...prevState };
}

export function setTableLists(prevState: DatabaseSearchState, tableLists: TableLists) {
  const { allTables, recentTables, favoriteTables, disabledTables } = tableLists;
  prevState.unfilteredTables = allTables;
  prevState.hasEmptyTables = allTables.some(
    (t) => t.num_rows === 0 && t.type !== 'transform' && t.database_table_type !== 'VIEW',
  );
  prevState.hasViews = allTables.some((t) => t.database_table_type === 'VIEW');

  // The API returns up to 20 recents.
  // Prune the recents count to not more than this number.
  const truncatedRecents = recentTables.slice(0, prevState.maxRecents);
  prevState.unfilteredRecentTables = truncatedRecents;
  prevState.unfilteredRecentsByTableID = keyBy(truncatedRecents, 'id');

  prevState.unfilteredFavoriteTables = favoriteTables;
  prevState.unfilteredSchemasMap = buildSchemaMap(
    truncatedRecents,
    favoriteTables,
    allTables,
    prevState.addAllSchema,
    prevState.addCuratedSchemas,
  );
  const [tagMap, unfilteredTablesInTagsCount] = buildTagMap(allTables, undefined);
  prevState.unfilteredTagsMap = tagMap;
  prevState.unfilteredTablesCount = allTables.length;
  prevState.unfilteredTablesInTagsCount = unfilteredTablesInTagsCount;
  buildSchemasExpandedMap(prevState);
  buildTagsExpandedMap(prevState);
  filterTables(prevState);

  prevState.disabledTablesByID = disabledTables.reduce((acc, table) => {
    acc[table.id] = table;
    return acc;
  }, {} as DisabledTablesByTableID);

  prevState.loaded = true;
}

function setSearchColumnsByTableID(
  prevState: DatabaseSearchState,
  searchColumnsByTableID: SearchColumnsByTableID,
) {
  prevState.searchColumnsByTableID = searchColumnsByTableID;
  filterTables(prevState);
  // Adequate decision, maybe revisit:
  // setSearchColumnsByTableID should not effect loaded.
  // Search results will quietly ignore columns until they are loaded.
}

function setFavoritesByTableID(prevState: DatabaseSearchState, favoritesByTableID: FavoritesByTableID) {
  prevState.favoritesByTableID = favoritesByTableID;
  filterTables(prevState);
  // Adequate decision, maybe revisit:
  // setFavoritesByTableID should not effect loaded.
  // Search results will quietly assume there are no favorites until they are loaded.
}

// 1. When the page first loads all schemas default collapsed.
// 2. After the user has edited any input in the search bar use the search area's values.
function buildSchemasExpandedMap(prevState: DatabaseSearchState) {
  const { allSchemasExpanded, userHasEditedFilterInAnyWay, schemasExpandedMap, unfilteredSchemasMap } =
    prevState;
  const newMap: SchemasExpandedMap = {};

  const defaultOpen = userHasEditedFilterInAnyWay ? allSchemasExpanded : false;

  for (const schemaKey in unfilteredSchemasMap) {
    const oldExpanded = schemasExpandedMap[schemaKey];
    newMap[schemaKey] = oldExpanded !== undefined ? oldExpanded : defaultOpen;
  }
  prevState.schemasExpandedMap = newMap;
}

function buildTagsExpandedMap(prevState: DatabaseSearchState) {
  const { tagsExpandedMap, unfilteredTables } = prevState;
  const newMap: SchemasExpandedMap = {};
  for (const t of unfilteredTables) {
    for (const tag of t.tagObjs) {
      if (newMap[tag.id] === undefined) {
        const oldExpanded = tagsExpandedMap[tag.id];
        newMap[tag.id] = oldExpanded !== undefined ? oldExpanded : false;
      }
    }
  }
  prevState.tagsExpandedMap = newMap;
}

// Build filteredSchemas when API changes state or filter changes
function filterTables(prevState: DatabaseSearchState) {
  const {
    filter,
    hiddenFilter,
    virtualSchemaKeysToSearch,
    hideEmptyTables,
    hideViews,
    unfilteredRecentTables,
    unfilteredRecentsByTableID,
    unfilteredFavoriteTables,
    searchColumnsByTableID,
    favoritesByTableID,
    getUnfilteredTables,
  } = prevState;
  let filterIncludes: KeywordLists = {};
  let filteredTables = getUnfilteredTables(prevState);
  let filteredRecentTables = unfilteredRecentTables;
  let filteredFavoriteTables = unfilteredFavoriteTables;

  if (hideEmptyTables) {
    const hideEmptyTablesFunc = (t: AggTable) =>
      t.num_rows !== 0 || t.type === 'transform' || t.type === 'dbt';
    filteredTables = filteredTables.filter(hideEmptyTablesFunc);
    filteredRecentTables = filteredRecentTables.filter(hideEmptyTablesFunc);
    filteredFavoriteTables = filteredFavoriteTables.filter(hideEmptyTablesFunc);
  }

  if (hideViews) {
    const hideViewsFunc = (t: AggTable) =>
      t.database_table_type !== 'VIEW' || t.schema === 'information_schema';
    filteredTables = filteredTables.filter(hideViewsFunc);
    filteredRecentTables = filteredRecentTables.filter(hideViewsFunc);
    filteredFavoriteTables = filteredFavoriteTables.filter(hideViewsFunc);
  }

  const actualFilter = hiddenFilter ? `${hiddenFilter} ${filter}` : filter;
  if (actualFilter) {
    const [filterFunc, includes] = pickFilterFunc(
      actualFilter,
      searchColumnsByTableID,
      favoritesByTableID,
    );
    filterIncludes = includes;
    filteredTables = filteredTables.filter(filterFunc);
    filteredRecentTables = filteredRecentTables.filter(filterFunc);
    filteredFavoriteTables = filteredFavoriteTables.filter(filterFunc);
  }

  const schemaMap = buildSchemaMap(
    filteredRecentTables,
    filteredFavoriteTables,
    filteredTables,
    prevState.addAllSchema,
    prevState.addCuratedSchemas,
  );

  // In Warehouse V2, we filter the tables an additional time by virtualSchema
  // after the text search is done and the `schemaMap` is built.
  const usesDefaultVirtualSchemas = isEqual(virtualSchemaKeysToSearch, allTablesVirtualSchemaKeys);
  if (!usesDefaultVirtualSchemas) {
    filteredTables = filterByVirtualSchemaKeys(
      filteredTables,
      virtualSchemaKeysToSearch,
      unfilteredRecentsByTableID,
      favoritesByTableID,
    );
    filteredRecentTables = filterByVirtualSchemaKeys(
      filteredRecentTables,
      virtualSchemaKeysToSearch,
      unfilteredRecentsByTableID,
      favoritesByTableID,
    );
    filteredFavoriteTables = filterByVirtualSchemaKeys(
      filteredFavoriteTables,
      virtualSchemaKeysToSearch,
      unfilteredRecentsByTableID,
      favoritesByTableID,
    );
  }

  const [tagMap, filteredTablesInTagsCount] = buildTagMap(filteredTables, filterIncludes.tag);

  prevState.filterIncludes = filterIncludes;
  prevState.filteredTables = filteredTables;
  prevState.filteredSchemasMap = schemaMap;
  prevState.filteredTagsMap = tagMap;
  prevState.filteredTablesCount = filteredTables.length;
  prevState.filteredTablesInTagsCount = filteredTablesInTagsCount;
  prevState.isFiltering = filter !== '';
}

function buildSchemaMap(
  recentTables: AggTable[],
  favoriteTables: AggTable[],
  allTables: AggTable[],
  addAllSchema: boolean,
  addCuratedSchemas: boolean,
) {
  // Schemas render in the order we add them to this object
  const map: FilteredSchemaMap = {};

  if (addAllSchema && allTables.length > 0) {
    map[virtualSchemaKey(MOZART_ALL, MOZART_ALL)] = allTables;
  }

  if (addCuratedSchemas) {
    if (recentTables.length > 0) {
      map[virtualSchemaKey(MOZART_RECENT, MOZART_RECENT)] = recentTables;
    }
    if (favoriteTables.length > 0) {
      map[virtualSchemaKey(MOZART_FAVORITES, MOZART_FAVORITES)] = favoriteTables;
    }
  }

  for (const t of allTables) {
    let schemaMapKey = virtualSchemaKey(UNMANAGED_SCHEMA, t.schema);
    if (t.type === 'transform' || t.type === 'dbt') {
      schemaMapKey = virtualSchemaKey(MOZART_TRANSFORMS, t.schema);
    } else if (t.type === 'snapshot') {
      schemaMapKey = virtualSchemaKey(MOZART_SNAPSHOTS, t.schema);
    }
    if (map[schemaMapKey] === undefined) {
      map[schemaMapKey] = [];
    }
    const tables: AggTable[] = map[schemaMapKey];
    tables.push(t);
  }

  return map;
}

function buildTagMap(allTables: AggTable[], tagSearches: string[] | undefined) {
  // Schemas render in the order we add them to this object
  const tablesByTagID: FilteredSchemaMap = {};
  const tagNamesByTagID: { [key: string]: string } = {};
  let count = 0;

  // Add the tables to each of the tags they belong to.
  for (const t of allTables) {
    for (const tag of t.tagObjs) {
      if (tablesByTagID[tag.id] === undefined) {
        tablesByTagID[tag.id] = [];
        tagNamesByTagID[tag.id] = tag.name;
      }
      const tables: AggTable[] = tablesByTagID[tag.id];
      tables.push(t);
      count++;
    }
  }

  // Tables can belong to multiple tags.
  // Remove any tag that do not meet the search criteria.
  if (tagSearches && tagSearches.length) {
    Object.keys(tablesByTagID).forEach((tagID) => {
      const tagName = tagNamesByTagID[tagID].toLowerCase();
      if (!tagSearches.every((tagSearch) => tagName.includes(tagSearch))) {
        delete tablesByTagID[tagID];
      }
    });
  }

  return [tablesByTagID, count] as [FilteredSchemaMap, number];
}

function filterByVirtualSchemaKeys(
  tables: AggTable[],
  virtualSchemaKeys: string[],
  unfilteredRecentsByTableID: Dictionary<AggTable>,
  favoritesByTableID: FavoritesByTableID,
) {
  return tables.filter((table: AggTable) =>
    virtualSchemaKeys.some((schemaKey: string) => {
      const { virtualSchemaType, schema } = virtualSchemaKeyParts(schemaKey);
      if (virtualSchemaType === MOZART_ALL) {
        return true;
      }
      if (virtualSchemaType === MOZART_RECENT) {
        return !!unfilteredRecentsByTableID[table.id];
      }
      if (virtualSchemaType === MOZART_FAVORITES) {
        return !!favoritesByTableID[table.id];
      }
      return schema === table.schema;
    }),
  );
}

function setFilterAndUpdate(prevState: DatabaseSearchState, filter: string) {
  prevState.filter = filter;
  updateFilteredTables(prevState);
}

function setHiddenFilterAndUpdate(prevState: DatabaseSearchState, hiddenFilter: string) {
  prevState.hiddenFilter = hiddenFilter;
  updateFilteredTables(prevState);
}

// Update the UI input as the user is typing but do not do anything expensive.
// 1. Recomputing of the filtered tables list is a little bit expensive.
// 2. Rerendering the recomputed list is orders of magnitude more expensive than
//    step #1.
function setFilterOnly(prevState: DatabaseSearchState, filter: string) {
  prevState.filter = filter;
}

function setSchemaKeysToSearch(prevState: DatabaseSearchState, virtualSchemaKeysToSearch: string[]) {
  prevState.virtualSchemaKeysToSearch = virtualSchemaKeysToSearch;
  updateFilteredTables(prevState);
}

export function updateFilteredTables(prevState: DatabaseSearchState, skipThresholdExpand?: boolean) {
  filterTables(prevState);
  if (!skipThresholdExpand) {
    thresholdSchemaExpand(prevState);
  }
}

function setHideEmptyTables(prevState: DatabaseSearchState, hideEmptyTables: boolean) {
  prevState.hideEmptyTables = hideEmptyTables;
  filterTables(prevState);
}

function setHideViews(prevState: DatabaseSearchState, hideViews: boolean) {
  prevState.hideViews = hideViews;
  filterTables(prevState);
}

// Set all schemas to the desired expand setting.
function setAllSchemasExpanded(prevState: DatabaseSearchState, expanded: boolean) {
  const { schemasExpandedMap } = prevState;

  const newMap: SchemasExpandedMap = {};
  for (const schema in schemasExpandedMap) {
    newMap[schema] = expanded;
  }

  prevState.allSchemasExpanded = expanded;
  prevState.userHasEditedFilterInAnyWay = true;
  prevState.schemasExpandedMap = newMap;
}

// Expand schemas if the filteredTableCount is at or below the specified value.
function thresholdSchemaExpand(prevState: DatabaseSearchState) {
  const { isFiltering, schemasExpandedMap, tagsExpandedMap, expandSchemaWithoutFilter } = prevState;

  const EXPAND_SCHEMAS_ON_SEARCH_THRESHOLD = 30;

  // Set expand state of all schemas
  const tryExpand = isFiltering || expandSchemaWithoutFilter;
  const schemasExpanded =
    tryExpand && prevState.filteredTablesCount <= EXPAND_SCHEMAS_ON_SEARCH_THRESHOLD;
  const newSchemasMap: SchemasExpandedMap = {};
  for (const schema in schemasExpandedMap) {
    newSchemasMap[schema] = schemasExpanded;
  }

  // Set expand state of all tags
  const tagsExpanded =
    tryExpand && prevState.filteredTablesInTagsCount <= EXPAND_SCHEMAS_ON_SEARCH_THRESHOLD;
  const newTagsMap: SchemasExpandedMap = {};
  for (const tag in tagsExpandedMap) {
    newTagsMap[tag] = tagsExpanded;
  }

  prevState.allSchemasExpanded = schemasExpanded;
  prevState.userHasEditedFilterInAnyWay = true;
  prevState.schemasExpandedMap = newSchemasMap;
  prevState.tagsExpandedMap = newTagsMap;
}

function setSchemaExpanded(prevState: DatabaseSearchState, schemaKey: string, expanded: boolean) {
  const { schemasExpandedMap } = prevState;
  schemasExpandedMap[schemaKey] = expanded;
  prevState.schemasExpandedMap = { ...schemasExpandedMap };
}

function setTagExpanded(prevState: DatabaseSearchState, tag: string, expanded: boolean) {
  const { tagsExpandedMap } = prevState;
  tagsExpandedMap[tag] = expanded;
  prevState.tagsExpandedMap = { ...tagsExpandedMap };
}
