/*
 * Type definitions, API methods, and conversion utilities for TableVisits.
 */
import API from 'api/API';

/*******************************************************************************
 * Types
 ******************************************************************************/
export interface TableVisit {
  id: string;
  last_visited_at: string;
}

/*******************************************************************************
 * APIs
 ******************************************************************************/
const tableVisitAPI = {
  // Add a TableVisit
  add: (tableID: string) => {
    const api = new API();
    return api.post(`/api/tables/${tableID}/visit`, {});
  },
};
export default tableVisitAPI;

/*******************************************************************************
 * Conversion Utilites
 ******************************************************************************/
