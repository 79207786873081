import React from 'react';

import cn from 'classnames';

import { HEADING_CLASS } from './VertexSidebar';

export type VertexTableSectionRow = { label: string; value: React.ReactNode };

interface VertexTableSectionProps {
  title: string;
  rows: VertexTableSectionRow[];
  className?: string;
}

function VertexTableSection(props: VertexTableSectionProps) {
  const { title, rows, className } = props;
  return (
    <div className={className}>
      <div className={cn('pb-1', HEADING_CLASS)}>{title}</div>
      <table>
        <tbody>
          {rows.map((r) => (
            <tr key={r.label}>
              <td className="w-auto h-8 !pr-6 text-sm font-medium whitespace-nowrap">{r.label}</td>
              <td className="w-full text-sm">{r.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default VertexTableSection;
