import React, { ForwardedRef, useState } from 'react';

import cn from 'classnames';

import IconButton, { IconKey } from '../Button/IconButton';

const DEFAULT_CLASS = 'p-2 text-pri-gray-700 placeholder:text-pri-gray-400 rounded focus:ring-2';
export const getActualClass = (className: string | undefined, hasError: boolean, disabled?: boolean) => {
  return cn(
    DEFAULT_CLASS,
    {
      'w-full': !className?.includes('w-'),
      ['border-pri-gray-300 focus:border-purple focus:ring-sec-purple-200']: !hasError, // eslint-disable-line no-useless-computed-key
      ['border-pri-error-700 focus:border-pri-error-700 focus:ring-pri-error-200']: hasError, // eslint-disable-line no-useless-computed-key
      '!text-pri-gray-400 cursor-default': disabled,
    },
    className,
  );
};

export type PasswordInputType = 'password' | 'text';

export interface PasswordInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  value: string;
  type?: PasswordInputType;
  hasError?: boolean;
}

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
  (props: PasswordInputProps, forwardedRef: ForwardedRef<HTMLInputElement>) => {
    let { name, value, type, hasError, disabled, placeholder, className, ...remainingProps } = props;
    const [icon, setIcon] = useState('Eye');
    // Sanitize optional props
    const inputType: any = {
      Eye: 'password',
      EyeSlash: 'text',
    };

    hasError = hasError === true;

    const actualClass = getActualClass(className, hasError, disabled);

    return (
      <span className="flex align-center">
        <input
          ref={forwardedRef}
          type={inputType[icon]}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          className={actualClass}
          {...remainingProps}
        />
        <IconButton
          variant="lightDullAction"
          className="!h-[42px] !w-[42px] !p-0 -ml-10"
          onClick={() => {
            setIcon(icon === 'Eye' ? 'EyeSlash' : 'Eye');
          }}
          icon={icon as IconKey}
        />
      </span>
    );
  },
);

export default PasswordInput;
