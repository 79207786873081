import { useState, useEffect, useContext, useRef } from 'react';

import API from 'api/API';
import csvUploadAPI, { CSVUpload, SaveableCSVUploadProps } from 'api/csvUploadAPI';
import { convertTable } from 'api/tableAPI';
import { useDatabaseAccount } from 'context/AuthContext';
import { TableContext } from 'model_layer/TableContext';
import { TableModelsContext } from 'model_layer/TableModelsContext';

type PromiseRefType = {
  promise: Promise<CSVUpload> | null;
  resolve: (value: CSVUpload) => void;
  reject: (reason?: any) => void;
};

const useUploadCSV = () => {
  const { updateTables } = useContext(TableContext);
  const { addCsvUpload } = useContext(TableModelsContext);
  const databaseType = useDatabaseAccount().type;

  const [pollForStatus, setPollForStatus] = useState(false);
  const [csvUpload, setCSVUpload] = useState<CSVUpload | null>(null);
  const [savingCSV, setSavingCSV] = useState(false);
  const [csvErrorMessage, setCSVErrorMessage] = useState('');
  const promiseRef = useRef<PromiseRefType>({ promise: null, resolve: () => {}, reject: () => {} });

  const saveCSV = async (saveableCSVProps: SaveableCSVUploadProps) => {
    setSavingCSV(true);
    setCSVErrorMessage('');
    return csvUploadAPI
      .upload(saveableCSVProps)
      .then((csvUploadResponse) => {
        setCSVUpload(csvUploadResponse);
        // We need to poll to see if the CSV was successfully uploaded
        setPollForStatus(true);
        promiseRef.current.promise = new Promise<CSVUpload>((resolve, reject) => {
          promiseRef.current.resolve = resolve;
          promiseRef.current.reject = reject;
        });

        return promiseRef.current.promise;
      })
      .catch((e) => {
        // The status polling code might reject the promiseRef, which will then get caught here.
        // e.response.data is either like ['Table already exists'] or like {"table_name":["This field may not be blank."]}
        const errorMsg = e?.response?.data
          ? JSON.stringify(e.response.data)
          : e.message ?? 'There was a problem uploading your CSV.';
        setCSVErrorMessage(errorMsg);
        setSavingCSV(false);
        return Promise.reject(new Error(errorMsg));
      });
  };

  const { pollData, pollError, pollIsLoading } = csvUploadAPI.usePollCSVUploadStatus({
    csvUploadId: csvUpload?.id || null,
    pollForStatus,
  });

  useEffect(() => {
    if (pollError) {
      setPollForStatus(false);
      setSavingCSV(false);
      promiseRef.current.reject(new Error('There was a problem uploading your CSV.'));
    } else if (pollForStatus && csvUpload && !pollIsLoading) {
      // Check the responseId matches the current csvUpload so we don't serve stale data from a prior upload attempt
      if (pollData && pollData.id === csvUpload.id) {
        if (pollData.state === 'success') {
          const api = new API();

          api
            .get(`api/tables/${csvUpload.table}`, (table) => convertTable(table, databaseType))
            .then((resp) => {
              const table = resp.data;
              // Update global state
              addCsvUpload(pollData);
              updateTables([table]);
              promiseRef.current.resolve(pollData);
            })
            .catch((e) => {
              promiseRef.current.reject(e);
            })
            .finally(() => {
              setPollForStatus(false);
              setSavingCSV(false);
            });
        } else if (pollData.state === 'failure') {
          const errorMsg = pollData.error_message || 'There was a problem uploading your CSV.';
          promiseRef.current.reject(new Error(errorMsg));
          setPollForStatus(false);
          setSavingCSV(false);
        }
      }
    }
  }, [
    pollError,
    pollIsLoading,
    csvUpload,
    pollForStatus,
    addCsvUpload,
    updateTables,
    pollData,
    databaseType,
  ]);
  return { saveCSV, savingCSV, csvErrorMessage, setCSVErrorMessage };
};

export default useUploadCSV;
