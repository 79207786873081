import { sortBy } from 'lodash';

import { DbtDestinationTable } from 'api/dbtAPI';
import { AggTable } from 'api/tableAPI';
import { Status as GenericRunStatus } from 'components/primitives/icons/StatusIcon/StatusIcon';
import { parseIso } from 'utils/dateTime';

import {
  displaySchedule as runConfigDisplaySchedule,
  calcGenericRunStatus as runConfigCalcGenericRunStatus,
  lastRunDate as runConfigLastRanDate,
  duration as runConfigDuration,
  lastCompletedRunVersionSavedBy as runConfigLastCompletedRunVersionSavedBy,
  lastCompletedRunVersionSavedAt as runConfigLastCompletedRunVersionSavedAt,
} from './dbtRunConfigHelper';
import { scheduleSortValue } from './sort/DbtSorter';

// An AggTable could have been created by more than one DbtRunConfig, but it probably wasn't.
// In scenarios where there is only room to print the stats for one DbtRunConfig,
// it may be most appropirate to use the most recently run DbtRunConfig.
export function getMostRecentRunConfig(table: AggTable) {
  if (table.dbtRunConfigs.length === 1) {
    return table.dbtRunConfigs[0];
  }
  if (table.dbtRunConfigs.length > 0) {
    const sortedRunConfigs = sortBy(
      table.dbtRunConfigs,
      (rc) => parseIso(rc.last_run?.created_at || null)?.getTime() || Number.MIN_VALUE,
    );
    return sortedRunConfigs[sortedRunConfigs.length - 1];
  }
  return null;
}

// An AggTable could have been created by more than one DbtRunConfig, but it probably wasn't.
// In scenarios where there is only room to print the stats for one DbtRunConfig,
// it may be most appropirate to use the most frequenly scheduled DbtRunConfig.
export function getMostFrequentRunConfig(table: AggTable) {
  if (table.dbtRunConfigs.length === 1) {
    return table.dbtRunConfigs[0];
  }
  if (table.dbtRunConfigs.length > 0) {
    const sortedRunConfigs = sortBy(table.dbtRunConfigs, (rc) => scheduleSortValue(rc));
    return sortedRunConfigs[0];
  }
  return null;
}

export function getMostRecentDestinationTable(table: AggTable): DbtDestinationTable | null {
  const runConfig = getMostRecentRunConfig(table);
  if (runConfig) {
    return table.dbtDestinationTables.find((t) => t.configuration === runConfig.id) || null;
  }
  return null;
}

export function displaySchedule(table: AggTable) {
  const runConfig = getMostFrequentRunConfig(table);
  return runConfigDisplaySchedule(runConfig);
}

export function calcGenericRunStatus(table: AggTable): GenericRunStatus {
  const runConfig = getMostRecentRunConfig(table);
  return runConfigCalcGenericRunStatus(runConfig);
}

export function lastRunDate(table: AggTable) {
  const runConfig = getMostRecentRunConfig(table);
  return runConfigLastRanDate(runConfig);
}

export function duration(table: AggTable) {
  const runConfig = getMostRecentRunConfig(table);
  return runConfigDuration(runConfig);
}

export function lastCompletedRunVersionSavedBy(table: AggTable): string {
  const runConfig = getMostRecentRunConfig(table);
  return runConfigLastCompletedRunVersionSavedBy(runConfig);
}

export function lastCompletedRunVersionSavedAt(table: AggTable): Date | null {
  const runConfig = getMostRecentRunConfig(table);
  return runConfigLastCompletedRunVersionSavedAt(runConfig);
}
