import React from 'react';

import { Connector } from 'api/APITypes';
import { DbtRunConfig } from 'api/dbtAPI';
import ConnectorIcon from 'components/primitives/icons/ConnectorIcon/ConnectorIcon';
import { humanSyncFrequency } from 'model_helpers/connectorHelper';
import { displaySchedule } from 'model_helpers/dbtRunConfigHelper';

interface RunConfigScheduleWidgetProps {
  runConfig: DbtRunConfig;
  connectorsByID: { [key: string]: Connector };
}

const RunConfigScheduleWidget = (props: RunConfigScheduleWidgetProps) => {
  const { runConfig, connectorsByID } = props;
  const { connector_dependencies } = runConfig;

  let displayedSchedule = displaySchedule(runConfig);
  if (displayedSchedule.includes('After All')) {
    return (
      <div>
        <div>{displayedSchedule}:</div>
        {connector_dependencies.map((cd, i) => {
          const connector = connectorsByID[cd];
          if (connector) {
            const { service, schema, sync_frequency } = connector;
            return (
              <div key={connector.id} className="mt-1 f-row-y-center whitespace-nowrap">
                <ConnectorIcon service={service} size={20} />
                <div className="ml-2">{schema}</div>
                <div className="ml-2 text-pri-gray-400">{humanSyncFrequency(sync_frequency)}</div>
              </div>
            );
          }
          return <React.Fragment key={i} />;
        })}
      </div>
    );
  }

  return <span>{displayedSchedule}</span>;
};

export default RunConfigScheduleWidget;
