import { EditorView, ViewPlugin } from '@codemirror/view';

import { SqlEditorTools } from '../SqlEditor';

const EditorToolsPlugin = (editorTools: SqlEditorTools) =>
  ViewPlugin.fromClass(
    class {
      constructor(private view: EditorView) {
        editorTools.insertAtCursor = this.insertAtCurosor;
        editorTools.focus = this.focus;
      }

      insertAtCurosor = (str: string) => {
        const mainSelection = this.view.state.selection.main;
        const transaction = this.view.state.update({
          changes: { from: mainSelection.from, to: mainSelection.to, insert: str },
        });
        this.view.dispatch(transaction);
      };

      focus = () => {
        this.view.focus();
      };
    },
  );

export default EditorToolsPlugin;
