import { useCallback, useMemo, useEffect } from 'react';

import { Clock } from 'react-bootstrap-icons';

import { AggTable } from 'api/APITypes';
import useSortableTable, {
  SortFunctions,
  UseSortableTableProps,
} from 'components/tables/SortableTable/useSortableTable';
import AggTableSorter from 'model_helpers/sort/AggTableSorter';

/*
This hook is repsonsible for sorting the tables rendered in TableTable.
*/
export default function useTableTableSorting(
  tables: AggTable[],
  recentTables: AggTable[],
  useFullName: boolean,
  hasHasOnlyRecents: boolean,
) {
  const sortFunctions = useMemo(() => {
    const sortFunctions: SortFunctions<AggTable> = {
      // `tableRecent` is not the key of an actual TableTable column.
      // It's special sort mode we go into when we are sorting in recent order.
      // All of the other keys in this object are keys of actual TableTable columns.
      tableRecent: (t: AggTable) => {
        // recentTables should be sorted in recent order.
        // So the index of a table in recentTables is a sort value.
        const index = recentTables.findIndex((rt) => rt.id === t.id);
        return index > -1 ? index : Number.MAX_SAFE_INTEGER;
      },
      table: (t: AggTable) => (useFullName ? t.full_name : t.name).toLowerCase(),
      description: (t: AggTable) => t.description.toLowerCase(),
      scheduled: (t: AggTable) => AggTableSorter.schedule(t),
      status: (t: AggTable) => AggTableSorter.runStatus(t),
      lastRun: (t: AggTable) => AggTableSorter.lastRunAgo(t),
      rowCount: (t: AggTable) => t.num_rows,
      snapshot: (t: AggTable) => t.snapshot,
    };

    return sortFunctions;
  }, [useFullName, recentTables]);

  const useSortableTableProps: UseSortableTableProps<AggTable> = useMemo(
    () => ({
      unsortedItems: tables,
      sortFunctions,
      defaultSortConfig: {
        key: 'table',
        direction: 'ascending',
      },
    }),
    [tables, sortFunctions],
  );

  // Pass our TableTable specific props to the default table sorter.
  const {
    sortedItems: sortedTables,
    sortConfig,
    requestSort: defaultRequestSort,
    getSortIcon: defaultGetSortIcon,
  } = useSortableTable(useSortableTableProps);

  // If the selection of schemas in the SchemaList changes such that
  // hasHasOnlyRecents changes value, resort in or out of tableRecent mode.
  useEffect(() => {
    if (hasHasOnlyRecents && sortConfig?.key === 'table') {
      defaultRequestSort('tableRecent');
    } else if (!hasHasOnlyRecents && sortConfig?.key === 'tableRecent') {
      defaultRequestSort('table');
    }
  }, [hasHasOnlyRecents]); // eslint-disable-line react-hooks/exhaustive-deps

  // When `hasOnlyRecent` is true and the current table column key is `table`,
  // overload requestSort() to rotate between recent, ascending, and descending mode.
  // If the user is currently sorting by a column that has a key other than `table`
  // and clicks on table, sort by tableRecent.
  // Otherwise, use the columns default sort order.
  const requestSort = useCallback(
    (key: string | undefined) => {
      if (hasHasOnlyRecents && key === 'table') {
        // Go from tableRecent/ascending to table/ascending.
        if (sortConfig?.key === 'tableRecent') {
          key = 'table';
        } else if (sortConfig?.key === 'table') {
          // Go from table/ascending to table/descending.
          if (sortConfig.direction === 'ascending') {
            // Do nothing. Use the default sort order.
          }
          // Go from table/descending to tableRecent/ascending.
          else if (sortConfig.direction === 'descending') {
            key = 'tableRecent';
          }
        }
        // Go from any other table column to tableRecent/ascending.
        else {
          key = 'tableRecent';
        }
      }
      defaultRequestSort(key);
    },
    [hasHasOnlyRecents, sortConfig, defaultRequestSort],
  );

  // Override getSortIcon() to return a recent icon.
  const getSortIcon = useCallback(
    (key: string | undefined) => {
      if (key === 'table' && sortConfig?.key === 'tableRecent') {
        return <Clock size="16" color="var(--pri-gray-500)" />;
      }

      return defaultGetSortIcon(key);
    },
    [sortConfig, defaultGetSortIcon],
  );

  return { sortedTables, requestSort, getSortIcon };
}
