/*
  Wraps TextInputGroup and injects the Formik hook props into the input group.
*/
import React from 'react';

import { useField } from 'formik';

import TextInputGroup, {
  TextInputGroupProps,
} from 'components/inputs/group/TextInputGroup/TextInputGroup';

// We get value from Formik
export type SanitizedTextInputGroupProps = Omit<TextInputGroupProps, 'value'>;

export interface TextFormikGroupProps extends SanitizedTextInputGroupProps {}

export default function TextFormikGroup(props: TextFormikGroupProps) {
  const { name, ...rest } = props;
  const [field, meta] = useField(name);
  const { name: fieldName, value: fieldValue, ...fieldRest } = field;

  return (
    <TextInputGroup
      name={name}
      value={meta.value}
      error={meta.touched && meta.error ? meta.error : ''}
      {...fieldRest}
      {...rest}
    />
  );
}
