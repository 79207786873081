/*
Actions that are specific to transforms.
*/
import { Fragment, useContext } from 'react';

import { CreateAsType, ScheduleMode } from 'api/tableAPI';
import { useDatabaseAccount } from 'context/AuthContext';
import { AggTable, TableModelsContext } from 'model_layer/TableModelsContext';
import { CREATE_AS_TYPE_LABEL_MAP } from 'pages/tables/ShowTable/SummaryTab/SummaryTab';
import { displayCron } from 'utils/cron';
import { convertColumnName, convertFullName } from 'utils/dbName';
import { sqlDiffPath } from 'utils/PathMaker';

import { ObjectDelta, TableLinkOrName, UserActionSentencePartProps } from './UserActionSentenceParts';

interface ScheduleProps {
  scheduled: boolean;
  schedule_mode: ScheduleMode;
  schedule: string;
  ancestors: { table_id: string; full_name: string }[];
}

const Schedule = (props: ScheduleProps) => {
  const { scheduled, schedule_mode, schedule, ancestors } = props;
  const databaseType = useDatabaseAccount().type;
  const { tablesByID } = useContext(TableModelsContext);
  if (!scheduled) {
    return <ObjectDelta value="off" />;
  }
  if (schedule_mode === 'cron') {
    return <ObjectDelta value={displayCron(schedule)} />;
  }

  return (
    <>
      <ObjectDelta value="after all marked" /> on (
      {ancestors.map((a, i) => {
        let fullName = 'unknown';
        const table = tablesByID[a.table_id];
        if (table) {
          fullName = table.full_name;
        } else if (a.full_name) {
          fullName = convertFullName(a.full_name, databaseType);
        }
        return (
          <Fragment key={a.table_id}>
            <ObjectDelta value={fullName} />
            {i < ancestors.length - 1 && ', '}
          </Fragment>
        );
      })}
      )
    </>
  );
};

// TODO:
// 1. API is returning ancestors[i].full_name as null.
// 2. API is returning metadata.old_value.schedule = true when it should be false.
// 3. We need a storybook test for each if block in this method.
export const TransformChangedScheduleText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => {
  const { old_value, new_value } = userAction.metadata;
  // There are a handful of events from the new feature development era that were not logging new and old values at all.
  if (!old_value || !new_value) {
    return (
      <>
        Schedule for <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> changed.
      </>
    );
  }

  // There are a handful of events from the new feature development era that were logging values as cron strings.
  if (typeof old_value === 'string' || typeof new_value === 'string') {
    return (
      <>
        Schedule for <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> changed
        from <ObjectDelta value={displayCron(old_value)} /> to{' '}
        <ObjectDelta value={displayCron(new_value)} />.
      </>
    );
  }

  return (
    <>
      Schedule for <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> changed from{' '}
      <Schedule {...old_value} /> to <Schedule {...new_value} />.
    </>
  );
};

export interface IncrementalMetadata {
  incremental: boolean;
  auto_rebuild_on_change: boolean;
  incremental_update_key: string | null;
}
export const TransformChangedIncrementalText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => {
  const oldValue = userAction.metadata.old_value as unknown as IncrementalMetadata;
  const newValue = userAction.metadata.new_value as unknown as IncrementalMetadata;
  const databaseType = useDatabaseAccount().type;
  if (oldValue.incremental === true && newValue.incremental === false) {
    return (
      <>
        Incremental mode for <TableLinkOrName userAction={userAction} eventLocation={eventLocation} />{' '}
        has been toggled <ObjectDelta value="off" />.
      </>
    );
  }

  const incrementalKey = (
    <ObjectDelta
      value={
        newValue.incremental_update_key
          ? convertColumnName(newValue.incremental_update_key, databaseType)
          : 'None'
      }
    />
  );
  const autoRebuild = <ObjectDelta value={newValue.auto_rebuild_on_change ? 'on' : 'off'} />;

  if (oldValue.incremental === false && newValue.incremental === true) {
    return (
      <>
        Incremental mode for <TableLinkOrName userAction={userAction} eventLocation={eventLocation} />{' '}
        was toggled <ObjectDelta value="on" /> with update key(
        {incrementalKey}) and auto rebuild({autoRebuild}).
      </>
    );
  }

  return (
    <>
      Incremental mode for <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> was
      changed to update key(
      {incrementalKey}) and auto rebuild({autoRebuild}).
    </>
  );
};

// DISCUSS:
// This is a legacy event that should no longer be created.
// There are probably a lot of these in production that we want to support.
// Decide what to do later.
// This is not tested and debugged.
export const TransformToggledScheduleText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => {
  const { old_value, new_value } = userAction.metadata;
  const fakeAPICron = '0 * * * *';
  const oldValue = old_value ? displayCron(fakeAPICron) : 'off';
  const newValue = new_value ? displayCron(fakeAPICron) : 'off';
  return (
    <>
      Schedule for <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> changed from{' '}
      <ObjectDelta value={oldValue} /> to <ObjectDelta value={newValue} />.
    </>
  );
};

export const TransformChangedSQLText = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  // The first recorded version changes didn't record `new_version_number` in the metadata.
  // So, don't add the verstion to the url path and only render the first part of the sentence.
  const versionNumber = userAction.metadata?.new_version_number;

  const pickPath = (table: AggTable) => {
    return sqlDiffPath(table.id, versionNumber);
  };

  const version = `version ${versionNumber}`;

  return (
    <>
      SQL for{' '}
      <TableLinkOrName
        userAction={userAction}
        eventLocation={eventLocation}
        {...(versionNumber && { pickPath })}
      />{' '}
      changed
      {versionNumber ? (
        <>
          {' '}
          to <ObjectDelta value={version} />.
        </>
      ) : (
        '.'
      )}
    </>
  );
};

export const TransformManualRunText = ({ userAction, eventLocation }: UserActionSentencePartProps) => (
  <>
    <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> has been{' '}
    <ObjectDelta value="manually run" />.
  </>
);

export const TransformChangedCreateAsText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => {
  const { old_value, new_value } = userAction.metadata;
  if (!old_value?.create_as || !new_value?.create_as) {
    return (
      <>
        Create As for <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> changed.
      </>
    );
  } else {
    const cleanOldValue = CREATE_AS_TYPE_LABEL_MAP[old_value.create_as as CreateAsType];
    const cleanNewValue = CREATE_AS_TYPE_LABEL_MAP[new_value.create_as as CreateAsType];
    return (
      <>
        Create As for <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> changed
        from <ObjectDelta value={cleanOldValue} /> to <ObjectDelta value={cleanNewValue} />.
      </>
    );
  }
};
