import { useRef, useState } from 'react';

import { useMutation, useQuery, UseMutationResult } from 'react-query';

import { flatten } from 'lodash';

import API, { DataConverter } from 'api/API';

// Not sure how to set these on global client on react-query V2
const defaultOptions = {
  retry: false,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

/*
One time HTTP GET Hook:

Example:
import {useGet} from 'hooks/useApi';
let [isLoading, isError, data] = useGet('connectorList', 'api/fivetran/connectors');
*/

export function useGet(key: string, apiPath: string, convert?: DataConverter): GetProps<any> {
  async function get() {
    const api = new API();
    try {
      const response = await api.get(apiPath, convert);
      return response.data;
    } catch (e: any) {
      throw e;
    }
  }

  return useQuery(key, get, defaultOptions);
}

/*
One time HTTP GET Hook that provides some commonly used type safety.

Example:
import {useSafeGet} from 'hooks/useApi';
let {isLoading, error, data} = useSafeGet<Connector[]>('connectorList', 'api/fivetran/connectors', [], 'Failed to fetch connectors.');
*/
export function useSafeGet<T>(
  key: string,
  apiPath: string,
  defaultData: T,
  friendlyErrorMsg: string,
  convert?: DataConverter,
) {
  const { isLoading, isError, data } = useGet(key, apiPath, convert);
  const strError = isError ? friendlyErrorMsg : '';
  const safeData = (data || defaultData) as T;
  return {
    isLoading,
    error: strError,
    data: safeData,
  };
}

/*
HTTP POST Mutation:

Example:
TODO: Create example after I decide what a cannonical example looks like.
*/
export function usePostMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(apiPath: string, convert?: DataConverter): UseMutationResult<TData, TError, TVariables, TContext> {
  async function post(data: TVariables) {
    const api = new API();
    const response = await api.post(apiPath, data, convert);
    return response.data;
  }

  return useMutation<TData, TError, TVariables, TContext>(post, defaultOptions);
}

/*
HTTP PATCH Mutation:

Example:
TODO: Create example after I decide what a cannonical example looks like.
*/
export function usePatchMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(apiPath: string, convert?: DataConverter): UseMutationResult<TData, TError, TVariables, TContext> {
  async function patch(data: TVariables) {
    const api = new API();
    const response = await api.patch(apiPath, data, convert);
    return response.data;
  }

  return useMutation<TData, TError, TVariables, TContext>(patch, defaultOptions);
}

/*
HTTP DELETE Mutation:

Example:
TODO: Create example after I decide what a cannonical example looks like.
*/
export function useDeleteMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(apiPath: string, convert?: DataConverter): UseMutationResult<TData, TError, TVariables, TContext> {
  async function deleteFunc() {
    const api = new API();
    const response = await api.delete(apiPath, {}, convert);
    return response.data;
  }

  return useMutation<TData, TError, TVariables, TContext>(deleteFunc, defaultOptions);
}

/*
Polling Hook:

Example:
import {usePoll} from 'hooks/useApi';
const {isLoading, isError, data, error} = usePoll<Connector[]>('connectorList', 'api/fivetran/connectors');
OR
const {isLoading, isError, data, error} = usePoll('connectorList', () => api.get('api/fivetran/connectors')); // generic type is inferred from the function return type
*/

// For debug logging
// let lastFetch = Date.now();

// The semi-exponential series of backoff times to poll with.
// FYI: Syncing a trivial spreadsheet takes about 40 seconds.
const POLL_SECONDS = flatten([
  // Super responsive at first as backend jobs get queued up and change state
  [5, 5],
  // Attentive for two minutes.
  // Anything longer than 30 seconds and the user will space out.
  // However in practice, Fivetran needs a little over a minute to sync a gsheet.
  // Being attentive for two minutes instead of one is a debugging convenience.
  [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
  // Less attentive for another minute
  [20, 20, 20],
  // Back off as not to waste resources
  [60, 60],
  [120, 120],
  [5 * 60, 5 * 60],
  10 * 60,
]);

function pollTime(pollIndex: number, pollArray: number[]) {
  const safeIndex = pollIndex < pollArray.length ? pollIndex : pollArray.length - 1;
  return pollArray[safeIndex] * 1000;
}

export interface GetProps<T> {
  isLoading: boolean;
  isError: boolean;
  data: T | undefined;
  error: any;
}

export interface PollProps<T> extends GetProps<T> {
  resetPolling(): void;
}

export function usePoll<T>(
  key: string,
  apiPathOrFunction: string | (() => Promise<T>),
  pollArray: number[] = POLL_SECONDS,
  enabled: boolean = true,
): PollProps<T> {
  // The current index in POLL_SECONDS
  // This will never exceed the max index of POLL_SECONDS
  const pollIndex = useRef(0);

  // The value passed to react-query
  // This may be a number of milliseconds
  // It may also be set to false to disable polling
  const refetchInterval = useRef<number | false>(pollTime(pollIndex.current, pollArray));
  const [myError, setMyError] = useState('');

  async function poll() {
    try {
      if (typeof apiPathOrFunction === 'function') {
        return await apiPathOrFunction();
      }
      const api = new API();
      return (await api.get(apiPathOrFunction)).data;
    } catch (e: any) {
      // 10/07/2022:
      // In practice, it seems like when api.get() throws an error options.onError() will get called,
      // but the error still gets thrown to the global error handler, which results in unwanted Sentry spam.
      // Catching the error here, and stopping polling to prevent spamming Sentry.
      // It's not great that we are stopping polling but this is probably due to a lack of internet connectivity,
      // expiring auth token, or some other event that would cause the user to refresh the browser anyway.
      // Note: useQuery has retry options that might be better for this provided we invest more time in learning useQuery().
      stopPolling(e);
      setMyError(
        `Failed to fetch${typeof apiPathOrFunction === 'string' ? ` ${apiPathOrFunction}` : ''}.`,
      );
      return undefined;
    }
  }

  const backOff = () => {
    if (refetchInterval.current !== false && pollIndex.current + 1 < pollArray.length) {
      const newIndex = pollIndex.current + 1;
      pollIndex.current = newIndex;
      refetchInterval.current = pollTime(newIndex, pollArray);
    }
    // let d: any = queryCache.getQuery(key);
    // console.log(myID, Date.now() - lastFetch, d.instances.length);
    // lastFetch = Date.now();
  };

  const stopPolling = (e: any) => {
    refetchInterval.current = false;
  };

  const resetPolling = () => {
    pollIndex.current = 0;
    refetchInterval.current = pollTime(0, pollArray);
  };

  const options = {
    ...defaultOptions,
    ...{
      enabled,
      refetchInterval: refetchInterval.current,
      onSuccess: backOff,
      // onError does not work as expected. See comments in poll() method.
      // onError: stopPolling,
    },
  };

  const queryProps = useQuery(key, poll, options);

  return { ...queryProps, error: queryProps.error || myError, resetPolling };
}
