/*******************************************************************************
Typically, we do not show any banners below the header.
In the rare event we do show a banner, we only show one banner at a time.
This hook picks the highest priority banner and tells AuthHeaderView to render it.
This includes all banners except the DevModeBanner which renders above the header.
This should be programmed in such a way that the UI defaults to having no banners
unless the API specifies there should be a banner.

To Add A Banner:
1. Add the banner's key to BannerKey.
2. Implement a `show[BannerName]` method and add it to the appropriate place in `pickBanner()`.
3. Add an appropriate section to `pickBannerProps()`
*******************************************************************************/
import { useCallback, useContext, useState } from 'react';

import { useLocation } from 'react-router';

import { UserProfile } from 'api/APITypes';
import { useUserProfile } from 'context/AuthContext';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import { HIDE_TRIAL_BANNER_ROUTES } from 'main/MainApp';
import {
  UserPreferencesContext,
  UserPreferencesContextInterface,
} from 'model_layer/UserPreferencesContext';

import { ConcretePromotionBannerProps } from './AuthHeaderView/banners/PromotionBanner/AbstractPromotionBanner';
import { TrialBannerProps } from './AuthHeaderView/banners/TrialBanner/TrialBanner';
import {
  USAGE_WARNING_EVENT_LOCATION,
  UsageWarningBannerProps,
} from './AuthHeaderView/banners/UsageWarningBanner/UsageWarningBanner';
import {
  pickPromotionBannerProps,
  showAIAssistantPromotionBanner,
  showReferralPromotionBanner,
} from './promotionBannerState';

export type BannerKey = 'UsageWarning' | 'Trial' | 'ReferralPromotion' | 'AIAssistantPromotion';
export type BannerProps = UsageWarningBannerProps | TrialBannerProps | ConcretePromotionBannerProps;

export interface BannerState {
  banner: BannerKey;
  bannerProps: BannerProps;
}

/*******************************************************************************
 * Hook that decides which banner to render from API state.
 * Do not render a banner if this returns undefined.
 ******************************************************************************/
export function useBannerState(): BannerState | undefined {
  const { userProfile } = useUserProfile();
  const location = useLocation();
  const aiAssistantEnabled = useBooleanFlag('ai_assistant_banner');
  const userPreferencesContext = useContext(UserPreferencesContext);

  // We want the `pickBanner()` method to be able to calculate the banner from
  // `userProfile`, `userPreferencesContext`, and `localStorage`; however,
  // we still need to be able to trigger a rerender if `localStorage` changes.
  // So build a forceUpdate() method.
  const [, updateState] = useState<Object>();
  const forceUpdate = useCallback(() => updateState({}), []);

  // Decide if we should render a banner or not.
  const banner = pickBanner(userProfile, userPreferencesContext, location.pathname, aiAssistantEnabled);

  // Show a banner.
  if (banner) {
    return {
      banner,
      bannerProps: pickBannerProps(banner, userProfile, userPreferencesContext, forceUpdate),
    };
  }

  // Base case: No Banner
  return undefined;
}

/*******************************************************************************
 * Pick the banner with the highest priority.
 * We render a maximum of one banner at a time.
 ******************************************************************************/
export function pickBanner(
  userProfile: UserProfile,
  userPreferencesContext: UserPreferencesContextInterface,
  pathname: string,
  aiAssistantEnabled: boolean,
): BannerKey | null {
  // Do not show any banner on a page where that would be inappropriate
  if (HIDE_TRIAL_BANNER_ROUTES.includes(pathname)) {
    return null;
  }
  if (showUsageWarningBanner(userProfile)) {
    return 'UsageWarning';
  }
  if (showTrialBanner(userProfile)) {
    return 'Trial';
  }
  if (showAIAssistantPromotionBanner(userProfile, userPreferencesContext) && aiAssistantEnabled) {
    return 'AIAssistantPromotion';
  }
  if (showReferralPromotionBanner(userProfile, userPreferencesContext)) {
    return 'ReferralPromotion';
  }

  // Base case: No Banner
  return null;
}

/*
If the user's company has an usage overage, show them the warning banner once a day.
The banner will go away for 24 hours if they click the "remind me later" button.
The remind me later check is tracked in browser localStorage on a per user basis.
If the user is logged out, their localStorage will get errased and they will see the warning again.

For frontend testing: Python Snippet to simulate API response:
company = Company.objects.get(name='DevMozart Inc.')
comp_conf = company.company_configuration
comp_conf.is_snowflake_overage = True
comp_conf.save()
*/
const USAGE_WARNING_KEY = 'last_usage_warning_at';
export function showUsageWarningBanner(userProfile: UserProfile) {
  // Do not show the banner on the first four(The weekend + 2 business days) days of the month
  // in case the cron job breaks or there is a weird month to month transition.
  const SHOW_BANNER_ON_DATE = 5;
  const now = new Date();
  if (now.getDate() < SHOW_BANNER_ON_DATE) {
    return false;
  }

  // Do not show the banner if the backend returns last month's billing date.
  // This prevents us from accidentally showing a banner for last month's overage.
  const overageMonthStr = userProfile.company.overage_month_start;
  if (overageMonthStr === null) {
    return false;
  } else {
    const billingPeriodStart = new Date(`${overageMonthStr} GMT-0800`);
    if (now.getMonth() !== billingPeriodStart.getMonth()) {
      return false;
    }
  }

  // Do now show the banner if the user has dismissed the banner in the last day
  const storedLastUsageWarningAt = localStorage.getItem(USAGE_WARNING_KEY);
  const lastUsageWarningAt = storedLastUsageWarningAt ? new Date(storedLastUsageWarningAt) : new Date(0);
  const ONE_DAY = 24 * 60 * 60 * 1000;
  const showWarningAt = new Date(lastUsageWarningAt.getTime() + ONE_DAY);
  if (now < showWarningAt) {
    return false;
  }

  return userProfile.company_role !== 'viewer' && userProfile.company.should_show_overage_banner;
}

export function showTrialBanner(userProfile: UserProfile) {
  return !userProfile.company.has_paid_access;
}

/*******************************************************************************
 * Pick the banner's render props based on the given banner type.
 ******************************************************************************/
export function pickBannerProps(
  banner: BannerKey,
  userProfile: UserProfile,
  userPreferencesContext: UserPreferencesContextInterface,
  forceUpdate: () => void,
) {
  if (banner === 'UsageWarning') {
    const onClose = () => {
      localStorage.setItem(USAGE_WARNING_KEY, new Date().toString());
      forceUpdate();
      analytics.track(`${USAGE_WARNING_EVENT_LOCATION} Close`);
    };
    const usageWarningProps: UsageWarningBannerProps = {
      scheduleLink: userProfile.company.sfdc_owner_calendly_link,
      onClose,
    };
    return usageWarningProps;
  }
  if (banner === 'Trial') {
    const trialProps: TrialBannerProps = {
      userProfile,
    };
    return trialProps;
  }
  return pickPromotionBannerProps(banner, userProfile, userPreferencesContext);
}
