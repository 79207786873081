import React, { useState, useEffect } from 'react';

import API from 'api/API';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { SnowflakeAccount } from './useAccounts';

interface DatabaseListingAccountModalProps {
  account: SnowflakeAccount;
  closeModal: () => void;
}

interface SnowflakeDatabase {
  name: string;
}

export default function DatabaseListingAccountModal(props: DatabaseListingAccountModalProps) {
  const { account, closeModal } = props;
  const [databases, setDatabases] = useState<SnowflakeDatabase[]>([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDatabases = async () => {
      setLoading(true);
      setError('');
      const api = new API();
      try {
        const response = await api.post('/api/snowflake_account_databases', {
          snowflake_account_id: account.id,
        });
        setDatabases(response.data);
      } catch (error) {
        setError('Failed to fetch databases.');
      } finally {
        setLoading(false);
      }
    };
    fetchDatabases();
  }, [account.id]);

  return (
    <Modal onClose={closeModal} header={`Databases for Account: ${account.name}`} cancelButton={true}>
      <div className="flex flex-col h-full pt-2">
        {error && (
          <Alert className="mx-4 mb-2" variant="error">
            {error}
          </Alert>
        )}
        {loading ? (
          <p>Loading databases...</p>
        ) : (
          <ul className="list-disc pl-6">
            {databases.map((database, index) => (
              <li key={index}>{database.name}</li> // Assuming the database objects have a `name` property
            ))}
          </ul>
        )}
      </div>
    </Modal>
  );
}
