export interface DbtSshKey {
  id: string;
  git_public_key: string;
  created_at: string;
}

export interface DbtRepositoryCreateParams {
  ssh_key_pair: string;
  git_url: string;
}

export interface DbtRepository {
  id: string;
  ssh_key_pair: string;
  git_repository_name: string;
  git_repository_owner: string;
  git_hostname: string;
  created_at: string;
  is_docs_ready: boolean;
}

export type DbtScheduleMode = 'cron' | 'all_marked';

/*******************************************************************************
 * Note: The UI and URLs refer to run config objects as "jobs".
 ******************************************************************************/
export interface DbtRunConfigUpsertParams {
  name: string;
  dbt_repository: string;
  custom_git_branch: string | null;
  dbt_version: string;
  target_schema: string;
  target_database: string;
  commands: string[];
  scheduled: boolean;
  schedule_mode: DbtScheduleMode;
  schedule: string;
  connector_dependencies: string[];
}

type DbtRunConfigUpsertParamsWithoutDbtRepo = Omit<DbtRunConfigUpsertParams, 'dbt_repository'>;

interface ObjectEmailSubscription {
  is_opted_out: boolean;
}

export interface DbtRunConfig extends DbtRunConfigUpsertParamsWithoutDbtRepo {
  id: string;
  created_at: string;
  updated_at: string;
  dbt_repository: DbtRepository;
  use_custom_git_branch: boolean;
  object_email_subscriptions: ObjectEmailSubscription[];
  last_run: DbtRun | null;
}

export interface DbtDestinationTable {
  id: string;
  created_at: string;
  updated_at: string;
  configuration: string;
  table: string;
  raw_code: string;
  compiled_code: string;
  configuration_name: string;
}

export type DbtRunState = 'running' | 'failed' | 'success' | 'created';

export interface DbtRun {
  id: string;
  created_at: string;
  configuration: string;
  commands: string[];
  state: DbtRunState;
  completed_at: string | null;
  error_message: string | null;
  is_manual: boolean;
}

export interface DbtRunLog {
  id: string;
  logs: string[];
}

export const cleanDbtRepository = (
  dbtRepository: DbtRepository,
): { displayName: string; link: string | null } => {
  const displayName = `${dbtRepository.git_repository_owner}/${dbtRepository.git_repository_name}`;
  // If we add more host names, fix this up
  const link = ['github.com', 'gitlab.com', 'bitbucket.org'].includes(dbtRepository.git_hostname)
    ? `https://${dbtRepository.git_hostname}/${displayName}`
    : null;
  return { displayName, link };
};
