import { AggTable, CompanyRole } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import { SPINNER_MIN_HEIGHT } from 'components/layouts/containers/TabLayout/TabLayout';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';

import GSheetDestinationInfoIcon from './GSheetDestinationInfoIcon';
import GSheetDestinationModal from './GSheetDestinations/GSheetDestinationModal';
import GSheetDestinationRow from './GSheetDestinations/GSheetDestinationRow';
import { GSheetDestination, NewGSheetDestination } from './SyncTabController';

const sheetHelpUrl = 'https://help.mozartdata.com/docs/google-sheet-sync';

export interface SyncTabProps {
  table: AggTable;
  loading: boolean;
  error: string;
  companyRole: CompanyRole;
  existingDestinations: GSheetDestination[];
  formDestination: NewGSheetDestination | null;
  openAddModal: () => void;
  openEditModal: (destination: GSheetDestination) => void;
  toggleDestination: (destination: GSheetDestination) => Promise<GSheetDestination>;
  deleteDestination: (destination: GSheetDestination) => Promise<void>;
  setDestinationsAndCloseModal: (destinations: GSheetDestination[]) => void;
  cancelEditModal: () => void;
}

export default function SyncTab(props: SyncTabProps) {
  const {
    table,
    loading,
    error,
    companyRole,
    existingDestinations,
    formDestination,
    openAddModal,
    openEditModal,
    toggleDestination,
    deleteDestination,
    setDestinationsAndCloseModal,
    cancelEditModal,
  } = props;
  if (loading) {
    return <CenteredSpinner containerMinHeight={SPINNER_MIN_HEIGHT} />;
  }

  if (error) {
    return (
      <div className="mt-2">
        <div className="flex my-4">
          <div className="text-base font-medium text-sec-blue-gray-700">Google Sheet Sync</div>
          <div className="flex items-center ml-2">
            <GSheetDestinationInfoIcon type={table.type} />
          </div>
        </div>
        <Alert variant="error">{error}</Alert>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="f-between mb-4">
        <div className="flex">
          <div className="text-base font-medium text-sec-blue-gray-700">Google Sheet Sync</div>
          <div className="flex items-center ml-2">
            <GSheetDestinationInfoIcon type={table.type} />
          </div>
        </div>
        {companyRole !== 'viewer' && (
          <Button variant="darkDullAction" size="small" onClick={openAddModal} className="">
            Add Destination
          </Button>
        )}
      </div>
      <div>
        {existingDestinations.length === 0 && (
          <NoObjectsAlert
            detail={
              <p className="font-medium">
                You haven’t set up a Google Sheet Destination.{' '}
                {companyRole === 'viewer' &&
                  'Viewers cannot add Destinations. Please contact an admin to add a Destination. '}
                Learn how to connect a Google Sheet{' '}
                <a
                  href={sheetHelpUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-sec-blue-light-700"
                >
                  here
                </a>
                .
              </p>
            }
          />
        )}
        {existingDestinations.length > 0 && (
          <div>
            <table className="blueGrayHeaderTable">
              <thead>
                <tr>
                  <th className="whitespace-nowrap">Spreadsheet</th>
                  <th className="whitespace-nowrap">Tab</th>
                  <th className="whitespace-nowrap">Last Synced At</th>
                  <th className="whitespace-nowrap">Last Sync Status</th>
                  <th className="whitespace-nowrap">Last Updated By</th>
                  <th className="whitespace-nowrap">Order By</th>
                  <th className="whitespace-nowrap text-center" style={{ width: '110px' }}>
                    Enabled
                  </th>
                  {companyRole !== 'viewer' && (
                    <th className="whitespace-nowrap text-center" style={{ width: '100px' }}></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {existingDestinations.map((e: GSheetDestination) => (
                  <GSheetDestinationRow
                    key={e.id}
                    destination={e}
                    toggleDestination={toggleDestination}
                    editDestination={openEditModal}
                    deleteDestination={deleteDestination}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
        {formDestination && (
          <GSheetDestinationModal
            table={table}
            existingDestinations={existingDestinations}
            destination={formDestination}
            setDestinationsAndClose={setDestinationsAndCloseModal}
            onCancel={cancelEditModal}
          />
        )}
      </div>
    </div>
  );
}
