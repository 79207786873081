import React from 'react';

import StatusIcon, { Status } from 'components/primitives/icons/StatusIcon/StatusIcon';
import { SetupState, SyncState, getConnectorStatus } from 'model_helpers/connectorHelper';

export interface StatusColProps {
  setup_state: SetupState;
  sync_state: SyncState;
  lastRunFailed: boolean;
  count?: number;
}

export default function StatusCol({ setup_state, sync_state, lastRunFailed, count }: StatusColProps) {
  return (
    <div className="flex">
      <span className="w-[37px]  flex items-center mr-2">
        <span className="w-[20px] pr-[2px] text-right">{count}</span>
        <StatusIcon
          status={getConnectorStatus(setup_state, sync_state, lastRunFailed) as Status}
          rotate={true}
        />
      </span>
    </div>
  );
}
