import React, { useState } from 'react';

import cn from 'classnames';

import TextInput from 'components/inputs/basic/TextInput/TextInput';
import RadioInputGroup from 'components/inputs/group/RadioInputGroup/RadioInputGroup';
import { scheduleOptionsMap } from 'utils/cron';

interface CronSettingsProps {
  schedule: string;
  setSchedule: (schedule: string) => void;
}

export default function CronSettings(props: CronSettingsProps) {
  const { schedule, setSchedule } = props;

  const cronIsCustom = !Object.keys(scheduleOptionsMap).includes(schedule);

  const [customCronSelected, setCustomCronSelected] = useState(cronIsCustom);

  const handleSelectSchedule = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue === 'custom') {
      setCustomCronSelected(true);
    } else {
      setCustomCronSelected(false);
      setSchedule(newValue);
    }
  };

  const handleChangeCustomCron = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSchedule(newValue);
  };

  const customRadioRow = (
    <div className="f-row-y-center">
      <div>Custom</div>
      <TextInput
        name="customSchedule"
        value={schedule}
        className="w-[120px] h-[28px] ml-2"
        disabled={!customCronSelected}
        onChange={handleChangeCustomCron}
      />
      <a
        href={`https://crontab.guru/#${schedule.replace(' ', '_')}`}
        rel="noopener noreferrer"
        target="_blank"
        className="ml-2 text-sec-blue-light-600 whitespace-nowrap hover:underline"
        data-track="DBT LearnCron"
      >
        Learn Cron
      </a>
    </div>
  );

  return (
    <div className={cn('flex flex-col')}>
      <div className="flex flex-col -mt-2">
        <RadioInputGroup
          value={customCronSelected ? 'custom' : schedule}
          onChange={handleSelectSchedule}
          name="schedule"
          options={Object.keys(scheduleOptionsMap)
            .map((k) => {
              return { value: k, label: scheduleOptionsMap[k] as string | React.ReactNode };
            })
            .concat({ value: 'custom', label: customRadioRow })}
          numCols={2}
          sharedRadioInputProps={{
            containerClass: 'text-sm mt-2',
          }}
        />
      </div>
    </div>
  );
}
