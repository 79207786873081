import { useState } from 'react';

import API from 'api/API';
import { AppConnector } from 'api/APITypes';

import { usePatchOneConnector } from '../ConnectorUtils';

export interface SyncFrequencyState {
  syncFrequency: number;
  dailySyncTime: string | null;
  loading: boolean;
  error: string;
  handleSetSyncFreq: (frequency: number, dailySyncTime: string | null) => void;
}

export default function useSyncFrequencyState(connector: AppConnector): SyncFrequencyState {
  const {
    fivetran_connector_id,
    sync_frequency: syncFrequency,
    daily_sync_time: dailySyncTime,
  } = connector;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const patchConnector = usePatchOneConnector();
  const handleSetSyncFreq = async (frequency: number, dailySyncTime: string | null) => {
    const api = new API();

    setError('');
    setLoading(true);

    try {
      const response = await api.post(`api/fivetran/connector/${fivetran_connector_id}/frequency`, {
        sync_frequency_minutes: frequency,
        daily_sync_time: frequency === 1440 ? dailySyncTime : null,
      });
      if (response.data.detail) {
        setError(response.data.detail);
      } else {
        analytics.track('ListConnectorsSummaryTab SetSyncFrequency', {
          sync_frequency_minutes: frequency,
        });
        patchConnector(response.data);
      }
    } catch (error) {
      setError('Failed to set sync frequency.');
    } finally {
      setLoading(false);
    }
  };

  return { syncFrequency, dailySyncTime, loading, error, handleSetSyncFreq };
}
