import React from 'react';

import { Helmet } from 'react-helmet';

const EmailVerification = () => {
  const title = 'Verify Your Email Address';

  return (
    <div className="f-center">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet>
      <div className="w-[471px] mt-[160px] f-col f-center">
        <img className="h-[28px] mb-[28px]" src="/images/logos/MozartDataLogoPurple.svg" alt="" />
        <img className="w-[220px] overflow-hidden" src="/images/graphics/messageSent.svg" alt="" />
        <div className="w-full h-full relative top-[-20px] flex flex-col p-[24px] items-center bg-sec-purple-25 text-pri-gray-700 rounded shadow">
          <div className="text-xl font-medium">{title}</div>
          <h5 className="mt-2 text-base text-center">
            Please find the confirmation email in your inbox
            <br />
            and click the link to verify your email and create a password
          </h5>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
