import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function DataAlertModeInfoIcon(props: {}) {
  const content = (
    <div className="whitespace-normal text-left">
      <div>
        <strong>Alerts on Transforms can have 2 modes:</strong>
      </div>
      <div className="mt-2">
        <strong>Notify</strong> will run the alert after the transform has run successfully and only send
        a notification if there is an error or rows are returned.
      </div>
      <div className="mt-2">
        <strong>Revert and Notify</strong> will run the alert after a transform has successfully run. If
        the alert errors or returns rows the transform's table will be reverted to its previous state and
        a notification will be sent.
      </div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} popoverProps={popoverProps} placement="left" />;
}
