import { useContext, useMemo } from 'react';

import { chain, differenceBy, isObject } from 'lodash';

import { AggTable, Connector } from 'api/APITypes';
import { Dashboard, ReportAvailable, ReportTransform } from 'api/dashboardAPI';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import { TableModelsContext } from 'model_layer/TableModelsContext';

import StatusCell from './StatusCell';
import TableNameCell from './TableNameCell';
import TableViewCell from './TableViewCell';

export type PartialConnector = Pick<Connector, 'id' | 'fivetran_connector_id' | 'service' | 'schema'>;
interface TransformTableProps {
  dashboard: Dashboard;
  reportsAvailable: ReportAvailable[];
  connector: PartialConnector;
}

const TransformTable = (props: TransformTableProps) => {
  const { dashboard, reportsAvailable, connector } = props;
  const { tablesByID, transformsByID } = useContext(TableModelsContext);

  // Calculate the list of transforms-like-objects to render.
  // This might be a mix of fully created Transforms and ReportTransforms to create in the future.
  const rows = useMemo<(AggTable | ReportTransform)[]>(() => {
    // If the dashboard has aready run, it has created these transform tables.
    const transformTables = chain(dashboard.reports)
      .map((r) => r.transforms)
      .flatten()
      .map((transformID) => transformsByID[transformID])
      // It's possible the API hasn't loaded this object into the app yet or state hasn't updated yet on this render cycle(especially in Storybook).
      .filter((transform) => isObject(transform))
      .map((transform) => tablesByID[transform.table_id])
      // It's possible the API hasn't loaded this object into the app yet or state hasn't updated yet on this render cycle(especially in Storybook).
      .filter((table) => isObject(table))
      .uniqBy('full_name')
      .sortBy('full_name')
      .value();

    // When the dashboard runs, it will create these transforms if they haven't been created already.
    const transformsWeMightCreate = chain(reportsAvailable)
      .map((r) => r.transforms)
      .flatten()
      .uniqBy((t) => fullName(connector, t))
      .sortBy((t) => fullName(connector, t))
      .value();

    const transformsNotCreatedYet = differenceBy(
      transformsWeMightCreate,
      transformTables,
      (a: AggTable | ReportTransform) => {
        return fullName(connector, a);
      },
    );

    // For the time being we decided to list the transforms we might create last.
    const joinedTransforms = [...transformTables, ...transformsNotCreatedYet];
    return joinedTransforms;
  }, [dashboard, reportsAvailable, tablesByID, transformsByID, connector]);

  const hasViewableTransform = rows.some((r) => {
    if (r.hasOwnProperty('full_name')) {
      const table = r as AggTable;
      return table.transform?.last_completed_run?.state === 'success';
    }
    return false;
  });

  return (
    <table className="blueGrayHeaderTable">
      <thead>
        <tr>
          <th style={{ width: '40%' }}>Transform</th>
          <th className="text-center" style={{ width: '8%' }}>
            Status
          </th>
          <th style={{ width: '44%' }}>Description</th>
          {hasViewableTransform && <th className="text-center" style={{ width: '8%' }}></th>}
        </tr>
      </thead>
      <tbody>
        {rows.map((tableLike) => {
          if (tableLike.hasOwnProperty('full_name')) {
            const table = tableLike as AggTable;
            return (
              <tr key={table.id}>
                <TableNameCell table={table} connector={connector} dashboard={dashboard} />
                <StatusCell table={table} />
                <td className="text-ellipsis">{table.description}</td>
                {hasViewableTransform && (
                  <TableViewCell table={table} connector={connector} dashboard={dashboard} />
                )}
              </tr>
            );
          } else {
            const rt = tableLike as ReportTransform;
            const myFullName = fullName(connector, rt);
            return (
              <tr key={myFullName}>
                <td>{myFullName}</td>
                <td>
                  <div className="f-center">
                    <StatusIcon status="waiting" />
                  </div>
                </td>
                <td className="text-ellipsis">{rt.description}</td>
                {hasViewableTransform && <td></td>}
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};

export default TransformTable;

function fullName(connector: PartialConnector, tableLike: AggTable | ReportTransform) {
  if (tableLike.hasOwnProperty('full_name')) {
    const aggTable = tableLike as AggTable;
    return aggTable.full_name;
  }
  const reportTransform = tableLike as ReportTransform;
  return `${reportTransform.report_schema_prefix}_${connector.schema}.${reportTransform.table_name}`;
}
