/*
  The Main QueryEditor that pages import.
  
  Responsible for:
  1. Composes hooks that manage individual windows into one master hook.
  2. Delegates running queries and exporting results to useQueryRunner().
  3. Delegates the state of the actual SQL in the editor and selected SQL state to useSqlEditor();
  4. Delegates AI Assistant state to useAIAssistant().
  5. Delegates Flowchart state to useFlowchartEditor().

  Try not to let UI state that is only used in a specific page and is not 
  generic QueryEditor behavior leak into this file.
*/
import { useState, useMemo, useContext } from 'react';

import { AggTable } from 'api/APITypes';
import { SavedFlowchartQueryModel } from 'components/query/FlowchartEditor/model/SavedFlowchartQueryModel';
import { TableModelsContext } from 'model_layer/TableModelsContext';

import useAIAssistant, { AIAssistantState } from './useAIAssistant';
import useFlowchartEditor, { FlowchartEditorState } from './useFlowchartEditor';
import useQueryRunner, { QueryRunnerState } from './useQueryRunner';
import useSqlEditor, { SqlEditorState } from './useSqlEditor';

export type EditorType = 'sql' | 'ai_assistant';

export interface FlowchartSaveProps {
  savedFlowchartModel: SavedFlowchartQueryModel;
  isSaving: boolean;
  error: string;
  save: (
    newSavedFlowchartModel: SavedFlowchartQueryModel,
    sql: string,
  ) => Promise<SavedFlowchartQueryModel>;
}

interface QueryEditorProps {
  eventPrefix: string;
  initialSql?: string;
  flowchartSaveProps?: FlowchartSaveProps;
  object: { id: string; type: 'savedQuery' | 'transform' | 'dataAlert' };
  onChangeSql?: (savedQueryId: string, sql: string) => void;
  onRefetchSavedQuery?: (savedQueryId: string) => void; // This refreshed data on a single savedQuery, to call after handleRun
  errorOnDuplicateColumns?: boolean;
  isHidden?: boolean; // Like a query editor tab that is not selected at the moment
  autoShowAIAssistant?: boolean;
}

export interface QueryEditorState {
  isFlowchart: boolean;
  editorType: EditorType;
  selectedTable: AggTable | null; // The table selected in the TableExplorer
  queryRunnerState: QueryRunnerState;
  sqlState: SqlEditorState;
  assistantState: AIAssistantState;
  flowchartState: FlowchartEditorState;
  setEditorType: React.Dispatch<React.SetStateAction<EditorType>>;
  setSelectedTable: React.Dispatch<React.SetStateAction<AggTable | null>>;
}

export default function useQueryEditor(props: QueryEditorProps): QueryEditorState {
  const {
    eventPrefix,
    initialSql,
    flowchartSaveProps,
    object,
    onChangeSql,
    onRefetchSavedQuery,
    errorOnDuplicateColumns,
    autoShowAIAssistant,
    isHidden = false,
  } = props;

  // Normal State Hooks:
  const [editorType, setEditorType] = useState<EditorType>(autoShowAIAssistant ? 'ai_assistant' : 'sql');
  const [selectedTable, setSelectedTable] = useState<AggTable | null>(null);

  const isFlowchart = useMemo(
    () => !!flowchartSaveProps?.savedFlowchartModel,
    [flowchartSaveProps?.savedFlowchartModel],
  );

  // Query Running State:
  const queryRunnerState = useQueryRunner({ eventPrefix });

  // SQL Editor State:
  const sqlState = useSqlEditor({
    queryRunnerState,
    eventPrefix,
    initialSql,
    savedQueryId: object.type === 'savedQuery' ? object.id : undefined,
    onChangeSql,
    onRefetchSavedQuery,
    errorOnDuplicateColumns,
  });
  const { getLatestEditorSql, setSqlAndRun, setSqlAndSimulateRun } = sqlState;

  // assistantState is defined here in the common parent so that it persists editor mode switches.
  const assistantState = useAIAssistant({
    getLatestEditorSql,
    setSqlAndRun,
    chatReferenceObjectId: object.id,
    isHidden: isHidden || isFlowchart,
    showAIAssistant: () => setEditorType('ai_assistant'),
  });

  // Flowchart state that needs to be defined in the common ancestor of several components.
  const tableModelContext = useContext(TableModelsContext);
  const flowchartState = useFlowchartEditor({
    flowchartSaveProps,
    setSqlAndRun,
    setSqlAndSimulateRun,
    tablesByID: tableModelContext.tablesByID,
    tablesLoaded: tableModelContext.allLoaded,
    isHidden,
  });

  return {
    isFlowchart,
    editorType,
    selectedTable,
    queryRunnerState,
    sqlState,
    assistantState,
    flowchartState,
    setEditorType,
    setSelectedTable,
  };
}
