/*
  Responsible for:
  1. Running queries.
  2. Exporting the last run query results to CSV or GSheet.
  3. Copying the last run query results to the browser's clipboard.
*/
import { useState, useCallback } from 'react';

import API from 'api/API';
import { ExportCsvProps, ExportGSheetProps, QueryRunProps, QueryRunResults } from 'api/APITypes';
import executeFnWithSignedInGapi from 'utils/gapi';

export interface RunProps {
  ranSql: string;
  results: QueryRunResults | null;
}

export interface QueryRunnerProps {
  eventPrefix: string;
}

export interface QueryRunnerState {
  addLimit: boolean;
  running: boolean;
  runningIncremental: boolean;
  exportingCsv: boolean;
  exportingGSheet: boolean;
  runErrorLines: string[];
  runResults: QueryRunResults | null;
  lastRunSql: string;
  toggleLimit: () => void;
  run: (
    sqlToRun: string,
    incremental: boolean,
    recordEvent: boolean,
    savedQueryId?: string,
    errorOnDuplicateColumns?: boolean,
  ) => Promise<RunProps>;
  simulateRun: (sql: string, runResults: QueryRunResults | null) => RunProps;
  clearLastRun: () => void;
  setRunErrorLines: React.Dispatch<React.SetStateAction<string[]>>;
  onExportCsv: (fileName: string) => void;
  onExportGSheet: (spreadsheet_name: string, worksheet_name: string) => void;
  onCopyToClipboard: () => void;
}

export default function useQueryRunner(props: QueryRunnerProps): QueryRunnerState {
  const { eventPrefix } = props;

  const [addLimit, setAddLimit] = useState<boolean>(true);
  const [running, setRunning] = useState<boolean>(false); // There is any run happening
  const [runningIncremental, setRunningIncremental] = useState<boolean>(false); // The run happening is incremental
  const [exportingCsv, setExportingCsv] = useState<boolean>(false);
  const [exportingGSheet, setExportingGSheet] = useState<boolean>(false);
  const [runErrorLines, setRunErrorLines] = useState<string[]>([]);
  const [runResults, setRunResults] = useState<QueryRunResults | null>(null);
  const [lastRunSql, setLastRunSql] = useState<string>('');

  const toggleLimit = useCallback(() => {
    setAddLimit(!addLimit);
  }, [addLimit]);

  const run = useCallback(
    (
      sqlToRun: string,
      incremental: boolean,
      recordEvent: boolean,
      savedQueryId?: string,
      errorOnDuplicateColumns?: boolean,
    ): Promise<RunProps> => {
      // There can only be one "run-like" operation at a time.
      if (running || exportingCsv || exportingGSheet) {
        return Promise.reject();
      }

      setRunning(true);
      setRunningIncremental(incremental);
      setRunErrorLines([]);
      setLastRunSql(sqlToRun);
      setRunResults(null);

      const postData: QueryRunProps = {
        sql: sqlToRun,
        limit: addLimit ? 100 : 2000,
        incremental,
        saved_query_id: savedQueryId,
        error_on_duplicate_columns: errorOnDuplicateColumns,
      };

      const api = new API();
      return api
        .post('api/run_sql', postData)
        .then((response) => {
          let results: QueryRunResults | null = null;
          if (response.data.error_lines) {
            setRunErrorLines(response.data.error_lines);
          } else {
            if (recordEvent) {
              analytics.track(`${eventPrefix} RunQuery`, {
                sqlLength: sqlToRun.length,
                sqlLines: sqlToRun.split('\n').length,
              });
            }
            results = response.data.results as QueryRunResults;
            setRunResults(results);
            setRunErrorLines([]);
          }
          return {
            ranSql: sqlToRun,
            results,
          } as RunProps;
        })
        .catch((error) => {
          setRunErrorLines(['There was a problem running the query.']);
          return Promise.reject(error);
        })
        .finally(() => {
          setRunning(false);
          setRunningIncremental(false);
        });
    },
    [addLimit, eventPrefix, exportingCsv, exportingGSheet, running],
  );

  const simulateRun = useCallback((sql: string, runResults: QueryRunResults | null) => {
    setRunning(false);
    setRunningIncremental(false);
    setRunErrorLines([]);
    setLastRunSql(sql);
    setRunResults(runResults);

    const simulatedRunProps: RunProps = {
      ranSql: sql,
      results: runResults,
    };

    return simulatedRunProps;
  }, []);

  const clearLastRun = useCallback(() => {
    setRunErrorLines([]);
    setRunResults(null);
  }, []);

  const handleExportCsv = useCallback(
    (fileName: string) => {
      if (running || exportingCsv) {
        return;
      }

      const api = new API();
      setExportingCsv(true);
      setRunErrorLines([]);

      const postData: ExportCsvProps = {
        sql: lastRunSql,
      };
      api
        .post('api/csv_export/query', postData)
        .then((response) => {
          if (response.data.error_lines) {
            setRunErrorLines(response.data.error_lines);
          } else {
            analytics.track(`${eventPrefix} ExportCSV`);
            setRunErrorLines([]);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          setRunErrorLines(['There was a problem exporting the query.']);
        })
        .finally(() => {
          setExportingCsv(false);
        });
    },
    [eventPrefix, exportingCsv, running, lastRunSql],
  );

  const handleExportGSheet = useCallback(
    (spreadsheet_name: string, worksheet_name: string) => {
      if (running || exportingGSheet) {
        return;
      }

      const api = new API();
      setExportingGSheet(true);
      setRunErrorLines([]);

      // This requires being signed into gapi, so we build this function to be wrapped with the signIn flow
      const callExportGSheetAPI = (gapi_access_token: string) => {
        const postData: ExportGSheetProps = {
          sql: lastRunSql,
          gapi_access_token,
          spreadsheet_name,
          worksheet_name,
        };
        api
          .post('api/gsheet_export/query', postData)
          .then((response) => {
            if (response.data.error_lines) {
              setRunErrorLines(response.data.error_lines);
            } else {
              // Success Case
              analytics.track(`${eventPrefix} ExportGSheet`);
              // After exporting to Google Sheets, open a new tab to the sheet
              window.open(
                `https://docs.google.com/spreadsheets/d/${response.data.spreadsheetId}/edit#gid=0`,
                '_blank',
                'noopener,noreferrer',
              );
              setRunErrorLines([]);
            }
          })
          .catch((error) => {
            setRunErrorLines(['There was a problem exporting the query.']);
          })
          .finally(() => {
            setExportingGSheet(false);
          });
      };
      const gSheetErrorFn = () => {
        setRunErrorLines(['There was a problem authenticating with Google Sheets.']);
        setExportingGSheet(false);
      };
      // Do the google auth flow to get permission to add a gsheet on the user's account and execute callExportGSheetAPI
      executeFnWithSignedInGapi(callExportGSheetAPI, gSheetErrorFn);
    },
    [eventPrefix, exportingGSheet, running, lastRunSql],
  );

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(runResults ? queryRunResultsToClipboard(runResults) : '');
    analytics.track(`${eventPrefix} CopyRunResultsToClipboard`);
  }, [runResults, eventPrefix]);

  return {
    addLimit,
    running,
    runningIncremental,
    exportingCsv,
    exportingGSheet,
    runErrorLines,
    runResults,
    lastRunSql,
    toggleLimit,
    run,
    simulateRun,
    clearLastRun,
    setRunErrorLines,
    onExportCsv: handleExportCsv,
    onExportGSheet: handleExportGSheet,
    onCopyToClipboard: handleCopyToClipboard,
  };
}

const escapeTsvValue = (value: any) => {
  if (value === null || value === undefined) return '';
  const str = value.toString();
  if (str.includes('"') || str.includes('\t') || str.includes('\n')) {
    return `"${str.replace(/"/g, '""')}"`;
  }
  return str;
};

const queryRunResultsToClipboard = (results: QueryRunResults) => {
  const columnNames = results.columns.map((col) => escapeTsvValue(col.name)).join('\t');
  const rows = results.rows.map((row) => row.map(escapeTsvValue).join('\t')).join('\n');
  return `${columnNames}\n${rows}`;
};
