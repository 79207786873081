import React, { ForwardedRef } from 'react';

import cn from 'classnames';

const DEFAULT_CLASS = 'p-2 text-pri-gray-700 placeholder:text-pri-gray-400 rounded focus:ring-2';
export const getActualClass = (className: string | undefined, hasError: boolean, disabled?: boolean) => {
  return cn(
    DEFAULT_CLASS,
    {
      'w-full': !className?.includes('w-'),
      ['border-pri-gray-300 focus:border-purple focus:ring-sec-purple-200']: !hasError, // eslint-disable-line no-useless-computed-key
      ['border-pri-error-700 focus:border-pri-error-700 focus:ring-pri-error-200']: hasError, // eslint-disable-line no-useless-computed-key
      '!text-pri-gray-400 cursor-default': disabled,
    },
    className,
  );
};

export type TextInputType = 'password' | 'text' | 'email' | 'tel' | 'number';

export interface TextInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  value: string | number;
  type?: TextInputType;
  hasError?: boolean;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props: TextInputProps, forwardedRef: ForwardedRef<HTMLInputElement>) => {
    let { name, value, type, hasError, disabled, placeholder, className, ...remainingProps } = props;

    // Sanitize optional props
    type = type || 'text';
    hasError = hasError === true;

    const actualClass = getActualClass(className, hasError, disabled);

    return (
      <input
        ref={forwardedRef}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        className={actualClass}
        {...remainingProps}
      />
    );
  },
);

export default TextInput;
