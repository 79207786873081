import { HEADING_CLASS } from './VertexSidebar';

interface SidebarDescriptionProps {
  description: string;
}

export default function SidebarDescription(props: SidebarDescriptionProps) {
  const { description } = props;

  return (
    <div>
      <div className={HEADING_CLASS}>Description</div>
      <div className="mt-1 text-sm">
        <div className="max-h-[182px] w-full flex whitespace-pre-wrap overflow-y-auto">
          {description || <div className="items-center text-pri-gray-400">None</div>}
        </div>
      </div>
    </div>
  );
}
