import React, { ForwardedRef } from 'react';

import cn from 'classnames';

import IconButton, { IconKey } from 'components/inputs/basic/Button/IconButton';

// NOTE: Try to use PageButtons component instead of PageButton directly.

export type PageDirection = 'prev' | 'next' | 'first' | 'last';

export interface PageButtonProps {
  direction: PageDirection;
  hasPage: boolean; // Button is disabled if there isn't a page in this direction to go to.
  onClick: () => void;
  className?: string;
}

const PageButton = React.forwardRef<HTMLButtonElement, PageButtonProps>(
  (props: PageButtonProps, forwardedRef: ForwardedRef<HTMLButtonElement>) => {
    const { direction, hasPage, onClick, className } = props;

    const icons: { [key: string]: IconKey } = {
      first: 'ChevronDoubleLeft',
      prev: 'ChevronLeft',
      next: 'ChevronRight',
      last: 'ChevronDoubleRight',
    };
    const spacingHackClass = direction === 'prev' ? '!pr-[2px]' : '!pl-[2px]';
    const defaultClass = 'w-[28px] !p-0';
    const actualClass = cn(defaultClass, spacingHackClass, className);

    return (
      <IconButton
        ref={forwardedRef}
        icon={icons[direction]}
        iconSize={16}
        size="small"
        disabled={!hasPage}
        variant="darkDullAction"
        onClick={onClick}
        className={actualClass}
      />
    );
  },
);

export default PageButton;
