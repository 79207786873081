import React from 'react';

import Hotkeys from 'components/widgets/Hotkeys/Hotkeys';
import { isMac } from 'utils/browser';

import { CommandPathItem } from './CommandPalette';

import s from './CommandPaletteHeader.module.css';

interface CommandPaletteHeaderProps {
  commandPath: CommandPathItem[];
}

export default function CommandPaletteHeader(props: CommandPaletteHeaderProps) {
  const { commandPath } = props;
  return (
    <div>
      <div className={s.keyRow}>
        <span>Search for a command</span>
        <span>
          <Hotkeys hotkeys="↑↓" /> to navigate
        </span>
        <span>
          <Hotkeys hotkeys="Enter" /> to select
        </span>
        <span>
          <Hotkeys hotkeys={`${isMac() ? '⌘' : '^'}+Enter`} /> to open in new tab
        </span>
        <span>
          <Hotkeys hotkeys="Esc" /> to dismiss
        </span>
      </div>
      {commandPath.length > 0 && (
        <div className="f-row-y-center" style={{ marginBottom: '10px' }}>
          <span className={s.pathLabel + ' mr-1'}>Command Path: </span>

          {commandPath.map((cpi) => (
            <span key={cpi.type} className={s.pathValue}>
              <span>/</span>
              <span>{cpi.name}</span>
            </span>
          ))}

          <span className={s.pathEscape + ' ml-1'}>
            (<Hotkeys hotkeys="Esc" /> to reset)
          </span>
        </div>
      )}
    </div>
  );
}
