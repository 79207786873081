import React from 'react';

import cn from 'classnames';

import { UserProfile, CompanyRole, DatabaseType } from 'api/APITypes';
import IconButton from 'components/inputs/basic/Button/IconButton';
import Listbox, { ListboxValue } from 'components/inputs/basic/Listbox/Listbox';
import Switch from 'components/inputs/basic/Switch/Switch';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import uph from 'model_helpers/userProfileHelper';
import { capitalize } from 'utils/String';

import { companyRoleMap } from '../../../useMozartUsers';

import s from './UserRow.module.css';

export interface UserRowProps {
  userProfile: UserProfile;
  selected: boolean;
  imAdmin: boolean;
  showSnowflakeRole: boolean;
  validSnowflakeRole: boolean;
  require_2fa: boolean;
  spinningRole: boolean;
  spinningIsActive: boolean;
  databaseType: DatabaseType;
  saveRole(userProfile: UserProfile, newRole: CompanyRole): void;
  openConfirmSaveIsActive(userProfile: UserProfile, newIsActive: boolean): void;
  openSnowflakeModal: (user: UserProfile) => void;
}

const UserRow = React.memo((props: UserRowProps) => {
  const {
    userProfile,
    selected,
    imAdmin,
    showSnowflakeRole,
    validSnowflakeRole,
    require_2fa,
    spinningRole,
    spinningIsActive,
    databaseType,
    saveRole,
    openConfirmSaveIsActive,
    openSnowflakeModal,
  } = props;
  const { user, twofa_enabled, company_role, snowflake_role } = userProfile;
  const { email, is_active } = user;
  const isByos =
    userProfile.company.database_account?.type === 'snowflake' &&
    userProfile.company.database_account.is_byos;
  const handleSaveMozartRole = (companyRole: ListboxValue) => {
    saveRole(userProfile, companyRole as CompanyRole);
  };

  const handleSaveIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIsActive = event.target.checked;
    openConfirmSaveIsActive(userProfile, newIsActive);
  };

  const expectedSnowflakeRole = companyRoleMap[company_role];
  let snowflakeRoleTip = snowflake_role;
  if (!validSnowflakeRole) {
    snowflakeRoleTip += ' is missing.';
  }

  return (
    <tr className={cn({ newListItem: selected, [s.inactive]: !is_active })} key="row">
      <td>{uph.displayName(userProfile)}</td>
      <td>{email}</td>
      <td>
        <div className="f-center">
          {require_2fa ? (
            <StatusIcon status={twofa_enabled ? 'success' : 'error'} />
          ) : twofa_enabled ? (
            <StatusIcon status="success" />
          ) : (
            'Optional'
          )}
        </div>
      </td>
      {/* This element's content is taller than 24px so we need to shrink it's padding
      to accomodate the extra height without making the row taller. */}
      <td className="!py-[10px]">
        <div className="f-center">
          {imAdmin ? (
            <Listbox
              value={company_role}
              options={[
                { label: 'Admin', value: 'admin' },
                { label: 'Editor', value: 'editor' },
                { label: 'Viewer', value: 'viewer' },
              ]}
              variant="gray"
              size="medium"
              spinning={spinningRole}
              onChange={handleSaveMozartRole}
              disabled={!is_active}
              widthClass="w-[110px]"
            />
          ) : (
            <>{capitalize(company_role)}</>
          )}
        </div>
      </td>
      {showSnowflakeRole && (
        <td>
          <div className="w-[300px] f-row-y-center">
            <TooltipTrigger tip={snowflakeRoleTip}>
              <div className="max-w-full f-row-y-center">
                <div
                  className={cn('overflow-hidden text-ellipsis whitespace-nowrap', {
                    'text-pri-error-700': !validSnowflakeRole,
                  })}
                >
                  {snowflake_role}
                </div>
                {snowflake_role === expectedSnowflakeRole && (
                  <div className="ml-1 text-pri-gray-400">(default)</div>
                )}
              </div>
            </TooltipTrigger>
            {!validSnowflakeRole && (
              <InfoIcon
                containerClass="ml-1"
                content="This user has a snowflake role that does not exist. You can asign the user a valid role with the settings button on the right side of this row."
              />
            )}
          </div>
        </td>
      )}
      {imAdmin && (
        <td>
          <div className="f-center">
            <Switch
              name="is_active"
              checked={is_active}
              spinning={spinningIsActive}
              onChange={handleSaveIsActive}
            />
          </div>
        </td>
      )}
      {/* This element's content is taller than 24px so we need to shrink it's padding
      to accomodate the extra height without making the row taller. */}
      {imAdmin && !isByos && databaseType !== 'bigquery' && (
        <td className="!py-[14px]">
          <div className="f-center">
            <TooltipTrigger tip="Advanced Settings">
              <IconButton
                icon="Gear"
                variant="lightDullTransparent"
                size="small"
                onClick={() => openSnowflakeModal(userProfile)}
              />
            </TooltipTrigger>
          </div>
        </td>
      )}
    </tr>
  );
});

export default UserRow;
