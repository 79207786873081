import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

import { ColumnConfigsObject } from '../useManageTablesTabState';

interface ColumnsTableProps {
  schemaName: string;
  tableName: string;
  columns: ColumnConfigsObject;
  savingDisabled: boolean;
  onToggleEnableColumn(schemaName: string, tableName: string, columnName: string): void;
  onToggleHashColumn(schemaName: string, tableName: string, columnName: string): void;
}
export default function ColumnsTable(props: ColumnsTableProps) {
  const { schemaName, tableName, columns, savingDisabled, onToggleEnableColumn, onToggleHashColumn } =
    props;
  return (
    <table className="blueGrayHeaderTable !w-auto">
      <thead>
        <tr>
          <th style={{ width: '70%' }}>Sync Column</th>
          <th style={{ width: '30%' }} className="text-center">
            <div className="flex items-center">
              <span>Hash Column</span>
              <InfoIcon
                content={
                  <div className="whitespace-normal text-left">
                    Column hashing is a method for anonymizing data in your destination while preserving
                    its analytical value. You can join across data sets using a hashed value without
                    introducing sensitive data to your destination.
                  </div>
                }
                containerClass="ml-1"
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(columns).map((k) => {
          const c = columns[k];
          const columnDisabled = !c.enabled_patch_settings.allowed || savingDisabled;
          const hashDisabled = !(c.enabled_patch_settings.allowed || c.enabled) || savingDisabled;
          const cellClass = '!h-[32px] !py-[4px]';
          return (
            <tr key={k}>
              <td className={cellClass}>
                <div className="flex items-center">
                  <Checkbox
                    name={`column-enabled-${schemaName}.${tableName}.${k}`}
                    variant="blue_gray"
                    disabled={columnDisabled}
                    checked={c.enabled}
                    onChange={() => {
                      onToggleEnableColumn(schemaName, tableName, k);
                    }}
                  />
                  <span>{k}</span>
                  {!c.enabled_patch_settings.allowed && (
                    <InfoIcon
                      content={c.enabled_patch_settings.reason || 'Not allowed to edit this column.'}
                      containerClass="ml-1"
                      iconColor={'var(--pri-gray-400)'}
                    />
                  )}
                </div>
              </td>
              <td className={cellClass}>
                <Checkbox
                  name={`column-hashed-${schemaName}.${tableName}.${k}`}
                  variant="blue_gray"
                  disabled={hashDisabled}
                  checked={c.hashed}
                  onChange={() => {
                    onToggleHashColumn(schemaName, tableName, k);
                  }}
                  containerClass="f-center"
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
