/**
 * This file calculates CompletionSource methods for useAutocompleteConfig.
 **/
import { useMemo } from 'react';

import _ from 'lodash';

import { SearchColumnsByTableID } from 'api/searchColumnAPI';
import { AggTable } from 'api/tableAPI';

import { Completion, CompletionContext } from '@codemirror/autocomplete';
import { syntaxTree } from '@codemirror/language';

import { CustomCompletion, AUTOCOMPLETE_SEARCH_BOOST } from './autocompleteConstants';
import { keywordCompletions, snowlflakeFunctionSnippetsCompletions } from './staticCompletions';
import { getEntireEditorTreeNodes, TreeNode, filterTreeNodes } from './treeNodeUtils';
import useColumnCompletionCache from './useColumnCompletionCache';

const compareCompletion = (a: Completion, b: Completion) => a.label === b.label;

// Gets column completions only if we are completing after a SELECT statement or comma (,)
const getColumnCompletions = (
  getColumnCompletionsForTables: (tableNames: string[]) => Completion[],
  treeNodes: TreeNode[],
  currentNodeIndex: number,
): CustomCompletion[] => {
  // SQL won't run unless a table is a correctly spelled full_name(<schema>.<table>)
  // (or some crypic permutation of table name parts wrapped in double quotes and spaces which we are completely ignoring.)
  // Let's assume, we can only get columns for tables that are correctly spelled full_names.
  // TODO: This might not work on all caps BQ names. Test at future date.
  const compositeIdentifierNodes = filterTreeNodes(treeNodes, ['CompositeIdentifier']);
  // This could be `schema.table`, `table_alias.column`, and maybe some other strings with a period in it.
  const maybeTableNames = compositeIdentifierNodes.map((n) => n.text.toLowerCase());
  let compositeColumnsCompletions = getColumnCompletionsForTables(maybeTableNames);
  // `compositeColumnsCompletions` contains duplicates because multiple tables can contain columns with the same name.
  // Remove duplicate columns.
  // Columns with the same name in different tables might have different types, descriptions, or other meta data
  // that gets printed in the autocomplete list. We only care about completing names so lets ignore duplicates
  // to keep things simple.
  compositeColumnsCompletions = _.uniqWith(compositeColumnsCompletions, compareCompletion);

  // Local indentifiers as those that aren't found in compositeColumnsCompletions
  const localColumnIdentifiers = filterTreeNodes(treeNodes, ['Identifier']);

  const currentText = treeNodes[currentNodeIndex].text;
  const afterDot = currentText.split('.')[1];

  // Only suggest local column names if we are before a dot.
  // If we are after a dot, we know it's a column OR a table
  // which is taken care of by the other completionSources.
  let localColumns: string[] = [];
  if (afterDot === undefined) {
    localColumns = localColumnIdentifiers
      .map((node) => node.text)
      // Exlcude the node we are currently typing from local vars
      .filter((column) => column.toLowerCase() !== currentText);
  }

  // Map local columns to completion objects
  const localCompletions = localColumns.map((text) => ({
    label: text,
    type: 'property',
    detail: 'local',
    boost: AUTOCOMPLETE_SEARCH_BOOST.localVar,
  }));

  // Local identifiers are more important than unused columns.
  // Remove compositeCompletions that are duplicates with localCompletions.
  compositeColumnsCompletions = _.differenceWith(
    compositeColumnsCompletions,
    localCompletions,
    compareCompletion,
  );

  const completions = [...localCompletions, ...compositeColumnsCompletions];

  return completions;
};

// All the xxxCompletionsSource methods check the same conditions at their start.
// Extract all of the duplicate code into this method.
const getCompletionSourceConditionChecks = (context: CompletionContext) => {
  const word = context.matchBefore(/\w*/);
  const nodeBefore = syntaxTree(context.state).resolveInner(context.pos, -1);
  const textBefore = context.state.sliceDoc(nodeBefore.from, context.pos);

  const userDidNotType = word?.from === word?.to && !context.explicit;
  const afteQuotes = !!textBefore.match(/'|"/);
  return { word, nodeBefore, textBefore, userDidNotType, afteQuotes };
};

const keywordCompletionSource = (context: CompletionContext) => {
  const { word, nodeBefore, userDidNotType, afteQuotes } = getCompletionSourceConditionChecks(context);
  const isNotExpectingIdentifierNode = ['.'].includes(nodeBefore?.prevSibling?.type?.name || '');

  if (userDidNotType || afteQuotes || isNotExpectingIdentifierNode) {
    return null;
  }

  return {
    // @ts-ignore
    from: word.from,
    validFor: /^\w*$/,
    options: keywordCompletions,
  };
};

// gets snippet completions for snowflake functions
const snowflakeFunctionSnippetCompletionSource = (context: CompletionContext) => {
  const { word, nodeBefore, userDidNotType, afteQuotes } = getCompletionSourceConditionChecks(context);
  const isNotIdentifierNode = ['CompositeIdentifier', 'Identifier', '.'].includes(
    nodeBefore?.prevSibling?.type?.name || '',
  );

  if (userDidNotType || afteQuotes || isNotIdentifierNode) {
    return null;
  }

  return {
    // @ts-ignore
    from: word.from,
    validFor: /^\w*$/,
    options: snowlflakeFunctionSnippetsCompletions,
  };
};

const computeColumnCompletionSource =
  (getColumnCompletionsForTables: (tableNames: string[]) => Completion[]) =>
  (context: CompletionContext) => {
    const { word, userDidNotType, afteQuotes } = getCompletionSourceConditionChecks(context);

    if (userDidNotType || afteQuotes) {
      return null;
    }

    // Get all tokens from the query in the editor
    // TODO: Maybe add a cutoff when it finds the current node so that we don't walk the entire document.
    // TODO: Check Lezzer syntaxTree operator to see if there's a better way to iterate nodes from current posiiton.
    let treeNodes: TreeNode[] = getEntireEditorTreeNodes(context);

    // Find the node we are currently typing in
    // because `word` uses match before which only matches things before the cursor, and
    // when you first start typing what is before the cursor is not what you are currently typing.
    const currentNodeIndex: number = _.findIndex(
      treeNodes,
      (node) => context.pos >= node.from && context.pos <= node.to,
    );

    // Columns can only be after these keywords
    const keywordWhitelist = ['select', 'where', 'having', 'by', 'on', 'and', 'or'];

    // Search backwards for the first keyword.
    // Check if the first keyword is from our whitelist.
    let isAfterKeyword = false;
    const nodesBeforeCompletion = treeNodes.slice(0, currentNodeIndex);

    for (let node of nodesBeforeCompletion.reverse()) {
      if (node.type === 'Keyword') {
        let nodeText = node.text.toLowerCase();
        if (keywordWhitelist.includes(nodeText)) {
          isAfterKeyword = true;
        }
        break;
      }
    }

    if (!isAfterKeyword) {
      return null;
    }

    return {
      // @ts-ignore
      from: word.from,
      validFor: /^\w*$/,
      options: getColumnCompletions(getColumnCompletionsForTables, treeNodes, currentNodeIndex),
    };
  };

const useCompletionSources = (
  tablesByFullName: { [fullName: string]: AggTable },
  searchColumnsByTableID: SearchColumnsByTableID,
) => {
  const getColumnCompletionsForTables = useColumnCompletionCache(
    tablesByFullName,
    searchColumnsByTableID,
  );

  const columnCompletionSource = useMemo(
    () => computeColumnCompletionSource(getColumnCompletionsForTables),
    [getColumnCompletionsForTables],
  );

  return {
    keywordCompletionSource,
    snowflakeFunctionSnippetCompletionSource,
    columnCompletionSource,
  };
};

export default useCompletionSources;
