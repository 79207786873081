import React from 'react';

import cn from 'classnames';
import { format } from 'date-fns';

import { parseIso } from 'utils/dateTime';

import { CompanyPlanRow } from '../useUsageFetch';

import { useLayoutRules } from './layout/FeatureModel';
import FEATURE_MODELS from './layout/LayoutModel';
import LayoutWidgets from './widgets/LayoutWidgets';
import MonthlyUsageChart from './widgets/MonthlyUsageChart';

interface SummaryTabProps {
  currentInvoice: CompanyPlanRow | undefined;
  companyPlanByMonth: CompanyPlanRow[];
}

// TODO: Move this to a utils file
export const cycleFormat = 'MM/dd/yyyy';
const isoDate = (date: string) => parseIso(date) as Date;
export const cycleDateFormatter = (date: string) => format(isoDate(date) as Date, cycleFormat);

export default function SummaryTab(props: SummaryTabProps) {
  const { currentInvoice, companyPlanByMonth } = props;

  const { CurrentCycleDates, PlanSummary, MAR, Compute, Storage, CloudService, EmptyMetricsCard } =
    useLayoutRules({
      state: { companyPlanByMonth, currentInvoice },
      features: LayoutWidgets,
      models: FEATURE_MODELS,
    });

  const wigetLayoutClass = cn(['w-full', 'grid', 'grid grid-cols-2', 'gap-4'], {
    'grid-rows-2':
      currentInvoice?.['IS_USAGE'] === true &&
      !['Annual', 'Monthly'].includes(currentInvoice?.['RECENT_ALLOTMENT_GRAIN'] || ''),
    'min-h-[420px]':
      currentInvoice?.['IS_USAGE'] === true &&
      !['Annual', 'Monthly'].includes(currentInvoice?.['RECENT_ALLOTMENT_GRAIN'] || ''),
    'min-h-[300px]': currentInvoice?.['IS_USAGE'] === false,
  });
  return (
    <div>
      <h3 className="mt-4 font-bold">{CurrentCycleDates}</h3>

      <section className="flex min-h-[300px] mt-4">
        {PlanSummary}

        <div className={wigetLayoutClass}>
          {EmptyMetricsCard}
          {MAR}
          {Compute}
          {CloudService}
          {Storage}
        </div>
      </section>
      <MonthlyUsageChart companyPlanByMonth={companyPlanByMonth} currentInvoice={currentInvoice} />
    </div>
  );
}
