import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import TextInput from 'components/inputs/basic/TextInput/TextInput';
import ConfirmDeleteModal from 'components/layouts/containers/modals/ConfirmDeleteModal/ConfirmDeleteModal';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { DeleteConnectorState } from '../useDeleteConnectorState';

interface ConfirmDeleteConnectorModalProps extends DeleteConnectorState {}

export default function ConfirmDeleteConnectorModal(props: ConfirmDeleteConnectorModalProps) {
  const {
    onClose,
    onConfirm,
    loading,
    dbObjectsToDelete,
    deleteSyncedData,
    toggleDeleteSyncedData,
    confirmConnectorText,
    confirmDataText,
    setConfirmConnectorText,
    setConfirmDataText,
    deleteDataPhrase,
    schema,
    enableDeleteButton,
    setupState,
  } = props;

  const incomplete = setupState === 'incomplete';

  return (
    <ConfirmDeleteModal
      header="Are you absolutely sure?"
      confirmText="I understand the consequences, delete this connector"
      deleting={loading}
      onCancel={onClose}
      onConfirm={onConfirm}
      enableConfirm={enableDeleteButton}
    >
      <div className="w-[700px] p-4 text-normal">
        <p>
          This action <span className="font-bold">cannot</span> be undone.
          <br />
          <span>
            This will permanently delete connector <span className="font-bold">{schema}</span>.
          </span>
        </p>

        <div className="mt-4">
          <div>
            Type <span className="font-bold">{schema}</span> to confirm.
          </div>
          <TextInput
            name="confirmConnectorText"
            value={confirmConnectorText}
            onChange={(e) => setConfirmConnectorText(e.target.value)}
            className="mt-2"
          />
        </div>
        {!incomplete && (
          <div className="mt-4">
            <Checkbox
              name="deleteSyncedData"
              variant="blue_gray"
              label={`Also delete data synced by ${schema}.`}
              checked={deleteSyncedData}
              onChange={toggleDeleteSyncedData}
            />
          </div>
        )}
        {deleteSyncedData && (
          <div className="mt-4">
            <div>
              Type <span className="font-bold">{deleteDataPhrase}</span> to confirm.
            </div>
            <TextInput
              name="confirmDataText"
              value={confirmDataText}
              onChange={(e) => setConfirmDataText(e.target.value)}
              className="mt-2"
            />
          </div>
        )}

        {!deleteSyncedData && !incomplete && (
          <Alert variant="warning" className="mt-4">
            <div className="text-left justify-start">
              <div className="text-lg font-medium">
                The following synced data will remain in Mozart Data, but this data will not be updated
                again.
              </div>
              {loading ? (
                <CenteredSpinner containerMinHeight="100px" />
              ) : (
                <ul className="mt-2 ml-4">
                  {dbObjectsToDelete.map((dbobject) => (
                    <li key={dbobject.name}>
                      {dbobject.type}: {dbobject.name}
                    </li>
                  ))}
                </ul>
              )}
              <div className="mt-2">
                Transforms and reports that rely on up-to-date data from this connector will give answers
                based on out-of-date data, and it might not be obvious they are doing so.
              </div>
            </div>
          </Alert>
        )}
        {deleteSyncedData && !incomplete && (
          <Alert variant="warning" className="mt-4">
            <div className="text-left justify-start">
              <div className="text-lg font-medium">
                The following will be dropped from the data warehouse:
              </div>
              {loading ? (
                <CenteredSpinner containerMinHeight="100px" />
              ) : (
                <ul className="mt-2 ml-4">
                  {dbObjectsToDelete.map((dbobject) => (
                    <li key={dbobject.name}>
                      {dbobject.type}: {dbobject.name}
                    </li>
                  ))}
                </ul>
              )}
              <div className="mt-2">
                This will break any transforms or reports that read the data this connector has
                previously synced.
              </div>
            </div>
          </Alert>
        )}
      </div>
    </ConfirmDeleteModal>
  );
}
