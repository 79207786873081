import { useState } from 'react';

import { Link } from 'react-router-dom';

import cn from 'classnames';

import { AppConnector, UserProfile } from 'api/APITypes';
import ConnectorIcon from 'components/primitives/icons/ConnectorIcon/ConnectorIcon';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { SYNC_FREQUENCIES } from 'model_helpers/connectorHelper';

import history from '../../../appHistory';

import { conName } from '../ConnectorRegistry';
import { connectorStartsPaused, openConnectCard } from '../ConnectorUtils';

import BrokenSetup from './BrokenSetup';
import DashboardCol from './DashboardCol';
import LastSyncCol from './LastSyncCol';
import StatusCol from './StatusCol';

import s from './ListConnectors.module.css';

export interface ConnectorRowProps extends AppConnector {
  selected: boolean;
  userProfile: UserProfile;
  showDashboardColumn: boolean;
  dashboardAvailable: boolean;
  hasDashboard: boolean;
  rowClassName?: string;
}

export default function ConnectorRow(props: ConnectorRowProps) {
  const {
    id: uuid,
    fivetran_connector_id,
    service,
    schema,
    sync_frequency,
    anyStatusError,
    lastRun: { lastRunAt, lastRunFailed },
    setupState,
    syncState,
    selected,
    userProfile,
    showDashboardColumn,
    dashboardAvailable,
    hasDashboard,
    rowClassName,
  } = props;

  const [actionLoading, setActionLoading] = useState(false);
  const [actionError, setActionError] = useState('');

  const connectorPath = `/connectors/${uuid}`;

  const openMyConnectCard = async () => {
    openConnectCard({
      mozartConnectorId: uuid,
      fivetranConnectorId: fivetran_connector_id,
      startsPaused: connectorStartsPaused(service),
      setError: setActionError,
      setLoading: setActionLoading,
    });
  };

  const handleCompleteConnector = async () => {
    analytics.track('ListConnectors ContinueSetup');
    openMyConnectCard();
  };

  const handleFixConnector = async () => {
    analytics.track('ListConnectors FixSetup');
    openMyConnectCard();
  };

  const handleGoToConnector = (event: React.MouseEvent) => {
    analytics.track('ListConnectors GoToConnector');
    // If the user is holding the CMD key,
    // open the connector in a new tab.
    if (event.metaKey) {
      window.open(connectorPath);
    } else {
      history.push(connectorPath);
    }
  };

  return (
    <>
      <tr
        className={cn(
          {
            newListItem: selected,
          },
          rowClassName,
          'hover:cursor-pointer',
          'hover:bg-pri-gray-100',
        )}
        data-id={fivetran_connector_id}
        key="row"
        onClick={handleGoToConnector}
      >
        <td className="!px-2 !w-[80px] !min-w-[40px] !pl-[32px]">
          <ConnectorIcon service={service} size={24} />
        </td>
        <td className="!pl-4">
          <div className="text-base font-medium text-pri-gray-700">
            <Link
              to={connectorPath}
              data-track="ListConnectors OpenConnectorPage"
              className="hover:underline hover:text-sec-blue-gray-500"
            >
              {schema}
            </Link>
          </div>
          <div>{conName(service)}</div>
          {anyStatusError && (
            <BrokenSetup
              anyStatusError={anyStatusError}
              userProfile={userProfile}
              actionLoading={actionLoading}
              handleCompleteConnector={handleCompleteConnector}
              handleFixConnector={handleFixConnector}
            />
          )}
          {actionError && <Alert variant="error">{actionError}</Alert>}
        </td>
        <td>
          <div className={cn('f-row-y-center w-full', s.colVertCenter)}>
            <div>{SYNC_FREQUENCIES.find((f) => f.value === sync_frequency)?.label}</div>
          </div>
        </td>
        <td className="whitespace-nowrap">
          <div className={s.colVertCenter}>
            <LastSyncCol
              incomplete={setupState === 'incomplete'}
              lastRun={lastRunAt}
              lastRunFailed={lastRunFailed}
            />
          </div>
        </td>
        <td className="text-center w-[135px]">
          <div className={cn('f-end', s.colVertCenter)}>
            <StatusCol setup_state={setupState} sync_state={syncState} lastRunFailed={lastRunFailed} />
          </div>
        </td>
        {showDashboardColumn && (
          <td className="text-center">
            <div className={cn('f-center', s.colVertCenter)}>
              <DashboardCol
                available={dashboardAvailable}
                installed={hasDashboard}
                connectorID={uuid}
                fivetranConnectorID={fivetran_connector_id}
                userProfile={userProfile}
              />
            </div>
          </td>
        )}
      </tr>
    </>
  );
}
