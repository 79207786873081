import { CheckCircle } from 'react-bootstrap-icons';
import { useHistory } from 'react-router';

import Button from 'components/inputs/basic/Button/Button';
import IconButton from 'components/inputs/basic/Button/IconButton';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useBooleanFlag } from 'hooks/useFeatureFlags';

export type StatusWidgetType = 'connector' | 'transform' | 'alert';

export type GoToPageArgs = {
  seeFailed?: boolean;
  seeBroken?: boolean;
};

export type StatusContent = {
  title: string;
  detail: string;
  handleGoToPageClick: (args: GoToPageArgs) => void;
  failedButton: (count: number, onClick: (args: GoToPageArgs) => void) => JSX.Element;
  brokenButton?: (count: number, onClick: (args: GoToPageArgs) => void) => JSX.Element;
  empty: {
    label: string;
    handleEmptyClick: () => void;
    detail: JSX.Element;
  };
};

export interface StatusWidgetItemData {
  loaded: boolean;
  error: string;
  successCount: number;
  failedCount?: number;
  brokenCount?: number;
  totalCount: number;
  disableEmptyState: boolean;
}

export interface StatusWidgetItemProps extends StatusWidgetItemData {
  type: StatusWidgetType;
}

export default function StatusWidgetItem(props: StatusWidgetItemProps) {
  const {
    type,
    loaded,
    error,
    disableEmptyState,
    successCount,
    failedCount = 0,
    brokenCount = 0,
    totalCount,
  } = props;
  const history = useHistory();
  const isCreateDataAlertsEnabled = useBooleanFlag('create_data_alerts');
  const percentage = totalCount === 0 ? 0 : Math.floor((successCount / totalCount) * 100);

  const STATUS_CONTENT: { [type: string]: StatusContent } = {
    connector: {
      title: 'Connector Sync',
      detail: 'Enabled connectors synced successfully',
      handleGoToPageClick: ({ seeFailed = false, seeBroken = false }: GoToPageArgs) => {
        const filterProps =
          seeFailed || seeBroken ? { filters: { status: seeFailed ? 'error' : 'broken' } } : {};
        analytics.track('HomepageStatusWidget GoToConnectors', {
          ...filterProps,
          successCount,
          failedCount,
          brokenCount,
          totalCount,
          percentage,
        });

        if (seeFailed) {
          history.push(`/connectors${seeFailed ? '?status=error' : ''}`);
        } else if (seeBroken) {
          history.push(`/connectors${seeBroken ? '?status=broken' : ''}`);
        } else {
          history.push('/connectors');
        }
      },
      failedButton: (count: number, onClick: (args: GoToPageArgs) => void) => (
        <IconButton
          icon="XCircle"
          text={`${count} failed`}
          onClick={() => onClick({ seeFailed: true })}
          variant="lightDanger"
          size="small"
          className="f-row-y-center mt-2"
        />
      ),
      brokenButton: (count: number, onClick: (args: GoToPageArgs) => void) => (
        <IconButton
          icon="ExclamationCircle"
          text={`${count} broken`}
          onClick={() => onClick({ seeBroken: true })}
          variant="lightWarning"
          size="small"
          className="f-row-y-center mt-2"
        />
      ),
      empty: {
        label: 'Add Connector',
        handleEmptyClick: () => {
          analytics.track('HomepageStatusWidget AddConnector');
          history.push('/connectors/add');
        },
        detail: (
          <>
            The successful connector syncs will be displayed here after the first sync.{' '}
            <a
              href="https://help.mozartdata.com/docs/connectors"
              rel="noopener noreferrer"
              target="_blank"
              className="text-sec-blue-light-700"
            >
              Learn more about connectors
            </a>
            .
          </>
        ),
      },
    },
    transform: {
      title: 'Transform Run',
      detail: 'Scheduled transforms ran successfully',
      handleGoToPageClick: ({ seeFailed = false }: GoToPageArgs) => {
        const filters = seeFailed ? 'is:transform is:scheduled is:failed' : 'is:transform';
        const eventProps = { filters, successCount, failedCount, totalCount, percentage };
        analytics.track('HomepageStatusWidget GoToTransforms', eventProps);
        history.push(`/warehouse?filter=${filters}`);
      },
      failedButton: (count: number, onClick: (args: GoToPageArgs) => void) => (
        <IconButton
          icon="XCircle"
          text={`${count} failed`}
          onClick={() => onClick({ seeFailed: true })}
          variant="lightDanger"
          size="small"
          className="f-row-y-center mt-2"
        />
      ),
      empty: {
        label: 'Create Transform',
        handleEmptyClick: () => {
          analytics.track('HomepageStatusWidget CreateTransform');
          history.push('/transforms/add');
        },
        detail: (
          <>
            The successful transform runs will be displayed here after the first run.{' '}
            <a
              href="https://help.mozartdata.com/docs/transforms"
              rel="noopener noreferrer"
              target="_blank"
              className="text-sec-blue-light-700"
            >
              Learn more about transforms
            </a>
            .
          </>
        ),
      },
    },
    alert: {
      title: 'Data Alert',
      detail: 'Enabled alerts ran successfully',
      handleGoToPageClick: ({ seeFailed = false }: GoToPageArgs) => {
        const filters = seeFailed ? { filters: { status: 'failed' } } : {};
        analytics.track('HomepageStatusWidget GoToDataAlerts', {
          ...filters,
          successCount,
          failedCount,
          totalCount,
          percentage,
        });
        history.push(`/alerts${seeFailed ? '?status=failed' : ''}`);
      },
      failedButton: (count: number, onClick: (args: GoToPageArgs) => void) => (
        <IconButton
          icon="ExclamationCircle"
          text={`${count} triggered`}
          onClick={() => onClick({ seeFailed: true })}
          variant="lightDanger"
          size="small"
          className="f-row-y-center mt-2"
        />
      ),
      empty: {
        label: 'Create Data Alert',
        handleEmptyClick: () => {
          analytics.track('HomepageStatusWidget CreateDataAlert');
          history.push('/alerts', { createAlert: true, eventLocation: 'HomepageStatusWidget' });
        },
        detail: (
          <>
            The successful data alerts will be displayed here after the first run.{' '}
            <a
              href="https://help.mozartdata.com/docs/data-alerts"
              rel="noopener noreferrer"
              target="_blank"
              className="text-sec-blue-light-700"
            >
              Learn more about data alerts
            </a>
            .
          </>
        ),
      },
    },
  };

  const {
    title,
    detail,
    handleGoToPageClick,
    failedButton,
    brokenButton = () => null,
    empty: { label: emptyButtonLabel, handleEmptyClick, detail: emptyDetail },
  } = STATUS_CONTENT[type];

  let content = null;
  if (!loaded) {
    content = <CenteredSpinner type="circle" spinnerSize="1.5rem" containerSize="3rem" />;
  } else if (error) {
    // TODO(homepage-v1): See if we can add a refresh button to retry fetching connector/transform/alerts data
    content = (
      <div>
        <Alert variant="error">
          There was an error loading {type}s data. Refresh the page to try again.
        </Alert>
      </div>
    );
  } else if (totalCount === 0) {
    content = (
      <>
        {type !== 'alert' || isCreateDataAlertsEnabled ? (
          <Button
            onClick={handleEmptyClick}
            disabled={disableEmptyState}
            variant="lightAction"
            className="mb-2"
          >
            {emptyButtonLabel}
          </Button>
        ) : null}
        <p className="text-sm text-pri-gray-500 mb-2">{emptyDetail}</p>
      </>
    );
  } else {
    content = (
      <>
        <div className="flex items-center mb-2">
          <CheckCircle size="28" className="text-pri-success-600" />
          <div className="text-4xl font-medium text-pri-success-600 ml-2">{percentage}%</div>
        </div>
        <div className="text-sm text-pri-gray-500">{detail}</div>
        <div className="f-row-y-center gap-x-2 flex-wrap">
          {failedCount > 0 && failedButton(failedCount, handleGoToPageClick)}
          {brokenCount > 0 && brokenButton(brokenCount, handleGoToPageClick)}
          {failedCount === 0 && brokenCount === 0 && (
            <IconButton
              icon="ChevronRight"
              iconLast
              text={`View ${type}s`}
              onClick={() => handleGoToPageClick({})}
              variant="lightDullTransparent"
              size="small"
              className="f-row-y-center mt-2 uppercase"
            />
          )}
        </div>
      </>
    );
  }

  return (
    <div>
      <h2 className="text-md font-medium mb-2">{title}</h2>
      {content}
    </div>
  );
}
