import React, { ForwardedRef } from 'react';

import { ChevronDown } from 'react-bootstrap-icons';

import cn from 'classnames';

import { Menu } from '@headlessui/react';

import { ButtonVariant, CommonButtonProps, getEffectiveClass } from './Button';
import ButtonSpinner from './ButtonSpinner';

export const ENABLED_CHEVRON_COLOR: { [key in ButtonVariant]: string } = {
  lightAction: 'var(--purple)',
  darkAction: 'var(--silver)',
  lightDullAction: 'var(--sec-blue-gray-500)',
  darkDullAction: 'var(--white)',
  save: 'var(--white)',
  lightWarning: 'var(--pri-warning-700)',
  lightDanger: 'var(--pri-error-700)',
  darkDanger: 'var(--silver)',
  lightTransparent: 'var(--purple)',
  lightDullTransparent: 'var(--sec-blue-gray-500)',
  saveTransparent: 'var(--pri-success-700)',
  warningTransparent: 'var(--pri-warning-700)',
  dangerTransparent: 'var(--pri-error-700)',
  lightDullTab: 'var(--pri-gray-500)',
};

export const DISABLED_CHEVRON_COLOR: { [key in ButtonVariant]: string } = {
  lightAction: 'var(--pri-gray-400)',
  darkAction: 'var(--pri-gray-100)',
  lightDullAction: 'var(--pri-gray-400)',
  darkDullAction: 'var(--white)',
  save: 'var(--white)',
  lightWarning: 'var(--pri-warning-400)',
  lightDanger: 'var(--pri-gray-400)',
  darkDanger: 'var(--pri-gray-100)',
  lightTransparent: 'var(--pri-gray-300)',
  lightDullTransparent: 'var(--pri-gray-300)',
  saveTransparent: 'var(--pri-success-300)',
  warningTransparent: 'var(--pri-warning-300)',
  dangerTransparent: 'var(--pri-gray-300)',
  lightDullTab: 'var(--pri-gray-300)',
};

export const ITEM_TEXT_COLOR: { [key in ButtonVariant]: string } = {
  lightAction: 'text-purple',
  darkAction: 'text-purple',
  lightDullAction: 'text-sec-blue-gray-500',
  darkDullAction: 'text-sec-blue-gray-500',
  save: 'text-pri-gray-700',
  lightWarning: 'text-pri-warning-700',
  lightDanger: 'text-pri-gray-700',
  darkDanger: 'text-pri-gray-700',
  lightTransparent: 'text-purple',
  lightDullTransparent: 'text-sec-blue-gray-500',
  saveTransparent: 'text-pri-success-700',
  warningTransparent: 'text-pri-warning-700',
  dangerTransparent: 'text-pri-error-700',
  lightDullTab: 'text-pri-gray-500',
};

const DROPDOWN_WIDTH = {
  large: 'w-[42px]',
  form: 'w-[42px]',
  small: 'w-[28px]',
  field: 'w-[34px]',
};

export interface ButtonMenuProps
  extends CommonButtonProps,
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  dropdownOptions: { label: string; onClick: () => void; disabled?: boolean }[];
}

const ButtonMenu = React.forwardRef<HTMLDivElement, ButtonMenuProps>(
  (props: ButtonMenuProps, forwardedRef: ForwardedRef<HTMLDivElement>) => {
    let {
      size,
      variant,
      spinning,
      disabled,
      dropdownOptions,
      forceHover,
      forceFocus,
      forceActive,
      className,
      ...buttonProps
    } = props;

    // Fix default values if they are undefined.
    size = size || 'large';
    const actualVariant = variant || 'lightAction';
    spinning = spinning === true;
    disabled = disabled === true;

    // Pick colors based on variant and colorState
    const variantAndSizeClasses = getEffectiveClass(
      size,
      actualVariant,
      spinning,
      disabled,
      false,
      props,
    );

    let dropdownButtonClass = cn(
      variantAndSizeClasses,
      DROPDOWN_WIDTH[size],
      'f-center',
      'h-full',
      'shrink-0',
    );

    // The dropdown button must take the entire size of the component. Remove padding.
    if (!spinning) {
      dropdownButtonClass += ' !p-0';
    }

    const dropdownItemsClass = `
    absolute mt-2 top-full right-0 origin-top-right 
    bg-white divide-y divide-gray-100 rounded-md 
    shadow-lg ring-1 ring-black ring-opacity-5 
    focus:outline-none
    z-50`;

    const baseDropdownItemClass = `
      group flex rounded-md items-center
      w-full px-2 py-2 text-sm whitespace-nowrap`;

    const chevronColor = disabled
      ? DISABLED_CHEVRON_COLOR[actualVariant]
      : ENABLED_CHEVRON_COLOR[actualVariant];
    const chevronSize = size === 'large' ? 16 : 14;

    return (
      <div ref={forwardedRef} className={dropdownButtonClass} {...buttonProps}>
        {spinning && <ButtonSpinner size={size} />}
        {!spinning && (
          <Menu as="div" className="w-full h-full f-center">
            <Menu.Button disabled={disabled} className="w-full h-full f-center">
              <ChevronDown color={chevronColor} size={chevronSize} />
            </Menu.Button>
            <Menu.Items className={dropdownItemsClass}>
              <div className="p-1">
                {dropdownOptions.map((o) => {
                  const textColorClassName = o.disabled
                    ? 'text-pri-gray-300'
                    : ITEM_TEXT_COLOR[actualVariant];
                  return (
                    <Menu.Item key={o.label}>
                      <button
                        className={cn(baseDropdownItemClass, textColorClassName)}
                        onClick={o.onClick}
                        disabled={o.disabled}
                      >
                        {o.label}
                      </button>
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Menu>
        )}
      </div>
    );
  },
);

export default ButtonMenu;
