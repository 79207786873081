import React, { useState } from 'react';

import cn from 'classnames';
import deepEqual from 'fast-deep-equal';
import _ from 'lodash';

import { SaveableTransformAndAncestorProps, Transform } from 'api/APITypes';
import { CreateAsType, ScheduleMode } from 'api/tableAPI';
import Button from 'components/inputs/basic/Button/Button';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useDatabaseAccount } from 'context/AuthContext';
import { DependencyEdge } from 'pages/tables/ShowTable/PipelineTab/PipelineEditor/PipelineEditor';
import { TransformTable } from 'pages/tables/ShowTable/ShowTable';
import { capitalize } from 'utils/String';

import CreateAsTab from './CreateAsTab';
import IncrementalTab from './IncrementalTab';
import ScheduleTab from './ScheduleTab';

export interface DependenciesById {
  [key: string]: DependencyEdge;
}

export interface SetRunOptionProps {
  table: TransformTable;
  incrementalUpdateKeys: string[] | null; // Names of columns produced by saved SQL
  validSql: boolean; // If the currently saved sql is valid and can get update keys
  loadingUpdateKeys: boolean;
  vertexDependencies: DependencyEdge[];
  onSaveTransformAndAncestors: (
    transformId: string, // The id of the transform to edit
    transformAndAncestorSaveProps: SaveableTransformAndAncestorProps,
  ) => Promise<{ transform: Transform; ancestors: DependencyEdge[] }>;
}

type TabTypes = 'schedule' | 'incremental' | 'create_as';

export interface UnsavedTransform {
  sql: string;
  scheduled: boolean;
  schedule_mode: ScheduleMode;
  schedule: string;
  incremental: boolean;
  incremental_update_key: string | null;
  auto_rebuild_on_change: boolean;
  create_as: CreateAsType;
  primary_key: string | null;
  cluster_by: string | null;
  statement_timeout_seconds: number | null;
}

interface RunOptionsModalProps extends SetRunOptionProps {
  onClose(): void;
}

export default function RunOptionsModal(props: RunOptionsModalProps) {
  const {
    table,
    incrementalUpdateKeys,
    validSql,
    loadingUpdateKeys,
    vertexDependencies,
    onClose,
    onSaveTransformAndAncestors,
  } = props;
  const transform = table.transform;
  const [tab, setTab] = useState<TabTypes>('schedule');
  const [unsavedTransform, setUnsavedTransform] = useState<UnsavedTransform>({
    sql: transform.sql,
    scheduled: transform.scheduled,
    schedule_mode: transform.schedule_mode,
    schedule: transform.schedule,
    incremental: transform.incremental,
    incremental_update_key: transform.incremental_update_key,
    auto_rebuild_on_change: transform.auto_rebuild_on_change,
    create_as: transform.create_as,
    primary_key: transform.primary_key,
    cluster_by: transform.cluster_by,
    statement_timeout_seconds: transform.statement_timeout_seconds,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [saving, setSaving] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  const databaseType = useDatabaseAccount().type;

  // Filter out self-references
  const vertexDependenciesById = _.keyBy(
    vertexDependencies.filter((d) => d.source !== d.destination),
    'id',
  );

  const [unsavedDependencies, setUnsavedDependencies] = useState<DependenciesById>(
    _.cloneDeep(vertexDependenciesById),
  );

  const handleSelectTab = (targetTab: TabTypes) => {
    analytics.track(`RunOptionsModal SelectTab${capitalize(targetTab)}`);
    setTab(targetTab);
  };

  const formatCommaSeparatedKey = (key: string | undefined | null) => {
    return key
      ? key
          .split(',')
          .map((k) => k.trim().toUpperCase())
          .filter((k) => k.length > 0)
          .join(',')
      : key;
  };

  const handleSave = () => {
    setSaving(true);
    // Find the values that have changed and only call save with those
    let transformToSave: SaveableTransformAndAncestorProps = _.pickBy(unsavedTransform, (v, k) => {
      const typedKey = k as keyof Omit<SaveableTransformAndAncestorProps, 'ancestors'>;
      return v !== transform[typedKey];
    });

    transformToSave.primary_key = formatCommaSeparatedKey(transformToSave.primary_key);
    transformToSave.cluster_by = formatCommaSeparatedKey(transformToSave.cluster_by);

    // We also want this same click to save dependencies
    if (!deepEqual(vertexDependenciesById, unsavedDependencies)) {
      const ancestors = Object.values(unsavedDependencies)
        .filter((d) => d.is_scheduling_dependency === true)
        .map((d) => d.id);
      transformToSave = { ...transformToSave, ancestors: ancestors };
    }

    onSaveTransformAndAncestors(table.transform.id, transformToSave)
      .then(() => {
        onClose();
        analytics.track('RunOptionsModal SaveTransformAndAncestors', transformToSave);
      })
      .catch((e) => {
        if (e.response?.data?.non_field_errors) {
          setErrorMessage(e.response?.data?.non_field_errors);
        } else if (e.response?.data) {
          setErrorMessage(e.response.data);
        } else {
          setErrorMessage('There was a problem saving.');
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const baseTabClass = 'font-medium text-sm p-2 cursor-pointer';
  const unselectedTabClass = cn(baseTabClass, 'text-pri-gray-400 hover:text-pri-gray-500');
  const selectedTabClass = cn(
    baseTabClass,
    'text-sec-blue-gray-500 border-b-2 border-sec-blue-gray-500',
  );
  const modalHeader = (
    <div className="f-row-y-center">
      <div
        className={tab === 'schedule' ? selectedTabClass : unselectedTabClass}
        onClick={() => handleSelectTab('schedule')}
      >
        Schedule Settings
      </div>
      <div
        className={tab === 'create_as' ? selectedTabClass : unselectedTabClass}
        onClick={() => handleSelectTab('create_as')}
      >
        Create As Settings
      </div>
      {databaseType !== 'bigquery' && (
        <div
          className={tab === 'incremental' ? selectedTabClass : unselectedTabClass}
          onClick={() => handleSelectTab('incremental')}
        >
          Incremental Settings
        </div>
      )}
    </div>
  );
  const modalFooter = (
    <div className="flex justify-end items-center">
      <Button variant="darkDanger" onClick={onClose} size="small">
        Cancel
      </Button>
      <Button
        variant="save"
        onClick={handleSave}
        size="small"
        spinning={saving}
        className="ml-2"
        disabled={disableSave}
      >
        Save
      </Button>
    </div>
  );
  return (
    <Modal
      header={modalHeader}
      footer={modalFooter}
      onClose={onClose}
      cancelButton={true}
      containerClass="w-[800px] h-[600px] m-[50px]"
    >
      <div className="h-full flex flex-col p-6">
        {errorMessage && (
          <Alert variant="error" className="mb-4">
            {errorMessage}
          </Alert>
        )}
        {tab === 'schedule' && (
          <ScheduleTab
            table={table}
            unsavedTransform={unsavedTransform}
            unsavedDependencies={unsavedDependencies}
            setUnsavedDependencies={setUnsavedDependencies}
            setUnsavedTransform={setUnsavedTransform}
          />
        )}
        {tab === 'incremental' && (
          <IncrementalTab
            incrementalUpdateKeys={incrementalUpdateKeys}
            validSql={validSql}
            loadingUpdateKeys={loadingUpdateKeys}
            unsavedTransform={unsavedTransform}
            setUnsavedTransform={setUnsavedTransform}
          />
        )}
        {tab === 'create_as' && (
          <CreateAsTab
            table={table}
            unsavedTransform={unsavedTransform}
            validSql={validSql}
            incrementalUpdateKeys={incrementalUpdateKeys}
            setUnsavedTransform={setUnsavedTransform}
            setDisableSave={setDisableSave}
          />
        )}
      </div>
    </Modal>
  );
}
