import React, { useRef } from 'react';

import s from './ColumnHandle.module.css';

export interface ColumnHandleProps {
  onMouseDown: (
    event: React.MouseEvent<HTMLDivElement>,
    columnHandleRef: React.RefObject<HTMLDivElement>,
  ) => void;
}

export default function ColumnHandle(props: ColumnHandleProps) {
  const { onMouseDown } = props;
  const myRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={myRef} onMouseDown={(e) => onMouseDown(e, myRef)} className={s.columnHandle}>
      <div className={s.visibleHandle}></div>
    </div>
  );
}
