import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import API from 'api/API';
import { AppConnector } from 'api/APITypes';

import { fivetranConnectors } from '../ConnectorRegistry';

export interface DeleteConnectorState {
  showModal: boolean;
  onOpen: () => void;
  onClose: () => void;
  onConfirm: () => void;
  error: string;
  loading: boolean;
  dbObjectsToDelete: DBObject[];
  loadingDbObjectsToDelete: boolean;
  deleteSyncedData: boolean;
  toggleDeleteSyncedData: () => void;
  confirmConnectorText: string;
  confirmDataText: string;
  setConfirmConnectorText: (text: string) => void;
  setConfirmDataText: (text: string) => void;
  deleteDataPhrase: string;
  schema: string;
  enableDeleteButton: boolean;
  setupState: string;
}

export interface DBObject {
  type: string;
  name: string;
}

export default function useDeleteConnectorState(
  connector: AppConnector,
  setPollingEnabled: (enabled: boolean) => void,
): DeleteConnectorState {
  const { service, fivetran_connector_id, schema, setupState } = connector;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [error, setError] = useState('');
  const [loadingDeleteConnector, setLoadingDeleteConnector] = useState(false);

  const [dbObjectsToDelete, setDbObjectsToDelete] = useState<DBObject[]>([]);
  const [loadingDbObjectsToDelete, setLoadingDbObjectsToDelete] = useState(false);
  const [deleteSyncedData, setDeleteSyncedData] = useState(false);
  const [confirmConnectorText, setConfirmConnectorText] = useState('');
  const [confirmDataText, setConfirmDataText] = useState('');

  const history = useHistory();

  const deleteDataPhrase = 'delete my data';

  let enableDeleteButton =
    confirmConnectorText === schema && !loadingDeleteConnector && !loadingDbObjectsToDelete;
  if (enableDeleteButton && deleteSyncedData) {
    enableDeleteButton = enableDeleteButton && confirmDataText === deleteDataPhrase;
  }

  const getDbObjectsToDelete = async (fivetran_connector_id: string, service: string) => {
    const api = new API();
    setLoadingDbObjectsToDelete(true);
    let postData = {
      sync_scope: fivetranConnectors[service].syncScope,
    };

    try {
      const response = await api.post(
        `api/fivetran/connector/${fivetran_connector_id}/dbobjects`,
        postData,
      );
      setDbObjectsToDelete(response.data);
    } catch (error) {
      // We really should display the error to the user, but this UI doesn't support that.
      // TODO: ^ old message, might fix later -MSM 2024-01-23
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setLoadingDbObjectsToDelete(false);
    }
  };

  useEffect(() => {
    getDbObjectsToDelete(fivetran_connector_id, service);
  }, [fivetran_connector_id, service]);

  const onOpenDeleteModal = () => {
    setShowDeleteModal(true);
    analytics.track('ShowConnectorSummaryTab OpenDeleteModal');
    // Stop polling so we don't reload non-existent connector but instead kick them back to connectors page
    setPollingEnabled(false);
  };

  const onCloseDeleteModal = () => {
    setShowDeleteModal(false);
    analytics.track('ShowConnectorSummaryTab CloseDeleteModal');
    setPollingEnabled(true);
  };

  const confirmDelete = async (deleteSyncedData: boolean) => {
    const api = new API();
    setError('');
    setLoadingDeleteConnector(true);

    let postData = {
      delete_synced_data: deleteSyncedData,
      sync_scope: fivetranConnectors[service].syncScope,
    };

    try {
      await api.post(`api/fivetran/connector/${fivetran_connector_id}/delete`, postData);
      analytics.track('ShowConnectorSummaryTab DeleteConnector');
      history.push('/connectors');
    } catch (error) {
      setError('There was an error deleting your connector.');
    } finally {
      setShowDeleteModal(false);
      setLoadingDeleteConnector(false);
    }
  };

  return {
    showModal: showDeleteModal,
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
    onConfirm: () => confirmDelete(deleteSyncedData),
    error: error,
    loading: loadingDeleteConnector,
    dbObjectsToDelete,
    loadingDbObjectsToDelete,
    deleteSyncedData,
    toggleDeleteSyncedData: () => setDeleteSyncedData((prev) => !prev),
    confirmConnectorText,
    confirmDataText,
    setConfirmConnectorText,
    setConfirmDataText,
    deleteDataPhrase,
    schema,
    enableDeleteButton,
    setupState,
  };
}
