import React, { useState, ReactNode, Dispatch, SetStateAction, createContext } from 'react';

export interface AIPermissionModalContextInterface {
  showAIPermissionModal: boolean;
  setShowAIPermissionModal: Dispatch<SetStateAction<boolean>>;
}

export const AIPermissionModalContext = createContext<AIPermissionModalContextInterface>({
  showAIPermissionModal: false,
  setShowAIPermissionModal: () => {},
});

export const AIPermissionModalProvider = ({ children }: { children: ReactNode }) => {
  const [showAIPermissionModal, setShowAIPermissionModal] = useState(false);

  return (
    <AIPermissionModalContext.Provider value={{ showAIPermissionModal, setShowAIPermissionModal }}>
      {children}
    </AIPermissionModalContext.Provider>
  );
};
