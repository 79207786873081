/**
 * Adds a popper wrapper around an arbitrary icon component.
 */
import React, { HTMLAttributes } from 'react';

import Popover from 'components/overlay/Popover/Popover';
import PopperTrigger from 'components/overlay/PopperTrigger/PopperTrigger';
import { Placement } from 'components/overlay/PopperTrigger/usePopperRefs';

import s from './InfoIcon.module.css';

export interface BaseInfoIconProps {
  title?: string;
  content: React.ReactNode;
  placement?: Placement;
  icon: React.ReactElement;
  containerClass?: string;
  popoverProps?: HTMLAttributes<HTMLDivElement>;
}

export default function BaseInfoIcon(props: BaseInfoIconProps) {
  const { title, content, containerClass, popoverProps } = props;
  let { placement, icon } = props;
  placement = placement || 'right';

  return (
    <PopperTrigger
      placement={placement}
      triggers="hover_click_lock"
      popoverProps={popoverProps}
      renderPopper={(popperProps) => {
        return <Popover title={title} content={content} {...popperProps} />;
      }}
    >
      <div className={containerClass}>
        <div className={s.inlineIconBox}>{icon}</div>
      </div>
    </PopperTrigger>
  );
}
