/*
Utilities for converting all caps names to the more readable lowercase form.

Mozart has taken the stance that identifier names should be lowercase to 
make them more readable, easier to type, and generally more friendly to humans.
This is consistent with Postgres, but not Snowflake or Oracle.
As a general rule, every database handles default letter case differently and
what happens can depend on the DB settings and the OS file system used.
Snowflake defaults identifiers names not wrapped in double quotes to be uppercase,
the behavior specified by the SQL standard.
Historically, some backend APIs would return table or column names in lowercase.
As of 11/23/2021, all used backend APIs have been changed to return the exact
case of the identifier in Snowflake and changing case to be human friendly
is exclusively the domain of the frontend.
This makes the API less prone to errors.
The frontend uses this library to convert names displayed to humans back to lower
case when doing so will not cause errors in SQL statements.
*/
import { DatabaseType } from 'api/APITypes';
import { isUpperCase } from 'utils/String';

import { reservedWords as bigQueryReservedWords } from './BigQueryReservedWords';
import { reservedWordDic } from './SnowflakeReservedWords';

// Matches any table-like object
interface TableKey {
  schema: string;
  name: string;
}

// All the types you can run convert on
type Convertable = TableKey | string;

/*******************************************************************************
 * Public API:
 * Utilities to Convert identifiers or table objects from ALL_CAPS to human readable from.
 * Examples:
 *   1. Lowercases names: TABLE_NAME => table_name
 *   2. Quotes reserved words: SELECT => "SELECT"
 *   3. Quotes mixed case: MixedCase => "MixedCase"
 *   4. SALESFORCE.TABLE_NAME => salesforce.table_name
 *   5. SALESFORCE.SELECT => salesforce."SELECT"
 ******************************************************************************/
export function convertSchemaName(convertable: Convertable, databaseType: DatabaseType) {
  const schema = typeof convertable === 'object' ? convertable.schema : convertable;
  return convertIdentifier(schema, databaseType);
}

export function convertTableName(convertable: Convertable, databaseType: DatabaseType) {
  const name = typeof convertable === 'object' ? convertable.name : convertable;
  return convertIdentifier(name, databaseType);
}

export function convertFullName(convertable: Convertable, databaseType: DatabaseType) {
  const tableObject = typeof convertable === 'object' ? convertable : fullNameToTableKey(convertable);
  return (
    convertSchemaName(tableObject, databaseType) + '.' + convertTableName(tableObject, databaseType)
  );
}

export function convertColumnName(column: string, databaseType: DatabaseType) {
  return convertIdentifier(column, databaseType);
}

export function snapshotFullName(table: TableKey) {
  // This function is run on already converted table schemas and names, so no need to convert again
  return `snapshots.${table.schema}__${table.name}`;
}

// This function does its best, but if the user's schema has a double underscore, it won't work
export function getSnapshotsSourcesFullName(snapshotTableName: string) {
  const splitName = snapshotTableName.replace('snapshots.', '').split('__');
  return `${splitName[0]}.${splitName.splice(1).join('__')}`;
}

export function convertIdentifier(name: string, databaseType: DatabaseType) {
  return databaseType === 'bigquery'
    ? convertIdentifierBigQuery(name)
    : convertIdentifierSnowflake(name);
}

function convertIdentifierSnowflake(name: string) {
  // Just return as is if already wrapped in quotes
  if (isQuoted(name)) {
    return name;
  }
  // Wrap it in quotes if it is lower case or mixed case.
  if (!isUpperCase(name)) {
    return `"${name}"`;
  }
  // Convert upper case to lower case
  const lowerName = name.toLowerCase();
  // If it's a reserved word, use the original case and wrap it in quotes.
  const escapedName = reservedWordDic[lowerName] ? `"${name}"` : lowerName;
  return escapedName;
}

function convertIdentifierBigQuery(name: string) {
  // https://cloud.google.com/bigquery/docs/reference/standard-sql/lexical#identifiers
  // starts with a letter or underscore, and subsequent characters can be letters, digits, or underscores
  const canBeUnbackticked = /^[A-Za-z_][A-Za-z0-9_]*$/;
  return !bigQueryReservedWords.has(name.toLowerCase()) && canBeUnbackticked.test(name)
    ? name
    : `\`${name}\``;
}

export function lowerIdentifier(name: string) {
  return removeQuotes(name).toLowerCase();
}

/*******************************************************************************
 * Miscellaneous
 ******************************************************************************/
export function fullNameToTableKey(fullName: string): TableKey {
  const parts = fullName.split('.');
  let schema = 'ERROR';
  let name = 'ERROR';
  // CASE: schema.table
  if (parts.length === 2) {
    schema = parts[0];
    name = parts[1];
  }
  // CASE: database.schema.table
  else if (parts.length === 3) {
    schema = parts[1];
    name = parts[2];
  }
  return { schema, name };
}

export function isQuoted(name: string) {
  return name.length >= 2 && name.slice(0, 1) === '"' && name.slice(-1) === '"';
}

function removeQuotes(name: string) {
  return isQuoted(name) ? name.slice(1, -1) : name;
}

// This is used to convert saved query names, which can include things like
// spaces and uppercase letters to names that can be used as transform name
export function convertUnsafeNametoSafeIndentifier(rawName: string) {
  return rawName.toLowerCase().replaceAll(' ', '_').replaceAll('-', '_').replaceAll(/\W+/g, '');
}

export function isFivetranColumn(column: string) {
  return column.startsWith('_fivetran');
}
