import { useEffect, useState } from 'react';

import { Redirect, RouteComponentProps } from 'react-router-dom';

import { useUserProfile } from 'context/AuthContext';
import { GCPMARKETPLACE_MARKETING_REFERRAL } from 'pages/signup/SignupGCPMarketplace';
import { THOUGHTSPOT_MARKETING_REFERRAL } from 'pages/signup/SignupThoughtspot';

import history from '../../../appHistory';

import useSetupWarehouse from '../useSetupWarehouse';

import SelectWarehouseView from './SelectWarehouseView';

interface MatchParams {}
interface SelectWarehouseControllerProps extends RouteComponentProps<MatchParams> {}

const SelectWarehouseController = (props: SelectWarehouseControllerProps) => {
  const [showUnmanagedOptions, setShowUnmanagedOptions] = useState(false);
  const { userProfile } = useUserProfile();
  const fromThoughtSpot =
    userProfile.company.marketing_referral_at_signup === THOUGHTSPOT_MARKETING_REFERRAL;
  const fromGcpMarketplace =
    userProfile.company.marketing_referral_at_signup === GCPMARKETPLACE_MARKETING_REFERRAL;
  const eventLocation = fromThoughtSpot
    ? 'CreateSnowflakeFromThoughtspotSignup'
    : 'CreateSnowflakeForUser';
  const { checkIsLoading, startSetupWarehouse, setupInProgress, setupError } = useSetupWarehouse(
    'managed',
    eventLocation,
  );

  // Make sure we don't attempt an autosetup more than once.
  const [attemptedAutoSetUpWarehouse, setAttemptedAutoSetUpWarehouse] = useState(false);

  // If the user came from the ThoughtSpot signup form,
  // automatically pick Snowflake for them.
  // Informal Convention:
  // If the `marketing_referral_at_signup` is all caps SNAKE_CASE, it's a special frontend code.
  // If it's lower case or mixed case, it's user input of some sort.
  useEffect(() => {
    // Logially, we'd prefer this to be after the <Redirect/>.
    // The rules of hooks forces us to put this first and add this, check inside the hook.
    if (!userProfile.company.database_account) {
      if (!attemptedAutoSetUpWarehouse && fromThoughtSpot) {
        setAttemptedAutoSetUpWarehouse(true);
        startSetupWarehouse({ warehouse_type: 'managed' });
      } else if (!attemptedAutoSetUpWarehouse && fromGcpMarketplace) {
        history.push('/setup-bigquery');
      }
    }
  }, [
    userProfile.company.database_account,
    fromThoughtSpot,
    fromGcpMarketplace,
    attemptedAutoSetUpWarehouse,
    startSetupWarehouse,
  ]);

  // Re-direct if user already has a database account
  if (userProfile.company.database_account) return <Redirect to="/" />;

  const handleGoToUnmanagedOptions = () => {
    analytics.track(`Signup GoToUnmanagedOptions`);
    setShowUnmanagedOptions(true);
  };

  const handleGoBackToManagedOption = () => {
    analytics.track(`Signup GoBackToManagedOption`);
    setShowUnmanagedOptions(false);
  };

  return (
    <SelectWarehouseView
      showUnmanagedOptions={showUnmanagedOptions}
      checkIsLoading={checkIsLoading}
      setupInProgress={setupInProgress}
      setupError={setupError}
      startSetupSnowflake={startSetupWarehouse}
      goToUnmanagedOptions={handleGoToUnmanagedOptions}
      goBackToManagedOption={handleGoBackToManagedOption}
    />
  );
};

export default SelectWarehouseController;
