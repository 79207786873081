import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function CompanyRequireSSOInfoIcon() {
  const content = (
    <div>
      <div>
        <strong>Require that all active users in your company use SSO to login.</strong>
      </div>
      <div>To enable Okta, you must first configure it on the "Integrations" tab.</div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} containerClass="ml-1" popoverProps={popoverProps} />;
}
