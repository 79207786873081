/**
 * We are making an attempt to have a homgenized notion of "scheduled" or "last run status"
 * across different model types, notably connectors and transforms, so that we can perform
 * the same operations on a list of mixed types or in the case of the Warehouse
 * tables created by mixed types.
 *
 * This will be useful when sorting table rows created by different types in the Warehouse,
 * creating <StatusIcons />, or any other concept that is roughly the equivalent in connectors or
 * transforms but expressed by the different APIs with slightly different type definitions.
 *
 * This may be foolhardy. Only time will tell.
 *
 * As of 05/23/23 this file is adopting a sort philosophy of "NULL-like items LAST"
 */

/**
 * Any object that creates a table(Transform, Connector, Snapshot, DBT Config, CSVUpload, etc...)
 * should have have an implementation of this class.
 *
 * It is expected these implementations will return values
 * consistent with the constants in the second half of this file.
 */
export default abstract class AbstractTableSorter<TableCreator> {
  // Returns the numeric value to use to sort the tables's schedule in minutes.
  // The closer to negative infinity the more frequent the sort value.
  abstract schedule(creator: TableCreator): number;
  // Returns how long ago a table updated in milliseconds.
  // The closer to zero the more recent the sort value.
  abstract lastRunAgo(creator: TableCreator): number;
  // Converts run status into sortable numberic code.
  abstract runStatus(creator: TableCreator): number;
}

/**
 * SCHEDULE_FREQUENCY is usually measured in minutes.
 * These are the constant values for special cases that are sorted into the minute values.
 */
export const SCHEDULE_FREQUENCY = {
  // AFTER_ANY and AFTER_ALL are special cases we are sorting before time intervals.
  AFTER_ANY: -2, // AFTER_ANY is a proposed schedule option that was never implemented.
  AFTER_ALL: -1,
  // Minute values or cron values that can easily be converted to minutes
  // are numeric values between between zero and UNKNOWN_CRON.
  // UNKNOWN_CRON is a special case for cron strings that are too obnoxious to easily convert to a minute value.
  UNKNOWN_CRON: Number.MAX_SAFE_INTEGER - 2,
  PAUSED_OR_NOT_SCHEDULED: Number.MAX_SAFE_INTEGER - 1,
  NOT_APPLICABLE: Number.MAX_SAFE_INTEGER,
};

/**
 * LAST_RUN_AGO is usually measured in milliseconds between last run time and right now.
 * These are the constant values for special cases that are sorted into the millisecond values.
 */
export const LAST_RUN_AGO = {
  // Known last run ago times would sort here.
  // They would be millisecond values less than NEVER_RAN.
  NEVER_RAN: Number.MAX_SAFE_INTEGER - 1,
  NOT_APPLICABLE: Number.MAX_SAFE_INTEGER,
};

// Connector and Transform runs are mapped onto these generic run status.
export const LAST_RUN_STATUS = {
  // Statuses with known fates
  success: 1,
  error: 2,
  warning: 3,
  broken: 4,
  incomplete: 5,
  // Statuses in with unresolved fates
  syncing: 6,
  waiting: 7,
  paused: 8,
  NA: Number.MAX_SAFE_INTEGER,
};
