import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function ScheduleInfoIcon(props: {}) {
  const content = (
    <div>
      <div>Scheduling automatically runs a transform on the specified schedule.</div>
      <div className="mt-1">Every run will overwrite the previous run's results.</div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px' } };
  return <InfoIcon content={content} popoverProps={popoverProps} />;
}
