import React, { useEffect } from 'react';

import Button from 'components/inputs/basic/Button/Button';
import IconButton from 'components/inputs/basic/Button/IconButton';

export interface ClosableBannerProps {
  eventLocation: string;
  closeText?: string;
  onClose(): void;
  children: React.ReactNode;
}

export const ClosableBanner = (props: ClosableBannerProps) => {
  const { eventLocation, closeText, onClose, children } = props;

  // Log that the user has seen this promotion ONLY when the component first mounts.
  useEffect(
    () => {
      // analytics is not defined in Storybook
      if (window.analytics) {
        window.analytics.track(`${eventLocation} LogImpression`);
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const closeButton = closeText ? (
    <Button size="small" variant="lightTransparent" onClick={onClose}>
      {closeText}
    </Button>
  ) : (
    <IconButton icon="XLg" onClick={onClose} variant="lightTransparent" size="small" />
  );

  return (
    <div className="h-[52px] p-4 f-between text-sec-purple-700 text-sm font-normal bg-sec-purple-100">
      {children}
      {closeButton}
    </div>
  );
};

export default ClosableBanner;
