import React, { useRef, useEffect } from 'react';

import {
  Chart,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  TooltipModel,
  TooltipItem,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';

import { parseApiDate } from 'utils/dateTime';
import { formatNumber } from 'utils/String';

import { UsageTabs } from './Usage';

Chart.register(
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  ChartDataLabels,
);

//--Chart Style Options--//
// @ts-ignore
Chart.defaults.font.family = "'PT Sans', sans-serif";
//--Chart Style Options--//

// These colors were hand-picked
export const COLORS = [
  '#53b1fd',
  '#fd6f8e',
  '#a4bcfd',
  '#12b76a',
  '#fd853a',
  '#7a5af8',
  '#fec84b',
  '#026aa2',
  '#fda29b',
  '#98a2b3',
];

const Y_LABEL_MAP = {
  summary: 'Summary',
  connectors: 'MAR',
  compute: 'Credits',
  storage: 'Storage (GB)',
  cloud_service: 'Cloud Service Credits',
  estimator: 'Estimator',
};

interface UsageChartProps {
  datasets: any;
  labels: any;
  tab: UsageTabs;
}

export default function UsageChart(props: UsageChartProps) {
  const chartRef: React.RefObject<HTMLCanvasElement> = useRef(null);
  const myChart: any = useRef(undefined);
  const { datasets, labels, tab } = props;

  // Build the chart
  useEffect(() => {
    const myChartRef = chartRef.current?.getContext('2d');
    if (!myChartRef) {
      return;
    }

    if (typeof myChart.current !== 'undefined') {
      myChart.current.destroy();
    }

    myChart.current = new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        plugins: {
          legend: {
            display: datasets.length > 1,
            position: 'bottom',
            labels: { padding: 16, boxHeight: 16, boxWidth: 16 },
          },
          datalabels: {
            display: labels.length < 20,
            anchor: 'end',
            align: 'top',
            color: '#026aa2',
            formatter: (value, context) => {
              // Here we are adding the values for every bar in a stack to get a total
              // Then we are only displaying it over the highest bar or line for that x value
              const datasetArray: Array<any> = [];
              context.chart.data.datasets.forEach((dataset) => {
                if (
                  dataset.data[context.dataIndex] !== undefined &&
                  dataset.data[context.dataIndex] !== null &&
                  dataset.label !== 'Budget'
                ) {
                  datasetArray.push(dataset.data[context.dataIndex]);
                }
              });
              function totalSum(total: number, datapoint: number) {
                return total + datapoint;
              }
              let sum = datasetArray.reduce(totalSum, 0);
              const budgetValue =
                context.chart.data.datasets.find((ds) => ds.label === 'Budget')?.data[
                  context.dataIndex
                ] || 0;
              // If the budget is higher than the sum, put the datalabel over the budget otherwise, put it over the last stacked bar
              const indexToAddLabelTo = sum > budgetValue ? datasetArray.length - 1 : 0;
              if (context.datasetIndex === indexToAddLabelTo) {
                return formatNumber(Math.round(sum * 100) / 100);
              } else {
                return '';
              }
            },
          },
          tooltip: {
            mode: 'x',
            intersect: false,
            callbacks: {
              title: function (this: TooltipModel<'bar'>, tooltipItems: TooltipItem<'bar'>[]) {
                const apiDate = tooltipItems[0].label as string;
                const date = parseApiDate(apiDate) as Date;
                return dayjs(date).format('MMMM D, YYYY');
              },
            },
          },
          title: {
            display: true,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            grid: { display: false },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            title: {
              display: true,
              text: Y_LABEL_MAP[tab],
            },
          },
        },
        aspectRatio: 4,
      },
    });
  }, [datasets, labels, tab]);

  return (
    <div className="relative w-full h-full py-4 px-0">
      <canvas id="myChart" ref={chartRef} width="100%" />
    </div>
  );
}
