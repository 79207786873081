import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import ExpandingTreeNode from 'components/layouts/containers/ExpandingTreeNode/ExpandingTreeNode';

import { Scope } from '../useHistoricalResyncState';
import { Schema, Table } from '../useManageTablesTabState';
import { SchemaExpanded } from '../useSchemasAndTablesToSyncState';

import ToggleableTable from './ToggleableTable';

interface ToggleableSchemaProps {
  schemaName: string;
  schema: Schema;
  tables: Record<string, Table>;
  schemaExpanded: SchemaExpanded;
  onToggleExpandSchema: (schemaName: string) => void;
  onToggleSchema: (schemaName: string) => void;
  onToggleExpandTable: (schemaName: string, tableName: string) => void;
  onToggleTable: (schemaName: string, tableName: string) => void;
  onToggleEnableColumn: (schemaName: string, tableName: string, columnName: string) => void;
  onToggleHashColumn: (schemaName: string, tableName: string, columnName: string) => void;
  onOpenHistoricalResyncModal: (newScope?: Scope) => void;
  resyncDisabled: boolean;
  resyncSpinning: boolean;
  savingDisabled: boolean;
  saving: boolean;
  startDisabledTables: string[] | null;
}

export default function ToggleableSchema(props: ToggleableSchemaProps) {
  const {
    schemaName,
    schema,
    tables,
    schemaExpanded,
    onToggleExpandSchema,
    onToggleSchema,
    onToggleExpandTable,
    onToggleTable,
    onToggleEnableColumn,
    onToggleHashColumn,
    onOpenHistoricalResyncModal,
    resyncDisabled,
    resyncSpinning,
    startDisabledTables,
    savingDisabled,
    saving,
  } = props;

  return (
    <ExpandingTreeNode
      key={schemaName}
      titleHeight={26}
      enabled={true}
      expanded={schemaExpanded.expanded}
      onExpand={() => onToggleExpandSchema(schemaName)}
    >
      <Checkbox
        name={`schema-${schemaName}`}
        variant="blue_gray"
        label={schemaName}
        checked={schema.enabled}
        onChange={() => {
          onToggleSchema(schemaName);
        }}
        disabled={savingDisabled}
      />
      <div key={`${schemaName}-tables`}>
        {Object.entries(tables).map(([tableName, table]) => {
          return (
            <ToggleableTable
              key={`${schemaName}__${tableName}`}
              schemaName={schemaName}
              tableName={tableName}
              table={table}
              tableExpanded={schemaExpanded.tables[tableName]}
              onToggleExpandTable={onToggleExpandTable}
              onToggleTable={onToggleTable}
              onToggleEnableColumn={onToggleEnableColumn}
              onToggleHashColumn={onToggleHashColumn}
              onOpenHistoricalResyncModal={onOpenHistoricalResyncModal}
              // If a table's schema is disabled,
              // Fivetran throws an error if you to resync the table.
              resyncDisabled={resyncDisabled || !schema.enabled}
              resyncSpinning={resyncSpinning}
              // If a table's schema is disabled,
              // Fivetran does not throw an error, but it does not save the change.
              savingDisabled={savingDisabled || !schema.enabled}
              saving={saving}
              startDisabled={!!startDisabledTables?.includes(tableName)}
            />
          );
        })}
      </div>
    </ExpandingTreeNode>
  );
}
