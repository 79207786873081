export interface AssistantAdviceRowProps {}

export default function AssistantAdviceRow(props: AssistantAdviceRowProps) {
  return (
    <div className="p-2 flex">
      <div className="py-1 px-2 rounded bg-white">
        <ol>
          <li>
            <div className="font-medium">How to Use:</div>
            <ol>
              <li>
                Ask a question, and the AI Assistant will generate or improve a SQL query. For best
                results, include the <b>source table and column names</b> in your question.
              </li>
            </ol>
          </li>
          <li className="mt-4">
            <div className="font-medium">Examples:</div>
            <ol className="ml-7 list-disc">
              <li>
                Show me the monthly revenue from the stripe.invoices table using the amount column.
              </li>
              <li>
                Join the users table with the orders table on the user_id column and show the total spend
                per user.
              </li>
              <li>Filter out the customers who signed before 2023.</li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
}
