import React from 'react';

interface SettingsGridProps {
  heading: string;
  children: React.ReactNode;
}

export function SettingsGrid(props: SettingsGridProps) {
  const { heading, children } = props;
  return (
    <div className="flex flex-col mb-1">
      <h2 className="font-medium text-sec-blue-gray-700 text-lg my-4">{heading}</h2>
      <div
        className={`grid grid-cols-[150px,1fr] grid-rows-${React.Children.count(
          children,
        )} gap-x-8 gap-y-4`}
      >
        {children}
      </div>
    </div>
  );
}

interface SettingsGridRowProps {
  label: string | React.ReactNode;
  children: React.ReactNode;
}

export function SettingsGridRow(props: SettingsGridRowProps) {
  const { label, children } = props;
  return (
    <>
      {typeof label === 'string' ? <GridLabel label={label} /> : label}
      <div>{children}</div>
    </>
  );
}

interface GridLabelProps {
  label: string;
}

export function GridLabel(props: GridLabelProps) {
  const { label } = props;
  return <div className="font-medium text-sec-blue-gray-700">{label}</div>;
}
