import React, { ReactNode } from 'react';

import { Link } from 'react-router-dom';

import { AggTable, CompanyRole, Favorite, TableType } from 'api/APITypes';
import ToggleFavoriteIcon from 'components/api_icon_buttons/ToggleFavoriteIcon/ToggleFavoriteIcon';
import Switch from 'components/inputs/basic/Switch/Switch';
import MozartSpinnerIcon, {
  CenteredMozartSpinnerIcon,
} from 'components/primitives/icons/MozartSpinnerIcon/MozartSpinnerIcon';
import TableIcon from 'components/primitives/icons/TableIcon/TableIcon';
import TableStatusIcon from 'components/primitives/icons/TableStatusIcon/TableStatusIcon';
import TagPickerIcon from 'components/primitives/icons/TagPickerIcon/TagPickerIcon';
import { queryTable } from 'components/query/queryUtil';
import { highlightSearch } from 'components/query/TableExplorer/highlightUtils';
import { humanSyncFrequency, lastRunAgo, numRowsAsString } from 'model_helpers/aggTableHelper';
import TagPill from 'pages/tags/TagPill';
import { eventType } from 'utils/String';

import ViewerSnapshotModal from './ViewerShanpshotModal';

import styles from './TableRowCell.module.css';

const NAME_CELL_CLASS = `font-medium ${styles.nameCell}`;
const DESCRIPTION_CELL_CLASS = styles.descriptionCell;
const usingCacheSpinner = <MozartSpinnerIcon spinning={false} />;
const usingCenteredCacheSpinner = <CenteredMozartSpinnerIcon spinning={false} justify="center" />;
const usingRightCacheSpinner = <CenteredMozartSpinnerIcon spinning={false} justify="end" />;

/*
We opted for lots of super small fast components instead of metaprogramming.
There is cutting and pasting instead of risking creating Wrapper Components.
*/
interface TableNameProps {
  table: AggTable;
  showIcon: boolean;
  useFullName: boolean;
  hasSchemaFilter: boolean;
  hasTableFilter: boolean;
  fullHighlightFilter: string | RegExp;
  schemaHighlightFilter: string | RegExp;
  tableHighlightFilter: string | RegExp;
}

const TableName = (props: TableNameProps) => {
  const {
    table,
    showIcon,
    useFullName,
    hasSchemaFilter,
    hasTableFilter,
    fullHighlightFilter,
    schemaHighlightFilter,
    tableHighlightFilter,
  } = props;
  const { type } = table;
  const eventName = `Warehouse ${eventType(type)}Link`;
  const trackOnClick = () => {
    analytics.track(eventName, {
      table_id: table.id,
      table_type: table.type,
    });
  };

  // Default name to empty string before the complication starts.
  let name: ReactNode = null;

  // OK So, there is overlap between what could be highlighed by full, schema, and table highlight filters.
  // It would be a tremendous pain to do the math of the overlap, advanced filters are rarely used,
  // and if they were John might be the only person that ever notices.
  // So we are only going to use one filter at a time in the event more than one applies.
  // Note, schema and table filters include the full_name filter by default.
  // This scheme breaks down when there is a (schema or a table filter) AND (a full_name filter that spans the seperating period).

  if (useFullName) {
    if (hasSchemaFilter || hasTableFilter) {
      name = (
        <span>
          {highlightSearch(table.schema, schemaHighlightFilter)}.
          {highlightSearch(table.name, tableHighlightFilter)}
        </span>
      );
    } else {
      name = highlightSearch(table.full_name, fullHighlightFilter);
    }
  } else {
    name = highlightSearch(table.name, tableHighlightFilter);
  }

  let url;
  if (type === 'transform') {
    url = `/tables/${table.id}/transform`;
  } else {
    url = `/tables/${table.id}`;
  }

  const link = (
    <Link
      to={url}
      onClickCapture={trackOnClick}
      className="hover:underline hover:text-sec-blue-gray-500"
    >
      {name}
    </Link>
  );

  return (
    <td className={NAME_CELL_CLASS}>
      {showIcon ? (
        <div className="f-row-y-center">
          <div className="w-[14px] min-w-[14px] mr-[6px]">
            <TableIcon table={table} size={14} variant="dullAction" />
          </div>
          {link}
        </div>
      ) : (
        link
      )}
    </td>
  );
};

interface DescriptionProps {
  table: AggTable;
  refKey: string;
  descRef: React.RefObject<HTMLDivElement>;
  descriptionHighlightFilter: string | RegExp;
  onTagClick: (tagName: string) => void;
}

const Description = (props: DescriptionProps) => {
  const { table, refKey, descRef, descriptionHighlightFilter, onTagClick } = props;
  const { description, tagObjs } = table;

  const firstLine = description.split('\n')[0];
  const hasTags = tagObjs.length > 0;
  const showTagIconInDescription = !hasTags;
  const descriptionDiv = firstLine && (
    <div
      ref={descRef}
      data-dk={refKey}
      className="mr-1 whitespace-nowrap overflow-hidden text-ellipsis text-left"
    >
      {highlightSearch(firstLine, descriptionHighlightFilter)}
    </div>
  );
  return (
    <td className={DESCRIPTION_CELL_CLASS}>
      {firstLine && showTagIconInDescription && <div className="f-row-y-center">{descriptionDiv}</div>}
      {firstLine && !showTagIconInDescription && <>{descriptionDiv}</>}
      {(hasTags || !firstLine) && (
        <div
          className="f-row-y-center"
          style={{ maxWidth: '90%', flexWrap: 'wrap', position: 'relative' }}
        >
          {tagObjs.map((t) => (
            <TagPill
              key={t.id}
              tag={t}
              eventPage="Warehouse"
              className={styles.tagPill}
              onClick={onTagClick}
            />
          ))}
        </div>
      )}
    </td>
  );
};

interface ScheduledProps {
  table: AggTable; // Only works on transforms at present
  usingCache: boolean;
  refKey: string;
  scheduledRef: React.RefObject<HTMLTableCellElement>;
}

const Scheduled = (props: ScheduledProps) => {
  const { table, usingCache, refKey, scheduledRef } = props;
  if (usingCache) {
    return <td>{usingCacheSpinner}</td>;
  }

  const frequency = humanSyncFrequency(table);
  if (frequency !== 'N/A') {
    return (
      <td
        ref={scheduledRef}
        data-sk={refKey}
        className="whitespace-nowrap overflow-hidden text-ellipsis"
      >
        {frequency}
      </td>
    );
  }

  return leftNotApplicableCell;
};

interface LastRunSuccessfulProps {
  table: AggTable; // Only works on transforms at present;
  usingCache: boolean;
}

const LastRunSuccessful = (props: LastRunSuccessfulProps) => {
  const { table, usingCache } = props;

  if (usingCache) {
    return <td>{usingCenteredCacheSpinner}</td>;
  }

  return (
    <td>
      <div className="f-center">
        <TableStatusIcon table={table} />
      </div>
    </td>
  );
};

interface AgoTimeProps {
  table: AggTable;
  usingCache: boolean;
  refKey: string;
  agoRef: React.RefObject<HTMLSpanElement>;
}

const AgoTime = (props: AgoTimeProps) => {
  const { table, usingCache, refKey, agoRef } = props;

  if (usingCache) {
    return <td>{usingCacheSpinner}</td>;
  }

  const ago = lastRunAgo(table);
  if (ago === 'Never Run') {
    return <td>{ago}</td>;
  }

  if (ago === 'N/A') {
    return leftNotApplicableCell;
  }

  return (
    <td>
      <span ref={agoRef} data-ahk={refKey}>
        {ago}
      </span>
    </td>
  );
};

interface RowCountProps {
  type: TableType;
  table: AggTable;
  usingCache: boolean;
  logRecent: (tableID: string) => void;
}
const RowCount = (props: RowCountProps) => {
  const { type, table, usingCache, logRecent } = props;
  const { id, full_name } = table;

  if (usingCache) {
    return <td>{usingRightCacheSpinner}</td>;
  }

  const count = numRowsAsString(table);
  if (count === 'N/A') {
    return rightNotApplicableCell;
  }

  let link = (
    <Link
      to={queryTable(full_name, true)}
      data-track={`Warehouse RowCountQuery${eventType(type)}`}
      className={styles.queryLink}
      onClick={() => logRecent(id)}
    >
      {count}
    </Link>
  );

  return <td className="text-right">{link}</td>;
};

interface SnapshotProps {
  table: AggTable;
  usingCache: boolean;
  spinning: boolean;
  showCantSnapshotModal: boolean;
  onSetSnapshot(event: React.ChangeEvent<HTMLInputElement>): void;
  onCloseCantSnapshotModal(): void;
}

const Snapshot = (props: SnapshotProps) => {
  const { table, usingCache, spinning, showCantSnapshotModal, onSetSnapshot, onCloseCantSnapshotModal } =
    props;

  if (usingCache) {
    return <td>{usingCenteredCacheSpinner}</td>;
  }

  if (table.type === 'snapshot') {
    return centeredNotApplicableCell;
  }

  const blockRowClickHandler = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <td>
      <Switch
        name="snapshot"
        checked={table.snapshot}
        onClick={blockRowClickHandler}
        onChange={onSetSnapshot}
        containerClass="!w-full !h-[20px]"
        spinning={spinning}
      />
      {showCantSnapshotModal && <ViewerSnapshotModal onClose={onCloseCantSnapshotModal} />}
    </td>
  );
};

interface ActionButtonsProps {
  table: AggTable;
  refKey: string;
  usingCache: boolean;
  type: TableType;
  favorite?: Favorite;
  tagPickerRef: React.RefObject<HTMLDivElement>;
  companyRole: CompanyRole;
  addFavorite: (favorite: Favorite) => void;
  removeFavorite: (favorite: Favorite) => void;
  onOpenTagPicker: (refKey: string) => void;
  logRecent: (tableID: string) => void;
}

const ActionButtons = (props: ActionButtonsProps) => {
  const {
    table,
    refKey,
    usingCache,
    favorite,
    tagPickerRef,
    companyRole,
    addFavorite,
    removeFavorite,
    onOpenTagPicker,
    logRecent,
  } = props;

  if (usingCache) {
    return <td>{usingCenteredCacheSpinner}</td>;
  }

  return (
    <td>
      <div className="f-row-y-center">
        <ToggleFavoriteIcon
          showTip={false}
          table={table}
          favorite={favorite}
          addFavorite={addFavorite}
          removeFavorite={removeFavorite}
          logRecent={logRecent}
          eventPage="Warehouse"
          className={styles.favoriteIcon}
        />
        {companyRole !== 'viewer' && (
          <TagPickerIcon
            tagPickerRef={tagPickerRef}
            refKey={refKey}
            iconSize={16}
            className={styles.tagPickerIcon}
            containerClass="w-[20px] h-[20px] ml-1"
            onOpenTagPicker={onOpenTagPicker}
          />
        )}
      </div>
    </td>
  );
};

const leftNotApplicableCell = <td className={styles.notApplicableCell}>N/A</td>;
const centeredNotApplicableCell = (
  <td className={styles.notApplicableCell}>
    <div className="f-center">N/A</div>
  </td>
);
const rightNotApplicableCell = (
  <td className={styles.notApplicableCell}>
    <div className="flex justify-end">N/A</div>
  </td>
);

export {
  TableName,
  Description,
  Scheduled,
  LastRunSuccessful,
  AgoTime,
  RowCount,
  Snapshot,
  ActionButtons,
};
