import GenerateSnowflakeUser from 'components/business_logic/GenerateSnowflakeUser/GenerateSnowflakeUser';
import InputCopy from 'components/inputs/composite/InputCopy/InputCopy';

import ExternalLink from '../ExternalLink';
import { fieldValue } from '../fieldUtils';
import { IntegrationLayoutProps } from '../IntegrationSnowflakeConfig';
import Steps from '../Steps';

const SnowCliLayout = (props: IntegrationLayoutProps) => {
  const { integration, values } = props;
  let account_name, database, warehouse;
  if (values) {
    account_name = fieldValue(values, 'account_name');
    database = fieldValue(values, 'database');
    warehouse = fieldValue(values, 'warehouse');
  }

  const command = `SNOWSQL_PWD=<Snowflake Password> snowsql -a ${account_name} -u <Snowflake Username> -d ${database} -w ${warehouse}`;
  const config = `[connections]
accountname = ${account_name}
username = <Snowflake Username>
password = <Snowflake Password>
dbname = ${database}
warehouse = ${warehouse}`;
  return (
    <>
      <Steps>
        <li>
          <ExternalLink href="https://docs.snowflake.com/en/user-guide/snowsql.html">
            Install {integration.uiName}
          </ExternalLink>
          .
        </li>
        <li>Then run the following command in your terminal.</li>
        <li>
          If you do not have a Snowflake username and password you want to use for this integration, you
          can create one below by clicking the "Generate Snowflake User" button. Then substitute the
          generated values into the command.
        </li>
      </Steps>
      <InputCopy label="Command" value={command} containerClass="py-4" />
      <Steps>
        <li>
          Additionally, you can set a{' '}
          <ExternalLink href="https://docs.snowflake.com/en/user-guide/snowsql-config.html#snowsql-config-file">
            SnowSQL <code>config</code> File
          </ExternalLink>{' '}
          and just run <code className="bg-pri-gray-200">snowsql</code>.
        </li>
      </Steps>
      <InputCopy label="SnowSQL config File" multiline value={config} containerClass="py-4" />
      <GenerateSnowflakeUser className="mt-4" />
    </>
  );
};

export default SnowCliLayout;
