/*
Actions that are specific to alerts (table_test event type from API).
*/
import { TableHasBeenRenamedText } from './TableActionSentence';
import { TableLinkOrName, UserActionSentencePartProps } from './UserActionSentenceParts';

export const AlertChangedSQLText = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  return (
    <>
      SQL for <TableLinkOrName userAction={userAction} eventLocation={eventLocation} /> changed.
    </>
  );
};

export const AlertHasBeenRenamedText = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  // Try to keep these the same until requirements force us to reimplement the method here.
  return TableHasBeenRenamedText({ userAction, eventLocation });
};
