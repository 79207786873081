import React, { useContext, useMemo } from 'react';

import _ from 'lodash';

import ComboboxFormikGroup from 'components/inputs/formik_group/ComboboxFormikGroup/ComboboxFormikGroup';
import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { TableContext } from 'model_layer/TableContext';

interface SchemaComboboxProps {
  schemaValue: string;
  tableType: 'transform' | 'csv_upload';
}

export default function SchemaCombobox(props: SchemaComboboxProps) {
  const { schemaValue, tableType } = props;

  const { tables } = useContext(TableContext);

  const allSchemas = useMemo(() => _.sortedUniq(tables.map((t) => t.schema).sort()), [tables]);

  // 1. It's a very bad idea to put a transform or an uploaded_csv in a schema managed by a connector.
  // 2. It's less bad, but still a discouraged practice to put transforms and uploaded_csvs in the same schema.
  // We discourage the user from using a schema in `schemasWithOtherTableTypes`, but still allow it.
  const [schemasWithOnlyMyTableType, schemasWithOtherTableTypes] = useMemo(() => {
    let schemasWithOnlyMyTableType: string[] = [];
    let schemasWithOtherTableTypes: string[] = [];
    tables.forEach((t) => {
      if (t.type === tableType) {
        schemasWithOnlyMyTableType.push(t.schema);
      } else {
        schemasWithOtherTableTypes.push(t.schema);
      }
    });
    schemasWithOnlyMyTableType = _.sortedUniq(schemasWithOnlyMyTableType.sort());
    schemasWithOtherTableTypes = _.sortedUniq(schemasWithOtherTableTypes.sort());
    schemasWithOnlyMyTableType = _.difference(schemasWithOnlyMyTableType, schemasWithOtherTableTypes);
    return [schemasWithOnlyMyTableType, schemasWithOtherTableTypes];
  }, [tables, tableType]);

  const warningMessage = (() => {
    switch (tableType) {
      case 'transform':
        return 'This schema is used by non-transform tables. We do not recommend placing transforms in schemas that are managed by connectors or uploaded CSVs.';
      case 'csv_upload':
        return 'This schema is used by tables not from CSV uploads. We do not recommend uploading CSVs to schemas that contain transforms or are managed by connectors.';
      default:
        return 'This schema is used by other types of tables. We recommend keeping them separate.';
    }
  })();
  const createdWhen = (() => {
    switch (tableType) {
      case 'transform':
        return 'the transform is run';
      case 'csv_upload':
        return 'the CSV is uploaded';
      default:
        return 'the table is loaded';
    }
  })();

  return (
    <ComboboxFormikGroup
      name="schema"
      label="Schema"
      options={schemasWithOnlyMyTableType} // We only want to show relevant schemas to discourage putting transforms/csvs in connected schemas
      dropdownAlert={
        schemasWithOtherTableTypes.includes(schemaValue) &&
        (allSchemas.includes(schemaValue) ? (
          <Alert variant="warning" className="mx-1 mb-1">
            {warningMessage}
          </Alert>
        ) : (
          <Alert variant="info" className="mx-1 mb-1 overflow-hidden">
            {`This will create a new schema with this name when ${createdWhen}.`}
          </Alert>
        ))
      }
      postLabelElement={
        <InfoIcon
          content={
            <div>
              <p>
                <code>Schemas</code> are folders in your data warehouse that hold tables. Use them to
                organize your tables by project, topic, or user.
              </p>
              <p className="mt-4">
                When your transform runs it will create a table that we will place in this schema.
              </p>
              <p className="mt-4">
                {`Select an existing schema or type a new one. If a schema does not already exist, it will be created when ${createdWhen}.`}
              </p>
            </div>
          }
          popoverProps={{ style: { maxWidth: '550px', fontSize: '1rem' } }}
          containerClass="ml-1"
        />
      }
      groupClass="w-full"
    />
  );
}
