/**
 * Comboxboxes are like Listboxes for long lists.
 * They have a searchbox at the top.
 */
import React from 'react';

import { ChevronExpand } from 'react-bootstrap-icons';

import cn from 'classnames';

import { Combobox as HeadlessUiCombobox } from '@headlessui/react';

import { getActualClass } from '../TextInput/TextInput';

export interface ComboboxProps {
  value: string;
  options: string[];
  hasError?: boolean;
  // The outermost class under any circumstance. Use for margin.
  containerClass?: string;
  // The class of the actual button.
  inputClass?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  noMatchesText?: string;
  dropdownAlert?: React.ReactNode;
  filter?: boolean;
  shouldShowCustomOption?: boolean;
  onChange: (newVal: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export default function Combobox(props: ComboboxProps) {
  let {
    value,
    options,
    hasError,
    containerClass,
    inputClass,
    autoFocus,
    disabled,
    noMatchesText,
    dropdownAlert,
    filter,
    shouldShowCustomOption = true,
    onChange,
    onBlur,
  } = props;

  // Sanitize optional props
  hasError = hasError === true;

  const actualInputClass = getActualClass(inputClass, hasError, disabled);

  const optionsClass =
    'absolute z-[101] mt-1 max-h-[400px] w-full overflow-auto rounded-[6px] bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none';

  const baseOptionClass = 'relative h-[32px] flex items-center select-none py-2 px-4';

  const calcOptionClass = ({ active }: { active: boolean }) =>
    cn(baseOptionClass, 'cursor-pointer', { 'bg-pri-gray-100 font-medium': active });

  const myBlur = (event: React.FocusEvent<HTMLInputElement>, open: boolean) => {
    if (onBlur && !open) {
      onBlur(event);
    }
  };

  const filteredOptions =
    value === '' || !filter
      ? options
      : options.filter((o) => {
          return o.toLowerCase().includes(value.toLowerCase());
        });

  // When the user is typing, if the value is not already in the options, add this additional option containing what they have written
  // This is so when the user hits tab, headless ui's internal hotkey press selects the value they have rather than the first option
  const showCustomOption = shouldShowCustomOption && !options.includes(value) && value !== '';
  const customOption = showCustomOption ? (
    <HeadlessUiCombobox.Option key="custom" className={calcOptionClass} value={value}>
      <span className="block truncate font-normal">{value}</span>
    </HeadlessUiCombobox.Option>
  ) : null;

  return (
    <HeadlessUiCombobox
      disabled={disabled}
      as="div"
      value={value}
      onChange={onChange}
      className={containerClass}
    >
      {({ open }) => (
        <div className="relative w-full">
          <HeadlessUiCombobox.Input
            className={actualInputClass}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => myBlur(event, open)}
            autoFocus={autoFocus}
          />
          <HeadlessUiCombobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronExpand size={16} color="var(--pri-gray-400)" />
          </HeadlessUiCombobox.Button>
          <HeadlessUiCombobox.Options className={optionsClass}>
            {value !== '' && dropdownAlert}
            {customOption}
            {filteredOptions && filteredOptions.length > 0 ? (
              filteredOptions.map((fo, oIdx) => (
                <HeadlessUiCombobox.Option key={oIdx} className={calcOptionClass} value={fo}>
                  <span className="block truncate font-normal">{fo}</span>
                </HeadlessUiCombobox.Option>
              ))
            ) : (
              <div key={'No matches'} className={baseOptionClass}>
                <span className="block truncate font-normal text-pri-gray-400">
                  {noMatchesText || 'No Matches'}
                </span>
              </div>
            )}
          </HeadlessUiCombobox.Options>
        </div>
      )}
    </HeadlessUiCombobox>
  );
}
