import { useMemo, useState } from 'react';

import deepEqual from 'fast-deep-equal';
import { cloneDeep } from 'lodash';
import * as yup from 'yup';

import { forEachDuplicateColumn } from 'components/query/FlowchartEditor/query_builder/columnDeduplicator';
import useMemoObject from 'hooks/useMemoObject';
import { getValidationErrorsSync, valDBIdentifier } from 'utils/Validators';

import { TableColumn } from '../../../../model/FlowchartQueryModel';

import { ColumnValueID } from './ColumnSearchableCheckboxPicker/ColumnSearchableCheckboxPickerRow';

export enum ColumnStatus {
  OK = 'ok', // Everything is fine. The column is the same as last time.
  NEW = 'new', // The column was added by the parent since the last time selectedColumns was saved.
  MISSING = 'missing', // The column was saved in selectedColumns but is currently missing in the columns inherrited from the parent.
}
export interface SelectorColumn extends TableColumn {
  status: ColumnStatus;
}

function effectivelySame(a: TableColumn, b: TableColumn) {
  return a.socket === b.socket && a.column?.name === b.column?.name && a.column?.type === b.column?.type;
}

function buildInitialSelections(
  availableColumns: TableColumn[],
  selectedColumns: TableColumn[] | undefined,
): SelectorColumn[] {
  let initialSelections: SelectorColumn[] = [];

  // The user previously selected columns.
  if (selectedColumns) {
    // The previous selectedColumns could be in or on not in the availableColumns inherited from the parent.
    // Mark their status as such.
    initialSelections = selectedColumns.map((sc) => {
      const found = availableColumns.find((ac) => effectivelySame(ac, sc));
      const status = found ? ColumnStatus.OK : ColumnStatus.MISSING;
      return { ...sc, status };
    });

    // New available columns could have been created since we last saved.
    // Because the initialSelections might be sorted,
    // append these new columns to the end.
    const newColumnsPicked = initialSelections.every((c) => c.picked);
    const newColumns = availableColumns
      .filter((ac) => {
        const found = initialSelections.find((ic) => effectivelySame(ic, ac));
        return !found;
      })
      .map((nc) => ({ ...nc, status: ColumnStatus.NEW, picked: newColumnsPicked }));

    if (newColumns.length) {
      initialSelections = [...initialSelections, ...newColumns];
    }
  }
  // The user HAS NOT previously selected columns.
  // Default initialSelections to the columns we inherited from our parent.
  // This would be a `SELECT *` query.
  else {
    initialSelections = availableColumns.map((c) => ({ ...c, status: ColumnStatus.OK }));
  }

  return initialSelections;
}

export interface UseColumnSelectorState {
  initialSelections: SelectorColumn[];
  currentSelections: SelectorColumn[];
  aliasErrors: Record<ColumnValueID, string>;
  formError: string;
  setCurrentSelections: React.Dispatch<React.SetStateAction<SelectorColumn[]>>;
}

const useColumnSelector = (
  availableColumns: TableColumn[],
  selectedColumns?: TableColumn[],
): UseColumnSelectorState => {
  const [initialSelections] = useState<SelectorColumn[]>(() =>
    buildInitialSelections(availableColumns, selectedColumns),
  );
  const [currentSelections, setCurrentSelections] = useState<SelectorColumn[]>(() =>
    cloneDeep(initialSelections),
  );

  // Validate the form every time current selections is updated
  const [aliasErrors, formError] = useMemo(() => {
    return validateColumnSelections(currentSelections);
  }, [currentSelections]);

  const state = useMemoObject<UseColumnSelectorState>({
    initialSelections,
    currentSelections,
    aliasErrors,
    formError,
    setCurrentSelections,
  });

  return state;
};

export default useColumnSelector;

export const validateColumnSelections = (
  currentSelections?: TableColumn[],
): [Record<string, string>, string] => {
  if (!currentSelections) {
    return [{}, ''];
  }

  const validatorsByColumn: Record<ColumnValueID, any> = {};
  const valuesByColumn: Record<ColumnValueID, string> = {};

  // TODO: Check that columns are inheritted.

  // Check the format of each alias
  // Only validate non-empty aliases
  currentSelections.forEach((cs) => {
    if (cs.alias) {
      // TODO:
      // This is probably going to need to be adjusted to allow reserved words
      // Good enough for now.
      validatorsByColumn[cs.id] = valDBIdentifier('Alias');
      valuesByColumn[cs.id] = cs.alias;
    }
  });
  const schema = yup.object(validatorsByColumn);
  const aliasErrors = getValidationErrorsSync(schema, valuesByColumn);

  // Check for duplicate column names
  const onDuplicate = (duplicate: TableColumn, duplicateName: string) => {
    aliasErrors[duplicate.id] = `"${duplicateName}" is already used by another column.`;
  };
  forEachDuplicateColumn(currentSelections, onDuplicate, true);

  let formError = '';
  const hasColumn = currentSelections.some((cs) => cs.picked);
  if (!hasColumn) {
    formError = 'You must select at least one column.';
  }
  return [aliasErrors, formError];
};

// Do we need to save the currentSelections or are they the same same as the availableColumns?
export function selectionsToSave(availableColumns: TableColumn[], currentSelections: TableColumn[]) {
  const allDefaultOrderAndValue = deepEqual(availableColumns, currentSelections);
  const toSave = allDefaultOrderAndValue ? undefined : currentSelections;
  return toSave;
}
