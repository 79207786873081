import { useCallback, useState } from 'react';

type LocalSyncState = { [key: string]: boolean };

export interface LocalSyncingState {
  // Whether we have triggered a sync and are waiting for it to start
  awaitingSync: boolean;

  // Sets/unsets that we are waiting for a sync to start. The sourceKey identifies where the sync is coming from,
  // so we can mark that a source is no longer awaiting a sync.
  setAwaitingSync: (sourceKey: string) => void;
  setNotAwaitingSync: (sourceKey: string) => void;
}

export default function useLocalSyncingState(): LocalSyncingState {
  /**
   * Local state to track whether we are waiting for a sync to start. We use this because when we trigger a sync,
   * it may take awhile for Fivetran to start it. During this waiting period, we want to show a spinner or disable
   * any button that would trigger another sync. For example, when we trigger a historical resync, the header Sync Now
   * button should be disabled until the historical resync is complete.
   */
  const [localSyncState, setLocalSyncState] = useState<LocalSyncState>({});

  const setAwaitingSync = useCallback(
    (sourceKey: string) => {
      setLocalSyncState((prev) => ({ ...prev, [sourceKey]: true }));
    },
    [setLocalSyncState],
  );

  const setNotAwaitingSync = useCallback(
    (sourceKey: string) => {
      setLocalSyncState((prev) => {
        const newState = { ...prev };
        delete newState[sourceKey];
        return newState;
      });
    },
    [setLocalSyncState],
  );

  const awaitingSync = Object.keys(localSyncState).length > 0;

  return {
    awaitingSync,
    setAwaitingSync,
    setNotAwaitingSync,
  };
}
