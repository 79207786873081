/**
 * Converts the FlowchartQueryModel which is a logical description of the query model
 * into the query model format that the Flowchart component renders.
 */
import { useMemo } from 'react';

import { ElementDefinition } from 'cytoscape';

import { InteractiveFlowchartProps } from '../../../useFlowchartEditor';

import {
  BiParentVertex,
  Edge,
  FlowchartQueryModel,
  FlowchartVertex,
} from '../../model/FlowchartQueryModel';

import { VERTEX_HEIGHT, getWidth } from '../Flowchart.styles';
import { VertexData } from '../FlowchartVertex/FlowchartVertex';
import { getEdgeColor } from '../utils/graphUtils';

const INPUT_SOCKET_SHIFT = 3;
const OUTPUT_SOCKET_SHIFT = -3;

const getHalfWidth = (vertex: FlowchartVertex) => {
  return getWidth(vertex) / 2;
};

const useFlowchartBuilder = (
  flowchartQueryModel: FlowchartQueryModel,
  interactiveFlowchartProps: InteractiveFlowchartProps,
) => {
  const { vertices, edges } = flowchartQueryModel;
  const { loadingVertexIDs, selectedVertexID, selectedEdgeID } = interactiveFlowchartProps;

  const graphElements = useMemo(() => {
    const verticiesByID: Record<string, FlowchartVertex> = {};
    const updatedVertices: Array<ElementDefinition> = vertices.map((v: FlowchartVertex) => {
      verticiesByID[v.id] = v;
      const data: VertexData = {
        id: v.id,
        vertex: v,
        isLoading: loadingVertexIDs[v.id] === true,
        isSelected: v.id === selectedVertexID,
        vertexHeight: VERTEX_HEIGHT,
      };
      return {
        id: v.id,
        data,
        position: v.position,
        grabbable: true,
        locked: false,
        classes: 'node',
      };
    });

    const updatedEdges: Array<ElementDefinition> = edges.map((e: Edge) => {
      const isSelected = e.id === selectedEdgeID;

      const edgeDefinition = {
        data: {
          id: e.id,
          edge: e,
          source: e.sourceID,
          target: e.destinationID,
          width: isSelected ? 2 : 0.75,
          sourceEndpoint: getSourceEndpoint(verticiesByID, e),
          targetEndpoint: getTargetEndpoint(verticiesByID, e),
          color: getEdgeColor(isSelected),
        },
      };

      return edgeDefinition;
    });

    return [...updatedVertices, ...updatedEdges];
  }, [vertices, edges, loadingVertexIDs, selectedVertexID, selectedEdgeID]);

  return {
    graphElements,
  };
};

const getSourceEndpoint = (verticiesByID: Record<string, FlowchartVertex>, e: Edge) => {
  const vertex = verticiesByID[e.sourceID];
  return `${getHalfWidth(vertex) + INPUT_SOCKET_SHIFT}px 0`;
};

const getTargetEndpoint = (verticiesByID: Record<string, FlowchartVertex>, e: Edge) => {
  const vertex = verticiesByID[e.destinationID];
  const widthStyle = `${-1 * getHalfWidth(vertex) + OUTPUT_SOCKET_SHIFT}px`;
  if (vertex) {
    if (vertex.isBiParent()) {
      const biParent = vertex as BiParentVertex;
      if (e.sourceID === biParent.leftParentID) {
        return `${widthStyle} -${(1 / 6) * VERTEX_HEIGHT}px`;
      } else {
        return `${widthStyle} ${(1 / 6) * VERTEX_HEIGHT}px`;
      }
    }
  }
  return `${widthStyle} 0`;
};

export default useFlowchartBuilder;
