import { useMemo } from 'react';

import { useUserProfile } from 'context/AuthContext';

import { Command } from '../MozartCommandPalette';

export interface PathCommand extends Command {
  path: string;
}

export interface PathCommandPrecursor {
  name: string;
  path: string;
  hotKeys?: string;
}

// PathCommandPrecursors are the unique properties of a PathCommand.
// The properties that are the same for all PathCommands
// are applied by metaprogramming later on.
// These should be ordered from most important to least important.
const pathPrecursors: PathCommandPrecursor[] = [
  { name: 'Home', path: '/home', hotKeys: 'g,h' },
  { name: 'Warehouse', path: '/warehouse', hotKeys: 'g,w' },
  { name: 'Query', path: '/query', hotKeys: 'g,q' },
  { name: 'Add Transform', path: '/transforms/add', hotKeys: 'a,t' },
  { name: 'Add/Upload CSV', path: '/csv/add', hotKeys: 'a,c' },
  { name: 'List Connectors', path: '/connectors', hotKeys: 'g,c' },
  { name: 'Add Connector', path: '/connectors/add', hotKeys: 'a,c' },
  { name: 'Getting Started', path: '/getting-started' },
  { name: 'List dbt Jobs', path: '/dbt', hotKeys: 'g,d' },
  { name: 'Add dbt Job', path: '/dbt/jobs/add', hotKeys: 'a,d' },
  { name: 'List Alerts', path: '/alerts', hotKeys: 'g,a' },
  { name: 'List Integrations', path: '/integrations', hotKeys: 'g,i' },
  { name: 'List Users', path: '/users', hotKeys: 'g,u' },
  { name: 'Add User', path: '/users/add', hotKeys: 'a,u' },
  { name: 'User Settings', path: '/settings?tab=user' },
  { name: 'Notification Settings', path: '/settings?tab=notifications' },
  { name: 'Company Settings', path: '/settings?tab=company' },
  { name: 'Integration Settings', path: '/settings?tab=integrations' },
  { name: 'Connector Usage', path: '/usage/connector' },
  { name: 'Compute Usage', path: '/usage/compute' },
  { name: 'Storage Usage', path: '/usage/storage' },
  { name: 'Notifications', path: '/notifications' },
  { name: 'User Actions', path: '/user-actions' },
  { name: 'Partnerships', path: '/partnerships' },
  { name: 'Referral Program', path: 'https://mozartdata.com/referral-program/' },
];

export default function usePathCommands(
  openPathCommand: (this: PathCommand, modKeyPressed: boolean) => boolean,
) {
  const {
    userProfile: {
      company: { metabase_integration, sigma_integration, looker_integration },
    },
  } = useUserProfile();
  const pathCommands = useMemo<PathCommand[]>(() => {
    const dynamicPathPrecursors = [...pathPrecursors];
    if (metabase_integration?.is_active) {
      dynamicPathPrecursors.push({ name: 'Metabase', path: metabase_integration.url });
    }
    if (sigma_integration) {
      dynamicPathPrecursors.push({ name: 'Sigma', path: sigma_integration.app_url });
    }
    if (looker_integration) {
      dynamicPathPrecursors.push({ name: 'Looker', path: looker_integration.base_url });
    }
    return dynamicPathPrecursors.map<PathCommand>((pp) => ({
      name: pp.name,
      path: pp.path,
      hotKeys: pp.hotKeys,
      type: 'path',
      command: openPathCommand,
    }));
  }, [openPathCommand, metabase_integration, sigma_integration, looker_integration]);

  return pathCommands;
}
