import { useState } from 'react';

import API from 'api/API';
import { LookerIntegration } from 'api/APITypes';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';

import { IntegrationLayoutProps } from '../../IntegrationSnowflakeConfig';

import LookerView, { LookerAPIKeyFormValues } from './LookerView';

export interface LookerScreen {
  show: boolean;
  loading: boolean;
  error: string | null;
}

const LookerController = (props: IntegrationLayoutProps) => {
  const { values } = props;
  const { userProfile, setUserProfile } = useUserProfile();
  const {
    company: { looker_integration: lookerIntegration },
  } = userProfile;
  const { type: databaseType, displayName: databaseDisplayName } = useDatabaseAccount();

  // API key form
  const [showAPIKeySetupForm, setShowAPIKeySetupForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Snowflake setup screen
  const [showSetupSnowflakeInLookerForm, setShowSetupSnowflakeInLookerForm] = useState(false);
  const [settingUpSnowflake, setSettingUpSnowflake] = useState(false);
  const [setupSnowflakeError, setSetupSnowflakeError] = useState<string | null>(null);

  const api = new API();

  const handleManualConnectionInstructionsClick = (newExpand: boolean) => {
    analytics.track(
      newExpand
        ? 'LookerIntegration ExpandManualConnectionInstructions'
        : 'LookerIntegration CollapseManualConnectionInstructions',
    );
  };

  const handleShowAPIKeysIntegrationClick = () => {
    const newShow = !showAPIKeySetupForm;
    setShowAPIKeySetupForm(newShow);
    analytics.track(
      newShow ? 'LookerIntegration OpenSetupIntegration' : 'LookerIntegration CloseSetupIntegration',
    );
  };

  const handleShowSnowflakeInLookerClick = () => {
    const newShow = !showSetupSnowflakeInLookerForm;
    setShowSetupSnowflakeInLookerForm(newShow);
    analytics.track(
      newShow
        ? 'LookerIntegration OpenSetupSnowflakeInLooker'
        : 'LookerIntegration CloseSetupSnowflakeInLooker',
    );
  };

  const handleSubmitAPIKey = (formValues: LookerAPIKeyFormValues) => {
    setIsLoading(true);
    setError(null);
    api
      .post('api/looker_integrations', formValues)
      .then((resp) => {
        const lookerIntegration = resp.data as LookerIntegration;
        setUserProfile({
          ...userProfile,
          company: {
            ...userProfile.company,
            looker_integration: lookerIntegration,
          },
        });
        setShowAPIKeySetupForm(false);
        setShowSetupSnowflakeInLookerForm(true);
        analytics.track('LookerIntegration SetupAPIKeys');
      })
      .catch((err) => {
        if (err.response.data.detail) {
          setError(err.response.data.detail);
        } else {
          setError(JSON.stringify(err.response.data));
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleDoSetupSnowflakeInLooker = () => {
    setSettingUpSnowflake(true);
    setSetupSnowflakeError(null);
    api
      .post('api/looker_integrations/create_database_connection', {})
      .then((resp) => {
        const lookerIntegration = resp.data as LookerIntegration;
        setUserProfile({
          ...userProfile,
          company: {
            ...userProfile.company,
            looker_integration: lookerIntegration,
          },
        });
        setShowSetupSnowflakeInLookerForm(false);
        analytics.track('LookerIntegration SetupSnowflakeInLooker');
      })
      .catch((err) => {
        if (err.response.data.detail) {
          setSetupSnowflakeError(err.response.data.detail);
        } else {
          setSetupSnowflakeError(JSON.stringify(err.response.data));
        }
      })
      .finally(() => setSettingUpSnowflake(false));
  };

  return LookerView({
    values,
    apiKeyScreen: {
      show: showAPIKeySetupForm,
      loading: isLoading,
      error,
    },
    setupSnowflakeScreen: {
      show: showSetupSnowflakeInLookerForm,
      loading: settingUpSnowflake,
      error: setupSnowflakeError,
    },
    handleManualConnectionInstructionsClick,
    handleShowAPIKeysIntegrationClick,
    handleShowSnowflakeInLookerClick,
    handleSubmitAPIKey,
    handleDoSetupSnowflakeInLooker,
    databaseType,
    databaseDisplayName,
    lookerIntegration,
  });
};

export default LookerController;
