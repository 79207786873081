import { useContext } from 'react';

import { DatabaseType } from 'api/APITypes';
import GenerateSnowflakeUser from 'components/business_logic/GenerateSnowflakeUser/GenerateSnowflakeUser';
import LinkButton from 'components/inputs/basic/Button/LinkButton';
import LinkIconButton from 'components/inputs/basic/Button/LinkIconButton';
import CodeCopy, { editorHeight } from 'components/inputs/composite/CodeCopy/CodeCopy';
import Accordion from 'components/widgets/accordions/Accordion/Accordion';
import { useUserProfile } from 'context/AuthContext';
import { useDatabaseAccount } from 'context/AuthContext';
import { TableModelsContext } from 'model_layer/TableModelsContext';

import ExternalLink from '../ExternalLink';
import { fieldValue } from '../fieldUtils';
import { IntegrationLayoutProps } from '../IntegrationSnowflakeConfig';
import Steps from '../Steps';

const DbtLayout = (props: IntegrationLayoutProps) => {
  const { values } = props;
  const { dbtRunConfigs } = useContext(TableModelsContext);
  const { type: databaseType } = useDatabaseAccount();

  let script: string;
  if (databaseType === 'bigquery') {
    let project_id;
    if (values) {
      project_id = fieldValue(values, 'project_id');
    }
    script = `mozart:
  outputs:
    dev:
      type: bigquery
      method: service-account
      project: ${project_id}
      dataset: enriched
      query_tag: dbt
      keyfile: /path/to/keyfile.json
  target: dev
`;
  } else {
    let account_name, database, warehouse;
    if (values) {
      account_name = fieldValue(values, 'account_name');
      database = fieldValue(values, 'database');
      warehouse = fieldValue(values, 'warehouse');
    }
    script = `mozart:
  outputs:
    dev:
      type: snowflake
      schema: enriched
      query_tag: dbt
      account: ${account_name}
      database: ${database}
      password: <Snowflake Password>
      user: <Snowflake Username>
      role: <Snowflake Role>
      warehouse: ${warehouse}
      threads: 8
  target: dev

`;
  }

  const handleLocalEnvInstructionsClick = (newExpand: boolean) => {
    analytics.track(
      newExpand
        ? 'DbtIntegration ExpandLocalEnvironmentInstruction'
        : 'DbtIntegration CollapseLocalEnvironmentInstruction',
    );
  };

  return (
    <>
      {dbtRunConfigs.length ? <YouAreAlreadyUsingDBT /> : <TryDBT databaseType={databaseType} />}
      <Accordion
        header="Run dbt in Local Environment"
        onClick={handleLocalEnvInstructionsClick}
        className="mt-6"
      >
        <Steps>
          <li>
            Copy the yaml below to <code>~/.dbt/profiles.yml</code>
            <br />
            under a heading corresponding to your project (the default is <code>mozart</code>).
          </li>
          {databaseType === 'snowflake' && (
            <li>
              If you do not have a Snowflake username, password, and role you want to use for this
              integration, you can create one below by clicking the "Generate Snowflake User" button.
              Then substitute the generated values into the yaml.
            </li>
          )}
          <li>
            Use <code>profile: 'mozart'</code> in your DBT project's <code>dbt_project.yml</code>
            <br />
            to refer to the profile below.
          </li>
          {databaseType === 'bigquery' && (
            <li>
              For the <code>keyfile</code> in the script below, you can reuse the service account JSON
              file that you used to connect BigQuery to Mozart Data, or make a new service account or key
              via the instructions{' '}
              <ExternalLink href="https://help.mozartdata.com/docs/bigquery-mozart-data">
                here
              </ExternalLink>
              .
            </li>
          )}
          <li>
            Learn more about{' '}
            <ExternalLink href="https://docs.getdbt.com/dbt-cli/configure-your-profile">
              DBT profiles setup
            </ExternalLink>
            .
          </li>
        </Steps>
        <CodeCopy
          label="~/.dbt/profiles.yml"
          value={script}
          mode="yaml"
          height={`${editorHeight(script.split('\n').length)}px`}
          containerClass="pt-4"
        />
        {databaseType === 'snowflake' && <GenerateSnowflakeUser className="mt-4" />}
      </Accordion>
    </>
  );
};

const TryDBT = (props: { databaseType: DatabaseType }) => {
  const {
    userProfile: { company_role },
  } = useUserProfile();
  const { databaseType } = props;
  return (
    <div className="px-[3.75rem] py-[2.5rem] flex flex-col bg-[--sec-blue-50]">
      <div className="flex flex-row">
        <img src="/images/graphics/integrations/mozart_dbt.svg" alt="" />
        <div className="ml-8 text-pri-gray-500">
          <h2 className="text-lg font-medium">Try Mozart Data + dbt Core Integration</h2>
          <p className="mt-2">
            Experience the power of dbt Core for transformation and use Mozart to schedule dbt jobs.
            Supercharge dbt jobs by scheduling runs as soon as the data from connectors finish syncing.
            Bring an existing dbt project repository or link a new repo in Mozart to get started.
          </p>
          <LinkButton
            variant="darkAction"
            className="mt-6"
            to={company_role === 'viewer' ? '/dbt' : '/dbt/jobs/add'} // it's unlikely viewers will be on /integrations/dbt unless they go here directly, but might as well direct them to a page they can actually use if they click this button
            data-track={'DbtIntegration LinkToAddDBTRunConfig'}
          >
            Get Started
          </LinkButton>
        </div>
      </div>
      {/* TODO: When we support Transforms for BigQuery, always include the Friendly Advice */}
      {databaseType !== 'bigquery' && (
        <div>
          <div className="mt-6 h-[1px] w-full bg-pri-gray-200" />
          <div className="mt-6 text-pri-gray-500">
            <h2 className="text-lg font-medium">Friendly Advice</h2>
            <p className="mt-2">
              If you are not familiar with dbt or git we recommend using{' '}
              <a
                href={`https://help.mozartdata.com/docs/transforms`}
                target="_blank"
                rel="noopener noreferrer"
                data-track="DbtIntegration OpenTransformDocs"
                className="text-sec-blue-light-700 hover:underline cursor-pointer"
              >
                Mozart Transforms
              </a>{' '}
              because they are easier to learn. You can learn how to set up dbt in Mozart{' '}
              <a
                href={`https://help.mozartdata.com/docs/dbt-core-integration`}
                target="_blank"
                rel="noopener noreferrer"
                data-track="DBTIntegration OpenDbtDocs"
                className="text-sec-blue-light-700 hover:underline cursor-pointer"
              >
                here.
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const YouAreAlreadyUsingDBT = () => (
  <div className="p-6 bg-[--sec-blue-50] text-pri-gray-500">
    <h2 className="text-lg font-medium">You are already running dbt with Mozart Data.</h2>
    <p className="mt-2">
      Experience the power of dbt Core for transformation and use Mozart to schedule dbt jobs.
      Supercharge dbt jobs by scheduling runs as soon as the data from connectors finish syncing.
    </p>
    <LinkIconButton
      variant="lightDullTransparent"
      className="mt-6"
      to={'/dbt'}
      data-track={'DbtIntegration LinkToListDBTRunConfigs'}
      icon="ChevronRight"
      iconLast={true}
      text="Manage dbt job"
      size="small"
    />
  </div>
);

export default DbtLayout;
