/**
 * The original InfoIcon API.
 */
import React, { HTMLAttributes } from 'react';

import { InfoCircleFill, Icon } from 'react-bootstrap-icons';

import cn from 'classnames';

import { Placement } from 'components/overlay/PopperTrigger/usePopperRefs';

import BaseInfoIcon from './BaseInfoIcon';

import s from './InfoIcon.module.css';

interface InfoIconProps {
  title?: string;
  content: React.ReactNode;
  placement?: Placement;
  icon?: Icon;
  iconColor?: string;
  iconRotate?: number; // the number of degrees like `45`
  containerClass?: string;
  popoverProps?: HTMLAttributes<HTMLDivElement>;
}

export default function InfoIcon(props: InfoIconProps) {
  const { title, content, containerClass, popoverProps } = props;
  let { placement, icon, iconColor, iconRotate } = props;
  placement = placement || 'right';

  const MyIcon = !!icon ? icon : InfoCircleFill;
  iconColor = iconColor || 'var(--pri-gray-400)';
  const rotateClass = iconRotate !== undefined ? `rotate-[${iconRotate}deg]` : '';
  const actualClass = cn(s.icon, rotateClass);

  const myIcon = <MyIcon color={iconColor} size={16} className={actualClass} />;

  return (
    <BaseInfoIcon
      title={title}
      content={content}
      placement={placement}
      containerClass={containerClass}
      popoverProps={popoverProps}
      icon={myIcon}
    />
  );
}
