import usePagedUserActions from 'pages/user_actions/usePagedUserActions';

import { UserActionsWidgetState } from './UserActionsWidget/UserActionsWidget';

export default function useUserActionsWidget(): UserActionsWidgetState {
  const { isLoading, error, userActions, eventLocation } = usePagedUserActions(
    'HomepageUserActionsWidget',
    {
      page_size: 10,
    },
  );

  /**
   * Track click event for the View Usage button. Navigation is handled by the parent component.
   */
  const onClickViewAll = () => {
    analytics.track(`${eventLocation} ViewAllUserActions`);
  };

  return {
    allLoaded: !isLoading,
    anyError: !!error,
    userActions,
    onClickViewAll,
  };
}
