import Hotkeys from 'components/widgets/Hotkeys/Hotkeys';

import { CommandPaletteRowIcon } from './CommandPaletteRowIcon';
import { Command } from './MozartCommandPalette';

interface CommandPaletteRowProps extends Command {
  highlight: string | null;
}

// the highlight comes from input enterd by the user however, dangerouslySetInnerHTML
// should be safe because the raw markup originates from fuzzsort
// and is derived from the default, see:
// https://github.com/farzher/fuzzysort#fuzzysorthighlightresult-openb-closeb
export default function CommandPaletteRow(props: CommandPaletteRowProps) {
  const { highlight, name, hotKeys } = props;

  const text = highlight ? (
    <span dangerouslySetInnerHTML={{ __html: highlight }} />
  ) : (
    <span>{name}</span>
  );

  return (
    <div className="item f-row-y-center justify-between">
      <div className="f-row-y-center">
        <div>
          <CommandPaletteRowIcon command={props} />
        </div>
        <div className="ml-2">{text}</div>
      </div>
      {hotKeys && (
        <div>
          <Hotkeys hotkeys={hotKeys} />
        </div>
      )}
    </div>
  );
}
