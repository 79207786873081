import cn from 'classnames';

import AnimatedAngleIcon from 'components/primitives/icons/AnimatedAngleIcon/AnimatedAngleIcon';

import s from './BulletedStep.module.css';

export interface BulletedStepProps {
  bullet: React.ReactElement;
  label: string;
  isOpen: boolean;
  children: React.ReactChild;
  className?: string;
  onClick(): void;
}

export default function BulletedStep(props: BulletedStepProps) {
  const { bullet, label, isOpen, className, children, onClick } = props;

  return (
    <div className={cn('w-full', className)}>
      <div className="w-full h-[56px] p-4 f-between hover:cursor-pointer" onClick={onClick}>
        <div className="f-row-y-center">
          {bullet}
          <div className="ml-2">{label}</div>
        </div>
        <AnimatedAngleIcon isOpen={isOpen} mode="accordion" size={16} />
      </div>
      <div className={cn(s.accordion, { [s.open]: isOpen })}>
        <div className={s.inner}>{children}</div>
      </div>
    </div>
  );
}
