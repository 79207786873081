import { AggTable, QueryRunResults } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import Listbox from 'components/inputs/basic/Listbox/Listbox';
import TextInput from 'components/inputs/basic/TextInput/TextInput';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import NoMatchesFoundAlert from 'components/widgets/alerts/NoMatchesFoundAlert/NoMatchesFoundAlert';
import NoObjectsAlert from 'components/widgets/alerts/NoObjectsAlert/NoObjectsAlert';
import { DataAlertWithTable, SavableDataAlertProps } from 'hooks/useDataAlerts';
import { alertsHelpURL } from 'hooks/useDataAlerts';
import { useBooleanFlag } from 'hooks/useFeatureFlags';
import DataAlertsTable from 'pages/tables/ShowTable/DataAlertTab/shared/DataAlertTable/DataAlertTable';
import EditDataAlertModal from 'pages/tables/ShowTable/DataAlertTab/shared/EditDataAlertModal';
import FailureLogsModal from 'pages/tables/ShowTable/DataAlertTab/shared/FailureLogsModal';

import CreateDataAlertsModal from './CreateDataAlerts/CreateDataAlertsModal';
import { CreateAlertSource } from './CreateDataAlerts/useCreateDataAlerts';
import { FilterDataAlerts } from './useFilterDataAlerts';

interface ListDataAlertsViewProps extends FilterDataAlerts {
  errorMessage: string;
  loading: boolean;
  saving: boolean;
  dataAlerts: DataAlertWithTable[];
  selectedDataAlert: DataAlertWithTable | null;
  updateErrorMessage: string;
  failureLogs: QueryRunResults | null;
  failureLogsErrorLines: string[];
  failureLogsDataAlert: DataAlertWithTable | null;
  loadingFailureLogs: boolean;
  showConfirmModal: boolean;
  isCreateModalOpen: boolean;
  selectedTableCreateAlertModal: AggTable | null;
  setUpdateErrorMessage: (newUpdateErrorMessage: string) => void;
  onUpdateDataAlert: (
    dataAlert: DataAlertWithTable,
    savableProps: SavableDataAlertProps,
  ) => Promise<DataAlertWithTable>;
  onSetUnsavedSql: (unsaved: boolean) => void;
  onCloseConfirmModal: () => void;
  onConfirmConfirmModal: () => void;
  onCloseDataAlertModal: () => void;
  onCloseFailureLogsModal: () => void;
  onGetFailureLogs: (dataAlert: DataAlertWithTable) => void;
  onDeleteDataAlert: (dataAlert: DataAlertWithTable) => void;
  onSelectDataAlert: (dataAlert: DataAlertWithTable) => void;
  onOpenCreateAlertModal: (eventLocation: CreateAlertSource) => void;
  onCloseCreateAlertModal: (eventLocation: CreateAlertSource) => void;
  setSelectedTableCreateAlertModal: React.Dispatch<React.SetStateAction<AggTable | null>>;
  onSubmitCreateAlertModal: (table: AggTable, eventLocation: CreateAlertSource) => void;
}

export default function ListDataAlertsView(props: ListDataAlertsViewProps) {
  const {
    // Stuff from useDataAlerts()
    errorMessage,
    loading,
    saving,
    dataAlerts,
    selectedDataAlert,
    updateErrorMessage,
    failureLogs,
    failureLogsErrorLines,
    failureLogsDataAlert,
    loadingFailureLogs,
    showConfirmModal,
    setUpdateErrorMessage,
    onUpdateDataAlert,
    onSetUnsavedSql,
    onCloseConfirmModal,
    onConfirmConfirmModal,
    onCloseDataAlertModal,
    onCloseFailureLogsModal,
    onGetFailureLogs,
    onDeleteDataAlert,
    onSelectDataAlert,
    // Stuff from useFilterDataAlerts()
    tableFilterRef,
    alertFilterRef,
    sqlFilterRef,
    tableFilter,
    alertFilter,
    sqlFilter,
    statusFilter,
    onlyEnabledFilter,
    onTableFilterChange,
    onAlertFilterChange,
    onSqlFilterChange,
    onStatusFilterChange,
    onOnlyEnabledFilterChange,
    filteredDataAlerts,
    onFilterBlur,
    // From useCreateDataAlerts()
    isCreateModalOpen,
    selectedTableCreateAlertModal,
    onOpenCreateAlertModal,
    onCloseCreateAlertModal,
    setSelectedTableCreateAlertModal,
    onSubmitCreateAlertModal,
  } = props;

  const isCreateDataAlertsEnabled = useBooleanFlag('create_data_alerts');

  return (
    <CenteredContainer
      maxWidth="95%"
      header={
        <div>
          <div className="f-row-y-center justify-between">
            <h1 className="display-sm">Data Alerts</h1>
            {isCreateDataAlertsEnabled && (
              <Button onClick={() => onOpenCreateAlertModal('DataAlertsPage')} variant="lightAction">
                Create Alert
              </Button>
            )}
          </div>
          <div className="f-row-y-center flex-wrap gap-4 mt-4">
            <TextInput
              ref={alertFilterRef}
              placeholder="Alert"
              name="alertFilter"
              value={alertFilter}
              onChange={onAlertFilterChange}
              onBlur={onFilterBlur}
              className="!w-[200px]"
              maxLength={200}
              autoFocus
            />
            <TextInput
              ref={tableFilterRef}
              placeholder="Table"
              name="tableFilter"
              value={tableFilter}
              onChange={onTableFilterChange}
              onBlur={onFilterBlur}
              className="!w-[200px]"
              maxLength={200}
            />
            <TextInput
              ref={sqlFilterRef}
              placeholder="SQL"
              name="sqlFilter"
              value={sqlFilter}
              onChange={onSqlFilterChange}
              onBlur={onFilterBlur}
              className="!w-[200px]"
              maxLength={200}
            />
            <Listbox
              value={statusFilter}
              onChange={onStatusFilterChange}
              options={[
                { label: 'All Statuses', value: 'all' },
                { label: 'Succeeded', value: 'succeeded' },
                { label: 'Triggered', value: 'failed' },
                { label: 'Never Ran', value: 'never_ran' },
              ]}
              containerClass="!w-[200px]"
              size="large"
            />
            <Checkbox
              label="Only Show Enabled"
              checked={onlyEnabledFilter}
              onChange={onOnlyEnabledFilterChange}
              containerClass="!w-[200px]"
              name="onlyEnabledFilter"
            />
          </div>
        </div>
      }
      loadingError={errorMessage}
      loading={loading}
    >
      {dataAlerts.length === 0 && !errorMessage && (
        <NoObjectsAlert
          detail={
            <p className="font-medium">
              You haven't set up any Data Alerts. Learn how to create an alert{' '}
              <a
                href={alertsHelpURL}
                rel="noopener noreferrer"
                target="_blank"
                className="text-sec-blue-light-700"
              >
                here
              </a>
              .
            </p>
          }
        />
      )}
      {dataAlerts.length > 0 && filteredDataAlerts.length === 0 && (
        <NoMatchesFoundAlert heading="No matching alerts." className="my-4" />
      )}
      {dataAlerts.length > 0 && filteredDataAlerts.length > 0 && (
        <>
          {selectedDataAlert && (
            <EditDataAlertModal
              table={selectedDataAlert.table}
              dataAlert={selectedDataAlert}
              saving={saving}
              updateErrorMessage={updateErrorMessage}
              showConfirmModal={showConfirmModal}
              setUpdateErrorMessage={setUpdateErrorMessage}
              onCloseDataAlertModal={onCloseDataAlertModal}
              onUpdateDataAlert={onUpdateDataAlert}
              onSetUnsavedSql={onSetUnsavedSql}
              onCloseConfirmModal={onCloseConfirmModal}
              onConfirmConfirmModal={onConfirmConfirmModal}
            />
          )}
          <FailureLogsModal
            loadingFailureLogs={loadingFailureLogs}
            failureLogs={failureLogs}
            failureLogsErrorLines={failureLogsErrorLines}
            failureLogsDataAlert={failureLogsDataAlert}
            table={failureLogsDataAlert?.table}
            onCloseFailureLogsModal={onCloseFailureLogsModal}
          />
          <DataAlertsTable
            dataAlerts={filteredDataAlerts}
            isOverallAlertsPage={true}
            analyticsLocation="DataAlertsPage"
            className="mt-4"
            rowProps={{
              onUpdateDataAlert,
              onDeleteDataAlert,
              onSelectDataAlert,
              onGetFailureLogs,
            }}
          />
        </>
      )}
      {isCreateModalOpen && (
        <CreateDataAlertsModal
          selectedTable={selectedTableCreateAlertModal}
          onClose={onCloseCreateAlertModal}
          setSelectedTable={setSelectedTableCreateAlertModal}
          onSubmit={onSubmitCreateAlertModal}
        />
      )}
    </CenteredContainer>
  );
}
