import React, { useState, useCallback, useMemo, useRef, ForwardedRef, ReactNode } from 'react';

import cn from 'classnames';

import { AggTable, Favorite, FavoritesByTableID } from 'api/APITypes';
import Popover from 'components/overlay/Popover/Popover';
import PopperOverlay from 'components/overlay/PopperOverlay/PopperOverlay';
import Tooltip from 'components/overlay/Tooltip/Tooltip';
import { highlightSearch, pickHighlightFilter } from 'components/query/TableExplorer/highlightUtils';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';
import { useUserProfile, useDatabaseAccount } from 'context/AuthContext';
import { HoverClient, HoverClientMap, useHoverCallbacks } from 'hooks/useHoverCallbacks';
import { humanSyncFrequency, lastRunDateAsString } from 'model_helpers/aggTableHelper';
import CreateTagModal from 'pages/tables/ShowTable/SummaryTab/CreateTagModal';
import TagPicker, { guessPickerHeight } from 'pages/tables/ShowTable/SummaryTab/TagPicker';
import { KeywordLists } from 'utils/TableFilter';

import { TableRefMap } from '../WarehouseSearchReducer';

import ColumnHandle from './ColumnHandle/ColumnHandle';
import {
  ColumnKey,
  AdjustableTransformWidths,
  DEFAULT_ADJUSTABLE_WIDTHS,
  nextRightColumn,
  resizeColumnWidths,
  sumFixedPixels,
  getAllRenderedColumnWidths,
} from './ColumnWidthTools';
import ScheduleInfoIcon from './ScheduleInfoIcon';
import ScrollableTableTable from './ScrollableTableTable';
import SnapshotInfoIcon from './SnapshotInfoIcon';
import useTableTableSorting from './useTableTableSorting';

import s from './TableTable.module.css';

const defaultResizeState = {
  columnKey: '' as keyof AdjustableTransformWidths,
  nextRightKey: '' as keyof AdjustableTransformWidths,
  originalWidth: 0,
  originalNextRightWidth: 0,
  mouseDownX: 0,
  columnHandleRef: undefined as React.RefObject<HTMLDivElement> | undefined,
};

export interface TableTableProps {
  tables: AggTable[];
  tableRefMap: TableRefMap;
  hasTransformTables: boolean;
  showIcon: boolean;
  useFullName: boolean;
  usingCache: boolean;
  hasHasOnlyRecents: boolean;
  favoritesByTableID: FavoritesByTableID;
  recentTables: AggTable[];
  filterIncludes: KeywordLists;
  tagCount: number;
  onClickTable(table: AggTable): void;
  onClickTag(tagName: string): void;
  addFavorite: (favorite: Favorite) => void;
  removeFavorite: (favorite: Favorite) => void;
  setSnapshot(table: AggTable, snapshot: boolean): Promise<AggTable>;
  logRecent: (tableID: string) => void;
}

export default function TableTable(props: TableTableProps) {
  const {
    tables,
    tableRefMap,
    hasTransformTables,
    showIcon,
    useFullName,
    usingCache,
    hasHasOnlyRecents,
    favoritesByTableID,
    recentTables,
    filterIncludes,
    tagCount,
    onClickTable,
    onClickTag,
    addFavorite,
    removeFavorite,
    setSnapshot,
    logRecent,
  } = props;
  const {
    userProfile: { company_role },
  } = useUserProfile();

  const databaseType = useDatabaseAccount().type;

  /*****************************************************************************
   * Table Sorting
   ****************************************************************************/
  const { sortedTables, requestSort, getSortIcon } = useTableTableSorting(
    tables,
    recentTables,
    useFullName,
    hasHasOnlyRecents,
  );

  /*****************************************************************************
   * Calculate Column Widths
   ****************************************************************************/
  const [adjustableWidths, setAdjustableWidths] =
    useState<AdjustableTransformWidths>(DEFAULT_ADJUSTABLE_WIDTHS);

  const setColumnWidth = useCallback(
    (columnKey: ColumnKey, nextRightKey: ColumnKey, tableWidth: number, deltaPixels: number) => {
      const newWidths = resizeColumnWidths(
        adjustableWidths,
        columnKey as keyof AdjustableTransformWidths,
        nextRightKey as keyof AdjustableTransformWidths,
        tableWidth,
        deltaPixels,
      );
      setAdjustableWidths(newWidths);
    },
    [adjustableWidths],
  );

  const columnWidths = getAllRenderedColumnWidths(adjustableWidths);

  /*****************************************************************************
   * Resizing Columns
   ****************************************************************************/
  const headerTableRef = useRef<HTMLTableElement>(null);

  // Ref for every column except query column.
  // Query column is excluded because the last column is not resizeable.
  const tableNameRef = useRef();
  const descriptionRef = useRef();
  const scheduledRef = useRef();
  const statusRef = useRef();
  const lastRunRef = useRef();
  const rowCountRef = useRef();
  const snapshotRef = useRef();

  const columnRefs: any = useMemo(
    () => ({
      table: tableNameRef,
      description: descriptionRef,
      scheduled: scheduledRef,
      status: statusRef,
      lastRun: lastRunRef,
      rowCount: rowCountRef,
      snapshot: snapshotRef,
    }),
    [],
  );

  // The state used to control resizing a column during an active drag.
  // It is a ref, not state, to avoid React rerenders.
  const resizeRef = useRef(defaultResizeState);

  const getHandleMouseDown = useCallback(
    (columnKey: keyof AdjustableTransformWidths) => {
      return (
        event: React.MouseEvent<HTMLDivElement>,
        columnHandleRef: React.RefObject<HTMLDivElement>,
      ) => {
        const headerElement = columnRefs[columnKey].current;
        headerElement.classList.add('resizing');
        const originalWidth = headerElement.offsetWidth;
        const nextRightKey = nextRightColumn(columnKey);
        const originalNextRightWidth = columnRefs[nextRightKey].current.offsetWidth;
        resizeRef.current = {
          columnKey,
          nextRightKey,
          originalWidth,
          originalNextRightWidth,
          mouseDownX: event.clientX,
          columnHandleRef,
        };
      };
    },
    [columnRefs],
  );

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const {
        columnKey,
        nextRightKey,
        originalWidth,
        originalNextRightWidth,
        mouseDownX,
        columnHandleRef,
      } = resizeRef.current;
      if (columnKey && headerTableRef.current && columnHandleRef && columnHandleRef.current) {
        let delta = event.clientX - mouseDownX;

        // Don't let user make the dragged column too small
        const MIN_COLUMN_SIZE = 40;
        if (originalWidth + delta < MIN_COLUMN_SIZE) {
          delta = originalWidth - MIN_COLUMN_SIZE;
        }

        // Don't let the user make the adjacent column too small.
        if (originalNextRightWidth - delta < MIN_COLUMN_SIZE) {
          delta = originalNextRightWidth - MIN_COLUMN_SIZE;
        }

        // Warning:
        // If React rerenders in the middle of a drag, the changes this line
        // made directly to the DOM will get rerendered ontop of.
        // The alternative would be to set React state as the user drags and resize by rerendering React.
        // Trying the less rerender option first.
        // If React were to rerender during a drag and set an inappropriate width,
        // the next call to onMouseMove would probably set the width right back to the drag size.
        // The user might not notice.
        const newWidth = originalWidth + delta;
        const dynmaicSize = headerTableRef.current.offsetWidth - sumFixedPixels;
        const percentWidth = (newWidth / dynmaicSize) * 100;
        columnRefs[columnKey].current.style.width = percentWidth + '%';

        // Substract width from adjacent column
        const newNextRightWidth = originalNextRightWidth - delta;
        const percentNextRightWidth = (newNextRightWidth / dynmaicSize) * 100;
        columnRefs[nextRightKey].current.style.width = percentNextRightWidth + '%';

        // The drag might alter the table's height when text overflows to next line.
        // Resize the ColumnHandle accordingly.
        columnHandleRef.current.style.height = headerTableRef.current.offsetHeight + 'px';
      }
    },
    [columnRefs, headerTableRef],
  );

  const handleMouseUp = useCallback(
    (event: React.MouseEvent) => {
      const { columnKey, nextRightKey, originalWidth, mouseDownX } = resizeRef.current;
      if (columnKey && columnRefs[columnKey]) {
        const headerElement = columnRefs[columnKey].current;
        headerElement.classList.remove('resizing');
        resizeRef.current = defaultResizeState;

        if (headerTableRef.current) {
          // Resize all the SchemaExpandos
          let deltaPixel = event.clientX - mouseDownX;
          const tableWidth = headerTableRef.current.offsetWidth;
          const newColumnWidth = headerElement.offsetWidth;
          setColumnWidth(columnKey, nextRightKey, tableWidth, deltaPixel);

          analytics.track('Warehouse SetColumnWidth', {
            columnKey,
            originalWidth,
            newColumnWidth,
          });
        }
      }
    },
    [columnRefs, setColumnWidth],
  );

  /*****************************************************************************
   * Configure Hover Client for description and ago times.
   ****************************************************************************/
  const [agoHoverRef, setAgoHoverRef] = useState<React.RefObject<HTMLElement> | null>(null);
  const [descHoverRef, setDescHoverRef] = useState<React.RefObject<HTMLElement> | null>(null);
  const [scheduledHoverRef, setScheduledHoverRef] = useState<React.RefObject<HTMLElement> | null>(null);
  const [tagPickerHoverRef, setTagPickerHoverRef] = useState<React.RefObject<HTMLDivElement> | null>(
    null,
  );
  const [agoTime, setAgoTime] = useState('');
  const [hoverDesc, setHoverDesc] = useState('');
  const [hoverScheduled, setHoverScheduled] = useState('');

  const descriptionHighlightFilter = useMemo(
    () => pickHighlightFilter(filterIncludes, 'description'),
    [filterIncludes],
  );

  // Add one mouse listener to the entire page since putting a hover listener
  // on every cell is really expensive.
  const hoverClients = useMemo(() => {
    const discClient: HoverClient = {
      onEnter: (refKey: string) => {
        const uState = tableRefMap[refKey];
        if (uState) {
          setDescHoverRef(uState.descRef || null);
          setHoverDesc(uState.table.description);
        }
      },
      onExit: () => {
        setDescHoverRef(null);
      },
    };

    const scheduledClient: HoverClient = {
      onEnter: (refKey: string) => {
        const uState = tableRefMap[refKey];
        if (uState) {
          setScheduledHoverRef(uState.scheduledRef || null);
          setHoverScheduled(humanSyncFrequency(uState.table));
        }
      },
      onExit: () => {
        setScheduledHoverRef(null);
      },
    };

    const agoClient: HoverClient = {
      onEnter: (refKey: string) => {
        const uState = tableRefMap[refKey];
        if (uState) {
          const table = uState.table;
          const lastRun = lastRunDateAsString(table);
          if (lastRun) {
            setAgoHoverRef(uState.agoRef);
            setAgoTime(lastRun);
          }
        }
      },
      onExit: () => {
        setAgoHoverRef(null);
      },
    };

    const tagPickerClient: HoverClient = {
      onEnter: (refKey: string) => {
        const uState = tableRefMap[refKey];
        if (uState) {
          setTagPickerHoverRef(uState.tagPickerRef || null);
        }
      },
      onExit: () => {
        setTagPickerHoverRef(null);
      },
    };

    const hoverClients: HoverClientMap = {
      dk: discClient,
      sk: scheduledClient,
      ahk: agoClient,
      tpk: tagPickerClient,
    };

    return hoverClients;
  }, [tableRefMap]);
  const mouseRef = useHoverCallbacks(hoverClients);

  /*****************************************************************************
   * TagPicker State and Event Handlers
   * Note:
   * We tried rendering the TagPicker in a TableExpando, but tables clip all content
   * when overflow:hidden is set event when the content is position:absolute.
   * So, you can't have the TagPicker overflow the description cell.
   ****************************************************************************/
  const [tagPickerTable, setTagPickerTable] = useState<AggTable | null>(null);
  const [tagPickerRef, setTagPickerRef] = useState<React.RefObject<HTMLDivElement> | null>(null);
  const [showTagPicker, setShowTagPicker] = useState(false);
  const [showCreateTagModal, setShowCreateTagModal] = useState(false);

  const handleOpenTagPicker = useCallback(
    (refKey: string) => {
      const refState = tableRefMap[refKey];
      if (refState) {
        setTagPickerRef(refState.tagPickerRef || null);
        setTagPickerTable(refState.table);
        setShowTagPicker(true);
        analytics.track('Warehouse OpenTagPicker');
      }
    },
    [tableRefMap],
  );

  const handleCloseTagPicker = () => {
    setTagPickerTable(null);
    setShowTagPicker(false);
    analytics.track('Warehouse CloseTagPicker');
  };

  const handleAfterTagPickerSaveTags = () => {
    if (tagPickerTable) {
      logRecent(tagPickerTable.id);
    }
    setTagPickerTable(null);
    setShowTagPicker(false);
    // Event is logged by TagPicker
  };

  const handleOpenCreateTagModal = () => {
    setShowTagPicker(false);
    setShowCreateTagModal(true);
    analytics.track('Warehouse OpenCreateTagModal');
  };

  const handleAfterCreateTag = () => {
    setTagPickerTable(null);
    setShowCreateTagModal(false);
    // Event is logged in CreateTagModal
  };

  const handleCancelCreateTagModal = () => {
    setTagPickerTable(null);
    setShowCreateTagModal(false);
    analytics.track('Warehouse CancelCreateTagModal');
  };

  const tagPickerCoords = useMemo(() => {
    if (mouseRef && mouseRef.current && tagPickerRef && tagPickerRef.current) {
      /*
      1. The tagPicker is positioned absolutey relative to the mouseRef.
      2. We'd rather not put the TagPicker inside the ScrollableTableTable.
         Plus, I don't think you could scroll the ScrollableTableTable once
         the TagPicker overlay was rendered.
         So, we need to make sure the TagPicker renders entirely inside the viewport
         because the user cannot scroll the viewport to get to the bottom of the TagPicker.
      3. We are using viewport coordinates because el.offsetHeight is not useful
         with the huge number of non-static positioned elmenents in this DOM element sub-tree.
      */
      const mouseEl = mouseRef.current;
      const tagPickerEl = tagPickerRef.current;
      const mouseRect = mouseEl.getBoundingClientRect();
      const tagPickerRect = tagPickerEl.getBoundingClientRect();
      const tagPickerHeight = guessPickerHeight(tagCount);
      const leftOffset = tagPickerRect.left - mouseRect.left;
      const topOffset = tagPickerRect.top - mouseRect.top;
      // Some positioning adjustments that are the width of objects
      // plus some hand tuned spacing.
      const TAG_PICKER_WIDTH = 360;
      const TAG_ICON_HEIGHT = 20;
      const LEFT_SHIFT = -TAG_PICKER_WIDTH;
      const TOP_SHIFT = TAG_ICON_HEIGHT / 2;
      const MIN_SPACE_BETWEEN_PICKER_AND_VIEWPORT_BOTTOM = 16;
      // left and top are relative to mouseRect
      const left = leftOffset + LEFT_SHIFT;
      let top = topOffset + TOP_SHIFT;
      // viewportX are relative to viewport
      const viewportTop = top + mouseRect.top;
      const viewportBottomOfPicker = viewportTop + tagPickerHeight;
      // Do not let tagPicker extend off the bottom of the viewport.
      if (viewportBottomOfPicker > window.innerHeight - MIN_SPACE_BETWEEN_PICKER_AND_VIEWPORT_BOTTOM) {
        const overflow =
          viewportBottomOfPicker - window.innerHeight + MIN_SPACE_BETWEEN_PICKER_AND_VIEWPORT_BOTTOM;
        top -= overflow;
      }
      return { left, top };
    } else {
      return { left: 0, top: 0 };
    }
  }, [mouseRef, tagPickerRef, tagCount]);

  /*****************************************************************************
   * TableHeader component with closure around some functions so we don't have
   * to pass a million props to each TableHeader JSX element.
   ****************************************************************************/
  interface TableHeaderProps {
    columnKey: keyof AdjustableTransformWidths | 'snapshot';
    label: ReactNode;
    width: string;
    hasHandle: boolean;
  }

  const TableHeader = React.forwardRef<HTMLTableCellElement, TableHeaderProps>(
    (props: TableHeaderProps, forwardedRef: ForwardedRef<HTMLTableCellElement>) => {
      const { columnKey, label, width, hasHandle } = props;
      let sortIcon = getSortIcon(columnKey);

      // Hide the sortIcon on columns that are too narrow to have room for it.
      // sortIcons that are clipped by overflow:hidden look wonky.
      if (columnKey === 'lastRun' || columnKey === 'snapshot') {
        sortIcon = null;
      }

      return (
        <th
          key={columnKey}
          ref={forwardedRef}
          onClick={() => requestSort(columnKey)}
          className="hover:cursor-pointer"
          style={{ width }}
        >
          <div className={cn('f-row-y-center', { 'justify-end': columnKey === 'rowCount' })}>
            <div>{label}</div>
            {sortIcon && <div className="w-[16px] ml-2">{sortIcon}</div>}
          </div>
          {hasHandle && (
            <ColumnHandle
              onMouseDown={getHandleMouseDown(columnKey as keyof AdjustableTransformWidths)}
            />
          )}
        </th>
      );
    },
  );

  return (
    <div ref={mouseRef} className="w-full h-full bg-white relative">
      {!hasTransformTables && (
        <FixMeAlert
          heading="Looks like you don't have any transforms yet."
          detail="Transforms save your most actionable data into a new table that is concise and easily digested."
          imgSrc="/images/diagrams/transform.svg"
          cta="Create Transform"
          ctaLink="/transforms/add"
          dataTrack="Warehouse NoTransformsCreateTransform"
          disabled={company_role === 'viewer'}
        />
      )}
      <div className="w-full h-full">
        <table
          ref={headerTableRef}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          className={'blueGrayHeaderTable ' + s.table}
        >
          <thead>
            <tr>
              <TableHeader
                columnKey="table"
                label="Table"
                ref={columnRefs.table}
                width={columnWidths.table}
                hasHandle={true}
              />
              <TableHeader
                columnKey="description"
                label="Description"
                ref={columnRefs.description}
                width={columnWidths.description}
                hasHandle={true}
              />
              <TableHeader
                columnKey="scheduled"
                label={
                  <div className="f-row-y-center">
                    <span>Scheduled</span>
                    <span className="ml-1">
                      <ScheduleInfoIcon />
                    </span>
                  </div>
                }
                ref={columnRefs.scheduled}
                width={columnWidths.scheduled}
                hasHandle={true}
              />
              <TableHeader
                columnKey="status"
                label="Last Run Status"
                ref={columnRefs.status}
                width={columnWidths.status}
                hasHandle={true}
              />
              <TableHeader
                columnKey="lastRun"
                label={'Last Run/Sync'}
                ref={columnRefs.lastRun}
                width={columnWidths.lastRun}
                hasHandle={true}
              />
              <TableHeader
                columnKey="rowCount"
                label="Row Count"
                ref={columnRefs.rowCount}
                width={columnWidths.rowCount}
                hasHandle={false}
              />
              {databaseType !== 'bigquery' && (
                <TableHeader
                  columnKey="snapshot"
                  label={
                    <div className="f-center">
                      <span>Snapshot</span>
                      <span className="ml-1">
                        <SnapshotInfoIcon />
                      </span>
                    </div>
                  }
                  ref={columnRefs.snapshot}
                  width={columnWidths.snapshot}
                  hasHandle={false}
                />
              )}
              <th key="query" style={{ width: columnWidths.query }}></th>
            </tr>
          </thead>
        </table>
        <ScrollableTableTable
          tables={sortedTables}
          tableRefMap={tableRefMap}
          columnWidths={columnWidths}
          showIcon={showIcon}
          useFullName={useFullName}
          usingCache={usingCache}
          favoritesByTableID={favoritesByTableID}
          filterIncludes={filterIncludes}
          companyRole={company_role}
          addFavorite={addFavorite}
          removeFavorite={removeFavorite}
          setSnapshot={setSnapshot}
          logRecent={logRecent}
          onOpenTagPicker={handleOpenTagPicker}
          onClickTag={onClickTag}
          onClickTable={onClickTable}
        />
      </div>

      <PopperOverlay
        target={agoHoverRef}
        show={agoHoverRef !== null}
        placement="top"
        renderPopper={(renderPopperProps) => {
          return <Tooltip tip={agoTime} {...renderPopperProps} />;
        }}
      />
      <PopperOverlay
        target={descHoverRef}
        show={descHoverRef !== null}
        placement="top"
        renderPopper={(renderPopperProps) => {
          return (
            <Popover
              content={highlightSearch(hoverDesc, descriptionHighlightFilter)}
              popoverProps={{ className: s.descriptionPopover, style: { maxWidth: '400px' } }}
              {...renderPopperProps}
            />
          );
        }}
      />
      <PopperOverlay
        target={scheduledHoverRef}
        show={scheduledHoverRef !== null}
        placement="top"
        renderPopper={(renderPopperProps) => {
          return <Tooltip tip={hoverScheduled} {...renderPopperProps} />;
        }}
      />
      <PopperOverlay
        target={tagPickerHoverRef}
        show={tagPickerHoverRef !== null}
        placement="top"
        renderPopper={(renderPopperProps) => {
          return <Tooltip tip="Edit Tags" {...renderPopperProps} />;
        }}
      />
      {showTagPicker && tagPickerTable && tagPickerRef && tagPickerRef.current && (
        <TagPicker
          table={tagPickerTable}
          eventPage="Warehouse"
          style={{ left: tagPickerCoords.left, top: tagPickerCoords.top }}
          onClosePicker={handleCloseTagPicker}
          onCreateNew={handleOpenCreateTagModal}
          onAfterSaveTags={handleAfterTagPickerSaveTags}
        />
      )}
      {showCreateTagModal && tagPickerTable && (
        <CreateTagModal
          table={tagPickerTable}
          eventPage="Warehouse"
          onAfterCreate={handleAfterCreateTag}
          onCancel={handleCancelCreateTagModal}
        />
      )}
    </div>
  );
}
