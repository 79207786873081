/*
I needed a reusable "Does this table have a certain tab logic?"
Ideally, this will get used in ShowTable after some more testing.
*/
import { AggTable } from 'api/tableAPI';
import { LabeledTab } from 'components/layouts/parts/TabRow/TabRow';

import { TabKey } from './ShowTable';

// Shared tabs mapped to variables so spellings are all changed at once.
const SUMMARY_TAB: LabeledTab = { key: 'summary', label: 'Summary' };
const PIPELINE_TAB: LabeledTab = { key: 'pipeline', label: 'Pipeline' };
const ALERTS_TAB: LabeledTab = { key: 'alerts', label: 'Alerts' };
const SYNC_TAB: LabeledTab = { key: 'sync', label: 'Sheet Sync' };
const USER_ACTIONS_TAB: LabeledTab = { key: 'userActions', label: 'User Actions' };
const SETTINGS_TAB: LabeledTab = { key: 'settings', label: 'Settings' };

const LABELED_TRANFORM_TABS: LabeledTab[] = [
  SUMMARY_TAB,
  { key: 'transform', label: 'Transform' },
  { key: 'run', label: 'Runs' },
  PIPELINE_TAB,
  ALERTS_TAB,
  { key: 'versionHistory', label: 'Versions' },
  SYNC_TAB,
  USER_ACTIONS_TAB,
  SETTINGS_TAB,
];

const LABELED_SNAPSHOT_TABS: LabeledTab[] = [
  SUMMARY_TAB,
  PIPELINE_TAB,
  ALERTS_TAB,
  SYNC_TAB,
  USER_ACTIONS_TAB,
  SETTINGS_TAB,
];

const LABELED_CSV_TABS: LabeledTab[] = [
  SUMMARY_TAB,
  PIPELINE_TAB,
  { key: 'uploads', label: 'Uploads' },
  USER_ACTIONS_TAB,
  SETTINGS_TAB,
];

const LABELED_HAS_CONNECTOR_TABS: LabeledTab[] = [
  SUMMARY_TAB,
  PIPELINE_TAB,
  ALERTS_TAB,
  SYNC_TAB,
  USER_ACTIONS_TAB,
  SETTINGS_TAB,
];

const LABELED_DBT_TABS: LabeledTab[] = [
  SUMMARY_TAB,
  { key: 'templatedCode', label: 'DBT Templated Code' },
  { key: 'compiledSql', label: 'DBT Compiled SQL' },
  PIPELINE_TAB,
];

const TRANSFORM_TAB_KEYS = LABELED_TRANFORM_TABS.map((t) => t.key);
const SNAPSHOT_TAB_KEYS = LABELED_SNAPSHOT_TABS.map((t) => t.key);
const CSV_TAB_KEYS = LABELED_CSV_TABS.map((t) => t.key);
const HAS_CONNECTOR_TAB_KEYS = LABELED_HAS_CONNECTOR_TABS.map((t) => t.key);
const DBT_TAB_KEYS = LABELED_DBT_TABS.map((t) => t.key);

export function hasTab(table: AggTable, tab: TabKey) {
  if (table.type === 'transform') {
    return TRANSFORM_TAB_KEYS.includes(tab);
  }
  if (table.type === 'snapshot') {
    return SNAPSHOT_TAB_KEYS.includes(tab);
  }
  if (table.type === 'csv_upload') {
    return CSV_TAB_KEYS.includes(tab);
  }
  const hasConnector = table.type === 'unmanaged' && table.connector !== null;
  if (hasConnector) {
    return HAS_CONNECTOR_TAB_KEYS.includes(tab);
  }
  if (table.type === 'dbt') {
    return DBT_TAB_KEYS.includes(tab);
  }

  // At this point we have a table that was created by an agent other than Mozart.
  if (tab === 'summary') {
    return true;
  }
  if (tab === 'viewSql') {
    const hasViewSqlTab = !!table.view_sql && table.type === 'unmanaged';
    if (hasViewSqlTab) {
      return true;
    }
  }
  if (tab === 'pipeline') {
    return true;
  }

  return false;
}
