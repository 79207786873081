import CodeEditor, {
  CodeEditorProps,
  editorHeight,
} from 'components/inputs/basic/CodeEditor/CodeEditor';

import CopyButton from '../../basic/Button/CopyButton';

// Re-export this so callers can use it.
export { editorHeight };

interface CodeCopyProps extends CodeEditorProps {
  label: string;
  containerClass?: string;
}

/**
 * CodeEditor with copy to clipboard functionality.
 * */
const CodeCopy = ({ label, value, containerClass, ...codeEditorProps }: CodeCopyProps) => {
  const copyValue = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <div className={containerClass}>
      <div className="mb-1 f-between">
        <label className="block text-purple text-xs font-medium tracking-[1px] uppercase">{label}</label>
        <CopyButton onClick={copyValue} />
      </div>
      <div>
        <CodeEditor value={value} {...codeEditorProps} />
      </div>
    </div>
  );
};

export default CodeCopy;
