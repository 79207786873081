import React from 'react';

import cn from 'classnames';

export type ButtonSize =
  | 'small'
  // usage_v2 introduced a size that is not the same as standard button sizes.
  // TODO: Make this aspect of the design system more consistent.
  | 'usage_v2';

export interface OptionSelectorOption {
  displayName: string | React.ReactNode;
  value: string;
}

interface OptionSelectorProps {
  selectedOption: string;
  options: OptionSelectorOption[];
  size?: ButtonSize;
  containerClass?: string;
  setSelectedOption: (newValue: string) => void;
}

const OptionSelector = (props: OptionSelectorProps) => {
  const { selectedOption, options, size = 'usage_v2', containerClass, setSelectedOption } = props;

  const handleButtonClick = (value: string) => {
    setSelectedOption(value);
  };

  return (
    <div className={cn('w-fit f-row-y-center bg-pri-gray-50 rounded', containerClass)}>
      {options.map((option) => {
        const selected = selectedOption === option.value;
        return (
          <button
            key={option.value}
            className={cn('bg-pri-gray-50 text-sm font-medium border rounded', {
              'py-2 px-4 ': size === 'usage_v2',
              'py-[3px] px-[15px]': size === 'small',
              'text-pri-gray-700 border-pri-gray-700': selected,
              'text-pri-gray-500 border-transparent hover:bg-pri-gray-100': !selected,
            })}
            onClick={() => handleButtonClick(option.value)}
          >
            {option.displayName}
          </button>
        );
      })}
    </div>
  );
};

export default OptionSelector;
