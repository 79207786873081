export type LayoutCondition = { condition: RulesEnum; conditionArgs: any };
export type RulesEnum =
  | 'hasBillingData'
  | 'hasCurrentInvoice'
  | 'isUsage'
  | 'planTypeMostRecentIncludes'
  | 'recentAllotmentGrainIncludes';

export type UsageMode = 'monthly' | 'annual' | 'cost' | 'sonata';
export type ComponentFlags = {
  [key: string]: {
    mode?: UsageMode;
  };
};

export type LayoutModelType = {
  // plain language description of the layout
  description: string;
  // Do all or just 1 of the conditions need to be met
  operator: 'and' | 'or';
  // arguments to be passed to the condition
  conditions: LayoutCondition[];
  // determines which components to show. If it's not in the results, it doesn't get shown
  result: ComponentFlags;
};

// Order matters the first match wins.
// The conditions determeine which layout to use.
// The result object determines which components to show for the chosen layout
// TODO: add a priority field to the layout model to allow for resolving multiple layoout matches
const FEATURE_MODELS: LayoutModelType[] = [
  // No data must be first because the first is what we show when there is no matching models (shouldn't happen)
  {
    description: 'No Data from /billing_monthly api',
    operator: 'or',
    conditions: [
      { condition: 'hasBillingData', conditionArgs: false },
      { condition: 'hasCurrentInvoice', conditionArgs: false },
    ],
    result: {
      EmptyMetricsCard: {},
    },
  },
  {
    description: 'Sonata Free',
    operator: 'and',
    conditions: [
      { condition: 'hasBillingData', conditionArgs: true },
      { condition: 'isUsage', conditionArgs: false },
      {
        condition: 'planTypeMostRecentIncludes',
        conditionArgs: ['Sonata Free', 'Sonata'],
      },
    ],
    result: {
      PlanSummary: { mode: 'sonata' },
      MAR: { mode: 'sonata' },
      Compute: { mode: 'sonata' },
    },
  },
  {
    description: 'Sonata Usage',
    operator: 'and',
    conditions: [
      { condition: 'hasBillingData', conditionArgs: true },
      {
        condition: 'planTypeMostRecentIncludes',
        conditionArgs: ['Sonata Usage'],
      },
    ],
    result: {
      PlanSummary: { mode: 'monthly' },
      MAR: { mode: 'monthly' },
      Compute: { mode: 'monthly' },
    },
  },
  {
    description: 'Usage Plan (non-sonata)',
    operator: 'and',
    conditions: [
      { condition: 'hasBillingData', conditionArgs: true },
      { condition: 'isUsage', conditionArgs: true },
    ],
    result: {
      CurrentCycleDates: {},
      PlanSummary: { mode: 'cost' },
      MAR: { mode: 'cost' },
      Compute: { mode: 'cost' },
      Storage: { mode: 'cost' },
      CloudService: { mode: 'cost' },
    },
  },
  {
    description: 'Monthly calendar based billing',
    operator: 'and',
    conditions: [
      { condition: 'hasBillingData', conditionArgs: true },
      { condition: 'isUsage', conditionArgs: false },
      {
        condition: 'recentAllotmentGrainIncludes',
        conditionArgs: ['Monthly'],
      },
    ],
    result: {
      CurrentCycleDates: {},
      PlanSummary: { mode: 'monthly' },
      MAR: { mode: 'monthly' },
      Compute: { mode: 'monthly' },
    },
  },
  {
    description: 'Annual calendar based billing',
    operator: 'and',
    conditions: [
      { condition: 'hasBillingData', conditionArgs: true },
      { condition: 'isUsage', conditionArgs: false },
      {
        condition: 'recentAllotmentGrainIncludes',
        conditionArgs: ['Annual'],
      },
    ],
    result: {
      CurrentCycleDates: {},
      PlanSummary: { mode: 'annual' },
      MAR: { mode: 'annual' },
      Compute: { mode: 'annual' },
    },
  },
];

export default FEATURE_MODELS;
