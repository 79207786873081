/**
 * This file is the definitive source of logged in truth.
 *
 * 1. You can use the API if you have a token in localStorage.
 * 2. To be considered logged in you must also have a user_profile in localStorage.
 *
 * NO OTHER FILES SHOULD EDIT OR READ THIS FILE'S localStorage VALUES!!!
 **/
import { LAST_TIP_KEY } from 'components/layouts/parts/ProtipSpinner/Protips';

import { UserProfile } from './APITypes';

const TOKEN_KEY = '__token__';
const USER_PROFILE_KEY = '__profile__/V2';

interface IToken {
  token: string;
  expiry: string;
}

function setLocalStorageToken(token: IToken) {
  window.localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
}

function setLocalStorageUserProfile(profile: UserProfile) {
  window.localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(profile));
}

function setHasLoggedIn() {
  window.localStorage.setItem('has_logged_in', JSON.stringify(true));
}

function getHasLoggedIn() {
  const strItem = window.localStorage.getItem('has_logged_in');
  return strItem ? (JSON.parse(strItem) as boolean) : false;
}

function getLocalStorageToken(): string | undefined {
  const value = window.localStorage.getItem(TOKEN_KEY);
  if (value) {
    const { token, expiry } = JSON.parse(value);
    if (token && expiry && isTokenValid(expiry)) {
      return token;
    }
  }
  return;
}

function isTokenValid(expiry: string) {
  const tokenExpiry = new Date(expiry).getTime();
  const now = Date.now();
  return now < tokenExpiry;
}

function getLocalStorageUserProfile(): UserProfile | undefined {
  const value = window.localStorage.getItem(USER_PROFILE_KEY);
  if (value) {
    return JSON.parse(value);
  }
  return;
}

function deleteLoginState() {
  // allows us to listen to changes in the token and log out other tabs
  window.localStorage.removeItem(TOKEN_KEY);

  // Experiments and protipIndex were getting cleared at log out.
  // We wanted to store experiments so that the experiment list is retained after the user has logged out.
  // Likewise, we don't want to forget the protipIndex.
  const serializedExperiments = window.localStorage.getItem('experiments');
  const serializedProtipIndex = window.localStorage.getItem(LAST_TIP_KEY);
  const serializedHasLoggedIn = window.localStorage.getItem('has_logged_in');

  // Then ensure that we clear everything else in storage.
  window.localStorage.clear();

  // Restore the keys we saved.
  if (serializedExperiments) {
    window.localStorage.setItem('experiments', serializedExperiments);
  }
  if (serializedProtipIndex) {
    window.localStorage.setItem(LAST_TIP_KEY, serializedProtipIndex);
  }
  if (serializedHasLoggedIn) {
    window.localStorage.setItem('has_logged_in', serializedHasLoggedIn);
  }
}

// Listen to StorageEvent.
// If another tab logs out, also log out this tab.
window.onstorage = (event: StorageEvent) => {
  if (event.key === TOKEN_KEY && Boolean(event.oldValue) && !Boolean(event.newValue)) {
    // The other tab already cleared localStorage.
    // Page refresh to reset app memory state.
    window.location.href = '/login';
  }
};

export {
  deleteLoginState,
  getLocalStorageToken,
  getLocalStorageUserProfile,
  isTokenValid,
  setLocalStorageToken,
  setLocalStorageUserProfile,
  setHasLoggedIn,
  getHasLoggedIn,
};
