import React from 'react';

import { AIPermissionModalProvider } from 'model_layer/AIPermissionModalContext';
import useCompanySetupGuideContext from 'model_layer/useCompanySetupGuideContext';
import useNotifcationsContext from 'model_layer/useNotificationsContext';
import useOnboardingPopoverTourContext from 'model_layer/useOnboardingPopoverTourContext';
import useColumnContext from 'model_layer/useSearchColumnContext';
import useTableContext from 'model_layer/useTableContext';
import useTableModelsContext from 'model_layer/useTableModelsContext';
import useTransformContext from 'model_layer/useTransformContext';
import useUpdatePolling from 'model_layer/useUpdatePolling';
import useUserPreferencesContext from 'model_layer/useUserPreferencesContext';

import AbstractAuthenticatedProviders from './AbstractAuthenticatedProviders';

interface AuthenticatedProviderProps {
  children: React.ReactNode;
}

export default function APIAuthenticatedProviders(props: AuthenticatedProviderProps) {
  // Fetch data from API and compute global app state.
  const userPreferencesContext = useUserPreferencesContext();
  const notificationsContext = useNotifcationsContext();
  const tableContext = useTableContext();
  const transformContext = useTransformContext();
  const tableModelsContext = useTableModelsContext(tableContext, transformContext);
  const searchColumnContext = useColumnContext();
  const onboardingPopoverTourContext = useOnboardingPopoverTourContext(userPreferencesContext);
  const companySetupGuideContext = useCompanySetupGuideContext(onboardingPopoverTourContext);

  // Poll API for updates to global app state.
  useUpdatePolling({
    tablesIsLoading: tableContext.isLoading,
    lastTablesChange: tableContext.lastTablesChange,
    refreshTables: tableContext.refreshTables,
    columnsIsLoading: searchColumnContext.isLoading,
    lastColumnsChange: searchColumnContext.lastColumnsChange,
    refreshColumns: searchColumnContext.refreshColumns,
    transformsIsLoading: transformContext.isLoading,
    lastTransformsChange: transformContext.lastTransformsChange,
    refreshTransforms: transformContext.refreshTransforms,
  });

  return (
    <AbstractAuthenticatedProviders
      userPreferencesContext={userPreferencesContext}
      notificationsContext={notificationsContext}
      tableContext={tableContext}
      tableModelsContext={tableModelsContext}
      searchColumnContext={searchColumnContext}
      onboardingPopoverTourContext={onboardingPopoverTourContext}
      companySetupGuideContext={companySetupGuideContext}
      AIPermissionModalProvider={AIPermissionModalProvider}
    >
      {props.children}
    </AbstractAuthenticatedProviders>
  );
}
