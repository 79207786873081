/*******************************************************************************
 * All Promotion Banners should store similar state.
 * This file makes sure future promotion banners follow the same interface.
 ******************************************************************************/
import { UserProfile } from 'api/APITypes';
import {
  UserPreferencesContextInterface,
  UserPreferencesInterface,
} from 'model_layer/UserPreferencesContext';

import { ConcretePromotionBannerProps } from './AuthHeaderView/banners/PromotionBanner/AbstractPromotionBanner';
import {
  AI_ASSISTANT_EVENT_LOCATION,
  AI_ASSISTANT_PROMOTION_KEY,
} from './AuthHeaderView/banners/PromotionBanner/AIAssistantPromotionBanner';
import {
  REFERRAL_EVENT_LOCATION,
  REFERRAL_PROMOTION_KEY,
} from './AuthHeaderView/banners/PromotionBanner/ReferralPromotionBanner';
import { BannerKey } from './useBannerState';

// Promotions are shown until the user closes them.
// Every promotion has its own key that is saved in UserPreferences when the promotion is closed.
// A promotion is not shown if this key is saved to UserPreferences.
export type PromotionKey = `hasSeenPromotion${string}` & keyof UserPreferencesInterface;

/*******************************************************************************
 * Methods that decide if a promotion should be shown.
 ******************************************************************************/
// Every implemenation of a PromotionBanner should call this method with its promotionKey.
export function abstractShowPromotionBanner(
  promotionKey: PromotionKey,
  userProfile: UserProfile,
  userPreferencesContext: UserPreferencesContextInterface,
  minUserAgeDays: number | null = 30,
) {
  const { initialLoadComplete, userPreferences } = userPreferencesContext;
  const now = new Date().getTime();
  const oneDay = 24 * 60 * 60 * 1000;

  // Do not bother new users with promotions unless we explicitly pass minUserAgeDays = null
  let userIsOldEnough: boolean;
  if (minUserAgeDays === null) {
    userIsOldEnough = true;
  } else {
    const minUserAge = minUserAgeDays * oneDay;
    userIsOldEnough = now > new Date(userProfile.created_at).getTime() + minUserAge;
  }

  // If the user closed a different promotion banner, don't show another for at least a day.
  const hasRecentlyClosedAPromotionBanner =
    !!userPreferences.lastPromotionBannerDismissedTime &&
    userPreferences.lastPromotionBannerDismissedTime + oneDay > now;

  return (
    userIsOldEnough &&
    initialLoadComplete &&
    userPreferences[promotionKey] !== true &&
    !hasRecentlyClosedAPromotionBanner
  );
}

export function showReferralPromotionBanner(
  userProfile: UserProfile,
  userPreferencesContext: UserPreferencesContextInterface,
) {
  return abstractShowPromotionBanner(REFERRAL_PROMOTION_KEY, userProfile, userPreferencesContext);
}

export function showAIAssistantPromotionBanner(
  userProfile: UserProfile,
  userPreferencesContext: UserPreferencesContextInterface,
) {
  return abstractShowPromotionBanner(
    AI_ASSISTANT_PROMOTION_KEY,
    userProfile,
    userPreferencesContext,
    null,
  );
}

/*******************************************************************************
 * Pick/calculate the props that should be passed to the PromotionBanner component.
 ******************************************************************************/
export function pickPromotionBannerProps(
  banner: BannerKey,
  userProfile: UserProfile,
  userPreferencesContext: UserPreferencesContextInterface,
) {
  let promotionKey = 'NOT_SET';
  let eventLocation = 'NOT_SET';
  if (banner === 'ReferralPromotion') {
    promotionKey = REFERRAL_PROMOTION_KEY;
    eventLocation = REFERRAL_EVENT_LOCATION;
  } else if (banner === 'AIAssistantPromotion') {
    promotionKey = AI_ASSISTANT_PROMOTION_KEY;
    eventLocation = AI_ASSISTANT_EVENT_LOCATION;
  }

  const onClose = () => {
    userPreferencesContext.updateUserPreferences({
      [promotionKey]: true,
      lastPromotionBannerDismissedTime: new Date().getTime(),
    });
    analytics.track(`${eventLocation} Close`);
  };

  const props: ConcretePromotionBannerProps = {
    eventLocation,
    onClose,
  };

  return props;
}
