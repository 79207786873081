import { SigmaIntegration } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import IconButton from 'components/inputs/basic/Button/IconButton';

interface YouAreAlreadyUsingSigmaProps {
  sigmaIntegration: SigmaIntegration;
  handleShowSnowflakeInSigmaClick: () => void;
}

const YouAreAlreadyUsingSigma = (props: YouAreAlreadyUsingSigmaProps) => {
  const {
    sigmaIntegration: { app_url, database_connection_id },
    handleShowSnowflakeInSigmaClick,
  } = props;
  return (
    <div className="p-6 bg-[--sec-blue-50] text-pri-gray-500">
      <h2 className="text-lg font-medium">You have successfully connected Mozart Data to Sigma.</h2>
      <p className="mt-2">
        Find Sigma in the top navigation bar and via the button below. More integration features are
        coming soon!
      </p>
      {database_connection_id && (
        <p className="mt-2">
          Your{' '}
          <a
            href={`${app_url}/admin/connections/${database_connection_id}`}
            target="_blank"
            rel="noreferrer"
            className="text-purple"
          >
            Mozart Data Snowflake
          </a>{' '}
          is connected to Sigma.
        </p>
      )}
      <a href={app_url} target="_blank" rel="noreferrer">
        <IconButton
          icon="ChevronRight"
          text="GO TO SIGMA"
          iconLast={true}
          variant="lightDullTransparent"
          size="small"
          className="mt-2"
          noClickGuard
        />
      </a>
      {!database_connection_id && (
        <div>
          <p className="mt-4">
            If you have not yet connected Sigma to your Mozart Data Snowflake, you can do so below.
          </p>
          <Button
            size="large"
            className="mt-4"
            variant="darkDullAction"
            onClick={handleShowSnowflakeInSigmaClick}
          >
            Connect Sigma to Snowflake
          </Button>
        </div>
      )}
    </div>
  );
};

export default YouAreAlreadyUsingSigma;
