import { capitalize } from 'lodash';

import { ErrorDic, ErrorObject, VertexErrorsPair } from './flowchartQueryModelValidator';

export function errorsToSqlComments(errorObject: ErrorObject): string {
  let header = indentStr('You must fix these errors to get valid SQL:', 0);
  const vertexLines = errorObject.vertex.map((pair) => indentVertexPair(pair, 1)).flat();
  const errorLines = errorObject.global.map((error) => indentStr(error, 1));

  return ['/*', header, vertexLines, errorLines, '*/'].flat().join('\n');
}

function indentStr(s: string, indentLevel: number): string {
  const indentString = '    ';
  return `${indentString.repeat(indentLevel)}${s}`;
}

function indentVertexPair(pair: VertexErrorsPair, indentLevel: number): string[] {
  const label = pair.vertex.type
    .split('_')
    .map((w) => capitalize(w))
    .join(' ');
  return [indentStr(label, indentLevel), indentErrorDic(pair.errors, indentLevel + 1)].flat();
}

function indentErrorDic(errors: string[] | ErrorDic, indentLevel: number): string[] {
  if (Array.isArray(errors)) {
    return errors.map((e) => indentStr(e, indentLevel));
  }
  return Object.keys(errors)
    .map((k) => {
      const label = indentStr(k, indentLevel);
      const stringOrDic = errors[k];
      if (typeof stringOrDic === 'string') {
        return [label, indentStr(stringOrDic, indentLevel + 1)];
      }
      return [label, indentErrorDic(stringOrDic, indentLevel + 1)].flat();
    })
    .flat();
}
