/*
 * Component that renders a single tab in the query page
 */
import React, { useCallback, useEffect, useMemo } from 'react';

import { usePrevious } from 'react-use';

import QueryEditor from 'components/query/QueryEditor';
import useQueryEditor, { FlowchartSaveProps } from 'components/query/useQueryEditor';
import { AutoSavingState, SavedQuery } from 'pages/Query/useQueryTabs';

import { SavedFlowchartQueryModel } from '../../components/query/FlowchartEditor/model/SavedFlowchartQueryModel';

import 'components/query/Query.css';

const EVENT_PREFIX = 'Query';

interface QueryTabProps {
  savedQuery: SavedQuery;
  autoSavingState: AutoSavingState;
  autoRun: boolean;
  autoShowAIAssistant: boolean;
  isSelectedTab: boolean; // Used to trigger results table rerender when switching between tabs
  onChangeSql: (tabId: string, sql: string) => void; // This changes the sql saved in the savedQuery
  onRefetchSavedQuery?: (savedQueryId: string) => void; // This refreshed data on a single savedQuery, to call after onRun

  // Flowchart Related Props:
  savingFlowchart: boolean;
  savingFlowchartError: string;
  saveFlowchart: (
    savedQueryId: string,
    flowchart_model: SavedFlowchartQueryModel,
    sql: string,
  ) => Promise<SavedFlowchartQueryModel>;
}

const QueryTab = (props: QueryTabProps) => {
  const {
    savedQuery,
    autoSavingState,
    autoRun,
    autoShowAIAssistant,
    isSelectedTab,
    onChangeSql,
    onRefetchSavedQuery,
    savingFlowchart,
    savingFlowchartError,
    saveFlowchart,
  } = props;

  const flowchartSaveProps = useMemo(() => {
    let flowchartSaveProps: FlowchartSaveProps | undefined = undefined;
    if (savedQuery.flowchart_model) {
      flowchartSaveProps = {
        savedFlowchartModel: savedQuery.flowchart_model,
        isSaving: savingFlowchart,
        error: savingFlowchartError,
        save: (newSavedFlowchartModel: SavedFlowchartQueryModel, sql: string) => {
          return saveFlowchart(savedQuery.id, newSavedFlowchartModel, sql);
        },
      };
    }
    return flowchartSaveProps;
  }, [savedQuery.flowchart_model, savedQuery.id, savingFlowchart, savingFlowchartError, saveFlowchart]);

  const isFlowchart = flowchartSaveProps !== undefined;

  const queryEditorState = useQueryEditor({
    eventPrefix: EVENT_PREFIX,
    initialSql: savedQuery.sql,
    flowchartSaveProps,
    object: { id: savedQuery.id, type: 'savedQuery' },
    // Temporarily disable autosave in flowchart mode
    // in favor of manual save which is easier to debug.
    // Probably turning this back on later.
    // TODO: This disabling is causing the the last_run time of the query
    // not to be updated which I suspect is going to result in ording bugs on the list queries page.
    onChangeSql: isFlowchart ? undefined : onChangeSql,
    onRefetchSavedQuery: isFlowchart ? undefined : onRefetchSavedQuery,
    isHidden: !isSelectedTab,
    autoShowAIAssistant,
  });

  const { onExportCsv, onExportGSheet } = queryEditorState.queryRunnerState;
  const { editorTools, onRun } = queryEditorState.sqlState;

  useEffect(() => {
    if (autoRun) {
      setTimeout(() => onRun(false, false));
    }
    // Do not add onRun as a dependency to avoid infinite loop
  }, [autoRun]); // eslint-disable-line react-hooks/exhaustive-deps

  // Focus the editor after the user clicks on this tab.
  const previousIsSelectedTab = usePrevious(isSelectedTab);
  useEffect(() => {
    if (isSelectedTab && !previousIsSelectedTab) {
      editorTools.focus();
    }
  }, [isSelectedTab, previousIsSelectedTab, editorTools]);

  const handleExportCsv = useCallback(
    () => onExportCsv(`${savedQuery.name}.csv`),
    [onExportCsv, savedQuery.name],
  );

  const handleExportGSheet = useCallback(
    () => onExportGSheet(savedQuery.name, savedQuery.name),
    [onExportGSheet, savedQuery.name],
  );

  return (
    <QueryEditor
      queryEditorState={queryEditorState}
      canShowEditButtons={false}
      autoRun={autoRun}
      isTransform={false}
      eventPrefix={EVENT_PREFIX}
      autoSavingState={autoSavingState}
      isShown={isSelectedTab}
      savedQuery={savedQuery}
      onExportCsv={handleExportCsv}
      onExportGSheet={handleExportGSheet}
    />
  );
};

export default QueryTab;
