import React, { useState, useContext, useMemo } from 'react';

import cn from 'classnames';
import * as yup from 'yup';

import API from 'api/API';
import { AggTable } from 'api/APITypes';
import { SaveableTableProps } from 'api/tableAPI';
import { useUserProfile } from 'context/AuthContext';
import { TableContext } from 'model_layer/TableContext';
import {
  Pipeline,
  Vertex,
  getDirectDownstreamVertices,
} from 'pages/tables/ShowTable/PipelineTab/PipelineEditor/PipelineEditor';
import { snapshotFullName } from 'utils/dbName';
import { valDBIdentifier } from 'utils/Validators';

import RenameModal from './RenameModal';

const validationSchema = yup.object({
  name: valDBIdentifier('table_name'),
  schema: valDBIdentifier('schema').trim(),
});

interface RenameTableProps {
  name: string;
  schema: string;
}

interface TitleEditorProps {
  table: AggTable;
  snapshotName: string | null;
  pipeline: Pipeline | null;
  loadingPipeline: boolean;
  fetchPipelineData: () => void;
  saveTable?(
    tableProps: SaveableTableProps,
    afterSave?: (table: AggTable) => void,
    afterFinally?: () => void,
    setError?: (e: string) => void,
  ): void;
}

export default function TableTitleEditor(props: TitleEditorProps) {
  const { table, snapshotName, pipeline, loadingPipeline, fetchPipelineData, saveTable } = props;
  const [saving, setSaving] = useState(false);
  const [editorOpened, setEditorOpened] = useState(false);
  const [error, setError] = useState('');
  const [autoUpdateDownstreamSQL, setAutoUpdateDownstreamSQL] = useState(true);

  const { userProfile } = useUserProfile();
  const { tables } = useContext(TableContext);

  let directDownstreamVertices: Vertex[] = [];
  if (pipeline) {
    directDownstreamVertices = getDirectDownstreamVertices(pipeline, table.id);
  }

  const snapshotTable = useMemo(() => {
    if (snapshotName) {
      return tables.find((t) => t.full_name === snapshotName);
    }
    return undefined;
  }, [tables, snapshotName]);

  const hasSnapshot = !!snapshotTable;

  let snapshotDirectDownstreamVertices: Vertex[] = [];
  if (pipeline && hasSnapshot) {
    snapshotDirectDownstreamVertices = getDirectDownstreamVertices(pipeline, snapshotTable.id);
  }

  const handleToggleAutoUpdate = () => {
    analytics.track(
      autoUpdateDownstreamSQL ? 'ShowTable DisableAutoUpdateSQL' : 'ShowTable EnableAutoUpdateSQL',
    );
    setAutoUpdateDownstreamSQL(!autoUpdateDownstreamSQL);
  };

  let handleOpenEditor = () => {
    analytics.track('ShowTable EditTableName');
    setEditorOpened(true);
  };

  let handleCancelEditor = () => {
    analytics.track('ShowTable CancelEditTableName');
    setEditorOpened(false);
    setError('');
  };

  const autoUpdateSQL = (transformIds: string[], oldName: string, newName: string) => {
    const api = new API();
    const postData = { transform_ids: transformIds, old_name: oldName, new_name: newName };
    api
      .post('/api/transforms/bulk_update_sql', postData)
      .then((response) => {
        setEditorOpened(false);
        fetchPipelineData();
      })
      .catch((e) => {
        setError('There was a problem updating downstream SQL.');
      });
  };

  // TODO: The TableContext wont immediately know about updated snapshot table names
  const handleSaveName = (values: RenameTableProps) => {
    if (!saveTable) {
      return;
    }
    const transformValues = validationSchema.cast(values);
    setSaving(true);
    const oldName = table.full_name;
    const oldSnapshotName = snapshotName;
    saveTable(
      { name: transformValues.name, schema: transformValues.schema },
      (newTable: AggTable) => {
        analytics.track('ShowTable SaveTableName');

        const hasDownstream =
          directDownstreamVertices.length > 0 || snapshotDirectDownstreamVertices.length > 0;

        if (hasDownstream && autoUpdateDownstreamSQL) {
          if (directDownstreamVertices.length > 0) {
            const downstreamTransformIds = directDownstreamVertices
              .filter((v) => !!v.table?.transform_id)
              .map((v) => v.table?.transform_id) as string[];
            autoUpdateSQL(downstreamTransformIds, oldName, newTable.full_name);
          }
          if (oldSnapshotName && snapshotDirectDownstreamVertices.length > 0) {
            const newSnapshotName = snapshotFullName(newTable);
            const downstreamSnapshotTransformIds = snapshotDirectDownstreamVertices
              .filter((v) => !!v.table?.transform_id)
              .map((v) => v.table?.transform_id) as string[];
            autoUpdateSQL(downstreamSnapshotTransformIds, snapshotName, newSnapshotName);
          }
        } else {
          setEditorOpened(false);
        }
      },
      () => {
        setSaving(false);
      },
      setError,
    );
  };

  const isEditableType = table.type === 'transform' || table.type === 'csv_upload';
  const isEditable = isEditableType && saveTable !== undefined && userProfile.company_role !== 'viewer';

  return (
    <>
      <h1
        onClick={isEditable ? handleOpenEditor : undefined}
        className={cn('text-xl font-medium ml-2', {
          'px-2 cursor-pointer hover:bg-pri-gray-100 active:bg-pri-gray-200': isEditable,
        })}
      >
        {table.full_name}
      </h1>
      {editorOpened && (
        <RenameModal
          table={table}
          saving={saving}
          error={error}
          loadingPipeline={loadingPipeline}
          hasSnapshot={hasSnapshot}
          directDownstreamVertices={directDownstreamVertices}
          snapshotDirectDownstreamVertices={snapshotDirectDownstreamVertices}
          autoUpdateDownstreamSQL={autoUpdateDownstreamSQL}
          toggleAutoUpdate={handleToggleAutoUpdate}
          closeModal={handleCancelEditor}
          saveName={handleSaveName}
        />
      )}
    </>
  );
}
