import { useContext, useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router';

import { HIDE_TRIAL_BANNER_ROUTES } from 'main/MainApp';
import { OnboardingPopoverTourContext } from 'model_layer/OnboardingPopoverTourContext';

import OnboardingPopover from './OnboardingPopover/OnboardingPopover';
import TOUR_STEPS from './OnboardingPopoverTourSteps';

export interface OnboardingPopoverTourProps {}

export default function OnboardingPopoverTour(props: OnboardingPopoverTourProps) {
  const { currentStep, refs, onNext, onPrevious, onSkip } = useContext(OnboardingPopoverTourContext);
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const timeoutIDRef = useRef(-1);
  const { pathname } = useLocation();

  /*
  The other parts of the app that this component attaches Popovers to render independently and in any order.
  This component has to know when they are done rendering so it knows where to attach Popovers.
  I tried for about 10 hours to get the those components to update state in a shared Context,
  but that caused infinite render loops that I could not resolve.
  In search of pragmatic use of time, we are falling back on using setTimeout() to check if they have rendered yet.
  */
  useEffect(() => {
    const pollTarget = () => {
      // Don't show a step if the tour is not started or already completed
      if (currentStep === 'never_started' || currentStep === 'complete') {
        setTargetElement(null);
        return;
      }

      const newTarget = refs[currentStep] || null;
      // Don't trigger a state update if we don't have a ref to attach the popover to.
      if (newTarget?.current) {
        setTargetElement(newTarget.current);
      } else {
        setTargetElement(null);
        const timeoutID = setTimeout(pollTarget, 200);
        timeoutIDRef.current = timeoutID as unknown as number;
      }
    };

    pollTarget();

    return () => {
      clearTimeout(timeoutIDRef.current);
    };
  }, [currentStep, refs]);

  // Don't render if the tour is not started or already completed
  if (currentStep === 'never_started' || currentStep === 'complete') {
    return null;
  }

  // Don't render if we don't have a targetElement
  if (!targetElement) {
    return null;
  }

  // Don't render if this is a special page that shouldn't have a popover tour
  if (HIDE_TRIAL_BANNER_ROUTES.includes(pathname)) {
    return null;
  }

  // Don't render if this step is undefined
  const stepIndex = TOUR_STEPS.findIndex((s) => s.key === currentStep);
  if (stepIndex < 0) {
    // eslint-disable-next-line no-console
    console.log(
      'OnboardingPopoverTour - ABORTING => stepIndex < 0. This represents a programming error. Check your input props and TOUR_STEPS.',
    );
    return null;
  }
  const stepConfig = TOUR_STEPS[stepIndex];

  return (
    <OnboardingPopover
      targetRef={{ current: targetElement }}
      show={true}
      step={stepIndex + 1}
      totalSteps={TOUR_STEPS.length}
      {...stepConfig}
      onNext={onNext}
      onPrevious={onPrevious}
      onSkip={onSkip}
    />
  );
}
