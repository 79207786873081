import React from 'react';

import { ChevronRight } from 'react-bootstrap-icons';

import { useExperiments } from 'context/ExperimentContext';
import { env } from 'utils/env';

// Show a unique banner when runnning in development or staging.
const DevModeBanner = () => {
  const {
    experiments: { showEnvBanner },
  } = useExperiments();
  if (env === 'production' || !showEnvBanner) {
    return null;
  }

  const bgColor = env === 'development' ? 'bg-pri-success-200' : 'bg-pri-warning-200';
  return (
    <div className={`h-[25px] f-center text-black text-sm font-medium ${bgColor}`}>
      <h3 className="mr-1 font-bold">{env.toUpperCase()}:</h3>
      {process.env.REACT_APP_GITBRANCH}
      <ChevronRight className="mx-1 inline" size="12" color="var(--pri-gray-500)" />{' '}
      <a
        className="underline inline-block"
        rel="noreferrer"
        href={`https://github.com/MozartData/mozart/commit/${process.env.REACT_APP_GITCOMMIT}`}
        target="_blank"
      >
        {process.env.REACT_APP_GITCOMMIT}
      </a>
      <ChevronRight className="mx-1 inline" size="12" color="var(--pri-gray-500)" /> Node{' '}
      {process.env.REACT_APP_NODE_VERSION}
    </div>
  );
};

export default DevModeBanner;
