import { Fragment } from 'react';

import { ScheduleMode } from 'api/tableAPI';
import { displayCron } from 'utils/cron';

import { ObjectDelta, DbtRunConfigOrName, UserActionSentencePartProps } from './UserActionSentenceParts';

interface ScheduleProps {
  scheduled: boolean;
  schedule_mode: ScheduleMode;
  schedule: string;
  connector_dependencies: { id: string; name: string }[];
}

const Schedule = (props: ScheduleProps) => {
  const { scheduled, schedule_mode, schedule, connector_dependencies } = props;
  if (!scheduled) {
    return <ObjectDelta value="off" />;
  }
  if (schedule_mode === 'cron') {
    return <ObjectDelta value={displayCron(schedule)} />;
  }

  return (
    <>
      <ObjectDelta value="after all marked" /> on (
      {connector_dependencies.map((a, i) => {
        return (
          <Fragment key={a.id}>
            <ObjectDelta value={a.name} />
            {i < connector_dependencies.length - 1 && ', '}
          </Fragment>
        );
      })}
      )
    </>
  );
};

export const DbtRunConfigManualRunText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => (
  <>
    <DbtRunConfigOrName userAction={userAction} eventLocation={eventLocation} /> has been{' '}
    <ObjectDelta value="manually run" />.
  </>
);

export const DbtRunConfigChangedScheduleText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => {
  const { old_value, new_value } = userAction.metadata;

  return (
    <>
      Schedule for <DbtRunConfigOrName userAction={userAction} eventLocation={eventLocation} /> changed
      from <Schedule {...old_value} /> to <Schedule {...new_value} />.
    </>
  );
};

export const DbtRunConfigRenamedText = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  const { old_value, new_value } = userAction.metadata;

  return (
    <>
      <DbtRunConfigOrName userAction={userAction} eventLocation={eventLocation} /> has been renamed from{' '}
      <ObjectDelta value={old_value.name} /> to <ObjectDelta value={new_value.name} />.
    </>
  );
};

export const DbtRunConfigChangedTargetSchemaText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => {
  const { old_value, new_value } = userAction.metadata;

  return (
    <>
      Target schema for <DbtRunConfigOrName userAction={userAction} eventLocation={eventLocation} />{' '}
      changed from <ObjectDelta value={old_value.target_schema} /> to{' '}
      <ObjectDelta value={new_value.target_schema} />.
    </>
  );
};

export const DbtRunConfigChangedCommandsText = ({
  userAction,
  eventLocation,
}: UserActionSentencePartProps) => {
  const { old_value, new_value } = userAction.metadata;

  return (
    <>
      Commands for <DbtRunConfigOrName userAction={userAction} eventLocation={eventLocation} /> changed
      from <ObjectDelta value={old_value.commands.map((c: string) => `'${c}'`).join(', ')} /> to{' '}
      <ObjectDelta value={new_value.commands.map((c: string) => `'${c}'`).join(', ')} />.
    </>
  );
};
