import cn from 'classnames';

import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';

import RecentWorkWidget, { RecentWorkWidgetState } from '../RecentWorkWidget/RecentWorkWidget';
import StatusWidget, { StatusWidgetState } from '../StatusWidget/StatusWidget';
import UsageWidget, { UsageWidgetState } from '../UsageWidget/UsageWidget';
import UserActionsWidget, { UserActionsWidgetState } from '../UserActionsWidget/UserActionsWidget';

import styles from './HomeView.module.css';

export interface HomeViewProps {
  statusWidgetState: StatusWidgetState;
  recentWorkWidgetState: RecentWorkWidgetState;
  userActionsWidgetState: UserActionsWidgetState;
  usageWidgetState: UsageWidgetState;
  companyRole: string;
  showGuide: boolean;
}

const HomeView = (props: HomeViewProps) => {
  const {
    statusWidgetState,
    recentWorkWidgetState,
    userActionsWidgetState,
    usageWidgetState,
    companyRole,
    showGuide,
  } = props;
  const { connectorsData, transformsData, dataAlertsData } = statusWidgetState;

  const anyStatusLoaded = connectorsData.loaded || transformsData.loaded || dataAlertsData.loaded;
  const anyLoaded =
    anyStatusLoaded ||
    recentWorkWidgetState.allLoaded ||
    usageWidgetState.allLoaded ||
    userActionsWidgetState.allLoaded;

  return (
    <CenteredLayout loading={!anyLoaded} maxWidth="100%" pageType="full">
      <div className={cn(styles.layout, { [styles.guideLayout]: showGuide })}>
        <StatusWidget
          {...statusWidgetState}
          className={cn(styles.status, { [styles.guideStatus]: showGuide })}
        />
        <RecentWorkWidget
          {...recentWorkWidgetState}
          className={cn(styles.recentWork, { [styles.guideRecentWork]: showGuide })}
        />
        {companyRole !== 'viewer' && (
          <UsageWidget
            {...usageWidgetState}
            className={cn(styles.usage, { [styles.guideUsage]: showGuide })}
          />
        )}
        <UserActionsWidget
          {...userActionsWidgetState}
          className={cn(styles.userActions, { [styles.guideUserActions]: showGuide })}
          contentClassName={cn(styles.userActionsContent, {
            [styles.guideUserActionsContent]: showGuide,
          })}
        />
      </div>
    </CenteredLayout>
  );
};

export default HomeView;
