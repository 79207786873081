import { CSVUpload } from 'api/csvUploadAPI';
import UserHover from 'components/overlay/UserHover/UserHover';
import StatusIcon from 'components/primitives/icons/StatusIcon/StatusIcon';
import DateHover from 'components/widgets/time/DateHover/DateHover';
import { calcGenericRunStatus } from 'model_helpers/csvUploadHelper';
import { duration } from 'model_helpers/csvUploadHelper';
import { formatDuration } from 'utils/dateTime';

interface CSVUploadHistoryTabProps {
  csvUploads: CSVUpload[];
}

export default function CSVUploadHistoryTab(props: CSVUploadHistoryTabProps) {
  const { csvUploads } = props;

  return (
    <table className="blueGrayHeaderTable">
      <thead>
        <tr className="whitespace-nowrap">
          <th>Completed At</th>
          <th>Duration</th>
          <th>Uploaded By</th>
          <th>Filename</th>
          <th>
            <div className="f-center">Status</div>
          </th>
          <th>Rows</th>
          <th>Error Message</th>
        </tr>
      </thead>
      <tbody>
        {csvUploads.map((upload) => (
          <tr key={upload.id}>
            <td>
              <DateHover date={upload.updated_at} />
            </td>
            <td>{formatDuration(duration(upload)) || 'In Progress'}</td>
            <td>
              <UserHover userProfile={upload.creator_user_profile} />
            </td>
            <td>{upload.file_name}</td>
            <td>
              <div className="f-center">
                <StatusIcon status={calcGenericRunStatus(upload)} rotate={true} />
              </div>
            </td>
            <td>{upload.num_rows ?? <span className="text-gray-400">N/A</span>}</td>
            <td>
              {upload.error_message ? upload.error_message : <span className="text-gray-400">N/A</span>}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
