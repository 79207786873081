import { useState, useEffect, useRef } from 'react';

import { ElementDefinition } from 'cytoscape';
import { isEqual } from 'lodash';

import { useDatabaseAccount } from 'context/AuthContext';

import { Edge } from '../../PipelineEditor';
import { PipelineVertex } from '../../useVertexConverter';

import { calcCustomizedVertexHeight, getEdgeColor } from '../utils/graphUtils';
import {
  getConnectorIcon,
  getSchemaName,
  getTableName,
  getVertexIconType,
} from '../utils/vertexBasicInfoUtils';
import { getCustomizedVertexData } from '../utils/vertexDetailsUtils';

interface Props {
  vertices: PipelineVertex[];
  edges: Edge[];
  customizeVertexKeys: string[];
}

const usePipelineGraphBuilder = (props: Props) => {
  const { vertices, edges, customizeVertexKeys } = props;
  const databaseType = useDatabaseAccount().type;

  /** Graph vertices created by latest pipeline updates */
  const [latestGraphVertices, setLatestGraphVertices] = useState<ElementDefinition[]>([]);
  /** Graph edges created by latest pipeline updates */
  const [latestGraphEdges, setLatestGraphEdges] = useState<ElementDefinition[]>([]);
  // Warning: Initializing latestCustomizeVertexKeys with props.customizeVertexKeys instead of an empty array
  // fixes a bug where the second useEffect() can undo the work of the first useEffect().
  const [latestCustomizeVertexKeys, setLatestCustomizeVertexKeys] =
    useState<string[]>(customizeVertexKeys);

  /**
   * Graph elements currently rendered in graph. Doesn't necessarily reflect the latest pipeline version,
   * which we currently need in order to avoid UX issues with reloading the graph on constant API updates,
   * thus interrupting the user's work flow.
   *
   * Initialized to null so that we can tell when we are initializing the graph vs. updating the graph.
   */
  const [renderedGraphElements, setRenderedGraphElements] = useState<ElementDefinition[] | null>(null);
  const [hasChangesToLoad, setHasChangesToLoad] = useState<boolean>(false);

  /**
   * We will automatically rerender the graph if the user has not done an interaction that mutated
   * the layout or position of the graph in the viewport.
   * A "Graph Perspective Mutation" is stuff like zooming, panning, dragging expanding/collapsing content if we build that in the future.
   * This does not include selecting nodes or other operations that might change the rendering of a node's color
   * but not change the general layout or position of the graph.
   *
   * It would be extremely rare that a new node would appear or dissappear causing the graph to rerender
   * in a different shape while the user is actively looking at it. We are going to ignore this rare scenario and rerender.
   */
  const userHasMutatedGraphPerspectiveRef = useRef<boolean>(false);

  const onUserMutatesGraphPerspective = () => {
    userHasMutatedGraphPerspectiveRef.current = true;
  };

  const onUserResetsGraphPerspective = () => {
    userHasMutatedGraphPerspectiveRef.current = false;
  };

  const loadLatestChanges = () => {
    userHasMutatedGraphPerspectiveRef.current = false;
    setRenderedGraphElements([...latestGraphVertices, ...latestGraphEdges]);
    setHasChangesToLoad(false);
  };

  useEffect(() => {
    // Don't need to sort latestGraphVertices again, already sorted when created
    const prevVertices = latestGraphVertices.map((v: any) => v.data.vertex);
    const newVertices = vertices.sort((a, b) => (a.id > b.id ? 1 : -1));
    const hasNewVerticies = !isEqual(prevVertices, newVertices);

    let updatedVertices: Array<ElementDefinition> = [];
    let updatedEdges: Array<ElementDefinition> = [];

    if (hasNewVerticies) {
      updatedVertices = newVertices.map((v: PipelineVertex) => ({
        data: {
          id: v.id,
          schemaName: getSchemaName(v, databaseType),
          tableName: getTableName(v, databaseType),
          // VertexShape enum
          type: v.shape,
          iconType: getVertexIconType(v),
          // ErrorColor enum
          status: v.errorColor,
          connectorIcon: getConnectorIcon(v),
          customizedData: getCustomizedVertexData(v, customizeVertexKeys),
          vertexHeight: calcCustomizedVertexHeight(customizeVertexKeys),
          // Needed to compare with the previous state to know when to update the graph
          vertex: v,
        },
        position: {
          x: v.generation,
          y: 0,
        },
      }));

      setLatestGraphVertices(updatedVertices);
    }

    // Don't need to sort latestGraphEdges again, already sorted when created
    const prevEdges = latestGraphEdges.map((v: any) => v.data.edge);
    const newEdges = edges.sort((a, b) => (a.id > b.id ? 1 : -1));
    const hasNewEdges = !isEqual(prevEdges, newEdges);

    if (hasNewEdges) {
      updatedEdges = newEdges.map((e: Edge) => ({
        data: {
          id: e.id,
          source: e.source,
          target: e.destination,
          color: getEdgeColor(e, newVertices),
          edge: e,
        },
        classes: e.source === e.destination ? 'loop' : '',
      }));

      setLatestGraphEdges(updatedEdges);
    }

    // Initialize renderedGraphElements if loading graph for the first time
    if (renderedGraphElements === null) {
      setRenderedGraphElements([...updatedVertices, ...updatedEdges]);
    } else if (hasNewVerticies || hasNewEdges) {
      // The user has not interacted with the graph in a way that would
      // alter the layout or position of the viewport contents.
      if (userHasMutatedGraphPerspectiveRef.current === false) {
        const actualVertices = hasNewVerticies ? updatedVertices : latestGraphVertices;
        const actualEdges = hasNewEdges ? updatedEdges : latestGraphEdges;
        setRenderedGraphElements([...actualVertices, ...actualEdges]);
      }
      // Tell the user their are new changes, but do not undo any interactions with the
      // graph layout or position they might have done.
      else if (!hasChangesToLoad) {
        setHasChangesToLoad(true);
      }
    }
  }, [
    vertices,
    edges,
    latestGraphVertices,
    latestGraphEdges,
    hasChangesToLoad,
    renderedGraphElements,
    customizeVertexKeys,
    databaseType,
  ]);

  useEffect(() => {
    if (!isEqual(latestCustomizeVertexKeys, customizeVertexKeys)) {
      setLatestCustomizeVertexKeys(customizeVertexKeys);
      const updatedVertices = latestGraphVertices.map((v: any) => ({
        data: {
          ...v.data,
          customizedData: getCustomizedVertexData(v.data.vertex, customizeVertexKeys),
          vertexHeight: calcCustomizedVertexHeight(customizeVertexKeys),
        },
      }));

      setLatestGraphVertices(updatedVertices);
      setRenderedGraphElements([...updatedVertices, ...latestGraphEdges]);
    }
  }, [customizeVertexKeys, latestCustomizeVertexKeys, latestGraphVertices, latestGraphEdges]);

  return {
    graphElements: renderedGraphElements ?? [],
    hasChangesToLoad,
    onUserMutatesGraphPerspective,
    onUserResetsGraphPerspective,
    loadLatestChanges,
  };
};

export default usePipelineGraphBuilder;
