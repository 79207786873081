import { Connector } from 'api/APITypes';
import { ReportAvailable } from 'api/dashboardAPI';
import Button from 'components/inputs/basic/Button/Button';
import Alert from 'components/widgets/alerts/Alert/Alert';

import ConnectorLayout from '../ConnectorLayout';

import PreviewReportModal from './PreviewReportModal';

export interface PreviewReportViewProps {
  connector: Pick<Connector, 'id' | 'service' | 'schema' | 'succeeded_at'>;
  reportAvailable: ReportAvailable;
  showPreviewModal: boolean;
  createLoading: boolean;
  createError: string;
  onShowPreviewModal(): void;
  onHidePreviewModal(): void;
  onLater(): void;
  onCreate(): void;
}

export default function PreviewReportView(props: PreviewReportViewProps) {
  const {
    connector,
    reportAvailable,
    showPreviewModal,
    createLoading,
    createError,
    onShowPreviewModal,
    onHidePreviewModal,
    onLater,
    onCreate,
  } = props;

  return (
    <ConnectorLayout connector={connector}>
      <div className="mt-10">
        {!connector.succeeded_at && (
          <h2 className="mb-[6px] text-xl font-medium">
            Your connector has been added and is currently syncing.
          </h2>
        )}
        <h2 className="text-xl font-medium">Would you like Mozart to create this report for you?</h2>
      </div>
      <div className="mt-6 p-6 flex justify-between bg-sec-purple-25">
        <div className="mt-4 pr-6">
          <h3 className="font-medium">{reportAvailable.title}</h3>
          <p className="mt-2">{reportAvailable.description}</p>
        </div>
        <div className="min-w-[270px] h-[160px] flex justify-end items-center">
          <img
            src={`/images/report_builder/cropped/${connector.service}.png`}
            alt="report preview"
            onClick={onShowPreviewModal}
            className="max-w-full max-h-full border rounded border-sec-purple-700 hover:cursor-pointer"
          />
        </div>
      </div>
      {createError && (
        <Alert variant="error" className="mt-6">
          {createError}
        </Alert>
      )}
      <div className="mt-6 f-row-y-center">
        <Button onClick={onCreate} spinning={createLoading}>
          Create
        </Button>
        <Button onClick={onLater} variant="lightDullTab" className="ml-2">
          {connector.succeeded_at ? 'Cancel' : 'Skip'}
        </Button>
      </div>
      {showPreviewModal && (
        <PreviewReportModal connectorService={connector.service} onClose={onHidePreviewModal} />
      )}
    </ConnectorLayout>
  );
}
