import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import API from 'api/API';
import { AggTable, QueryRunResults } from 'api/APITypes';
import { deslug } from 'utils/Form';

export interface SummaryData {
  results: QueryRunResults;
  error_lines?: string[];
}

const SUMMARY_ERROR_SLUGS = {
  table_does_not_exist: 'Table does not exist in this database.',
  unknown_error: 'There was a problem loading table summary.',
};

export function usePreviewPaneData(previewTable: AggTable | null) {
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingError, setLoadingError] = useState('');
  const [summaryData, setSummaryData] = useState<SummaryData | null>(null);
  const summaryDataCacheRef = useRef<{ [key: string]: SummaryData }>({});

  const getTableSummary = useCallback(async (tableID: string) => {
    // Before we fetch data from the API check if we cached this table's data already.
    const cachedData = summaryDataCacheRef.current[tableID];
    if (cachedData) {
      setSummaryData(cachedData);
      return;
    }

    // No cached data, so fetch from API
    const api = new API();

    setLoadingSummary(true);

    try {
      const response = await api.get(`api/tables/${tableID}/summary?limit=100`);
      summaryDataCacheRef.current[tableID] = response.data.summary;
      setSummaryData(response.data.summary);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || 'unknown_error';
      setLoadingError(deslug(errorMessage, SUMMARY_ERROR_SLUGS));
    } finally {
      setLoadingSummary(false);
    }
  }, []);

  useEffect(() => {
    if (previewTable) {
      const { type, transform } = previewTable;
      const isTransform = type === 'transform';
      const neverRan = isTransform && !transform?.last_completed_run;

      // Only fetch data if there is data to fetch
      if (!isTransform || !neverRan) {
        getTableSummary(previewTable.id);
      }
    }
  }, [getTableSummary, previewTable]);

  const memoizedResponse = useMemo(
    () => ({ loadingSummary, loadingError, summaryData }),
    [loadingSummary, loadingError, summaryData],
  );

  return memoizedResponse;
}
