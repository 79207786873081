import { useAuth } from 'context/AuthContext';

function useFeatureFlags() {
  const { userProfile } = useAuth();
  return userProfile?.feature_flags || {};
}

function useFeatureFlag<T>(flag: string, defaultValue: T) {
  const featureFlags = useFeatureFlags();
  return (featureFlags[flag] || defaultValue) as T;
}

function useBooleanFlag(flag: string, defaultValue?: boolean) {
  return useFeatureFlag<boolean>(flag, defaultValue ?? false);
}

export { useFeatureFlags, useFeatureFlag, useBooleanFlag };
