import React, { useState } from 'react';

import { useFocus } from 'utils/React';

import DateRangeCalendar from './DateRangeCalendar/DateRangeCalendar';
import DateRangeInput from './DateRangeInput/DateRangeInput';

// Commentary on Date types:
//   There is an arguement we should use Date() objects and not date strings
//   for this component's date props.
//   For the time being we are going to use strings since that is the
//   format the API uses in the one place we use this component as of 2021-08-02.
//   This also eliminates ambiguity around time of day.
//   Right now Date() objects are considered an internal implmentation detail of `DateRangeInput.tsx`.
//   Feel free to reverse this decision if it proves a bad idea.
export interface DateRangePickerProps {
  startDate: string;
  endDate: string;
  setDateRange: (startDate: string, endDate: string) => void;
}

type CalendarMode = 'closed' | 'start' | 'end';

export default function DateRangePicker(props: DateRangePickerProps) {
  const { startDate, endDate, setDateRange } = props;
  const [calendarMode, setCalendarMode] = useState<CalendarMode>('closed');
  const [startDateRef] = useFocus();
  const [endDateRef, focusEndDateRef] = useFocus();

  const handleFocusStart = () => {
    if (calendarMode !== 'start') {
      setCalendarMode('start');
    }
  };

  const handleFocusEnd = () => {
    if (calendarMode !== 'end') {
      setCalendarMode('end');
    }
  };

  const handleSetStart = (startDate: string, event?: React.FocusEvent<HTMLInputElement>) => {
    // If the user tabs to the end date input,
    // just focus end date input and do not call API.
    // Otherwise, call API.
    if (endDateRef.current && event?.relatedTarget === endDateRef.current) {
      setCalendarMode('end');
      focusEndDateRef();
    } else {
      setDateRange(startDate, endDate);
      setCalendarMode('closed');
    }
  };

  const handleSetEnd = (endDate: string, event?: React.FocusEvent<HTMLInputElement>) => {
    setDateRange(startDate, endDate);
    setCalendarMode('closed');
  };

  const handleSetRange = (startDate: string, endDate: string) => {
    setDateRange(startDate, endDate);
    setCalendarMode('closed');
  };

  const handleCloseCalendar = () => {
    setCalendarMode('closed');
  };

  const showCalendar = calendarMode === 'start' || calendarMode === 'end';
  let calendarValue = startDate;
  let handleCalendarSet = handleSetStart;
  if (calendarMode === 'end') {
    calendarValue = endDate;
    handleCalendarSet = handleSetEnd;
  }

  return (
    <div className="f-center relative">
      <div>
        <DateRangeInput
          name="Start Date"
          ref={startDateRef}
          value={startDate}
          onFocus={handleFocusStart}
          onSetDate={handleSetStart}
          onSetRange={handleSetRange}
        />
        <span className="mx-2">to</span>
        <DateRangeInput
          name="End Date"
          ref={endDateRef}
          value={endDate}
          externalInvalid={endDate < startDate}
          onFocus={handleFocusEnd}
          onSetDate={handleSetEnd}
          onSetRange={handleSetRange}
        />
      </div>
      {showCalendar && (
        <DateRangeCalendar
          value={calendarValue}
          startDate={startDate}
          endDate={endDate}
          onSetDate={handleCalendarSet}
          onSetRange={handleSetRange}
          onClose={handleCloseCalendar}
        />
      )}
    </div>
  );
}
