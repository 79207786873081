// Example of experiment with string values:
// const stringExperimentNameValues = ['control', 'version_one', 'version_two'];
// type StringExperimentNameValues = typeof stringExperimentNameValues[number]

export interface Experiments {
  externalAPI: boolean;
  showEnvBanner: boolean;
  // Example:
  // stringExperimentName: StringExperimentNameValues
}

// You only need to fill this out if you create an experiment whos possible values are of type `string[]`
export const experimentOptions: { [K in keyof Experiments]?: string[] } = {
  // Example:
  // stringExperimentName: stringExperimentNameValues,
};

export const defaultExperiments: Experiments = {
  externalAPI: false,
  showEnvBanner: false,
  // Example:
  // stringExperimentName: 'control',
};

export type ExperimentKeys = keyof Experiments;
