import IconButton from 'components/inputs/basic/Button/IconButton';

export interface BackButtonProps {
  text: string;
  onClick(): void;
  className?: string;
}

const BackButton = (props: BackButtonProps) => {
  const { text, className, onClick } = props;

  return (
    <IconButton
      icon="ChevronLeft"
      text={text}
      variant="lightDullTransparent"
      size="small"
      className={className}
      onClick={onClick}
    />
  );
};

export default BackButton;
