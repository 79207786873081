/*
  Use <NoObjectsAlert/> when the user should add the first instance of a type of object to a list AND
  that type of object is not critical to system operation (tags, alerts, sheet syncs, etc...).
  Use <FixMeAlert/> when something is broken or critcal to system operation,
  and the user should take immediate action to "fix" the problem.
*/
import Alert from 'components/widgets/alerts/Alert/Alert';

interface NoObjectsAlertProps {
  heading?: string;
  detail?: React.ReactNode;
  className?: string;
}

export default function NoObjectsAlert(props: NoObjectsAlertProps) {
  const { heading, detail, className } = props;
  return (
    <Alert variant="info" className={className}>
      {heading && <h6 className="text-base font-medium">{heading}</h6>}
      {typeof detail === 'string' ? <p className="text-base mt-1">{detail}</p> : detail}
    </Alert>
  );
}
