/**
 * The API returns a SavedFlowchartQueryModel that has object IDs instead of fully
 * hydrated objects. This file fetches all of the API objects needed
 * to convert a SavedFlowchartQueryModel into a normal FlowchartQueryModel.
 */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Column, ColumnsByTableID, columnCacheAPI } from 'api/columnAPI';
import { AggTable } from 'api/tableAPI';
import {
  SavedFlowchartQueryModel,
  SavedSourceTable,
} from 'components/query/FlowchartEditor/model/SavedFlowchartQueryModel';
import { useDatabaseAccount } from 'context/AuthContext';
import useMemoObject from 'hooks/useMemoObject';

export interface FlowchartAPILoaderState {
  // If savedFlowchartModel is undefined that means isFlowchart is false and it should do nothing.
  savedFlowchartModel?: SavedFlowchartQueryModel;
  // This hook assumes the tables for each table used in the savedFlowchartModel are already in tablesByID.
  // tablesByID is loaded by the app-wide model layer.
  tablesByID: { [tableID: string]: AggTable };
  // The columns of the tables used by this flowchart.
  // This hook loads the columns for each table used in the savedFlowchartModel from the API.
  columnsByTableID: ColumnsByTableID;
  // All tables and columns needed to turn a SavedFlowchartQueryModel into a hydrated FlowchartQueryModel
  // at the last save point have been loaded from the API. This does not cover table and column models
  // that have been added since the last save point.
  objectsForSavedModelLoaded: boolean;
  fetchColumns: (tableID: string) => Promise<{ columns: Column[]; columnsByTableID: ColumnsByTableID }>;
}

interface UseFlowchartEditorProps {
  savedFlowchartModel?: SavedFlowchartQueryModel;
  tablesByID: { [tableID: string]: AggTable };
  tablesLoaded: boolean;
  isHidden: boolean;
}

export default function useFlowchartEditor(props: UseFlowchartEditorProps): FlowchartAPILoaderState {
  const { savedFlowchartModel, tablesByID, tablesLoaded, isHidden } = props;

  const [columnsByTableID, setColumnsByTableID] = useState<ColumnsByTableID>({});
  const fetchingColumnsByTableID = useRef<{ [tableID: string]: boolean }>({});

  const databaseType = useDatabaseAccount().type;

  const fetchColumns = useCallback(
    (tableID: string) => {
      fetchingColumnsByTableID.current[tableID] = true;
      return columnCacheAPI
        .fetchTableColumns(tableID, databaseType)
        .then((columns: Column[]) => {
          // ref is a hack to both use method based state setter and return that object in a promise.
          const ref = { current: {} as ColumnsByTableID };
          setColumnsByTableID((oldColumnsByTableID) => {
            const newColumnsByTableID: ColumnsByTableID = {
              ...oldColumnsByTableID,
              [tableID]: columns,
            };
            ref.current = newColumnsByTableID;
            return newColumnsByTableID;
          });
          return Promise.resolve({ columns, columnsByTableID: ref.current });
        })
        .finally(() => {
          fetchingColumnsByTableID.current[tableID] = false;
        });
    },
    [databaseType],
  );

  const tablesIDsToLoadColumnsFor = useMemo(
    () =>
      savedFlowchartModel && !isHidden
        ? (savedFlowchartModel.vertices
            .filter((v) => v.type === 'source_table')
            .map((v) => (v as SavedSourceTable).tableID)
            .filter((id) => id !== null) as string[])
        : undefined,
    [savedFlowchartModel, isHidden],
  );

  // Load the columns of each table in the saved model
  useEffect(() => {
    if (tablesIDsToLoadColumnsFor) {
      tablesIDsToLoadColumnsFor.forEach((tableID) => {
        const hasColumns = columnsByTableID[tableID];
        const isFetching = fetchingColumnsByTableID.current[tableID];
        if (!hasColumns && !isFetching) {
          fetchColumns(tableID);
        }
      });
    }
  }, [tablesIDsToLoadColumnsFor, columnsByTableID, fetchColumns]);

  const columnsLoaded = useMemo(
    () =>
      tablesIDsToLoadColumnsFor !== undefined &&
      tablesIDsToLoadColumnsFor.every((tableID) => !!columnsByTableID[tableID]),
    [tablesIDsToLoadColumnsFor, columnsByTableID],
  );

  const objectsForSavedModelLoaded = tablesLoaded && columnsLoaded;

  const flowchartEditorState = useMemoObject<FlowchartAPILoaderState>({
    savedFlowchartModel,
    tablesByID,
    columnsByTableID,
    objectsForSavedModelLoaded,
    fetchColumns,
  });

  return flowchartEditorState;
}
