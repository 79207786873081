import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from 'components/inputs/basic/Button/Button';
import PasswordFormikGroup from 'components/inputs/formik_group/PasswordFormikGroup/PasswordFormikGroup';
import RadioFormikGroup, {
  RadioInputOption,
} from 'components/inputs/formik_group/RadioFormikGroup/RadioFormikGroup';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { SigmaAPIKeyFormValues } from './SigmaView';

import cls from 'pages/byo/SetupPage.module.css';

interface APIKeyFormProps {
  handleSubmitAPIKey: (values: SigmaAPIKeyFormValues) => void;
  handleShowAPIKeysIntegrationClick: () => void;
  loading: boolean;
  error: string | null;
}

const SetupSnowflakeSchema = Yup.object().shape({
  base_url_prefix: Yup.string().required('Required'),
  client_id: Yup.string().required('Required'),
  client_secret: Yup.string().required('Required'),
});

const sigmaBaseUrlOptions: RadioInputOption[] = [
  { label: 'AWS US', value: 'aws-api' },
  { label: 'AWS Canada', value: 'api.ca.aws' },
  { label: 'AWS Europe', value: 'api.eu.aws' },
  { label: 'AWS UK', value: 'api.uk.aws' },
  { label: 'Azure US', value: 'api.us.azure' },
  { label: 'Google Cloud (GCP)', value: 'api' },
];

const APIKeyForm = (props: APIKeyFormProps) => {
  const { handleSubmitAPIKey, loading, error, handleShowAPIKeysIntegrationClick } = props;
  return (
    <CenteredContainer>
      <Formik
        initialValues={{ base_url_prefix: '', client_id: '', client_secret: '' }}
        onSubmit={handleSubmitAPIKey}
        validationSchema={SetupSnowflakeSchema}
      >
        {({ handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <ol>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>Select the cloud region of your Sigma instance</p>
                <p className="mt-2">
                  (Administration {'>'} Account {'>'} General Settings {'>'} Site {'>'} Cloud, or{' '}
                  <a
                    href="https://help.sigmacomputing.com/reference/get-started-sigma-api#identify-your-api-request-url"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={cls.setupStepsItemTitleLink}
                    data-track="SigmaIntegration ClickIdentifyAPIRequestURLDocs"
                  >
                    see docs
                  </a>
                  ).
                </p>
                <RadioFormikGroup
                  name="base_url_prefix"
                  disabled={loading}
                  options={sigmaBaseUrlOptions}
                  groupClass="mt-2"
                />
              </li>
              <li className={cls.setupStepsItem}>
                <p className={cls.setupStepsItemTitle}>Enter your API Key</p>
                <p className="mt-2">
                  Follow{' '}
                  <a
                    href="https://help.sigmacomputing.com/reference/generate-client-credentials"
                    rel="noopener noreferrer"
                    target="_blank"
                    className={cls.setupStepsItemTitleLink}
                    data-track="SigmaIntegration ClickGenerateClientCredentialsDocs"
                  >
                    the docs
                  </a>{' '}
                  to generate a REST API key with an Owner that has Admin permission.
                </p>
                <TextFormikGroup
                  type="text"
                  disabled={loading}
                  className="w-[445px]"
                  name="client_id"
                  placeholder=""
                  label="Client ID"
                  groupClass="mt-4"
                />
                <PasswordFormikGroup
                  name="client_secret"
                  disabled={loading}
                  placeholder=""
                  label="Client Secret"
                  className="w-[445px]"
                  groupClass="my-4"
                />
              </li>
            </ol>
            <div className="mt-4 flex justify-end">
              <Button
                variant="lightDanger"
                onClick={handleShowAPIKeysIntegrationClick}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button className="ml-4" type="submit" disabled={loading || !isValid} spinning={loading}>
                Test and Save API Key
              </Button>
            </div>
            {error && (
              <Alert variant="error" className="mt-4">
                {error}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </CenteredContainer>
  );
};

export default APIKeyForm;
