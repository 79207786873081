/*
This the signup form we made in Feb 2024, when we launched our partnership with Thoughtspot.

It copies code from SignupRightSideForm except that it makes the marketing_referral a hidden input.
*/
import { Helmet } from 'react-helmet';

import LoggedOutFormBackgroundLayout from 'components/layouts/pages/LoggedOutFormBackgroundLayout/LoggedOutFormBackgroundLayout';

import MeritBadges from './MeritBadges';
import SignupFormikForm from './SignupFormikForm';
import { useSignup } from './useSignup';

export const THOUGHTSPOT_MARKETING_REFERRAL = 'THOUGHTSPOT_SIGNUP_FORM';

interface SignupThoughtspotProps {}

const SignupThoughtspot = (props: SignupThoughtspotProps) => {
  const formProps = useSignup();

  // This value is hard coded for this form and the input is removed from the HTML.
  formProps.initialValues.marketing_referral = THOUGHTSPOT_MARKETING_REFERRAL;

  return (
    <LoggedOutFormBackgroundLayout>
      <Helmet>
        <title>Signup for Mozart Data | Mozart Data</title>
        <meta
          name="description"
          content="Sign up for Mozart Data and deploy your own data stack in just a few minutes. Start your 14 day free trial with Mozart Data today."
        />
      </Helmet>
      <div className="w-full h-full p-4 f-center overflow-auto">
        <div className="w-full lg:min-w-[444px] lg:w-[444px] mt-8 lg:mt-0 lg:mx-2 f-col">
          <a href="https://www.mozartdata.com" data-track="Signup LinkToMarketingSite">
            <img className="h-[28px]" src="/images/logos/MozartDataLogoPurple.svg" alt="" />
          </a>
          <div className="mt-4 p-4 bg-white rounded shadow-[0_0_4px_0_rgba(0,0,0,0.1)]">
            <SignupFormikForm {...formProps} hideMarketingReferral={true} />
          </div>
          <div className="w-full f-center mt-4">
            <div className="w-full max-w-[400px]">
              <MeritBadges />
            </div>
          </div>
        </div>
      </div>
    </LoggedOutFormBackgroundLayout>
  );
};

export default SignupThoughtspot;
