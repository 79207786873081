import { Check, GiftFill } from 'react-bootstrap-icons';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function FreeInfoIcon() {
  const content = (
    <div>
      <div className="f-row-y-center">$500 of free credits includes:</div>
      <div className="f-row-y-center mt-2">
        <div className="w-4 h-4 f-center mr-2 bg-teal rounded">
          <Check size="14" color="var(--white)" />
        </div>
        <div>250,000 Monthly Active Rows (MAR)</div>
      </div>
      <div className="f-row-y-center mt-2">
        <div className="w-4 h-4 f-center mr-2 bg-teal rounded">
          <Check size="14" color="var(--white)" />
        </div>
        <div>15 Compute Credits</div>
      </div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return (
    <InfoIcon
      content={content}
      containerClass="ml-1"
      popoverProps={popoverProps}
      icon={GiftFill}
      iconColor="var(--sec-blue-gray-500)"
    />
  );
}
