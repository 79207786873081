/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';

import axios from 'axios';
import { Resizable } from 're-resizable';

import Button from 'components/inputs/basic/Button/Button';
import CodeEditor from 'components/inputs/basic/CodeEditor/CodeEditor';
import APIKeyManager from 'pages/internal/APIKeyManager';
import FilePanel, { File } from 'pages/internal/FilePanel';
import GitHubPRHelper, { FilesContext } from 'pages/internal/GitHubPRHelper';
import { OpenAIUsageContext } from 'pages/internal/OpenAIUsageContext';
import OpenAIUsageTracker from 'pages/internal/OpenAIUsageTracker';

const API_URL = 'https://api.openai.com/v1/chat/completions';
const OPENAI_KEYS_URL = 'https://platform.openai.com/api-keys';
const openAIKeyManager = new APIKeyManager('OpenAI');

const callGPT4 = async (api_key: string, prompt: string, functions: any[]): Promise<any> => {
  try {
    const response = await axios.post(
      API_URL,
      {
        model: 'gpt-4o',
        messages: [
          {
            role: 'system',
            content:
              'You are an expert full stack developer that uses provided functions to write code.',
          },
          {
            role: 'user',
            content: prompt,
          },
        ],
        functions: functions,
        function_call: {
          name: 'create_file',
        },
        max_tokens: 4000,
        n: 1,
        stop: null,
        temperature: 0.7,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${api_key}`,
        },
      },
    );

    return response;
  } catch (error) {
    console.error('Error calling GPT-4:', error);
    openAIKeyManager.handleAPIKeyError();
    throw error;
  }
};

const functions = [
  {
    name: 'create_file',
    description: 'Creates a file with the given filename and content.',
    parameters: {
      type: 'object',
      properties: {
        filename: {
          type: 'string',
          description: 'The name of the file to create.',
        },
        content: {
          type: 'string',
          description: 'The content to write to the file.',
        },
      },
      required: ['filename', 'content'],
    },
  },
];

export const saveFilesToLocal = (files: File[]) => {
  localStorage.setItem('savedFiles', JSON.stringify(files));
};

export const getFilesFromLocal = (): File[] => {
  const files = localStorage.getItem('savedFiles');
  return files ? JSON.parse(files) : [];
};

export default function CodeEditorTab() {
  const [editorContent, setEditorContent] = useState<string>('Placeholder text for the initial version');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [gptResponse, setGptResponse] = useState<string>('');
  const [gptThinking, setGptThinking] = useState<boolean>(false);
  const [ciStatuses, setCIStatuses] = useState<any[]>([]);
  const [editingPR, setEditingPR] = useState<string | null>(null);
  const [pullRequests, setPullRequests] = useState<any[]>([]);
  const [files, setFiles] = useState<File[]>(getFilesFromLocal());
  const [isPanelOnLeft, setIsPanelOnLeft] = useState<boolean>(true);
  const [showFileInput, setShowFileInput] = useState<boolean>(false);
  const { setModel, setTokenCounts } = useContext(OpenAIUsageContext);

  useEffect(() => {
    saveFilesToLocal(files);
  }, [files]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    const file = event.target.files?.[0];

    if (file) {
      fileReader.readAsText(file, 'UTF-8');
      fileReader.onload = (e) => {
        const content = e.target?.result?.toString() || '';
        setFiles([...files, { filename: file.name, content, sendToGPT: true, locked: false }]);
        setShowFileInput(false);
      };
    }
  };

  const toggleFileSendStatus = (index: number) => {
    setFiles(
      files.map((file, i) => {
        if (i === index) {
          const updatedSendToGPT = !file.sendToGPT;
          return { ...file, sendToGPT: updatedSendToGPT, locked: updatedSendToGPT ? file.locked : true };
        }
        return file;
      }),
    );
  };

  const toggleFileLockStatus = (index: number) => {
    setFiles(
      files.map((file, i) => {
        if (i === index) {
          const updatedLocked = !file.locked;
          return { ...file, locked: updatedLocked, sendToGPT: updatedLocked ? file.sendToGPT : true };
        }
        return file;
      }),
    );
  };

  const handleFileSelect = (filename: string) => {
    const selectedFile = files.find((file) => file.filename === filename);
    if (selectedFile) {
      setEditorContent(selectedFile.content);
    }
  };

  const handleClearFile = (filename: string) => {
    const updatedFiles = files.filter((file) => file.filename !== filename);
    setFiles(updatedFiles);
    saveFilesToLocal(updatedFiles);
  };

  const handleGPTInteraction = async () => {
    setGptThinking(true);
    try {
      const apiKey = openAIKeyManager.getAPIKey(
        `Visit ${OPENAI_KEYS_URL} and get a new key with a name like one-time-use-for-ide. To keep your account secured, delete other similarly-named keys in the account that were created for earlier sessions.`,
      );
      if (!apiKey) {
        alert('Need API key to talk to GPT.');
        return;
      }
      const nonHiddenFilesContent = files
        .filter((file) => file.sendToGPT)
        .map(
          (file) =>
            `Contents of ${file.filename}:\n${file.content}\n[END OF ${file.filename} CONTENTS]\n\n`,
        )
        .join('\n');
      const prompt = `${editorContent}\n\nHere are current file contents for context:\n${nonHiddenFilesContent}`;
      const contentSubmitted = editorContent;
      setEditorContent(`${editorContent}\n\nGPT is working on the request...`);

      const gptResponse = await callGPT4(apiKey, prompt, functions);
      setGptResponse(JSON.stringify(gptResponse, null, 2));
      const tokenCounts = gptResponse.data.usage;
      setModel(gptResponse.data.model);
      setTokenCounts((prevCounts) => {
        console.log(prevCounts, tokenCounts);
        return {
          promptTokens: prevCounts.promptTokens + tokenCounts.prompt_tokens,
          completionTokens: prevCounts.completionTokens + tokenCounts.completion_tokens,
        };
      });
      setEditorContent(`${editorContent}\n\nParsing the response from GPT...`);
      try {
        const result = JSON.parse(gptResponse.data.choices[0].message.function_call.arguments);
        if (result.filename && result.content) {
          // QUESTION:
          // This line is adding a duplicate entry to the file list everytime I click "Send To GPT"
          // Is that designed behavior?
          // OR do you want to search and replace the old file?
          // Maybe you should append a suffix to the new file's name if you want to keep both.
          setFiles([
            ...files,
            { filename: result.filename, content: result.content, sendToGPT: true, locked: false },
          ]);
          setEditorContent(
            `${contentSubmitted}\n\nGPT has populated this output into ${result.filename}:\n\n${result.content}`,
          );
        } else {
          setErrorMessage('GPT-4 did not return the expected file data.');
        }
      } catch (error) {
        setEditorContent(
          `${editorContent}\n\nThere was a JSON parsing error. Check debugger window for the raw GPT response.`,
        );
        console.error('Error in parsing JSON from GPT: ', error);
        setErrorMessage('Failed to parse valid JSON from GPT response.');
      }
    } catch (error) {
      console.error('Unexpected error in GPT-4 call: ', error);
      setErrorMessage('Failed to interact with GPT-4');
    } finally {
      setGptThinking(false);
    }
  };

  const sendToGPTButton = (
    <Button onClick={handleGPTInteraction} style={{ margin: '10px 0' }} spinning={gptThinking}>
      Send to GPT
    </Button>
  );

  return (
    <div className="code-editor-tab">
      {errorMessage && <p className="error">{errorMessage}</p>}
      <div
        style={{
          display: 'flex',
          flexDirection: isPanelOnLeft ? 'row' : 'row-reverse',
          alignItems: 'stretch',
          width: '100%',
        }}
      >
        <div
          style={{
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: '250px',
            minWidth: '250px',
            maxHeight: 'calc(100vh - 20px)',
            overflowY: 'auto',
            marginRight: isPanelOnLeft ? '20px' : '0',
            marginLeft: isPanelOnLeft ? '0' : '20px',
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FilePanel
            files={files}
            onToggleSend={toggleFileSendStatus}
            onToggleLock={toggleFileLockStatus}
            onSelect={handleFileSelect}
            onClear={handleClearFile}
          />
          {showFileInput && (
            <input type="file" onChange={handleFileChange} style={{ marginBottom: '10px' }} />
          )}
          {!showFileInput && (
            <button onClick={() => setShowFileInput(true)} style={{ marginBottom: '10px' }}>
              ➕
            </button>
          )}
          <button onClick={() => setIsPanelOnLeft(!isPanelOnLeft)} style={{ marginBottom: '10px' }}>
            ↔️
          </button>
          {sendToGPTButton}
          <FilesContext.Provider
            value={{
              setCIStatuses: setCIStatuses,
              setFiles: setFiles,
              pullRequests: pullRequests,
              setPullRequests: setPullRequests,
              editingPR: editingPR,
              setEditingPR: setEditingPR,
            }}
          >
            <GitHubPRHelper />
          </FilesContext.Provider>
        </div>
        <div>
          <Resizable
            size={{ width: '800', height: 'calc(100% - 120px)' }}
            defaultSize={{ width: '800', height: 'calc(100% - 120px)' }}
            handleStyles={{ bottom: { cursor: 'row-resize' } }}
            enable={{
              top: false,
              right: false,
              bottom: true,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontFamily: 'monospace',
              fontSize: '12px',
              maxWidth: 'calc(120ch + 5px)',
              width: 'calc(120ch + 5px)',
            }}
          >
            <CodeEditor
              value={editorContent}
              mode="python"
              editable={true}
              minHeight="200px"
              maxHeight="calc(100% - 20px)"
              height="auto"
              style={{
                flexGrow: 1,
                flexShrink: 1,
                flexBasis: 'auto',
                overflow: 'hidden',
                marginLeft: isPanelOnLeft ? '20px' : '0',
                marginRight: isPanelOnLeft ? '0' : '20px',
                maxWidth: 'calc(120ch + 40px)',
                fontSize: '12px',
                fontFamily: 'monospace',
              }}
              onChange={(newContent) => setEditorContent(newContent)}
            />
            <div
              style={{ height: '2px', background: '#ccc', cursor: 'row-resize', position: 'relative' }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '-4px',
                  left: '50%',
                  width: '20px',
                  height: '10px',
                  background: '#888',
                  cursor: 'row-resize',
                }}
              ></div>
            </div>
          </Resizable>
          <div style={{ marginTop: '20px', flexGrow: 0 }}>
            {ciStatuses && (
              <div>
                <h4>CI Status:</h4>
                {ciStatuses
                  .reduce((acc: any[], curr: any) => {
                    if (!acc.find((item) => item.context === curr.context)) {
                      acc.push(curr);
                    }
                    return acc;
                  }, [])
                  .map((value: any, index: number, array: any[]) => (
                    <div key={index}>
                      <span>{value.context}: </span>
                      <span
                        style={{
                          color:
                            value.state === 'pending'
                              ? 'lightgray'
                              : value.state === 'success'
                                ? 'green'
                                : 'red',
                        }}
                      >
                        {value.state}
                      </span>
                    </div>
                  )) || <span>Open a Pull Request to view CI Status in this console.</span>}
              </div>
            )}
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <OpenAIUsageTracker />
          <h3>Debugging Window</h3>
          <textarea value={gptResponse} readOnly style={{ width: '100%', height: '100px' }} />
        </div>
      </div>
    </div>
  );
}
