import React from 'react';

import PopperOverlay from 'components/overlay/PopperOverlay/PopperOverlay';
import Tooltip from 'components/overlay/Tooltip/Tooltip';

interface CopiedTipProps {
  show: boolean;
  targetRef: React.MutableRefObject<HTMLButtonElement | null> | null;
}

const CopiedTip = ({ show, targetRef }: CopiedTipProps) => {
  return (
    <PopperOverlay
      target={targetRef}
      show={show}
      placement="top"
      renderPopper={(renderPopperProps) => {
        return <Tooltip tip="Copied" {...renderPopperProps} />;
      }}
    />
  );
};

export default CopiedTip;
