import React from 'react';

import { useTitle } from 'react-use';

import CenteredContainer from 'components/layouts/containers/CenteredContainer/CenteredContainer';

const TrialExpired = () => {
  useTitle('Mozart: Trial Expired');
  return (
    <CenteredContainer maxWidth="500px">
      <div className="f-col-center">
        <h1 className="display-sm">Your free trial has expired.</h1>
        <img
          src="images/icons/time_expired.svg"
          alt="time expired"
          className="mt-4"
          style={{ width: '250px' }}
        />
        <div className="f-col-center mt-4 text-lg">
          <div>
            Please contact{' '}
            <a href="mailto:sales@mozartdata.com?subject=Trial%20Expiration" className="text-purple">
              Sales
            </a>
          </div>
          <div>to have your trial account reenabled and continue your trial.</div>
        </div>
      </div>
    </CenteredContainer>
  );
};

export default TrialExpired;
