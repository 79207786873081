/*
 * Component that renders Tables for viewing and editing.
 */
import React, { useState, useEffect, useMemo, useRef, useContext, useCallback } from 'react';

import { matchPath } from 'react-router';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom-v5-compat';
import { useTitle } from 'react-use';

import API from 'api/API';
import {
  AggTable,
  SaveableTransformProps,
  SaveableTableProps,
  SaveableTransformAndAncestorProps,
  Transform,
} from 'api/APITypes';
import columnAPI, { Column, updateColumnCache } from 'api/columnAPI';
import tableAPI, { convertTable } from 'api/tableAPI';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import { TabKeyType } from 'components/layouts/parts/TabRow/TabRow';
import { useDatabaseAccount, useUserProfile } from 'context/AuthContext';
import { getMostRecentDestinationTable } from 'model_helpers/dbtAggTableHelper';
import { TableModelsContext } from 'model_layer/TableModelsContext';
import CSVUploadHistoryTab from 'pages/tables/ShowTable/CSVUploadHistoryTab/CSVUploadHistoryTab';
import PipelineTab from 'pages/tables/ShowTable/PipelineTab/PipelineTab';
import SettingsTab from 'pages/tables/ShowTable/SettingsTab/SettingsTab';
import TableLayout from 'pages/tables/ShowTable/TableLayout';
import TransformTab from 'pages/tables/ShowTable/TransformTab/TransformTab';
import UserActionsTab from 'pages/tables/ShowTable/UserActionsTab/UserActionsTab';
import VersionHistoryTab from 'pages/tables/ShowTable/VersionHistoryTab/VersionHistoryTab';
import { handleSqlErrors } from 'utils/apiResponseFormatter';
import { capitalize } from 'utils/String';

import DataAlertsTab from './DataAlertTab/DataAlertsTab';
import { convertPayload, APIPipelinePayload } from './PipelineTab/PipelineConverter';
import { DependencyEdge, Pipeline } from './PipelineTab/PipelineEditor/PipelineEditor';
import RunTab from './RunTab/RunTab';
import SqlTab from './SqlTab/SqlTab';
import SummaryTab from './SummaryTab/SummaryTab';
import SyncTabController from './SyncTab/SyncTabController';

import 'components/query/Query.css';

// This is the same as AggTable, but transform cannot be null
export interface TransformTable extends Omit<AggTable, 'transform'> {
  transform: Transform;
}

export const TABS = [
  'summary',
  'transform',
  'run',
  'alerts',
  'versionHistory',
  'pipeline',
  'sync',
  'userActions',
  'settings',
  'viewSql',
  'templatedCode',
  'compiledSql',
  'uploads',
] as const;

export type TabKey = (typeof TABS)[number];
const sanitizeTab = (unsafeTab: string | undefined) => {
  const castKey = unsafeTab as TabKey;
  return TABS.includes(castKey) ? castKey : 'summary';
};

interface MatchParams {
  id: string;
  tab: string;
}

interface ShowTableProps extends RouteComponentProps<MatchParams> {
  setCurrentPathObject: React.Dispatch<React.SetStateAction<any>>;
}

export default function ShowTable(props: ShowTableProps) {
  const { setCurrentPathObject } = props;
  const { id: tableId, tab } = useParams();

  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [loadingErrorMessage, setLoadingErrorMessage] = useState<string>('');
  const [saveErrorMessage, setSaveErrorMessage] = useState<string>('');
  const [runCreateErrorMessage, setRunCreateErrorMessage] = useState<string>('');
  const [loadingToggleSnapshot, setLoadingToggleSnapshot] = useState(false);
  const [showCantSnapshotModal, setShowCantSnapshotModal] = useState(false);
  const [savingDescription, setSavingDescription] = useState<boolean>(false);
  const [savingTransform, setSavingTransform] = useState<boolean>(false);
  const [runningCreateAs, setRunningCreateAs] = useState<boolean>(false);
  const [runningCreateAsIncremental, setRunningCreateAsIncremental] = useState<boolean>(false);
  const [columns, setColumns] = useState<Column[] | null>(null);
  const [loadingColumns, setLoadingColumns] = useState<boolean>(true);
  const [confirmAbandonChanges, setConfirmAbandonChanges] = useState<string>('');

  const [currentTab, setCurrentTab] = useState<string>(sanitizeTab(tab));
  // const [fetchDependenciesError, setFetchDependenciesError] = useState<string>('');
  // Pipeline State
  const [apiPipeline, setApiPipeline] = useState<APIPipelinePayload>({ vertices: [], edges: [] });
  const [pipeline, setPipeline] = useState<Pipeline | null>(null);
  const [loadingPipeline, setLoadingPipeline] = useState(true);
  const [loadPipelineErrorMessage, setLoadPipelineErrorMessage] = useState<string>('');
  const [vertexDependencies, setVertexDependencies] = useState<DependencyEdge[]>([]);

  const {
    userProfile: { company_role },
  } = useUserProfile();
  const databaseType = useDatabaseAccount().type;

  const {
    tables,
    tablesByID,
    connectorsByID,
    tags,
    allLoaded,
    updateTables,
    updateTransforms,
    logRecent,
  } = useContext(TableModelsContext);
  const history = useHistory();
  const onAbandonChangesConfirmed = useRef(() => {});
  const unsavedSql = useRef(false);
  const setTableAndTransformRef = useRef(
    (newTable: AggTable | null, newTransform: Transform | null) => {},
  );

  // Changing the tab in the URL path doesn't create a new instance of ShowTable, so navigate
  useEffect(() => {
    setCurrentTab(sanitizeTab(tab));
  }, [tab]);

  // TableModelsContext is the source of truth.
  // Find the current table and transfrom objects in TableModelsContext.
  // This page fetches the latest table and transfrom, then updates TableModelsContext,
  // which then updates this useMemo().
  const [aggTable, transform] = useMemo(() => {
    const aggTable: AggTable | TransformTable | null = tables.find((t) => t.id === tableId) || null;
    return [aggTable, aggTable?.transform || null];
  }, [tableId, tables]);

  useTitle(aggTable !== null ? aggTable.name : 'Show Table');

  // Pass the current table to AuthHeader
  useEffect(() => {
    setCurrentPathObject(aggTable);
  }, [setCurrentPathObject, aggTable]);

  // Log Recent when user visits this table for the first time.
  // No need to log again if the user switches between tabs on the same table.
  useEffect(() => {
    if (allLoaded) {
      logRecent(tableId as string);
    }
  }, [allLoaded, tableId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Use this method when setting the the table or transform object from the API.
  const setTableAndTransform = useCallback(
    (newTable: AggTable | null, newTransform: Transform | null) => {
      // This component updates on table updates, so updating transforms first as a level of paranoia
      // to prevent race conditions since the table object and the transform object are not added
      // in one transaction. Also, this should reduce rerenders.
      if (newTransform) {
        updateTransforms([newTransform]);
      }

      if (newTable) {
        updateTables([newTable]);
      }
    },
    [updateTables, updateTransforms],
  );

  // When this page loads the API fetches /api/tables/${tableId}/table_and_transform.
  // The API promise would like to make a closure on setTableAndTransform which later changes.
  // The API fetch should only happen once every time the tableId changes.
  // So, we don't want it's dependency array to include `setTableAndTransform`; therefore,
  // we use a useEffect to create a reference to the newest version of `setTableAndTransform`.
  useEffect(() => {
    setTableAndTransformRef.current = setTableAndTransform;
  }, [setTableAndTransform]);

  const afterLoadError = saveErrorMessage || runCreateErrorMessage || loadPipelineErrorMessage;

  /*****************************************************************************
   * Pipeline Data
   ****************************************************************************/
  const fetchPipelineData = useCallback(() => {
    setLoadingPipeline(true);
    setLoadPipelineErrorMessage('');

    const api = new API();
    api
      .get(`/api/tables/${tableId}/get_lineage`)
      .then((response) => {
        setApiPipeline(response.data);
      })
      .catch((e) => {
        setLoadPipelineErrorMessage('There was an error getting the pipeline of your table.');
      })
      .finally(() => {
        setLoadingPipeline(false);
      });
  }, [tableId]);

  useEffect(() => {
    fetchPipelineData();
  }, [aggTable?.full_name, aggTable?.transform?.sql, fetchPipelineData]); // If the full_name or sql changed, lineage may have also changed

  // We don't want to reload pipeline if tables changes,
  // so let's do the convert in a separate useEffect
  useEffect(() => {
    if (aggTable) {
      setPipeline(convertPayload(apiPipeline, aggTable, tablesByID));
    }
  }, [apiPipeline, aggTable, tablesByID]);

  // TODO: There is some redundancy between get_lineage and get_dependencies that could be consolidated
  // Gets the table's vertex's dependency edges so we can display schedule mode options
  const handleGetVertexDependencies = useCallback(() => {
    const api = new API();
    api
      .get(`/api/tables/${tableId}/get_dependencies`)
      .then((response) => {
        setVertexDependencies(response.data);
      })
      .catch((e) => {
        // setFetchDependenciesError('There was a problem fetching ancestors.');
      });
  }, [tableId]);

  // Get dependencies on table load
  useEffect(() => {
    handleGetVertexDependencies();
  }, [handleGetVertexDependencies]);

  /*****************************************************************************
   * Lifecycle Methods
   ****************************************************************************/
  // There are two checks for navigating before saving changes to the query.
  // 1. This one checks for navigating to another page.
  // 2. There is another check for navigating to another tab.
  useEffect(() => {
    history.block(({ pathname }) => {
      if (unsavedSql.current && !isPathAnyTableTab(pathname)) {
        setConfirmAbandonChanges('Are you sure you want to change pages without saving?');
        onAbandonChangesConfirmed.current = () => {
          unsavedSql.current = false;
          history.push(pathname);
        };
        return false;
      }
    });
  }, [history]);

  // This API call is intended to pull a fresh copy of the table and transform data for this table
  // in the event the global app calls to /api/tables and /api/transforms happened a long time ago and that data is now stale.
  // This API can come back before /api/tables or /api/transforms if a user loads a /api/tables/{ID}/{tab} route directly.
  // Either of those APIs will overwrite the data returned by this API if they return after this API.
  // This doesn't create any known errors, but is something to keep in mind.
  // In theory, this API returns the same data for one table as /api/tables and /api/transforms do for all tables.
  // So, if all three APIs load from the DB at the same time, the order of writing should not matter.
  useEffect(() => {
    setLoadingTable(true);
    setLoadingErrorMessage('');

    const api = new API();
    api
      .get(`/api/tables/${tableId}/table_and_transform`)
      .then((response) => {
        const table = convertTable(response.data.table, databaseType);
        const transform = response.data.transform;
        setTableAndTransformRef.current(table, transform);
      })
      .catch((e) => {
        setLoadingTable(false);
        setLoadingErrorMessage('There was a problem loading table ' + tableId);
      });
  }, [tableId]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadColumns = useCallback(
    (tableId: string) => {
      setLoadingColumns(true);
      columnAPI
        .fetchTableColumns(tableId, databaseType)
        .then((response) => {
          const columns = response.data as Column[];
          setColumns(columns);
          updateColumnCache(tableId, columns);
        })
        .catch(() => {
          setLoadingErrorMessage('There was a problem loading columns for table ' + tableId);
        })
        .finally(() => {
          setLoadingColumns(false);
        });
    },
    [databaseType],
  );

  // We want to load the columns at the page level so it doesnt have to reload when switching between tabs
  useEffect(() => {
    if (tableId) {
      loadColumns(tableId);
    }
  }, [tableId, loadColumns]);

  const isPathAnyTableTab = (pathname: string) => {
    const showTableMatch = matchPath(pathname, {
      path: '/tables/:id',
      exact: false,
    });

    return !!showTableMatch;
  };

  /*****************************************************************************
   * Saving Transforms
   ****************************************************************************/
  // Used by tabs to save any combination of SaveableTransformProps
  const handleSaveTransform = (transformProps: SaveableTransformProps) => {
    if (transform) {
      setSavingTransform(true);
      setSaveErrorMessage('');
      const api = new API();
      return api
        .patch(`api/transforms/${transform.id}`, transformProps)
        .then((response) => response.data as Transform)
        .then((transform) => {
          setTableAndTransform(null, transform);
          // For now just get dependencies again after saving SQL. Maybe in the future we only do so if the API tells us lineage changed
          handleGetVertexDependencies();
          return transform;
        })
        .catch((e) => {
          if (e.response?.data?.sql) {
            const sqlError = e.response.data.sql[0];
            const sqlErrorMessage = handleSqlErrors(sqlError);
            setSaveErrorMessage(sqlErrorMessage);
          } else if (e.response?.data?.non_field_errors) {
            setSaveErrorMessage(e.response.data.non_field_errors);
          } else {
            setSaveErrorMessage('There was a problem saving the transform.');
          }
          return Promise.reject(e);
        })
        .finally(() => {
          setSavingTransform(false);
        });
    }
  };

  const handleSaveTransformAndAncestors = (
    transformId: string, // The id of the transform to edit
    transformAndAncestorSaveProps: SaveableTransformAndAncestorProps,
  ) => {
    const api = new API();
    return api
      .patch(`api/transforms/${transformId}/save_settings_and_ancestors`, transformAndAncestorSaveProps)
      .then((response) => response.data as { transform: Transform; ancestors: DependencyEdge[] })
      .then(({ transform, ancestors }) => {
        setTableAndTransform(null, transform);
        // For now just get dependencies again after saving SQL. Maybe in the future we only do so if the API tells us lineage changed
        setVertexDependencies(ancestors);
        return { transform, ancestors };
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };

  /*****************************************************************************
   * Manually Run Transform Now!!!
   ****************************************************************************/
  const handleRunCreateAs = (fullBuild: boolean = false) => {
    if (transform) {
      setRunningCreateAs(true);
      setRunningCreateAsIncremental(!fullBuild);
      setRunCreateErrorMessage('');

      const api = new API();
      let postData = { full_build: fullBuild };

      api
        .post(`api/transforms/${transform.id}/run`, postData)
        .then((response) => {
          if (response.data.error) {
            setTableAndTransform(null, response.data.transform);
            setRunCreateErrorMessage(response.data.error);
          } else {
            analytics.track('RunTab RunNow');
            setTableAndTransform(null, response.data.transform);
            setRunCreateErrorMessage('');
            // The TableContext's table list is updated by the run history polling
          }
        })
        .catch((e) => {
          setRunCreateErrorMessage('There was a problem running the transform.');
        })
        .finally(() => {
          setRunningCreateAs(false);
          setRunningCreateAsIncremental(false);
        });
    }
  };

  /*****************************************************************************
   * Save Table State
   ****************************************************************************/
  const saveTable = async (
    tableProps: SaveableTableProps,
    afterSave?: (table: AggTable) => void,
    afterFinally?: () => void,
    setError?: (e: string) => void,
  ) => {
    const setErrorMessage = setError || setSaveErrorMessage; // This allows us to pass in a setError, but defaults to the page level error state
    if (aggTable) {
      const payload = {
        ...tableProps,
      };
      setErrorMessage('');
      tableAPI
        .patch(aggTable.id, payload, databaseType)
        .then((newTable) => {
          setTableAndTransform(newTable, null);
          if (afterSave) {
            afterSave(newTable);
          }
        })
        .catch((e) => {
          if (e.response?.data?.snapshot) {
            const snapshotErrorSlug = e.response.data.snapshot[0];
            // First check for cannot_snapshot_snapshot_table
            if (snapshotErrorSlug === 'cannot_snapshot_snapshot_table') {
              setErrorMessage('You are not allowed to toggle snapshots on for a snapshot table.');
            } else {
              setErrorMessage(handleSqlErrors(snapshotErrorSlug));
            }
          } else if (e.response?.data?.schema) {
            setErrorMessage(e.response.data.schema[0]);
          } else if (e.response?.data?.non_field_errors) {
            setErrorMessage(e.response.data.non_field_errors);
          } else if (e.response?.data) {
            // This catch is for potential rename errors
            const errorSlug = e.response.data[0];
            setErrorMessage(handleSqlErrors(errorSlug));
          } else {
            setErrorMessage('There was a problem saving the table.');
          }
        })
        .finally(() => {
          if (afterFinally) {
            afterFinally();
          }
        });
    }
  };

  const handleToggleSnapshot = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (aggTable) {
      if (company_role === 'viewer') {
        setShowCantSnapshotModal(true);
        analytics.track('SummaryTab ViewerAttemptedSnapshot');
        return;
      }

      let { snapshot } = aggTable;
      snapshot = !snapshot;
      const tableProps = {
        snapshot,
      };
      setLoadingToggleSnapshot(true);
      saveTable(
        tableProps,
        (table: AggTable) => {
          analytics.track(snapshot ? 'SummaryTab EnableSnapshot' : 'SummaryTab DisableSnapshot');
        },
        () => {
          setLoadingToggleSnapshot(false);
        },
      );
    }
  };

  const handleCloseCantSnapshotModal = () => {
    setShowCantSnapshotModal(false);
    analytics.track('SummaryTab ViewerClosedCantSnapshotModal');
  };

  // Used by DescriptionEditor just to save description.
  // Gives DescriptionEditor a discrete spinner.
  const handleSaveDescription = (description: string, onSaveSuccess: () => void) => {
    setSavingDescription(true);
    saveTable({ description }, onSaveSuccess, () => {
      setSavingDescription(false);
    });
  };

  /*****************************************************************************
   * UI Methods
   ****************************************************************************/
  const handleSelectTab = (
    unsafeTab: string | null,
    event?: React.SyntheticEvent<unknown>,
    trackEvent?: string,
  ) => {
    const tab = unsafeTab || 'summary';
    trackEvent = trackEvent || `ShowTable Set${capitalize(tab)}Tab`;
    analytics.track(trackEvent);
    // There are two checks for navigating before saving changes to the query.
    // 1. This one checks for navigating to another tab.
    // 2. There is another check for navigating to another page.
    if (tab !== 'transform' && unsavedSql.current) {
      setConfirmAbandonChanges('Are you sure you want to change tabs without saving?');
      onAbandonChangesConfirmed.current = () => {
        unsavedSql.current = false;
        setConfirmAbandonChanges('');
        setCurrentTab(tab);
        setSaveErrorMessage('');
        setRunCreateErrorMessage('');
        setPath(tab);
      };
    } else {
      setCurrentTab(tab);
      setSaveErrorMessage('');
      setRunCreateErrorMessage('');
      setPath(tab);
    }
  };

  const setPath = (tab: string) => {
    let path = `/tables/${tableId}`;
    if (tab !== 'summary') {
      path += `/${tab}`;
    }
    history.replace(path);
  };

  const handleAbandonCancelled = () => {
    setConfirmAbandonChanges('');
  };

  const handleAbandonConfirmed = () => {
    onAbandonChangesConfirmed.current();
  };

  const handleSetUnsavedSql = (newUnsavedSql: boolean) => {
    unsavedSql.current = newUnsavedSql;
  };

  // Pick which tabs to render
  let tabs: TabKeyType[] = [];
  const isTransform = aggTable?.type === 'transform';
  const isSnapshot = aggTable?.type === 'snapshot';
  const isCSVUpload = aggTable?.type === 'csv_upload';
  const hasConnector = aggTable?.type === 'unmanaged' && aggTable.connector !== null;
  if (isTransform) {
    tabs = [
      'summary',
      'transform',
      { key: 'run', label: 'Runs' },
      { key: 'pipeline', label: 'Pipeline' },
    ];
    if (databaseType !== 'bigquery') {
      tabs.push({ key: 'alerts', label: 'Alerts' });
    }
    tabs.push(
      { key: 'versionHistory', label: 'Versions' },
      { key: 'sync', label: 'Sheet Sync' },
      { key: 'userActions', label: 'User Actions' },
    );
  } else if (aggTable) {
    tabs = ['summary'];

    const hasViewSqlTab = !!aggTable.view_sql && aggTable.type === 'unmanaged';
    if (hasViewSqlTab) {
      tabs.push({ key: 'viewSql', label: 'View SQL' });
    }

    const hasDBTSqlTabs = aggTable.type === 'dbt';
    if (hasDBTSqlTabs) {
      tabs.push({ key: 'templatedCode', label: 'DBT Templated Code' });
      tabs.push({ key: 'compiledSql', label: 'DBT Compiled SQL' });
    }

    tabs.push({ key: 'pipeline', label: 'Pipeline' });

    if (isCSVUpload) {
      tabs.push({ key: 'uploads', label: 'Uploads' }, { key: 'userActions', label: 'User Actions' });
    }

    if ((isSnapshot || hasConnector) && databaseType !== 'bigquery') {
      tabs.push({ key: 'alerts', label: 'Alerts' });
    }

    if (isSnapshot || hasConnector) {
      tabs.push({ key: 'sync', label: 'Sheet Sync' }, { key: 'userActions', label: 'User Actions' });
    }
  }
  // These tables have alerts, sync sheets, etc., so people might want to unsubscribe from notifications
  if (isTransform || isSnapshot || isCSVUpload || hasConnector) {
    tabs.push({ key: 'settings', label: 'Settings' });
  }

  // Only render the current tab.
  let tabContent = null;
  if (aggTable) {
    tabContent = (
      <>
        {currentTab === 'summary' && (
          <SummaryTab
            table={aggTable}
            loadingColumns={loadingColumns}
            columns={columns}
            setColumns={setColumns}
            allTags={tags}
            loadingToggleSnapshot={loadingToggleSnapshot}
            savingDescription={savingDescription}
            vertexDependencies={vertexDependencies}
            apiPipeline={apiPipeline}
            tablesByID={tablesByID}
            connectorsByID={connectorsByID}
            showCantSnapshotModal={showCantSnapshotModal}
            toggleSnapshot={handleToggleSnapshot}
            onSaveDescription={handleSaveDescription}
            onCloseCantSnapshotModal={handleCloseCantSnapshotModal}
            loadColumns={loadColumns}
          />
        )}
        {currentTab === 'transform' && (
          <TransformTab
            table={aggTable as TransformTable}
            saving={savingTransform}
            hasSavingError={!!saveErrorMessage}
            vertexDependencies={vertexDependencies}
            saveTransform={handleSaveTransform} // For saving SQL
            onSaveTransformAndAncestors={handleSaveTransformAndAncestors}
            setUnsavedSql={handleSetUnsavedSql}
          />
        )}
        {currentTab === 'pipeline' && (
          <PipelineTab pipeline={pipeline} loadingPipeline={loadingPipeline} />
        )}
        {currentTab === 'run' && (
          <RunTab
            table={aggTable as TransformTable}
            creatingAs={runningCreateAs}
            creatingAsIncremental={runningCreateAsIncremental}
            setTableAndTransform={setTableAndTransform}
            setColumns={setColumns}
            onRunCreateAs={handleRunCreateAs}
          />
        )}
        {currentTab === 'uploads' && <CSVUploadHistoryTab table={aggTable} />}
        {currentTab === 'alerts' && <DataAlertsTab table={aggTable} />}
        {currentTab === 'versionHistory' && <VersionHistoryTab table={aggTable as TransformTable} />}
        {currentTab === 'sync' && <SyncTabController table={aggTable} />}
        {currentTab === 'userActions' && <UserActionsTab table={aggTable as TransformTable} />}
        {currentTab === 'settings' && (
          <SettingsTab table={aggTable as TransformTable} setErrorMessage={setSaveErrorMessage} />
        )}
        {currentTab === 'viewSql' && <SqlTab sql={aggTable.view_sql || ''} />}
        {currentTab === 'templatedCode' && (
          <SqlTab sql={getMostRecentDestinationTable(aggTable)?.raw_code || ''} />
        )}
        {currentTab === 'compiledSql' && (
          <SqlTab sql={getMostRecentDestinationTable(aggTable)?.compiled_code || ''} />
        )}
      </>
    );
  }

  // Loading state is controlled by whether we have the data to render or not.
  // This is different from the typical pattern of setting a `loading` state variable
  // that says if an API request is in flight.
  // Many users will get the aggTable out of the TableModelsContext, which in turn may
  // have gotten the aggTable from the localStorage cache or loaded it by an API request
  // on a previous page.
  // The table_id in the URL may not exist,
  // so we also need to stop loading if the API request comes back with not table object.
  const loadingRequiredData = !aggTable && loadingTable;

  return (
    <TableLayout
      loading={loadingRequiredData}
      loadingError={loadingErrorMessage}
      afterLoadError={afterLoadError}
      table={aggTable}
      pipeline={pipeline}
      loadingPipeline={loadingPipeline}
      fetchPipelineData={fetchPipelineData}
      saveTable={saveTable}
      tabs={tabs}
      tabContent={tabContent}
      currentTab={currentTab}
      onSelectTab={handleSelectTab}
      modals={
        confirmAbandonChanges !== '' && (
          <ConfirmModal
            header={confirmAbandonChanges}
            confirmText="Change Without Saving"
            confirmVariant="darkDanger"
            onCancel={handleAbandonCancelled}
            onConfirm={handleAbandonConfirmed}
          />
        )
      }
    />
  );
}
