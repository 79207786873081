/*
  Create an input component that is a missing link between a
  radio button and a toggle switch. Actual radio buttons don't
  quite work for this, so doing something custom.
*/
import cn from 'classnames';
import { capitalize } from 'lodash';

import { AbstractTabRowProps } from 'components/layouts/parts/TabRow/TabRow';

import RadioTab from './RadioTab';

export type { TabKeyType } from 'components/layouts/parts/TabRow/TabRow';

export interface RadioTabColumnProps extends AbstractTabRowProps {
  enabled: boolean;
  horizontal?: boolean;
}

const RadioTabColumn = (props: RadioTabColumnProps) => {
  const { tabs, selectedTab, onClick, enabled, horizontal } = props;
  return (
    <div
      className={cn('shrink-0', {
        'f-row-y-center h-[72px] w-full': horizontal,
        'f-col w-[150px] h-full': !horizontal,
      })}
    >
      {tabs.map((t) => {
        const key = typeof t === 'string' ? t : t.key;
        const label = typeof t === 'string' ? capitalize(t) : t.label;
        const selected = key === selectedTab;
        const handleClick = () => {
          if (enabled) {
            onClick(key);
          }
        };
        return (
          <RadioTab
            key={key}
            label={label}
            selected={selected}
            enabled={enabled}
            onClick={handleClick}
          />
        );
      })}
    </div>
  );
};

export default RadioTabColumn;
