import { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import API from 'api/API';
import { DbtRunConfig, DbtRun } from 'api/dbtAPI';
import { usePoll } from 'hooks/useApi';
import { downloadFile } from 'utils/React';

import { DBT_POLLING_TIMES } from './usePaginatedRuns';

type MaybeLogs = string[] | null;

const useRunLog = (
  runConfig: DbtRunConfig,
  paginatedRunsLoading: boolean,
  paginatedRuns: DbtRun[],
  setFetchRunError: (error: string) => void,
  runID?: string,
) => {
  const [currentLogRun, setCurrentLogRun] = useState<DbtRun | null>(null);
  const [keepPolling, setKeepPolling] = useState(false);
  const [logs, setLogs] = useState<MaybeLogs>(null);
  const [fetchingLogs, setFetchingLogs] = useState(false);

  const history = useHistory();

  const openLogs = useCallback((run: DbtRun) => {
    setCurrentLogRun(run);
    setFetchingLogs(true);
    setKeepPolling(true);
  }, []);

  // If the URL specified a specific run, open it.
  useEffect(() => {
    // Make sure we don't replay opening the route's run
    if (
      !!runID &&
      !currentLogRun &&
      !fetchingLogs &&
      !paginatedRunsLoading &&
      paginatedRuns.length > 0
    ) {
      // Reset state before we continue.
      setFetchRunError('');

      // If the run is on the current page, all we have to do is open it.
      const foundRun = paginatedRuns.find((r) => r.id === runID);
      if (foundRun) {
        openLogs(foundRun);
      }
      // If the URL's runID is not on the current page we need to fetch the run first.
      else {
        const api = new API();
        api
          .get(`api/dbt_runs/${runID}`)
          .then((response) => {
            openLogs(response.data);
          })
          .catch((e) => {
            setFetchRunError(`Failed to fetch run ${runID}.`);
          });
      }
    }
  }, [
    runID,
    currentLogRun,
    fetchingLogs,
    paginatedRunsLoading,
    paginatedRuns,
    openLogs,
    setFetchRunError,
  ]);

  // Poll for updated logs
  const {
    data: polledLogs,
    error: pollError,
    isLoading: pollIsLoading,
  } = usePoll<{ id: string; logs: MaybeLogs }>(
    `dbtConfigRunRunLogs/${currentLogRun?.id}`,
    `api/dbt_runs/${currentLogRun?.id}/logs`,
    DBT_POLLING_TIMES, // Poll with gradual backoff
    currentLogRun !== null && keepPolling, // Fetch logs at least once. Then poll as long as the run is not complete.
  );

  // Every time the poll updates, update the UI.
  useEffect(() => {
    if (currentLogRun && !pollIsLoading) {
      // Update the logs every time the poll returns new logs.
      if (!pollError) {
        setLogs(polledLogs?.logs || null);
      }

      // Once we have fetched once, set fetching to false even if we are still polling.
      setFetchingLogs(false);

      // Stop polling if the run is complete.
      if (currentLogRun.state === 'failed' || currentLogRun.state === 'success') {
        // Get logs one more time right away.
        const api = new API();
        api.get(`api/dbt_runs/${currentLogRun.id}/logs`).then((response) => {
          setLogs(response.data.logs);
        });

        // After a small delay, stop polling
        setTimeout(() => {
          setKeepPolling(false);
        }, 5000);

        // Get logs one last time
        api.get(`api/dbt_runs/${currentLogRun.id}/logs`).then((response) => {
          setLogs(response.data.logs);
        });
      }
    }
  }, [currentLogRun, polledLogs, pollError, pollIsLoading]);

  // When the API updates the currentLogRun model, call this.
  const handleAPIUpdateCurrentLogRun = (run: DbtRun) => {
    setCurrentLogRun(run);
  };

  const handleOpenLogs = (run: DbtRun) => {
    analytics.track('ShowDBTRunConfigRunTab OpenRunLogs');
    history.push(`/dbt/jobs/${runConfig.id}/runs/${run.id}`);
  };

  const handleCloseLogs = () => {
    setCurrentLogRun(null);
    setFetchingLogs(false);
    setLogs(null);
    analytics.track('ShowDBTRunConfigRunTab CloseRunLogs');
    history.push(`/dbt/jobs/${runConfig.id}/runs`);
  };

  const handleCopyLogs = () => {
    if (currentLogRun && logs) {
      const joinedLogs = logs.join('\n');
      navigator.clipboard.writeText(joinedLogs);
      analytics.track('ShowDBTRunConfigRunTab CopyRunLogs');
    }
  };

  const handleDownloadLogs = () => {
    if (currentLogRun && logs) {
      const fileName = `${runConfig.name}_run_logs_${currentLogRun.id}.txt`;
      const joinedLogs = logs.join('\n');
      downloadFile(fileName, joinedLogs);
      analytics.track('ShowDBTRunConfigRunTab DownloadRunLogs');
    }
  };

  return {
    currentLogRun,
    fetchingLogs,
    logs,
    handleAPIUpdateCurrentLogRun,
    handleOpenLogs,
    handleCloseLogs,
    handleCopyLogs,
    handleDownloadLogs,
  };
};

export default useRunLog;
