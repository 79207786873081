// If you need a new bootstrap icon in an icon button, add it to this list.
// `import *` adds a lot of unused icons that makes the build big, which we don't want.
import { Link45deg } from 'react-bootstrap-icons';

// GRUMBLE: I couldn't figure out how to alias the named imports object, so we have to repeat ourselves.
// Top honors to the first person to figure out how to not repeat the list, preserve import tree shaking, and preserve type safety.
const usedIcons = {
  Link45deg,
};

export default usedIcons;
