import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function TwoFAInfoIcon() {
  const content = (
    <div>
      <div>
        <strong>Enabling 2-Factor Authentication makes your account more secure.</strong>
      </div>
      <div className="mt-2">
        To enable, download the Google Authenticator mobile app (or similar) and use it to scan the QR
        Code we give you.
      </div>
      <div className="mt-2">
        When 2FA is enabled, you will be asked for a 6-digit token provided by Google Authenticator as an
        additional step when logging in.
      </div>
      <div className="mt-2">
        If you are unable to scan the QR Code, you can alternately enter the 2FA Key manually by clicking
        on 'Show 2FA Key' under the QR Code, and entering the displayed key in your mobile app with type
        'Time Based'.
      </div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} containerClass="ml-1" popoverProps={popoverProps} />;
}
