import { useContext } from 'react';

import { Link } from 'react-router-dom';

import { TableModelsContext, AggTable } from 'model_layer/TableModelsContext';
import { connectorPath, dbtRunConfigurationPath, tablePath } from 'utils/PathMaker';

import type { UserAction, UserActionObjectType, PagedLocation } from '../../usePagedUserActions';

/*******************************************************************************
 * Generic Object Parts
 ******************************************************************************/
export const ObjectLink = ({
  object_type,
  object_id,
  object_name,
  path,
  eventLocation,
}: {
  object_type: UserActionObjectType;
  object_id: string;
  object_name: string;
  path: string;
  eventLocation: PagedLocation;
}) => {
  return (
    <Link
      to={path}
      target="_blank"
      rel="noopener"
      onClick={() => {
        analytics.track(`${eventLocation} LinkToObject`, {
          object_type,
          object_id,
        });
      }}
      className="text-sec-purple-700 hover:underline"
    >
      {object_name}
    </Link>
  );
};

const StaticLink = ({
  object_name,
  path,
  eventLocation,
}: {
  object_name: string;
  path: string;
  eventLocation: PagedLocation;
}) => {
  return (
    <Link
      to={path}
      target="_blank"
      rel="noopener"
      onClick={() => {
        analytics.track(`${eventLocation} StaticLink`, {
          path,
        });
      }}
      className="text-sec-purple-700 hover:underline"
    >
      {object_name}
    </Link>
  );
};

// This should render differently than an ObjectLink.
export const ObjectName = ({ object_name }: { object_name: string }) => (
  <span className="text-pri-success-600">{object_name}</span>
);

export const DeletedObjectName = ({ object_name }: { object_name: string }) => (
  <span className="text-pri-error-900">{object_name}</span>
);

export const ObjectDelta = ({ value }: { value: string }) => (
  <span className="text-pri-success-600">{value}</span>
);

export const ObjectJSON = ({ value }: { value: Object }) => (
  <span className="whitespace-pre-wrap">
    <ObjectDelta value={JSON.stringify(value, undefined, 2)} />
  </span>
);

/*******************************************************************************
 * Object Link Parts
 ******************************************************************************/
export interface UserActionSentencePartProps {
  userAction: UserAction;
  eventLocation: PagedLocation;
}

export const ConnectorLinkOrName = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  const { object_id, object_type, object_name } = userAction;
  const { connectorsByID } = useContext(TableModelsContext);

  // The backend should always give us id for Connectors, but satisfy TypeScript since other UserActions don't require id.
  if (!object_id) {
    return <ObjectName object_name={object_name} />;
  }
  // ATTENTION BEN:
  // On my system the first time a connector test loads on storybook the test renders correctly.
  // If you click on another connector test and come back to the first,
  // the link that should render as deleted renders as a link because the data is still in the context.
  // For some reason(probably a race condition) this only effects connectors and not the other object types.
  // Maybe the context in MockAuthenticatedProviders isn't getting reset.
  // Gold Star if you figure this out.
  // console.log(object_id, connectors, connectorsByID);

  const connector = connectorsByID[object_id];

  // The object has been deleted or created since we last fetched the list of objects.
  // Fall back on text.
  if (!connector) {
    return <DeletedObjectName object_name={object_name} />;
  }

  return (
    <ObjectLink
      object_id={object_id}
      object_type={object_type}
      object_name={object_name}
      path={connectorPath(connector.id)}
      eventLocation={eventLocation}
    />
  );
};

interface TableLinkProps extends UserActionSentencePartProps {
  pickPath?: (table: AggTable, userAction: UserAction) => string;
}

export const TableLinkOrName = ({ userAction, pickPath, eventLocation }: TableLinkProps) => {
  const {
    object_type,
    object_id,
    object_name,
    event_type,
    metadata: { table_id },
  } = userAction;
  const { tablesByID, transformsByID } = useContext(TableModelsContext);

  // The backend should always give us id for Tables, but satisfy TypeScript since other UserActions don't require id.
  if (!object_id) {
    return <ObjectName object_name={object_name} />;
  }

  // Get table on best effort.
  // Sometimes the API returns a table id and other times it returns a transform id.
  let table = tablesByID[table_id || object_id];

  if (!table) {
    const transform = transformsByID[object_id];
    if (transform) {
      table = tablesByID[transform.table_id];
    }
  }

  // The object has been deleted or created since we last fetched the list of objects.
  // Fall back on text.
  if (!table) {
    return <DeletedObjectName object_name={object_name} />;
  }

  // Choose which tab to use in the url.
  // Default to summary tab because all tables have that tab.
  let tab = 'summary';
  // All Transform events go to the transform tab.
  if (object_type === 'transform') {
    tab = 'transform';
  }
  // Renamed transforms are of userAction.object_type 'table' but they go to the transform tab.
  else if (table.type === 'transform' && event_type === 'renamed') {
    tab = 'transform';
  }
  // All alerts go to the alerts tab.
  else if (object_type === 'table_test') {
    tab = 'alerts';
  }

  let url = pickPath ? pickPath(table, userAction) : tablePath(table.id, tab);

  return (
    <ObjectLink
      object_id={object_id}
      object_type={object_type}
      object_name={object_name}
      path={url}
      eventLocation={eventLocation}
    />
  );
};

export const DbtRunConfigOrName = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  const { object_type, object_name, object_id } = userAction;
  const { dbtRunConfigsByID } = useContext(TableModelsContext);

  // The backend should always give us id for DbtRunConfigs, but satisfy TypeScript since other UserActions don't require id.
  if (!object_id) {
    return <ObjectName object_name={object_name} />;
  }
  const dbtRunConfig = dbtRunConfigsByID[object_id];

  if (!dbtRunConfig) {
    return <DeletedObjectName object_name={object_name} />;
  }
  const url = dbtRunConfigurationPath(object_id);
  return (
    <ObjectLink
      object_name={object_name}
      object_id={object_id}
      object_type={object_type}
      path={url}
      eventLocation={eventLocation}
    />
  );
};

export const SnowflakeUsersLink = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  const { object_name } = userAction;
  return <StaticLink object_name={object_name} path="/users/snowflake" eventLocation={eventLocation} />;
};

export const ObjectLinkOrName = ({ userAction, eventLocation }: UserActionSentencePartProps) => {
  const { object_type, object_name } = userAction;

  switch (object_type) {
    case 'connector':
      return <ConnectorLinkOrName userAction={userAction} eventLocation={eventLocation} />;
    case 'transform':
    case 'table':
    case 'table_test':
      return <TableLinkOrName userAction={userAction} eventLocation={eventLocation} />;
    case 'dbt_run_configuration':
      return <DbtRunConfigOrName userAction={userAction} eventLocation={eventLocation} />;
    case 'snowflake_user':
      return <SnowflakeUsersLink userAction={userAction} eventLocation={eventLocation} />;
    default:
      return <ObjectName object_name={object_name} />;
  }
};
