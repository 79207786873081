import React from 'react';

import { Formik } from 'formik';
import * as yup from 'yup';

import { AggTable } from 'api/APITypes';
import Button from 'components/inputs/basic/Button/Button';
import LinkButton from 'components/inputs/basic/Button/LinkButton';
import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import TextFormikGroup from 'components/inputs/formik_group/TextFormikGroup/TextFormikGroup';
import Modal from 'components/layouts/containers/modals/Modal/Modal';
import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';
import { useDatabaseAccount } from 'context/AuthContext';
import { Vertex } from 'pages/tables/ShowTable/PipelineTab/PipelineEditor/PipelineEditor';
import SchemaCombobox from 'pages/transforms/SchemaCombobox';
import { valDBIdentifier, valSchemaName } from 'utils/Validators';

const validationSchema = yup.object({
  name: valDBIdentifier('Table Name'),
  schema: valSchemaName('Schema').trim(),
});

interface RenameTableProps {
  name: string;
  schema: string;
}

interface RenameModalProps {
  table: AggTable;
  saving: boolean;
  error: string;
  loadingPipeline: boolean;
  hasSnapshot: boolean;
  directDownstreamVertices: Vertex[];
  snapshotDirectDownstreamVertices: Vertex[];
  autoUpdateDownstreamSQL: boolean;
  toggleAutoUpdate: () => void;
  closeModal(): void;
  saveName(values: RenameTableProps): void;
}

export default function RenameModal(props: RenameModalProps) {
  const {
    table,
    saving,
    error,
    loadingPipeline,
    hasSnapshot,
    directDownstreamVertices,
    snapshotDirectDownstreamVertices,
    autoUpdateDownstreamSQL,
    toggleAutoUpdate,
    closeModal,
    saveName,
  } = props;
  const { schema, name } = table;
  const databaseType = useDatabaseAccount().type;

  const initialValues: RenameTableProps = {
    name: name,
    schema: schema,
  };

  const hasDownstream =
    directDownstreamVertices.length > 0 || snapshotDirectDownstreamVertices.length > 0;

  let infoSection = (
    <Alert variant="info" className="!items-start">
      <p>
        This table{hasSnapshot ? ' and its snapshot have' : ' has'} no downstream transforms in Mozart
        that would need to be updated.
      </p>
      <p>
        There may be other references that need to be updated, such as Data Alerts or queries in a BI
        tool.
      </p>
    </Alert>
  );
  if (hasDownstream) {
    infoSection = (
      <Alert variant="warning">
        <div className="w-full flex flex-col">
          <div className="mb-4">
            <p>
              Renaming this table will impact downstream transforms whose references to this table will
              need to be updated.
            </p>
            <p>
              There may be other references that need to be updated, such as Data Alerts or queries in a
              BI tool.
            </p>
          </div>
          {directDownstreamVertices.length > 0 && (
            <div className="flex flex-col ml-4">
              <div className="mb-2">The following tables reference this table:</div>
              {directDownstreamVertices.map((d) => (
                <LinkButton
                  size="small"
                  variant="dangerTransparent"
                  to={`/tables/${d.table?.id}/transform`}
                  disabled={d.table === null}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-fit"
                  key={d.id}
                >
                  {d.table ? d.table.full_name : d.name}
                </LinkButton>
              ))}
            </div>
          )}
          {snapshotDirectDownstreamVertices.length > 0 && (
            <div className="ml-4">
              <div className="mb-2">
                The following tables reference this table's snapshot, which will also be renamed:
              </div>
              {snapshotDirectDownstreamVertices.map((d) => (
                <LinkButton
                  size="small"
                  variant="dangerTransparent"
                  to={`/tables/${d.table?.id}/transform`}
                  disabled={d.table === null}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-fit"
                  key={d.id}
                >
                  {d.table ? d.table.full_name : d.name}
                </LinkButton>
              ))}
            </div>
          )}
        </div>
      </Alert>
    );
  }

  const modalHeader = (
    <div className="text-xl">
      Rename {schema}.{name}
    </div>
  );
  return (
    <Modal header={modalHeader} onClose={closeModal} containerClass="mx-10">
      <Formik validationSchema={validationSchema} onSubmit={saveName} initialValues={initialValues}>
        {({ handleSubmit, handleChange, values }) => {
          // Don't let unnoticed spaces mess up input validation
          const trimmedChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            event.target.value = event.target.value.trim();
            handleChange(event);
          };
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div className="min-h-[480px] flex-col ml-2 p-4">
                {error && (
                  <Alert variant="error" className="mb-4">
                    {error}
                  </Alert>
                )}
                <div className="w-full grid grid-cols-2 gap-4">
                  {databaseType !== 'bigquery' && (
                    <SchemaCombobox schemaValue={values.schema} tableType="transform" />
                  )}
                  <TextFormikGroup
                    name="name"
                    label="Table Name"
                    placeholder="Table Name"
                    onChange={trimmedChange}
                    groupClass="w-full"
                  />
                </div>
                <div className="mt-4">
                  {loadingPipeline ? <CenteredSpinner containerClass="mt-[100px]" /> : infoSection}
                </div>
              </div>
              <div className="f-between p-4">
                <div className="flex ml-2">
                  {hasDownstream && (
                    <>
                      <Checkbox
                        name="autoUpdate"
                        checked={autoUpdateDownstreamSQL}
                        variant="blue_gray"
                        onChange={toggleAutoUpdate}
                      />
                      <div className="ml-1 text-pry-gray-400">
                        Automatically update downstream SQL on save
                      </div>
                    </>
                  )}
                </div>
                <div className="f-row-y-center">
                  <Button variant="lightAction" type="submit" className="ml-2" spinning={saving}>
                    Save
                  </Button>
                  <Button variant="lightDanger" className="ml-2" onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}
