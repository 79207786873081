import Hotkeys from 'components/widgets/Hotkeys/Hotkeys';
import { isMac } from 'utils/browser';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CommandPaletteTrigger(
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) {
  return (
    <button className={'h-7 px-2 f-row-y-center rounded-[14px] bg-silver'} {...props}>
      <div style={{ marginTop: '1px' }}>
        <FontAwesomeIcon icon={faSearch} className="text-pri-gray-500" />
      </div>
      <span className="px-2 text-sm text-pri-gray-500">Find Anything</span>
      <Hotkeys hotkeys={`${isMac() ? '⌘' : '^'}+p`} size="small" variant="dull" />
    </button>
  );
}
