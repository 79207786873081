import React, { useState } from 'react';

import { AxiosResponse } from 'axios';
import cn from 'classnames';
import { FormikHelpers } from 'formik';

import tagAPI, { Tag, SaveableTagProps } from 'api/tagAPI';
import IconButton from 'components/inputs/basic/Button/IconButton';
import ConfirmModal from 'components/layouts/containers/modals/ConfirmModal/ConfirmModal';
import { useUserProfile } from 'context/AuthContext';

import TagPill from '../TagPill';

import EditTagForm from './EditTagForm';

import s from './ListTags.module.css';

export interface TagRowProps extends Tag {
  tags: Tag[];
  addedThisPageVisit: boolean;
  updateTags: (tags: Tag[]) => void;
  removeTag: (tag: Tag) => void;
}

export default function TagRow(props: TagRowProps) {
  const { id, name, description, color, tags, addedThisPageVisit, updateTags, removeTag } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [saving, setSaving] = useState(false);
  const {
    userProfile: { company_role },
  } = useUserProfile();

  /*******************************************************************************
   * Editing
   ******************************************************************************/
  const handleOpenTagEditor = () => {
    setEditMode(true);
    setErrorMessage('');
    analytics.track('ListTags OpenTagRowEditor');
  };

  const handleCancelTagEditor = () => {
    setEditMode(false);
    setErrorMessage('');
    analytics.track('ListTags CancelTagRowEditor');
  };

  const handleUpdateTag = (
    saveableProps: SaveableTagProps,
    formikHelpers: FormikHelpers<SaveableTagProps>,
  ) => {
    setSaving(true);
    tagAPI
      .update(id, saveableProps)
      .then((response: AxiosResponse<Tag>) => {
        const newTag = response.data;
        updateTags([newTag]);
        setEditMode(false);
        analytics.track('ListTags UpdateTag');
      })
      .catch((error) => {
        // Form validation errors
        if (error.response?.status === 400) {
          const data = error.response.data;
          setErrorMessage(data.non_field_errors);
          formikHelpers.setErrors(data);
        } else {
          setErrorMessage('Error saving tag.');
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  /*******************************************************************************
   * Deleting
   ******************************************************************************/
  const handleCancelDelete = () => {
    analytics.track('ListTags CancelDelete');
    setConfirmDelete(false);
  };

  const handleOpenConfirmDeleteModal = () => {
    analytics.track('ListTags OpenConfirmDeleteModal');
    setConfirmDelete(true);
  };

  const handleDeleteTag = (tagID: string) => {
    setSaving(true);
    tagAPI
      .delete(tagID)
      .then((response: AxiosResponse<Tag>) => {
        removeTag(props);
        analytics.track('ListTags DeleteTag');
      })
      .catch((error) => {
        setErrorMessage('Error deleting tag.');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleConfirmDelete = () => {
    handleDeleteTag(id);
    setConfirmDelete(false);
  };

  return (
    <tr className={cn({ newListItem: addedThisPageVisit })}>
      {editMode ? (
        <td colSpan={3} className={s.editTagCell}>
          <EditTagForm
            tags={tags}
            initialValues={{
              name,
              description,
              color,
            }}
            saving={saving}
            errorMessage={errorMessage}
            editMode={true}
            eventPage="ListTags"
            handleCancel={handleCancelTagEditor}
            handleSave={handleUpdateTag}
          />
        </td>
      ) : (
        <>
          <td style={{ verticalAlign: 'middle' }}>
            <div className="f-row-y-center">
              <TagPill tag={props} eventPage="ListTags" />
            </div>
          </td>
          <td style={{ verticalAlign: 'middle' }}>{description}</td>
          {company_role !== 'viewer' && (
            <td style={{ verticalAlign: 'middle' }}>
              <div className="f-center">
                <div className="f-center">
                  <IconButton
                    icon="PencilSquare"
                    variant="lightDullTransparent"
                    size="small"
                    onClick={handleOpenTagEditor}
                  />
                </div>
                <div className="f-center ml-2">
                  <IconButton
                    icon="Trash"
                    variant="lightDullTransparent"
                    size="small"
                    onClick={handleOpenConfirmDeleteModal}
                  />
                </div>
              </div>
              {confirmDelete && (
                <ConfirmModal
                  header={
                    <div className="f-row-y-center">
                      <span>Are you sure you want to delete the tag&nbsp;</span>
                      <TagPill tag={props} eventPage="ListTags" disableLink={true} />
                      <span>?</span>
                    </div>
                  }
                  confirmText="Delete"
                  confirmVariant="darkDanger"
                  onCancel={handleCancelDelete}
                  onConfirm={handleConfirmDelete}
                />
              )}
            </td>
          )}
        </>
      )}
    </tr>
  );
}
