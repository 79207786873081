import React, { useContext } from 'react';

import { OpenAIUsageContext } from 'pages/internal/OpenAIUsageContext';

export default function OpenAIUsageTracker() {
  const { model, tokenCounts } = useContext(OpenAIUsageContext);
  return (
    <div>
      <h3>Model: {model}</h3>
      <h3>Total Prompt Tokens Used: {tokenCounts.promptTokens}</h3>
      <h3>Total Completion Tokens Used: {tokenCounts.completionTokens}</h3>
    </div>
  );
}
