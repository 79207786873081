export const reservedWords = new Set([
  'all',
  'and',
  'any',
  'array',
  'as',
  'asc',
  'assert_rows_modified',
  'at',
  'between',
  'by',
  'case',
  'cast',
  'collate',
  'contains',
  'create',
  'cross',
  'cube',
  'current',
  'default',
  'define',
  'desc',
  'distinct',
  'else',
  'end',
  'enum',
  'escape',
  'except',
  'exclude',
  'exists',
  'extract',
  'false',
  'fetch',
  'following',
  'for',
  'from',
  'full',
  'group',
  'grouping',
  'groups',
  'hash',
  'having',
  'if',
  'ignore',
  'in',
  'inner',
  'intersect',
  'interval',
  'into',
  'is',
  'join',
  'lateral',
  'left',
  'like',
  'limit',
  'lookup',
  'merge',
  'natural',
  'new',
  'no',
  'not',
  'null',
  'nulls',
  'of',
  'on',
  'or',
  'order',
  'outer',
  'over',
  'partition',
  'preceding',
  'proto',
  'qualify',
  'range',
  'recursive',
  'respect',
  'right',
  'rollup',
  'rows',
  'select',
  'set',
  'some',
  'struct',
  'tablesample',
  'then',
  'to',
  'treat',
  'true',
  'unbounded',
  'union',
  'unnest',
  'using',
  'when',
  'where',
  'window',
  'with',
  'within',
]);
