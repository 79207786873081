type ErrorListDictionary = { [key: string]: string[] };
type ErrorDictionary = { [key: string]: string };
type SlugDictionary = { [key: string]: string };

function deslug(error: string, slugDictionary: SlugDictionary): string;
function deslug(errors: ErrorListDictionary, slugDictionary: SlugDictionary): ErrorDictionary;
function deslug(
  errors: string | ErrorListDictionary,
  slugDictionary: SlugDictionary,
): ErrorDictionary | string {
  // We were passed a string, return a string
  if (typeof errors === 'string') {
    return slugDictionary[errors] || errors;
  }

  // We were passes an ErrorListDictionary, return a ErrorDictionary.
  const newErrors: ErrorDictionary = {};
  for (const k in errors) {
    const firstSlug = errors[k][0];
    newErrors[k] = slugDictionary[firstSlug] || firstSlug;
  }
  return newErrors;
}

export { deslug };
