/*****************************************************************************
Virtual Schema Keys were designed to be super debuggable to programmers,
but they look a gross in URLs. This file converts virtual schema keys
into a cleaner a more concise format.

We could maybe use this more concise format everywhere, but we are not updating
everywhere virtual schema keys are used to avoid breaking things.
*****************************************************************************/
import {
  MOZART_ALL,
  MOZART_RECENT,
  MOZART_FAVORITES,
  MOZART_TRANSFORMS,
  MOZART_SNAPSHOTS,
  UNMANAGED_SCHEMA,
  VIRTUAL_SCHEMA_KEY_DIVIDER,
  virtualSchemaKey,
  virtualSchemaKeyParts,
} from './virtualSchema';

export const UK_ALL = 'all';
export const UK_RECENT = 'recent';
export const UK_FAVORITES = 'favorites';
export const UK_SNAPSHOTS = 'snapshots';
export const UK_TRANSFORM = 't';
export const UK_UNMANAGED = 'u';

export function vskToUrlKey(vsk: string) {
  const { virtualSchemaType, schema } = virtualSchemaKeyParts(vsk);
  if (virtualSchemaType === MOZART_ALL) {
    return UK_ALL;
  } else if (virtualSchemaType === MOZART_RECENT) {
    return UK_RECENT;
  } else if (virtualSchemaType === MOZART_FAVORITES) {
    return UK_FAVORITES;
  } else if (virtualSchemaType === MOZART_SNAPSHOTS) {
    return UK_SNAPSHOTS;
  } else if (virtualSchemaType === MOZART_TRANSFORMS) {
    return UK_TRANSFORM + VIRTUAL_SCHEMA_KEY_DIVIDER + schema;
  } else if (virtualSchemaType === UNMANAGED_SCHEMA) {
    return UK_UNMANAGED + VIRTUAL_SCHEMA_KEY_DIVIDER + schema;
  }

  return 'VSK_TO_URL_KEY_ERROR';
}

export function urlKeyToVSK(urlSchemaKey: string) {
  const { urlSchemaType, schema } = urlKeyParts(urlSchemaKey);
  if (urlSchemaType === UK_ALL) {
    return virtualSchemaKey(MOZART_ALL, MOZART_ALL);
  } else if (urlSchemaType === UK_RECENT) {
    return virtualSchemaKey(MOZART_RECENT, MOZART_RECENT);
  } else if (urlSchemaType === UK_FAVORITES) {
    return virtualSchemaKey(MOZART_FAVORITES, MOZART_FAVORITES);
  } else if (urlSchemaType === UK_SNAPSHOTS) {
    return virtualSchemaKey(MOZART_SNAPSHOTS, 'snapshots');
  } else if (urlSchemaType === UK_TRANSFORM) {
    return virtualSchemaKey(MOZART_TRANSFORMS, schema);
  } else if (urlSchemaType === UK_UNMANAGED) {
    return virtualSchemaKey(UNMANAGED_SCHEMA, schema);
  }

  return 'URL_KEY_TO_VSK_ERROR';
}

export function urlKeyParts(urlSchemaKey: string) {
  // Some url keys don't have a divider.
  // Default to setting the urlSchemaType and schema to the urlSchemaKey
  let urlSchemaType = urlSchemaKey;
  let schema = urlSchemaKey;

  // Split on the first occurrence of the divider.
  const firstIndex = urlSchemaKey.indexOf(VIRTUAL_SCHEMA_KEY_DIVIDER);
  if (firstIndex !== -1) {
    urlSchemaType = urlSchemaKey.slice(0, firstIndex);
    schema = urlSchemaKey.slice(firstIndex + VIRTUAL_SCHEMA_KEY_DIVIDER.length);
  }
  return { urlSchemaType, schema };
}
