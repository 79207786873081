import { AggTable } from 'api/tableAPI';
import TableIcon from 'components/primitives/icons/TableIcon/TableIcon';
import { humanSyncFrequency } from 'model_helpers/aggTableHelper';

import { APIVertex } from '../PipelineTab/PipelineConverter';
import { DependencyEdge, Edge, Vertex } from '../PipelineTab/PipelineEditor/PipelineEditor';

// This type definition is needed to make Typescript happy
export type AnyVertex = APIVertex | Vertex;
export type AnyEdge = DependencyEdge | Edge;
export interface RunAfterDependenciesProps {
  title: string;
  vertexDependencies: AnyEdge[];
  vertices: AnyVertex[];
  tablesByID: { [key: string]: AggTable };
}

export default function RunAfterDependencies(props: RunAfterDependenciesProps) {
  const { title, vertexDependencies, vertices, tablesByID } = props;
  return (
    <div>
      <div className="">{title}</div>
      <div className="mt-1 whitespace-nowrap">
        {vertexDependencies
          .filter((d) => d.is_scheduling_dependency)
          .map((d) => {
            // TODO: Fix legacy get_dependencies/get_lineage API cruft.
            // vertexDependencies is more likely to be up to date because they are updated
            // in the handleSaveTransform() function; however, apiPipeline, and tablesByID
            // are probably up to date 99% of the time.
            // Try to look up the AggTable on on a best effort basis.
            let table = null;
            const vertex = vertices.find((v: { id: string }) => v.id === d.source);
            if (vertex) {
              const tableID =
                typeof vertex.table === 'string' ? vertex.table : vertex?.table?.id || 'MISSING';
              table = tablesByID[tableID] || null;
            }
            const tableName = table?.full_name || (d as DependencyEdge).source_name || 'MISSING';
            return (
              <div key={d.id} className="mt-1 f-row-y-center whitespace-nowrap">
                {table && <TableIcon table={table} size={20} variant="dullAction" />}
                <div className="ml-2">{tableName}</div>
                {table && <div className="ml-2 text-pri-gray-400">{humanSyncFrequency(table)}</div>}
              </div>
            );
          })}
      </div>
    </div>
  );
}
