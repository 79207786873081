import { Link } from 'react-router-dom';

import { Connector, UserProfile } from 'api/APITypes';
import { Dashboard, ReportAvailable } from 'api/dashboardAPI';
import BackButton from 'components/inputs/basic/Button/BackButton';
import Button from 'components/inputs/basic/Button/Button';
import IconButton from 'components/inputs/basic/Button/IconButton';
import Alert from 'components/widgets/alerts/Alert/Alert';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';
import { humanSyncFrequency } from 'model_helpers/connectorHelper';
import uph from 'model_helpers/userProfileHelper';

import ConnectorLayout from '../ConnectorLayout';
import { authorizeGSheetPath } from '../pathUtils';
import { trackDashboard } from '../reportUtils';

import DeleteReportModal from './DeleteReportModal';
import TransformTable from './TransformTable/TransformTable';

export interface ShowReportViewProps {
  connector: Connector;
  dashboard?: Dashboard;
  reportsAvailable: ReportAvailable[];
  showConfirmDelete: boolean;
  deleting: boolean;
  deleteError: string;
  userProfile: UserProfile;
  onOpenSpreadsheet(): void;
  onBackToConnectors(): void;
  onDeleteAttempted(): void;
  onDeleteCanceled(): void;
  onDeleteConfirmed(): void;
}

export default function ShowReportView(props: ShowReportViewProps) {
  const {
    connector,
    dashboard,
    reportsAvailable,
    showConfirmDelete,
    deleting,
    deleteError,
    userProfile,
    onOpenSpreadsheet,
    onBackToConnectors,
    onDeleteAttempted,
    onDeleteCanceled,
    onDeleteConfirmed,
  } = props;

  const hasGSheet = dashboard && dashboard.gsheet_spreadsheet_id !== null;
  const isReportReady = dashboard && dashboard.initial_setup_email_sent_at !== null;
  const isCurrentUserAuthor = dashboard && dashboard.created_by.user.email === userProfile.user.email;

  const handleGoToAuthorizeGSheet = () => {
    if (dashboard) {
      trackDashboard('ReportBuilderShowReport GoToAuthorizeGSheet', connector, dashboard);
    }
  };

  return (
    <ConnectorLayout connector={connector} maxWidth="920px">
      <div className="mt-6 f-row-y-center justify-between">
        <h2 className="text-lg font-medium">Report Builder</h2>
        <BackButton text="ALL CONNECTORS" onClick={onBackToConnectors} />
      </div>

      {hasGSheet ? (
        <>
          <div className="mt-4 f-row-y-center">
            <IconButton
              icon="FileBarGraphFill"
              text="Open Report"
              variant="darkDullAction"
              disabled={!isReportReady}
              onClick={onOpenSpreadsheet}
            />
            <div className="ml-2">
              {isReportReady ? (
                <div>
                  Updates after the connector syncs, currently set to{' '}
                  {humanSyncFrequency(connector.sync_frequency).toLowerCase()}.
                </div>
              ) : (
                <div>
                  Your report is still running. The report's creator will get an email once it's ready.
                </div>
              )}
            </div>
          </div>
          {!isCurrentUserAuthor && isReportReady && (
            <Alert variant="info" className="my-6 !p-2">
              If you need permission to open this report contact its creator,{' '}
              {uph.displayName(dashboard.created_by)}.
            </Alert>
          )}
          <div className="mt-6">
            {/* There is a split second rerender between dashboard delete and page redirect where dashboard is undefined. */}
            {dashboard && (
              <TransformTable
                dashboard={dashboard}
                reportsAvailable={reportsAvailable}
                connector={connector}
              />
            )}
          </div>
        </>
      ) : (
        <FixMeAlert
          heading="Google Sheet Authorization Required"
          alertClass="my-6"
          additionalWordClass="max-w-[520px]"
          detail={
            <p className="max-w-[520px] mt-2">
              This report cannot run until Mozart Data is granted authorization to write a google sheet
              to your account. Go{' '}
              <Link
                to={authorizeGSheetPath(connector)}
                onClick={handleGoToAuthorizeGSheet}
                className="text-sec-blue-light-700 font-medium"
              >
                HERE
              </Link>{' '}
              and grant Mozart Data access.
            </p>
          }
        />
      )}

      <div className="my-6 f-row-y-center">
        {userProfile.company_role !== 'viewer' && (
          <Button onClick={onDeleteAttempted} variant="lightDanger">
            Delete Report
          </Button>
        )}
      </div>
      {showConfirmDelete && (
        <DeleteReportModal
          reportsAvailable={reportsAvailable}
          deleting={deleting}
          error={deleteError}
          onCancel={onDeleteCanceled}
          onConfirm={onDeleteConfirmed}
          connector={connector}
        />
      )}
    </ConnectorLayout>
  );
}
