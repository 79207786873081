import React, { useState } from 'react';

import { X } from 'react-bootstrap-icons';

import cn from 'classnames';

import IconButton from 'components/inputs/basic/Button/IconButton';
import Checkbox from 'components/inputs/basic/Checkbox/Checkbox';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import { useDatabaseAccount } from 'context/AuthContext';
import { convertColumnName } from 'utils/dbName';

import s from './Columnizer.module.css';

interface ColumnizerProps {
  selectedColumns: string[];
  hideUnselectedColumns: boolean;
  onUnselectColumn: (column: string) => void;
  onInvertColumns: () => void;
  onUnselectAllColumns: () => void;
  onDropColumn: (draggedColumn: string, droppedOnColumn: string) => void;
  setHideUnselectedColumns: (filter: boolean) => void;
}

const Columnizer = React.memo((props: ColumnizerProps) => {
  const {
    selectedColumns,
    hideUnselectedColumns,
    onUnselectColumn,
    onInvertColumns,
    onUnselectAllColumns,
    onDropColumn,
    setHideUnselectedColumns,
  } = props;
  const databaseType = useDatabaseAccount().type;

  const [draggingColumn, setDraggingColumn] = useState('');
  const [dragEntered, setDragEntered] = useState('');

  const handleUnselectColumnTag = (column: string) => {
    analytics.track('Columnizer UnselectColumnTag');
    onUnselectColumn(column);
  };

  const handleCopyColumns = () => {
    analytics.track('Columnizer CopyColumns');
    navigator.clipboard.writeText(
      selectedColumns.map((c) => convertColumnName(c, databaseType)).join(', '),
    );
  };

  const handleClose = () => {
    analytics.track('Columnizer Close');
    onUnselectAllColumns();
  };

  const handleToggleHideUnselectedColumns = () => {
    const newValue = !hideUnselectedColumns;
    analytics.track(newValue ? 'Columnizer HideUnselectedColumns' : 'Columnizer ShowUnselectedColumns');
    setHideUnselectedColumns(newValue);
  };

  const onDragStart = (column: string, event: React.DragEvent<HTMLSpanElement>) => {
    setDraggingColumn(column);
    event.dataTransfer.setData('text/plain', column);
  };

  const onDragEnd = () => {
    setDraggingColumn('');
  };

  const onDragOver = (event: React.DragEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (column: string) => {
    setDragEntered(column);
  };

  const onDragLeave = (event: React.DragEvent<HTMLSpanElement>) => {
    // This event fires when we mouse over a column tag's child element.
    // Do not remove the class when this happens.
    // @ts-ignore
    if (event.target.classList.contains(s.columnTag)) {
      setDragEntered('');
    }
  };

  const onDrop = (droppedOnColumn: string, event: React.DragEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setDraggingColumn('');
    setDragEntered('');
    const draggedColumn = event.dataTransfer.getData('text/plain');
    onDropColumn(draggedColumn, droppedOnColumn);
  };

  return (
    <div className={s.columnizer}>
      <div>
        {selectedColumns.map((c, i) => {
          const className = cn(s.columnTag, 'text-sm', {
            [s.dragging]: c === draggingColumn,
            [s.dragEntered]: c === dragEntered,
          });
          return (
            <div
              key={c}
              draggable="true"
              onDragStart={(e) => onDragStart(c, e)}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
              onDragEnter={() => onDragEnter(c)}
              onDragLeave={onDragLeave}
              onDrop={(e) => onDrop(c, e)}
              className={className}
            >
              <span className={s.name}>{convertColumnName(c, databaseType)}</span>
              <span onClick={() => handleUnselectColumnTag(c)} className={s.removeButton}>
                <X size="24" color="var(--sec-blue-gray-500)" />
              </span>
            </div>
          );
        })}
      </div>
      <div className={s.controls}>
        <div className="flex mr-2">
          <Checkbox
            name="hideUnselectedColumns"
            variant="blue_gray"
            checked={hideUnselectedColumns}
            onChange={handleToggleHideUnselectedColumns}
            className="mr-2 align-self-center"
          />
          <div className="text-pri-gray-700">Hide Unselected</div>
        </div>

        <div className="flex">
          <TooltipTrigger tip="Copy Selected Columns">
            <IconButton
              icon="Files"
              variant="lightDullTransparent"
              onClick={handleCopyColumns}
              size="small"
            />
          </TooltipTrigger>
          <TooltipTrigger tip="Invert Selected Columns">
            <IconButton
              icon="ArrowLeftRight"
              variant="lightDullTransparent"
              onClick={onInvertColumns}
              size="small"
            />
          </TooltipTrigger>
          <TooltipTrigger tip="Close">
            <IconButton icon="XLg" variant="lightDullTransparent" onClick={handleClose} size="small" />
          </TooltipTrigger>
        </div>
      </div>
    </div>
  );
});

export default Columnizer;
