import React from 'react';

import AnimatedAngleIcon from 'components/primitives/icons/AnimatedAngleIcon/AnimatedAngleIcon';

import s from './ExpandingTreeNode.module.css';

export interface ExpandingTreeNodeProps {
  titleHeight: number;
  enabled: boolean;
  expanded: boolean;
  onExpand(): void;
  children: React.ReactNode[];
}

export default function ExpandingTreeNode(props: ExpandingTreeNodeProps) {
  const { titleHeight, enabled, expanded, onExpand, children } = props;

  const handleClick = () => {
    if (enabled) {
      onExpand();
    }
  };

  const dim = `${titleHeight}px`;
  const angleClass = 'f-center' + (enabled ? '' : ' text-pri-gray-400');

  return (
    <div className={s.titleRow}>
      <div className={angleClass} style={{ width: dim, height: dim }}>
        <AnimatedAngleIcon isOpen={expanded} mode="accordion" onClick={handleClick} />
      </div>
      <div className={s.expandingColumn}>
        {children[0]}
        {expanded && children[1]}
      </div>
    </div>
  );
}
