import React, { useContext, useEffect } from 'react';

import { useHistory } from 'react-router';
import { useTitle } from 'react-use';

import LinkButton from 'components/inputs/basic/Button/LinkButton';
import CenteredLayout from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import DbtIcon from 'components/primitives/icons/DbtIcon/DbtIcon';
import FixMeAlert from 'components/widgets/alerts/FixMeAlert/FixMeAlert';
import { useUserProfile } from 'context/AuthContext';
import { TableModelsContext } from 'model_layer/TableModelsContext';

import DBTRunConfigTable from './DBTRunConfigTable';

const ListDBTRunConfigs = () => {
  useTitle('dbt jobs');
  const { dbtRunConfigs, loadedDbtRunConfigs } = useContext(TableModelsContext);
  const {
    userProfile: { company_role },
  } = useUserProfile();
  const history = useHistory();
  // TODO: The list of dbtRunConfigs should be updated by polling in the model layer.

  // If the user does NOT have any dbtRunConfigs yet, redirect them to the add page.
  useEffect(() => {
    if (loadedDbtRunConfigs && dbtRunConfigs.length === 0 && company_role !== 'viewer') {
      history.push('/dbt/jobs/add');
    }
  }, [history, dbtRunConfigs, loadedDbtRunConfigs, company_role]);

  const sortedDBTRunConfigs = [...dbtRunConfigs].sort((a, b) => a.name.localeCompare(b.name));

  const header = (
    <div className="f-between">
      <div className="f-row-y-center">
        <DbtIcon size={32} />
        <h1 className="ml-2 display-sm text-pri-gray-700">dbt jobs</h1>
      </div>
      {company_role !== 'viewer' && (
        <LinkButton
          variant="lightAction"
          className="ml-4"
          to="/dbt/jobs/add"
          data-track={'ListDBTRunConfigs AddConfig'}
        >
          Add Job
        </LinkButton>
      )}
    </div>
  );

  return (
    <CenteredLayout maxWidth="1100px" header={header} loading={!loadedDbtRunConfigs}>
      {sortedDBTRunConfigs.length > 0 ? (
        <DBTRunConfigTable runConfigs={sortedDBTRunConfigs} />
      ) : (
        <FixMeAlert
          heading="No dbt jobs yet."
          detail={<p className="mt-2">Users with editor or admin permissions can create dbt jobs.</p>}
          alertClass="mt-2"
        />
      )}
    </CenteredLayout>
  );
};

export default ListDBTRunConfigs;
