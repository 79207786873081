/*
The user is authenticated but their account might be
disabled or setup might be incomplete.
Usually, this file will render the `MainApp`.
*/
// sort-imports-ignore
import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';

import { UserProfile } from 'api/APITypes';
import AccountDisabledHeader from 'components/page_parts/headers/AccountDisabledHeader/AccountDisabledHeader';
import MainApp from 'main/MainApp';
import SelectWarehouseController from 'pages/byo/SelectWarehouse/SelectWarehouseController';
import SetupBYOBQController from 'pages/byo/SetupBYOBQ/SetupBYOBQController';
import SetupBYOSController from 'pages/byo/SetupBYOS/SetupBYOSController';
import Page404 from 'pages/Page404';
import TrialExpired from 'pages/TrialExpired';

interface AuthenticatedAppProps {
  userProfile: UserProfile;
}

const AuthenticatedApp = (props: AuthenticatedAppProps) => {
  /*
  A user's account could be disabled for several reasons.
  MVP code is to always forward any disabled account to the /trial-expired page.
  In the unlikely event an account is disabled for another reason,
  customer support will manually sort things out.
  */
  if (!props.userProfile.company.is_enabled) {
    return (
      <>
        <AccountDisabledHeader />
        <TrialExpired />
      </>
    );
  }

  // The user doesn't have a database account.
  // Force them to configure that.
  if (!props.userProfile.company.database_account) {
    return (
      <>
        <AccountDisabledHeader />
        <Switch>
          <CompatRoute path="/" exact>
            <Redirect to={{ pathname: '/select-warehouse' }} />
          </CompatRoute>
          <CompatRoute path="/select-warehouse" exact component={SelectWarehouseController} />
          <CompatRoute path="/setup-snowflake" exact component={SetupBYOSController} />
          <CompatRoute path="/setup-bigquery" exact component={SetupBYOBQController} />
          <Route>
            <Page404 isAuthenticated={true} />
          </Route>
        </Switch>
      </>
    );
  }

  // Everything is normal.
  // You are go for launch.
  if (props.userProfile.company.is_enabled && props.userProfile.company.database_account) {
    return <MainApp userProfile={props.userProfile} />;
  }

  // When all else fails, do this.
  return (
    <>
      <AccountDisabledHeader />
      <CompatRoute component={Page404} />
    </>
  );
};

export default AuthenticatedApp;
