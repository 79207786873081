import React from 'react';

import CenteredSpinner from 'components/layouts/parts/CenteredSpinner/CenteredSpinner';
import Alert from 'components/widgets/alerts/Alert/Alert';

import { UsageTab } from '../Usage';

import UsageFilter, { UsageFilterProps } from './UsageFilter/UsageFilter';

interface UsageTabLayoutProps extends UsageFilterProps {
  loading: boolean;
  error: string;
  zeroText: string; // Set to non-empty string to show this message instead of the headerBox, chart, and table.
  headerBox: React.ReactNode;
  chart: React.ReactNode;
  table: React.ReactNode;
  tabType: UsageTab;
}

export default function UsageTabLayout(props: UsageTabLayoutProps) {
  const {
    loading,
    error,
    zeroText,
    interval,
    startDate,
    endDate,
    connectorName,
    setInterval,
    setDateRange,
    onBack,
    headerBox,
    chart,
    table,
    tabType,
  } = props;

  return (
    <div className="mb-4">
      {error && <Alert variant="error">{error}</Alert>}
      <UsageFilter
        interval={interval}
        startDate={startDate}
        endDate={endDate}
        connectorName={connectorName}
        setInterval={setInterval}
        setDateRange={setDateRange}
        onBack={onBack}
        tabType={tabType}
      />
      <>
        {/* 
            This div keeps DateRangeCalendar from being clipped.
            This is probably not the best way to fix this bug.
        */}
        <div style={{ minHeight: '400px' }}>
          {loading ? (
            <CenteredSpinner containerMinHeight="300px" />
          ) : (
            <>
              {zeroText ? (
                <div className="f-col-center " style={{ minHeight: '200px' }}>
                  <h3>{zeroText}</h3>
                </div>
              ) : (
                <>
                  <div>{headerBox}</div>
                  <div>{chart}</div>
                  <div className="mt-4">{table}</div>
                </>
              )}
            </>
          )}
        </div>
      </>
    </div>
  );
}
