import { NotificationEventType } from 'api/APITypes';

interface NotificationEvent {
  notification_event_type: NotificationEventType;
  entity_id: string;
  name: string;
  error: string;
}

export interface InAppNotification {
  id: string;
  seen: boolean;
  notification_event: NotificationEvent;
  url: string | null;
  created_at: string;
}

export interface PaginatedInAppNotification {
  count: number;
  next: string | null;
  previous: string | null;
  results: InAppNotification[];
}

type NotificationEventTypeDisplayMap = {
  [key in NotificationEventType]: string;
};

const notificationEventTypeDisplayMap: NotificationEventTypeDisplayMap = {
  transform_failure: 'Transform Failure',
  connector_initial_sync: 'Connector Initial Sync',
  connector_failure: 'Connector Sync Failures',
  gsheet_failure: 'GSheet Failure',
  data_alert: 'Data Alert',
  dbt_run_failure: 'DBT Run Failure',
};

export const displayableEventType = (notificationEventType: NotificationEventType) => {
  return notificationEventTypeDisplayMap[notificationEventType] || 'Unknown';
};
