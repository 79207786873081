import React, { useState } from 'react';

import { useTitle } from 'react-use';

import API from 'api/API';
import Button from 'components/inputs/basic/Button/Button';
import Alert from 'components/widgets/alerts/Alert/Alert';

export default function ResetDemoPage() {
  useTitle('Mozart: Reset Demo');

  const [done, setDone] = useState(false);
  const [working, setWorking] = useState(false);
  const [error, setError] = useState(false);

  let handleClick = async (event: any) => {
    const api = new API();
    setWorking(true);
    setError(false);
    setDone(false);

    try {
      await api.get('api/XXX/reset_demo');
      setWorking(false);
      setDone(true);
    } catch (error) {
      setWorking(false);
      setError(true);
    }
  };

  return (
    <>
      {error && (
        <Alert variant="error">There was a problem resetting the demo db. Please tell Dan.</Alert>
      )}
      {done && <Alert variant="no_results">Done! Please enjoy your fresh demo data.</Alert>}
      <div className="m-4">
        <Button variant="lightAction" onClick={handleClick} spinning={working}>
          Reset demo data
        </Button>
      </div>
    </>
  );
}
