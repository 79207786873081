import cn from 'classnames';

import Popover from 'components/overlay/Popover/Popover';
import PopperTrigger from 'components/overlay/PopperTrigger/PopperTrigger';

import { VertexType } from '../../model/FlowchartQueryModel';

import HoverContent from './HoverContent';
import ToolbarItemIcon from './ToolbarItemIcon';

const ICON_WIDTH = 20;

export interface ToolbarItemDefinition {
  id: VertexType;
  title: string;
  description: string;
  img: string;
}

export interface ToolbarItemProps extends ToolbarItemDefinition {
  enabled: boolean;
}

const ToolbarItem = (props: ToolbarItemProps) => {
  const { id, title, description, img, enabled } = props;

  const handleDragStart = (vertexType: VertexType, event: React.DragEvent<HTMLSpanElement>) => {
    const encodedData = JSON.stringify({ dropType: 'ToolbarItem', vertexType });
    event.dataTransfer.setData('text/plain', encodedData);
  };

  const handleDragOver = (event: React.DragEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const itemClass = cn('w-[36px] p-2 rounded', {
    'hover:bg-sec-blue-gray-200': enabled,
    'hover:cursor-pointer': enabled,
  });

  return (
    <PopperTrigger
      placement="right"
      triggers="hover"
      delay={{ show: 500, hide: 50 }}
      popoverProps={{ style: { maxWidth: '700px' } }}
      renderPopper={(popperProps) => {
        return (
          <Popover
            content={<HoverContent title={title} description={description} img={img} />}
            {...popperProps}
          />
        );
      }}
    >
      <div
        className={itemClass}
        draggable={enabled}
        onDragStartCapture={(e) => handleDragStart(id, e)}
        onDragOverCapture={handleDragOver}
      >
        <ToolbarItemIcon vertexType={id} enabled={enabled} size={ICON_WIDTH} />
      </div>
    </PopperTrigger>
  );
};

export default ToolbarItem;
