import React, { useMemo, useState } from 'react';

import { AggTable, Tag } from 'api/APITypes';
import IconButton from 'components/inputs/basic/Button/IconButton';
import TooltipTrigger from 'components/overlay/TooltipTrigger/TooltipTrigger';
import { useUserProfile } from 'context/AuthContext';
import TagPill from 'pages/tags/TagPill';
import { caselessCompare } from 'utils/String';

import CreateTagModal from './CreateTagModal';
import TagPicker from './TagPicker';

interface TagListProps {
  allTags: Tag[];
  table: AggTable;
}

export default function TagList(props: TagListProps) {
  const { allTags, table } = props;
  const [showTagPicker, setShowTagPicker] = useState(false);
  const [showCreateTagModal, setShowCreateTagModal] = useState(false);
  const { userProfile } = useUserProfile();

  const myTags = useMemo(() => {
    const myTags = allTags.filter((t) => table.tags.includes(t.id));
    myTags.sort((a: Tag, b: Tag) => caselessCompare(a.name, b.name));
    return myTags;
  }, [allTags, table]);

  const handleOpenTagPicker = () => {
    setShowTagPicker(true);
    analytics.track('SummaryTab OpenTagPicker');
  };

  const handleCloseTagPicker = () => {
    setShowTagPicker(false);
    analytics.track('SummaryTab CloseTagPicker');
  };

  const handleAfterTagPickerSaveTags = () => {
    setShowTagPicker(false);
    // Event is logged by TagPicker
  };

  const handleOpenCreateTagModal = () => {
    setShowTagPicker(false);
    setShowCreateTagModal(true);
    analytics.track('SummaryTab OpenCreateTagModal');
  };

  const handleAfterCreateTag = () => {
    setShowCreateTagModal(false);
    // Event is logged in CreateTagModal
  };

  const handleCancelCreateTagModal = () => {
    setShowCreateTagModal(false);
    analytics.track('SummaryTab CancelCreateTagModal');
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className="f-row-y-center">
        {myTags.length === 0 ? (
          <div className="f-row-y-center mr-1 text-pri-gray-400">None</div>
        ) : (
          <div className="f-row-y-center" style={{ maxWidth: '90%', flexWrap: 'wrap' }}>
            {myTags.map((t) => (
              <TagPill key={t.id} tag={t} eventPage="SummaryTab" className="mr-1 my-1" />
            ))}
          </div>
        )}
        {userProfile.company_role !== 'viewer' && (
          <TooltipTrigger tip="Edit Tags">
            <IconButton
              icon="Tag"
              size="small"
              variant="lightDullTransparent"
              onClick={handleOpenTagPicker}
            />
          </TooltipTrigger>
        )}
      </div>
      {showTagPicker && (
        <TagPicker
          table={table}
          eventPage="SummaryTab"
          style={{ top: '43px' }}
          onClosePicker={handleCloseTagPicker}
          onCreateNew={handleOpenCreateTagModal}
          onAfterSaveTags={handleAfterTagPickerSaveTags}
        />
      )}

      {showCreateTagModal && (
        <CreateTagModal
          table={table}
          eventPage="SummaryTab"
          onAfterCreate={handleAfterCreateTag}
          onCancel={handleCancelCreateTagModal}
        />
      )}
    </div>
  );
}
