import { Connector } from 'api/APITypes';
import CenteredLayout, {
  CenteredLayoutProps,
} from 'components/layouts/pages/CenteredLayout/CenteredLayout';
import ConnectorIcon from 'components/primitives/icons/ConnectorIcon/ConnectorIcon';

import { LAYOUT_WIDTH } from './PickReportsRouter';

interface ConnectorLayoutProps extends Omit<CenteredLayoutProps, 'title' | 'maxWidth' | 'header'> {
  connector: Pick<Connector, 'service' | 'schema'>;
  // The max-width of the centered column as a CSS prop.
  maxWidth?: string;
}

export default function ConnectorLayout(props: ConnectorLayoutProps) {
  const { connector, maxWidth, children, ...rest } = props;
  const { service } = connector;

  const header = (
    <div className="f-row-y-center">
      <ConnectorIcon service={service} size={36} />
      <h1 className="ml-2 display-sm text-pri-gray-700">{connector.schema}</h1>
    </div>
  );

  return (
    <CenteredLayout maxWidth={maxWidth || LAYOUT_WIDTH} header={header} {...rest}>
      {children}
    </CenteredLayout>
  );
}
