import { useCallback, useMemo } from 'react';

import { Command } from '../MozartCommandPalette';

export interface HelpCommand extends Command {
  url: string;
  path: string;
}

interface HelpCommandPrecursor {
  name: string;
  url: string;
  path: string;
}

// HelpCommandPrecursors are the unique properties of a HelpCommand.
// The properties that are the same for all HelpCommands
// are applied by metaprogramming later on.
// These should be ordered from most important to least important.
const helpPrecursors: HelpCommandPrecursor[] = [
  { name: 'Getting Started', url: 'https://help.mozartdata.com/docs', path: 'N/A' },
  {
    name: 'Setting Up a Data Warehouse',
    url: 'https://help.mozartdata.com/docs/setting-up-data-warehouse',
    path: 'N/A',
  },
  { name: 'Connectors', url: 'https://help.mozartdata.com/docs/connectors', path: '/connectors' },
  { name: 'CSV Uploader', url: 'https://help.mozartdata.com/docs/csv-uploader', path: '/csv' },
  { name: 'Transforms', url: 'https://help.mozartdata.com/docs/transforms', path: '/transform' },
  {
    name: 'Incremental Transforms',
    url: 'https://help.mozartdata.com/docs/incremental-transforms',
    path: '/transform',
  },
  { name: 'Warehouse', url: 'https://help.mozartdata.com/docs/warehouse', path: '/warehouse' },
  { name: 'Queries', url: 'https://help.mozartdata.com/docs/queries', path: '/query' },
  { name: 'Snapshots', url: 'https://help.mozartdata.com/docs/snapshotting', path: '/warehouse' },
  { name: 'Integrations', url: 'https://help.mozartdata.com/docs/integrations', path: '/integrations' },
  { name: 'Data Alerts', url: 'https://help.mozartdata.com/docs/data-alerts', path: '/alerts' },
  { name: 'Usage', url: 'https://help.mozartdata.com/docs/usage', path: '/usage' },
  {
    name: 'Optimizing Usage from the Start',
    url: 'https://help.mozartdata.com/docs/optimizing-usage-from-the-start',
    path: 'N/A',
  },
  {
    name: 'How To Save Money',
    url: 'https://help.mozartdata.com/docs/how-to-save-money',
    path: '/usage',
  },
  { name: 'FAQs', url: 'https://help.mozartdata.com/docs/faq', path: 'N/A' },
  { name: 'dbt', url: 'https://help.mozartdata.com/docs/dbt-core-integration', path: '/dbt' },
  {
    name: 'Notifications',
    url: 'https://help.mozartdata.com/docs/notifications',
    path: '/notifications',
  },
  {
    name: 'User Actions',
    url: 'https://help.mozartdata.com/docs/user-actions',
    path: '/user-actions',
  },
  {
    name: 'Incremental Transforms',
    url: 'https://help.mozartdata.com/docs/incremental-transforms',
    path: '/transforms',
  },
  {
    name: 'Version History',
    url: 'https://help.mozartdata.com/docs/version-history',
    path: '/versionHistory',
  },
  {
    name: 'Data Pipeline',
    url: 'https://help.mozartdata.com/docs/pipeline-features',
    path: '/pipeline',
  },
  { name: 'FAQ', url: 'https://help.mozartdata.com/docs/faq', path: 'N/A' },
  { name: 'Mozart Data Users & Roles', url: 'https://help.mozartdata.com/docs/users', path: '/users' },
  {
    name: `Power User's Cheat Sheet`,
    url: 'https://help.mozartdata.com/docs/power-users-cheat-sheet',
    path: 'N/A',
  },
  {
    name: `Changelog`,
    url: 'https://help.mozartdata.com/docs/changelog',
    path: 'N/A',
  },
];

export default function useHelpCommands(
  openExternalPath: (path: string, modKeyPressed: boolean) => void,
) {
  const openHelp = useCallback(
    function (this: HelpCommand, modKeyPressed: boolean) {
      openExternalPath(this.url, modKeyPressed);
      return true;
    },
    [openExternalPath],
  );

  // Turn HelpCommandPrecursors into HelpCommands.
  const helpCommands: HelpCommand[] = useMemo(
    () =>
      helpPrecursors.map<HelpCommand>((hp) => ({
        name: hp.name,
        url: hp.url,
        path: hp.path,
        type: 'help',
        command: openHelp,
      })),
    [openHelp],
  );

  return helpCommands;
}
