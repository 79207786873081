import React from 'react';

import cn from 'classnames';

import s from './SpinningLogo.module.css';

interface LogoProps {
  style?: React.CSSProperties;
  className?: string;
}

function SpinningLogo(props: LogoProps) {
  const { style, className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={cn(s.svg, className)}
      fill="none"
      viewBox="0 0 32 20"
    >
      <g className={s.pipes}>
        <path
          fill="var(--purple)"
          d="M24.12 11.137a1.275 1.275 0 00-1.274 1.275v4.99H9.106v-4.99a1.275 1.275 0 00-1.274-1.275H1.275a1.275 1.275 0 100 2.55h5.282v4.989a1.275 1.275 0 001.275 1.275h16.289a1.275 1.275 0 001.275-1.275v-6.264a1.275 1.275 0 00-1.275-1.275zM30.725 6.264h-5.282v-4.99A1.275 1.275 0 0024.168 0H7.879a1.275 1.275 0 00-1.275 1.275v6.264a1.275 1.275 0 002.55 0v-4.99h13.74v4.99a1.275 1.275 0 001.274 1.275h6.557a1.275 1.275 0 100-2.55z"
        ></path>
      </g>
      <g className={s.m}>
        <path
          fill="var(--purple)"
          d="M11.676 14.316h1.348a.456.456 0 00.453-.387l.404-2.836 1.607 3.08a.477.477 0 00.407.258h.207a.501.501 0 00.405-.254l1.6-3.082.413 2.832a.47.47 0 00.454.392h1.35a.46.46 0 00.352-.16.453.453 0 00.1-.372l-1.292-7.682a.456.456 0 00-.442-.386h-.183a.48.48 0 00-.404.24L16 10.528l-2.452-4.565a.486.486 0 00-.407-.246h-.183a.455.455 0 00-.442.387l-1.292 7.684a.45.45 0 00.452.527z"
        ></path>
      </g>
    </svg>
  );
}

export default SpinningLogo;
