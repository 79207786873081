/*
Merges data returned by tables, transforms, and connectors API calls
to create a list of AggTables and other data structures
that are useful to the Warehouse and other pages.
*/
import { keyBy, sortBy } from 'lodash';

import {
  AggTable,
  Transform,
  Connector,
  TableVisit,
  Favorite,
  FavoritesByTableID,
  Tag,
} from 'api/APITypes';
import { CSVUpload } from 'api/csvUploadAPI';
import { DbtRunConfig, DbtDestinationTable } from 'api/dbtAPI';
import { AggRecentTable } from 'api/tableAPI';
import { fivetranConnectors } from 'pages/connectors/ConnectorRegistry';

export type ConnectorsBySchema = {
  // Different types of connectors create different arrangements of data.
  // Connectors can create many schemas with many tables, one schmea with many tables, or a single table.
  // Key is a schema prefix, schema, for full_name depending on the connector type.
  [key: string]: Connector;
};

export type TagsByID = {
  [key: string]: Tag;
};

interface AggTableState {
  connectors: Connector[];
  hasConnector: boolean;
  connectorsByID: { [id: string]: Connector }; // Connectors keyed by Mozart UUID, not Fivetran ID.
  connectorsBySchema: ConnectorsBySchema;
  _apiTables: AggTable[]; // This reducer's private copy of what the API returned.
  tables: AggTable[]; // The public list of tables used by the app, excluding any tables in _apiTables that are invalid.
  tablesByID: { [id: string]: AggTable }; // AggTableState.tables keyed by ID
  tablesByFullName: { [fullName: string]: AggTable }; // AggTableState.tables keyed by full_name
  transforms: Transform[];
  transformsByID: { [id: string]: Transform }; // AggTableState.transform keyed by ID
  transformsByTableID: { [tableID: string]: Transform }; // AggTableState.transform keyed by table ID
  recentTables: AggRecentTable[];
  favoriteTables: AggTable[];
  recents: TableVisit[];
  favorites: Favorite[];
  favoritesByTableID: FavoritesByTableID;
  tags: Tag[];
  tagsByID: TagsByID;
  dbtRunConfigs: DbtRunConfig[];
  dbtRunConfigsByID: { [id: string]: DbtRunConfig };
  dbtDestinationTables: DbtDestinationTable[];
  dbtDestinationTablesByID: { [id: string]: DbtDestinationTable };
  dbtDestinationTablesByTableID: { [tableID: string]: [DbtDestinationTable] };
  latestCsvUploads: CSVUpload[];
  latestCsvUploadsByTableID: { [tableID: string]: CSVUpload };
  loadedConnectors: boolean;
  loadedTables: boolean;
  loadedTransforms: boolean;
  loadedRecents: boolean;
  loadedFavorites: boolean;
  loadedTags: boolean;
  loadedDbtRunConfigs: boolean;
  loadedDbtDestinationTables: boolean;
  loadedLatestCsvUploads: boolean;
  aggTablesLoaded: boolean;
}

export const initialState: AggTableState = {
  connectors: [],
  // True seems like a better default than false in this particular case.
  hasConnector: true,
  connectorsByID: {},
  connectorsBySchema: {},
  _apiTables: [],
  tables: [],
  tablesByID: {},
  tablesByFullName: {},
  transforms: [],
  transformsByID: {},
  transformsByTableID: {},
  recentTables: [],
  favoriteTables: [],
  recents: [],
  favorites: [],
  favoritesByTableID: {},
  tags: [],
  tagsByID: {},
  dbtRunConfigs: [],
  dbtRunConfigsByID: {},
  dbtDestinationTables: [],
  dbtDestinationTablesByID: {},
  dbtDestinationTablesByTableID: {},
  latestCsvUploads: [],
  latestCsvUploadsByTableID: {},
  loadedConnectors: false,
  loadedTables: false,
  loadedTransforms: false,
  loadedRecents: true, // Temporary hack sets this to true. Undo once recents implemented.
  loadedFavorites: false,
  loadedTags: false,
  loadedDbtRunConfigs: false,
  loadedDbtDestinationTables: false,
  loadedLatestCsvUploads: false,
  aggTablesLoaded: false,
};

type ReducerAction =
  | 'SET_TABLES'
  | 'SET_TRANSFORMS'
  | 'SET_CONNECTORS'
  | 'SET_RECENTS'
  | 'SET_FAVORITES'
  | 'SET_TAGS'
  | 'SET_DBT_RUN_CONFIGS'
  | 'SET_DBT_DESTINATION_TABLES'
  | 'SET_LATEST_CSV_UPLOADS';

interface Action {
  type: ReducerAction;
  data: any;
}

export function reducer(prevState: AggTableState, action: Action) {
  switch (action.type) {
    case 'SET_CONNECTORS':
      setConnectors(prevState, action.data);
      break;
    case 'SET_TABLES':
      setTables(prevState, action.data);
      break;
    case 'SET_TRANSFORMS':
      setTransforms(prevState, action.data);
      break;
    case 'SET_RECENTS':
      setRecents(prevState, action.data);
      break;
    case 'SET_FAVORITES':
      setFavorites(prevState, action.data);
      break;
    case 'SET_TAGS':
      setTags(prevState, action.data);
      break;
    case 'SET_DBT_RUN_CONFIGS':
      setdbtRunConfigs(prevState, action.data);
      break;
    case 'SET_DBT_DESTINATION_TABLES':
      setDbtDestinationTables(prevState, action.data);
      break;
    case 'SET_LATEST_CSV_UPLOADS':
      setLatestCsvUploads(prevState, action.data);
      break;
    default:
      throw new Error();
  }

  // Return new object to force a rerender.
  // Only the changed properties should be new objects.
  // So, memoized calculations should not be recomputed
  // unless their inputs were changed.
  return { ...prevState };
}

function checkAppendTransforms(prevState: AggTableState) {
  const { loadedTransforms, loadedTables, _apiTables, transformsByTableID } = prevState;
  if (loadedTransforms && loadedTables) {
    _apiTables.forEach((table) => {
      table.transform = transformsByTableID[table.id] || null;
    });
  }
}

function checkAllLoaded(prevState: AggTableState) {
  const {
    loadedConnectors,
    loadedTables,
    loadedTransforms,
    loadedRecents,
    loadedFavorites,
    loadedTags,
    loadedDbtRunConfigs,
    loadedDbtDestinationTables,
    loadedLatestCsvUploads,
  } = prevState;
  const aggTablesLoaded =
    loadedConnectors &&
    loadedTables &&
    loadedTransforms &&
    loadedRecents &&
    loadedFavorites &&
    loadedTags &&
    loadedDbtRunConfigs &&
    loadedDbtDestinationTables &&
    loadedLatestCsvUploads;

  if (aggTablesLoaded) {
    onAllLoaded(prevState);
  }

  prevState.aggTablesLoaded = aggTablesLoaded;
}

function setConnectors(prevState: AggTableState, connectors: Connector[]) {
  prevState.loadedConnectors = true;
  prevState.hasConnector = connectors.length > 0;
  prevState.connectors = connectors;
  prevState.connectorsByID = keyBy(prevState.connectors, 'id');
  prevState.connectorsBySchema = buildConnectorsBySchema(connectors);
  checkAppendConnectors(prevState);
  checkAllLoaded(prevState);
}

function setTables(prevState: AggTableState, tables: AggTable[]) {
  prevState.loadedTables = true;
  prevState._apiTables = tables;
  prevState.tablesByID = keyBy(prevState._apiTables, 'id');
  prevState.tablesByFullName = keyBy(prevState._apiTables, 'full_name');
  checkAppendConnectors(prevState);
  checkAppendTransforms(prevState);
  checkAppendTagObjs(prevState);
  checkAppendDbtDestinationTables(prevState);
  checkAppendDbtRunConfigs(prevState);
  checkAppendCsvUploads(prevState);
  checkAllLoaded(prevState);
}

function setTransforms(prevState: AggTableState, transforms: Transform[]) {
  prevState.loadedTransforms = true;
  prevState.transforms = transforms;
  prevState.transformsByID = keyBy(prevState.transforms, 'id');
  prevState.transformsByTableID = keyBy(prevState.transforms, 'table_id');
  checkAppendTransforms(prevState);
  checkAllLoaded(prevState);
}

function onAllLoaded(prevState: AggTableState) {
  const { _apiTables } = prevState;
  // Tables and their different table creating object(TCO) APIs can load at different times in different orders.
  // It's possible that the tables API will return a transform table
  // that does not have a corresponding transform object in the most recent transfrom API response.
  // So, filter transform tables without transform objects out.
  // This is also important because it will create a new array so we trigger a new React render.
  prevState.tables = _apiTables.filter((t) => t.type !== 'transform' || t.transform !== null);

  // These methods assume prevState.tables has been set.
  // So, run these here.
  checkBuildRecentTables(prevState);
  checkBuildFavoriteTables(prevState);
}

function checkAppendConnectors(state: AggTableState) {
  const { loadedConnectors, loadedTables, connectorsBySchema, _apiTables } = state;
  if (loadedConnectors && loadedTables) {
    _apiTables.forEach((u) => {
      u.connector = findConnector(u, connectorsBySchema);
    });
  }
}

// Build lookup table from array
function buildConnectorsBySchema(connectors: Connector[]) {
  const connectorsBySchema: ConnectorsBySchema = {};
  for (const c of connectors) {
    connectorsBySchema[c.schema] = c;
  }
  return connectorsBySchema;
}

// TODO: We are still using this method instead of the connector_ids returned by the API to find connectors.
// Change this in another PR.
function findConnector(table: AggTable, connectorsBySchema: ConnectorsBySchema) {
  // 0. Short circuit if its not unmanaged.
  // Only unmanaged tables have connectors.
  if (table.type !== 'unmanaged') {
    return null;
  }

  // 1. Base case: The connector key is the same as its schema.
  let connector = connectorsBySchema[table.schema];
  if (connector) {
    return connector;
  }

  // 2. The connector is on a per table basis as in google_sheets
  const registryConnector = fivetranConnectors[table.schema];
  if (registryConnector && registryConnector.syncScope === 'table') {
    connector = connectorsBySchema[table.full_name];
    if (connector) {
      return connector;
    }
  }

  // 3. The connector key has a schema prefix as in any database connector
  const words = table.schema.split('_');
  const numWords = words.length;
  for (let i = 1; i < numWords; i++) {
    const growingSchema = words.slice(0, i).join('_');
    if (connectorsBySchema.hasOwnProperty(growingSchema)) {
      connector = connectorsBySchema[growingSchema];
    }
  }
  // Since it's possible to have multiple connector schemas that partially match a table schema, we need to pick the longest matching one
  // For example, if the table.schema === 'mongo', we can have connectors.schemas 'mongo', 'mongo_test', and 'mongo_test_1'.
  //    In this case, we want to keep growing our schema and pick the longest/last one.
  if (connector) {
    return connector;
  }

  // Reasons there is no connector:
  // 1. It's a transform table or a snapshot table.
  // 2. The table was made by a new type of connector that we have not added to the connectorDic yet.
  // 3. Added a table to Snowflake not via a connector.
  // 4. Deleted a connector but not the corresponding Snowflake table.
  // 5. Fivetran's number appended to connector name gets underscore prefixed bug:
  //    Fivetran will map a connector named `table_name1` to schemas named like `table_name_1` that causes the lookup in this method to fail.
  return null;
}

function setRecents(prevState: AggTableState, recents: TableVisit[]) {
  prevState.loadedRecents = true;
  prevState.recents = recents;
  checkBuildRecentTables(prevState);
  checkAllLoaded(prevState);
}

function checkBuildRecentTables(state: AggTableState) {
  const { loadedRecents, loadedTables, tables, recents } = state;
  if (loadedRecents && loadedTables) {
    // Note: The API returns recents in most recent first order
    const recentTables: AggRecentTable[] = recents
      .map((recent) => {
        const table = tables.find((table) => table.id === recent.id);
        return (
          table && {
            ...table,
            last_visited_at: recent.last_visited_at,
          }
        );
      })
      .filter((table): table is AggRecentTable => table !== undefined);

    state.recentTables = recentTables;
  }
}

function setFavorites(prevState: AggTableState, favorites: Favorite[]) {
  prevState.loadedFavorites = true;
  prevState.favorites = favorites;
  checkBuildFavoriteTables(prevState);
  checkAllLoaded(prevState);
}

function checkBuildFavoriteTables(state: AggTableState) {
  const { loadedFavorites, loadedTables, tables, favorites } = state;
  if (loadedFavorites && loadedTables) {
    state.favoritesByTableID = keyBy(favorites, 'table');
    let favoriteTables = favorites
      .map((f) => {
        return tables.find((t) => t.id === f.table);
      })
      .filter((t) => t !== undefined) as AggTable[];
    favoriteTables = sortBy(favoriteTables, ['full_name']);
    state.favoriteTables = favoriteTables;
  }
}

function setTags(prevState: AggTableState, tags: Tag[]) {
  prevState.loadedTags = true;
  prevState.tags = tags;
  prevState.tagsByID = keyBy(tags, 'id');
  checkAppendTagObjs(prevState);
  checkAllLoaded(prevState);
}

function checkAppendTagObjs(state: AggTableState) {
  const { loadedTables, loadedTags, _apiTables, tagsByID } = state;
  if (loadedTables && loadedTags) {
    _apiTables.forEach((table) => {
      // The vast majority of tables will not have tags, so this IF will be a performance boost.
      if (table.tags.length > 0) {
        // Only include tags we have objects for.
        const newTags: Tag[] = [];
        table.tags.forEach((tagID) => {
          const tag = tagsByID[tagID];
          if (tag) {
            newTags.push(tag);
          }
        });
        newTags.sort((a: Tag, b: Tag) => {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        });
        table.tagObjs = newTags;
      }
    });
  }
}

function setdbtRunConfigs(prevState: AggTableState, dbtRunConfigs: DbtRunConfig[]) {
  prevState.loadedDbtRunConfigs = true;
  prevState.dbtRunConfigs = dbtRunConfigs;
  prevState.dbtRunConfigsByID = keyBy(dbtRunConfigs, 'id');
  checkAppendDbtDestinationTables(prevState);
  checkAppendDbtRunConfigs(prevState);
  checkAllLoaded(prevState);
}

function checkAppendDbtRunConfigs(state: AggTableState) {
  const {
    loadedTables,
    loadedDbtRunConfigs,
    loadedDbtDestinationTables,
    _apiTables,
    dbtRunConfigsByID,
  } = state;
  if (loadedTables && loadedDbtRunConfigs && loadedDbtDestinationTables) {
    _apiTables.forEach((table) => {
      // Program this defensively in case there is somehow a dbtRunConfit table without a dbtDestinationTable.
      const dbtRunConfigs: DbtRunConfig[] = [];
      table.dbtDestinationTables.forEach((dt) => {
        const runConfig = dbtRunConfigsByID[dt.configuration];
        if (runConfig) {
          dbtRunConfigs.push(runConfig);
        }
      });
      table.dbtRunConfigs = dbtRunConfigs;
    });
  }
}

function setDbtDestinationTables(prevState: AggTableState, dbtDestinationTables: DbtDestinationTable[]) {
  prevState.loadedDbtDestinationTables = true;
  prevState.dbtDestinationTables = dbtDestinationTables;
  prevState.dbtDestinationTablesByTableID = dbtDestinationTables.reduce(
    (acc, dt) => {
      const destinations = acc[dt.table];
      if (destinations) {
        destinations.push(dt);
      } else {
        acc[dt.table] = [dt];
      }
      return acc;
    },
    {} as { [tableID: string]: [DbtDestinationTable] },
  );
  checkAppendDbtDestinationTables(prevState);
  checkAppendDbtRunConfigs(prevState);
  checkAllLoaded(prevState);
}

function checkAppendDbtDestinationTables(state: AggTableState) {
  const { loadedTables, loadedDbtDestinationTables, _apiTables, dbtDestinationTablesByTableID } = state;
  if (loadedTables && loadedDbtDestinationTables) {
    _apiTables.forEach((table) => {
      table.dbtDestinationTables = dbtDestinationTablesByTableID[table.id] || [];
    });
  }
}

function setLatestCsvUploads(prevState: AggTableState, csvUploads: CSVUpload[]) {
  prevState.loadedLatestCsvUploads = true;
  prevState.latestCsvUploads = csvUploads;
  prevState.latestCsvUploadsByTableID = keyBy(csvUploads, 'table');
  checkAppendCsvUploads(prevState);
  checkAllLoaded(prevState);
}

function checkAppendCsvUploads(state: AggTableState) {
  const { loadedTables, loadedLatestCsvUploads, latestCsvUploadsByTableID, _apiTables } = state;
  if (loadedTables && loadedLatestCsvUploads) {
    _apiTables.forEach((table) => {
      const upload = latestCsvUploadsByTableID[table.id];
      table.csvUpload = upload || null;
    });
  }
}
