import reactStringReplace from 'react-string-replace';

import { union } from 'lodash';

import { regexFrom } from 'utils/Array';
import { KeywordLists } from 'utils/TableFilter';

// Replaces any part of a string that matches the filter expression with
// HTML that highlights the match.
export const highlightSearch = (name: string, filter: string | RegExp) => {
  // filter === '' means highlight nothing.
  // If this isn't here reactStringReplace() will split a string into an array of characters
  // when filter is an empty string.
  if (filter === '') {
    return name;
  }

  return reactStringReplace(name, filter, (match, i) => (
    <span key={i} className="bg-search-yellow">
      {match}
    </span>
  ));
};

// `filterIncludes` has every possible search filter in it, most of which do not apply
// to the property the frontend is currently trying to render.
// `pickHighlightFilter` picks the RegExp to search for when doing a highlight operation.
// `view` is the property the frontend is currently trying to render and highlight.
export const pickHighlightFilter = (
  filterIncludes: KeywordLists,
  view: 'full_name' | 'schema' | 'table' | 'column' | 'description',
) => {
  let filters: (string | RegExp)[] = [];
  let forceRegex = false;

  // The default search searches schema, table, and column.
  // Therefore, we want to include the default search in all of those searches.
  // Description is not in the default search so it skips this step.
  // The frontend may be rendering the full_name as one string(view === full_name) or it may be rendering
  // the table name as discrete pieces (view === schema or view === table).
  if (filterIncludes.f && view !== 'description') {
    if (view === 'full_name') {
      filters = union(filters, filterIncludes.f);
    } else {
      // filters may or may not span the dot separating a schema and a table as in:
      // "art.dim" would span both sides of "mozart.dim_companies" and "com" would not.
      // We want to pick the part that is on the current view's side of the dot.
      const splitFilters = filterIncludes.f.map((i) => i.split('.'));
      const correctSideOfSlitFilters = splitFilters.map((sf) => {
        if (sf.length === 1) {
          return sf[0];
        } else {
          forceRegex = true;
          if (view === 'schema') {
            return new RegExp(sf[0] + '$'); // Must border end of string.
          } else {
            return new RegExp('^' + sf[1]); // Must border start of string.
          }
        }
      });
      filters = union(filters, correctSideOfSlitFilters);
    }
  }

  if (filterIncludes.s && view === 'schema') {
    filters = union(filters, filterIncludes.s);
  }

  if (filterIncludes.t && view === 'table') {
    filters = union(filters, filterIncludes.t);
  }

  if (filterIncludes.c && view === 'column') {
    filters = union(filters, filterIncludes.c);
  }

  if (filterIncludes.td && view === 'description') {
    filters = union(filters, filterIncludes.td);
  }

  // Performance optimization.
  if (filters.length === 0) {
    return '';
  }

  // Performance optimization.
  if (filters.length === 1 && !forceRegex) {
    return filters[0];
  }

  // TODO BUG:
  // The strings "bbcc" and "ccdd" overlap at "cc", but this does not match
  // overlaps. So this will not match all of the string "bbccdd" in "aabbccddee".

  return regexFrom(filters, 'gi');
};
