import Button from 'components/inputs/basic/Button/Button';
import Alert from 'components/widgets/alerts/Alert/Alert';

interface ConnectSnowflakeProps {
  handleShowSnowflakeInSigmaClick: () => void;
  handleDoSetupSnowflakeInSigma: () => void;
  settingUpSnowflake: boolean;
  setupSnowflakeError: string | null;
}

const ConnectSnowflake = (props: ConnectSnowflakeProps) => {
  const {
    handleShowSnowflakeInSigmaClick,
    handleDoSetupSnowflakeInSigma,
    settingUpSnowflake,
    setupSnowflakeError,
  } = props;
  return (
    <div>
      {setupSnowflakeError && (
        <Alert variant="error" className="mb-4">
          {setupSnowflakeError}
        </Alert>
      )}
      <h2 className="text-md font-medium">Make Snowflake Connection</h2>
      <p className="text-md mt-2">
        If you have not already connected Sigma to your Mozart Data Snowflake database, the button below
        will do it for you in one click.
      </p>
      <div className="w-full h-[1px] bg-pri-gray-200 my-4" />
      <div className="flex justify-end space-x-4">
        <Button onClick={handleShowSnowflakeInSigmaClick} variant="lightTransparent">
          Already Connected
        </Button>
        <Button
          onClick={handleDoSetupSnowflakeInSigma}
          variant="lightAction"
          spinning={settingUpSnowflake}
        >
          Connect to Snowflake
        </Button>
      </div>
    </div>
  );
};

export default ConnectSnowflake;
