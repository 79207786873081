import React from 'react';

import ProgressBar from 'components/widgets/progress/ProgressBar/ProgressBar';
import { percentFormatted } from 'utils/Math';
import { cleanColor } from 'utils/String';

import { UsageTabs } from './Usage';
import { CompanyPlanRow } from './useUsageFetch';

interface UsageProgressBarProps {
  currentMonthPlan: CompanyPlanRow;
  currentMonthValue: number; // XXX TODO: Maybe rename this
  tab: UsageTabs;
}

// This component uses a series of useMemos to filter and group the data
export default function UsageProgressBar(props: UsageProgressBarProps) {
  const { currentMonthPlan, currentMonthValue, tab } = props;

  const isCompute = tab === 'compute';

  const progressBarTitle = isCompute ? 'Total Compute Credits this month:' : 'Total MAR this month:';
  const progressBarUnits = isCompute ? 'credits' : 'MAR';
  const progressBarAllotment = isCompute
    ? currentMonthPlan.SUM_SNOWFLAKE_CREDITS_ALLOTMENT
    : currentMonthPlan.SUM_FIVETRAN_MAR_ALLOTMENT;

  const backgroundColor = isCompute ? cleanColor('--pri-success-50') : cleanColor('--sec-blue-50');
  const progressBarBackgroundColor = isCompute
    ? cleanColor('--pri-success-100')
    : cleanColor('--sec-blue-100');
  let textAndBarColor = isCompute ? cleanColor('--pri-success-700') : cleanColor('--sec-blue-700');

  const isOverage = currentMonthValue > progressBarAllotment;
  const overageColor = cleanColor('--pri-warning-500');

  return (
    <div className="w-full mt-4 py-4 px-6 rounded" style={{ backgroundColor: backgroundColor }}>
      <div className="f-row-y-center text-lg font-medium">
        <div>{progressBarTitle}</div>
        <div className="ml-2" style={{ color: isOverage ? overageColor : textAndBarColor }}>
          {percentFormatted(currentMonthValue, progressBarAllotment)}%
        </div>
      </div>
      <ProgressBar
        numerator={currentMonthValue}
        denominator={progressBarAllotment}
        containerClassName="w-full h-[40px] rounded"
        barColor={textAndBarColor}
        backgroundColor={progressBarBackgroundColor}
        overageBarColor={overageColor}
      />
      <div className="flex mt-1">
        <div className="mr-1" style={{ color: isOverage ? overageColor : textAndBarColor }}>
          {Math.ceil(currentMonthValue)}
        </div>
        {progressBarUnits} out of {progressBarAllotment}
      </div>
    </div>
  );
}
