import React from 'react';

import InfoIcon from 'components/primitives/icons/InfoIcon/InfoIcon';

export default function CreateOrReplaceNowInfoIcon(props: { transformTable: string }) {
  const content = (
    <div>
      <div>
        <strong>Rebuild this transform now.</strong>
      </div>
      <div className="mt-2">
        Run the transform in non-incremental mode. Completely replace the contents of table{' '}
        <code className="ace-code">
          <span className="ace-keyword">{props.transformTable}</span>
        </code>{' '}
        with the results of this transform run.
      </div>
      <div className="mt-2">
        If{' '}
        <code className="ace-code">
          <span className="ace-keyword">{props.transformTable}</span>
        </code>{' '}
        does not exist, this transform will create it.
      </div>
    </div>
  );
  const popoverProps = { style: { maxWidth: '550px', fontSize: '1rem' } };
  return <InfoIcon content={content} popoverProps={popoverProps} placement="top" />;
}
