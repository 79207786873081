/*
  Used by useOnboardingPopoverTourContext and useMockOnboardingPopoverTourContext
  to define basic refs and state. Those two hooks differ in how they
  pick the initial step and how they iterate through steps.
*/
import { useMemo, useRef, useState } from 'react';

import { OnboardingPopoverTourStep, OnboardingPopoverTourRefs } from './OnboardingPopoverTourContext';

export default function useOnboardingPopoverTourState(initialStep: OnboardingPopoverTourStep) {
  const [loaded, setLoaded] = useState(false);
  const [currentStep, setCurrentStep] = useState<OnboardingPopoverTourStep>(initialStep);
  const connectors = useRef<HTMLAnchorElement | null>(null);
  const warehouse = useRef<HTMLAnchorElement | null>(null);
  const transforms = useRef<HTMLAnchorElement | null>(null);
  const dbt = useRef<HTMLAnchorElement | null>(null);
  const query = useRef<HTMLAnchorElement | null>(null);
  const alerts = useRef<HTMLAnchorElement | null>(null);
  const integrations = useRef<HTMLAnchorElement | null>(null);
  const docs = useRef<HTMLAnchorElement | null>(null);
  // const analystSupport = useRef<HTMLButtonElement | null>(null);

  const refs: OnboardingPopoverTourRefs = useMemo(
    () => ({
      connectors,
      warehouse,
      transforms,
      dbt,
      query,
      alerts,
      integrations,
      docs,
      // analyst_support: analystSupport,
    }),
    [connectors],
  );

  // This file memoizes the context it returns to reduce rerenders further down the tree.
  const context = useMemo(
    () => ({
      loaded,
      setLoaded,
      currentStep,
      setCurrentStep,
      refs,
    }),
    [loaded, setLoaded, currentStep, setCurrentStep, refs],
  );

  return context;
}
