import CheckableNumber from 'components/widgets/CheckableNumber/CheckableNumber';

import BulletedStep, { BulletedStepProps } from '../BulletedStep/BulletedStep';

interface CheckableNumberStepProps extends Omit<BulletedStepProps, 'bullet'> {
  number: number;
  checked: boolean;
}

export default function CheckableNumberStep(props: CheckableNumberStepProps) {
  const { number, checked, ...rest } = props;
  const bullet = <CheckableNumber number={number} checked={checked} variant="dullAction" />;

  return <BulletedStep {...rest} bullet={bullet} />;
}
